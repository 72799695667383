import React, { useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment-timezone';
import Api from './API';
import { IApprovalForm, IApprovalFormTableProps } from './types';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { classes } from '../Job/Applications/styles';
import GenericDialog from '../Components/Modals/GenericDialog';
import NotePopover from './NotePopover';
import { Edit as EditIcon } from '@mui/icons-material';
import { isPermittedOption } from './helper';
import Tooltip from '@mui/material/Tooltip';

const BASE_URL = window.location.origin;

const tablePadding = (density: string) => {
  if (density === 'Default') {
    return '22px';
  } else if (density === 'Compact') {
    return '15px';
  } else {
    return '8px';
  }
};

export default function ApprovalFormsTable({
  approvalForms,
  setApprovalForms,
  getApprovalForms,
  getAllApprovalForms,
  globalSelected,
  setGlobalSelected,
  selectAllIsChecked,
  setSelectAllIsChecked,
  selectAllIsIndeterminate,
  setSelectAllIsIndeterminate,
  setSnackbarState,
  isLoading,
  setIsLoading,
  selected,
  setSelected,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalPages,
  totalApprovalForms,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  sortableColumns,
  apiKey,
  userPermissions,
  density,
  archived
}: IApprovalFormTableProps) {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedApprovalForm, setSelectedApprovalForm] = useState<{
    id: null | number;
    note: null | string;
  }>({ id: null, note: null });
  const [selectAllApprovalForms, setSelectAllApprovalForms] = useState<boolean>(false);
  const [isFetchingAllApprovalForms, setIsFetchingAllApprovalForms] = useState<boolean>(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [tableHovered, setTableHovered] = useState(false);
  const [canScroll, setCanScroll] = useState([false, true]);
  const tableRef = useRef<HTMLDivElement>(null);
  const actionCellRef = useRef(null);
  const titleCellRef = useRef(null);
  const preventLoop = useRef(0);
  const [trashAlertOpen, setTrashAlertOpen] = useState(false);
  const [deleteApprovalForms, setDeleteApprovalForms] = useState([]);
  const [noteAnchorEl, setNoteAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newGlobalSelected = globalSelected.filter(
      (currentApp) => !approvalForms.map((app) => app.id).includes(currentApp.id)
    );
    if (event.target.checked) {
      const selectAllGlobalSelected = [...newGlobalSelected, ...approvalForms];
      setSelected(selectAllGlobalSelected.map((approvalForm) => approvalForm.id));
      setGlobalSelected(selectAllGlobalSelected);
      setSelectAllIsChecked(true);
      setSelectAllIsIndeterminate(false);
      setSelectAllApprovalForms(true);
      return;
    }
    setSelected(newGlobalSelected.map((approvalForm) => approvalForm.id));
    setGlobalSelected([]);
    setSelectAllIsChecked(false);
    setSelectAllIsIndeterminate(false);
    setSelectAllApprovalForms(false);
  };

  const handleSelectAllApprovalForms = async () => {
    setIsFetchingAllApprovalForms(true);
    const allApprovalForms = await getAllApprovalForms(
      totalApprovalForms,
      null,
      null,
      null,
      archived
    );
    setGlobalSelected(allApprovalForms);
    setSelected(allApprovalForms.map((approvalForm) => approvalForm.id));
    setSelectAllIsChecked(true);
    setSelectAllApprovalForms(true);
    setSelectAllIsIndeterminate(false);
    setIsFetchingAllApprovalForms(false);
  };

  const clearAllSelection = () => {
    setSelectAllApprovalForms(false);
    setGlobalSelected([]);
    setSelected([]);
    setSelectAllIsChecked(false);
    setSelectAllIsIndeterminate(false);
  };

  const handleArchiveApprovalForms = async (ids: Array<number>) => {
    setIsLoading(true);
    try {
      const response = await Api.postArchiveApprovalForms(
        { 'X-api-authenticate': apiKey },
        { requisition_ids: ids }
      );
      if (response.res?.requisition_ids?.failure?.length) {
        const message =
          response.res.requisition_ids.success?.length > 0 ||
          response.res.requisition_ids.failure?.length > 1
            ? `${response.res.requisition_ids.failure?.length} ${
                response.res.requisition_ids.failure?.length > 1
                  ? 'approval forms were'
                  : 'approval form was'
              } unable to be archived.`
            : `${
                response.res.requisition_ids.failure?.length === 1
                  ? 'Unable to archive approval form.'
                  : ''
              }`;
        setSnackbarState({
          message: message,
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbarState({
        message: error?.errors,
        state: 'error'
      });
      alert('Something went wrong. Please try again.');
    } finally {
      setSelected([]);
      setGlobalSelected([]);
      setSelectedApprovalForm({ id: null, note: null });
      setActionsAnchorEl(null);
      getApprovalForms(null, null, null, null, null, archived);
    }
  };

  const handleUnarchiveApprovalForms = async (ids: Array<number>) => {
    setIsLoading(true);
    try {
      const response = await Api.postUnarchiveApprovalForms(
        { 'X-api-authenticate': apiKey },
        { requisition_ids: ids }
      );
      if (response.res?.requisition_ids?.failure?.length) {
        const message =
          response.res.requisition_ids.success?.length > 0 ||
          response.res.requisition_ids.failure?.length > 1
            ? `${response.res.requisition_ids.failure?.length} ${
                response.res.requisition_ids.failure?.length > 1
                  ? 'approval forms were'
                  : 'approval form was'
              } unable to be unarchived.`
            : `${
                response.res.requisition_ids.failure?.length === 1
                  ? 'Unable to unarchive approval form.'
                  : ''
              }`;
        setSnackbarState({
          message: message,
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbarState({
        message: error?.errors,
        state: 'error'
      });
      alert('Something went wrong. Please try again.');
    } finally {
      setSelected([]);
      setGlobalSelected([]);
      setSelectedApprovalForm({ id: null, note: null });
      setActionsAnchorEl(null);
      getApprovalForms(null, null, null, null, null, archived);
    }
  };

  const handleTrashApprovalForms = async (ids: Array<number>) => {
    setIsLoading(true);
    try {
      const response = await Api.deleteTrashApprovalForms(
        { 'X-api-authenticate': apiKey },
        { requisition_ids: ids }
      );
      if (response.res?.requisition_ids?.failure?.length) {
        const message =
          response.res.requisition_ids.success?.length > 0 ||
          response.res.requisition_ids.failure?.length > 1
            ? `${response.res.requisition_ids.failure?.length} ${
                response.res.requisition_ids.failure?.length > 1
                  ? 'approval forms were'
                  : 'approval form was'
              } unable to be trashed.`
            : `${
                response.res.requisition_ids.failure?.length === 1
                  ? 'Unable to trash approval form.'
                  : ''
              }`;
        setSnackbarState({
          message: message,
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbarState({
        message: error?.errors,
        state: 'error'
      });
      alert('Something went wrong. Please try again.');
    } finally {
      setSelected([]);
      setGlobalSelected([]);
      setSelectedApprovalForm({ id: null, note: null });
      setActionsAnchorEl(null);
      getApprovalForms(null, null, null, null, null, archived);
    }
  };

  const cloneApprovalForm = async (id: number) => {
    setIsLoading(true);

    try {
      const response = await fetch(`/api/v4/requisitions/${id}/clone`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        }
      }).then((response) => response.json());
      if (response.url) {
        window.location.href = `${BASE_URL}${response.url}`;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectOne = (
    event: React.ChangeEvent<HTMLInputElement>,
    approvalForm: IApprovalForm
  ) => {
    let newGlobalSelected = [...globalSelected];
    event.target.checked
      ? newGlobalSelected.push(approvalForm)
      : (newGlobalSelected = newGlobalSelected.filter((app) => app.id !== approvalForm.id));
    const newSelected = newGlobalSelected.map((app) => app.id);
    setSelected(newSelected);
    setGlobalSelected(newGlobalSelected);
    setSelectAllIsChecked(
      approvalForms.map((app) => app.id).every((id) => newSelected.includes(id))
    );
    setSelectAllIsIndeterminate(
      approvalForms.map((app) => app.id).some((id) => newSelected.includes(id))
    );
  };

  const handleRowsPerPageChange = (selection: number) => {
    if (selection === rowsPerPage) return;
    setRowsPerPage(selection);
    getApprovalForms(null, selection, null, null, null, archived);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage === currentPage) return;
    setCurrentPage(newPage);
    sessionStorage.setItem(`approvalFormsTablePage`, newPage);
    getApprovalForms(newPage, null, null, null, null, archived);
  };

  const handleSortChange = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    columnValue: string
  ) => {
    const isAsc = sortBy === columnValue && sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(columnValue);
    getApprovalForms(
      null,
      null,
      null,
      { sortBy: columnValue, sortOrder: newSortOrder },
      null,
      archived
    );
  };

  const handleScroll = () => {
    if (tableRef.current.scrollLeft < 10) {
      setCanScroll([false, true]);
    } else if (
      tableRef.current.scrollLeft >
      tableRef.current.scrollWidth - tableRef.current.clientWidth - 10
    ) {
      setCanScroll([true, false]);
    } else {
      setCanScroll([true, true]);
    }
  };

  const handleScrollLeft = () => {
    tableRef.current.scrollLeft > 360
      ? (tableRef.current.scrollLeft -= 300)
      : (tableRef.current.scrollLeft = 0);
  };

  const handleScrollRight = () => {
    tableRef.current.scrollWidth - tableRef.current.clientWidth > 360
      ? (tableRef.current.scrollLeft += 300)
      : (tableRef.current.scrollLeft = tableRef.current.scrollWidth - tableRef.current.clientWidth);
  };

  function isScrollable(element: HTMLElement) {
    return element && element.scrollWidth > element.clientWidth + 20;
  }

  const handleScrollableEnter = () => {
    if (isScrollable(tableRef.current)) setTableHovered(true);
  };

  const handleScrollableExit = () => {
    setTableHovered(false);
  };

  const handleOpenNote = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
    note: string | null
  ) => {
    setSelectedApprovalForm({ id, note });
    setNoteAnchorEl(event.currentTarget);
  };

  const handleCloseNote = () => {
    setNoteAnchorEl(null);
    setSelectedApprovalForm({ id: null, note: null });
    preventLoop.current = 0;
  };

  const headerStyle = (name: string) => {
    switch (name) {
      case 'position_title':
        return {
          ...classes.sticky,
          ...classes.scrollShadowLeft,
          ...(tableHovered && classes.scrollShadowVisible),
          minWidth: '200px',
          left: '138px'
        };
      case 'id':
        return { ...classes.sticky, maxWidth: '96px', width: '96px', left: '58px' };
      case 'note':
        return { minWidth: '300px' };
      case 'created_at':
        return { minWidth: '120px' };
      case 'updated_at':
        return { minWidth: '120px' };
      case 'requested_by':
        return { minWidth: '140px' };
      case 'form_template':
        return { minWidth: '145px' };
      case 'assigned_user':
        return { minWidth: '135px' };
      case 'state':
        return { minWidth: '120px', textAlign: 'center' };
      default:
        return { minWidth: '90px' };
    }
  };

  const displaySelectAll =
    selectAllApprovalForms &&
    globalSelected.length !== totalApprovalForms &&
    totalApprovalForms > rowsPerPage;

  const renderTableCell = useCallback(
    (
      column: IApprovalFormTableProps['sortableColumns'][0],
      approvalForm: IApprovalForm,
      createdAt: JSX.Element[] | '-',
      updatedAt: JSX.Element[] | '-'
    ) => {
      switch (column.id) {
        case 'id':
          return (
            <TableCell
              key={approvalForm.id + column.id}
              sx={{
                ...classes.noLinkStyling,
                ...classes.sticky,
                minWidth: '80px',
                left: '58px'
              }}
            >
              <a href={approvalForm.requisition_link} className="approval-form-id-link">
                {approvalForm.id || '-'}
              </a>
            </TableCell>
          );
        case 'position_title':
          return (
            <TableCell
              key={approvalForm.id + column.id}
              sx={{
                ...classes.sticky,
                minWidth: '200px',
                left: '138px',
                ...classes.scrollShadowLeft,
                ...(tableHovered && classes.scrollShadowVisible)
              }}
            >
              <Box
                sx={{
                  '& a': {
                    color: '#666666',
                    fontWeight: 'bold',
                    '&:hover': {
                      textDecoration: 'none'
                    }
                  }
                }}
              >
                <a href={approvalForm.requisition_link} className="approval-form-title-link">
                  {approvalForm.position_title || '-'}
                </a>
              </Box>
            </TableCell>
          );
        case 'created_at':
          return <TableCell key={approvalForm.id + column.id}>{createdAt}</TableCell>;
        case 'updated_at':
          return <TableCell key={approvalForm.id + column.id}>{updatedAt}</TableCell>;
        case 'requested_by':
          return (
            <TableCell key={approvalForm.id + column.id}>
              {approvalForm.requested_by || '-'}
            </TableCell>
          );
        case 'state':
          return (
            <TableCell>
              <Box
                sx={{
                  ...classes[approvalForm.state],
                  width: '112px',
                  textAlign: 'center'
                }}
              >
                {approvalForm.state}
              </Box>
            </TableCell>
          );
        case 'entity':
          return <TableCell>{approvalForm.entity_name || '-'}</TableCell>;
        case 'form_template':
          return <TableCell>{approvalForm.form_template || '-'}</TableCell>;
        case 'assigned_user':
          return <TableCell>{approvalForm.assigned_user || '-'}</TableCell>;
        case 'note':
          return <TableCell>{approvalForm.note || '-'}</TableCell>;
        default:
          return <TableCell></TableCell>;
      }
    },
    [currentPage, rowsPerPage, selectedApprovalForm.id, tableHovered, userPermissions]
  );

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box sx={classes.arrowsContainer}>
          <Box
            onClick={handleScrollLeft}
            onMouseEnter={handleScrollableEnter}
            sx={{
              ...classes.leftArrow,
              ...(tableHovered && canScroll[0] && classes.showArrows),
              left: `${titleCellRef.current?.getBoundingClientRect().right}px`,
              top: 0
            }}
            id="left-arrow"
          >
            <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <Box sx={classes.arrowsContainer}>
          <Box
            onMouseEnter={handleScrollableEnter}
            onClick={handleScrollRight}
            sx={{
              ...classes.rightArrow,
              ...(tableHovered && canScroll[1] && classes.showArrows),
              left: `${actionCellRef.current?.getBoundingClientRect().left - 40}px`,
              top: 0
            }}
            id="right-arrow"
          >
            <KeyboardArrowRightIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <TableContainer
          sx={{ boxShadow: 'none', scrollBehavior: 'smooth' }}
          component={Paper}
          onMouseEnter={handleScrollableEnter}
          onMouseLeave={handleScrollableExit}
          ref={tableRef}
          onScroll={handleScroll}
        >
          <Table
            sx={{
              ...classes.tableWrapper,
              'th, td, tr, thead': { padding: `${tablePadding(density)} 10px` }
            }}
            aria-label="approval forms"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...classes.sticky, width: '58px' }}>
                  <Checkbox
                    id="select-all-checkbox"
                    sx={classes.checkbox}
                    indeterminate={!selectAllIsChecked && selectAllIsIndeterminate}
                    checked={selectAllIsChecked}
                    onChange={handleSelectAll}
                    inputProps={{
                      'aria-label': 'select all approval forms'
                    }}
                  />
                </TableCell>
                {sortableColumns.map(
                  (column) =>
                    column.enabled &&
                    isPermittedOption(column.id, userPermissions) && (
                      <TableCell
                        key={column.id}
                        sx={headerStyle(column.id)}
                        ref={column.id === 'position_title' ? titleCellRef : null}
                      >
                        <TableSortLabel
                          active={sortBy === column.id}
                          direction={sortBy === column.id ? (sortOrder as 'asc' | 'desc') : 'asc'}
                          onClick={(event) => handleSortChange(event, column.id)}
                          sx={column.sortable ? classes.sortable : null}
                          disabled={!column.sortable}
                          id={`${column.id}-sort-label-approval-forms`}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    )
                )}
                {(userPermissions?.['Approval Forms']?.['Archive Approval Forms'] ||
                  userPermissions?.['Approval Forms']?.['Manage Approval Form Notes'] ||
                  userPermissions?.['Approval Forms']?.[
                    'Edit Approval Form at Any Stage (HR use)'
                  ] ||
                  userPermissions?.['Approval Forms']?.['Edit Previously Submitted Form'] ||
                  userPermissions?.['Approval Forms']?.['Clone Previously Submitted Form']) && (
                  <TableCell
                    sx={{
                      zIndex: '4',
                      ...classes.attachmentsHeader,
                      ...classes.stickyRight,
                      ...classes.scrollShadowRight,
                      ...(tableHovered && classes.scrollShadowVisible)
                    }}
                  />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {approvalForms &&
                !isLoading &&
                approvalForms.map((approvalForm: IApprovalForm, index) => {
                  const createdAt = approvalForm.created_at
                    ? moment
                        .tz(approvalForm.created_at, timeZoneString)
                        .format('DD MMM YYYY,-h:mma z')
                        .split('-')
                        .map((line) => <div key={`${approvalForm.id}-${line}`}>{line}</div>)
                    : '-';
                  const updatedAt = approvalForm.updated_at
                    ? moment
                        .tz(approvalForm.updated_at, timeZoneString)
                        .format('DD MMM YYYY,-h:mma z')
                        .split('-')
                        .map((line) => <div key={`${approvalForm.id}-${line}`}>{line}</div>)
                    : '-';
                  return (
                    <TableRow key={approvalForm.id}>
                      <TableCell sx={{ ...classes.sticky, width: '58px' }}>
                        <Checkbox
                          id={`select-checkbox-${approvalForm.id}`}
                          sx={classes.checkbox}
                          checked={selected.includes(approvalForm.id)}
                          onChange={(event) => handleSelectOne(event, approvalForm)}
                          inputProps={{
                            'aria-label': `select approval form ${approvalForm.position_title}`
                          }}
                        />
                      </TableCell>
                      {sortableColumns.map((column) => {
                        if (column.enabled && isPermittedOption(column.id, userPermissions)) {
                          return renderTableCell(column, approvalForm, createdAt, updatedAt);
                        }
                      })}
                      {userPermissions?.['Approval Forms']?.['Archive Approval Forms'] ||
                      userPermissions?.['Approval Forms']?.['Manage Approval Form Notes'] ||
                      userPermissions?.['Approval Forms']?.[
                        'Edit Approval Form at Any Stage (HR use)'
                      ] ||
                      userPermissions?.['Approval Forms']?.['Edit Previously Submitted Form'] ||
                      userPermissions?.['Approval Forms']?.['Clone Previously Submitted Form'] ? (
                        <TableCell
                          sx={{
                            zIndex: '4',
                            ...classes.attachmentsCell,
                            ...classes.stickyRight,
                            ...classes.scrollShadowRight,
                            ...(tableHovered && classes.scrollShadowVisible)
                          }}
                          ref={actionCellRef}
                        >
                          <Box sx={classes.actionItems}>
                            {archived ? (
                              <>
                                {userPermissions?.['Approval Forms']?.[
                                  'Archive Approval Forms'
                                ] && (
                                  <Tooltip placement="right" title="Unarchive" arrow>
                                    <IconButton
                                      className="unarchive-button"
                                      onClick={(e) =>
                                        handleUnarchiveApprovalForms([approvalForm.id])
                                      }
                                    >
                                      <UnarchiveIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </>
                            ) : (
                              <>
                                {userPermissions?.['Approval Forms']?.[
                                  'Manage Approval Form Notes'
                                ] && (
                                  <Tooltip placement="top" title="Add note" arrow>
                                    <IconButton
                                      className="note-button"
                                      onClick={(e) =>
                                        handleOpenNote(e, approvalForm.id, approvalForm.note)
                                      }
                                    >
                                      <NoteAddIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {(userPermissions?.['Approval Forms']?.[
                                  'Edit Approval Form at Any Stage (HR use)'
                                ] ||
                                  ((approvalForm.state === 'draft' ||
                                    approvalForm.state === 'pending') &&
                                    userPermissions?.['Approval Forms']?.[
                                      'Edit Previously Submitted Form'
                                    ])) && (
                                  <Tooltip placement="top" title="Edit" arrow>
                                    <IconButton
                                      onClick={() => {
                                        window.location.href =
                                          BASE_URL + `/admin/requisitions/${approvalForm.id}/edit`;
                                      }}
                                      className="edit-approval-form-action-button"
                                    >
                                      <EditIcon sx={{ color: '#666666' }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {(userPermissions?.['Approval Forms']?.[
                                  'Clone Previously Submitted Form'
                                ] ||
                                  userPermissions?.['Approval Forms']?.['Archive Approval Forms'] ||
                                  userPermissions?.['Approval Forms']?.[
                                    'Edit Approval Form at Any Stage (HR use)'
                                  ] ||
                                  userPermissions?.['Approval Forms']?.[
                                    'Edit Previously Submitted Form'
                                  ]) && (
                                  <Tooltip placement="top" title="More options" arrow>
                                    <IconButton
                                      onClick={(e) => {
                                        setActionsAnchorEl(e.currentTarget);
                                        setSelectedApprovalForm({
                                          id: approvalForm.id,
                                          note: null
                                        });
                                      }}
                                      className="approval-form-action-dropdown-button"
                                    >
                                      <MoreVertIcon sx={{ color: '#666666' }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </Box>
                        </TableCell>
                      ) : (
                        <TableCell ref={actionCellRef}></TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!isLoading && approvalForms?.length === 0 && (
            <Box sx={classes.noApplications}>No Approval Forms found</Box>
          )}
          {isLoading && (
            <>
              {[...Array(10)].map((_, index) => (
                <Box key={index} sx={classes.skeletonContainer}>
                  <Skeleton animation="wave" height={60} />
                </Box>
              ))}
            </>
          )}
        </TableContainer>
      </Box>
      <Box sx={classes.paginationParent}>
        <Box sx={classes.rowSelectContainer}>
          <span>Rows per page: </span>
          <FormControl>
            <Select
              id="rows-per-page"
              sx={classes.rowSelect}
              value={rowsPerPage}
              onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
              displayEmpty
              MenuProps={{ sx: classes.paginationMenuItems }}
            >
              <MenuItem id="first-rpp-item" value={10}>
                10
              </MenuItem>
              <MenuItem id="second-rpp-item" value={20}>
                20
              </MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={classes.paginationContainer}>
          <Pagination
            id="pagination-menu"
            count={totalPages}
            page={currentPage}
            siblingCount={0}
            onChange={handlePageChange}
            sx={classes.pagination}
          />
          <span id="total-jobs">{totalApprovalForms} Total</span>
        </Box>
      </Box>
      <Box
        id="selected-tooltip"
        sx={
          globalSelected?.length > 0
            ? { ...classes.selectedTooltip, ...classes.fadeIn }
            : { ...classes.selectedTooltip, ...classes.fadeOut }
        }
      >
        <Box
          id="selected-tooltip-text"
          sx={{
            display: 'flex',
            alignItems: 'center',
            ...classes.selectedTooltipText
          }}
        >
          {globalSelected.length === totalApprovalForms
            ? `All ${globalSelected?.length} approval forms are selected`
            : `${globalSelected?.length} selected`}
          <IconButton
            size="small"
            sx={{
              ...classes.selectedTooltipText,
              ...classes.selectedAllTooltipText,
              marginLeft: 1,
              color: '#666666'
            }}
            onClick={clearAllSelection}
          >
            Clear selection
          </IconButton>
        </Box>
        {displaySelectAll && (
          <IconButton
            disabled={isFetchingAllApprovalForms}
            size="small"
            sx={{ ...classes.selectedTooltipText, ...classes.selectedAllTooltipText }}
            onClick={handleSelectAllApprovalForms}
          >
            Select all {totalApprovalForms} approval forms
          </IconButton>
        )}
        {!archived && (
          <NotePopover
            setApprovalForms={setApprovalForms}
            anchorEl={noteAnchorEl}
            handleCloseNote={handleCloseNote}
            approvalFormId={selectedApprovalForm?.id}
            approvalFormNote={selectedApprovalForm?.note}
            apiKey={apiKey}
            setSnackbarState={setSnackbarState}
          />
        )}
        {!archived && (
          <Popover
            id={actionsAnchorEl ? 'actions-menu-popover' : undefined}
            sx={classes.actionsMenu}
            open={Boolean(actionsAnchorEl)}
            anchorEl={actionsAnchorEl}
            onClose={() => {
              setActionsAnchorEl(null);
              setSelectedApprovalForm({ id: null, note: null });
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {userPermissions?.['Approval Forms']?.['Clone Previously Submitted Form'] && (
              <span
                onClick={() => {
                  setActionsAnchorEl(null);
                  cloneApprovalForm(selectedApprovalForm.id);
                }}
                className="clone-approval-form-action-button"
              >
                Clone
              </span>
            )}
            {userPermissions?.['Approval Forms']?.['Archive Approval Forms'] && (
              <span
                onClick={() => handleArchiveApprovalForms([selectedApprovalForm.id])}
                className="archive-approval-form-action-button"
              >
                Archive
              </span>
            )}
            {(userPermissions?.['Approval Forms']?.['Edit Approval Form at Any Stage (HR use)'] ||
              userPermissions?.['Approval Forms']?.['Edit Previously Submitted Form']) && (
              <span
                onClick={() => {
                  setActionsAnchorEl(null);
                  setTimeout(() => setTrashAlertOpen(true), 100);
                  setDeleteApprovalForms([selectedApprovalForm.id]);
                }}
                className="trash-approval-form-action-button"
              >
                Trash
              </span>
            )}
          </Popover>
        )}
        {(userPermissions?.['Approval Forms']?.['Archive Approval Forms'] ||
          userPermissions?.['Approval Forms']?.['Edit Approval Form at Any Stage (HR use)'] ||
          userPermissions?.['Approval Forms']?.['Edit Previously Submitted Form']) && (
          <Box sx={classes.selectedTooltipButtonsContainer}>
            {userPermissions?.['Approval Forms']?.['Archive Approval Forms'] &&
              (archived ? (
                <Button
                  variant="contained"
                  sx={classes.selectedTooltipButton}
                  onClick={() => handleUnarchiveApprovalForms(selected)}
                >
                  Unarchive
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={classes.selectedTooltipButton}
                  onClick={() => handleArchiveApprovalForms(selected)}
                >
                  Archive
                </Button>
              ))}
            {(userPermissions?.['Approval Forms']?.['Edit Approval Form at Any Stage (HR use)'] ||
              userPermissions?.['Approval Forms']?.['Edit Previously Submitted Form']) && (
              <Button
                variant="contained"
                sx={classes.selectedTooltipButton}
                onClick={() => {
                  setDeleteApprovalForms(selected);
                  setTrashAlertOpen(true);
                }}
              >
                Trash
              </Button>
            )}
          </Box>
        )}
      </Box>
      <GenericDialog
        isDialogOpen={trashAlertOpen}
        setDialogOpen={setTrashAlertOpen}
        title={deleteApprovalForms.length > 1 ? 'Trash approval forms?' : 'Trash approval form?'}
        description={
          deleteApprovalForms.length > 1
            ? 'Are you sure want to trash these approval forms?'
            : 'Are you sure want to trash this approval form?'
        }
        buttonCallback={() => {
          setTrashAlertOpen(false);
          handleTrashApprovalForms(deleteApprovalForms);
          setDeleteApprovalForms([]);
        }}
        callbackLoading={false}
        buttonText="Trash"
        url=""
      />
    </Box>
  );
}
