"use strict";
export const dropdownOptions = [
  {
    name: "title",
    label: "Title",
    id: 1,
    previousCellOffSet: true,
    sticky: true,
    leftBound: true,
    style: { width: "20%", minWidth: "150px" }
  },
  {
    name: "created_at",
    label: "Created At",
    id: 2,
    style: { width: "15%", minWidth: "140px", paddingRight: "40px !important" }
  },
  {
    name: "updated_at",
    label: "Last Saved On",
    id: 3,
    style: { width: "15%", minWidth: "140px", paddingRight: "40px !important" }
  },
  {
    name: "query_config",
    label: "Report Period",
    id: 4,
    style: { width: "15%", minWidth: "155px", paddingRight: "40px !important" }
  },
  {
    name: "user_name",
    label: "Created By",
    id: 5,
    style: { width: "15%", minWidth: "140px" }
  }
];
export const defaultActiveColumns = {
  title: { enabled: true, styles: { width: "40%" } },
  created_at: { enabled: true, styles: { width: "20%" } },
  updated_at: { enabled: true, styles: { width: "20%" } },
  query_config: { enabled: true, styles: { width: "20%" } },
  user_name: { enabled: true, styles: { width: "20%" } }
};
export const filteringStates = {
  report_type: [],
  user: []
};
