var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Chip, CircularProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Api from "../../lib/Api";
import { classes } from "./styles";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(isToday);
function ActivityFeed() {
  const [activityList, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  function getActivities(prevId) {
    return __async(this, null, function* () {
      setLoading(true);
      const response = yield Api.getActivities(prevId);
      if (response[0]) {
        setList([...activityList, ...response]);
      } else {
        setFinished(true);
      }
      setLoading(false);
    });
  }
  function checkScroll(evt) {
    if (loading || finished) return;
    if (evt.target.scrollTop + evt.target.clientHeight < evt.target.scrollHeight - 50) return;
    const lastDate = activityList[activityList.length - 1];
    const lastActivity = lastDate.activities[lastDate.activities.length - 1];
    getActivities(lastActivity.id);
  }
  useEffect(() => {
    getActivities();
  }, []);
  if (activityList[0]) activityList[0].isToday = dayjs(activityList[0].date).isToday();
  return /* @__PURE__ */ React.createElement(Box, { mb: 2 }, /* @__PURE__ */ React.createElement(Accordion, { defaultExpanded: true, sx: classes.accordian }, /* @__PURE__ */ React.createElement(
    AccordionSummary,
    {
      sx: classes.accordianButton,
      expandIcon: /* @__PURE__ */ React.createElement(ExpandMoreIcon, { sx: { color: "#ffffff" } })
    },
    /* @__PURE__ */ React.createElement(Typography, { sx: classes.accordianTitle }, "Activity Feed")
  ), /* @__PURE__ */ React.createElement(
    AccordionDetails,
    {
      sx: classes.activitiesContainer,
      onScroll: checkScroll
    },
    activityList.map(({ date, activities, isToday: isToday2 }) => /* @__PURE__ */ React.createElement(Box, { py: 2, key: date, width: "100%" }, /* @__PURE__ */ React.createElement(Box, { sx: classes.dateContainer }, /* @__PURE__ */ React.createElement(
      Chip,
      {
        size: "small",
        label: isToday2 ? "Today" : dayjs(date).format("LL"),
        sx: classes.chip
      }
    )), activities.map(({ id, timestamp, actor, activity_html }) => /* @__PURE__ */ React.createElement(Box, { key: id, py: 1 }, /* @__PURE__ */ React.createElement(Typography, { sx: classes.timeStamp }, isToday2 ? dayjs().to(dayjs(timestamp)) : dayjs(timestamp).format("LT")), /* @__PURE__ */ React.createElement(Typography, { sx: classes.actor }, actor), /* @__PURE__ */ React.createElement(
      Typography,
      {
        dangerouslySetInnerHTML: { __html: activity_html },
        sx: classes.activity
      }
    ))))),
    loading && /* @__PURE__ */ React.createElement(Box, { display: "flex", justifyContent: "center" }, /* @__PURE__ */ React.createElement(CircularProgress, null))
  )));
}
export default ActivityFeed;
