import React, { useState, useRef, useEffect } from "react";
import useStyles from "./useStyles";
import PropTypes from "prop-types";
import {
  Button,
  IconButton,
  Popper,
  TextareaAutosize,
  CircularProgress,
  ClickAwayListener,
  ButtonGroup
} from "@material-ui/core";
import {
  TemplatePicker,
  TagPicker,
  SignatureToggle
} from "./components";
import DayPickerInput from "react-day-picker/DayPickerInput";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TimeField from "react-simple-timefield";
import { formatDate } from "react-day-picker/moment";
function ChatBox({
  conversation,
  userOptions,
  body,
  setBody,
  signature,
  setSignature,
  handleSend,
  sending,
  scheduleDate,
  setScheduleDate,
  scheduleTime,
  setScheduleTime,
  isPm,
  setIsPm,
  job
}) {
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
  const timeInputEl = useRef(null);
  const anchor = useRef(null);
  const textArea = useRef(null);
  const classes = useStyles();
  function insertTag(tag) {
    const index = textArea.current.selectionStart;
    setBody(body.slice(0, index) + tag + body.substring(index));
  }
  function handleTimeChange(event, value) {
    const time = value.replace(/-/g, ":");
    const [hour, minute] = time.split(":");
    hour >= 12 ? setScheduleTime(`12:${minute}:00`) : setScheduleTime(`${hour}:${minute}:00`);
  }
  return /* @__PURE__ */ React.createElement("div", { className: classes.root }, /* @__PURE__ */ React.createElement(
    TextareaAutosize,
    {
      ref: textArea,
      className: classes.textArea,
      minRows: 2,
      maxRows: 6,
      placeholder: `Reply to ${conversation.length > 1 ? `${conversation.length} applicants` : conversation[0].candidate_name}`,
      value: body,
      onChange: (e) => setBody(e.target.value)
    }
  ), signature && /* @__PURE__ */ React.createElement("div", { className: classes.signature }, signature), /* @__PURE__ */ React.createElement("div", { className: classes.actions }, /* @__PURE__ */ React.createElement("div", { className: classes.actionButtons }, /* @__PURE__ */ React.createElement(
    TagPicker,
    {
      insertTag,
      job
    }
  ), /* @__PURE__ */ React.createElement(
    TemplatePicker,
    {
      setBody,
      userOptions
    }
  ), userOptions && userOptions.signature && /* @__PURE__ */ React.createElement(
    SignatureToggle,
    {
      signature,
      setSignature,
      userOptions
    }
  )), /* @__PURE__ */ React.createElement("div", { className: classes.characterCount }, body.length + signature.length, "/420 characters"), /* @__PURE__ */ React.createElement("div", { className: classes.send }, /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      variant: "contained",
      classes: { root: classes.sendButton },
      onClick: handleSend
    },
    sending ? /* @__PURE__ */ React.createElement(CircularProgress, { thickness: 4.5, size: 28, classes: { root: classes.spinner } }) : scheduleDate ? "Schedule" : "Send"
  ), /* @__PURE__ */ React.createElement(ClickAwayListener, { onClickAway: () => setIsSchedulerOpen(false) }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    IconButton,
    {
      classes: { root: `${classes.schedulerButton} ${isSchedulerOpen ? classes.schedulerButtonActive : ""}` },
      ref: anchor,
      onClick: () => setIsSchedulerOpen(!isSchedulerOpen)
    },
    /* @__PURE__ */ React.createElement(ArrowDropDownIcon, { fontSize: "small" })
  ), /* @__PURE__ */ React.createElement(
    Popper,
    {
      className: `${classes.popper} ${classes.popperScheduler}`,
      anchorEl: anchor.current,
      open: isSchedulerOpen,
      placement: "top-end",
      popperOptions: { positionFixed: true }
    },
    /* @__PURE__ */ React.createElement("h4", null, "Schedule message to be sent"),
    /* @__PURE__ */ React.createElement("div", { className: classes.schedulerOptions }, /* @__PURE__ */ React.createElement(
      DayPickerInput,
      {
        value: scheduleDate,
        onDayChange: (day) => setScheduleDate(formatDate(day, "LL")),
        classNames: {
          container: classes.calendarContainer
        },
        format: "LL",
        placeholder: "Select a date"
      }
    ), /* @__PURE__ */ React.createElement("div", { className: classes.startingTimeInputWrapper }, /* @__PURE__ */ React.createElement(
      TimeField,
      {
        ref: timeInputEl,
        value: scheduleTime,
        onChange: handleTimeChange,
        className: classes.startTimeInput
      }
    ), /* @__PURE__ */ React.createElement(ButtonGroup, { className: classes.timeBntGroupWrapper }, /* @__PURE__ */ React.createElement(
      Button,
      {
        className: !isPm && classes.isAMActive,
        onClick: () => setIsPm(false)
      },
      "AM"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        className: isPm && classes.isPMActive,
        onClick: () => setIsPm(true)
      },
      "PM"
    )), scheduleDate && /* @__PURE__ */ React.createElement(
      IconButton,
      {
        onClick: () => {
          setScheduleDate("");
          setScheduleTime("00:00");
        }
      },
      /* @__PURE__ */ React.createElement(HighlightOffIcon, { color: "error" })
    )))
  ))))));
}
ChatBox.propTypes = {
  conversation: PropTypes.array.isRequired,
  userOptions: PropTypes.object.isRequired,
  body: PropTypes.string,
  setBody: PropTypes.func.isRequired,
  signature: PropTypes.string,
  setSignature: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  scheduleDate: PropTypes.string.isRequired,
  setScheduleDate: PropTypes.func.isRequired,
  scheduleTime: PropTypes.string.isRequired,
  setScheduleTime: PropTypes.func.isRequired,
  isPm: PropTypes.bool.isRequired,
  setIsPm: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired
};
export default ChatBox;
