import React, { useCallback, useEffect, useState } from 'react';
import { Box, ToggleButtonGroup, ToggleButton, Skeleton } from '@mui/material';
import IndeedAds from '../Components/Multiposter/Indeed/IndeedAds';
import SeekAds from '../Components/Multiposter/Seek/SeekAds';
import IdibuAds from '../Components/Multiposter/Idibu/IdibuAds';
import BroadbeanAds from '../Components/Multiposter/Broadbean/BroadbeanAds';
import CouncilJobsAds from '../Components/Multiposter/CouncilJobs/CouncilJobsAds';
import { ISingleMultiposterProps } from './types';
import { classes } from './styles';

export default function SingleMultiposter({ apiKey, jobId, jobTitle }: ISingleMultiposterProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [showSeek, setShowSeek] = useState(false);
  const [showIndeed, setShowIndeed] = useState(false);
  const [showIdibu, setShowIdibu] = useState(false);
  const [showBroadbean, setShowBroadbean] = useState(false);
  const [showCouncilJobs, setShowCouncilJobs] = useState(false);
  const [multiposterSelection, setMultiposterSelection] = useState('');

  const getPermissions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/v4/job_ad_permissions?job_id=${jobId}`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setShowSeek(response.seek);
      setShowIndeed(response.indeed);
      setShowIdibu(response.idibu);
      setShowBroadbean(response.broadbean);
      setShowCouncilJobs(response.council_jobs);
      switch (true) {
        case response.seek:
          setMultiposterSelection('seek');
          break;
        case response.indeed:
          setMultiposterSelection('indeed');
          break;
        case response.idibu:
          setMultiposterSelection('idibu');
          break;
        case response.broadbean:
          setMultiposterSelection('broadbean');
          break;
        case response.council_jobs:
          setMultiposterSelection('counciljobs');
          break;
        default:
          setMultiposterSelection('');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, jobId]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const displayAdType = () => {
    switch (multiposterSelection) {
      case 'seek':
        return <SeekAds apiKey={apiKey} jobId={jobId} />;
      case 'indeed':
        return <IndeedAds apiKey={apiKey} jobId={jobId} />;
      case 'idibu':
        return <IdibuAds apiKey={apiKey} jobId={jobId} />;
      case 'broadbean':
        return <BroadbeanAds apiKey={apiKey} jobId={jobId} />;
      case 'counciljobs':
        return <CouncilJobsAds apiKey={apiKey} jobId={jobId} />;
      default:
        return <Box>Multiposter is not enabled for this job</Box>;
    }
  };

  return (
    <Box sx={classes.jobPageContainer}>
      <Box sx={classes.jobPageHeader}>
        <h1>{jobTitle}</h1>
      </Box>
      <Box sx={classes.multiposterContainer}>
        <Box sx={classes.toggleContainer}>
          <ToggleButtonGroup
            value={multiposterSelection}
            exclusive
            onChange={(e, value) => {
              if (value === null) return;
              setMultiposterSelection(value);
            }}
            aria-label="Multiposter selection"
            sx={classes.toggleButtonGroup}
          >
            {showSeek && (
              <ToggleButton sx={classes.toggleButton} value="seek" id="multiposter-seek-tab">
                <Box id="seek-logo" sx={classes.logoSize} />
              </ToggleButton>
            )}
            {showIndeed && (
              <ToggleButton sx={classes.toggleButton} value="indeed" id="multiposter-indeed-tab">
                <Box id="indeed-logo" sx={{ transform: 'translateY(3px)', ...classes.logoSize }} />
              </ToggleButton>
            )}
            {showIdibu && (
              <ToggleButton sx={classes.toggleButton} value="idibu" id="multiposter-idibu-tab">
                <Box id="idibu-logo" sx={classes.logoSize} />
              </ToggleButton>
            )}
            {showBroadbean && (
              <ToggleButton
                sx={classes.toggleButton}
                value="broadbean"
                id="multiposter-broadbean-tab"
              >
                <Box id="broadbean-logo" sx={classes.logoSize} />
              </ToggleButton>
            )}
            {showCouncilJobs && (
              <ToggleButton
                sx={classes.toggleButton}
                value="counciljobs"
                id="multiposter-counciljobs-tab"
              >
                <Box id="counciljobs-logo" sx={classes.logoSize} />
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Box>
        {isLoading ? <Skeleton sx={classes.skeleton} animation="wave" /> : displayAdType()}
      </Box>
    </Box>
  );
}
