import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import SelectedApplicants from '../SelectedApplicants';
import { IExtendedActionModalProps } from '../../types';
import { classes } from '../styles';
import moment from 'moment';

interface IFailedExports {
  candidate_name: string;
  error: string;
}

export default function ExportToOnboard({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  filters
}: IExtendedActionModalProps) {
  const [isSending, setIsSending] = useState(false);
  const [failedExports, setFailedExports] = useState<IFailedExports[] | null>(null);

  const handleClose = () => {
    setFailedExports(null);
    setModalsOpen((prev) => ({ ...prev, exportToOnboard: false }));
  };

  const handleSubmit = async () => {
    setIsSending(true);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/scout_onboard_exports`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        body: JSON.stringify({
          start_date: moment().format('YYYY-MM-DD'),
          application_ids: globalSelected.map((app) => app.id)
        })
      });
      if (response.ok) {
        const data = await response.json();
        if (data.errors) {
          setFailedExports(data.errors);
          setSnackbar({
            message: 'Some candidates failed to export',
            state: 'warning'
          });
        } else {
          handleClose();
          setSnackbar({
            message: 'Successfully exported candidate(s) to SCOUT Onboard',
            state: 'success'
          });
        }
      }
    } catch (error) {
      setSnackbar({
        message: 'Error exporting candidate(s) to SCOUT Onboard',
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Modal
      open={modalsOpen.exportToOnboard}
      onClose={() => setModalsOpen((prev) => ({ ...prev, exportToOnboard: false }))}
      aria-labelledby="export-onboard-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon
          onClick={() => setModalsOpen((prev) => ({ ...prev, exportToOnboard: false }))}
          sx={classes.closeIcon}
        />
        <Box id="export-onboard-title" sx={classes.actionModalHeader}>
          Export candidates to Onboard
        </Box>
        <Box
          sx={classes.modalContent}
          id="export-onboard-modal-content"
          aria-label="Export Onboard Modal Content"
        >
          <Box sx={{ marginLeft: '15px' }}>
            {!failedExports ? (
              <>
                <h3>Candidates</h3>
                <SelectedApplicants
                  globalSelected={globalSelected}
                  handleDeselectCandidate={handleDeselectCandidate}
                  closeModalCallback={() =>
                    setModalsOpen((prev) => ({ ...prev, exportToOnboard: false }))
                  }
                  filters={filters}
                />
              </>
            ) : (
              <Box sx={classes.centerColumn}>
                <h3>Some candidates failed to export:</h3>
                <Box sx={{ ...classes.centerColumn, ...classes.exportErrors }}>
                  {failedExports.map((failedExport) => (
                    <Box key={failedExport.candidate_name} sx={classes.centerColumn}>
                      <h4>{failedExport.candidate_name}</h4>
                      <p>{failedExport.error}</p>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {!failedExports ? (
          <Box sx={classes.modalFooter}>
            <Button
              id="close-export-onboard-button"
              sx={classes.modalCancelButton}
              onClick={() => setModalsOpen((prev) => ({ ...prev, exportToOnboard: false }))}
            >
              Cancel
            </Button>
            <Button
              id="submit-export-onboard-button"
              type="submit"
              sx={{ ...classes.modalSendButton, width: '160px', height: '41px' }}
              onClick={handleSubmit}
            >
              {isSending ? <CircularProgress size={20} color="inherit" /> : 'Export to Onboard'}
            </Button>
          </Box>
        ) : (
          <Box sx={classes.modalFooter}>
            <Button
              id="close-export-onboard-button"
              sx={{ ...classes.modalSendButton, width: '91px', height: '41px' }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
