import * as React from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';
import { classes } from '../styles';
import { SearchState, SearchAction } from '../useReport';

interface Props {
  entityOptions: {
    value: string;
    label: string;
  }[];
  isLoading: boolean;
  reportState: SearchState;
  reportDispatch: React.Dispatch<SearchAction>;
  fieldName: string;
  resetOptions: {
    [key: string]: [];
  };
}

export default function EntityField(myProps: Props) {
  const { entityOptions, reportState, isLoading, reportDispatch, fieldName, resetOptions } =
    myProps;
  return (
    <Box sx={{ position: 'relative', marginBottom: '4px' }}>
      <Autocomplete
        id="entity"
        multiple
        options={entityOptions}
        getOptionLabel={(option) => option.name}
        value={reportState.entity}
        loading={isLoading && entityOptions?.length === 0}
        loadingText={isLoading ? 'Loading entities...' : 'No entities'}
        sx={{ ...classes.formAutocomplete, ...classes.formAutocompleteDynamicWidth }}
        ListboxProps={{ style: { ...classes.autoCompleteListStyles } }}
        renderInput={(params) => (
          <TextField {...params} label="Entities" InputLabelProps={{ shrink: true }} />
        )}
        onChange={(event, value) => {
          if (value && value?.length) {
            //If All entities options is selected, remove all other selected options.
            if (value[value.length - 1].name === 'All entities') {
              value = [value[value.length - 1]];
            } else {
              //If All entities options is already selected and a new option is selected, remove the 'All entities' option
              if (value[0].name === 'All entities') value.shift();
            }
          }
          reportDispatch({ type: fieldName, payload: { entity: value, ...resetOptions } });
        }}
      />
    </Box>
  );
}
