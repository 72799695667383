import React, { useState } from 'react';
import { Box, SxProps } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AttachmentSelector from '../Utilities/AttachmentSelector';
import DocTypeIcon from '../Utilities/DocTypeIcon';
import { humanFileSize } from '../Utilities/humanFileSize';
import { sharedClasses } from './sharedClasses';

const AttachmentSelectorAndPreview = ({
  attachments,
  setAttachments,
  templateAttachments,
  selectedPoolIds,
  styleOverrides
}: {
  attachments: any[];
  setAttachments: any;
  templateAttachments?: any[];
  selectedPoolIds?: number[];
  styleOverrides?: SxProps;
}) => {
  const [attachmentHovered, setAttachmentHovered] = useState(-1);
  const removeAttachment = (index: number) => {
    if (index === -1) return;
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };
  return (
    <Box sx={{ ...sharedClasses.modalSection, paddingRight: '3px', ...styleOverrides }}>
      {!selectedPoolIds && (
        <AttachmentSelector
          performUpload={false}
          setFileList={setAttachments}
          maxSize={50000000}
          heightOverride="240px"
        />
      )}
      {(attachments?.length > 0 || templateAttachments?.length > 0) && (
        <>
          <h3>Attachments</h3>
          <Box sx={sharedClasses.emailAttachmentsList}>
            {templateAttachments &&
              templateAttachments.map((templateAttachment, index) => (
                <Box
                  key={templateAttachment.id}
                  sx={{
                    ...sharedClasses.emailAttachmentContainer
                  }}
                >
                  <Box sx={sharedClasses.emailAttachmentIcon}>
                    {DocTypeIcon(templateAttachment.attached_file_name.split('.').pop())}
                  </Box>
                  <Box>
                    <Box sx={sharedClasses.emailAttachmentName}>
                      {templateAttachment.attached_file_name}
                    </Box>
                    <Box sx={sharedClasses.fileSize}>
                      {humanFileSize(templateAttachment.attached_file_size)}
                    </Box>
                  </Box>
                </Box>
              ))}
            {attachments.map((attachment, index) => (
              <Box
                key={attachment.path}
                sx={{
                  ...sharedClasses.emailAttachmentContainer,
                  ...(attachmentHovered === index && { cursor: 'pointer' })
                }}
                onMouseEnter={() => setAttachmentHovered(index)}
                onMouseLeave={() => setAttachmentHovered(-1)}
                onClick={() => removeAttachment(index)}
              >
                <Box sx={sharedClasses.emailAttachmentIcon}>
                  {attachmentHovered === index ? (
                    <ClearIcon sx={{ '& svg': { color: '#D6827D !important' } }} />
                  ) : (
                    DocTypeIcon(attachment.name.split('.').pop())
                  )}
                </Box>
                <Box>
                  <Box sx={sharedClasses.emailAttachmentName}>
                    {attachmentHovered === index ? 'Click to remove attachment' : attachment.name}
                  </Box>
                  <Box sx={sharedClasses.fileSize}>{humanFileSize(attachment.size)}</Box>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default AttachmentSelectorAndPreview;
