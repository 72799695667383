import React, { Dispatch } from 'react';
import { Stack, Avatar, Chip, Tooltip, CircularProgress } from '@mui/material';
import {
  Alarm as AlarmIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  CheckCircle as CheckCircleIcon,
  Task as TaskIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { styles } from '../../../Candidate/styles';
import { ApplicationAction, IApplicationTask } from '../../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IApplication } from '../../types';
import Api from '../../API';
import { ModalType } from '../../config';

export default function Task({
  task,
  dispatch
}: {
  task: IApplicationTask;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const tasks = queryClient.getQueryData<IApplicationTask[]>(['tasks']);

  const { mutate: completeTask, isLoading: completingTask } = useMutation({
    mutationFn: async () => {
      if (application) {
        const { res } = await Api.updateApplicationTask(
          application.job.id,
          application.id,
          task.id,
          {
            completed_at: task.completed_at ? null : dayjs().toISOString()
          }
        );
        return res;
      }
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Task has been successfully updated`, state: 'success' }
      });
      if (tasks) {
        const newTasks = [...tasks];
        const index = tasks.findIndex((t) => t.id === task?.id);
        newTasks[index] = res;
        queryClient.setQueryData(['tasks'], newTasks);
      }
    },
    onError: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `There was an error updating application task`, state: 'error' }
      });
    }
  });
  return (
    <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Stack sx={{ flexDirection: 'row', columnGap: 2, alignItems: 'center' }}>
        <Tooltip
          placement="top"
          title={`${task.priority === 'Normal' ? 'Medium' : task.priority} priority`}
          arrow
        >
          <Avatar variant="rounded" sx={{ ...styles[task.priority], borderRadius: '6px' }}>
            <TaskIcon sx={{ fontSize: '1.1rem' }} />
          </Avatar>
        </Tooltip>
        <Stack
          id="task-subject-link"
          sx={{ fontSize: '16px', fontWeight: 'bold', cursor: 'pointer', color: '#333333' }}
          onClick={() => {
            dispatch({ type: 'SET_EDITED_TASK', payload: task });
            dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ADD_EDIT_TASK });
          }}
        >
          {task.subject}
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: 'row', columnGap: 2, alignItems: 'center' }}>
        <Chip
          label={`Assigned to: ${task.assigned_user}`}
          sx={{ ...styles.candidateTaskChip, color: '#084D6D', background: '#EDF2F5' }}
        />
        <Chip
          icon={<AlarmIcon sx={{ fontSize: '16px' }} />}
          label={dayjs(task.due_at).format('ddd, D MMM YYYY, LT')}
          sx={{ ...styles.candidateTaskChip, color: '#666666', minWidth: '210px' }}
        />
        {completingTask ? (
          <CircularProgress size={24} sx={{ color: '#5BC4BF' }} />
        ) : (
          <Stack
            id="application-complete-task-button"
            onClick={() => completeTask()}
            sx={{ cursor: 'pointer' }}
          >
            {task.completed_at ? (
              <CheckCircleIcon sx={{ color: '#5BC4C0' }} />
            ) : (
              <CheckCircleOutlineIcon sx={{ color: '#DDDDDD' }} />
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
