import React, { Dispatch, SetStateAction, useState, useRef, useEffect, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { Box, Autocomplete, TextField, Modal, Button, CircularProgress } from '@mui/material';
import { classes } from './styles';
import { INewApprovalOptions } from '../types';
import Api from '../API';

export default function NewApprovalModal({
  apiKey,
  newApprovalOptions,
  setNewApprovalOptions,
  isDialogOpen,
  setIsDialogOpen,
  setSnackbar,
  templateArchived,
  setTemplateArchived
}: {
  apiKey: string;
  newApprovalOptions: INewApprovalOptions;
  setNewApprovalOptions: (approvals: INewApprovalOptions) => void;
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbar: Dispatch<
    SetStateAction<{ message: string; state: 'success' | 'warning' | 'error' }>
  >;
  templateArchived: boolean;
  setTemplateArchived: Dispatch<SetStateAction<boolean>>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [approvalTemplate, setApprovalTemplate] = useState(null);
  const [isNavigating, setIsNavigating] = useState(false);

  const fieldRefs = {
    approvalTemplate: useRef<HTMLInputElement>(null)
  };

  const fetchApprovalTemplates = useCallback(async () => {
    setIsLoading(true);
    try {
      const { res } = await Api.getApprovalTemplates(
        { 'X-api-authenticate': apiKey },
        {
          archived: false,
          override_limit: true,
          limit: 500,
          sort: 'name',
          sort_order: 'ASC'
        }
      );
      setNewApprovalOptions(res?.requisition_forms.filter((form) => form.name.trim() !== ''));
    } catch (error) {
      setSnackbar({
        message: `There was an error getting approval templates, ${error.errors}`,
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiKey]);

  const navigateToNewApproval = () => {
    if (approvalTemplate === null) return;
    window.location.href = `/admin/requisitions/new?form_id=${approvalTemplate.id}`;
    setIsNavigating(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    setApprovalTemplate(null);
  };

  useEffect(() => {
    newApprovalOptions?.length === 0 && !isLoading && fetchApprovalTemplates();
  }, [fetchApprovalTemplates]);

  useEffect(() => {
    if (templateArchived) {
      fetchApprovalTemplates();
      setTemplateArchived(false);
    }
  }, [templateArchived, setTemplateArchived, fetchApprovalTemplates]);

  return (
    <Box>
      <Modal open={isDialogOpen} onClose={handleClose} aria-labelledby="new-approval-modal">
        <Box sx={classes.actionsModal}>
          <Box sx={{ padding: '12px 0px 30px 0px' }}>
            <Box id="new-approval-modal-title" sx={classes.modalHeader}>
              New approval form
            </Box>
          </Box>
          <Box sx={{ marginTop: '1.75rem' }}>
            <Box sx={classes.modalFormLine}>
              <Box sx={{ position: 'relative' }}>
                <Autocomplete
                  disablePortal
                  options={newApprovalOptions}
                  ref={fieldRefs.approvalTemplate}
                  getOptionLabel={(option) => option?.name}
                  id="state-region-province"
                  loading={newApprovalOptions?.length < 1}
                  loadingText={
                    isLoading ? 'Loading approval templates...' : 'No approval templates'
                  }
                  value={approvalTemplate}
                  onChange={(event, value) => setApprovalTemplate(value)}
                  sx={{ width: '290px', ...sharedClasses.formAutocomplete }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select an approval template"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  renderOption={(
                    props: React.HTMLAttributes<HTMLLIElement>,
                    option: INewApprovalOptions
                  ) => (
                    <Box {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
            <Box sx={classes.modalActions}>
              <Button
                disableElevation
                sx={{ ...classes.buttonRedLight }}
                variant="text"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                id="submit-new-approval-button"
                type="submit"
                sx={classes.modalEditButton}
                disabled={approvalTemplate === null}
                onClick={navigateToNewApproval}
              >
                {isNavigating ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
