import React, { useReducer } from 'react';
import { InitialCandidatesState, CandidatesReducer } from './reducer';
import { Stack } from '@mui/material';
import { useQuery, useMutation } from '@tanstack/react-query';
import Api from './API';
import { styles } from './styles';
import Search from '../SmartForms/Search';
import GenericTable from '../Components/GenericTable/GenericTable';
import { headerStyle, candidatesActionsListItem } from './config';
import CandidatesTableRows from './CandidatesTableRows';
import StyledSnackbar, { ISnackbarInput } from '../Components/CustomUIElements/StyledSnackbar';
import EditCandidate from './EditCandidate';
import DeleteCandidate from './DeleteCandidate';
import { formatUserPermissions } from '../Components/Utilities/formatUserPermissions';
import { getPermissions } from '../../shared/permissionHelpers';

export default function Candidates({ isAdminUser }: { isAdminUser: boolean }) {
  const [CandidatesState, dispatch] = useReducer(CandidatesReducer, InitialCandidatesState);

  const { data: permissions } = useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const res = await getPermissions();
      return formatUserPermissions(res);
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting user permissions, ${error}`,
          state: 'error'
        }
      });
    }
  });

  const { isFetched: fetchedPreferences } = useQuery({
    queryKey: ['preferences'],
    queryFn: () => Api.getPreferences(),
    onSuccess: (pref) => {
      dispatch({
        type: 'SET_LIMIT',
        payload: pref.res.ui_preferences[0]?.row_count || 10
      });
      dispatch({
        type: 'SET_SORT',
        payload: {
          sortBy: pref.res.ui_preferences[0]?.sorting?.sortBy || 'lastname',
          sortOrder: pref.res.ui_preferences[0]?.sorting?.sortOrder?.toLowerCase() || 'asc'
        }
      });
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting user preference data, ${error}`,
          state: 'error'
        }
      })
  });

  useQuery({
    queryKey: ['table density'],
    queryFn: () => Api.getDensityPreferences(),
    onSuccess: (res) => {
      dispatch({ type: 'SET_DENSITY', payload: res.res.ui_preferences[0]?.columns?.density });
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting table density preference, ${error}`,
          state: 'error'
        }
      })
  });

  const handleUserPreferenceChange = useMutation({
    mutationFn: () =>
      Api.postUserPreferences({
        limit: CandidatesState.candidatesTableSettings.limit,
        sort: CandidatesState.candidatesTableSettings.sort,
        sortOrder: CandidatesState.candidatesTableSettings.sort_order
      }),
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error updating user preferences, ${error}`,
          state: 'error'
        }
      })
  });

  const { data: candidates, isLoading: loadingCandidates } = useQuery({
    queryKey: ['candidates', CandidatesState.candidatesTableSettings, CandidatesState.searchQuery],
    queryFn: async () => {
      const res = await Api.getCandidates({
        page: CandidatesState.candidatesTableSettings.page,
        limit: CandidatesState.candidatesTableSettings.limit,
        sort: CandidatesState.candidatesTableSettings.sort,
        sort_order: CandidatesState.candidatesTableSettings.sort_order,
        'q[search]': CandidatesState.searchQuery.replace('+', '%2B')
      });
      return res;
    },
    onSuccess: () => {
      handleUserPreferenceChange.mutate();
      sessionStorage.setItem(
        `candidatesTablePage`,
        CandidatesState.candidatesTableSettings.page.toString()
      );
    },
    enabled: fetchedPreferences
  });

  const editedCandidate = CandidatesState.candidatesTableState.actions.selectedItem;
  const openEditModal = CandidatesState.candidatesTableState.actions.isOpen.edit;
  const filteredTableActionList = () => {
    if (isAdminUser) {
      return candidatesActionsListItem;
    } else if (permissions?.Candidates['Edit Candidates']) {
      return candidatesActionsListItem.filter((item) => item.id !== 'delete');
    } else {
      return undefined;
    }
  };

  return (
    <Stack sx={styles.candidatesPageContainer}>
      <Stack sx={styles.candidatesPageHeader}>Candidates</Stack>
      <Search
        idLabel="candidates"
        placeholder="Search by name or email"
        setSearch={(query) => dispatch({ type: 'SET_SEARCH_QUERY', payload: query })}
      />
      <GenericTable
        label="candidates list"
        items={candidates?.res?.candidates || []}
        isLoadingItems={loadingCandidates}
        tableState={CandidatesState.candidatesTableState}
        dispatch={dispatch}
        headerStyle={headerStyle}
        paginationState={CandidatesState.candidatesTableSettings}
        pagination={{
          totalItems: Number(candidates?.resHead['x-total-count']) || 0,
          totalPages: Number(candidates?.resHead['x-total-pages']) || 0
        }}
        TableRows={CandidatesTableRows}
        TableActionList={filteredTableActionList()}
        allowMultipleSelection={false}
        titleCellId="lastname"
      />
      <StyledSnackbar
        message={CandidatesState.snackbar.message}
        state={CandidatesState.snackbar.state}
        setSnackbarState={(snackbarInput: ISnackbarInput) =>
          dispatch({ type: 'SET_SNACKBAR', payload: snackbarInput })
        }
      />
      {CandidatesState.candidatesTableState.actions.selectedItem?.id && (
        <DeleteCandidate
          isDialogOpen={CandidatesState.candidatesTableState.actions.isOpen.delete}
          candidateId={CandidatesState.candidatesTableState.actions.selectedItem?.id}
          CandidatesDispatch={dispatch}
        />
      )}
      {editedCandidate && openEditModal && (
        <EditCandidate
          candidates={candidates}
          CandidatesState={CandidatesState}
          editedCandidate={editedCandidate}
          CandidatesDispatch={dispatch}
        />
      )}
    </Stack>
  );
}
