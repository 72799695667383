import React from 'react';
import PropTypes from 'prop-types';

export default class ScoutTablePagination extends React.Component {
  static propTypes = {
    pagination: PropTypes.obj,
    onClick: PropTypes.func
  }

  constructor(props) {
    super(props)
    const width = 7;

    // {
    //   total_pages: 10,
    //   current_page: 1,
    // }
    this.renderControl = this.renderControl.bind(this)
    this.renderPageControl = this.renderPageControl.bind(this)
    this.renderActiveLink = this.renderActiveLink.bind(this)
    this.renderForwardControl = this.renderForwardControl.bind(this)
    this.renderBackwardControl = this.renderBackwardControl.bind(this)

  }

  renderActiveLink(key, index, label, classNames = '') {
    return(<a key={ key } className={ classNames } href={ `#page=${index}` } onClick={ () => { this.props.onClick(index) }}>{ label }</a>);
  }

  renderControl(links) {
    return (
      <span>
        {links}
      </span>
    )
  }

  renderPageControl() {
    const { pagination } = this.props;
    const { total_pages, current_page } = pagination;
    const width = 6;
    const middle = 3;

    var start = current_page - middle
    var end = start + width
    if( start <= 0) {
      start = 1;
      if (width > total_pages) {
        end = total_pages;
      } else {
        end = start + width;
      }
    } else if(end > total_pages) {
      if (width > total_pages) {
        start = 1;
      } else {
        start = total_pages - width;
      }
      end = total_pages;
    }

    var output = []
    for (var i = start; i <= end; i++) {
      if(current_page === i) {
        output.push(<em key={ i } className='current'>{i}</em>)
      } else {
        output.push(this.renderActiveLink(i, i, i, 'next_page'))
      }
    }
    if(total_pages > end) {
      output.push('...')
      output.push(this.renderActiveLink(end + 1,
                                        total_pages,
                                        total_pages,
                                        'next_page'))
    }
    return this.renderControl(output)
  }

  renderBackwardControl() {
    const { pagination } = this.props;
    const { total_pages, current_page } = pagination;

    if (current_page > 1) {
      return this.renderControl([
                 this.renderActiveLink(1, 1, 'First', 'next_page'),
                 this.renderActiveLink(2, current_page - 1, '← Previous', 'next_page')
               ])
    } else {
      return this.renderControl([
                 (<span key={1} className='next_page disabled'>First</span>),
                 (<span key={2} className='previous_page disabled'>← Previous</span>)
               ])
    }
  }

  renderForwardControl() {
    const { pagination } = this.props;
    const { total_pages, current_page } = pagination;
    if (current_page < total_pages) {
      return this.renderControl([
                 this.renderActiveLink(1, current_page + 1, 'Next →', 'next_page'),
                 this.renderActiveLink(2, total_pages, 'Last',   'next_page')
               ])
    } else {
      return this.renderControl([
                 (<span key={1} className='next_page disabled'>Next →</span>),
                 (<span key={2} className='next_page disabled'>Last</span>)
               ])
    }
  }

  render() {
    const { pagination } = this.props;
    const { total_pages, total_records } = pagination;
    return (
      <div className='pagination' >
        <span style={{float: 'left'}}> Total Records: {total_records} </span>
        { total_pages != 1 && this.renderBackwardControl() }
        { total_pages != 1 && this.renderPageControl() }
        { total_pages != 1 && this.renderForwardControl() }
      </div>
    );
  }
};
