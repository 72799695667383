"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { containsDuplicates } from "../../ApprovalForms/NewApproval/NewApproval";
export function validateForm(questions, answers, setQuestionErrors, questionsRef) {
  var _a;
  if (!questionsRef.current) return;
  let isValid = true;
  let questionError = void 0;
  questions.forEach((question, index) => {
    let tempErrorMessage = "";
    if (question.type === "CheckBoxField") {
      const isChecked = answers[question.id] && Object.values(answers[question.id]).some((value) => value === true);
      if (question.required && !isChecked) {
        tempErrorMessage = "Must select at least one option";
        if (questionError === void 0) {
          questionError = index;
        }
        isValid = false;
      }
    } else if (question.type === "RankField") {
      const duplicates = answers[question.id] && containsDuplicates(Object.values(answers[question.id]));
      if (duplicates) {
        tempErrorMessage = "Ranked choices must be unique";
        if (questionError === void 0) {
          questionError = index;
        }
        isValid = false;
      }
    } else if (question.required && !answers[question.id]) {
      tempErrorMessage = question.type !== "RadioButtonField" ? "This field is required" : "Must select an option";
      if (questionError === void 0) {
        questionError = index;
      }
      isValid = false;
    }
    setQuestionErrors((prev) => __spreadProps(__spreadValues({}, prev), {
      [question.id]: tempErrorMessage
    }));
  });
  if (questionError !== void 0 && questionsRef.current && questionsRef.current[questionError]) {
    (_a = questionsRef.current[questionError]) == null ? void 0 : _a.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  return isValid;
}
