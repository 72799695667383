import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Chip,
  IconButton,
  useTheme,
  CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from './useStyles';
import { withScoutTheme } from '../../../../../ThemeContext/ThemeContext';
import Api from '../../../../API';
import ReactLoading from 'react-loading';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import WorkIcon from '@material-ui/icons/Work';
import { IStatus, ICandidate, IJobPosition } from '../../../../types';

interface IProps {
  apiKey: string;
  defaultJob: boolean;
  jobPositions: IJobPosition[];
  setJobPositions: (jobs: IJobPosition[]) => void;
  selectedJobPosition: IJobPosition;
  setSelectedJobPosition: (job: IJobPosition) => void;
  statuses: IStatus[];
  setStatuses: (statuses: IStatus[]) => void;
  selectedStatuses: IStatus[];
  setSelectedStatuses: (statuses: IStatus[] | ((statuses: IStatus[]) => IStatus[])) => void;
  candidates: ICandidate[];
  setCandidates: (candidates: ICandidate[]) => void;
  selectedCandidates: ICandidate[];
  candidateList: ICandidate[];
  setSelectedCandidates: (candidate: ICandidate[]) => void;
}

function Step1({
  apiKey,
  defaultJob,
  jobPositions,
  setJobPositions,
  selectedJobPosition,
  setSelectedJobPosition,
  statuses,
  setStatuses,
  selectedStatuses,
  setSelectedStatuses,
  candidates,
  candidateList,
  setCandidates,
  selectedCandidates,
  setSelectedCandidates
}: IProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [statusIsLoading, setStatusIsLoading] = useState(false);
  const initialRender = useRef(true);

  const classes = useStyles();
  const theme = useTheme();
  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const { job_positions } = await Api.getEventOptions();
        setJobPositions(job_positions);
      } catch (e) {
        throw new Error(e);
      } finally {
        setIsLoading(false);
      }
    }
    if (!selectedJobPosition) getData();
    else setIsLoading(false);
  }, [selectedJobPosition, setJobPositions]);

  async function refreshApplications() {
    setStatuses([]);
    setCandidates([]);
    setSelectedCandidates([]);
    setSelectedStatuses([]);
    const applications: ICandidate[] = await Api.getJobApplications(
      selectedJobPosition.id,
      apiKey
    ).then((data) => replaceNullStatuses(data.applications));
    const statuses = applications.map((application) => application.status);
    const uniqueStatuses = statuses
      .filter(
        (status: IStatus, index: number) =>
          statuses.findIndex((inner) => inner.id === status.id) === index
      )
      .sort((a, b) => a.position - b.position);
    const candidateList = replaceNullStatuses(applications);
    const selectedList = candidateList.filter((candidate) =>
      selectedCandidates.some((selected) => candidate.id == selected?.id)
    );
    setCandidates(candidateList);
    setSelectedCandidates(selectedList);
    setStatuses(uniqueStatuses);
  }

  useEffect(() => {
    setStatusIsLoading(true);
    if (initialRender.current) {
      initialRender.current = false;
      if (selectedJobPosition && (!candidates.length || !statuses.length)) {
        refreshApplications().then(() => setStatusIsLoading(false));
      }
    } else {
      if (selectedJobPosition) {
        refreshApplications().then(() => setStatusIsLoading(false));
      }
    }
  }, [selectedJobPosition]);

  useEffect(() => {
    setSelectedStatuses((selectedStatuses: IStatus[]) =>
      selectedStatuses.filter((status) =>
        selectedCandidates.map((app) => app.status.id).includes(status.id)
      )
    );
  }, [selectedCandidates, setSelectedStatuses]);

  function handleStatusRemove(id: number) {
    setSelectedStatuses(selectedStatuses.filter((selected) => selected.id !== id));
    setSelectedCandidates(selectedCandidates.filter((candidate) => candidate.status.id !== id));
  }

  function handleStatusAdd(status: IStatus) {
    const newApplicants = candidates
      .filter((candidate) => candidate.status.id === status.id)
      .filter(
        (candidate) => !selectedCandidates.map((selected) => selected.id).includes(candidate.id)
      );
    setSelectedStatuses([...selectedStatuses, status]);
    setSelectedCandidates([...selectedCandidates, ...newApplicants]);
  }

  function loaderOrEmpty(loading: boolean) {
    if (loading) {
      return (
        <div className={classes.loading}>
          <CircularProgress size={20} color="inherit" />
        </div>
      );
    } else {
      return <div className={classes.noData}>No Candidates Found</div>;
    }
  }

  function replaceNullStatuses(applications: ICandidate[]): ICandidate[] {
    return applications.map((application) =>
      application.status
        ? application
        : {
            ...application,
            status: {
              id: 1,
              name: 'New',
              position: 1
            }
          }
    );
  }

  return !isLoading ? (
    <>
      <h4>Select candidates</h4>
      {
        <div className={classes.searchContainer}>
          <Autocomplete
            classes={{
              root: classes.autocompleteField,
              inputRoot: classes.autoCompleteInput,
              endAdornment: classes.endAdornment
            }}
            style={{ marginBottom: 14 }}
            options={jobPositions}
            getOptionLabel={(option) => `${option.reference} - ${option.title}`}
            value={selectedJobPosition}
            filterSelectedOptions={true}
            disableClearable={true}
            onChange={(_, newValue: IJobPosition) => setSelectedJobPosition(newValue)}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Job"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <WorkIcon />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </div>
      }
      <div className={classes.searchContainer}>
        <Autocomplete
          id="candidate-autocomplete"
          classes={{
            root: classes.autocompleteField,
            inputRoot: classes.autoCompleteInput,
            endAdornment: classes.endAdornment
          }}
          disabled={!selectedJobPosition}
          multiple
          options={candidates}
          noOptionsText={
            statusIsLoading ? (
              <div className={classes.loading}>
                <CircularProgress size={20} color="inherit" />
              </div>
            ) : (
              'No Candidates Found for Selected Job'
            )
          }
          getOptionLabel={(option) => option.name}
          value={selectedCandidates}
          filterSelectedOptions={true}
          onChange={(_, newValue: ICandidate[]) => setSelectedCandidates(newValue)}
          renderTags={() => null}
          disableClearable={true}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search Candidates"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                )
              }}
            />
          )}
        />
        <Select
          id="candidate-filter"
          disabled={!selectedJobPosition || !candidates.length}
          className={classes.inputBase}
          displayEmpty={true}
          renderValue={() => 'Filter candidates'}
          variant="outlined"
          value=""
          MenuProps={{
            classes: { paper: classes.dialogPaper },
            PaperProps: {
              id: 'candidate-filter-list'
            }
          }}
        >
          {!statuses.length ? (
            loaderOrEmpty(statusIsLoading)
          ) : statuses.length === selectedStatuses?.length ? (
            <MenuItem value="" disabled>
              No Remaining Statuses
            </MenuItem>
          ) : (
            statuses
              .filter((status) =>
                candidates.map((candidate) => candidate.status.id).includes(status.id)
              )
              .filter(
                (status) => !selectedStatuses.map((selected) => selected.id).includes(status.id)
              )
              .map((status) => (
                <MenuItem key={status.id} onClick={() => handleStatusAdd(status)}>
                  {status.name}
                </MenuItem>
              ))
          )}
        </Select>
      </div>
      {!!selectedStatuses.length && (
        <div className={classes.chipContainer} id="candidate-filter-chips">
          {selectedStatuses.map((status) => (
            <Chip
              key={status.id}
              className={classes.chipItem}
              label={status.name}
              onDelete={() => handleStatusRemove(status.id)}
              variant="outlined"
            />
          ))}
        </div>
      )}
      <div
        className={`${classes.table} ${selectedCandidates.length > 6 ? classes.shadow : ''}`}
        id="candidate-list"
      >
        <div className={classes.row}>
          <div className={`${classes.tableHeader} ${classes.tableLg}`}>Name</div>
          <div className={`${classes.tableHeader} ${classes.tableLg}`}>Email</div>
          <div className={`${classes.tableHeader} ${classes.tableMd}`}>Status</div>
          <div />
        </div>
        {!selectedCandidates.length ? (
          <div className={classes.row}>
            <span className={classes.noApplicants}>No applicants selected</span>
          </div>
        ) : (
          selectedCandidates?.map((candidate) => (
            <div key={candidate?.id} className={classes.row}>
              <div className={classes.tableLg}>{candidate?.name}</div>
              <div className={classes.tableLg}>{candidate?.email}</div>
              <div className={classes.tableMd}>{candidate?.status && candidate?.status?.name}</div>
              <IconButton
                onClick={() =>
                  setSelectedCandidates(
                    selectedCandidates.filter((selected) => selected.id !== candidate?.id)
                  )
                }
              >
                <HighlightOffIcon color="error" />
              </IconButton>
            </div>
          ))
        )}
      </div>
    </>
  ) : (
    <div className={classes.loading}>
      <ReactLoading type="spin" color={theme.palette.primary.main} width={45} height={45} />
    </div>
  );
}

export default withScoutTheme(Step1);
