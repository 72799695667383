import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Checkbox, CircularProgress, IconButton } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CodeIcon from '@mui/icons-material/Code';
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { classes } from './styles';
import { arrayQuestion } from './Utility/questionStringToArray';

const SelectableCopyableQuestions = ({
  questions,
  setQuestions,
  maxHeight,
  deleteQuestion
}: {
  questions: arrayQuestion[];
  setQuestions: Dispatch<SetStateAction<arrayQuestion[]>>;
  maxHeight?: string;
  deleteQuestion?: (index: number) => void;
}) => {
  const handleCopyQuestion = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index].copied = true;
    setQuestions(newQuestions);
    setTimeout(() => {
      const newQuestions = [...questions];
      newQuestions[index].copied = false;
      setQuestions(newQuestions);
    }, 3000);
  };

  const handleSelectQuestion = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index].selected = !newQuestions[index].selected;
    setQuestions(newQuestions);
  };

  const renderQuestionStatus = (item: arrayQuestion, index: number) => {
    switch (item.status) {
      case 'loading':
        return <CircularProgress size={18} color="inherit" />;
      case 'error':
        return <WarningIcon color="inherit" />;
      case 'sent':
        return <CheckCircleIcon sx={{ color: '#8CCF95' }} />;
      default:
        return (
          <Checkbox
            sx={classes.checkbox}
            checked={item.selected}
            onChange={() => handleSelectQuestion(index)}
          />
        );
    }
  };

  return (
    <Box sx={{ ...classes.questionsContainer, ...(maxHeight && { maxHeight }) }}>
      {questions &&
        questions.map((item, index) => (
          <Box sx={{ ...classes.question, justifyContent: 'space-around' }} key={item.id}>
            <Box sx={classes.checkboxContainer}>{renderQuestionStatus(item, index)}</Box>
            <Box sx={{ maxWidth: '700px' }}>{item.text}</Box>
            {deleteQuestion ? (
              <Box>
                <CopyToClipboard text={item.text} onCopy={() => handleCopyQuestion(index)}>
                  <IconButton sx={{ color: '#2D388D' }}>
                    {item.copied ? <LibraryAddCheckIcon /> : <ContentCopyIcon />}
                  </IconButton>
                </CopyToClipboard>
                <IconButton sx={{ color: '#D6827D' }} onClick={() => deleteQuestion(index)}>
                  {item.status === 'deleting' ? <CircularProgress size={24} /> : <DeleteIcon />}
                </IconButton>
              </Box>
            ) : (
              <CopyToClipboard text={item.text} onCopy={() => handleCopyQuestion(index)}>
                <Button
                  className="copy-question-button"
                  sx={classes.copyButton}
                  endIcon={<CodeIcon />}
                >
                  {item.copied ? 'Copied!' : 'Copy'}
                </Button>
              </CopyToClipboard>
            )}
          </Box>
        ))}
    </Box>
  );
};

export default SelectableCopyableQuestions;
