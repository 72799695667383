"use strict";
import { theme } from "../../ThemeContext/ThemeObject";
import { red, grey } from "@mui/material/colors";
export const classes = {
  jobPageContainer: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "#FFFFFF",
    padding: "40px"
  },
  jobArchivedContainer: {
    display: "flex",
    padding: "0px 16px",
    marginBottom: 1
  },
  jobArchiveAlert: {
    color: red[300],
    backgroundColor: red[50],
    padding: "0 16px",
    fontWeight: "bold",
    borderRadius: "6px"
  },
  jobUnarchiveButton: {
    color: grey[700],
    fontWeight: "bold",
    textDecoration: "underline",
    marginLeft: 1
  },
  jobPageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px",
    "& h1": {
      fontWeight: "bold",
      fontSize: "38px",
      lineHeight: "1.2em",
      color: "#666666",
      marginTop: "0",
      "@media (max-width: 1450px)": {
        fontSize: "34px"
      },
      "@media (max-width: 1260px)": {
        fontSize: "28px"
      }
    }
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "10px",
    marginLeft: "20px"
  },
  actionsMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "11px",
      minWidth: "153px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#939393",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  iconGrey: {
    color: "#666666",
    fontSize: "28px"
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "20px",
    padding: "6px 16px",
    width: "100%",
    "& a:hover": {
      textDecoration: "none"
    }
  },
  link: {
    fontSize: "19px",
    color: "#939393",
    cursor: "pointer",
    transition: "color 0.2s ease-in-out",
    "&:hover": {
      color: "#737373"
    },
    "@media (max-width: 1450px)": {
      fontSize: "16px"
    },
    "@media (max-width: 1260px)": {
      fontSize: "14px"
    }
  },
  copyButton: {
    fontSize: "12px",
    fontWeight: "bold",
    backgroundColor: "#5BC4BF1F",
    color: theme.palette.action.main,
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.action.main,
      color: "#FFFFFF"
    }
  },
  stateStatusContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "20px",
    padding: "8px 16px"
  },
  stateDropdown: {
    fontSize: "13px",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "140px",
    height: "35px",
    borderRadius: "6px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  Published: {
    color: "#8CCF95",
    backgroundColor: "RGBA(140, 207, 149, 0.12)"
  },
  Draft: {
    color: "#E6A052",
    backgroundColor: "RGBA(230, 160, 82, 0.12)"
  },
  Unpublished: {
    color: "#D6827D",
    backgroundColor: "RGBA(214, 130, 125, 0.12)"
  },
  Saved: {
    color: "#FFFFFF",
    backgroundColor: "#CCCCCC"
  },
  Closed: {
    color: "#FFFFFF",
    backgroundColor: "#CCCCCC"
  },
  stateOptions: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "11px",
      minWidth: "140px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "13px",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#939393",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  downIcon: {
    marginLeft: "8px",
    fontSize: "18px",
    color: "inherit"
  },
  statusDropdown: {
    position: "relative",
    fontSize: "13px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E5E5E5",
    padding: "8px 20px 8px 12px",
    width: "140px",
    height: "35px",
    borderRadius: "6px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "&:hover": {
      border: "1px solid #D5D5D5",
      textOverflow: "unset",
      overflow: "unset",
      width: "auto",
      minWidth: "140px"
    }
  },
  statusDownIcon: {
    position: "absolute",
    right: "3px",
    fontSize: "18px",
    color: "#E5E5E5"
  },
  statusDropdownItem: {
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F1F1F1"
    }
  },
  statusDropdownItems: {
    "& .MuiPaper-root": {
      width: "200px",
      maxHeight: "250px",
      overflowY: "auto",
      borderRadius: "6px",
      marginTop: "6px"
    }
  },
  statusLoader: {
    position: "absolute",
    left: "calc(50% - 9px)"
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "#EEEEEE",
    margin: "40px 0px"
  },
  tabs: {
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      letterSpacing: "0",
      color: "#939393",
      textTransform: "none",
      minWidth: "unset",
      "&:hover": {
        color: "#737373"
      },
      "&:focus": {
        outline: "none"
      }
    },
    "& button.Mui-selected": {
      fontWeight: "600",
      color: "#084D6D"
    }
  },
  tabIndicator: {
    backgroundColor: "#5BC4BF",
    margin: "0 16px"
  },
  tabContent: {
    padding: "0px 16px"
  },
  applicationsTabButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "12px",
    backgroundColor: "#EDF2F5",
    borderRadius: "18px",
    padding: "0px 10px",
    minWidth: "48px",
    height: "24px"
  },
  widgetContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "50px",
    rowGap: "50px"
  },
  iframeContainer: {
    position: "relative",
    width: "100%",
    height: "600px",
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    marginTop: "50px"
  },
  multiposterContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "40px",
    padding: "24px 16px",
    marginTop: "22px"
  },
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute"
  },
  toggleButtonGroup: {
    borderRadius: "11px",
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#F3F3F3",
      "&:hover": {
        backgroundColor: "#F3F3F3"
      }
    }
  },
  toggleButton: {
    padding: "none !important",
    borderRadius: "11px",
    width: "125px",
    height: "47px",
    borderColor: "#F3F3F3"
  },
  logoSize: {
    width: "72px !important",
    height: "40px !important"
  },
  skeleton: {
    width: "100%",
    height: "140px",
    borderRadius: "6px"
  }
};
