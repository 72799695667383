import React, { Dispatch, ReactNode, useRef, useState } from 'react';
import { Box, Popper, IconButton, Badge, Button, Tooltip, ClickAwayListener } from '@mui/material';
import { classes } from './styles';
import SortIcon from '@material-ui/icons/Sort';
import CancelIcon from '@mui/icons-material/Cancel';
import { TableFilter, TableFilterAction } from '../types';

export default function Filters({
  dispatch,
  numberOfFilters,
  label,
  children
}: {
  dispatch: Dispatch<TableFilterAction<TableFilter>>;
  numberOfFilters: number;
  label: string;
  children: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);

  const handleDropdownOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleApplyFilters = () => {
    dispatch({ type: 'SET_FILTERS' });
    setIsOpen(false);
  };

  const handleResetFilters = () => {
    dispatch({ type: 'RESET_FILTERS' });
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Box>
        <Badge
          badgeContent={numberOfFilters}
          sx={{ '& .MuiBadge-badge': { backgroundColor: '#E37D7A', color: '#FFF' } }}
        >
          <Tooltip title="Filter" placement="top" arrow>
            <IconButton
              id={`filter-dropdown-${label}-button`}
              data-testid={`filter-dropdown-${label}-button`}
              sx={
                isOpen ? { ...classes.iconButton, ...classes.iconButtonOpen } : classes.iconButton
              }
              onClick={handleDropdownOpen}
              ref={anchorEl}
            >
              <SortIcon />
            </IconButton>
          </Tooltip>
        </Badge>
        <Popper
          id="column-settings-popover"
          sx={classes.popover}
          open={isOpen}
          anchorEl={anchorEl.current}
          placement="bottom-end"
        >
          <Box sx={classes.filterPopoverContent}>
            <Box sx={classes.scrollableFilters}>
              <Box sx={classes.filterPopoverHeader}>
                <Button
                  id="reset-filters-button"
                  variant="contained"
                  endIcon={<CancelIcon />}
                  sx={classes.clearButton}
                  onClick={handleResetFilters}
                >
                  Clear all
                </Button>
                <Button
                  id="apply-filters-button"
                  variant="contained"
                  onClick={handleApplyFilters}
                  sx={classes.applyButton}
                >
                  Apply
                </Button>
              </Box>
              {children}
            </Box>
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
