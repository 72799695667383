import React from 'react';

const FileTickSVG = () => {
  return (
    <svg width="43" height="50" viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.52632 0H29.4211C30.6658 0 31.6842 1.01842 31.6842 2.26316C31.6842 3.50789 30.6658 4.52632 29.4211 4.52632H6.78947C5.54474 4.52632 4.52632 5.54474 4.52632 6.78947V33.9474C4.52632 35.1921 3.50789 36.2105 2.26316 36.2105C1.01842 36.2105 0 35.1921 0 33.9474V4.52632C0 2.03684 2.03684 0 4.52632 0ZM41.6874 21.3189L30.7563 10.3879C29.8963 9.52789 28.7421 9.05263 27.5426 9.05263H13.5789C11.0895 9.05263 9.07526 11.0895 9.07526 13.5789L9.05263 45.2632C9.05263 47.7526 11.0668 49.7895 13.5563 49.7895H38.4737C40.9632 49.7895 43 47.7526 43 45.2632V24.51C43 23.3105 42.5247 22.1563 41.6874 21.3189ZM16.0889 35.3849L21.2798 41.0084L35.9627 25.1019C36.5411 24.4753 37.4607 24.4753 38.0391 25.1019C38.6175 25.7285 38.6175 26.7247 38.0391 27.3513L22.318 44.3664C21.7396 44.9931 20.8052 44.9931 20.2268 44.3664L14.0125 37.6343C13.4341 37.0077 13.4341 36.0115 14.0125 35.3849C14.591 34.7583 15.5105 34.7583 16.0889 35.3849Z"
        fill="#5BC4C0"
      />
    </svg>
  );
};

export default FileTickSVG;
