import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Box } from '@mui/material';
import { classes } from './styles';
import ReactDOM from 'react-dom';
import { keyframes } from '@emotion/react';

const ResponseMessage = ({ index, message }: { index: string; message: string }) => {
  return (
    <Box
      key={index}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '20px'
      }}
    >
      <Box
        sx={classes.systemMessage}
        dangerouslySetInnerHTML={{ __html: message.replace(/\n\n/g, '<br />') }}
      ></Box>
    </Box>
  );
};

const blink = keyframes`
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
`;

const LoadingMessage = () => {
  return (
    <Box
      sx={{
        ...classes.systemMessage,
        display: 'flex',
        alignItems: 'baseline',
        fontSize: '18px !important',
        padding: '6px 24px !important'
      }}
    >
      Generating
      <Box
        sx={{
          animation: `${blink} 1s linear infinite`,
          marginLeft: '6px',
          fontSize: '30px'
        }}
      >
        .
      </Box>
      <Box
        sx={{
          animation: `${blink} 1s 0.2s linear infinite`,
          marginLeft: '4px',
          fontSize: '30px'
        }}
      >
        .
      </Box>
      <Box
        sx={{
          animation: `${blink} 1s 0.4s linear infinite`,
          marginLeft: '4px',
          fontSize: '30px'
        }}
      >
        .
      </Box>
    </Box>
  );
};

export default function AIInterviewModal(jobDescription: string) {
  const [inputValue, setInputValue] = useState<string>('');
  const [chatLog, setChatLog] = useState<{ role: string; content: string }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>('');
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const [response, setResponse] = useState<{ index: string; content: string }[]>([]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatLog]);

  const generateChatResponse = useCallback(
    async (index: string, question: string, input: string, reactComponentRoot?: any) => {
      setIsLoading(true);
      try {
        const response = await fetch('/api/v4/ai_interviews', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            description: jobDescription,
            question: question,
            input: input
          })
        }).then((res) => res.json());
        if (response && response.result) {
          setResponse((prevResponse) => [
            ...prevResponse,
            { index: index, content: response.result }
          ]);
          ReactDOM.render(
            <ResponseMessage index={index} message={response.result} />,
            reactComponentRoot
          );
        } else {
          throw new Error('No response from STG GPT');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [jobDescription]
  );

  useEffect(() => {
    const buttons = document.getElementsByClassName('felix');
    const handleClick = (event) => {
      const fieldContainer = event.target.closest('.field-container');
      const reactComponentRoot = fieldContainer.querySelector('#react-component-root');
      const button = event.target.closest('button');
      const inputField = button.previousElementSibling;
      const id = inputField.id;
      const match = id.match(/answers_attributes_(\d+)_/);
      const number = match ? match[1] : null;
      if (number !== null) {
        const dataInputField = document.getElementById(
          `application_answers_attributes_${number}_data`
        ) as HTMLInputElement;
        setInputValue(dataInputField?.value);

        const label = document.querySelector(
          `label[for="application_answers_attributes_${number}_data"]`
        );
        const labelText = label?.textContent?.replace('*', '') || '';
        setQuestion(labelText);

        ReactDOM.render(<LoadingMessage />, reactComponentRoot);

        generateChatResponse(button.id, labelText, dataInputField?.value, reactComponentRoot);
      }
    };

    Array.from(buttons).forEach((button) => {
      button.addEventListener('click', handleClick);
    });

    return () => {
      Array.from(buttons).forEach((button) => {
        button.removeEventListener('click', handleClick);
      });
    };
  }, [generateChatResponse, response, isLoading]);

  return <Box></Box>;
}
