import React from "react";
import useStyles from "./useStyles";
import PropTypes from "prop-types";
import moment from "moment";
function CandidateListItem({ conversation, selectedConversation, setSelectedConversation }) {
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `${classes.root} ${selectedConversation[0].id === conversation.id ? "selected" : ""}`,
      onClick: () => setSelectedConversation([conversation])
    },
    /* @__PURE__ */ React.createElement("div", { className: classes.details }, /* @__PURE__ */ React.createElement("div", { className: classes.name }, /* @__PURE__ */ React.createElement("h4", null, conversation.candidate_name)), /* @__PURE__ */ React.createElement("div", { className: classes.time }, conversation.messages.length > 0 && conversation.messages[0].sent_time && moment(conversation.messages[0].sent_time).fromNow())),
    /* @__PURE__ */ React.createElement("p", { className: classes.excerpt }, conversation.messages.length > 0 ? conversation.messages[0].body : "No messages")
  );
}
CandidateListItem.propTypes = {
  conversation: PropTypes.object.isRequired,
  selectedConversation: PropTypes.array.isRequired,
  setSelectedConversation: PropTypes.func.isRequired
};
export default CandidateListItem;
