import React, { Dispatch } from 'react';
import {
  Avatar,
  Button,
  // IconButton,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  Stack,
  Skeleton
  // Tooltip
} from '@mui/material';
// import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { SystemReportsState, SystemReportsAction } from './reducer';
import { SystemReportsList } from './config';
import { classes } from './styles';

export default function SystemReportsTable({
  state,
  dispatch,
  permissions
}: {
  state: SystemReportsState;
  dispatch: Dispatch<SystemReportsAction>;
  permissions: Record<string, Record<string, boolean>> | undefined;
}) {
  return (
    <Table sx={classes.tableStyling} aria-label="system reports" data-testid="system-report-table">
      {permissions ? (
        <>
          <TableHead>
            <TableRow>
              <TableCell sx={classes.systemReportsTableHeader}>
                <Stack>Title</Stack>
              </TableCell>
              <TableCell sx={classes.systemReportsTableHeader}>
                <Stack>Description</Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {SystemReportsList(permissions)
              .filter((r) => !!r.permission)
              .map((report, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ minWidth: '250px' }}>
                    <Stack sx={classes.reportTitleContainer}>
                      <Typography sx={classes.reportTitle}>{report.title}</Typography>
                      <Avatar sx={classes.avatarStyle} variant="square">
                        S
                      </Avatar>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ minWidth: '450px' }}>
                    <Stack>{report.description}</Stack>
                  </TableCell>
                  <TableCell>
                    <Stack sx={classes.actionsColumn}>
                      <Button
                        id="system-reports-generate-button"
                        variant="contained"
                        sx={classes.generateReportButton}
                        onClick={() => dispatch({ type: 'OPEN_PREVIEW', payload: report.title })}
                      >
                        Generate Report
                      </Button>
                      {/* <Tooltip title="Scheduled Reports" placement="top">
                  <IconButton id="system-reports-scheduled-button" sx={{ color: '#666666' }}>
                    <ScheduleSendIcon sx={{ fontSize: '18px' }} />
                  </IconButton>
                </Tooltip> */}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </>
      ) : (
        <>
          {[...Array(10)].map((_, index) => (
            <Stack key={index}>
              <Skeleton animation="wave" height={60} />
            </Stack>
          ))}
        </>
      )}
    </Table>
  );
}
