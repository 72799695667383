"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import queryString from "query-string";
const BASE_URL = window.location.origin;
class Api {
  constructor() {
    this.handleResponse = (response) => __async(this, null, function* () {
      if (response.ok) {
        const resHead = {};
        [...response.headers].forEach(
          (header) => resHead[header[0]] = header[1]
        );
        const res = yield response.json();
        return { res, resHead };
      }
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    });
    this.get = (path, params = {}, headers, noCache) => fetch(
      `${BASE_URL}${path}?${queryString.stringify(params, {
        arrayFormat: "bracket",
        encode: false
      })}`,
      __spreadValues({
        headers: this.getHeaders(headers)
      }, noCache && { cache: "no-store" })
    ).then((r) => this.handleResponse(r));
    this.post = (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "POST",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.put = (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "PUT",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.delete = (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "DELETE",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.getEntityApprovers = (apiKey) => this.get(`/api/v4/requisition_managers/entity_default_approvers`, {}, apiKey);
    this.getApprovalManagers = (params) => this.get(`/api/v4/requisition_managers`, params);
    this.createApprovalManager = (apiKey, params) => this.post(`/api/v4/requisition_managers`, params, apiKey);
    this.deleteApprovalManager = (apiKey, approvalManagerId) => this.delete(`/api/v4/requisition_managers/${approvalManagerId}`, {}, apiKey);
    this.deleteApprovalManagers = (apiKey, params) => this.delete(`/api/v4/requisition_managers/bulk_destroy`, params, apiKey);
    this.updateApprovalManager = (apiKey, params, approvalManagerId) => this.put(`/api/v4/requisition_managers/${approvalManagerId}`, params, apiKey);
    this.getEmailTemplates = () => this.get(`/api/v4/requisition_forms/email_templates`, {});
    this.getEnabledApprovalTemplates = (apiKey) => this.get("/api/v4/requisition_forms/enabled_for_applications", {}, apiKey);
    this.createApprovalTemplate = (params) => this.post(`/api/v4/requisition_forms`, params);
    this.updateApprovalTemplate = (approvalTemplateId, params) => this.put(`/api/v4/requisition_forms/${approvalTemplateId}`, params);
    this.cloneApprovalTemplate = (approvalTemplateId, params) => this.post(`/api/v4/requisition_forms/${approvalTemplateId}/clone`, params);
    this.getApprovalTemplate = (apiKey, approvalTemplateId) => this.get(`/api/v4/requisition_forms/${approvalTemplateId}`, {}, apiKey);
    this.getApprovalTemplates = (apiKey, params) => this.get(`/api/v4/requisition_forms`, params, apiKey);
    this.getUserPermissions = (apiKey) => this.get(`/api/v4/user_permissions`, {}, apiKey);
    this.getApprovalForms = (apiKey, params) => this.get(`/api/v4/requisitions`, params, apiKey);
    this.getApprovalForm = (apiKey, approvalFormId) => this.get(`/api/v4/requisitions/${approvalFormId}`, {}, apiKey);
    this.putApprovalForm = (apiKey, approvalFormId, params) => this.put(`/api/v4/requisitions/${approvalFormId}`, params, apiKey);
    this.postResendEmail = (approvalFormId, params) => this.post(`/api/v4/requisitions/${approvalFormId}/resend_email`, params);
    this.cloneApprovalForm = (apiKey, approvalFormId) => this.post(`/api/v4/requisitions/${approvalFormId}/clone`, {}, apiKey);
    this.generateBulkPdf = (apiKey, approvalFormId) => this.post(`/api/v4/requisitions/${approvalFormId}/bulk_pdf`, {}, apiKey);
    this.getTargetEntities = (apiKey) => this.get(`/api/v4/target_entities`, {}, apiKey);
    this.moveApprovalTemplate = (apiKey, approvalTemplateId, entityId) => this.post(
      `/api/v4/requisition_forms/${approvalTemplateId}/move?requisition_form[entity_id]=${entityId}`,
      {},
      apiKey
    );
    this.getCreators = (apiKey) => this.get(`/api/v4/requisition_forms/creators`, {}, apiKey);
    this.getApprovalPublicLinks = (apiKey, params) => this.get(`/api/v4/requisition_public_links`, params, apiKey);
    this.createApprovalPublicLink = (apiKey, params) => this.post(`/api/v4/requisition_public_links`, params, apiKey);
    this.updateApprovalPublicLink = (apiKey, publicLinkId, params) => this.put(`/api/v4/requisition_public_links/${publicLinkId}`, params, apiKey);
    this.deleteApprovalPublicLinks = (apiKey, params) => this.delete(`/api/v4/requisition_public_links/bulk_destroy`, params, apiKey);
    this.newField = (apiKey, approvalTemplateId, params) => this.post(`/api/v4/fields?requisition_form_id=${approvalTemplateId}`, params, apiKey);
    this.getSystemFields = (apiKey) => this.get(`/api/v4/system_fields?form_type=RequisitionForm`, {}, apiKey);
    this.addSystemField = (apiKey, approvalTemplateId, systemFieldId) => this.post(
      `/api/v4/system_fields?requisition_form_id=${approvalTemplateId}&system_field_id=${systemFieldId}`,
      {},
      apiKey
    );
    this.getQuestions = (apiKey, params) => this.get(`/api/v4/fields`, params, apiKey);
    this.deleteQuestion = (apiKey, id, approvalTemplateId) => this.delete(`/api/v4/fields/${id}?requisition_form_id=${approvalTemplateId}`, {}, apiKey);
    this.cloneQuestion = (apiKey, id, approvalTemplateId) => this.post(`/api/v4/fields/${id}/clone?requisition_form_id=${approvalTemplateId}`, {}, apiKey);
    this.putQuestion = (apiKey, id, approvalTemplateId, params) => this.put(`/api/v4/fields/${id}?requisition_form_id=${approvalTemplateId}`, params, apiKey);
    this.postArchiveApprovalForms = (apiKey, params) => this.post(`/api/v4/requisitions/bulk_archive`, params, apiKey);
    this.postUnarchiveApprovalForms = (apiKey, params) => this.post(`/api/v4/requisitions/bulk_unarchive`, params, apiKey);
    this.deleteTrashApprovalForms = (apiKey, params) => this.delete(`/api/v4/requisitions/bulk_destroy`, params, apiKey);
    this.postArchiveApprovalTemplates = (apiKey, params) => this.post(`/api/v4/requisition_forms/bulk_archive`, params, apiKey);
    this.postUnarchiveApprovalTemplates = (apiKey, params) => this.post(`/api/v4/requisition_forms/bulk_unarchive`, params, apiKey);
    this.deleteTrashApprovalTemplates = (apiKey, params) => this.delete(`/api/v4/requisition_forms/bulk_destroy`, params, apiKey);
    this.getAssignableUsers = (apiKey) => this.get(`/api/v4/assignable_users`, {}, apiKey);
    this.getCSVData = (apiKey, questionId, positionId) => this.get(
      `/api/v4/system_fields/${questionId}/remote`,
      { position_id: positionId, new_position: false },
      apiKey
    );
    this.getJobs = (apiKey) => this.get(`/api/v4/jobs/jobs_for_requisitions`, {}, apiKey);
    this.getApplications = (apiKey, jobId) => this.get(`/api/v4/jobs/${jobId}/applications_for_requisition`, {}, apiKey);
    this.getFilters = (apiKey, url, params) => this.get(`/api/v4/requisitions/${url}`, params, apiKey);
  }
  getHeaders(headers) {
    return Object.assign(
      {},
      {
        "Content-Type": "application/json"
      },
      headers
    );
  }
}
export default new Api();
