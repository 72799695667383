import React, { Dispatch } from 'react';
import { GenericTableData, TableActionItemAction } from './types';
import { IconButton, Tooltip } from '@mui/material';

export type ActionListItem = {
  id: string;
  tooltipLabel: string;
  icon: JSX.Element;
  actionCall?: (id: string) => void;
  elementId?: string;
};

interface IGenericTableActionProps<T> {
  item: T;
  dispatch: Dispatch<TableActionItemAction<T>>;
  actions: ActionListItem[];
}

const GenericTableActions = <GenericTableActionsProps extends GenericTableData>({
  item,
  dispatch,
  actions
}: IGenericTableActionProps<GenericTableActionsProps>) => {
  return (
    <>
      {actions.map((action) => (
        <Tooltip placement="top" title={action.tooltipLabel} key={action.id} arrow>
          <IconButton
            className={`${action.id}-button`}
            id={action?.elementId ? action.elementId : 'table-action-button'}
            onClick={(event) => {
              dispatch({
                type: 'SELECT_ACTION_ITEM',
                payload: { item: item, anchorEl: event.currentTarget, name: action.id }
              });

              action.id === 'emailAnalytics' &&
                dispatch({
                  type: 'SELECT_ACTION_ITEM',
                  payload: { item: item, anchorEl: event.currentTarget, name: 'candidatePool' }
                });

              action?.actionCall && action.actionCall(item);
            }}
          >
            {action.icon}
          </IconButton>
        </Tooltip>
      ))}
    </>
  );
};

export default GenericTableActions;
