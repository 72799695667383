import React, { Dispatch, FC, useContext, useState } from 'react';
import {
  Box,
  Modal,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Autocomplete,
  Stack,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import { useQuery } from '@tanstack/react-query';
import { ReportContext } from '../../index';
import RecruitApi from '../../RecruitApi';

interface IApprovalModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
  sendReport: () => void;
}

const ApprovalModal: FC<IApprovalModal> = ({ dispatch, state, sendReport }) => {
  const { filtersModal, openPreview, cognology, approvalState, approvalForm } = state;
  const [modalApprovalState, setModalApprovalState] = useState<string>(approvalState);
  const [modalApprovalForm, setModalApprovalForm] = useState<{
    approval_id: number;
    approval_form_name: string;
  } | null>(null);
  const [includeCognology, setIncludeCognology] = useState<boolean>(cognology);
  const reportServiceApi = useContext(ReportContext);

  const {
    data: options,
    isLoading: loadingOptions,
    isFetching: fetchingOptions
  } = useQuery({
    queryKey: ['options', includeCognology],
    queryFn: async () => {
      if (includeCognology) {
        const { res }: { res: { approval_forms: { id: number; name: string }[] } } =
          await RecruitApi.getCognologyForms();
        return {
          approvals: res.approval_forms.map((form) => ({
            approval_id: form.id,
            approval_form_name: form.name
          }))
        };
      }
      const { options } = await reportServiceApi.systemReports.options(openPreview);
      return options as {
        approvals: { approval_id: number; approval_form_name: string }[];
      };
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading approval forms, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: () => {
      const initialForm = options?.approvals.find(
        (approval) => approval.approval_id === approvalForm
      );
      setModalApprovalForm(initialForm || null);
    },
    enabled: openPreview === 'Approvals Report'
  });

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, 'Approvals Report': false }
    });
  };

  return (
    <Modal
      open={filtersModal['Approvals Report']}
      onClose={handleClose}
      aria-labelledby="approval-report-filters-modal"
    >
      <Box sx={{ ...classes.actionsModal, maxHeight: '460px', width: '720px' }}>
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>Approval report filters</Box>
        </Box>
        <Stack sx={{ marginTop: '1.75rem', flexGrow: 1 }}>
          <Box sx={{ ...classes.modalFormLine }}>
            <Autocomplete
              disableClearable
              disablePortal
              autoHighlight
              includeInputInList
              options={['All', 'Active', 'Archived']}
              loading={loadingOptions}
              getOptionLabel={(option) => option}
              noOptionsText="No approval state"
              value={modalApprovalState}
              onChange={(_event, newValue) => setModalApprovalState(newValue)}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
              ListboxProps={{
                style: { ...classes.autocompleteListboxStyle, textTransform: 'capitalize' }
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ input: { textTransform: 'capitalize' } }}
                  {...params}
                  label="Approval state"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {fetchingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              options={options?.approvals || []}
              loading={loadingOptions}
              getOptionLabel={(option) => option.approval_form_name}
              noOptionsText="No approval forms"
              value={modalApprovalForm}
              onChange={(_event, newValue) =>
                newValue ? setModalApprovalForm(newValue) : setModalApprovalForm(null)
              }
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 3 }}
              ListboxProps={{ style: classes.autocompleteListboxStyle }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.approval_id}>
                    {option.approval_form_name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Approval form"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {fetchingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={classes.checkbox}
                  checked={includeCognology}
                  onClick={() => setIncludeCognology(!includeCognology)}
                  disableRipple
                />
              }
              label="Include Cognology export data"
              sx={classes.checkboxLabel}
            />
          </Box>
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset' }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              const filter: Record<string, string> = {};
              if (modalApprovalState === 'All') {
                delete filter['Approval state'];
              } else {
                filter['Approval state'] = modalApprovalState;
              }
              if (modalApprovalForm) {
                filter['Approval form'] = modalApprovalForm.approval_form_name;
                dispatch({ type: 'APPROVAL_FORM', payload: modalApprovalForm.approval_id });
              } else {
                delete filter['Approval form'];
                dispatch({ type: 'APPROVAL_FORM', payload: null });
              }
              if (includeCognology) {
                filter['Include cognology export data'] = 'True';
              } else {
                delete filter['Include cognology export data'];
              }
              dispatch({ type: 'APPROVAL_STATE', payload: modalApprovalState });
              dispatch({ type: 'COGNOLOGY', payload: includeCognology });
              dispatch({ type: 'SET_FILTERS', payload: filter });
              includeCognology && sendReport();
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default ApprovalModal;
