import React, { useState } from 'react';
import { IEvent } from '../../../../Job/types';
import { Box, Tooltip } from '@mui/material';
import { theme } from '../../../../../ThemeContext/ThemeObject';

interface IProps {
  attendees: IEvent['attendees'];
}

const HEIGHT = 26;
const MARGIN_BOTTOM = 6;
const BORDER_HEIGHT = 2;
const MAX_CHARACTERS = 30;

export default function AttendeeList({ attendees }: IProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandedItems, setShowExpandedItems] = useState(false);

  const filteredAttendees = attendees.filter((attendee) => attendee.type === 'Candidate');

  const handleExpandClick = () => {
    if (isExpanded) {
      setIsExpanded(false);
      setTimeout(() => {
        setShowExpandedItems(false);
      }, 500);
    } else {
      setIsExpanded(true);
      setShowExpandedItems(true);
    }
  };

  function getHeight() {
    if (!isExpanded) {
      return `${HEIGHT}px`;
    } else {
      return `${HEIGHT * (filteredAttendees.length + 1) + MARGIN_BOTTOM * filteredAttendees.length}px`;
    }
  }

  const displayBadge = filteredAttendees.length > 1 && !isExpanded;

  return (
    <Box sx={{ ...styles.container, ...{ height: getHeight() } }}>
      <Box display="flex">
        {filteredAttendees.length > 0 && (
          <Attendee
            attendee={filteredAttendees[0]}
            badge={{
              show: displayBadge,
              handleExpandClick,
              numberOfAttendees: filteredAttendees.length - 1
            }}
          />
        )}
      </Box>
      {showExpandedItems &&
        filteredAttendees
          .slice(1)
          .map((attendee) => <Attendee key={attendee.id} attendee={attendee} />)}
      {isExpanded && (
        <Box onClick={handleExpandClick} sx={{ ...styles.item, ...styles.showLess }}>
          Show less
        </Box>
      )}
    </Box>
  );
}

function Attendee({
  attendee,
  badge
}: {
  attendee: IEvent['attendees'][0];
  badge?: {
    show: boolean;
    handleExpandClick: () => void;
    numberOfAttendees: number;
  };
}) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const tagText = `${attendee.type}: ${attendee.name}`;
  const label = tagText.length > MAX_CHARACTERS ? tagText.slice(0, MAX_CHARACTERS) + '…' : tagText;

  const handleMouseEnter = (): void => {
    if (tagText.length >= MAX_CHARACTERS) {
      setShowTooltip(true);
    }
  };

  return (
    <>
      <Tooltip key={attendee.id} title={attendee.name} placement="top" open={showTooltip}>
        <Box
          key={attendee.id}
          sx={{
            ...styles.item,
            ...{ width: badge?.show ? '82.5%' : '100%' },
            ...(attendee.application_url && { cursor: 'pointer' })
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => setShowTooltip(false)}
          onClick={() => attendee.application_url && window.open(attendee.application_url, '_self')}
          onAuxClick={() =>
            attendee.application_url && window.open(attendee.application_url, '_blank')
          }
        >
          {label}
        </Box>
      </Tooltip>
      {badge?.show && (
        <Box onClick={badge.handleExpandClick} sx={styles.badge}>
          {`+${badge.numberOfAttendees}`}
        </Box>
      )}
    </>
  );
}

const styles = {
  container: {
    transition: 'height 0.5s cubic-bezier(.75,.34,.19,.79)',
    overflow: 'hidden'
  },
  item: {
    backgroundColor: '#EDF2F5',
    color: theme.palette.primary.main,
    borderRadius: HEIGHT / 2,
    height: HEIGHT,
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: `${MARGIN_BOTTOM}px`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '0 18px',
    border: `${BORDER_HEIGHT}px solid white`,
    zIndex: 1,
    lineHeight: `${HEIGHT - BORDER_HEIGHT * 2}px`,
    transition: 'width 0.5s cubic-bezier(.75,.34,.19,.79)',
    fontSize: '13px'
  },
  badge: {
    backgroundColor: '#EDF2F5',
    color: theme.palette.primary.main,
    borderRadius: HEIGHT / 2,
    height: HEIGHT,
    border: `${BORDER_HEIGHT}px solid white`,
    textAlign: 'center',
    fontWeight: '600',
    transform: 'translateX(-22.5%)',
    userSelect: 'none',
    lineHeight: `${HEIGHT - BORDER_HEIGHT * 2}px`,
    transition: 'opacity 0.5s, width 0.5s cubic-bezier(.75,.34,.19,.79)',
    width: '17.5%',
    cursor: 'pointer'
  },
  showLess: {
    fontSize: '12px',
    cursor: 'pointer',
    userSelect: 'none',
    width: '100px',
    margin: 'auto'
  }
};
