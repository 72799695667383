"use strict";
import { useContext } from "react";
import { ReportContext } from "../index";
export const SystemReportsList = (permission) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const reportServiceApi = useContext(ReportContext);
  return [
    {
      title: "Applications Report",
      description: "Applications received by entity or source. Ability to filter by timeframe.",
      permission: (_a = permission == null ? void 0 : permission.Reports) == null ? void 0 : _a["Applications by Source & Entity Reports"]
    },
    {
      title: "Application Timeline Report",
      description: "Details of user activity on applications. Ability to filter by timeframe.",
      permission: reportServiceApi.currentUser.admin
      // keeping this permission for when it's ready to launch
      // permission?.Reports?.['Application Timeline Report']
    },
    {
      title: "Approvals Report",
      description: "Provides details of submitted forms against a specific approval form template. Ability to filter by timeframe.",
      permission: reportServiceApi.currentUser.admin
      // keeping this permission for when it's ready to launch
      // permission?.Reports?.['Approval Forms Report']
    },
    {
      title: "Aurion Report",
      description: "Export of candidate data to import into Aurion.",
      permission: (_b = permission == null ? void 0 : permission.Reports) == null ? void 0 : _b["Aurion's Export Report (P)"]
    },
    {
      title: "Candidates for eShots Report",
      description: "Provides details of candidates who can be sent eShots.",
      permission: ((_c = permission == null ? void 0 : permission.Reports) == null ? void 0 : _c["Candidate eShots Export"]) && (reportServiceApi.currentUser.staff || reportServiceApi.currentUser.admin)
    },
    {
      title: "Caretakers Report",
      description: "Provides details of which AE is the caretaker for entities in :Recruit.",
      permission: reportServiceApi.currentUser.staff || reportServiceApi.currentUser.admin
    },
    {
      title: "Daily Applications Report",
      description: "Summary of application data including current status. Ability to filter by timeframe.",
      permission: (_d = permission == null ? void 0 : permission.Reports) == null ? void 0 : _d["Daily Applications"]
    },
    {
      title: "Job Details Report",
      description: "Summary of jobs published in a specified timeframe.",
      permission: (_e = permission == null ? void 0 : permission.Reports) == null ? void 0 : _e["Job Details Report"]
    },
    {
      title: "Job State Report",
      description: "Provides details of the jobs' state in a specified timeframe (e.g. Saved).",
      permission: (_f = permission == null ? void 0 : permission.Reports) == null ? void 0 : _f["Job Status Report"]
    },
    {
      title: "Onboard Express Report",
      description: "Candidate data that has been exported to Onboard Express.",
      permission: (_g = permission == null ? void 0 : permission.Integrations) == null ? void 0 : _g["Export To Revolve Onboard"]
    },
    {
      title: "Placements Advanced Report",
      description: "Detailed summary of application data within :Recruit, based on status changes.",
      permission: (_h = permission == null ? void 0 : permission.Reports) == null ? void 0 : _h["Placements Advanced Report"]
    },
    {
      title: "Published Jobs Report",
      description: "Provides the published date of jobs in a specified timeframe.",
      permission: (_i = permission == null ? void 0 : permission.Reports) == null ? void 0 : _i["Job Details Report"]
    },
    {
      title: "Questions Report",
      description: "Export of candidate's answers to screening questions in a specified timeframe.",
      permission: reportServiceApi.currentUser.admin
      // keeping this permission for when it's ready to launch
      // permission?.Reports?.['Job Questions Report (P)']
    },
    {
      title: "Recruitment Timeline Report",
      description: "Provides details of the jobs' status in a specified timeframe.",
      permission: reportServiceApi.currentUser.admin
      // keeping this permission for when it's ready to launch
      // permission?.Reports?.['Job Details Report'] && reportServiceApi.jobStatusFeature
    },
    {
      title: "SMS Report",
      description: "Provides details of all SMS messages sent from your :Recruit account.",
      permission: reportServiceApi.currentUser.staff || reportServiceApi.currentUser.admin
    },
    {
      title: "Tags Report",
      description: "Export of all tags in use within :Recruit.",
      permission: true
    },
    {
      title: "User Events Report",
      description: "Event details for a specific user in a specified timeframe.",
      permission: (_j = permission == null ? void 0 : permission.Reports) == null ? void 0 : _j["User Events Report (P)"]
    }
  ];
};
export const JobStates = ["draft", "saved", "closed", "published", "unpublished"];
export const URL = {
  "Applications Report": "application_by_",
  "Application Timeline Report": "application_timeline",
  "Approvals Report": "approvals",
  "Aurion Report": "aurion_export",
  "Candidates for eShots Report": "candidates_eshot",
  "Caretakers Report": "caretakers",
  "Daily Applications Report": "daily_applications",
  "Job Details Report": "job_details",
  "Job State Report": "job_state",
  "Placements Advanced Report": "placement_advanced",
  "Questions Report": "questions",
  "Tags Report": "candidate_tags",
  "Published Jobs Report": "published_jobs",
  "Recruitment Timeline Report": "recruitment_timeline",
  "SMS Report": "sms"
};
