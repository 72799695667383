import { SxProps } from '@mui/material';
import { classes } from '../../../../NewUI/Components/GenericTable/styles';

export const emailsColumns = [
  {
    id: 'email_template',
    label: 'Email Template',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'user',
    label: 'User',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'only_new',
    label: 'Recently Added Candidates',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'include_deleted',
    label: 'Include Trashed Applications',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'applications',
    label: 'Applications',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'delayed_to',
    label: 'Delayed to',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'created_at',
    label: 'Created at',
    sortable: true,
    canToggle: false,
    enabled: true
  }
];

export const headerStyles = (
  name: (typeof emailsColumns)[number]['id'] | 'checkbox' | 'actions',
  tableHovered: boolean
): SxProps => {
  switch (name) {
    case 'email_template':
      return {
        ...classes.sticky,
        ...classes.itemTitleTableCell,
        left: '0',
        minWidth: '180px',
        paddingLeft: '60px !important',
        '& span': {
          width: '130px'
        }
      };
    case 'user':
      return { minWidth: '150px' };
    case 'only_new':
      return { minWidth: '120px' };
    case 'include_deleted':
      return { minWidth: '100px' };
    case 'applications':
      return { minWidth: '100px' };
    case 'delayed_to':
      return { minWidth: '120px' };
    case 'created_at':
      return { minWidth: '130px' };
    case 'actions':
      return {
        ...classes.actionsColumnHeader,
        ...(tableHovered && classes.scrollShadowVisible)
      };
    default:
      return { minWidth: '90px' };
  }
};
