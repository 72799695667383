"use strict";
import { theme } from "../../ThemeContext/ThemeObject";
export const styles = {
  applicationStatusListboxStyle: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#333333",
    maxHeight: "200px",
    fontSize: "12px"
  },
  actionsMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "11px",
      minWidth: "153px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    }
  },
  actionsItem: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#939393",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  checkbox: {
    color: "#DDDDDD",
    "&.Mui-checked": {
      color: theme.palette.action.main
    },
    "& .MuiSvgIcon-root": {
      fontSize: "22px"
    }
  },
  checkboxLabel: {
    color: "#939393",
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  },
  modalInfoStyle: {
    color: "#939393",
    fontFamily: "Source Sans Pro",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "normal"
  },
  linkedInURLInput: {
    ".MuiInputBase-input": {
      height: "10px",
      margin: "unset",
      padding: "4px 6px",
      fontSize: "11px",
      minWidth: "150px",
      borderRadius: "4px",
      "&::placeholder": {
        fontStyle: "italic"
      }
    }
  },
  timeStampInput: {
    ".MuiInputBase-input": {
      height: "16px",
      padding: "4px 6px",
      fontSize: "11px",
      minWidth: "50px",
      borderRadius: "4px",
      "&::placeholder": {
        fontStyle: "italic"
      }
    }
  },
  timeStampItem: {
    cursor: "pointer",
    color: theme.palette.primary.dark,
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    height: "16px",
    margin: "unset",
    padding: "4px 6px",
    fontSize: "14px",
    minWidth: "50px"
  },
  linkedInURLLink: {
    cursor: "pointer",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#084D6D",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    "&:hover": { textDecoration: "underline" }
  },
  modalStyleOverrides: {
    maxHeight: "unset",
    width: "700px",
    padding: "40px",
    height: "350px"
  },
  modalContainer: {
    width: "100%",
    height: "100%",
    rowGap: 3
  },
  modalTitle: {
    fontWeight: "bold",
    color: "#084D6D",
    fontSize: "25px"
  },
  listboxPropsStyle: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#333333",
    maxHeight: "200px"
  },
  clickableGreyIcon: {
    cursor: "pointer",
    color: "#666666"
  },
  scoutNavyIcon: {
    color: "#084D6D",
    fontSize: "20px"
  },
  applicationHeaderItems: {
    flexDirection: "row",
    columnGap: 1,
    alignItems: "center",
    fontSize: "14px",
    color: "#333333"
  },
  applicationHeaderActionsContainer: {
    flexDirection: "row",
    columnGap: 2,
    justifyContent: "end",
    flexGrow: 1
  },
  surroundingApplications: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "8px",
    columnGap: 1,
    color: "#939393"
  },
  surroundingApplicationsButton: {
    border: "1px solid #E3E3E3",
    borderRadius: "6px",
    padding: "2px 6px",
    cursor: "pointer"
  },
  additionalInfoContainer: {
    padding: "24px 0px",
    borderRadius: "6px",
    border: "1px solid #E3E3E3",
    flexDirection: "row",
    columnGap: 2,
    justifyContent: "space-around"
  },
  AISummaryContainer: {
    padding: 3,
    borderRadius: "6px",
    border: "1px solid #E3E3E3",
    rowGap: 1
  },
  AISummaryHeader: {
    svg: { width: "40px", height: "40px" },
    flexDirection: "row",
    columnGap: 1,
    alignItems: "center"
  },
  AISummaryMessage: {
    fontWeight: 600,
    color: "#2D388D",
    span: { color: "#AFBCFF" }
  },
  AISummaryButton: {
    background: "#7A8EF1",
    color: "white",
    padding: "6px 24px",
    borderRadius: "16px",
    fontWeight: "bold",
    cursor: "pointer"
  },
  generateSummaryButton: {
    flexDirection: "row",
    color: "#AFBCFF",
    padding: "6px 16px",
    borderRadius: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    border: "2px solid #AFBCFF",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "170px"
  },
  summaryLastUpdated: {
    flexDirection: "row",
    columnGap: "4px",
    alignItems: "center",
    color: "#939393",
    fontStyle: "italic",
    fontSize: "12px"
  },
  ratingCell: {
    textAlign: "center",
    padding: "4px 12px",
    borderRadius: "4px",
    width: "60px",
    fontWeight: "400"
  },
  ratingInfoContainer: {
    padding: "6px 16px",
    margin: 1,
    border: "1px solid #5BC4C0",
    borderRadius: "10px"
  },
  ratingInfoClickable: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    svg: { color: "#5BC4C0" },
    cursor: "pointer"
  },
  ratingButton: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    padding: "6px 10px",
    color: "#939393",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "14px"
  },
  ratingColor: {
    1: {
      color: "#E37D7A",
      background: "#FCEFEF",
      borderColor: "#FCEFEF"
    },
    2: {
      color: "#E58F66",
      background: "rgba(229, 143, 102, 0.12)",
      borderColor: "rgba(229, 143, 102, 0.12)"
    },
    3: {
      color: "#E6A052",
      background: "#FCF4EA",
      borderColor: "#FCF4EA"
    },
    4: {
      color: "#9BB974",
      background: "rgba(155, 185, 116, 0.12)",
      borderColor: "rgba(155, 185, 116, 0.12)"
    },
    5: {
      color: "#8CCF95",
      background: "#EFFDF3",
      borderColor: "#EFFDF3"
    }
  },
  questionnaireContainer: {
    padding: 3,
    borderRadius: "6px",
    border: "1px solid #E3E3E3",
    rowGap: 2
  },
  ratingCard: {
    borderRadius: "3px",
    minWidth: "150px",
    width: "20%",
    padding: "12px 16px",
    height: "87px",
    justifyContent: "space-between",
    svg: { fontSize: "14px" }
  },
  editRating: {
    ".MuiInputBase-input": {
      height: "8px",
      borderRadius: "3px",
      padding: 0.5,
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: "unset"
    },
    "& input::placeholder": {
      fontSize: "12px"
    }
  },
  editableRatings: {
    ".MuiFormControl-root": { width: "70px" },
    ".MuiStack-root": { marginTop: "unset" }
  },
  sortableSectionsButton: {
    border: "1px solid #EAEEF0",
    borderRadius: "6px",
    padding: "6px 14px",
    height: "min-content",
    cursor: "pointer",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    }
  },
  sortableSectionsHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 1
  },
  addToEventModalStyle: {
    padding: "40px",
    width: "900px",
    height: "700px",
    overflow: "auto"
  },
  timeslotAvailabilityButton: {
    padding: "6px 24px",
    borderRadius: "6px",
    alignItems: "center",
    fontWeight: "bold",
    width: "min-content"
  },
  preview: {
    color: "#666666",
    fontSize: "medium",
    "&:hover": {
      cursor: "pointer"
    }
  },
  fileIconContainer: {
    display: "flex",
    alignItems: "center"
  },
  fileIcon: {
    cursor: "pointer",
    width: "50px",
    height: "50px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    backgroundColor: "#084D6D10",
    "& svg": {
      color: theme.palette.primary.main,
      margin: "0px 2px 2px 0px"
    }
  },
  fileName: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    fontWeight: "bold",
    cursor: "pointer"
  },
  fileDate: {
    color: "#999999"
  },
  fileLinkContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: "15px",
    "& a": {
      color: "#333333",
      fontSize: "15px",
      "&:hover": {
        color: "#000000",
        textDecoration: "none"
      }
    }
  },
  fileAttachedBy: {
    color: "#333333",
    fontSize: "15px"
  },
  noAttachments: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "24px 0px"
  },
  tableWrapper: {
    tableLayout: "auto !important",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "12px",
      cursor: "inherit"
    },
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    }
  },
  tableHeaderRow: {
    "& td, th": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      backgroundColor: "white",
      "& span": {
        color: theme.palette.primary.main,
        "&:hover": {
          color: "#0c74a4"
        }
      }
    },
    "& .Mui-selected": {
      backgroundColor: "#F2F2F2"
    },
    "& .Mui-active": {
      color: theme.palette.primary.main
    },
    "& .MuiTableSortLabel-icon": {
      color: "darkgrey !important"
    }
  },
  tableActionIcon: {
    "&:focus": {
      outline: "none"
    }
  },
  timeslotsContainer: {
    flexDirection: "row",
    width: "100%",
    maxHeight: "290px",
    columnGap: 2,
    rowGap: 2,
    flexFlow: "wrap",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  timeslot: {
    border: `1px solid #DDDDDD`,
    borderRadius: "6px",
    padding: "8px 16px",
    fontWeight: "bold",
    width: "125px"
  },
  timeslotSubject: {
    fontSize: "12px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  noTimeslotMessage: {
    padding: 9,
    fontSize: "20px",
    fontWeight: "bold",
    color: theme.palette.common.grey
  },
  emptyStateContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "100px 0px"
  },
  emptyStateText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  applicationPageStickyHeader: {
    position: "sticky",
    top: "0px",
    zIndex: 10,
    background: "white",
    rowGap: 3,
    paddingBottom: 3
  },
  emptyStateIcon: { fontSize: "5rem", color: "#EAEAEA" },
  emptyStateMessage: { color: "#DDDDDD", fontWeight: "bold" },
  autoCompleteTextfield: {
    "& .MuiInputBase-root": { margin: "unset", marginTop: 1 },
    "& .MuiFormHelperText-root": {
      margin: 0.5,
      fontSize: "12px",
      fontFamily: "inherit",
      color: theme.palette.error.main
    }
  },
  errorMessage: { marginLeft: 0.5, color: "#E37D7A", fontSize: "12px" },
  widget: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    flex: "40%",
    minWidth: "600px"
  },
  subcontainer: {
    flex: "40%",
    minWidth: "600px",
    display: "flex",
    flexWrap: "wrap",
    columnGap: "50px",
    rowGap: "50px"
  },
  subWidget: {
    marginTop: "24px",
    flex: "40%",
    borderRadius: "3px",
    height: "100px",
    minWidth: "240px"
  },
  widgetIcon: {
    backgroundColor: "#EDF2F5",
    padding: "12px",
    borderRadius: "16px",
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "32px"
    }
  },
  widgetText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    rowGap: "6px",
    marginLeft: "30px"
  },
  widgetTextTitle: {
    fontSize: "12px",
    color: theme.palette.primary.main
  },
  widgetTextContent: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#333333"
  },
  widgetBlockContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    columnGap: "50px"
  },
  widgetBlock: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    paddingX: "16px",
    alignItems: "center",
    width: "50%",
    height: "100%"
  },
  widgetBlockText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "12px"
  },
  widgetBlockHeading: {
    color: theme.palette.primary.main
  },
  widgetBlockBody: {
    fontSize: "14px",
    color: "#333333"
  },
  blockTitle: {
    fontSize: "10px",
    fontWeight: "regular",
    color: "#939393"
  },
  widgetBlockIcon: {
    backgroundColor: "#EDF2F5",
    padding: "12px",
    borderRadius: "8px",
    color: theme.palette.primary.main
  },
  smartFormsAnswersPreviewContainer: {
    "& > div > div:first-of-type": {
      color: theme.palette.primary.dark,
      fontWeight: "bold"
    },
    "& > div > div:not(:first-of-type)": {
      color: "#333333"
    },
    "& > div": {
      paddingLeft: 0
    }
  },
  smartFormsTableContainer: {
    tableLayout: "auto !important",
    border: "none",
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "24px"
    },
    "& thead > tr": { borderBottom: "1px solid #E5E5E5" },
    "& tbody > tr:nth-of-type(2n)": {
      borderBottom: "1px solid #E5E5E5"
    }
  },
  ratingInput: { flexDirection: "row", alignItems: "center", columnGap: 0.5 },
  questionsAndRatingsContainer: {
    overflow: "auto",
    rowGap: 2,
    flexGrow: 1,
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  loaderContainer: {
    flexGrow: 1,
    rowGap: 3,
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.action.main,
    fontSize: "18px"
  },
  timestampError: {
    color: theme.palette.error.main,
    fontSize: "12px",
    paddingY: "3px"
  },
  betaButtonTooltipContainer: {
    width: "430px",
    height: "400px",
    color: "#333333",
    fontFamily: "Source Sans Pro",
    rowGap: 2,
    padding: 2
  },
  candidateLink: {
    color: "#084D6D",
    fontSize: "20px",
    fontWeight: "bold",
    width: "fit-content"
  }
};
