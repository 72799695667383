import React, { Component } from 'react';

export default class IntegrationSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entity_id: this.props.entityId,
      integration_id: this.props.integration.id,
      enabled: false,
      auth_string: '',
      body: '',
      response: ''
    }
  }

  componentWillMount() {
    const { url } = this.props;

    fetch(`${url}?entity_id=${this.props.entityId}&integration_id=${this.props.integration.id}`,
      { headers: { "Content-Type": "application/json" }}
    )
      .then(response => response.json())
      .then((json) => {
        json.id !== null && this.setState(json);
      });
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  handleSave = () => {
    const { url } = this.props;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    }).then(response => {
      if (response.status === 201) {
        this.setState({ response: 'Saved successfully'})
      } else if(response.status === 422) {
        this.setState({ response: 'Invalid JSON' })
      }
    })
      .catch(error => this.setState({ response: 'Could not save settings' }));
  }

  render () {
    const { integration } = this.props;

    return (
      <div>
        <fieldset>
          <legend>{this.props.integration.name}</legend>
          <div>
            <li className="boolean input">
              <label>
                <input
                  name="enabled"
                  type="checkbox"
                  onChange={this.handleChange}
                  checked={this.state.enabled}
                />
                Enabled
              </label>
            </li>
          </div>
          <div>
            <li className="string input optional stringish">
              <label>Auth String</label>
              <textarea
                name="auth_string"
                rows="10"
                cols="150"
                onChange={this.handleChange}
                value={this.state.auth_string}
              />
            </li>
          </div>
          <div>
            <li className="string input optional stringish">
              <label>Body</label>
              <textarea
                name="body"
                rows="20"
                cols="150"
                onChange={this.handleChange}
                value={this.state.body}
              />
            </li>
          </div>
          <li className="action input_action">
            <button
              className="commit"
              type="button"
              onClick={this.handleSave}
            >
              Save
            </button>
          </li>
          {this.state.response}
        </fieldset>
      </div>
    );
  }
}
