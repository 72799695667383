import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { styles } from './styles';
import { sharedClasses } from '../CustomUIElements/sharedClasses';

const TableHeader = ({ state }: { state: string[] }) => {
  return (
    <TableHead>
      <TableRow sx={sharedClasses.tableHeaderRow}>
        {state?.map((option, i: number) => (
          <TableCell key={i} sx={styles.tableCellData}>
            {option}
          </TableCell>
        ))}
        <TableCell> </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
