import React, { useEffect, useState } from "react"
import { createConsumer } from "@rails/actioncable"
import StyledSnackbar from "./NewUI/Components/CustomUIElements/StyledSnackbar"

function TestActionCable() {
  const [message, setMessage] = useState<string>("")
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({});

  useEffect(() => {
    const consumer = createConsumer(
      document.querySelector('meta[name="action-cable-url"]').content
    )
    consumer.subscriptions.create(
      { channel: "MessageChannel" },
      {
        connected: () => setMessage("Connected"),
        received: data => {
          setMessage(data.message)
          if (data.state === "success" && setSnackbar) {
            setSnackbar && setSnackbar(data)
          }
        },
      }
    )
    return () => {
      consumer.disconnect()
    }
  }, [])

  return (
    <>
      <h1>{message}</h1>
      {snackbar.message && <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />}
    </>
  )
}

export default TestActionCable
