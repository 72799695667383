import { TableBody, Table, Box, TableContainer, Paper, Skeleton } from '@mui/material';
import React, { Dispatch, useRef, useState } from 'react';
import { styles } from './styles';
import PaginationWithNumberOfRowsSelection from './PaginationWithNumberOfRowsSelection';
import { TableAction } from './types';
import TableHeader from './TableHeader';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ArrowRefsAndHoveredContext } from './ArrowRefsAndHoveredContext';

type Props = {
  state: {
    activeColumns: any;
    limit: number;
    currentPage: number;
    maxPages: number;
    maxRecords: number;
    density: string;
    sort_by: string;
    sort_order: string;
    [key: string]: any;
  };
  dispatch: Dispatch<TableAction<any, any>>;
  tableBody: React.ReactNode;
  stickyColumnSettings?: {
    leftBoundOffSet: number;
    rightBoundOffSet: number;
  };
  pendoId?: string;
};


const GenericTable = ({ state, dispatch, tableBody, stickyColumnSettings, pendoId }: Props) => {
  const tablePadding = (density: string) => {
    if (density === 'Default') {
      return '22px';
    } else if (density === 'Compact') {
      return '15px';
    } else {
      return '8px';
    }
  };

  function isScrollable(element: HTMLElement) {
    return element && element.scrollWidth > element.clientWidth + 20;
  }

  const [canScroll, setCanScroll] = useState([false, true]);
  const [tableHovered, setTableHovered] = useState<boolean>(false);
  const rightBoundCellRef = useRef<HTMLTableCellElement>(null);
  const leftBoundCellRef = useRef<HTMLTableCellElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const handleScrollableEnter = () => {
    if (isScrollable(tableRef.current)) setTableHovered(true);
  };

  const handleScrollableExit = () => {
    setTableHovered(false);
  };

  const handleScroll = () => {
    if (tableRef.current.scrollLeft < 10) {
      setCanScroll([false, true]);
    } else if (
      tableRef.current.scrollLeft >
      tableRef.current.scrollWidth - tableRef.current.clientWidth - 10
    ) {
      setCanScroll([true, false]);
    } else {
      setCanScroll([true, true]);
    }
  };

  const handleScrollLeft = () => {
    tableRef.current.scrollLeft > 360
      ? (tableRef.current.scrollLeft -= 300)
      : (tableRef.current.scrollLeft = 0);
  };

  const handleScrollRight = () => {
    tableRef.current.scrollWidth - tableRef.current.clientWidth > 360
      ? (tableRef.current.scrollLeft += 300)
      : (tableRef.current.scrollLeft = tableRef.current.scrollWidth - tableRef.current.clientWidth);
  };

  const boundingElementWidth = () => {
    if (rightBoundCellRef.current) {
      return (
        rightBoundCellRef.current.getBoundingClientRect().right -
        rightBoundCellRef.current.getBoundingClientRect().left
      );
    } else {
      return 0;
    }
  };

  const leftOffset = stickyColumnSettings?.leftBoundOffSet || 0;
  const rightOffset = stickyColumnSettings?.rightBoundOffSet || 0;

  const { records, isLoading, density } = state;
  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <ArrowRefsAndHoveredContext.Provider
          value={{ leftBoundCellRef, rightBoundCellRef, tableHovered }}
        >
          <Box sx={styles.arrowsContainer}>
            <Box
              onClick={handleScrollLeft}
              onMouseEnter={handleScrollableEnter}
              sx={{
                ...styles.leftArrow,
                ...(tableHovered && canScroll[0] && styles.showArrows),
                left: `${
                  leftBoundCellRef.current?.getBoundingClientRect().right -
                  leftOffset -
                  boundingElementWidth()
                }px`,
                top: 0
              }}
              id="left-arrow"
            >
              <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
            </Box>
          </Box>
          <Box sx={styles.arrowsContainer}>
            <Box
              onMouseEnter={handleScrollableEnter}
              onClick={handleScrollRight}
              sx={{
                ...styles.rightArrow,
                ...(tableHovered && canScroll[1] && styles.showArrows),
                left: `${
                  rightBoundCellRef.current?.getBoundingClientRect().left -
                  rightOffset -
                  boundingElementWidth()
                }px`,
                top: 0
              }}
              id="right-arrow"
            >
              <KeyboardArrowRightIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
            </Box>
          </Box>
          <TableContainer
            sx={{ boxShadow: 'none', scrollBehavior: 'smooth' }}
            component={Paper}
            onMouseEnter={handleScrollableEnter}
            onMouseLeave={handleScrollableExit}
            ref={tableRef}
            onScroll={handleScroll}
          >
            <Table
              sx={{
                ...styles.tableWrapper,
                'th, td, tr, thead': { padding: `${tablePadding(density)} 10px` }
              }}
            >
              <TableHeader state={state} dispatch={dispatch} pendoId={pendoId} />
              {!isLoading && records?.length > 0 && <TableBody>{tableBody}</TableBody>}
            </Table>
            {!isLoading && records?.length === 0 && (
              <Box sx={styles.noRecords}>No Records Found</Box>
            )}
            {isLoading && (
              <>
                {[...Array(10)].map((_, index) => (
                  <Box key={index} sx={styles.skeletonContainer}>
                    <Skeleton animation="wave" height={60} />
                  </Box>
                ))}
              </>
            )}
          </TableContainer>
          <PaginationWithNumberOfRowsSelection state={state} dispatch={dispatch} />
        </ArrowRefsAndHoveredContext.Provider>
      </Box>
    </Box>
  );
};

export default GenericTable;
