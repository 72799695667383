import React from 'react';
import { Box, Collapse } from '@mui/material';
import { classes } from '../../styles';
import { TMenuItem } from '../../config';

const NavigationChildren = ({
  item,
  expandedParents,
  activeItem
}: {
  item: TMenuItem;
  expandedParents: string[];
  activeItem: {
    parent: string;
    child: string;
  };
}) => {
  if (!(item.submenu && item.submenu.length > 0)) {
    return null;
  }
  return (
    <Collapse in={expandedParents.includes(item.title)}>
      <Box sx={classes.menuItemChildrenContainer}>
        {item?.submenu.map((subItem) => {
          return (
            <a href={subItem.url} key={subItem.title} id={`${subItem?.id ? subItem?.id : ''}`}>
              <Box
                sx={{
                  ...classes.menuItemChild,
                  ...(activeItem.child === subItem.title && classes.activeItem)
                }}
              >
                <span>{subItem.title}</span>
              </Box>
            </a>
          );
        })}
      </Box>
    </Collapse>
  );
};

export default NavigationChildren;
