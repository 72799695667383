"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useQuery } from "@tanstack/react-query";
import API from "../Job/API";
export const useEmailTemplatesOptions = ({
  apiKey,
  onError
}) => {
  return useQuery({
    queryKey: ["email templates"],
    queryFn: () => __async(void 0, null, function* () {
      const { email_templates } = yield API.getEmailTemplates({
        "X-api-authenticate": apiKey
      });
      const result = [];
      email_templates.forEach((group) => {
        group[1].forEach((template) => {
          result.push(`${template.id} - ${template.name}@-*@${group[0]}`);
        });
      });
      return result;
    }),
    onError: (error) => onError(error.errors.join(", "))
  });
};
