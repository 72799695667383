"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  tableAndArrowsContainer: {
    position: "relative"
  },
  tableWrapper: {
    tableLayout: "auto !important",
    minWidth: "750px",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none"
    },
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    tr: {
      fontSize: "16px",
      borderBottom: "1px solid #DDDDDD"
    }
  },
  checkbox: {
    color: "#aaa !important",
    "& .MuiSvgIcon-root": {
      fontSize: "20px"
    },
    "&.Mui-checked": {
      color: "#15C7C0 !important"
    }
  },
  sortable: {
    "&:hover": {
      color: "#0C74A4"
    },
    "& .MuiTableSortLabel-icon": {
      color: "darkgrey !important"
    },
    "& svg": {
      fontSize: "1.1rem"
    }
  },
  notSortable: {
    "& .MuiTableSortLabel-icon": {
      display: "none"
    }
  },
  sticky: {
    position: "sticky",
    left: "0px",
    background: "white",
    zIndex: 2
  },
  stickyRight: {
    position: "sticky",
    right: "0px",
    background: "white"
  },
  paginationParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    width: "100%"
  },
  rowSelectContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666",
    "& span": {
      marginRight: "12px",
      paddingBottom: "6px"
    }
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666"
  },
  pagination: {
    marginRight: "24px",
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#CCCCCC",
      margin: "0px -2px"
    },
    "& .Mui-selected": {
      color: "#666666",
      backgroundColor: "#ffffff00 !important",
      "&:hover": {
        backgroundColor: "#ffffff00 !important"
      }
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#666666"
    }
  },
  rowSelect: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: "#666666",
    fontWeight: "bold",
    borderRadius: "6px !important",
    "& legend": {
      display: "none"
    },
    "& svg": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DBDEDF"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CACDCE"
    },
    "& .MuiOutlinedInput-input": {
      padding: "4px 12px 8px 12px !important"
    }
  },
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  },
  actionsColumnHeader: {
    zIndex: 4,
    minWidth: "86px",
    position: "sticky",
    right: "0px",
    background: "white",
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      left: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      left: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  scrollShadowVisible: {
    "&&::after": {
      opacity: "20%"
    }
  },
  actionsCell: {
    minWidth: "86px",
    width: "50px",
    "& svg": {
      color: "#666666",
      fontSize: "18px",
      cursor: "pointer"
    }
  },
  idTableCell: {
    "& a": {
      color: "rgba(0, 0, 0, 0.87)",
      "&:hover": {
        textDecoration: "none",
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    minWidth: "80px",
    left: "58px"
  },
  itemTitleTableCell: {
    minWidth: "200px",
    left: "138px",
    "& a": {
      color: "#666666",
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "none"
      }
    },
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      right: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      right: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  actionsTableCell: {
    minWidth: "86px",
    width: "50px",
    "& svg": {
      color: "#666666",
      fontSize: "18px",
      cursor: "pointer"
    },
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      left: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      left: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  actionItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  arrowsContainer: {
    height: 0,
    overflow: "visible",
    position: "sticky",
    top: "200px",
    zIndex: "10",
    transform: "translateY(100px)"
  },
  leftArrow: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "40px",
    height: "65px",
    borderRadius: "0px 10px 10px 0px",
    background: "#eeeeee",
    zIndex: "11",
    opacity: "0.9",
    cursor: "pointer"
  },
  rightArrow: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "40px",
    height: "65px",
    borderRadius: "10px 0px 0px 10px",
    background: "#eeeeee",
    zIndex: "11",
    opacity: "0.9",
    cursor: "pointer"
  },
  showArrows: {
    display: "flex",
    animation: "$fadeIn linear 0.17s"
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 0.9 }
  },
  noResultsContainer: {
    width: "100%",
    height: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "42px",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "20px",
    fontWeight: "bold",
    padding: "32px 0px"
  },
  noteContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
    overflow: "hidden",
    maxHeight: "86px",
    textOverflow: "ellipsis"
  },
  noteSubject: {
    fontWeight: "bold"
  },
  noteUser: {
    padding: "4px 0px 10px 0px"
  },
  notesExpanded: {
    maxHeight: "unset"
  },
  notesExpandContract: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  attachmentItem: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    padding: "14px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  fileIcon: {
    color: theme.palette.primary.main
  },
  fileName: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    maxWidth: "240px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  notesTitle: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: "12px",
    width: "100%"
  },
  editNotePopover: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
      rowGap: "10px",
      width: "300px",
      minHeight: "150px",
      padding: "17px 17px 14px 17px"
    }
  },
  noteBox: {
    padding: "10px 12px",
    width: "100%",
    overflowWrap: "break-word"
  },
  noteInput: {
    marginBottom: "4px",
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    "& input": {
      padding: "9.5px 14px"
    }
  },
  notesButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    columnGap: "10px"
  },
  notesEditSaveButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "86px",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    },
    "& svg": {
      color: "#FFFFFF",
      fontSize: "16px !important"
    },
    "&:disabled": {
      backgroundColor: "#EEEEEE",
      color: "#BBBBBB",
      transition: "all 250ms ease-in-out"
    }
  },
  notesCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "86px",
    textTransform: "none",
    color: "#939393",
    backgroundColor: "rgba(147, 147, 147, 0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "rgba(147, 147, 147, 0.24)",
      boxShadow: "none"
    },
    "& svg": {
      color: theme.palette.error.main,
      fontSize: "16px !important"
    }
  },
  actionsContianer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "22px",
    paddingBottom: "16px"
  },
  actionButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#666666",
    backgroundColor: "#9393931F",
    padding: "10px 24px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#93939335",
      border: "none"
    }
  },
  fadeIn: {
    visibility: "visible",
    opacity: "1",
    transform: "translateX(0px)",
    transition: "visibility 0s linear 0s, opacity 300ms, transform 300ms"
  },
  fadeOut: {
    visibility: "hidden",
    opacity: "0",
    transform: "translateX(20px)",
    transition: "visibility 0s linear 300ms, opacity 300ms, transform 300ms"
  },
  selectedTooltip: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    visibility: "hidden",
    padding: "20px 30px",
    minWidth: "665px",
    bottom: "40px",
    left: "calc(50% - 350px)",
    position: "fixed",
    zIndex: 99,
    backgroundColor: "rgba(242,242,242)",
    borderRadius: "8px",
    transform: "translateX(20px)"
  },
  selectedTooltipText: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "600",
    color: "#666666"
  },
  selectedAllTooltipText: {
    color: "#084D6D",
    fontWeight: "700",
    textDecoration: "underline",
    borderRadius: "10px",
    padding: "4px 12px",
    "&:hover": {
      background: "rgba(171, 205, 239, 0.3)"
    }
  },
  selectAllWithActionsTooltip: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingInline: "20px",
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#666666"
  },
  selectAllWithActionsTooltipButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "600",
    color: "#666666",
    backgroundColor: "#9393931F",
    padding: "10px 24px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#93939335",
      border: "none"
    }
  },
  actionsMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      width: "170px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#666666",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  centerCellContent: {
    display: "flex",
    justifyContent: "center"
  }
};
