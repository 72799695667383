import React from 'react';
import { Box, Stack } from '@mui/material';
import { FelixStarsSVG } from '../../../AIStudio/SharedComponents/Graphics/FelixSVG';
import { styles } from '../styles';

export default function AISummaryNoAccess() {
  return (
    <Stack sx={{ svg: { width: '75px', height: '75px' }, alignItems: 'center', rowGap: 2 }}>
      <FelixStarsSVG />
      <Stack sx={{ ...styles.AISummaryMessage, alignItems: 'center' }}>
        <Box>
          You do not have <span>AI Screen</span> access. Unlock <span>AI Screen</span> for enhanced
          capabilities
        </Box>
        <Stack>that will optimize your workflow and save you valuable time!</Stack>
      </Stack>
      <Stack
        id="find-out-more-button"
        sx={styles.AISummaryButton}
        onClick={() =>
          window.open(`https://scouttalenthq.com/talent-acquisition-software/ai-suite`, '_blank')
        }
      >
        Find out more
      </Stack>
    </Stack>
  );
}
