import React from 'react';
import { Box } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { sharedClasses } from '../CustomUIElements/sharedClasses';

const CreateButton = ({
  id,
  ariaLabel,
  onClick,
  isOpen,
  disableDropDown = false
}: {
  id: string;
  ariaLabel: string;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isOpen?: boolean;
  disableDropDown?: boolean;
}) => {
  return (
    <Box
      id={id}
      aria-label={ariaLabel}
      sx={{
        ...sharedClasses.createButtonAction,
        '& div': {
          ...sharedClasses.createButtonDiv
        },
        ...(disableDropDown && {
          '& div': {
            ...sharedClasses.createButtonDiv,
            paddingRight: '16px'
          }
        })
      }}
      data-testid={id}
      onClick={onClick}
    >
      <AddCircleIcon sx={sharedClasses.addIcon} />
      <Box>Create</Box>
      {!disableDropDown && (
        <ArrowDropDownIcon
          sx={{
            ...sharedClasses.dropdownIcon,
            transform: isOpen ? 'rotate(180deg)' : null
          }}
        />
      )}
    </Box>
  );
};

export default CreateButton;
