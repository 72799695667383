import { Box, Button, Collapse } from '@mui/material';
import React from 'react';
import { classes } from './styles';
import { TableActionDrawerAction } from './types';

export type DrawerActionItem = {
  id: string;
  label: string;
  icon: JSX.Element;
};

const ItemActionsDrawer = ({
  drawerOpen,
  dispatch,
  actions,
  label
}: {
  drawerOpen: boolean;
  dispatch: React.Dispatch<TableActionDrawerAction>;
  actions: DrawerActionItem[];
  label: string;
}) => {
  return (
    <Collapse in={drawerOpen}>
      <Box sx={classes.actionsContianer}>
        {actions.map((action) => (
          <Button
            key={action.id}
            id={`${action.id}-action-${label}-button`}
            variant="outlined"
            startIcon={action.icon}
            sx={classes.actionButton}
            onClick={() =>
              dispatch({
                type: 'SET_ACTION_MODAL_OPEN',
                payload: { name: action.id, isOpen: true }
              })
            }
          >
            {action.label}
          </Button>
        ))}
      </Box>
    </Collapse>
  );
};

export default ItemActionsDrawer;
