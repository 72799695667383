"use strict";
import queryString from "query-string";
class Api {
  constructor() {
    this.handleResponse = (response) => {
      if (response.ok) return response.json();
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    };
    this.get = (path, params = {}, headers = {}) => fetch(`${path}?${queryString.stringify(params, { arrayFormat: "bracket" })}`, {
      headers: this.getHeaders(headers)
    }).then((r) => this.handleResponse(r));
    this.post = (path, params = {}, headers = {}) => fetch(`${path}`, {
      headers: this.getHeaders(headers),
      method: "POST",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.put = (path, params = {}, headers = {}) => fetch(`${path}`, {
      headers: this.getHeaders(headers),
      method: "PUT",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.delete = (path, params = {}, headers = {}) => fetch(`${path}`, {
      headers: this.getHeaders(headers),
      method: "DELETE",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.custom_get = (path, params = {}, headers = {}) => fetch(`${path}${queryString.stringify(params, { arrayFormat: "bracket" })}`, {
      headers: this.getHeaders(headers)
    }).then((r) => this.handleResponse(r));
    this.getEventOptions = () => this.custom_get("/api/calendar/event_options");
    this.getJobApplications = (id, apiKey) => this.get(`/api/calendly/jobs/${id}/applications`);
    this.getEmailTemplates = () => this.get("/api/calendar/email_templates");
    this.getCalendlyEmailTemplates = () => this.get("/api/calendar/email_templates", { calendly: 1 });
    this.sendInvitations = (id, body) => this.post(`/api/calendly/jobs/${id}/invitations`, body);
    this.addToEvent = (id, body) => this.post(`/api/calendly/jobs/${id}/invitations/add_to_event`, body);
  }
  getHeaders(headers) {
    return Object.assign({}, {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "X-api-authenticate": localStorage.getItem("userKey")
    }, headers);
  }
}
export default new Api();
