"use strict";
import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    width: "100%",
    padding: "50px 60px",
    alignItems: "center",
    "& svg": {
      fontSize: 68,
      marginBottom: 20
    },
    "& h4": {
      fontSize: 32,
      color: "#666666",
      paddingBottom: 20,
      width: "100%",
      textAlign: "center",
      borderBottom: "1px solid #DDDDDD",
      marginTop: 0,
      marginBottom: 20
    }
  },
  text: {
    fontSize: 20,
    color: "#000",
    marginBottom: 40
  }
}));
