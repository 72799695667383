import React from "react";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export default function TableSkeleton() {

  const boxStyle = {width: '100%', padding: '20px 0px', borderTop: '1px solid #eaeaea'};

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box sx={boxStyle}>
        <Skeleton animation="wave" width="100%" height="40px" />
      </Box>
      <Box sx={boxStyle}>
        <Skeleton animation="wave" width="100%" height="40px" />
      </Box>
      <Box sx={boxStyle}>
        <Skeleton animation="wave" width="100%" height="40px" />
      </Box>
      <Box sx={boxStyle}>
        <Skeleton animation="wave" width="100%" height="40px" />
      </Box>
      <Box sx={boxStyle}>
        <Skeleton animation="wave" width="100%" height="40px" />
      </Box>
      <Box sx={boxStyle}>
        <Skeleton animation="wave" width="100%" height="40px" />
      </Box>
    </Box>
  )
}