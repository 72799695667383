import React, { Dispatch, useContext } from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { sharedClasses } from '../components/NewUI/Components/CustomUIElements/sharedClasses';
import { TableAction } from './types';
import { StickyLeftCell, StickyRightCell } from './StickyComponents';
import { ArrowRefsAndHoveredContext } from './ArrowRefsAndHoveredContext';

const parseColumnName = (columnName: string) => {
  const split = columnName.split(/_/);
  split.forEach((word, index) => {
    split[index] = word.charAt(0).toUpperCase() + word.substring(1);
  });
  return split.length > 1 ? split.join(' ') : split[0];
};

const handleSortDirection = (sort_order: string) => {
  return sort_order === 'asc' ? 'desc' : 'asc';
};

type Props = {
  state: {
    activeColumns: any;
    sort_by: string;
    sort_order: string;
    [key: string]: any;
  };
  dispatch: Dispatch<TableAction<any, any>>;
  pendoId?: string;
};

const TableHeader = ({ state, dispatch, pendoId }: Props) => {
  const { activeColumns, sort_by, sort_order } = state;
  const { leftBoundCellRef, rightBoundCellRef, tableHovered } = useContext(
    ArrowRefsAndHoveredContext
  );

  const activeFields = Object.entries(state.fieldOptions).filter(
    ([key, value]) => activeColumns[value.name].enabled
  );

  return (
    <TableHead>
      <TableRow sx={sharedClasses.tableHeaderRow}>
        {!state.hasEmptyHeaderFirstColumn && (
          <TableCell sx={{ minWidth: '10px', position: 'sticky', left: '0px' }}> </TableCell>
        )}
        {activeFields.map(([key, value]) => (
          <StickyLeftCell
            tableHovered={tableHovered}
            leftBoundRef={leftBoundCellRef}
            sticky={value.sticky}
            leftBound={value.leftBound}
            header={true}
            previousCellOffSet={value?.previousCellOffSet ? 0 : 50} // 50px offset from line 43 with min-width: 50px
            key={key}
          >
            <TableSortLabel
              id={`${pendoId}-sort-label-${value.name}`}
              active={sort_by === key}
              direction={sort_by === key ? (sort_order as 'asc' | 'desc') : 'asc'}
              onClick={() =>
                dispatch({
                  type: 'SET_SORTING',
                  payload: { sort_by: value.name, sort_order: handleSortDirection(sort_order) }
                })
              }
            >
              {parseColumnName(value.label)}
            </TableSortLabel>
          </StickyLeftCell>
        ))}
        <StickyRightCell
          tableHovered={tableHovered}
          header={true}
          rightBoundRef={rightBoundCellRef}
        />
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
