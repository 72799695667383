import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Skeleton,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NewAutoForward from './NewAutoForward';
import ImmediateChangesNotice from '../shared/ImmediateChangesNotice';
import SkeletonTable from '../../shared/SkeletonTable';
import { classes } from '../styles';
import { IStatusTransitionsProps, IAutoForwarders } from '../../types';
import { IApplicationStatus, IEmailTemplate } from '../../../Job/types';
import { immutableRemove } from '../../../Components/Utilities/immutableRemove';

export default function AutoForward({ apiKey, jobId, setSnackbar }: IStatusTransitionsProps) {
  const [loading, setLoading] = useState({
    autoForwarders: true,
    applicationStatuses: true,
    templatesList: true
  });
  const [isDeleting, setIsDeleting] = useState(-1);
  const [autoForwarders, setAutoForwarders] = useState<IAutoForwarders[]>([]);
  const [applicationStatuses, setApplicationStatuses] = useState<IApplicationStatus[]>([]);
  const [templates, setTemplates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [forwardIndex, setForwardIndex] = useState(0);

  const getAutoForwarders = useCallback(async () => {
    setLoading((prev) => ({ ...prev, autoForwarders: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_forwarders`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { auto_forwarders } = await response.json();
      setAutoForwarders(auto_forwarders);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching auto forwarders',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, autoForwarders: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getApplicationStatuses = useCallback(async () => {
    setLoading((prev) => ({ ...prev, applicationStatuses: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/job_application_statuses`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { job_application_statuses } = await response.json();
      setApplicationStatuses(job_application_statuses);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching status transitions',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, applicationStatuses: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getTemplatesAndPlaceholders = useCallback(async () => {
    setLoading((prev) => ({ ...prev, templatesList: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/email_templates`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((res) => res.json());
      setTemplates(
        response.email_templates.map(
          (template: IEmailTemplate) => `${template.id} - ${template.name}`
        )
      );
    } catch (error) {
      setSnackbar({
        message: 'Error fetching templates',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, templatesList: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const removeAutoForwarder = async (index: number) => {
    setIsDeleting(index);
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/auto_forwarders/${autoForwarders[index].id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-api-authenticate': apiKey
          }
        }
      );
      if (response.ok) {
        setAutoForwarders(immutableRemove(autoForwarders, index));
        setSnackbar({
          message: 'Auto forwarder deleted successfully',
          state: 'success'
        });
      } else {
        throw new Error('Error deleting auto forwarder');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error removing auto forwarder',
        state: 'error'
      });
    } finally {
      setIsDeleting(-1);
    }
  };

  const handleOpenModal = (index: number) => {
    setForwardIndex(index);
    setModalOpen(true);
  };

  useEffect(() => {
    getAutoForwarders();
    getApplicationStatuses();
    getTemplatesAndPlaceholders();
  }, [getApplicationStatuses, getAutoForwarders, getTemplatesAndPlaceholders]);

  return (
    <Box sx={classes.automationContainer}>
      <Box sx={classes.titleAndButtonContainer}>
        <h2>Auto forward</h2>
        <Button
          data-testid="add-auto-forwarding-button"
          sx={classes.newButton}
          onClick={() => handleOpenModal(-1)}
        >
          New auto forwarding config
        </Button>
      </Box>
      <ImmediateChangesNotice />
      {loading.autoForwarders || autoForwarders.length > 0 ? (
        <TableContainer sx={classes.tableContainer}>
          <Table sx={classes.table} aria-label="auto forwarders table">
            <TableHead>
              <TableRow>
                <TableCell>Trigger Status</TableCell>
                <TableCell>Recipient Emails</TableCell>
                <TableCell align="right">{/*Action column*/}</TableCell>
              </TableRow>
            </TableHead>
            {loading.autoForwarders || loading.applicationStatuses || loading.templatesList
              ? <SkeletonTable rows={5} cols={3} />
              : (
                <TableBody>
                  {autoForwarders.map((forwarder, index) => (
                    <TableRow key={forwarder.id}>
                      <TableCell>
                        {applicationStatuses.find(
                          (status) => status.id === forwarder.trigger_status_id
                        )?.name ?? '-'}
                      </TableCell>
                      <TableCell>{forwarder.recipient_emails}</TableCell>
                      <TableCell sx={classes.tableActions}>
                        <IconButton
                          id="auto-forward-edit-button"
                          data-testid="auto-forward-edit-button"
                          sx={classes.tableEditButton}
                          onClick={() => handleOpenModal(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          id="auto-forward-delete-button"
                          data-testid="auto-forward-delete-button"
                          sx={classes.tableDeleteButton}
                          onClick={() => removeAutoForwarder(index)}
                        >
                          {isDeleting === index ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            <DeleteIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
          </Table>
        </TableContainer>
      ) : (
        <Box sx={classes.noAutoForwarders}>
          No auto forwarders configured. Click on the button above to create one.
        </Box>
      )}
      {!loading.autoForwarders && !loading.applicationStatuses && (
        <NewAutoForward
          jobId={jobId}
          apiKey={apiKey}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          autoForwarders={autoForwarders}
          setAutoForwarders={setAutoForwarders}
          applicationStatuses={applicationStatuses || []}
          emailTemplates={templates}
          forwardIndex={modalOpen ? forwardIndex : -1}
          isLoadingTemplateList={loading.templatesList}
          setSnackbar={setSnackbar}
        />
      )}
    </Box>
  );
}
