import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  modalRoot: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    position: "relative",
    maxHeight: 630,
    minHeight: 630,
    height: 630,
    width: 720,
    transition: "width 0.3s cubic-bezier(.65,.37,.25,1)",
    maxWidth: "none"
  },
  modalLarge: {
    width: 960
  }
}));
