import React, { Dispatch, useRef, useState } from 'react';
import { Box } from '@mui/material';
import moment, { Moment } from 'moment';
import { sharedClasses } from '../../CustomUIElements/sharedClasses';
import DayPicker, { DateUtils } from 'react-day-picker';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

type DateRange = { from: Date | null; to: Date | null };
export type TempDateRange = { from: Moment | null; to: Moment | null };

export type DateRangeAction =
  | {
      type: 'SET_DATE_RANGE_TEMP_FILTER';
      payload: DateRange;
    }
  | {
      type: 'SET_DATE_FIELD_RANGE';
      payload: TempDateRange;
    };

const MIN_DATE = moment('01/01/1990', 'DD/MM/YYYY');

const DateRangeFilter = ({
  dispatch,
  dateRange,
  dateRangeTemp,
  label
}: {
  dispatch: Dispatch<DateRangeAction>;
  dateRange: DateRange;
  dateRangeTemp: TempDateRange;
  label: string;
}) => {
  const dateRangeInputsRefs = useRef<{
    from: HTMLInputElement | null;
    to: HTMLInputElement | null;
  }>({ from: null, to: null });

  // Changes the date range when a day is clicked on the calendar
  const handlePublishedDayClick = (day: Date) => {
    let range = DateUtils.addDayToRange(day, dateRange);
    if (range.from && range.to && range.from > range.to) range = { from: range.to, to: range.from };
    // Add the new range to the temp filter
    dispatch({ type: 'SET_DATE_RANGE_TEMP_FILTER', payload: range as DateRange });
    // Update the text input date fields
    dispatch({
      type: 'SET_DATE_FIELD_RANGE',
      payload: { from: moment(range.from), to: moment(range.to) }
    });
  };

  // Swaps the to/from dates and field focus if the from date is after the to date
  const handleDateOrder = (dateRange: { from: Date | null; to: Date | null }) => {
    if (moment(dateRange.from).isAfter(moment(dateRange.to))) {
      dispatch({
        type: 'SET_DATE_FIELD_RANGE',
        payload: { from: moment(dateRange.to), to: moment(dateRange.from) }
      });
      if (dateRangeInputsRefs.current.from === document.activeElement) {
        dateRangeInputsRefs.current.to?.focus();
      } else {
        dateRangeInputsRefs.current.from?.focus();
      }
      return { from: dateRange.to, to: dateRange.from };
    }
    return dateRange;
  };

  // Changes the date range when the date field is edited
  const handleDateFieldChange = (date: Moment | null, type: 'from' | 'to') => {
    if (type === 'from') {
      dispatch({
        type: 'SET_DATE_FIELD_RANGE',
        payload: { from: date, to: dateRangeTemp.to }
      });
      date?.isValid() &&
        date.isAfter(MIN_DATE) &&
        dispatch({
          type: 'SET_DATE_RANGE_TEMP_FILTER',
          payload: handleDateOrder({ from: date.toDate(), to: dateRangeTemp.to?.toDate() || null })
        });
    } else {
      dispatch({
        type: 'SET_DATE_FIELD_RANGE',
        payload: { from: dateRangeTemp.from, to: date }
      });
      date?.isValid() &&
        date.isAfter(MIN_DATE) &&
        dispatch({
          type: 'SET_DATE_RANGE_TEMP_FILTER',
          payload: handleDateOrder({
            from: dateRangeTemp.from?.toDate() || null,
            to: date.toDate()
          })
        });
    }
  };
  return (
    <Box
      sx={{
        ...sharedClasses.datePickerCalendar,
        ...(dateRange.from && dateRange.to === null ? sharedClasses.onlyStartDate : {})
      }}
      id={`${label}date-range-filter`}
    >
      <DayPicker
        onDayClick={handlePublishedDayClick}
        selectedDays={[dateRange.from || undefined, dateRange]}
        modifiers={{
          start: dateRange.from || undefined,
          end: dateRange.to || undefined,
          first: { daysOfWeek: [0] },
          last: { daysOfWeek: [6] }
        }}
        modifiersStyles={{
          first: {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%'
          },
          last: {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%'
          }
        }}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box sx={datefieldsContainerClass}>
          <DateField
            value={dateRangeTemp.from}
            onChange={(value) => handleDateFieldChange(value, 'from')}
            format="DD/MM/YYYY"
            inputProps={{ sx: dateFieldClass }}
            minDate={MIN_DATE}
            inputRef={(ref) => (dateRangeInputsRefs.current.from = ref)}
          />
          <span>to</span>
          <DateField
            value={dateRangeTemp.to}
            onChange={(value) => handleDateFieldChange(value, 'to')}
            format="DD/MM/YYYY"
            inputProps={{ sx: dateFieldClass }}
            minDate={MIN_DATE}
            inputRef={(ref) => (dateRangeInputsRefs.current.to = ref)}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

const dateFieldClass = {
  padding: '8px 0px 8px 0px',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Source Sans Pro'
};

const datefieldsContainerClass = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '8px',
  padding: '12px 2px 6px 2px',
  fontSize: '14px',
  fontWeight: 600,
  fontFamily: 'Source Sans Pro',
  '& span': {
    color: '#084D6D'
  }
};

export default DateRangeFilter;
