"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { candidatesTableColumn } from "./config";
export const InitialCandidatesState = {
  snackbar: {
    message: "",
    state: "success"
  },
  candidatesTableState: {
    canScroll: [false, true],
    tableHovered: false,
    density: "Default",
    columns: candidatesTableColumn,
    selectedItemIds: [],
    selectedItems: [],
    actions: {
      selectedItem: null,
      anchorEl: null,
      isOpen: {
        delete: false,
        edit: false
      }
    },
    modalsOpen: { edit: false }
  },
  candidatesTableSettings: {
    page: Number(sessionStorage.getItem(`candidatesTablePage`)) || 1,
    limit: 10,
    sort: "firstname",
    sort_order: "asc"
  },
  searchQuery: ""
};
export const CandidatesReducer = (state, action) => {
  switch (action.type) {
    case "SET_SNACKBAR":
      return __spreadProps(__spreadValues({}, state), { snackbar: action.payload });
    case "SET_SEARCH_QUERY":
      return __spreadProps(__spreadValues({}, state), {
        candidatesTableSettings: __spreadProps(__spreadValues({}, state.candidatesTableSettings), { page: 1 }),
        searchQuery: action.payload
      });
    case "SET_DENSITY":
      return __spreadProps(__spreadValues({}, state), {
        candidatesTableState: __spreadProps(__spreadValues({}, state.candidatesTableState), {
          density: action.payload
        })
      });
    case "SET_CAN_SCROLL":
      return __spreadProps(__spreadValues({}, state), {
        candidatesTableState: __spreadProps(__spreadValues({}, state.candidatesTableState), {
          canScroll: action.payload
        })
      });
    case "SET_TABLE_HOVERED":
      return __spreadProps(__spreadValues({}, state), {
        candidatesTableState: __spreadProps(__spreadValues({}, state.candidatesTableState), {
          tableHovered: action.payload
        })
      });
    case "SET_SORT":
      return __spreadProps(__spreadValues({}, state), {
        candidatesTableSettings: __spreadProps(__spreadValues({}, state.candidatesTableSettings), {
          sort: action.payload.sortBy,
          sort_order: action.payload.sortOrder
        })
      });
    case "SET_PAGE":
      return __spreadProps(__spreadValues({}, state), {
        candidatesTableSettings: __spreadProps(__spreadValues({}, state.candidatesTableSettings), {
          page: action.payload
        })
      });
    case "SET_LIMIT":
      return __spreadProps(__spreadValues({}, state), {
        candidatesTableSettings: __spreadProps(__spreadValues({}, state.candidatesTableSettings), {
          limit: action.payload
        })
      });
    case "SELECT_ACTION_ITEM":
      return __spreadProps(__spreadValues({}, state), {
        candidatesTableState: __spreadProps(__spreadValues({}, state.candidatesTableState), {
          actions: __spreadProps(__spreadValues({}, state.candidatesTableState.actions), {
            selectedItem: action.payload.item,
            anchorEl: action.payload.anchorEl,
            isOpen: __spreadProps(__spreadValues({}, state.candidatesTableState.actions.isOpen), {
              [action.payload.name]: true
            })
          })
        })
      });
    case "CLOSE_ACTION_ITEM":
      return __spreadProps(__spreadValues({}, state), {
        candidatesTableState: __spreadProps(__spreadValues({}, state.candidatesTableState), {
          actions: __spreadProps(__spreadValues({}, state.candidatesTableState.actions), {
            selectedItem: null,
            isOpen: __spreadProps(__spreadValues({}, state.candidatesTableState.actions.isOpen), {
              [action.payload]: false
            }),
            anchorEl: null
          })
        })
      });
    default:
      return state;
  }
};
