import React from 'react';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { classes } from './styles';
import { sharedClasses } from '../NewUI/Components/CustomUIElements/sharedClasses';
import DateDisplay from './CreateEvent/EventProgress/DateDisplay';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { ScoutCalendarState } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export default function EventCreated({
  open,
  onClose,
  ScoutCalendarState,
  userTimezone
}: {
  open: boolean;
  onClose: () => void;
  ScoutCalendarState: ScoutCalendarState;
  userTimezone: string;
}) {
  const { createEventObject } = ScoutCalendarState;
  const sessions = Array.from({ length: Number(createEventObject.sessions) }, (_, i) =>
    dayjs
      .tz(createEventObject.eventDate, userTimezone)
      .add(i * (Number(createEventObject.duration) + Number(createEventObject.breaks)), 'm')
      .toDate()
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack sx={classes.eventCreatedModalContainer}>
        <CheckCircleIcon sx={{ color: '#5BC4BF', fontSize: '4rem', marginTop: 4 }} />
        <Typography sx={classes.eventCreatedModalTitle}>Event Created</Typography>
        <Stack sx={{ alignItems: 'center', rowGap: 1, color: '#333333' }}>
          <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
            {createEventObject.eventName}
          </Typography>
          <DateDisplay dates={createEventObject.dates} userTimezone={userTimezone} />
          <Stack sx={classes.eventSessionsContainer}>
            {sessions.map((session, index) => (
              <Typography sx={{ textDecoration: 'underline' }} key={index}>
                {dayjs.tz(session, userTimezone).format('h:mm a')} -{' '}
                {dayjs
                  .tz(session, userTimezone)
                  .add(Number(createEventObject.duration), 'm')
                  .format('h:mm a')}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Button
          variant="contained"
          sx={{ ...sharedClasses.genericButton, margin: '16px 0px' }}
          onClick={onClose}
        >
          Exit
        </Button>
      </Stack>
    </Dialog>
  );
}
