import React, { useState } from 'react';
import {
  Box,
  Button,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextareaAutosize,
  Typography,
  FormControl,
  Dialog,
  FormLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import { DialogProps } from './types';

const XrefDialogue = (props: DialogProps) => {
  const { open, handleClose } = props;
  const [resolution, setResolution] = useState('0');
  const [comments, setComments] = useState('');
  const { referee, reference_id } = props;

  const sendInfo = () => {
    fetch(props.resolution_endpoint, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ resolution, reference_id, referee, note: comments })
    })
      .then((response) => response.json())
      .then((data) => {
        props.setSnackbarState({
          message: 'Successfully Updated Unusual Activity',
          state: 'success'
        });
      })
      .catch((error) => {
        props.setSnackbarState({ message: 'Failed To Update Unusual Activity', state: 'error' });
      });
    handleClose();
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResolution(event.target.value);
  };

  const handleCommentsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments(event.target.value);
  };

  const renderFindings = (index: number, key: string, value: string | boolean) => {
    if (value) {
      return (
        <Stack key={index} flexDirection="row" columnGap="16px">
          <FlagIcon />
          <Typography variant="body2">{findingDescriptions(key)}</Typography>
        </Stack>
      );
    }
  };

  const findingDescriptions = (key: string) => {
    switch (key) {
      case 'flag_ip_address':
        return 'Suspicious IP Address Detected: As an example, from same IP Address from the candidate';
      case 'flag_email_address':
        return 'Suspicious Email Address Detected: As an example, from a personal email address';
      case 'flag_browser':
        return 'Suspicious Browser Detected: As an example, from a browser that is not Chrome, Firefox, or Safari';
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          width: '400px',
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <DialogTitle>Unusual Activity</DialogTitle>
        <Box sx={{ margin: 1, width: '100%' }}>
          <Typography sx={{ textTransform: 'capitalize' }}>Status: {props.ua?.status}</Typography>
          {props.ua?.findings &&
            Object.entries(props.ua.findings).map(([key, value], index) =>
              renderFindings(index, key, value)
            )}
        </Box>
        <FormControl sx={{ width: '100%' }}>
          <FormLabel>Verify This Activity</FormLabel>
          <RadioGroup defaultValue="0" name="radio-buttons-group" onChange={handleRadioChange}>
            <FormControlLabel value="0" control={<Radio />} label="Valid" />
            <FormControlLabel value="1" control={<Radio />} label="Suspicious" />
          </RadioGroup>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            style={{ width: '100%' }}
            placeholder="Comments"
            onChange={handleCommentsChange}
          />

          <Stack sx={{ columnGap: 1, width: '100%', paddingTop: 1, flexDirection: 'row' }}>
            <Button color="primary" onClick={sendInfo} variant="outlined">
              Submit
            </Button>
            <Button color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </FormControl>
      </Box>
    </Dialog>
  );
};

export default XrefDialogue;
