import React from 'react';
import PropTypes from 'prop-types';
import SearchResultsHeaderRow from './header_row';

export default class SearchResultsHeader extends React.Component {
  static propTypes = {
    onSorting: PropTypes.func
  };

  render() {
    return (
      <div className="header">
        <div className="sorting row">
          {SearchResultsHeader.rows.map((row, key) => (
            <SearchResultsHeaderRow row={row} key={key} onSorting={this.props.onSorting} />
          ))}
        </div>
      </div>
    );
  }
}

SearchResultsHeader.rows = [
  { id: 'id', name: 'ID' },
  { id: 'candidate', name: 'Candidate' },
  { id: 'reference', name: 'Reference' },
  { id: 'job', name: 'Job' },
  { id: 'post_code', name: 'Postcode' },
  { id: 'location', name: 'Location' },
  { id: 'created_at', name: 'Created At' },
  { id: 'rating', name: 'Rating' },
  { id: 'status', name: 'Status' }
];
