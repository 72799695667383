import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box } from '@mui/material';
import { classes } from '../styles';
import InfoIcon from '@mui/icons-material/Info';
import Lottie from 'react-lottie';
import felixLoading from '../Graphics/felix-loading.json';
import { generateLoadingText } from '../../AIStudioModal/config';

const LoadingResponse = ({
  generationType,
  showSocketMessage,
  customMessages
}: {
  generationType: string;
  showSocketMessage?: boolean;
  customMessages?: string[];
}) => {
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);
  const changeTextIndex = useRef<ReturnType<typeof setTimeout> | null>(null);

  const loadingAnimation = {
    loop: true,
    autoplay: true,
    animationData: felixLoading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const loadingText = useMemo(() => {
    if (generationType === 'custom' && customMessages) {
      return customMessages;
    }
    return generateLoadingText(generationType);
  }, [customMessages, generationType]);

  useEffect(() => {
    changeTextIndex.current = setTimeout(() => {
      if (loadingTextIndex < loadingText.length - 1) {
        setLoadingTextIndex((prev) => prev + 1);
      } else {
        setLoadingTextIndex(0);
      }
    }, 5000);
    return () => {
      if (changeTextIndex.current) {
        clearTimeout(changeTextIndex.current);
      }
    };
  }, [loadingText.length, loadingTextIndex]);

  return (
    <Box sx={classes.loadingContainer}>
      <Lottie options={loadingAnimation} height={500} width={220} />
      <Box sx={classes.loadingMessage}>
        {loadingText[loadingTextIndex as keyof typeof loadingText]}
      </Box>
      <Box sx={{ ...classes.socketsMessage, ...(showSocketMessage && { opacity: '1px' }) }}>
        <InfoIcon sx={classes.socketsInfoIcon} />
        <span>
          You can close this at anytime and Felix will let you know when your content has been
          generated.
        </span>
      </Box>
    </Box>
  );
};

export default LoadingResponse;
