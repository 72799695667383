import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { classes } from '../styles';
import { widgetsbarWidths } from '../../Widgetsbar/config';

const ShowRightMenu = ({
  widgetsbarState,
  setWidgetsbarState
}: {
  widgetsbarState: keyof typeof widgetsbarWidths;
  setWidgetsbarState: Dispatch<SetStateAction<keyof typeof widgetsbarWidths>>;
}) => {
  const handleWidgetsbarStateChange = (state: typeof widgetsbarState) => {
    localStorage.setItem('widgetsbarState', state);
    setWidgetsbarState(state);
  };
  return (
    !window.location.href.includes('search/advanced') && (
      <Box
        sx={
          widgetsbarState === 'expanded' ? classes.showButtonContainer : classes.hideButtonContainer
        }
        onClick={() => {
          handleWidgetsbarStateChange(widgetsbarState === 'expanded' ? 'collapsed' : 'expanded');
          const event = new Event('onWidgetBarChange');
          window.dispatchEvent(event);
        }}
      >
        {widgetsbarState === 'expanded' ? (
          <CloseIcon id="close-icon-sidebar" sx={{ fontSize: '26px' }} />
        ) : (
          <MenuIcon id="sandwich-icon-sidebar" sx={{ fontSize: '26px' }} />
        )}
      </Box>
    )
  );
};

export default ShowRightMenu;
