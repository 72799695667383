"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export const dismissNotification = (historyId, onError) => __async(void 0, null, function* () {
  try {
    yield fetch(`/api/v4/ai_generation_histories/${historyId}/dismiss`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" }
    });
  } catch (error) {
    onError ? onError() : console.error(error);
  }
});
