import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { theme } from '../../../ThemeContext/ThemeObject';
import { sharedClasses } from './sharedClasses';
import CircleIcon from '@mui/icons-material/Circle';

interface IFormSelectWithIconFieldProps {
  label?: string;
  options: string[] | { value: string; label: string; color?: string }[];
  defaultValue?: string;
  onChange: (value: string | number) => void;
  id?: string;
  innerRef?: React.RefObject<HTMLInputElement> | ((el: HTMLDivElement) => void);
  styles?: React.CSSProperties | Record<string, React.CSSProperties>;
  error?: string;
  required?: boolean;
  isDisabled?: boolean;
}

const SelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: '24px'
  },
  '& .MuiInputBase-input': {
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    color: '#333333',
    border: '1px solid #DDDDDD',
    fontSize: '16px',
    padding: '6px 10px',
    margin: '3px',
    fontFamily: 'Source Sans Pro, sans-serif',
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  '& .MuiInputBase-input:focus': {
    backgroundColor: '#ffffff',
    borderRadius: '6px'
  },
  '& .MuiSelect-icon': {
    color: '#AAAAAA !important',
    marginRight: '10px',
    transition: 'all 0.16s ease-in-out'
  }
}));

const menuItemStyle = {
  fontFamily: 'Source Sans Pro, sans-serif',
  color: '#333333',
  maxHeight: '200px'
};

export default function FormSelectWithIconField({
  label,
  options,
  defaultValue,
  onChange,
  id,
  innerRef,
  styles,
  error,
  required,
  isDisabled
}: IFormSelectWithIconFieldProps) {
  const [value, setValue] = useState(defaultValue ?? '');

  const handleChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl variant="standard" sx={{ position: 'relative' }} ref={innerRef}>
      {label && (
        <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
          {label}
          {required && <span>*</span>}
        </InputLabel>
      )}
      <Select
        sx={styles}
        value={value}
        id={id}
        onChange={handleChange}
        input={<SelectInput />}
        disabled={isDisabled || false}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 500,
            }
          }
        }}
      >
        {options?.map((option, index) =>
          typeof option === 'string' ? (
            <MenuItem key={index} value={option} sx={menuItemStyle}>
              {option}
            </MenuItem>
          ) : (
            <MenuItem key={index} value={option.value} sx={menuItemStyle}>
              {option.label}
              {option.color && <CircleIcon sx={{marginLeft: '10px', fontSize: '12px', color: option.color}}/>}
            </MenuItem>
          )
        )}
      </Select>
      {error && <Box sx={sharedClasses.errorBox}>{error}</Box>}
    </FormControl>
  );
}
