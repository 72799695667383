// eslint-disable-next-line react/no-deprecated
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Authentication from '../../../tableau/authentication';
import '../../../tableau/tableau-2.5.0.min.js';

export default class Report extends Component {
  state = {
    error: null,
    showToolbar: false,
  };

  vizRef = {};

  static propTypes = {
    options: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
    entityId: PropTypes.number.isRequired,
    country: PropTypes.string.isRequired,
  };

  loadReport = async (element) => {
    const { url, options, entityId, country } = this.props;
    this.ticket = await Authentication.run().catch(this.catchError);
    this.vizRef = new window.tableau.Viz(
      element,
      `${url}/trusted/${this.ticket}/views/scouttalent/${options.key}`,
      {
        hideTabs: true,
        hideToolbar: true,
        top_parent_entity_id: entityId,
        country: country,
      }
    );
    this.setState({ showToolbar: true });
  };

  catchError = ({error}) => this.setState({ error });

  render () {
    const { error } = this.state;

    if (error) return (
      <h2
        className="Error"
        style={{ color: 'red' }}
      >
        {error}
      </h2>
    )

    return (
      <div className="report-container">
        <div ref={this.loadReport} />
        {this.state.showToolbar && (
          <div className="report-toolbar">
            <div
              className="report-toolbar-button"
              onClick={() => this.vizRef.showExportImageDialog()}
            >
              <i className="material-icons">image</i>
              &nbsp;Download Image
            </div>
          </div>
        )}
      </div>
    );
  }
}
