import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import NoEmailStatusSVG from '../EmailStatus/NoEmailStatusSVG';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import Statistics from './Widgets/Statistics';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import { IJobEmailStatus } from '../types';
import moment from 'moment';
import Api from '../API';
import { classes } from './styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmailPreviewModal from './EmailPreviewModal';
import StatusTypeIcon from './StatusTypeIcon';
import { StyledSnackbarProps } from '../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import Chip from '@mui/material/Chip';
import GenericDialog from '../../Components/Modals/GenericDialog';

export default function EmailStatus({
  apiKey,
  jobId,
  setSnackbarState
}: {
  apiKey: string;
  jobId: number;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
}) {
  const [emailStatuses, setEmailStatuses] = useState(null);
  const [totalEmailStatuses, setTotalEmailStatuses] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isCancelling, setIsCancelling] = useState([]);
  const [hoveredScheduleButtons, setHoveredScheduleButtons] = useState(
    new Array(emailStatuses?.length || 0).fill(false)
  );
  const [unscheduleIndex, setUnscheduleIndex] = useState(-1);
  const [unscheduleDialogOpen, setUnscheduleDialogOpen] = useState(false);
  const [unscheduledDetailsIsHovered, setUnscheduledDetailsIsHovered] = useState(
    new Array(emailStatuses?.length || 0).fill(false)
  );
  const [selectedEmailStatusId, setSelectedEmailStatusId] = useState(null);

  const fetchInitialEmailStatuses = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await Api.getEmailStatuses({ 'X-api-authenticate': apiKey }, jobId, {});
      setEmailStatuses(response.data.job_email_statuses);
      setTotalEmailStatuses(response.totalItems);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, jobId]);

  const fetchEmailStatuses = async (page: number, rows: number) => {
    setIsLoading(true);
    try {
      const response = await Api.getEmailStatuses({ 'X-api-authenticate': apiKey }, jobId, {
        page: page,
        limit: rows
      });
      setEmailStatuses(response.data.job_email_statuses);
      setTotalEmailStatuses(response.totalItems);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowsPerPageChange = (selection: number) => {
    if (selection === rowsPerPage) return;
    setRowsPerPage(selection);
    fetchEmailStatuses(currentPage, selection);
  };

  const handlePageChange = (event: React.ChangeEvent, newPage: number) => {
    if (newPage === currentPage) return;
    setCurrentPage(newPage);
    fetchEmailStatuses(newPage, rowsPerPage);
  };

  const handleOpen = (id: number) => {
    if (!open.includes(id)) {
      const copy = [...open];
      copy.push(id);
      setOpen(copy);
    }
    const flag = open.indexOf(id);
    if (flag > -1) {
      const copy = [...open];
      copy.splice(flag, 1);
      setOpen(copy);
    }
  };
  const cancelEmail = async (emailStatusId: number) => {
    try {
      await Api.cancelEmailBatch(jobId, emailStatusId);
    } catch (error) {
      setSnackbarState({
        message: 'Error cancelling email, please try again',
        state: 'error'
      });
    }
  };

  const handleCancel = async (emailStatusId: number) => {
    if (unscheduleIndex !== -1) {
      const index = emailStatuses.findIndex((emailStatus) => emailStatus.id === emailStatusId);
      const newIsCancelling = [...isCancelling];
      newIsCancelling[index] = true;
      setIsCancelling(newIsCancelling);
      try {
        await cancelEmail(emailStatusId);
      } catch (error) {
        setSnackbarState({
          message: 'Error cancelling email, please try again',
          state: 'error'
        });
      } finally {
        const response = await Api.getEmailStatuses({ 'X-api-authenticate': apiKey }, jobId, {});
        setEmailStatuses(response.data.job_email_statuses);
        setTotalEmailStatuses(response.totalItems);
        setTotalPages(response.totalPages);
        const resetIsCancelling = [...isCancelling];
        resetIsCancelling[index] = false;
        setIsCancelling(resetIsCancelling);
      }
      setUnscheduleIndex(-1);
    } else {
      handleUnscheduleDialogOpen(emailStatusId);
    }
  };

  const handlePreview = (emailStatus: IJobEmailStatus) => {
    setIsDialogOpen(true);
    setPreviewId(emailStatus.id);
    setSubject(emailStatus.email_template?.subject);
    setBody(emailStatus.email_template?.body);
  };

  const statusPastTense = (name: string) => {
    switch (name) {
      case 'open':
        return 'opened';
      case 'click':
        return 'clicked';
      case 'bounce':
        return 'bounced';
      case 'unsubscribe':
        return 'unsubscribed';
      case 'deliver':
        return 'delivered';
      default:
        return name;
    }
  };
  const handleUnscheduleDialogOpen = (emailStatusId: number) => {
    setSelectedEmailStatusId(emailStatusId);
    setUnscheduleIndex(emailStatusId);
    setIsDialogOpen(true);
  };
  useEffect(() => {
    fetchInitialEmailStatuses();
  }, [fetchInitialEmailStatuses]);

  return (
    <>
      {emailStatuses && emailStatuses.length > 0 && (
        <>
          <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
            <Table sx={classes.tableWrapper} aria-label="job email status">
              <TableHead>
                <TableRow style={{ borderBottom: '1px solid #f3f3f3' }}>
                  <TableCell></TableCell>
                  <TableCell>Email Template</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Sent From</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>Applications</TableCell>
                  <TableCell sx={classes.center}>State</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {emailStatuses &&
                  !isLoading &&
                  emailStatuses.map((emailStatus: IJobEmailStatus, index: number) => (
                    <>
                      <TableRow key={emailStatus.id}>
                        <TableCell style={{ padding: '24px 0px 12px 12px' }}>
                          <VisibilityIcon
                            sx={classes.preview}
                            onClick={() => handlePreview(emailStatus)}
                          />
                        </TableCell>
                        <TableCell>
                          <Box sx={classes.title}>{emailStatus.email_template?.name || '-'}</Box>
                        </TableCell>
                        <TableCell>
                          <Box>{`${emailStatus.created_by.firstname} ${emailStatus.created_by.lastname}`}</Box>
                        </TableCell>
                        <TableCell>
                          {moment(emailStatus.created_at).format('D MMMM YYYY, h:mm A')}
                        </TableCell>
                        <TableCell>{emailStatus.from}</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                          {emailStatus.applications_count}
                        </TableCell>
                        <TableCell sx={classes.center}>
                          {emailStatus.state == 'Sent' ? (
                            <Box
                              sx={{
                                ...classes.state,
                                backgroundColor: '#8CCF951F',
                                color: '#8CCF95'
                              }}
                            >
                              {emailStatus.state}
                            </Box>
                          ) : emailStatus.canceled_at ? (
                            <Box
                              sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
                            >
                              <Box
                                sx={{ ...classes.unscheduledEmailDetails }}
                                onMouseEnter={() => {
                                  const newHoveredRows = [...unscheduledDetailsIsHovered];
                                  newHoveredRows[index] = true;
                                  setUnscheduledDetailsIsHovered(newHoveredRows);
                                }}
                                onMouseLeave={() => {
                                  const newHoveredRows = [...unscheduledDetailsIsHovered];
                                  newHoveredRows[index] = false;
                                  setUnscheduledDetailsIsHovered(newHoveredRows);
                                }}
                              >
                                Unscheduled
                              </Box>
                              <Box
                                sx={{
                                  display: unscheduledDetailsIsHovered[index] ? 'flex' : 'none',
                                  flexDirection: 'column',
                                  transform: 'translateY(4px)',
                                  ...classes.unsheduledDetailsPopover
                                }}
                              >
                                <Box sx={classes.unsheduledDetailsLabel}>Unscheduled by:</Box>
                                <Box sx={classes.unsheduledDetailsInfo}>
                                  {emailStatus.canceled_by.firstname}{' '}
                                  {emailStatus.canceled_by.lastname}
                                </Box>
                                <Box sx={classes.unsheduledDetailsLabel}>Unscheduled at:</Box>
                                <Box sx={classes.unsheduledDetailsInfo}>
                                  {moment(emailStatus.canceled_at).format('D MMMM YYYY, h:mm A')}
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  handleCancel(emailStatus.id);
                                  setUnscheduleDialogOpen(true);
                                }}
                                disabled={isCancelling[index]}
                                sx={{ ...classes.cancelEmailButton }}
                                onMouseEnter={() => {
                                  const newHoveredScheduleButton = [...hoveredScheduleButtons];
                                  newHoveredScheduleButton[index] = true;
                                  setHoveredScheduleButtons(newHoveredScheduleButton);
                                }}
                                onMouseLeave={() => {
                                  const newHoveredScheduleButtons = [...hoveredScheduleButtons];
                                  newHoveredScheduleButtons[index] = false;
                                  setHoveredScheduleButtons(newHoveredScheduleButtons);
                                }}
                              >
                                {isCancelling[index]
                                  ? 'Cancelling...'
                                  : hoveredScheduleButtons[index]
                                    ? 'UnSchedule'
                                    : 'Scheduled'}
                              </Button>
                            </Box>
                          )}
                        </TableCell>
                        <TableCell>
                          <Box sx={classes.details} onClick={() => handleOpen(emailStatus.id)}>
                            <Box sx={classes.center}>More details</Box>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              disableFocusRipple={true}
                              disableRipple={true}
                            >
                              {open.includes(emailStatus.id) ? (
                                <KeyboardArrowDownIcon />
                              ) : (
                                <KeyboardArrowRightIcon />
                              )}
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ borderBottom: '1px solid #f3f3f3' }}>
                        <TableCell style={{ paddingBottom: '12px', paddingTop: '0px' }} colSpan={8}>
                          <Collapse in={open.includes(emailStatus.id)} timeout="auto" unmountOnExit>
                            {emailStatus.filters && (
                              <Box sx={classes.chipContainer} id="applied-filter-chips">
                                <Chip
                                  sx={classes.filterLabel}
                                  label="Filters Applied"
                                  variant="outlined"
                                />
                                {Object.entries(emailStatus.filters).map((filter) => {
                                  const [key, value] = filter;
                                  const newKey = key == 'withoutRating' ? 'without rating' : key;
                                  const newValue =
                                    key == 'rating' ? `${value[0]}-${value[1]}` : value;
                                  const label = `${newKey}: ${newValue}`;
                                  return (
                                    <div>
                                      {value !== '' && (
                                        <Chip
                                          key={key}
                                          sx={classes.chipItem}
                                          label={label}
                                          variant="outlined"
                                        />
                                      )}
                                    </div>
                                  );
                                })}
                              </Box>
                            )}
                            <Box>
                              <Box sx={classes.widgetContainer}>
                                <Statistics
                                  bounced={emailStatus.statistics.bounced}
                                  bounced_pcent={emailStatus.statistics.bounced_pcent}
                                  clicks={emailStatus.statistics.clicks}
                                  clicks_pcent={emailStatus.statistics.clicks_pcent}
                                  delivered={emailStatus.statistics.delivered}
                                  delivered_pcent={emailStatus.statistics.delivered_pcent}
                                  opened={emailStatus.statistics.opened}
                                  opened_pcent={emailStatus.statistics.opened_pcent}
                                  unsubscribed={emailStatus.statistics.unsubscribed}
                                  unsubscribed_pcent={emailStatus.statistics.unsubscribed_pcent}
                                  isLoading={isLoading}
                                />
                              </Box>
                              <TableContainer sx={{ maxHeight: 440 }}>
                                <Table
                                  stickyHeader
                                  aria-label="sticky table"
                                  sx={classes.candidateTableWrapper}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>ID</TableCell>
                                      <TableCell>Candidate</TableCell>
                                      <TableCell>Email</TableCell>
                                      <TableCell>Status</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {emailStatus.applications.candidates.map((candidate) => (
                                      <TableRow key={candidate.id}>
                                        <TableCell>{candidate.id}</TableCell>
                                        <TableCell>{candidate.candidate}</TableCell>
                                        <TableCell>{candidate.email}</TableCell>
                                        <TableCell sx={classes.status}>
                                          <Box
                                            sx={
                                              StatusTypeIcon(candidate.status) && {
                                                paddingRight: '10px'
                                              }
                                            }
                                          >
                                            {StatusTypeIcon(statusPastTense(candidate.status))}
                                          </Box>
                                          <Box>{statusPastTense(candidate.status)}</Box>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
            {isLoading && (
              <>
                <Skeleton animation="wave" height={60} />
                <Skeleton animation="wave" height={60} />
                <Skeleton animation="wave" height={60} />
                <Skeleton animation="wave" height={60} />
              </>
            )}
          </TableContainer>
          <Box sx={classes.paginationParent}>
            <Box sx={classes.rowSelectContainer}>
              <span>Rows per page: </span>
              <FormControl>
                <Select
                  id="rows-per-page"
                  sx={classes.rowSelect}
                  value={rowsPerPage}
                  onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
                  displayEmpty
                  MenuProps={{ sx: classes.paginationMenuItems }}
                >
                  <MenuItem id="first-rpp-item" value={10}>
                    10
                  </MenuItem>
                  <MenuItem id="second-rpp-item" value={20}>
                    20
                  </MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={classes.paginationContainer}>
              <Pagination
                id="pagination-menu"
                count={totalPages}
                page={currentPage}
                siblingCount={0}
                onChange={handlePageChange}
                sx={classes.pagination}
              />
              <span id="total-jobs">{totalEmailStatuses} Total</span>
            </Box>
          </Box>
        </>
      )}
      {!isLoading && emailStatuses?.length === 0 && (
        <Box sx={{ ...classes.noEmailStatusContainer, margin: '100px 0px' }}>
          <Box>
            <NoEmailStatusSVG />
          </Box>
          <Box sx={classes.noEmailStatusesText}>No emails have been sent yet</Box>
        </Box>
      )}
      {unscheduleDialogOpen && (
        <GenericDialog
          isDialogOpen={unscheduleDialogOpen}
          setDialogOpen={setUnscheduleDialogOpen}
          title="Unschedule Email Batch?"
          description="Are you sure you want to unschedule this email batch?"
          buttonCallback={() => {
            handleCancel(selectedEmailStatusId);
            setSelectedEmailStatusId(null);
            setUnscheduleDialogOpen(false);
          }}
          callbackLoading={false}
          buttonText="Unschedule"
          url=""
        />
      )}
      {previewId && isDialogOpen && (
        <EmailPreviewModal
          title={'Email Template'}
          subject={subject}
          body={body}
          isDialogOpen={isDialogOpen}
          setDialogOpen={setIsDialogOpen}
          dialogId={previewId}
        />
      )}
    </>
  );
}
