"use strict";
export const getPlatform = () => {
  const userAgent = navigator.userAgent;
  return {
    isAndroid: /android/i.test(userAgent),
    isIOS: /iPad|iPhone|iPod/.test(userAgent),
    isMac: /Macintosh/.test(userAgent),
    isWindows: /Windows/.test(userAgent),
    isMobile: /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)
  };
};
