import React, { Dispatch, useState } from 'react';
import { Stack } from '@mui/material';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import { ModalType } from '../config';
import ModalFooterButtons from '../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import SmsTemplateComponent from '../../Components/CustomUIElements/SmsTemplateComponent';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';

export const validatePhone = (phone: string) => {
  const phoneRegex = /^[+-\s0-9()]*$/;
  return phoneRegex.test(phone);
};

export default function SendSms({
  smsTemplatePermissions,
  ApplicationState,
  dispatch
}: {
  smsTemplatePermissions: boolean;
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [smsMessage, setSmsMessage] = useState<string>('');
  const [phone, setPhone] = useState<string>(application?.candidate.phone1 || '');
  const [phoneError, setPhoneError] = useState<string>('');
  const [smsMessageError, setSmsMessageError] = useState<string>('');
  const { modalsOpen } = ApplicationState;

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  const { mutate: sendSms, isLoading: sendingSms } = useMutation({
    mutationFn: async () => {
      if (application) {
        const { res } = await Api.sms(application.job.id, application.id, {
          to: phone,
          content: smsMessage
        });
        return res;
      }
    },
    onSuccess: (res) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.message, state: 'success' } });
      handleClose();
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Failed to send sms`, state: 'error' }
      })
  });

  const handleSubmit = () => {
    !validatePhone(phone) &&
      setPhoneError(
        'Please use a valid phone format. Digits only or add + for international numbers'
      );
    !phone && setPhoneError('Must add phone number');
    !smsMessage.trim() && setSmsMessageError('Must add SMS content');
    validatePhone(phone) && phone.trim() && smsMessage.trim() && sendSms();
  };

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.SEND_SMS}
      label="Move application modal"
      handleClose={handleClose}
      styleOverrides={{ ...styles.modalStyleOverrides, height: '725px' }}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Send SMS</Stack>
        <Stack sx={{ rowGap: 1, marginTop: 1, flexGrow: 1 }}>
          <FormTextField
            label="Phone number"
            value={phone}
            onChange={(e) => {
              phoneError && setPhoneError('');
              setPhone(e.target.value);
            }}
            styles={{ width: '40%' }}
            required
            error={phoneError}
          />
          <SmsTemplateComponent
            smsMessage={smsMessage}
            setSmsMessage={setSmsMessage}
            smsTemplatePermissions={smsTemplatePermissions}
            dispatch={dispatch}
            dropdownLabel="Template"
            smsMessageError={smsMessageError}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Send"
          primaryButtonCallback={handleSubmit}
          isLoading={sendingSms}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
