import React from 'react';
import { Box, Popper, ClickAwayListener, Fade } from '@mui/material';
import { classes } from '../../styles';
import { TMenuItems } from '../../config';

const CollapsedNavigationChildren = ({
  menuItems,
  activeChild,
  childState,
  handleChildPopperState
}: {
  menuItems: TMenuItems;
  activeChild: string;
  childState: [number, null | HTMLElement];
  handleChildPopperState: (index: number, anchorEl: HTMLElement | null) => void;
}) => {
  if (childState[0] === -1) return null;
  return (
    <ClickAwayListener onClickAway={() => handleChildPopperState(-1, null)}>
      <Popper
        open={childState[0] !== -1}
        anchorEl={childState[1]}
        placement="right-start"
        transition
        sx={{ zIndex: 1400 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={classes.collapsedNavigationChildContainer}>
              {menuItems[childState[0]]?.submenu?.map((subItem) => {
                return (
                  <a href={subItem.url} key={subItem.title}>
                    <Box sx={{ ...(activeChild === subItem.title && { fontWeight: 700 }) }}>
                      {subItem.title}
                    </Box>
                  </a>
                );
              })}
            </Box>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default CollapsedNavigationChildren;
