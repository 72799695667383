import React, { useState, Dispatch } from 'react';
import { Box, FormControl, Checkbox } from '@mui/material';
import DisplayDescription from './DisplayDescription';
import ToggleButtons from '../../SharedComponents/Buttons/ToggleButtons';
import PositionTitleInput from '../../SharedComponents/Inputs/PositionTitle';
import StyledDropdown from '../../SharedComponents/Inputs/StyledDropdown';
import { aiToneTypes } from '../../SharedComponents/sharedConfig';
import { adTypes, toggleButtonOptions } from '../config';
import { classes } from '../styles';
import { SmartShareAction, SmartShareState } from '../reducer';

const JobBoardInput = ({
  jobDescription,
  dispatch,
  smartShareState
}: {
  jobDescription: string;
  dispatch: Dispatch<SmartShareAction>;
  smartShareState: SmartShareState;
}) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Box sx={{ ...classes.inputStepContainer, rowGap: '42px' }}>
      <Box sx={classes.genericContainer}>
        <Box sx={classes.fieldHeader}>Position title</Box>
        <PositionTitleInput
          positionTitle={smartShareState.generationJobTitle}
          onChange={(value: string) =>
            dispatch({ type: 'SET_GENERATION_JOB_TITLE', payload: value })
          }
        />
      </Box>
      <Box sx={classes.genericContainer}>
        <ToggleButtons
          value={smartShareState.useCustomDescription}
          setValue={(value: string) =>
            dispatch({ type: 'SET_USE_CUSTOM_DESCRIPTION', payload: value })
          }
          options={toggleButtonOptions}
        />
      </Box>
      <DisplayDescription
        useCustomDescription={smartShareState.useCustomDescription}
        jobDescription={jobDescription}
        generationJobDescription={smartShareState.generationJobDescription}
        setGenerationJobDescription={(value: string) =>
          dispatch({ type: 'SET_GENERATION_JOB_DESCRIPTION', payload: value })
        }
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        maxCharacters={10000}
      />
      <Box sx={classes.sideBySideContainer}>
        <FormControl fullWidth>
          <Box sx={classes.fieldSubHeader}>Ad type</Box>
          <StyledDropdown
            id="ad-type"
            value={smartShareState.adType}
            onChange={(value: string) => dispatch({ type: 'SET_AD_TYPE', payload: value })}
            options={adTypes}
          />
        </FormControl>
        <FormControl fullWidth>
          <Box sx={classes.fieldSubHeader}>Generated tone</Box>
          <StyledDropdown
            id="generated-tone"
            value={smartShareState.generationTone}
            onChange={(value: string) => dispatch({ type: 'SET_GENERATION_TONE', payload: value })}
            options={aiToneTypes}
          />
        </FormControl>
        <FormControl fullWidth>
          <Box sx={classes.checkboxContainer}>
            <Checkbox
              sx={classes.checkbox}
              checked={smartShareState.includeKeyPoints}
              onChange={(e) =>
                dispatch({ type: 'SET_INCLUDE_KEY_POINTS', payload: e.target.checked })
              }
            />
            <Box sx={classes.checkboxLabel}>Generate key selling points</Box>
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
};

export default JobBoardInput;
