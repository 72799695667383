import React, { Dispatch } from 'react';
import { GenericTableData, TSortableColumn, TTableState, TableActionItemAction } from '../types';
import { Popover } from '@mui/material';
import { classes } from '../styles';

type IOption = {
  id: string;
  label: string;
  action: () => void;
};

const MoreOptionsList = ({
  options,
  actionsState,
  dispatch,
  label
}: {
  options: IOption[];
  actionsState: TTableState<GenericTableData, TSortableColumn>['actions'];
  dispatch: Dispatch<TableActionItemAction<GenericTableData>>;
  label: string;
}) => {
  const handleSelectItem = (action: () => void) => {
    dispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'moreOptions' });
    action();
  };
  return (
    <Popover
      id={`${label}-more-options-popover`}
      sx={classes.actionsMenu}
      open={actionsState?.isOpen?.moreOptions}
      anchorEl={actionsState?.anchorEl}
      onClose={() => dispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'moreOptions' })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {options.map((option) => (
        <span
          key={option.id}
          onClick={() => handleSelectItem(option.action)}
          className={`${option.id}-action-button`}
        >
          {option.label}
        </span>
      ))}
    </Popover>
  );
};

export default MoreOptionsList;
