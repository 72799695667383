var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
import { withScoutTheme } from "../../../ThemeContext/ThemeContext";
import PropTypes from "prop-types";
import axios from "axios";
import ReactLoading from "react-loading";
import queryString from "query-string";
import CandidateListItem from "./components/CandidateListItem";
import ChatWindow from "./components/ChatWindow";
import ChatBox from "./components/ChatBox";
import CandidateFilter from "./components/CandidateFilter";
import CandidateDetails from "./components/CandidateDetails";
import moment from "moment";
function Messenger({ job, setLargeModal, statuses, selectedApplications, setSelectedApplications, isGroupMessage, config, applications }) {
  const [conversationsLoading, setConversationsLoading] = useState(true);
  const [userOptionsLoading, setUserOptionsLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [userOptions, setUserOptions] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showSidebar, setShowSidebar] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState([]);
  const [body, setBody] = useState("");
  const [signature, setSignature] = useState("");
  const [scheduleDate, setScheduleDate] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(moment().add("1 h").format("hh:mm"));
  const [isPm, setIsPm] = useState(false);
  const [groupMessage, setGroupMessage] = useState(isGroupMessage);
  const classes = useStyles();
  useEffect(() => {
    function initData() {
      return __async(this, null, function* () {
        const conversations2 = yield fetchConversations(selectedApplications);
        setConversations(conversations2);
        fetchUserOptions();
      });
    }
    initData();
  }, [selectedApplications]);
  useEffect(() => {
    if (conversations.length === 1 || selectedConversation.length > 1) {
      setLargeModal(false);
      setShowSidebar(false);
    } else {
      setLargeModal(true);
      setShowSidebar(true);
    }
  }, [conversations, selectedConversation]);
  useEffect(() => {
    setBody("");
    setSignature("");
  }, [selectedConversation]);
  function fetchUserOptions() {
    return __async(this, null, function* () {
      setUserOptionsLoading(true);
      try {
        const { data } = yield axios.get("/admin/integrations/textus/user_options");
        setUserOptions(data);
      } catch (e) {
        console.log(e);
      } finally {
        setUserOptionsLoading(false);
      }
    });
  }
  function fetchConversations(applications2) {
    return __async(this, null, function* () {
      setConversationsLoading(true);
      try {
        const { data: conversationsData } = yield axios.get("/admin/integrations/textus/conversations", __spreadProps(__spreadValues({}, config), {
          params: __spreadValues({
            job_id: job.id
          }, applications2.length === 1 && { candidate_id: applications2[0].candidate_id }),
          paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: "bracket" })
        }));
        const conversations2 = applications2.map((app) => {
          const matchingConversation = conversationsData && conversationsData.find((convo) => convo && convo.candidate_id === app.candidate_id);
          return {
            id: app.id,
            candidate_id: app.candidate_id,
            candidate_name: app.name,
            candidate_phone: app.phone,
            candidate_status: app.status,
            candidate_tags: app.tags,
            messages: matchingConversation ? matchingConversation.messages : []
          };
        });
        groupMessage ? setSelectedConversation(conversations2) : setSelectedConversation([conversations2[0]]);
        return conversations2;
      } catch (e) {
        console.log(e);
      } finally {
        setConversationsLoading(false);
      }
    });
  }
  function handleTimeValueForPost(value) {
    const time = value.replace(/-/g, ":");
    const [hour, minute] = time.split(":");
    if (isPm) {
      return parseInt(hour) === 12 ? `00:${minute}:00` : `${parseInt(hour) + 12}:${minute}:00`;
    } else {
      return `${hour}:${minute}:00`;
    }
  }
  function handleSend() {
    return __async(this, null, function* () {
      setSending(true);
      try {
        if (groupMessage) {
          const { data } = yield axios.post("/admin/integrations/textus/campaigns", {
            job_id: job.id,
            candidate_ids: selectedApplications.map((app) => app.candidate_id),
            campaign_title: `${job.reference} - ${job.title} (${moment().format("LLLL")})`,
            body,
            schedule_time: scheduleDate ? moment(`${scheduleDate} ${handleTimeValueForPost(scheduleTime)}`).format() : null
          }, config);
          const newConversations = conversations.map((convo) => __spreadProps(__spreadValues({}, convo), {
            messages: [data.messages[0], ...convo.messages].filter((msg) => msg)
          }));
          const existingConversations = yield fetchConversations(applications.filter((app) => !selectedApplications.map((selected) => selected.candidate_id).includes(app.candidate_id)));
          const combinedConversations = [...newConversations, ...existingConversations];
          setGroupMessage(false);
          setConversations(combinedConversations);
          setSelectedConversation([combinedConversations[0]]);
        } else {
          const { data } = yield axios.post("/admin/integrations/textus/messages", {
            job_id: job.id,
            candidate_id: selectedConversation[0].candidate_id,
            body,
            signature,
            schedule_time: scheduleDate ? moment(`${scheduleDate} ${handleTimeValueForPost(scheduleTime)}`).format() : null
          }, config);
          const newConversation = __spreadProps(__spreadValues({}, selectedConversation[0]), {
            messages: [data.messages[0], ...selectedConversation[0].messages]
          });
          setSelectedConversation([newConversation]);
          setConversations([newConversation, ...conversations.filter((convo) => convo.candidate_id !== selectedConversation[0].candidate_id)]);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setSending(false);
      }
    });
  }
  if (userOptionsLoading || conversationsLoading) return /* @__PURE__ */ React.createElement(ReactLoading, { type: "spin", color: "#004E70", width: 45, className: classes.loading });
  return /* @__PURE__ */ React.createElement("div", { className: classes.root }, showSidebar && /* @__PURE__ */ React.createElement("div", { className: classes.modalLeft }, /* @__PURE__ */ React.createElement(
    CandidateFilter,
    {
      statuses,
      selectedStatuses,
      setSelectedStatuses,
      searchTerm,
      setSearchTerm,
      conversations
    }
  ), /* @__PURE__ */ React.createElement("div", { className: classes.candidateList }, conversations.filter((convo) => selectedStatuses.length > 0 ? selectedStatuses.map((status) => status.id).includes(convo.candidate_status) : convo.candidate_name.toLowerCase().includes(searchTerm.toLowerCase())).sort((a, b) => !a.messages[0] - !b.messages[0] || a.messages[0] && b.messages[0] && new Date(b.messages[0].sent_time) - new Date(a.messages[0].sent_time)).map(
    (convo) => /* @__PURE__ */ React.createElement(
      CandidateListItem,
      {
        key: convo.id,
        conversation: convo,
        statuses,
        selectedConversation,
        setSelectedConversation
      }
    )
  ))), /* @__PURE__ */ React.createElement("div", { className: classes.modalRight, style: { flexBasis: showSidebar ? "64%" : "100%" } }, /* @__PURE__ */ React.createElement(
    CandidateDetails,
    {
      conversation: selectedConversation
    }
  ), /* @__PURE__ */ React.createElement(
    ChatWindow,
    {
      body,
      conversation: selectedConversation,
      signature
    }
  ), /* @__PURE__ */ React.createElement(
    ChatBox,
    {
      body,
      setBody,
      signature,
      setSignature,
      conversation: selectedConversation,
      userOptions,
      handleSend,
      sending,
      scheduleDate,
      setScheduleDate,
      scheduleTime,
      setScheduleTime,
      isPm,
      setIsPm,
      job
    }
  )));
}
Messenger.propTypes = {
  job: PropTypes.object.isRequired,
  setLargeModal: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  selectedApplications: PropTypes.array.isRequired,
  setSelectedApplications: PropTypes.func.isRequired,
  applications: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired
};
export default withScoutTheme(Messenger);
