import React, { Component } from 'react';
import T from 'prop-types';

export default class DataHeader extends Component {
  static propTypes = {
    data: T.string,
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (<th>{this.props.data}</th>)
  }
}
