import React, { Dispatch, SetStateAction } from 'react';
import { OutlinedInput } from '@mui/material';
import { classes } from '../../AIStudioModal/styles';

const PreferredSkillsInput = ({
  preferredSkills,
  onChange,
  onBlur
}: {
  preferredSkills: string;
  onChange: Dispatch<SetStateAction<string>>;
  onBlur?: (value: string) => void;
}) => {
  return (
    <OutlinedInput
      id="preferred-skills"
      sx={classes.fieldInput}
      placeholder="eg. 'Adobe Illustrator, Figma, attention to detail'"
      value={preferredSkills}
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => {
        onBlur ? onBlur(preferredSkills) : null;
      }}
    />
  );
};

export default PreferredSkillsInput;
