import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GenericDialog from '../GenericDialog';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { classes } from '../styles';
import { IJobAdBroadbeanProps } from '../types';

export default function AdTable({ jobAds, setAds, apiKey }: IJobAdBroadbeanProps) {
  const [dialogUrl, setDialogUrl] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [dialogButtonText, setDialogButtonText] = useState('');
  const [dialogButtonCallback, setDialogButtonCallback] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  function handleSnack(message: string) {
    setIsSnackOpen(true);
    setSnackMessage(message);
  }

  function handleEdit(url: string): void {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  async function handleDelete(url: string) {
    setDialogLoading(true);
    try {
      const { data } = await axios.delete(`${url}`, {
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      if (!data.errors) {
        handleSnack(`${data.bb_job_title} has been deleted`);
        setAds(jobAds.filter((ad) => ad.id !== data.id));
      }
    } catch (e) {
      console.log(e);
      handleSnack('Cannot access the multiposting portal. Please contact your administrator.');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  return (
    <Box sx={classes.table}>
      <Box sx={{ ...classes.row, ...classes.rowHeader }}>
        <span>Reference</span>
        <span>Title</span>
        <Box component="span" sx={classes.center}>
          
        </Box>
      </Box>
      {jobAds.length > 0 ? (
        jobAds.map((ad) => (
          <Box key={ad.id} sx={classes.row}>
            <Box>{ad.bb_job_ref}</Box>
            <Box sx={classes.title}>{ad.bb_job_title}</Box>
            <Box sx={classes.actionsContainer}>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => handleEdit(ad.broadbean_urls.edit)}
                >
                  <EditIcon fontSize="small" sx={classes.iconGrey} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    setDialogUrl(`/api/v4/broadbean/jobs/${ad.job_id}/job_ads/${ad.id}`);
                    setDialogTitle('Are you sure you want to delete this ad?');
                    setDialogDescription(
                      'Your job ad will be permanently deleted and you will not be able to recover your ad details.'
                    );
                    setDialogButtonText('Delete ad');
                    setDialogButtonCallback(() => handleDelete);
                    setDialogOpen(true);
                  }}
                >
                  <DeleteIcon sx={classes.iconRed} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ))
      ) : (
        <Box sx={classes.row}>
          <Box sx={classes.title}>No ads to display</Box>
        </Box>
      )}
      <GenericDialog
        url={dialogUrl}
        title={dialogTitle}
        description={dialogDescription}
        buttonText={dialogButtonText}
        buttonCallback={dialogButtonCallback}
        callbackLoading={dialogLoading}
        isDialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
      <Snackbar open={isSnackOpen} onClose={() => setIsSnackOpen(false)} message={snackMessage} />
    </Box>
  );
}
