import React, {
  SetStateAction,
  useContext,
  useReducer,
  useState,
  Dispatch,
  useEffect
} from 'react';
import CustomReportsTable from './CustomReportsTable';
import { Box, Skeleton, Button } from '@mui/material';
import { ReportContext } from '../index';
import { useReportingData } from '../hooks';
import CreateEditModal from './ModalContents/CreateEditModal';
import GenericDialog from '../../NewUI/Components/Modals/GenericDialog';
import StyledSnackbar from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { reportReducer, initialCustomReportState } from './customReportReducer';
import { usePreviewTable } from './hooks';
import CustomReportPreview from './CustomReportsPreview';
import EmptyApplicantsSVG from '../EmptyApplicantsSVG';
import { classes } from './styles';
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';

const CustomReportSection = ({
  openCreateReport,
  setOpenCreateReport,
  setPreviewReport
}: {
  openCreateReport: boolean;
  setOpenCreateReport: Dispatch<SetStateAction<boolean>>;
  setPreviewReport: Dispatch<SetStateAction<boolean>>;
}) => {
  const [confirmCancelIsOpen, setConfirmCancelIsOpen] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const reportServiceApi = useContext(ReportContext);

  const [reportState, dispatchReport] = useReducer<typeof reportReducer>(
    reportReducer,
    initialCustomReportState({
      entity_id: reportServiceApi.accessibleEntities,
      user: [reportServiceApi.currentUser.id]
    })
  );

  const {
    hasData,
    refetch: refetchForms,
    isLoading
  } = useReportingData(
    reportState,
    dispatchReport,
    reportServiceApi.reports.getCustomReports,
    'forms'
  );

  const { isLoading: loadPreviewTable } = usePreviewTable(
    reportState,
    dispatchReport,
    setSnackbar,
    reportServiceApi
  );

  const closeModal = () => {
    openCreateReport && setOpenCreateReport(false);
    reportState.open && dispatchReport({ type: 'CLOSE_MODAL' });
    setConfirmCancelIsOpen(false);
  };

  useEffect(() => {
    setPreviewReport(reportState.openPreview);
  }, [reportState.openPreview, setPreviewReport]);

  if (isLoading) {
    return (
      <Box>
        {[...Array(5)].map((_, index) => (
          <Box key={index} sx={{ padding: '10px 0px' }}>
            <Skeleton animation="wave" height={60} />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box>
      {reportState.openPreview ? (
        <CustomReportPreview
          reportState={reportState}
          dispatch={dispatchReport}
          setSnackbar={setSnackbar}
          loadPreviewTable={loadPreviewTable}
          refetchForms={refetchForms}
        />
      ) : (
        <>
          {hasData ? (
            <CustomReportsTable
              tableState={reportState}
              dispatch={dispatchReport}
              refetchForms={refetchForms}
            />
          ) : (
            <Box sx={classes.emptyStateContainer}>
              <Box sx={{ marginLeft: '2rem' }}>
                <EmptyApplicantsSVG />
              </Box>
              <Box sx={classes.noReportsText}>
                <Box>Your report is empty</Box>
                <Box>Click below to start building your report</Box>
              </Box>
              <Button sx={sharedClasses.genericButton} onClick={() => setOpenCreateReport(true)}>
                Create Report
              </Button>
            </Box>
          )}
        </>
      )}
      {(openCreateReport || reportState.open) && (
        <CreateEditModal
          reportState={reportState}
          dispatch={dispatchReport}
          setConfirmCancelIsOpen={setConfirmCancelIsOpen}
          setSnackbar={setSnackbar}
          openCreateReport={openCreateReport}
          setOpenCreateReport={setOpenCreateReport}
        />
      )}
      <GenericDialog
        isDialogOpen={confirmCancelIsOpen}
        setDialogOpen={setConfirmCancelIsOpen}
        title={
          reportState.openPreview ? 'Are you sure you want to exit' : 'Close without submitting?'
        }
        description={
          reportState.openPreview
            ? 'Your changes will not be saved'
            : 'Are you sure you want to cancel your report? You will lose any progress you have made.'
        }
        buttonCallback={() => closeModal()}
        callbackLoading={false}
        buttonText={reportState.openPreview ? 'Exit' : 'Close without saving'}
        url=""
      />
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
};

export default CustomReportSection;
