import React, { useState, useMemo, useCallback, useEffect } from 'react';
import MultiSelect, { IOptionType } from '../../Components/CustomUIElements/MultiSelect';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import SortIcon from '@mui/icons-material/Sort';
import CircularProgress from '@mui/material/CircularProgress';
import { defaultFilters } from './config';
import { IFilterDropdownProps } from './types';
import { styles } from '../styles';
import Tooltip from '@mui/material/Tooltip';

export default function FilterDropdown({
  filters,
  setFilters,
  getApprovalTemplates,
  creators,
  setUserPreferences,
  setCurrentPage,
  archived
}: IFilterDropdownProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [tempFilters, setTempFilters] = useState({ ...filters });
  const [numberOfFilters, setNumberOfFilters] = useState(0);

  const calculateNumberOfFilters = useCallback((newFilters) => {
    return Number(!!newFilters.created_by.length);
  }, []);

  const handleDropdownOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };

  const handleFilterChange = (name: string, value: IOptionType[] | number[] | boolean) => {
    setTempFilters({ ...tempFilters, [name]: value });
  };

  const handleApplyFilters = () => {
    setFilters({ ...tempFilters });
    setCurrentPage(1);
    sessionStorage.setItem(`approvalTemplatesTablePage`, 1);
    setUserPreferences(null, null, null, { ...tempFilters }, null);
    getApprovalTemplates(1, null, null, null, tempFilters, archived);
    setDropdownOpen(false);
    setAnchorEl(null);
    setNumberOfFilters(calculateNumberOfFilters(tempFilters));
  };

  const handleResetFilters = () => {
    setTempFilters({ ...defaultFilters });
    setUserPreferences(null, null, null, { ...defaultFilters }, null);
    setFilters({ ...defaultFilters });
    getApprovalTemplates(null, null, null, null, defaultFilters, archived);
    setDropdownOpen(false);
    setAnchorEl(null);
    setNumberOfFilters(0);
  };

  const creatorOptions = useMemo(() => {
    return creators?.map((creator) => ({
      name: creator.name,
      id: creator.id
    }));
  }, [creators]);

  useEffect(() => {
    setTempFilters({ ...filters });
    setNumberOfFilters(calculateNumberOfFilters(filters));
  }, [filters, calculateNumberOfFilters]);

  return (
    <Tooltip title="Filter" placement="top" arrow>
      <Box>
        <Badge
          badgeContent={numberOfFilters}
          sx={{ '& .MuiBadge-badge': { backgroundColor: '#E37D7A', color: '#FFF' } }}
        >
          <IconButton
            id="filter-approval-templates-button"
            sx={
              dropdownOpen ? { ...styles.iconButton, ...styles.iconButtonOpen } : styles.iconButton
            }
            onClick={handleDropdownOpen}
          >
            {loadingFilters ? <CircularProgress size={24} /> : <SortIcon />}
          </IconButton>
        </Badge>
        <Popover
          id="filter-dropdown-popover"
          sx={{
            ...styles.popover,
            background: 'none',
            '& .MuiBox-root': {
              marginTop: 0
            }
          }}
          open={dropdownOpen}
          anchorEl={anchorEl}
          onClose={() => setDropdownOpen(false)}
          PaperProps={{
            sx: {
              overflow: 'unset',
              boxShadow: '0px 2px 14px rgba(48,90,111,0.16)'
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box sx={styles.filterPopoverContent}>
            <Box sx={styles.scrollableFilters}>
              <Box sx={styles.filterPopoverHeader}>
                <Button
                  id="reset-filters-button"
                  variant="contained"
                  endIcon={<CancelIcon />}
                  sx={styles.clearButton}
                  onClick={handleResetFilters}
                >
                  Clear all
                </Button>
                <Button
                  id="apply-filters-button"
                  variant="contained"
                  onClick={handleApplyFilters}
                  sx={styles.applyButton}
                >
                  Apply
                </Button>
              </Box>
              <Box
                sx={{ ...styles.filterOptionContainer, marginBottom: '20px' }}
                id="created-by-filter"
              >
                Created By
                <MultiSelect
                  handleFilterChange={(name, value) => handleFilterChange(name, value)}
                  selectOptions={creatorOptions}
                  name={'created_by'}
                  filters={tempFilters.created_by}
                />
              </Box>
            </Box>
          </Box>
        </Popover>
      </Box>
    </Tooltip>
  );
}
