import React, { useState, useEffect, useRef, Dispatch, SetStateAction, useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import { StyledSnackbarProps } from '../../../Components/CustomUIElements/StyledSnackbar';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import Api from '../../../Job/API';

export default function AddSystemField({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  getQuestions,
}: {
  apiKey: string;
  modalsOpen: Record<string, boolean>;
  setModalsOpen: Dispatch<SetStateAction<Record<string, boolean>>>;
  jobId: number;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
  getQuestions: any;
}) {
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const selectStatusRef = useRef(null);
  const [systemFields, setSystemFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [errorField, setErrorField] = useState('');

  const handleClose = () => {
    setSelectedField(null);
    setErrorField('');
    setModalsOpen((prev) => ({ ...prev, addSystemField: false }));
  };

  const getSystemFields = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/v4/system_fields?form_type=Job`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setSystemFields(
        response?.system_fields.map((field: { id: number; title: string }) => `${field.id} - ${field.title}`)
      )
    } catch (error) {
      console.log(error);
    } finally {
    setIsLoading(false);
    }
  }, [apiKey]);

  useEffect(() => {
    getSystemFields();
  }, [getSystemFields]);

  const handleSubmit = async () => {
    if (!selectedField) {
      setErrorField('This field is required');
      return;
    }
    setIsCreating(true);
    try {
      const data = await Api.addSystemField(
        { 'X-api-authenticate': apiKey },
        jobId,
        selectedField.split("-")[0],
      );
      setSnackbar({
        message:
          'Field has been added',
        state: 'success'
      });
      handleClose();
      setSelectedField(null);
      setErrorField('');
    } catch (error) {
      console.log(error);
    } finally {
      setIsCreating(false);
      getQuestions();
    }
  }

  return (
    <Modal
      open={modalsOpen.addSystemField}
      onClose={handleClose}
      aria-labelledby="add-system-field-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
        <Box id="add-system-title" sx={classes.actionModalHeader}>
          Add system field
        </Box>
        <Box
          sx={classes.modalContent}
          id="add-system-modal-content"
          aria-label="Add System Field Modal Content"
        >
          <Box role="form" id="add-system-form" aria-label="Add System Field form">
            <Box sx={{ ...classes.actionModalFormLine, marginTop: '20px' }} ref={selectStatusRef}>
              <Virtualize
                value={selectedField}
                passOptions={systemFields}
                setValue={(value: string) => {
                  setSelectedField(value);
                  setErrorField('');
                }}
                passedStyles={{
                  ...sharedClasses.formAutocomplete,
                  width: '700px',
                  marginTop: '20px'
                }}
                label="Select a system field"
                loading={isLoading}
                loadingText="Loading system fields..."
              />

              {errorField && <Box sx={{...classes.formError, top: '63px'}}>{errorField}</Box>}
            </Box>
          </Box>
        </Box>
        <Box sx={classes.modalFooterSmallerSection}>
          <Button
            id="close-add-system-button"
            sx={classes.modalCancelButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            id="submit-add-system-button"
            type="submit"
            sx={{ ...classes.modalCreateButton, width: '91px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isCreating ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
