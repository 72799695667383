import React from 'react';
import Box from '@mui/material/Box';
import ApplicationsAndViews from './ApplicationsAndViews';
import CreationExpiry from './CreationExpiry';
import { ISubcontainerProps } from '../types';
import { classes } from './styles';

export default function Subcontainer({
  applications,
  openApplications,
  jobCounts,
  createdAt,
  publishedAt,
  expiresAt,
  isLoading,
  handleTabChange,
  timeZone
}: ISubcontainerProps) {
  return (
    <Box sx={classes.subcontainer}>
      <ApplicationsAndViews
        jobCounts={jobCounts}
        isLoading={isLoading}
        applications={applications}
        openApplications={openApplications}
        handleTabChange={handleTabChange}
      />
      <CreationExpiry
        createdAt={createdAt}
        publishedAt={publishedAt}
        expiresAt={expiresAt}
        isLoading={isLoading}
        timeZone={timeZone}
      />
    </Box>
  );
}
