import React, { Component } from 'react';
import paginatedDataSource from '../data-source';
import serviceInterface from '../../scout/service-interface-client';
import LinkControl from '../../scout/table/fields/control/link_control';
import ScoutIndexTable from '../../scout/table/table';
import ScoutIndexRow from '../../scout/table/row';
import ScoutFilter from '../../scout/table/filter';
import CallbackHeader from '../../scout/table/headers/callback';
import DataHeader from '../../scout/table/headers/data';
import DataColumn from '../../scout/table/fields/data';
import LinkColumn from '../../scout/table/fields/link';
import CustomControlColumn from '../../scout/table/fields/custom_control';
import ScoutSelect from '../../scout/select';

import T from 'prop-types';

class PayingUserIndexTemplate extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      entityValues: [],
      rootEntityValues: [],
      stateValues: []
    };
    this.selectCallback = this.selectCallback.bind(this);
    this.rolePaidFilterCallback = this.rolePaidFilterCallback.bind(this);
    this.buildCol = this.buildCol.bind(this);
  }

  buildControls(user) {
    return [
      <LinkControl
        key={0}
        url={`/admin/entities/${user.entity_id}/users/${user.id}`}
        title="show"
      />
    ];
  }

  buildCol(user) {
    let roles = user.roles.map(function (elem, index) {
      if (index == user.roles.length - 1) {
        return elem.paid ? (
          <b key={index} style={{ paddingRight: '0.2em' }}>
            {elem.name}
          </b>
        ) : (
          `${elem.name}`
        );
      } else {
        return elem.paid ? (
          <span key={index} style={{ paddingRight: '0.2em' }}>
            <b>{elem.name}</b>,
          </span>
        ) : (
          `${elem.name}, `
        );
      }
    });

    return [
      <DataColumn key={0} data={user.first_name} />,
      <DataColumn key={1} data={user.last_name} />,
      <DataColumn key={2} data={user.email} />,
      <DataColumn key={3} data={user.state} />,
      <LinkColumn key={4} data={user.entity_name} url={`/admin/entities/${user.entity_id}`} />,
      <DataColumn key={5} data={roles} />,
      <CustomControlColumn key={6} components={this.buildControls(user)} />
    ];
  }

  buildRow(user, index) {
    return <ScoutIndexRow key={index} cols={this.buildCol(user)} index={index} />;
  }

  buildHeaders() {
    const { sortCallback, buildSortableHeader } = this.props;

    return [
      <CallbackHeader
        key={0}
        data={buildSortableHeader('FIRST NAME', 'firstname')}
        callback={sortCallback}
        field={'firstname'}
      />,
      <CallbackHeader
        key={1}
        data={buildSortableHeader('LAST NAME', 'lastname')}
        callback={sortCallback}
        field={'lastname'}
      />,
      <CallbackHeader
        key={2}
        data={buildSortableHeader('EMAIL', 'email')}
        callback={sortCallback}
        field={'email'}
      />,
      <CallbackHeader
        key={3}
        data={buildSortableHeader('STATE', 'state')}
        callback={sortCallback}
        field={'state'}
      />,
      <CallbackHeader
        key={4}
        data={buildSortableHeader('Entity', 'entities.name')}
        callback={sortCallback}
        field={'entities.name'}
      />,
      <DataHeader key={5} data={'ROLES'} />,
      <DataHeader key={6} data={'OPERATIONS'} />
    ];
  }

  selectCallback(attr, stateName, value) {
    const { filterCallback } = this.props;
    filterCallback({ [attr]: value });
    this.setState({ [stateName]: [].concat(value) });
  }

  rolePaidFilterCallback(event) {
    const { filterCallback } = this.props;
    filterCallback({ roles: { paid: $(event.target).prop('checked') } });
  }

  payingUserFilters() {
    const { entities, root_entities, user_states, filters, filterCallback } = this.props;

    return (
      <ol>
        <li style={{ width: '20em' }}>
          <label htmlFor={'entity_filter'}>Specific Entity</label>
          <ScoutSelect
            name="entity_filter"
            onChange={(value) => this.selectCallback('entity_id', 'entityValues', value)}
            data={entities}
            values={this.state.entityValues}
            multi={true}
          />
        </li>
        <li style={{ width: '20em' }}>
          <label htmlFor={'root_entity_filter'}>Client Level Entity</label>
          <ScoutSelect
            name="root_entity_filter"
            onChange={(value) => this.selectCallback('root_entity_id', 'rootEntityValues', value)}
            data={root_entities}
            values={this.state.rootEntityValues}
            multi={true}
          />
        </li>
        <li style={{ width: '20em' }}>
          <label htmlFor={'state_filter'}>State</label>
          <ScoutSelect
            name="state_filter"
            onChange={(value) => this.selectCallback('state', 'stateValues', value)}
            data={user_states}
            values={this.state.stateValues}
            multi={true}
          />
        </li>
        <li>
          <label htmlFor={'filter_paid_role'}>Paid Role?</label>
          <input
            type={'checkbox'}
            checked={filters.roles.paid}
            onChange={this.rolePaidFilterCallback}
            id={'filter_paid_role'}
          />
        </li>
        <li>
          <label htmlFor={'filter_email'}>Email</label>
          <input
            type={'text'}
            checked={filters.email}
            onChange={(event) => {
              filterCallback({ email: event.target.value });
            }}
            id={'filter_email'}
          />
        </li>
        <li>
          <label htmlFor={'filter_firstname'}>First Name</label>
          <input
            type={'text'}
            checked={filters.firstname}
            onChange={(event) => {
              filterCallback({ firstname: event.target.value });
            }}
            id={'filter_firstname'}
          />
        </li>
        <li>
          <label htmlFor={'filter_lastname'}>Last Name</label>
          <input
            type={'text'}
            checked={filters.lastname}
            onChange={(event) => {
              filterCallback({ lastname: event.target.value });
            }}
            id={'filter_lastname'}
          />
        </li>
      </ol>
    );
  }

  render() {
    const { data, pagination, paginationCallback, loading } = this.props;

    return (
      <div>
        <ScoutFilter
          title={'Paying Users'}
          csvSubmit={this.props.csvSubmit}
          filterSubmit={this.props.filterSubmit}
          filters={this.payingUserFilters()}
        />
        <ScoutIndexTable
          rows={data.map((item, index) => {
            return this.buildRow(item, index);
          })}
          headers={this.buildHeaders()}
          paginationCallback={paginationCallback}
          pagination={pagination}
          loading={loading}
        />
      </div>
    );
  }
}

var options = {
  default_filter: { entity_id: [''], root_entity_id: [''], roles: { paid: true } },
  data_name: 'users',
  title: 'Paying Users',
  sort_key: 'users.id',
  sort_state: {
    expires_at: 'Desc'
  }
};

const payingUserIndex = serviceInterface(paginatedDataSource(PayingUserIndexTemplate, options), {});
export default payingUserIndex;
