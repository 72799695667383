import React, { Dispatch, SetStateAction } from 'react';
import FormRadioButtonGroup from '../../../Components/CustomUIElements/FormRadioButtonGroup';

export default function NotificationOptionSwitch({
  setNotification,
  notification
}: {
  setNotification: Dispatch<SetStateAction<string>>;
  notification: string;
}) {
  return (
    <FormRadioButtonGroup
      options={[
        {
          label: 'Email',
          value: 'email'
        },
        {
          label: 'SMS',
          value: 'sms'
        }
      ]}
      onChange={(value) => setNotification(value.toString())}
      directionRow
      color={'#939393'}
      defaultValue={notification}
      groupLabel="Choose notification preference"
    />
  );
}
