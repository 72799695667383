import React, { Dispatch, ReactNode, useState } from 'react';
import { Box, Popover, IconButton, Badge, Button, Tooltip } from '@mui/material';
import { classes } from './styles';
import SortIcon from '@material-ui/icons/Sort';
import CancelIcon from '@mui/icons-material/Cancel';

interface IFilterDropdownProps {
  state: {
    filters: object;
  };
  dispatch: Dispatch<any>;
  children: ReactNode;
  applyFilters: (newFilter: boolean) => void;
}

export default function Index({ state, dispatch, children, applyFilters }: IFilterDropdownProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const calculateNumberOfFilters = (newFilters) => {
    let count = 0;
    Object.values(newFilters).forEach((value) => {
      const items = value as { name: string; id?: number }[];
      if (items.length > 0) {
        count += items.length;
      }
    });
    return count;
  };

  const handleDropdownOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };

  const handleApplyFilters = () => {
    applyFilters(true);
    setDropdownOpen(false);
  };

  const handleResetFilters = () => {
    dispatch({ type: 'RESET_FILTERS' });
  };

  return (
    <Tooltip title="Filter" placement="top" arrow>
      <Box>
        <Badge
          badgeContent={calculateNumberOfFilters(state.filters)}
          sx={{ '& .MuiBadge-badge': { backgroundColor: '#E37D7A', color: '#FFF' } }}
        >
          <IconButton
            id="filter-dropdown-applications-table-button"
            sx={
              dropdownOpen
                ? { ...classes.iconButton, ...classes.iconButtonOpen }
                : classes.iconButton
            }
            onClick={handleDropdownOpen}
          >
            <SortIcon />
          </IconButton>
        </Badge>
        <Popover
          id="filter-dropdown-popover"
          sx={classes.popover}
          open={dropdownOpen}
          anchorEl={anchorEl}
          onClose={() => setDropdownOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box sx={classes.filterPopoverContent}>
            <Box sx={classes.scrollableFilters}>
              <Box sx={classes.filterPopoverHeader}>
                <Button
                  id="reset-filters-button"
                  variant="contained"
                  endIcon={<CancelIcon />}
                  sx={classes.clearButton}
                  onClick={handleResetFilters}
                >
                  Clear all
                </Button>
                <Button
                  id="apply-filters-button"
                  variant="contained"
                  onClick={handleApplyFilters}
                  sx={classes.applyButton}
                >
                  Apply
                </Button>
              </Box>
              {children}
            </Box>
          </Box>
        </Popover>
      </Box>
    </Tooltip>
  );
}
