"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  applicationsContainer: {
    paddingTop: "6px"
  },
  checkbox: {
    color: "#aaa !important",
    "& .MuiSvgIcon-root": {
      fontSize: "20px"
    },
    "&.Mui-checked": {
      color: "#15C7C0 !important"
    }
  },
  sortable: {
    "&:hover": {
      color: "#0C74A4"
    },
    "& .MuiTableSortLabel-icon": {
      color: "darkgrey !important"
    }
  },
  candidateCell: {
    display: "flex",
    alignItems: "center",
    "& a": {
      color: "#666666",
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  paginationParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  },
  rowSelectContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666",
    "& span": {
      marginRight: "12px",
      paddingBottom: "6px"
    }
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666"
  },
  pagination: {
    marginRight: "24px",
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#CCCCCC",
      margin: "0px -2px"
    },
    "& .Mui-selected": {
      color: "#666666",
      backgroundColor: "#ffffff00 !important",
      "&:hover": {
        backgroundColor: "#ffffff00 !important"
      }
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#666666"
    }
  },
  rowSelect: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: "#666666",
    fontWeight: "bold",
    borderRadius: "6px !important",
    "& legend": {
      display: "none"
    },
    "& svg": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DBDEDF"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CACDCE"
    },
    "& .MuiOutlinedInput-input": {
      padding: "4px 12px 8px 12px !important"
    }
  },
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  },
  reviewSubheaders: {
    color: "#838383",
    fontSize: "15px",
    fontFamily: "Source Sans Pro, sans-serif",
    paddingLeft: "4px"
  },
  reviewBoxes: {
    backgroundColor: "#f8f6f6",
    borderRadius: "6px",
    border: "1px solid #DDDDDD",
    display: "block",
    minHeight: "8.5rem",
    width: "10.5rem",
    padding: "1.5rem"
  },
  reviewContent: {
    padding: "0 0 30px 4px"
  },
  noApplications: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  skeletonContainer: {
    borderBottom: "1px solid #DDDDDD",
    padding: "10px 0px"
  },
  selectedTooltip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    visibility: "hidden",
    width: "250px",
    height: "62px",
    bottom: "40px",
    left: "calc(50% - 105px)",
    position: "fixed",
    zIndex: 99,
    backgroundColor: "rgba(242,242,242)",
    borderRadius: "8px",
    transform: "translateX(20px)"
  },
  fadeIn: {
    visibility: "visible",
    opacity: "1",
    transform: "translateX(0px)",
    transition: "visibility 0s linear 0s, opacity 300ms, transform 300ms"
  },
  fadeOut: {
    visibility: "hidden",
    opacity: "0",
    transform: "translateX(20px)",
    transition: "visibility 0s linear 300ms, opacity 300ms, transform 300ms"
  },
  selectedTooltipText: {
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#666666"
  },
  actionsContianer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "22px",
    marginBottom: "50px"
  },
  actionButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#666666",
    backgroundColor: "#9393931F",
    padding: "10px 24px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#93939335",
      border: "none"
    }
  },
  deleteApplicant: {
    color: theme.palette.error.main,
    backgroundColor: "#D6827D33",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: theme.palette.error.main,
      border: "none"
    }
  },
  filtersRow: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: "30px",
    columnGap: "17px"
  },
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  popover: {
    marginTop: "15px"
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)"
  },
  switchLabels: {
    justifyContent: "space-between",
    width: "200px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  switchActive: {
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  filterSwitch: {
    transform: "translateX(10px)",
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  filterSwitchActive: {
    transform: "translateX(10px)",
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  filterSwitchLabels: {
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "0px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  searchInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "regular",
    borderRadius: "6px",
    minHeight: "54px",
    "& legend": {
      display: "none"
    },
    "& svg": {
      fontSize: "31px",
      color: "#084D6D"
    },
    "& input": {
      padding: "10.5px 11px 15.5px 11px",
      color: "#000000"
    },
    "& fieldset": {
      borderColor: "#EAEEF0"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADEE0 !important"
      }
    }
  },
  newApplicationButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "1.3",
    color: theme.palette.action.main,
    backgroundColor: "#5BC4BF1F",
    height: "60px",
    width: "260px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "@media  screen and (max-width: 1130px)": {
      fontSize: "14px"
    },
    "&:hover": {
      backgroundColor: "#5BC4BF35",
      border: "none"
    }
  },
  attachmentsHeader: {
    width: "50px"
  },
  attachmentsCell: {
    width: "50px",
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "18px",
      cursor: "pointer"
    }
  },
  attachmentItem: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    padding: "14px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  fileIcon: {
    color: theme.palette.primary.main
  },
  fileName: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    maxWidth: "240px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  filterPopoverContent: {
    width: "272px",
    maxHeight: "500px",
    overflowY: "auto",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  filterPopoverHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    backgroundColor: "white",
    position: "sticky",
    top: "0px",
    zIndex: 99,
    paddingTop: "26px",
    paddingBottom: "2px",
    boxShadow: "0px 6px 10px 7px #ffffff"
  },
  clearButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#939393",
    backgroundColor: "rgba(147, 147, 147, 0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "rgba(147, 147, 147, 0.24)",
      boxShadow: "none"
    },
    "& .MuiButton-endIcon svg": {
      fontSize: "10px"
    }
  },
  applyButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 23px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  filterOptionContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    marginBottom: "12px"
  },
  checkboxContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "24px"
  },
  ratingSlider: {
    fontFamily: "Source Sans Pro",
    color: theme.palette.action.main,
    height: "2px",
    "& .MuiSlider-rail": {
      backgroundColor: "#E0E0E0"
    },
    "& .MuiSlider-track": {
      backgroundColor: theme.palette.action.main
    },
    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.action.main,
      width: "8px",
      height: "8px",
      "&:hover": {
        boxShadow: "0px 0px 0px 5px" + theme.palette.action.main + "16"
      }
    },
    "& .MuiSlider-thumb.Mui-active": {
      boxShadow: "0px 0px 0px 7px" + theme.palette.action.main + "16"
    },
    "& .MuiSlider-valueLabel": {
      fontSize: "11px",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      top: "36px",
      "&::after": {
        display: "none"
      }
    }
  },
  csvExportModal: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    width: "520px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "Source Sans Pro",
    color: "#333333"
  },
  csvExportHeader: {
    fontSize: "20px",
    fontWeight: "600"
  },
  csvExportSubheader: {
    color: "#333333",
    fontSize: "14px"
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  chipPacket: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "20px",
    columnGap: "26px",
    marginTop: "36px"
  },
  chipSelected: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    padding: "20px 12px",
    borderRadius: "25px",
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    border: "2px solid " + theme.palette.action.main,
    transition: "all 0.4s ease-in-out",
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  },
  chip: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    padding: "20px 12px",
    borderRadius: "25px",
    backgroundColor: "#FFFFFF",
    border: "2px solid #CCCCCC",
    color: "#CCCCCC",
    transition: "all 0.4s ease-in-out",
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  },
  csvExportButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: theme.palette.action.main,
    borderRadius: "8px",
    height: "58px",
    marginTop: "42px",
    "&:hover": {
      backgroundColor: "#4da8a4"
    }
  },
  actionsModal: {
    display: "flex",
    flexDirection: "row",
    padding: "0 60px",
    maxWidth: "1000px",
    width: "100%",
    height: "84vh",
    maxHeight: "1200px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  modalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    lineHeight: "32px"
  },
  calendar: {
    zIndex: "19001",
    "& .PrivatePickersFadeTransitionGroup-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.action.main} !important`
    },
    "& .PrivatePickersSlideTransition-root": {
      minHeight: "242px !important"
    }
  },
  formAutocomplete: {
    "& .MuiAutocomplete-root": {
      "& .MuiOutlinedInput-root": {
        padding: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "0px",
      margin: "3px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "& .MuiAutocomplete-input": {
        padding: "6px 4px 6px 11px"
      }
    },
    "& fieldset": {
      display: "none"
    },
    "& label": {
      color: "#838383 !important",
      fontSize: "21px",
      fontFamily: "Source Sans Pro, sans-serif",
      paddingLeft: "4px",
      left: "-13px",
      top: "-15px",
      "& .MuiFormLabel-asterisk": {
        color: theme.palette.error.main
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      transition: "all 0.25s ease-in-out",
      color: "#AAAAAA"
    }
  },
  datePicker: {
    fontFamily: "Source Sans Pro",
    width: "250px",
    color: "#666666",
    borderRadius: "6px",
    "& input": {
      padding: "5px 10px 7px 10px",
      fontFamily: "Source Sans Pro"
    },
    "& fieldset": {
      borderColor: "#DDDDDD",
      "& legend": {
        display: "none"
      }
    },
    "& button": {
      padding: "0px",
      transform: "translate(-2px, -1.5px)",
      "&:hover": {
        background: "transparent",
        "& svg": {
          color: "#888888"
        }
      }
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0px"
    },
    "& svg": {
      color: "#AAAAAA"
    }
  },
  editStep: {
    position: "fixed",
    fontSize: "13px",
    paddingLeft: "1px",
    cursor: "pointer"
  },
  modalHeaderContent: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: "#727272",
    marginBottom: "1.75rem",
    lineHeight: "2rem"
  },
  stepNumberGreen: {
    width: "2rem",
    height: "2rem",
    borderRadius: "1rem",
    backgroundColor: "#5BC4BF",
    color: "#FFFFFF",
    textAlign: "center",
    alignItems: "center",
    fontWeight: "700",
    display: "grid"
  },
  stepNumberGrey: {
    width: "2rem",
    height: "2rem",
    borderRadius: "1rem",
    backgroundColor: "#DDDDDD",
    color: "#727272",
    textAlign: "center",
    alignItems: "center",
    fontWeight: "700",
    display: "grid"
  },
  verticleLine: {
    width: "1px",
    backgroundColor: "#DDDDDD",
    marginLeft: "0.97rem",
    height: "100%"
  },
  verticleLineWhite: {
    width: "1px",
    backgroundColor: "#FFFFFF",
    marginLeft: "0.97rem",
    height: "100%"
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    height: "80%",
    overflowY: "auto",
    margin: "46px 0px"
  },
  modalContentStep3: {
    display: "flex",
    flexDirection: "column",
    height: "91%",
    overflowY: "auto",
    marginBottom: "25px"
  },
  errorBox: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    position: "absolute",
    bottom: "-30px",
    height: "2rem",
    left: "4px"
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px"
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "26px",
    width: "100%"
  },
  modalConfirmationButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  modalEditButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "rgb(91,196,191)",
    background: "rgb(91,196,191,0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#5BC4BF",
      color: "#FFFFFF",
      boxShadow: "none"
    }
  },
  modalCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "100px",
    textTransform: "none",
    textDecoration: "underline",
    color: theme.palette.error.main,
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      boxShadow: "none"
    }
  },
  fullWidthFieldContainer: {
    maxWidth: "630px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "26px"
  },
  fullWidthField: {
    width: "100%",
    marginBottom: "26px"
  },
  uploadSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& h3": {
      color: "#939393",
      fontSize: "19px",
      fontWeight: "Bold",
      marginBottom: "14px"
    }
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "2px",
    marginBottom: "24px",
    rowGap: "8px",
    fontSize: "17px",
    fontWeight: "600",
    color: "#AAAAAA"
  },
  statusClamp: {
    display: "-webkit-box",
    position: "relative",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    width: "140px",
    minHeight: "35px",
    maxHeight: "53px",
    padding: "6px 20px 5px 24px",
    textAlign: "left",
    "&::after": {
      content: '"\u25BE"',
      fontSize: "13px",
      color: "#CCCCCC",
      position: "absolute",
      right: "6px",
      lineHeight: "0",
      top: "50%"
    },
    "& .status-dropdown-full": {
      visibility: "hidden",
      width: "140px",
      minHeight: "35px",
      padding: "6px 20px 5px 24px",
      textAlign: "left",
      position: "absolute",
      top: "-1px",
      left: "-1px",
      zIndex: "2",
      background: "#ffffff",
      borderRadius: "6px",
      border: "1px solid #CCCCCC",
      "&::after": {
        content: '"\u25BE"',
        fontSize: "13px",
        color: "#CCCCCC",
        position: "absolute",
        right: "6px",
        lineHeight: "0"
      }
    },
    "&:hover": {
      overflow: "visible",
      WebkitLineClamp: "unset",
      "& .status-dropdown-full": {
        visibility: "visible"
      }
    }
  },
  statusColour: {
    position: "absolute",
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    top: "calc(50% - 3px)",
    left: "10px"
  },
  noDropdown: {
    border: "1px solid #FFFFFF",
    "& .status-dropdown-full": {
      border: "1px solid #FFFFFF"
    },
    "&::after": {
      display: "none"
    }
  },
  noStatuses: {
    width: "100%",
    paddingLeft: "18px",
    display: "flex"
  },
  statusDropdown: {
    border: "1px solid #CCCCCC",
    borderRadius: "6px",
    cursor: "pointer",
    "&::after": {
      display: "block"
    }
  },
  statusDropdownItem: {
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F1F1F1"
    }
  },
  statusDropdownItems: {
    "& .MuiPaper-root": {
      width: "200px",
      maxHeight: "250px",
      overflowY: "auto",
      borderRadius: "6px",
      marginTop: "6px"
    }
  },
  statusLoader: {
    color: theme.palette.primary.main,
    position: "absolute",
    zIndex: "4",
    top: "calc(50% - 9px)",
    left: "calc(50% - 9px)"
  },
  applicationStatus: {
    "& span": {
      cursor: "pointer"
    }
  },
  formCheckbox: {
    color: "#ddd !important",
    "& .MuiSvgIcon-root": {
      fontSize: "20px"
    },
    "&.Mui-checked": {
      color: "#15C7C0 !important"
    }
  },
  checkboxLabel: {
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      color: "#939393"
    }
  },
  actionModalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "26px",
    width: "100%",
    marginLeft: "12px"
  },
  actionModalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginLeft: "12px"
  },
  actionsModalReducedPadding: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    paddingLeft: "48px",
    maxWidth: "950px",
    width: "100%",
    height: "84vh",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& h3": {
      color: "#939393",
      fontSize: "19px",
      fontWeight: "Bold",
      marginBottom: "30px"
    }
  },
  modalSendButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 19px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  selectedApplicantsTable: {
    tableLayout: "auto !important",
    minWidth: "650px",
    width: "unset",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "5px 8px",
      paddingLeft: "0px"
    },
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    tr: {
      fontSize: "16px",
      border: "none"
    }
  },
  selectedApplicantsTableHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.primary.main,
    marginTop: "24px",
    marginBottom: "20px"
  },
  candidateSearch: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "regular",
    color: "#939393",
    borderRadius: "6px",
    width: "320px",
    "& .MuiOutlinedInput-root": {
      paddingLeft: "3px"
    },
    "& legend": {
      display: "none"
    },
    "& svg": {
      fontSize: "22px",
      color: "#939393",
      transform: "translate(-6px, -1px)"
    },
    "& input": {
      padding: "8px 9px 12px 0px",
      color: "#000000"
    },
    "& fieldset": {
      borderColor: "#6DB5B1",
      backgroundColor: "#AAAAAA09"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADEE0 !important"
      }
    }
  },
  iconGrey: {
    color: "#666666",
    fontSize: "28px"
  },
  questionsTitle: {
    color: theme.palette.primary.main,
    fontSize: "13px",
    fontWeight: "bold",
    paddingBottom: "5px"
  },
  weightValue: {
    fontSize: "13px",
    textAlign: "center"
  },
  iconGreen: {
    color: theme.palette.success.main,
    fontSize: "1.25rem",
    margin: "auto",
    display: "flex"
  },
  iconGreenResources: {
    color: theme.palette.success.main,
    fontSize: "1.25rem",
    marginLeft: "0.5rem",
    display: "flex"
  },
  iconRed: {
    color: theme.palette.error.main,
    fontSize: "1.25rem",
    margin: "auto",
    display: "flex"
  },
  questionsWrapper: {
    display: "flex",
    padding: "12px 18px 0 18px",
    background: "#F3F3F3",
    marginBottom: "1.75rem",
    borderRadius: "6px"
  },
  questionInput: {
    "& span": {
      fontSize: "14px !Important"
    }
  },
  previewContainer: {
    marginTop: "1rem",
    "& h3": {
      marginBottom: "1rem",
      marginTop: ".3rem",
      color: "#727272",
      fontSize: "16px"
    }
  }
};
