"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  // Generic dialog
  dialog: {
    padding: "40px 60px",
    alignItems: "center",
    textAlign: "center",
    "& > h4": {
      fontSize: "26px",
      color: theme.palette.primary.main,
      marginBottom: "14px"
    },
    "& > span": {
      fontWeight: "600",
      color: "#666666",
      fontSize: "16px",
      lineHeight: "20px"
    },
    "& button:focus": {
      outline: "0"
    }
  },
  dialogButtonContainer: {
    display: "flex",
    marginTop: "40px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    "& > button": {
      margin: "0 10px",
      minWidth: "150px"
    }
  },
  dialogHeader: {
    color: "#727272",
    fontSize: "26px",
    fontWeight: "800",
    marginBottom: "1.5rem"
  },
  confirmationButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    whiteSpace: "nowrap",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  tickWrapper: {
    background: "#5BC4BF",
    borderRadius: "5rem",
    height: "3.75rem",
    width: "3.75rem",
    display: "flex",
    boxShadow: "2px 2px 5px #DDDDDD",
    marginBottom: "1.5rem"
  },
  crossWrapper: {
    background: theme.palette.error.main,
    borderRadius: "5rem",
    height: "3.75rem",
    width: "3.75rem",
    display: "flex",
    boxShadow: "2px 2px 5px #DDDDDD",
    marginBottom: "1.5rem"
  },
  tickGreen: {
    color: "#FFFFFF",
    fontSize: "2.5rem",
    display: "flex",
    margin: "auto"
  },
  crossRed: {
    color: "#FFFFFF",
    fontSize: "2.5rem",
    display: "flex",
    margin: "auto"
  },
  buttonSecondaryLight: {
    borderRadius: "6px",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    padding: "14px 30px",
    fontSize: "15px",
    fontWeight: "bold",
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#FFF"
    }
  },
  buttonRed: {
    borderRadius: "6px",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "14px 30px",
    color: "#FFF",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    fontSize: "15px",
    background: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.dark,
      color: "#FFF"
    }
  },
  // File Preview Modal
  imagePreview: {
    objectFit: "contain",
    maxWidth: "96%",
    maxHeight: "96%",
    width: "auto",
    height: "auto",
    transition: "opacity 0.3s linear"
  },
  previewLoader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "250px",
    rowGap: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "bold",
    color: theme.palette.action.main
  },
  absoluteLoader: {
    position: "absolute",
    top: "0px"
  },
  downloadButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "120px",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  noPreview: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: "#999999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  pdfPage: {
    border: "1px solid #ccc",
    boxShadow: "4px 5px 3px 0px #CCCCCC",
    marginBottom: "14px"
  },
  mediaContentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%"
  },
  attachmentPreviewModal: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    width: "900px",
    height: "84vh",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  attachmentPreviewTitle: {
    fontFamily: "Source Sans Pro",
    fontSize: "30px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column"
  },
  attachmentPreviewContent: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    overflowY: "auto",
    marginTop: "36px"
  },
  attachmentPreviewActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
    width: "100%",
    columnGap: "16px",
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  fileLinkContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: "15px",
    "& a": {
      color: "#333333",
      fontSize: "15px",
      "&:hover": {
        color: "#000000",
        textDecoration: "none"
      }
    }
  },
  copyButton: {
    fontSize: "10px",
    fontWeight: "bold",
    backgroundColor: "#5BC4BF1F",
    color: theme.palette.action.main,
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.action.main,
      color: "#FFFFFF"
    }
  },
  docPreviewNavigation: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "16px"
  },
  downloadModalContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    rowGap: "20px",
    justifyContent: "center",
    color: theme.palette.primary.main
  },
  downloadModalDescription: {
    fontFamily: "Source Sans Pro",
    fontSize: "17px",
    color: "#939393",
    width: "100%"
  },
  primaryButtonAlternateColor: {
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  secondaryButtonAlternateColor: {
    backgroundColor: "#5BC4BF1F",
    "&:hover": {
      backgroundColor: "#5BC4BF35",
      border: "none"
    }
  }
};
