import React from "react";
import PropTypes from "prop-types";
import useStyles from "../useStyles";
import { IconButton } from "@material-ui/core";
import GestureIcon from "@material-ui/icons/Gesture";
function SignatureToggle({ signature, setSignature, userOptions }) {
  const classes = useStyles();
  function handleSignature() {
    signature ? setSignature("") : setSignature(userOptions.signature);
  }
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    IconButton,
    {
      classes: { root: classes.actionButton },
      onClick: handleSignature
    },
    /* @__PURE__ */ React.createElement(GestureIcon, { fontSize: "small", color: signature ? "primary" : "disabled" })
  ));
}
SignatureToggle.propTypes = {
  signature: PropTypes.string,
  setSignature: PropTypes.func.isRequired,
  userOptions: PropTypes.object.isRequired
};
export default SignatureToggle;
