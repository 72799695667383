import type { PointerEvent } from "react";
import { PointerSensor } from "@dnd-kit/core";

/**
 * An extended "PointerSensor" that prevent some
 * interactive html element(button, input, textarea, select, option...) from dragging
 */

export class QuestionCardSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown" as any,
      handler: ({ nativeEvent: event }: PointerEvent) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target as Element)
        ) {
          return false;
        }

        return true;
      },
    },
  ];
}

function isInteractiveElement(element: Element | null) {
  if (element?.dataset?.testid === "DragIndicatorIcon" 
      || element?.className?.baseVal.toLowerCase().includes("select")
    ) return false; 

  const interactiveElements = [
      "button",
      "input",
      "textarea",
      "select",
      "option",
      "icon",
      "svg",
      "path",
      "ul",
      "li",
      "span",
  ];
  if (
      element?.tagName &&
      interactiveElements.includes(element.tagName.toLowerCase())
  ) {
      return true;
  }

  return false;
}
