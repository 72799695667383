import React from "react";
import PropTypes from "prop-types";

export default class SearchResultsHeaderRow extends React.Component {
  static propTypes = {
    row: PropTypes.object,
    onSorting: PropTypes.func
  };

  isSortingAsc() {
    return this.context.sorting === this.props.row.id;
  }

  isSortingDesc() {
    return this.context.sorting === `-${this.props.row.id}`;
  }

  className() {
    const defaultClassName = `head-cell ${this.props.row.id}`;
    if (this.isSortingAsc()) {
      return `sorting-asc ${defaultClassName}`;
    }
    if (this.isSortingDesc()) {
      return `sorting-desc ${defaultClassName}`;
    }
    return defaultClassName;
  }

  render() {
    const { row } = this.props;
    return (
      <div className={this.className()}>
        <div className="title" onClick={() => this.props.onSorting(row.id)}>
          {row.name}
        </div>
      </div>
    );
  }
}

SearchResultsHeaderRow.contextTypes = {
  sorting: PropTypes.string
};
