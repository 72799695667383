import React from 'react';

export default function OpenedSVG() {
return (<svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
<g id="Group_578" data-name="Group 578" transform="translate(-657 -710)">
  <rect id="Rectangle_672" data-name="Rectangle 672" width="53" height="53" rx="16" transform="translate(657 710)" fill="#edf2f5"/>
  <g id="MDI_email-open" data-name="MDI / email-open" transform="translate(671.5 724.5)">
    <g id="Boundary" fill="#084d6d" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
      <rect width="24" height="24" stroke="none"/>
      <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
    </g>
    <path id="Path_email-open" data-name="Path / email-open" d="M4,8l8,5,8-5h0L12,3,4,8H4M22,8V18a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V8a1.991,1.991,0,0,1,.97-1.71L12,.64l9.03,5.65A1.991,1.991,0,0,1,22,8Z" transform="translate(0 1.36)" fill="#084d6d"/>
  </g>
</g>
</svg>)
}