import React from 'react';

export default function EventCircle() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.5" cy="8.5" r="8.5" fill="#EDF2F5" />
      <circle cx="8.5" cy="8.5" r="5.5" fill="#D6DEE2" />
      <circle cx="8.5" cy="8.5" r="2.5" fill="#084D6D" />
    </svg>
  );
}
