import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import DocTypeIcon from '../../../Components/Utilities/DocTypeIcon';
import { IJobAttachment } from '../../../Job/types';
import { classes } from './styles';
import Api from '../../../Job/API';

export default function Attachments({
  apiKey,
  jobId,
}: {
  apiKey: string;
  jobId: number;
}) {
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchInitialAttachments = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await Api.getJobAttachments({ 'X-api-authenticate': apiKey }, jobId, {
        page: 1,
        limit: 50
      });
      setAttachments(response.data.job_attachments);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, jobId]);

  useEffect(() => {
    fetchInitialAttachments();
  }, [fetchInitialAttachments]);

  return (
    <Box>
      <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
        <Table sx={classes.tableWrapper} aria-label="job attachments">
          <TableHead>
            {!isLoading && attachments?.length > 0 &&
              <TableRow>
                <TableCell>File</TableCell>
                <TableCell sx={{paddingLeft: '8px'}}>Public link</TableCell>
                <TableCell>Attached by</TableCell>
              </TableRow>
            } 
          </TableHead>
          <TableBody>
            {attachments &&
              !isLoading &&
              attachments.map((attachment: IJobAttachment) => (
                <TableRow key={attachment.id}>
                  <TableCell>
                    <Box sx={classes.fileIconContainer}>
                      <Box sx={{paddingRight: '20px'}}>
                        <Box sx={classes.fileIcon}>
                          {DocTypeIcon(attachment.attached_file_name.split('.').pop())}
                        </Box>
                      </Box>
                      <Box>
                        <Box sx={classes.fileDate}>
                          {moment(attachment.created_at).format('Do MMMM YYYY[,] h:mma')}
                        </Box>
                        <Box sx={classes.fileName}>{attachment.attached_file_name}</Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={classes.fileLinkContainer}>
                      <a href={attachment.public_link} target="_blank" rel="noopener noreferrer">
                        <Box>{attachment.public_link}</Box>
                      </a>
                    </Box>
                  </TableCell>
                  <TableCell sx={classes.fileAttachedBy}>{attachment.attached_by}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!isLoading && attachments?.length === 0 && (
          <Box sx={classes.noAttachments}>No attachments added</Box>
        )}
        {isLoading && (
          <>
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
          </>
        )}
      </TableContainer>
    </Box>
  );
}
