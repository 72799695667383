import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { FelixMessageSVG } from '../../SharedComponents/Graphics/FelixSVG';
import { IQuestion } from '../AIStudioQuestions';
import { classes } from '../styles';

const ResponseFooter = ({
  generatedResponse,
  setCurrentStep,
  handleSubmit,
  isCreating,
  addButtonText
}: {
  generatedResponse: IQuestion[] | undefined;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  handleSubmit: () => void;
  isCreating: boolean;
  addButtonText: string;
}) => {
  return (
    <Box sx={classes.footerContainer}>
      <Box sx={classes.felixMessageContainer}>
        <FelixMessageSVG />
        <Box sx={classes.felixMessage}>
          Ta-da! Your <span>screening questions</span> are ready for action, let&apos;s get that
          position filled!
        </Box>
      </Box>
      <Box
        sx={{
          ...classes.footerButtonsContainer,
          minWidth: '310px',
          justifyContent: 'flex-end',
          rowGap: 'none'
        }}
      >
        <Button
          id="back-to-edit-fields-screening-question-integration"
          sx={classes.footerBackButton}
          onClick={() => setCurrentStep(0)}
        >
          Back
        </Button>
        <Button
          id="add-generated-questions-to-job-button"
          sx={classes.footerAddButton}
          onClick={handleSubmit}
          disabled={
            generatedResponse && generatedResponse.filter((item) => item.selected).length < 1
          }
        >
          {isCreating ? <CircularProgress size={20} color="inherit" /> : addButtonText}
        </Button>
      </Box>
    </Box>
  );
};

export default ResponseFooter;
