import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Modal,
  Button,
  TextField,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styles } from '../styles';
import { IApproversData } from '../types';
import Api from '../API';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { MultilineFormTextField } from '../../Components/CustomUIElements/FormTextField';

export default function ActionModal({
  apiKey,
  approver,
  setModalsOpen,
  modalsOpen,
  setSnackbar,
  requisitionId,
  getApprovalFormData,
  index
}: {
  apiKey: string;
  approver: IApproversData;
  modalsOpen: boolean;
  setModalsOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbar: Dispatch<
    SetStateAction<{
      message: string;
      state: string;
    }>
  >;
  requisitionId: number;
  getApprovalFormData: () => void;
  index: number;
}) {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [approval, setApproval] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const handleClose = () => {
    setModalsOpen(false);
    setApproval('');
    setComment('');
  };

  const updateApprover = async () => {
    setIsUpdating(true);

    try {
      const response = await Api.putApprovalForm({ 'X-api-authenticate': apiKey }, requisitionId, {
        requisition: {
          approvers_attributes: {
            [index]: { id: approver.id, comment: comment }
          }
        },
        approval: approval
      });
      if (response.res.success) {
        setSnackbar({
          message: response.res.success,
          state: 'success'
        });
      }
    } catch (err) {
      setSnackbar({
        message: `Failed to update approver, ${err}`,
        state: 'error'
      });
    } finally {
      setIsUpdating(false);
      setModalsOpen(false);
      getApprovalFormData();
    }
  };

  return (
    <Modal open={modalsOpen}>
      <Box sx={{ ...styles.actionsModal, height: '640px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 0',
            minWidth: '255px'
          }}
        >
          <Box>
            <CloseIcon onClick={() => handleClose()} sx={styles.closeIcon} />
          </Box>
          <Box sx={styles.modalHeader}>Approve/decline Approval</Box>
        </Box>
        <Box>
          <Box sx={{ padding: '32px 0px' }}>
            <Autocomplete
              disablePortal
              options={['Approve', 'Decline']}
              getOptionLabel={(option) => option}
              value={approval || null}
              onChange={(_event, value) => (value ? setApproval(value) : setApproval(''))}
              sx={{ ...sharedClasses.formAutocomplete, width: '290px' }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro, sans-serif',
                  color: '#333333',
                  maxHeight: '200px'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Approve/decline"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Please select"
                />
              )}
            />
            <FormHelperText sx={{ margin: '3px' }}>This action cannot be reversed.</FormHelperText>
          </Box>
          <Box>
            <MultilineFormTextField
              value={approver.comment || comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              styles={{ width: '680px', margin: '20px, 0px' }}
              rows={6}
              label={'Comments'}
            />
          </Box>
        </Box>
        <Box sx={{ ...styles.modalActions, marginBottom: 2 }}>
          <Button
            disableElevation
            sx={{ ...styles.buttonRedLight }}
            variant="text"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            id="approval-form-action-button"
            type="submit"
            disabled={isUpdating}
            sx={styles.modalEditButton}
            onClick={() => updateApprover()}
          >
            {isUpdating ? <CircularProgress size={20} color="inherit" /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
