import React from 'react';

export default function ClicksSVG() {
return (<svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
<g id="Group_579" data-name="Group 579" transform="translate(-899 -710)">
  <rect id="Rectangle_674" data-name="Rectangle 674" width="53" height="53" rx="16" transform="translate(899 710)" fill="#edf2f5"/>
  <g id="MDI_cursor-default-click" data-name="MDI / cursor-default-click" transform="translate(912.333 723.833)">
    <g id="Boundary" fill="#084d6d" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
      <rect width="26.334" height="26.334" stroke="none"/>
      <rect x="0.5" y="0.5" width="25.334" height="25.334" fill="none"/>
    </g>
    <path id="Path_cursor-default-click" data-name="Path / cursor-default-click" d="M11.612,9.535a.834.834,0,0,0-.834.834V22.933a.834.834,0,0,0,.834.834.845.845,0,0,0,.527-.176l2.1-1.7,1.821,3.972a.835.835,0,0,0,.757.472.558.558,0,0,0,.362-.088l3.028-1.4a.815.815,0,0,0,.4-1.108L18.766,19.75l2.644-.494a.965.965,0,0,0,.472-.285.824.824,0,0,0-.132-1.185l-9.59-8.065-.011.011a.829.829,0,0,0-.538-.2m4.652,1.437V8.778H21.75v2.194H16.264M14.98,5.223l3.105-3.105,1.547,1.547L16.528,6.77,14.98,5.223M10.778,0h2.194V5.486H10.778V0M4.118,16.086,7.223,12.98,8.77,14.528,5.665,17.633,4.118,16.086m0-12.421L5.665,2.118,8.77,5.223,7.223,6.77,4.118,3.665m3.369,7.308H2V8.778H7.486v2.194" transform="translate(1.292)" fill="#084d6d"/>
  </g>
</g>
</svg>)
}