import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, CardContent, Grid, Box, CardActions, Button, Tooltip, IconButton } from "@mui/material";
import TextInput from "../../../inputs/TextInput/TextInput";
import Checkbox from "../../../inputs/Checkbox/Checkbox";
import Select from "../../../inputs/Select";
import HelpIcon from "@mui/icons-material/Help";
import { classes } from "../styles";
const THRESHOLD_DIRECTION_OPTS = [{
  value: "<=",
  label: "Less than"
}, {
  value: ">=",
  label: "Greater than"
}];
export default function KpiSettingsCard({ title, visible, onChange, good, bad, move, tooltip, good_direction, bad_direction }) {
  return /* @__PURE__ */ React.createElement(Card, { variant: "outlined", sx: { maxWidth: "320px" } }, /* @__PURE__ */ React.createElement(CardContent, null, /* @__PURE__ */ React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" }, /* @__PURE__ */ React.createElement(Grid, { item: true }, /* @__PURE__ */ React.createElement(Tooltip, { title: tooltip }, /* @__PURE__ */ React.createElement(IconButton, { size: "small" }, /* @__PURE__ */ React.createElement(HelpIcon, { fontSize: "small" })))), /* @__PURE__ */ React.createElement(Grid, { item: true, xs: true }, /* @__PURE__ */ React.createElement(Typography, { sx: { fontFamily: "Source Sans Pro" } }, title)), /* @__PURE__ */ React.createElement(Grid, { item: true }, /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      onCheck: (checked) => onChange("visible", checked),
      checked: visible
    }
  ))), /* @__PURE__ */ React.createElement(Box, { mb: 1 }), /* @__PURE__ */ React.createElement(Grid, { container: true, spacing: 1, sx: { marginBottom: "14px" } }, /* @__PURE__ */ React.createElement(Grid, { item: true, sx: { width: "50%" } }, /* @__PURE__ */ React.createElement(
    Select,
    {
      options: THRESHOLD_DIRECTION_OPTS,
      value: bad_direction,
      onChange: (selected) => onChange("bad_direction", selected),
      valueField: "value",
      labelField: "label"
    }
  )), /* @__PURE__ */ React.createElement(Grid, { item: true, xs: true }, /* @__PURE__ */ React.createElement(
    TextInput,
    {
      label: "Bad Threshold",
      value: bad,
      onChange: (val) => onChange("bad", val),
      type: "number",
      variant: "standard"
    }
  ))), /* @__PURE__ */ React.createElement(Box, { mb: 1 }), /* @__PURE__ */ React.createElement(Grid, { container: true, spacing: 1 }, /* @__PURE__ */ React.createElement(Grid, { item: true, sx: { width: "50%" } }, /* @__PURE__ */ React.createElement(
    Select,
    {
      options: THRESHOLD_DIRECTION_OPTS,
      value: good_direction,
      onChange: (selected) => onChange("good_direction", selected),
      valueField: "value",
      labelField: "label"
    }
  )), /* @__PURE__ */ React.createElement(Grid, { item: true, xs: true }, /* @__PURE__ */ React.createElement(
    TextInput,
    {
      label: "Good Threshold",
      value: good,
      onChange: (val) => onChange("good", val),
      type: "number",
      variant: "standard"
    }
  )))), /* @__PURE__ */ React.createElement(CardActions, { sx: { display: "flex", justifyContent: "space-around", width: "100%" } }, /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "small",
      variant: "text",
      onClick: () => move(true),
      sx: classes.moveButton
    },
    "Move Forward"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "small",
      variant: "text",
      onClick: () => move(false),
      sx: classes.moveButton
    },
    "Move Back"
  )));
}
KpiSettingsCard.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onChange: PropTypes.func,
  good: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bad: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  move: PropTypes.func,
  tooltip: PropTypes.string,
  bad_direction: PropTypes.string,
  good_direction: PropTypes.string
};
