"use strict";
import { translate } from "./convertToLocalEnglish/internals/translate";
export const convertToLocalEnglish = (input) => {
  const locale = navigator.language;
  if (locale === "en-US") {
    return translate(input, { american: true });
  } else {
    return translate(input);
  }
};
