"use strict";
export const handleRichCopy = (ref, setCopied) => {
  var _a, _b, _c;
  if (!ref) return;
  setCopied(true);
  setTimeout(() => setCopied(false), 3e3);
  (_a = window == null ? void 0 : window.getSelection()) == null ? void 0 : _a.removeAllRanges();
  const range = document.createRange();
  range.selectNode(ref);
  (_b = window == null ? void 0 : window.getSelection()) == null ? void 0 : _b.addRange(range);
  document.execCommand("copy");
  (_c = window == null ? void 0 : window.getSelection()) == null ? void 0 : _c.removeAllRanges();
};
