import React, { useState, useEffect } from 'react';
import { theme } from '../../../ThemeContext/ThemeObject';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// import TextField from '@mui/material/TextField';
import { sharedClasses } from './sharedClasses';
import { IApplicantQuestion } from '../Utilities/QuestionFieldGenerator';
import { MultilineFormTextField } from './FormTextField';

export default function FormRadioButtonWithTextInputGroup({
  options,
  onChange,
  defaultValue,
  groupLabel,
  directionRow,
  innerRef,
  error,
  required,
  color,
  draggable,
  question,
  inputText,
  setApprovalFormQuestions
}: {
  options: { label: string; value: string | number }[];
  onChange: (value: string | number) => void;
  directionRow?: boolean;
  defaultValue?: string | number;
  groupLabel?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  error?: string;
  color?: string;
  required?: boolean;
  draggable?: boolean;
  question: IApplicantQuestion;
  inputText: string;
  setApprovalFormQuestions?: (value: Record<string, { data: string; inputText: string }>) => void;
}) {
  const [value, setValue] = useState(defaultValue || '');
  // const [inputValue, setInputValue] = useState('');

  const onInternalChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  const radioStyle = {
    color: '#DDDDDD',
    '&.Mui-checked': {
      color: theme.palette.action.main
    },
    '& .MuiSvgIcon-root': {
      fontSize: '22px'
    }
  };

  const labelStyle = {
    '& .MuiTypography-root': {
      color: color ? color : '#333333 !important',
      fontSize: '16px',
      marginLeft: '2px',
      fontFamily: 'Source Sans Pro, sans-serif'
    }
  };

  useEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  return (
    <FormControl>
      <RadioGroup
        ref={innerRef}
        aria-labelledby={groupLabel?.toLowerCase().split(' ').join('-')}
        name="radio-buttons-group"
        value={value}
        onChange={(e) => onInternalChange(e.target.value)}
        sx={{ padding: '8px 0px 10px 12px', flexDirection: directionRow ? 'row' : 'column' }}
      >
        {options.map((option, index) => {
          return (
            <>
              <FormControlLabel
                id={option.label.toLowerCase().split(' ').join('-')}
                key={index}
                value={draggable ? 'disabled' : option.value}
                control={<Radio sx={radioStyle} />}
                label={option.label}
                sx={labelStyle}
                disabled={draggable ? true : false}
              />
              {options.findIndex((option) => Number(value) === option.value) === index &&
                index != 0 && (
                  <MultilineFormTextField
                    rows={4}
                    key={index}
                    value={inputText}
                    onChange={(e) => {
                      setApprovalFormQuestions((prev) => ({
                        ...prev,
                        [question.id]: {
                          ...prev[question.id],
                          inputText: e.target.value
                        }
                      }));
                    }}
                    styles={{ marginLeft: '30px', width: 'calc(100% - 50px)' }}
                    required={question.required}
                    placeholder={
                      'Please type your selected response into this box and insert names and details where required'
                    }
                  />
                )}
            </>
          );
        })}
        {error && <Box sx={sharedClasses.errorBoxCheckbox}>{error}</Box>}
      </RadioGroup>
    </FormControl>
  );
}
