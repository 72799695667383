import React from 'react';
import moment from 'moment-timezone';
const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
const FormattedDate = ({ date }: { date: string }) => {
  return (
    <>
      {moment
        .tz(date, timeZoneString)
        .format('DD MMM YYYY,-h:mma z')
        .split('-')
        .map((line) => (
          <div key={line}>{line}</div>
        ))}
    </>
  );
};

export default FormattedDate;
