import React, { useState, useRef, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { classes } from './styles';
import { INewApprovalOptions } from '../types';
import { IGetApprovalPublicLinks, IApprovalPublicLink } from '../ApprovalTemplates/types';
import Api from '../API';
import { DebouncedFormTextField } from '../../Components/CustomUIElements/FormTextField';
import { StyledSnackbarProps } from '../../Components/CustomUIElements/StyledSnackbar';

export default function NewApprovalPublicLink({
  apiKey,
  newApprovalOptions,
  setNewApprovalOptions,
  isPublicLinkDialogOpen,
  setIsPublicLinkDialogOpen,
  getApprovalPublicLinks,
  setSnackbarState,
  editingApprovalPublicLink,
  setEditingApprovalPublicLink
}: {
  apiKey: string;
  newApprovalOptions: INewApprovalOptions;
  setNewApprovalOptions: (approvals: INewApprovalOptions) => void;
  isPublicLinkDialogOpen: boolean;
  setIsPublicLinkDialogOpen: Dispatch<SetStateAction<boolean>>;
  getApprovalPublicLinks: IGetApprovalPublicLinks;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
  editingApprovalPublicLink?: IApprovalPublicLink;
  setEditingApprovalPublicLink?: Dispatch<SetStateAction<IApprovalPublicLink | null>>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [approvalTemplate, setApprovalTemplate] = useState(null);
  const [publicLinkName, setPublicLinkName] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const fieldRefs = {
    approvalTemplate: useRef<HTMLInputElement>(null)
  };

  const fetchApprovalTemplates = useCallback(async () => {
    setIsLoading(true);
    try {
      const { res } = await Api.getApprovalTemplates(
        { 'X-api-authenticate': apiKey },
        {
          archived: false,
          override_limit: true,
          limit: 500,
          sort: 'name',
          sort_order: 'ASC'
        }
      );
      setNewApprovalOptions(res?.requisition_forms.filter((form) => form.name.trim() !== ''));
    } catch (error) {
      setSnackbarState({
        message: error?.errors,
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiKey]);

  const handleApprovalPublicLinkAction = async () => {
    setIsCreating(true);
    const approvalPublicLinkObj = {
      name: publicLinkName || editingApprovalPublicLink?.name,
      requisition_form_id: approvalTemplate?.id || editingApprovalPublicLink?.approval_template_id,
    };
    try {
      const response = editingApprovalPublicLink ? await Api.updateApprovalPublicLink(
        { 'X-api-authenticate': apiKey },
        editingApprovalPublicLink?.id,
        {
          requisition_public_link: approvalPublicLinkObj
        }
      ) : await Api.createApprovalPublicLink(
        { 'X-api-authenticate': apiKey },
        {
          requisition_public_link: approvalPublicLinkObj
        }
      );
      if (response.res?.success) {
        setSnackbarState({
          message: response.res?.success,
          state: 'success'
        });
      }
    } catch (error) {
      setSnackbarState({
        message: error?.error,
        state: 'error'
      });
    } finally {
      setIsCreating(false);
      setIsPublicLinkDialogOpen(false);
      getApprovalPublicLinks();
    }
  };

  useEffect(() => {
    newApprovalOptions?.length === 0 && !isLoading && fetchApprovalTemplates();
  }, [fetchApprovalTemplates]);

  useEffect(() => {
    setPublicLinkName('');
    setApprovalTemplate(null);
  }, [isPublicLinkDialogOpen]);

  return (
    <Box>
      <Modal
        open={isPublicLinkDialogOpen}
        onClose={() => {
          setIsPublicLinkDialogOpen(false);
          setEditingApprovalPublicLink(null);
        }}
        aria-labelledby="new-approval-public-link-modal"
      >
        <Box sx={classes.actionsModal}>
          <Box sx={{ padding: '12px 0px 30px 0px' }}>
            <Box id="new-approval-modal-title" sx={classes.modalHeader}>
              {`${editingApprovalPublicLink ? 'Edit' : 'New'} approval public link`}
            </Box>
          </Box>
          <Box sx={{ marginTop: '1.75rem' }}>
            <Box sx={{ ...classes.modalFormLine, marginBottom: '40px !important' }}>
              <Box sx={{ position: 'relative' }}>
                <DebouncedFormTextField
                  onDebouncedChange={(value: string) => {
                    setPublicLinkName(value);
                  }}
                  id="public_link_name"
                  label="Name"
                  defaultValue={editingApprovalPublicLink?.name}
                  required
                  styles={{ width: '250px' }}
                />
              </Box>
            </Box>
            <Box sx={classes.modalFormLine}>
              <Autocomplete
                disablePortal
                options={newApprovalOptions}
                ref={fieldRefs.approvalTemplate}
                getOptionLabel={(option) => option?.name}
                id="approval-templates"
                loading={newApprovalOptions?.length < 1}
                loadingText={
                  isLoading ? 'Loading approval templates...' : 'No approval templates'
                }
                value={approvalTemplate || newApprovalOptions.find(template => template.id === editingApprovalPublicLink?.approval_template_id)}
                onChange={(event, value) => setApprovalTemplate(value)}
                sx={{ width: '400px', ...sharedClasses.formAutocomplete }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select an approval template"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box>
            <CloseIcon
              onClick={() => {
                setIsPublicLinkDialogOpen(false);
                setEditingApprovalPublicLink(null);
              }}
              sx={classes.closeIcon}
            />
            <Box sx={classes.modalActions}>
              <Button
                disableElevation
                sx={{ ...classes.buttonRedLight }}
                variant="text"
                onClick={() => {
                  setIsPublicLinkDialogOpen(false);
                  setEditingApprovalPublicLink(null);
                }}
              >
                Cancel
              </Button>
              <Button
                id="submit-new-approval-button"
                type="submit"
                sx={classes.modalEditButton}
                disabled={approvalTemplate === null && editingApprovalPublicLink === null}
                onClick={handleApprovalPublicLinkAction}
              >
                {isCreating ? <CircularProgress size={20} color="inherit" /> : (editingApprovalPublicLink ? 'Update' : 'Save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
