import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from './styles';

// pdf.js worker needed for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface IJobAttachment {
  id: number;
  attached_content_type: string;
  attached_file_name: string;
  created_at: string;
  public_link?: string;
  url?: string;
  attached_by?: string;
  updated_at?: string;
  application_link?: string;
}

export default function FilePreviewModal({
  previewOpen,
  setPreviewOpen,
  selectedAttachment,
  isShareable,
  applicationLink
}: {
  previewOpen: boolean;
  setPreviewOpen: (open: boolean) => void;
  selectedAttachment: IJobAttachment | null;
  isShareable: boolean;
  applicationLink?: string;
}) {
  const BASE_URL = window.location.origin;
  const [numPages, setNumPages] = useState(0);
  const [copied, setCopied] = useState(false);
  const [text, setText] = useState('');
  const [preview, setPreview] = useState({
    url: '',
    prev: false,
    next: false,
    index: 0
  });
  const [isLoadingPreview, setIsLoadingPreview] = useState(true);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const handleClose = () => {
    setPreviewOpen(false);
    setIsLoadingPreview(true);
  };

  const getText = async () => {
    try {
      const response = await fetch(`${BASE_URL}/admin/assets/${selectedAttachment?.id}`);
      const text = await response.text();
      setText(text);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingPreview(false);
    }
  };

  const getPreveiwImage = async (id: number, link: string, index: number) => {
    setIsLoadingPreview(true);
    try {
      const response = await fetch(`${link}/assets/${id}/preview?index=${index}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-csrf-token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
        }
      }).then((response) => response.json());
      setPreview({
        url: response.data,
        prev: response.prev,
        next: response.next,
        index: index
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingPreview(false);
    }
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (applicationLink && selectedAttachment.id && selectedAttachment.previewable)
      getPreveiwImage(selectedAttachment.id, applicationLink, 0);
  }, [applicationLink, selectedAttachment]);

  const handleDisplayContent = () => {
    if (!selectedAttachment) return null;
    if (selectedAttachment.attached_content_type?.split('/')[0] === 'image') {
      return (
        <>
          <img
            src={`${BASE_URL}/admin/assets/${selectedAttachment.id}`}
            alt={selectedAttachment.attached_file_name}
            style={{
              ...(classes.imagePreview as React.CSSProperties),
              opacity: isLoadingPreview ? 0 : 1
            }}
            onLoad={() => setIsLoadingPreview(false)}
          />
          {isLoadingPreview && (
            <Box sx={{ ...classes.previewLoader, ...classes.absoluteLoader }}>
              <CircularProgress sx={{ color: '#084D6D' }} />
              Loading Preview
            </Box>
          )}
        </>
      );
    } else if (selectedAttachment.attached_content_type?.split('/')[0] === 'video') {
      return (
        <Box sx={classes.mediaContentContainer}>
          <video width="100%" controls>
            <source src={`${BASE_URL}/admin/assets/${selectedAttachment.id}`} />
            Your browser does not support the video tag.
          </video>
        </Box>
      );
    } else if (selectedAttachment.attached_content_type?.split('/')[0] === 'audio') {
      return (
        <Box sx={classes.mediaContentContainer}>
          <audio controls>
            <source src={`${BASE_URL}/admin/assets/${selectedAttachment.id}`} />
            Your browser does not support the audio tag.
          </audio>
        </Box>
      );
    } else if (selectedAttachment.attached_content_type === 'text/plain') {
      getText();
      return (
        <>
          <Box>
            <pre>{text}</pre>
          </Box>
          {isLoadingPreview && (
            <Box sx={{ ...classes.previewLoader, ...classes.absoluteLoader }}>
              <CircularProgress sx={{ color: '#084D6D' }} />
              Loading Preview
            </Box>
          )}
        </>
      );
    } else if (selectedAttachment.attached_content_type?.split('/')[1] === 'pdf') {
      return (
        <Box>
          <Document
            file={`${BASE_URL}/admin/assets/${selectedAttachment.id}`}
            loading={
              <Box sx={classes.previewLoader}>
                <CircularProgress sx={{ color: '#084D6D' }} />
                Loading Preview
              </Box>
            }
            onLoadSuccess={onDocumentLoadSuccess}
            style={{ paddingBottom: '5px' }}
          >
            {[...Array(numPages)].map((_, page) => (
              <Box sx={classes.pdfPage} key={page}>
                <Page pageNumber={page + 1} renderTextLayer={false} renderAnnotationLayer={false} />
              </Box>
            ))}
          </Document>
        </Box>
      );
    } else if (
      (selectedAttachment.attached_content_type?.split('/')[1] === 'msword' ||
        selectedAttachment.attached_file_name.split('.').pop() === 'odt' ||
        selectedAttachment.attached_content_type?.split('/')[1] ===
          'vnd.openxmlformats-officedocument.wordprocessingml.document') &&
      selectedAttachment.previewable
    ) {
      return (
        <>
          {preview.url ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={classes.docPreviewNavigation}>
                {preview.prev && (
                  <Button
                    sx={classes.downloadButton}
                    onClick={() =>
                      getPreveiwImage(selectedAttachment.id, applicationLink, preview.index - 1)
                    }
                  >
                    Previous
                  </Button>
                )}
                {preview.next && (
                  <Button
                    sx={classes.downloadButton}
                    onClick={() =>
                      getPreveiwImage(selectedAttachment.id, applicationLink, preview.index + 1)
                    }
                  >
                    Next
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                  overflowY: 'auto'
                }}
              >
                <img
                  src={preview.url || ''}
                  alt={selectedAttachment.attached_file_name}
                  style={{ ...classes.imagePreview, minHeight: '1000px' }}
                />
              </Box>
            </Box>
          ) : (
            !isLoadingPreview && <Box sx={classes.noPreview}>No preview available.</Box>
          )}
          {isLoadingPreview && (
            <Box sx={{ ...classes.previewLoader, ...classes.absoluteLoader }}>
              <CircularProgress sx={{ color: '#084D6D' }} />
              Loading Preview
            </Box>
          )}
        </>
      );
    } else {
      return <Box sx={classes.noPreview}>No preview available for this file type.</Box>;
    }
  };

  return (
    <Modal open={previewOpen} onClose={handleClose} aria-labelledby="file-preview-modal">
      <Box sx={classes.attachmentPreviewModal}>
        <CloseIcon
          id="close-attachment-preview-button"
          onClick={handleClose}
          sx={classes.closeIcon}
          className="close-button"
        />
        <Box id="file-preview-modal-title" sx={classes.attachmentPreviewTitle}>
          {selectedAttachment?.attached_file_name}
          {isShareable && (
            <Box sx={{ ...classes.fileLinkContainer, marginTop: '8px' }}>
              <a href={selectedAttachment?.public_link} target="_blank" rel="noopener noreferrer">
                <Box sx={{ fontWeight: 'regular', color: '#999999' }}>
                  {selectedAttachment?.public_link}
                </Box>
              </a>
              <CopyToClipboard text={selectedAttachment?.public_link} onCopy={() => handleCopy()}>
                <Button sx={classes.copyButton}>{copied ? 'Copied!' : 'Copy'}</Button>
              </CopyToClipboard>
            </Box>
          )}
        </Box>
        <Box sx={classes.attachmentPreviewContent}>{handleDisplayContent()}</Box>
        <Box sx={classes.attachmentPreviewActions}>
          <a href={`${BASE_URL}/admin/assets/${selectedAttachment?.id}`}>
            <Button id="download-attachment-button" sx={classes.downloadButton}>
              Download
            </Button>
          </a>
        </Box>
      </Box>
    </Modal>
  );
}
