import React from 'react';
import { Box, FormControl, MenuItem, Pagination, Select } from '@mui/material';
import { styles } from './styles';

const PaginationWithNumberOfRowsSelection = ({ state, dispatch }) => {
  const { limit, currentPage, maxPages, maxRecords } = state;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '16px'
      }}
    >
      <Box sx={styles.rowSelectContainer}>
        <span>Rows per page: </span>
        <FormControl>
          <Select
            id="rows-per-page"
            sx={styles.rowSelect}
            value={limit}
            onChange={(e) =>
              dispatch({ type: 'SET_LIMIT', payload: parseInt(e.target.value as string, 10) })
            }
            displayEmpty
            MenuProps={{ sx: styles.paginationMenuItems }}
          >
            <MenuItem id="first-rpp-item" value={10}>
              10
            </MenuItem>
            <MenuItem id="second-rpp-item" value={20}>
              20
            </MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={40}>40</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={styles.paginationContainer}>
        <Pagination
          id="pagination-menu"
          count={maxPages}
          page={currentPage}
          defaultPage={Math.ceil(maxPages / 2)}
          siblingCount={0}
          onChange={(e, v) => {
            if (v === currentPage) return;
            dispatch({ type: 'SET_CURRENT_PAGE', payload: v });
          }}
          sx={styles.pagination}
        />
        <span id="total-records">{maxRecords} Total</span>
      </Box>
    </Box>
  );
};

export default PaginationWithNumberOfRowsSelection;
