import React, { Component } from 'react';
import T from 'prop-types';

export default class DataColumn extends Component {
  static propTypes = {
    data: T.node // T.oneOf(['string', 'number', 'date'])
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (<td>{this.props.data}</td>)
  }
}
