import React from 'react';

export default function NoQuestionSVG() {
return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="106.125" height="131.719" viewBox="0 0 106.125 131.719">
<defs>
  <clipPath id="clip-path">
    <rect id="Rectangle_775" data-name="Rectangle 775" width="106.125" height="131.719" fill="none"/>
  </clipPath>
</defs>
<g id="Group_587" data-name="Group 587" transform="translate(-902.121 -666.15)">
  <g id="Group_586" data-name="Group 586" transform="translate(902.121 666.15)">
    <g id="Group_585" data-name="Group 585" clip-path="url(#clip-path)">
      <path id="Path_232" data-name="Path 232" d="M98.892,28.023l0,95.46a4.412,4.412,0,0,1-4.419,4.4l-90.051,0A4.412,4.412,0,0,1,0,123.481V107.9l.074-8.585,0-94.914A4.412,4.412,0,0,1,4.5,0l87.5,0a6.9,6.9,0,0,1,6.9,6.9V28.023Z" fill="#eee"/>
      <path id="Path_233" data-name="Path 233" d="M109.948,34.73l0,95.46a4.412,4.412,0,0,1-4.419,4.405l-90.051,0a4.412,4.412,0,0,1-4.419-4.405V114.611l.074-8.585,0-94.914a4.412,4.412,0,0,1,4.419-4.405l87.5,0a6.9,6.9,0,0,1,6.9,6.9V34.73Z" transform="translate(-8.685 -5.269)" fill="#f9f9f9"/>
      <path id="Path_234" data-name="Path 234" d="M107.477,33.918q0,5.843,0,11.687,0,13.491,0,26.982,0,15.22,0,30.44,0,11.054,0,22.108c0,1.38.031,2.764,0,4.144a3.979,3.979,0,0,1-3.718,4.128c-.388.015-.78,0-1.168,0H26.623c-4.281,0-8.594.151-12.873,0-2.635-.093-4.129-2.306-4.128-4.75V122.8c0-3.361-.022-6.722.007-10.082q.057-6.582.068-13.162,0-11.627,0-23.254,0-15.255,0-30.509,0-13.074,0-26.147,0-3.79,0-7.579c0-2.284-.1-5,2.628-5.908a14.371,14.371,0,0,1,4.219-.167h83.6a9.1,9.1,0,0,1,2.13.169,6.889,6.889,0,0,1,5.2,6.759c.048,4.09,0,8.184,0,12.275q0,4.36,0,8.72c0,.219,1.036.057,1.036-.277,0-7.036.127-14.083,0-21.118a7.1,7.1,0,0,0-5.82-6.882,11.409,11.409,0,0,0-2.03-.12H16.479c-1.644,0-3.444-.186-4.974.518a4.781,4.781,0,0,0-2.841,4.389c-.006.489,0,.979,0,1.469q0,9.436,0,18.872,0,15.1,0,30.206,0,14.464,0,28.927,0,7.518,0,15.037c-.01,6.361-.074,12.72-.075,19.081,0,1.74-.035,3.484,0,5.223a4.655,4.655,0,0,0,4.723,4.641c1.2.028,2.409,0,3.613,0h85.587c.378,0,.761.015,1.139-.009a5,5,0,0,0,4.863-4.788c.113-4.206,0-8.431,0-12.638q0-13.96,0-27.919,0-15.37,0-30.739v-21.1q0-1.523,0-3.047c0-.219-1.036-.057-1.036.277" transform="translate(-6.732 -4.318)" fill="#cfcfcf"/>
      <path id="Path_235" data-name="Path 235" d="M129.948,44.73l0,95.46a4.412,4.412,0,0,1-4.419,4.405l-90.051,0a4.412,4.412,0,0,1-4.419-4.405V124.611l.074-8.585,0-94.914a4.412,4.412,0,0,1,4.419-4.405l87.5,0a6.9,6.9,0,0,1,6.9,6.9V44.73Z" transform="translate(-24.397 -13.125)" fill="#f9f9f9"/>
      <path id="Path_236" data-name="Path 236" d="M127.178,55.845l0,95.981a4.277,4.277,0,0,1-4.258,4.3l-87.6,0a4.278,4.278,0,0,1-4.258-4.3V136.629l.071-8.374,0-92.579a4.278,4.278,0,0,1,4.258-4.3l87.962,0a3.8,3.8,0,0,1,3.826,3.773Z" transform="translate(-24.398 -24.652)" fill="#fff"/>
      <path id="Path_237" data-name="Path 237" d="M127.478,43.918q0,5.843,0,11.687,0,13.491,0,26.982,0,15.22,0,30.44,0,11.054,0,22.108c0,1.38.031,2.764,0,4.144a3.979,3.979,0,0,1-3.718,4.128c-.388.015-.78,0-1.168,0H46.623c-4.281,0-8.594.151-12.873,0-2.635-.093-4.129-2.306-4.128-4.75V132.8c0-3.361-.022-6.722.007-10.082q.057-6.582.068-13.162,0-11.627,0-23.254,0-15.255,0-30.509,0-13.074,0-26.147,0-3.79,0-7.579c0-2.284-.1-5,2.628-5.908a14.371,14.371,0,0,1,4.219-.167h83.6a9.1,9.1,0,0,1,2.13.169,6.889,6.889,0,0,1,5.2,6.759c.048,4.09,0,8.184,0,12.275q0,4.36,0,8.72c0,.219,1.036.057,1.036-.277,0-7.036.127-14.083,0-21.118a7.1,7.1,0,0,0-5.82-6.882,11.409,11.409,0,0,0-2.03-.12H36.479c-1.644,0-3.444-.186-4.974.518a4.781,4.781,0,0,0-2.841,4.389c-.006.489,0,.979,0,1.469q0,9.436,0,18.872,0,15.1,0,30.206,0,14.464,0,28.927,0,7.518,0,15.037c-.01,6.361-.074,12.72-.075,19.081,0,1.74-.035,3.484,0,5.223a4.655,4.655,0,0,0,4.723,4.641c1.2.028,2.409,0,3.613,0h85.587c.378,0,.761.015,1.139-.009a5,5,0,0,0,4.863-4.788c.113-4.206,0-8.431,0-12.638q0-13.96,0-27.919,0-15.37,0-30.739v-21.1q0-1.523,0-3.047c0-.219-1.036-.057-1.036.277" transform="translate(-22.444 -12.174)" fill="#cfcfcf"/>
      <path id="Path_259" data-name="Path 259" d="M358.778,506.84h6.332c.588,0,.8-.748.094-.748h-6.332c-.588,0-.8.748-.095.748" transform="translate(-281.501 -397.576)" fill="#fff"/>
      <path id="Path_261" data-name="Path 261" d="M246.408,200.5H172.726c-1.69,0-3.06-.8-3.06-1.778s1.37-1.778,3.06-1.778h73.682c1.69,0,3.06.8,3.06,1.778s-1.37,1.778-3.06,1.778" transform="translate(-152.539 -173.05)" fill="#efefef"/>
    </g>
  </g>
  <g id="Group_588" data-name="Group 588" transform="translate(925.322 708.289)">
    <g id="Group_589" data-name="Group 589" transform="translate(0)">
      <path id="Path_262" data-name="Path 262" d="M52.355.577h56.424c.467,0,.66-.577.086-.577H52.441c-.467,0-.661.577-.086.577" transform="translate(-42.917)" fill="#cfcfcf"/>
      <path id="Path_263" data-name="Path 263" d="M.355,30.382H63.189a23.607,23.607,0,0,0,2.562,0,1.028,1.028,0,0,1,.111,0c.467,0,.66-.577.086-.577H3.114a23.56,23.56,0,0,0-2.562,0,1.029,1.029,0,0,1-.111,0c-.467,0-.661.577-.086.577" transform="translate(0 -24.564)" fill="#cfcfcf"/>
      <path id="Path_264" data-name="Path 264" d="M.355,60.577h55.2c.467,0,.66-.577.086-.577H.441c-.467,0-.661.577-.086.577" transform="translate(0 -49.519)" fill="#cfcfcf"/>
      <path id="Path_265" data-name="Path 265" d="M48.262,90.577H105.4c.467,0,.661-.577.086-.577H48.348c-.467,0-.661.577-.086.577" transform="translate(-39.539 -74.278)" fill="#cfcfcf"/>
      <path id="Path_266" data-name="Path 266" d="M.355,120.382H63.189a23.6,23.6,0,0,0,2.562,0,1.029,1.029,0,0,1,.111,0c.467,0,.66-.577.086-.577H3.114a23.562,23.562,0,0,0-2.562,0,1.029,1.029,0,0,1-.111,0c-.467,0-.661.577-.086.577" transform="translate(0 -98.843)" fill="#cfcfcf"/>
      <path id="Path_267" data-name="Path 267" d="M.355,150.577H55.189c.467,0,.66-.577.086-.577H.441c-.467,0-.661.577-.086.577" transform="translate(0 -123.797)" fill="#cfcfcf"/>
    </g>
  </g>
</g>
</svg>
)
}
