import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DoneIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { INoteProps } from './types';
import { classes } from '../../Job/Applications/styles';

const NotePopover = ({
  setApprovalTemplates,
  anchorEl,
  handleCloseNote,
  approvalTemplateId,
  approvalTemplateNote,
  apiKey,
  setSnackbarState
}: INoteProps) => {
  const [noteBody, setNoteBody] = useState(approvalTemplateNote || '');
  const [savingNote, setSavingNote] = useState<boolean>(false);

  async function saveNote() {
    setSavingNote(true);
    try {
      const response = await fetch(`/api/v4/requisition_forms/${approvalTemplateId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        },
        body: JSON.stringify({
          requisition_form: {
            note: noteBody
          }
        })
      }).then((res) => res.json());
      if (response) {
        setApprovalTemplates((prevApprovalTemplates) => {
          const newApprovalTemplates = [...prevApprovalTemplates];
          const approvalTemplateIndex = newApprovalTemplates.findIndex(
            (approvalTemplate) => approvalTemplate.id === approvalTemplateId
          );
          newApprovalTemplates[approvalTemplateIndex].note = noteBody;
          return newApprovalTemplates;
        });
      }
    } catch (error) {
      setSnackbarState({
        message: `Error saving note, ${error}`,
        state: 'error'
      });
    } finally {
      handleCloseNote();
    }
  }

  return (
    <Popover
      id={anchorEl ? 'edit-note-popover' : undefined}
      sx={classes.editNotePopover}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleCloseNote}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <>
        <Box sx={classes.notesTitle}>Add approval template note</Box>
        <TextField
          id="note-input"
          sx={classes.noteInput}
          label="Note"
          multiline
          fullWidth
          maxRows={4}
          variant="outlined"
          defaultValue={approvalTemplateNote || noteBody}
          onChange={(e) => setNoteBody(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Box id="notes-button-container" sx={classes.notesButtonContainer}>
          <Button sx={classes.notesCancelButton} endIcon={<CloseIcon />} onClick={handleCloseNote}>
            Cancel
          </Button>
          <Button
            id="save-note-approval-templates-button"
            sx={classes.notesEditSaveButton}
            endIcon={savingNote ? <CircularProgress size={16} /> : <DoneIcon />}
            onClick={() => {
              saveNote();
            }}
            disabled={savingNote}
          >
            {approvalTemplateNote ? 'Update' : 'Save'}
          </Button>
        </Box>
      </>
    </Popover>
  );
};

export default NotePopover;
