import React, { useState, useEffect } from 'react';
import { theme } from '../../../ThemeContext/ThemeObject';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { sharedClasses } from './sharedClasses';

export default function FormRadioButtonGroup({
  options,
  onChange,
  defaultValue,
  groupLabel,
  directionRow,
  innerRef,
  error,
  required,
  color,
  draggable
}: {
  options: { label: string; value: string | number }[];
  onChange: (value: string | number) => void;
  directionRow?: boolean;
  defaultValue?: string | number;
  groupLabel?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  error?: string;
  color?: string;
  required?: boolean;
  draggable?: boolean;
}) {
  const [value, setValue] = useState(defaultValue || '');

  const onInternalChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  const radioStyle = {
    color: '#DDDDDD',
    '&.Mui-checked': {
      color: theme.palette.action.main
    },
    '& .MuiSvgIcon-root': {
      fontSize: '22px'
    }
  };

  const labelStyle = {
    '& .MuiTypography-root': {
      color: color ? color : '#333333 !important',
      fontSize: '14px',
      marginLeft: '2px',
      fontFamily: 'Source Sans Pro, sans-serif'
    }
  };

  useEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  return (
    <FormControl>
      {groupLabel && (
        <FormLabel
          id={groupLabel?.toLowerCase().split(' ').join('-')}
          data-testid={groupLabel?.toLowerCase().split(' ').join('-')}
          sx={{ ...sharedClasses.inputLabel, fontSize: '14px' }}
        >
          {groupLabel}
          {required && <span>*</span>}
        </FormLabel>
      )}
      <RadioGroup
        ref={innerRef}
        aria-labelledby={groupLabel?.toLowerCase().split(' ').join('-')}
        name="radio-buttons-group"
        value={value}
        onChange={(e) => onInternalChange(e.target.value)}
        sx={{ padding: '8px 0px 10px 12px', flexDirection: directionRow ? 'row' : 'column' }}
      >
        {options.map((option, index) => {
          return (
            <FormControlLabel
              id={option.label.toLowerCase().split(' ').join('-')}
              data-testid={option.label.toLowerCase().split(' ').join('-')}
              key={index}
              value={draggable ? 'disabled' : option.value}
              control={<Radio sx={radioStyle} />}
              label={option.label}
              sx={labelStyle}
              disabled={draggable ? true : false}
            />
          );
        })}
        {error && <Box sx={sharedClasses.errorBoxCheckbox}>{error}</Box>}
      </RadioGroup>
    </FormControl>
  );
}
