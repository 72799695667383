import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { classes } from '../styles';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import RequisitionInfoViewer from '../../../Components/Utilities/RequisitionInfoViewer';

export default function RequisitionInfo({
  apiKey,
  jobId,
  setSnackbar,
  requisitionInfoTabJobData,
  setRequisitionInfoTabJobData
}: {
  apiKey: string;
  jobId: number;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
}) {
  const [editable, setEditable] = useState(true);
  const [requisitionInfoId, setRequisitionInfoId] = useState();
  const [requisitionInfos, setRequisitionInfos] = useState([]);

  const getRequisitionInfos = useCallback(async () => {
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      if (response.requisition_info?.requisition_id) {
        setRequisitionInfoId(response.requisition_info.requisition_id);
        setEditable(false);
        setRequisitionInfos(response.requisition_info.answers);
      }
    } catch (error) {
      console.log(error);
    }
  }, [apiKey, jobId]);

  const createRequisitionInfos = async (id: number) => {
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/requisition_info?requisition_id=${id}`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setRequisitionInfos(response.requisition_info);
    } catch (error) {
      console.log(error);
    } finally {
      getRequisitionInfos();
    }
  };

  const updateRequisitionInfos = async () => {
    const attributes = {};
    requisitionInfos.map((requisition, index) => {
      if (['TextField', 'TextAreaField', 'DateField'].includes(requisition.type)) {
        attributes[index] = {
          data: requisition.answer,
          field_id: requisition.field_id,
          id: requisition.id
        };
      } else if (['SelectField', 'RadioButtonField'].includes(requisition.type)) {
        attributes[index] = {
          field_choice_id: requisition.answer,
          field_id: requisition.field_id,
          id: requisition.id
        };
      } else if (['CheckBoxField'].includes(requisition.type)) {
        attributes[index] = {
          field_choice_ids: requisition.answer,
          field_id: requisition.field_id,
          id: requisition.id
        };
      }
    });
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/requisition_info`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        },
        body: JSON.stringify({
          requisition_info: {
            answers_attributes: attributes
          }
        })
      }).then((response) => response.json());
      setSnackbar({
        message: 'Requisition info has been updated',
        state: 'success'
      });
    } catch (error) {
      console.log(error);
      setSnackbar({
        message: error.errors,
        state: 'error'
      });
    }
  };

  useEffect(() => {
    getRequisitionInfos();
  }, [getRequisitionInfos]);

  return (
    <Box sx={classes.tabContent}>
      <Box id="template-title" sx={classes.headerContent}>
        Requisition info
      </Box>
      <Box sx={classes.bodyContainer}>
        <Box sx={classes.modalFormLine}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Stack flexGrow="0.5" maxWidth="250px">
              <FormTextField
                draggable={!editable}
                value={requisitionInfoId}
                label={'Approval ID'}
                onChange={(e) => setRequisitionInfoId(e.target.value)}
                required={false}
              />
            </Stack>
            <Button
              id="get-requisition-info-button"
              sx={classes.modalCreateRequisitionButton}
              onClick={() => createRequisitionInfos(requisitionInfoId)}
              disabled={!editable}
            >
              Get requisition information
            </Button>
          </Box>
          {requisitionInfos && requisitionInfos.length > 0 && (
            <Box sx={classes.requisitionWrapper}>
              {requisitionInfos.map((requisition, index) => (
                <Box key={requisition.id}>
                  {requisition.type === 'AvailabilityField' ||
                  requisition.type === 'JobReferenceLookupField' ? (
                    <span />
                  ) : (
                    <Box sx={{ marginRight: 'auto', ...classes.requisitionInput }}>
                      <RequisitionInfoViewer
                        index={index}
                        requisition={requisition}
                        requisitionInfos={requisitionInfos}
                        setRequisitionInfos={setRequisitionInfos}
                        draggable={false}
                      />
                    </Box>
                  )}
                </Box>
              ))}
              <Button
                id="update-requisition-info-button"
                sx={classes.updateRequisitionButton}
                onClick={updateRequisitionInfos}
              >
                Update requisition information
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
