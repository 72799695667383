import React, { useState, useEffect, useRef, Dispatch, SetStateAction, useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { MultilineFormTextField, FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import FormSelectField from '../../../Components/CustomUIElements/FormSelectField';
import { StyledSnackbarProps } from '../../../Components/CustomUIElements/StyledSnackbar';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ClearIcon from '@mui/icons-material/Clear';
import { theme } from '../../../../ThemeContext/ThemeObject';
import Api from '../../../Job/API';

const fieldTypeOptions = [
  "Select",
  "Date",
  "Text",
  "Radio Button",
  "Rank",
  "Checkbox",
  "Textarea",
]

const fieldTypeValues = {
  "Select": 'select_field',
  "CSV Data Lookup": 'csv_data_lookup_field',
  "Date": 'date_field',
  "Text": 'text_field',
  "Radio Button": 'radio_button_field',
  "Rank": 'rank_field',
  "Job Reference Lookup": 'job_reference_lookup_field',
  "Availability": 'availability_field',
  "Checkbox": 'check_box_field',
  "Textarea": 'text_area_field',
}

const Date = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export default function NewField({
  apiKey,
  jobId,
  applicationQuestionTemplateId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  editingQuestion,
  getQuestions,
  canEnable
}: {
  apiKey: string;
  modalsOpen: Record<string, boolean>;
  setModalsOpen: Dispatch<SetStateAction<Record<string, boolean>>>;
  jobId: number;
  applicationQuestionTemplateId: number;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
  editingQuestion?: any;
  getQuestions: any;
  canEnable: boolean;
}) {
  const [isCreating, setIsCreating] = useState(false);
  const [fieldType, setFieldType] = useState('');
  const [label, setLabel]= useState("");
  const [enabledSwitch, setEnabledSwitch] = useState(true);
  const [choiceList, setChoiceList] = useState([{id: '', _destroy: 0, position: 0, name: ''}])
  const [weekDuration, setWeekDuration] = useState('weekly');
  const [weekStartDay, setWeekStartDay] = useState(0);
  const [headingOne, setHeadingOne] = useState('');
  const [headingTwo, setHeadingTwo] = useState('');
  const [allowBreak, setAllowBreak] = useState(false);
  const [errorLabel, setErrorLabel] = useState('');
  const [errorField, setErrorField] = useState('');
  const [errorChoices, setErrorChoices] = useState('');
  const [enableable, setEnableable] = useState(false);

  const labelRef = useRef<HTMLInputElement>(null);
  const fieldTypeRef = useRef<HTMLInputElement>(null);
  const choicesRef = useRef<HTMLInputElement>(null);

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }

  const setDefaultValue = useCallback(async () => {
    if (editingQuestion) {
      setFieldType(getKeyByValue(fieldTypeValues, editingQuestion.field_type));
      setLabel(editingQuestion.title);
      setEnabledSwitch(editingQuestion.enabled);
      setChoiceList(editingQuestion.field_choices);
      setEnableable(editingQuestion.enabled);
    } else {
      setEnableable(canEnable);
    }
  }, [canEnable, editingQuestion]);

  const handleClose = () => {
    setModalsOpen((prev) => ({ ...prev, newField: false }));
    clearData();
  };

  const handleSubmit = async (jobId: number) => {
    if(!fieldType || !label) {
      if (!fieldType) {
        fieldTypeRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setErrorField('This field is required');
      } else { 
        labelRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setErrorLabel('This field is required');
      }
      return;
    }
    setIsCreating(true);
    
    let fieldObject = {
      title: label,
      enabled: enableable && enabledSwitch,
      field_type: fieldTypeValues[fieldType],
    }

    if (['Select', 'Rank', 'Checkbox', 'Radio Button'].includes(fieldType)) {
      let isValid = true;
      choiceList.map((choice, index) => {
        if (!choice?.name) {
          choicesRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setErrorChoices('All field choices must each have a value');
          isValid = false;
        }
        choice.position = index;
      });
      if (!isValid) return;
      const jsonString = JSON.stringify(Object.assign({}, choiceList));
      const attributes = JSON.parse(jsonString);
      fieldObject['field_choices_attributes'] = attributes
    }
    else if (['Availability'].includes(fieldType)) {
      fieldObject['availability_config_attributes'] = {
        'week_duration': weekDuration.toLowerCase(),
        'week_start_day': Date.indexOf(weekStartDay),
        'heading_1': headingOne,
        'heading_2': headingTwo,
        'allow_break': allowBreak,
      }
    }

    try {
      if (editingQuestion) {
        const data = await Api.putHRQuestion(
          { 'X-api-authenticate': apiKey },
          editingQuestion.id,
          jobId,
          applicationQuestionTemplateId,
          {
            field: fieldObject
          }
        );
        setSnackbar({
          message:
            'Field has been updated',
          state: 'success'
        });
      }
      else {
        const data = await Api.newHRField(
          { 'X-api-authenticate': apiKey },
          jobId,
          applicationQuestionTemplateId,
          {
            field: fieldObject
          }
        );
        setSnackbar({
          message: 
            'Field has been created',
          state: 'success'
        });
        clearData();
      }
      handleClose();
    } catch (error) {
      console.log(error);
      setSnackbar({
        message: error.errors,
        state: 'error'
      });
    } finally {
      setIsCreating(false);
      getQuestions();
    }
  };

  const handleAddChoice = () => {
    setChoiceList([...choiceList, {id: '', _destroy: 0, position: 0, name: ''}]);
  };

  const onRemoveChoice = (index: number) => {
    const list = [...choiceList];
    list[index]._destroy = 1;
    setChoiceList(list);
  };

  const handleChoiceInput = (e, index: number) => {
    const {name, value} = e.target;
    const list = [...choiceList];
    list[index].name = value;
    setChoiceList(list);
  };

  const clearData = () => {
    setFieldType('');
    setLabel('');
    setEnabledSwitch(true);
    setChoiceList([{id: '', _destroy: 0, position: 0, name: ''}]);
    setWeekDuration('weekly');
    setWeekStartDay(0);
    setHeadingOne('');
    setHeadingTwo('');
    setAllowBreak(false);
    setErrorLabel('');
    setErrorField('');
    setErrorChoices('');
  };

  useEffect(() => {
    setDefaultValue();
  }, [setDefaultValue, editingQuestion]);

  return (
    <Modal
      open={modalsOpen.newField}
      onClose={() => handleClose(true)}
      aria-labelledby="new-field-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <Box sx={classes.modalWrapper}>
          <CloseIcon onClick={() => handleClose(true)} sx={classes.closeIcon} />
          <Box id="new-field-title" sx={classes.actionModalHeader}>
            New field
          </Box>
          <Box
            sx={classes.modalContent}
            id="new-field-modal-content"
            aria-label="New Field Modal Content"
          >
            <Box role="form" id="new-field-form" aria-label="New Field form">
              <Box sx={{ ...classes.actionModalFormLine, marginTop: '20px' }} ref={fieldTypeRef}>
                <Virtualize
                  value={fieldType}
                  passOptions={fieldTypeOptions}
                  setValue={(value: string) => {
                    setFieldType(value);
                    setErrorField('');
                  }}
                  passedStyles={{
                    ...sharedClasses.formAutocomplete,
                    width: '300px',
                    marginTop: '20px'
                  }}
                  label="Field type"
                />
                {errorField && <Box sx={{...classes.formError, top: '63px'}}>{errorField}</Box>}
              </Box>
            </Box>
            <Box sx={{ marginLeft: '15px' }}>
              <Box sx={classes.modalFormLine}>
                <MultilineFormTextField
                  value={label}
                  onChange={(e) => { 
                    setErrorLabel('');
                    setLabel(e.target.value)
                  }}
                  styles={{ width: '600px', marginTop: '20px' }}
                  rows={6}
                  innerRef={labelRef}
                  error={errorLabel}
                  label={'Field label'}
                />
              </Box>
            </Box>
            {!enableable && (
              <Box sx={{...classes.noticeContainer, maxWidth: '500px', marginTop: '0px'}}>
                <Box sx={classes.noticeHeader}>
                    <InfoIcon sx={classes.noticeIcon} />
                    Only one question can be enabled at a time.
                </Box>
              </Box>
            )}
            <Box sx={classes.checkboxContainer} id="enabled-filter">
              <FormControlLabel
                sx={
                  !enableable && enabledSwitch
                    ? { ...classes.switchLabels, ...classes.labelActive }
                    : classes.switchLabels
                }
                control={
                  <Switch
                    sx={enableable && enabledSwitch ? classes.switchActive : classes.switch}
                    checked={enableable && enabledSwitch}
                    onChange={() => setEnabledSwitch(!enabledSwitch)}
                    disabled={!enableable}
                  />
                }
                label="Enabled"
                labelPlacement="end"
              />
            </Box>
            {['Select', 'Rank', 'Checkbox', 'Radio Button'].includes(fieldType) &&
              <Box sx={classes.addChoiceContainer} ref={choicesRef}>
                <Box sx={classes.addChoiceHead}>
                  <Box id="add-choice-title" sx={classes.addChoiceLabel}>
                    Choices
                  </Box>
                  <Button
                    id="add-choice-button"
                    sx={classes.modalAddChoiceButton}
                    onClick={handleAddChoice}
                  >
                    Add choice
                  </Button>
                </Box>
                {choiceList.map((choice, index) => 
                  (!(choice?._destroy === 1) &&
                    <Box
                      key={index}
                      sx={classes.choice}
                    >
                      <FormTextField
                        value={choice.name}
                        onChange={(e) => handleChoiceInput(e, index)}
                        required={true}
                        styles={{ width: '250px' }}
                      />
                      <IconButton
                        onClick={() => onRemoveChoice(index)}
                        sx={{ marginLeft: '35px' }}
                      >
                        <ClearIcon sx={{ color: theme.palette.error.main }} />
                      </IconButton>
                    </Box>
                  )
                )}
                {errorChoices && <Box sx={{...classes.inlineError}}>{errorChoices}</Box>}
              </Box>
            }
            {['Availability'].includes(fieldType) &&
              <Box sx={classes.availabilityContainer}>
                <Box id="availability-config-title" sx={classes.addChoiceLabel}>
                  Availability Config
                </Box>
                <Box
                  sx={classes.config}
                >
                  <FormSelectField
                    options={['Weekly', 'Fortnightly']}
                    defaultValue="Weekly"
                    value={weekDuration}
                    onChange={(value) => setWeekDuration(value)}
                    label={'How long is your work period?'}
                    styles={{ width: '290px', marginBottom: '15px' }}
                    required={true}
                  />
                  <FormSelectField
                    options={Date}
                    defaultValue="Sunday"
                    value={weekStartDay}
                    onChange={(value) => {setWeekStartDay(value)}}
                    label={'When does the work period start?'}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                  <FormTextField
                    label='Heading 1'
                    value={headingOne}
                    onChange={(e) => setHeadingOne(e.target.value)}
                    required={false}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                  <FormTextField
                    label='Heading 2'
                    value={headingTwo}
                    onChange={(e) => setHeadingTwo(e.target.value)}
                    required={false}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                  <FormSelectField
                    options={['Yes', 'No']}
                    defaultValue="No"
                    value={allowBreak}
                    onChange={(value) => setAllowBreak(value)}
                    label={'Allow break?'}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                </Box>
              </Box>
            }
            <Box sx={classes.modalFooter}>
              <Button
                id="close-new-field-button"
                sx={classes.modalCancelButton}
                onClick={() => handleClose(true)}
              >
                Cancel
              </Button>
              <Button
                id="submit-new-field-button"
                type="submit"
                sx={{ ...classes.modalCreateButton, width: '91px', height: '41px' }}
                onClick={() => handleSubmit(jobId)}
              >
                {isCreating ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
