import React, { useState, useEffect, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import SelectedApplicants from '../SelectedApplicants';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import { IExtendedActionModalProps, IGetApplications } from '../../types';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';

interface IProps extends IExtendedActionModalProps {
  getApplications: IGetApplications;
  deselectAll: () => void;
}

export default function MoveApplications({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  getApplications,
  deselectAll,
  filters
}: IProps) {
  const [isSending, setIsSending] = useState(false);
  const [loadingJobs, setIsLoadingJobs] = useState(false);
  const [jobList, setJobList] = useState<string[]>([]);
  const [selectedJob, setSelectedJob] = useState<string | null>(null);
  const [noJobSelected, setNoJobSelected] = useState(false);

  const jobListRef = useRef<HTMLDivElement>(null);

  const getJobList = useCallback(async () => {
    setIsLoadingJobs(true);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/bulk_move_applications`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const data = await response.json();
      setJobList(
        data?.jobs
          .filter((job: { reference: string; title: string }) => job.reference)
          .map((job: { reference: string; title: string }) => `${job.reference} - ${job.title}`)
      );
    } catch (error) {
      setSnackbar({
        message: 'Error fetching jobs',
        state: 'error'
      });
    } finally {
      setIsLoadingJobs(false);
    }
  }, [apiKey, jobId, setSnackbar]);

  const handleSubmit = async () => {
    if (!selectedJob) {
      setNoJobSelected(true);
      jobListRef?.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setIsSending(true);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/bulk_move_applications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        },
        body: JSON.stringify({
          target_job: selectedJob.split(' -')[0],
          application_ids: globalSelected.map((application) => application.id)
        })
      });
      if (response.status === 200) {
        setSnackbar({
          message: `Successfully moved ${globalSelected.length} applications to ${
            selectedJob.split('- ')[1]
          }`,
          state: 'success'
        });
        deselectAll();
        getApplications(null, null, null, null, null);
        handleClose();
      }
    } catch (error) {
      setSnackbar({
        message: 'Error moving applications',
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleClose = () => {
    setModalsOpen((prev) => ({ ...prev, move: false }));
    setSelectedJob(null);
    setNoJobSelected(false);
  };

  useEffect(() => {
    if (modalsOpen.move && !jobList.length) {
      getJobList();
    }
  }, [modalsOpen.move, getJobList, jobList.length]);

  return (
    <Modal open={modalsOpen.move} onClose={handleClose} aria-labelledby="move-applications-modal">
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
        <Box id="move-applications-title" sx={classes.actionModalHeader}>
          Move Applications
        </Box>
        <Box
          sx={classes.modalContent}
          id="move-applications-modal-content"
          aria-label="Move Applications Modal Content"
        >
          <Box role="form" id="move-applications-form" aria-label="Move Applications form">
            <Box sx={classes.actionModalFormLine} ref={jobListRef}>
              <Virtualize
                value={selectedJob}
                passOptions={jobList}
                setValue={setSelectedJob}
                passedStyles={{
                  ...sharedClasses.formAutocomplete,
                  width: '600px',
                  marginTop: '20px'
                }}
                label="Select job to move applications to"
                loading={loadingJobs}
                loadingText="Loading job list..."
              />
              {noJobSelected && <Box sx={classes.formError}>Job must be selected</Box>}
            </Box>
          </Box>
          <Box sx={{ marginLeft: '15px' }}>
            <h3>Candidates</h3>
            <SelectedApplicants
              globalSelected={globalSelected}
              handleDeselectCandidate={handleDeselectCandidate}
              closeModalCallback={handleClose}
              filters={filters}
            />
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <Button
            id="close-move-application-button"
            sx={classes.modalCancelButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            id="submit-move-application-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '71px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSending ? <CircularProgress size={20} color="inherit" /> : 'Move'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
