import React, { useState, useRef } from "react";
import useStyles from "./useStyles";
import sharedStyles from "../ChatBox/useStyles";
import PropTypes from "prop-types";
import {
  TextField,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Popper,
  Chip
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
function CandidateFilter({ conversations, statuses, selectedStatuses, setSelectedStatuses, searchTerm, setSearchTerm }) {
  const [isOpen, setIsOpen] = useState(false);
  const anchor = useRef(null);
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const unselectedStatuses = statuses.filter((status) => conversations.map((app) => app.candidate_status).includes(status.id)).filter((status) => !selectedStatuses.map((selected) => selected.id).includes(status.id));
  return /* @__PURE__ */ React.createElement("div", { className: `${classes.root} ${selectedStatuses.length > 0 ? classes.reducePadding : ""}` }, /* @__PURE__ */ React.createElement("div", { className: classes.searchContainer }, /* @__PURE__ */ React.createElement(
    TextField,
    {
      classes: { root: classes.search },
      variant: "outlined",
      placeholder: "Search",
      value: searchTerm,
      onChange: (e) => setSearchTerm(e.target.value),
      InputProps: {
        startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, /* @__PURE__ */ React.createElement(SearchIcon, null))
      }
    }
  ), /* @__PURE__ */ React.createElement(ClickAwayListener, { onClickAway: () => setIsOpen(false) }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(IconButton, { ref: anchor, onClick: () => setIsOpen(!isOpen), className: `${classes.filterIcon} ${isOpen ? classes.filterIconActive : ""}` }, /* @__PURE__ */ React.createElement(FilterListIcon, null)), /* @__PURE__ */ React.createElement(
    Popper,
    {
      className: `${sharedClasses.popper} ${classes.popperStatuses}`,
      anchorEl: anchor.current,
      open: isOpen,
      placement: "bottom-end",
      popperOptions: { positionFixed: true }
    },
    unselectedStatuses.length > 0 ? unselectedStatuses.map(
      (status) => /* @__PURE__ */ React.createElement(
        "div",
        {
          key: status.id,
          className: sharedClasses.popperItem,
          onClick: () => {
            setSelectedStatuses([...selectedStatuses, status]);
            setIsOpen(false);
          }
        },
        status.name
      )
    ) : /* @__PURE__ */ React.createElement("div", { className: classes.chipNoItems }, "No statuses to display")
  )))), selectedStatuses.length > 0 && /* @__PURE__ */ React.createElement("div", { className: classes.chipContainer }, selectedStatuses.map(
    (status) => /* @__PURE__ */ React.createElement(
      Chip,
      {
        key: status.id,
        className: classes.chipItem,
        label: status.name,
        onDelete: () => setSelectedStatuses(selectedStatuses.filter((selected) => selected.id !== status.id)),
        variant: "outlined"
      }
    )
  )));
}
CandidateFilter.propTypes = {
  conversations: PropTypes.array.isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      colour: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      position: PropTypes.number,
      state: PropTypes.string
    })
  ).isRequired,
  selectedStatuses: PropTypes.array.isRequired,
  setSelectedStatuses: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired
};
export default CandidateFilter;
