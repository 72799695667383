import { Box, Skeleton } from '@mui/material';
import React from 'react';
import dashboardStyles from '../styles';
import { theme } from '../../../ThemeContext/ThemeObject';
import { useQuery } from '@tanstack/react-query';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { IKpiItems } from '../types';
import Api from '../API';

interface IProps {
  onClick?: () => void;
  type: keyof IKpiItems;
}

function KpiItem({ onClick, type }: IProps) {
  const { icon, iconColor, title, link } = getDetails(type);
  const { isLoading, isSuccess, data } = useQuery({
    queryKey: ['kpiDashboard'],
    queryFn: Api.getKpiItems
  });

  function handleRedirect() {
    window.location.href = `/admin/${link}`;
  }

  return (
    <Box
      id={`kpi-${type}`}
      onClick={onClick || handleRedirect}
      sx={{ ...dashboardStyles.section, ...dashboardStyles.kpiSection, ...styles.section }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box sx={{ backgroundColor: `${iconColor}33`, borderRadius: '6px' }} p={1}>
          {icon}
        </Box>
        {isLoading && <Skeleton animation="wave" width={30} height={30} />}
        {isSuccess && !!data[type]?.fluctuation_daily && (
          <Box
            sx={{
              ...styles.fluctuation,
              color:
                data[type]?.fluctuation_daily > 0
                  ? theme.palette.success.main
                  : theme.palette.error.main
            }}
          >
            {data[type]?.fluctuation_daily > 0 && '+'}
            {data[type]?.fluctuation_daily}
          </Box>
        )}
      </Box>
      <Box>
        <Box style={styles.value}>
          {isLoading && <Skeleton animation="wave" width={70} height={60} />}
          {isSuccess &&
            (data[type]?.value.toLocaleString() ? data[type]?.value.toLocaleString() : 0)}
        </Box>
        <Box style={styles.title}>{title}</Box>
      </Box>
    </Box>
  );
}

function getDetails(type: keyof IKpiItems): {
  title: string;
  icon: JSX.Element;
  iconColor: string;
  link: string;
} {
  if (type === 'applications')
    return {
      title: 'Today’s new applications',
      icon: <InsertDriveFileRoundedIcon sx={{ color: '#F478FF' }} fontSize="small" />,
      iconColor: '#F478FF',
      link: 'candidates'
    };
  if (type === 'approvals')
    return {
      title: 'Pending approvals',
      icon: <ThumbUpAltRoundedIcon sx={{ color: '#91CAFF' }} fontSize="small" />,
      iconColor: '#91CAFF',
      link: 'requisitions'
    };
  return {
    title: `Today's Events`,
    icon: <CalendarMonthRoundedIcon sx={{ color: '#FFCC68' }} fontSize="small" />,
    iconColor: '#FFCC68',
    link: 'calendar/events'
  };
}

const styles = {
  section: {
    transition: 'box-shadow 0.3s ease-in-out',
    justifyContent: 'space-between',
    '&:hover': {
      boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.05)',
      cursor: 'pointer'
    }
  },
  value: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#333'
  },
  title: {
    color: '#333',
    fontWeight: '600',
    fontSize: '16px'
  },
  fluctuation: {
    fontWeight: 'bold',
    fontSize: '20px'
  }
};

export default KpiItem;
