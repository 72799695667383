var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState, useMemo } from "react";
import {
  Select,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Typography
} from "@material-ui/core";
import useStyles from "../useStyles";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}
const autocompleteService = { current: null };
function Step1({ answers, setAnswers, jobAdParams, requiredRefs, activeError }) {
  const [value, setValue] = React.useState({
    description: [answers.address, answers.city, answers.state].filter((cur) => cur).join(", ")
  });
  const [inputValue, setInputValue] = React.useState(
    [answers.address, answers.city, answers.state].filter((cur) => cur).join(", ")
  );
  const [options, setOptions] = useState([]);
  const loaded = React.useRef(false);
  const classes = useStyles();
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAF83260uv1i9sjdtsQkK-skJ8fgGCe9_4&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }
    loaded.current = true;
  }
  const fetch = useMemo(
    () => throttle(
      (request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      },
      1e3,
      { trailing: true, leading: false }
    ),
    []
  );
  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) return void 0;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return void 0;
    }
    fetch(
      {
        input: inputValue,
        componentRestrictions: (() => {
          switch (answers.country) {
            case "Australia":
              return { country: "au" };
            case "Canada":
              return { country: "ca" };
            case "New Zealand":
              return { country: "nz" };
            default:
              return { country: "au" };
          }
        })(),
        token: new window.google.maps.places.AutocompleteSessionToken(),
        types: ["address"]
      },
      (results) => {
        if (active) {
          let newOptions = [];
          if (value) newOptions = [value];
          if (results) newOptions = [...newOptions, ...results];
          setOptions(newOptions);
        }
      }
    );
    if (value && value.terms) {
      const [country, state, city, ...address] = value.terms.reverse();
      setAnswers(__spreadProps(__spreadValues({}, answers), {
        city: city.value,
        state: state.value,
        address: address.reverse().map((cur) => cur.value).join(" ")
      }));
    }
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  useEffect(() => {
    if (!inputValue)
      setAnswers(__spreadProps(__spreadValues({}, answers), {
        city: "",
        state: "",
        address: ""
      }));
  }, [inputValue]);
  return /* @__PURE__ */ React.createElement("div", { className: classes.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Let's get started"), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.companyName,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Company name for this job"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.companyName,
        className: classes.textField,
        variant: "outlined",
        value: answers.companyName,
        onChange: (e) => e.target.value.length <= 52 && setAnswers(__spreadProps(__spreadValues({}, answers), { companyName: e.target.value })),
        InputProps: {
          endAdornment: activeError === requiredRefs.companyName && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
        }
      }
    ),
    /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, answers.companyName.length, "/52")
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.industry,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Industry"),
    /* @__PURE__ */ React.createElement(
      Select,
      {
        classes: { select: classes.input },
        variant: "outlined",
        value: answers.industry,
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { industry: e.target.value, subIndustry: "" })),
        displayEmpty: true,
        renderValue: (value2) => value2 && value2[0] || /* @__PURE__ */ React.createElement("span", { className: classes.placeholder }, "Select an option"),
        endAdornment: activeError === requiredRefs.industry && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
      },
      jobAdParams.industry_options.map((industry) => /* @__PURE__ */ React.createElement(MenuItem, { key: industry[1], value: industry }, industry[0]))
    )
  ), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { className: classes.label }, "Sub-industry"), /* @__PURE__ */ React.createElement(
    Select,
    {
      disabled: !answers.industry,
      classes: { select: classes.input },
      variant: "outlined",
      value: answers.subIndustry,
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { subIndustry: e.target.value })),
      displayEmpty: true,
      renderValue: (value2) => value2 ? value2[0] : /* @__PURE__ */ React.createElement("span", { className: classes.placeholder }, "Select an option")
    },
    answers.industry && jobAdParams.sub_industry_options[answers.industry[1]].map((subIndustry) => /* @__PURE__ */ React.createElement(MenuItem, { key: subIndustry[1], value: subIndustry }, subIndustry[0]))
  )), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.jobTitle,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Job title"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.jobTitle,
        className: classes.textField,
        variant: "outlined",
        value: answers.jobTitle,
        onChange: (e) => e.target.value.length <= 52 && setAnswers(__spreadProps(__spreadValues({}, answers), { jobTitle: e.target.value })),
        InputProps: {
          endAdornment: activeError === requiredRefs.jobTitle && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
        }
      }
    ),
    /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, answers.companyName.length, "/52")
  ), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Where will an employee report to work?"), /* @__PURE__ */ React.createElement(
    RadioGroup,
    {
      value: answers.reportToWork,
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { reportToWork: e.target.value }))
    },
    /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        label: "Employees will report to a specific address",
        value: "true",
        control: /* @__PURE__ */ React.createElement(Radio, null)
      }
    ),
    /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        label: "Employees will not report to a specific address",
        value: "false",
        control: /* @__PURE__ */ React.createElement(Radio, null)
      }
    )
  )), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.address,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Enter a street address"),
    /* @__PURE__ */ React.createElement("span", { className: classes.subLabel }, "We won\u2019t share your street address. We use your location to find candidates in your area."),
    /* @__PURE__ */ React.createElement(
      Autocomplete,
      {
        forcePopupIcon: false,
        fullWidth: true,
        getOptionLabel: (option) => typeof option === "string" ? option : option.description,
        classes: { root: classes.autoComplete, popper: classes.autoCompletePopper },
        filterOptions: (x) => x,
        options,
        autoComplete: true,
        includeInputInList: true,
        filterSelectedOptions: true,
        value,
        onChange: (event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        },
        onInputChange: (event, newInputValue) => {
          setInputValue(newInputValue);
        },
        renderInput: (params) => /* @__PURE__ */ React.createElement(
          TextField,
          __spreadProps(__spreadValues({}, params), {
            error: activeError === requiredRefs.address,
            value,
            placeholder: "Select an address",
            variant: "outlined",
            fullWidth: true,
            InputProps: __spreadProps(__spreadValues({}, params.InputProps), {
              classes: {
                root: classes.autoCompleteRoot
              },
              endAdornment: /* @__PURE__ */ React.createElement(React.Fragment, null, activeError === requiredRefs.address && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required")), inputValue && /* @__PURE__ */ React.createElement(
                IconButton,
                {
                  className: classes.autoCompleteClose,
                  onClick: () => {
                    setAnswers(__spreadProps(__spreadValues({}, answers), { address: "", city: "", state: "" }));
                    setValue(null);
                    setInputValue("");
                  }
                },
                /* @__PURE__ */ React.createElement(CloseIcon, null)
              ))
            })
          })
        ),
        renderOption: (option) => {
          if (!option.structured_formatting) return;
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );
          return /* @__PURE__ */ React.createElement(Grid, { container: true, alignItems: "center" }, /* @__PURE__ */ React.createElement(Grid, { item: true }, /* @__PURE__ */ React.createElement(LocationOnIcon, { className: classes.icon })), /* @__PURE__ */ React.createElement(Grid, { item: true, xs: true }, parts.map((part, index) => /* @__PURE__ */ React.createElement("span", { key: index, style: { fontWeight: part.highlight ? 700 : 400 } }, part.text)), /* @__PURE__ */ React.createElement(Typography, { variant: "body2", color: "textSecondary" }, option.structured_formatting.secondary_text)));
        }
      }
    )
  ), /* @__PURE__ */ React.createElement("div", { className: `${classes.formControl} ${classes.medium} ${classes.greyBox}` }, /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true }, /* @__PURE__ */ React.createElement(Grid, { container: true, alignItems: "center" }, /* @__PURE__ */ React.createElement("span", null, "Country:"), /* @__PURE__ */ React.createElement(
    Select,
    {
      classes: {
        select: classes.greyInput,
        icon: classes.greyIcon
      },
      value: answers.country,
      renderValue: (value2) => jobAdParams.country_options.find((cur) => cur[1] === value2)[0],
      onChange: (e) => {
        setValue(null);
        setAnswers(__spreadProps(__spreadValues({}, answers), {
          country: e.target.value,
          address: "",
          city: "",
          state: ""
        }));
      },
      IconComponent: EditIcon,
      disableUnderline: true
    },
    jobAdParams.country_options.map((country) => /* @__PURE__ */ React.createElement(MenuItem, { key: country[1], value: country[1] }, country[0]))
  ))), /* @__PURE__ */ React.createElement(FormControl, null, /* @__PURE__ */ React.createElement(Grid, { container: true, alignItems: "center" }, /* @__PURE__ */ React.createElement("span", null, "Job posting will be displayed in:"), /* @__PURE__ */ React.createElement(
    Select,
    {
      classes: {
        select: classes.greyInput,
        icon: classes.greyIcon
      },
      value: answers.language,
      renderValue: (value2) => jobAdParams.languages_options.find((cur) => cur[1] === value2)[0],
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { language: e.target.value })),
      IconComponent: EditIcon,
      disableUnderline: true
    },
    jobAdParams.languages_options.map((language) => /* @__PURE__ */ React.createElement(MenuItem, { key: language[1], value: language[1] }, language[0]))
  )))), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Can this job be performed remotely, meaning primarily from home? If so, we\u2019ll add a \u201CRemote\u201D tag to your post"), /* @__PURE__ */ React.createElement(
    RadioGroup,
    {
      value: answers.remoteWork,
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { remoteWork: e.target.value }))
    },
    jobAdParams.remote_type_options.map((remote) => /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        key: remote[1],
        label: remote[0],
        value: remote[1],
        control: /* @__PURE__ */ React.createElement(Radio, null)
      }
    ))
  )), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.hires,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, required: true, className: classes.label }, "How many hires?"),
    /* @__PURE__ */ React.createElement("span", { className: classes.subLabel }, "This helps us match you with the right number of applicants."),
    /* @__PURE__ */ React.createElement(
      Grid,
      {
        container: true,
        classes: {
          root: `${classes.controlledNumberInput} ${activeError === requiredRefs.hires ? classes.errorOutline : ""}`
        }
      },
      /* @__PURE__ */ React.createElement(
        TextField,
        {
          type: "number",
          variant: "outlined",
          className: `${classes.textField}`,
          value: answers.hires,
          onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { hires: parseInt(e.target.value) || 0 })),
          InputProps: {
            classes: { root: classes.w200 },
            endAdornment: activeError === requiredRefs.hires && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
          }
        }
      ),
      /* @__PURE__ */ React.createElement(
        IconButton,
        {
          onClick: () => setAnswers(__spreadProps(__spreadValues({}, answers), { hires: answers.hires - 1 })),
          disabled: answers.hires <= 0 || answers.hiring
        },
        /* @__PURE__ */ React.createElement(RemoveIcon, null)
      ),
      /* @__PURE__ */ React.createElement(
        IconButton,
        {
          onClick: () => setAnswers(__spreadProps(__spreadValues({}, answers), { hires: answers.hires + 1 })),
          disabled: answers.hires >= 100 || answers.hiring
        },
        /* @__PURE__ */ React.createElement(AddIcon, null)
      )
    ),
    /* @__PURE__ */ React.createElement(Grid, { container: true, style: { marginTop: 10 } }, /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        label: "Always Hiring",
        control: /* @__PURE__ */ React.createElement(
          Checkbox,
          {
            checked: answers.hiring,
            onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { hiring: !answers.hiring }))
          }
        )
      }
    ))
  ));
}
Step1.propTypes = {
  jobAdParams: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  requiredRefs: PropTypes.object.isRequired,
  activeError: PropTypes.ref
};
export default Step1;
