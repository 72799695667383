import React from 'react';
import { Box, IconButton, FormControlLabel, Switch } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IDropdownProps } from '../../types';
import { classes } from '../styles';

const SortableField = ({
  field,
  handleFieldSwitchChange
}: {
  field: IDropdownProps['sortableColumns'][0];
  handleFieldSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id: field.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <Box key={field.id} sx={classes.sortableField} style={style} ref={setNodeRef} {...attributes}>
      <IconButton ref={setActivatorNodeRef} {...listeners}>
        <DragIndicatorIcon sx={{ fontSize: 16 }} />
      </IconButton>
      <FormControlLabel
        sx={
          !field.enabled
            ? { ...classes.sortableSwitchLabels, ...classes.labelActive }
            : classes.sortableSwitchLabels
        }
        control={
          <Switch
            sx={field.enabled ? classes.switchActive : classes.switch}
            checked={field.enabled}
            onChange={handleFieldSwitchChange}
            name={field.id}
          />
        }
        label={field.label}
        labelPlacement="start"
      />
    </Box>
  );
};

export default SortableField;
