import React, { Dispatch } from 'react';
import { Stack, Avatar, Chip, Tooltip, CircularProgress } from '@mui/material';
import {
  Alarm as AlarmIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  CheckCircle as CheckCircleIcon,
  Task as TaskIcon
} from '@mui/icons-material';
import { CandidateAction, ICandidateTask } from '../types';
import dayjs from 'dayjs';
import { styles } from '../styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../API';

export default function CandidateTask({
  task,
  dispatch,
  candidateId,
  tasks
}: {
  task: ICandidateTask;
  dispatch: Dispatch<CandidateAction>;
  candidateId: number;
  tasks: ICandidateTask[];
}) {
  const queryClient = useQueryClient();
  const { mutate: completeCandidateTask, isLoading: completingCandidateTask } = useMutation({
    mutationFn: async () => {
      const { res } = await Api.updateCandidateTask(candidateId, task.id, {
        completed_at: task.completed_at ? null : dayjs().toISOString()
      });
      return res;
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Task has been successfully updated`, state: 'success' }
      });
      const newTasks = [...tasks];
      const index = tasks.findIndex((t) => t.id === task?.id);
      newTasks[index] = res;
      queryClient.setQueryData(['tasks'], { tasks: newTasks });
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error updating candidate task, ${error.error}`,
          state: 'error'
        }
      });
    }
  });
  return (
    <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Stack sx={{ flexDirection: 'row', columnGap: 2, alignItems: 'center' }}>
        <Tooltip
          placement="top"
          title={`${task.priority === 'Normal' ? 'Medium' : task.priority} priority`}
          arrow
        >
          <Avatar variant="rounded" sx={{ ...styles[task.priority], borderRadius: '6px' }}>
            <TaskIcon sx={{ fontSize: '1.1rem' }} />
          </Avatar>
        </Tooltip>
        <Stack
          id="task-name-link"
          sx={{ fontSize: '16px', fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => {
            dispatch({ type: 'SET_EDITED_TASK', payload: task });
            dispatch({ type: 'OPEN_TASK_MODAL', payload: true });
          }}
        >
          {task.subject}
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: 'row', columnGap: 2, alignItems: 'center' }}>
        <Chip
          label={`Assigned to: ${task.assigned_user}`}
          sx={{ ...styles.candidateTaskChip, color: '#084D6D', background: '#EDF2F5' }}
        />
        <Chip
          icon={<AlarmIcon sx={{ fontSize: '16px' }} />}
          label={dayjs(task.due_at).format('ddd, D MMM YYYY, LT')}
          sx={{ ...styles.candidateTaskChip, color: '#666666', minWidth: '210px' }}
        />
        {completingCandidateTask ? (
          <CircularProgress size={24} sx={{ color: '#5BC4BF' }} />
        ) : (
          <Stack
            id="complete-task-button"
            onClick={() => completeCandidateTask()}
            sx={{ cursor: 'pointer' }}
          >
            {task.completed_at ? (
              <CheckCircleIcon sx={{ color: '#5BC4C0' }} />
            ) : (
              <CheckCircleOutlineIcon sx={{ color: '#DDDDDD' }} />
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
