import React, { useReducer, Fragment, useState } from 'react';
import { Box, Typography, Divider, Popover } from '@mui/material';
import { styles } from './styles';
import { useQuery, useMutation } from '@tanstack/react-query';
import SmartFormsTable from './SmartFormsTable';
import StyledSnackbar from '../Components/CustomUIElements/StyledSnackbar';
import Api from './API';
import { InitialSmartFormsState, SmartFormsReducer, SmartFormsState } from './reducer';
import Search from './Search';
import SmartFormModal from './SmartFormModal';
import { ModalType } from './types';
import CreateButton from '../Components/Buttons/CreateButton';

export default function SmartForms({ entityId }: { entityId: number }) {
  const [SmartFormsState, dispatch] = useReducer(SmartFormsReducer, InitialSmartFormsState);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLDivElement | null>(null);
  const modalType = (SmartFormsState as SmartFormsState).showSmartFormModal;

  const { isFetched: fetchedPreferences } = useQuery({
    queryKey: ['preferences'],
    queryFn: () => Api.getPreferences(),
    onSuccess: (pref) => {
      dispatch({ type: 'SET_ROWS_PER_PAGE', payload: pref.res.ui_preferences[0]?.row_count || 10 });
      dispatch({
        type: 'SET_SORT_BY',
        payload: pref.res.ui_preferences[0]?.sorting?.sortBy || 'name'
      });
      dispatch({
        type: 'SET_SORT_ORDER',
        payload: pref.res.ui_preferences[0]?.sorting?.sortOrder?.toLowerCase() || 'asc'
      });
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting user preference data, ${error}`,
          state: 'error'
        }
      })
  });

  const { data: density } = useQuery({
    queryKey: ['density'],
    queryFn: () => Api.getDensityPreferences(),
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting table density preference, ${error}`,
          state: 'error'
        }
      })
  });

  const {
    data: forms,
    isLoading: loadingForms,
    refetch: refetchForms
  } = useQuery({
    queryKey: [
      'forms',
      {
        page: SmartFormsState.currentPage,
        limit: SmartFormsState.rowsPerPage,
        sort: SmartFormsState.sortBy,
        sort_order: SmartFormsState.sortOrder,
        search: SmartFormsState.search
      }
    ],
    queryFn: () =>
      Api.getSmartForms({
        page: SmartFormsState.currentPage,
        limit: SmartFormsState.rowsPerPage,
        sort: SmartFormsState.sortBy,
        sort_order: SmartFormsState.sortOrder,
        'q[search]': SmartFormsState.search
      }),
    onSuccess: () => {
      handleUserPreferenceChange.mutate();
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading smart forms, ${error}`,
          state: 'error'
        }
      }),
    enabled: fetchedPreferences
  });

  const handleUserPreferenceChange = useMutation({
    mutationFn: () =>
      Api.postUserPreferences({
        ui_preference: {
          row_count: SmartFormsState.rowsPerPage,
          sorting: { sortBy: SmartFormsState.sortBy, sortOrder: SmartFormsState.sortOrder },
          source: `smart_forms`
        }
      }),

    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error updating user preferences, ${error}`,
          state: 'error'
        }
      })
  });

  return (
    <Fragment>
      <Box sx={styles.smartFormsPageContainer}>
        <Box sx={{ ...styles.smartFormsPageHeader, paddingLeft: 2, paddingRight: 1 }}>
          <Typography variant="h1">Smart Forms</Typography>
          <CreateButton
            id="smart-form-open-modal-button"
            ariaLabel="Add new smart form"
            onClick={(e) => {
              setActionsAnchorEl(e.currentTarget);
            }}
            isOpen={Boolean(actionsAnchorEl)}
          />
          <Popover
            id={actionsAnchorEl ? 'actions-menu-popover' : undefined}
            sx={styles.actionsMenu}
            open={Boolean(actionsAnchorEl)}
            anchorEl={actionsAnchorEl}
            onClose={() => {
              setActionsAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <span
              onClick={() => {
                setActionsAnchorEl(null);
                dispatch({
                  type: 'SET_MODAL_OPEN',
                  payload: ModalType.CREATE
                });
              }}
              className="create-smart-form-action-button"
            >
              Create Smart Form
            </span>
            <span
              onClick={() => {
                setActionsAnchorEl(null);
                dispatch({
                  type: 'SET_MODAL_OPEN',
                  payload: ModalType.CLONE_WITHOUT_FORM
                });
              }}
              className="clone-smart-form-action-button"
            >
              Clone Smart Form
            </span>
          </Popover>
        </Box>
        <Divider sx={{ margin: '32px 16px' }} />
        <Box sx={{ padding: 2 }}>
          <Search
            idLabel="smart-forms"
            placeholder="Search by title"
            setSearch={(title) => dispatch({ type: 'SET_SEARCH', payload: title })}
          />
        </Box>
        <SmartFormsTable
          forms={forms?.res.smart_forms}
          loadingForms={loadingForms}
          density={density?.res.ui_preferences[0]?.columns?.density || 'Default'}
          totalPages={forms?.resHead['x-total-pages']}
          totalForms={forms?.resHead['x-total-count']}
          SmartFormsState={SmartFormsState}
          dispatch={dispatch}
          refetchForms={refetchForms}
        />
      </Box>
      <StyledSnackbar
        message={SmartFormsState?.snackbar?.message}
        state={SmartFormsState?.snackbar?.state}
        setSnackbarState={() => {
          dispatch({ type: 'SET_SNACKBAR', payload: { message: '', state: 'success' } });
        }}
      />
      {modalType && (
        <SmartFormModal
          dispatch={dispatch}
          limit={forms?.resHead['x-total-count']}
          entityId={entityId}
          smartFormState={SmartFormsState}
          refetchForms={refetchForms}
          modalType={modalType}
        />
      )}
    </Fragment>
  );
}
