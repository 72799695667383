import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NewAutoSMSForward from './NewAutoSMSForward';
import ImmediateChangesNotice from '../shared/ImmediateChangesNotice';
import SkeletonTable from '../../shared/SkeletonTable';
import { classes } from '../styles';
import { IStatusTransitionsProps, IAutoSmsForward } from '../../types';
import { IApplicationStatus } from '../../../Job/types';
import { immutableRemove } from '../../../Components/Utilities/immutableRemove';
import { useV4Api } from '../../../hooks/useV4Api';

export default function AutoSMSForward({ apiKey, jobId, setSnackbar }: IStatusTransitionsProps) {
  const [loading, setLoading] = useState({
    autoForwards: true,
    applicationStatuses: true,
    templatesList: true,
  });
  const [isDeleting, setIsDeleting] = useState(-1);
  const [autoForwards, setAutoForwards] = useState<IAutoSmsForward[]>([]);
  const [applicationStatuses, setApplicationStatuses] = useState<IApplicationStatus[]>([]);
  const [templates, setTemplates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [forwardIndex, setForwardIndex] = useState(0);
  const { apiGet, apiDelete } = useV4Api({ apiKey });

  const getAutoForwards = useCallback(async () => {
    setLoading((prev) => ({ ...prev, autoForwards: true }));
    try {
      const { auto_sms_forwards } = await apiGet(`/jobs/${jobId}/auto_sms_forwards`);
      setAutoForwards(auto_sms_forwards);
    } catch (error: any) {
      setSnackbar({
        message: error.message || 'Error fetching auto SMS forwards',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, autoForwards: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getApplicationStatuses = useCallback(async () => {
    setLoading((prev) => ({ ...prev, applicationStatuses: true }));
    try {
      const { job_application_statuses } = await apiGet(`/jobs/${jobId}/job_application_statuses`);
      setApplicationStatuses(job_application_statuses);
    } catch (error: any) {
      setSnackbar({
        message: error.message || 'Error fetching application statuses',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, applicationStatuses: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getTemplatesAndPlaceholders = useCallback(async () => {
    setLoading((prev) => ({ ...prev, templatesList: true }));
    try {
      const { sms_templates } = await apiGet(`/jobs/${jobId}/sms_templates?kind=custom`);
      setTemplates(sms_templates);
    } catch (error) {
      setSnackbar({
        message: 'Error fetching templates',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, templatesList: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const removeAutoForwards = async (index: number) => {
    setIsDeleting(index);
    try {
      const response = await apiDelete(`/jobs/${jobId}/auto_sms_forwards/${autoForwards[index].id}`);
      if (response.ok) {
        setSnackbar({
          message: 'Auto SMS forward deleted successfully',
          state: 'success'
        });
        setAutoForwards(immutableRemove(autoForwards, index));
      } else {
        throw new Error('Error deleting auto SMS forwards');
      }
    } catch (error: any) {
      setSnackbar({
        message: error.message || 'Error deleting auto SMS forward',
        state: 'error'
      });
    } finally {
      setIsDeleting(-1);
    }
  };

  const handleOpenModal = (index: number) => {
    setForwardIndex(index);
    setModalOpen(true);
  };

  useEffect(() => {
    getAutoForwards();
    getApplicationStatuses();
    getTemplatesAndPlaceholders();
  }, [getApplicationStatuses, getAutoForwards, getTemplatesAndPlaceholders]);

  return (
    <Box sx={classes.automationContainer}>
      <Box sx={classes.titleAndButtonContainer}>
        <h2>Auto SMS forward</h2>
        <Button
          id='new-auto-sms-forward-button'
          data-testid='new-auto-sms-forward-button'
          sx={classes.newButton}
          onClick={() => handleOpenModal(-1)}
        >
          New auto SMS forward
        </Button>
      </Box>
      <ImmediateChangesNotice />
      {loading.autoForwards || (autoForwards && autoForwards.length > 0) ? (
        <TableContainer sx={classes.tableContainer}>
          <Table sx={classes.table} aria-label='auto sms forwards table'>
            <TableHead>
              <TableRow>
                <TableCell>Trigger status</TableCell>
                <TableCell>Phones</TableCell>
                <TableCell align='right'>{/*Action column*/}</TableCell>
              </TableRow>
            </TableHead>
            {loading.autoForwards || loading.applicationStatuses ? (
                <SkeletonTable rows={5} cols={3} />
              ) : (
                <TableBody>
                  {autoForwards.map((forward, index) => (
                    <TableRow key={forward.id}>
                      <TableCell>
                        {applicationStatuses.find((status) => status.id === forward.trigger_status_id)
                          ?.name ?? '-'}
                      </TableCell>
                      <TableCell>{forward.phones.join(', ') || '-'}</TableCell>
                      <TableCell sx={classes.tableActions}>
                        <IconButton
                          id='auto-sms-forward-edit-button'
                          data-testid='auto-sms-forward-edit-button'
                          sx={classes.tableEditButton}
                          onClick={() => handleOpenModal(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          id='auto-sms-forward-delete-button'
                          data-testid='auto-sms-forward-delete-button'
                          sx={classes.tableDeleteButton}
                          onClick={() => removeAutoForwards(index)}
                        >
                          {isDeleting === index ? (
                            <CircularProgress size={24} color='inherit' />
                          ) : (
                            <DeleteIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <Box sx={classes.noAutoForwarders}>
          No auto forwards configured. Click on the button above to create one.
        </Box>
      )}
      {!loading.autoForwards && !loading.applicationStatuses && (
        <NewAutoSMSForward
          jobId={jobId}
          apiKey={apiKey}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          autoForwards={autoForwards}
          setAutoForwards={setAutoForwards}
          applicationStatuses={applicationStatuses || []}
          smsTemplates={templates}
          forwardIndex={modalOpen ? forwardIndex : -1}
          isLoadingTemplateList={loading.templatesList}
          setSnackbar={setSnackbar}
        />
      )}
    </Box>
  );
}
