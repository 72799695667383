import React, { useState, useCallback, useEffect } from 'react';
import { Dialog, Button } from '@mui/material';
import { handleAPIRequest } from '../../../Components/Utilities/handleAPIRequest';
import Api from '../../../Dashboard/API';
import Select from '../../../../inputs/Select';
import { IUserInfo } from '../../Header';
import { classes } from '../styles';

const SwitchEntityDialog = ({
  open,
  setChangingEntity,
  userInfo
}: {
  open: boolean;
  setChangingEntity: (changingEntity: boolean) => void;
  userInfo: IUserInfo;
}) => {
  const [userEntities, setUserEntities] = useState([]);
  const [entityId, setEntityId] = useState(userInfo?.entity?.id);
  const getUserEntities = useCallback(async () => {
    try {
      const { entities } = await Api.getUserEntities();
      setUserEntities(entities);
    } catch (error) {
      setAlertState({
        message: 'There was an error retrieving user Entities',
        state: 'error'
      });
    }
  }, [setUserEntities]);

  const submitUserEntityChange = () => {
    handleAPIRequest({
      url: '/api/v4/switch_entity',
      method: 'POST',
      body: { entity_id: entityId },
      onSuccess: () => {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    getUserEntities();
  }, [getUserEntities]);

  return (
    <Dialog
      open={open}
      sx={classes.switchEntityDialog}
      onClose={() => {
        setChangingEntity(false);
      }}
    >
      <Select
        options={userEntities}
        value={entityId}
        onChange={(value) => setEntityId(value)}
        valueField="id"
        labelField="name"
      />
      <Button
        onClick={submitUserEntityChange}
        sx={classes.switchEntityButton}
      >
        Switch Entity
      </Button>
    </Dialog>
  );
};

export default SwitchEntityDialog;
