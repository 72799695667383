import React, { Dispatch } from 'react';
import { Popover, Stack } from '@mui/material';
import { styles } from './styles';
import { ApplicationAction, ApplicationState } from './types';
import { ModalType } from './config';
import { DARK_RED, RED } from '../../ThemeContext/constants';
import { useQueryClient } from '@tanstack/react-query';
import { IUserPermissions } from '../Components/sharedTypes';

type IOption = {
  id: string;
  label: string;
  action: () => void;
  permission?: boolean;
};

const MoreOptionsPopover = ({
  actionsState,
  dispatch,
  label
}: {
  actionsState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  label: string;
}) => {
  const queryClient = useQueryClient();
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);
  const { isAdminUser } = actionsState;

  const options: IOption[] = [
    {
      id: 'clone',
      label: 'Clone',
      action: () => {
        dispatch({
          type: 'SET_MODALS_OPEN',
          payload: ModalType.CLONE
        });
      },
      permission: permissions?.Applications?.['Clone and Move Application']
    },
    {
      id: 'resend',
      label: 'Resend Application',
      action: () => {
        dispatch({
          type: 'SET_MODALS_OPEN',
          payload: ModalType.RESEND
        });
      },
      permission: permissions?.Applications?.['Re-send Application']
    },
    {
      id: 'trash',
      label: 'Trash',
      action: () => {
        dispatch({
          type: 'SET_MODALS_OPEN',
          payload: ModalType.TRASH
        });
      },
      permission: permissions?.Trash?.['Trash Application']
    },
    {
      id: 'delete',
      label: 'Delete',
      action: () => {
        dispatch({
          type: 'SET_MODALS_OPEN',
          payload: ModalType.DELETE
        });
      },
      permission: isAdminUser
    },
    {
      id: 'search-index',
      label: 'Search index',
      action: () => {
        dispatch({
          type: 'SET_MODALS_OPEN',
          payload: ModalType.SEARCH
        });
      },
      permission: isAdminUser
    }
  ];
  const handleSelectItem = (action: () => void) => {
    dispatch({
      type: 'SET_ACTIONS',
      payload: { anchorEl: null }
    });

    action();
  };

  return (
    <Popover
      id={`${label}-more-options-popover`}
      sx={styles.actionsMenu}
      open={actionsState?.actions.anchorEl !== null}
      anchorEl={actionsState?.actions.anchorEl}
      onClose={() =>
        dispatch({
          type: 'SET_ACTIONS',
          payload: { anchorEl: null }
        })
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {options
        .filter((option) => option.permission)
        .map((option) => (
          <Stack
            id={`${option.id}${option.id === 'search-index' ? '' : `-application`}-button`}
            sx={{
              ...styles.actionsItem,
              ...(option.id === 'delete'
                ? { color: `${RED}`, ':hover': { color: `${DARK_RED}` } }
                : undefined)
            }}
            key={option.id}
            onClick={() => handleSelectItem(option.action)}
            className={`${option.id}-action-button`}
          >
            {option.label}
          </Stack>
        ))}
    </Popover>
  );
};

export default MoreOptionsPopover;
