import { Box } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import KpiItem from './components/KpiItem';
import KpiBanner from './components/KpiBanner';
import PinnedJobs from './components/PinnedJobs';
import NewApplicants from './components/NewApplicants';
import Api from '../Jobs/API';
import { IPermissions } from '../Jobs/types';
import StyledSnackbar from '../Components/CustomUIElements/StyledSnackbar';
import TasksDashboard from './components/TasksDashboard/TasksDashboard';
import UpcomingEvents from './components/UpcomingEvents/UpcomingEvents';
import dashboardStyles from './styles';
import { getPermissions } from '../../shared/permissionHelpers';

const queryClient = new QueryClient();

function Dashboard({
  apiKey,
  currentUserId,
  newCalendarEnabled
}: {
  apiKey: string;
  currentUserId: number;
  newCalendarEnabled: boolean;
}) {
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [userPermissions, setUserPermissions] = useState<Record<string, Record<string, boolean>>>(
    {}
  );
  const newApplicationsRef = useRef<HTMLDivElement | null>(null);

  const scrollToComponent = () => {
    if (newApplicationsRef.current) {
      newApplicationsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  async function getUserPermissions() {
    try {
      const data = await getPermissions();
      const allRoles: Record<string, Record<string, boolean>> = {};
      data.roles.forEach((role: IPermissions) =>
        role.feature_groups.forEach((group) => {
          !allRoles[group.name] ? (allRoles[group.name] = {}) : null;
          group.permissions.forEach((permission) => (allRoles[group.name][permission] = true));
        })
      );
      const hasRecruitmentTeamEnabled = data.roles.some(
        (user) => user.recruitment_team_enabled
      );
      allRoles['recruitment_team_enabled'] = hasRecruitmentTeamEnabled;
      setUserPermissions(allRoles);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserPermissions();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Box id="dashboard" sx={styles.container}>
        <h1 style={styles.header}>My Dashboard</h1>
        <Box display="flex" flexWrap="wrap" gap="40px">
          <KpiItem onClick={scrollToComponent} type="applications" />
          <KpiItem type="approvals" />
          <KpiItem type="events" />
          <KpiBanner />
          <UpcomingEvents
            setSnackbar={setSnackbar}
            apiKey={apiKey}
            currentUserId={currentUserId}
            newCalendarEnabled={newCalendarEnabled}
            userPermissions={userPermissions}
          />
          <TasksDashboard setSnackbar={setSnackbar} />
          <PinnedJobs setSnackbar={setSnackbar} userPermissions={userPermissions} />
          <Box ref={newApplicationsRef} sx={{ ...dashboardStyles.section, flexBasis: '100%' }}>
            <NewApplicants setSnackbar={setSnackbar} userPermissions={userPermissions} />
          </Box>
        </Box>
      </Box>
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </QueryClientProvider>
  );
}

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    padding: '40px 56px'
  },
  header: {
    color: '#666666',
    fontSize: '42px',
    marginTop: '0px',
    marginBottom: '40px'
  }
};

export default Dashboard;
