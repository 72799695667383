import React from 'react';

export default function DocTypeIcon(doctype: string | undefined) {
  switch (doctype) {
    case 'pdf':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 22 22">
          <path
            fill="currentColor"
            d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9.5 11.5C9.5 12.3 8.8 13 8 13H7V15H5.5V9H8C8.8 9 9.5 9.7 9.5 10.5V11.5M14.5 13.5C14.5 14.3 13.8 15 13 15H10.5V9H13C13.8 9 14.5 9.7 14.5 10.5V13.5M18.5 10.5H17V11.5H18.5V13H17V15H15.5V9H18.5V10.5M12 10.5H13V13.5H12V10.5M7 10.5H8V11.5H7V10.5Z"
          />
        </svg>
      );
    case 'doc':
    case 'docx':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 22 22">
          <path
            fill="currentColor"
            d="M15.5,17H14L12,9.5L10,17H8.5L6.1,7H7.8L9.34,14.5L11.3,7H12.7L14.67,14.5L16.2,7H17.9M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
          />
        </svg>
      );
    case 'xls':
    case 'xlsx':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 22 22">
          <path
            fill="currentColor"
            d="M16.2,17H14.2L12,13.2L9.8,17H7.8L11,12L7.8,7H9.8L12,10.8L14.2,7H16.2L13,12M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
          />
        </svg>
      );
    case 'ppt':
    case 'pptx':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 22 22">
          <path
            fill="currentColor"
            d="M9.8,13.4H12.3C13.8,13.4 14.46,13.12 15.1,12.58C15.74,12.03 16,11.25 16,10.23C16,9.26 15.75,8.5 15.1,7.88C14.45,7.29 13.83,7 12.3,7H8V17H9.8V13.4M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M9.8,12V8.4H12.1C12.76,8.4 13.27,8.65 13.6,9C13.93,9.35 14.1,9.72 14.1,10.24C14.1,10.8 13.92,11.19 13.6,11.5C13.28,11.81 12.9,12 12.22,12H9.8Z"
          />
        </svg>
      );
    case 'zip':
    case 'rar':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 22 22">
          <path
            fill="currentColor"
            d="M14,17H12V15H10V13H12V15H14M14,9H12V11H14V13H12V11H10V9H12V7H10V5H12V7H14M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
          />
        </svg>
      );
    case 'jpg':
    case 'jpeg':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9 13.5C9 14.6 8.1 15 7 15S5 14.6 5 13.5V12H6.5V13.5H7.5V9H9V13.5M14 11.5C14 12.3 13.3 13 12.5 13H11.5V15H10V9H12.5C13.3 9 14 9.7 14 10.5V11.5M19 10.5H16.5V13.5H17.5V12H19V13.7C19 14.4 18.5 15 17.7 15H16.4C15.6 15 15.1 14.3 15.1 13.7V10.4C15 9.7 15.5 9 16.3 9H17.6C18.4 9 18.9 9.7 18.9 10.3V10.5M11.5 10.5H12.5V11.5H11.5V10.5Z"
          />
        </svg>
      );
    case 'png':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9 11.5C9 12.3 8.3 13 7.5 13H6.5V15H5V9H7.5C8.3 9 9 9.7 9 10.5V11.5M14 15H12.5L11.5 12.5V15H10V9H11.5L12.5 11.5V9H14V15M19 10.5H16.5V13.5H17.5V12H19V13.7C19 14.4 18.5 15 17.7 15H16.4C15.6 15 15.1 14.3 15.1 13.7V10.4C15 9.7 15.5 9 16.3 9H17.6C18.4 9 18.9 9.7 18.9 10.3V10.5H19M6.5 10.5H7.5V11.5H6.5V10.5Z"
          />
        </svg>
      );
    case 'gif':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 22 22">
          <path
            fill="currentColor"
            d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M10 10.5H7.5V13.5H8.5V12H10V13.7C10 14.4 9.5 15 8.7 15H7.3C6.5 15 6 14.3 6 13.7V10.4C6 9.7 6.5 9 7.3 9H8.6C9.5 9 10 9.7 10 10.3V10.5M13 15H11.5V9H13V15M17.5 10.5H16V11.5H17.5V13H16V15H14.5V9H17.5V10.5Z"
          />
        </svg>
      );
    case 'bmp':
    case 'tiff':
    case 'tif':
    case 'svg':
    case 'psd':
    case 'ai':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 22 22">
          <path
            fill="currentColor"
            d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z"
          />
        </svg>
      );
    case 'mov':
    case 'mp4':
    case 'm4v':
    case 'avi':
    case 'wmv':
    case 'mpg':
    case 'mpeg':
    case 'mp2':
    case 'mpe':
    case 'ogg':
    case 'webm':
    case 'mkv':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 22 22">
          <path
            fill="currentColor"
            d="M19 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.89 20.1 3 19 3M10 16V8L15 12"
          />
        </svg>
      );
    case 'mp3':
    case 'wav':
    case 'flac':
    case 'aac':
    case 'wma':
    case 'm4a':
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 22 22">
          <path
            fill="currentColor"
            d="M16,9H13V14.5A2.5,2.5 0 0,1 10.5,17A2.5,2.5 0 0,1 8,14.5A2.5,2.5 0 0,1 10.5,12C11.07,12 11.58,12.19 12,12.5V7H16M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z"
          />
        </svg>
      );
    default:
      return (
        <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 22 22">
          <path
            fill="currentColor"
            d="M14,17H7V15H14M17,13H7V11H17M17,9H7V7H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
          />
        </svg>
      );
  }
}
