import React from 'react';

export default function NoEmailStatusSVG() {
return (<svg 
id="Group_299" 
data-name="Group 299" 
xmlns="http://www.w3.org/2000/svg" 
xmlnsXlink="http://www.w3.org/1999/xlink" 
width="116.117" height="115.798" 
viewBox="0 0 116.117 115.798">
<defs>
  <clipPath id="clip-path">
    <rect id="Rectangle_619" data-name="Rectangle 619" width="116.117" height="115.798" fill="none"/>
  </clipPath>
</defs>
<g id="Group_298" data-name="Group 298" clipPath="url(#clip-path)">
  <path id="Path_128" data-name="Path 128" d="M116.117,57.813A57.783,57.783,0,1,1,112.626,38a58.07,58.07,0,0,1,3.491,19.81M58.159,115.469A57.569,57.569,0,1,0,.652,57.739a57.46,57.46,0,0,0,57.507,57.73" transform="translate(0 0)" fill="#cfcfcf"/>
  <path id="Path_129" data-name="Path 129" d="M61.743,117.287a57.569,57.569,0,1,1,57.305-57.293,57.685,57.685,0,0,1-57.305,57.293M96.434,88.636l.741-.844-1.249-.084c.252-.038.5-.1.756-.112,4.377-.266,8.754-.538,13.132-.778.637-.035.859-.266.808-.893-.09-1.1-.126-2.2-.193-3.3q-.482-7.948-.97-15.9-.657-10.674-1.32-21.347-.545-8.869-1.072-17.739c-.034-.582-.27-.751-.817-.706-1.124.093-2.251.154-3.378.219q-4.607.267-9.215.527l-1.086-1.223a2.23,2.23,0,0,0-.368-.667Q74.74,10.815,52.151,15.215c-.313.061-.613.183-.991.3.22.457.389.825.572,1.186Q69.064,50.864,86.4,85.028q2.175,4.288,4.349,8.577c.2.39.427.827.9.366,1.646-1.613,3.262-3.258,4.873-4.906.058-.059-.051-.282-.083-.428M25.144,91.867,29,91.727a3.3,3.3,0,0,1,1.863,1.4,9.51,9.51,0,0,0,1.028,1.217,45.75,45.75,0,0,0,48.579,6.806c.247-.113.481-.256.768-.41-.165-.345-.29-.622-.427-.892Q72.53,83.52,64.245,67.191,52.411,43.864,40.576,20.536c-.465-.917-.485-.923-1.37-.423a45.105,45.105,0,0,0-11.487,9.181c-.318.354-.576.761-.862,1.143l-.775,1.329.49.1a4.085,4.085,0,0,1-.526.1c-4.2.252-8.394.523-12.594.724-.689.033-.827.25-.787.853.212,3.224.406,6.449.6,9.674q.694,11.286,1.384,22.573.786,12.9,1.549,25.8c.038.649.26.836.881.793,2.686-.189,5.373-.348,8.061-.517" transform="translate(-3.584 -1.819)" fill="#fff"/>
  <path id="Path_130" data-name="Path 130" d="M369.019,166.181a3.75,3.75,0,0,1,.906-.3q11.579-.721,23.16-1.414c3.067-.188,6.133-.4,9.2-.606q4.608-.263,9.215-.527c1.127-.065,2.254-.126,3.378-.219.546-.045.783.124.817.706q.517,8.87,1.072,17.739.656,10.674,1.32,21.347.489,7.948.97,15.9c.067,1.1.1,2.2.193,3.3.051.627-.171.858-.808.893-4.378.241-8.755.512-13.132.778-.253.015-.5.074-.756.112l-5.9.307c-.1-.6.387-.515.7-.544.919-.084,1.842-.125,2.763-.18q6.988-.413,13.975-.827c.567-.034,1.132-.11,1.919-.189l-32.688-22.3.084-.248a1.952,1.952,0,0,1,.631.014,3.136,3.136,0,0,1,.646.412q15.573,10.628,31.146,21.256c.195.133.4.244.732.441l-3.552-58.051c-.4.335-.632.521-.855.717q-2.776,2.436-5.549,4.875-8.307,7.274-16.617,14.544-4.6,4.03-9.2,8.065a5.5,5.5,0,0,1-.8.49l-.187-.284,32.721-28.668c-.461,0-.708-.013-.954,0q-5.106.306-10.211.617-8.062.484-16.124.965c-2.687.164-5.372.365-8.059.53q-4.607.283-9.216.522a4.111,4.111,0,0,1-.943-.171" transform="translate(-312.211 -138)" fill="#cfcfcf"/>
  <path id="Path_131" data-name="Path 131" d="M72.866,189.992q5.1-.286,10.191-.571c2.4-.131,4.8-.252,7.2-.377a3.181,3.181,0,0,1-.749.289c-.661.079-1.329.106-1.995.145q-12.249.73-24.5,1.459c-.922.055-1.842.134-2.763.2l-.029.178,48.558,33.022c-.8.484-1.17-.058-1.556-.318-4.039-2.728-8.061-5.481-12.091-8.222Q77.7,203.94,60.259,192.089c-.159-.108-.336-.188-.612-.34l3.543,57.933c.5-.422.829-.7,1.155-.984q2.722-2.381,5.442-4.764,8.512-7.45,17.025-14.9,5.444-4.763,10.9-9.517a3.21,3.21,0,0,1,.692-.355l.174.275L63.7,249.962l.109.181,6.514-.308,1.116.156c-2.687.17-5.375.328-8.061.517-.621.044-.843-.144-.881-.792q-.75-12.9-1.549-25.8-.687-11.287-1.384-22.573c-.2-3.225-.392-6.45-.6-9.674-.04-.6.1-.82.787-.853,4.2-.2,8.4-.472,12.594-.724a4.08,4.08,0,0,0,.526-.1" transform="translate(-49.877 -159.942)" fill="#cfcfcf"/>
  <path id="Path_132" data-name="Path 132" d="M169.35,127.827q-1.98-3.951-3.962-7.9c-.448-.891-.9-1.778-1.431-2.812-1.295.831-2.561,1.586-3.767,2.429a45.464,45.464,0,0,0-8.069,7.209,3.876,3.876,0,0,1-.939.586c.286-.382.544-.789.862-1.143a45.108,45.108,0,0,1,11.487-9.181c.885-.5.9-.494,1.37.423q11.836,23.327,23.669,46.655,8.284,16.329,16.568,32.659c.137.27.262.547.427.892-.287.154-.521.3-.768.41a45.75,45.75,0,0,1-48.579-6.806,9.535,9.535,0,0,1-1.028-1.217,4.007,4.007,0,0,1,1.057.476A44.892,44.892,0,0,0,177.686,201a44.277,44.277,0,0,0,26.349-2.951c.165-.071.327-.149.485-.233a2.456,2.456,0,0,0,.283-.236l-6.062-11.864q-2.929-5.8-5.858-11.6-9.846-19.417-19.7-38.829c-1.265-2.491-2.553-4.969-3.831-7.453" transform="translate(-127.909 -98.718)" fill="#cfcfcf"/>
  <path id="Path_133" data-name="Path 133" d="M350.467,92.773a3.6,3.6,0,0,1-.992-.438,45.043,45.043,0,0,0-21.737-10.7,44.435,44.435,0,0,0-17.114.076c-.222.044-.438.119-.689.188a2.229,2.229,0,0,0,.11.4q3.572,7.023,7.15,14.043c.139.3.268.61.418.906q9.9,19.512,19.807,39.022,4.6,9.05,9.2,18.095l2.8,5.568c.962-.95,1.837-1.8,2.692-2.663.75-.76,1.479-1.542,2.217-2.315.031.146.141.369.083.428-1.611,1.648-3.227,3.293-4.873,4.906-.47.46-.7.024-.9-.366q-2.172-4.29-4.349-8.577-17.333-34.164-34.666-68.33c-.183-.361-.352-.729-.572-1.186.377-.115.678-.237.99-.3q22.583-4.4,40.053,10.577a2.241,2.241,0,0,1,.368.667" transform="translate(-261.478 -68.133)" fill="#cfcfcf"/>
  <path id="Path_134" data-name="Path 134" d="M133.922,581.814l-1.115-.155,6.22-.414,1.277.158-2.525.27-3.858.141" transform="translate(-112.362 -491.766)" fill="#cfcfcf"/>
  <path id="Path_135" data-name="Path 135" d="M80.357,249.421l-6.221.414-6.514.308-.109-.181,34.876-30.524-.174-.275a3.2,3.2,0,0,0-.692.355q-5.456,4.749-10.9,9.517-8.513,7.448-17.025,14.9-2.721,2.381-5.442,4.764c-.326.285-.659.561-1.155.984-1.186-19.385-2.361-38.6-3.543-57.933.276.152.454.232.612.34Q81.514,203.941,98.951,215.8c4.03,2.741,8.052,5.494,12.091,8.222.386.261.759.8,1.556.318L64.04,191.317l.029-.178c.921-.068,1.841-.146,2.762-.2q12.249-.733,24.5-1.459c.666-.04,1.333-.066,1.995-.145a3.184,3.184,0,0,0,.749-.289l1.059.006c1.277,2.484,2.566,4.962,3.831,7.453q9.856,19.411,19.7,38.829,2.939,5.8,5.858,11.6l-.9,0a3.222,3.222,0,0,0-.72-.14c-.9.03-1.791.083-2.686.148-1.148.083-2.294.186-3.44.28-2.89.157-5.781.3-8.67.476-4.168.252-8.335.527-12.5.792l-3.918.264c-.737.055-1.473.12-2.21.162q-4.558.256-9.117.5" transform="translate(-53.692 -159.942)" fill="#eaeaea"/>
  <path id="Path_136" data-name="Path 136" d="M272.245,568.263c4.167-.265,8.334-.54,12.5-.792,2.889-.175,5.78-.319,8.67-.476l1.123.151c-4.039.259-8.077.528-12.116.774-2.965.18-5.933.33-8.9.493l-1.28-.15" transform="translate(-230.335 -479.71)" fill="#cfcfcf"/>
  <path id="Path_137" data-name="Path 137" d="M173.214,577.613q4.559-.249,9.117-.5c.737-.041,1.473-.107,2.21-.162l.964.158-11.015.661-1.277-.158" transform="translate(-146.549 -488.134)" fill="#cfcfcf"/>
  <path id="Path_138" data-name="Path 138" d="M410.9,564.8l-1.123-.151c1.146-.094,2.292-.2,3.44-.28.894-.065,1.79-.118,2.686-.148a3.209,3.209,0,0,1,.72.14c-.655.085-1.309.2-1.967.247-1.25.086-2.5.13-3.756.192" transform="translate(-346.696 -477.358)" fill="#cfcfcf"/>
  <path id="Path_139" data-name="Path 139" d="M247.762,575.656l-.965-.158,3.917-.264,1.28.15-4.233.272" transform="translate(-208.804 -486.68)" fill="#cfcfcf"/>
  <path id="Path_140" data-name="Path 140" d="M392.657,227.5l-1.3.03q-4.6-9.047-9.2-18.095-9.907-19.509-19.807-39.022c-.15-.3-.279-.6-.418-.906l1.091-.024a4.109,4.109,0,0,0,.943.171q4.609-.239,9.216-.522c2.687-.165,5.372-.366,8.059-.53q8.061-.493,16.124-.965,5.106-.306,10.211-.617c.246-.015.493,0,.954,0l-32.721,28.668.187.284a5.491,5.491,0,0,0,.8-.49q4.608-4.023,9.2-8.065,8.307-7.274,16.617-14.544,2.778-2.433,5.549-4.875c.223-.2.455-.382.855-.717,1.189,19.428,2.365,38.653,3.552,58.051-.328-.2-.537-.308-.732-.441q-15.574-10.626-31.146-21.256a3.139,3.139,0,0,0-.646-.412,1.951,1.951,0,0,0-.631-.014l-.084.248,32.689,22.3c-.787.079-1.352.155-1.919.189q-6.987.42-13.975.827c-.922.054-1.844.1-2.763.18-.314.029-.8-.056-.7.544" transform="translate(-306.213 -141.3)" fill="#eaeaea"/>
</g>
</svg>)
}