import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox as MaterialCheckbox } from "@mui/material";
export default function Checkbox({ checked, onCheck, label, disabled }) {
  return label ? /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      checked,
      control: /* @__PURE__ */ React.createElement(
        MaterialCheckbox,
        {
          onChange: (evt, checked2) => onCheck(checked2)
        }
      ),
      label,
      disabled
    }
  ) : /* @__PURE__ */ React.createElement(
    MaterialCheckbox,
    {
      checked,
      onChange: (evt, checked2) => onCheck(checked2),
      disabled,
      sx: {
        color: "#5BC4BF",
        "&.Mui-checked": {
          color: "#5BC4BF"
        }
      }
    }
  );
}
Checkbox.propTypes = {
  checked: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
  label: PropTypes.node,
  disabled: PropTypes.bool
};
