import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styles } from './styles';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import React, { Dispatch } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type Props = {
  dispatch: Dispatch<any>;
  fetchData: () => void;
  state: { limit: number; searchItem: string };
};

const Search = ({ dispatch, fetchData, state }: Props) => {
  const debouncedAPICall = useDebouncedCallback(() => {
    dispatch({ type: 'SET_LOADING', payload: true });
    fetchData();
  }, 600);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_SEARCH_ITEM', payload: event.target.value });
    debouncedAPICall();
  };

  return (
    <FormControl fullWidth>
      <OutlinedInput
        id="records-search"
        sx={styles.searchInput}
        placeholder={'Search by title'}
        value={state.searchItem}
        onChange={handleChange}
        startAdornment={
          <InputAdornment sx={{ padding: '0px 0px 0px 14px' }} position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default Search;
