import React from 'react';
import useStyles from './useStyles';
import { withScoutTheme } from '../ThemeContext/ThemeContext';

interface IProps {
  portal: {
    social_preview_title: string,
    social_preview_description: string,
    social_preview_image_url: string,
  }
}

function CareersPortalSocialPreview({ portal }: IProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="modal fade" id="careers-portal-social-preview" tabIndex="-1" aria-hidden="true" aria-labelledby="careersPortalSocialModalLabel">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="mt-0">Careers Portal Social Preview</h2>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src={portal.social_preview_image_url} />
              <h3>{portal.social_preview_title}</h3>
              <p>{portal.social_preview_description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withScoutTheme(CareersPortalSocialPreview);
