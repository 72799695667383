"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
export const parseCountryData = (data) => {
  return data.map((country) => __spreadProps(__spreadValues({}, country), {
    label: country.name.toLowerCase().split(" ").map((word) => {
      if (word !== "and" && word !== "of") {
        return word.charAt(0) === "(" ? word[0] + word[1].toUpperCase() + word.slice(2) : word[0].toUpperCase() + word.slice(1);
      } else {
        return word;
      }
    }).join(" "),
    states: country.states.map((state) => __spreadProps(__spreadValues({}, state), {
      label: state.name
    }))
  })).sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};
