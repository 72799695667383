"use strict";
import { theme } from "../../../../ThemeContext/ThemeObject";
import { alpha } from "@mui/material/styles";
export const classes = {
  buttonRedLight: {
    color: "#d74742",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "10px",
    fontWeight: "bold",
    fontSize: "15px",
    textDecoration: "underline",
    backgroundColor: "#ffffff",
    "&:hover": {
      color: "#d74742",
      backgroundColor: "#ffffff"
    }
  },
  buttonGreen: {
    color: "#5BC4BF",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "10px",
    fontWeight: "bold",
    fontSize: "15px",
    textDecoration: "underline",
    backgroundColor: "#ffffff",
    "&:hover": {
      color: "#5BC4BF",
      textDecoration: "underline",
      backgroundColor: "#ffffff"
    }
  },
  buttonRed: {
    color: "#E37D7A",
    backgroundColor: "#ffffff",
    "& span": {
      margin: "0px"
    },
    "&:hover": {
      color: "#d74742",
      backgroundColor: "#ffffff"
    }
  },
  revertloadButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#E37D7A",
    backgroundColor: "#ffffff",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#E37D7A",
      boxShadow: "none"
    }
  },
  createNewWorkflowButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    margin: "auto",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  buttonSecondaryLight: {
    borderRadius: "6px",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "14px 30px",
    fontSize: "15px",
    fontWeight: "bold",
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#FFF"
    }
  },
  actionButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "600",
    color: "#666666",
    backgroundColor: "#9393931F",
    padding: "10px 24px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#93939335",
      border: "none"
    }
  },
  deleteApplicant: {
    color: theme.palette.error.main,
    backgroundColor: "#D6827D33",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: theme.palette.error.main,
      border: "none"
    }
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    maxWidth: "800px",
    width: "90%",
    height: "100%",
    maxHeight: "650px",
    background: "#FFFFFF",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  modalTitle: {
    fontFamily: "Source Sans Pro",
    fontSize: "30px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column"
  },
  tableHeader: {
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& div": {
      paddingRight: "0.5rem",
      fontWeight: "700"
    }
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& div": {
      padding: "0.75rem 0.5rem 0 0"
    }
  },
  calendar: {
    "& .PrivatePickersFadeTransitionGroup-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.action.main} !important`
    },
    "& .PrivatePickersSlideTransition-root": {
      minHeight: "242px !important"
    }
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "20px",
    width: "100%"
  },
  datePicker: {
    margin: "33px 0 0 4px",
    fontFamily: "Source Sans Pro",
    width: "290px",
    color: "#666666",
    "& input": {
      padding: "4px 10px 5px 10px",
      fontFamily: "Source Sans Pro"
    },
    "& fieldset": {
      "& legend": {
        display: "none"
      }
    },
    "& button": {
      padding: "0px",
      transform: "translate(-2px, -1.5px)",
      "&:hover": {
        background: "transparent",
        "& svg": {
          color: "#888888"
        }
      }
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0px"
    },
    "& svg": {
      color: "#AAAAAA"
    }
  },
  noteTextInput: {
    "label + &": {
      marginTop: "24px"
    },
    "& .MuiInputBase-input": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "6px 10px",
      margin: "3px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  noWorkflows: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    height: "80%",
    alignItems: "end"
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
    width: "100%",
    columnGap: "16px",
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  }
};
