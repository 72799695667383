import React from 'react';
import { Skeleton, TableRow, TableCell, TableBody } from '@mui/material';

export default function SkeletonTable({ rows, cols }) {
  return (
    <TableBody>
      {Array.from(new Array(rows)).map((_row, i) => (
        <TableRow key={i}>
          {Array.from(new Array(cols)).map((_cell, j) => (
            <TableCell key={j}>
              <Skeleton width={'80%'} height={'30px'} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}
