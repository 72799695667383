"use strict";
export const dropdownOptions = [
  {
    name: "title",
    label: "Title",
    id: 1,
    previousCellOffSet: true,
    sticky: true,
    leftBound: true,
    style: { width: "20%", minWidth: "150px" }
  },
  {
    name: "updated_at",
    label: "Last Saved On",
    id: 2,
    style: { width: "20%", minWidth: "140px" }
  },
  {
    name: "user_name",
    label: "Created By",
    id: 4,
    style: { width: "15%", minWidth: "140px" }
  }
];
export const defaultActiveColumns = {
  title: { enabled: true, styles: { width: "40%" } },
  updated_at: { enabled: true, styles: { width: "30%" } },
  user_name: { enabled: true, styles: { width: "30%" } }
};
export const filteringStates = {
  report_type: [],
  user: []
};
