import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import useStyles from "../useStyles";
import {
  Popper
} from "@material-ui/core";
import moment from "moment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
function ChatItem({ message }) {
  const [isOpen, setIsOpen] = useState(false);
  const anchor = useRef(null);
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement("div", { className: `${classes.chatItemContainer} ${message.status === "received" ? classes.alignRight : ""}` }, /* @__PURE__ */ React.createElement("div", { className: `${classes.chatItem} ${message.status === "received" ? classes.fromCandidate : ""}` }, message.body), /* @__PURE__ */ React.createElement("div", { ref: anchor, className: classes.status, onMouseEnter: () => setIsOpen(true), onMouseLeave: () => setIsOpen(false) }, message.status === "scheduled" && /* @__PURE__ */ React.createElement(ScheduleIcon, { fontSize: "small", color: "disabled" }), message.status === "bounced" && /* @__PURE__ */ React.createElement(ErrorOutlineIcon, { fontSize: "small", color: "error" })), /* @__PURE__ */ React.createElement(
    Popper,
    {
      className: classes.popper,
      anchorEl: anchor.current,
      open: isOpen,
      placement: "top-center"
    },
    message.status === "scheduled" ? /* @__PURE__ */ React.createElement("div", null, `Scheduled for ${moment(message.scheduled_time).format("lll")}`) : /* @__PURE__ */ React.createElement("div", null, `Message bounced`)
  ));
}
ChatItem.propTypes = {
  message: PropTypes.object.isRequired
};
export default ChatItem;
