import React, { Dispatch, SetStateAction } from 'react';
import { Box, ClickAwayListener, Fade, Popper } from '@mui/material';
import { appMenuItems, navbarWidths } from '../../config';
import { classes } from '../../styles';

const AppMenuItems = ({
  appMenuAnchorEl,
  setAppMenuAnchorEl,
  navbarState,
  isRecruit
}: {
  appMenuAnchorEl: HTMLElement | null;
  setAppMenuAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  navbarState: keyof typeof navbarWidths;
  isRecruit: boolean;
}) => {
  return (
    <ClickAwayListener onClickAway={() => setAppMenuAnchorEl(null)}>
      <Popper
        open={Boolean(appMenuAnchorEl)}
        anchorEl={appMenuAnchorEl}
        placement={navbarState === 'expanded' ? 'bottom' : 'right-start'}
        transition
        sx={{ zIndex: 1245 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                ...classes.appMenuItemsContainer,
                ...(navbarState === 'collapsed' && classes.appMenuItemsContainerCollapsed)
              }}
            >
              {appMenuItems(isRecruit).map(
                (item) =>
                  item && (
                    <a href={item.link} key={item.title} target={item.target}>
                      {item.title}
                    </a>
                  )
              )}
            </Box>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default AppMenuItems;
