import React from 'react';
import ScoutSelect from './scout/select';

export default class MvsRequestTest extends React.Component {
  constructor(props) {
    super(props);

    this.state = { values: [] };
  }

  onPackageChange(e) {
    let values = [];

    this.props.packages.map(function (item) {
      if (item.id === e.target.value) {
        values = item.checks.map((item) => item.value);
        return true;
      }
    });

    this.setState({ values });
  }

  render() {
    const { packages, blueprints } = this.props;
    const { values } = this.state;

    return (
      <fieldset className="inputs">
        <ol>
          <li className="select input">
            <label htmlFor="package_id">Package</label>
            <select name="package_id" id="package_id" onChange={this.onPackageChange.bind(this)}>
              <option value=""> </option>
              {packages.map(function (item, key) {
                return (
                  <option key={key} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </li>
        </ol>
        <ol>
          <li className="select input">
            <label htmlFor="blueprint_id">Additional Checks</label>
            <ScoutSelect
              key={values}
              name="blueprint_ids[]"
              values={values}
              data={blueprints}
              multi={true}
            />
          </li>
        </ol>
      </fieldset>
    );
  }
}
