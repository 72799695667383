import React, { Dispatch, SetStateAction } from 'react';
import { ApplicationAction, ApplicationState, ITimestamp } from '../../types';
import { Grid, IconButton, Stack } from '@mui/material';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { styles } from '../../styles';
import { Add as AddIcon } from '@mui/icons-material';
import { theme } from '../../../../ThemeContext/ThemeObject';

export const TimestampTableForm = ({
  videoRef,
  ApplicationState,
  isTimestampValid,
  addTimestamp,
  dispatch,
  newTimestamp,
  setNewTimestamp,
  currentTime
}: {
  videoRef: React.RefObject<HTMLVideoElement>;
  ApplicationState: ApplicationState;
  isTimestampValid: (timestamp: ITimestamp) => boolean;
  addTimestamp: () => void;
  dispatch: Dispatch<ApplicationAction>;
  newTimestamp: ITimestamp;
  setNewTimestamp: Dispatch<SetStateAction<ITimestamp>>;
  currentTime: string;
}) => {
  const { hasTyped, timestampError } = ApplicationState.timeStampState;
  const isAddingTimestamp = newTimestamp.id === null;
  const hasError = timestampError.id === null;

  const handleFieldChange =
    (field: keyof ITimestamp) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isAddingTimestamp) return;
      setNewTimestamp({ ...newTimestamp, [field]: e.target.value });
      if (field === 'value') {
        dispatch({ type: 'SET_TIMESTAMP_STATE', payload: { name: 'hasTyped', value: true } });
      }
    };

  const handleFocusBehavior = () => {
    if (isAddingTimestamp && !hasTyped) {
      setNewTimestamp({ ...newTimestamp, value: currentTime });
    }
    if (isAddingTimestamp) {
      videoRef.current?.pause();
    }
  };

  const handleNewTimestamp = () => {
    if (isAddingTimestamp && !hasTyped) {
      setNewTimestamp({ ...newTimestamp, value: currentTime });
    }
  };

  return (
    <Grid container spacing={2} columns={12}>
      <Grid item xs={5}>
        <FormTextField
          value={newTimestamp.id ? '' : newTimestamp.name}
          onChange={handleFieldChange('name')}
          styles={styles.timeStampInput}
          placeholder={'Add timestamp title'}
          draggable={!isAddingTimestamp}
          fullWidth={true}
          onFocus={handleFocusBehavior}
          error={hasError ? timestampError.nameMessage : ''}
        />
      </Grid>
      <Grid item xs={2}>
        <FormTextField
          value={newTimestamp.id ? '' : timestampError.id !== null ? '' : newTimestamp.value}
          onChange={handleFieldChange('value')}
          styles={styles.timeStampInput}
          placeholder={currentTime}
          draggable={!isAddingTimestamp}
          fullWidth={true}
          onFocus={handleFocusBehavior}
          onBlur={handleNewTimestamp}
          error={hasError ? timestampError.valueMessage : ''}
          noWrapError={true}
        />
      </Grid>
      <Grid item xs={5}>
        <Stack direction={'row'}>
          <IconButton
            id="add-timestamp-button"
            sx={styles.tableActionIcon}
            onClick={() => {
              if (isTimestampValid(newTimestamp) && addTimestamp) addTimestamp();
            }}
            disabled={!isAddingTimestamp}
          >
            <AddIcon sx={{ color: theme.palette.primary.main, fontSize: '15px' }} />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
};
