import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Modal,
  Switch,
  Stack,
  TextField
} from '@mui/material';
import { Close as CloseIcon, NavigateBefore as NavigateBeforeIcon } from '@mui/icons-material';
import moment from 'moment';
import API from '../../API';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ControlledStaticDatePicker } from '../../../Components/CustomUIElements/SingleDatePicker';
import VirtualizeGrouped from '../../../Components/CustomUIElements/VirtualizedGroupedAutocomplete';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import HtmlEditor from '../../../Components/Utilities/HtmlEditor';
import { IError } from '../../../SmartForms/types';
import { IEvent, IAddToEventModalProps } from '../../types';
import SelectedApplicants from '../SelectedApplicants';
import { classes } from '../styles';
import NotificationOptionSwitch from '../../../Application/Modals/Components/NotificationOptionsSwitch';
import SmsTemplateComponent from '../../../Components/CustomUIElements/SmsTemplateComponent';
import { usePlaceholders } from '../../../hooks/usePlaceholders';
import { useEmailTemplatesOptions } from '../../../hooks/useEmailTemplatesOptions';
import { scrollToElement } from '../../../utils/scroll-to-element';

interface IRecruiter {
  id: number;
  name: string;
}

interface IEventType {
  id: number;
  name: string;
}

export default function InviteToTimeslot({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  filters,
  smsTemplatePermissions
}: IAddToEventModalProps) {
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState({
    templates: false,
    recruiters: false,
    eventTypes: false,
    events: false
  });
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');
  const [sendableBody, setSendableBody] = useState('');
  const [selectedRecruiter, setSelectedRecruiter] = useState<IRecruiter | null>(null);
  const [eventTypes, setEventTypes] = useState<IEventType[]>([]);
  const [selectedEventType, setSelectedEventType] = useState<IEventType | null>(null);
  const [excludeApplicants, setExcludeApplicants] = useState(false);
  const [showTimeslots, setShowTimeslots] = useState(false);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [step, setStep] = useState<number>(1);
  const [timeslotHovered, setTimeslotHovered] = useState<number | null>(null);
  const recruiterRef = useRef<HTMLDivElement>(null);
  const eventTypeRef = useRef<HTMLDivElement>(null);
  const templateRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format('YYYY-MM-DD'));
  const [notification, setNotification] = useState<string>('email');
  const [smsMessage, setSmsMessage] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});

  const buttonName = step === 1 ? 'Next' : 'Send';

  const { data: emailTemplates, isLoading: loadingEmailTemplates } = useEmailTemplatesOptions({
    apiKey,
    onError: (message: string) => setSnackbar({ message, state: 'error' })
  });

  const { data: placeholders } = usePlaceholders({
    type: 'interview',
    apiKey,
    onError: (message: string) => setSnackbar({ message, state: 'error' })
  });

  const { data: recruiters, isLoading: loadingRecruiters } = useQuery({
    queryKey: ['recruiters'],
    queryFn: async () => {
      const { users } = await API.getRecruiters({ 'X-api-authenticate': apiKey });
      return users;
    },
    onError: (error: IError) => setSnackbar({ message: error.errors.join(', '), state: 'error' })
  });

  const { mutate: sendSms, isLoading: sendingSms } = useMutation({
    mutationFn: async () => {
      if (!selectedEventType) return;

      const res = await API.sendInviteSms(
        { 'X-api-authenticate': apiKey },
        jobId,
        selectedEventType.id,
        globalSelected.map((a) => a.id),
        smsMessage
      );
      if (res.success !== 0) {
        setSnackbar({
          message: 'Invitation to the event has been sent successfully',
          state: 'success'
        });
        setModalsOpen((prev) => ({ ...prev, inviteToTimeslot: false }));
      } else {
        setSnackbar({ message: 'There is an error in sending SMS invitation', state: 'error' });
      }
      return res;
    }
  });

  const getEventTypes = useCallback(async () => {
    setIsLoading((prev) => ({ ...prev, eventTypes: true }));
    try {
      const response = await fetch(`/api/v4/calendar/event_types`, {
        method: 'GET',
        headers: {
          'x-api-authenticate': apiKey
        }
      });
      const data = await response.json();
      setEventTypes(data.event_types);
    } catch (error) {
      setSnackbar({
        message: 'Failed to load event types',
        state: 'error'
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, eventTypes: false }));
    }
  }, [apiKey, setSnackbar]);

  const handleSelectTemplate = async (template: string) => {
    if (!template) {
      setSelectedTemplate(null);
      setBody('');
      setSendableBody('');
      return;
    }
    setSelectedTemplate(template);
    const templateId = template.split(' -')[0];
    try {
      const response = await fetch(`/api/v4/calendar/email_templates/${templateId}`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const data = await response.json();
      setSubject(data.subject);
      setBody(data.body);
      setSendableBody(data.body);
      if (response.status !== 200) {
        throw new Error(data);
      }
    } catch (error) {
      setSnackbar({
        message: 'Error loading template',
        state: 'error'
      });
      setBody('');
    }
  };

  const getTimeslots = useCallback(
    async (eventId?: number) => {
      setIsLoading((prev) => ({ ...prev, events: true }));
      const queryString = `/api/v4/calendar/events?${
        eventId ? 'event_type_id=' + eventId : ''
      }&date=${selectedMonth}&override_limit=true`;
      try {
        const response = await fetch(queryString, {
          method: 'GET',
          headers: {
            'x-api-authenticate': apiKey
          }
        });
        const data = await response.json();
        setEvents(data.events);
      } catch (error) {
        setSnackbar({
          message: 'Failed to load timeslots',
          state: 'error'
        });
      } finally {
        setIsLoading((prev) => ({ ...prev, events: false }));
      }
    },
    [apiKey, setSnackbar, selectedMonth]
  );

  const handleClose = () => setModalsOpen((prev) => ({ ...prev, inviteToTimeslot: false }));

  const validateInput = () => {
    const fieldsErrors: Record<string, string> = {};
    if (step === 1) {
      !selectedRecruiter && (fieldsErrors.recruiter = 'Must select a recruiter');
      !selectedEventType && (fieldsErrors.eventType = 'Must select an event type');
    } else {
      if (notification === 'email') {
        !selectedTemplate && (fieldsErrors.template = 'Must select a template');
        !sendableBody.trim() && (fieldsErrors.body = 'Must add email body');
      } else {
        !smsMessage.trim() && (fieldsErrors.sms = 'Must add SMS content');
      }
    }
    setErrors(fieldsErrors);
    if (fieldsErrors.recruiter) {
      scrollToElement(recruiterRef);
    } else if (fieldsErrors.eventType) {
      scrollToElement(eventTypeRef);
    } else if (fieldsErrors.template) {
      scrollToElement(templateRef);
    } else if (fieldsErrors.body) {
      scrollToElement(bodyRef);
    }
    return !Object.keys(fieldsErrors).length;
  };

  const handleSubmit = async () => {
    if (step === 1) {
      validateInput() && setStep(step + 1);
    } else {
      notification === 'email'
        ? validateInput() && handleSubmitEmail()
        : validateInput() && sendSms();
    }
  };

  const handleSubmitEmail = async () => {
    setIsSending(true);
    const templateId = selectedTemplate?.split(' -')[0];
    try {
      const response = await fetch('/api/v4/calendar/invitations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        body: JSON.stringify({
          user_id: selectedRecruiter?.id,
          event_type_id: selectedEventType?.id,
          job_id: jobId,
          invitation: {
            application_ids: globalSelected.map((a) => a.id),
            subject: subject,
            body: sendableBody,
            ...(selectedTemplate ? { email_template_id: templateId } : {}),
            only_new: Number(excludeApplicants)
          }
        })
      });
      if (response.ok) {
        setSnackbar({
          message: 'Timeslot invitations sent successfully',
          state: 'success'
        });
        handleClose();
      } else {
        setSnackbar({
          message: 'Failed to send timeslot invitations, please try again',
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        message: 'Failed to send timeslot invitations, please try again',
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  const disableDate = (date: Date) => {
    const datelist = events.map((e) => moment(e.begins_at).format('YYYY-MM-DD'));
    return !selectedEventType ? true : !datelist.includes(moment(date).format('YYYY-MM-DD'));
  };

  const sameDay = (date: string) => {
    return moment(date).format('YYYY-MM-DD') === moment(selectedDay).format('YYYY-MM-DD');
  };

  useEffect(() => {
    if (modalsOpen.inviteToTimeslot) {
      getTimeslots();
      getEventTypes();
    }
  }, [getEventTypes, getTimeslots, modalsOpen.inviteToTimeslot, selectedMonth]);

  return (
    <Modal
      open={modalsOpen.inviteToTimeslot}
      onClose={handleClose}
      disableEnforceFocus
      aria-labelledby="invite-to-event-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
        <Box id="invite-to-event-title" sx={{ ...classes.actionModalHeader, paddingBottom: 3 }}>
          Invite to event
        </Box>
        <Box
          sx={{ padding: '0px 8px', flexGrow: 1, overflow: 'auto' }}
          id="invite-to-event-modal-content"
          aria-label="Invite to event modal content"
        >
          <Box role="form" id="invite-to-event-form" aria-label="Invite to event form">
            {step === 1 && (
              <Stack sx={{ rowGap: 2 }}>
                <Autocomplete
                  options={recruiters}
                  loading={loadingRecruiters}
                  getOptionLabel={(option) => option.name}
                  onChange={(_event, value) => {
                    value && setErrors({ ...errors, recruiter: '' });
                    setSelectedRecruiter(value);
                  }}
                  value={selectedRecruiter}
                  sx={{ ...sharedClasses.formAutocomplete, width: '287px', marginTop: 3 }}
                  ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Recruiter"
                      InputLabelProps={{ shrink: true }}
                      required
                      sx={sharedClasses.autoCompleteTextfield}
                      helperText={errors.recruiter}
                    />
                  )}
                  ref={recruiterRef}
                />
                <Autocomplete
                  options={eventTypes}
                  getOptionLabel={(option) => option.name}
                  onChange={(_event, value) => {
                    value && setErrors({ ...errors, eventType: '' });
                    setSelectedEventType(value);
                    getTimeslots(value?.id);
                  }}
                  value={selectedEventType}
                  sx={{ ...sharedClasses.formAutocomplete, width: '287px', marginTop: 3 }}
                  ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Event type"
                      InputLabelProps={{ shrink: true }}
                      required
                      sx={sharedClasses.autoCompleteTextfield}
                      helperText={errors.eventType}
                    />
                  )}
                  ref={eventTypeRef}
                />
                <FormControlLabel
                  sx={classes.formSwitchLabels}
                  control={
                    <Switch
                      sx={{
                        ...(showTimeslots ? classes.switchActive : classes.switch)
                      }}
                      checked={showTimeslots}
                      onChange={() => {
                        setSelectedDay(new Date());
                        setShowTimeslots((prev) => !prev);
                      }}
                    />
                  }
                  label="Show timeslots"
                  labelPlacement="start"
                />
                <Collapse in={showTimeslots}>
                  <Box sx={classes.timeslotsContainer}>
                    <Box sx={classes.calendar}>
                      <ControlledStaticDatePicker
                        displayStaticWrapperAs="desktop"
                        views={['day']}
                        value={selectedDay}
                        shouldDisableDate={disableDate}
                        onChange={(value) => setSelectedDay(value)}
                        onMonthChange={(value) =>
                          setSelectedMonth(moment(value._d).format('YYYY-MM-DD'))
                        }
                      />
                    </Box>
                    <Box sx={classes.dailyTimeslots}>
                      <Box sx={classes.dailyTimeslotsHeader}>Timeslots</Box>
                      <Box sx={classes.dailyTimeslotsContainer}>
                        {events.length > 0 &&
                          !isLoading.events &&
                          events
                            .filter((event) => sameDay(event.begins_at))
                            .map((event) => (
                              <Box
                                key={event.id}
                                sx={classes.timeslot}
                                onMouseEnter={() => {
                                  setTimeslotHovered(event.id);
                                }}
                                onMouseLeave={() => {
                                  setTimeslotHovered(null);
                                }}
                              >
                                <Box sx={classes.timeslotTime}>
                                  {moment(event.begins_at).format('HH:mm')}
                                </Box>
                                <Box sx={{ ...classes.timeslotType, ...classes.ellipsis }}>
                                  {event.subject}
                                </Box>
                                {timeslotHovered === event.id && (
                                  <Box sx={classes.expandedTimeslot}>
                                    <Box sx={classes.timeslotTime}>
                                      {moment(event.begins_at).format('HH:mm')}
                                    </Box>
                                    <Box sx={classes.timeslotType}>{event.subject}</Box>
                                    {event.note && (
                                      <Box sx={classes.timeslotNote}>{event.note}</Box>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            ))}
                        {!isLoading.events &&
                          events.filter((event) => sameDay(event.begins_at)).length === 0 && (
                            <Box sx={classes.noTimeslots}>No timeslots on selected date</Box>
                          )}
                        {isLoading.events && (
                          <Box sx={classes.noTimeslots}>
                            <CircularProgress size={32} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
                <Box sx={{ marginLeft: '8px' }}>
                  <Stack sx={classes.modalCandidatesHeader}>Candidates</Stack>
                  <SelectedApplicants
                    globalSelected={globalSelected}
                    handleDeselectCandidate={handleDeselectCandidate}
                    closeModalCallback={handleClose}
                    filters={filters}
                  />
                </Box>
              </Stack>
            )}
            {step === 2 && (
              <Box>
                <NotificationOptionSwitch
                  setNotification={setNotification}
                  notification={notification}
                />
                {notification === 'email' ? (
                  <Stack sx={{ paddingTop: 4, rowGap: 3 }}>
                    <VirtualizeGrouped
                      value={selectedTemplate}
                      options={emailTemplates || []}
                      onChange={(value) => {
                        value ? handleSelectTemplate(value) : setBody('');
                      }}
                      styles={{ ...sharedClasses.formAutocomplete, width: '600px' }}
                      label="Template"
                      loading={loadingEmailTemplates}
                      loadingText="Loading template list..."
                      innerRef={templateRef}
                      error={errors.template}
                      errorStyles={{ ...sharedClasses.errorBox, top: '-24px', right: '0px' }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={classes.formCheckbox}
                          onChange={(e) => setExcludeApplicants(e.target.checked)}
                          value={excludeApplicants}
                        />
                      }
                      label="Exclude applicants who have received this email template"
                      sx={classes.checkboxLabel}
                    />
                    <Box ref={bodyRef}>
                      <Stack sx={{ ...classes.formLabel, flexDirection: 'row', columnGap: '2px' }}>
                        Body <Stack sx={{ color: '#E37D7A' }}>*</Stack>
                      </Stack>
                      <HtmlEditor body={body} onChange={setSendableBody} />
                      <Stack sx={sharedClasses.errorBox}>{errors.body}</Stack>
                      <Box sx={classes.placeholderText}>
                        Available placeholders are: {placeholders}
                      </Box>
                    </Box>
                  </Stack>
                ) : (
                  <SmsTemplateComponent
                    smsTemplatePermissions={smsTemplatePermissions}
                    setSnackbar={setSnackbar}
                    smsMessage={smsMessage}
                    setSmsMessage={setSmsMessage}
                    smsMessageError={errors.sms}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ ...classes.modalFooter, columnGap: 1, paddingTop: 2 }}>
          <Button
            id="close-invite-to-event-button"
            sx={classes.modalCancelButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          {step !== 1 && (
            <Button
              onClick={() => setStep(1)}
              sx={{ ...sharedClasses.genericButtonSecondary, minWidth: 'unset', padding: 1 }}
            >
              <NavigateBeforeIcon />
            </Button>
          )}
          <Button
            id="send-invite-to-event-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '71px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSending || sendingSms ? <CircularProgress size={20} color="inherit" /> : buttonName}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
