"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { searchTableColumns, actionDrawerModals } from "./config";
import { initialSearchFilters } from "./AISearch/SearchFilters";
import {
  addSelected,
  removeSelected,
  addAllPageItems,
  removeAllPageItems
} from "../NewUI/Components/GenericTable/tableHelper";
import moment from "moment";
export const initialEngageState = {
  selectedTab: 0,
  indicatorWidth: 0,
  snackbarState: {
    message: "",
    state: "success"
  },
  searchQuery: "",
  booleanQuery: null,
  searchType: "none",
  searchSettings: {
    page: 1,
    limit: 10,
    sort: "last_applied_on",
    sort_order: "desc"
  },
  searchFilters: {},
  tempSearchFilters: initialSearchFilters,
  searchTable: {
    canScroll: [false, true],
    tableHovered: false,
    density: "Default",
    columns: searchTableColumns,
    selectedItemIds: [],
    selectedItems: [],
    actions: {
      selectedItem: null,
      anchorEl: null,
      isOpen: {
        addNote: false,
        viewAttachments: false,
        moreOptions: false,
        email: false,
        candidatePool: false
      }
    },
    modalsOpen: actionDrawerModals.reduce((acc, { id }) => {
      acc[id] = false;
      return __spreadProps(__spreadValues({}, acc), { exportCsv: false, addSearch: false, candidatePool: false });
    }, {})
  },
  dateFieldRange: {
    from: null,
    to: null
  },
  tlpMatchModalState: {
    open: false,
    step: "select",
    isEditing: false,
    editableBooleanQuery: "",
    selectedTLP: {
      id: -1,
      name: "",
      html: ""
    },
    jobsList: {
      data: [],
      isLoading: false
    }
  }
};
export const engageReducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_TAB":
      return __spreadProps(__spreadValues({}, state), {
        selectedTab: action.payload
      });
    case "SET_INDICATOR_WIDTH": {
      if (action.payload === void 0) {
        return state;
      } else {
        return __spreadProps(__spreadValues({}, state), {
          indicatorWidth: action.payload - (state.indicatorWidth ? 32 : 37)
        });
      }
    }
    case "SET_SNACKBAR":
      return __spreadProps(__spreadValues({}, state), {
        snackbarState: action.payload
      });
    case "SET_SEARCH_QUERY":
      return __spreadProps(__spreadValues({}, state), {
        searchQuery: action.payload
      });
    case "EDIT_BOOLEAN_QUERY":
      return __spreadProps(__spreadValues({}, state), {
        tlpMatchModalState: __spreadProps(__spreadValues({}, state.tlpMatchModalState), {
          isEditing: true,
          open: true,
          editableBooleanQuery: state.booleanQuery,
          step: "edit"
        })
      });
    case "CANCEL_EDIT_BOOLEAN_QUERY":
      return __spreadProps(__spreadValues({}, state), {
        tlpMatchModalState: __spreadProps(__spreadValues({}, state.tlpMatchModalState), {
          isEditing: false,
          open: false,
          editableBooleanQuery: ""
        })
      });
    case "SET_EDITABLE_BOOLEAN_QUERY":
      return __spreadProps(__spreadValues({}, state), {
        tlpMatchModalState: __spreadProps(__spreadValues({}, state.tlpMatchModalState), {
          editableBooleanQuery: action.payload
        })
      });
    case "CONFIRM_STRING_MATCH_SEARCH":
      return __spreadProps(__spreadValues({}, state), {
        booleanQuery: action.payload,
        searchType: "string_match"
      });
    case "CONFIRM_EDIT_BOOLEAN_QUERY":
      return __spreadProps(__spreadValues({}, state), {
        booleanQuery: action.payload,
        searchType: state.tlpMatchModalState.isEditing ? state.searchType : "tlp_match",
        tlpMatchModalState: __spreadProps(__spreadValues({}, state.tlpMatchModalState), {
          open: false,
          isEditing: false
        })
      });
    case "CLEAR_SEARCH":
      return __spreadProps(__spreadValues({}, state), {
        booleanQuery: null,
        searchType: "none",
        searchQuery: ""
      });
    case "SET_CAN_SCROLL":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadProps(__spreadValues({}, state.searchTable), {
          canScroll: action.payload
        })
      });
    case "SET_TABLE_HOVERED":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadProps(__spreadValues({}, state.searchTable), {
          tableHovered: action.payload
        })
      });
    case "OPEN_TLP_MATCH_MODAL":
      return __spreadProps(__spreadValues({}, state), {
        tlpMatchModalState: __spreadProps(__spreadValues({}, initialEngageState.tlpMatchModalState), {
          step: "select",
          open: true
        })
      });
    case "CLOSE_TLP_MATCH_MODAL":
      return __spreadProps(__spreadValues({}, state), {
        tlpMatchModalState: __spreadProps(__spreadValues({}, state.tlpMatchModalState), {
          open: false
        })
      });
    case "SET_TLP_MATCH_MODAL_STEP":
      return __spreadProps(__spreadValues({}, state), {
        tlpMatchModalState: __spreadProps(__spreadValues({}, state.tlpMatchModalState), {
          step: action.payload
        })
      });
    case "SET_JOBS_LIST_LOADING":
      return __spreadProps(__spreadValues({}, state), {
        tlpMatchModalState: __spreadProps(__spreadValues({}, state.tlpMatchModalState), {
          jobsList: __spreadProps(__spreadValues({}, state.tlpMatchModalState.jobsList), {
            isLoading: true
          })
        })
      });
    case "SET_JOBS_LIST":
      return __spreadProps(__spreadValues({}, state), {
        tlpMatchModalState: __spreadProps(__spreadValues({}, state.tlpMatchModalState), {
          jobsList: __spreadProps(__spreadValues({}, state.tlpMatchModalState.jobsList), {
            isLoading: false,
            data: action.payload
          })
        })
      });
    case "SET_SELECTED_TLP":
      return __spreadProps(__spreadValues({}, state), {
        tlpMatchModalState: __spreadProps(__spreadValues({}, state.tlpMatchModalState), {
          selectedTLP: action.payload
        })
      });
    case "ADD_SELECTED_ITEM":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadValues(__spreadValues({}, state.searchTable), addSelected(
          state.searchTable.selectedItems,
          state.searchTable.selectedItemIds,
          action.payload
        ))
      });
    case "REMOVE_SELECTED_ITEM":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadValues(__spreadValues({}, state.searchTable), removeSelected(
          state.searchTable.selectedItems,
          state.searchTable.selectedItemIds,
          action.payload
        ))
      });
    case "ADD_ALL_PAGE_ITEMS":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadValues(__spreadValues({}, state.searchTable), addAllPageItems(state.searchTable.selectedItems, action.payload))
      });
    case "REMOVE_ALL_SELECTED_ITEMS":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadProps(__spreadValues({}, state.searchTable), {
          selectedItemIds: [],
          selectedItems: []
        })
      });
    case "REMOVE_ALL_PAGE_ITEMS":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadValues(__spreadValues({}, state.searchTable), removeAllPageItems(state.searchTable.selectedItems, action.payload))
      });
    case "SELECT_ALL_ITEMS":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadProps(__spreadValues({}, state.searchTable), {
          selectedItemIds: action.payload
        })
      });
    case "SET_COUNTRIES_DATA":
      return __spreadProps(__spreadValues({}, state), {
        countriesData: action.payload
      });
    case "SET_DENSITY":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadProps(__spreadValues({}, state.searchTable), {
          density: action.payload
        })
      });
    case "SET_SORT":
      return __spreadProps(__spreadValues({}, state), {
        searchSettings: __spreadProps(__spreadValues({}, state.searchSettings), {
          sort: action.payload.sortBy,
          sort_order: action.payload.sortOrder
        })
      });
    case "SET_COLUMNS":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadProps(__spreadValues({}, state.searchTable), {
          columns: action.payload
        })
      });
    case "SET_PAGE":
      return __spreadProps(__spreadValues({}, state), {
        searchSettings: __spreadProps(__spreadValues({}, state.searchSettings), {
          page: action.payload
        })
      });
    case "SET_LIMIT":
      return __spreadProps(__spreadValues({}, state), {
        searchSettings: __spreadProps(__spreadValues({}, state.searchSettings), {
          limit: action.payload
        })
      });
    case "SET_TEMP_FILTERS":
      return __spreadProps(__spreadValues({}, state), {
        tempSearchFilters: __spreadValues(__spreadValues({}, state.tempSearchFilters), action.payload)
      });
    case "SET_DATE_RANGE_TEMP_FILTER":
      return __spreadProps(__spreadValues({}, state), {
        tempSearchFilters: __spreadProps(__spreadValues({}, state.tempSearchFilters), {
          date1: action.payload.from,
          date2: action.payload.to
        })
      });
    case "SET_DATE_FIELD_RANGE":
      return __spreadProps(__spreadValues({}, state), {
        dateFieldRange: action.payload
      });
    case "SET_FILTERS": {
      const convertedFilters = Object.entries(state.tempSearchFilters).reduce(
        (acc, [key, value]) => {
          if (Array.isArray(value)) {
            if (value.length > 0) {
              if (key === "country" || key === "state") {
                acc[key] = value.map((f) => f.name);
              } else {
                acc[key] = value[0].id ? value.map((f) => f.id) : value;
              }
            }
          } else if (value) {
            if (key === "date1" || key === "date2") {
              acc[key] = moment(value).format("YYYY-MM-DD");
            } else {
              acc[key] = value;
            }
          }
          return acc;
        },
        {}
      );
      return __spreadProps(__spreadValues({}, state), {
        searchFilters: convertedFilters
      });
    }
    case "RESET_FILTERS":
      return __spreadProps(__spreadValues({}, state), {
        searchFilters: {},
        tempSearchFilters: initialSearchFilters,
        dateFieldRange: {
          from: null,
          to: null
        }
      });
    case "SELECT_ACTION_ITEM":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadProps(__spreadValues({}, state.searchTable), {
          actions: __spreadProps(__spreadValues({}, state.searchTable.actions), {
            selectedItem: action.payload.item,
            anchorEl: action.payload.anchorEl,
            isOpen: __spreadProps(__spreadValues({}, state.searchTable.actions.isOpen), {
              [action.payload.name]: true
            })
          })
        })
      });
    case "CLOSE_ACTION_ITEM":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadProps(__spreadValues({}, state.searchTable), {
          actions: __spreadProps(__spreadValues({}, state.searchTable.actions), {
            selectedItem: null,
            isOpen: __spreadProps(__spreadValues({}, state.searchTable.actions.isOpen), {
              [action.payload]: false
            })
          })
        })
      });
    case "SET_ACTION_MODAL_OPEN":
      return __spreadProps(__spreadValues({}, state), {
        searchTable: __spreadProps(__spreadValues({}, state.searchTable), {
          modalsOpen: __spreadProps(__spreadValues({}, state.searchTable.modalsOpen), {
            [action.payload.name]: action.payload.isOpen
          })
        })
      });
    default:
      return state;
  }
};
