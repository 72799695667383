var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import AdTable from "../components/AdTable";
import PropTypes from "prop-types";
import { withScoutTheme } from "../../ThemeContext/ThemeContext";
import axios from "axios";
import useStyles from "./useStyles";
const ADTYPES = [{
  id: ["open", "new"],
  name: "Active"
}, {
  id: ["expired"],
  name: "Expired"
}, {
  id: ["archived"],
  name: "Archived"
}];
function AdList({ job_ads, requestNewAdUrl }) {
  const [ads, setAds] = useState(job_ads);
  const [selectedAdType, setSelectedAdType] = useState(ADTYPES[0].id);
  const classes = useStyles();
  function getNewAdUrl() {
    return __async(this, null, function* () {
      try {
        const token = document.querySelector('meta[name="csrf-token"]').content;
        const { data } = yield axios.post(requestNewAdUrl, {
          authenticity_token: token,
          headers: {
            "Content-Type": "x-www-form-urlencoded"
          }
        });
        window.location = data.url;
      } catch (e) {
        console.log(e);
      }
    });
  }
  return /* @__PURE__ */ React.createElement("div", { className: classes.containerOuter }, /* @__PURE__ */ React.createElement("div", { className: classes.container }, /* @__PURE__ */ React.createElement("div", { className: classes.banner }, /* @__PURE__ */ React.createElement("div", { id: "indeed-logo" }), /* @__PURE__ */ React.createElement(
    Button,
    {
      style: { minWidth: 110, fontSize: 12 },
      disableElevation: true,
      className: classes.buttonSecondary,
      onClick: () => getNewAdUrl()
    },
    "New Ad"
  )), /* @__PURE__ */ React.createElement("div", { className: classes.toggleButtonGroup }, ADTYPES.map(
    (type) => /* @__PURE__ */ React.createElement(
      Button,
      {
        key: type.name,
        disableElevation: true,
        onClick: () => setSelectedAdType(type.id),
        className: type.id[0] === selectedAdType[0] ? [classes.buttonPrimary, classes.buttonActive] : classes.buttonGrey
      },
      type.name
    )
  )), /* @__PURE__ */ React.createElement(
    AdTable,
    {
      jobAds: job_ads,
      ads: ads.filter((ad) => selectedAdType.includes(ad.status)),
      setAds
    }
  )));
}
AdList.propTypes = {
  job_ads: PropTypes.array.isRequired,
  requestNewAdUrl: PropTypes.string.isRequired
};
export default withScoutTheme(AdList);
