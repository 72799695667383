var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Chip,
  Button,
  Tooltip
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useStyles from "./useStyles";
import { withScoutTheme } from "../../../ThemeContext/ThemeContext";
import PropTypes from "prop-types";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
function SelectRecipients({ statuses, applications, setSelectedApplications, setIsSelectModalOpen }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [unselectedStatuses, setUnselectedStatuses] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    setUnselectedStatuses(
      statuses.filter((status) => applications.map((app) => app.status).includes(status.id)).filter((status) => !selectedStatuses.map((selected) => selected.id).includes(status.id))
    );
  }, [selectedStatuses]);
  useEffect(() => {
    setSelectedStatuses(selectedStatuses.filter((status) => selectedUsers.map((app) => app.status).includes(status.id)));
  }, [selectedUsers]);
  function handleStatusRemove(id) {
    setSelectedStatuses(selectedStatuses.filter((selected) => selected.id !== id));
    setSelectedUsers(selectedUsers.filter((app) => app.status !== id));
  }
  function handleStatusAdd(e) {
    const newApplicants = applications.filter((app) => app.status === e.target.value.id).filter((app) => !selectedUsers.map((selected) => selected.id).includes(app.id));
    setSelectedStatuses([...selectedStatuses, e.target.value]);
    setSelectedUsers([...selectedUsers, ...newApplicants]);
  }
  function handleClick() {
    setSelectedApplications(selectedUsers);
    setIsSelectModalOpen(false);
  }
  return /* @__PURE__ */ React.createElement("div", { className: classes.root }, /* @__PURE__ */ React.createElement("h4", null, "Select Recipients"), /* @__PURE__ */ React.createElement("div", { className: classes.searchContainer }, /* @__PURE__ */ React.createElement(
    Autocomplete,
    {
      classes: {
        root: classes.autocompleteField,
        inputRoot: classes.autoCompleteInput,
        endAdornment: classes.endAdornment
      },
      multiple: true,
      disableClearable: true,
      options: applications,
      getOptionLabel: (option) => option.name,
      value: selectedUsers,
      filterSelectedOptions: true,
      onChange: (e, newValue) => {
        setSelectedUsers(newValue);
      },
      variant: "outlined",
      renderTags: () => null,
      renderInput: (params) => /* @__PURE__ */ React.createElement(
        TextField,
        __spreadProps(__spreadValues({}, params), {
          classes: { root: classes.search },
          placeholder: "Search Users",
          InputLabelProps: {
            classes: { root: classes.search }
          },
          InputProps: __spreadProps(__spreadValues({}, params.InputProps), {
            disableUnderline: true,
            startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, /* @__PURE__ */ React.createElement(SearchOutlinedIcon, null))
          })
        })
      )
    }
  ), /* @__PURE__ */ React.createElement(
    Select,
    {
      className: classes.inputBase,
      classes: { root: classes.select },
      displayEmpty: true,
      renderValue: () => "Filter candidates",
      variant: "outlined",
      value: "",
      onChange: handleStatusAdd
    },
    unselectedStatuses.map(
      (status) => /* @__PURE__ */ React.createElement(MenuItem, { key: status.id, value: status }, status.name)
    )
  )), selectedStatuses.length > 0 && /* @__PURE__ */ React.createElement("div", { className: classes.chipContainer }, selectedStatuses.map(
    (status) => /* @__PURE__ */ React.createElement(
      Chip,
      {
        key: status.id,
        className: classes.chipItem,
        label: status.name,
        value: status,
        onDelete: () => handleStatusRemove(status.id),
        variant: "outlined"
      }
    )
  )), /* @__PURE__ */ React.createElement("div", { className: classes.table }, /* @__PURE__ */ React.createElement("div", { className: classes.row }, /* @__PURE__ */ React.createElement("div", { className: `${classes.tableHeader} ${classes.tableLg}` }, "Name"), /* @__PURE__ */ React.createElement("div", { className: `${classes.tableHeader} ${classes.tableMd}` }, "Phone"), /* @__PURE__ */ React.createElement("div", { className: `${classes.tableHeader} ${classes.tableLg}` }, "Status"), /* @__PURE__ */ React.createElement("div", null)), selectedUsers.length <= 0 ? /* @__PURE__ */ React.createElement("div", { className: classes.row }, /* @__PURE__ */ React.createElement("span", { className: classes.noApplicants }, "No applicants selected")) : selectedUsers.map(
    (app) => /* @__PURE__ */ React.createElement(
      "div",
      {
        key: app.id,
        className: classes.row
      },
      /* @__PURE__ */ React.createElement("div", { className: classes.tableLg }, app.name),
      /* @__PURE__ */ React.createElement("div", { className: classes.tableMd }, app.phone),
      /* @__PURE__ */ React.createElement("div", { className: classes.tableLg }, statuses.find((status) => status.id === app.status) ? statuses.find((status) => status.id === app.status).name : "None"),
      /* @__PURE__ */ React.createElement(IconButton, { onClick: () => setSelectedUsers(selectedUsers.filter((selected) => selected.id !== app.id)) }, /* @__PURE__ */ React.createElement(HighlightOffIcon, { color: "error" }))
    )
  )), /* @__PURE__ */ React.createElement("div", { className: classes.buttonContainer }, selectedUsers.length <= 1 ? /* @__PURE__ */ React.createElement(Tooltip, { title: "Please select 2 or more applicants" }, /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
    Button,
    {
      disabled: true,
      disableElevation: true,
      variant: "contained",
      color: "secondary",
      onClick: handleClick,
      endIcon: /* @__PURE__ */ React.createElement(ChevronRightRoundedIcon, null)
    },
    "Next"
  ))) : /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      variant: "contained",
      color: "secondary",
      onClick: handleClick,
      endIcon: /* @__PURE__ */ React.createElement(ChevronRightRoundedIcon, null)
    },
    "Next"
  )));
}
SelectRecipients.propTypes = {
  statuses: PropTypes.array.isRequired,
  applications: PropTypes.array.isRequired,
  setSelectedApplications: PropTypes.func.isRequired,
  setIsSelectModalOpen: PropTypes.func.isRequired
};
export default withScoutTheme(SelectRecipients);
