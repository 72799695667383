"use strict";
export const TabGroup = {
  JOB_INFORMATION: 0,
  CANDIDATE_SETTINGS: 1,
  AUTOMATION: 2,
  ADVANCED: 3
};
const JOB_INFORMATION_TAB_GROUP_SIZE = 5;
const CANDIDATE_SETTINGS_TAB_GROUP_SIZE = 1;
const AUTOMATION_TAB_GROUP_SIZE = 6;
const ADVANCED_TAB_GROUP_SIZE = 7;
let offset = 1;
const JOB_INFORMATION_OFFSET = offset;
offset += JOB_INFORMATION_TAB_GROUP_SIZE + 1;
const CANDIDATE_SETTINGS_OFFSET = offset;
offset += CANDIDATE_SETTINGS_TAB_GROUP_SIZE + 1;
const AUTOMATION_OFFSET = offset;
offset += AUTOMATION_TAB_GROUP_SIZE + 1;
const ADVANCED_OFFSET = offset;
export function convertToNormalTabIndex(tabIndex, group) {
  switch (group) {
    case TabGroup.JOB_INFORMATION:
      return JOB_INFORMATION_OFFSET - 1 + tabIndex;
    case TabGroup.CANDIDATE_SETTINGS:
      return CANDIDATE_SETTINGS_OFFSET - 2 + tabIndex;
    case TabGroup.AUTOMATION:
      return AUTOMATION_OFFSET - 3 + tabIndex;
    case TabGroup.ADVANCED:
      return ADVANCED_OFFSET - 4 + tabIndex;
  }
  return tabIndex;
}
export function convertToPanelIndex(tabIndex, group) {
  switch (group) {
    case TabGroup.JOB_INFORMATION:
      return JOB_INFORMATION_OFFSET + tabIndex;
    case TabGroup.CANDIDATE_SETTINGS:
      return CANDIDATE_SETTINGS_OFFSET + tabIndex;
    case TabGroup.AUTOMATION:
      return AUTOMATION_OFFSET + tabIndex;
    case TabGroup.ADVANCED:
      return ADVANCED_OFFSET + tabIndex;
  }
  return tabIndex;
}
;
export function convertToGroupAndTabIndex(panelIndex) {
  if (panelIndex < CANDIDATE_SETTINGS_OFFSET) {
    return createTabIndex(TabGroup.JOB_INFORMATION, panelIndex - JOB_INFORMATION_OFFSET);
  }
  if (panelIndex < AUTOMATION_OFFSET) {
    return createTabIndex(TabGroup.CANDIDATE_SETTINGS, panelIndex - CANDIDATE_SETTINGS_OFFSET);
  }
  if (panelIndex < ADVANCED_OFFSET) {
    return createTabIndex(TabGroup.AUTOMATION, panelIndex - AUTOMATION_OFFSET);
  }
  return createTabIndex(TabGroup.ADVANCED, panelIndex - ADVANCED_OFFSET);
}
function createTabIndex(group, index) {
  return { index, group };
}
