import React, { Dispatch, useState } from 'react';
import { Box, CircularProgress, Modal, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import SelectField from '../../../Components/CustomUIElements/SelectField';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import Api from '../../API';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { SmartFormFieldsState, SmartFormFieldsAction } from '../../reducer';

const sourceTypes = ['Smart Form', 'Approval Template', 'Job'];

export default function ImportFields({
  formId,
  SmartFormFieldsState,
  dispatch
}: {
  formId: number;
  SmartFormFieldsState: SmartFormFieldsState;
  dispatch: Dispatch<SmartFormFieldsAction>;
}) {
  const [sourceType, setSourceType] = useState<string>('Smart Form');
  const queryClient = useQueryClient();

  const handleClose = () => {
    dispatch({ type: 'SET_SELECTED_SOURCE_ID', payload: null });
    dispatch({ type: 'SET_SYSTEM_FIELD_ERROR', payload: '' });
    dispatch({
      type: 'SET_MODALS_OPEN',
      payload: { ...SmartFormFieldsState.modalsOpen, importFields: false }
    });
  };

  const { data: sources, isLoading: loadingSources } = useQuery({
    queryKey: ['sources', sourceType],
    queryFn: async () => {
      let res = null;
      switch (sourceType) {
        case 'Smart Form':
          res = await Api.getSmartForms({ limit: 100 });
          return res.res.smart_forms.map(
            (form: { id: number; title: string }) => `${form.id} - ${form.title}`
          );
        case 'Approval Template':
          res = await Api.getRequisitionForms({ limit: 100 });
          return res.res.requisition_forms.map(
            (form: { id: number; name: string }) => `${form.id} - ${form.name}`
          );
        case 'Job':
          res = await Api.getJobs({ limit: 100 });
          return res.res.jobs.map(
            (job: { reference: number; title: string }) => `${job.reference} - ${job.title}`
          );
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Failed to fetch sources, ${error}`,
          state: 'error'
        }
      })
  });

  const handleSubmit = async () => {
    if (!SmartFormFieldsState.selectedSourceId) {
      dispatch({ type: 'SET_SYSTEM_FIELD_ERROR', payload: 'This field is required' });
      return;
    }
    ImportFields.mutate(SmartFormFieldsState.selectedSourceId);
  };

  const ImportFields = useMutation({
    mutationFn: (selectedSourceId: string) =>
      Api.importFields(formId, sourceType, selectedSourceId.split('-')[0].trim()),
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There has been an error importing the fields, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'Fields have been imported',
          state: 'success'
        }
      });
      queryClient.invalidateQueries(['questions'], { exact: true });
      handleClose();
    }
  });

  return (
    <Modal
      open={SmartFormFieldsState.modalsOpen.importFields}
      onClose={handleClose}
      aria-labelledby="add-from-form-field-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
        <Box id="import-fields-title" sx={classes.actionModalHeader}>
          Import Fields
        </Box>
        <Box
          sx={classes.modalContent}
          id="add-from-form-modal-content"
          aria-label="Add Fields From Form Modal Content"
        >
          <Box role="form" id="import-fields-form" aria-label="Import fields from source">
            <Box sx={{ ...classes.actionModalFormLine, marginTop: '20px' }}>
              <SelectField
                id="source-type-select"
                label="Delay"
                options={sourceTypes}
                value={sourceType}
                onChange={setSourceType}
                styles={{ width: '290px' }}
              />
            </Box>
            <Box sx={{ ...classes.actionModalFormLine, marginTop: '20px' }}>
              <Virtualize
                value={SmartFormFieldsState.selectedSourceId}
                passOptions={sources || []}
                setValue={(value: string) => {
                  dispatch({ type: 'SET_SELECTED_SOURCE_ID', payload: value });
                  dispatch({ type: 'SET_SYSTEM_FIELD_ERROR', payload: '' });
                }}
                passedStyles={{
                  ...sharedClasses.formAutocomplete,
                  width: '700px',
                  marginTop: '20px'
                }}
                label={`Select a ${sourceType}`}
                loading={loadingSources}
                loadingText="Loading sources..."
              />

              {SmartFormFieldsState.systemFieldError && (
                <Box sx={{ ...classes.formError, top: '63px' }}>
                  {SmartFormFieldsState.systemFieldError}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={classes.modalFooterSmallerSection}>
          <Button
            id="close-add-from-form-button"
            sx={classes.modalCancelButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            id="submit-add-from-form-button"
            type="submit"
            sx={{ ...classes.modalCreateButton, width: '91px', height: '41px' }}
            onClick={handleSubmit}
          >
            {ImportFields.isLoading ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
