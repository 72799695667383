import React, { Dispatch, useState } from 'react';
import { Box, Button, Stack, Skeleton } from '@mui/material';
import { LiveHelp as LiveHelpIcon, Info as InfoIcon } from '@mui/icons-material';
import NewSystemFieldSVG from '../../EditJob/Questions/NewSystemFieldSVG';
import NoQuestionSVG from '../../EditJob/Questions/NoQuestionSVG';
import NewField from './Modals/NewField';
import AddSystemField from './Modals/AddSystemField';
import ImportFields from './Modals/ImportFields';
import { IApplicantQuestion } from '../../Components/Utilities/QuestionFieldViewer';
import { classes } from './styles';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

import SortableItem from './SortableItem';
import { QuestionCardSensor } from '../../EditJob/Questions/QuestionCardSensor';
import Api from '../API';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SmartFormFieldsState, SmartFormFieldsAction } from '../reducer';
import { IError, IForm, ModalType } from '../types';
import SmartFormModal from '../SmartFormModal';
import { OptionToggleGroup } from '../../Components/OptionToggleGroup';

const toggleButtonOptions = [
  { value: 'enabledQuestions', label: 'Enabled questions' },
  { value: 'disabledQuestions', label: 'Disabled questions' }
];

export default function Questions({
  formData,
  fetchedFormData,
  SmartFormFieldsState,
  dispatch,
  refetchFormData,
  formId,
  userPermissions
}: {
  formData: IForm;
  fetchedFormData: boolean;
  SmartFormFieldsState: SmartFormFieldsState;
  dispatch: Dispatch<SmartFormFieldsAction>;
  refetchFormData: () => void;
  formId: number;
  userPermissions: Record<string, Record<string, boolean>>;
}) {
  const [selected, setSelected] = useState<string>('enabledQuestions');
  const queryClient = useQueryClient();
  const sensors = useSensors(
    useSensor(QuestionCardSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (!questions || !over) return;
    if (active.id !== over.id) {
      const originalQuestions = [...questions];
      const oldIndex = questions.map((question) => question.id).indexOf(Number(active.id));
      const newIndex = questions.map((question) => question.id).indexOf(Number(over.id));
      const newArray = arrayMove(questions, oldIndex, newIndex);
      queryClient.setQueryData(['questions'], newArray);
      updateQuestions.mutate(newArray, {
        onError: () => queryClient.setQueryData(['questions'], originalQuestions)
      });
    }
  }

  const updateQuestions = useMutation({
    mutationFn: (questions: IApplicantQuestion[]) => {
      const fieldObject: Record<string, { position: number }> = {};
      questions.map(
        (question, index) =>
          (fieldObject[question.id] = {
            position: index
          })
      );
      return Api.updateQuestions(formId, {
        fields: fieldObject
      });
    },
    onError: (error: IError) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error saving your changes, ${error.errors.join('. ')}`,
          state: 'error'
        }
      });
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'Questions have been updated',
          state: 'success'
        }
      });
    }
  });

  const { data: questions, isLoading: loadingQuestions } = useQuery({
    queryKey: ['questions'],
    queryFn: async () => {
      const { res } = await Api.getQuestions({
        entity_id: formData?.entity_id,
        form_id: formData?.id
      });

      return res.fields;
    },
    onError: (error: IError) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Failed to fetch questions, ${error.errors.join('. ')}`,
          state: 'error'
        }
      });
    },
    onSuccess: (questions: IApplicantQuestion[]) => {
      const questionsActionStates: {
        [key: number]: {
          enabled: boolean;
          required: boolean;
          rateable: boolean;
          confidential: boolean;
        };
      } = {};
      questions.map((question) => {
        questionsActionStates[question.id] = {
          enabled: question.enabled,
          required: question.required,
          rateable: question.rateable,
          confidential: question.confidential
        };
      });
      dispatch({ type: 'SET_ACTION_STATES', payload: questionsActionStates });
    },
    enabled: fetchedFormData
  });

  return (
    <Box sx={classes.questionsPageContainer}>
      <Box sx={classes.noticeContainer}>
        <InfoIcon sx={classes.infoIcon} />
        <Box sx={{ marginLeft: 1 }}>
          <b>All changes will be automatically saved and applied immediately. </b>
          All fields that have been answered by a user are not able to be deleted. To remove a
          field, you will need to disable it and create a new field.
        </Box>
      </Box>

      <OptionToggleGroup
        options={toggleButtonOptions}
        selected={selected}
        setSelected={setSelected}
      />

      <Box sx={classes.actionsContianer}>
        {loadingQuestions ? (
          <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
        ) : (
          <Stack sx={{ flexDirection: 'row', gap: '22px' }}>
            {selected === 'enabledQuestions' && (
              <>
                <Button
                  id="smart-form-new-field-button"
                  variant="outlined"
                  startIcon={<LiveHelpIcon />}
                  sx={classes.actionButton}
                  onClick={() => {
                    dispatch({
                      type: 'SET_MODALS_OPEN',
                      payload: { ...SmartFormFieldsState.modalsOpen, newField: true }
                    });
                  }}
                >
                  New field
                </Button>
                <Button
                  id="smart-form-add-system-field-button"
                  variant="outlined"
                  startIcon={<NewSystemFieldSVG />}
                  sx={classes.actionButton}
                  onClick={() => {
                    dispatch({
                      type: 'SET_MODALS_OPEN',
                      payload: { ...SmartFormFieldsState.modalsOpen, addSystemField: true }
                    });
                  }}
                >
                  Add system field
                </Button>
                <Button
                  id="smart-form-add-fields-from-form-button"
                  variant="outlined"
                  startIcon={<NewSystemFieldSVG />}
                  sx={classes.actionButton}
                  onClick={() => {
                    dispatch({
                      type: 'SET_MODALS_OPEN',
                      payload: { ...SmartFormFieldsState.modalsOpen, importFields: true }
                    });
                  }}
                >
                  Import fields
                </Button>
              </>
            )}
          </Stack>
        )}
        <>
          {loadingQuestions ? (
            <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
          ) : (
            <Stack>
              <Button
                id="smart-form-settings-button"
                sx={classes.settingButton}
                onClick={() => {
                  dispatch({
                    type: 'SET_MODALS_OPEN',
                    payload: { ...SmartFormFieldsState.modalsOpen, smartFormSettings: true }
                  });
                }}
              >
                Settings
              </Button>
            </Stack>
          )}
        </>
      </Box>
      {questions && questions.length > 0 && (
        <Box sx={{ overflow: 'auto' }}>
          <Box sx={classes.questionsContainer}>
            <Box sx={classes.questionsHeader}>Questions</Box>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={questions} strategy={verticalListSortingStrategy}>
                {questions
                  .filter((el) => (selected === 'enabledQuestions') === el.enabled)
                  .map(
                    (question) =>
                      Object.keys(SmartFormFieldsState.actionStates).length > 0 && (
                        <SortableItem
                          question={question}
                          key={question.id}
                          formId={formData?.id}
                          SmartFormFieldsState={SmartFormFieldsState}
                          dispatch={dispatch}
                          userPermissions={userPermissions}
                        />
                      )
                  )}
              </SortableContext>
            </DndContext>
          </Box>
        </Box>
      )}
      {!loadingQuestions && questions?.length === 0 && (
        <Box sx={{ ...classes.noQuestionsContainer, margin: '150px 0px' }}>
          <NoQuestionSVG />
          <Box sx={classes.noQuestionsText}>
            Your smart form is empty. Please add a
            <br />
            question from the options above.
          </Box>
        </Box>
      )}
      {fetchedFormData && (
        <>
          <NewField
            formId={formData?.id}
            SmartFormFieldsState={SmartFormFieldsState}
            dispatch={dispatch}
            userPermissions={userPermissions}
          />
          <AddSystemField
            formId={formData?.id}
            SmartFormFieldsState={SmartFormFieldsState}
            dispatch={dispatch}
          />
          <ImportFields
            formId={formData?.id}
            SmartFormFieldsState={SmartFormFieldsState}
            dispatch={dispatch}
          />
          <SmartFormModal
            dispatch={dispatch}
            form={formData}
            entityId={formData.entity_id}
            smartFormState={SmartFormFieldsState}
            refetchForms={refetchFormData}
            modalType={ModalType.UPDATE}
          />
        </>
      )}
    </Box>
  );
}
