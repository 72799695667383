import React, { Dispatch, useMemo } from 'react';
import { Box, Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import { classes } from './styles';
import { handleSelectOne } from './tableHelper';
import { GenericTableAction } from './genericTableReducer';
import {
  GenericTableData,
  TSortableColumn,
  TTableState,
  TableActionItemAction,
  TableSelectionAction
} from './types';
import GenericTableActions, { ActionListItem } from './GenericTableActions';

interface IGenericTableBodyProps<T> {
  tableState: TTableState<T, TSortableColumn[]>;
  dispatch: Dispatch<TableActionItemAction<T> | TableSelectionAction<T>>;
  items: T[];
  TableRows: React.FC<{
    column: TSortableColumn;
    item: T;
    tableHovered: boolean;
    dispatch?: Dispatch<
      TableActionItemAction<GenericTableData> | TableSelectionAction<GenericTableData>
    >;
  }>;
  TableActionList?: ActionListItem[];
  allowMultipleSelection?: boolean;
}

const GenericTableBody = <GenericTableBodyProps extends GenericTableData>({
  tableState,
  dispatch,
  items,
  TableRows,
  TableActionList,
  allowMultipleSelection = true
}: IGenericTableBodyProps<GenericTableBodyProps>) => {
  const allItemIds = useMemo(() => items.map((item) => item.id), [items]);

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id}>
          {allowMultipleSelection && (
            <TableCell sx={{ ...classes.sticky, width: '58px' }}>
              <Checkbox
                id={`select-checkbox-${item.id}`}
                sx={classes.checkbox}
                checked={tableState.selectedItemIds.includes(item.id)}
                onChange={(event) => handleSelectOne(event, item, allItemIds, dispatch)}
                inputProps={{
                  'aria-label': `select ${item.name}`
                }}
              />
            </TableCell>
          )}
          {tableState.columns.map(
            (column) =>
              column.enabled && (
                <TableRows
                  key={column.id}
                  item={item}
                  column={column}
                  tableHovered={tableState.tableHovered}
                  dispatch={dispatch}
                />
              )
          )}
          <TableCell
            sx={{
              zIndex: '4',
              ...classes.actionsTableCell,
              ...classes.stickyRight,
              ...(tableState.tableHovered && classes.scrollShadowVisible),
              ...(!TableActionList && { minWidth: '24px', width: '24px' })
            }}
          >
            <Box sx={classes.actionItems}>
              {TableActionList && (
                <GenericTableActions item={item} dispatch={dispatch} actions={TableActionList} />
              )}
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default GenericTableBody;
