import React, { Dispatch, useRef } from 'react';
import queryString from 'query-string';
import {
  GenericTableAction,
  GenericTableState
} from '../../NewUI/Components/GenericTable/genericTableReducer';
import { classes } from '../styles';
import { CandidatePoolAPIReturn } from '../types';
import { candidatePoolActionsList, headerStyles, poolActionDrawerModals } from './config';
import { Box } from '@mui/material';
import GenericTable from '../../NewUI/Components/GenericTable/GenericTable';
import { IStyle } from '../../ThemeContext/ThemeObject';
import CandidatePoolsTableRows from './CandidatePoolsTableRows';
import SearchInput from '../../NewUI/Components/GenericTable/SearchInput';
import { getPreferences } from '../../NewUI/Components/GenericTable/utilities';
import { useQuery } from '@tanstack/react-query';
import { ISnackbarInput } from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { callPaginatedAPI } from '../../NewUI/Components/Utilities/callAPI';
import ColumnSettings from '../../NewUI/Components/GenericTable/Dropdowns/ColumnSettings';
import TotalSelectedIndicatorWithActions from '../../NewUI/Components/GenericTable/TotalSelectedIndicatorWithActions';
import CreateNewPoolButton from './Modals/CreateNewPoolButton';
import ActionDrawerModals from './Modals/ActionDrawerModals';
import EmailIcon from '@mui/icons-material/Email';
import CandidatePoolsActionComponents from './CandidatePoolsActionComponents';
import ItemActionsDrawer from '../../NewUI/Components/GenericTable/ItemActionsDrawer';
import IndividualCandidatePoolModal from './Modals/CandidatePool/IndividualCandidatePoolModal';

const USER_PREFERENCE_SOURCE = 'candidate-pools';

const CandidatePools = ({
  individualCandidatePoolsState,
  individualCandidatePoolsDispatch,
  emailsState,
  emailsDispatch,
  candidatePoolsState,
  dispatch,
  setSnackbarState,
  modalTabValue,
  setModalTabValue
}: {
  individualCandidatePoolsState: GenericTableState;
  individualCandidatePoolsDispatch: Dispatch<GenericTableAction>;
  emailsState: GenericTableState;
  emailsDispatch: Dispatch<GenericTableAction>;
  candidatePoolsState: GenericTableState;
  dispatch: Dispatch<GenericTableAction>;
  setSnackbarState: (snackbarState: ISnackbarInput) => void;
  modalTabValue: number;
  setModalTabValue: (i: number) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isFetched: fetchedPreferences } = useQuery({
    queryKey: ['candidatePoolPreferences'],
    queryFn: () => getPreferences(USER_PREFERENCE_SOURCE, dispatch),
    onError: () =>
      setSnackbarState({
        message: `There was an error getting user preference data`,
        state: 'error'
      }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 3.6e6 // 1 hour
  });

  const {
    data: candidate_pools,
    isLoading: isLoadingPools,
    refetch
  } = useQuery({
    queryKey: [
      'candidatePools',
      candidatePoolsState.searchQuery,
      candidatePoolsState.searchSettings
    ],
    queryFn: () =>
      callPaginatedAPI(
        `/api/v4/candidate_pools?${queryString.stringify(
          { ...candidatePoolsState.searchSettings, 'q[search]': candidatePoolsState.searchQuery },
          {
            skipEmptyString: true,
            skipNull: true,
            arrayFormat: 'bracket',
            encode: false
          }
        )}`,
        null,
        'GET'
      ) as Promise<CandidatePoolAPIReturn>,
    onError() {
      setSnackbarState({
        message: `There was an error retrieving candidate pools, please refresh the page and try again.`,
        state: 'error'
      });
    },
    enabled: fetchedPreferences
  });

  return (
    <>
      <Box sx={classes.searchAndFiltersRow} ref={containerRef}>
        <SearchInput dispatch={dispatch} />
        <ColumnSettings
          tableState={candidatePoolsState.tableState}
          dispatch={dispatch}
          label="candidate-pools"
          userPreferenceSource={USER_PREFERENCE_SOURCE}
        />
        <CreateNewPoolButton
          isOpen={candidatePoolsState.tableState.modalsOpen.newPool}
          onClick={() =>
            dispatch({
              type: 'SET_ACTION_MODAL_OPEN',
              payload: { name: 'newPool', isOpen: true }
            })
          }
        />
      </Box>
      <ItemActionsDrawer
        drawerOpen={candidatePoolsState.tableState.selectedItemIds.length > 0}
        dispatch={dispatch}
        actions={poolActionDrawerModals}
        label="candidate-pools"
      />

      {(candidatePoolsState.tableState.actions.isOpen.candidatePool ||
        candidatePoolsState.tableState.actions.isOpen.emailAnalytics) && (
        <IndividualCandidatePoolModal
          isOpen={
            candidatePoolsState.tableState.actions.isOpen.candidatePool ||
            candidatePoolsState.tableState.actions.isOpen.emailAnalytics
          }
          selectedPool={candidatePoolsState.tableState.actions.selectedItem}
          handleClose={() => {
            dispatch({
              type: 'CLOSE_ACTION_ITEM',
              payload: 'emailAnalytics'
            });
            dispatch({
              type: 'CLOSE_ACTION_ITEM',
              payload: 'candidatePool'
            });
            setModalTabValue(0);
          }}
          setSnackbarState={setSnackbarState}
          refetch={refetch}
          individualCandidatePoolsState={individualCandidatePoolsState}
          individualCandidatePoolsDispatch={individualCandidatePoolsDispatch}
          emailsState={emailsState}
          emailsDispatch={emailsDispatch}
          modalTabValue={modalTabValue}
          setModalTabValue={setModalTabValue}
        />
      )}
      <GenericTable
        items={candidate_pools?.data.candidate_pools || []}
        isLoadingItems={isLoadingPools}
        dispatch={dispatch}
        tableState={candidatePoolsState.tableState}
        paginationState={candidatePoolsState.searchSettings}
        pagination={{
          totalItems: candidate_pools?.totalItems || 0,
          totalPages: candidate_pools?.totalPages || 0
        }}
        headerStyle={headerStyles as (id: string, tableHovered: boolean) => IStyle}
        TableRows={CandidatePoolsTableRows}
        TableActionList={candidatePoolActionsList}
        label="candidate pools"
        titleCellId="title"
        userPreferenceSource={USER_PREFERENCE_SOURCE}
      />
      <TotalSelectedIndicatorWithActions
        totalSelected={candidatePoolsState.tableState.selectedItemIds.length}
        parentDiv={containerRef.current}
        dispatch={dispatch}
        actionButtonsList={[
          {
            id: 'email',
            label: 'Email',
            icon: <EmailIcon />
          }
        ]}
        actionButtonId="email-pool-button"
      />
      <CandidatePoolsActionComponents
        actionsState={candidatePoolsState.tableState.actions}
        dispatch={dispatch}
        setSnackbarState={setSnackbarState}
      />
      <ActionDrawerModals
        tableState={candidatePoolsState.tableState}
        dispatch={dispatch}
        setSnackbarState={setSnackbarState}
      />
    </>
  );
};

export default CandidatePools;
