import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

export default class SearchResultsTableWithSpinner extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    color: PropTypes.string
  }

  render() {
    return (
      <div className='body'>
        { this.props.items.map((item, key) => {
          return(
            <div className='row' key={ key }>
              <div className='body-cell'>
              </div>
              <div className='body-cell'>
              </div>
              <div className='body-cell'>
              </div>
              <div className='body-cell'>
                <div className="loading">
                  <ReactLoading type='cylon' color={ this.props.color } height='25px' width='25px'/>
                </div>
              </div>
              <div className='body-cell'>
              </div>
              <div className='body-cell'>
              </div>
              <div className='body-cell'>

              </div>
              <div className='body-cell'>

              </div>
              <div className='body-cell'>

              </div>
            </div>
          );
        })
        }
      </div>);
  }
}
