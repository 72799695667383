import React from 'react';
import { Box, Breadcrumbs, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { classes } from '../styles';

const BreadcrumbsContainer = ({ breadcrumbsData }: { breadcrumbsData: string }) => {
  const convertBreadcrumbsData = (breadcrumbsData: string) => {
    if (breadcrumbsData?.length < 1) return [];
    const nameRegex = /<a[^>]*>(.*?)<\/a>/;
    const hrefRegex = /<a[^>]*href="([^"]*)"[^>]*>/;
    // if path is /admin then return only dashboard
    if (window.location.pathname !== '/admin') {
      const breadcrumbsArray = breadcrumbsData.split('</li>');
      return breadcrumbsArray
        .map((breadcrumb) => ({
          name: breadcrumb.match(nameRegex)?.[1],
          url: breadcrumb.match(hrefRegex)?.[1]
        }))
        .slice(0, -1);
    } else {
      return [
        {
          name: 'Dashboard',
          url: '/admin'
        }
      ];
    }
  };

  return (
    <Box sx={classes.breadcrumbContainer}>
      <HomeRoundedIcon onClick={() => window.location.replace('/admin')} sx={classes.homeIcon} />
      <Breadcrumbs
        separator={<NavigateNextIcon sx={classes.breadcrumbSeparator} />}
        aria-label="breadcrumb"
        sx={classes.breadcrumbs}
      >
        <Link underline="hover" color="inherit" href=""></Link>
        {convertBreadcrumbsData(breadcrumbsData).map(
          (breadcrumb) =>
            breadcrumb.name !== 'Home' && (
              <Link underline="hover" key={breadcrumb.name} color="inherit" href={breadcrumb.url}>
                {breadcrumb.name}
              </Link>
            )
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbsContainer;
