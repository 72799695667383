import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    flexBasis: "75%",
    padding: "0 40px",
    overflowY: "scroll"
  },
  chatContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    "& > div:first-child": {
      marginBottom: 20
    }
  },
  chatItemContainer: {
    marginLeft: "auto",
    marginRight: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse"
  },
  alignRight: {
    marginLeft: 0,
    marginRight: "auto",
    flexDirection: "row"
  },
  chatItem: {
    borderRadius: 6,
    maxWidth: 460,
    color: "#FFF",
    fontSize: 14,
    background: theme.palette.primary.main,
    padding: "15px 20px",
    lineHeight: "18px",
    margin: "3px 0",
    display: "table"
  },
  fromCandidate: {
    background: "#F3F6F7",
    color: theme.palette.primary.main
  },
  timestamp: {
    fontSize: 14,
    color: "#939393",
    marginTop: 20,
    marginBottom: 10,
    textAlign: "center"
  },
  status: {
    padding: "0 15px"
  },
  popper: {
    boxShadow: "0px 2px 14px rgba(48, 90, 111, 0.16)",
    padding: "10px 20px",
    background: "#FFF",
    borderRadius: 6,
    zIndex: 1e5,
    fontSize: 12
  },
  noMessages: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "column",
    userSelect: "none",
    "& svg": {
      height: 160,
      width: 160,
      fill: "#F7F7F7"
    },
    "& > span": {
      color: "#E8E8E8",
      fontSize: 24,
      fontWeight: "600"
    }
  }
}));
