import React, { useState, Dispatch } from 'react';
import { Box, Stack } from '@mui/material';
import GenericDialog from '../Components/Modals/GenericDialog';
import { FormTextField } from '../Components/CustomUIElements/FormTextField';
import { CandidatesAction } from './types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from './API';
import { CandidateAction } from '../Candidate/types';

export default function DeleteCandidate({
  isDialogOpen,
  candidateId,
  CandidatesDispatch,
  CandidateDispatch
}: {
  isDialogOpen: boolean;
  candidateId: number;
  CandidatesDispatch?: Dispatch<CandidatesAction>;
  CandidateDispatch?: Dispatch<CandidateAction>;
}) {
  const [confirmDelete, setConfirmDelete] = useState<string>('');
  const queryClient = useQueryClient();

  const deleteCandidate = useMutation({
    mutationFn: () => Api.deleteCandidate(candidateId),
    onSuccess: () => {
      const successSnackbarPayload: {
        type: 'SET_SNACKBAR';
        payload: { message: string; state: 'success' };
      } = {
        type: 'SET_SNACKBAR',
        payload: {
          message: `Candidate has been deleted`,
          state: 'success'
        }
      };
      CandidatesDispatch && CandidatesDispatch(successSnackbarPayload);
      CandidateDispatch && CandidateDispatch(successSnackbarPayload);
      if (CandidatesDispatch) {
        queryClient.removeQueries(['candidates']);
        CandidatesDispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'delete' });
      } else {
        CandidateDispatch && CandidateDispatch({ type: 'OPEN_DELETE_MODAL', payload: false });
        window.location.href = `${window.location.origin}/admin/candidates`;
      }
    },
    onError: (error) => {
      const errorSnackbarPayload: {
        type: 'SET_SNACKBAR';
        payload: { message: string; state: 'error' };
      } = {
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error deleting candidate, ${error}`,
          state: 'error'
        }
      };
      CandidatesDispatch && CandidatesDispatch(errorSnackbarPayload);
      CandidateDispatch && CandidateDispatch(errorSnackbarPayload);
    }
  });

  const dialogDescription = (
    <Stack sx={{ color: '#000000', rowGap: 2 }}>
      <Box>
        This will <b>permanently</b> delete the candidate and all associated applications
      </Box>
      <Stack sx={{ rowGap: 1, alignItems: 'center' }}>
        <Box sx={{ color: '#939393', fontSize: '14px', '& b': { color: '#000000' } }}>
          Type the word <b>DELETE</b> in the text field below to
          <Box>confirm deletion</Box>
        </Box>
        <FormTextField value={confirmDelete} onChange={(e) => setConfirmDelete(e.target.value)} />
      </Stack>
    </Stack>
  );
  return (
    <GenericDialog
      isDialogOpen={isDialogOpen}
      setDialogOpen={() => {
        CandidatesDispatch &&
          CandidatesDispatch({
            type: 'CLOSE_ACTION_ITEM',
            payload: 'delete'
          });
        CandidateDispatch && CandidateDispatch({ type: 'OPEN_DELETE_MODAL', payload: false });
      }}
      title="Are you sure you want to delete this candidate?"
      description={dialogDescription}
      buttonCallback={() => deleteCandidate.mutate()}
      callbackLoading={deleteCandidate.isLoading}
      buttonText="Delete"
      url=""
      disablePrimaryButton={confirmDelete !== 'DELETE'}
      primaryButtonId="delete-candidate-button"
      secondaryButtonId="cancel-delete-candidate-button"
    />
  );
}
