import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import { styles } from './styles';
import Search from './Search';
import FilterDropdown from './Dropdowns/FilterDropdown';
import DndFieldDropdown from './Dropdowns/DndFieldDropdown';
import { defaultFilters, sortableColumnsDefaults } from './config';
import Api from './API';
import ApprovalFormsTable from './ApprovalFormsTable';
import {
  IFilterDropdownProps,
  IGetApprovalForms,
  TSetUserPreferences,
  IGetAllApprovalForms
} from './types';
import StyledSnackbar from '../Components/CustomUIElements/StyledSnackbar';
import { IUserPermissions } from '../Components/sharedTypes';

export default function ApprovalFormsTab({
  apiKey,
  userPermissions,
  archived
}: {
  apiKey: string;
  userPermissions: IUserPermissions | undefined;
  archived: boolean;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [selected, setSelected] = useState<number[]>([]);
  const [approvalForms, setApprovalForms] = useState([]);
  const [globalSelected, setGlobalSelected] = useState([]);
  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
  const [selectAllIsIndeterminate, setSelectAllIsIndeterminate] = useState(false);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<IFilterDropdownProps['filters']>(defaultFilters);
  const [sortableColumns, setSortableColumns] = useState(sortableColumnsDefaults);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalApprovalForms, setTotalApprovalForms] = useState(0);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [density, setDensity] = useState<string>('Default');
  const [filterableEntities, setFilterableEntities] = useState([]);
  const [filterableAssignedUsers, setFilterableAssignedUsers] = useState([]);
  const [filterableRequesters, setFilterableRequesters] = useState([]);

  const getFirstApprovalFormsAndPreferences = useCallback(async () => {
    setIsLoading(true);
    try {
      const preferenceData = await fetch(
        `/api/v4/ui_preferences?source=approval_forms${archived ? '_archived' : ''}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-authenticate': apiKey
          },
          cache: 'no-store'
        }
      ).then((res) => res.json());
      const densityData = await fetch(`/api/v4/ui_preferences?source=universal`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        cache: 'no-store'
      }).then((res) => res.json());
      const preference = preferenceData?.ui_preferences[0];
      const densityPreference = densityData?.ui_preferences[0];
      preference?.sorting?.sortBy && setSortBy(preference.sorting.sortBy);
      preference?.sorting?.sortOrder && setSortOrder(preference.sorting.sortOrder.toLowerCase());
      preference?.filters && setFilters(preference.filters);
      const selectedStates = preference?.filters?.state?.map((state) => state.value);
      const selectedEntityIds = preference?.filters?.entity_ids?.map((entity) => entity.id);
      const selectedAssignedUserIds = preference?.filters?.assigned_user_ids?.map(
        (user) => user.id
      );
      const selectedRequesters = preference?.filters?.requested_by_users?.map(
        (requester) => requester.name
      );
      const sessionPage = sessionStorage.getItem(`approvalFormsTablePage`);
      !archived && sessionPage && setCurrentPage(Number(sessionPage));
      preference?.row_count && setRowsPerPage(preference.row_count);
      preference?.sortable_columns && setSortableColumns(preference.sortable_columns);
      densityPreference?.columns?.density && setDensity(densityPreference?.columns?.density);

      const response = await Api.getApprovalForms(
        { 'X-api-authenticate': apiKey },
        {
          archived: archived || false,
          page: !archived && sessionPage ? Number(sessionPage) : 1,
          limit: preference?.row_count || rowsPerPage,
          sort: preference?.sorting?.sortBy || 'created_at',
          sort_order: (preference?.sorting?.sortOrder?.toLowerCase() || 'desc').toUpperCase(),
          'q[search]': search,
          'filter[state]': selectedStates?.filters?.length === 0 ? [] : selectedStates,
          'filter[entity_ids]': selectedEntityIds?.filters?.length === 0 ? [] : selectedEntityIds,
          'filter[assigned_user_ids]':
            selectedAssignedUserIds?.filters?.length === 0 ? [] : selectedAssignedUserIds,
          'filter[requested_by_users]':
            selectedRequesters?.filters?.length === 0 ? [] : selectedRequesters
        }
      );
      setApprovalForms(response.res.requisitions);
      setTotalPages(parseInt(response.resHead['x-total-pages']));
      setTotalApprovalForms(parseInt(response.resHead['x-total-count']));
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting all approval forms',
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, search]);

  const setUserPreferences: TSetUserPreferences = useCallback(
    async (limit, sort, columns, newFilters, density) => {
      if (density) {
        try {
          await fetch(`/api/v4/ui_preferences`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-authenticate': apiKey
            },
            body: JSON.stringify({
              ui_preference: {
                columns: { density: density },
                source: `universal`
              }
            })
          });
        } catch (error) {
          setSnackbar({
            message: `There was an error saving your preferences, ${error}`,
            state: 'error'
          });
        }
      } else {
        try {
          await fetch(`/api/v4/ui_preferences`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-authenticate': apiKey
            },
            body: JSON.stringify({
              ui_preference: {
                sortable_columns: columns || sortableColumns,
                row_count: limit || rowsPerPage,
                filters: newFilters || filters,
                sorting: sort,
                source: `approval_forms${archived ? '_archived' : ''}`
              }
            })
          });
        } catch (error) {
          setSnackbar({
            message: 'There was an error saving your preferences',
            state: 'error'
          });
        }
      }
    },
    [apiKey, archived, filters, rowsPerPage, sortBy, sortOrder, sortableColumns]
  );

  const getAllApprovalForms: IGetAllApprovalForms = async (
    limit,
    searchQuery,
    sort,
    updatedFilters,
    archived
  ) => {
    setIsLoading(true);
    try {
      const selectedStates = (updatedFilters?.state || filters.state)?.map((state) => state.value);
      const selectedEntityIds = (updatedFilters?.entity_ids || filters.entity_ids)?.map(
        (entity) => entity.id
      );
      const selectedAssignedUserIds = (
        updatedFilters?.assigned_user_ids || filters.assigned_user_ids
      )?.map((user) => user.id);
      const selectedRequesters = (
        updatedFilters?.requested_by_users || filters.requested_by_users
      )?.map((requester) => requester.name);
      const response = await Api.getApprovalForms(
        { 'X-api-authenticate': apiKey },
        {
          archived: archived || false,
          override_limit: true,
          limit: totalApprovalForms,
          sort: sort?.sortBy || sortBy,
          sort_order: (sort?.sortOrder || sortOrder).toUpperCase(),
          'filter[position_title]': searchQuery !== null ? searchQuery : search,
          'filter[state]': selectedStates?.length === 0 ? [] : selectedStates,
          'filter[entity_ids]': selectedEntityIds?.length === 0 ? [] : selectedEntityIds,
          'filter[assigned_user_ids]':
            selectedAssignedUserIds?.length === 0 ? [] : selectedAssignedUserIds,
          'filter[requested_by_users]': selectedRequesters?.length === 0 ? [] : selectedRequesters
        }
      );
      return response.res.requisitions;
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting all approval forms',
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getApprovalForms: IGetApprovalForms = async (
    page,
    limit,
    searchQuery,
    sort,
    updatedFilters,
    archived
  ) => {
    setIsLoading(true);
    try {
      const selectedStates = (updatedFilters?.state || filters.state)?.map((state) => state.value);
      const selectedEntityIds = (updatedFilters?.entity_ids || filters.entity_ids)?.map(
        (entity) => entity.id
      );
      const selectedAssignedUserIds = (
        updatedFilters?.assigned_user_ids || filters.assigned_user_ids
      )?.map((user) => user.id);
      const selectedRequesters = (
        updatedFilters?.requested_by_users || filters.requested_by_users
      )?.map((requester) => requester.name);
      const response = await Api.getApprovalForms(
        { 'X-api-authenticate': apiKey },
        {
          archived: archived || false,
          page: page || currentPage,
          limit: limit || rowsPerPage,
          sort: sort?.sortBy || sortBy,
          sort_order: (sort?.sortOrder || sortOrder).toUpperCase(),
          'q[search]': searchQuery !== null ? searchQuery : search,
          'filter[state]': selectedStates?.length === 0 ? [] : selectedStates,
          'filter[entity_ids]': selectedEntityIds?.length === 0 ? [] : selectedEntityIds,
          'filter[assigned_user_ids]':
            selectedAssignedUserIds?.length === 0 ? [] : selectedAssignedUserIds,
          'filter[requested_by_users]': selectedRequesters?.length === 0 ? [] : selectedRequesters
        }
      );
      setApprovalForms(response.res.requisitions);
      setTotalPages(parseInt(response.resHead['x-total-pages']));
      setTotalApprovalForms(parseInt(response.resHead['x-total-count']));
      setSelectAllIsChecked(
        response.res.requisitions
          .map((requisition) => requisition.id)
          .every((id: number) => globalSelected.map((requisition) => requisition.id).includes(id))
      );
      setSelectAllIsIndeterminate(
        response.res.requisitions
          .map((requisition) => requisition.id)
          .some((id: number) => globalSelected.map((requisition) => requisition.id).includes(id))
      );
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting approval forms',
        state: 'error'
      });
    } finally {
      if (sort || updatedFilters) {
        setUserPreferences(null, sort, null, updatedFilters, null);
      } else if (limit) {
        setUserPreferences(limit, null, null, null, null);
      }
      setIsLoading(false);
    }
  };

  const getFilterableEntities = useCallback(async () => {
    try {
      const response = await Api.getFilters({ 'X-api-authenticate': apiKey }, 'entities', {});
      setFilterableEntities(response.res.entities);
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting entities',
        state: 'error'
      });
    }
  }, []);

  const getFilterableAssignedUsers = useCallback(async () => {
    try {
      const response = await Api.getFilters({ 'X-api-authenticate': apiKey }, 'assigned_users', {});
      setFilterableAssignedUsers(response.res.assigned_users);
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting assigned users',
        state: 'error'
      });
    }
  }, []);

  const getFilterableRequesters = useCallback(async () => {
    try {
      const response = await Api.getFilters(
        { 'X-api-authenticate': apiKey },
        'requested_by_users',
        {}
      );
      setFilterableRequesters(response.res.requested_by_users);
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting requesters',
        state: 'error'
      });
    }
  }, []);

  useEffect(() => {
    getFirstApprovalFormsAndPreferences();
    getFilterableEntities();
    getFilterableAssignedUsers();
    getFilterableRequesters();
  }, [
    apiKey,
    getFirstApprovalFormsAndPreferences,
    getFilterableEntities,
    getFilterableAssignedUsers,
    getFilterableRequesters
  ]);

  return (
    <Box>
      <Box sx={styles.filtersRow}>
        <Search setSearch={setSearch} getApprovalForms={getApprovalForms} archived={archived} />
        <FilterDropdown
          filters={filters}
          setFilters={setFilters}
          getApprovalForms={getApprovalForms}
          entityList={filterableEntities}
          assignedUserList={filterableAssignedUsers}
          requesterList={filterableRequesters}
          setUserPreferences={setUserPreferences}
          setCurrentPage={setCurrentPage}
          archived={archived}
        />
        <DndFieldDropdown
          sortableColumns={sortableColumns}
          setSortableColumns={setSortableColumns}
          density={density}
          setDensity={setDensity}
          setUserPreferences={setUserPreferences}
          label="approval-forms"
          userPermissions={userPermissions}
        />
      </Box>
      <ApprovalFormsTable
        approvalForms={approvalForms}
        setApprovalForms={setApprovalForms}
        getApprovalForms={getApprovalForms}
        getAllApprovalForms={getAllApprovalForms}
        globalSelected={globalSelected}
        setGlobalSelected={setGlobalSelected}
        selectAllIsChecked={selectAllIsChecked}
        setSelectAllIsChecked={setSelectAllIsChecked}
        selectAllIsIndeterminate={selectAllIsIndeterminate}
        setSelectAllIsIndeterminate={setSelectAllIsIndeterminate}
        setSnackbarState={setSnackbar}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        selected={selected}
        setSelected={setSelected}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalPages={totalPages}
        totalApprovalForms={totalApprovalForms}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortableColumns={sortableColumns}
        apiKey={apiKey}
        userPermissions={userPermissions}
        density={density}
        archived={archived}
      />
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
