import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Candidate from './Candidate';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function CandidatePage({
  candidateId,
  isAdminUser
}: {
  candidateId: number;
  isAdminUser: boolean;
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <Candidate candidateId={candidateId} isAdminUser={isAdminUser} />
    </QueryClientProvider>
  );
}
