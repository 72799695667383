import React from 'react';
import Popover from '@mui/material/Popover';
import { IExpiryPopoverProps } from './types';
import { classes } from './styles';

export default function ExpiryPopover({
  immediateExpiryOnClick,
  dateExpiryOnClick,
  expiryActionsAnchorEl,
  setExpiryActionsAnchorEl,
  ad
}: IExpiryPopoverProps) {
  return (
    <Popover
      id="actions-menu-popover"
      sx={classes.actionsMenu}
      open={Boolean(expiryActionsAnchorEl)}
      anchorEl={expiryActionsAnchorEl}
      onClose={() => {
        setExpiryActionsAnchorEl(null);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <span onClick={() => immediateExpiryOnClick(ad)} className="expire-now-seek-button">Expire now</span>
      <span onClick={dateExpiryOnClick} className="choose-expiry-seek-button">Choose expiry date</span>
    </Popover>
  );
}
