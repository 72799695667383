import React, { useReducer } from 'react';
import { Box } from '@mui/material';
import StyledSnackbar from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { SystemReportsReducer, InitialSystemReportsState } from './reducer';
import SystemReportsTable from './SystemReportsTable';
import SystemReportPreview from './SystemReportPreview';
import { useQuery } from '@tanstack/react-query';
import { getPermissions } from '../../shared/permissionHelpers';
import { IPermissions } from '../../NewUI/Job/types';
import { IUserPermissions } from '../../NewUI/Components/sharedTypes';

const SystemReportsSection = () => {
  const [SystemReportsState, dispatch] = useReducer(
    SystemReportsReducer,
    InitialSystemReportsState
  );
  const { snackbar, openPreview } = SystemReportsState;
  const { data: permissions } = useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const res = await getPermissions();
      const allRoles: Record<string, Record<string, boolean>> = {};
      res.roles.forEach((role: IPermissions) =>
        role.feature_groups.forEach((group) => {
          const groupName = group.name as keyof IUserPermissions;
          !(groupName in allRoles) ? (allRoles[groupName] = {}) : null;
          group.permissions.forEach((permission) => (allRoles[groupName][permission] = true));
        })
      );
      return allRoles;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting user permissions, ${error}`,
          state: 'error'
        }
      })
  });

  return (
    <Box sx={{ overflow: 'auto' }}>
      {openPreview ? (
        <SystemReportPreview state={SystemReportsState} dispatch={dispatch} />
      ) : (
        <SystemReportsTable
          state={SystemReportsState}
          dispatch={dispatch}
          permissions={permissions}
        />
      )}
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={() => {
          dispatch({ type: 'SET_SNACKBAR', payload: { message: '', state: 'success' } });
        }}
      />
    </Box>
  );
};

export default SystemReportsSection;
