import React, { useState } from 'react';
import {
  Box,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  Tooltip,
  Popper
} from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { IDropdownProps } from '../types';
import { styles } from '../styles';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import SortableField from './SortableField';
import { isPermittedOption } from '../helper';

export default function DndFieldDropdown({
  sortableColumns,
  setSortableColumns,
  density,
  setDensity,
  setUserPreferences,
  label,
  userPermissions
}: IDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleFieldSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSortableColumns = sortableColumns.map((field) => {
      if (field.id === event.target.name) {
        return { ...field, enabled: event.target.checked };
      } else {
        return field;
      }
    });
    setSortableColumns(newSortableColumns);
    setUserPreferences(null, null, newSortableColumns, null, null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen((prev) => !prev);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = sortableColumns.map((c) => c.id).indexOf(active.id);
      const newIndex = sortableColumns.map((c) => c.id).indexOf(over.id);
      const newArray = arrayMove(sortableColumns, oldIndex, newIndex);

      setSortableColumns(newArray);
      setUserPreferences(null, null, newArray, null, null);
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
      <Box>
        <Tooltip title="Table settings" placement="top" arrow>
          <IconButton
            id={`field-dropdown-${label}-button`}
            sx={
              dropdownOpen ? { ...styles.iconButton, ...styles.iconButtonOpen } : styles.iconButton
            }
            onClick={handleClick}
          >
            <ViewColumnIcon />
          </IconButton>
        </Tooltip>
        <Popper
          id="field-dropdown-popover"
          sx={styles.popover}
          open={dropdownOpen}
          anchorEl={anchorEl}
          placement="bottom-end"
        >
          <Box sx={styles.popoverContent}>
            <FormControl component="fieldset" variant="standard">
              <Stack sx={styles.tableSettingsHeader}>Column</Stack>
              <FormGroup id="field-dropdown-switches">
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext items={sortableColumns} strategy={verticalListSortingStrategy}>
                    {sortableColumns.map((field, index) => {
                      if (
                        field.canToggle === false ||
                        !isPermittedOption(field.id, userPermissions)
                      )
                        return null;
                      return (
                        <SortableField
                          key={index}
                          field={field}
                          handleFieldSwitchChange={handleFieldSwitchChange}
                        />
                      );
                    })}
                  </SortableContext>
                </DndContext>
              </FormGroup>
            </FormControl>
            <Stack sx={{ ...styles.tableSettingsHeader, paddingTop: 2 }}>Density</Stack>
            <FormControl>
              <FormGroup>
                {['Default', 'Compact', 'Ultra Compact'].map((size, index) => {
                  const isSwitched = size === density;
                  return (
                    <FormControlLabel
                      sx={
                        !isSwitched
                          ? { ...styles.switchLabels, ...styles.labelActive }
                          : styles.switchLabels
                      }
                      key={index}
                      control={
                        <Switch
                          sx={isSwitched ? styles.switchActive : styles.switch}
                          checked={isSwitched}
                          onChange={(event) => {
                            setDensity(size);
                            setUserPreferences(null, null, null, null, size);
                          }}
                          name={'density'}
                        />
                      }
                      label={size}
                      labelPlacement="start"
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
