import React from 'react';
import { EngageState } from '../reducer';
import { TCandidate } from '../types';
import { TableCell } from '@mui/material';
import { classes } from '../../NewUI/Components/GenericTable/styles';
import ExpandableLinkedTags from '../../NewUI/Components/GenericTable/TableCells/ExpandableLinkedTags';
import { TagList } from '../../NewUI/Components/CustomUIElements/TagList/TagList';
import NotesWithSubjectLine from '../../NewUI/Components/GenericTable/TableCells/NotesWithSubjectLine';
import FormattedDate from '../../NewUI/Components/GenericTable/TableCells/FormattedDate';

const EngageSearchTableRows = ({
  column,
  item,
  tableHovered
}: {
  column: EngageState['searchTable']['columns'][number];
  item: TCandidate;
  tableHovered: boolean;
}) => {
  if (!column.enabled) return null;
  switch (column.id) {
    case 'id':
      return (
        <TableCell
          key={item.id + column.id}
          sx={{
            ...classes.sticky,
            ...classes.idTableCell
          }}
        >
          <a
            href={`/admin/candidates/${item.id}`}
            className="candidate-id-link-engage"
            id="application-id-link"
          >
            {item.id || '-'}
          </a>
        </TableCell>
      );
    case 'name':
      return (
        <TableCell
          key={item.id + column.id}
          sx={{
            ...classes.sticky,
            ...classes.itemTitleTableCell,
            ...(tableHovered && classes.scrollShadowVisible)
          }}
        >
          <a
            href={`/admin/candidates/${item.id}`}
            className="candidate-link"
            id="candidate-name-link"
          >
            {item.name || '-'}
          </a>
        </TableCell>
      );
    case 'suburb':
      return <TableCell key={item.id + column.id}>{item.suburb || '-'}</TableCell>;
    case 'country':
      return <TableCell key={item.id + column.id}>{item.country || '-'}</TableCell>;
    case 'last_applied_on':
      return (
        <TableCell key={item.id + column.id}>
          {item.last_applied_on ? <FormattedDate date={item.last_applied_on} /> : '-'}
        </TableCell>
      );
    case 'jobs_applied_to':
      return (
        <TableCell sx={{ maxWidth: '180px' }}>
          {item?.jobs_applied_to?.length ? (
            <ExpandableLinkedTags
              label="applications"
              titleKeyType="title"
              items={item.jobs_applied_to}
              maxItems={10}
              maxItemsLink={`/admin/candidates/${item.id}`}
            />
          ) : (
            '-'
          )}
        </TableCell>
      );
    case 'tags':
      return <TableCell>{item.tags?.length ? <TagList tags={item.tags} /> : '-'}</TableCell>;
    case 'notes':
      return (
        <TableCell sx={{ maxWidth: '280px' }}>
          {item.notes?.length ? <NotesWithSubjectLine notes={item.notes} /> : '-'}
        </TableCell>
      );
    default:
      return <TableCell></TableCell>;
  }
};

export default EngageSearchTableRows;
