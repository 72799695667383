import React, { Component } from 'react';
import ScoutSelect from './select';

export default class ScoutAddButton extends Component {
  constructor() {
    super();

    this.state = {
      fields: []
    };

    this.add = this.add.bind(this);
  }

  add(e) {
    e.preventDefault();
    const { fields } = this.state;
    fields.push({ id: Date.now(), label: '', value: '' });
    this.setState({ fields });
  }

  remove(index) {
    const { fields } = this.state;
    fields.splice(index, 1);

    this.setState({ fields });
  }

  handleSelect = (i) => (e) => {
    const { fields } = this.state;
    const re = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._+-]+\.[a-zA-Z0-9_+-]+)/gi;

    if (e.target.innerText != 'Select...') {
      fields[i].label = e.target.innerText;
      fields[i].value = e.target.innerText.match(re).toString();
    }

    this.setState(fields);
  };

  render() {
    const { fields } = this.state;
    const { name, data, selected } = this.props;

    const inputs = fields.map((item, i) => (
      <div key={i} className="approver">
        <div className="select inputs" style={{ marginBottom: '5px' }}>
          <div className="label" style={{ fontWeight: 'bold' }}>
            Email
          </div>
          <span onClick={this.handleSelect(i)}>
            <ScoutSelect name={name.replace('%i', item.id)} data={data} value={item.value} />
          </span>
        </div>
        <div className="approver-remove">
          <span onClick={this.remove.bind(this, i)}>Remove</span>
        </div>
        <div className="clearfix"></div>
      </div>
    ));

    return (
      <div>
        <div>{inputs}</div>
        <button
          onClick={this.add}
          className={'add_field'}
          disabled={fields.length + selected.length >= data.length}
        >
          {this.props.title}
        </button>
      </div>
    );
  }
}
