import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import BouncedSVG from './BouncedSVG'
import ClicksSVG from './ClicksSVG'
import DeliveredSVG from './DeliveredSVG'
import OpenedSVG from './OpenedSVG'
import UnsubscribedSVG from './UnsubscribedSVG'
import { classes } from './styles';

interface IStatisticsProps {
    bounced: number,
    bounced_pcent: number,
    clicks: number,
    clicks_pcent: number,
    delivered: number,
    delivered_pcent: number,
    opened: number,
    opened_pcent: number,
    unsubscribed: number,
    unsubscribed_pcent: number,
    isLoading: boolean,
}

export default function Statistics({
  bounced,
  bounced_pcent,
  clicks,
  clicks_pcent,
  delivered,
  delivered_pcent,
  opened,
  opened_pcent,
  unsubscribed,
  unsubscribed_pcent,
  isLoading
}: IStatisticsProps) {
  return (
    <Box sx={classes.subWidget}>
      <Box sx={classes.widgetBlockContainer}>
        <Box sx={classes.widgetBlock}>
          <Box sx={classes.widgetBlockIcon}>
            <DeliveredSVG />
          </Box>
          <Box sx={classes.widgetBlockText}>
            {isLoading ? (
              <Skeleton animation="wave" width={80} height={30} />
            ) : (
              <Box sx={classes.blockCount}>{`${delivered_pcent}%`}</Box>
            )}
            <Box sx={classes.blockTitle}>{`Delivered (${delivered})`}</Box>
          </Box>
        </Box>
        <Box sx={classes.widgetBlock}>
          <Box sx={classes.widgetBlockIcon}>
            <OpenedSVG />
          </Box>
          <Box sx={classes.widgetBlockText}>
            {isLoading ? (
              <Skeleton animation="wave" width={80} height={30} />
            ) : (
              <Box sx={classes.blockCount}>{`${opened_pcent}%`}</Box>
            )}
            <Box sx={classes.blockTitle}>Opened ({opened})</Box>
          </Box>
        </Box>
        <Box sx={classes.widgetBlock}>
          <Box sx={classes.widgetBlockIcon}>
            <ClicksSVG />
          </Box>
          <Box sx={classes.widgetBlockText}>
            {isLoading ? (
              <Skeleton animation="wave" width={80} height={30} />
            ) : (
              <Box sx={classes.blockCount}>{`${clicks_pcent}%`}</Box>
            )}
            <Box sx={classes.blockTitle}>Clicks ({clicks})</Box>
          </Box>
        </Box>
        <Box sx={classes.widgetBlock}>
          <Box sx={classes.widgetBlockIcon}>
            <BouncedSVG />
          </Box>
          <Box sx={classes.widgetBlockText}>
            {isLoading ? (
              <Skeleton animation="wave" width={80} height={30} />
            ) : (
              <Box sx={classes.blockCount}>{`${bounced_pcent}%`}</Box>
            )}
            <Box sx={classes.blockTitle}>Bounced ({bounced})</Box>
          </Box>
        </Box>
        <Box sx={classes.widgetBlock}>
          <Box sx={classes.widgetBlockIcon}>
            <UnsubscribedSVG />
          </Box>
          <Box sx={classes.widgetBlockText}>
            {isLoading ? (
              <Skeleton animation="wave" width={80} height={30} />
            ) : (
              <Box sx={classes.blockCount}>{`${unsubscribed_pcent}%`}</Box>
            )}
            <Box sx={classes.blockTitle}>Unsubscribed ({unsubscribed})</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
