import { SxProps } from '@mui/material';
import { classes } from '../../../../NewUI/Components/GenericTable/styles';

export const individualCandidatePoolsColumns = [
  {
    id: 'candidate_name',
    label: 'Name',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'candidate_email',
    label: 'Email',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'added_date',
    label: 'Date Added',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'postcode',
    label: 'Postcode',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'candidate_phone_number',
    label: 'Phone',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'location',
    label: 'Location',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'unsubscribed',
    label: 'Unsubscribed',
    sortable: true,
    canToggle: false,
    enabled: true
  }
];

export const headerStyles = (
  name: (typeof individualCandidatePoolsColumns)[number]['id'] | 'checkbox' | 'actions',
  tableHovered: boolean
): SxProps => {
  switch (name) {
    case 'checkbox':
      return { ...classes.sticky, width: '58px' };
    case 'candidate_name':
      return { ...classes.sticky, ...classes.itemTitleTableCell, left: '58px', minWidth: '150px' };
    case 'candidate_email':
      return { minWidth: '150px' };
    case 'added_date':
      return { minWidth: '100px' };
    case 'postcode':
      return { minWidth: '100px' };
    case 'location':
      return { minWidth: '100px' };
    case 'unsubscribed':
      return { minWidth: '100px' };
    case 'actions':
      return {
        ...classes.actionsColumnHeader,
        ...(tableHovered && classes.scrollShadowVisible)
      };
    default:
      return { minWidth: '90px' };
  }
};
