import React, { useState } from 'react';
import { AvatarGroup, Avatar, Popover, Stack, Tooltip } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { classes } from './styles';

const getInitial = (name: string) => {
  if (name.toLowerCase() === 'me') return name;
  return name
    .trim()
    .split(' ')
    .map((x) => x[0])
    .reduce((acc, curr) => acc + curr)
    .slice(0, 2);
};

const colorScheme = (background: string, state?: string) => {
  if (state === 'accepted') {
    return { color: '#8CCF95', background: '#EFFDF3' };
  } else if (state === 'declined') {
    return { color: '#E37D7A', background: '#FCEFEF' };
  } else {
    return { background };
  }
};

const renderAvatar = (user: { id: number; name: string; state?: string }, background: string) => {
  return (
    <Tooltip key={user.id} title={user.name} placement="top" arrow>
      <Avatar sx={{ ...classes.avatar, ...colorScheme(background, user.state) }}>
        {getInitial(user.name).toUpperCase()}
      </Avatar>
    </Tooltip>
  );
};

export default function GroupedAvatars({
  users,
  background = '#DEF3F2'
}: {
  users: { id: number; name: string; state?: string }[];
  background?: string;
}) {
  const [avatarAnchorEl, setAvatarAnchorEl] = useState<HTMLDivElement | null>(null);
  return (
    <>
      <AvatarGroup
        sx={{
          justifyContent: 'start'
        }}
      >
        {users.length <= 4 ? (
          users.slice(0, 4).map((r) => renderAvatar(r, background))
        ) : (
          <Stack flexDirection="row-reverse">
            <Avatar
              sx={{
                ...classes.avatar,
                background: 'unset',
                border: '2px solid #084D6D !important',
                cursor: 'pointer'
              }}
              onClick={(e) => setAvatarAnchorEl(e.currentTarget)}
            >{`+${users.length - 3}`}</Avatar>
            {users
              .slice(0, 3)
              .reverse()
              .map((r) => renderAvatar(r, background))}
          </Stack>
        )}
      </AvatarGroup>
      <Popover
        open={Boolean(avatarAnchorEl)}
        anchorEl={avatarAnchorEl}
        onClose={() => setAvatarAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ '& .MuiPaper-root': { marginTop: 1 } }}
      >
        <Stack sx={{ padding: '8px 16px', rowGap: '4px' }}>
          {users.slice(3).map((r) => (
            <Stack key={r.id} sx={{ flexDirection: 'row', columnGap: 2, alignItems: 'center' }}>
              {r.name}
              {r.state && r.state !== 'invited' && (
                <Avatar sx={{ width: 16, height: 16, ...colorScheme(background, r.state) }}>
                  {r.state === 'accepted' ? (
                    <CheckIcon sx={{ fontSize: '0.7rem' }} />
                  ) : (
                    <CloseIcon sx={{ fontSize: '0.7rem' }} />
                  )}
                </Avatar>
              )}
            </Stack>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
