import React, { useState } from 'react';
import { Box, Popover } from '@mui/material';
import DocTypeIcon from '../../Utilities/DocTypeIcon';
import FilePreviewModal, { IJobAttachment } from '../../../Components/Modals/FilePreviewModal';
import { classes } from '../styles';

export type Attachment = {
  id: number;
  attached_file_name: string;
  attached_content_type: string;
  url: string;
  created_at: string;
  previewable: boolean;
};

const AttachmentsList = ({
  attachments,
  isOpen,
  handleClose,
  anchorEl
}: {
  attachments: Attachment[];
  isOpen: boolean;
  handleClose: () => void;
  anchorEl: Element | null;
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<IJobAttachment | null>(null);

  const sortedAttachments = [...attachments].reverse();

  return (
    <Box>
      {selectedAttachment && (
        <FilePreviewModal
          previewOpen={previewOpen}
          setPreviewOpen={setPreviewOpen}
          selectedAttachment={selectedAttachment}
          applicationLink={selectedAttachment?.application_link}
          isShareable={false}
        />
      )}
      <Popover
        id={isOpen ? 'applicant-attachments' : undefined}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box>
          {sortedAttachments?.length < 1 && (
            <Box sx={{ padding: '7px', ...classes.fileName }}>No attachments</Box>
          )}
          {sortedAttachments?.map((attachment) => (
            <Box
              key={attachment.id}
              sx={classes.attachmentItem}
              onClick={() => {
                setSelectedAttachment(attachment);
                setPreviewOpen(true);
              }}
              id="specific-attachment-option"
            >
              <Box sx={classes.fileIcon}>
                {DocTypeIcon(attachment.attached_file_name.split('.').pop())}
              </Box>
              <Box sx={classes.fileName}>{attachment.attached_file_name}</Box>
            </Box>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default AttachmentsList;
