import React from 'react';
import { Stack, IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function SortableSection({ section }: { section: string }) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id: section });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <Stack
      key={section}
      style={style}
      sx={{ flexDirection: 'row', alignItems: 'center', columnGap: 0.5 }}
      ref={setNodeRef}
      {...attributes}
    >
      <IconButton ref={setActivatorNodeRef} {...listeners} sx={{ padding: 0.5 }}>
        <DragIndicatorIcon sx={{ fontSize: 16 }} />
      </IconButton>
      <Stack sx={{ color: 'rgba(8, 77, 109, 1)', fontWeight: 'bold' }}>{section}</Stack>
    </Stack>
  );
}
