"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { theme } from "../../../ThemeContext/ThemeObject";
import { classes as rootClass } from "../styles";
export const classes = __spreadValues({
  sectionHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  sectionSubHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "17px",
    fontWeight: "500",
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    color: theme.palette.primary.main,
    "& svg": {
      fontSize: "19px",
      marginRight: "0.75rem",
      color: theme.palette.primary.main
    }
  },
  sectionSubHeaderActive: {
    fontFamily: "Source Sans Pro",
    fontSize: "17px",
    fontWeight: "550",
    alignItems: "center",
    display: "flex",
    background: "#EDF2F5",
    "& svg": {
      fontSize: "19px",
      marginRight: "0.75rem",
      color: theme.palette.primary.main
    }
  },
  sectionContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "46px 0px",
    padding: "0 60px"
  },
  formContent: {
    "& > div": {
      width: "100%",
      marginTop: "2rem"
    }
  },
  fieldHeader: {
    display: "contents",
    "& div": {
      width: "1.5rem",
      textAlign: "center",
      background: theme.palette.primary.main,
      borderRadius: "1rem",
      marginLeft: "0.7rem",
      color: "white",
      fontSize: "12px"
    }
  },
  leftSideActiveHeader: {
    background: theme.palette.primary.main,
    width: "2px",
    height: "3.75rem",
    marginRight: "40px"
  },
  leftSideHeader: {
    width: "2px",
    height: "3.75rem",
    marginRight: "40px"
  },
  tickWrapper: {
    background: "#5BC4BF",
    borderRadius: "5rem",
    height: "5rem",
    width: "5rem",
    display: "flex",
    margin: "auto",
    boxShadow: "2px 2px 5px #DDDDDD",
    marginBottom: "1.5rem"
  },
  crossWrapper: {
    background: theme.palette.error.main,
    borderRadius: "5rem",
    height: "5rem",
    width: "5rem",
    display: "flex",
    boxShadow: "2px 2px 5px #DDDDDD",
    marginBottom: "1.5rem"
  },
  tickGreen: {
    color: "#FFFFFF",
    fontSize: "4rem",
    display: "flex",
    margin: "auto"
  },
  crossRed: {
    color: "#FFFFFF",
    fontSize: "4rem",
    display: "flex",
    margin: "auto"
  },
  tickTextHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "29px",
    fontWeight: "700",
    color: "#666666"
  },
  tickTextBody: {
    fontFamily: "Source Sans Pro",
    fontSize: "17px",
    marginTop: "1rem"
  }
}, rootClass);
