import * as React from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';
import { classes } from '../styles';
import { SearchState, SearchAction } from '../useReport';

interface Props {
  sourceNameOptions: {
    id: number;
    name: string;
  }[];
  isLoading: boolean;
  reportState: SearchState;
  reportDispatch: React.Dispatch<SearchAction>;
}

export default function SourceNameField(myProps: Props) {
  const { sourceNameOptions, reportState, isLoading, reportDispatch } = myProps;
  return (
    <Box sx={{ position: 'relative', marginBottom: '4px' }}>
      <Autocomplete
        id="sourceName"
        multiple
        options={sourceNameOptions}
        getOptionLabel={(option) => option.name}
        value={reportState.applicationSourcesFields.sourceName}
        loading={isLoading}
        loadingText={isLoading ? 'Loading source names...' : 'No source names'}
        sx={{ ...classes.formAutocomplete, ...classes.formAutocompleteDynamicWidth }}
        ListboxProps={{ style: { ...classes.autoCompleteListStyles } }}
        renderInput={(params) => (
          <TextField {...params} label="Source Name" InputLabelProps={{ shrink: true }} />
        )}
        onChange={(event, value) => {
          reportDispatch({
            type: 'applicationSourcesFields',
            payload: { sourceName: value }
          });
        }}
      />
    </Box>
  );
}
