import React, { useState } from 'react';
import { Box, Modal, Button, Autocomplete, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from '../../ScheduledReportModal/styles';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';

type Props = {
  shareModalOpen: boolean;
  setShareModalOpen: (value: boolean) => void;
  reportId: number;
};

const dummyData: string[] = ['Bilbo bagins', 'Steven bagins'];

const ShareModal = ({ shareModalOpen, setShareModalOpen }: Props) => {
  const [shareValue, setShareValue] = useState<string[]>([]);

  return (
    <Modal open={shareModalOpen} onClose={() => setShareModalOpen(false)}>
      <Box sx={sharedClasses.modalBody}>
        <CloseIcon onClick={() => setShareModalOpen(false)} sx={sharedClasses.closeIcon} />
        <Box sx={sharedClasses.sectionContent}>
          <Box sx={sharedClasses.sectionHeader}>Share report</Box>
          <Box sx={{ ...classes.formContent, marginTop: 4 }} role="form">
            <Autocomplete
              id="share-users"
              options={dummyData}
              value={shareValue}
              onChange={(_event, newValue) => setShareValue(newValue)}
              sx={sharedClasses.formAutocomplete}
              multiple
              renderInput={(params) => (
                <TextField
                  label={'Share to'}
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  placeholder={shareValue.length ? '' : 'Please select'}
                  sx={{ input: { '&::placeholder': { opacity: 1 } } }}
                />
              )}
            />
          </Box>
          <Box sx={sharedClasses.modalFooter}>
            <Button
              id="share-report-button"
              variant="contained"
              sx={sharedClasses.genericButton}
              onClick={() => console.log('SHARE REPORT')}
            >
              Share
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareModal;
