import React, { Dispatch, useRef, useState } from 'react';
import { EngageAction } from '../../reducer';
import StyledModal from '../../../NewUI/Components/GenericModal/StyledModal';
import { emailIsValid } from '../../../NewUI/Components/Utilities/emailIsValid';
import { Box } from '@mui/material';
import MultiTagInput from '../../../NewUI/Components/CustomUIElements/MultiTagInput';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../NewUI/Components/GenericModal/ModalFooterButtons';
import { handleAPIRequest } from '../../../NewUI/Components/Utilities/handleAPIRequest';
import { ISnackbarInput } from '../../../NewUI/Components/CustomUIElements/StyledSnackbar';

const ExternalEmail = ({
  isOpen,
  dispatch,
  selectedCandidateIds,
  selectedPoolIds,
  apiUrl
}: {
  isOpen: boolean;
  dispatch: Dispatch<EngageAction>;
  selectedCandidateIds?: number[];
  selectedPoolIds?: number[];
  apiUrl: string;
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [submissionError, setSubmissionError] = useState<string>('');
  const [isSendingEmails, setIsSendingEmails] = useState(false);
  const emailInputRef = useRef<HTMLDivElement>(null);
  const handleClose = () => {
    dispatch({ type: 'SET_ACTION_MODAL_OPEN', payload: { name: 'externalEmail', isOpen: false } });
  };
  const handleSendEmails = () => {
    if (!emails.length) {
      setSubmissionError('Must input at least one valid email');
      emailInputRef?.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    const body = {
      external_view: {
        emails: emails.join(','),
        full_view: true,
        smart_forms: true,
        include_summary: true,
        notes: 'Include notes'
      }
    };

    if (selectedCandidateIds && selectedCandidateIds.length > 0) {
      body['candidate_ids'] = selectedCandidateIds;
    } else if (selectedPoolIds && selectedPoolIds.length > 0) {
      body['candidate_pools_ids'] = selectedPoolIds;
    }

    handleAPIRequest({
      url: apiUrl,
      method: 'POST',
      body: body,
      setLoaderState: setIsSendingEmails,
      setSnackbarState: (snackbarState: ISnackbarInput) =>
        dispatch({
          type: 'SET_SNACKBAR',
          payload: snackbarState
        }),
      onSuccess: () => {
        handleClose();
      }
    });
  };
  return (
    <StyledModal
      isOpen={isOpen}
      handleClose={handleClose}
      label="External Email"
      modalTitle="External Email"
      styleOverrides={{
        maxWidth: '900px',
        maxHeight: '900px'
      }}
    >
      <Box sx={{ ...sharedClasses.mainModalContent, height: '100%' }}>
        <Box sx={sharedClasses.actionModalFormLine} ref={emailInputRef}>
          <MultiTagInput
            tags={emails}
            setTags={setEmails}
            validation={emailIsValid}
            validationMessage="Must input valid email"
            label="Email address/s"
            width="600px"
            error={submissionError}
            tagType="emails"
            helperText="To copy and paste bulk emails, email addresses must be separated with a comma"
          />
        </Box>
      </Box>
      <ModalFooterButtons
        primaryButtonText="Send"
        primaryButtonCallback={handleSendEmails}
        primaryButtonID="send-candidate-emails"
        secondaryButtonText="Cancel"
        secondaryButtonCallback={handleClose}
        secondaryButtonID="cancel-candidate-emails"
        isLoading={isSendingEmails}
      />
    </StyledModal>
  );
};

export default ExternalEmail;
