import React, { Component } from 'react';
import T from 'prop-types';

export default class LinkColumn extends Component {
  static propTypes = {
    data: T.string,
    url: T.string
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { className, url, data } = this.props
    return (<td><a className={className} href={url}>{data}</a></td>)
  }
}
