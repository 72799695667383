import React, { Dispatch, SetStateAction, useState } from 'react';
import { FirstStep, ReviewStep, SecondStep } from './EventProgress';
import { Avatar, Box, Button, CircularProgress, Dialog, Divider, Stack } from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  NavigateBefore,
  NavigateNext
} from '@mui/icons-material';
import Api from '../API';
import { withFormValidation } from 'react-form-context-validation';
import { classes } from './styles';
import { IEvent, ScoutCalendarAction, ScoutCalendarState } from '../types';
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { InitialScoutCalendarState } from '../reducer';
import dayjs from 'dayjs';
import { IError } from '../../NewUI/SmartForms/types';
import GenericDialog from '../../NewUI/Components/Modals/GenericDialog';

const URL_PARAMS = new URL(window.location.href);

interface Props {
  events: IEvent[];
  ScoutCalendarState: ScoutCalendarState;
  dispatch: Dispatch<ScoutCalendarAction>;
  setEventCreated?: Dispatch<SetStateAction<boolean>>;
  // setSnackbar indicates that the modal is called from dashboard
  setSnackbar?: Dispatch<
    SetStateAction<{ message: string; state: 'success' | 'warning' | 'error' }>
  >;
  userTimezone: string;
}

function CreateEvent({
  events,
  ScoutCalendarState,
  dispatch,
  setEventCreated,
  setSnackbar,
  userTimezone
}: Props) {
  const [exitDialogOpen, setExitDialogOpen] = useState<boolean>(false);
  const { apiKey, showCreateEventModal, createEventObject, createMultipleEvents, weekStart } =
    ScoutCalendarState;
  const { step, eventId } = createEventObject;
  const queryClient = useQueryClient();

  const { data: options, isLoading: loadingOptions } = useQuery({
    queryKey: ['event options'],
    queryFn: () => Api.getEventOptions(apiKey),
    onError: (error) => {
      const snackbarPayload: { message: string; state: 'error' } = {
        message: `There was an error getting event type options, ${error}`,
        state: 'error'
      };
      setSnackbar
        ? setSnackbar(snackbarPayload)
        : dispatch({ type: 'SET_SNACKBAR', payload: snackbarPayload });
    },
    onSuccess: (options) =>
      !eventId &&
      dispatch({
        type: 'SET_CREATE_EVENT_OBJECT',
        payload: {
          ...createEventObject,
          eventType: options.event_types[0],
          duration: String(options.event_types[0].duration),
          maxCandidates: String(options.event_types[0].max_candidates)
        }
      })
  });

  const { data: recruiterOptions, isLoading: loadingRecruiterOptions } = useQuery({
    queryKey: ['recruiter options'],
    queryFn: () => Api.getEventRecruiters(apiKey),
    onError: (error) => {
      const snackbarPayload: { message: string; state: 'error' } = {
        message: `There was an error getting event recruiter options, ${error}`,
        state: 'error'
      };
      setSnackbar
        ? setSnackbar(snackbarPayload)
        : dispatch({ type: 'SET_SNACKBAR', payload: snackbarPayload });
    },
    onSuccess: (recruiterOptions) =>
      !eventId &&
      dispatch({
        type: 'SET_CREATE_EVENT_OBJECT',
        payload: { ...createEventObject, recruiters: [recruiterOptions.users[0]] }
      })
  });

  const validateInput = () => {
    const errors = { ...InitialScoutCalendarState.errorsMessage };
    if (step === 1) {
      if (!createEventObject.eventType) {
        errors.eventType = 'Event Type is a mandatory field';
      }
      if (Number(createEventObject.duration) < 1) {
        errors.duration = 'Duration can not be zero';
      }
      if (Number(createEventObject.maxCandidates) < createEventObject.candidates.length) {
        errors.maxCandidates =
          'The number of candidates currently booked to this event exceeds max capacity';
      }
      if (Number(createEventObject.maxCandidates) < 1) {
        errors.maxCandidates = 'Minimum 1 candidate per session';
      }
      if (Number(createEventObject.sessions) < 1) {
        errors.sessions = 'Minimum 1 session per day';
      }
      if (!createEventObject.recruiters.length) {
        errors.recruiters = 'You need at least 1 recruiter';
      }
      if (dayjs(createEventObject.eventDate).isBefore(Date.now())) {
        errors.time = 'Time must be in the future';
      }
    } else {
      if (!createEventObject.eventName) {
        errors.eventName = 'Event name is a mandatory field';
      }
    }
    dispatch({ type: 'SET_ERROR_MESSAGE', payload: errors });
    return Object.values(errors).every((item) => !item);
  };

  const nextStep = () => {
    if (validateInput()) {
      dispatch({
        type: 'SET_CREATE_EVENT_OBJECT',
        payload: { ...createEventObject, step: step + 1 }
      });
    }
  };

  const onClose = () => {
    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'SET_SHOW_CREATE_EVENT_MODAL', payload: false });
    URL_PARAMS.search = '';
    window.history.pushState(null, '', URL_PARAMS.href);
  };

  const createEventParams = {
    begins_at: dayjs(createEventObject.eventDate).toISOString(),
    ends_at: dayjs(createEventObject.eventDate)
      .add(Number(createEventObject.duration), 'm')
      .toISOString(),
    subject: createEventObject.eventName,
    note: createEventObject.eventDescription,
    event_type_id: createEventObject.eventType?.id,
    break_length: eventId ? undefined : Number(createEventObject.breaks),
    duration: Number(createEventObject.duration),
    max_candidates: Number(createEventObject.maxCandidates),
    dates: eventId ? undefined : createEventObject.dates.map((d) => dayjs(d).toISOString()),
    user_ids: createEventObject.recruiters.map((r) => r.id),
    number_of_sessions: eventId ? undefined : Number(createEventObject.sessions)
  };

  const { mutate: createEvents, isLoading: creatingEvents } = useMutation({
    mutationFn: async () => {
      const res = await Api.createEventsBatches(apiKey, createEventParams);
      return res;
    },
    onError: (error: IError) => {
      const emojiError = error.errors.includes('Incorrect string value');
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error creating the event. ${
            emojiError ? 'Event name and description can not have emojis.' : error.errors
          }`,
          state: 'error'
        }
      });
    },
    onSuccess: (res) => {
      queryClient.setQueryData(
        ['events', weekStart],
        [
          ...events,
          ...res.events.map((e: IEvent) => ({
            ...e,
            start: new Date(e.begins_at),
            end: new Date(e.ends_at)
          }))
        ]
      );
      dispatch({ type: 'SET_SHOW_CREATE_EVENT_MODAL', payload: false });
      setEventCreated && setEventCreated(true);
    }
  });

  const { mutate: editEvent, isLoading: editingEvent } = useMutation({
    mutationFn: async () => {
      const res = await Api.updateEvent(apiKey, eventId, createEventParams);
      return res;
    },
    onError: (error: IError) => {
      const emojiError = error.errors.includes('Incorrect string value');
      const snackbarPayload: { message: string; state: 'error' } = {
        message: `There was an error editing the event. ${
          emojiError ? 'Event name and description can not have emojis.' : error.errors
        }`,
        state: 'error'
      };
      setSnackbar
        ? setSnackbar(snackbarPayload)
        : dispatch({ type: 'SET_SNACKBAR', payload: snackbarPayload });
    },
    onSuccess: (res) => {
      const newList = [...events];
      const index = newList.findIndex((e) => e.id === eventId);
      newList[index] = { ...res, start: new Date(res.begins_at), end: new Date(res.ends_at) };
      setSnackbar
        ? queryClient.setQueryData(['getEvents'], { pages: [{ data: { events: newList } }] })
        : queryClient.setQueryData(['events', weekStart], newList);
      const snackbarPayload: { message: string; state: 'success' } = {
        message: `Event has been updated`,
        state: 'success'
      };
      setSnackbar
        ? setSnackbar(snackbarPayload)
        : dispatch({ type: 'SET_SNACKBAR', payload: snackbarPayload });
      onClose();
    }
  });

  const buttonOnClickAction = () => {
    if (step < 3) {
      return nextStep();
    }
    return eventId ? editEvent() : createEvents();
  };

  const buttonName = () => {
    if (step < 3) {
      return 'Next';
    }
    return eventId ? 'Save Event' : 'Create Event';
  };

  const createEventModalTitle = `Create ${createMultipleEvents ? 'multiple events' : 'an event'}`;

  const ExitDialogDescription = () => (
    <Stack>
      <Stack>{`Are you sure you want to ${
        eventId ? 'discard your changes' : 'cancel your event'
      }?`}</Stack>
      <Stack>You will lose any progress you have made.</Stack>
    </Stack>
  );

  return (
    <Dialog
      open={showCreateEventModal}
      onClose={() => setExitDialogOpen(true)}
      sx={classes.modalWrapper}
    >
      <Stack sx={{ flexDirection: 'row' }}>
        <Stack sx={{ padding: 5, minWidth: '260px' }}>
          <Stack sx={classes.modalTitle}>{eventId ? 'Edit event' : createEventModalTitle}</Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              fontFamily: 'Source Sans Pro',
              color: '#666666',
              paddingLeft: 1,
              columnGap: 1,
              alignItems: 'center',
              fontWeight: step === 1 ? 'bold' : 'inherit',
              width: 'fit-content',
              cursor: step !== 1 ? 'pointer' : 'unset'
            }}
            onClick={() =>
              step !== 1 &&
              dispatch({
                type: 'SET_CREATE_EVENT_OBJECT',
                payload: { ...createEventObject, step: 1 }
              })
            }
          >
            <Avatar
              sx={{
                fontFamily: 'inherit',
                fontWeight: 'bold',
                color: step >= 1 ? 'white' : 'inherit',
                background: step >= 1 ? '#5BC4C0' : '#D6DEE2',
                width: 32,
                height: 32
              }}
            >
              1
            </Avatar>
            Event Time
            {step > 1 && <CheckIcon sx={{ color: '#76D18F' }} />}
          </Stack>
          <Stack sx={{ flexDirection: 'row', height: '64px', paddingLeft: '23px' }}>
            <Divider orientation="vertical" sx={{ borderColor: '#D6DEE2', borderWidth: '1px' }} />
          </Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              fontFamily: 'Source Sans Pro',
              color: '#666666',
              paddingLeft: 1,
              columnGap: 1,
              alignItems: 'center',
              fontWeight: step === 2 ? 'bold' : 'inherit',
              width: 'fit-content',
              cursor: step !== 2 ? 'pointer' : 'unset'
            }}
            onClick={() =>
              step !== 2 &&
              validateInput() &&
              dispatch({
                type: 'SET_CREATE_EVENT_OBJECT',
                payload: { ...createEventObject, step: 2 }
              })
            }
          >
            <Avatar
              sx={{
                fontFamily: 'inherit',
                fontWeight: 'bold',
                color: step >= 2 ? 'white' : 'inherit',
                background: step >= 2 ? '#5BC4C0' : '#D6DEE2',
                width: 32,
                height: 32
              }}
            >
              2
            </Avatar>
            Event Details
            {step > 2 && <CheckIcon sx={{ color: '#76D18F' }} />}
          </Stack>
          <Stack sx={{ flexDirection: 'row', height: '64px', paddingLeft: '23px' }}>
            <Divider orientation="vertical" sx={{ borderColor: '#D6DEE2', borderWidth: '1px' }} />
          </Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              fontFamily: 'Source Sans Pro',
              color: '#666666',
              paddingLeft: 1,
              columnGap: 1,
              alignItems: 'center',
              fontWeight: step === 3 ? 'bold' : 'inherit',
              width: 'fit-content',
              cursor: step === 1 && !createEventObject.eventName ? 'unset' : 'pointer'
            }}
            onClick={() =>
              ((step === 1 && createEventObject.eventName) || (step === 2 && validateInput())) &&
              dispatch({
                type: 'SET_CREATE_EVENT_OBJECT',
                payload: { ...createEventObject, step: 3 }
              })
            }
          >
            <Avatar
              sx={{
                fontFamily: 'inherit',
                fontWeight: 'bold',
                color: step === 3 ? 'white' : 'inherit',
                background: step === 3 ? '#5BC4C0' : '#D6DEE2',
                width: 32,
                height: 32
              }}
            >
              3
            </Avatar>
            Review
          </Stack>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ padding: 3, minWidth: '800px', width: '800px' }}>
          <Stack sx={{ alignItems: 'end' }}>
            <CloseIcon
              id={`close-${eventId ? 'edit' : 'create'}-event-modal-button`}
              onClick={() => setExitDialogOpen(true)}
              style={{ color: '#DDDDDD', cursor: 'pointer' }}
            />
          </Stack>
          <Box sx={{ padding: 2 }}>
            {step === 1 && (
              <FirstStep
                eventTypes={options?.event_types}
                loadingOptions={loadingOptions}
                recruiterOptions={recruiterOptions?.users}
                loadingRecruiterOptions={loadingRecruiterOptions}
                ScoutCalendarState={ScoutCalendarState}
                dispatch={dispatch}
                {...{ userTimezone }}
              />
            )}
            {step === 2 && (
              <SecondStep ScoutCalendarState={ScoutCalendarState} dispatch={dispatch} />
            )}
            {step === 3 && <ReviewStep {...{ userTimezone, ScoutCalendarState, dispatch }} />}
          </Box>
          <Stack
            sx={{ flexDirection: 'row', justifyContent: 'flex-end', columnGap: 2, padding: 2 }}
          >
            {step > 1 && (
              <Button
                id={`back-to-step-${step === 3 ? 'two' : 'one'}-${eventId ? 'edit' : 'create'}-event-button`}
                onClick={() =>
                  dispatch({
                    type: 'SET_CREATE_EVENT_OBJECT',
                    payload: { ...createEventObject, step: step - 1 }
                  })
                }
                sx={{ ...sharedClasses.genericButtonSecondary, minWidth: 'unset', padding: 1 }}
              >
                <NavigateBefore />
              </Button>
            )}
            <Button
              variant="contained"
              id={
                step === 3
                  ? `${eventId ? 'save' : 'create'}-event-button`
                  : `next-to-step-${step === 2 ? 'three' : 'two'}-${eventId ? 'edit' : 'create'}-event-button`
              }
              onClick={buttonOnClickAction}
              sx={{ ...sharedClasses.genericButton, padding: '8px 12px 8px 20px' }}
            >
              {buttonName()}
              {creatingEvents || editingEvent ? (
                <CircularProgress size={16} sx={{ color: '#FFFFFF', marginLeft: 1 }} />
              ) : (
                <NavigateNext />
              )}
            </Button>
          </Stack>
        </Box>
      </Stack>
      <GenericDialog
        isDialogOpen={exitDialogOpen}
        setDialogOpen={setExitDialogOpen}
        title={`Close without submitting?`}
        description={ExitDialogDescription()}
        buttonCallback={onClose}
        callbackLoading={false}
        buttonText="Yes, close"
        secondaryButtonText="Back to editing"
        url=""
      />
    </Dialog>
  );
}

export default withFormValidation(CreateEvent);
