import React, { Dispatch, useState, useEffect, useCallback, SetStateAction } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Api from '../../../Job/API';
import { classes } from '../styles';

export default function PanelWorkflow({
  apiKey,
  jobId,
  chairsOptions,
  setChairsOptions,
  panelWorkflowTabJobData,
  setPanelWorkflowTabJobData,
  setIsEdited
}: { apiKey: string, jobId: string, setIsEdited: Dispatch<SetStateAction<boolean>> }) {
  const [isLoading, setIsLoading] = useState(false);

  const fetchWorkflowData = useCallback(async () => {
    setIsLoading(true);
      try {
        const response = await Api.getJobPanelChairs({ 'X-api-authenticate': apiKey }, jobId);
        setChairsOptions(response?.panel_chairs);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
  }, [apiKey, jobId]);

  useEffect(() => {
    chairsOptions?.length === 0 && !isLoading && fetchWorkflowData();
  }, [fetchWorkflowData]);

  const getDefaults = (options, defaults) => {
    if (options?.length > 0 && defaults?.length > 0) {
      const ids = defaults.map(item => item.id);
      const selected = options.filter((option) => ids.indexOf(option?.id) >= 0);
      return selected;
    } else return [];
  };

  return (
    <Box sx={classes.tabContent}>
      <Box id="panel-workflow-title" sx={classes.headerContent}>
        Panel workflow
      </Box>
      <Box sx={{...classes.modalFormLine, marginBottom: 3}}>
        <Box sx={{ position: 'relative', marginBottom: '4px' }}>
          <Autocomplete
            id="panelChairs"
            multiple
            options={chairsOptions}
            getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
            value={getDefaults(chairsOptions, panelWorkflowTabJobData?.panel_chairs?.value)}
            loading={chairsOptions?.length < 1}
            loadingText={isLoading ? "Loading Panel chairs..." : "No Panel chairs"}
            sx={{ ...classes.formAutocomplete, minWidth: '250px', width: '100%', marginTop: '24px' }}
            ListboxProps={{
              style: {
                fontFamily: 'Source Sans Pro, sans-serif',
                color: '#333333',
                maxHeight: '200px'
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Panel chairs"
                InputLabelProps={{ shrink: true }}
              />
            )}
            onChange={(event, value) => {
              setPanelWorkflowTabJobData((prev) => ({
                ...prev,
                panel_chairs: { ...prev.panel_chairs, value },
              }))
              setIsEdited(true);
            }}
          />
        </Box>
      </Box>
      <Box sx={{...classes.modalFormLine, marginBottom: 3}}>
        <Box sx={{ position: 'relative', marginBottom: '4px' }}>
          <Autocomplete
            id="panelMembers"
            multiple
            options={chairsOptions}
            getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
            value={getDefaults(chairsOptions, panelWorkflowTabJobData?.panel_members?.value)}
            loading={chairsOptions?.length < 1}
            loadingText={isLoading ? "Loading Panel members..." : "No Panel members"}
            sx={{ ...classes.formAutocomplete, minWidth: '250px', width: '100%', marginTop: '24px' }}
            ListboxProps={{
              style: {
                fontFamily: 'Source Sans Pro, sans-serif',
                color: '#333333',
                maxHeight: '200px'
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Panel members"
                InputLabelProps={{ shrink: true }}
              />
            )}
            onChange={(event, value) => {
              setPanelWorkflowTabJobData((prev) => ({
                ...prev,
                panel_members: { ...prev.panel_members, value },
              }))
              setIsEdited(true);
            }}
          />
        </Box>
      </Box>
      <Box sx={{...classes.modalFormLine, marginBottom: 3}}>
        <Box sx={{ position: 'relative', marginBottom: '4px' }}>
          <Autocomplete
            id="panelObservers"
            multiple
            options={chairsOptions}
            getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
            value={getDefaults(chairsOptions, panelWorkflowTabJobData?.panel_observers?.value)}
            loading={chairsOptions?.length < 1}
            loadingText={isLoading ? "Loading Panel observers..." : "No Panel observers"}
            sx={{ ...classes.formAutocomplete, minWidth: '250px', width: '100%', marginTop: '24px' }}
            ListboxProps={{
              style: {
                fontFamily: 'Source Sans Pro, sans-serif',
                color: '#333333',
                maxHeight: '200px'
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Panel observers"
                InputLabelProps={{ shrink: true }}
              />
            )}
            onChange={(event, value) => {
              setPanelWorkflowTabJobData((prev) => ({
                ...prev,
                panel_observers: { ...prev.panel_observers, value },
              }))
              setIsEdited(true);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
