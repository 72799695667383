import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { IGetApplications } from '../types';
import { classes } from './styles';
import { useDebouncedCallback } from 'use-debounce';

export default function Search({
  setSearch,
  getApplications,
  setPage,
  jobId,
}: {
  setSearch: (query: string) => void;
  getApplications: IGetApplications;
  setPage: (page: number) => void;
  jobId: number;
}) {
  const [tempSearch, setTempSearch] = useState('');
  const debouncedAPICall = useDebouncedCallback((searchQuery) => {
    sessionStorage.setItem(`${jobId}-applicationTablePage`, 1);
    getApplications(1, null, searchQuery, null, null);
    setPage(1);
    setSearch(searchQuery);
  }, 600);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearch(event.target.value);
    debouncedAPICall(event.target.value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <OutlinedInput
          id="applicant-search"
          sx={classes.searchInput}
          placeholder={'Search by name'}
          value={tempSearch}
          onChange={handleChange}
          startAdornment={
            <InputAdornment sx={{ padding: '0px 0px 0px 14px' }} position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}
