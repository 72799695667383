import React from 'react';

export const SocialSVG = () => {
  return (
    <svg
      width="180"
      height="122"
      viewBox="0 0 180 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3855_3117)">
        <rect x="6" y="24" width="93" height="92" rx="6" fill="white" />
      </g>
      <rect x="14" y="35" width="74" height="44" fill="#E3E8FF" />
      <rect x="14" y="85" width="64" height="2" fill="#E3E8FF" />
      <rect x="14" y="96" width="55" height="3" fill="#E3E8FF" />
      <g filter="url(#filter1_d_3855_3117)">
        <rect x="81" y="6" width="93" height="92" rx="6" fill="white" />
      </g>
      <rect x="89" y="17" width="74" height="43" fill="#E3E8FF" />
      <rect x="89" y="66" width="64" height="3" fill="#E3E8FF" />
      <rect x="89" y="77" width="55" height="3" fill="#E3E8FF" />
      <ellipse cx="167.5" cy="100" rx="9.5" ry="10" fill="#C7D1FF" />
      <circle cx="119" cy="100" r="10" fill="#C7D1FF" />
      <mask id="mask0_3855_3117" maskUnits="userSpaceOnUse" x="112" y="92" width="15" height="16">
        <rect x="112.058" y="92.8486" width="14.368" height="14.368" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3855_3117)">
        <path
          d="M121.337 99.4339C121.586 99.4339 121.798 99.3466 121.973 99.172C122.148 98.9974 122.235 98.7853 122.235 98.5359C122.235 98.2864 122.148 98.0744 121.973 97.8998C121.798 97.7252 121.586 97.6379 121.337 97.6379C121.087 97.6379 120.875 97.7252 120.701 97.8998C120.526 98.0744 120.439 98.2864 120.439 98.5359C120.439 98.7853 120.526 98.9974 120.701 99.172C120.875 99.3466 121.087 99.4339 121.337 99.4339ZM117.146 99.4339C117.396 99.4339 117.608 99.3466 117.782 99.172C117.957 98.9974 118.044 98.7853 118.044 98.5359C118.044 98.2864 117.957 98.0744 117.782 97.8998C117.608 97.7252 117.396 97.6379 117.146 97.6379C116.897 97.6379 116.685 97.7252 116.51 97.8998C116.336 98.0744 116.248 98.2864 116.248 98.5359C116.248 98.7853 116.336 98.9974 116.51 99.172C116.685 99.3466 116.897 99.4339 117.146 99.4339ZM119.242 103.325C119.92 103.325 120.536 103.133 121.09 102.749C121.644 102.365 122.045 101.858 122.295 101.23H116.188C116.438 101.858 116.839 102.365 117.393 102.749C117.947 103.133 118.563 103.325 119.242 103.325ZM119.242 106.019C118.413 106.019 117.635 105.862 116.907 105.548C116.178 105.233 115.545 104.807 115.006 104.268C114.467 103.729 114.041 103.096 113.726 102.367C113.412 101.639 113.255 100.861 113.255 100.033C113.255 99.2044 113.412 98.4261 113.726 97.6978C114.041 96.9694 114.467 96.3358 115.006 95.797C115.545 95.2582 116.178 94.8316 116.907 94.5173C117.635 94.203 118.413 94.0459 119.242 94.0459C120.07 94.0459 120.848 94.203 121.576 94.5173C122.305 94.8316 122.938 95.2582 123.477 95.797C124.016 96.3358 124.442 96.9694 124.757 97.6978C125.071 98.4261 125.228 99.2044 125.228 100.033C125.228 100.861 125.071 101.639 124.757 102.367C124.442 103.096 124.016 103.729 123.477 104.268C122.938 104.807 122.305 105.233 121.576 105.548C120.848 105.862 120.07 106.019 119.242 106.019Z"
          fill="white"
        />
      </g>
      <circle cx="144" cy="100" r="10" fill="#C7D1FF" />
      <rect x="14" y="85" width="64" height="2" fill="#E3E8FF" />
      <rect x="14" y="96" width="55" height="3" fill="#E3E8FF" />
      <rect x="14" y="85" width="64" height="2" fill="#E3E8FF" />
      <rect x="14" y="96" width="55" height="3" fill="#E3E8FF" />
      <rect x="14" y="85" width="64" height="2" fill="#E3E8FF" />
      <rect x="14" y="96" width="55" height="3" fill="#E3E8FF" />
      <mask id="mask1_3855_3117" maskUnits="userSpaceOnUse" x="161" y="94" width="14" height="14">
        <rect x="161.368" y="94.3579" width="12.7007" height="12.7007" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask1_3855_3117)">
        <path
          d="M167.719 105.471L166.951 104.783C166.061 103.98 165.324 103.288 164.742 102.706C164.16 102.124 163.697 101.601 163.353 101.138C163.009 100.675 162.769 100.25 162.632 99.8615C162.495 99.4734 162.427 99.0766 162.427 98.6708C162.427 97.8418 162.705 97.1494 163.26 96.5937C163.816 96.0381 164.508 95.7603 165.337 95.7603C165.796 95.7603 166.233 95.8573 166.647 96.0513C167.062 96.2454 167.419 96.5188 167.719 96.8716C168.019 96.5188 168.376 96.2454 168.79 96.0513C169.205 95.8573 169.641 95.7603 170.1 95.7603C170.929 95.7603 171.622 96.0381 172.177 96.5937C172.733 97.1494 173.011 97.8418 173.011 98.6708C173.011 99.0766 172.942 99.4734 172.806 99.8615C172.669 100.25 172.429 100.675 172.085 101.138C171.741 101.601 171.278 102.124 170.695 102.706C170.113 103.288 169.377 103.98 168.486 104.783L167.719 105.471Z"
          fill="white"
        />
      </g>
      <rect x="89" y="17" width="74" height="43" fill="#E3E8FF" />
      <rect x="89" y="66" width="64" height="3" fill="#E3E8FF" />
      <rect x="89" y="77" width="55" height="3" fill="#E3E8FF" />
      <ellipse cx="167.5" cy="100" rx="9.5" ry="10" fill="#C7D1FF" />
      <circle cx="119" cy="100" r="10" fill="#C7D1FF" />
      <mask id="mask2_3855_3117" maskUnits="userSpaceOnUse" x="112" y="92" width="15" height="16">
        <rect x="112.058" y="92.8486" width="14.368" height="14.368" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask2_3855_3117)">
        <path
          d="M121.337 99.4339C121.586 99.4339 121.798 99.3466 121.973 99.172C122.148 98.9974 122.235 98.7853 122.235 98.5359C122.235 98.2864 122.148 98.0744 121.973 97.8998C121.798 97.7252 121.586 97.6379 121.337 97.6379C121.087 97.6379 120.875 97.7252 120.701 97.8998C120.526 98.0744 120.439 98.2864 120.439 98.5359C120.439 98.7853 120.526 98.9974 120.701 99.172C120.875 99.3466 121.087 99.4339 121.337 99.4339ZM117.146 99.4339C117.396 99.4339 117.608 99.3466 117.782 99.172C117.957 98.9974 118.044 98.7853 118.044 98.5359C118.044 98.2864 117.957 98.0744 117.782 97.8998C117.608 97.7252 117.396 97.6379 117.146 97.6379C116.897 97.6379 116.685 97.7252 116.51 97.8998C116.336 98.0744 116.248 98.2864 116.248 98.5359C116.248 98.7853 116.336 98.9974 116.51 99.172C116.685 99.3466 116.897 99.4339 117.146 99.4339ZM119.242 103.325C119.92 103.325 120.536 103.133 121.09 102.749C121.644 102.365 122.045 101.858 122.295 101.23H116.188C116.438 101.858 116.839 102.365 117.393 102.749C117.947 103.133 118.563 103.325 119.242 103.325ZM119.242 106.019C118.413 106.019 117.635 105.862 116.907 105.548C116.178 105.233 115.545 104.807 115.006 104.268C114.467 103.729 114.041 103.096 113.726 102.367C113.412 101.639 113.255 100.861 113.255 100.033C113.255 99.2044 113.412 98.4261 113.726 97.6978C114.041 96.9694 114.467 96.3358 115.006 95.797C115.545 95.2582 116.178 94.8316 116.907 94.5173C117.635 94.203 118.413 94.0459 119.242 94.0459C120.07 94.0459 120.848 94.203 121.576 94.5173C122.305 94.8316 122.938 95.2582 123.477 95.797C124.016 96.3358 124.442 96.9694 124.757 97.6978C125.071 98.4261 125.228 99.2044 125.228 100.033C125.228 100.861 125.071 101.639 124.757 102.367C124.442 103.096 124.016 103.729 123.477 104.268C122.938 104.807 122.305 105.233 121.576 105.548C120.848 105.862 120.07 106.019 119.242 106.019Z"
          fill="white"
        />
      </g>
      <mask id="mask3_3855_3117" maskUnits="userSpaceOnUse" x="161" y="94" width="14" height="14">
        <rect x="161.368" y="94.3579" width="12.7007" height="12.7007" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask3_3855_3117)">
        <path
          d="M167.719 105.471L166.951 104.783C166.061 103.98 165.324 103.288 164.742 102.706C164.16 102.124 163.697 101.601 163.353 101.138C163.009 100.675 162.769 100.25 162.632 99.8615C162.495 99.4734 162.427 99.0766 162.427 98.6708C162.427 97.8418 162.705 97.1494 163.26 96.5937C163.816 96.0381 164.508 95.7603 165.337 95.7603C165.796 95.7603 166.233 95.8573 166.647 96.0513C167.062 96.2454 167.419 96.5188 167.719 96.8716C168.019 96.5188 168.376 96.2454 168.79 96.0513C169.205 95.8573 169.641 95.7603 170.1 95.7603C170.929 95.7603 171.622 96.0381 172.177 96.5937C172.733 97.1494 173.011 97.8418 173.011 98.6708C173.011 99.0766 172.942 99.4734 172.806 99.8615C172.669 100.25 172.429 100.675 172.085 101.138C171.741 101.601 171.278 102.124 170.695 102.706C170.113 103.288 169.377 103.98 168.486 104.783L167.719 105.471Z"
          fill="white"
        />
      </g>
      <rect x="89" y="17" width="74" height="43" fill="#E3E8FF" />
      <rect x="89" y="66" width="64" height="3" fill="#E3E8FF" />
      <rect x="89" y="77" width="55" height="3" fill="#E3E8FF" />
      <ellipse cx="167.5" cy="100" rx="9.5" ry="10" fill="#C7D1FF" />
      <circle cx="119" cy="100" r="10" fill="#C7D1FF" />
      <mask id="mask4_3855_3117" maskUnits="userSpaceOnUse" x="112" y="92" width="15" height="16">
        <rect x="112.058" y="92.8486" width="14.368" height="14.368" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask4_3855_3117)">
        <path
          d="M121.337 99.4339C121.586 99.4339 121.798 99.3466 121.973 99.172C122.148 98.9974 122.235 98.7853 122.235 98.5359C122.235 98.2864 122.148 98.0744 121.973 97.8998C121.798 97.7252 121.586 97.6379 121.337 97.6379C121.087 97.6379 120.875 97.7252 120.701 97.8998C120.526 98.0744 120.439 98.2864 120.439 98.5359C120.439 98.7853 120.526 98.9974 120.701 99.172C120.875 99.3466 121.087 99.4339 121.337 99.4339ZM117.146 99.4339C117.396 99.4339 117.608 99.3466 117.782 99.172C117.957 98.9974 118.044 98.7853 118.044 98.5359C118.044 98.2864 117.957 98.0744 117.782 97.8998C117.608 97.7252 117.396 97.6379 117.146 97.6379C116.897 97.6379 116.685 97.7252 116.51 97.8998C116.336 98.0744 116.248 98.2864 116.248 98.5359C116.248 98.7853 116.336 98.9974 116.51 99.172C116.685 99.3466 116.897 99.4339 117.146 99.4339ZM119.242 103.325C119.92 103.325 120.536 103.133 121.09 102.749C121.644 102.365 122.045 101.858 122.295 101.23H116.188C116.438 101.858 116.839 102.365 117.393 102.749C117.947 103.133 118.563 103.325 119.242 103.325ZM119.242 106.019C118.413 106.019 117.635 105.862 116.907 105.548C116.178 105.233 115.545 104.807 115.006 104.268C114.467 103.729 114.041 103.096 113.726 102.367C113.412 101.639 113.255 100.861 113.255 100.033C113.255 99.2044 113.412 98.4261 113.726 97.6978C114.041 96.9694 114.467 96.3358 115.006 95.797C115.545 95.2582 116.178 94.8316 116.907 94.5173C117.635 94.203 118.413 94.0459 119.242 94.0459C120.07 94.0459 120.848 94.203 121.576 94.5173C122.305 94.8316 122.938 95.2582 123.477 95.797C124.016 96.3358 124.442 96.9694 124.757 97.6978C125.071 98.4261 125.228 99.2044 125.228 100.033C125.228 100.861 125.071 101.639 124.757 102.367C124.442 103.096 124.016 103.729 123.477 104.268C122.938 104.807 122.305 105.233 121.576 105.548C120.848 105.862 120.07 106.019 119.242 106.019Z"
          fill="white"
        />
      </g>
      <mask id="mask5_3855_3117" maskUnits="userSpaceOnUse" x="161" y="94" width="14" height="14">
        <rect x="161.368" y="94.3579" width="12.7007" height="12.7007" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask5_3855_3117)">
        <path
          d="M167.719 105.471L166.951 104.783C166.061 103.98 165.324 103.288 164.742 102.706C164.16 102.124 163.697 101.601 163.353 101.138C163.009 100.675 162.769 100.25 162.632 99.8615C162.495 99.4734 162.427 99.0766 162.427 98.6708C162.427 97.8418 162.705 97.1494 163.26 96.5937C163.816 96.0381 164.508 95.7603 165.337 95.7603C165.796 95.7603 166.233 95.8573 166.647 96.0513C167.062 96.2454 167.419 96.5188 167.719 96.8716C168.019 96.5188 168.376 96.2454 168.79 96.0513C169.205 95.8573 169.641 95.7603 170.1 95.7603C170.929 95.7603 171.622 96.0381 172.177 96.5937C172.733 97.1494 173.011 97.8418 173.011 98.6708C173.011 99.0766 172.942 99.4734 172.806 99.8615C172.669 100.25 172.429 100.675 172.085 101.138C171.741 101.601 171.278 102.124 170.695 102.706C170.113 103.288 169.377 103.98 168.486 104.783L167.719 105.471Z"
          fill="white"
        />
      </g>
      <rect x="89" y="17" width="74" height="43" fill="#E3E8FF" />
      <rect x="89" y="66" width="64" height="3" fill="#E3E8FF" />
      <rect x="89" y="77" width="55" height="3" fill="#E3E8FF" />
      <ellipse cx="167.5" cy="100" rx="9.5" ry="10" fill="#C7D1FF" />
      <circle cx="119" cy="100" r="10" fill="#C7D1FF" />
      <mask id="mask6_3855_3117" maskUnits="userSpaceOnUse" x="112" y="92" width="15" height="16">
        <rect x="112.058" y="92.8486" width="14.368" height="14.368" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask6_3855_3117)">
        <path
          d="M121.337 99.4339C121.586 99.4339 121.798 99.3466 121.973 99.172C122.148 98.9974 122.235 98.7853 122.235 98.5359C122.235 98.2864 122.148 98.0744 121.973 97.8998C121.798 97.7252 121.586 97.6379 121.337 97.6379C121.087 97.6379 120.875 97.7252 120.701 97.8998C120.526 98.0744 120.439 98.2864 120.439 98.5359C120.439 98.7853 120.526 98.9974 120.701 99.172C120.875 99.3466 121.087 99.4339 121.337 99.4339ZM117.146 99.4339C117.396 99.4339 117.608 99.3466 117.782 99.172C117.957 98.9974 118.044 98.7853 118.044 98.5359C118.044 98.2864 117.957 98.0744 117.782 97.8998C117.608 97.7252 117.396 97.6379 117.146 97.6379C116.897 97.6379 116.685 97.7252 116.51 97.8998C116.336 98.0744 116.248 98.2864 116.248 98.5359C116.248 98.7853 116.336 98.9974 116.51 99.172C116.685 99.3466 116.897 99.4339 117.146 99.4339ZM119.242 103.325C119.92 103.325 120.536 103.133 121.09 102.749C121.644 102.365 122.045 101.858 122.295 101.23H116.188C116.438 101.858 116.839 102.365 117.393 102.749C117.947 103.133 118.563 103.325 119.242 103.325ZM119.242 106.019C118.413 106.019 117.635 105.862 116.907 105.548C116.178 105.233 115.545 104.807 115.006 104.268C114.467 103.729 114.041 103.096 113.726 102.367C113.412 101.639 113.255 100.861 113.255 100.033C113.255 99.2044 113.412 98.4261 113.726 97.6978C114.041 96.9694 114.467 96.3358 115.006 95.797C115.545 95.2582 116.178 94.8316 116.907 94.5173C117.635 94.203 118.413 94.0459 119.242 94.0459C120.07 94.0459 120.848 94.203 121.576 94.5173C122.305 94.8316 122.938 95.2582 123.477 95.797C124.016 96.3358 124.442 96.9694 124.757 97.6978C125.071 98.4261 125.228 99.2044 125.228 100.033C125.228 100.861 125.071 101.639 124.757 102.367C124.442 103.096 124.016 103.729 123.477 104.268C122.938 104.807 122.305 105.233 121.576 105.548C120.848 105.862 120.07 106.019 119.242 106.019Z"
          fill="white"
        />
      </g>
      <mask id="mask7_3855_3117" maskUnits="userSpaceOnUse" x="161" y="94" width="14" height="14">
        <rect x="161.368" y="94.3579" width="12.7007" height="12.7007" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask7_3855_3117)">
        <path
          d="M167.719 105.471L166.951 104.783C166.061 103.98 165.324 103.288 164.742 102.706C164.16 102.124 163.697 101.601 163.353 101.138C163.009 100.675 162.769 100.25 162.632 99.8615C162.495 99.4734 162.427 99.0766 162.427 98.6708C162.427 97.8418 162.705 97.1494 163.26 96.5937C163.816 96.0381 164.508 95.7603 165.337 95.7603C165.796 95.7603 166.233 95.8573 166.647 96.0513C167.062 96.2454 167.419 96.5188 167.719 96.8716C168.019 96.5188 168.376 96.2454 168.79 96.0513C169.205 95.8573 169.641 95.7603 170.1 95.7603C170.929 95.7603 171.622 96.0381 172.177 96.5937C172.733 97.1494 173.011 97.8418 173.011 98.6708C173.011 99.0766 172.942 99.4734 172.806 99.8615C172.669 100.25 172.429 100.675 172.085 101.138C171.741 101.601 171.278 102.124 170.695 102.706C170.113 103.288 169.377 103.98 168.486 104.783L167.719 105.471Z"
          fill="white"
        />
      </g>
      <mask id="mask8_3855_3117" maskUnits="userSpaceOnUse" x="138" y="94" width="12" height="12">
        <rect x="138" y="94" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask8_3855_3117)">
        <path
          d="M147 104.5H142V98L145.5 94.5L146.125 95.125C146.183 95.1833 146.231 95.2625 146.269 95.3625C146.306 95.4625 146.325 95.5583 146.325 95.65V95.825L145.775 98H148.5C148.767 98 149 98.1 149.2 98.3C149.4 98.5 149.5 98.7333 149.5 99V100C149.5 100.058 149.494 100.121 149.481 100.188C149.469 100.254 149.45 100.317 149.425 100.375L147.925 103.9C147.85 104.067 147.725 104.208 147.55 104.325C147.375 104.442 147.192 104.5 147 104.5ZM141 98V104.5H139V98H141Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3855_3117"
          x="0.5"
          y="18.5"
          width="104"
          height="103"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="0.5"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_3855_3117"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.508333 0 0 0 0 0.588229 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3855_3117" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3855_3117"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3855_3117"
          x="75.5"
          y="0.5"
          width="104"
          height="103"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="0.5"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_3855_3117"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.508333 0 0 0 0 0.588229 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3855_3117" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3855_3117"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const AdvertisementSVG = () => {
  return (
    <svg
      width="90"
      height="132"
      viewBox="0 0 90 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3855_3146)">
        <rect x="6" y="6" width="78" height="120" rx="6" fill="white" />
      </g>
      <rect x="14" y="18" width="62" height="21" fill="#7D7DEF" />
      <rect x="61" y="111" width="15" height="6" fill="#7D7DEF" />
      <rect x="14" y="47" width="62" height="2" fill="#A4A4FF" />
      <rect x="14" y="63" width="62" height="2" fill="#A4A4FF" />
      <rect x="14" y="79" width="62" height="2" fill="#A4A4FF" />
      <rect x="14" y="55" width="62" height="2" fill="#A4A4FF" />
      <rect x="14" y="71" width="62" height="2" fill="#A4A4FF" />
      <rect x="14" y="87" width="62" height="2" fill="#A4A4FF" />
      <rect x="14" y="95" width="53" height="2" fill="#A4A4FF" />
      <defs>
        <filter
          id="filter0_d_3855_3146"
          x="0.5"
          y="0.5"
          width="89"
          height="131"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="0.5"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_3855_3146"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.337255 0 0 0 0 0.337255 0 0 0 0 0.858824 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3855_3146" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3855_3146"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
