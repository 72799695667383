"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  widgetsbarContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowX: "hidden",
    overflowY: "auto",
    zIndex: 1200,
    backgroundColor: "#EAEEF0",
    transition: "width 0.2s ease-in-out",
    marginLeft: "auto !important",
    paddingBottom: "30%"
  },
  hideButtonContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "91px",
    marginLeft: "auto !important"
  },
  addButton: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: "20px",
    borderRadius: "50% !important",
    zIndex: 1200,
    "&:focus": { outline: "none" }
  },
  actionsMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      width: "170px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#666666",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  datePicker: {
    position: "relative",
    width: "fit-content",
    margin: " auto",
    "& .MuiPickersLayout-root": {
      width: "260px !important",
      borderRadius: "6px !important",
      boxShadow: "0px 4px 20px 3px #305A6F14",
      marginInline: "auto"
    },
    "& .MuiPickersLayout-contentWrapper": {
      width: "inherit"
    },
    "& .MuiDateCalendar-root": {
      width: "260px !important"
    },
    "& .MuiPickersFadeTransitionGroup-root": {
      paddingInline: "12px"
    },
    "& .MuiPickersCalendarHeader-root": {
      '& div[role="presentation"]': {
        fontFamily: "Source Sans Pro",
        fontSize: "18px",
        color: "#666666",
        fontWeight: "700"
      }
    },
    "& .PrivatePickersFadeTransitionGroup-root": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: theme.palette.primary.main
    },
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro",
      color: "#939393",
      fontSize: "14px"
    },
    "& .MuiDayCalendar-weekContainer": {
      justifyContent: "space-between",
      "& button": {
        fontFamily: "Source Sans Pro",
        fontSize: "14px",
        minWidth: "30px",
        height: "30px",
        borderRadius: "50%"
      }
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.action.main} !important`
    },
    "& .PrivatePickersSlideTransition-root": {
      minHeight: "250px !important"
    },
    "& .MuiPickersCalendarHeader-labelContainer": {
      maxHeight: "40px !important",
      marginRight: "unset"
    },
    "& .MuiPickersArrowSwitcher-root": {
      marginLeft: "8px"
    },
    "& .PrivatePickersYear-yearButton": {
      height: "30px"
    },
    "& .MuiPickersCalendarHeader-switchViewButton": {
      display: "none"
    },
    "& .MuiDayPicker-weekContainer": {
      margin: "10px 0 !important"
    },
    "& .MuiYearCalendar-root": {
      width: "280px !important"
    }
  },
  recentlyViewedContainer: {
    width: "100%",
    padding: "0 10px 20px 10px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    rowGap: "13px",
    justifyContent: "space-between",
    margin: "0 10px 40px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E3E3E3",
    boxSizing: "border-box",
    borderRadius: "6px",
    boxShadow: "0px 4px 20px 3px #305A6F14"
  },
  title: {
    fontFamily: "Source Sans Pro",
    margin: "20px 20px 10px 14px",
    fontSize: "18px",
    fontWeight: "700",
    color: "#666666"
  }
};
