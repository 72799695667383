const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.color = "#084D6D";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-20%, -70%)";
    tooltipEl.style.transition = "all .1s ease";
    const table = document.createElement("table");
    table.style.margin = "0px";
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};
const paperCon = document.createElement("img");
paperCon.src = "/images/icons/newNote.svg";
paperCon.style.height = "22px";
paperCon.style.paddingTop = "3px";
export const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  const data = tooltip.body[0].lines[0];
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }
  if (tooltip.body) {
    const title = data.split(": ")[0];
    const body = data.split(": ")[1];
    const tableHead = document.createElement("thead");
    const trow = document.createElement("tr");
    trow.style.borderWidth = 0;
    trow.style.background = "transparent";
    trow.style.fontSize = "18px";
    const th = document.createElement("th");
    th.style.borderWidth = 0;
    th.style.background = "transparent";
    th.style.padding = "0px";
    th.style.display = "flex";
    th.style.alignItems = "center";
    th.style.textTransform = "unset";
    const titleText = document.createTextNode(title);
    th.appendChild(paperCon);
    th.appendChild(titleText);
    trow.appendChild(th);
    tableHead.appendChild(trow);
    const tableBody = document.createElement("tbody");
    const span = document.createElement("span");
    span.innerText = " Applicants";
    span.style.fontSize = "12px";
    const tr = document.createElement("tr");
    tr.style.backgroundColor = "transparent";
    tr.style.borderWidth = 0;
    const td = document.createElement("td");
    td.style.borderWidth = 0;
    td.style.fontSize = "12px";
    td.style.color = "#666666";
    td.style.padding = "0px";
    td.style.paddingLeft = "3px";
    const text = document.createTextNode(body);
    td.appendChild(text);
    td.appendChild(span);
    tr.appendChild(td);
    tableBody.appendChild(tr);
    const tableRoot = tooltipEl.querySelector("table");
    tableRoot.style.background = "transparent";
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.background = "#FEFEFE80";
  tooltipEl.style.borderRadius = "4px";
  tooltipEl.style.padding = "6px";
  tooltipEl.style.backdropFilter = "blur(4px)";
};
