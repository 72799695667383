"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { theme } from "../../../../ThemeContext/ThemeObject";
import { sharedClasses } from "../../../Components/CustomUIElements/sharedClasses";
export const classes = {
  tableWrapper: {
    tableLayout: "auto !important",
    // minWidth: '1000px',
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "12px 0px 12px 4px"
    },
    th: {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontWeight: "bold",
      paddingBottom: "5px"
    }
  },
  fileIconContainer: {
    display: "flex",
    alignItems: "center"
  },
  fileIcon: {
    minWidth: "50px",
    minHeight: "50px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#084D6D10",
    "& svg": {
      color: theme.palette.primary.main,
      margin: "0px 2px 2px 0px"
    }
  },
  fileDate: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "bold"
  },
  fileName: {
    color: "#999999"
  },
  fileLinkContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: "14px",
    "& a": {
      color: "#333333",
      fontSize: "14px",
      "&:hover": {
        color: "#000000",
        textDecoration: "none"
      }
    }
  },
  fileAttachedBy: {
    color: "#000000",
    fontSize: "14px"
  },
  copyButton: {
    fontSize: "10px",
    fontWeight: "bold",
    backgroundColor: "#5BC4BF1F",
    color: theme.palette.action.main,
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.action.main,
      color: "#FFFFFF"
    }
  },
  noAttachments: __spreadValues({}, sharedClasses.noContentWrapper),
  paginationParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  },
  rowSelectContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666",
    "& span": {
      marginRight: "12px",
      paddingBottom: "6px"
    }
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666"
  },
  pagination: {
    marginRight: "24px",
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#CCCCCC",
      margin: "0px -2px"
    },
    "& .Mui-selected": {
      color: "#666666",
      backgroundColor: "#ffffff00 !important",
      "&:hover": {
        backgroundColor: "#ffffff00 !important"
      }
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#666666"
    }
  },
  rowSelect: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: "#666666",
    fontWeight: "bold",
    borderRadius: "6px !important",
    "& legend": {
      display: "none"
    },
    "& svg": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DBDEDF"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CACDCE"
    },
    "& .MuiOutlinedInput-input": {
      padding: "4px 12px 8px 12px !important"
    }
  },
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  }
};
