import React, { Dispatch, FC, useState } from 'react';
import { Box, Modal, Button, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import FormCheckboxGroup from '../../../NewUI/Components/CustomUIElements/FormCheckboxGroup';

interface IPublishedJobsAndSMSModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
}

const PublishedJobsAndSMSModal: FC<IPublishedJobsAndSMSModal> = ({ dispatch, state }) => {
  const { filtersModal, notExpired, scoutClient, drilldown } = state;
  const [options, setOptions] = useState<Record<string, boolean>>(
    filtersModal['SMS Report'] ? { 1: scoutClient, 2: drilldown } : { 1: notExpired }
  );

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, 'Published Jobs Report': false, 'SMS Report': false }
    });
  };

  const checkboxOptions = filtersModal['SMS Report']
    ? [
        { name: 'Scout clients', id: 1 },
        { name: 'Drilldown to sub-entities', id: 2 }
      ]
    : [{ name: 'Only show jobs that are currently in the published status', id: 1 }];

  return (
    <Modal
      open={filtersModal['Published Jobs Report'] || filtersModal['SMS Report']}
      onClose={handleClose}
      aria-labelledby="published-and-sms-filters-modal"
    >
      <Box sx={{ ...classes.actionsModal, maxHeight: '276px', width: '720px' }}>
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>{`${
            filtersModal['SMS Report'] ? 'SMS' : 'Published jobs'
          } report filters`}</Box>
        </Box>
        <Stack sx={{ flexGrow: 1 }}>
          <Box sx={{ ...classes.modalFormLine }}>
            <FormCheckboxGroup
              options={checkboxOptions}
              defaultValues={options}
              onChange={(value) => setOptions(value)}
              directionRow
              color={'#939393'}
            />
          </Box>
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset' }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              const filter: Record<string, string> = {};
              if (filtersModal['Published Jobs Report']) {
                if (options[1]) filter['Currently published Jobs'] = 'true';
                dispatch({ type: 'NOT_EXPIRED', payload: options[1] });
              } else {
                if (options[1]) filter['Scout clients'] = 'true';
                if (options[2]) filter['Drilldown to sub-entities'] = 'true';
                dispatch({ type: 'SCOUT_CLIENT', payload: options[1] });
                dispatch({ type: 'DRILLDOWN', payload: options[2] });
              }
              dispatch({ type: 'SET_FILTERS', payload: filter });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default PublishedJobsAndSMSModal;
