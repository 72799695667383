import React from 'react';
import { Box } from '@mui/material';
import { TEmailTemplate } from '../../types';
import ReactLoading from 'react-loading';
import { theme } from '../../../ThemeContext/ThemeObject';
import HtmlEditor from '../../../NewUI/Components/Utilities/HtmlEditor';
import AttachmentPreview from '../../../NewUI/Components/CustomUIElements/AttachmentsPreview';

const EmailTemplates = ({
  isLoading,
  emailTemplate
}: {
  isLoading: boolean;
  emailTemplate: TEmailTemplate;
}) => {
  return (
    <Box sx={{ marginTop: '-10px' }}>
      {isLoading ? (
        <Box
          sx={{
            height: '300px',
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <ReactLoading type="spin" color={theme.palette.primary.main} width={100} height={100} />
        </Box>
      ) : (
        <Box>
          <HtmlEditor
            readonlyEditor={{
              body: emailTemplate.body,
              styling: {
                '& .tox-editor-header, .tox-statusbar': { display: 'none !important' },
                '& .tox-tinymce': { height: '600px !important' }
              }
            }}
          />
          {emailTemplate.email_attachments && (
            <AttachmentPreview templateAttachments={emailTemplate.email_attachments} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default EmailTemplates;
