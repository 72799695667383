import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import SelectedApplicants from '../SelectedApplicants';
import { IExtendedActionModalProps } from '../../types';
import { classes } from '../styles';

export default function ExportFoundU({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  filters
}: IExtendedActionModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const body = { application_ids: globalSelected.map((app) => app.id) }
      if (sendEmail) {
        body.send_welcome_email = '1'
      }

      const response = await fetch(`/api/v4/jobs/${jobId}/export_foundu`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        body: JSON.stringify(body)
      });
      if (response.ok) {
        const json = await response.json()
        const failedCount = json.failed

        setModalsOpen((prev) => ({ ...prev, exportToFoundu: false }));
        setSnackbar({
          message: `Applications exported. ${failedCount} failed`,
          state: failedCount === 0 ? 'success' : 'warning'
        })
      }
    } catch (error) {
      setSnackbar({
        message: 'An error occurred while exporting to FoundU. Please try again.',
        state: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={modalsOpen.exportToFoundu}
      onClose={() => setModalsOpen((prev) => ({ ...prev, exportToFoundu: false }))}
      aria-labelledby="export-foundu-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon
          onClick={() => setModalsOpen((prev) => ({ ...prev, exportToFoundu: false }))}
          sx={classes.closeIcon}
        />
        <Box id="export-foundu-title" sx={classes.actionModalHeader}>
          Export to FoundU
        </Box>
        <Box
          sx={classes.modalContent}
          id="export-foundu-modal-content"
          aria-label="Export Foundu Modal Content"
        >
          <Box sx={{ marginLeft: '15px' }}>
            <h3>Candidates</h3>
            <SelectedApplicants
              globalSelected={globalSelected}
              handleDeselectCandidate={handleDeselectCandidate}
              closeModalCallback={() => setModalsOpen((prev) => ({ ...prev, exportToFoundu: false }))}
              filters={filters}
            />
          </Box>
          <Box sx={{ marginLeft: '15px' }}>
            <Checkbox
              sx={classes.checkbox}
              checked={sendEmail}
              onChange={({ target }) => setSendEmail(target.checked)}
            />
            <span>Send welcome email</span>
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <Button
            id="close-export-foundu-button"
            sx={classes.modalCancelButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, exportToFoundu: false }))}
          >
            Cancel
          </Button>
          <Button
            id="submit-export-foundu-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '128px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSubmitting ? <CircularProgress size={20} color="inherit" /> : 'Export'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
