import * as React from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';
import { classes } from '../styles';
import { SearchState, SearchAction } from '../useReport';

interface Props {
  jobTitleOptions: any;
  userOptions: {
    value: string;
    label: string;
  };
  isLoading: boolean;
  reportState: SearchState;
  reportDispatch: React.Dispatch<SearchAction>;
}

export default function jobTitleField(myProps: Props) {
  const { jobTitleOptions, reportState, isLoading, reportDispatch } = myProps;
  return (
    <Box sx={{ position: 'relative', marginBottom: '4px' }}>
      <Autocomplete
        id="jobTitle"
        multiple
        options={jobTitleOptions}
        loading={isLoading}
        value={reportState.timeToHireFields.jobTitle}
        loadingText={isLoading ? 'Loading job titles...' : 'No job titles'}
        sx={{ ...classes.formAutocomplete, ...classes.formAutocompleteDynamicWidth }}
        ListboxProps={{ style: { ...classes.autoCompleteListStyles } }}
        renderInput={(params) => (
          <TextField {...params} label="Job Title" InputLabelProps={{ shrink: true }} />
        )}
        onChange={(event, value) => {
          reportDispatch({
            type: 'timeToHireFields',
            payload: { jobTitle: value }
          });
        }}
      />
    </Box>
  );
}
