import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, Select as MaterialSelect, MenuItem, useTheme, Input } from "@mui/material";
import { useFormValidator } from "react-form-context-validation";
export default function Select(props) {
  const theme = useTheme();
  const { value, onChange, options, label, style, multiple, placeholder, getOptionLabel, getOptionValue, disabled, valueField, labelField, required = false } = props;
  const getLabel = getOptionLabel || ((option) => labelField ? `${option[labelField]}` : `${option}`);
  const getValue = getOptionValue || ((option) => valueField ? `${option[valueField]}` : `${option}`);
  const error = useFormValidator(required && !value);
  return /* @__PURE__ */ React.createElement(
    FormControl,
    {
      style,
      disabled,
      required,
      error,
      fullWidth: true
    },
    /* @__PURE__ */ React.createElement(InputLabel, null, label),
    /* @__PURE__ */ React.createElement(
      MaterialSelect,
      {
        value: value || "",
        onChange: (evt) => onChange(evt.target.value),
        input: /* @__PURE__ */ React.createElement(
          Input,
          {
            label,
            style: {
              color: value ? theme.palette.text.primary : theme.palette.text.disabled,
              fontFamily: "Source Sans Pro"
            }
          }
        ),
        MenuProps: {
          sx: {
            "& li": {
              fontFamily: "Source Sans Pro"
            }
          }
        },
        multiple,
        displayEmpty: !!placeholder,
        labelId: "TEST"
      },
      placeholder && !value && /* @__PURE__ */ React.createElement(MenuItem, { value: "", disabled: true }, placeholder),
      options.map((opt, i) => /* @__PURE__ */ React.createElement(
        MenuItem,
        {
          key: i,
          value: getValue(opt)
        },
        getLabel(opt)
      ))
    )
  );
}
Select.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string,
  style: PropTypes.object,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  disabled: PropTypes.bool,
  valueField: PropTypes.string,
  labelField: PropTypes.string,
  required: PropTypes.bool
};
