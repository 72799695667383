import React, { Dispatch } from 'react';
import { Stack, Button } from '@mui/material';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { styles } from '../../../Candidate/styles';
import { ApplicationAction, ApplicationState } from '../../types';
import TasksTab from './TasksTab';
import TaskWorkflow from '../../Modals/TaskWorkflow';
import { ModalType } from '../../config';
import AddEditTask from '../../Modals/AddEditTask';

export default function Tasks({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  return (
    <Stack sx={styles.candidateSectionContainer}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Stack sx={{ fontSize: '18px', fontWeight: 600, color: '#333333' }}>Tasks</Stack>
        <Stack sx={{ flexDirection: 'row', columnGap: 2 }}>
          <Button
            id="application-add-task-button"
            sx={sharedClasses.genericButtonSecondary}
            onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ADD_EDIT_TASK })}
          >
            Add task
          </Button>
          <Button
            id="application-start-workflow-button"
            sx={sharedClasses.genericButtonSecondary}
            onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.TASK_WORKFLOW })}
          >
            Start workflow
          </Button>
        </Stack>
      </Stack>
      <TasksTab dispatch={dispatch} />
      {modalsOpen === ModalType.TASK_WORKFLOW && (
        <TaskWorkflow ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.ADD_EDIT_TASK && (
        <AddEditTask ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
    </Stack>
  );
}
