import React, { useCallback } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { classes } from '../styles';
import { EngageState } from '../reducer';

const TabSelector = ({
  engageState,
  handleTabChange,
  tabIndicatorRef
}: {
  engageState: EngageState;
  handleTabChange: (event: React.SyntheticEvent, tabValue: number) => void;
  tabIndicatorRef: React.MutableRefObject<HTMLElement[]>;
}) => {
  const TabProps = useCallback((index: number) => {
    return {
      id: `engage-tab-${index}`,
      'aria-controls': `engage-tabpanel-${index}`
    };
  }, []);

  return (
    <Box sx={classes.tabsContainer}>
      <Tabs
        value={engageState.selectedTab}
        onChange={handleTabChange}
        aria-label="engage tabs"
        sx={{
          ...classes.tabs,
          '& .MuiTabs-indicator': {
            ...classes.tabIndicator,
            maxWidth: engageState.indicatorWidth + 'px'
          }
        }}
      >
        <Tab
          label="AI Search"
          {...TabProps(0)}
          ref={(el) => (tabIndicatorRef.current[0] = el)}
          id="ai-search-tab-button"
        />
        <Tab
          label="Candidate Pools"
          {...TabProps(1)}
          ref={(el) => (tabIndicatorRef.current[1] = el)}
          id="engage-pool-tab"
        />
      </Tabs>
    </Box>
  );
};

export default TabSelector;
