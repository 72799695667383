import React from 'react';
import PropTypes from 'prop-types';

export default class InPlaceInputEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      value: this.props.value
    }

    this.toggleEditing = this.toggleEditing.bind(this)
    this.saveCallback = this.saveCallback.bind(this)
    this.inputCallback = this.inputCallback.bind(this)
  }

  toggleEditing() {
    const { editing } = this.state
    this.setState({editing: !editing})
  }

  saveCallback(event) {
    const { value, editing } = this.state
    const { id } = this.props
    this.toggleEditing();
    this.props.saveCallback(value, id);
  }

  inputCallback(event) {
    this.setState({value: event.target.value})
  }

  renderInput(event) {
    const { saveCallback, defaultLabel} = this.props
    const { editing, value } = this.state
    if (editing) {
      return (<div>
                <input onChange={this.inputCallback} value={value} style={{width: '80%'}}/>
                <a href='#' className='' onClick={this.saveCallback} style={{paddingLeft: '10px'}}><i className='fa fa-check' style={{color: 'green'}}/></a>
                <a href='#' className='' onClick={this.toggleEditing}>
                  <i className='fa fa-close' style={{color: 'red'}}/>
                </a>
              </div>)
    } else {
      return (<div>
                {defaultLabel}
                <a href='#' style={{paddingLeft: '10px'}} onClick={this.toggleEditing}><i className='fa fa-pencil'/></a>
              </div>)
    }
  }

  render() {
    return(
      this.renderInput()
    )
  }
}
