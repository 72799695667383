"use strict";
import { theme } from "../../../../ThemeContext/ThemeObject";
import { alpha } from "@mui/material/styles";
export const classes = {
  buttonRedLight: {
    color: "#E37D7A",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "8px 18px",
    fontWeight: "bold",
    fontSize: "15px",
    textDecoration: "underline",
    backgroundColor: "#ffffff",
    "&:hover": {
      color: "#d74742",
      backgroundColor: "#ffffff"
    }
  },
  revertloadButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 18px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    maxWidth: "800px",
    width: "90%",
    height: "95%",
    maxHeight: "800px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  modalTitle: {
    fontFamily: "Source Sans Pro",
    fontSize: "30px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column"
  },
  calendar: {
    "& .PrivatePickersFadeTransitionGroup-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.action.main} !important`
    },
    "& .PrivatePickersSlideTransition-root": {
      minHeight: "242px !important"
    }
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "20px",
    width: "100%"
  },
  datePicker: {
    margin: "33px 0 0 4px",
    fontFamily: "Source Sans Pro",
    width: "290px",
    color: "#666666",
    "& input": {
      padding: "4px 10px 5px 10px",
      fontFamily: "Source Sans Pro"
    },
    "& fieldset": {
      "& legend": {
        display: "none"
      }
    },
    "& button": {
      padding: "0px",
      transform: "translate(-2px, -1.5px)",
      "&:hover": {
        background: "transparent",
        "& svg": {
          color: "#888888"
        }
      }
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0px"
    },
    "& svg": {
      color: "#AAAAAA"
    }
  },
  noteTextInput: {
    "label + &": {
      marginTop: "24px"
    },
    "& .MuiInputBase-input": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "6px 10px",
      margin: "3px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
    width: "100%",
    columnGap: "16px",
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  }
};
