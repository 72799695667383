import React, { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { Box, Button } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import HomeIcon from '@mui/icons-material/Home';
import DynamicFooter from '../../SharedComponents/Footers/DynamicFooter';
import { classes } from '../styles';
import { modalSteps } from '../types';
import { StyledSnackbarProps } from '../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { IWebsocketData } from '../../SharedComponents/types';
import { convertToLocalEnglish } from '../../../NewUI/Components/Utilities/convertToLocalEnglish';
import {
  arrayQuestion,
  questionStringToArray
} from '../../SharedComponents/Utility/questionStringToArray';
import SelectableCopyableQuestions from '../../SharedComponents/SelectableCopyableQuestions';
import { handleAPIRequest } from '../../../NewUI/Components/Utilities/handleAPIRequest';
import FollowUpEmailModal from './FollowUpEmail/FollowUpEmail';

const convertedQuestionData = (questions: string) => {
  const convertedQUestions = convertToLocalEnglish(questions || '');
  return questionStringToArray(convertedQUestions);
};

const ResponseStep = ({
  setStep,
  requestQuestions,
  questionData,
  isNotification,
  jobId,
  selectedApplicantId,
  setSnackbarState
}: {
  setStep?: Dispatch<SetStateAction<modalSteps>>;
  requestQuestions?: () => void;
  questionData: IWebsocketData | null;
  isNotification?: boolean;
  jobId?: number;
  selectedApplicantId?: number | null;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
}) => {
  const [questions, setQuestions] = useState<arrayQuestion[]>(
    convertedQuestionData(questionData?.content || '')
  );
  const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false);
  const saveSelectedQuestions = useCallback(async () => {
    const sendableQuestions = questions.filter((item) => item.selected).map((item) => item.text);
    setQuestions((prev) =>
      prev.map((item) => (item.selected ? { ...item, status: 'loading' } : item))
    );
    handleAPIRequest({
      url: `/api/v4/jobs/${jobId || questionData?.job_id}/applications/${
        selectedApplicantId || questionData?.application_id
      }/saved_interview_questions/add_questions`,
      method: 'PATCH',
      body: {
        saved_interview_questions: {
          questions: sendableQuestions
        }
      },
      onSuccess: () => {
        setQuestions((prev) =>
          prev.map((item) => (item.selected ? { ...item, status: 'sent', selected: false } : item))
        );
      },
      onFailure: () => {
        setQuestions((prev) =>
          prev.map((item) => (item.selected ? { ...item, status: 'error', selected: false } : item))
        );
      }
    });
  }, [jobId, questionData?.application_id, questionData?.job_id, questions, selectedApplicantId]);

  return (
    <>
      <Box sx={classes.responseHeader}>
        <Box sx={classes.header}>Suggested interview questions</Box>
        {!isNotification && requestQuestions && (
          <Box sx={{ display: 'flex', columnGap: '32px' }}>
            <Button
              sx={{ ...classes.pillButton, width: '154px' }}
              endIcon={<HomeIcon />}
              onClick={() => setStep && setStep('input')}
            >
              Start again
            </Button>
            <Button
              sx={{ ...classes.pillButton, width: '154px' }}
              endIcon={<CachedIcon />}
              onClick={requestQuestions}
            >
              Regenerate
            </Button>
          </Box>
        )}
      </Box>
      <Box sx={classes.applicantNameHeader}>
        <a
          href={`/admin/jobs/${jobId || questionData?.job_id}/applications/${
            selectedApplicantId || questionData?.application_id
          }`}
          target="_blank"
          rel="noreferrer"
        >
          {questionData?.candidate_name}
        </a>
      </Box>
      <SelectableCopyableQuestions
        questions={questions}
        setQuestions={setQuestions}
        maxHeight="450px"
      />
      <DynamicFooter
        primaryButtonText="Save questions"
        primaryButtonID="save-questions-button"
        handlePrimaryButtonClick={saveSelectedQuestions}
        disablePrimaryButton={!questions.some((item) => item.selected)}
        secondaryButtonText="Generate email"
        secondaryButtonID="generate-email-button"
        handleSecondaryButtonClick={() => setEmailModalOpen(true)}
        disableSecondaryButton={!questions.some((item) => item.selected)}
        felixMessage={
          <>
            Here are some follow up questions, you can use them during an <span>interview</span>,{' '}
            <span>email</span> them to your candidate or save them to view later.
          </>
        }
        felixMessageMaxWidth="420px"
        alternateSecondaryButton={true}
      />
      {emailModalOpen && (
        <FollowUpEmailModal
          questions={questions.filter((item) => item.selected).map((item) => item.text)}
          modalOpen={emailModalOpen}
          setModalOpen={setEmailModalOpen}
          jobId={jobId || questionData?.job_id}
          applicationId={selectedApplicantId || questionData?.application_id}
          setSnackbarState={setSnackbarState}
        />
      )}
    </>
  );
};

export default ResponseStep;
