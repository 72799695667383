import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Stack,
  Divider
} from '@mui/material';
import { styles } from '../styles';
import { IAvailabilityTableData } from '../types';

const DayRow = ({
  payrollDay,
  day,
  data,
  allowBreak
}: {
  payrollDay: number;
  day: string;
  data: IAvailabilityTableData;
  allowBreak: boolean;
}) => (
  <TableRow>
    <TableCell sx={styles.availabilityTableBody}>
      <Stack alignItems="center">{payrollDay}</Stack>
    </TableCell>
    <TableCell sx={styles.availabilityTableBody}>
      <Stack alignItems="center">{day}</Stack>
    </TableCell>
    <TableCell sx={styles.availabilityTableBody}>
      <Stack alignItems="center">{data.heading_1 ? data.heading_1 : '-'}</Stack>
    </TableCell>
    <TableCell sx={styles.availabilityTableBody}>
      <Stack alignItems="center">{data.heading_2 ? data.heading_2 : '-'}</Stack>
    </TableCell>
    <TableCell sx={styles.availabilityTableBody}>
      <Stack flexDirection="row" justifyContent="space-around">
        <Stack>{data.from_time}</Stack>
        <Stack>{data.to_time}</Stack>
      </Stack>
    </TableCell>
    {allowBreak && (
      <TableCell sx={styles.availabilityTableBody}>
        <Stack alignItems="center">{data.break_time} mins</Stack>
      </TableCell>
    )}
    <TableCell sx={styles.availabilityTableBody}>
      <Stack alignItems="center">{Math.round(data.total_time * 100) / 100}</Stack>
    </TableCell>
  </TableRow>
);

export default function AvailabilityAnswer({
  availabilityTableData,
  config,
  shift,
  totalTime
}: {
  availabilityTableData: { [key: string]: IAvailabilityTableData };
  config: { heading_1: string; heading_2: string; week_start_day: number; allow_break: boolean };
  shift: number;
  totalTime: number;
}) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={styles.availabilityTableHeader}>
              <Stack alignItems="center">Payroll Day</Stack>
            </TableCell>
            <TableCell sx={styles.availabilityTableHeader}>
              <Stack alignItems="center">Day</Stack>
            </TableCell>
            <TableCell sx={styles.availabilityTableHeader}>
              <Stack alignItems="center">{config.heading_1}</Stack>
            </TableCell>
            <TableCell sx={styles.availabilityTableHeader}>
              <Stack alignItems="center">{config.heading_2}</Stack>
            </TableCell>
            <TableCell sx={styles.availabilityTableHeader}>
              <Stack alignItems="center" height="14px">
                Shift times
              </Stack>
              <Stack flexDirection="row" justifyContent="space-around">
                <Stack fontSize="10px" height="10px">
                  From
                </Stack>
                <Stack fontSize="10px" height="10px">
                  To
                </Stack>
              </Stack>
            </TableCell>
            {config.allow_break && (
              <TableCell sx={styles.availabilityTableHeader}>
                <Stack alignItems="center">Break</Stack>
              </TableCell>
            )}
            <TableCell sx={styles.availabilityTableHeader}>
              <Stack alignItems="center">Paid Hours</Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(availabilityTableData).map((key: string, index) => {
            const data = availabilityTableData[key];
            return (
              <React.Fragment key={index}>
                {index === 7 && (
                  <TableRow>
                    {Array(config.allow_break ? 7 : 6)
                      .fill(0)
                      .map((e, index) => (
                        <TableCell sx={{ padding: '4px 0px', border: 'unset' }} key={index}>
                          <Divider />
                        </TableCell>
                      ))}
                  </TableRow>
                )}
                <DayRow
                  payrollDay={index + 1}
                  day={days[(index + config.week_start_day) % days.length].slice(0, 3)}
                  data={data}
                  allowBreak={config.allow_break}
                />
              </React.Fragment>
            );
          })}
          <TableRow>
            <TableCell sx={styles.availabilityTableBody} colSpan={4}>
              <Stack alignItems="center">TOTAL</Stack>
            </TableCell>
            <TableCell sx={styles.availabilityTableBody} colSpan={config.allow_break ? 2 : 1}>
              <Stack alignItems="center">{shift} Shifts</Stack>
            </TableCell>
            <TableCell sx={styles.availabilityTableBody}>
              <Stack alignItems="center">{totalTime}</Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
