import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, Stack, Typography, Button } from '@mui/material';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import SelectFields from './SelectFields';
import { CustomReportsActionType, CustomReportsStateType, DataType } from '../types';
import { useReportOptions } from '../hooks';
import { ReportContext } from '../../index';
import { DebouncedFormTextField } from '../../../NewUI/Components/CustomUIElements/FormTextField';
import { classes } from './styles';

const CreateEditModal = ({
  reportState,
  dispatch,
  setConfirmCancelIsOpen,
  setSnackbar,
  openCreateReport,
  setOpenCreateReport
}: {
  reportState: CustomReportsStateType;
  dispatch: Dispatch<CustomReportsActionType>;
  setConfirmCancelIsOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbar: Dispatch<
    SetStateAction<{
      message: string;
      state: 'success' | 'warning' | 'error';
    }>
  >;
  openCreateReport: boolean;
  setOpenCreateReport: Dispatch<SetStateAction<boolean>>;
}) => {
  const reportApiService = useContext(ReportContext);
  const [data, setData] = useState<DataType>(reportState.data);
  const [nameError, setNameError] = useState<string>('');
  const { isLoading: loadingCreateOptions } = useReportOptions(
    dispatch,
    setSnackbar,
    reportApiService
  );
  const commonProps = {
    dispatch,
    reportState,
    loadingCreateOptions,
    data,
    setData
  };

  const reportFields = ['Jobs', 'Candidates', 'Applications'];
  // const adminFields = ['Approval Forms', 'Events / Interviews', 'Smart forms'];
  // const reportFields = [...fields, ...(reportApiService.currentUser.admin ? adminFields : [])];

  return (
    <Modal
      open={reportState.open || openCreateReport}
      onClose={() => {
        setConfirmCancelIsOpen(true);
      }}
    >
      <Box sx={{ ...sharedClasses.modalBody, minHeight: 'unset' }}>
        <CloseIcon
          onClick={() => {
            setConfirmCancelIsOpen(true);
          }}
          sx={sharedClasses.closeIcon}
        />
        <Box sx={classes.sectionContentWithNav}>
          {(openCreateReport || reportState.editingTitle) && (
            <Stack flexDirection="row">
              <Stack sx={{ ...classes.leftSideContent, paddingLeft: 5 }}>
                <Typography sx={classes.sectionHeader}>Report Name</Typography>
              </Stack>
              <Stack sx={{ padding: 5, paddingTop: 8, flexGrow: 1, marginRight: '20px' }}>
                <DebouncedFormTextField
                  onDebouncedChange={(value: string) => {
                    setData({ ...data, name: value.trim() });
                  }}
                  id="reportName"
                  label="What is the name of your report"
                  defaultValue={data.name}
                  required={true}
                  error={nameError}
                />
              </Stack>
            </Stack>
          )}
          {(openCreateReport || !reportState.editingTitle) && (
            <SelectFields {...commonProps} fields={reportFields} />
          )}
          <Stack flexDirection="row">
            <Stack sx={classes.leftSideContent} />
            <Stack sx={{ padding: 5, flexGrow: 1 }}>
              <Box sx={classes.modalFooter}>
                <Button
                  id="generate-report-builder-button"
                  variant="contained"
                  sx={sharedClasses.genericButton}
                  onClick={() => {
                    if (!data.name) {
                      setNameError('Please give a name for your report');
                    } else {
                      dispatch({ type: 'SET_CREATE_REPORT_PREVIEW', payload: data });
                      setOpenCreateReport(false);
                    }
                  }}
                >
                  Generate
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateEditModal;
