import React from 'react';
import { SxProps } from '@mui/material';
import { classes } from '../Components/GenericTable/styles';
import { ActionListItem } from '../Components/GenericTable/GenericTableActions';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

export const candidatesTableColumn = [
  {
    id: 'id',
    label: 'ID',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'lastname',
    label: 'Last Name',
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: 'firstname',
    label: 'First Name',
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: 'email',
    label: 'Email Address',
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: 'location',
    label: 'Location',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'applications',
    label: 'Applications',
    sortable: false,
    canToggle: false,
    enabled: true
  }
];

export const headerStyle = (
  name: (typeof candidatesTableColumn)[number]['id'] | 'checkbox' | 'actions',
  tableHovered: boolean
): SxProps => {
  switch (name) {
    case 'id':
      return { ...classes.sticky, maxWidth: '100px', width: '100px' };
    case 'lastname':
      return {
        ...classes.sticky,
        ...classes.itemTitleTableCell,
        ...(tableHovered && classes.scrollShadowVisible),
        left: '100px',
        minWidth: '125px'
      };
    case 'firstname':
      return { minWidth: '125px' };
    case 'email':
      return { minWidth: '240px' };
    case 'location':
      return { minWidth: '150px' };
    case 'applications':
      return { minWidth: '200px' };
    case 'actions':
      return {
        ...classes.actionsColumnHeader,
        ...(tableHovered && classes.scrollShadowVisible)
      };
    default:
      return { minWidth: '90px' };
  }
};

export const candidatesActionsListItem: ActionListItem[] = [
  {
    id: 'edit',
    tooltipLabel: 'Edit',
    icon: <EditIcon />,
    elementId: 'edit-candidate-button'
  },
  {
    id: 'delete',
    tooltipLabel: 'Delete',
    icon: <DeleteIcon sx={{ color: '#E37D7A !important' }} />,
    elementId: 'delete-candidate-button'
  }
];

export const streetTypes = [
  'Alley',
  'Arcade',
  'Avenue',
  'Boulevard',
  'Bypass',
  'Circuit',
  'Close',
  'Corner',
  'Court',
  'Crescent',
  'Cul-de-sac',
  'Drive',
  'Esplanade',
  'Green',
  'Grove',
  'Highway',
  'Junction',
  'Lane',
  'Link',
  'Mews',
  'Parade',
  'Place',
  'Ridge',
  'Road',
  'Square',
  'Street',
  'Terrace'
];
