import React, { Dispatch } from 'react';
import {
  GenericTableState,
  GenericTableAction
} from '../../NewUI/Components/GenericTable/genericTableReducer';
import { TCandidatePool } from '../types';
import { TableCell } from '@mui/material';
import { classes } from '../../NewUI/Components/GenericTable/styles';
import { TagList } from '../../NewUI/Components/CustomUIElements/TagList/TagList';
import FormattedDate from '../../NewUI/Components/GenericTable/TableCells/FormattedDate';
import NotesWithSubjectLine from '../../NewUI/Components/GenericTable/TableCells/NotesWithSubjectLine';

const CandidatePoolsTableRows = ({
  column,
  item,
  tableHovered,
  dispatch
}: {
  column: GenericTableState['tableState']['columns'][number];
  item: TCandidatePool;
  tableHovered: boolean;
  dispatch?: Dispatch<GenericTableAction>;
}) => {
  const candidateCountCell = (item: TCandidatePool) => {
    if (typeof item.candidates_count === 'number') {
      if (typeof item.new_candidates === 'number') {
        return `${item.candidates_count} (${item.new_candidates})`;
      } else {
        return item.candidates_count;
      }
    }
    return '-';
  };
  if (!column.enabled) return null;
  switch (column.id) {
    case 'title': {
      return (
        <TableCell
          key={item.id + column.id}
          sx={{
            ...classes.sticky,
            ...classes.itemTitleTableCell,
            left: '58px',
            ...(tableHovered && classes.scrollShadowVisible),
            cursor: 'pointer'
          }}
          onClick={() => {
            dispatch &&
              dispatch({
                type: 'SELECT_ACTION_ITEM',
                payload: { name: 'candidatePool', item: item, anchorEl: null }
              });
          }}
        >
          {item.title || '-'}
        </TableCell>
      );
    }
    case 'candidates_count':
      return (
        <TableCell
          sx={{ textAlign: 'right', paddingRight: '37px !important' }}
          key={item.id + column.id}
        >
          {candidateCountCell(item)}
        </TableCell>
      );
    case 'pool_tags':
      return (
        <TableCell>{item.pool_tags?.length ? <TagList tags={item.pool_tags} /> : '-'}</TableCell>
      );
    case 'created_at':
      return (
        <TableCell key={item.id + column.id}>
          {item.created_at ? <FormattedDate date={item.created_at} /> : '-'}
        </TableCell>
      );
    case 'last_email_date':
      return (
        <TableCell key={item.id + column.id}>
          {item.last_email_date ? <FormattedDate date={item.last_email_date} /> : '-'}
        </TableCell>
      );
    case 'notes':
      return (
        <TableCell sx={{ maxWidth: '280px' }}>
          {item.notes?.length ? <NotesWithSubjectLine notes={item.notes} /> : '-'}
        </TableCell>
      );
    default:
      return <TableCell></TableCell>;
  }
};

export default CandidatePoolsTableRows;
