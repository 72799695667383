import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DoneIcon from '@mui/icons-material/Done';
import { ResendIcon } from './Applications/CustomIcons';
import Api from './API';
import jobsApi from '../Jobs/API';
import Applications from './Applications/Applications';
import AddTask from './ActionModals/AddTask/AddTasks';
import MoveJob from './ActionModals/MoveJob/MoveJob';
import StartWorkFlow from './ActionModals/StartWorkflow/StartWorkFlow';
import CeridianDayforce from './ActionModals/CeridianDayforce/CeridianDayforce';
import EmailStatus from './EmailStatus/EmailStatus';
import Attachments from './Attachments/Attachments';
import Notes from './Notes/Notes';
import Subcontainer from './Widgets/Subcontainer';
import JobInformation from './Widgets/JobInformation';
import DonutChart from '../Components/Charts/DonutChart';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import LineChart from '../Components/Charts/LineChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ChartWidget from './Graphs/ChartWidget';
import SmartShare from '../../AIStudio/AIStudioSmartShare/SmartShare';
import { IJob, IJobCounts, IStyle, ITabs } from './types';
import { IUserPermissions } from '../Components/sharedTypes';
import { classes } from './styles';
import Revisions from './ActionModals/Revisions/Revisions';
import StyledSnackbar from '../Components/CustomUIElements/StyledSnackbar';
import { resourceDefault } from '../Jobs/config';
import queryString from 'query-string';
import PushPinIcon from '@mui/icons-material/PushPin';
import { formatUserPermissions } from '../Components/Utilities/formatUserPermissions';
import { getPermissions } from '../../shared/permissionHelpers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const BASE_URL = window.location.origin;
const URL_PARAMS = new URL(window.location.href);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export const EntityContext = createContext({
  apply_with_phone: undefined,
  apply_with_mobile: undefined,
  ai_studio_summaries_enabled: false,
  foundu_enabled: false
});

export default function Job({
  apiKey,
  job_id,
  aiStudioEnabled,
  ai_studio_summaries_enabled,
  foundu_enabled,
  foundu_auth_url,
  entity,
  userTimeZone,
  newNoteOnApplicationEnabled,
  newApplicationEnabled
}: {
  apiKey: string;
  job_id: number;
  aiStudioEnabled: boolean;
  ai_studio_summaries_enabled: boolean;
  foundu_enabled: boolean;
  foundu_auth_url?: string;
  entity: object;
  userTimeZone: string;
  newNoteOnApplicationEnabled: boolean;
  newApplicationEnabled: boolean;
}) {
  const getTabNumber = (urlParams: string) => {
    switch (urlParams) {
      case '?applications=true':
        return 1;
      case '?emailstatus=true':
        return 2;
      case '?attachments=true':
        return 3;
      case '?notes=true':
        return 4;
      default:
        return 0;
    }
  };
  const [job, setJob] = useState<IJob | null>(null);
  const [jobCounts, setJobCounts] = useState<IJobCounts | null>(null);
  const [tabValue, setTabValue] = useState(getTabNumber(URL_PARAMS.search));
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [copied, setCopied] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [stateAnchorEl, setStateAnchorEl] = useState<HTMLDivElement | null>(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState<HTMLDivElement | null>(null);
  const [jobLoading, setJobLoading] = useState(true);
  const [unarchiveLoading, setUnarchiveLoading] = useState(false);
  const [cloneLoading, setCloneLoading] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [stateIsSending, setStateIsSending] = useState(false);
  const [statusIsSending, setStatusIsSending] = useState(false);
  const [isResending, setIsResending] = useState(0);
  const [resourses, setResourses] = useState(resourceDefault);
  const [userPermissions, setUserPermissions] = useState<IUserPermissions>({});
  const [isPinning, setIsPinning] = useState(false);
  const [pinnedByCurrentUser, setPinnedByCurrentUser] = useState(null);

  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const headerRefs = useRef<HTMLDivElement[]>([]);
  const jobQuestions = job ? job.questions.filter((question) => question.enabled) : [];

  const TabPanel = useCallback((props: ITabs) => {
    const { children, tabValue, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        id={`jobs-tabpanel-${index}`}
        data-testid={`jobs-tabpanel-${index}`}
        aria-labelledby={`jobs-tab-${index}`}
        {...other}
      >
        {tabValue === index && <>{children}</>}
      </div>
    );
  }, []);

  const TabProps = useCallback((index: number) => {
    return {
      id: `jobs-tab-${index}`,
      'aria-controls': `jobs-tabpanel-${index}`,
      'data-testid': `jobs-tab-${index}`
    };
  }, []);

  const getPageParams = (tabNo: number) => {
    switch (tabNo) {
      case 0:
        return '';
      case 1:
        return '?applications=true';
      case 2:
        return '?emailstatus=true';
      case 3:
        return '?attachments=true';
      case 4:
        return '?notes=true';
      default:
        return '';
    }
  };

  const fetchJobs = useCallback(async () => {
    setJobLoading(true);
    try {
      const jobResponse = await Api.getJob(job_id, { 'X-api-authenticate': apiKey });
      setJob(jobResponse);
      setPinnedByCurrentUser(jobResponse.pinned_by_user);
      const jobCountsResponse = await Api.getJobCounts(job_id, { 'X-api-authenticate': apiKey });
      setJobCounts(jobCountsResponse);
      setJobLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, [job_id, apiKey]);

  const getUserPermissions = useCallback(async () => {
    try {
      const data = await getPermissions(apiKey);
      setUserPermissions(formatUserPermissions(data));
    } catch (error) {
      console.log(error);
    }
  }, [setUserPermissions, apiKey]);

  const handleStateChange = useCallback(
    async (link: string) => {
      setStateAnchorEl(null);
      try {
        setStateIsSending(true);
        const response = await Api.putChangedState(link, { 'X-api-authenticate': apiKey });
        setJob((prev) =>
          prev
            ? { ...prev, job_states: response?.job_states, job_statuses: response?.job_statuses }
            : null
        );
      } catch (error) {
        console.error(error);
      } finally {
        setStateIsSending(false);
      }
    },
    [apiKey]
  );

  const handleStatusChange = useCallback(
    async (link: string) => {
      setStatusAnchorEl(null);
      try {
        setStatusIsSending(true);
        const response = await Api.putChangedStatus(link, { 'X-api-authenticate': apiKey });
        setJob((prev) => (prev ? { ...prev, job_statuses: response?.job_statuses } : null));
      } catch (error) {
        console.error(error);
      } finally {
        setStatusIsSending(false);
      }
    },
    [apiKey]
  );

  const handleStateDropdownOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setStateAnchorEl(event.currentTarget);
    document.addEventListener('wheel', () => handleScrollClose('state'));
  };

  const handleStatusDropdownOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setStatusAnchorEl(event.currentTarget);
    document.addEventListener('wheel', () => handleScrollClose('status'));
  };

  const handleScrollClose = (type: string) => {
    if (type === 'state') {
      setStateAnchorEl(null);
      document.removeEventListener('wheel', () => handleScrollClose('state'));
    } else if (type === 'status') {
      setStatusAnchorEl(null);
      document.removeEventListener('wheel', () => handleScrollClose('status'));
    }
  };

  const displayResendIcon = () => {
    if (isResending === 1) {
      return <CircularProgress size={24} color={'inherit'} />;
    } else if (isResending === 2) {
      return <DoneIcon />;
    } else {
      return <ResendIcon />;
    }
  };

  const handleResend = async () => {
    setIsResending(1);
    try {
      const response = await fetch(`/api/v4/jobs/${job_id}/applications/resend_all_emails`, {
        method: 'POST',
        headers: {
          'x-api-authenticate': apiKey
        }
      });
      if (response.ok) {
        setIsResending(2);
        setSnackbar({
          message: 'Successfully resent all applications to recipient.',
          state: 'success'
        });
      } else {
        setIsResending(0);
        const error = await response.json();
        setSnackbar({
          message: error.errors,
          state: 'error'
        });
      }
    } catch (error) {
      setIsResending(0);
      console.log(error);
      setSnackbar({
        message: 'Something went wrong',
        state: 'error'
      });
    } finally {
      setTimeout(() => {
        setIsResending(0);
      }, 5000);
    }
  };

  useEffect(() => {
    fetchJobs();
    getUserPermissions();
  }, [fetchJobs, getUserPermissions]);

  useEffect(() => {
    setIndicatorWidth(headerRefs.current[tabValue].clientWidth - 32);
  }, [tabValue]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const handleTabChange = (event: React.ChangeEvent, newValue: number) => {
    setTabValue(newValue);
    URL_PARAMS.search = getPageParams(newValue);
    window.history.pushState(null, '', URL_PARAMS.href);
  };

  const cloneJob = async () => {
    setCloneLoading(true);
    const queryParams = `?${queryString.stringify(resourses, {
      arrayFormat: 'bracket',
      encode: false
    })}`;
    try {
      const response = await fetch(`/api/v4/jobs/${job_id}/clone${queryParams}`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          job: {
            reference: job.reference,
            title: job.title,
            expires_at: '',
            location: job.location
          }
        })
      }).then((response) => response.json());
      if (response.job_link) {
        window.location.href = `${BASE_URL}${response.job_link}/edit`;
      }
    } catch (error) {
      setSnackbar({
        message: 'An error occurred while cloning the job',
        state: 'error'
      });
    } finally {
      setCloneLoading(false);
    }
  };

  async function handleUnarchiveJob(ids: Array<number>) {
    setUnarchiveLoading(true);
    try {
      const response = await Api.putUnarchiveJob(
        { 'X-api-authenticate': apiKey },
        { job_ids: ids }
      );
      if (response.res?.job_ids?.failure?.length) {
        const message = 'Unable to unarchive job.';
        setSnackbar({
          message: message,
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        message: 'Something went wrong. Please try again.',
        state: 'error'
      });
    } finally {
      fetchJobs();
    }
  }

  async function pinJob(jobId: number) {
    setIsPinning(true);
    try {
      const response = await jobsApi.putPinJob(jobId, { 'X-api-authenticate': apiKey });
      if (!response.res.errors) {
        setPinnedByCurrentUser(true);
      } else {
        setSnackbar({
          message: response.res.errors,
          state: 'error'
        });
      }
    } catch (err) {
      setSnackbar({
        message: err?.errors,
        state: 'error'
      });
    } finally {
      setIsPinning(false);
    }
  }

  async function unpinJob(jobId: number) {
    setIsPinning(true);
    try {
      const response = await jobsApi.putUnpinJob(jobId, { 'X-api-authenticate': apiKey });
      if (!response.res.errors) {
        setPinnedByCurrentUser(false);
      }
    } catch (err) {
      setSnackbarState({
        message: err?.errors,
        state: 'error'
      });
    } finally {
      setIsPinning(false);
    }
  }

  const handlePin = (event: React.MouseEvent, id: number, pinned_by_user: boolean) => {
    pinned_by_user ? unpinJob(id) : pinJob(id);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Box sx={classes.jobPageContainer}>
        {job?.archived && (
          <Box sx={classes.jobArchivedContainer}>
            <Stack color="error" justifyContent="center" sx={classes.jobArchiveAlert}>
              THIS JOB HAS BEEN ARCHIVED
            </Stack>
            <Button sx={classes.jobUnarchiveButton} onClick={() => handleUnarchiveJob([job.id])}>
              UNARCHIVE
              {unarchiveLoading && (
                <CircularProgress size={24} color={'inherit'} sx={{ marginLeft: 2 }} />
              )}
            </Button>
          </Box>
        )}
        <Box sx={classes.jobPageHeader}>
          {jobLoading ? (
            <Skeleton animation="wave" width={600} height={56} />
          ) : (
            <Box sx={{ color: '#666666' }}>
              <h4 id="reference">{job?.reference}</h4>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  columnGap: '10px'
                }}
              >
                {isPinning ? (
                  <CircularProgress size={24} sx={{ color: '#E6A052' }} />
                ) : (
                  <PushPinIcon
                    onClick={(event) => handlePin(event, job?.id, pinnedByCurrentUser)}
                    sx={
                      pinnedByCurrentUser
                        ? {
                            color: '#E6A052',
                            cursor: 'pointer'
                          }
                        : {
                            color: '#CCCCCC',
                            cursor: 'pointer'
                          }
                    }
                  />
                )}
                <h1 id="title" data-testid="title">
                  {job?.title}
                </h1>
              </Box>
            </Box>
          )}
          <Box sx={classes.actionButtons}>
            {jobLoading ? (
              <Skeleton animation="wave" width={200} height={56} />
            ) : (
              <>
                {userPermissions?.Jobs?.['Create / Edit Jobs'] && (
                  <>
                    <Tooltip title="Edit">
                      <IconButton
                        sx={classes.iconGrey}
                        onClick={() => {
                          window.location.href = `${BASE_URL}${job?.job_link}/edit`;
                        }}
                        id="edit-job-button"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Clone">
                      <IconButton
                        sx={classes.iconGrey}
                        onClick={cloneJob}
                        id="clone-job-button"
                        data-testid="clone-job-button"
                      >
                        {cloneLoading ? (
                          <CircularProgress size={24} color={'inherit'} />
                        ) : (
                          <FileCopyIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {userPermissions?.Integrations?.['Recruit Multiposter (P)'] && (
                  <Tooltip title="Multiposter">
                    <IconButton
                      sx={classes.iconGrey}
                      onClick={() => {
                        window.location.href = `${BASE_URL}${job?.job_link}/job_ads`;
                      }}
                      id="multiposter-job-button"
                    >
                      <ArrowCircleRightIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {userPermissions?.Applications?.['Re-send Application'] && tabValue === 1 && (
                  <Tooltip title="Resend all">
                    <IconButton
                      sx={classes.iconGrey}
                      onClick={handleResend}
                      id="resend-all-job-button"
                    >
                      {displayResendIcon()}
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="More Options">
                  <IconButton
                    sx={classes.iconGrey}
                    onClick={(event) => setActionsAnchorEl(event.currentTarget)}
                    id="more-options-job-button"
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
        <Box sx={classes.linkContainer}>
          {jobLoading ? (
            <Skeleton animation="wave" width={400} height={50} />
          ) : (
            job?.public_url && (
              <>
                <a href={job?.public_url} target="_blank" rel="noopener noreferrer">
                  <Box id="external-link" sx={classes.link}>
                    {job.public_url}
                  </Box>
                </a>
                <CopyToClipboard text={job?.public_url} onCopy={handleCopy}>
                  <Button sx={classes.copyButton} id="copy-link-job-button">
                    {copied ? 'Copied!' : 'Copy'}
                  </Button>
                </CopyToClipboard>
                {aiStudioEnabled && (
                  <SmartShare
                    apiKey={apiKey}
                    jobTitle={job?.title}
                    jobUrl={job?.public_url}
                    jobDescription={job?.description}
                  />
                )}
              </>
            )
          )}
        </Box>
        <Box sx={classes.stateStatusContainer}>
          {jobLoading ? (
            <Skeleton animation="wave" width={200} height={40} />
          ) : (
            <>
              {job?.job_states &&
                (job.job_states[1] && userPermissions?.Jobs?.['Create / Edit Jobs'] ? (
                  <Box
                    id="state-dropdown"
                    data-testid={'state-dropdown'}
                    sx={{
                      ...classes[job.job_states[0].label],
                      ...classes.stateDropdown,
                      cursor: 'pointer'
                    }}
                    onClick={handleStateDropdownOpen}
                  >
                    {stateIsSending ? (
                      <CircularProgress size={18} />
                    ) : (
                      <>
                        {job?.job_states[0]?.label} <KeyboardArrowDownIcon sx={classes.downIcon} />
                      </>
                    )}
                  </Box>
                ) : (
                  <Box sx={{ ...classes[job.job_states[0].label], ...classes.stateDropdown }}>
                    {job?.job_states[0]?.label}
                  </Box>
                ))}
              {job?.job_statuses &&
                (job.job_statuses[1] && userPermissions?.Jobs?.['Create / Edit Jobs'] ? (
                  <Box
                    id="status-job-dropdown"
                    sx={{ ...classes.statusDropdown, cursor: 'pointer' }}
                    onClick={handleStatusDropdownOpen}
                  >
                    {statusIsSending || stateIsSending ? (
                      <CircularProgress size={18} sx={classes.statusLoader} />
                    ) : (
                      <>
                        {job?.job_statuses[0]?.label}
                        <ArrowDropDownIcon sx={classes.statusDownIcon} />
                      </>
                    )}
                  </Box>
                ) : (
                  <Box
                    id="status-dropdown"
                    sx={{
                      ...classes.statusDropdown,
                      width: 'auto',
                      maxWidth: '140xp',
                      '&:hover': {
                        ...(classes.statusDropdown as IStyle)['&:hover' as keyof IStyle],
                        border: '1px solid #E5E5E5',
                        maxWidth: 'unset',
                        minWidth: 'unset'
                      }
                    }}
                  >
                    {statusIsSending || stateIsSending ? (
                      <CircularProgress size={18} sx={classes.statusLoader} />
                    ) : (
                      <>{job?.job_statuses[0]?.label}</>
                    )}
                  </Box>
                ))}
            </>
          )}
        </Box>
        <Box sx={classes.tabsContainer}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Jobs, Archive, Multiposter Tabs"
            sx={{
              ...classes.tabs,
              '& .MuiTabs-indicator': { ...classes.tabIndicator, maxWidth: indicatorWidth + 'px' }
            }}
          >
            <Tab
              ref={(el) => (el ? (headerRefs.current[0] = el) : null)}
              label="Overview"
              {...TabProps(0)}
              id="job-overview-tab"
            />
            <Tab
              ref={(el) => (el ? (headerRefs.current[1] = el) : null)}
              label="Applications"
              id="job-applications-tab"
              icon={
                <Box
                  sx={{
                    ...classes.applicationsTabButton,
                    ...(tabValue !== 1 ? { backgroundColor: '#f6f6f6' } : {})
                  }}
                >
                  {job && `${job.applications_count} (${job.open_applications_count})`}
                </Box>
              }
              iconPosition="end"
              {...TabProps(1)}
            />
            <Tab
              sx={{
                display: userPermissions?.Applications?.['View Email Status']
                  ? 'flex'
                  : 'none !important'
              }}
              ref={(el) => (el ? (headerRefs.current[2] = el) : null)}
              label="Email Status"
              id="job-email-status-tab"
              {...TabProps(2)}
            />
            <Tab
              ref={(el) => (el ? (headerRefs.current[3] = el) : null)}
              label="Attachments"
              id="job-attachments-tab"
              {...TabProps(3)}
            />
            <Tab
              sx={{
                display: userPermissions?.['Job Options']?.['View Job Notes']
                  ? 'flex'
                  : 'none !important'
              }}
              ref={(el) => (el ? (headerRefs.current[4] = el) : null)}
              label="Notes"
              id="job-notes-tab"
              {...TabProps(4)}
            />
          </Tabs>
        </Box>
        <TabPanel tabValue={tabValue} index={0}>
          <Box sx={classes.tabContent}>
            <Box sx={classes.widgetContainer}>
              <Subcontainer
                applications={job?.applications_count}
                openApplications={job?.open_applications_count}
                jobCounts={jobCounts}
                createdAt={job?.created_at}
                publishedAt={job?.published_at}
                expiresAt={job?.expires_at}
                handleTabChange={handleTabChange}
                isLoading={jobLoading}
                timeZone={job?.time_zone ?? userTimeZone}
              />
              <JobInformation
                createdBy={
                  job?.created_by
                    ? job?.created_by?.firstname + ' ' + job?.created_by?.lastname
                    : null
                }
                createdByLink={
                  job?.created_by?.link && job?.created_by?.link !== 'None'
                    ? `${BASE_URL}${job?.created_by?.link}`
                    : null
                }
                entity={job?.entity}
                entityLink={
                  job?.entity_link && job?.entity_link !== 'None'
                    ? `${BASE_URL}${job?.entity_link}`
                    : null
                }
                brand={job?.brand?.name}
                brandLink={
                  job?.brand_link && job?.brand_link !== 'None'
                    ? `${BASE_URL}${job?.brand_link}`
                    : null
                }
                location={job?.location}
                tags={job?.tags}
                assignedUser={
                  job?.assigned_user
                    ? job?.assigned_user?.firstname + ' ' + job?.assigned_user?.lastname
                    : null
                }
                isLoading={jobLoading}
              />
              <ChartWidget
                Chart={LineChart}
                ChartIcon={ShowChartIcon}
                chartTitle={'Applications per day'}
                url={`/api/v4/jobs/${job_id}/daily_applications`}
                apiKey={apiKey}
              />
              <ChartWidget
                Chart={DonutChart}
                ChartIcon={DonutLargeIcon}
                chartTitle={'Applications by source'}
                url={`/api/v4/jobs/${job_id}/sources`}
                apiKey={apiKey}
              />
            </Box>
            <Box sx={classes.iframeContainer}>
              {iframeLoading && (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={600}
                  sx={{ position: 'absolute', top: 0, bottom: 0, zIndex: 2 }}
                />
              )}
              <iframe
                src={`${BASE_URL}${job?.job_link}/preview`}
                title="job-iframe"
                width="100%"
                height="100%"
                frameBorder="0"
                onLoad={() => setIframeLoading(false)}
              ></iframe>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel tabValue={tabValue} index={1}>
          <Box sx={classes.tabContent}>
            <EntityContext.Provider
              value={{
                ...entity,
                ai_studio_summaries_enabled,
                foundu_enabled,
                foundu_auth_url
              }}
            >
              <Applications
                apiKey={apiKey}
                jobId={job_id}
                jobTitle={job?.title}
                jobIsLoading={jobLoading}
                jobQuestions={jobQuestions}
                jobAttachments={job?.candidate_attachments}
                jobSources={job?.sources}
                jobHasPrivacyAcknowledgement={job?.privacy_acknowledgement}
                setJob={setJob}
                userPermissions={userPermissions}
                jobOptions={job?.options}
                newNoteOnApplicationEnabled={newNoteOnApplicationEnabled}
                newApplicationEnabled={newApplicationEnabled}
              />
            </EntityContext.Provider>
          </Box>
        </TabPanel>
        <TabPanel tabValue={tabValue} index={2}>
          <EmailStatus apiKey={apiKey} jobId={job_id} setSnackbarState={setSnackbar} />
        </TabPanel>
        <TabPanel tabValue={tabValue} index={3}>
          <Attachments
            apiKey={apiKey}
            jobId={job_id}
            permissions={{
              delete: userPermissions?.['Job Options']?.['Delete Job Attachments']
            }}
          />
        </TabPanel>
        <TabPanel tabValue={tabValue} index={4}>
          <Notes
            apiKey={apiKey}
            jobId={job_id}
            permissions={{
              create: userPermissions?.['Job Options']?.['Make a Job Note'],
              edit: userPermissions?.['Job Options']?.['Edit Job Note'],
              delete: userPermissions?.['Job Options']?.['Delete Job Note']
            }}
            setSnackbarState={setSnackbar}
          />
        </TabPanel>
        <Popover
          id="actions-menu-popover"
          sx={classes.actionsMenu}
          open={Boolean(actionsAnchorEl)}
          anchorEl={actionsAnchorEl}
          onClose={() => {
            setActionsAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {userPermissions?.['Job Options']?.['Move Jobs'] && (
            <span>
              <MoveJob
                apiKey={apiKey}
                jobId={job_id}
                entity={job?.entity}
                fetchJobs={fetchJobs}
                setSnackbarState={setSnackbar}
                setActionsAnchorEl={setActionsAnchorEl}
              />
            </span>
          )}
          <span>
            <AddTask apiKey={apiKey} jobId={job_id} setActionsAnchorEl={setActionsAnchorEl} />
          </span>
          <span>
            {userPermissions?.['Jobs']?.['Create / Edit Jobs'] && (
              <Revisions
                apiKey={apiKey}
                jobId={job_id}
                fetchJobs={fetchJobs}
                setSnackbar={setSnackbar}
                setActionsAnchorEl={setActionsAnchorEl}
                userTimeZone={userTimeZone}
              />
            )}
          </span>
          <span>
            <StartWorkFlow
              apiKey={apiKey}
              jobId={job_id}
              setSnackbarState={setSnackbar}
              setActionsAnchorEl={setActionsAnchorEl}
            />
          </span>
          <CeridianDayforce
            apiKey={apiKey}
            jobId={job_id}
            setSnackbar={setSnackbar}
            setActionsAnchorEl={setActionsAnchorEl}
          />
        </Popover>
        <Popover
          id="state-selection"
          sx={classes.stateOptions}
          open={Boolean(stateAnchorEl)}
          anchorEl={stateAnchorEl}
          onClose={() => setStateAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          {job?.job_states &&
            job.job_states.slice(1).map((state: IJob['job_states'][0]) => (
              <span
                className="state-option"
                data-testid={`state-option-${state.label}`}
                key={state.label}
                onClick={() => handleStateChange(state.link)}
              >
                {state.label}
              </span>
            ))}
        </Popover>
        <Popover
          id="status-selection"
          sx={classes.statusDropdownItems}
          open={Boolean(statusAnchorEl)}
          anchorEl={statusAnchorEl}
          onClose={() => setStatusAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          {job?.job_statuses &&
            job.job_statuses.slice(1).map((status: IJob['job_statuses'][0]) => (
              <Box
                className="status-option"
                key={`${status.link}`}
                sx={classes.statusDropdownItem}
                onClick={() => handleStatusChange(status.link)}
              >
                {status.label}
              </Box>
            ))}
        </Popover>
        <StyledSnackbar
          message={snackbar.message}
          state={snackbar.state}
          setSnackbarState={setSnackbar}
        />
      </Box>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
