import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { classes } from '../styles';

const ExportButton = forwardRef<HTMLElement, { onClick: () => void; buttonId?: string }>(
  ({ onClick, buttonId, ...props }, ref) => {
    return (
      <Box
        id={buttonId}
        sx={classes.floatingActionButton}
        onClick={() => onClick()}
        ref={ref}
        {...props}
      >
        <IosShareIcon />
      </Box>
    );
  }
);
ExportButton.displayName = 'ExportButton';
export default ExportButton;
