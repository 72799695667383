import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Candidates from './Candidates';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function CandidatesPage({ isAdminUser }: { isAdminUser: boolean }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Candidates isAdminUser={isAdminUser} />
    </QueryClientProvider>
  );
}
