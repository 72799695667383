"use strict";
import { LIST_ITEMS_REGEX } from "../sharedConfig";
export const questionStringToArray = (questionString) => {
  if (questionString.split("**").length > 1) {
    return questionString.replace(LIST_ITEMS_REGEX, "").replace(/\n/g, "").split("**").slice(0, -1).map((item, index) => ({
      id: index,
      text: item,
      copied: false,
      selected: false,
      status: "notSent"
    }));
  } else {
    return [
      {
        id: 0,
        text: questionString,
        copied: false,
        selected: false,
        status: "notSent"
      }
    ];
  }
};
export const formatQuestionArray = (questionArray) => {
  return questionArray.map((item, index) => ({
    id: index,
    text: item,
    copied: false,
    selected: false,
    status: "notSent"
  }));
};
