import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function CeridianIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Layer_1-2" transform="translate(7 4)">
        <g id="Group_423" data-name="Group 423" transform="translate(0)">
          <path
            id="Path_235"
            data-name="Path 235"
            d="M11.715,12.739l.8,1.179c-.271.178-.528.363-.8.523a7.24,7.24,0,0,1-2.787.985,7.636,7.636,0,0,1-7.193-2.8A7.286,7.286,0,0,1,.11,9.04,7.78,7.78,0,0,1,4.061.913,7.374,7.374,0,0,1,6.572.065c.223-.033.45-.044.675-.065H8.3A7.435,7.435,0,0,1,10.2.354,7.836,7.836,0,0,1,12.4,1.5c.043.032.085.066.145.113L11.73,2.782a.4.4,0,0,0-.074-.087,10.132,10.132,0,0,0-.852-.562,6.429,6.429,0,0,0-2-.676A6.409,6.409,0,0,0,3.385,3.117a6.253,6.253,0,0,0-1.112,1.4,6.374,6.374,0,0,0-.863,2.812A5.9,5.9,0,0,0,1.54,9.072,6.439,6.439,0,0,0,2.13,10.7a6.244,6.244,0,0,0,1.387,1.782,6.807,6.807,0,0,0,1.656,1.084,6.087,6.087,0,0,0,2.114.537,6.2,6.2,0,0,0,2.261-.235,6.268,6.268,0,0,0,2.167-1.127Z"
            transform="translate(0)"
            fill="#606060"
          />
          <path
            id="Path_236"
            data-name="Path 236"
            d="M25.576,26.35a6.276,6.276,0,0,1-2.167,1.127,6.2,6.2,0,0,1-2.261.235,6.087,6.087,0,0,1-2.114-.537,6.825,6.825,0,0,1-1.656-1.084,6.215,6.215,0,0,1-1.387-1.782,6.426,6.426,0,0,1-.59-1.627,5.934,5.934,0,0,1-.129-1.741,6.377,6.377,0,0,1,.863-2.812,6.253,6.253,0,0,1,1.112-1.4,6.412,6.412,0,0,1,7.419-.983,10.132,10.132,0,0,1,.852.562.432.432,0,0,1,.074.087l0,0s-.013,0-.013,0a.178.178,0,0,0,0,.022l-.76,1.129a5,5,0,0,0-2.754-1.137c.075-.066,0-.048-.029-.048-.272,0-.544,0-.816,0-.036,0-.07.033-.105.05a5.13,5.13,0,0,0-1.595.455A4.884,4.884,0,0,0,17,19.6a4.958,4.958,0,0,0,2.46,6.217,4.734,4.734,0,0,0,1.724.478,3.54,3.54,0,0,0,.361.051,5.232,5.232,0,0,0,3.147-1.08c.031-.025.063-.047.105-.079l.779,1.165Z"
            transform="translate(-13.861 -13.611)"
            fill="#828588"
          />
          <path
            id="Path_237"
            data-name="Path 237"
            d="M79.28,30.079c.035-.017.07-.049.105-.05.272,0,.544,0,.816,0,.029,0,.1-.018.029.048Z"
            transform="translate(-72.027 -27.278)"
            fill="#3b6fa9"
          />
          <path
            id="Path_238"
            data-name="Path 238"
            d="M80.265,0h0Z"
            transform="translate(-71.964)"
            fill="#4a6a98"
          />
          <path
            id="Path_239"
            data-name="Path 239"
            d="M128.06,30.406a.177.177,0,0,1,0-.022s.008,0,.013,0A.027.027,0,0,1,128.06,30.406Z"
            transform="translate(-116.343 -27.601)"
            fill="#3167d9"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ExternalEmailIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M14,2H6C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M12.54,19.37V17.37H8.54V15.38H12.54V13.38L15.54,16.38L12.54,19.37M13,9V3.5L18.5,9H13Z" />
    </SvgIcon>
  );
}

export function ResendIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M13 19C13 15.69 15.69 13 19 13C20.1 13 21.12 13.3 22 13.81V6C22 4.89 21.1 4 20 4H4C2.89 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H13.09C13.04 19.67 13 19.34 13 19M4 8V6L12 11L20 6V8L12 13L4 8M20 22V20H16V18H20V16L23 19L20 22Z"
      />
    </SvgIcon>
  );
}

export function PDFIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9.5 11.5C9.5 12.3 8.8 13 8 13H7V15H5.5V9H8C8.8 9 9.5 9.7 9.5 10.5V11.5M14.5 13.5C14.5 14.3 13.8 15 13 15H10.5V9H13C13.8 9 14.5 9.7 14.5 10.5V13.5M18.5 10.5H17V11.5H18.5V13H17V15H15.5V9H18.5V10.5M12 10.5H13V13.5H12V10.5M7 10.5H8V11.5H7V10.5Z"
      />
    </SvgIcon>
  );
}
