import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SampleList from './Sample/SampleList';
import SmartForms from './SmartForms';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function SmartFormsPage({ entityId }: { entityId: number }) {
  return (
    <QueryClientProvider client={queryClient}>
      <SmartForms entityId={entityId} />
      {/* <SampleList apiKey={apiKey} /> */}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
