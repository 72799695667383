"use strict";
import { useState } from "react";
import { newBuildBody } from "./helper";
import { useQuery } from "@tanstack/react-query";
export const useReportingData = (tableState, dispatch, apiCall, queryKey) => {
  const [hasData, setHasData] = useState(true);
  const { isLoading, refetch } = useQuery({
    queryKey: [
      queryKey,
      {
        page: tableState.currentPage,
        limit: tableState.limit,
        sort: tableState.sort_by,
        sort_order: tableState.sort_order
      }
    ],
    queryFn: () => apiCall(newBuildBody(tableState, false)),
    onSuccess: (data) => {
      const { records, pagination, filter_options, errors } = data;
      if (errors) throw new Error(errors);
      if ((pagination == null ? void 0 : pagination.total_count) === 0 && tableState.searchItem === "") setHasData(false);
      else setHasData(true);
      dispatch({
        type: "LOAD_PAGE",
        payload: {
          records,
          maxPages: pagination.total_pages,
          maxRecords: pagination.total_count,
          currentPage: pagination.current_page,
          limit: pagination.limit,
          filterOptions: filter_options
        }
      });
    },
    onError: () => dispatch({
      type: "SET_SNACKBAR",
      payload: {
        message: "There was an error fetching the report data",
        state: "error"
      }
    })
  });
  return { hasData, refetch, isLoading };
};
