"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { classes as rootClass } from "../styles";
export const classes = __spreadValues({
  formContent: {
    "& > div": {
      width: "100%",
      marginTop: "2rem"
    }
  },
  sectionHeader: {
    fontFamily: "Source Sans Pro",
    fontWeight: "700",
    fontSize: "20px",
    color: "#666666",
    marginBottom: "1.3rem"
  },
  sendToInput: {
    marginTop: "1.5rem",
    "& div": {
      width: "100%"
    },
    "& span": {
      fontStyle: "italic",
      fontSize: "12px",
      color: "#666666"
    }
  }
}, rootClass);
