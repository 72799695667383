"use strict";
import { theme } from "../../../../ThemeContext/ThemeObject";
export const classes = {
  revertloadButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 18px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    maxWidth: "800px",
    width: "90%",
    height: "95%",
    maxHeight: "650px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  modalTitle: {
    fontFamily: "Source Sans Pro",
    fontSize: "30px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column"
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
    width: "100%",
    columnGap: "16px",
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  tableContainer: {
    marginTop: "20px",
    maxHeight: "60%",
    overflow: "scroll"
  },
  table: {
    "& th": {
      border: "none",
      backgroundColor: "#FFFFFF",
      whiteSpace: "nowrap"
    },
    "& td": {
      border: "none",
      whiteSpace: "nowrap"
    },
    "& thead th": {
      fontFamily: "Source Sans Pro",
      fontSize: "15px",
      fontWeight: "600",
      color: theme.palette.primary.main,
      textTransform: "none"
    }
  },
  sticky: {
    position: "sticky",
    left: "0px",
    background: "white"
  },
  stickyRight: {
    position: "sticky",
    right: "0px",
    background: "white"
  },
  clickable: {
    textDecoration: "underline",
    cursor: "pointer"
  },
  noCandidateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#999999",
    textAlign: "center",
    lineHeight: "28px"
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  filterContainer: {
    width: "100%",
    marginTop: "36px"
  },
  filterMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "11px",
      minWidth: "166px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#939393",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  filterButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "600",
    textTransform: "none",
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAEEF0",
    borderRadius: "6px",
    padding: "8px 18px",
    marginLeft: "12px"
  }
};
