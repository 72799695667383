import React, { Dispatch, SetStateAction } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { classes } from '../styles';

const ToggleButtons = ({
  value,
  setValue,
  options
}: {
  value: string;
  setValue: (value: string) => void | Dispatch<SetStateAction<string>>;
  options: {
    value: string;
    label: string;
  }[];
}) => {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, value) => {
        if (value === null) return;
        setValue(value);
      }}
    >
      {options.map((button, index) => (
        <ToggleButton value={button.value} sx={classes.toggleButton} key={index}>
          {button.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;
