import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { classes } from './styles';
import { LIST_ITEMS_REGEX } from './sharedConfig';

const CopyableQuestions = ({
  questions,
  numberOfQuestions,
  maxHeight
}: {
  questions: string | undefined;
  numberOfQuestions?: number;
  maxHeight?: string;
}) => {
  // create an array of booleans to track which questions have been copied
  const arrayOfBooleans = Array.from(Array(numberOfQuestions || 5), () => false);
  const [copiedQuestion, setCopiedQuestion] = useState(arrayOfBooleans);

  const handleCopyQuestion = (index: number) => {
    setCopiedQuestion((prev) => prev.map((item, i) => (i === index ? true : item)));
    setTimeout(
      () => setCopiedQuestion((prev) => prev.map((item, i) => (i === index ? false : item))),
      3000
    );
  };

  return (
    <Box sx={{ ...classes.questionsContainer, ...(maxHeight && { maxHeight }) }}>
      {questions &&
        questions
          .replace(LIST_ITEMS_REGEX, '')
          .split('**')
          .slice(0, -1)
          .map((item, index) => (
            <Box sx={classes.question} key={index}>
              <Box sx={{ maxWidth: '700px' }}>{item}</Box>
              <CopyToClipboard text={item} onCopy={() => handleCopyQuestion(index)}>
                <Button
                  className="copy-question-button"
                  sx={classes.copyButton}
                  endIcon={<CodeIcon />}
                >
                  {copiedQuestion[Number(index)] ? 'Copied!' : 'Copy'}
                </Button>
              </CopyToClipboard>
            </Box>
          ))}
    </Box>
  );
};

export default CopyableQuestions;
