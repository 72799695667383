"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
export const initialSmartShareState = {
  open: false,
  buttonHovered: false,
  currentStep: 0,
  generationTone: "excited",
  useCustomDescription: "description",
  includeHashtags: false,
  includeKeyPoints: false,
  summaryLength: "medium",
  generationJobDescription: "",
  generationJobTitle: "",
  adType: "dot points",
  generatedResponse: "",
  generatedKeyPoints: [],
  copied: false,
  confirmCancelIsOpen: false,
  generationType: "social",
  generationError: false
};
export const smartShareReducer = (state, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return __spreadProps(__spreadValues({}, state), { open: true, generationJobTitle: action.payload });
    case "CLOSE_MODAL":
      return initialSmartShareState;
    case "SET_CONFIRM_CANCEL_IS_OPEN":
      return __spreadProps(__spreadValues({}, state), { confirmCancelIsOpen: action.payload });
    case "SET_COPIED":
      return __spreadProps(__spreadValues({}, state), { copied: action.payload });
    case "SET_CURRENT_STEP":
      return __spreadProps(__spreadValues({}, state), { currentStep: action.payload });
    case "SET_GENERATED_RESPONSE":
      return __spreadProps(__spreadValues({}, state), { generatedResponse: action.payload });
    case "SET_GENERATED_KEY_POINTS":
      return __spreadProps(__spreadValues({}, state), { generatedKeyPoints: action.payload });
    case "SET_GENERATION_ERROR":
      return __spreadProps(__spreadValues({}, state), { generationError: action.payload });
    case "SET_BUTTON_HOVERED":
      return __spreadProps(__spreadValues({}, state), { buttonHovered: action.payload });
    case "SELECT_SOCIAL":
      return __spreadProps(__spreadValues({}, state), { generationType: "social", generationTone: "excited", currentStep: 1 });
    case "SELECT_JOB_AD":
      return __spreadProps(__spreadValues({}, state), {
        generationType: "advertisement",
        generationTone: "excited",
        currentStep: 4
      });
    case "SET_USE_CUSTOM_DESCRIPTION":
      return __spreadProps(__spreadValues({}, state), { useCustomDescription: action.payload });
    case "SET_GENERATION_JOB_DESCRIPTION":
      return __spreadProps(__spreadValues({}, state), { generationJobDescription: action.payload });
    case "SET_GENERATION_JOB_TITLE":
      return __spreadProps(__spreadValues({}, state), { generationJobTitle: action.payload });
    case "SET_GENERATION_TONE":
      return __spreadProps(__spreadValues({}, state), { generationTone: action.payload });
    case "SET_SUMMARY_LENGTH":
      return __spreadProps(__spreadValues({}, state), { summaryLength: action.payload });
    case "SET_INCLUDE_HASHTAGS":
      return __spreadProps(__spreadValues({}, state), { includeHashtags: action.payload });
    case "SET_INCLUDE_KEY_POINTS":
      return __spreadProps(__spreadValues({}, state), { includeKeyPoints: action.payload });
    case "SET_AD_TYPE":
      return __spreadProps(__spreadValues({}, state), { adType: action.payload });
    default:
      return state;
  }
};
