import React, { useState, useCallback, useEffect, SetStateAction, Dispatch } from 'react';
import Box from '@mui/material/Box';
import ApprovalPublicLinksTable from './ApprovalPublicLinksTable';
import Api from '../API';
import { IGetApprovalPublicLinks, TSetUserPreferences } from '../ApprovalTemplates/types';
import { styles } from '../styles';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import { IUserPermissions } from '../../Components/sharedTypes';

export default function ApprovalPublicLinks({
  apiKey,
  userPermissions,
  isPublicLinkDialogOpen,
  setIsPublicLinkDialogOpen
}: {
  apiKey: string;
  userPermissions: IUserPermissions | undefined;
  isPublicLinkDialogOpen: boolean;
  setIsPublicLinkDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [approvalPublicLinks, setApprovalPublicLinks] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [selected, setSelected] = useState<number[]>([]);
  const [globalSelected, setGlobalSelected] = useState([]);
  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
  const [selectAllIsIndeterminate, setSelectAllIsIndeterminate] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalApprovalPublicLinks, setTotalApprovalPublicLinks] = useState(0);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [density, setDensity] = useState<string>('Default');

  const getFirstApprovalPublicLinksAndPreferences = useCallback(async () => {
    setIsLoading(true);
    try {
      const preferenceData = await fetch(`/api/v4/ui_preferences?source=approval_public_links`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        cache: 'no-store'
      }).then((res) => res.json());
      const densityData = await fetch(`/api/v4/ui_preferences?source=universal`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        cache: 'no-store'
      }).then((res) => res.json());
      const preference = preferenceData?.ui_preferences[0];
      const densityPreference = densityData?.ui_preferences[0];
      preference?.sorting?.sortBy && setSortBy(preference.sorting.sortBy);
      preference?.sorting?.sortOrder && setSortOrder(preference.sorting.sortOrder.toLowerCase());
      const sessionPage = sessionStorage.getItem(`approvalPublicLinksTablePage`);
      sessionPage && setCurrentPage(Number(sessionPage));
      preference?.row_count && setRowsPerPage(preference.row_count);
      densityPreference?.columns?.density && setDensity(densityPreference?.columns?.density);

      const response = await Api.getApprovalPublicLinks(
        { 'X-api-authenticate': apiKey },
        {
          page: sessionPage ? Number(sessionPage) : 1,
          limit: preference?.row_count || rowsPerPage,
          sort: preference?.sorting?.sortBy || 'updated_at',
          sort_order: (preference?.sorting?.sortOrder?.toLowerCase() || 'desc').toUpperCase()
        }
      );
      setApprovalPublicLinks(response.res.requisition_public_links);
      setTotalPages(parseInt(response.resHead['x-total-pages']));
      setTotalApprovalPublicLinks(parseInt(response.resHead['x-total-count']));
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting approval public links',
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiKey]);

  const setUserPreferences: TSetUserPreferences = async (
    limit,
    sort,
    columns,
    newFilters,
    density
  ) => {
    if (density) {
      try {
        await fetch(`/api/v4/ui_preferences`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-authenticate': apiKey
          },
          body: JSON.stringify({
            ui_preference: {
              columns: { density: density },
              source: `universal`
            }
          })
        });
      } catch (error) {
        setSnackbar({
          message: `There was an error saving your preferences, ${error}`,
          state: 'error'
        });
      }
    } else {
      try {
        await fetch(`/api/v4/ui_preferences`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-authenticate': apiKey
          },
          body: JSON.stringify({
            ui_preference: {
              row_count: limit || rowsPerPage,
              sorting: sort,
              source: `approval_public_links`
            }
          })
        });
      } catch (error) {
        setSnackbar({
          message: 'There was an error saving your preferences',
          state: 'error'
        });
      }
    }
  };

  const getApprovalPublicLinks: IGetApprovalPublicLinks = async (page, limit, sort) => {
    setIsLoading(true);
    try {
      const response = await Api.getApprovalPublicLinks(
        { 'X-api-authenticate': apiKey },
        {
          page: page || currentPage,
          limit: limit || rowsPerPage,
          sort: sort?.sortBy || sortBy,
          sort_order: (sort?.sortOrder || sortOrder).toUpperCase()
        }
      );
      setApprovalPublicLinks(response.res.requisition_public_links);
      setTotalPages(parseInt(response.resHead['x-total-pages']));
      setTotalApprovalPublicLinks(parseInt(response.resHead['x-total-count']));
      setSelectAllIsChecked(
        response.res.requisition_public_links
          .map((requisitionPublicLink) => requisitionPublicLink.created_by)
          .every((id: number) =>
            globalSelected
              .map((requisitionPublicLink) => requisitionPublicLink.created_by)
              .includes(id)
          )
      );
      setSelectAllIsIndeterminate(
        response.res.requisition_public_links
          .map((requisitionPublicLink) => requisitionPublicLink.created_by)
          .some((id: number) =>
            globalSelected
              .map((requisitionPublicLink) => requisitionPublicLink.created_by)
              .includes(id)
          )
      );
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting approval templates',
        state: 'error'
      });
    } finally {
      if (sort) {
        setUserPreferences(null, sort, null, null, null);
      } else if (limit) {
        setUserPreferences(limit, null, null, null, null);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFirstApprovalPublicLinksAndPreferences();
  }, [apiKey, getFirstApprovalPublicLinksAndPreferences]);

  return (
    <Box>
      <ApprovalPublicLinksTable
        approvalPublicLinks={approvalPublicLinks}
        setApprovalPublicLinks={setApprovalPublicLinks}
        getApprovalPublicLinks={getApprovalPublicLinks}
        globalSelected={globalSelected}
        setGlobalSelected={setGlobalSelected}
        selectAllIsChecked={selectAllIsChecked}
        setSelectAllIsChecked={setSelectAllIsChecked}
        selectAllIsIndeterminate={selectAllIsIndeterminate}
        setSelectAllIsIndeterminate={setSelectAllIsIndeterminate}
        setSnackbarState={setSnackbar}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        selected={selected}
        setSelected={setSelected}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalPages={totalPages}
        totalApprovalPublicLinks={totalApprovalPublicLinks}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        apiKey={apiKey}
        userPermissions={userPermissions}
        density={density}
        isPublicLinkDialogOpen={isPublicLinkDialogOpen}
        setIsPublicLinkDialogOpen={setIsPublicLinkDialogOpen}
      />
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
