import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { defaultInclusions } from '../config';
import { classes } from '../styles';
import LoadingResponse from '../../SharedComponents/Steps/LoadingResponse';
import InputStep from './InputStep';
import { IJobApplication } from '../../../NewUI/Job/types';
import ResponseStep from './ResponseStep';
import ErrorScreen from '../../SharedComponents/Steps/ErrorScreen';
import { modalSteps } from '../types';
import { IWebsocketData } from '../../SharedComponents/types';
import { useWebSockets } from '../../../NewUI/Components/Utilities/useWebSockets';
import { dismissNotification } from '../../SharedComponents/Utility/dismissNotification';
import { StyledSnackbarProps } from '../../../NewUI/Components/CustomUIElements/StyledSnackbar';

const InsightsModal = ({
  jobId,
  open,
  handleClose,
  selectedApplicants,
  handleDeselectApplicant,
  setSnackbar
}: {
  jobId: number;
  open: boolean;
  handleClose: () => void;
  selectedApplicants: IJobApplication[];
  handleDeselectApplicant: (applicantId: number) => void;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
}) => {
  const [step, setStep] = useState<modalSteps>('input');
  const [selectedFormat, setSelectedFormat] = useState('Paragraphs');
  const [inclusions, setInclusions] = useState(defaultInclusions);
  const [emails, setEmails] = useState<string[]>([]);
  const [summaryData, setSummaryData] = useState<IWebsocketData | null>(null);

  const { subscribeToChannel, unsubscribeFromChannel, socketConnected } = useWebSockets(
    'AiStudioChannel',
    (data: IWebsocketData, UID?: number) => {
      if (data.UID !== UID) return;
      if (data.kind === 'error') {
        setStep('error');
      } else {
        setSummaryData(data);
        setStep('response');
      }
      unsubscribeFromChannel();
    }
  );

  const requestInsights = async () => {
    setStep('loading');
    const UID = Date.now();
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/application_summaries`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          application_summary: {
            format: selectedFormat,
            information_include: Object.keys(inclusions).filter(
              (key) => inclusions[key as keyof typeof inclusions].value
            ),
            emails,
            application_ids: selectedApplicants.map((applicant) => applicant.id),
            UID: UID
          }
        })
      });
      await response.json();
      if (response.ok) {
        subscribeToChannel(UID);
      } else {
        throw new Error(`API Request failed with status ${response.status}`);
      }
    } catch (error) {
      setStep('error');
    }
  };

  const handleDeselect = (applicantId: number) => {
    if (selectedApplicants.length === 1) {
      handleDeselectApplicant(applicantId);
      onClose();
    } else {
      handleDeselectApplicant(applicantId);
    }
  };

  const onClose = () => {
    if (step === 'response' && summaryData) {
      dismissNotification(summaryData.id);
    }
    setStep('input');
    setSelectedFormat('Paragraphs');
    setInclusions(defaultInclusions);
    setEmails([]);
    setSummaryData(null);
    unsubscribeFromChannel();
    handleClose();
  };

  const renderStep = (step: modalSteps) => {
    switch (step) {
      case 'input':
        return (
          <InputStep
            selectedFormat={selectedFormat}
            setSelectedFormat={setSelectedFormat}
            inclusions={inclusions}
            setInclusions={setInclusions}
            selectedApplicants={selectedApplicants}
            handleDeselectApplicant={handleDeselect}
            requestInsights={requestInsights}
          />
        );
      case 'loading':
        return <LoadingResponse generationType="summary" showSocketMessage={socketConnected} />;
      case 'response':
        return (
          <ResponseStep
            jobId={jobId}
            summaryData={summaryData}
            setStep={setStep}
            requestInsights={requestInsights}
            emails={emails}
            setEmails={setEmails}
            selectedApplicants={selectedApplicants}
            setSnackbar={setSnackbar}
          />
        );
      case 'error':
        return (
          <ErrorScreen regenerateCallback={requestInsights} backToHome={() => setStep('input')} />
        );
      default:
        return <div>Something went wrong</div>;
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="ai-insights-modal">
      <Box sx={classes.modal}>
        <Box sx={{ ...classes.aiModalContent, ...(step === 'loading' && { marginTop: '0px' }) }}>
          <CloseIcon onClick={onClose} sx={classes.closeIcon} />
          {renderStep(step)}
        </Box>
      </Box>
    </Modal>
  );
};

export default InsightsModal;
