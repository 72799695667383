import React, { useState, useEffect } from 'react';
import useStyles from './useStyles';
import { withScoutTheme } from '../../../../../ThemeContext/ThemeContext';
import { ITemplate } from '../../../../types';
import { useTheme } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import ReactLoading from 'react-loading';
import Api from '../../../../API';

interface IProps {
  templates: ITemplate[],
  setTemplates: (template: ITemplate[]) => void,
  selectedTemplate: ITemplate,
  setSelectedTemplate: (template: ITemplate) => void,
  editedSubject: string,
  setEditedSubject: (subject: string) => void,
  editedBody: string,
  setEditedBody: (body: string) => void,
}

function Step3({ templates, setTemplates, selectedTemplate, setSelectedTemplate, editedSubject, setEditedSubject, editedBody, setEditedBody }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editorReadyState, setEditorReadyState] = useState(null);

  const classes = useStyles();
  const theme = useTheme();

  const handleDraftValue = (e) => {
    const contentBlock = htmlToDraft(e);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorReadyState(editorState);
    }
  }

  const handleTemplateChange = (template: ITemplate) => {
    setSelectedTemplate(template);
  
    setEditedBody(template.body);
    setEditedSubject(template.subject);

    handleDraftValue(template.body);
  }

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const { email_templates } = await Api.getCalendlyEmailTemplates();
        setTemplates(email_templates);
        setSelectedTemplate(email_templates[0]);
        setEditedSubject(email_templates[0].subject);
        setEditedBody(email_templates[0].body);
        handleDraftValue(editedBody || email_templates[0].body);
      } catch(e) {
        console.log(e)
      } finally {
        setIsLoading(false);
      }
    }
    if (!templates.length) {
      getData();
    } else {
      !selectedTemplate && setSelectedTemplate(templates[0]);
      handleDraftValue(editedBody);
      setIsLoading(false)
    }
  }, [])

  return (
    !isLoading
    ? <div className={classes.container}>
        <div>
          <h4 id="step-3-header">Select template</h4>
          { !templates.length
              ? <div className={classes.noTemplates}>
                  Could not find any templates with the <span>{`{CALENDLY_URL}`}</span> placeholder in the body.
                  Please go to your entity settings and create a template with this placeholder in order for it to appear here.
                </div>
              : <div className={classes.templateContainer} id="template-container">
                  { templates.map(template =>
                      <div
                        key={template.id}
                        className={selectedTemplate?.id === template.id ? classes.selected : ''}
                        onClick={() => handleTemplateChange(template)}
                      >
                        { template.name }
                      </div>
                    )}
                </div>
              
            }
        </div>
        <div className={classes.templateView}>
          { selectedTemplate
            ? <>
                <TextField
                  label='Subject'
                  variant="outlined"
                  size="small"
                  value={editedSubject}
                  onChange={e => setEditedSubject(e.target.value)}
                />
                <label className={classes.formLabelWrapper}>Body</label>
                <Editor
                  editorState={editorReadyState}
                  wrapperClassName={classes.textEditor}
                  toolbarClassName={classes.textEditorToolbar}
                  editorClassName={classes.textEditorBody}
                  onEditorStateChange={(e) => { 
                    const value = convertToRaw(e.getCurrentContent());
                    if (value.blocks && value.blocks.length === 1 && value.blocks[0].text === '') {
                      setEditedBody('');
                    } else {
                      setEditedBody(draftToHtml((value)));
                    }
                    setEditorReadyState(e);
                  }}
                  toolbar={{
                    options: ['inline', 'list', 'textAlign'],
                    inline: {
                      inDropdown: false,
                      options: ['bold', 'italic', 'underline'],
                    },
                  }}
                />
              </>
            : <div className={classes.noPreview}>No preview available</div>
          }
        </div>
      </div>
    : <div className={classes.loading}>
        <ReactLoading type="spin" color={theme.palette.primary.main} width={45} height={45} />
      </div>
  )
}

export default withScoutTheme(Step3)
