import React, { Dispatch } from 'react';
import AddToCandidatePool from './AddToCandidatePool';
import { EngageAction, EngageState } from '../../../reducer';
import DownloadModal from '../../../../NewUI/Components/Modals/DownloadModal';
import ExternalEmail from '../../../SharedComponents/Modals/ExternalEmail';
import callAPI from '../../../../NewUI/Components/Utilities/callAPI';

const ActionDrawerModals = ({
  dispatch,
  engageState
}: {
  dispatch: Dispatch<EngageAction>;
  engageState: EngageState;
}) => {
  const downloadAPICall = async (type: string) => {
    if (type === 'attachments') {
      const { zip_file } = await callAPI(
        '/api/v4/searches/zip_attachments',
        { candidate_ids: engageState.searchTable.selectedItemIds },
        'POST'
      );
      return zip_file.id;
    } else if (type === 'pdf') {
      const response = await callAPI(
        '/api/v4/searches/export_pdf',
        { candidate_ids: engageState.searchTable.selectedItemIds },
        'POST'
      );
      return response.success || response.errors || 'Something went wrong';
    } else if (type === 'attachmentsInPdf') {
      const response = await callAPI(
        '/api/v4/searches/export_pdf',
        { candidate_ids: engageState.searchTable.selectedItemIds, attachments_in_pdf: true },
        'POST'
      );
      return response.success || response.errors || 'Something went wrong';
    } else if (type === 'csv') {
      const enabledColumns: Record<string, boolean> = {};
      engageState.searchTable.columns.forEach((column) => {
        enabledColumns[column.id as keyof typeof enabledColumns] = column.enabled;
      });
      const response = await callAPI(
        '/api/v4/searches/export_csv',
        {
          searches: {
            candidate_ids: engageState.searchTable.selectedItemIds,
            activeColumns: enabledColumns
          }
        },
        'POST'
      );
      return response.message || response.errors || 'Something went wrong';
    }
  };

  return (
    <>
      <AddToCandidatePool
        engageState={engageState}
        isOpen={
          engageState.searchTable.modalsOpen.addToPool ||
          engageState.searchTable.modalsOpen.addSearch
        }
        handleClose={() => {
          engageState.searchTable.modalsOpen.addToPool
            ? dispatch({
                type: 'SET_ACTION_MODAL_OPEN',
                payload: { name: 'addToPool', isOpen: false }
              })
            : dispatch({
                type: 'SET_ACTION_MODAL_OPEN',
                payload: { name: 'addSearch', isOpen: false }
              });
        }}
        selectedCandidates={engageState.searchTable.selectedItemIds}
        isSearch={engageState.searchTable.modalsOpen.addSearch}
        selectAllBody={{
          override_limit: true,
          limit: 9999,
          ...(engageState.searchQuery && { query: engageState.booleanQuery }),
          ...engageState.searchFilters
        }}
        setSnackbarState={({ message, state }) => {
          dispatch({
            type: 'SET_SNACKBAR',
            payload: {
              message,
              state
            }
          });
        }}
      />
      <ExternalEmail
        isOpen={engageState.searchTable.modalsOpen.externalEmail}
        dispatch={dispatch}
        selectedCandidateIds={engageState.searchTable.selectedItemIds}
        apiUrl="/api/v4/searches/bulk_external_email"
      />
      <DownloadModal
        type="attachments"
        isOpen={engageState.searchTable.modalsOpen.downloadAttachments}
        handleClose={() =>
          dispatch({
            type: 'SET_ACTION_MODAL_OPEN',
            payload: { name: 'downloadAttachments', isOpen: false }
          })
        }
        downloadAPICall={downloadAPICall}
      />
      <DownloadModal
        type="attachmentsInPdf"
        isOpen={engageState.searchTable.modalsOpen.exportAttachments}
        handleClose={() =>
          dispatch({
            type: 'SET_ACTION_MODAL_OPEN',
            payload: { name: 'exportAttachments', isOpen: false }
          })
        }
        downloadAPICall={downloadAPICall}
        emailFallback={true}
      />
      <DownloadModal
        type="pdf"
        isOpen={engageState.searchTable.modalsOpen.exportAsPdf}
        handleClose={() =>
          dispatch({
            type: 'SET_ACTION_MODAL_OPEN',
            payload: { name: 'exportAsPdf', isOpen: false }
          })
        }
        downloadAPICall={downloadAPICall}
        emailFallback={true}
      />
      <DownloadModal
        type="csv"
        isOpen={engageState.searchTable.modalsOpen.exportCsv}
        handleClose={() =>
          dispatch({
            type: 'SET_ACTION_MODAL_OPEN',
            payload: { name: 'exportCsv', isOpen: false }
          })
        }
        downloadAPICall={downloadAPICall}
        emailFallback={true}
      />
    </>
  );
};

export default ActionDrawerModals;
