import React from 'react';
import { IRecentlyViewed } from '../../../Dashboard/types';
import { Box, IconButton, Link, Button } from '@mui/material';
import { theme } from '../../../../ThemeContext/ThemeObject';
import WorkIcon from '@mui/icons-material/Work';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PersonIcon from '@mui/icons-material/Person';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ContactsIcon from '@mui/icons-material/Contacts';

function RecentlyViewedItem({ view }: { view: IRecentlyViewed }) {
  const renderColor = (icon: string) => {
    switch (icon) {
      case 'jobs':
        return '#FFA085 !important';
      case 'applications':
      case 'search_index':
        return '#F478FF !important';
      case 'entities':
        return '#B080FF !important';
      case 'users':
        return '#85F0A7 !important';
      case 'events':
        return '#FFC700 !important';
      case 'forms':
      case 'requisitions':
      case 'requisition_forms':
      case 'approvals':
        return '#91CAFF !important';
      case 'candidates':
        return '#F78CA6 !important';
      default:
        return null;
    }
  };

  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'jobs':
        return <WorkIcon sx={{ fontSize: '13px !important', color: '#FFFFFF' }} />;
      case 'applications':
      case 'search_index':
        return <ContactPageIcon sx={{ fontSize: '13px !important', color: '#FFFFFF' }} />;
      case 'entities':
        return <AccountTreeIcon sx={{ fontSize: '13px !important', color: '#FFFFFF' }} />;
      case 'users':
        return <PersonIcon sx={{ fontSize: '13px !important', color: '#FFFFFF' }} />;
      case 'events':
        return <EventNoteIcon sx={{ fontSize: '13px !important', color: '#FFFFFF' }} />;
      case 'forms':
      case 'requisitions':
      case 'requisition_forms':
      case 'approvals':
        return <HowToRegIcon sx={{ fontSize: '13px !important', color: '#FFFFFF' }} />;
      case 'candidates':
        return <ContactsIcon sx={{ fontSize: '13px !important', color: '#FFFFFF' }} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', padding: '0px 14px' }}>
      <IconButton
        sx={{
          fontSize: '15px !important',
          padding: '5px !important',
          backgroundColor: renderColor(view.controller),
          borderRadius: '50% !important',
          '&:hover': { backgroundColor: renderColor(view.controller), cursor: 'default' }
        }}
      >
        {renderIcon(view.controller)}
      </IconButton>
      <Link href={view.request_url} underline="none" sx={styles.infoContainer}>
        {view.caption}
      </Link>
    </Box>
  );
}

const styles = {
  infoContainer: {
    marginLeft: theme.spacing(2),
    color: '#666666 !important',
    fontWeight: '400 !important',
    fontSize: '13px !important'
  }
};

export default RecentlyViewedItem;
