import React, { useState, Dispatch } from 'react';
import { Stack, Box, Divider, Skeleton, Tabs, Tab } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import EmptyTab from '../../../Candidate/CandidateTasks/EmptyTab';
import { ApplicationAction, IApplication } from '../../types';
import { styles } from '../../../Candidate/styles';
import Api from '../../API';
import Task from './Task';

export default function TasksTab({ dispatch }: { dispatch: Dispatch<ApplicationAction> }) {
  const [tabValue, setTabValue] = useState<number>(0);
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const { data: tasks, isFetching: fetchingTasks } = useQuery({
    queryKey: ['tasks'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getApplicationTasks(application.job.id, application.id);
        return res.tasks;
      }
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: 'Failed in fetching application tasks', state: 'error' }
      })
  });

  const filteredTasks = tabValue
    ? tasks?.filter((task) => !!task.completed_at) // completed tasks
    : tasks?.filter((task) => !task.completed_at); // pending tasks
  return (
    <>
      <Tabs
        value={tabValue}
        onChange={(e, value) => setTabValue(value)}
        TabIndicatorProps={{
          sx: { ...styles.candidateTabIndicator, maxWidth: tabValue ? '5.6em' : '4.3em' }
        }}
        sx={styles.candidateTabs}
      >
        <Tab label="Pending" id="application-tasks-pending-tab" />
        <Tab label="Completed" id="application-tasks-completed-tab" />
      </Tabs>
      <Divider />
      {fetchingTasks ? (
        <>
          {[...Array(5)].map((_, index) => (
            <Box key={index}>
              <Skeleton animation="wave" height={60} />
            </Box>
          ))}
        </>
      ) : (
        <>
          {tasks && filteredTasks?.length ? (
            <Stack sx={{ paddingTop: 3, rowGap: 3 }}>
              {filteredTasks.map((task) => {
                return <Task key={task.id} task={task} dispatch={dispatch} />;
              })}
            </Stack>
          ) : (
            <EmptyTab tabValue={tabValue} />
          )}
        </>
      )}
    </>
  );
}
