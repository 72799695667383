"use strict";
export const classes = {
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  popover: {
    marginTop: "15px"
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)"
  },
  switchLabels: {
    justifyContent: "space-between",
    width: "200px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  switchActive: {
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  tableSettingsHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: 1,
    paddingLeft: "4px"
  },
  sortableField: {
    display: "flex",
    alignItems: "center",
    width: "204px",
    ".MuiSvgIcon-root": {
      fontSize: "16px"
    }
  },
  sortableSwitchLabels: {
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "4px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  }
};
