import { useState, useRef, useEffect, useCallback } from 'react';
import { createConsumer } from '@rails/actioncable';

export const useWebSockets = (channel: string, onReceive: (data: any, UID?: number) => void) => {
  const [socketConnected, setSocketConnected] = useState(false);
  const subscription = useRef<any>(null);
  const consumer = useRef<any>(null);

  useEffect(() => {
    consumer.current = createConsumer(
      document?.querySelector('meta[name="action-cable-url"]')?.content
    );
  }, []);

  const unsubscribeFromChannel = useCallback(() => {
    subscription.current && consumer.current.subscriptions.remove(subscription.current);
    setSocketConnected(false);
  }, []);

  const subscribeToChannel = useCallback(
    (UID?: number) => {
      if (consumer.current.subscriptions.subscriptions.length > 0) return;
      subscription.current = consumer.current.subscriptions.create(
        { channel },
        {
          connected: () => setSocketConnected(true),
          received: (data: any) => onReceive(data, UID)
        }
      );
    },
    [channel, onReceive]
  );

  return { subscribeToChannel, unsubscribeFromChannel, socketConnected };
};
