import { makeStyles } from "@material-ui/core/styles";
const DARK_BLUE = "#004E70";
export default makeStyles(() => ({
  root: {
    position: "relative"
  },
  footer: {
    button: {
      marginLeft: 12
    }
  }
}));
