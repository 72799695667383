"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  tabContent: {
    display: "flex",
    flexDirection: "column",
    margin: "20px 0px 40px 0"
  },
  headerContent: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#727272",
    marginBottom: "1rem",
    lineHeight: "2rem"
  },
  bodyContainer: {
    padding: "8px 0"
  },
  modalFormLine: {
    width: "100%",
    display: "grid"
  },
  hintText: {
    fontFamily: "Source Sans Pro",
    color: "#939393",
    fontSize: "12px",
    margin: "6px 0px"
  },
  testGridContainer: {
    display: "flex",
    flexDirection: "column",
    h2: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#666666",
      marginBottom: "24px"
    }
  },
  titleAndButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  newButton: {
    fontFamily: "Source Sans Pro",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "6px",
    padding: "8px 16px",
    color: theme.palette.action.main,
    backgroundColor: `${theme.palette.action.main}1F`,
    "&:hover": {
      backgroundColor: `${theme.palette.action.main}2F`
    }
  },
  tableContainer: {
    maxWidth: "100%",
    overflowX: "scroll"
  },
  table: {
    "& th": {
      border: "none",
      backgroundColor: "#FFFFFF",
      whiteSpace: "nowrap",
      fontFamily: "Source Sans Pro",
      padding: "12px 0px 38px 0px"
    },
    "& td": {
      border: "none",
      fontFamily: "Source Sans Pro"
    },
    "& thead th": {
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textTransform: "none"
    },
    "& tbody tr": {
      borderTop: "1px solid #DDDDDD"
    },
    "& tbody tr td": {
      padding: "25px 0px"
    }
  },
  tableActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "8px"
  },
  tableDeleteButton: {
    "& svg": {
      color: theme.palette.error.main
    }
  },
  tableEditButton: {
    "& svg": {
      color: "#666666"
    }
  },
  percentageSlider: {
    fontFamily: "Source Sans Pro",
    color: theme.palette.action.main,
    height: "3.5px",
    maxWidth: "267px",
    marginInline: "13px",
    "& .MuiSlider-rail": {
      backgroundColor: "#E0E0E0"
    },
    "& .MuiSlider-track": {
      backgroundColor: theme.palette.action.main
    },
    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.action.main,
      width: "14px",
      height: "14px",
      "&:hover": {
        boxShadow: "0px 0px 0px 7px" + theme.palette.action.main + "16"
      }
    },
    "& .MuiSlider-thumb.Mui-active": {
      boxShadow: "0px 0px 0px 9px" + theme.palette.action.main + "16"
    },
    "& .MuiSlider-valueLabel": {
      fontSize: "13px",
      fontWeight: "500",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      top: "44px",
      "&::after": {
        display: "none"
      }
    }
  },
  actionsModalReducedPadding: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    paddingLeft: "48px",
    maxWidth: "900px",
    width: "100%",
    height: "84vh",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& h3": {
      color: "#939393",
      fontSize: "19px",
      fontWeight: "Bold",
      marginBottom: "30px"
    }
  },
  actionModalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginLeft: "8px"
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    margin: "46px 0px"
  },
  actionModalFormLine: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "50px",
    width: "100%",
    marginLeft: "12px"
  },
  sliderLine: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "60px",
    paddingTop: "5px",
    margin: "0px 12px 0px 12px",
    width: "100%"
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px"
  },
  modalCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "100px",
    textTransform: "none",
    textDecoration: "underline",
    color: theme.palette.error.main,
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      boxShadow: "none"
    }
  },
  modalSendButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 19px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  inputLabel: {
    color: "#838383 !important",
    fontSize: "16px",
    fontFamily: "Source Sans Pro, sans-serif",
    paddingLeft: "4px",
    "& span": {
      color: theme.palette.error.main,
      marginLeft: "2px"
    }
  },
  formError: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    position: "absolute",
    bottom: "-24px",
    left: "4px"
  },
  noTests: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "380px",
    rowGap: "36px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#939393"
  },
  modalCreateRequisitionButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#5BC4BF",
    backgroundColor: "#5BC4BF12",
    borderRadius: "6px",
    boxShadow: "none",
    marginTop: "22px",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#4da8a412",
      boxShadow: "none"
    }
  },
  updateRequisitionButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#5BC4BF",
    backgroundColor: "#5BC4BF12",
    borderRadius: "6px",
    boxShadow: "none",
    margin: "22px 0px",
    "&:hover": {
      backgroundColor: "#4da8a412",
      boxShadow: "none"
    }
  },
  requisitionWrapper: {
    marginTop: "20px",
    padding: "12px 18px",
    background: "#F3F3F3",
    borderRadius: "6px"
  },
  requisitionInput: {
    "& span": {
      fontSize: "14px !Important"
    }
  },
  requisitionTitle: {
    color: theme.palette.primary.main,
    fontSize: "13px",
    fontWeight: "bold",
    paddingBottom: "5px",
    width: "70px",
    padding: "0px"
  },
  formAutocomplete: {
    "& .MuiAutocomplete-root": {
      "& .MuiOutlinedInput-root": {
        padding: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "0px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "& .MuiAutocomplete-input": {
        padding: "6px 4px 6px 11px"
      }
    },
    "& fieldset": {
      display: "none"
    },
    "& label": {
      color: "#838383 !important",
      fontSize: "21px",
      fontFamily: "Source Sans Pro, sans-serif",
      paddingLeft: "4px",
      left: "-13px",
      top: "-15px",
      "& .MuiFormLabel-asterisk": {
        color: theme.palette.error.main
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      transition: "all 0.25s ease-in-out",
      color: "#AAAAAA"
    }
  },
  editInfoText: {
    color: "#939393",
    fontSize: "16px",
    padding: "16px 0"
  },
  question: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 24px",
    background: "rgba(122, 142, 241, 0.12)",
    borderRadius: "6px",
    color: "#2D388D",
    fontFamily: "Source Sans Pro",
    fontSize: "16px"
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "24px",
    rowGap: "28px",
    width: "100%",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  switchContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "200px"
  },
  questionSkeleton: {
    width: "100%",
    height: "180px",
    borderRadius: "6px"
  }
};
