import { IEvent } from '../../../Job/types';
import { copyRichHTML } from '../../../Components/Utilities/copyToClipboard';

export const copyEventLinks = (events: IEvent[]) => {
  const eventLinks = events.map((event) => {
    const eventDate = new Date(event.begins_at);
    const eventDateString = eventDate.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    const eventTimeString = eventDate.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric'
    });
    const eventDateTimeString = `${eventDateString} at ${eventTimeString}`;
    const eventLink = `<a href="${window.location.origin}/admin/calendar/events/${event.id}" target="_blank" rel="noreferrer">${eventDateTimeString}: ${event.subject}</a>`;
    const candidateLinks = event.attendees
      .filter((attendee) => attendee.type === 'Candidate')
      .map(
        (attendee) =>
          `<a href="${attendee.application_url}" target="_blank" rel="noreferrer">${attendee.name}</a>`
      )
      .join(', ');
    return `<li>${eventLink}${candidateLinks && ' - Candidates: ' + candidateLinks}</li>`;
  });
  const eventLinksHTML = `<ul>${eventLinks.join('')}</ul>`;
  copyRichHTML(eventLinksHTML);
};
