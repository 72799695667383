import React, { useState, Dispatch } from 'react';
import { Box, FormControl, Checkbox } from '@mui/material';
import StyledDropdown from '../../SharedComponents/Inputs/StyledDropdown';
import DisplayDescription from './DisplayDescription';
import ToggleButtons from '../../SharedComponents/Buttons/ToggleButtons';
import PositionTitleInput from '../../SharedComponents/Inputs/PositionTitle';
import { postTones, responseLength, toggleButtonOptions } from '../config';
import { classes } from '../styles';
import { SmartShareAction, SmartShareState } from '../reducer';

function SocialInputStep({
  jobDescription,
  dispatch,
  smartShareState
}: {
  jobDescription: string;
  dispatch: Dispatch<SmartShareAction>;
  smartShareState: SmartShareState;
}) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Box sx={{ ...classes.inputStepContainer, rowGap: '42px' }}>
      <Box sx={classes.genericContainer}>
        <Box sx={classes.fieldHeader}>Position title</Box>
        <PositionTitleInput
          positionTitle={smartShareState.generationJobTitle}
          onChange={(value: string) =>
            dispatch({ type: 'SET_GENERATION_JOB_TITLE', payload: value })
          }
        />
      </Box>
      <Box sx={classes.genericContainer}>
        <ToggleButtons
          value={smartShareState.useCustomDescription}
          setValue={(value: string) =>
            dispatch({ type: 'SET_USE_CUSTOM_DESCRIPTION', payload: value })
          }
          options={toggleButtonOptions}
        />
      </Box>
      <DisplayDescription
        useCustomDescription={smartShareState.useCustomDescription}
        jobDescription={jobDescription}
        generationJobDescription={smartShareState.generationJobDescription}
        setGenerationJobDescription={(value: string) =>
          dispatch({ type: 'SET_GENERATION_JOB_DESCRIPTION', payload: value })
        }
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        maxCharacters={12000}
      />
      <Box sx={classes.sideBySideContainer}>
        <FormControl fullWidth>
          <Box sx={classes.fieldSubHeader}>Tone selection</Box>
          <StyledDropdown
            id="generated-tone"
            value={smartShareState.generationTone}
            onChange={(value: string) => dispatch({ type: 'SET_GENERATION_TONE', payload: value })}
            options={postTones}
          />
        </FormControl>
        <FormControl fullWidth>
          <Box sx={classes.fieldSubHeader}>Post length</Box>
          <StyledDropdown
            id="post-length"
            value={smartShareState.summaryLength}
            onChange={(value: string) => dispatch({ type: 'SET_SUMMARY_LENGTH', payload: value })}
            options={responseLength}
          />
        </FormControl>
        <FormControl fullWidth>
          <Box sx={classes.checkboxContainer}>
            <Checkbox
              sx={classes.checkbox}
              checked={smartShareState.includeHashtags}
              onChange={(e) =>
                dispatch({ type: 'SET_INCLUDE_HASHTAGS', payload: e.target.checked })
              }
            />
            <Box sx={classes.checkboxLabel}>Include hashtags</Box>
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
}

export default SocialInputStep;
