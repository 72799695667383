import React, { useState } from 'react';
import StyledModal from '../../../../NewUI/Components/GenericModal/StyledModal';
import {
  Box,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  FormHelperText
} from '@mui/material';
import MultiSelectWithCreate from '../../../../NewUI/Components/CustomUIElements/MultiSelectWithCreate';
import InfoBox from '../../../../NewUI/Components/CustomUIElements/InfoBox';
import { sharedClasses } from '../../../../NewUI/Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../../NewUI/Components/GenericModal/ModalFooterButtons';
import callAPI from '../../../../NewUI/Components/Utilities/callAPI';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { handleAPIRequest } from '../../../../NewUI/Components/Utilities/handleAPIRequest';
import { ISnackbarInput } from '../../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { EngageState } from '../../../reducer';
import { theme } from '../../../../ThemeContext/ThemeObject';

const AddToCandidatePool = ({
  engageState,
  isOpen,
  handleClose,
  selectedCandidates,
  isSearch,
  selectAllBody,
  setSnackbarState
}: {
  engageState: EngageState;
  isOpen: boolean;
  handleClose: () => void;
  selectedCandidates: number[];
  isSearch: boolean;
  selectAllBody: any;
  setSnackbarState: (snackbarState: ISnackbarInput) => void;
}) => {
  const [filterTab, setFilterTab] = useState('addCurrentResults');
  const [selectedPools, setSelectedPools] = useState<
    {
      id: number;
      title: string;
    }[]
  >([]);
  const [addingCandidates, setAddingCandidates] = useState(false);
  const [poolNameLengthError, setPoolNameLengthError] = useState(false);

  const queryClient = useQueryClient();

  const { data: allCurrentCandidates, isLoading: isLoadingCandidates } = useQuery({
    queryKey: ['allCurrentCandidates'],
    queryFn: () => callAPI(`/api/v4/searches`, selectAllBody, 'POST'),
    enabled: isOpen && isSearch
  });

  const { data: candidate_pools, isLoading: isLoadingPools } = useQuery({
    queryKey: ['candidatePoolsList'],
    queryFn: () => callAPI(`/api/v4/candidate_pools/list_pools`, null, 'GET'),
    enabled: isOpen
  });

  const { mutate: addCandidatePool, isLoading: creatingPool } = useMutation({
    mutationFn: (data) =>
      callAPI(
        `/api/v4/candidate_pools`,
        {
          title: data,
          params: {
            query: engageState.booleanQuery,
            ...engageState.searchSettings,
            ...engageState.searchFilters
          }
        },
        'POST'
      ),
    onSuccess: (data) => {
      queryClient.setQueryData(['candidatePoolsList'], (oldData: any) => {
        return {
          candidate_pools: [...oldData.candidate_pools, data]
        };
      });
      setSelectedPools([...selectedPools, data]);
    }
  });

  const handleMultiSelectValueChange = (value: any) => {
    setPoolNameLengthError(false);
    if (typeof value.at(-1) === 'string') {
      if (value.at(-1).length >= 255) return setPoolNameLengthError(true);
      addCandidatePool(value.at(-1));
    } else {
      setSelectedPools(value);
    }
  };

  const addCandidatesToPools = () => {
    const body = {
      candidate_ids: isSearch
        ? allCurrentCandidates?.ai_search?.map((candidate: { id: number }) => candidate.id)
        : selectedCandidates,
      candidate_pool_ids: selectedPools.map((pool) => pool.id)
    };
    handleAPIRequest({
      url: '/api/v4/candidate_pools/update_pools',
      method: 'POST',
      body,
      setLoaderState: setAddingCandidates,
      setSnackbarState,
      onSuccess: () => {
        handleClose();
        setSelectedPools([]);
        queryClient.invalidateQueries(['candidatePools', 'candidates']);
      }
    });
  };

  return (
    <StyledModal
      isOpen={isOpen}
      handleClose={handleClose}
      styleOverrides={{
        maxWidth: '830px',
        maxHeight: '540px'
      }}
      label="add-to-candidate-pool"
      modalTitle={isSearch ? 'Add search to candidate pool' : 'Add candidates to pool'}
      subHeaderStyling={false}
    >
      <Box sx={{ ...sharedClasses.mainModalContent, height: '100%' }}>
        <Box
          sx={{
            fontFamily: 'Source Sans Pro',
            fontSize: '16px',
            color: '#939393',
            width: '100%',
            paddingInline: '2px'
          }}
        >
          Select or create candidate pools
          <MultiSelectWithCreate
            dropdownOptions={candidate_pools?.candidate_pools || []}
            onChange={(newValues) => handleMultiSelectValueChange(newValues)}
            values={selectedPools}
            loadingOptions={isLoadingPools}
            creatingOption={creatingPool}
          />
          <FormHelperText
            sx={{
              marginLeft: '4px',
              color: poolNameLengthError ? theme.palette.error.main : theme.palette.common.grey
            }}
          >
            Candidate name length must be a maximum of 255 characters
          </FormHelperText>
        </Box>
        <Box sx={{ paddingTop: '46px' }}>
          {isSearch && isLoadingCandidates ? (
            <>
              <Box
                sx={{
                  fontFamily: 'Source Sans Pro',
                  fontSize: '17px',
                  color: '#939393',
                  width: '100%'
                }}
              >
                Loading all candidates in the current search, please wait...
              </Box>
              <Box sx={{ width: '100%' }}>
                <LinearProgress color="inherit" />
              </Box>
            </>
          ) : (
            <>
              <ToggleButtonGroup
                value={filterTab}
                exclusive
                onChange={(e, value) => {
                  if (value) setFilterTab(value);
                }}
                aria-label="subscribe or add current results"
              >
                {/* {selectedCandidates && <ToggleButton value="subscribe" sx={classes.toggleButton}>
          Subscribe to search
        </ToggleButton>} */}
                <ToggleButton value="addCurrentResults" sx={sharedClasses.toggleButton}>
                  Add current results
                </ToggleButton>
              </ToggleButtonGroup>
              <InfoBox
                message={
                  <Box sx={{ marginLeft: 1 }}>
                    {filterTab === 'subscribe' ? (
                      <>
                        Candidate pools subscribed to a search will be automatically updated with
                        new applicants that fall under that search
                        <br />
                        Any ‘newly added’ Nurture Journey actions will be executed for the existing
                        candidates in this search, and when new applicants are automatically added
                        to your pools
                      </>
                    ) : (
                      <>
                        All
                        {isSearch ? ' candidates in the current search ' : ' selected candidates '}
                        will be added to the selected candidate pools
                        <br />
                        Any ‘newly added’ Nurture Journey actions will be executed for the newly
                        added candidates
                      </>
                    )}
                  </Box>
                }
              />
            </>
          )}
        </Box>
      </Box>
      <ModalFooterButtons
        primaryButtonText={isSearch ? 'Add search' : 'Add candidates'}
        primaryButtonCallback={addCandidatesToPools}
        primaryButtonDisabled={selectedPools.length === 0 || (isSearch && isLoadingCandidates)}
        secondaryButtonText="Cancel"
        secondaryButtonCallback={handleClose}
        isLoading={addingCandidates}
        primaryButtonID="add-candidates-button"
        secondaryButtonID="cancel-add-too-pool"
      />
    </StyledModal>
  );
};

export default AddToCandidatePool;
