import React, { Dispatch, FC, useState } from 'react';
import {
  Box,
  CircularProgress,
  Modal,
  Button,
  TextField,
  Autocomplete,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import { useQuery } from '@tanstack/react-query';
import RecruitApi from '../../RecruitApi';
import { IUser } from '../types';

interface ICaretakersModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
}

const CaretakersModal: FC<ICaretakersModal> = ({ dispatch, state }) => {
  const { filtersModal, selectedUsers } = state;
  const [users, setUsers] = useState<IUser[]>([]);

  const {
    data: userOptions,
    isLoading: loadingUserOptions,
    isFetching: fetchingUserOptions
  } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const { res } = await RecruitApi.getUsers({});
      if (res.users.length) {
        return res.users as IUser[];
      } else {
        return [];
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading users, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: () => {
      const initialUsers: IUser[] = [];
      selectedUsers.map((user) => {
        const existingUser = userOptions.find((u) => u.id === user);
        if (existingUser) initialUsers.push(existingUser);
      });
      setUsers(initialUsers);
    },
    initialData: []
  });

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, 'Caretakers Report': false }
    });
  };

  return (
    <Modal
      open={filtersModal['Caretakers Report']}
      onClose={handleClose}
      aria-labelledby="caretakers-modal"
    >
      <Box sx={{ ...classes.actionsModal, maxHeight: '460px', width: '720px' }}>
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>Caretakers report filters</Box>
        </Box>
        <Stack sx={{ marginTop: '1.75rem', flexGrow: 1 }}>
          <Box sx={{ ...classes.modalFormLine }}>
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              multiple
              options={userOptions}
              getOptionLabel={(option) =>
                `${option.full_name} ${option.email ? `(${option.email})` : ''}`
              }
              value={users}
              onChange={(_event, newValue) => setUsers(newValue)}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
              ListboxProps={{ style: classes.autocompleteListboxStyle }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Users"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {(loadingUserOptions || fetchingUserOptions) && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  placeholder={users.length ? '' : 'All users'}
                  sx={{ input: { '&::placeholder': { opacity: 1 }, textTransform: 'capitalize' } }}
                />
              )}
            />
          </Box>
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset' }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              const filter: Record<string, string> = {};
              if (users.length) {
                filter.Users = users.map((user) => user.full_name).join(', ');
                dispatch({ type: 'SELECTED_USERS', payload: users.map((user) => user.id) });
              } else {
                delete filter.Users;
                dispatch({ type: 'SELECTED_USERS', payload: [] });
              }
              dispatch({ type: 'SET_FILTERS', payload: filter });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default CaretakersModal;
