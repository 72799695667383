import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import NoQuestionSVG from './NoQuestionSVG';
import NoQuestionArrowSVG from './NoQuestionArrowSVG';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import InfoIcon from '@mui/icons-material/Info';
import NewSystemFieldSVG from './NewSystemFieldSVG';
import NewField from './Modals/NewField';
import AddSystemField from './Modals/AddSystemField';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import AIStudioField from '../../../AIStudio/AIStudioQuestions/AIStudioQuestions';
import { FelixButtonSVG } from '../../../AIStudio/SharedComponents/Graphics/FelixSVG';
import { classes } from './styles';
import { classes as AIClasses } from '../styles';
import { DndContext, closestCenter, KeyboardSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

import SortableItem from './SortableItem';
import { QuestionCardSensor } from './QuestionCardSensor';
import { IPermissions, IJob } from '../../Job/types';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';

export default function Questions({
  apiKey,
  jobId,
  job,
  userPermissions,
  aiStudio
}: {
  apiKey: string;
  jobId: number;
  job: IJob;
  userPermissions: IPermissions;
  aiStudio: boolean;
}) {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selected, setSelected] = useState('enabledQuestions');
  const [modalsOpen, setModalsOpen] = useState<Record<string, boolean>>({
    newField: false,
    addSystemField: false,
    aiStudioField: false
  });
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });

  const [editingQuestion, setEditingQuestion] = useState<IApplicantQuestion | null>();

  const sensors = useSensors(
    useSensor(QuestionCardSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = questions.map((question) => question.id).indexOf(active.id);
      const newIndex = questions.map((question) => question.id).indexOf(over.id);
      const newArray = arrayMove(questions, oldIndex, newIndex);

      setQuestions(newArray);
      updateJobQuestions(newArray);
    }
  }

  const getQuestions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/v4/fields?job_id=${jobId}`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setQuestions(response.fields);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, jobId]);

  const updateJobQuestions = async (questions) => {
    const fieldObject = {};
    questions.map(
      (question, index) =>
        (fieldObject[question.id] = {
          position: index
        })
    );
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/update_questions`, {
        method: 'PUT',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fields: fieldObject
        })
      }).then((response) => response.json());
      setQuestions(response.questions);
      setSnackbar({
        message: 'Questions have been updated',
        state: 'success'
      });
    } catch (error) {
      setSnackbar({
        message: 'There was an error saving your changes',
        state: 'error'
      });
    }
  };

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newSelected: string) => {
    if (newSelected !== null) {
      setSelected(newSelected);
    }
  };

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  return (
    <Box sx={classes.questionsPageContainer}>
      <Box sx={classes.pageHeader}>
        <h2 data-testid="questions-label">Questions</h2>
      </Box>
      <Box sx={classes.noticeContainer}>
        <InfoIcon sx={classes.noticeIcon} />
        <Box sx={{ marginLeft: 1 }}>
          Any changes (adding, editing, removing etc.) to your questions will effect and block the
          importing of applications from SEEK into :Recruit.{' '}
          <b>All changes will also be automatically saved and applied immediately.</b>
        </Box>
      </Box>
      <Box sx={classes.noticeContainer}>
        <InfoIcon sx={classes.infoIcon} />
        <Box sx={{ marginLeft: 1 }}>
          All questions that have been answered by a candidate are not able to be edited or deleted.
          To remove or edit a question, you will need to disable it and create a new question.
        </Box>
      </Box>
      <Box sx={classes.toggleButtonsContainer}>
        <ToggleButtonGroup
          value={selected}
          exclusive
          onChange={handleToggle}
          aria-label="enabled or disabled questions"
        >
          <ToggleButton value="enabledQuestions" sx={sharedClasses.toggleButton}>
            Enabled questions
          </ToggleButton>
          <ToggleButton value="disabledQuestions" sx={sharedClasses.toggleButton}>
            Disabled questions
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={classes.actionsContianer}>
        {selected === 'enabledQuestions' && (
          <Button
            data-testid="new-field-button"
            variant="outlined"
            startIcon={<LiveHelpIcon />}
            sx={classes.actionButton}
            onClick={() => {
              setEditingQuestion(null);
              setModalsOpen((prev) => ({ ...prev, newField: true }));
            }}
          >
            New question
          </Button>
        )}
        {selected === 'enabledQuestions' && (
          <Button
            variant="outlined"
            startIcon={<NewSystemFieldSVG />}
            sx={classes.actionButton}
            onClick={() => {
              setEditingQuestion(null);
              setModalsOpen((prev) => ({ ...prev, addSystemField: true }));
            }}
          >
            Add system field
          </Button>
        )}
        {aiStudio && (
          <Box sx={{ marginLeft: 'auto' }}>
            <Box
              id="ai-studio-questions-integration-button"
              sx={{
                ...AIClasses.buttonContainer,
                ...(buttonHovered ? AIClasses.hoverAnimation : AIClasses.unhoverAnimation)
              }}
              onMouseEnter={() => setButtonHovered(true)}
              onMouseLeave={() => setButtonHovered(false)}
              onClick={() => {
                setEditingQuestion(null);
                setModalsOpen((prev) => ({ ...prev, aiStudioField: true }));
              }}
            >
              <Box sx={AIClasses.avatarContainer}>
                <FelixButtonSVG />
              </Box>
              <Button sx={AIClasses.button}>Generate questions</Button>
              <Box sx={AIClasses.betaPill}>Beta</Box>
            </Box>
          </Box>
        )}
      </Box>
      {selected === 'enabledQuestions'
        ? !isLoading &&
          questions.filter((question) => question.enabled).length > 0 && (
            <Box sx={classes.questionsContainer}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={questions.filter((question) => question.enabled)}
                  strategy={verticalListSortingStrategy}
                >
                  {questions
                    .filter((question) => question.enabled)
                    .map((question, index) => (
                      <SortableItem
                        allQuestions={questions}
                        questions={questions.filter((question) => question.enabled)}
                        setQuestions={setQuestions}
                        getQuestions={getQuestions}
                        index={index}
                        key={question.id}
                        jobId={jobId}
                        apiKey={apiKey}
                        setModalsOpen={setModalsOpen}
                        editingQuestion={question}
                        setEditingQuestion={setEditingQuestion}
                        setSnackbar={setSnackbar}
                        applicationCount={job.applications_count}
                        userPermissions={userPermissions}
                      />
                    ))}
                </SortableContext>
              </DndContext>
            </Box>
          )
        : !isLoading &&
          questions.filter((question) => !question.enabled).length > 0 && (
            <Box sx={classes.questionsContainer}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={questions.filter((question) => !question.enabled)}
                  strategy={verticalListSortingStrategy}
                >
                  {questions
                    .filter((question) => !question.enabled)
                    .map((question, index) => (
                      <SortableItem
                        allQuestions={questions}
                        questions={questions.filter((question) => !question.enabled)}
                        setQuestions={setQuestions}
                        getQuestions={getQuestions}
                        index={index}
                        key={question.id}
                        jobId={jobId}
                        apiKey={apiKey}
                        setModalsOpen={setModalsOpen}
                        editingQuestion={question}
                        setEditingQuestion={setEditingQuestion}
                        setSnackbar={setSnackbar}
                        applicationCount={job.applications_count}
                      />
                    ))}
                </SortableContext>
              </DndContext>
            </Box>
          )}
      {!isLoading && questions?.length === 0 && (
        <Box sx={{ ...classes.noQuestionsContainer, margin: '100px 0px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '100px' }}></Box>
            <NoQuestionSVG />
            <NoQuestionArrowSVG />
          </Box>
          <Box sx={classes.noQuestionsText}>
            You have no questions
            <br />
            Choose an options above to start creating questions
          </Box>
        </Box>
      )}
      <NewField
        apiKey={apiKey}
        getQuestions={getQuestions}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        jobId={jobId}
        setSnackbar={setSnackbar}
        editingQuestion={editingQuestion}
        autoRating={job.auto_rater != null}
        userPermissions={userPermissions}
      />
      {aiStudio && (
        <AIStudioField
          getQuestions={getQuestions}
          modalsOpen={modalsOpen.aiStudioField}
          setModalsOpen={(open: boolean) =>
            setModalsOpen((prev) => ({ ...prev, aiStudioField: open }))
          }
          jobId={jobId}
          setSnackbar={setSnackbar}
          jobTitle={job.title}
          jobDescription={job.description}
          userPermissions={userPermissions}
        />
      )}
      <AddSystemField
        apiKey={apiKey}
        getQuestions={getQuestions}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        jobId={jobId}
        setSnackbar={setSnackbar}
      />
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
