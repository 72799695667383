export const classes = {
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  moveButton: {
    textTransform: "none",
    fontFamily: "Source Sans Pro",
    fontWeight: 700,
    color: "#084d6d",
    padding: "8px 16px"
  },
  buttonContainer: {
    display: "flex",
    columnGap: "16px",
    paddingRight: "24px",
    paddingBottom: "24px"
  },
  saveButton: {
    textTransform: "none",
    fontFamily: "Source Sans Pro",
    fontWeight: 700,
    color: "#ffffff",
    backgroundColor: "#084d6d",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: "rgb(5, 53, 76)"
    }
  },
  cancelButton: {
    textTransform: "none",
    fontFamily: "Source Sans Pro",
    fontWeight: 700,
    color: "#084d6d",
    borderColor: "#084d6d",
    padding: "8px 16px",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "rgb(235, 235, 235)"
    }
  }
};
