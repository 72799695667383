import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import { IExportCSVProps } from '../../types';
import { classes } from '../styles';

export default function CSVExport({
  apiKey,
  filters,
  order,
  orderBy,
  activeColumns
}: IExportCSVProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    candidate: activeColumns.candidate,
    gender: activeColumns.gender,
    suburb: activeColumns.suburb,
    country: activeColumns.country,
    date: activeColumns.date,
    rating: activeColumns.rating,
    status: activeColumns.status,
    answers: true,
    notes: false,
    individualRating: false
  });

  const handleModalOpen = () => {
    setModalOpen(true);
    setSelectedFields({
      candidate: activeColumns.candidate,
      gender: activeColumns.gender,
      suburb: activeColumns.suburb,
      country: activeColumns.country,
      date: activeColumns.date,
      rating: activeColumns.rating,
      status: activeColumns.status,
      answers: true,
      notes: false,
      individualRating: false
    });
  };

  const handleClickChip = (field: string) => {
    setSelectedFields((previousSelections) => ({
      ...previousSelections,
      [field]: !previousSelections[field]
    }));
  };

  const handleExportCSV = () => {
    setIsLoading(true);
    try {
      console.log('api call will go here');
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setModalOpen(false);
    }
  };

  return (
    <>
      <Box>
        <IconButton id="export-applications-csv" sx={classes.iconButton} onClick={handleModalOpen}>
          <IosShareIcon />
        </IconButton>
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="export-applications-csv-modal"
      >
        <Box sx={classes.csvExportModal}>
          <CloseIcon onClick={() => setModalOpen(false)} sx={classes.closeIcon} />
          <Box id="export-applications-csv-modal-title" sx={classes.csvExportHeader}>
            Select the fields to include in the CSV export
          </Box>
          <Box sx={classes.csvExportSubheader}>
            Your active filters and sorting will be applied automatically.
          </Box>
          <Box sx={classes.chipPacket}>
            <Chip label="ID" sx={classes.chipSelected} />
            <Chip label="Candidate" sx={classes.chipSelected} />
            <Chip
              label="Gender"
              sx={selectedFields.gender ? classes.chipSelected : classes.chip}
              onClick={() => handleClickChip('gender')}
            />
            <Chip
              label="Suburb"
              sx={selectedFields.suburb ? classes.chipSelected : classes.chip}
              onClick={() => handleClickChip('suburb')}
            />
            <Chip
              label="Country"
              sx={selectedFields.country ? classes.chipSelected : classes.chip}
              onClick={() => handleClickChip('country')}
            />
            <Chip
              label="Date"
              sx={selectedFields.date ? classes.chipSelected : classes.chip}
              onClick={() => handleClickChip('date')}
            />
            <Chip
              label="Rating"
              sx={selectedFields.rating ? classes.chipSelected : classes.chip}
              onClick={() => handleClickChip('rating')}
            />
            <Chip
              label="Status"
              sx={selectedFields.status ? classes.chipSelected : classes.chip}
              onClick={() => handleClickChip('status')}
            />
            <Chip
              label="Answers"
              sx={selectedFields.answers ? classes.chipSelected : classes.chip}
              onClick={() => handleClickChip('answers')}
            />
            <Chip
              label="Notes"
              sx={selectedFields.notes ? classes.chipSelected : classes.chip}
              onClick={() => handleClickChip('notes')}
            />
            <Chip
              label="Individual Rating"
              sx={selectedFields.individualRating ? classes.chipSelected : classes.chip}
              onClick={() => handleClickChip('individualRating')}
            />
          </Box>
          <Button
            id="export-applications-csv-button"
            sx={classes.csvExportButton}
            onClick={handleExportCSV}
          >
            Export CSV
          </Button>
        </Box>
      </Modal>
    </>
  );
}
