"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { saveAs } from "file-saver";
import { newBuildBody } from "../helper";
export const downloadReport = (record, dispatch, reportServiceApi) => __async(void 0, null, function* () {
  const buildBody = __spreadProps(__spreadValues({}, record), {
    fromHistoryTable: true
  });
  reportServiceApi.reports.download(buildBody).then((blob) => {
    saveAs(blob, `${record.title}.csv`);
  }).catch((error) => {
    dispatch({ type: "SET_SNACKBAR", payload: { message: error.message, state: "error" } });
  });
});
export const fetchDataFromReportService = (state, dispatch, reportServiceApi, newFilter = false) => __async(void 0, null, function* () {
  reportServiceApi.reports.getHistories(newBuildBody(state, newFilter)).then((data) => {
    const { records, pagination, errors } = data;
    if (errors) {
      throw new Error(errors);
    }
    dispatch({
      type: "LOAD_PAGE",
      payload: {
        records,
        maxPages: pagination.total_pages,
        maxRecords: pagination.total_count,
        currentPage: pagination.current_page,
        limit: pagination.limit
      }
    });
  }).catch((error) => {
    dispatch({ type: "SET_SNACKBAR", payload: { message: error.message, state: "error" } });
  });
});
