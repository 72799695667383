"use strict";
import { theme } from "../../../../components/ThemeContext/ThemeObject";
export const styles = {
  tableWrapper: {
    tableLayout: "auto !important",
    minWidth: "1000px",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none"
    },
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    tr: {
      fontSize: "16px"
    }
  },
  skeletonContainer: {
    padding: "10px 0px"
  },
  noRecords: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  tableCellData: {
    width: "20%",
    minWidth: "10rem"
  }
};
