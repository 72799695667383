"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
export const getUpdatedSortableColumns = (sortableColumns, columnsAndLimitPreferences) => {
  const sortableColumnMap = /* @__PURE__ */ new Map();
  sortableColumns.forEach((column) => {
    sortableColumnMap.set(column.id, column);
  });
  const usedIds = [];
  const updatedColumns = [];
  columnsAndLimitPreferences.sortable_columns.forEach((column) => {
    const matchingColumn = sortableColumnMap.get(column.id);
    if (matchingColumn) {
      updatedColumns.push(__spreadProps(__spreadValues({}, matchingColumn), {
        sortable: column.sortable,
        canToggle: column.canToggle,
        enabled: column.enabled
      }));
      usedIds.push(matchingColumn.id);
    }
  });
  sortableColumns.forEach((column) => {
    if (!usedIds.includes(column.id)) {
      updatedColumns.push(column);
    }
  });
  return { updatedColumns };
};
