import React, { useState, useRef, Dispatch } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import { Update as UpdateIcon, Autorenew as AutorenewIcon } from '@mui/icons-material';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ApplicationAction, IApplication } from '../types';
import AISummaryNoAccess from './AISummaryNoAccess';
import { styles } from '../styles';
import {
  FelixConfused,
  FelixMessageSVG
} from '../../../AIStudio/SharedComponents/Graphics/FelixSVG';
import DisplayHTMLViewOnly from '../../../AIStudio/SharedComponents/DisplayHTMLViewOnly';
import { handleRichCopy } from '../../Components/Utilities/handleRichCopy';
import dayjs from 'dayjs';
import Api from '../API';

export default function AISummary({
  dispatch,
  aiSummariesEnabled
}: {
  dispatch: Dispatch<ApplicationAction>;
  aiSummariesEnabled: boolean;
}) {
  const [emails, setEmails] = useState<string[]>([]);
  const [emailInputOpen, setEmailInputOpen] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const summaryRef = useRef<HTMLDivElement | null>(null);

  const downloadPDF = () => {
    const url = `/api/v4/jobs/${application?.job.id}/applications/${application?.id}/application_summary.pdf`;
    window.open(url, '_blank');
  };

  const { mutate: sendEmail, isLoading: sendingEmails } = useMutation({
    mutationFn: async () => {
      if (application) {
        await Api.sendApplicationSummary(application.job.id, application.id, emails);
      }
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Email has been sent successfully`, state: 'success' }
      });
      setEmailInputOpen(false);
      setEmails([]);
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in sending email, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const { mutate: updateSummary, isLoading: updatingSummary } = useMutation({
    mutationFn: async () => {
      if (application) {
        await Api.updateSummary(application.job.id, application.id);
      }
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'Summary is being generated, this may take a few minutes',
          state: 'success'
        }
      });
      queryClient.invalidateQueries(['application'], { exact: true });
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in generating summary, ${error}`,
          state: 'error'
        }
      });
    }
  });

  return (
    <Stack sx={styles.AISummaryContainer}>
      <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Felix AI Summary</Stack>
      {aiSummariesEnabled ? (
        <>
          {application?.summary ? (
            <Stack sx={{ rowGap: 2 }}>
              <Stack sx={{ flexDirection: 'row', columnGap: 1, justifyContent: 'space-between' }}>
                <Stack sx={styles.AISummaryHeader}>
                  <FelixMessageSVG />
                  <Stack sx={{ ...styles.AISummaryMessage, fontSize: '15px' }}>
                    <Box>
                      I’ve created your <span>candidate summary</span>! This summary
                    </Box>
                    <Stack>compares your application against the job requirements.</Stack>
                  </Stack>
                </Stack>
                <Stack sx={{ rowGap: '4px', marginTop: 2 }}>
                  <Stack sx={{ flexDirection: 'row', columnGap: 2 }}>
                    <Stack
                      id="update-summary-button"
                      sx={styles.generateSummaryButton}
                      onClick={() => updateSummary()}
                    >
                      Update summary{' '}
                      {updatingSummary ? (
                        <CircularProgress size={12} color="inherit" sx={{ marginRight: '4px' }} />
                      ) : (
                        <AutorenewIcon sx={{ transform: 'rotate(90deg)', fontSize: '18px' }} />
                      )}
                    </Stack>
                    <Stack
                      id="copy-summary-button"
                      sx={{
                        ...styles.AISummaryButton,
                        width: '80px',
                        padding: '6px 16px',
                        alignItems: 'center'
                      }}
                      onClick={() => handleRichCopy(summaryRef.current, setCopied)}
                    >
                      {copied ? 'Copied!' : 'Copy'}
                    </Stack>
                  </Stack>
                  <Stack sx={styles.summaryLastUpdated}>
                    <UpdateIcon sx={{ transform: 'scaleX(-1)', fontSize: '14px' }} /> Last updated{' '}
                    {dayjs(application?.summary_updated_at).format('D/M/YY, LT')}
                  </Stack>
                </Stack>
              </Stack>
              <DisplayHTMLViewOnly
                content={application.summary}
                viewerRef={summaryRef}
                onExportClick={downloadPDF}
                setEmails={setEmails}
                emails={emails}
                onSendEmailClick={sendEmail}
                emailInputOpen={emailInputOpen}
                setEmailInputOpen={setEmailInputOpen}
                sendingEmails={sendingEmails}
                sendEmailButtonId="send-email-summary-button"
                emailButtonId="email-summary-button"
                exportButtonId="download-summary-as-pdf-button"
              />
            </Stack>
          ) : (
            <Stack sx={{ alignItems: 'center', rowGap: 2 }}>
              <Stack sx={{ width: '40px' }}>
                <FelixConfused />
              </Stack>
              <Stack sx={{ color: '#939393', fontWeight: 600 }}>No summary available</Stack>
              <Stack
                id="generate-summary-button"
                sx={{
                  ...styles.generateSummaryButton,
                  minWidth: '155px',
                  justifyContent: 'center'
                }}
                onClick={() => updateSummary()}
              >
                {updatingSummary ? (
                  <CircularProgress size={18} color="inherit" sx={{ marginRight: '4px' }} />
                ) : (
                  'Generate summary'
                )}
              </Stack>
            </Stack>
          )}
        </>
      ) : (
        <AISummaryNoAccess />
      )}
    </Stack>
  );
}
