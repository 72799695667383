"use strict";
export const handleSelectOne = (event, item, allItemIds, dispatch) => {
  if (event.target.checked) {
    dispatch({ type: "ADD_SELECTED_ITEM", payload: item });
  } else {
    dispatch({
      type: "REMOVE_SELECTED_ITEM",
      payload: item.id
    });
  }
};
export const handleSelectAllOnPage = (event, allItems, dispatch) => {
  if (event.target.checked) {
    dispatch({ type: "ADD_ALL_PAGE_ITEMS", payload: allItems });
  } else {
    dispatch({ type: "REMOVE_ALL_PAGE_ITEMS", payload: allItems.map((i) => i.id) });
  }
};
export const handleScroll = (tableRef, dispatch) => {
  if (!tableRef.current) return;
  if (tableRef.current.scrollLeft < 10) {
    dispatch({ type: "SET_CAN_SCROLL", payload: [false, true] });
  } else if (tableRef.current.scrollLeft > tableRef.current.scrollWidth - tableRef.current.clientWidth - 10) {
    dispatch({ type: "SET_CAN_SCROLL", payload: [true, false] });
  } else {
    dispatch({ type: "SET_CAN_SCROLL", payload: [true, true] });
  }
};
export const handleScrollLeft = (tableRef) => {
  if (!tableRef.current) return;
  tableRef.current.scrollLeft > 360 ? tableRef.current.scrollLeft -= 300 : tableRef.current.scrollLeft = 0;
};
export const handleScrollRight = (tableRef) => {
  if (!tableRef.current) return;
  tableRef.current.scrollWidth - tableRef.current.clientWidth > 360 ? tableRef.current.scrollLeft += 300 : tableRef.current.scrollLeft = tableRef.current.scrollWidth - tableRef.current.clientWidth;
};
export const isScrollable = (element) => {
  return element && element.scrollWidth > element.clientWidth + 20;
};
export const handleScrollableEnter = (tableRef, dispatch) => {
  if (tableRef.current && isScrollable(tableRef.current))
    dispatch({ type: "SET_TABLE_HOVERED", payload: true });
};
export const handleScrollableExit = (dispatch) => {
  dispatch({ type: "SET_TABLE_HOVERED", payload: false });
};
export const tablePadding = (density) => {
  if (density === "Default") {
    return "22px";
  } else if (density === "Compact") {
    return "15px";
  } else {
    return "8px";
  }
};
export const removeSelected = (selectedItems, selectedItemIds, itemId) => {
  const newSelectedItemIds = selectedItemIds.filter((id) => id !== itemId);
  const newSelectedItems = selectedItems.filter((item) => item.id !== itemId);
  return {
    selectedItemIds: newSelectedItemIds,
    selectedItems: newSelectedItems
  };
};
export const addSelected = (selectedItems, selectedItemIds, item) => {
  const newSelectedItemIds = [...selectedItemIds, item.id];
  const newSelectedItems = [...selectedItems, item];
  return {
    selectedItemIds: newSelectedItemIds,
    selectedItems: newSelectedItems
  };
};
export const addAllPageItems = (selectedItems, itemsOnPage) => {
  const newSelectedItems = Array.from(/* @__PURE__ */ new Set([...selectedItems, ...itemsOnPage]));
  const newSelectedItemIds = newSelectedItems.map((item) => item.id);
  return {
    selectedItemIds: newSelectedItemIds,
    selectedItems: newSelectedItems
  };
};
export const removeAllPageItems = (selectedItems, itemsOnPage) => {
  const newSelectedItems = selectedItems.filter((item) => !itemsOnPage.includes(item.id));
  const newSelectedItemIds = newSelectedItems.map((item) => item.id);
  return {
    selectedItemIds: newSelectedItemIds,
    selectedItems: newSelectedItems
  };
};
