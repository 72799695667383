import React, { useContext, useEffect, useReducer } from 'react';
import { ReportContext } from '../index';
import { setInitialState, reducer } from './reducer';
import { defaultActiveColumns, dropdownOptions, filteringStates } from './configs';
import { fetchDataFromReportService } from './API';
import { TableWithSearchFieldDropDownAndFilter } from '../../../TableWithSearchFieldDropDownAndFilter';
import ReportTableBody from './ReportTableBody';

const HistorySection = () => {
  const reportServiceApi = useContext(ReportContext);
  const [tableState, dispatch] = useReducer<typeof reducer>(
    reducer,
    setInitialState(defaultActiveColumns, dropdownOptions, filteringStates)
  );

  useEffect(() => {
    if (!reportServiceApi) return;
    fetchDataFromReportService(tableState, dispatch, reportServiceApi);
  }, [tableState.currentPage, tableState.limit, tableState.sort_by, tableState.sort_order]);

  return (
    <>
      {reportServiceApi ? (
        <TableWithSearchFieldDropDownAndFilter
          tableState={tableState}
          dispatch={dispatch}
          tableBody={<ReportTableBody state={tableState} dispatch={dispatch} />}
          fetchData={(newFilter: boolean) =>
            fetchDataFromReportService(tableState, dispatch, reportServiceApi, newFilter)
          }
          stickyColumnSettings={{ leftBoundOffSet: -150, rightBoundOffSet: -110 }} // This is all the padding and/or margin in total from parents above this page
          filtersComponent={null}
          fieldComponent={false}
          pendoId={'history-reports'}
        />
      ) : null}
    </>
  );
};

export default HistorySection;
