"use strict";
import { theme } from "../components/ThemeContext/ThemeObject";
export const styles = {
  searchInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "regular",
    borderRadius: "6px",
    minHeight: "54px",
    "& legend": {
      display: "none"
    },
    "& svg": {
      fontSize: "31px",
      color: "#084D6D"
    },
    "& input": {
      padding: "10.5px 11px 15.5px 11px",
      color: "#000000"
    },
    "& fieldset": {
      borderColor: "#EAEEF0"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADEE0 !important"
      }
    }
  },
  rowSelectContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "8px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666",
    "& span": {
      marginRight: "12px",
      paddingBottom: "6px"
    }
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "8px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666"
  },
  pagination: {
    marginRight: "24px",
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#CCCCCC",
      margin: "0px -2px"
    },
    "& .Mui-selected": {
      color: "#666666",
      background: "none",
      "&:hover": {
        background: "none"
      }
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#666666"
    }
  },
  rowSelect: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: "#666666",
    fontWeight: "bold",
    borderRadius: "6px !important",
    "& legend": {
      display: "none"
    },
    "& svg": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EAEEF0"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CACDCE"
    },
    "& .MuiOutlinedInput-input": {
      padding: "4px 12px 8px 12px !important"
    }
  },
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  },
  tableWrapper: {
    tableLayout: "auto !important",
    minWidth: "1000px",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none"
    },
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    tr: {
      fontSize: "16px",
      borderBottom: "1px solid #DDDDDD"
    }
  },
  skeletonContainer: {
    borderBottom: "1px solid #DDDDDD",
    padding: "10px 0px"
  },
  noRecords: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  arrowsContainer: {
    height: 0,
    overflow: "visible",
    position: "sticky",
    top: "200px",
    zIndex: "99",
    transform: "translateY(100px)"
  },
  leftArrow: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    width: "40px",
    height: "65px",
    background: "black",
    bottom: "370px",
    borderRadius: "0px 10px 10px 0px",
    background: "#eeeeee",
    zIndex: "999",
    opacity: "0.9",
    cursor: "pointer"
  },
  rightArrow: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    width: "40px",
    height: "65px",
    background: "black",
    bottom: "370px",
    borderRadius: "10px 0px 0px 10px",
    background: "#eeeeee",
    zIndex: "999",
    opacity: "0.9",
    cursor: "pointer"
  },
  showArrows: {
    display: "flex",
    animation: "$fadeIn linear 0.17s"
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 0.9 }
  },
  stickyRight: {
    position: "sticky",
    right: "0px",
    background: "white"
  },
  scrollShadowLeft: {
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      right: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      right: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  scrollShadowRight: {
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      left: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      left: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  scrollShadowVisible: {
    "&&::after": {
      opacity: "20%"
    }
  },
  attachmentsHeader: {
    minWidth: "86px"
  },
  attachmentsCell: {
    minWidth: "86px",
    width: "50px",
    "& svg": {
      color: "#666666",
      fontSize: "18px",
      cursor: "pointer"
    }
  },
  actionItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  attachmentItem: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    padding: "14px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  sticky: (prevLeft = 0) => ({
    position: "sticky",
    left: `${prevLeft}px`,
    background: "white",
    zIndex: 1
  })
};
