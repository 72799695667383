import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeStep from './HomeStep/HomeStep';
import LoadingResponse from '../../SharedComponents/Steps/LoadingResponse';
import ResponseStep from './ResponseStep';
import ErrorScreen from '../../SharedComponents/Steps/ErrorScreen';
import { StyledSnackbarProps } from '../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { defaultInclusions } from '../config';
import { classes } from '../styles';
import { modalSteps } from '../types';
import { IWebsocketData } from '../../SharedComponents/types';
import { useWebSockets } from '../../../NewUI/Components/Utilities/useWebSockets';
import { dismissNotification } from '../../SharedComponents/Utility/dismissNotification';
import { IJobApplication } from '../../../NewUI/Job/types';

const InterviewQuestions = ({
  jobId,
  open,
  handleClose,
  selectedApplicantId,
  positionTitle,
  setSnackbarState
}: {
  jobId: number;
  open: boolean;
  handleClose: () => void;
  selectedApplicantId: IJobApplication['id'];
  positionTitle: string;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
}) => {
  const [step, setStep] = useState<modalSteps>('input');
  const [questionType, setQuestionType] = useState('general');
  const [inclusions, setInclusions] = useState(defaultInclusions);
  const [questionData, setQuestionData] = useState<IWebsocketData | null>(null);

  const { subscribeToChannel, unsubscribeFromChannel, socketConnected } = useWebSockets(
    'AiStudioChannel',
    (data: IWebsocketData, UID?: number) => {
      if (data.UID !== UID) return;
      if (data.kind === 'error') {
        setStep('error');
      } else {
        setQuestionData(data);
        setStep('response');
      }
      unsubscribeFromChannel();
    }
  );

  const requestQuestions = async () => {
    setStep('loading');
    const UID = Date.now();
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/applications/${selectedApplicantId}/interview_question`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            interview_question: {
              position_title: positionTitle,
              question_type: questionType,
              information_include: Object.keys(inclusions).filter(
                (key) => inclusions[key as keyof typeof inclusions].value
              ),
              UID: UID
            }
          })
        }
      );
      await response.json();
      if (response.ok) {
        subscribeToChannel(UID);
      } else {
        throw new Error(`API Request failed with status ${response.status}`);
      }
    } catch (error) {
      setStep('error');
    }
  };

  const onClose = () => {
    if (step === 'response' && questionData) {
      dismissNotification(questionData.id);
    }
    setStep('input');
    setQuestionType('general');
    setInclusions(defaultInclusions);
    setQuestionData(null);
    unsubscribeFromChannel();
    handleClose();
  };

  const renderStep = (step: modalSteps) => {
    switch (step) {
      case 'input':
        return (
          <HomeStep
            positionTitle={positionTitle}
            questionType={questionType}
            setQuestionType={setQuestionType}
            inclusions={inclusions}
            setInclusions={setInclusions}
            requestQuestions={requestQuestions}
            jobId={jobId}
            applicationId={selectedApplicantId}
            setSnackbarState={setSnackbarState}
          />
        );
      case 'loading':
        return <LoadingResponse generationType="interview" showSocketMessage={socketConnected} />;
      case 'response':
        return (
          <ResponseStep
            setStep={setStep}
            requestQuestions={requestQuestions}
            questionData={questionData}
            jobId={jobId}
            selectedApplicantId={selectedApplicantId}
            setSnackbarState={setSnackbarState}
          />
        );
      case 'error':
        return (
          <ErrorScreen regenerateCallback={requestQuestions} backToHome={() => setStep('input')} />
        );
      default:
        return <div>Something went wrong</div>;
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="suggested-interview-questions-modal">
      <Box sx={classes.modal}>
        <Box sx={classes.aiModalContent}>
          <CloseIcon onClick={onClose} sx={classes.closeIcon} />
          {renderStep(step)}
        </Box>
      </Box>
    </Modal>
  );
};

export default InterviewQuestions;
