import React, { Component } from 'react';
import T from 'prop-types';

export default class LinkControl extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { url, method, confirm, title } = this.props
    return(
      <div><a href={url} data-method={method} data-confirm={confirm}> {title} </a></div>
    )
  }
}
