import React from 'react';
import { Box, Modal, Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { FelixStarsSVG } from '../SharedComponents/Graphics/FelixSVG';
import { classes } from './styles';

const featuresText = [
  'Job advertisements',
  'Social media posts',
  'Talent landing pages',
  'Screening and interview questions'
];

const handleButtonAction = () => {
  window.open('https://scouttalenthq.com/talent-acquisition-software/ai-studio', '_blank');
};

const AIStudioTeaserModal = ({
  open,
  handleCloseAction
}: {
  open: boolean;
  handleCloseAction: () => void;
}) => {
  return (
    <Modal open={open} onClose={handleCloseAction} aria-labelledby="ai-studio-teaser">
      <Box sx={classes.previewModal}>
        <CloseIcon onClick={handleCloseAction} sx={classes.closeIcon} />
        <Box sx={classes.teaserContainer}>
          <FelixStarsSVG />
          <Box sx={classes.teaserHeader}>
            <span>Launching: AI Studio</span>
            <Box sx={classes.teaserPill}>BETA</Box>
          </Box>
          <Box sx={{ fontWeight: '700' }}>
            Reduce the time it takes to create talent attraction content by 80%.
          </Box>
          <Box sx={classes.teaserText}>
            Leverage the power of AI Studio to draft recruitment collateral in seconds:
          </Box>
          <Box sx={classes.featureList}>
            {featuresText.map((feature, index) => (
              <Box sx={classes.featureListItem} key={index}>
                <DoneIcon sx={classes.featureListItemIcon} />
                <Box>{feature}</Box>
              </Box>
            ))}
          </Box>
          <Button
            id="discover-ai-studio-button"
            sx={classes.teaserButton}
            onClick={handleButtonAction}
          >
            Discover benefits
          </Button>
        </Box>
        <Button sx={classes.teaserCloseButton} onClick={handleCloseAction}>
          No thanks, I don’t want to save time
        </Button>
      </Box>
    </Modal>
  );
};

export default AIStudioTeaserModal;
