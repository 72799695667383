import React, { useState } from 'react';
import { Box, Modal, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from './styles';
import DynamicFooter from '../SharedComponents/Footers/DynamicFooter';
import DisplayHTMLViewOnly from '../SharedComponents/DisplayHTMLViewOnly';
import { handleRichCopy } from '../../NewUI/Components/Utilities/handleRichCopy';
import { IApplicationInfo } from '../SharedComponents/types';
import { StyledSnackbarProps } from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { handleAPIRequest } from '../../NewUI/Components/Utilities/handleAPIRequest';

const ExpandedSummaryModal = ({
  jobId,
  expanded,
  handleCloseModal,
  lastHoveredApplicant,
  summaryRef,
  copied,
  setCopied,
  loadingSummary,
  generateSummary,
  setSnackbarState
}: {
  jobId: number;
  expanded: boolean;
  handleCloseModal: () => void;
  lastHoveredApplicant: IApplicationInfo;
  summaryRef: React.RefObject<HTMLDivElement>;
  copied: boolean;
  setCopied: React.Dispatch<React.SetStateAction<boolean>>;
  loadingSummary: boolean;
  generateSummary: () => void;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [emailInputOpen, setEmailInputOpen] = useState<boolean>(false);
  const [sendingEmails, setSendingEmails] = useState(false);

  const downloadPDF = () => {
    const url = `/api/v4/jobs/${jobId}/applications/${lastHoveredApplicant.id}/application_summary.pdf`;
    window.open(url, '_blank');
  };

  const handleSendEmailClick = () => {
    handleAPIRequest({
      url: `/api/v4/jobs/${jobId}/applications/${lastHoveredApplicant.id}/send_individual_summary`,
      method: 'POST',
      body: { emails },
      onSuccess: () => {
        setEmailInputOpen(false);
        setEmails([]);
      },
      setLoaderState: setSendingEmails,
      setSnackbarState
    });
  };

  return (
    <Modal
      open={expanded}
      onClose={handleCloseModal}
      aria-labelledby="application-summary-expanded"
    >
      <Box sx={classes.modal}>
        <Box sx={{ ...classes.aiModalContent, rowGap: '36px' }}>
          <CloseIcon onClick={handleCloseModal} sx={classes.closeIcon} />
          <Box sx={classes.header}>{lastHoveredApplicant.name}</Box>
          <DisplayHTMLViewOnly
            content={lastHoveredApplicant.summary}
            viewerRef={summaryRef}
            onExportClick={downloadPDF}
            setEmails={setEmails}
            emails={emails}
            onSendEmailClick={handleSendEmailClick}
            emailInputOpen={emailInputOpen}
            setEmailInputOpen={setEmailInputOpen}
            sendingEmails={sendingEmails}
          />
          <DynamicFooter
            primaryButtonText={copied ? 'Copied!' : 'Copy'}
            secondaryButtonText={
              loadingSummary ? <CircularProgress size={20} color="inherit" /> : 'Regenerate'
            }
            primaryButtonID="copy-summary-text"
            secondaryButtonID="regenerate-summary"
            primaryButtonMinWidth="110px"
            secondaryButtonMinWidth="130px"
            handlePrimaryButtonClick={() => handleRichCopy(summaryRef.current, setCopied)}
            handleSecondaryButtonClick={generateSummary}
            felixMessage={
              <>
                I’ve created your <span>candidate summary</span>! This summary compares your
                application against the job requirements.
              </>
            }
            felixMessageMaxWidth="400px"
            alternateSecondaryButton={true}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ExpandedSummaryModal;
