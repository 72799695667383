"use strict";
export const getAttachmentType = (selectedAttachment) => {
  var _a, _b, _c, _d, _e, _f;
  const isImage = ((_a = selectedAttachment == null ? void 0 : selectedAttachment.attached_content_type) == null ? void 0 : _a.split("/")[0]) === "image";
  const isVideo = ((_b = selectedAttachment == null ? void 0 : selectedAttachment.attached_content_type) == null ? void 0 : _b.split("/")[0]) === "video";
  const isAudio = ((_c = selectedAttachment == null ? void 0 : selectedAttachment.attached_content_type) == null ? void 0 : _c.split("/")[0]) === "audio";
  const isText = (selectedAttachment == null ? void 0 : selectedAttachment.attached_content_type) === "text/plain";
  const isPdf = ((_d = selectedAttachment == null ? void 0 : selectedAttachment.attached_content_type) == null ? void 0 : _d.split("/")[1]) === "pdf";
  const isWord = ((_e = selectedAttachment == null ? void 0 : selectedAttachment.attached_content_type) == null ? void 0 : _e.split("/")[1]) === "msword" || ((_f = selectedAttachment == null ? void 0 : selectedAttachment.attached_content_type) == null ? void 0 : _f.split("/")[1]) === "vnd.openxmlformats-officedocument.wordprocessingml.document";
  return {
    isImage,
    isVideo,
    isAudio,
    isText,
    isPdf,
    isWord
  };
};
