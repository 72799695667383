"use strict";
export const classes = {
  systemMessage: {
    backgroundColor: "#FFFFFF",
    color: "#2D388D",
    fontWeight: "400",
    padding: "16px 24px",
    width: "fit-content",
    margin: "10px 0",
    maxWidth: "100%",
    borderRadius: "20px",
    border: "1.5px solid #2D388D",
    "&& ul li": {
      fontWeight: "600 !important",
      color: "#2D388D !important"
    }
  }
};
