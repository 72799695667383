import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, defaults } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { externalTooltipHandler } from './DonutTooltip';
import { IChartData } from './types';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DonutChart({ data }: { data: IChartData }) {
  defaults.font.family = 'Source Sans Pro';

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 56,
    borderWidth: 1,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          fontSize: 12,
          color: '#084D6D'
        }
      },
      tooltip: {
        enabled: false,
        external: externalTooltipHandler
      }
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10
      }
    }
  };
  const dataset = {
    labels: data?.labels,
    datasets: [
      {
        data: data?.data,
        backgroundColor: [
          '#71E599',
          '#4D91ED',
          '#F5DD5B',
          '#F46F6F',
          '#D958F0',
          '#5AC7EF',
          '#4242EF',
          '#8045F0',
          '#FC8D47',
          '#9DED2F',
          '#91EBDA',
          '#F569E0',
          '#E85096',
          '#5FA362'
        ],
        hoverBackgroundColor: [
          '#45dd79',
          '#2176e8',
          '#f2d32b',
          '#f03d3d',
          '#ce2aeb',
          '#2cb7ea',
          '#1717eb',
          '#6219ec',
          '#fb6f17',
          '#88dd13',
          '#60e2c9',
          '#f137d5',
          '#e2267d',
          '#4f8b52'
        ],
        hoverOffset: 8
      }
    ]
  };

  return <Doughnut data={dataset} options={options} />;
}
