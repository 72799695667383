import React, { useState } from 'react';
import { Box, Modal, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from './styles';
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';
import { SavedReportRecord } from '../History/types';
import Step1 from './Step1';
import reducer from './reducer';

type Props = {
  scheduleModalOpen: boolean;
  setScheduleModalOpen: (value: boolean) => void;
  reportData?: SavedReportRecord;
};

const ScheduleReportModal = ({ scheduleModalOpen, setScheduleModalOpen }: Props) => {
  const [scheduleState, scheduleDispatch] = reducer();
  const [firstStep, setFirstStep] = useState(true);

  return (
    <Modal open={scheduleModalOpen} onClose={() => setScheduleModalOpen(false)}>
      <Box sx={sharedClasses.modalBody}>
        <CloseIcon onClick={() => setScheduleModalOpen(false)} sx={sharedClasses.closeIcon} />
        <Box sx={sharedClasses.sectionContent}>
          <Box sx={sharedClasses.sectionHeader}>Report Settings</Box>
          <Box sx={classes.formContent} role="form">
            {firstStep ? (
              <Step1
                scheduleState={scheduleState}
                scheduleDispatch={scheduleDispatch}
                isLoading={false}
              />
            ) : (
              <Box /> // <Step2 />
            )}
          </Box>
          <Box sx={sharedClasses.modalFooter}>
            <Button
              id="next-button"
              variant="contained"
              sx={sharedClasses.genericButton}
              onClick={() => {
                firstStep ? setFirstStep(false) : setFirstStep(true);
              }}
            >
              {firstStep ? 'Next' : 'Schedule'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ScheduleReportModal;
