import React, { useState, Dispatch } from 'react';
import { Box, FormControl } from '@mui/material';
import PositionTitleInput from '../../SharedComponents/Inputs/PositionTitle';
import PreferredSkillsInput from '../../SharedComponents/Inputs/PreferredSkills';
import StyledDropdown from '../../SharedComponents/Inputs/StyledDropdown';
import DynamicFooter from '../../SharedComponents/Footers/DynamicFooter';
import { questionTypes } from '../../SharedComponents/sharedConfig';
import { classes } from '../styles';
import { AIStudioAction, AIStudioState } from '../reducer';

const QuestionsInput = ({
  dispatch,
  aiStudioState,
  generateQuestions
}: {
  dispatch: Dispatch<AIStudioAction>;
  aiStudioState: AIStudioState;
  generateQuestions: () => void;
}) => {
  const [positionTitle, setPositionTitle] = useState(aiStudioState.jobDetails.position_title || '');
  const [preferredSkills, setPreferredSkills] = useState(
    aiStudioState.jobDetails.preferred_skills || ''
  );

  const handleGenerateClick = () => {
    setPositionTitle('');
    setPreferredSkills('');
    generateQuestions();
  };

  return (
    <Box sx={classes.descriptionContainer}>
      <Box sx={classes.genericContainer}>
        <Box sx={classes.fieldHeader}>Position title</Box>
        <PositionTitleInput
          positionTitle={positionTitle}
          onChange={setPositionTitle}
          onBlur={(value: string) =>
            dispatch({ type: 'SET_JOB_DETAILS', payload: value, fieldName: 'position_title' })
          }
        />
      </Box>
      <Box sx={classes.genericContainer}>
        <Box sx={classes.fieldSubHeader}>Preferred skills (optional)</Box>
        <PreferredSkillsInput
          preferredSkills={preferredSkills}
          onChange={setPreferredSkills}
          onBlur={(value: string) =>
            dispatch({ type: 'SET_JOB_DETAILS', payload: value, fieldName: 'Preferred_skills' })
          }
        />
      </Box>
      <Box sx={classes.genericContainer}>
        <FormControl fullWidth>
          <Box sx={classes.fieldSubHeader}>Question type</Box>
          <StyledDropdown
            id="question-type"
            value={aiStudioState.questionType}
            onChange={(value: string) => dispatch({ type: 'SET_QUESTION_TYPE', payload: value })}
            options={questionTypes}
          />
        </FormControl>
      </Box>
      <DynamicFooter
        handleSecondaryButtonClick={() => dispatch({ type: 'GO_TO_HOME_STEP' })}
        handlePrimaryButtonClick={handleGenerateClick}
        secondaryButtonText="Back"
        primaryButtonText="Generate"
        primaryButtonID="write-screening-questions-generate-button"
        felixMessage={
          <>
            Let me help you write your <span>screening questions</span>!
          </>
        }
        disablePrimaryButton={positionTitle === ''}
      />
    </Box>
  );
};

export default QuestionsInput;
