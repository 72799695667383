import React, { Dispatch, SetStateAction } from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { navbarWidths } from '../config';
import { classes } from '../styles';

const ExpandCollapseButton = ({
  navbarState,
  setNavbarState,
  setChildState
}: {
  navbarState: keyof typeof navbarWidths;
  setNavbarState: Dispatch<SetStateAction<keyof typeof navbarWidths>>;
  setChildState: Dispatch<SetStateAction<[number, null | HTMLElement]>>;
}) => {
  const handleNavbarStateChange = (state: typeof navbarState) => {
    if (state === 'expanded') {
      setChildState([-1, null]);
    }
    localStorage.setItem('navbarState', state);
    setNavbarState(state);
  };
  return (
    <Box
      sx={{
        ...classes.expandCollapseButtonContainer,
        left: `${navbarWidths[navbarState] - 15}px`
      }}
    >
      {navbarState === 'expanded' ? (
        <IconButton
          onClick={() => handleNavbarStateChange('collapsed')}
          sx={classes.expandCollapseButton}
        >
          <ArrowBackIosIcon sx={{ marginLeft: '5px' }} />
        </IconButton>
      ) : (
        <IconButton
          onClick={() => handleNavbarStateChange('expanded')}
          sx={classes.expandCollapseButton}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default ExpandCollapseButton;
