import React, { Dispatch, FC } from 'react';
import { Box } from '@mui/material';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import ApplicationAndPlacementModal from './ApplicationAndPlacementModal';
import ApprovalModal from './ApprovalModal';
import AurionModal from './AurionModal';
import CandidatesEShotsModal from './CandidatesEShotsModal';
import CaretakersModal from './CaretakersModal';
import DailyApplicationsModal from './DailyApplicationsModal';
import JobDetailsModal from './JobDetailsModal';
import JobStateModal from './JobStateModal';
import PublishedJobsAndSMSModal from './PublishedJobsAndSMSModal';
import QuestionsModal from './QuestionsModal';
import UserEventsModal from './UserEventsModal';
import { useQuery, useMutation } from '@tanstack/react-query';
import RecruitApi from '../../RecruitApi';
import { IEntity } from '../types';

interface IFilterModals {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
  sendReport: () => void;
}

const FilterModals: FC<IFilterModals> = ({ dispatch, state, sendReport }) => {
  const { filtersModal, openPreview } = state;

  const { data: entities, isFetching: fetchingEntities } = useQuery({
    queryKey: ['entities'],
    queryFn: async () => {
      const { res } = await RecruitApi.getEntities();
      return res.entities;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading entities, ${error}`,
          state: 'error'
        }
      }),
    initialData: [],
    enabled: [
      'Applications Report',
      'Application Timeline Report',
      'Daily Applications Report',
      'Job Details Report',
      'Placements Advanced Report',
      'Questions Report',
      'User Events Report'
    ].includes(openPreview)
  });

  const { mutate: getEntitiesAndChildren } = useMutation({
    mutationFn: async (id: number) => {
      const { res } = await RecruitApi.getEntitiesAndChildren(id);
      return res;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting the entities data, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: (res) => {
      const subEntitiesIds = res.subentities.map((entity: IEntity) => entity.id);
      dispatch({ type: 'SELECTED_ENTITIES', payload: [res.entity.id, ...subEntitiesIds] });
    }
  });

  return (
    <Box>
      {(filtersModal['Applications Report'] ||
        filtersModal['Application Timeline Report'] ||
        filtersModal['Placements Advanced Report']) && (
        <ApplicationAndPlacementModal
          dispatch={dispatch}
          state={state}
          entities={entities}
          fetchingEntities={fetchingEntities}
          getEntitiesAndChildren={getEntitiesAndChildren}
        />
      )}
      {filtersModal['Approvals Report'] && (
        <ApprovalModal dispatch={dispatch} state={state} sendReport={sendReport} />
      )}
      {filtersModal['Aurion Report'] && <AurionModal dispatch={dispatch} state={state} />}
      {filtersModal['Candidates for eShots Report'] && (
        <CandidatesEShotsModal
          dispatch={dispatch}
          state={state}
          getEntitiesAndChildren={getEntitiesAndChildren}
        />
      )}
      {filtersModal['Caretakers Report'] && <CaretakersModal dispatch={dispatch} state={state} />}
      {filtersModal['Daily Applications Report'] && (
        <DailyApplicationsModal
          dispatch={dispatch}
          state={state}
          entities={entities}
          fetchingEntities={fetchingEntities}
        />
      )}
      {filtersModal['Job Details Report'] && (
        <JobDetailsModal
          dispatch={dispatch}
          state={state}
          entities={entities}
          fetchingEntities={fetchingEntities}
        />
      )}
      {filtersModal['Job State Report'] && <JobStateModal dispatch={dispatch} state={state} />}
      {(filtersModal['Published Jobs Report'] || filtersModal['SMS Report']) && (
        <PublishedJobsAndSMSModal dispatch={dispatch} state={state} />
      )}
      {filtersModal['Questions Report'] && (
        <QuestionsModal
          dispatch={dispatch}
          state={state}
          entities={entities}
          fetchingEntities={fetchingEntities}
        />
      )}
      {filtersModal['User Events Report'] && (
        <UserEventsModal
          dispatch={dispatch}
          state={state}
          entities={entities}
          fetchingEntities={fetchingEntities}
          getEntitiesAndChildren={getEntitiesAndChildren}
        />
      )}
    </Box>
  );
};

export default FilterModals;
