import React from 'react';
import { Box, Button } from '@mui/material';
import { pdfjs } from 'react-pdf';
import { IAttachment, IPreviewData } from '../types';
import { classes } from '../../Components/Modals/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function WordPreview({
  selectedAttachment,
  previewData,
  setCurrentIndex
}: {
  selectedAttachment: IAttachment;
  previewData: IPreviewData | undefined;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsLoadingPreview: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <>
      {previewData?.data && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={classes.docPreviewNavigation}>
            {previewData.prev && (
              <Button
                sx={classes.downloadButton}
                onClick={() =>
                  setCurrentIndex((prev) => {
                    return prev - 1;
                  })
                }
              >
                Previous
              </Button>
            )}
            {previewData.next && (
              <Button
                sx={classes.downloadButton}
                onClick={() =>
                  setCurrentIndex((prev) => {
                    return prev + 1;
                  })
                }
              >
                Next
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
              overflowY: 'auto'
            }}
          >
            <Box
              component="img"
              src={previewData.data || ''}
              alt={selectedAttachment.attached_file_name}
              sx={{ ...classes.imagePreview, minHeight: '1000px' }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
