import React, { Dispatch, SetStateAction, memo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import { sharedClasses } from './sharedClasses';
import { Portal } from '@mui/base';

export type SnackbarState = 'success' | 'warning' | 'error';

export interface ISnackbarInput {
  message: string;
  state: SnackbarState;
}
export interface StyledSnackbarProps {
  message: string;
  state: SnackbarState;
  setSnackbarState:
    | Dispatch<SetStateAction<ISnackbarInput>>
    | (({ message, state }: ISnackbarInput) => void);
}

function StyledSnackbar({ message, state, setSnackbarState }: StyledSnackbarProps) {
  function TransitionDown(props: SlideProps) {
    return <Slide direction="down" {...props} />;
  }

  const snackbarIcon = (state: string) => {
    switch (state) {
      case 'success':
        return <CheckCircleIcon style={{ color: '#00C853', fontSize: '28px' }} />;
      case 'warning':
        return <WarningIcon style={{ color: '#E6A052', fontSize: '28px' }} />;
      case 'error':
        return <CancelIcon style={{ color: '#E37D7A', fontSize: '28px' }} />;
      default:
        return <WarningIcon style={{ color: '#E6A052', fontSize: '28px' }} />;
    }
  };

  const snackbarStyle = (state: string) => {
    switch (state) {
      case 'success':
        return { ...sharedClasses.snackbar, ...sharedClasses.snackbarSuccess };
      case 'warning':
        return { ...sharedClasses.snackbar, ...sharedClasses.snackbarWarning };
      case 'error':
        return { ...sharedClasses.snackbar, ...sharedClasses.snackbarError };
      default:
        return { ...sharedClasses.snackbar, ...sharedClasses.snackbarWarning };
    }
  };

  return (
    <Portal>
      <Snackbar
        open={!!message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setSnackbarState({ message: '', state: 'success' })}
        message={message}
        TransitionComponent={TransitionDown}
        ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
      >
        <Box sx={snackbarStyle(state)}>
          <Box sx={sharedClasses.snackbarMessageIconContainer}>
            {snackbarIcon(state)}
            <Box sx={sharedClasses.snackbarMessage}>{message}</Box>
          </Box>
          <Button
            sx={sharedClasses.snackbarButton}
            onClick={() => setSnackbarState({ message: '', state: 'success' })}
          >
            DISMISS
          </Button>
        </Box>
      </Snackbar>
    </Portal>
  );
}

export default memo(StyledSnackbar);
