// eslint-disable-next-line react/no-deprecated
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

export class AccordionItem extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
  };

  state = {
    isActive: false,
  };

  onClick = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  render() {
    const { title } = this.props;
    const { isActive } = this.state;
    const classes = className({
      "accordion__item": true,
      "accordion__item--active": isActive,
    });

    return <div className={classes}>
      <h2 onClick={this.onClick} className='title'> {title} </h2>
      <div className='accordion__body'>
        {isActive && this.props.children}
      </div>
    </div>
  }
}

export class Accordion extends Component {
  render() {
    return <div className='accordion'>
      { this.props.children }
    </div>
  }
}

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
};
