import React, { useState, Dispatch, SetStateAction } from 'react';
import { Box, FormControl } from '@mui/material';
import PositionTitleInput from '../SharedComponents/Inputs/PositionTitle';
import PreferredSkillsInput from '../SharedComponents/Inputs/PreferredSkills';
import StyledDropdown from '../SharedComponents/Inputs/StyledDropdown';
import DynamicFooter from '../SharedComponents/Footers/DynamicFooter';
import DisplayHTMLCollapsible from '../SharedComponents/DisplayHTMLCollapsible';
import ToggleButtons from '../SharedComponents/Buttons/ToggleButtons';
import { questionTypes } from '../SharedComponents/sharedConfig';
import { classes } from './styles';

const toggleButtonOptions = [
  {
    value: 'skills',
    label: 'Use preferred skills'
  },
  {
    value: 'description',
    label: 'Use job description'
  }
];

export default function AIStudioForm({
  jobDescription,
  positionTitle,
  setPositionTitle,
  preferredSkills,
  setPreferredSkills,
  questionType,
  setQuestionType,
  jobInformationSource,
  setJobInformationSource,
  generateQuestions
}: {
  jobDescription?: string;
  positionTitle: string;
  setPositionTitle: Dispatch<SetStateAction<string>>;
  preferredSkills: string;
  setPreferredSkills: Dispatch<SetStateAction<string>>;
  questionType: string;
  setQuestionType: Dispatch<SetStateAction<string>>;
  jobInformationSource: string;
  setJobInformationSource: Dispatch<SetStateAction<string>>;
  generateQuestions: () => void;
}) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Box sx={{ ...classes.aiQuestionsContainer, rowGap: '42px' }}>
      <Box sx={classes.genericContainer}>
        <Box sx={classes.fieldHeader}>Position title</Box>
        <PositionTitleInput positionTitle={positionTitle} onChange={setPositionTitle} />
      </Box>
      {jobDescription && (
        <Box sx={classes.genericContainer}>
          <ToggleButtons
            value={jobInformationSource}
            setValue={setJobInformationSource}
            options={toggleButtonOptions}
          />
        </Box>
      )}
      {jobInformationSource === 'skills' || !jobDescription ? (
        <Box sx={classes.genericContainer}>
          <Box sx={classes.fieldSubHeader}>Preferred skills (optional)</Box>
          <PreferredSkillsInput preferredSkills={preferredSkills} onChange={setPreferredSkills} />
        </Box>
      ) : (
        <Box sx={classes.genericContainer}>
          <Box sx={classes.fieldSubHeader}>Job description</Box>
          <DisplayHTMLCollapsible
            content={jobDescription}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        </Box>
      )}
      <Box sx={classes.genericContainer}>
        <FormControl fullWidth>
          <Box sx={classes.fieldSubHeader}>Question type</Box>
          <StyledDropdown
            id="generated-tone"
            value={questionType}
            onChange={setQuestionType}
            options={questionTypes}
          />
        </FormControl>
      </Box>
      <DynamicFooter
        handlePrimaryButtonClick={generateQuestions}
        primaryButtonText="Generate"
        primaryButtonID="generate-questions-integration-button"
        disablePrimaryButton={positionTitle === ''}
        felixMessage={
          <>
            Let me help you write your <span>screening questions</span>!
          </>
        }
      />
    </Box>
  );
}
