import React, { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import GenericDialog from '../../Components/Modals/GenericDialog';
import NoteList from './NoteList';
import Api from '../API';
import { IJobNote } from '../types';
import { classes } from './styles';
import { emojiInString } from '../../utils/emoji-checker';
import { NOTES_ERROR_MESSAGE } from '../../constants';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';

export default function Notes({
  apiKey,
  jobId,
  permissions,
  setSnackbarState
}: {
  apiKey: string;
  jobId: number;
  permissions: Record<string, boolean>;
  setSnackbarState: (state: { message: string; state: string }) => void;
}) {
  const [notes, setNotes] = useState(null);
  const [editMode, setEditMode] = useState(0);
  const [editedNote, setEditedNote] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [noteError, setNoteError] = useState('');

  const fetchNotes = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await Api.getNotes({ 'X-api-authenticate': apiKey }, jobId);
      setNotes(response.job_notes);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, jobId]);

  const addNote = async () => {
    setIsSending(true);
    try {
      const response = await Api.newNote({ 'X-api-authenticate': apiKey }, jobId, {
        job_note: { body: editedNote }
      });
      setNotes((prev: IJobNote[]) => [response, ...prev]);
      setEditMode(0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSending(false);
    }
  };

  const updateNote = async () => {
    setIsSending(true);
    try {
      const response = await Api.putNote({ 'X-api-authenticate': apiKey }, jobId, editingId, {
        job_note: { body: editedNote }
      });
      if (!response.error) {
        setNotes((prev: IJobNote[]) =>
          prev.map((note) => (note.id === editingId ? response : note))
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSending(false);
      setEditMode(0);
      setEditingId(null);
    }
  };

  const deleteNote = useCallback(
    async (url: string, noteId: number) => {
      setIsDeleting(true);
      try {
        const response = await Api.deleteNote({ 'X-api-authenticate': apiKey }, jobId, noteId);
        if (!response.error) {
          setNotes((prev: IJobNote[]) => prev.filter((note) => note.id !== noteId));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsDeleting(false);
        setEditMode(0);
        setEditingId(null);
        setIsDialogOpen(false);
      }
    },
    [apiKey, jobId]
  );

  const handleNewNote = useCallback(() => {
    setEditedNote('');
    setEditMode(2);
  }, []);

  const handleNoteInput = (value: string) => {
    setEditedNote(value);
  };

  const handleSaveNote = () => {
    setNoteError('');
    try {
      if (emojiInString(editedNote)) {
        setNoteError(NOTES_ERROR_MESSAGE);
        return;
      }
      if (editMode === 2) {
        addNote();
      } else if (editMode === 1) {
        updateNote();
      }
    } catch (error) {
      console.error(error.message);
      setSnackbarState({
        message: error.message,
        state: 'error'
      });
    }
  };

  const handleEditNote = useCallback((noteId: number, note: string) => {
    setEditingId(noteId);
    setEditedNote(note);
    setEditMode(1);
  }, []);

  const handleDeleteNote = useCallback((noteId: number) => {
    setEditingId(noteId);
    setIsDialogOpen(true);
  }, []);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  return (
    <>
      <Box
        sx={{
          ...classes.noteEdit,
          height: editMode ? 'fit-content' : '0px'
        }}
      >
        <TextField
          id="note-input"
          data-testid="note-input-[Notes]"
          label=" "
          multiline
          rows={6}
          fullWidth
          variant="outlined"
          value={editedNote}
          onChange={(e) => handleNoteInput(e.target.value)}
          InputLabelProps={{
            shrink: false
          }}
        />
        {!!noteError && (
          <Box sx={{ ...sharedClasses.errorText, mt: '8px !important' }}>{noteError}</Box>
        )}
        <Box sx={classes.noteEditFooter}>
          <Button
            sx={classes.notesCancelButton}
            endIcon={<CloseIcon />}
            onClick={() => {
              setNoteError('');
              setEditMode(0);
            }}
          >
            Cancel
          </Button>
          <Button
            sx={classes.notesEditSaveButton}
            onClick={handleSaveNote}
            disabled={!editedNote}
            endIcon={isSending ? <CircularProgress size={16} /> : <DoneIcon />}
            id="save-job-note-button"
            data-testid="save-job-note-button"
          >
            Save
          </Button>
        </Box>
      </Box>
      <NoteList
        notes={notes}
        editMode={editMode}
        isDeleting={isDeleting}
        isLoading={isLoading}
        editingId={editingId}
        handleNewNote={handleNewNote}
        handleEditNote={handleEditNote}
        handleDeleteNote={handleDeleteNote}
        permissions={permissions}
      />
      <GenericDialog
        url={''}
        title={'Are you sure you want to delete this note?'}
        description={'This action cannot be undone.'}
        buttonText={'Delete Note'}
        buttonCallback={deleteNote}
        callbackLoading={isDeleting}
        isDialogOpen={isDialogOpen}
        setDialogOpen={setIsDialogOpen}
        dialogId={editingId}
      />
    </>
  );
}
