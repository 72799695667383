"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export const fetchSummary = (jobDescription, adType, positionTitle, customDescription, tone, signal) => __async(void 0, null, function* () {
  const res = yield fetch("/api/v4/ai_studio", {
    signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ai_studio: {
        type: "advertisement",
        job_description: jobDescription,
        ad_type: adType,
        job_details: { position_title: positionTitle },
        use_custom_description: customDescription,
        tone
      }
    })
  });
  return yield res.json();
});
export const fetchKeyPoints = (jobDescription, positionTitle) => __async(void 0, null, function* () {
  const res = yield fetch("/api/v4/ai_studio", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ai_studio: {
        type: "keypoints",
        job_description: jobDescription,
        job_details: { position_title: positionTitle }
      }
    })
  });
  return yield res.json();
});
export const fetchSocial = (jobDescription, positionTitle, customDescription, includeHashtags, tone, length, signal) => __async(void 0, null, function* () {
  const res = yield fetch("/api/v4/ai_studio", {
    signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ai_studio: {
        type: "social",
        job_description: jobDescription,
        job_details: { position_title: positionTitle },
        use_custom_description: customDescription,
        include_hashtags: includeHashtags,
        tone,
        summary_length: length
      }
    })
  });
  return yield res.json();
});
