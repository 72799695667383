import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { styles } from './styles';
import { MultilineFormTextField } from '../Components/CustomUIElements/FormTextField';
import ModalFooterButtons from '../Components/GenericModal/ModalFooterButtons';

export default function Notepad({
  note,
  updateNote,
  updatingNote,
  inputId,
  buttonId
}: {
  note?: string;
  updateNote: (note: string) => void;
  updatingNote: boolean;
  inputId?: string;
  buttonId?: string;
}) {
  const [notepad, setNotepad] = useState<string>(note || '');

  return (
    <Stack sx={{ ...styles.candidateSectionContainer, rowGap: 1 }}>
      <Stack sx={styles.candidateSectionHeader}>Notepad</Stack>
      <MultilineFormTextField
        value={notepad}
        onChange={(e) => setNotepad(e.target.value)}
        placeholder="Write a comment here..."
        id={inputId || 'candidate-notepad-input'}
        rows={5}
      />
      <ModalFooterButtons
        primaryButtonText="Save"
        primaryButtonID={buttonId || 'candidate-save-notepad-button'}
        primaryButtonCallback={() => updateNote(notepad)}
        isLoading={updatingNote}
      />
    </Stack>
  );
}
