import React from 'react';
import Box from '@mui/material/Box';
import { FormTextField, MultilineFormTextField } from '../CustomUIElements/FormTextField';
import FormRadioButtonGroup from '../CustomUIElements/FormRadioButtonGroup';
import FormCheckboxGroup from '../CustomUIElements/FormCheckboxGroup';
import FormSelectField from '../CustomUIElements/FormSelectField';
import DebounceInput from './DebounceInput';
import { UncontrolledSingleDatePicker } from '../CustomUIElements/SingleDatePicker';
import { classes } from './styles';
import dompurify from 'dompurify';
import FormRankField from '../CustomUIElements/FormRankField';

export interface IApplicantQuestion {
  id: number;
  required: boolean;
  position: number;
  title: string;
  type: string;
  field_choices: IFieldChoice[];
  field_type: string;
  availability_config: IAvailabilityConfig;
  weight: number;
  enabled: boolean;
  editable: boolean;
  deletable: boolean;
  rateable: boolean;
  answered: boolean;
  enabled_for_summary: boolean;
  is_approval_form_title: boolean | null;
  for_email_template: boolean;
  confidential: boolean;
}

export interface IAvailabilityConfig {
  allow_break: boolean;
  created_at: string;
  field_id: number;
  heading_1: string;
  heading_2: string;
  id: number;
  updated_at: string;
  week_duration: string;
  week_start_day: number;
}

export interface IFieldChoice {
  id: number;
  name: string;
  position: number;
}

export default function QuestionFieldViewer({
  question,
  draggable
}: {
  question: IApplicantQuestion;
  draggable?: boolean;
}) {
  switch (question.type) {
    case 'RadioButtonField':
      return (
        <Box>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(question.title.replace(/\n/g, '<br>'))
            }}
          />
          <FormRadioButtonGroup
            options={question.field_choices.map((choice) => ({
              label: choice.name,
              value: choice.id
            }))}
          />
        </Box>
      );
    case 'CheckBoxField':
      return (
        <Box>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.title) }}
          />
          <Box sx={{ right: '12px' }}>
            <FormCheckboxGroup options={question.field_choices} />
          </Box>
        </Box>
      );
    case 'TextField':
      return (
        <Box>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.title) }}
          />
          <DebounceInput>{({ value }) => <FormTextField value={value} />}</DebounceInput>
        </Box>
      );
    case 'TextAreaField':
      return (
        <Box>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.title) }}
          />
          <DebounceInput>
            {({ value }) => (
              <MultilineFormTextField
                value={value}
                styles={{ minWidth: '300px', maxWidth: '600px' }}
              />
            )}
          </DebounceInput>
        </Box>
      );
    case 'SelectField':
      return (
        <Box>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.title) }}
          />
          <FormSelectField
            options={question.field_choices.map((choice) => ({
              label: choice.name,
              value: choice.id
            }))}
            defaultValue={''}
            styles={{
              width: '222px',
              '& .MuiInputBase-input': {
                fontSize: '14px'
              }
            }}
            onChange={{}}
          />
        </Box>
      );
    case 'RankField':
      return (
        <Box>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.title) }}
          />
          <Box sx={classes.questionsRankInput}>
            <FormRankField choices={question.field_choices} />
          </Box>
        </Box>
      );
    case 'DateField':
      return (
        <Box>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.title) }}
          />
          <Box sx={{ marginTop: '10px', ...classes.formDateWrapper }}>
            <UncontrolledSingleDatePicker defaultValue={null} disabled={true} />
          </Box>
        </Box>
      );
    case 'CsvDataLookupField':
      return (
        <Box>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.title) }}
          />
          <FormSelectField
            options={['Please choose...', 'Existing Position', 'New Position']}
            defaultValue={'Please choose...'}
            styles={{ width: '222px' }}
            onChange={{}}
          />
        </Box>
      );
    case 'AvailabilityField':
      return (
        <Box>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.title) }}
          />
          {/*
           * Availability fields have previews which don't work well with this flex layout hence
           * they're rendered separated from this row
           */}
        </Box>
      );
    case 'JobReferenceLookupField':
      return null;
    default:
      return (
        <Box>
          <Box ref={questionRef}>{question.title}</Box>
          Invalid question type
        </Box>
      );
  }
}
