var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useEffect, useState } from "react";
import { Dialog, Snackbar } from "@material-ui/core";
import useStyles from "./useStyles";
import { withScoutTheme } from "../ThemeContext/ThemeContext";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import axios from "axios";
import queryString from "query-string";
import Messenger from "./components/Messenger";
import SelectRecipients from "./components/SelectRecipients";
function TextUsModal({ applicationId, job, open }) {
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState(null);
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [isGroupMessage, setIsGroupMessage] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [largeModal, setLargeModal] = useState(true);
  const [isOpen, setIsOpen] = useState(open);
  const [snack, setSnack] = useState(null);
  const classes = useStyles();
  const token = document.querySelector('meta[name="csrf-token"]').content;
  const config = {
    credentials: "same-origin",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": token
    }
  };
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  useEffect(() => {
    const toggleTextusModal = (e) => {
      if (!e.target.classList.contains("toggle-textus-btn")) return;
      e.preventDefault();
      setIsGroupMessage(e.target.dataset.isSelectModalOpen === "true");
      openModal();
    };
    document.addEventListener("click", toggleTextusModal);
    return () => document.removeEventListener("click", toggleTextusModal);
  }, [isOpen]);
  useEffect(() => {
    function getData() {
      return __async(this, null, function* () {
        setLargeModal(true);
        setLoading(true);
        try {
          const { data } = yield axios.get(`/admin/jobs/${job.id}/applications/`, __spreadProps(__spreadValues({}, config), {
            params: { application_ids: applicationId },
            paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: "bracket" })
          }));
          setApplications(data.applications);
          if (isGroupMessage) {
            setIsSelectModalOpen(true);
          } else {
            setIsSelectModalOpen(false);
            setSelectedApplications(data.applications);
          }
          setStatuses([
            {
              id: null,
              state: "new",
              name: "New",
              colour: ""
            },
            ...data.statuses
          ]);
        } catch (e) {
          setSnack("Could not fetch applicant data");
        } finally {
          setLoading(false);
        }
      });
    }
    getData();
  }, [isGroupMessage, isOpen]);
  if (loading) return /* @__PURE__ */ React.createElement(ReactLoading, { type: "spin", color: "#004E70", width: 45, className: classes.loading });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Dialog,
    {
      disableEnforceFocus: true,
      fullWidth: true,
      open: isOpen,
      onClose: closeModal,
      classes: { paper: `${classes.modalRoot} ${largeModal ? classes.modalLarge : ""}` }
    },
    isSelectModalOpen ? /* @__PURE__ */ React.createElement(
      SelectRecipients,
      {
        statuses,
        applications,
        setSelectedApplications,
        setIsSelectModalOpen
      }
    ) : /* @__PURE__ */ React.createElement(
      Messenger,
      {
        job,
        statuses,
        applications,
        selectedApplications,
        setSelectedApplications,
        setLargeModal,
        closeModal,
        setSnack,
        isGroupMessage,
        setIsGroupMessage,
        token,
        config
      }
    )
  ), /* @__PURE__ */ React.createElement(
    Snackbar,
    {
      open: snack,
      onClose: () => setSnack(null),
      message: snack
    }
  ));
}
TextUsModal.propTypes = {
  applicationId: PropTypes.number,
  job: PropTypes.object.isRequired,
  isSelectModalOpen: PropTypes.bool
};
export default withScoutTheme(TextUsModal);
