import React, { useState, useRef, useContext } from 'react';
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { classes } from './styles';
import BarGraph from '../../NewUI/Components/Charts/TImeToHireBarGraph';
import StackedHorizontalBarGraph from '../../NewUI/Components/Charts/ApplicationSourcesStackedHorizontalBarGraph';
import ARStackedHorizontalBarGraph from '../../NewUI/Components/Charts/ActivityReportStackedHorizontalBarGraph';
import ARStackedBarGraph from '../../NewUI/Components/Charts/ActivityReportStackedBarGraph';
import StyledSnackbar from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';
import useReport from './useReport';
import { updateEntityOptions } from './GraphDefaultOptions';
import RecruitApi from '../RecruitApi';
import { useQuery } from '@tanstack/react-query';
import OverviewMetrics from './OverviewMetrics';
import { IEntity } from '../SystemReports/types';
import { ReportContext } from '../index';

const DataVizSection = () => {
  const refChartActivityReportWithUsers = useRef<HTMLDivElement>(null);
  const refChartActivityReportWithOutUsers = useRef<HTMLDivElement>(null);
  const [selectedChart, setSelectedChart] = useState('TimeToHire');
  const [reportState, reportDispatch] = useReport();
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const reportServiceApi = useContext(ReportContext);

  useQuery({
    queryKey: ['fetchEntities'],
    queryFn: async () => {
      const { res } = await RecruitApi.getEntities();
      const { entities, current_entity } = res;
      if (current_entity) {
        const i = entities?.findIndex((x: IEntity) => current_entity?.id === x?.id) as number;
        if (i >= 0)
          entities[i] = { id: entities[i].id, name: `${entities[i].name} (parent entity)` };
      }
      reportDispatch({
        type: 'setChartData',
        payload: {
          loadingOptions: false,
          entitiesOptions: updateEntityOptions(entities)
        }
      });
      return res;
    },
    onError: () =>
      setSnackbar({
        message: 'There was an error with retrieving the reporting data',
        state: 'error'
      })
  });

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newSelected: string) => {
    if (newSelected !== null) {
      setSelectedChart(newSelected);
    }
  };

  return (
    <Box>
      {reportServiceApi.currentUser.admin && <OverviewMetrics setSnackbar={setSnackbar} />}
      <Box sx={classes.visualReportSection}>
        <Box
          sx={{
            marginTop: '20px',
            marginBottom: '40px'
          }}
        >
          <Box sx={{ ...sharedClasses.sectionSubHeader, paddingBottom: '2.5rem' }}>
            Visual Reports
          </Box>
          <ToggleButtonGroup
            value={selectedChart}
            exclusive
            onChange={handleToggle}
            aria-label="approval forms or templates"
          >
            <ToggleButton
              value="TimeToHire"
              sx={sharedClasses.toggleButton}
              id="visual-reports-time-to-hire-tab"
            >
              <Box sx={{ padding: '0 20px' }}>Time To Hire</Box>
            </ToggleButton>
            <ToggleButton
              value="ApplicationSources"
              sx={{ ...sharedClasses.toggleButton, borderRight: '1px solid #EEEEEE !Important' }}
              id="visual-reports-application-sources-tab"
            >
              <Box sx={{ padding: '0 10px' }}>Application Sources</Box>
            </ToggleButton>
            <ToggleButton
              value="ActivityReport"
              sx={{ ...sharedClasses.toggleButton, borderLeft: '1px solid #EEEEEE !Important' }}
              id="visual-reports-activity-report-tab"
            >
              <Box sx={{ padding: '0 10px' }}>Activity Report</Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          {selectedChart === 'TimeToHire' && (
            <BarGraph
              chartData={reportState.timeToHire}
              reportState={reportState}
              reportDispatch={reportDispatch}
            />
          )}
          {selectedChart === 'ApplicationSources' && (
            <StackedHorizontalBarGraph
              chartData={reportState.applicationSources}
              reportState={reportState}
              reportDispatch={reportDispatch}
            />
          )}
          {selectedChart === 'ActivityReport' && (
            <Box>
              <ARStackedBarGraph
                chartData={reportState.statusActivitiesWithActor}
                reportState={reportState}
                reportDispatch={reportDispatch}
                refChart={refChartActivityReportWithUsers}
              />
              <ARStackedHorizontalBarGraph
                chartData={reportState.statusActivitiesWithoutActor}
                reportState={reportState}
                refChartWithoutActors={refChartActivityReportWithOutUsers}
                refChartWithActors={refChartActivityReportWithUsers}
              />
            </Box>
          )}
        </Box>
        <StyledSnackbar
          message={snackbar.message}
          state={snackbar.state}
          setSnackbarState={setSnackbar}
        />
      </Box>
    </Box>
  );
};

export default DataVizSection;
