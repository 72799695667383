import React, { Dispatch, useState } from 'react';
import { Stack, Button, Tabs, Tab, Divider } from '@mui/material';
import { CandidateAction, CandidateState, ICandidateTask } from '../types';
import CandidateTasksTab from './CandidateTasksTab';
import { useQuery } from '@tanstack/react-query';
import Api from '../API';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { styles } from '../styles';
import AddEditTaskModal from './AddEditTaskModal';
import { TCandidate } from '../../Candidates/types';
import TaskWorkflowModal from './TaskWorkflowModal';
import { IUserPermissions } from '../../Components/sharedTypes';

export default function CandidateTasks({
  candidate,
  CandidateState,
  dispatch,
  permissions
}: {
  candidate: TCandidate;
  CandidateState: CandidateState;
  dispatch: Dispatch<CandidateAction>;
  permissions?: IUserPermissions;
}) {
  const [tabValue, setTabValue] = useState<number>(0);

  const { data: tasks, isFetching: fetchingTasks } = useQuery({
    queryKey: ['tasks'],
    queryFn: async () => {
      const { res } = await Api.getCandidateTasks(candidate.id);
      return res as { tasks: ICandidateTask[] };
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting candidate data, ${error}`,
          state: 'error'
        }
      })
  });

  const { data: assignableUsers, isLoading: loadingAssignableUsers } = useQuery({
    queryKey: ['assignable users'],
    queryFn: async () => {
      const { res } = await Api.getAssignableUsers();
      return res;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting assignable users data, ${error}`,
          state: 'error'
        }
      })
  });

  return (
    <Stack sx={styles.candidateSectionContainer}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Stack sx={styles.candidateSectionHeader}>Tasks</Stack>
        <Stack sx={{ flexDirection: 'row', columnGap: 2 }}>
          <Button
            id="add-task-button"
            sx={sharedClasses.genericButtonSecondary}
            onClick={() => dispatch({ type: 'OPEN_TASK_MODAL', payload: true })}
          >
            Add task
          </Button>
          {permissions?.Entities?.['Task Workflow'] && (
            <Button
              id="start-workflow-button"
              sx={sharedClasses.genericButtonSecondary}
              onClick={() => dispatch({ type: 'OPEN_TASK_WORKFLOW_MODAL', payload: true })}
            >
              Start workflow
            </Button>
          )}
        </Stack>
      </Stack>
      <Tabs
        value={tabValue}
        onChange={(e, value) => setTabValue(value)}
        TabIndicatorProps={{
          sx: { ...styles.candidateTabIndicator, maxWidth: tabValue ? '5.6em' : '4.3em' }
        }}
        sx={styles.candidateTabs}
      >
        <Tab label="Pending" id="pending-tab" />
        <Tab label="Completed" id="completed-tab" />
      </Tabs>
      <Divider />
      <CandidateTasksTab
        tasks={tasks?.tasks}
        tabValue={tabValue}
        fetchingTasks={fetchingTasks}
        dispatch={dispatch}
        candidateId={candidate.id}
      />
      {tasks && CandidateState.candidateTaskModalIsOpen && (
        <AddEditTaskModal
          tasks={tasks.tasks}
          candidate={candidate}
          assignableUsers={assignableUsers.assignable_users}
          loadingAssignableUsers={loadingAssignableUsers}
          CandidateState={CandidateState}
          dispatch={dispatch}
        />
      )}
      {CandidateState.taskWorkflowModalIsOpen && (
        <TaskWorkflowModal
          candidate={candidate}
          assignableUsers={assignableUsers.assignable_users}
          loadingAssignableUsers={loadingAssignableUsers}
          CandidateState={CandidateState}
          dispatch={dispatch}
        />
      )}
    </Stack>
  );
}
