import React from 'react'
import { Button, Modal, Box } from '@material-ui/core'
import ErrorIcon from '@mui/icons-material/Error';
import { withScoutTheme } from '../../ThemeContext/ThemeContext';
import useStyles from './useStyles'

function BadAPIModal({invalidAPIKey, hasAPIKey}: {invalidAPIKey: boolean, hasAPIKey: boolean}) {

  const classes = useStyles()
  const BASE_URL = document.location.origin

  return (
    <Modal
      open={invalidAPIKey}
      aria-labelledby="no api modal"
      aria-describedby="this modal appears when the API key is invalid or missing"
    >
      <Box className={classes.badAPIModalInner}>
        <ErrorIcon 
          sx={{
            fontSize: 70,
            color: 'rgb(208, 119, 114)',
            filter: 'drop-shadow(2px 2px 1px rgb(0 0 0 / 0.2))'
          }} 
        />
        <h1>Error</h1>
        <p>
          {hasAPIKey
            ? "The Calendly API token attached to your account is invalid."
            : "You need an API token to access Calendly."
          }
        </p>
        <p>To find your token, go to</p>
        <a href="https://calendly.com/integrations/api_webhooks" target="_blank" rel="noopener noreferrer">
          https://calendly.com/integrations/api_webhooks
        </a>
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '30px',
            width: '65%'
          }}
        >
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {window.location.href = `${BASE_URL}/admin/`}}
        >
          Back to home
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {window.location.href = `${BASE_URL}/admin/settings/`}}
        >
          {hasAPIKey
            ? "Edit API token"
            : "I have a token"
          }
        </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default withScoutTheme(BadAPIModal);