"use strict";
import RecruitApiClient from "../../RecruitApiClient";
class Api {
  constructor() {
    this.getPreferences = () => RecruitApiClient.get(`/api/v4/ui_preferences?source=smart_forms`, null);
    this.getDensityPreferences = () => RecruitApiClient.get(`/api/v4/ui_preferences?source=universal`, null);
    this.getSmartForms = (params) => RecruitApiClient.get(`/api/v4/smart_forms`, params);
    this.getSmartForm = (formId) => RecruitApiClient.get(`/api/v4/smart_forms/${formId}`, {});
    this.getRequisitionForms = (params) => RecruitApiClient.get(`/api/v4/requisition_forms`, params);
    this.getJobs = (params) => RecruitApiClient.get(`/api/v4/jobs`, params);
    this.deleteSmartForm = (formId) => RecruitApiClient.delete(`/api/v4/smart_forms/${formId}`, {});
    this.postUserPreferences = (params) => RecruitApiClient.post(`/api/v4/ui_preferences`, params);
    this.postSmartForm = (params) => RecruitApiClient.post(`/api/v4/smart_forms`, params);
    this.updateSmartForm = (formId, params) => RecruitApiClient.put(`/api/v4/smart_forms/${formId}`, params);
    this.newField = (formId, params) => RecruitApiClient.post(`/api/v4/fields?form_id=${formId}`, params);
    this.getSystemFields = () => RecruitApiClient.get(`/api/v4/system_fields?form_type=Form`, {});
    this.addSystemField = (formId, systemFieldId) => RecruitApiClient.post(
      `/api/v4/system_fields?form_id=${formId}&system_field_id=${systemFieldId}`,
      {}
    );
    this.importFields = (formId, sourceType, selectedId) => RecruitApiClient.post(`/api/v4/fields/import`, {
      source_type: sourceType,
      source_id: selectedId,
      form_id: formId
    });
    this.getQuestions = (params) => RecruitApiClient.get(`/api/v4/fields`, params);
    this.putQuestion = (id, formId, params) => RecruitApiClient.put(`/api/v4/fields/${id}?form_id=${formId}`, params);
    this.deleteQuestion = (id, formId) => RecruitApiClient.delete(`/api/v4/fields/${id}?form_id=${formId}`, {});
    this.cloneQuestion = (id, formId) => RecruitApiClient.post(`/api/v4/fields/${id}/clone?form_id=${formId}`, {});
    this.getTargetEntities = () => RecruitApiClient.get(`/api/v4/target_entities`, {});
    this.cloneForm = (formId, params) => RecruitApiClient.post(`/api/v4/smart_forms/${formId}/clone`, params);
    this.updateQuestions = (formId, params) => RecruitApiClient.put(`/api/v4/smart_forms/${formId}/update_questions`, params);
  }
}
export default new Api();
