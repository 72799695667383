import { Box, CircularProgress, InputBase, Slider, styled } from '@mui/material';
import React, { useState } from 'react';
import { sharedClasses } from '../../CustomUIElements/sharedClasses';
import { handleAPIRequest } from '../../Utilities/handleAPIRequest';
import { useDebouncedCallback } from 'use-debounce';
import { ISnackbarInput } from '../../CustomUIElements/StyledSnackbar';

const DEFAULT_RADIUS = 2;
const DEFAULT_MIN_RADIUS = 1;
const DEFAULT_MAX_RADIUS = 20;
const SEARCH_DELAY = 900;

const RadiusSearchFilter = ({
  setPostcodes,
  setSnackbarState
}: {
  setPostcodes: (postcodes: string[]) => void;
  setSnackbarState: (snackbarState: ISnackbarInput) => void;
}) => {
  const [inputPostcode, setInputPostcode] = useState('');
  const [inputRadius, setInputRadius] = useState(DEFAULT_RADIUS);
  const [isLoading, setIsLoading] = useState(false);

  const handleRadiusSearch = useDebouncedCallback((postcode, radius) => {
    if (!postcode && !inputPostcode) return;
    handleAPIRequest({
      method: 'POST',
      url: `/api/v4/searches/find_nearby_postcodes`,
      body: {
        postcode: postcode || inputPostcode,
        radius: radius || inputRadius
      },
      setLoaderState: setIsLoading,
      setSnackbarState,
      successMessage: { show: false },
      onSuccess: (response: { postal_codes: string[] }) => {
        setPostcodes(response.postal_codes);
      }
    });
  }, SEARCH_DELAY);

  return (
    <Box>
      <StyledInput
        value={inputPostcode}
        onChange={(event) => {
          if (isLoading) return;
          setInputPostcode(event.target.value);
          handleRadiusSearch(event.target.value, inputRadius);
        }}
        placeholder="Input a postcode"
        endAdornment={
          isLoading && (
            <Box sx={adornmentContainer}>
              <CircularProgress size={18} />
            </Box>
          )
        }
      />
      <Box sx={sliderContainer} id="radius-search-filter">
        Within
        <Box>
          <Slider
            sx={sharedClasses.ratingSlider}
            aria-label="Radius search slider"
            value={inputRadius}
            onChange={(event, value) => {
              if (isLoading) return;
              setInputRadius(value as number);
              handleRadiusSearch(inputPostcode, value as number);
            }}
            valueLabelDisplay="on"
            valueLabelFormat={(value: number) => `${value}km`}
            getAriaValueText={(value: number) => `${value}km`}
            min={DEFAULT_MIN_RADIUS}
            max={DEFAULT_MAX_RADIUS}
          />
        </Box>
      </Box>
    </Box>
  );
};

const sliderContainer = {
  fontFamily: 'Source Sans Pro',
  fontSize: '10px',
  color: '#084D6D',
  padding: '6px 9px 0px 9px'
};

const adornmentContainer = {
  height: '100%',
  display: 'flex',
  alignItems: 'center'
};

const StyledInput = styled(InputBase)(() => ({
  width: '100%',
  height: '41px',
  border: '1px solid #DADEE0',
  backgroundColor: '#ffffff',
  borderRadius: '6px',
  padding: '6px 22px 6px 6px',
  display: 'flex',
  marginTop: '3px',
  '&:focus-within': {
    borderColor: '#77C2BE',
    boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)'
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Source Sans Pro',
    fontSize: '12px',
    color: '#000000',
    paddingLeft: '6px',
    height: '26px'
  },
  '& input': {
    '&::placeholder': {
      color: '#000000'
    }
  }
}));

export default RadiusSearchFilter;
