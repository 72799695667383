"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useReducer } from "react";
import { dateRangeOptions } from "../DataVizSection/GraphDefaultOptions";
import moment from "moment";
const getDefaultSearchState = () => ({
  timeFrameDates: {
    dateRange: dateRangeOptions[1],
    startDate: moment().subtract(30, "days").toDate(),
    endDate: moment().toDate()
  },
  scheduleDate: {
    startDate: moment().subtract(dateRangeOptions[0].value, "days").toDate(),
    dateRange: dateRangeOptions[0]
  },
  frequency: dateRangeOptions[1],
  sendTo: ""
});
function searchReducer(state, action) {
  switch (action.type) {
    case "TIME_FRAME_DATES": {
      return __spreadProps(__spreadValues({}, state), {
        timeFrameDates: __spreadValues(__spreadValues({}, state.timeFrameDates), action.payload)
      });
    }
    case "SET_DATE":
      return __spreadProps(__spreadValues({}, state), {
        scheduleDate: {
          dateRange: action.payload.dateRange,
          startDate: action.payload.startDate
        }
      });
    case "SET_FREQUENCY":
      return __spreadProps(__spreadValues({}, state), {
        frequency: action.payload
      });
    case "SET_SEND_TO":
      return __spreadProps(__spreadValues({}, state), {
        sendTo: action.payload
      });
    default:
      throw new Error();
  }
}
export default function useReport() {
  return useReducer(searchReducer, getDefaultSearchState());
}
