import React, { Dispatch, SetStateAction } from 'react';
import { Stack } from '@mui/material';
import GenericDialog from '../../Components/Modals/GenericDialog';

export function ExitConfirmationModal({
  exitConfirmation,
  setExitConfirmation,
  handleClose
}: {
  exitConfirmation: boolean;
  setExitConfirmation: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
}) {
  const ExitDialogDescription = () => (
    <Stack>
      <Stack>Are you sure you want to cancel editing the form?</Stack>
      <Stack>You will lose any progress you have made.</Stack>
    </Stack>
  );

  return (
    <GenericDialog
      isDialogOpen={exitConfirmation}
      setDialogOpen={setExitConfirmation}
      title="Close without submitting?"
      description={ExitDialogDescription()}
      buttonCallback={handleClose}
      callbackLoading={false}
      buttonText="Yes, close"
      secondaryButtonText="Back to editing"
      url=""
    />
  );
}
