import React, { useContext } from 'react';
import { Box, Skeleton } from '@mui/material';
import { ReportContext } from '../index';
import EmptyApplicantsSVG from '../EmptyApplicantsSVG';
import { useReportingData } from '../hooks';
import { classes } from './styles';
import { TableWithSearchFieldDropDownAndFilter } from '../../../TableWithSearchFieldDropDownAndFilter';
import ReportTableBody from './MyReportTable/ReportTableBody';

const MyReportSection = ({
  selectedReport,
  tableState,
  dispatch
}: {
  selectedReport: string;
  tableState: any;
  dispatch: any;
}) => {
  const reportServiceApi = useContext(ReportContext);

  const {
    hasData,
    refetch: refetchForms,
    isLoading
  } = useReportingData(tableState, dispatch, reportServiceApi.reports.getSavedReports, 'myReports');

  if (isLoading) {
    return (
      <Box>
        {[...Array(5)].map((_, index) => (
          <Box key={index} sx={{ padding: '10px 0px' }}>
            <Skeleton animation="wave" height={60} />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box>
      {hasData ? (
        <Box>
          {selectedReport === 'SavedReports' ? (
            <TableWithSearchFieldDropDownAndFilter
              tableState={tableState}
              dispatch={dispatch}
              tableBody={
                <ReportTableBody
                  state={tableState}
                  dispatch={dispatch}
                  refetchForms={refetchForms}
                />
              }
              fetchData={() => refetchForms()}
              filtersComponent={null}
              fieldComponent={false}
              stickyColumnSettings={{ leftBoundOffSet: -255, rightBoundOffSet: -215 }}
            />
          ) : (
            <Box>Schedule table to come....</Box>
          )}
        </Box>
      ) : (
        <Box sx={{ ...classes.emptyStateContainer, display: 'flex' }}>
          <Box sx={{ marginLeft: '2rem' }}>
            <EmptyApplicantsSVG />
          </Box>
          <Box sx={classes.noReportsText}>
            <Box>
              You have no {selectedReport === 'SavedReports' ? 'saved' : 'scheduled'} saved reports
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MyReportSection;
