import React from 'react';

export default function NewSystemFieldSVG() {
return (<svg id="MDI_alpha-s-box" data-name="MDI / alpha-s-box" xmlns="http://www.w3.org/2000/svg" width="20.719" height="20.719" viewBox="0 0 18.719 18.719">
<g id="Boundary" fill="#666" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
  <rect width="19.719" height="19.719" stroke="none"/>
  <rect x="0.5" y="0.5" width="18.719" height="18.719" fill="none"/>
</g>
<path id="Path_alpha-s-box" data-name="Path / alpha-s-box" d="M9.1,6.049A1.524,1.524,0,0,0,7.573,7.573V9.1A1.524,1.524,0,0,0,9.1,10.622h1.524v1.524H7.573V13.67h3.049a1.524,1.524,0,0,0,1.524-1.524V10.622A1.524,1.524,0,0,0,10.622,9.1H9.1V7.573h3.049V6.049H9.1M4.524,3h10.67a1.524,1.524,0,0,1,1.524,1.524v10.67a1.524,1.524,0,0,1-1.524,1.524H4.524A1.524,1.524,0,0,1,3,15.194V4.524A1.524,1.524,0,0,1,4.524,3Z" transform="translate(0)" fill="#666"/>
</svg>
)
}
