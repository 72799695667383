"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export const useV4Api = ({ apiKey }) => {
  const callApi = (path, method, jsonBody) => __async(void 0, null, function* () {
    const response = yield fetch(`/api/v4${path}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        "X-api-authenticate": apiKey
      },
      body: jsonBody
    });
    return response;
  });
  const callApiWithResponseBody = (path, method, jsonBody) => __async(void 0, null, function* () {
    const response = yield callApi(path, method, jsonBody);
    const json = yield response.json();
    return json;
  });
  const apiGet = (path) => __async(void 0, null, function* () {
    const response = yield callApiWithResponseBody(path, "GET");
    return response;
  });
  const apiPost = (path, jsonBody) => __async(void 0, null, function* () {
    const response = yield callApi(path, "POST", jsonBody);
    return response;
  });
  const apiPut = (path, jsonBody) => __async(void 0, null, function* () {
    const response = yield callApi(path, "PUT", jsonBody);
    return response;
  });
  const apiDelete = (path) => __async(void 0, null, function* () {
    const response = yield callApi(path, "DELETE");
    return response;
  });
  return { apiGet, apiPost, apiPut, apiDelete };
};
