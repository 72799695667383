import React, { FC } from 'react';
import Box from '@mui/material/Box';
import EventIcon from '@mui/icons-material/Event';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment-timezone';
import { ICreationExpiryProps, IDateWidget } from '../types';
import { classes } from './styles';
import { theme } from '../../../ThemeContext/ThemeObject';

export default function CreationExpiry({
  createdAt,
  publishedAt,
  expiresAt,
  isLoading,
  timeZone
}: ICreationExpiryProps) {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const jobTimeZone = timeZone ? timeZone : timeZoneString;

  const expiryDateColor = (date: string) => {
    const dayToExpired = (new Date(date).getTime() - Date.now()) / (1000 * 60 * 60 * 24);
    if (dayToExpired < 0) {
      return theme.palette.error.main;
    } else if (dayToExpired < 1) {
      return theme.palette.secondary.yellow;
    } else {
      return '#333333';
    }
  };

  return (
    <Box sx={{ ...classes.subWidget, border: '1px solid #E3E3E3' }}>
      <Box sx={classes.creationExpiryContainer}>
        <Box sx={classes.widgetIcon}>
          <EventIcon />
        </Box>
        <DateWidget
          isLoading={isLoading}
          label="Creation Date"
          timezone={jobTimeZone}
          utcDate={createdAt}
        />
        <DateWidget
          isLoading={isLoading}
          label="Published Date"
          timezone={jobTimeZone}
          utcDate={publishedAt}
        />
        <DateWidget
          isLoading={isLoading}
          label="Expiry Date"
          sx={{ color: expiryDateColor(expiresAt) }}
          timezone={jobTimeZone}
          utcDate={expiresAt}
        />
      </Box>
    </Box>
  );
}

const DateWidget: FC<IDateWidget> = ({ isLoading, label, timezone, utcDate, sx }) => {
  return (
    <Box sx={{ ...classes.widgetText, marginRight: '20px' }}>
      <Box sx={classes.widgetTextTitle}>{label}</Box>
      <Box sx={{ ...classes.widgetTextContent, ...sx }}>
        {isLoading ? (
          <Skeleton animation="wave" width={100} height={60} />
        ) : utcDate ? (
          <>
            <Box>{moment.tz(utcDate, timezone).format('DD MMM YY')},</Box>
            <Box>{moment.tz(utcDate, timezone).format('hh:mma z')}</Box>
          </>
        ) : (
          <Box sx={{ height: '40px' }}>-</Box>
        )}
      </Box>
    </Box>
  );
};
