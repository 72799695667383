import React, { Dispatch } from 'react';
import Box from '@mui/material/Box';
import Search from './Search';
import FieldDropdown from './FieldDropDown';
import FilterDropdown from './FilterDropDown';
import GenericTable from './GenericTable';
import StyledSnackbar from '../components/NewUI/Components/CustomUIElements/StyledSnackbar';
import { TableAction, TableState } from './types';

type Props = {
  tableState: TableState<any, any, any>;
  dispatch: Dispatch<TableAction<any, any>>;
  fetchData: (newFilter: boolean) => void;
  filtersComponent?: React.ReactNode;
  tableBody: React.ReactNode;
  fieldComponent: boolean;
  actionsComponent?: React.ReactNode;
  stickyColumnSettings?: {
    leftBoundOffSet: number;
    rightBoundOffSet: number;
  };
  pendoId?: string;
};
export const TableWithSearchFieldDropDownAndFilter = ({
  tableState,
  dispatch,
  fetchData,
  filtersComponent,
  fieldComponent,
  tableBody,
  actionsComponent,
  stickyColumnSettings,
  pendoId
}: Props) => {
  return (
    <Box sx={{ padding: '0px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          marginBottom: '30px',
          columnGap: '17px'
        }}
      >
        <Search dispatch={dispatch} fetchData={fetchData} state={tableState} />
        {fieldComponent && <FieldDropdown state={tableState} dispatch={dispatch} />}
        {filtersComponent && (
          <FilterDropdown state={tableState} dispatch={dispatch} applyFilters={fetchData}>
            {filtersComponent}
          </FilterDropdown>
        )}
        {actionsComponent}
      </Box>
      <Box sx={{ margin: '0' }}>
        <GenericTable
          state={tableState}
          dispatch={dispatch}
          tableBody={tableBody}
          stickyColumnSettings={stickyColumnSettings}
          pendoId={pendoId}
        />
      </Box>
      <StyledSnackbar
        message={tableState.snackbar.message}
        state={tableState.snackbar.state}
        setSnackbarState={() => {
          dispatch({ type: 'SET_SNACKBAR', payload: { message: '', state: 'error' } });
        }}
      />
    </Box>
  );
};
