import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';
import { classes } from './styles';
import { DataType } from '../types';
import { CustomReportsActionType, CustomReportsStateType } from '../types';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import { selectFieldsInputs } from '../helper';

interface SelectFieldsProps {
  dispatch: Dispatch<CustomReportsActionType>;
  reportState: CustomReportsStateType;
  fields: Array<string>;
  loadingCreateOptions: boolean;
  data: DataType;
  setData: Dispatch<SetStateAction<DataType>>;
}

const SelectFields = ({
  dispatch,
  reportState,
  fields,
  loadingCreateOptions,
  data,
  setData
}: SelectFieldsProps) => {
  const renderIcon = (field: string, i: number) => {
    const report: [
      'jobFields',
      'candidateFields',
      'applicationFields',
      'approvalFields',
      'eventInterviewFields',
      'smartFormFields'
    ] = [
      'jobFields',
      'candidateFields',
      'applicationFields',
      'approvalFields',
      'eventInterviewFields',
      'smartFormFields'
    ];
    const displayValue = data[report[i]]?.length > 0 ? data[report[i]]?.length : '';

    switch (field) {
      case 'Jobs':
        return (
          <Box sx={classes.fieldHeader}>
            <WorkOutlineIcon />
            {field}
            <Box>{displayValue}</Box>
          </Box>
        );
      case 'Candidates':
        return (
          <Box sx={classes.fieldHeader}>
            <PersonOutlinedIcon />
            {field}
            <Box>{displayValue}</Box>
          </Box>
        );
      case 'Applications':
        return (
          <Box sx={classes.fieldHeader}>
            <AssignmentOutlinedIcon />
            {field}
            <Box>{displayValue}</Box>
          </Box>
        );
      case 'Approval Forms':
        return (
          <Box sx={classes.fieldHeader}>
            <AssignmentTurnedInOutlinedIcon />
            {field}
            <Box>{displayValue}</Box>
          </Box>
        );
      case 'Events / Interviews':
        return (
          <Box sx={classes.fieldHeader}>
            <CalendarMonthOutlinedIcon />
            {field}
            <Box>{displayValue}</Box>
          </Box>
        );
      case 'Smart forms':
        return (
          <Box sx={classes.fieldHeader}>
            <BallotOutlinedIcon />
            {field}
            <Box>{displayValue}</Box>
          </Box>
        );
      default:
        <Box />;
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }} role="form">
      <Box sx={{ ...classes.leftSideContent, paddingTop: reportState.open ? 8 : 'unset' }}>
        <Box sx={{ ...classes.sectionHeader, padding: '0 0 50px 40px' }}>Select Fields</Box>
        {fields.map((field: string, i: number) => (
          <Box
            key={i}
            onClick={() => dispatch({ type: 'SET_ACTIVE_FIELD', payload: i })}
            sx={
              reportState.currentActiveField === i
                ? classes.sectionSubHeaderActive
                : classes.sectionSubHeader
            }
          >
            <Box
              sx={
                reportState.currentActiveField === i
                  ? classes.leftSideActiveHeader
                  : classes.leftSideHeader
              }
            />
            {renderIcon(field, i)}
          </Box>
        ))}
      </Box>
      <Box sx={classes.rightSideContent}>
        <Box sx={classes.formContent}>
          {selectFieldsInputs(
            reportState,
            data,
            setData,
            reportState.currentActiveField,
            loadingCreateOptions
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SelectFields;
