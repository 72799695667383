import React, { Dispatch, useEffect, useState } from 'react';
import { Collapse, Divider, Grid, Stack, ToggleButtonGroup, ToggleButton } from '@mui/material';
import {
  Info as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  ContentPaste as ContentPasteIcon
} from '@mui/icons-material';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { ApplicationAction, IApplication } from '../types';
import { styles } from '../styles';
import Rating from './Rating';
import ModalFooterButtons from '../../Components/GenericModal/ModalFooterButtons';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import Api from '../API';
import AvailabilityPreview from '../../ApprovalForms/Questions/Modals/AvailabilityPreview';
import dompurify from 'dompurify';
import { IUserPermissions } from '../../Components/sharedTypes';

export default function Responses({ dispatch }: { dispatch: Dispatch<ApplicationAction> }) {
  const [showRatingInfo, setShowRatingInfo] = useState<boolean>(false);
  const [ratings, setRatings] = useState<Record<number, number>>({});
  const [jobQuestions, setJobQuestions] = useState<string>('current');
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newSelected: string) => {
    if (newSelected !== null) {
      setJobQuestions(newSelected);
    }
  };

  const formatRatings = () => {
    const ratingsObject: { [key: number]: { field_value_id: number; rating: number } } = {};
    Object.keys(ratings)
      .filter((t) => ratings[Number(t)])
      .map(
        (m, index) =>
          (ratingsObject[index] = { field_value_id: Number(m), rating: ratings[Number(m)] })
      );
    return ratingsObject;
  };

  const { mutate: rateResponses, isLoading: ratingResponses } = useMutation({
    mutationFn: async () => {
      if (application) {
        if (Object.keys(formatRatings()).length === 0) {
          const { res } = await Api.resetRatingForApplication(application.job.id, application.id);
          return res;
        } else {
          const { res } = await Api.rateResponses(application.job.id, application.id, {
            ratings_attributes: formatRatings()
          });
          return res;
        }
      }
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Application responses has been rated successfully`,
          state: 'success'
        }
      });
      queryClient.setQueryData(['application'], {
        ...res,
        surround_application: application?.surround_application
      });
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in rating application responses, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const { isLoading: loadingRatings } = useQuery({
    queryKey: ['rating set'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getRatingSetForApplication(application.job.id, application.id);
        return res.ratings;
      }
    },
    onSuccess: (res) => {
      setRatings(res);
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting rating set for application, ${error}`,
          state: 'error'
        }
      })
  });

  const questions =
    jobQuestions === 'current' ? application?.job.questions : application?.original_job?.questions;

  const showRatings = !application?.original_job;

  if (!application) return null;

  return (
    <Stack
      sx={{
        padding: 3,
        borderRadius: '6px',
        border: '1px solid #E3E3E3',
        rowGap: 2
      }}
    >
      <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Responses</Stack>
      {application.original_job && (
        <Stack sx={{ paddingLeft: 1 }}>
          <ToggleButtonGroup
            value={jobQuestions}
            exclusive
            onChange={handleToggle}
            aria-label="approval forms or templates"
          >
            <ToggleButton value="current" sx={sharedClasses.toggleButton}>
              Current Job
            </ToggleButton>
            <ToggleButton value="original" sx={sharedClasses.toggleButton}>
              Original Job
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      )}
      {questions?.length ? (
        <>
          {application.ratings_label && (
            <Stack sx={styles.ratingInfoContainer}>
              <Stack
                sx={styles.ratingInfoClickable}
                onClick={() => setShowRatingInfo(!showRatingInfo)}
              >
                <Stack sx={{ flexDirection: 'row', columnGap: 1 }}>
                  <InfoIcon fontSize="small" />
                  <Stack sx={{ color: '#666666', fontWeight: 'bold' }}>Rating information</Stack>
                </Stack>
                {showRatingInfo ? (
                  <ExpandLessIcon fontSize="large" />
                ) : (
                  <ExpandMoreIcon fontSize="large" />
                )}
              </Stack>
              <Collapse in={showRatingInfo}>
                <Stack sx={{ rowGap: 0.5, padding: '0px 0px 8px 30px', color: '#666666' }}>
                  {Object.keys(application.ratings_label).map((key) => (
                    <Stack
                      key={key}
                      sx={{ flexDirection: 'row', columnGap: 0.5, alignItems: 'center' }}
                    >
                      <Stack sx={{ fontWeight: 'bold' }}>{key}</Stack>
                      <Divider sx={{ width: '8px', border: '1px solid #E5E5E5' }} />
                      <Stack>{application.ratings_label?.[Number(key)]}</Stack>
                    </Stack>
                  ))}
                </Stack>
              </Collapse>
            </Stack>
          )}
          <Stack sx={{ rowGap: 2, padding: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Stack sx={{ color: '#666666', fontWeight: 'bold' }}>Questions/Answers</Stack>
              </Grid>
              {showRatings && (
                <Grid item xs={3}>
                  <Stack sx={{ color: '#666666', fontWeight: 'bold' }}>Rating</Stack>
                </Grid>
              )}
            </Grid>
            {questions?.map((q) => {
              const answerFound = application.question_answers.find((r) => r.field_id === q.id);
              return (
                <Grid key={q.id} container spacing={2}>
                  <Grid item xs={9}>
                    <Stack sx={{ rowGap: 1 }}>
                      <Stack
                        key={q.id}
                        sx={{ color: '#084D6D', fontWeight: 'bold' }}
                        dangerouslySetInnerHTML={{
                          __html: dompurify.sanitize(q.title)
                        }}
                      />
                      {answerFound?.type === 'AvailabilityField' ? (
                        <AvailabilityPreview
                          availabilityConfig={q.availability_config}
                          answer={
                            typeof answerFound.answer === 'object' ? answerFound.answer : undefined
                          }
                          disabled
                          removePadding
                        />
                      ) : (
                        <Stack
                          sx={{
                            color: answerFound?.answer ? '#333333' : '#939393',
                            fontStyle: answerFound?.answer ? 'none' : 'italic'
                          }}
                        >
                          {answerFound
                            ? answerFound.answer || 'Question unanswered'
                            : 'Question unavailable to candidate'}
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                  {showRatings && !loadingRatings && (
                    <Grid item xs={3}>
                      {q.rateable && answerFound && (
                        <Rating
                          value={ratings[answerFound?.id]}
                          setValue={(rate) => {
                            rate === ratings[answerFound?.id]
                              ? setRatings({ ...ratings, [answerFound?.id]: 0 })
                              : setRatings({ ...ratings, [answerFound?.id]: rate });
                          }}
                          disabled={!permissions?.Applications?.['Rate Applications']}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              );
            })}
            {showRatings && permissions?.Applications?.['Rate Applications'] && (
              <ModalFooterButtons
                primaryButtonText="Rate"
                primaryButtonCallback={rateResponses}
                isLoading={ratingResponses}
                primaryButtonMinWidth="75px"
                primaryButtonID="rate-responses-button"
              />
            )}
          </Stack>
        </>
      ) : (
        <Stack sx={{ padding: 5, alignItems: 'center', rowGap: 2 }}>
          <ContentPasteIcon sx={styles.emptyStateIcon} />
          <Stack sx={styles.emptyStateMessage}>No questions found for this job</Stack>
        </Stack>
      )}
    </Stack>
  );
}
