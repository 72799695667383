"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
export const sharedTableInitialState = {
  density: "Default",
  maxPages: 1,
  maxRecords: 0,
  currentPage: 1,
  limit: 10,
  records: [],
  isLoading: true,
  searchItem: "",
  snackbar: {
    message: "",
    state: "success"
  },
  sort_by: "",
  sort_order: "desc",
  activeColumns: {},
  hasEmptyHeaderFirstColumn: true
};
export const sharedTableActionReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_PAGE":
      return __spreadProps(__spreadValues({}, state), {
        records: action.payload.records,
        maxPages: action.payload.maxPages,
        maxRecords: action.payload.maxRecords,
        currentPage: action.payload.currentPage,
        limit: action.payload.limit,
        isLoading: false
      });
    case "SET_SORTING":
      return __spreadProps(__spreadValues({}, state), {
        sort_by: action.payload.sort_by,
        sort_order: action.payload.sort_order,
        isLoading: true
      });
    case "SET_LOADING":
      return __spreadProps(__spreadValues({}, state), { isLoading: action.payload });
    case "SET_DENSITY":
      return __spreadProps(__spreadValues({}, state), { density: action.payload });
    case "SET_CURRENT_PAGE":
      return __spreadProps(__spreadValues({}, state), { currentPage: action.payload, isLoading: true });
    case "SET_LIMIT":
      return __spreadProps(__spreadValues({}, state), { limit: action.payload, isLoading: true, currentPage: 1 });
    case "SET_SNACKBAR":
      return __spreadProps(__spreadValues({}, state), {
        snackbar: action.payload
      });
    case "SET_SEARCH_ITEM":
      return __spreadProps(__spreadValues({}, state), {
        searchItem: action.payload,
        currentPage: 1
      });
    default:
      return state;
  }
};
