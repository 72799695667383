import { Box } from '@mui/material';
import React, { useState } from 'react';
import dashboardStyles from '../styles';
import CurrentJobs from '../../Jobs/JobsTable/CurrentJobs';
import { jobsConfig } from '../../Jobs/config';
import InfoIcon from '@mui/icons-material/Info';

interface IProps {
  userPermissions?: Record<string, Record<string, boolean>>;
  setSnackbar: React.Dispatch<
    React.SetStateAction<{
      message: string;
      state: 'success' | 'warning' | 'error';
    }>
  >;
}

function NewApplicants({ userPermissions, setSnackbar }: IProps) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');

  return (
    <Box id="dashboard-new-applicants" sx={{ ...dashboardStyles.section, flexBasis: '100%' }}>
      <Box sx={dashboardStyles.titleContainer}>
        <Box sx={dashboardStyles.sectionTitle}>Jobs with new Applications</Box>
        <Box sx={dashboardStyles.noticeContainer}>
          <InfoIcon sx={dashboardStyles.infoIcon} />
          <Box sx={{ marginLeft: 1 }}>
            New Applications are displayed in brackets in the &apos;Applications&apos; column
          </Box>
        </Box>
      </Box>
      <Box ml={5} mr={5}>
        <CurrentJobs
          apiKey={''}
          sortableColumns={jobsConfig.sortableColumnsDefaults.map((column) => {
            switch (column.id) {
              case 'expires_at':
                return { ...column, enabled: true };
              case 'notes':
                return { ...column, enabled: true };
              case 'assigned_user':
                return { ...column, enabled: true };
              case 'recruitment_ad_user':
                return { ...column, enabled: true };
              default:
                return column;
            }
          })}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          search={''}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filters={filters}
          fetchUISwitch={false}
          page={page}
          setPage={setPage}
          filtersApplied={false}
          userPermissions={userPermissions}
          setSnackbarState={setSnackbar}
          density="Default"
          jobStatusFeature={true}
          showNewApplicants
          hideArrows
        />
      </Box>
    </Box>
  );
}

const filters = {
  state: [],
  status: [],
  entity: [],
  location: [],
  assigned_user: [],
  recruitment_ad_user: [],
  published: [],
  expiry: [],
  pinned: true
};

export default NewApplicants;
