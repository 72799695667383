import React, { Dispatch, SetStateAction } from 'react';
import { Box, Checkbox } from '@mui/material';
import { classes } from '../styles';
import { defaultInclusions } from '../config';

const Inclusions = ({
  inclusions,
  setInclusions
}: {
  inclusions: typeof defaultInclusions;
  setInclusions: Dispatch<SetStateAction<typeof defaultInclusions>>;
}) => {
  return (
    <Box sx={classes.inclusionsContainer}>
      {Object.keys(inclusions).map((inclusion) => (
        <Box sx={classes.checkboxGroup} key={inclusion}>
          <Checkbox
            sx={classes.inclusionsCheckbox}
            checked={inclusions[inclusion].value}
            onChange={(e, checked) =>
              setInclusions((prev) => ({
                ...prev,
                [inclusion]: { ...prev[inclusion], value: checked }
              }))
            }
          />
          <Box sx={classes.inclusionsLabel}>{inclusions[inclusion].label}</Box>
        </Box>
      ))}
    </Box>
  );
};

export default Inclusions;
