var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { withScoutTheme } from "../ThemeContext/ThemeContext";
import Api from "./API";
import useStyles from "./useStyles";
import ScoutSelect from "../scout/select";
function StatusCategories({ entityId, apiKey }) {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [defaults, setDefaults] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [differentFromDefaults, setDifferentFromDefaults] = useState(false);
  const [hasParentSettings, setHasParentSettings] = useState(false);
  const [error, setError] = useState();
  const fetchCategories = () => __async(this, null, function* () {
    try {
      setError(void 0);
      const data = yield Api.getCategories(entityId);
      return data;
    } catch (err) {
      setError(err.message);
      return;
    }
  });
  const initialiseCategories = () => __async(this, null, function* () {
    setLoading(true);
    const data = yield fetchCategories();
    setCategories(data.categories);
    setStatuses(data.statuses);
    setDefaults(data.defaults);
    setHasParentSettings(data.has_parent_settings);
    setDifferentFromDefaults(defaultsDiff(data.categories, data.defaults));
    setLoading(false);
  });
  useEffect(() => {
    localStorage.setItem("userKey", apiKey);
    initialiseCategories();
  }, []);
  const updateCategories = (cats) => __async(this, null, function* () {
    setDirty(false);
    setSaving(true);
    setSaved(false);
    const params = {
      category_assignment: { categories: cats }
    };
    try {
      setError(void 0);
      yield Api.updateCategories(entityId, params);
      setSaved(true);
      setCategories(cats);
      setDifferentFromDefaults(defaultsDiff(cats, defaults));
    } catch (err) {
      setError(err.message);
    }
    setSaving(false);
    setTimeout(() => {
      setSaved(false);
    }, 3e3);
  });
  const createDefaultCategories = () => __async(this, null, function* () {
    try {
      const { categories: categories2 } = yield Api.createDefaultCategories(entityId);
      setCategories(categories2);
      setDifferentFromDefaults(false);
    } catch (err) {
      setError(err.message);
    }
  });
  const removeCategories = () => __async(this, null, function* () {
    try {
      setError(void 0);
      const { categories: categories2 } = yield Api.removeCategories(entityId);
      setCategories([]);
      setDifferentFromDefaults(false);
    } catch (err) {
      setError(err.message);
    }
  });
  const resetCategories = () => __async(this, null, function* () {
    const newCategories = categories.map((category) => {
      return __spreadProps(__spreadValues({}, category), { status_id: defaults[category.name] });
    });
    yield updateCategories(newCategories);
  });
  const saveCategories = () => updateCategories(categories);
  const handleStatusChanged = (category, id) => {
    if (toS(category.status_id) === toS(id)) {
      return;
    }
    category.status_id = id;
    setDirty(true);
  };
  const toS = (obj) => (obj || "") + "";
  const categoryColumn = (category) => {
    return /* @__PURE__ */ React.createElement("div", { className: "category-column", key: category.id }, /* @__PURE__ */ React.createElement("div", { className: "category-column-header" }, category.name), /* @__PURE__ */ React.createElement(
      ScoutSelect,
      {
        name: "category_status",
        value: toS(category.status_id),
        data: statusOptions,
        onChange: (id) => handleStatusChanged(category, id)
      }
    ));
  };
  const defaultCategoryColumn = (categoryName, statusId) => {
    const status = statuses.find(({ id }) => id == statusId);
    return /* @__PURE__ */ React.createElement("div", { className: "category-column", key: categoryName }, /* @__PURE__ */ React.createElement("div", { className: "category-column-header" }, categoryName), /* @__PURE__ */ React.createElement("div", null, status ? status.name : "New"));
  };
  const defaultsDiff = (collection, defaultsData) => collection.some((category) => toS(category.status_id) !== toS(defaultsData[category.name]));
  const statusOptions = statuses.map((status) => ({ label: status.name, value: toS(status.id) }));
  return /* @__PURE__ */ React.createElement("div", { className: classes.root }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "modal fade",
      id: "statusCategoriesModal",
      tabIndex: "-1",
      "aria-labelledby": "statusCategoriesModalLabel",
      "aria-hidden": "true"
    },
    /* @__PURE__ */ React.createElement("div", { className: "modal-dialog modal-xl" }, /* @__PURE__ */ React.createElement("div", { className: "modal-content" }, /* @__PURE__ */ React.createElement("div", { className: "modal-header" }, /* @__PURE__ */ React.createElement("h2", { className: "mt-0" }, "Status Categories for Hiring Manager App"), /* @__PURE__ */ React.createElement("button", { type: "button", className: "close", "data-dismiss": "modal", "aria-label": "Close" }, /* @__PURE__ */ React.createElement("span", { "aria-hidden": "true" }, "\xD7"))), /* @__PURE__ */ React.createElement("div", { className: "modal-body" }, loading ? /* @__PURE__ */ React.createElement("div", { className: "d-flex justify-content-center" }, /* @__PURE__ */ React.createElement(CircularProgress, null)) : /* @__PURE__ */ React.createElement("div", { className: "categories-board" }, categories.length > 0 && categories.map((category) => categoryColumn(category)), categories.length == 0 && Object.keys(defaults).map(
      (categoryName) => defaultCategoryColumn(categoryName, defaults[categoryName])
    ))), !loading && /* @__PURE__ */ React.createElement("div", { className: `modal-footer ${classes.footer}` }, error && /* @__PURE__ */ React.createElement("div", { className: "alert alert-danger" }, error), saved && /* @__PURE__ */ React.createElement("div", { className: "alert alert-success" }, "Saved"), categories.length == 0 && /* @__PURE__ */ React.createElement(Button, { variant: "text", onClick: createDefaultCategories }, "Use custom setting"), hasParentSettings && categories.length > 0 && /* @__PURE__ */ React.createElement(Button, { variant: "text", onClick: removeCategories }, "Use parent setting"), categories.length > 0 && /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "contained",
        color: "primary",
        disabled: !dirty,
        onClick: saveCategories,
        startIcon: saving ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 12, color: "inherit" }) : null
      },
      "Save"
    ), differentFromDefaults && /* @__PURE__ */ React.createElement(Button, { variant: "outlined", color: "secondary", onClick: resetCategories }, "Reset to default"))))
  ));
}
export default withScoutTheme(StatusCategories);
