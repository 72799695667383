import React, { useEffect, useState } from 'react';
import { Button, Box } from '@material-ui/core';
import useStyles from './useStyles';
import { ICalendlyEventListItem, ICalendlyEventInvitee } from '../../../types';
import { withScoutTheme } from '../../../../ThemeContext/ThemeContext';
import moment from 'moment';
import axios from 'axios';

import EventIcon from '@material-ui/icons/Event';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

interface IProps {
  event: ICalendlyEventListItem,
}

function EventItem ({ event }: IProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const classes = useStyles();

  function handleReschedule() {
    window.open(event.invitees[0].reschedule_url, '_blank').focus();
  }

  function handlEdit() {
    window.open('https://calendly.com/app/scheduled_events/user/me')
  }

  function handleCancel() {
    event.invitees[0].status !== 'canceled' && window.open(event.invitees[0].cancel_url, '_blank').focus();
  }

  return (
    <div className={classes.container}>
      <div className={classes.detailsTop}>
        <div className={classes.date}>
          <div className={classes.dateDot} />
          { `${moment(event.start_time).format('h:mma')} - ${moment(event.end_time).format('h:mma')}` }
        </div>
        <div className={classes.details}>
          <h4>
            { event.invitees.length === 0 && 'No Invitees' }
            { event.invitees.length === 1 && event.invitees[0].name }
            { event.invitees.length > 1 && `${event.invitees.length} of ${event.invitees_counter.limit} Invitees` }
          </h4>
          <div>
            <span>Event Type</span>
            <span>{ event.name }</span>
          </div>
        </div>
        <div className={classes.detailsButton} onClick={() => setIsExpanded(expanded => !expanded)}>
          { isExpanded ? <ArrowDropUpIcon fontSize="small" /> : <ArrowDropDownIcon fontSize="small" /> }
          Details
        </div>
      </div>
      { isExpanded && 
        <div className={classes.detailsBottom}>
          <div>
            <Button
              disableElevation
              color="secondary"
              variant="outlined"
              onClick={handleReschedule}
              startIcon={<EventIcon />}
            >
              Reschedule
            </Button>
            <Button
              disableElevation
              className={classes.buttonRedLight}
              onClick={handleCancel}
              startIcon={<DeleteIcon />}
            >
              Cancel
            </Button>
            <div className={classes.divider} />
            <Button
              disableElevation
              className={classes.buttonOutline}
              onClick={handlEdit}
            >
              Edit event type
            </Button>
            <Button
              disableElevation
              className={classes.buttonOutline}
              onClick={handlEdit}
            >
              Filter by event type
            </Button>
            <Button
              disableElevation
              className={classes.buttonOutline}
              onClick={handlEdit}
            >
              Schedule invitee again
            </Button>
          </div>
          {event.invitees_counter.limit > 1 
            ? <div>
                <div className={classes.expandDetailItem}>
                  <div>Location</div>
                  <span>{event.location ? event.location.location : 'Custom'}</span>
                </div>
                <h3>Invitees <span>({event.invitees_counter.limit - event.invitees_counter.active} spots remaining)</span></h3>
                {event.invitees.map((evt, i) => 
                  <Box key={i} style={{ padding: '12px'}}>
                    <div className={classes.expandDetailItem}>
                      <div>Invitee Name</div>
                      <span>{evt.name ? evt.name : 'No name'}</span>
                    </div>
                    <div className={classes.expandDetailItem}>
                      <div>Email</div>
                      <span>{evt.email ? evt.email : 'No email'}</span>
                    </div>
                    <div className={classes.expandDetailItem}>
                      <div>Invitee Time Zone</div>
                      <span>{evt.timezone ? evt.timezone : 'No timezone'}</span>
                    </div>
                  </Box>
                )}
                <div className={classes.expandDetailItem}>
                  <div>Created at</div>
                  {event.created_at &&< span>{moment(event.created_at).format('DD/MM/YY')}</span>}
                </div>
              </div>
            : <div>
                <div className={classes.expandDetailItem}>
                  <div>Email</div>
                  <span>{event.invitees[0].email}</span>
                </div>
                <div className={classes.expandDetailItem}>
                  <div>Location</div>
                  <span>{event.location ? event.location.location : 'Custom'}</span>
                </div>
                <div className={classes.expandDetailItem}>
                  <div>Invitee Time Zone</div>
                  <span>{event.invitees[0].timezone}</span>
                </div>
                <div className={classes.expandDetailItem}>
                  <div>Created at</div>
                  {event.created_at &&< span>{moment(event.created_at).format('DD/MM/YY')}</span>}
                </div>
              </div>
          }
        </div>
      }
    </div>


  )
}

export default withScoutTheme(EventItem);
