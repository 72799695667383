import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { IStyle } from '../../../ThemeContext/ThemeObject';

export interface VirtualizeProps {
  passOptions: string[];
  value?: string | null;
  label?: string;
  error?: boolean;
  loading?: boolean;
  hasIdAndRef?: boolean;
  loadingText?: string;
  setValue: ((value: string) => void) | ((event: string, value: string) => void);
  passedStyles: IStyle;
  errorStyles?: IStyle;
  freeSolo?: boolean;
}

function renderRow(props: ListChildComponentProps) {
  const [displayTooltip, setDisplayTooltip] = React.useState(false);
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + 8
  };

  function refCallback(ref: HTMLElement) {
    if (!ref) return;
    if (ref.clientWidth > ref.parentElement?.clientWidth) {
      setDisplayTooltip(true);
    }
  }

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        <Tooltip
          title={displayTooltip ? dataSet.group : ''}
          enterDelay={600}
          placement="bottom-start"
        >
          <div ref={refCallback}>{dataSet.group}</div>
        </Tooltip>
      </ListSubheader>
    );
  }

  return (
    <Typography
      component="li"
      {...dataSet[0]}
      data-testid={`option-${dataSet[1]}`}
      noWrap
      style={inlineStyle}
    >
      <Tooltip title={displayTooltip ? dataSet[1] : ''} enterDelay={600} placement="bottom-start">
        <div ref={refCallback}>{dataSet[1]}</div>
      </Tooltip>
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach(
      (item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      }
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactChild) => {
      if (child.hasOwnProperty('group')) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * 8}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
});

export function Virtualize({
  passOptions,
  value,
  setValue,
  passedStyles,
  error,
  errorStyles,
  hasIdAndRef,
  loading,
  loadingText,
  label,
  freeSolo
}: VirtualizeProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <Autocomplete
        id="virtualize-demo"
        data-testid="virtualize-demo"
        sx={passedStyles}
        disableListWrap
        disablePortal
        value={value}
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={passOptions}
        loading={loading}
        loadingText={loadingText}
        freeSolo={freeSolo}
        forcePopupIcon={freeSolo}
        onChange={(event, value) =>
          hasIdAndRef ? setValue(event?.target?.dataset?.optionIndex, value) : setValue(value)
        }
        renderInput={(params) => (
          <TextField {...params} label={label} InputLabelProps={{ shrink: true }} />
        )}
        renderOption={(props, option) => [props, option] as React.ReactNode}
      />
      {error && <Box sx={errorStyles}>This feild is required</Box>}
    </Box>
  );
}
