import React from 'react';

export default function DeliveredSVG() {
return (<svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
<g id="Group_577" data-name="Group 577" transform="translate(-415 -710)">
  <rect id="Rectangle_59" data-name="Rectangle 59" width="53" height="53" rx="16" transform="translate(415 710)" fill="#edf2f5"/>
  <g id="MDI_account-multiple-check" data-name="MDI / account-multiple-check" transform="translate(426.592 721.592)">
    <g id="Boundary" fill="#084d6d" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
      <rect width="29.815" height="29.815" stroke="none"/>
      <rect x="0.5" y="0.5" width="28.815" height="28.815" fill="none"/>
    </g>
    <path id="Path_account-multiple-check" data-name="Path / account-multiple-check" d="M23.361,19.908v2.485H8.454V19.908s0-4.969,7.454-4.969,7.454,4.969,7.454,4.969M19.634,8.727a3.727,3.727,0,1,0-3.727,3.727,3.727,3.727,0,0,0,3.727-3.727m3.975,6.286a6.957,6.957,0,0,1,2.236,4.895v2.485h3.727V19.908s0-4.286-5.963-4.895M22.119,5a3.615,3.615,0,0,0-1.106.174,6.211,6.211,0,0,1,0,7.106,3.615,3.615,0,0,0,1.106.174,3.727,3.727,0,1,0,0-7.454M8.876,9.87l1.441,1.752-5.9,5.9L1,13.8l1.441-1.441,1.975,1.963L8.876,9.87" transform="translate(0.242 1.211)" fill="#084d6d"/>
  </g>
</g>
</svg>)
}