import React, { Component } from 'react';
import T from 'prop-types';
import ScoutAutocomplete from './autocomplete';

export default class NestedAutocomplete extends Component {
  static propTypes = {
    parentUrl: T.string.isRequired,
    childBaseUrl: T.string.isRequired,
    parentLabel: T.string.isRequired,
    label: T.string.isRequired,
    parentValue: T.string,
    value: T.string,
    parentName: T.string,
    name: T.string,
    includeBlank: T.string
  };

  constructor(props) {
    super(props);
    const { parentValue, value, childBaseUrl } = props;

    this.state = {
      parentValue,
      value,
      childUrl: childBaseUrl.replace('{parentValue}', parentValue || '')
    };
  }

  setParentValue = (parentValue) => {
    const { childBaseUrl } = this.props;
    this.setState({
      parentValue,
      childUrl: childBaseUrl.replace('{parentValue}', parentValue || '')
    });
  };

  render() {
    const { parentName, parentLabel, parentUrl, label, name, includeBlank } = this.props;
    const { parentValue, value, childUrl } = this.state;

    return (
      <fieldset className="inputs">
        <ol>
          <li>
            <label>{parentLabel}</label>
            <ScoutAutocomplete
              url={parentUrl}
              name={parentName}
              onChange={this.setParentValue}
              value={parentValue}
            />
          </li>
          <li>
            <label>{label}</label>
            <ScoutAutocomplete
              url={childUrl}
              name={name}
              value={value}
              includeBlank={includeBlank}
            />
          </li>
        </ol>
      </fieldset>
    );
  }
}
