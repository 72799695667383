import { ISmsTemplate } from '../../../Job/Applications/Modals/SMSCandidates';

export const getBodyFromSmsTemplateValue = (value: string, smsTemplates: ISmsTemplate[]) : string => {
  if (!value) {
    return '';
  }

  const id = Number(value.split(' -')[0]);
  const smsTemplate = smsTemplates.find((template) => template.id === id);
  return smsTemplate?.body || '';
};
