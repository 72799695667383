import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%"
  },
  modalLeft: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "36%",
    borderRight: "1px solid #F3F3F3"
  },
  modalRight: {
    display: "flex",
    flexDirection: "column"
  },
  candidateList: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflowY: "scroll"
  },
  loading: {
    margin: "auto"
  }
}));
