import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React, { FC, Fragment, PropsWithChildren, useEffect, useState } from 'react';

interface Props extends PropsWithChildren<ButtonProps> {
  loadingCaption?: string;
  task: () => Promise<unknown>;
}

interface ViewProps extends Props {
  isLoading: boolean;
}

export const LoadingButtonView: FC<ViewProps> = (props) => {
  const { children, disabled, isLoading, loadingCaption, sx, task, variant, ...rest } = props;

  return (
    <Button
      {...rest}
      disabled={isLoading || disabled}
      onClick={
        !isLoading
          ? (e) => {
              e.preventDefault();
              return task();
            }
          : undefined
      }
      sx={{ display: 'flex', alignItems: 'center', ...sx }}
      variant={variant ?? 'contained'}
    >
      {isLoading ? (
        <Fragment>
          <CircularProgress color="inherit" role="status" size={20} />
          <span>{loadingCaption}</span>
        </Fragment>
      ) : (
        children
      )}
    </Button>
  );
};

export const LoadingButton: FC<Props> = (props) => {
  const { children, task, ...rest } = props;
  const [isLoading, setLoading] = useState(false);

  const wrappedTask = (): Promise<unknown> => {
    setLoading(true);
    return task()
      .catch()
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <LoadingButtonView {...rest} isLoading={isLoading} task={wrappedTask}>
      {children}
    </LoadingButtonView>
  );
};

export default LoadingButton;
