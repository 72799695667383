import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Api from '../../../Dashboard/API';
import { IRecentlyViewed } from '../../../Dashboard/types';
import { classes } from '../styles';
import RecentlyViewedItem from './RecentlyViewedItem';

function RecentlyViewed() {
  const [recentlyViewedList, setRecentlyViewedList] = useState<IRecentlyViewed[]>([]);

  async function getRecentlyViewed() {
    try {
      const response = await Api.getRecentlyViewed();
      setRecentlyViewedList(response);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getRecentlyViewed();
  }, []);

  return (
    <Box sx={classes.recentlyViewedContainer}>
      <Box sx={classes.title}>Recently Viewed</Box>
      {recentlyViewedList.map((item) => (
        <RecentlyViewedItem key={item.id} view={item} />
      ))}
    </Box>
  );
}

export default RecentlyViewed;
