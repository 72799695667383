import { Box } from '@mui/material';
import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { classes } from '../styles';

interface IProps {
  setHeight: React.Dispatch<SetStateAction<number>>;
  props: {
    children: React.ReactNode;
    ['is-visible']: boolean;
  };
}

const Child: React.FC<IProps> = ({ props, setHeight }) => {
  const visible = props['is-visible'];

  const ref = (node: HTMLElement) => {
    if (!node || !visible) return;
    setHeight(node.offsetHeight);
  };

  return (
    <Box ref={ref} sx={visible ? classes.fadeIn : classes.fadeOut}>
      {props.children}
    </Box>
  );
};

const FadeChildrenVisibility: React.FC = ({ children }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return
    const index = Array.from(children).findIndex(child => child.props['is-visible'])
    const target = ref.current.children[index]
    setTimeout(() => {
      setHeight(target.clientHeight)
    }, 400)
  }, [children])

  return (
    <Box
      ref={ref}
      style={{
        height: height || 'auto',
        position: 'relative',
        width: '100%',
        transition: 'height 0.4s ease-in-out'
      }}
    >
      {Array.isArray(children) &&
        children.map((child) => (
          <Child {...child} key={child.key} setHeight={setHeight} height={height} />
        ))}
    </Box>
  );
};

export default FadeChildrenVisibility;
