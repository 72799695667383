// eslint-disable-next-line react/no-deprecated
import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionItem } from '../../shared/accordion.jsx'
import reportViews from '../../../tableau/reportViews';
import Report from './report.jsx';

export default function Reports ({ tableauUrl, entityId, country }) {
  return (
    <div>
      <Accordion>
        {reportViews.map((report) => (
          <AccordionItem title={report.title} key={report.title}>
            <Report
              options={report}
              url={tableauUrl}
              entityId={entityId}
              country={country}
            />
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

Reports.propTypes = {
  tableauUrl: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  country: PropTypes.string.isRequired,
};
