import React, { useReducer, useCallback, useRef } from 'react';
import { Box, Button, Modal } from '@mui/material';
import { FelixButtonSVG } from '../SharedComponents/Graphics/FelixSVG';
import LoadingResponse from '../SharedComponents/Steps/LoadingResponse';
import { WelcomePage } from './Steps/WelcomePage';
import JobBoardInput from './Steps/JobBoardInput';
import ResponseStep from './Steps/ResponseStep';
import SocialInputStep from './Steps/SocialInputStep';
import CloseIcon from '@mui/icons-material/Close';
import GenericDialog from '../../NewUI/Components/Modals/GenericDialog';
import { displayFelixMessage, disableNextButton } from './config';
import DynamicFooter from '../SharedComponents/Footers/DynamicFooter';
import he from 'he';
import { classes } from './styles';
import ResponseFooter from './Footers/ResponseFooter';
import WelcomeFooter from './Footers/WelcomeFooter';
import ErrorScreen from '../SharedComponents/Steps/ErrorScreen';
import { initialSmartShareState, smartShareReducer } from './reducer';
import { fetchSummary, fetchKeyPoints, fetchSocial } from './API';
import { convertToLocalEnglish } from '../../NewUI/Components/Utilities/convertToLocalEnglish';
import { LIST_ITEMS_REGEX } from '../SharedComponents/sharedConfig';

function SmartShare({
  apiKey,
  jobTitle,
  jobDescription,
  jobUrl
}: {
  apiKey: string;
  jobTitle: string;
  jobDescription: string;
  jobUrl: string;
}) {
  const [smartShareState, dispatch] = useReducer(smartShareReducer, initialSmartShareState);
  const generatedResponseRef = useRef<HTMLDivElement>(null);
  const requestController = useRef<AbortController | null>(null);

  const handleRichCopy = () => {
    if (!generatedResponseRef.current) return;
    dispatch({ type: 'SET_COPIED', payload: true });
    setTimeout(() => dispatch({ type: 'SET_COPIED', payload: false }), 3000);
    window?.getSelection()?.removeAllRanges();
    const range = document.createRange();
    range.selectNode(generatedResponseRef.current);
    window?.getSelection()?.addRange(range);
    document.execCommand('copy');
    window?.getSelection()?.removeAllRanges();
  };

  const generatePost = useCallback(async () => {
    requestController.current = new AbortController();
    dispatch({ type: 'SET_CURRENT_STEP', payload: 2 });
    try {
      const response = await fetchSocial(
        smartShareState.useCustomDescription === 'description'
          ? jobDescription
          : smartShareState.generationJobDescription,
        smartShareState.generationJobTitle,
        smartShareState.useCustomDescription,
        smartShareState.includeHashtags,
        smartShareState.generationTone,
        smartShareState.summaryLength,
        requestController.current.signal
      );
      if (response && response.result && response.result.length > 30) {
        let responseText = smartShareState.includeHashtags
          ? response.result
          : response.result.replace(/#[a-zA-Z0-9]+/g, '');
        responseText = he.decode(responseText);
        const localEnglishResponse = convertToLocalEnglish(responseText);
        dispatch({ type: 'SET_GENERATED_RESPONSE', payload: localEnglishResponse });
      } else {
        throw new Error('No response from AI API');
      }
    } catch (error) {
      if (error.name === 'AbortError') return;
      dispatch({ type: 'SET_GENERATION_ERROR', payload: true });
      console.error(error);
    } finally {
      if (!requestController.current.signal.aborted) {
        dispatch({ type: 'SET_CURRENT_STEP', payload: 3 });
      }
    }
  }, [
    jobDescription,
    smartShareState.generationJobDescription,
    smartShareState.generationJobTitle,
    smartShareState.generationTone,
    smartShareState.includeHashtags,
    smartShareState.summaryLength,
    smartShareState.useCustomDescription
  ]);

  const generateSummary = useCallback(async () => {
    requestController.current = new AbortController();
    dispatch({ type: 'SET_CURRENT_STEP', payload: 2 });
    const sendableJobDescription =
      smartShareState.useCustomDescription === 'description'
        ? jobDescription
        : smartShareState.generationJobDescription;
    const summaryParams: Parameters<typeof fetchSummary> = [
      sendableJobDescription,
      smartShareState.adType,
      smartShareState.generationJobTitle,
      smartShareState.useCustomDescription,
      smartShareState.generationTone,
      requestController.current.signal
    ];
    try {
      let summaryResponse: { result: string } | undefined;
      let keyPointsResponse: { result: string } | undefined;

      if (smartShareState.includeKeyPoints) {
        [summaryResponse, keyPointsResponse] = await Promise.all([
          fetchSummary(...summaryParams),
          fetchKeyPoints(sendableJobDescription, smartShareState.generationJobTitle)
        ]);
      } else {
        summaryResponse = await fetchSummary(...summaryParams);
      }

      if (summaryResponse && summaryResponse.result && summaryResponse.result.length > 30) {
        const localEnglishResponse = convertToLocalEnglish(summaryResponse.result);
        dispatch({ type: 'SET_GENERATED_RESPONSE', payload: localEnglishResponse });
      }
      if (keyPointsResponse && keyPointsResponse.result && keyPointsResponse.result.length > 30) {
        const ksp = convertToLocalEnglish(keyPointsResponse.result)
          .replace(LIST_ITEMS_REGEX, '')
          .split('**')
          .slice(0, -1);
        dispatch({ type: 'SET_GENERATED_KEY_POINTS', payload: ksp });
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Description generation aborted');
      }
      console.error(error);
      dispatch({ type: 'SET_GENERATION_ERROR', payload: true });
    } finally {
      if (!requestController.current.signal.aborted) {
        dispatch({ type: 'SET_CURRENT_STEP', payload: 3 });
      }
    }
  }, [
    jobDescription,
    smartShareState.adType,
    smartShareState.generationJobDescription,
    smartShareState.generationJobTitle,
    smartShareState.generationTone,
    smartShareState.includeKeyPoints,
    smartShareState.useCustomDescription
  ]);

  const stepSelect = () => {
    if (smartShareState.generationError) {
      return (
        <ErrorScreen
          regenerateCallback={
            smartShareState.generationType === 'social' ? generatePost : generateSummary
          }
          setGenerationError={(value) => dispatch({ type: 'SET_GENERATION_ERROR', payload: value })}
          backToHome={() => dispatch({ type: 'SET_CURRENT_STEP', payload: 0 })}
        />
      );
    }
    switch (smartShareState.currentStep) {
      case 0:
        return (
          <>
            <WelcomePage dispatch={dispatch} />
            <WelcomeFooter />
          </>
        );
      case 1:
        return (
          <>
            <SocialInputStep
              jobDescription={jobDescription}
              dispatch={dispatch}
              smartShareState={smartShareState}
            />
            <DynamicFooter
              handleSecondaryButtonClick={() => dispatch({ type: 'SET_CURRENT_STEP', payload: 0 })}
              handlePrimaryButtonClick={generatePost}
              secondaryButtonText="Back"
              primaryButtonText="Generate"
              primaryButtonID={`generate-social-post-${smartShareState.useCustomDescription}`}
              disablePrimaryButton={disableNextButton(
                smartShareState.useCustomDescription,
                jobDescription,
                smartShareState.generationJobDescription,
                smartShareState.generationJobTitle,
                12000
              )}
              felixMessage={displayFelixMessage(
                smartShareState.useCustomDescription,
                smartShareState.currentStep
              )}
              felixMessageMaxWidth="351px"
              footerWidth="90%"
            />
          </>
        );
      case 2:
        return <LoadingResponse generationType={smartShareState.generationType} />;
      case 3:
        return (
          <>
            <ResponseStep
              apiKey={apiKey}
              generatedResponse={smartShareState.generatedResponse}
              generatedKeyPoints={smartShareState.generatedKeyPoints}
              generatedResponseRef={generatedResponseRef}
              generationType={smartShareState.generationType}
              jobUrl={jobUrl}
              generatePost={generatePost}
              generateSummary={generateSummary}
            />
            <ResponseFooter
              dispatch={dispatch}
              smartShareState={smartShareState}
              handleRichCopy={handleRichCopy}
              handleCopy={handleCopy}
              jobUrl={jobUrl}
            />
          </>
        );
      case 4:
        return (
          <>
            <JobBoardInput
              jobDescription={jobDescription}
              dispatch={dispatch}
              smartShareState={smartShareState}
            />
            <DynamicFooter
              handleSecondaryButtonClick={() => dispatch({ type: 'SET_CURRENT_STEP', payload: 0 })}
              handlePrimaryButtonClick={generateSummary}
              secondaryButtonText="Back"
              primaryButtonText="Generate"
              primaryButtonID={`generate-job-ad-${smartShareState.useCustomDescription}`}
              disablePrimaryButton={disableNextButton(
                smartShareState.useCustomDescription,
                jobDescription,
                smartShareState.generationJobDescription,
                smartShareState.generationJobTitle,
                10000
              )}
              felixMessage={displayFelixMessage(
                smartShareState.useCustomDescription,
                smartShareState.currentStep
              )}
              felixMessageMaxWidth="351px"
              footerWidth="90%"
            />
          </>
        );
      case 9:
        return <Box>Something went wrong, please refresh and try again</Box>;
      default:
        return <Box>Something went wrong, please refresh and try again</Box>;
    }
  };

  const handleCopy = () => {
    dispatch({ type: 'SET_COPIED', payload: true });
    setTimeout(() => dispatch({ type: 'SET_COPIED', payload: false }), 3000);
  };

  const handleClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });

    // abort any pending requests
    requestController?.current?.abort();
  };

  const handleCloseAction = () => {
    if (smartShareState.currentStep === 0) {
      handleClose();
    } else {
      dispatch({ type: 'SET_CONFIRM_CANCEL_IS_OPEN', payload: true });
    }
  };

  return (
    <Box sx={{ marginLeft: '-16px' }}>
      <Box
        sx={{
          ...classes.buttonContainer,
          ...(smartShareState.buttonHovered ? classes.hoverAnimation : classes.unhoverAnimation)
        }}
        onMouseEnter={() => dispatch({ type: 'SET_BUTTON_HOVERED', payload: true })}
        onMouseLeave={() => dispatch({ type: 'SET_BUTTON_HOVERED', payload: false })}
        onClick={() => dispatch({ type: 'OPEN_MODAL', payload: jobTitle })}
        id="smart-share-button"
      >
        <Box sx={classes.avatarContainer}>
          <FelixButtonSVG />
        </Box>
        <Button sx={classes.button}>{!smartShareState.open ? 'Smart Share' : 'Close'}</Button>
        {!smartShareState.open && <Box sx={classes.betaPill}>Beta</Box>}
      </Box>
      <Modal
        open={smartShareState.open}
        onClose={handleCloseAction}
        aria-labelledby="smart-share-modal"
        aria-describedby="use-ai-to-generate-social-media-content-for-your-job-posting"
      >
        <Box sx={classes.modal}>
          <CloseIcon onClick={handleCloseAction} sx={classes.closeIcon} />
          {stepSelect()}
        </Box>
      </Modal>
      <GenericDialog
        isDialogOpen={smartShareState.confirmCancelIsOpen}
        setDialogOpen={(value: boolean) =>
          dispatch({ type: 'SET_CONFIRM_CANCEL_IS_OPEN', payload: value })
        }
        title="Close Smart Share window?"
        description="All unsaved changes will be lost."
        buttonCallback={handleClose}
        callbackLoading={false}
        buttonText="Close Smart Share"
        url=""
      />
    </Box>
  );
}

export default SmartShare;
