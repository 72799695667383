import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { IGetApprovalTemplates } from './types';
import { styles } from '../styles';
import { useDebouncedCallback } from 'use-debounce';

export default function Search({
  setSearch,
  getApprovalTemplates,
  archived
}: {
  setSearch: (query: string) => void;
  getApprovalTemplates: IGetApprovalTemplates;
  archived: boolean;
}) {
  const [tempSearch, setTempSearch] = useState('');
  const debouncedAPICall = useDebouncedCallback((searchQuery) => {
    getApprovalTemplates(null, null, searchQuery, null, null, archived);
    setSearch(searchQuery);
  }, 600);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearch(event.target.value);
    debouncedAPICall(event.target.value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <OutlinedInput
          id="approval-template-search"
          sx={styles.searchInput}
          placeholder={'Search by title'}
          value={tempSearch}
          onChange={handleChange}
          startAdornment={
            <InputAdornment sx={{ padding: '0px 0px 0px 14px' }} position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}
