import React, { useState, useContext } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Box, Button, CircularProgress } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import StyledSnackbar from '../CustomUIElements/StyledSnackbar';
import { IChartData } from './types';
import { activityReportStackedBarOptions } from '../../../reports/DataVizSection/GraphDefaultOptions';
import { classes } from '../../EditJob/styles';
import DateRangeField from '../../../NewUI/Components/CustomUIElements/DateRangePickerWithDynamicTitle/DateRangeField';
import EntityField from '../../../reports/DataVizSection/FilterFields/EntityField';
import {
  dateRangeOptions,
  setDatePeriod,
  sortGraphByDate,
  htmlLegendPlugin
} from '../../../reports/DataVizSection/GraphDefaultOptions';
import StatusField from '../../../reports/DataVizSection/FilterFields/StatusField';
import UserField from '../../../reports/DataVizSection/FilterFields/UserField';
import { emptyChartText } from '../../../reports/DataVizSection/EmptyChartText';
import { ReportContext } from '../../../reports';
import { startOfDay, endOfDay } from './helper';
import dayjs from 'dayjs';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function StackedBarGraph({
  chartData,
  reportState,
  reportDispatch,
  refChart
}: {
  chartData: IChartData;
  reportState;
  reportDispatch;
  refChart;
}) {
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [hasChartData, setHasChartData] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const reportServiceApi = useContext(ReportContext);
  const userTimezone = reportServiceApi.currentUser.iana_timezone;
  const startDate = startOfDay(reportState.statusActivitiesFields.startDate, userTimezone);
  const endDate = endOfDay(reportState.statusActivitiesFields.endDate, userTimezone);

  const fetchOptions = async (entities: number[]) => {
    setIsLoadingOptions(true);
    try {
      const { included_data } = await reportServiceApi.dataVisualisation.getChartOptions({
        entity_id: entities,
        chart_type: 'status_activities',
        start_date: startDate,
        end_date: endDate,
        status_name: reportState.statusActivitiesFields.status.length
          ? reportState.statusActivitiesFields.status
          : undefined,
        actor_id: reportState.statusActivitiesFields.user.length
          ? reportState.statusActivitiesFields.user.map(
              (user: { id: number; name: string }) => user.id
            )
          : undefined
      });
      reportDispatch({
        type: 'setChartData',
        payload: {
          usersOptions: included_data.actors,
          statusOptionsActivityReport: included_data.statuses
        }
      });
    } catch (error) {
      setSnackbar({
        message: `There was an error with retrieving the filtering options, ${error}`,
        state: 'error'
      });
    } finally {
      setIsLoadingOptions(false);
    }
  };

  const filterData = async () => {
    reportDispatch({
      type: 'setChartData',
      payload: { loadingOptions: true }
    });
    try {
      const fields = reportState.statusActivitiesFields;

      const entityId = fields.entity[0]?.id
        ? fields.entity[0]?.name === 'All entities'
          ? fields.entity[0].id
          : fields.entity.map((i) => i.id)
        : [1];

      const period = setDatePeriod(dayjs(fields.endDate).diff(dayjs(fields.startDate), 'day'));
      const params: IApplicationSourcesFields = {
        entity_id: entityId,
        start_date: startDate,
        end_date: endDate,
        period: period
      };
      if (fields.status?.length) params['status_name'] = fields?.status;
      if (fields.user?.length) params['actor_id'] = fields?.user?.map((val) => val?.id);
      const [statusActor, statusNoActor] = await Promise.all([
        reportServiceApi.dataVisualisation.getStatusActivities({
          ...params,
          by_actor: true
        }),
        reportServiceApi.dataVisualisation.getStatusActivities(params)
      ]);

      reportDispatch({
        type: 'setChartData',
        payload: {
          statusActivitiesWithActor: {
            datasets: statusActor?.data?.datasets,
            labels: statusActor?.data?.labels
          },
          statusActivitiesWithoutActor:
            period === 'year'
              ? sortGraphByDate(statusNoActor)
              : {
                  datasets: statusNoActor?.data?.datasets,
                  labels: statusNoActor?.data?.labels
                }
        }
      });
    } catch (error) {
      setSnackbar({
        message: 'There was an error with retrieving the reporting data',
        state: 'error'
      });
    } finally {
      reportDispatch({
        type: 'setChartData',
        payload: { loadingOptions: false }
      });
    }
  };

  const isLoading = reportState.statusActivitiesFields.loadingOptions;

  return (
    <Box>
      <Box sx={classes.generalModalFormLine}>
        <EntityField
          entityOptions={reportState.entitiesOptions}
          reportState={reportState.statusActivitiesFields}
          isLoading={reportState.loadingOptions}
          reportDispatch={reportDispatch}
          fieldName="statusActivitiesFields"
          resetOptions={{
            status: [],
            user: []
          }}
        />
        <DateRangeField
          dateRangeOptions={dateRangeOptions}
          reportState={reportState.statusActivitiesFields}
          isLoading={isLoading}
          reportDispatch={reportDispatch}
          fieldName="statusActivitiesFields"
          inputValue={reportState.statusActivitiesFields.timeToHire}
          payloadName="timeToHire"
          inputHeader="Date Range"
        />
        <UserField
          userOptions={reportState.usersOptions}
          reportState={reportState}
          isLoading={isLoadingOptions}
          reportDispatch={reportDispatch}
          fieldName="statusActivitiesFields"
        />
        <StatusField
          statusOptions={reportState.statusOptionsActivityReport}
          reportState={reportState}
          isLoading={isLoadingOptions}
          reportDispatch={reportDispatch}
          fieldName="statusActivitiesFields"
        />
        <Button
          id="visual-reports-activity-report-generate-chart"
          type="submit"
          variant="contained"
          sx={{ ...classes.saveButton, ...classes.generateButton }}
          disabled={
            !reportState?.statusActivitiesFields?.entity?.length ||
            !dayjs(reportState.statusActivitiesFields.startDate).isValid() ||
            !dayjs(reportState.statusActivitiesFields.endDate).isValid() ||
            !reportState.statusActivitiesFields.timeToHire
          }
          onClick={() => {
            filterData();
            setHasChartData(true);
            if (reportState.statusActivitiesFields.entity[0].name === 'All entities') {
              fetchOptions(reportState.statusActivitiesFields.entity[0].id);
            } else {
              fetchOptions(
                reportState.statusActivitiesFields.entity.map(
                  (e: { name: string; id: number }) => e.id
                )
              );
            }
          }}
        >
          Generate
        </Button>
      </Box>
      <Box sx={isLoading ? classes.loadingWrapper : null}>
        {isLoading && <CircularProgress sx={{ position: 'absolute' }} size={24} color="inherit" />}
        <Box sx={isLoading ? classes.loadingContent : classes.graphWrapper} ref={refChart}>
          {reportState.statusActivitiesWithActor?.datasets?.length ? (
            <Box>
              <Box sx={{ position: 'relative', minHeight: '525px', maxHeight: '1200px' }}>
                <Bar
                  options={activityReportStackedBarOptions(reportState)}
                  data={chartData}
                  plugins={[htmlLegendPlugin]}
                />
              </Box>
              <div id="legend-container" />
            </Box>
          ) : (
            <Box sx={classes.noDataContainer}>{emptyChartText(hasChartData)}</Box>
          )}
        </Box>
      </Box>
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
