import React, { useState } from 'react';
import { serialize } from 'object-to-formdata';
import { Box, OutlinedInput, CircularProgress } from '@mui/material';
import { IWebsocketData } from '../../../SharedComponents/types';
import { StyledSnackbarProps } from '../../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { handleAPIRequest } from '../../../../NewUI/Components/Utilities/handleAPIRequest';
import HtmlEditor from '../../../../NewUI/Components/Utilities/HtmlEditor';
import DynamicFooter from '../../../SharedComponents/Footers/DynamicFooter';
import { classes } from '../../styles';
import { dismissNotification } from '../../../SharedComponents/Utility/dismissNotification';

const EditStep = ({
  emailData,
  handleClose,
  goBack,
  jobId,
  applicationId,
  setSnackbarState
}: {
  emailData: IWebsocketData | null;
  handleClose: () => void;
  goBack: () => void;
  jobId: number;
  applicationId: number;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
}) => {
  const initialEmailText = emailData?.content
    .split('\n\n')
    .map((s) => `<p>${s}</p>`)
    .slice(1)
    .join('');
  const [emailText, setEmailText] = useState(initialEmailText);
  const [subjectLine, setSubjectLine] = useState(
    emailData?.content.split('\n\n')[0].replace('Subject: ', '')
  );
  const [isSending, setIsSending] = useState(false);

  const sendEmail = () => {
    if (isSending) return;
    const data = {
      email_batch: {
        applications_ids: applicationId,
        subject: subjectLine,
        body: emailText,
        delay: 0
      }
    };
    const serializedData = serialize(data);
    handleAPIRequest({
      url: `/api/v4/jobs/${jobId}/applications/bulk_email`,
      method: 'POST',
      body: serializedData,
      onSuccess: () => {
        handleClose();
        emailData && dismissNotification(emailData.id);
      },
      setSnackbarState,
      formData: true,
      setLoaderState: setIsSending
    });
  };
  return (
    <>
      <Box sx={classes.emailQuestionsContainer}>
        <Box sx={classes.aiRow}>
          <Box sx={classes.fieldSubHeader}>Email subject line</Box>
          <OutlinedInput
            id="email-subject-line"
            sx={classes.fieldInput}
            placeholder="Subject line, eg. 'Application follow up'"
            value={subjectLine}
            onChange={(e) => setSubjectLine(e.target.value)}
          />
        </Box>
        <Box sx={classes.aiRow}>
          <HtmlEditor body={initialEmailText} onChange={setEmailText} maxHeight={380} />
        </Box>
      </Box>
      <DynamicFooter
        primaryButtonText={
          isSending ? <CircularProgress size={20} color="inherit" /> : 'Send email'
        }
        handlePrimaryButtonClick={sendEmail}
        primaryButtonID="send-follow-up-email-button"
        primaryButtonMinWidth="130px"
        secondaryButtonText="Back"
        handleSecondaryButtonClick={goBack}
        felixMessage={
          <>
            Here's your <span>follow up email</span>! You can edit it here or send it as is.
          </>
        }
      />
    </>
  );
};

export default EditStep;
