import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button } from '@mui/material';
import { styles } from './styles';
import { IApprovalTemplateFields } from '../types';
import DoneIcon from '@mui/icons-material/Done';

export default function FormTitleTab({
  questionsList,
  selectedQuestion,
  setSelectedQuestion,
  action
}: {
  questionsList?: IApprovalTemplateFields[];
  selectedQuestion: number | null;
  setSelectedQuestion: Dispatch<SetStateAction<number | null>>;
  action: string;
}) {
  return (
    <Box sx={styles.tabContent}>
      <Box sx={styles.tabContentTitle}>Approval form title selection</Box>
      {action === 'Create' ? (
        <Box sx={styles.tabContentBody}>
          Once an approval form has been created from this template, the title of the form will be
          selectable from your created questions.
          <br />
          <br />
          All approval forms using this template will use the response to your selected question as
          the title.
          <br />
          <br />
          When you a ready, please press &apos;create&apos; below to create your approval template.
          <br />
          <br />
          You will see a selectable checkbox underneath any question you create allowing you to make
          that question the title for the approval form.
        </Box>
      ) : (
        <Box sx={styles.tabContentBody}>
          All approvals using this approval template will use your selected question&apos;s response
          as the title.
        </Box>
      )}
      <Box sx={{ marginTop: 1 }}>
        {questionsList && questionsList.length > 0 ? (
          questionsList.map(
            (question, index) =>
              question.enabled && (
                <Box
                  key={question.id}
                  sx={
                    index === selectedQuestion
                      ? styles.questionListItemSelected
                      : styles.questionListItem
                  }
                >
                  Question {index + 1}: {question.title}
                  <Button
                    sx={{ ...styles.selectButton, padding: '0px 12px', fontSize: '12px' }}
                    onClick={() => setSelectedQuestion(index)}
                  >
                    {index === selectedQuestion ? 'Selected ' : 'Select'}
                    {index === selectedQuestion && (
                      <DoneIcon sx={{ fontSize: '12px', marginLeft: '2px', marginBottom: '2px' }} />
                    )}
                  </Button>
                </Box>
              )
          )
        ) : (
          <Box sx={{ padding: 6, marginTop: 2, fontWeight: 'bold', color: '#939393' }}>
            You have no questions set up on this approval template
          </Box>
        )}
      </Box>
    </Box>
  );
}
