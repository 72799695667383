import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableSkeleton from '../TableSkeleton';
import { classes } from '../styles';

export default function IdibuAds({ apiKey, jobId }: { apiKey: string; jobId: string | null }) {
  const [newUrl, setNewUrl] = useState(null);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const getAds = useCallback(async () => {
    setLoading(true);
    const url = jobId ? `/api/v4/idibu_ad/job_ads/${jobId}` : '';
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setNewUrl(response.new_ad_url);
      setIframeUrl(response.iframe_url);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [apiKey, jobId]);

  const createNewJob = () => {
    window.open(newUrl, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    getAds();
  }, [getAds]);

  return (
    <Box sx={classes.containerOuter}>
      <Box sx={classes.container}>
        <Box sx={classes.banner}>
          <Box id="idibu-logo"></Box>
          {jobId && (
            <Button
              disableElevation
              sx={{ ...classes.buttonSecondary, ...classes.newAdButton }}
              id="IdibuNewAdMultiposterBtn"
              onClick={createNewJob}
            >
              New Ad
            </Button>
          )}
        </Box>
        {loading && <TableSkeleton />}
        <iframe className="one-whole" style={{background: '#FFF', borderRadius: '8px', marginTop: '20px'}} src= {iframeUrl}/>
      </Box>
    </Box>
  );
}
