import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { Dispatch, useState } from 'react';
import MultiSelectFilter from '../../NewUI/Components/GenericTable/Filters/MultiSelectFilter';
import { classes } from '../styles';
import RadiusSearchFilter from '../../NewUI/Components/GenericTable/Filters/RadiusSearchFilter';
import { EngageAction, EngageState } from '../reducer';
import { ISnackbarInput } from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { ICountries, IStates } from '../../NewUI/Components/sharedTypes';
import DateRangeFilter from '../../NewUI/Components/GenericTable/Filters/DateRangeFilter';
import { useQuery } from '@tanstack/react-query';
import callAPI from '../../NewUI/Components/Utilities/callAPI';
import { parseCountryData } from '../../NewUI/Components/Utilities/countryParser';

export interface ISearchFilters {
  entity_ids: Array<number>;
  postal_codes: Array<string>;
  include_status: Array<string>;
  exclude_status: Array<string>;
  tag: Array<string>;
  date1: string;
  date2: string;
  country: Array<string>;
  state: Array<string>;
  employment_type: Array<string>;
}

export const initialSearchFilters = {
  entity_ids: [],
  postal_codes: [],
  include_status: [],
  exclude_status: [],
  tag: [],
  date1: null,
  date2: null,
  country: [],
  state: [],
  employment_type: []
};

export interface ITempFilters {
  entity_ids: FilterType[];
  postal_codes: Array<string>;
  include_status: Array<string>;
  exclude_status: Array<string>;
  tag: Array<string>;
  date1: Date | null;
  date2: Date | null;
  country: ICountries[];
  state: IStates[];
  employment_type: FilterType[];
}

export type FilterType = {
  name: string;
  id?: number;
};

const SearchFilters = ({
  engageState,
  dispatch
}: {
  engageState: EngageState;
  dispatch: Dispatch<EngageAction>;
}) => {
  const [filterTab, setFilterTab] = useState<'radius' | 'manual'>('radius');
  const handleFilterChange = (name: string, value: FilterType[]) => {
    dispatch({ type: 'SET_PAGE', payload: 1 });
    dispatch({ type: 'SET_TEMP_FILTERS', payload: { [name]: value } });
  };
  const { data: countriesData } = useQuery({
    queryKey: ['countries'],
    queryFn: () =>
      callAPI('/api/system/v1/countries_states.json').then((response: ICountries[]) =>
        parseCountryData(response)
      )
  });

  const { data: entities } = useQuery({
    queryKey: ['entities'],
    queryFn: () => callAPI('/api/v4/searches/entities').then((response) => response.entities)
  });

  const { data: statuses } = useQuery({
    queryKey: ['statuses'],
    queryFn: () =>
      callAPI('/api/v4/searches/statuses_and_tags').then((response) => response.statuses)
  });

  return (
    <Box sx={classes.filtersContainer}>
      <ToggleButtonGroup
        value={filterTab}
        exclusive
        onChange={(e, value) => setFilterTab(value)}
        aria-label="enabled or disabled questions"
      >
        <ToggleButton id="radius-tab-filter-tab" value="radius" sx={classes.toggleButton}>
          Radius
        </ToggleButton>
        <ToggleButton
          id="location-tab-filter-tab"
          value="disabledQuestions"
          sx={classes.toggleButton}
        >
          Location
        </ToggleButton>
      </ToggleButtonGroup>
      {filterTab === 'radius' ? (
        <RadiusSearchFilter
          setPostcodes={(postalCodes: string[]) =>
            dispatch({ type: 'SET_TEMP_FILTERS', payload: { postal_codes: postalCodes } })
          }
          setSnackbarState={(snackbarState: ISnackbarInput) =>
            dispatch({ type: 'SET_SNACKBAR', payload: snackbarState })
          }
        />
      ) : (
        <>
          <MultiSelectFilter
            handleFilterChange={handleFilterChange}
            selectOptions={countriesData || []}
            name="country"
            label="Countries"
            value={engageState.tempSearchFilters.country}
            displayLabel={true}
          />
          <MultiSelectFilter
            handleFilterChange={handleFilterChange}
            selectOptions={engageState.tempSearchFilters.country
              .map((country) => country.states)
              .flat()}
            name="state"
            label="States/Provinces"
            value={engageState.tempSearchFilters.state}
            disabled={engageState.tempSearchFilters.country.length === 0}
            displayLabel={true}
          />
        </>
      )}
      <MultiSelectFilter
        handleFilterChange={handleFilterChange}
        selectOptions={[]}
        name="postal_codes"
        label="Post/ZIP Codes"
        freeInput={true}
        value={engageState.tempSearchFilters.postal_codes}
      />
      <MultiSelectFilter
        handleFilterChange={handleFilterChange}
        selectOptions={entities || []}
        name="entity_ids"
        label="Entity"
        value={engageState.tempSearchFilters.entity_ids}
      />
      <MultiSelectFilter
        handleFilterChange={handleFilterChange}
        selectOptions={[]}
        name="tag"
        label="Tags"
        freeInput={true}
        value={engageState.tempSearchFilters.tag}
      />
      <MultiSelectFilter
        handleFilterChange={handleFilterChange}
        selectOptions={statuses || []}
        name="include_status"
        label="Application Status"
        subLabel="Include"
        value={engageState.tempSearchFilters.include_status}
      />
      <MultiSelectFilter
        handleFilterChange={handleFilterChange}
        selectOptions={statuses || []}
        name="exclude_status"
        label=""
        subLabel="Exclude"
        value={engageState.tempSearchFilters.exclude_status}
      />
      <DateRangeFilter
        dispatch={dispatch}
        dateRange={{
          from: engageState.tempSearchFilters.date1,
          to: engageState.tempSearchFilters.date2
        }}
        dateRangeTemp={engageState.dateFieldRange}
        label="engage-search"
      />
    </Box>
  );
};

export default SearchFilters;
