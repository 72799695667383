"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { classes as sharedClasses } from "../styles";
export const classes = __spreadProps(__spreadValues({}, sharedClasses), {
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  editTabsContainer: {
    flexGrow: 1,
    display: "flex",
    borderColor: "none"
  },
  modalWrapper: {
    "& .MuiPaper-root": {
      maxWidth: "100%"
    }
  },
  modalHeaderRight: {
    display: "flex",
    float: "right"
  },
  modalActionButtons: {
    display: "flex",
    "& span": {
      width: "1rem",
      borderRight: "1px solid #EDEDED",
      paddingLeft: "10px"
    },
    "& svg": {
      width: "1.5rem",
      height: "1.5rem",
      fontSize: "1.75rem",
      marginLeft: "px",
      "&:hover": {
        cursor: "pointer"
      }
    }
  },
  checkBoxes: {
    color: `#15C7C0 !important`
  },
  checkBoxOff: {
    color: "#6c757d"
  }
});
