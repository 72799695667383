import React, { Dispatch, SetStateAction, useState, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import { IJobData, IClassificationOptions } from '../../types';
import { classes } from '../../styles';
import { Checkbox, FormControlLabel, TextField, Autocomplete, Button } from '@mui/material';

export default function Classifications({
  isLoading,
  setNewJobsData,
  classificationsOptions,
  newJobData,
  setIsEdited
}: {
  isLoading: boolean;
  classificationsOptions: IClassificationOptions;
  newJobData: IJobData;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
}) {
  const [selectedClassification, setSelectedClassification] = useState();

  const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    setIsEdited(true);
    if (e.target.checked) {
      setNewJobsData((prev: IJobData) => ({
        ...prev,
        classification_ids: [...newJobData?.classification_ids, id]
      }));
    } else {
      setNewJobsData((prev: IJobData) => ({
        ...prev,
        classification_ids: prev.classification_ids.filter((prevState) => prevState !== id)
      }));
    }
  };

  const addSourceLabel = () => {
    if (!newJobData?.sub_classifications?.value || !newJobData?.classifications?.value) return;
    if (!newJobData?.classification_ids?.includes(selectedClassification.id)) {
      const tempArray = [...newJobData?.classification_list];
      tempArray.push({
        id: selectedClassification.id,
        name: newJobData.classifications.value?.name,
        parent: newJobData.sub_classifications.value
      });
      setNewJobsData((prev: IJobData) => ({
        ...prev,
        classification_list: tempArray,
        classification_ids: [...newJobData.classification_ids, selectedClassification.id]
      }));
    }
  };

  return (
    <Box>
      <Box sx={classes.bodyContainer}>
        {newJobData?.classification_list?.length > 0 &&
          newJobData.classification_list.map((classification, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  sx={{ ...classes.checkbox, marginLeft: 1 }}
                  onChange={(e) => handleChange(e, classification.id)}
                  defaultChecked={true}
                />
              }
              label={`${classification?.parent?.name} ${classification?.name}`}
              sx={classes.classificationLabel}
            />
          ))}
      </Box>
      <Box sx={classes.selectGroup}>
        <Box sx={classes.generalModalFormLine}>
          <Box sx={{ position: 'relative', marginBottom: '4px' }}>
            <Autocomplete
              id="classifications"
              options={classificationsOptions}
              getOptionLabel={(option) => option?.name}
              value={newJobData?.classifications?.value}
              loading={classificationsOptions?.length < 1}
              loadingText={isLoading ? 'Loading classifications...' : 'No classifications'}
              sx={{ ...classes.formAutocomplete, width: '250px', marginTop: '24px' }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro, sans-serif',
                  color: '#333333',
                  maxHeight: '200px'
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Classification" InputLabelProps={{ shrink: true }} />
              )}
              onChange={(event, value) => {
                setNewJobsData((prev: IJobData) => ({
                  ...prev,
                  classifications: { ...prev.classifications, value },
                  sub_classifications: { ...prev.sub_classifications, value: null }
                }));
                setSelectedClassification(null);
              }}
            />
          </Box>
          {newJobData.classifications.value ? (
            <Box sx={{ position: 'relative', marginBottom: '4px' }}>
              <Autocomplete
                id="sub_classifications"
                options={
                  newJobData?.classifications?.value?.sub_occupation_classifications
                    ? newJobData?.classifications?.value?.sub_occupation_classifications
                    : []
                }
                getOptionLabel={(option) => option?.name}
                value={newJobData?.sub_classifications?.value}
                loading={classificationsOptions?.length < 1}
                loadingText={
                  isLoading ? 'Loading Sub-classifications......' : 'No Sub-classifications'
                }
                sx={{ ...classes.formAutocomplete, width: '250px', marginTop: '24px' }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sub-classifications"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(event, value) => {
                  setNewJobsData((prev: IJobData) => ({
                    ...prev,
                    sub_classifications: { ...prev.sub_classifications, value }
                  }));
                  setSelectedClassification(value);
                }}
              />
            </Box>
          ) : (
            <Box />
          )}
        </Box>
      </Box>
      <Button
        id="add-source-button"
        sx={{
          ...classes.addSourceButton,
          marginTop: '0.5rem',
          padding: '8px 11px',
          width: 'unset'
        }}
        onClick={() => addSourceLabel(newJobData.classifications.value)}
        disabled={!selectedClassification}
      >
        Add Classification
      </Button>
    </Box>
  );
}
