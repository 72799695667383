import React, { Dispatch } from 'react';
import { Box, Button } from '@mui/material';
import { classes } from './styles';
import { TableActionDrawerAction } from './types';

// The parentDiv prop is used to center the tooltip in relation to the table and not the
// entire page. You can pass in a ref.current which should be a parent div with 100% width

const TotalSelectedIndicatorWithActions = ({
  totalSelected,
  parentDiv,
  dispatch,
  actionButtonsList,
  actionCall,
  actionButtonId = ''
}: {
  totalSelected: number;
  parentDiv: HTMLDivElement | null;
  dispatch?: Dispatch<TableActionDrawerAction>;
  actionButtonsList?: {
    id: string;
    label: string;
    icon: JSX.Element;
  }[];
  actionCall?: null | (() => void);
  actionButtonId?: string;
}) => {
  return (
    <Box
      id="selected-tooltip"
      sx={{
        ...classes.selectedTooltip,
        left: parentDiv
          ? parentDiv.getBoundingClientRect().left +
            parentDiv.getBoundingClientRect().width / 2 -
            332 // this is half the size of the 'tooltip' div
          : 55, // this value for when the parent div is inside a modal
        ...(totalSelected ? classes.fadeIn : classes.fadeOut)
      }}
    >
      <Box id="selected-tooltip-text" sx={classes.selectAllWithActionsTooltip}>
        {totalSelected} selected
        <Box>
          {actionButtonsList &&
            dispatch &&
            actionButtonsList.map((action) => (
              <Button
                sx={classes.selectAllWithActionsTooltipButton}
                startIcon={action.icon}
                onClick={() => {
                  dispatch &&
                    dispatch({
                      type: 'SET_ACTION_MODAL_OPEN',
                      payload: { name: action.id, isOpen: true }
                    });
                  actionCall && actionCall();
                }}
                key={action.id}
                id={actionButtonId}
              >
                {action.label}
              </Button>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TotalSelectedIndicatorWithActions;
