
import React, { Component } from 'react';
import T from 'prop-types';

export default class CallbackHeader extends Component {
  static propTypes = {
    callback: T.func,
    field:    T.string
  };
  constructor(props) {
    super(props)
  }

  render() {
    return (<th><a href='#' onClick={this.props.callback} data-field={this.props.field}>{this.props.data}</a></th>)
  }
}
