import React from 'react';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SystemReportsSection from './index';
import { ReportContext } from '../index';
import ReportServiceApi from '../ReportServiceApi';
import '@testing-library/jest-dom';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

jest.mock('@tanstack/react-query', () => ({
  ...jest.requireActual('@tanstack/react-query'),
  useQuery: jest.fn().mockReturnValue({
    data: { data: [{ attributes: ['data 1'] }], headers: ['header 1'] },
    isLoading: false,
    error: {}
  })
}));

const { getByTestId, getAllByText, getByText } = screen;

describe('SystemReportsSection', () => {
  const user = userEvent.setup();

  it('should render SystemReportsTable when preview is not opened', () => {
    render(
      <ReportContext.Provider
        value={
          new ReportServiceApi(
            [1],
            {
              firstname: 'Di',
              lastname: 'Wu',
              api_key: '123',
              email: 'test@test.com',
              id: 1,
              staff: true,
              iana_timezone: 'Australia/Sydney',
              admin: false
            },
            'someUrl',
            { id: 1, name: 'test', new_reporting_enabled: true },
            false
          )
        }
      >
        <QueryClientProvider client={queryClient}>
          <SystemReportsSection />
        </QueryClientProvider>
      </ReportContext.Provider>
    );
    expect(screen.getByTestId('system-report-table')).toBeInTheDocument();
  });

  it('should render SystemReportsPreview when Generate Report button is clicked', async () => {
    render(
      <ReportContext.Provider
        value={
          new ReportServiceApi(
            [1],
            {
              firstname: 'Di',
              lastname: 'Wu',
              api_key: '123',
              email: 'test@test.com',
              id: 1,
              staff: true,
              iana_timezone: 'Australia/Sydney',
              admin: false
            },
            'someUrl',
            { id: 1, name: 'test', new_reporting_enabled: true },
            false
          )
        }
      >
        <QueryClientProvider client={queryClient}>
          <SystemReportsSection />
        </QueryClientProvider>
      </ReportContext.Provider>
    );
    await user.click(getAllByText('Generate Report')[0]);
    expect(getByTestId('system-report-preview')).toBeInTheDocument();
    expect(getByText('header 1')).toBeInTheDocument();
    expect(getByText('data 1')).toBeInTheDocument();
  });
});
