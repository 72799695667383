"use strict";
export const stringToSeconds = (delay) => {
  const [number, unit] = delay.split(" ");
  if (unit === "hour" || unit === "hours") {
    return Number(number) * 60 * 60;
  } else if (unit === "day" || unit === "days") {
    return Number(number) * 60 * 60 * 24;
  } else {
    return 0;
  }
};
