import React, { Dispatch } from 'react';
import { IEvent } from '../../../../Job/types';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { theme } from '../../../../../ThemeContext/ThemeObject';
import EventCircle from '../../../assets/EventCircle';
import AttendeeList from './AttendeeList';
import { ScoutCalendarState, ScoutCalendarAction } from '../../../../../ScoutCalendar/types';

interface IProps {
  event: IEvent & { isFirst: boolean; isLast: boolean };
  ScoutCalendarState: ScoutCalendarState;
  dispatch: Dispatch<ScoutCalendarAction>;
  newCalendarEnabled: boolean;
}

function EventItem({ event, ScoutCalendarState, dispatch, newCalendarEnabled }: IProps) {
  const { createEventObject } = ScoutCalendarState;
  function handleSelectEvent(event: IEvent) {
    dispatch({
      type: 'SET_CREATE_EVENT_OBJECT',
      payload: {
        ...createEventObject,
        eventName: event.subject,
        eventDescription: event.note || '',
        maxCandidates: event.max_candidates,
        recruiters: event.attendees
          .filter((a) => a.type === 'User')
          .map((a) => ({
            id: a.id,
            name: a.name,
            checked: false,
            attendeeId: a.attendee_id,
            state: a.state
          })),
        eventType: event.event_type,
        duration: event.duration_in_minutes,
        eventDate: new Date(event.begins_at),
        eventId: event.id,
        candidates: event.attendees.filter((a) => a.type === 'Candidate'),
        dates: [new Date(event.begins_at)],
        owner: event.owner
      }
    });
    dispatch({ type: 'SET_SHOW_VIEW_EVENT_MODAL', payload: true });
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      {event.isFirst && <Box sx={styles.date}>{dayjs(event.date).format('D MMM YYYY')}</Box>}
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="flex-start"
        gap="24px"
      >
        <Box display="flex" height="100%">
          <Box sx={{ ...styles.lineContainer, ...(!event.isLast && { ...styles.connectingLine }) }}>
            <EventCircle />
          </Box>
          <Box sx={styles.infoContainer}>
            <Box>{dayjs(event.begins_at).format('hh:mm a')}</Box>
            {newCalendarEnabled ? (
              <Box onClick={() => handleSelectEvent(event)} sx={styles.eventSubject}>
                {event.subject}
              </Box>
            ) : (
              <Box>
                <a
                  className="dashboard-event"
                  href={`/admin/calendar/events/${event.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {event.subject}
                </a>
              </Box>
            )}
            <Box>{event.job_title}</Box>
          </Box>
        </Box>
        <Box sx={styles.candidatesContainer}>
          {!!event.attendees?.length && <AttendeeList attendees={event.attendees} />}
        </Box>
        <Box sx={styles.timeContainer}>
          <Box>{event.duration}</Box>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  date: {
    textTransform: 'uppercase',
    color: '#333',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    fontWeight: '600'
  },
  lineContainer: {
    marginRight: theme.spacing(2),
    position: 'relative',
    '& svg': {
      width: '30px',
      height: '30px'
    }
  },
  connectingLine: {
    '&:before': {
      content: '""',
      display: 'block',
      width: '1px',
      background: '#edf2f5',
      height: '100%',
      position: 'absolute',
      left: '15px',
      top: '30px'
    }
  },
  infoContainer: {
    '& > div:nth-child(1)': {
      color: '#666',
      fontWeight: '700',
      fontSize: '13px',
      textTransform: 'uppercase'
    },
    '& > div:nth-child(2) a': {
      color: '#333',
      fontWeight: '700',
      fontSize: '18px'
    },
    '& > div:nth-child(3)': {
      color: '#666',
      fontSize: '14px'
    }
  },
  candidatesContainer: {
    display: 'flex',
    width: '40%',
    position: 'relative',
    justifyContent: 'center'
  },
  timeContainer: {
    background: '#F2F2F2',
    borderRadius: '4px',
    padding: '6px 0',
    color: '#666666',
    fontWeight: '700',
    width: '80px',
    textAlign: 'center',
    fontSize: '14px',
    flexShrink: 0
  },
  eventSubject: {
    cursor: 'pointer',
    color: '#333',
    fontWeight: '700',
    fontSize: '18px',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
};

export default EventItem;
