import React, { Dispatch, FC, useState } from 'react';
import { Box, Modal, Button, TextField, Autocomplete, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import { JobStates } from '../config';

interface IJobStatesModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
}

const JobStateModal: FC<IJobStatesModal> = ({ dispatch, state }) => {
  const { filtersModal, jobStates } = state;
  const [states, setStates] = useState<string[]>(jobStates);

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, 'Job State Report': false }
    });
  };

  return (
    <Modal
      open={filtersModal['Job State Report']}
      onClose={handleClose}
      aria-labelledby="job-state-filters-modal"
    >
      <Box sx={{ ...classes.actionsModal, maxHeight: '360px', width: '720px' }}>
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>Job state report filters</Box>
        </Box>
        <Stack sx={{ marginTop: '1.75rem', flexGrow: 1 }}>
          <Box sx={{ ...classes.modalFormLine }}>
            <Autocomplete
              multiple
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              options={JobStates}
              getOptionLabel={(option) => option}
              value={states}
              onChange={(_event, newValue) => setStates(newValue)}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro',
                  color: '#333333',
                  maxHeight: '200px',
                  textTransform: 'capitalize'
                }
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ textTransform: 'capitalize', input: { '&::placeholder': { opacity: 1 } } }}
                  {...params}
                  label="Job states"
                  placeholder={states.length ? '' : 'Please select'}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                  }}
                />
              )}
            />
          </Box>
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset' }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              if (states.length) {
                dispatch({ type: 'SET_FILTERS', payload: { 'Job states': states.join(', ') } });
              } else {
                dispatch({ type: 'SET_FILTERS', payload: {} });
              }
              dispatch({ type: 'SET_PAGE', payload: 1 });
              dispatch({ type: 'JOB_STATES', payload: states });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default JobStateModal;
