import React from 'react';
import { Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { classes } from './styles';
import QuestionSwitch from '../SharedComponents/Inputs/Switch';
import { IGeneratedContent } from './AIStudioNewJob';

export const QuestionsPreview = ({
  questions,
  previewOnly,
  handleSwitchChange,
  handleRemoveQuestion
}: {
  questions: IGeneratedContent['screeningQuestions'];
  previewOnly?: boolean;
  handleSwitchChange?: (index: number, type: string) => void;
  handleRemoveQuestion?: (index: number) => void;
}) => {
  const onSwitchChange = (index: number, type: string) => {
    if (handleSwitchChange) {
      handleSwitchChange(index, type);
    }
  };

  return (
    <Box sx={classes.previewContainer}>
      <h3>Screening Questions</h3>
      <Box sx={{ ...classes.questionsContainer, ...(previewOnly && { filter: 'contrast(0.5)' }) }}>
        {questions.map((item, index) => (
          <Box sx={classes.question} key={index}>
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
              <Box sx={{ marginTop: '7px' }}>{item.title}</Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <QuestionSwitch
                    item={item}
                    index={index}
                    type="enabled"
                    label="Enabled"
                    onChange={onSwitchChange}
                  />
                  <QuestionSwitch
                    item={item}
                    index={index}
                    type="required"
                    label="Required"
                    onChange={onSwitchChange}
                  />
                  <QuestionSwitch
                    item={item}
                    index={index}
                    type="rateable"
                    label="Rateable"
                    onChange={onSwitchChange}
                  />
                  {handleRemoveQuestion && (
                    <Button
                      sx={classes.deleteQuestionButton}
                      endIcon={<DeleteIcon />}
                      onClick={() => handleRemoveQuestion(index)}
                    >
                      Remove
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
