"use strict";
export const booleanQueryToEditableArray = (booleanQuery) => {
  try {
    const queryArray = booleanQuery.split(") ");
    const queryObject = [];
    queryArray.forEach((query, index) => {
      const matchArray = query.match(/AND \(|OR \(|-\(/);
      const baseOperator = matchArray && matchArray.length > 0 ? matchArray[0].replace(" (", "").replace("-(", "NOT") : "ROOT";
      const operatorArray = query.match(/ AND | OR /);
      const operator = operatorArray && operatorArray.length > 0 ? operatorArray[0].trim() : "ERROR";
      const queryValues = query.replace(/\(|\)/g, "").split(/\s*AND\s*|\s*OR\s*/).filter((value) => value !== "");
      queryObject.push({
        id: index,
        baseOperator,
        operator,
        queryValues
      });
    });
    return queryObject;
  } catch (e) {
    console.log(e);
    return [];
  }
};
export const editableBooleanArrayToBooleanQuery = (editableBooleanArray) => {
  try {
    let booleanQuery = "";
    editableBooleanArray.forEach((queryObject, index) => {
      if (index === 0) {
        booleanQuery += `(${queryObject.queryValues.join(` ${queryObject.operator} `)})`;
      } else {
        booleanQuery += ` ${queryObject.baseOperator.replace(
          "NOT",
          "-"
        )} (${queryObject.queryValues.join(` ${queryObject.operator} `)})`;
      }
    });
    return booleanQuery;
  } catch (e) {
    console.log(e);
    return "";
  }
};
