import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { styles } from '../styles';

export default function Rating({
  value,
  setValue,
  disabled = false
}: {
  value: number;
  setValue: (rating: number) => void;
  disabled?: boolean;
}) {
  const [hover, setHover] = useState<number>(0);
  const ratings: (1 | 2 | 3 | 4 | 5)[] = [1, 2, 3, 4, 5];
  return (
    <Stack sx={{ flexDirection: 'row', columnGap: 1 }}>
      {ratings.map((r) => (
        <Stack
          key={r}
          sx={{
            ...styles.ratingButton,
            ...((hover || value) >= r && styles.ratingColor[r]),
            cursor: disabled ? 'inherit' : 'pointer'
          }}
          onClick={() => !disabled && setValue(r)}
          onMouseEnter={() => !disabled && setHover(r)}
          onMouseLeave={() => !disabled && setHover(0)}
        >
          {r}
        </Stack>
      ))}
    </Stack>
  );
}
