import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
  Popover,
  Divider,
  Typography,
  Collapse
} from '@mui/material';
import {
  StaticDatePicker,
  LocalizationProvider,
  PickersDay,
  PickersActionBarProps,
  StaticDatePickerProps
} from '@mui/x-date-pickers';
import { Event as EventIcon, Close as CloseIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { sharedClasses } from './sharedClasses';
import { theme } from '../../../ThemeContext/ThemeObject';
import { TransitionGroup } from 'react-transition-group';

interface IControlledTimePicker extends Omit<StaticDatePickerProps<Dayjs>, 'onAccept'> {
  values: Date[];
  onAccept: (dates: Date[]) => void;
  label: string;
  required?: boolean;
}

interface IDayItem {
  day: Dayjs;
  removeSelectedDate: (item: Dayjs) => void;
}

const ServerDay = (props: any) => {
  const { selectedDays = [], onDaySelect, day, outsideCurrentMonth, ...other } = props;
  const isSelected = selectedDays
    .map((e: Dayjs) => e.format('M/D/YYYY'))
    .includes(day.format('M/D/YYYY'));
  const nextDayIsSelected = selectedDays
    .map((e: Dayjs) => e.format('M/D/YYYY'))
    .includes(day.add(1, 'day').format('M/D/YYYY'));
  const previousDayIsSelected = selectedDays
    .map((e: Dayjs) => e.format('M/D/YYYY'))
    .includes(day.subtract(1, 'day').format('M/D/YYYY'));

  const borderRadius = () => {
    if (previousDayIsSelected && nextDayIsSelected) {
      return 'unset';
    } else if (nextDayIsSelected) {
      return '50% 0% 0% 50%';
    } else if (previousDayIsSelected) {
      return '0% 50% 50% 0%';
    }
  };

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      selected={isSelected}
      onDaySelect={onDaySelect}
      sx={{
        ...sharedClasses.pickersDay,
        '&.Mui-selected': {
          background: `${theme.palette.action.main} !important`,
          borderRadius: borderRadius()
        }
      }}
    />
  );
};

const ActionBar = ({
  onAccept,
  onCancel
}: Omit<PickersActionBarProps, 'onClear' | 'onSetToday'>) => {
  return (
    <DialogActions>
      <Button
        onClick={onCancel}
        sx={{
          ...sharedClasses.genericRedButton,
          background: theme.palette.common.white,
          '&:hover': { background: theme.palette.common.white }
        }}
      >
        Cancel
      </Button>
      <Button onClick={onAccept} sx={sharedClasses.genericButton}>
        Submit
      </Button>
    </DialogActions>
  );
};

export default function MultipleDatesPicker({
  values,
  onAccept,
  label,
  required,
  ...rest
}: IControlledTimePicker) {
  const [selectedDays, setSelectedDays] = useState<Dayjs[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    setSelectedDays(values.map((v) => dayjs(v)));
  }, [values]);

  const onDaySelect = (day: any) => {
    const newValue = [...selectedDays];
    if (newValue.map((n) => n.format('M/D/YYYY')).includes(day.format('M/D/YYYY'))) {
      setSelectedDays(newValue.filter((v) => v.format('M/D/YYYY') !== day.format('M/D/YYYY')));
    } else {
      newValue.push(day);
      setSelectedDays(newValue.sort((a, b) => (a.isAfter(b) ? 1 : -1)));
    }
  };

  const onClose = () => {
    setSelectedDays(values.map((v) => dayjs(v)));
    setAnchorEl(null);
  };

  const onActionAccept = () => {
    onAccept(selectedDays.map((d) => d.toDate()));
    setAnchorEl(null);
  };

  const pickerDayComponent = (props: any) => (
    <ServerDay {...props} selectedDays={selectedDays} onDaySelect={onDaySelect} />
  );

  const actionBarComponent = () => <ActionBar onAccept={onActionAccept} onCancel={onClose} />;

  return (
    <Stack>
      <InputLabel sx={sharedClasses.multipleDatesPickerLabel}>
        {label}
        {required && <Stack sx={{ color: theme.palette.error.main }}>*</Stack>}
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        sx={sharedClasses.multipleDatesPickerInput}
        endAdornment={
          <InputAdornment position="end">
            {values.length > 1 && (
              <Stack sx={sharedClasses.multipleDatesPickerExtraDates}>{`+${
                values.length - 1
              }`}</Stack>
            )}
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              edge="end"
              sx={{ '&:focus': { outline: 'none' } }}
            >
              <EventIcon />
            </IconButton>
          </InputAdornment>
        }
        error={selectedDays.length === 0}
        label="Password"
        value={selectedDays[0]?.format('DD/MM/YYYY') || ''}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiPaper-root': {
            marginTop: 1,
            marginLeft: 1,
            display: 'flex'
          }
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            slots={{ day: pickerDayComponent, actionBar: actionBarComponent }}
            slotProps={{
              toolbar: { hidden: true }
            }}
            sx={sharedClasses.multipleDatesPickerStaticDatePicker}
            {...rest}
          />
        </LocalizationProvider>
        <Divider orientation="vertical" flexItem />
        <Stack
          spacing={2}
          sx={{
            width: '200px',
            py: theme.spacing(3),
            px: theme.spacing(2),
            maxHeight: '406px'
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack sx={{ fontWeight: 'bold', color: '#666666' }}>Selected Dates</Stack>
            <Stack
              sx={{
                color: theme.palette.action.main,
                fontWeight: 'bold',
                paddingInline: theme.spacing(0.65)
              }}
            >
              {selectedDays.length}
            </Stack>
          </Stack>
          <Stack
            sx={{
              overflowY: 'auto'
            }}
          >
            <TransitionGroup>
              {selectedDays.map((day, idx) => (
                <Collapse key={idx}>
                  <DayItem day={day} removeSelectedDate={onDaySelect} />
                </Collapse>
              ))}
            </TransitionGroup>
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
}

function DayItem({ day, removeSelectedDate }: IDayItem) {
  return (
    <Stack direction="row" justifyContent="space-between" mb={1}>
      <Typography sx={{ fontFamily: theme.typography.fontFamily }}>
        {dayjs(day).format('ddd, D MMMM YYYY')}
      </Typography>
      <IconButton
        onClick={() => removeSelectedDate(day)}
        sx={{ p: 0, color: theme.palette.error.main }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
}
