import React, { useState, useEffect, useRef, Dispatch, SetStateAction, useCallback } from 'react';
import { Box, Modal, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MultilineFormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { StyledSnackbarProps } from '../../../Components/CustomUIElements/StyledSnackbar';
import { classes } from '../styles';
import Api from '../../API';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import SortableChoice from './SortableChoice';
import { IUserPermissions } from '../../../Components/sharedTypes';

export default function NewEmailTemplateField({
  apiKey,
  approvalTemplateId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  editingQuestion,
  getQuestions,
  userPermissions
}: {
  apiKey: string;
  modalsOpen: Record<string, boolean>;
  setModalsOpen: Dispatch<SetStateAction<Record<string, boolean>>>;
  approvalTemplateId: number;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
  editingQuestion?: any;
  getQuestions: any;
  userPermissions: IUserPermissions;
}) {
  const [isCreating, setIsCreating] = useState(false);
  const [label, setLabel] = useState('');
  const [choiceList, setChoiceList] = useState([
    { id: '', _destroy: 0, position: null, name: '', rating: null, rank: null, rank_value: null }
  ]);
  const [errorLabel, setErrorLabel] = useState('');
  const [errorField, setErrorField] = useState('');
  const [errorChoices, setErrorChoices] = useState('');

  const labelRef = useRef<HTMLInputElement>(null);
  const fieldTypeRef = useRef<HTMLInputElement>(null);
  const choicesRef = useRef<HTMLInputElement>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = choiceList.map((c) => c.id).indexOf(active.id);
      const newIndex = choiceList.map((c) => c.id).indexOf(over.id);
      const newArray = arrayMove(choiceList, oldIndex, newIndex);

      setChoiceList(newArray);
    }
  }

  const setDefaultValue = useCallback(async () => {
    if (editingQuestion) {
      setLabel(editingQuestion.title);
      setChoiceList(editingQuestion.field_choices);
    }
  }, [editingQuestion]);

  const handleClose = () => {
    setModalsOpen((prev) => ({ ...prev, newEmailTemplateField: false }));
    clearData();
  };

  const handleSubmit = async (approvalTemplateId: number) => {
    if (!label) {
      labelRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setErrorLabel('This field is required');
      return;
    }
    setIsCreating(true);

    let fieldObject = {
      title: label,
      enabled: true,
      required: true,
      rateable: false,
      field_type: 'radio_button_field',
      for_email_template: true
    };

    choiceList.map((choice, index) => {
      if (!choice?.name) {
        choicesRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setErrorChoices('All field choices must each have a value');
        return;
      }
      choice.position = index;
      if (choice.id.toString().length == 13) {
        choice.id = '';
      }
    });

    const jsonString = JSON.stringify(Object.assign({}, choiceList));
    const attributes = JSON.parse(jsonString);
    fieldObject['field_choices_attributes'] = attributes;

    try {
      if (editingQuestion) {
        const data = await Api.putQuestion(
          { 'X-api-authenticate': apiKey },
          editingQuestion.id,
          approvalTemplateId,
          {
            field: fieldObject
          }
        );
        setSnackbar({
          message: 'Field has been updated',
          state: 'success'
        });
      } else {
        const data = await Api.newField({ 'X-api-authenticate': apiKey }, approvalTemplateId, {
          field: fieldObject
        });
        setSnackbar({
          message: 'Field has been created',
          state: 'success'
        });
        clearData();
      }
      handleClose();
    } catch (error) {
      setSnackbar({
        message: `Field failed to create: ${error}`,
        state: 'error'
      });
    } finally {
      setIsCreating(false);
      getQuestions();
    }
  };

  const handleAddChoice = () => {
    const newTempId = Date.now();
    setChoiceList([...choiceList, { id: newTempId, _destroy: 0, position: null, name: '' }]);
  };

  const onRemoveChoice = (index: number) => {
    const list = [...choiceList];
    if (list[index].position) {
      list[index]._destroy = 1;
    } else {
      list.splice(index, 1);
    }
    setChoiceList(list);
  };

  const handleChoiceInput = (e, index: number) => {
    const { name, value } = e.target;
    const list = [...choiceList];
    list[index].name = value;
    setChoiceList(list);
  };

  const clearData = () => {
    setLabel('');
    setChoiceList([{ id: '', _destroy: 0, position: 0, name: '' }]);
    setErrorLabel('');
    setErrorField('');
    setErrorChoices('');
  };

  useEffect(() => {
    setDefaultValue();
  }, [setDefaultValue, editingQuestion]);

  return (
    <Modal
      open={modalsOpen.newEmailTemplateField}
      onClose={() => handleClose(true)}
      aria-labelledby="new-email-template-field-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <Box sx={classes.modalWrapper}>
          <CloseIcon onClick={() => handleClose(true)} sx={classes.closeIcon} />
          <Box id="new-email-template-field-title" sx={classes.actionModalHeader}>
            New email template field
          </Box>
          <Box
            sx={classes.modalContent}
            id="new-email-template-field-modal-content"
            aria-label="New Email Template Field Modal Content"
          >
            <Box sx={{ marginLeft: '15px' }}>
              <Box sx={classes.modalFormLine}>
                <MultilineFormTextField
                  value={label}
                  onChange={(e) => {
                    setErrorLabel('');
                    setLabel(e.target.value);
                  }}
                  styles={{ width: '600px', marginTop: '20px' }}
                  rows={6}
                  innerRef={labelRef}
                  error={errorLabel}
                  label={'Field label'}
                />
              </Box>
            </Box>
            <Box sx={classes.addChoiceContainer} ref={choicesRef}>
              <Box sx={classes.addChoiceHead}>
                <Box id="add-choice-title" sx={classes.addChoiceLabel}>
                  Choices
                </Box>
                <Button
                  id="add-choice-button"
                  sx={classes.modalAddChoiceButton}
                  onClick={handleAddChoice}
                >
                  Add choice
                </Button>
              </Box>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext items={choiceList} strategy={verticalListSortingStrategy}>
                  {choiceList.map(
                    (choice, index) =>
                      !(choice?._destroy === 1) && (
                        <SortableChoice
                          key={choice.id}
                          id={choice.id}
                          choice={choice}
                          index={index}
                          handleChoiceInput={handleChoiceInput}
                          onRemoveChoice={onRemoveChoice}
                        />
                      )
                  )}
                </SortableContext>
              </DndContext>
              {errorChoices && <Box sx={{ ...classes.inlineError }}>{errorChoices}</Box>}
            </Box>
            <Box sx={classes.modalFooter}>
              <Button
                id="close-new-email-template-field-button"
                sx={classes.modalCancelButton}
                onClick={() => handleClose(true)}
              >
                Cancel
              </Button>
              <Button
                id="submit-new-email-template-field-button"
                type="submit"
                sx={{ ...classes.modalCreateButton, width: '91px', height: '41px' }}
                onClick={() => handleSubmit(approvalTemplateId)}
              >
                {isCreating ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
