"use strict";
const lineHeight = 1.43;
const linesToShow = 3;
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  category: {
    marginBottom: theme.spacing(2),
    marginTop: 0
  },
  tableWrapper: {
    tableLayout: "auto !important",
    "& tbody": {
      "& td, th ": {
        borderWidth: "1px 0px 0px 0px",
        backgroundColor: "white"
      },
      "& tr:last-child td, th": {
        borderBottom: "none"
      }
    },
    "& .Mui-selected": {
      backgroundColor: "#F2F2F2"
    },
    "& tr": {
      "& td, th": {
        borderWidth: "0px",
        textTransform: "none"
      }
    },
    "& svg": {
      fontSize: "1.1rem"
    }
  },
  manageMetrics: {
    position: "absolute",
    top: 0,
    right: 0
  },
  tableHeaderRow: {
    "& td, th": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      border: "none",
      backgroundColor: "white",
      textTransform: "none",
      "& span": {
        color: theme.palette.primary.main,
        "&:hover": {
          color: "#0c74a4"
        }
      },
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    "& .Mui-selected": {
      backgroundColor: "#F2F2F2"
    },
    "& .MuiTableSortLabel-icon": {
      color: "darkgrey !important"
    }
  },
  tableContainer: {
    display: "relative",
    scrollBehavior: "smooth"
  },
  tableBody: {
    "& tr, td": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px"
    }
  },
  leftArrow: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    width: "40px",
    height: "65px",
    background: "black",
    position: "fixed",
    bottom: "370px",
    borderRadius: "0px 10px 10px 0px",
    background: "#eeeeee",
    zIndex: "999",
    opacity: "0.9",
    cursor: "pointer"
  },
  rightArrow: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    width: "40px",
    height: "65px",
    background: "black",
    position: "fixed",
    bottom: "370px",
    borderRadius: "10px 0px 0px 10px",
    background: "#eeeeee",
    zIndex: "999",
    opacity: "0.9",
    cursor: "pointer"
  },
  showArrows: {
    display: "flex",
    animation: "$fadeIn linear 0.17s"
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 0.9 }
  },
  iconRed: {
    color: theme.palette.error.main
  },
  iconGreen: {
    color: theme.palette.success.main
  },
  iconBlue: {
    color: theme.palette.primary.main
  },
  iconGray: {
    color: "#666666"
  },
  stateColumn: {
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "113px",
    height: "35px",
    borderRadius: "6px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  noteColumn: {
    display: "-webkit-box",
    height: `${lineHeight}*${linesToShow}`,
    margin: "0 auto",
    lineHeight,
    lineClamp: linesToShow,
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  link: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    color: theme.palette.primary.main
  },
  published: {
    color: "#8CCF95",
    backgroundColor: "RGBA(140, 207, 149, 0.12)"
  },
  draft: {
    color: "#E6A052",
    backgroundColor: "RGBA(230, 160, 82, 0.12)"
  },
  unpublished: {
    color: "#D6827D",
    backgroundColor: "RGBA(214, 130, 125, 0.12)"
  },
  saved: {
    color: "#FFFFFF",
    backgroundColor: "#CCCCCC"
  },
  closed: {
    color: "#FFFFFF",
    backgroundColor: "#CCCCCC"
  },
  stateDropdown: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "11px",
      minWidth: "145px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "13px",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#939393",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  stateLoader: {
    color: theme.palette.primary.main
  },
  stateErrorTooltip: {
    "& .MuiTooltip-tooltip": {
      textAlign: "center"
    }
  },
  statusClamp: {
    display: "-webkit-box",
    position: "relative",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    width: "126px",
    minHeight: "35px",
    maxHeight: "53px",
    padding: "6px 20px 5px 12px",
    textAlign: "left",
    "&::after": {
      content: '"\u25BE"',
      fontSize: "13px",
      color: "#CCCCCC",
      position: "absolute",
      right: "6px",
      lineHeight: "0",
      top: "50%"
    },
    "& .status-dropdown-full": {
      visibility: "hidden",
      width: "126px",
      minHeight: "35px",
      padding: "6px 20px 5px 12px",
      textAlign: "left",
      position: "absolute",
      top: "-1px",
      left: "-1px",
      zIndex: "2",
      background: "#ffffff",
      borderRadius: "6px",
      border: "1px solid #CCCCCC",
      "&::after": {
        content: '"\u25BE"',
        fontSize: "13px",
        color: "#CCCCCC",
        position: "absolute",
        right: "6px",
        lineHeight: "0"
      }
    },
    "&:hover": {
      overflow: "visible",
      lineClamp: "unset",
      "& .status-dropdown-full": {
        visibility: "visible"
      }
    }
  },
  noDropdown: {
    border: "1px solid #FFFFFF",
    "& .status-dropdown-full": {
      border: "1px solid #FFFFFF"
    },
    "&::after": {
      display: "none"
    }
  },
  noStatuses: {
    width: "100%",
    paddingLeft: "18px",
    display: "flex"
  },
  statusDropdown: {
    border: "1px solid #CCCCCC",
    borderRadius: "6px",
    cursor: "pointer"
  },
  statusDropdownItem: {
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F1F1F1"
    }
  },
  statusDropdownItems: {
    "& .MuiPaper-root": {
      width: "200px",
      maxHeight: "250px",
      overflowY: "auto",
      borderRadius: "6px",
      marginTop: "6px"
    }
  },
  statusLoader: {
    color: theme.palette.primary.main,
    position: "absolute",
    zIndex: "4",
    top: "calc(50% - 9px)",
    left: "calc(50% - 9px)"
  },
  rowSelectContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "8px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666",
    "& span": {
      marginRight: "12px",
      paddingBottom: "6px"
    }
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "8px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666"
  },
  pagination: {
    marginRight: "24px",
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#CCCCCC",
      margin: "0px -2px"
    },
    "& .Mui-selected": {
      color: "#666666",
      background: "none",
      "&:hover": {
        background: "none"
      }
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#666666"
    }
  },
  rowSelect: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: "#666666",
    fontWeight: "bold",
    borderRadius: "6px !important",
    "& legend": {
      display: "none"
    },
    "& svg": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EAEEF0"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CACDCE"
    },
    "& .MuiOutlinedInput-input": {
      padding: "4px 12px 8px 12px !important"
    }
  },
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  },
  titleCell: {
    position: "sticky",
    left: "206px",
    width: "200px",
    background: "white !important",
    paddingRight: "8px !important",
    zIndex: "3",
    "& a": {
      fontWeight: "bold",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      color: "#666666",
      wordBreak: "break-word !important"
    },
    "& a:hover": {
      textDecoration: "none"
    },
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      right: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      right: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  titleHeaderCell: {
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      right: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      right: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  actionsCell: {
    position: "sticky",
    right: 0,
    width: "70px !important",
    background: "white",
    zIndex: "3",
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      left: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      left: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  actionsHeaderCell: {
    position: "sticky",
    right: 0,
    width: "70px !important",
    background: "white",
    zIndex: "1",
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      left: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      left: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  cellAnimate: {
    "&&::after": {
      opacity: "20%"
    }
  },
  selectedTooltip: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    visibility: "hidden",
    padding: "20px 50px",
    width: "665px",
    bottom: "40px",
    left: "calc(50% - 300px)",
    position: "fixed",
    zIndex: 99,
    backgroundColor: "rgba(242,242,242)",
    borderRadius: "8px",
    transform: "translateX(20px)"
  },
  selectedTooltipText: {
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#666666"
  },
  selectedTooltipButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "17px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#D6827D",
    backgroundColor: "rgba(214, 130, 125, 0.22)",
    boxShadow: "none",
    width: "129px",
    height: "50px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "rgba(214, 130, 125, 0.33)",
      boxShadow: "none"
    }
  },
  selectedTooltipButtonsContainer: {
    display: "flex",
    columnGap: "18px"
  },
  fadeIn: {
    visibility: "visible",
    opacity: "1",
    transform: "translateX(0px)",
    transition: "visibility 0s linear 0s, opacity 300ms, transform 300ms"
  },
  fadeOut: {
    visibility: "hidden",
    opacity: "0",
    transform: "translateX(20px)",
    transition: "visibility 0s linear 300ms, opacity 300ms, transform 300ms"
  },
  actionsMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      width: "135px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#666666",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  editNotePopover: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
      rowGap: "10px",
      width: "300px",
      minHeight: "150px",
      padding: "17px 17px 14px 17px"
    }
  },
  noteInput: {
    marginBottom: "4px"
  },
  notesButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    columnGap: "10px"
  },
  notesEditSaveButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "86px",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    },
    "& svg": {
      color: "#FFFFFF",
      fontSize: "16px !important"
    },
    "&:disabled": {
      backgroundColor: "#EEEEEE",
      color: "#BBBBBB",
      transition: "all 250ms ease-in-out"
    }
  },
  notesCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "86px",
    textTransform: "none",
    color: "#939393",
    backgroundColor: "rgba(147, 147, 147, 0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "rgba(147, 147, 147, 0.24)",
      boxShadow: "none"
    },
    "& svg": {
      color: theme.palette.error.main,
      fontSize: "16px !important"
    }
  },
  infoIcon: {
    color: "#084d6d",
    marginBottom: "3.5px",
    marginRight: "5px"
  },
  snackbar: {
    backgroundColor: "#5BC4BF",
    color: "#FFFFFF",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    maxWidth: "440px",
    textAlign: "center"
  },
  pinJobsButton: {
    width: "100px",
    height: "26px",
    borderRadius: "6px",
    backgroundColor: "rgba(230, 160, 82, 0.2)",
    color: "rgba(230, 160, 82)",
    textTransform: "none",
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "700",
    position: "absolute",
    zIndex: 99,
    top: "28px",
    right: "0px",
    "&:hover": {
      backgroundColor: "rgba(230, 160, 82, 0.3)"
    }
  },
  pinJobsNotActive: {
    backgroundColor: "#EEEEEE",
    color: "#AAAAAA",
    "&:hover": {
      backgroundColor: "rgba(230, 160, 82, 0.2)",
      color: "rgba(230, 160, 82)"
    }
  }
};
