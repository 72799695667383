import React, { useState, Dispatch, useRef } from 'react';
import { TCandidate } from '../../../types';
import StyledModal from '../../../../NewUI/Components/GenericModal/StyledModal';
import ModalFooterButtons from '../../../../NewUI/Components/GenericModal/ModalFooterButtons';
import { Box, Tabs, Tab } from '@mui/material';
import { FormTextField } from '../../../../NewUI/Components/CustomUIElements/FormTextField';
import {
  GenericTableState,
  GenericTableAction
} from '../../../../NewUI/Components/GenericTable/genericTableReducer';
import { ISnackbarInput } from '../../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { sharedClasses } from '../../../../NewUI/Components/CustomUIElements/sharedClasses';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import callAPI from '../../../../NewUI/Components/Utilities/callAPI';
import { initialTableState } from '../../../../NewUI/Components/GenericTable/genericTableReducer';
import { individualCandidatePoolsColumns } from './candidatesConfig';
import { classes } from '../../../styles';
import CandidateTab from './CandidatesTab';
import EmailTab from './EmailTab';

const IndividualCandidatePoolModal = ({
  isOpen,
  selectedPool,
  individualCandidatePoolsState,
  individualCandidatePoolsDispatch,
  emailsState,
  emailsDispatch,
  handleClose,
  setSnackbarState,
  refetch,
  modalTabValue,
  setModalTabValue
}: {
  isOpen: boolean;
  individualCandidatePoolsState: GenericTableState;
  individualCandidatePoolsDispatch: Dispatch<GenericTableAction>;
  emailsState: GenericTableState;
  emailsDispatch: Dispatch<GenericTableAction>;
  selectedPool: {
    candidates: TCandidate;
    id: number;
    title: string;
  };
  handleClose: () => void;
  setSnackbarState: (snackbarState: ISnackbarInput) => void;
  refetch: () => void;
  modalTabValue: number;
  setModalTabValue: (i: number) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [poolName, setPoolName] = useState<string>(selectedPool?.title);

  const queryClient = useQueryClient();

  const { mutate: updatedCandidatePool, isLoading: isLoadingUpdatePool } = useMutation({
    mutationFn: (data: string) =>
      callAPI(`/api/v4/candidate_pools/${selectedPool.id}`, { title: data }, 'PUT'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['individualCandidatePools'] });
      handleClose();
    },
    onError() {
      setSnackbarState({
        message: `There was an error updating the candidate pool, please refresh the page and try again.`,
        state: 'error'
      });
    }
  });

  return (
    <StyledModal
      isOpen={isOpen}
      handleClose={() => {
        individualCandidatePoolsDispatch({
          type: 'SET_DEFAULT_DATA',
          payload: initialTableState(individualCandidatePoolsColumns, {
            sortBy: 'created_at',
            sortOrder: 'desc'
          })
        });
        handleClose();
        refetch();
      }}
      label="Create new candidate pool"
      modalTitle="Candidate Pool"
      styleOverrides={{
        maxWidth: '1100px',
        maxHeight: '1100px'
      }}
      subHeaderStyling={false}
    >
      <Box
        sx={{
          ...sharedClasses.mainModalContent,
          height: '100%',
          paddingInline: '2px',
          marginTop: '15px'
        }}
        ref={containerRef}
      >
        <FormTextField
          id="pool-name"
          label="Candidate pool name"
          value={poolName}
          onChange={(e) => setPoolName(e.target.value)}
          styles={{ width: '290px' }}
        />
        <Tabs
          value={modalTabValue}
          onChange={(e, value) => setModalTabValue(value)}
          TabIndicatorProps={{
            sx: { maxWidth: modalTabValue ? '4.8em' : '5.75em' }
          }}
          sx={{
            ...classes.tabs,
            '& .MuiTabs-indicator': {
              ...classes.tabIndicator
            },
            margin: '1rem 0 2.5rem 0',
            borderBottom: '1px solid #EEEEEE'
          }}
        >
          <Tab label="Candidate" id="candidate-tab" />
          <Tab label="Emails" id="emails-tab" />
        </Tabs>
        {modalTabValue ? (
          <EmailTab
            isOpen={isOpen}
            setSnackbarState={setSnackbarState}
            selectedPool={selectedPool}
            emailsState={emailsState}
            emailsDispatch={emailsDispatch}
          />
        ) : (
          <CandidateTab
            isOpen={isOpen}
            setSnackbarState={setSnackbarState}
            selectedPool={selectedPool}
            candidatePoolsState={individualCandidatePoolsState}
            candidatePoolsDispatch={individualCandidatePoolsDispatch}
          />
        )}
      </Box>
      <ModalFooterButtons
        primaryButtonText="Update"
        secondaryButtonText="Cancel"
        primaryButtonCallback={() => updatedCandidatePool(poolName)}
        secondaryButtonCallback={handleClose}
        primaryButtonID="update-candidate-pool-button"
        secondaryButtonID="cancel-candidate-pool-button"
        primaryButtonDisabled={!poolName}
        isLoading={isLoadingUpdatePool}
      />
    </StyledModal>
  );
};

export default IndividualCandidatePoolModal;
