import axios from 'axios';

export function getBookmarks(url, videoId) {
  const query = `query fetchBookmark($videoId: ID!, $slugId: ID){
    video(id: $videoId, slug: $slugId) {
      bookmarks {
        text : description
        timeStamp : seconds
        id
      }
    }
  }`;

  return axios.post(
    url,
    JSON.stringify({
      query: query,
      variables: {
        videoId: videoId,
      },
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then(response => {
    if (response.data && response.data.data) {
      const sortBookmarks = response.data.data.video.bookmarks;
      sortBookmarks.sort(function (a, b) {
        return a.timeStamp - b.timeStamp;
      });
      return sortBookmarks;
    } else {
      alert('Error fetching bookmarks, refresh to try again');
    }
  });
}

export function addBookmark(url, text, seconds, videoId) {
  const variables = {
    "videoId": videoId,
    "seconds": parseInt(seconds),
    "description": text
  };

  const query = `mutation createBookmark($videoId: ID!, $seconds: Int!, $description: String) {
    createBookmark(input: {videoId: $videoId, seconds: $seconds, description: $description}){
      errors
      bookmark {
        id
        seconds
        description
      }
    }
  }`;
  
  return axios.post(
    url,
    JSON.stringify({
      query: query,
      variables: variables,
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then(response => {
    if (response.data && response.data.data) {
      return response.data.data.createBookmark.bookmark.id;
    } else {
      alert('Error adding bookmark, please refresh the page and try again.');
    }
  });
}

export function updateBookmark(url, timeStamp, text, bookmarkId) {
  const variables = {
    "bookmarkId": bookmarkId,
    "seconds": parseInt(timeStamp),
    "description": text
  }

  const query = `mutation udpdateBookmark($bookmarkId: ID!, $seconds: Int!, $description: String!){
    updateBookmark(input: {bookmarkId: $bookmarkId, seconds: $seconds, description: $description}){
      errors
      bookmark{
        id
        seconds
        description
      }
    }
  }`;

  axios.post(
    url,
    JSON.stringify({
      query: query,
      variables: variables,
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export function deleteBookmark(url, bookmarkId) {
  const query = `
      mutation deleteBookmark($bookMarkID: ID!){
        deleteBookmark(input: {bookmarkId: $bookMarkID}){
        errors
      }
  }`;
  axios.post(
    url,
    JSON.stringify({
      query: query,
      variables: {bookMarkID: bookmarkId},
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}
