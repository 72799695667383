"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
    marginBottom: "30px",
    width: "100%",
    columnGap: "16px",
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  buttonRedLight: {
    color: "#E37D7A",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "8px 18px",
    fontWeight: "bold",
    fontSize: "14px",
    textDecoration: "underline",
    backgroundColor: "#ffffff",
    "&:hover": {
      color: "#d74742",
      backgroundColor: "#ffffff"
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  actionsModal: {
    display: "flex",
    flexDirection: "column",
    padding: "40px 60px",
    maxWidth: "800px",
    width: "90%",
    height: "95%",
    maxHeight: "400px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  modalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "30px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column"
  },
  modalEditButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 18px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  iconGrey: {
    color: "#666666",
    fontSize: "28px"
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "30px",
    width: "100%"
  },
  autocompleteListboxStyle: {
    fontFamily: "Source Sans Pro",
    color: "#333333",
    maxHeight: "200px"
  },
  checkboxLabel: {
    color: "#939393",
    paddingLeft: "4px"
  },
  checkbox: {
    color: "#DDDDDD",
    "&.Mui-checked": {
      color: "#5BC4BF"
    }
  }
};
