"use strict";
import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    width: "100%",
    padding: "50px 60px 20px 60px",
    minHeight: 630,
    maxHeight: 630,
    height: 630,
    "& h4": {
      marginTop: 0,
      color: "#004E70",
      fontSize: 26,
      fontWeight: 700,
      marginBottom: 30
    }
  },
  buttonContainer: {
    marginTop: "auto",
    paddingTop: 30,
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      padding: "12px 26px",
      color: "#FFF",
      minHeight: 50,
      marginLeft: 12
    },
    "&.move-z": {
      marginBottom: -20,
      paddingBottom: 20,
      zIndex: 2,
      background: "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,1), rgba(255,255,255,1))"
    }
  },
  progressBar: {
    transition: "width 0.2s ease-in-out",
    height: 8,
    backgroundColor: theme.palette.secondary.main
  }
}));
