import React, { Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import { styles } from './styles';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';

export default function TemplateTitleTab({
  templateName,
  setTemplateName,
  tempErrorMessage
}: {
  templateName: string;
  setTemplateName: Dispatch<SetStateAction<string>>;
  tempErrorMessage: string | null;
}) {
  return (
    <Box sx={styles.tabContent}>
      <Box sx={styles.tabContentTitle}>Approval template title</Box>
      <Box sx={styles.tabContentBody}>
        <FormTextField
          onChange={(e) => setTemplateName(e.target.value)}
          label="Title"
          value={templateName ? templateName : ''}
          required
          styles={{ width: '350px' }}
        />
        {tempErrorMessage && <Box sx={styles.tabContentError}>{tempErrorMessage}</Box>}
      </Box>
    </Box>
  );
}
