import React from 'react';
import ScoutSelect from './select';

interface IEventStatus {
  id: number;
  details: string;
}

function EventStatusSelect({
  data,
  onChangeFunctionName
}: {
  data: IEventStatus;
  onChangeFunctionName: string;
}) {
  const formattedData = data.map(({ id, details }) => ({ value: id, label: details }));

  const handleChange = (value) => {
    window[onChangeFunctionName](value);
  };

  return <ScoutSelect name="event_status_id" data={formattedData} onChange={handleChange} />;
}

export default EventStatusSelect;
