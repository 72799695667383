import * as React from 'react';
import {
  Box,
  Autocomplete,
  TextField
} from '@mui/material';
import { classes } from '../../EditJob/styles';
import { SearchState, SearchAction } from '../../../reports/DataVizSection/useReport';

interface Props {
  userOptions: {
    value: string;
    label: string;
  }
  isLoading: boolean;
  reportState: SearchState;
  reportDispatch: React.Dispatch<SearchAction>;
}

export default function jobTitleField(myProps: Props) {
  const {
    jobTitleOptions,
    reportState,
    isLoading,
    reportDispatch
  } = myProps;
  return (
    <Box sx={{ position: 'relative', marginBottom: '4px' }}>
      <Autocomplete
        id="jobTitle"
        multiple
        options={jobTitleOptions}
        loading={reportState.timeToHireFields.jobTitle?.length < 1}
        getOptionLabel={(option) => `${option.label}`}
        value={reportState.jobTitle}
        loadingText={isLoading ? 'Loading job titles...' : 'No job titles'}
        sx={{
          ...classes.formAutocomplete,
          minWidth: '225px',
          width: '100%',
          marginTop: '24px'
        }}
        ListboxProps={{
          style: {
            fontFamily: 'Source Sans Pro, sans-serif',
            color: '#333333',
            maxHeight: '200px'
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Job Title"
            InputLabelProps={{ shrink: true }}
          />
        )}
        onChange={(event, value) => {
          reportDispatch({
            type: 'timeToHireFields',
            payload: { 'jobTitle': value }
          })
        }}
      />
    </Box>
  );
}
