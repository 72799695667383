import React, { Dispatch, FC, useContext, useState } from 'react';
import {
  Box,
  CircularProgress,
  Modal,
  Button,
  TextField,
  Autocomplete,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import { useQuery } from '@tanstack/react-query';
import { ReportContext } from '../../index';
import RecruitApi from '../../RecruitApi';

interface IAurionModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
}

const AurionModal: FC<IAurionModal> = ({ dispatch, state }) => {
  const { filtersModal, openPreview, statusId } = state;
  const [status, setStatus] = useState<[string, number] | null>(null);
  const reportServiceApi = useContext(ReportContext);

  const { data: statuses, isFetching: fetchingStatuses } = useQuery({
    queryKey: ['dropdown options'],
    queryFn: async () => {
      const { res } = await RecruitApi.getAurionReport({}, 'dropdown_options');
      return res.status_options as [string, number][];
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting ${openPreview} preview data, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: () => {
      setStatus(statuses.find((opt) => opt[1] === statusId) || null);
    },
    enabled: openPreview === 'Aurion Report',
    initialData: []
  });

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, [openPreview]: false }
    });
  };

  return (
    <Modal
      open={filtersModal['Aurion Report']}
      onClose={handleClose}
      aria-labelledby="aurion-filters-modal"
    >
      <Box
        sx={{
          ...classes.actionsModal,
          maxHeight: `360px`,
          width: '720px'
        }}
      >
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>Aurion report filters</Box>
        </Box>
        <Stack sx={{ marginTop: '1.75rem', flexGrow: 1 }}>
          <Box sx={{ ...classes.modalFormLine }}>
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              options={statuses}
              getOptionLabel={(option) => option[0]}
              value={status}
              onChange={(_event, newValue) => (newValue ? setStatus(newValue) : setStatus(null))}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro',
                  color: '#333333',
                  maxHeight: '200px',
                  textTransform: 'capitalize'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Include time for first application with statuses"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {fetchingStatuses && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  placeholder={'Please select'}
                  sx={{ input: { '&::placeholder': { opacity: 1 }, textTransform: 'capitalize' } }}
                />
              )}
            />
          </Box>
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset' }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              const filter: Record<string, string> = {};
              if (status) {
                filter['Application statuses'] = status[0];
                dispatch({
                  type: 'STATUS_ID',
                  payload: status[1]
                });
              } else {
                delete filter['Application statuses'];
                dispatch({ type: 'STATUS_ID', payload: null });
              }
              dispatch({ type: 'SET_FILTERS', payload: filter });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default AurionModal;
