import React from 'react';
import SmartFormFields from './SmartFormFields';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function EditFormPage({
  formId,
  userPermissions: userPermissions
}: {
  formId: number;
  userPermissions: Record<string, Record<string, boolean>>;
}) {
  const allRoles = userPermissions.roles.reduce(
    (acc, role) => {
      acc['paid_user'] = role.paid_role;
      role.feature_groups.forEach((group) => {
        acc[group.name] = acc[group.name] || {};
        group.permissions.forEach((permission) => (acc[group.name][permission] = true));
      });
      return acc;
    },
    {} as Record<string, Record<string, boolean> | string | boolean>
  );

  allRoles['user_type'] = userPermissions.roles[0]?.user_type;

  return (
    <QueryClientProvider client={queryClient}>
      <SmartFormFields formId={formId} userPermissions={allRoles} />
    </QueryClientProvider>
  );
}
