import React from 'react';
import { Box, Button } from '@mui/material';
import { FelixMessageSVG } from '../Graphics/FelixSVG';
import { classes } from '../styles';

const DynamicFooter = ({
  handleSecondaryButtonClick,
  handlePrimaryButtonClick,
  secondaryButtonText,
  primaryButtonText,
  secondaryButtonID,
  primaryButtonID,
  primaryButtonMinWidth,
  secondaryButtonMinWidth,
  felixMessage,
  felixMessageMaxWidth,
  disablePrimaryButton,
  disableSecondaryButton,
  footerWidth,
  primaryEndIcon,
  alternateSecondaryButton
}: {
  handleSecondaryButtonClick?: () => void;
  handlePrimaryButtonClick: () => void;
  secondaryButtonText?: string | JSX.Element;
  primaryButtonText: string | JSX.Element;
  secondaryButtonID?: string;
  primaryButtonID?: string;
  primaryButtonMinWidth?: string;
  secondaryButtonMinWidth?: string;
  felixMessage: JSX.Element;
  disablePrimaryButton?: boolean;
  disableSecondaryButton?: boolean;
  felixMessageMaxWidth?: string;
  footerWidth?: string;
  primaryEndIcon?: JSX.Element;
  alternateSecondaryButton?: boolean;
}) => {
  return (
    <Box sx={{ ...classes.footerContainer, ...(footerWidth && { width: footerWidth }) }}>
      <Box sx={classes.felixMessageContainer}>
        <FelixMessageSVG />
        <Box
          sx={{
            ...classes.felixMessage,
            ...(felixMessageMaxWidth && { maxWidth: felixMessageMaxWidth })
          }}
        >
          {felixMessage}
        </Box>
      </Box>
      <Box sx={classes.footerButtonsContainer}>
        {handleSecondaryButtonClick && (
          <Button
            id={secondaryButtonID}
            sx={{
              ...(alternateSecondaryButton
                ? classes.altFooterSecondaryButton
                : classes.footerBackButton),
              ...(secondaryButtonMinWidth && { minWidth: secondaryButtonMinWidth })
            }}
            onClick={handleSecondaryButtonClick}
            disabled={disableSecondaryButton || false}
          >
            {secondaryButtonText}
          </Button>
        )}
        <Button
          id={primaryButtonID}
          sx={{
            ...classes.footerContinueButton,
            ...(primaryButtonMinWidth && { minWidth: primaryButtonMinWidth })
          }}
          onClick={handlePrimaryButtonClick}
          disabled={disablePrimaryButton || false}
          endIcon={primaryEndIcon}
        >
          {primaryButtonText}
        </Button>
      </Box>
    </Box>
  );
};

export default DynamicFooter;
