import React from 'react';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { classes } from '../../../NewUI/Components/GenericTable/Dropdowns/styles';

const CreateNewPoolButton = ({ isOpen, onClick }: { isOpen: boolean; onClick: () => void }) => {
  return (
    <Tooltip title="Create new pool" placement="top" arrow>
      <IconButton
        id={`create-new-pool-button`}
        sx={isOpen ? { ...classes.iconButton, ...classes.iconButtonOpen } : classes.iconButton}
        onClick={onClick}
      >
        <SvgIcon sx={{ fontSize: '37px !important', transform: 'translate(2px, 4px) !important' }}>
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.75 10.125H4.5C3.88125 10.125 3.375 10.6312 3.375 11.25C3.375 11.8688 3.88125 12.375 4.5 12.375H15.75C16.3687 12.375 16.875 11.8688 16.875 11.25C16.875 10.6312 16.3687 10.125 15.75 10.125ZM15.75 5.625H4.5C3.88125 5.625 3.375 6.13125 3.375 6.75C3.375 7.36875 3.88125 7.875 4.5 7.875H15.75C16.3687 7.875 16.875 7.36875 16.875 6.75C16.875 6.13125 16.3687 5.625 15.75 5.625ZM4.5 16.875H11.25C11.8687 16.875 12.375 16.3687 12.375 15.75C12.375 15.1313 11.8687 14.625 11.25 14.625H4.5C3.88125 14.625 3.375 15.1313 3.375 15.75C3.375 16.3687 3.88125 16.875 4.5 16.875Z"
              fill="#084D6D"
            />
            <path
              d="M21.75 13.125H14.25C13.7297 13.125 13.3172 13.5422 13.3172 14.0625L13.3125 19.6875C13.3125 20.2078 13.7297 20.625 14.25 20.625H21.75C22.2703 20.625 22.6875 20.2078 22.6875 19.6875V14.0625C22.6875 13.5422 22.2703 13.125 21.75 13.125ZM16.3594 17.9109C16.3594 18.1125 16.1906 18.2812 15.9891 18.2812C15.8719 18.2812 15.7594 18.225 15.6891 18.1266L14.6016 16.6406V17.9906C14.6016 18.1547 14.4703 18.2812 14.3109 18.2812C14.1516 18.2812 14.0156 18.15 14.0156 17.9906V15.8391C14.0156 15.6375 14.1844 15.4688 14.3859 15.4688H14.4094C14.5313 15.4688 14.6437 15.525 14.7141 15.6234L15.7734 17.1094V15.7594C15.7734 15.6 15.9047 15.4688 16.0688 15.4688C16.2328 15.4688 16.3594 15.6 16.3594 15.7594V17.9109ZM18.7031 15.7688C18.7031 15.9328 18.5719 16.0594 18.4125 16.0594H17.5312V16.5844H18.4125C18.5766 16.5844 18.7031 16.7156 18.7031 16.875V16.8797C18.7031 17.0438 18.5719 17.1703 18.4125 17.1703H17.5312V17.6906H18.4125C18.5766 17.6906 18.7031 17.8219 18.7031 17.9812C18.7031 18.1453 18.5719 18.2719 18.4125 18.2719H17.2266C17.0063 18.2719 16.8281 18.0938 16.8281 17.8734V15.8578C16.8281 15.6469 17.0063 15.4688 17.2266 15.4688H18.4125C18.5766 15.4688 18.7031 15.6 18.7031 15.7594V15.7688ZM21.9844 17.8125C21.9844 18.0703 21.7734 18.2812 21.5156 18.2812H19.6406C19.3828 18.2812 19.1719 18.0703 19.1719 17.8125V15.7594C19.1719 15.6 19.3031 15.4688 19.4625 15.4688C19.6219 15.4688 19.7531 15.6 19.7531 15.7594V17.5828H20.2828V16.2234C20.2828 16.0594 20.4141 15.9328 20.5734 15.9328C20.7328 15.9328 20.8641 16.0641 20.8641 16.2234V17.5781H21.3891V15.7594C21.3891 15.5953 21.5203 15.4688 21.6797 15.4688C21.8391 15.4688 21.9703 15.6 21.9703 15.7594V17.8125H21.9844Z"
              fill="#084D6D"
            />
          </svg>
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
};

export default CreateNewPoolButton;
