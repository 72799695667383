var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import queryString from "query-string";
const BASE_URL = "/api/application_categories/category_assignments";
class Api {
  constructor() {
    __publicField(this, "handleResponse", (response) => {
      if (response.ok) return response.json();
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    });
    __publicField(this, "get", (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}?${queryString.stringify(params, { arrayFormat: "bracket" })}`, {
      headers: this.getHeaders(headers)
    }).then((r) => this.handleResponse(r)));
    __publicField(this, "post", (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "POST",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r)));
    __publicField(this, "put", (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "PUT",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r)));
    __publicField(this, "delete", (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "DELETE",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r)));
    __publicField(this, "getCategories", (entityId) => this.get(`/${entityId}`));
    __publicField(this, "updateCategories", (entityId, params) => this.put(`/${entityId}`, params));
    __publicField(this, "createDefaultCategories", (entityId) => this.post(`/${entityId}/create_defaults`));
    __publicField(this, "removeCategories", (entityId) => this.delete(`/${entityId}`));
  }
  getHeaders(headers) {
    return Object.assign({}, {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "X-api-authenticate": localStorage.getItem("userKey")
    }, headers);
  }
}
export default new Api();
