import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SmartFormPreview from './SmartFormPreview';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function SmartFormPreviewPage({ formId }: { formId: number }) {
  return (
    <QueryClientProvider client={queryClient}>
      <SmartFormPreview formId={formId} />
    </QueryClientProvider>
  );
}
