import React, { useState } from 'react';
import { styles } from './styles';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { ISearchProps } from './types';
import SearchIcon from '@mui/icons-material/Search';

export default function Search({ search, setSearch, setPage }: ISearchProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <OutlinedInput
          id="job-search"
          data-testid="job-search-[Search]"
          sx={styles.searchInput}
          placeholder={'Search by title or reference'}
          value={search}
          onChange={handleChange}
          startAdornment={
            <InputAdornment sx={{ padding: '0px 0px 0px 14px' }} position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}
