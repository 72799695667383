import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { RefObject, UIEvent } from 'react';
import Api from '../../../Dashboard/API';
import ActivityFeedItem from './ActivityFeedItem';
import { theme } from '../../../../ThemeContext/ThemeObject';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { useInfiniteQuery } from '@tanstack/react-query';
import LoadingButton from '../../../Components/Buttons/LoadingButton';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';

dayjs.extend(isToday);

interface IProps {
  parentRef: RefObject<HTMLDivElement>;
}

function ActivityFeed({ parentRef }: IProps) {
  let isExecuted = false;
  const {
    isInitialLoading,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    status,
    refetch
  } = useInfiniteQuery({
    queryKey: ['getActivityFeed'],
    queryFn: ({ queryKey, pageParam = null }) => {
      const data = Api.getActivityFeed({ page: pageParam });
      return data;
    },
    getNextPageParam: (lastPage) => {
      const lastDate = lastPage[lastPage?.length - 1];
      const lastActivity = lastDate.activities[lastDate.activities?.length - 1];
      return lastActivity.id || undefined;
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      scrollToBottom();
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false
  });

  const handleScroll = (event: UIEvent<HTMLElement>) => {
    if (!event) return;
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 50) {
      if (!isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    }
  };

  const scrollToBottom = () => {
    window.requestAnimationFrame(() => {
      if (!parentRef.current) return;
      parentRef.current.scrollTo({ top: parentRef.current.scrollHeight, behavior: 'smooth' });
    });
  };

  const handleOnTransitionEnd = () => {
    if (!isExecuted) {
      scrollToBottom();
      isExecuted = true;
    }
  };

  return (
    <Box sx={{ width: '260px' }}>
      <Accordion sx={styles.accordian} onTransitionEnd={handleOnTransitionEnd}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={styles.accordionButton}
          id="activity-feed-accordion"
        >
          <Typography
            sx={{
              fontFamily: 'Source Sans Pro',
              fontSize: '18px',
              fontWeight: '700',
              color: '#666666'
            }}
          >
            Activity Feed
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={styles.activitiesContainer} onScroll={handleScroll}>
          {!data && (
            <LoadingButton
              sx={{
                ...sharedClasses.genericButton,
                fontSize: '14px',
                minWidth: '110px'
              }}
              task={refetch}
              id="load-feed-button"
            >
              Load Feed
            </LoadingButton>
          )}
          {!isInitialLoading &&
            data?.pages.map((page) =>
              page.map(({ date, activities }, i) => (
                <Box key={date}>
                  <Box
                    sx={{ ...styles.date, marginTop: `${i === 0 ? 'inherit' : theme.spacing(2)}` }}
                  >
                    {dayjs(date).isToday() ? 'Today' : dayjs(date).format('D MMM YYYY')}
                  </Box>
                  {activities.map((item, index) => (
                    <ActivityFeedItem
                      key={item.id}
                      activity={item}
                      isLast={index === activities.length - 1}
                    />
                  ))}
                </Box>
              ))
            )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

const styles = {
  date: {
    textTransform: 'uppercase',
    color: '#333',
    marginBottom: theme.spacing(2),
    fontWeight: '600'
  },
  accordian: {
    boxShadow: '0px 4px 20px 3px #305A6F14',
    borderRadius: '6px !important',
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '40px !important'
    }
  },
  accordionButton: {
    padding: '0 !important',
    width: '100%',
    minHeight: '40px',
    '& .MuiAccordionSummary-content': {
      margin: '20px !important'
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: '10px !important'
    }
  },
  activitiesContainer: {
    maxHeight: '400px',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'grid'
  }
};

export default ActivityFeed;
