import { Box, Button, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import dashboardStyles from '../../styles';
import { theme } from '../../../../ThemeContext/ThemeObject';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import AddTasks from '../../../Job/ActionModals/AddTask/AddTasks';
import StartWorkFlow from '../../../Job/ActionModals/StartWorkflow/StartWorkFlow';
import TaskItem from './components/TaskItem';
import Loader from '../Loader';

interface IProps {
  setSnackbar: React.Dispatch<
    React.SetStateAction<{
      message: string;
      state: 'success' | 'warning' | 'error';
    }>
  >;
}

function TasksDashboard({ setSnackbar }: IProps) {
  const tabRef = useRef<HTMLDivElement>();
  const [tab, setTab] = useState(0);
  const [tabDimensions, setTabDimensions] = useState<{ width: number; offset: number }[]>([]);
  const queryClient = useQueryClient();

  const { isLoading, isSuccess, data, fetchNextPage, isFetchingNextPage, hasNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['getTasks', tab],
      queryFn: ({ queryKey, pageParam = 1 }) =>
        Api.getTasks({ to_do: queryKey[1] === 0, page: pageParam }),
      getNextPageParam: (lastPage) => lastPage.nextPage || undefined
    });

  const { mutate } = useMutation({
    mutationFn: ({ id, completed }: { id: string; completed: boolean }) =>
      Api.completeTask(id, completed),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['getTasks'] });
      setSnackbar({
        message: data?.message,
        state: 'success'
      });
    },
    onError: () => {
      setSnackbar({
        message: 'Could not update. Please try again.',
        state: 'error'
      });
    }
  });

  function handleScroll(e: React.UIEvent<HTMLDivElement>) {
    if (!e) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (!isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    }
  }

  useEffect(() => {
    if (!tabRef.current) return;
    setTabDimensions(
      Array.from(tabRef.current.childNodes as NodeListOf<HTMLDivElement>).map((child) => ({
        offset: child.offsetLeft,
        width: child.clientWidth
      }))
    );
  }, []);

  const handleCompleteTask = useCallback(
    (id: number, completed: boolean) => {
      mutate({ id: id.toString(), completed });
    },
    [mutate]
  );

  return (
    <Box sx={{ ...dashboardStyles.section, ...styles.section }}>
      <Box sx={{ ...dashboardStyles.sectionTitle, ...styles.sectionTitle }}>
        <Box>Tasks</Box>
        <Box display="flex" gap={theme.spacing(2)}>
          <AddTasks
            isDashboard
            buttonElement={<Button sx={styles.button}>Add Task</Button>}
            apiKey={''}
            refetch={refetch}
          />
          <StartWorkFlow
            isDashboard
            buttonElement={<Button sx={styles.button}>Add workflow</Button>}
            setSnackbarState={setSnackbar}
            apiKey={''}
          />
        </Box>
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Box ref={tabRef} ml={4} mr={4} sx={styles.tabContainer}>
          <Box sx={styles.tab} onClick={() => setTab(0)}>
            To do
          </Box>
          <Box sx={styles.tab} onClick={() => setTab(1)}>
            Completed
          </Box>
        </Box>
        <Box
          sx={{
            ...styles.line,
            width: tabDimensions[tab]?.width,
            left: tabDimensions[tab]?.offset
          }}
        />
      </Box>
      <Box sx={styles.taskContainer} onScroll={handleScroll} mr={4} ml={4}>
        {isLoading &&
          Array(3)
            .fill(null)
            .map((_, i) => <Loader key={i} />)}
        {isSuccess && (
          <>
            {data.pages.length ? (
              <>
                {data.pages.map((page) =>
                  page.data.map((item) => (
                    <TaskItem key={item.id} data={item} handleCompleteTask={handleCompleteTask} />
                  ))
                )}
                {isFetchingNextPage && hasNextPage && (
                  <Box flex={1} justifyContent="center">
                    <Box display="flex" flex={1} justifyContent="center">
                      <CircularProgress size={20} sx={{ color: theme.palette.primary.main }} />
                    </Box>
                  </Box>
                )}
              </>
            ) : (
              <Box>No tasks</Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

const styles = {
  section: {
    flexBasis: '40%',
    flexGrow: 1,
    maxHeight: '420px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  button: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: '#5BC4BF1F',
    color: theme.palette.action.main,
    borderRadius: '6px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.main,
      color: '#FFFFFF'
    }
  },
  tabContainer: {
    display: 'flex',
    gap: '20px',
    borderBottom: '1px solid #DDDDDD'
  },
  tab: {
    color: '#939393',
    fontSize: '18px',
    paddingBottom: '10px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tabSelected: {
    color: theme.palette.primary.main,
    fontWeight: '600'
  },
  line: {
    height: '2px',
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    bottom: 0,
    borderRadius: '1px',
    transition: 'left 0.25s cubic-bezier(.6,.36,.25,1), width 0.25s cubic-bezier(.6,.36,.25,1)'
  },
  taskContainer: {
    padding: '20px 0',
    overflowY: 'scroll',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '26px',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& .MuiSkeleton-root': {
      transformOrigin: '0px 0px !important'
    },
    '& .spinner': {
      margin: 'auto'
    }
  }
};

export default TasksDashboard;
