import React from "react";
import PropTypes from "prop-types";
import { FormControl, TextField, FormHelperText } from "@mui/material";
import { useFormValidator } from "react-form-context-validation";
export default function TextInput(props) {
  const { placeholder, value, onChange, required, tabIndex, label, className, type = "text", onBlur, id, disabled, error = false, rows, variant } = props;
  const inputError = useFormValidator(required && !value || error, id);
  return /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className }, /* @__PURE__ */ React.createElement(
    TextField,
    {
      label,
      type,
      placeholder,
      value: value != null ? value : "",
      onChange: (e) => onChange(e.target.value),
      onBlur,
      required,
      tabIndex,
      disabled,
      error: !!inputError,
      multiline: !!rows,
      rows,
      variant,
      inputProps: {
        sx: {
          fontFamily: "Source Sans Pro"
        }
      },
      InputLabelProps: {
        sx: {
          fontFamily: "Source Sans Pro"
        }
      }
    }
  ), typeof error === "string" && /* @__PURE__ */ React.createElement(FormHelperText, { error: true }, error));
}
TextInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(["password", "text", "number"]),
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  rows: PropTypes.number,
  variant: PropTypes.oneOf(["standard", "filled", "outlined"])
};
