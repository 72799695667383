import React, { Dispatch, useMemo, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import {
  GenericTableData,
  TSortableColumn,
  TTableState,
  TableFilter,
  TableSelectionAction
} from './types';
import { classes } from './styles';
import { handleAPIRequest } from '../Utilities/handleAPIRequest';

const TotalSelectedIndicator = ({
  tableState,
  tableFilters,
  tableSearch,
  dispatch,
  totalItems,
  items,
  selectAllUrl,
  label
}: {
  tableState: TTableState<GenericTableData, TSortableColumn[]>;
  tableFilters?: TableFilter;
  tableSearch?: string;
  dispatch: Dispatch<TableSelectionAction<GenericTableData>>;
  totalItems: number;
  items: GenericTableData[];
  selectAllUrl?: string;
  label?: string;
}) => {
  const [isLoadingSelectAll, setIsLoadingSelectAll] = useState(false);
  const selectAllIsChecked = useMemo(
    () => items.map((item) => item.id).every((id) => tableState.selectedItemIds.includes(id)),
    [items, tableState.selectedItemIds]
  );
  const handleSelectAll = () => {
    if (!selectAllUrl) return;
    handleAPIRequest({
      url: selectAllUrl,
      method: 'POST',
      body: {
        override_limit: true,
        limit: totalItems,
        ...(tableSearch && { query: tableSearch }),
        ...tableFilters
      },
      setLoaderState: setIsLoadingSelectAll,
      onSuccess: (data: { ai_search: Array<{ id: number }> }) =>
        dispatch({ type: 'SELECT_ALL_ITEMS', payload: data?.ai_search?.map((item) => item.id) })
    });
  };

  return (
    <Box
      id="selected-tooltip"
      sx={
        tableState.selectedItemIds?.length > 0
          ? { ...classes.selectedTooltip, ...classes.fadeIn }
          : { ...classes.selectedTooltip, ...classes.fadeOut }
      }
    >
      <Box
        id="selected-tooltip-text"
        sx={{
          display: 'flex',
          paddingLeft: '12px',
          alignItems: 'center',
          ...classes.selectedTooltipText
        }}
      >
        {tableState.selectedItemIds.length === totalItems
          ? `All ${tableState.selectedItemIds.length} ${label || 'items'} are selected`
          : `${tableState.selectedItemIds.length} selected`}
        <IconButton
          size="small"
          sx={{
            ...classes.selectedTooltipText,
            ...classes.selectedAllTooltipText,
            marginLeft: 1,
            color: '#666666'
          }}
          onClick={() => dispatch({ type: 'REMOVE_ALL_SELECTED_ITEMS' })}
        >
          Clear selection
        </IconButton>
      </Box>
      {selectAllIsChecked && totalItems > tableState.selectedItemIds.length && (
        <IconButton
          disabled={false}
          size="small"
          sx={{ ...classes.selectedTooltipText, ...classes.selectedAllTooltipText }}
          onClick={handleSelectAll}
        >
          {!isLoadingSelectAll ? `Select all ${totalItems} ${label}` : `Loading all ${label}...`}
        </IconButton>
      )}
    </Box>
  );
};

export default TotalSelectedIndicator;
