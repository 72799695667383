import React from 'react';
import { TableCell } from '@mui/material';
import { styles } from './styles';

type LeftCellProps = {
  previousCellOffSet: number;
  tableHovered: boolean;
  sticky?: boolean;
  leftBound?: boolean;
  header?: boolean;
  leftBoundRef?: React.RefObject<HTMLTableCellElement>;
  additionalStyles?: React.CSSProperties;
  children?: React.ReactNode;
};
export const StickyLeftCell = ({
  previousCellOffSet,
  tableHovered,
  sticky,
  leftBound,
  header,
  leftBoundRef,
  additionalStyles,
  children
}: LeftCellProps) => (
  <TableCell
    sx={{
      ...(additionalStyles ? additionalStyles : null),
      ...(sticky ? styles.sticky(previousCellOffSet) : null),
      ...(leftBound
        ? {
            ...styles.scrollShadowLeft,
            ...(tableHovered && styles.scrollShadowVisible)
          }
        : null)
    }}
    ref={leftBound && header ? leftBoundRef : null}
  >
    {children}
  </TableCell>
);

type RightCellProps = {
  tableHovered: boolean;
  header?: boolean;
  rightBoundRef?: React.RefObject<HTMLTableCellElement>;
  additionalStyles?: React.CSSProperties;
  children?: React.ReactNode;
};
export const StickyRightCell = ({
  tableHovered,
  header,
  rightBoundRef,
  additionalStyles,
  children
}: RightCellProps) => (
  <TableCell
    sx={{
      ...(additionalStyles ? additionalStyles : null),
      ...styles.attachmentsHeader,
      ...styles.stickyRight,
      ...styles.scrollShadowRight,
      ...(tableHovered && styles.scrollShadowVisible)
    }}
    ref={header ? rightBoundRef : null}
  >
    {children}
  </TableCell>
);
