import React, { Component } from 'react';
import T from 'prop-types';

export default class ScoutIndexRow extends Component {
  static propTypes = {
    cols:  T.array,
    index: T.number
  }

  constructor (props) {
    super(props)
  }

  zebraClass() {
    if(this.props.index % 2 == 0) {
      return 'even'
    } else {
      return 'odd'
    }
  }

  render() {
    return(
      <tr className={this.zebraClass()}>
        {this.props.cols}
      </tr>
    )
  }
}
