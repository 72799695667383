import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../ApprovalForms/API';
import ListItem from './ListItem';

export default function SampleList({ apiKey }: { apiKey: string }) {
  const [openManager, setOpenManager] = useState<boolean>(false);
  const [manager, setManager] = useState<{ id: number; name: string; email: string } | null>(null);
  const queryClient = useQueryClient();

  const managers = useQuery({
    queryKey: ['managers'], // this key has to be unique to each query for caching purposes
    queryFn: () => Api.getApprovalManagers({ override_limit: true, limit: 100 })
    // cacheTime: 10 * 60 * 1000    <--- set cache time for 10 minutes, otherwise, by default it's 5 minutes
  });

  // You can call the properties inside the managers directly such as managers.isLoading, managers.error, managers.data
  // or you can deconstruct it and give it a name like below

  const {
    status: deleteStatus, // can be used to display loading state
    error: deleteError, // can be used to display snackbar and error message
    mutate: deleteManager
  } = useMutation({
    mutationFn: (id: number) => Api.deleteApprovalManager({ 'X-api-authenticate': 'apiKey' }, id),
    onSuccess: () => {
      // can be used for chaining. think of it as similar to '.then'
      queryClient.invalidateQueries(['managers'], { exact: true }); // remove cached data and initiate refetch for the managers query
    }
  });

  // when to use useQuery and useMutation?
  //
  // UseQuery is used to query async data, useMutation is used to mutate it.
  // useQuery is declarative, useMutation is imperative.
  // meaning useQuery runs when component is in focus, or is mounted;
  // whereas useMutation has to be 'invoked'.
  // or in CRUD:
  //
  // Read: useQuery
  // Create/Update/Delete: useMutation

  if (managers.error) return <Stack>render snackbar error message</Stack>;

  return (
    <Box>
      <Stack sx={{ paddingBottom: 2 }}>
        <Typography variant="h3">Smart Forms New UI Page</Typography>
      </Stack>
      <Box>
        {openManager ? (
          <ListItem manager={manager} setOpenManager={setOpenManager} setManager={setManager} />
        ) : (
          <>
            {managers.isLoading ? (
              <Stack>Loading...</Stack>
            ) : (
              <Stack>
                {managers.data.res.requisition_managers.map((e) => (
                  <Stack
                    key={e.id}
                    sx={{ padding: 1, width: '250px' }}
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      onClick={() => {
                        setManager(e);
                        setOpenManager(true);
                      }}
                      sx={{ '&:hover': { cursor: 'pointer' } }}
                    >
                      {e.name}
                    </Typography>
                    <Button color="error" onClick={() => deleteManager(e.id)}>
                      Delete
                    </Button>
                  </Stack>
                ))}
              </Stack>
            )}
            <Button variant="outlined" onClick={() => setOpenManager(true)}>
              Add Manager
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
