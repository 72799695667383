import React, { Dispatch, SetStateAction, useState } from 'react';
import { FileError, FileWithPath, useDropzone } from 'react-dropzone';
import axios from 'axios';
import CircularProgressWithLabel from '../../Components/CustomUIElements/CircularProgressWithLabel';
import { classes } from './styles';
import UploadSVG from '../Graphics/UploadSVG';
import { Box, Button, Stack } from '@mui/material';
import { theme } from '../../../ThemeContext/ThemeObject';

export default function AttachmentSelector({
  performUpload,
  apiKey,
  uploadUrl,
  onUploadComplete,
  setFileList,
  maxSize,
  maxFiles,
  heightOverride,
  attachment,
  maxVideoSize,
  buttonId
}: {
  performUpload: boolean;
  apiKey?: string;
  uploadUrl?: string;
  onUploadComplete?: () => void;
  setFileList?: Dispatch<SetStateAction<FileWithPath[]>>;
  maxSize: number;
  maxFiles?: number;
  heightOverride?: number | string;
  attachment?: boolean;
  maxVideoSize?: number;
  buttonId?: string;
}) {
  const getMaxSize = (fileType: string): number => {
    if (fileType.startsWith('video/')) {
      return maxVideoSize ? maxVideoSize : maxSize;
    }
    return maxSize;
  };
  const sizeValidator = (file: File): FileError | null => {
    const maximumSize = getMaxSize(file.type);
    if (file.size > maximumSize) {
      return {
        code: 'file-too-large',
        message: attachment ? 'Files must be under specified size.' : 'Files must be under 50 MB'
      };
    }
    return null;
  };
  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    noClick: true,
    noKeyboard: true,
    validator: sizeValidator,
    maxFiles: maxFiles ? maxFiles : 0,
    onDragOver: () => {
      setIsDraggedOver(true);
    },
    onDragLeave: () => {
      setIsDraggedOver(false);
    },
    onDropRejected: () => {
      setIsRejected(true);
    },
    onDrop: (acceptedFiles: FileWithPath[]) => {
      const parsedFiles = acceptedFiles.map(
        (file) => new File([file], file.name.replace(/[()]/g, ''), { type: file.type })
      );
      parsedFiles.forEach((file) => {
        if (performUpload) {
          uploadFiles(file);
        }
        if (setFileList) {
          setFileList(parsedFiles);
        }
        setIsDraggedOver(false);
        setIsRejected(false);
      });
    }
  });

  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [error, setError] = useState('');

  const uploadFiles = async (file: FileWithPath) => {
    if (!apiKey || !uploadUrl || !onUploadComplete) {
      console.error(
        'Missing required parameters for AttachmentSelector component. If performUpload is true, please provide apiKey, uploadUrl, and onUploadComplete.'
      );
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append('asset', file);
    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: { 'Content-Type': 'multipart/form-data', 'X-api-authenticate': apiKey },
        onUploadProgress: (progressEvent) => {
          setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
      });
      if (response.status === 201 || response.status === 200) {
        setError('');
        onUploadComplete();
      }
    } catch (error) {
      if (error.response?.data?.errors) {
        error.response.data.errors === 'Invalid file.'
          ? setError('Unsupported file type')
          : setError(error.response.data.errors);
      }
      console.log(error);
    } finally {
      setIsRejected(false);
      setIsLoading(false);
      setPercentCompleted(0);
    }
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        {...getRootProps()}
        sx={{
          ...classes.dragArea,
          ...(isDraggedOver ? classes.dragAreaOver : {}),
          ...(heightOverride ? { height: heightOverride } : {})
        }}
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <>
            <CircularProgressWithLabel value={percentCompleted} />
            <Box sx={{ marginTop: '12px' }}>Uploading...</Box>
          </>
        ) : (
          <>
            <Box sx={{ marginBottom: '12px' }}>
              <UploadSVG />
            </Box>
            {!isDraggedOver ? (
              <>
                <Stack>
                  <Box>Drag or choose files to upload</Box>
                </Stack>
                {(isRejected || error) && (
                  <Box sx={{ color: '#D6827D' }}>
                    {isRejected
                      ? attachment
                        ? 'Files must be under specified size.'
                        : 'Files must be under 50 MB'
                      : error}
                  </Box>
                )}
              </>
            ) : (
              <>
                <Box>
                  {performUpload ? 'Drop files here to upload!' : 'Drop files here to add!'}
                </Box>
                {fileRejections.length > 0 && isDraggedOver && (
                  <Box sx={{ color: '#D6827D' }}>
                    {fileRejections.map((fileRejection, index) => (
                      <Box key={index}>{fileRejection.errors.map((e) => e.message).join(', ')}</Box>
                    ))}
                  </Box>
                )}
              </>
            )}
            {attachment && (
              <Stack
                sx={{
                  fontStyle: 'italic',
                  fontWeight: 200,
                  fontSize: theme.spacing(1.5),
                  textAlign: 'center',
                  paddingTop: theme.spacing(0.5)
                }}
              >
                <Box>Maximum size per file: 50MB</Box>
                <Box>Maximum size per video: 500MB</Box>
              </Stack>
            )}
            <Button
              id={buttonId}
              sx={classes.selectFilesButton}
              onClick={open}
              className="select-files-button"
              data-testid="select-files-button"
            >
              Choose files
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
