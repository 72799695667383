import { theme } from "../../ThemeContext/ThemeObject";
const STATUS_COLOR = {
  good: "#4ED182",
  bad: "#FF2121",
  average: "#FF981F"
};
export const getClasses = (props) => {
  return {
    card: {
      borderLeft: "4px solid",
      borderLeftColor: STATUS_COLOR[props.status],
      display: "flex",
      alignItems: "center",
      padding: "16px 18px",
      height: "124px",
      width: "296px"
    },
    cardImage: {
      height: "32px",
      width: "32px",
      margin: "12px",
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${props.imageUrl})`
    },
    cardImageContainer: {
      backgroundColor: STATUS_COLOR[props.status] || theme.palette.common.black,
      borderRadius: "50%",
      marginRight: "24px"
    },
    description: {
      marginTop: "10px",
      fontSize: "0.8rem",
      color: "#878787",
      maxHeight: "1rem",
      textAlign: "left"
    },
    indicator: {
      height: "36px",
      fontWeight: "bold",
      position: "relative",
      lineHeight: "36px",
      color: STATUS_COLOR[props.status]
    },
    kpiIcon: {
      height: "20px",
      width: "20px",
      backgroundPosition: "center",
      backgroundSize: "cover",
      marginLeft: "8px"
    },
    trendingIcon: {
      color: STATUS_COLOR[props.directionStatus],
      fontSize: "40px !important"
    },
    cardInner: {
      display: "flex",
      alignItems: "center",
      width: "100%"
    }
  };
};
