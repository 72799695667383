import React, { useState, Dispatch } from 'react';
import { Box, Button, Checkbox, FormControl } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import StyledDropdown from '../../SharedComponents/Inputs/StyledDropdown';
import PositionTitleInput from '../../SharedComponents/Inputs/PositionTitle';
import OptionalInputButtons from '../Buttons/OptionalInput';
import { classes } from '../styles';
import DynamicFooter from '../../SharedComponents/Footers/DynamicFooter';
import { AIStudioAction, AIStudioState } from '../reducer';
import SingleAttachmentSelector from '../../../NewUI/Components/Utilities/SingleAttachmentSelector';

const aiToneTypes = {
  english: ['neutral', 'professional', 'casual', 'creative', 'excited', 'urgent', 'persuasive'],
  french: [
    'neutre',
    'professionnel',
    'décontracté',
    'créatif',
    'enthousiaste',
    'urgent',
    'persuasif'
  ]
};

const frenchToEnglishTonetype = {
  neutre: 'neutral',
  professionnel: 'professional',
  décontracté: 'casual',
  créatif: 'creative',
  enthousiaste: 'excited',
  urgent: 'urgent',
  persuasif: 'persuasive'
};

const englishToFrenchTonetypes = {
  neutral: 'neutre',
  professional: 'professionnel',
  casual: 'décontracté',
  creative: 'créatif',
  excited: 'enthousiaste',
  urgent: 'urgent',
  persuasive: 'persuasif'
};

const backCloseButton = {
  jobFlow: {
    english: 'Close',
    french: 'Fermer'
  },
  studio: {
    english: 'Back',
    french: 'Retour'
  }
};

const JobDescriptionInput = ({
  dispatch,
  aiStudioState,
  generateDescription,
  jobFlow
}: {
  dispatch: Dispatch<AIStudioAction>;
  aiStudioState: AIStudioState;
  generateDescription: () => void;
  jobFlow?: boolean;
}) => {
  const [positionTitle, setPositionTitle] = useState(
    aiStudioState.jobDetails['position_title'] || ''
  );
  const [fileList, setFileList] = useState(aiStudioState.document || undefined);

  const pillClickHandler = (pillType: string) => {
    dispatch({ type: 'SELECT_OPTIONAL_INPUT', payload: pillType });
  };

  const handleBackClick = () => {
    if (!jobFlow) {
      setPositionTitle('');
      setFileList(undefined);
      dispatch({ type: 'GO_TO_HOME_STEP' });
    } else {
      dispatch({ type: 'SET_CONFIRM_CANCEL_IS_OPEN', payload: true });
    }
  };

  const handleGenerateClick = () => {
    setPositionTitle('');
    generateDescription();
  };

  const handleClearAllClick = () => {
    setPositionTitle('');
    setFileList(undefined);
    dispatch({ type: 'CLEAR_ALL_JOB_DETAILS' });
  };

  return (
    <Box sx={classes.descriptionContainer}>
      <Box sx={classes.genericContainer}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={classes.fieldHeader}>
            {aiStudioState.aiStudioFrenchEnabled ? 'Titre du poste' : 'Position title'}
          </Box>
          <Button sx={classes.pill} endIcon={<ClearIcon />} onClick={handleClearAllClick}>
            {aiStudioState.aiStudioFrenchEnabled ? 'Effacer tout' : 'Clear all'}
          </Button>
        </Box>
        <PositionTitleInput
          positionTitle={positionTitle}
          onChange={setPositionTitle}
          aiStudioFrenchEnabled={aiStudioState.aiStudioFrenchEnabled}
          onBlur={(value: string) =>
            dispatch({ type: 'SET_JOB_DETAILS', payload: value, fieldName: 'position_title' })
          }
        />
        <Box sx={{ marginBlock: '20px' }}>
          <Box sx={classes.fieldSubHeader}>
            {aiStudioState.aiStudioFrenchEnabled ? 'Position du poste' : 'Position description'}
          </Box>
          <SingleAttachmentSelector
            setFile={(file) => {
              dispatch({ type: 'SET_DOCUMENT', payload: file });
              setFileList(file);
            }}
            file={aiStudioState.document || fileList}
            maxSize={50000000}
            performUpload={false}
          />
        </Box>
      </Box>
      <Box sx={classes.optionalInformationContainer}>
        <Box sx={classes.fieldSubHeader}>
          {aiStudioState.aiStudioFrenchEnabled
            ? 'Informations facultatives'
            : 'Optional information'}
        </Box>
        <OptionalInputButtons
          jobDetails={aiStudioState.jobDetails}
          pillClickHandler={pillClickHandler}
          aiStudioFrenchEnabled={aiStudioState.aiStudioFrenchEnabled}
        />
      </Box>
      <FormControl fullWidth>
        <Box sx={classes.fieldSubHeader}>
          {aiStudioState.aiStudioFrenchEnabled ? 'Tonalité générée' : 'Generated tone'}
        </Box>
        <StyledDropdown
          id="generated-tone"
          value={
            aiStudioState.aiStudioFrenchEnabled
              ? englishToFrenchTonetypes[aiStudioState.generatedTone]
              : aiStudioState.generatedTone
          }
          onChange={(value) =>
            dispatch({
              type: 'SET_GENERATED_TONE',
              payload: aiStudioState.aiStudioFrenchEnabled ? frenchToEnglishTonetype[value] : value
            })
          }
          options={aiToneTypes[aiStudioState.aiStudioFrenchEnabled ? 'french' : 'english']}
        />
      </FormControl>
      <Box sx={classes.formContainer}>
        <Checkbox
          sx={classes.checkbox}
          checked={aiStudioState.sendEmail}
          onChange={(e) => dispatch({ type: 'SET_SEND_EMAIL', payload: e.target.checked })}
        />
        <Box sx={classes.formLabel}>
          {aiStudioState.aiStudioFrenchEnabled
            ? "Envoyez-moi par email une copie de ma page d'atterrissage pour les candidats (talent landing page)."
            : 'Email me a copy of my talent landing page'}
        </Box>
      </Box>
      <DynamicFooter
        handleSecondaryButtonClick={handleBackClick}
        handlePrimaryButtonClick={handleGenerateClick}
        secondaryButtonText={
          backCloseButton[jobFlow ? 'jobFlow' : 'studio'][
            aiStudioState.aiStudioFrenchEnabled ? 'french' : 'english'
          ]
        }
        primaryButtonText={aiStudioState.aiStudioFrenchEnabled ? 'Générer' : 'Generate'}
        primaryButtonID="write-tlp-generate-button"
        felixMessage={
          aiStudioState.aiStudioFrenchEnabled ? (
            <>
              Dites-moi le titre du poste que vous souhaitez afficher. Tous les autres champs sont{' '}
              <span>facultatifs</span> mais utiles pour moi.
            </>
          ) : (
            <>
              Tell me your position title. All other fields are <span>optional</span> but helpful
              for me.
            </>
          )
        }
        felixMessageMaxWidth="325px"
        disablePrimaryButton={positionTitle === ''}
      />
    </Box>
  );
};

export default JobDescriptionInput;
