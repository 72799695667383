"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  headerContainer: {
    display: "flex",
    columnGap: "36px",
    alignItems: "center",
    height: "91px",
    width: "fill-available",
    zIndex: 1100,
    backgroundColor: "#fff",
    paddingLeft: "48px"
  },
  searchInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "regular",
    height: "54px",
    paddingRight: "20px",
    "& legend": {
      display: "none"
    },
    "& input": {
      padding: "0px 16px 0px 32px",
      color: "#000000"
    },
    "& fieldset": {
      borderColor: "#FFFFFF !important"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#FFFFFF !important"
      }
    }
  },
  searchDialog: {
    "& .MuiDialog-container": {
      alignItems: "flex-start"
    },
    "& .MuiDialog-paper": {
      top: "60px",
      borderRadius: "32px",
      width: "600px",
      boxShadow: "unset"
    }
  },
  switchEntityDialog: {
    "& .MuiDialog-container": {
      alignItems: "flex-start"
    },
    "& .MuiDialog-paper": {
      top: "60px",
      width: "600px",
      boxShadow: "unset",
      padding: "10px 20px 30px 20px"
    },
    "& .MuiMenuItem-root": {
      display: "flex"
    }
  },
  switchEntityButton: {
    width: "auto",
    color: "#FFFFFF",
    backgroundColor: "#084D6D",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "#5BC4C0"
    }
  },
  searchIcon: {
    fontSize: "26px",
    color: "#666666",
    cursor: "pointer"
  },
  clearSearch: {
    cursor: "pointer",
    fontSize: "22px",
    color: "#939393"
  },
  profileDropdownContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    columnGap: "6px"
  },
  profileAvatar: {
    minWidth: "50px",
    minHeight: "50px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F5F5F5",
    color: theme.palette.primary.main,
    fontSize: "20px",
    fontWeight: "700",
    textTransform: "uppercase"
  },
  avatarSmall: {
    minWidth: "45px",
    minHeight: "45px"
  },
  profileDropdownIcon: {
    color: theme.palette.primary.main,
    fontSize: "22px"
  },
  showButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    columnGap: "12px",
    color: "#666666",
    fontWeight: "600",
    marginLeft: "auto"
  },
  hideButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    zIndex: 1250,
    right: "56px",
    cursor: "pointer",
    color: "#666666",
    fontWeight: "600",
    marginLeft: "auto",
    transition: "left 0.2s ease-in-out"
  },
  profileDropdownMenuContainer: {
    display: "flex",
    flexDirection: "column",
    width: "256px",
    padding: "18px",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "0px 2px 14px 0px rgba(48, 90, 111, 0.16)",
    marginTop: "10px",
    rowGap: "18px"
  },
  profileInformation: {
    display: "flex",
    alignItems: "flex-start",
    columnGap: "12px"
  },
  roleInformationWrapper: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px"
  },
  roleInformation: {
    marginRight: "6px",
    maxWidth: "120px",
    color: "#AAAAAA",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textWrap: "nowrap"
  },
  profileNameAndEntityContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    "& a": {
      textDecoration: "none",
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      color: "#AAAAAA"
    }
  },
  profileName: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.primary.main
  },
  profileDropdownMenuItemsContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    "& a": {
      textDecoration: "none",
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      color: theme.palette.primary.main,
      fontWeight: "600",
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  profileDropdownMenuItem: {
    display: "flex",
    alignItems: "center",
    columnGap: "24px",
    cursor: "pointer",
    color: theme.palette.primary.main,
    padding: "6px 8px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  masqueradeAvatar: {
    backgroundColor: "#D6DEE2",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#C4D0D7"
    }
  },
  breadcrumbContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "36px",
    backgroundColor: "#FFFFFF",
    color: "#939393"
  },
  homeIcon: {
    cursor: "pointer",
    color: "#939393",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  breadcrumbs: {
    fontFamily: "Source Sans Pro",
    fontSize: "17px",
    fontWeight: "400",
    color: "#939393",
    "& a": {
      color: "#939393"
    }
  },
  breadcrumbSeparator: {
    color: "#939393",
    fontSize: "20px",
    marginInline: "10px"
  },
  uatBanner: {
    backgroundImage: 'url("/images/uat-alert-band.svg")',
    height: "20px",
    backgroundColor: "red"
  },
  modalWrapper: {
    "& .MuiDialogContent-root": {
      padding: "0 50px"
    }
  }
};
