import React, { Dispatch, useState } from 'react';
import { Box, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from '../../styles';
import LoadingResponse from '../../../SharedComponents/Steps/LoadingResponse';
import InputStep from './InputStep';
import EditStep from './EditStep';
import ErrorScreen from '../../../SharedComponents/Steps/ErrorScreen';
import { StyledSnackbarProps } from '../../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { useWebSockets } from '../../../../NewUI/Components/Utilities/useWebSockets';
import { IWebsocketData } from '../../../SharedComponents/types';
import { emailTones } from '../../config';

type TStep = 'input' | 'loading' | 'edit' | 'error';

const FollowUpEmailModal = ({
  questions,
  jobId,
  applicationId,
  modalOpen,
  setModalOpen,
  setSnackbarState
}: {
  questions: string[];
  jobId: number;
  applicationId: number;
  modalOpen: boolean;
  setModalOpen: Dispatch<React.SetStateAction<boolean>>;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
}) => {
  const [currentStep, setCurrentStep] = useState<TStep>('input');
  const [emailTone, setEmailTone] = useState<typeof emailTones[number]>('Professional');
  const [emailData, setEmailData] = useState<IWebsocketData | null>(null);

  const handleClose = () => {
    setCurrentStep('input');
    setEmailTone('Professional');
    setEmailData(null);
    setModalOpen(false);
  };

  const { subscribeToChannel, unsubscribeFromChannel, socketConnected } = useWebSockets(
    'AiStudioChannel',
    (data: IWebsocketData, UID?: number) => {
      if (data.UID !== UID) return;
      if (data.kind === 'error') {
        setCurrentStep('error');
      } else {
        setEmailData(data);
        setCurrentStep('edit');
      }
      unsubscribeFromChannel();
    }
  );

  const generateFollowUpEmail = async () => {
    setCurrentStep('loading');
    const UID = Date.now();
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/applications/${applicationId}/follow_up_email`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            follow_up_email: {
              UID,
              email_tone: emailTone.toLowerCase(),
              follow_up_questions: questions
            }
          })
        }
      );
      if (response.ok) {
        subscribeToChannel(UID);
      } else {
        throw new Error(`API Request failed with status ${response.status}`);
      }
    } catch (error) {
      setCurrentStep('error');
    }
  };

  const renderStep = (step: TStep) => {
    switch (step) {
      case 'input':
        return (
          <>
            <Box sx={classes.header}>Send follow up email</Box>
            <InputStep
              questions={questions}
              emailTone={emailTone}
              setEmailTone={setEmailTone}
              generateFollowUpEmail={generateFollowUpEmail}
              handleClose={handleClose}
            />
          </>
        );
      case 'loading':
        return (
          <LoadingResponse generationType="follow up email" showSocketMessage={socketConnected} />
        );
      case 'edit':
        return (
          <>
            <Box sx={classes.header}>Edit your follow up email</Box>
            <EditStep
              emailData={emailData}
              goBack={() => setCurrentStep('input')}
              handleClose={handleClose}
              jobId={jobId}
              applicationId={applicationId}
              setSnackbarState={setSnackbarState}
            />
          </>
        );
      case 'error':
        return (
          <ErrorScreen
            backToHome={() => setCurrentStep('input')}
            regenerateCallback={generateFollowUpEmail}
          />
        );
      default:
        return <Box>An error has occurred, please refresh the page</Box>;
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="suggested-interview-questions-modal"
    >
      <Box sx={classes.modal}>
        <Box sx={classes.aiModalContent}>
          <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
          {renderStep(currentStep)}
        </Box>
      </Box>
    </Modal>
  );
};

export default FollowUpEmailModal;
