"use strict";
import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  recContainer: {
    display: "flex"
  },
  recImage: {
    borderRadius: "100%",
    width: 45,
    height: 45,
    maxHeight: 45,
    maxWidth: 45,
    background: "#DDDDDD",
    color: "#000",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  recDetails: {
    marginLeft: 14,
    display: "flex",
    flexDirection: "column",
    "& h4": {
      fontSize: 15,
      color: "#000000",
      fontWeight: 400,
      marginBottom: 4
    },
    "& a": {
      fontWeight: "600",
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontSize: 14,
      lineHeight: "14px"
    }
  },
  overviewContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "baseline",
    paddingTop: "10%"
  },
  overviewItem: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "26%",
    minWidth: "26%",
    height: "100%",
    "& svg": {
      color: theme.palette.primary.main,
      height: 36,
      width: 36,
      marginBottom: 14
    }
  },
  overviewTitle: {
    fontSize: 24,
    marginBottom: 8
  },
  overviewDescription: {
    color: "#939393",
    fontSize: 18,
    marginBottom: 10
  },
  overviewLink: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    marginTop: "auto",
    "&:hover": {
      cursor: "pointer",
      userSelect: "none"
    }
  }
}));
