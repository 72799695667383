import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Modal,
  Button,
  Autocomplete,
  TextField,
  Slider,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ITestGridProps } from '../../types';
import { IApplicationStatus } from '../../../Job/types';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';

export default function TestGridModal({
  apiKey,
  jobId,
  modalOpen,
  setModalOpen,
  automatedTests,
  setAutomatedTests,
  applicationStatuses,
  testGridPackages,
  testIndex,
  loading,
  setSnackbar
}: ITestGridProps) {
  const [isSending, setIsSending] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<{ value: string; label: string } | null>(
    null
  );
  const [selectedTriggerStatus, setSelectedTriggerStatus] = useState<IApplicationStatus | null>(
    null
  );
  const [passAt, setPassAt] = useState(0);
  const [selectedPassStatus, setSelectedPassStatus] = useState<IApplicationStatus | null>(null);
  const [selectedFailStatus, setSelectedFailStatus] = useState<IApplicationStatus | null>(null);
  const [errors, setErrors] = useState({
    package: '',
    trigger: '',
    pass: '',
    passStatus: '',
    failStatus: ''
  });

  const refObject = {
    package: useRef<HTMLDivElement>(null),
    trigger: useRef<HTMLDivElement>(null),
    pass: useRef<HTMLDivElement>(null),
    passStatus: useRef<HTMLDivElement>(null),
    failStatus: useRef<HTMLDivElement>(null)
  };

  const handleSubmit = async () => {
    let errorRef = '';
    if (
      !selectedPackage ||
      (automatedTests.some((test) => test.package === selectedPackage.value) &&
        selectedPackage.value !== automatedTests[testIndex].package)
    ) {
      setErrors((prev) => ({
        ...prev,
        package: !selectedPackage ? 'Please select a package' : 'Package already being used'
      }));
      errorRef = errorRef || 'package';
    }
    if (!selectedTriggerStatus) {
      setErrors((prev) => ({ ...prev, trigger: 'Please select a trigger status' }));
      errorRef = errorRef || 'trigger';
    }
    if (!passAt) {
      setErrors((prev) => ({ ...prev, pass: 'Please select a trigger percentage' }));
      errorRef = errorRef || 'pass';
    }
    if (!selectedPassStatus) {
      setErrors((prev) => ({ ...prev, passStatus: 'Please select a pass status' }));
      errorRef = errorRef || 'passStatus';
    }
    if (!selectedFailStatus) {
      setErrors((prev) => ({ ...prev, failStatus: 'Please select a fail status' }));
      errorRef = errorRef || 'failStatus';
    }
    if (errorRef) {
      refObject[errorRef as keyof typeof refObject].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      return;
    }
    try {
      setIsSending(true);
      if (testIndex === -1) {
        const response = await fetch(`/api/v4/jobs/${jobId}/test_grid_tests`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-api-authenticate': apiKey
          },
          body: JSON.stringify({
            package: selectedPackage?.value,
            trigger_status_id: selectedTriggerStatus?.id,
            pass_at: passAt,
            pass_status_id: selectedPassStatus?.id,
            fail_status_id: selectedFailStatus?.id
          })
        });
        const data = await response.json();
        if (response.ok) {
          setAutomatedTests((prev) => [...prev, data]);
          setSnackbar({
            message: 'Automated test created successfully',
            state: 'success'
          });
          setModalOpen(false);
        } else {
          throw new Error('Error creating automated test');
        }
      } else {
        const response = await fetch(
          `/api/v4/jobs/${jobId}/test_grid_tests/${automatedTests[testIndex].id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'X-api-authenticate': apiKey
            },
            body: JSON.stringify({
              package: selectedPackage?.value,
              trigger_status_id: selectedTriggerStatus?.id,
              pass_at: passAt,
              pass_status_id: selectedPassStatus?.id,
              fail_status_id: selectedFailStatus?.id
            })
          }
        );
        const data = await response.json();
        if (response.ok) {
          setAutomatedTests((prev) => {
            const newTests = [...prev];
            newTests[testIndex] = data;
            return newTests;
          });
          setSnackbar({
            message: 'Auto reply updated successfully',
            state: 'success'
          });
          setModalOpen(false);
        } else {
          throw new Error('Error updating auto reply');
        }
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error creating/updating automated test',
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    setErrors({
      package: '',
      trigger: '',
      pass: '',
      passStatus: '',
      failStatus: ''
    });
    if (testIndex === -1) {
      setSelectedPackage(null);
      setSelectedTriggerStatus(null);
      setPassAt(0);
      setSelectedPassStatus(null);
      setSelectedFailStatus(null);
      return;
    }
    setSelectedPackage(
      testGridPackages.find((p) => p.value === automatedTests[testIndex].package) || null
    );
    setSelectedTriggerStatus(
      applicationStatuses.find(
        (status) => status.id === automatedTests[testIndex].trigger_status_id
      ) || null
    );
    setPassAt(automatedTests[testIndex].pass_at);
    setSelectedPassStatus(
      applicationStatuses.find(
        (status) => status.id === automatedTests[testIndex].pass_status_id
      ) || null
    );
    setSelectedFailStatus(
      applicationStatuses.find(
        (status) => status.id === automatedTests[testIndex].fail_status_id
      ) || null
    );
  }, [applicationStatuses, automatedTests, testGridPackages, testIndex]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="new-test-grid-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={() => setModalOpen(false)} sx={classes.closeIcon} />
        <Box sx={classes.actionModalHeader}>
          {`${testIndex === -1 ? 'New' : 'Edit'} automated test`}
        </Box>
        <Box
          sx={classes.modalContent}
          aria-label={`${testIndex === -1 ? 'New' : 'Edit'} automated test modal content`}
        >
          <Box
            role="form"
            id="automated-test-form"
            aria-label="automated test form"
            sx={{ height: '100%', overflowX: 'clip' }}
          >
            <Box sx={{ ...classes.actionModalFormLine, marginTop: '20px' }} ref={refObject.package}>
              <Autocomplete
                options={testGridPackages}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => {
                  setSelectedPackage(value);
                  setErrors((prev) => ({ ...prev, package: '' }));
                }}
                loading={loading.testGridPackages}
                loadingText="Loading packages..."
                value={selectedPackage}
                sx={{ ...sharedClasses.formAutocomplete, width: '287px' }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Package status"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              {errors.package && <Box sx={classes.formError}>{errors.package}</Box>}
            </Box>
            <Box>
              <Box
                sx={{ ...classes.actionModalFormLine, marginBottom: '18px' }}
                ref={refObject.trigger}
              >
                <Autocomplete
                  options={applicationStatuses}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    setSelectedTriggerStatus(value);
                    setErrors((prev) => ({ ...prev, trigger: '' }));
                  }}
                  loading={loading.applicaitonStatuses}
                  loadingText="Loading statuses..."
                  value={selectedTriggerStatus}
                  sx={{ ...sharedClasses.formAutocomplete, width: '287px' }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Trigger status"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                {errors.trigger && <Box sx={classes.formError}>{errors.trigger}</Box>}
              </Box>
              <Box sx={classes.sliderLine} ref={refObject.pass}>
                <Box sx={classes.inputLabel}>Pass percentage</Box>
                <Slider
                  sx={classes.percentageSlider}
                  value={passAt}
                  onChange={(event, value: number | number[]) => setPassAt(value as number)}
                  valueLabelDisplay="on"
                  valueLabelFormat={(value: number) => `${value}%`}
                />
              </Box>
              <Box sx={classes.actionModalFormLine} ref={refObject.passStatus}>
                <Autocomplete
                  options={applicationStatuses}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    setSelectedPassStatus(value);
                    setErrors((prev) => ({ ...prev, passStatus: '' }));
                  }}
                  loading={loading.applicaitonStatuses}
                  loadingText="Loading statuses..."
                  value={selectedPassStatus}
                  sx={{ ...sharedClasses.formAutocomplete, width: '287px' }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Pass status" InputLabelProps={{ shrink: true }} />
                  )}
                />
                {errors.passStatus && <Box sx={classes.formError}>{errors.passStatus}</Box>}
              </Box>
              <Box sx={classes.actionModalFormLine} ref={refObject.failStatus}>
                <Autocomplete
                  options={applicationStatuses}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    setSelectedFailStatus(value);
                    setErrors((prev) => ({ ...prev, failStatus: '' }));
                  }}
                  loading={loading.applicaitonStatuses}
                  loadingText="Loading statuses..."
                  value={selectedFailStatus}
                  sx={{ ...sharedClasses.formAutocomplete, width: '287px' }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Fail status" InputLabelProps={{ shrink: true }} />
                  )}
                />
                {errors.failStatus && <Box sx={classes.formError}>{errors.failStatus}</Box>}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <Button
            id="close-test-grid-button"
            sx={classes.modalCancelButton}
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            id="send-test-grid-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '71px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSending ? <CircularProgress size={20} color="inherit" /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
