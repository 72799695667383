import React, { useContext, useReducer, useState } from 'react';
import MyReportSection from './MyReportSection';
import { Box, ToggleButtonGroup, ToggleButton, Modal } from "@mui/material";
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';
import { reducer, setInitialState } from './MyReportTable/reducer';
import { defaultActiveColumns, dropdownOptions, filteringStates } from './MyReportTable/configs';
import CustomReportPreview from '../CustomReports/CustomReportsPreview';
import StyledSnackbar from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { ReportContext } from '..';
import CloseIcon from "@mui/icons-material/Close";
import ReportSettings from "../CustomReports/ModalContents/ReportSettings";
import SelectFields from "../CustomReports/ModalContents/SelectFields";

const MyReports = () => {
  const reportServiceApi = useContext(ReportContext);
  const [selectedReport, setSelectedReport] = useState('SavedReports');
  const [tableState, dispatch] = useReducer<typeof reducer>(
    reducer,
    setInitialState(defaultActiveColumns, dropdownOptions, filteringStates, {
      entity_id: reportServiceApi.accessibleEntities,
      user: [reportServiceApi.currentUser.id]
    })
  );
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newSelected: string) => {
    if (newSelected !== null) {
      setSelectedReport(newSelected);
    }
  };

  const renderPreview = () => {
    if (tableState.openPreviewCustom) {
      if (tableState.data?.report_type === 'custom') {
        return (
          <CustomReportPreview
            reportState={tableState}
            dispatch={dispatch}
            setSnackbar={setSnackbar}
            loadPreviewTable={tableState.isLoading}
            editFlow={true}
            resetReportSettingsModal={() => dispatch({ type: 'CLOSE_PREVIEW' })}
          />
        );
      } else {
        return <p>{console.log(tableState.data)}</p>;
      }
    } else {
      return <MyReportSection {...{ selectedReport, tableState, dispatch }} />;
    }
  };

  return (
    <Box>
      <ToggleButtonGroup
        value={selectedReport}
        exclusive
        onChange={handleToggle}
        aria-label="my reports"
        sx={{ paddingBottom: '3rem' }}
      >
        <ToggleButton value="SavedReports" sx={sharedClasses.toggleButton}>
          <Box sx={{ padding: '0 20px' }}>Saved reports</Box>
        </ToggleButton>
        <ToggleButton
          value="ScheduledReports"
          sx={{ ...sharedClasses.toggleButton, borderRight: '1px solid #EEEEEE !Important' }}
        >
          <Box sx={{ padding: '0 10px' }}>Scheduled reports</Box>
        </ToggleButton>
      </ToggleButtonGroup>
      {renderPreview()}
      <StyledSnackbar {...snackbar} setSnackbar={setSnackbar} />
    </Box>
  );
};

export default MyReports;
