import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack
} from '@mui/material';
import { classes } from './styles';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';
import CheckIcon from '@mui/icons-material/Check';
import { v4 as uuidv4 } from 'uuid';
import { IJob } from '../../Job/types';
import { DndContext, DragStartEvent, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import SortableRow from './SortableRow';

export default function CandidateAttachments({
  apiKey,
  job,
  candidateAttachments,
  setCandidateAttachments,
  attachmentsTabJobData,
  setAttachmentsTabJobData,
  setIsEdited
}: {
  apiKey: string;
  job: IJob;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
}) {
  const [isPillTick, setIsPillTick] = useState<Record<string, boolean>>({
    Resume: false,
    'Drivers licence': false,
    Video: false
  });
  const [newAttachment, setNewAttachment] = useState('');
  const [activeId, setActiveId] = useState<string | null>(null);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      setIsEdited(true);
      const { active, over } = event;
      if (over === null) {
        return;
      }
      if (active.id !== over.id) {
        const oldIndex = candidateAttachments
          .map((item) => {
            return item.id;
          })
          .indexOf(active.id as string);
        const newIndex = candidateAttachments
          .map((item) => {
            return item.id;
          })
          .indexOf(over.id as string);
        const newData = arrayMove(candidateAttachments, oldIndex, newIndex);
        setCandidateAttachments(newData);
      }
      setActiveId(null);
    },
    [candidateAttachments]
  );

  const checkPillValue = useCallback(async () => {
    candidateAttachments?.map((attachment) => {
      const flag = Object.keys(isPillTick).includes(attachment.label);
      flag && setIsPillTick((prev) => ({ ...prev, [attachment.label]: attachment.required }));
    });
  }, [job]);

  const handleAttachmentInput = (e, index: number) => {
    setIsEdited(true);
    const { name, value } = e.target;
    const list = [...candidateAttachments];
    list[index].label = value;
    setCandidateAttachments(list);
  };

  const onRemoveAttachment = (index: number) => {
    setIsEdited(true);
    const list = [...candidateAttachments];
    if (Object.keys(isPillTick).includes(list[index].label)) {
      setIsPillTick((prev) => ({ ...prev, [list[index].label]: false }));
    }
    list.splice(index, 1);
    setCandidateAttachments(list);
  };

  const handleChange = (e, index: number) => {
    setIsEdited(true);
    const list = [...candidateAttachments];
    list[index].required = !list[index].required;
    setCandidateAttachments(list);
  };

  const handleAddAttachment = (name: string) => {
    setIsEdited(true);
    const flag = Object.keys(isPillTick).includes(name);

    setNewAttachment('');

    if (flag && !isPillTick[name]) {
      setCandidateAttachments([
        ...candidateAttachments,
        { id: uuidv4(), label: name, value: name, required: flag }
      ]);
      setIsPillTick((prev) => ({ ...prev, [name]: true }));
    } else if (!flag) {
      setCandidateAttachments([
        ...candidateAttachments,
        { id: uuidv4(), label: name, value: name, required: flag }
      ]);
    }
  };

  useEffect(() => {
    checkPillValue();
  }, [checkPillValue]);

  return (
    <Box sx={classes.pageContainer}>
      <Box sx={classes.pageHeader}>
        <h2>Candidate attachments</h2>
      </Box>
      <Box sx={{ color: '#939393', fontSize: '16px', padding: '30px 0' }}>
        Select or add the documents that you want candidates to attach. All attachments will be
        optional unless made mandatory.
      </Box>
      <Box sx={classes.actionsContianer}>
        {Object.keys(isPillTick).map((pill, index) => (
          <Button
            key={index}
            variant="outlined"
            startIcon={isPillTick[pill] ? <CheckIcon /> : ''}
            sx={isPillTick[pill] ? classes.checkedPill : classes.actionButton}
            onClick={() => handleAddAttachment(pill)}
          >
            {pill}
          </Button>
        ))}
      </Box>
      <Box sx={{ padding: '20px 0' }}>
        <Box id="add-attachment-title" sx={{ color: '#939393', marginBottom: 1 }}>
          New attachment
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Stack flexGrow="0.5" maxWidth="250px">
            <FormTextField
              dataTestId="new-attachment-text-input"
              value={newAttachment}
              onChange={(e) => {
                setNewAttachment(e.target.value);
                setIsEdited(true);
              }}
              required={true}
            />
          </Stack>
          <Button
            id="add-attachment-button"
            data-testid="add-attachment-button"
            sx={classes.addAttachmentButton}
            onClick={() => handleAddAttachment(newAttachment)}
            disabled={!newAttachment}
          >
            Add attachment
          </Button>
        </Box>
      </Box>
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
          <Table sx={classes.tableWrapper} aria-label="attachment table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ minWidth: '150px' }}>
                  Mandatory
                </TableCell>
                <TableCell>Title</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <SortableContext items={candidateAttachments} strategy={rectSortingStrategy}>
                {candidateAttachments &&
                  candidateAttachments.map((attachment, index) => (
                    <SortableRow
                      key={attachment.value + attachment.id}
                      id={attachment.id}
                      row={attachment}
                      index={index}
                      handleChange={handleChange}
                      handleAttachmentInput={handleAttachmentInput}
                      onRemoveAttachment={onRemoveAttachment}
                    />
                  ))}
              </SortableContext>
            </TableBody>
          </Table>
        </TableContainer>
      </DndContext>
    </Box>
  );
}
