import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { theme } from '../../../ThemeContext/ThemeObject';
import { classes } from './styles';
import { ISelectedApplicantsProps } from '../types';

export const getRatingColor = (rating: number) => {
  if (rating <= 20) {
    return '#CF5005';
  } else if (rating <= 40) {
    return '#E7B500';
  } else if (rating <= 60) {
    return '#B7F018';
  } else if (rating <= 80) {
    return '#94EDBB';
  } else if (rating <= 100) {
    return '#6FCC7C';
  } else {
    return '#FFFFFF';
  }
};

export default function SelectedApplicants({
  globalSelected,
  handleDeselectCandidate,
  closeModalCallback,
  filters,
  eventCandidates
}: ISelectedApplicantsProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const statusDropdownRef = useRef<HTMLDivElement>(null);

  const filterCandidates = (application: ISelectedApplicantsProps['globalSelected'][0]) => {
    return searchTerm
      ? `${application.candidate?.firstname?.toLowerCase()} ${application.candidate?.lastname?.toLowerCase()}`.includes(
          searchTerm.toLowerCase()
        ) || application.candidate?.phone1?.includes(searchTerm)
      : true;
  };

  const onRemoveCandidate = (id: number) => {
    handleDeselectCandidate(id);
    if (globalSelected?.length <= 1) closeModalCallback();
  };

  return (
    <>
      {globalSelected.length > 50 ? (
        <Box></Box>
      ) : (
        <OutlinedInput
          id="job-search"
          sx={classes.candidateSearch}
          placeholder={'Search candidates'}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment sx={{ padding: '0px 0px 0px 10px' }} position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      )}
      <Box sx={classes.selectedApplicantsTableHeader}>
        {globalSelected.length} candidates selected
      </Box>
      {globalSelected.length > 50 ? (
        <>
          <Box sx={classes.selectedApplicantsTableHeader}>Status</Box>
          {filters?.status.length === 0 && <Box sx={classes.filterButton}>No Status Selected</Box>}
          <Stack sx={{ flexDirection: 'row' }}>
            {filters?.status.map((filterStatus) => (
              <Stack
                key={filterStatus.id}
                sx={{
                  ...classes.filterButton,
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginRight: 2
                }}
              >
                <Box
                  sx={{
                    ...classes.statusColour,
                    position: 'unset',
                    marginRight: 1,
                    height: '8px',
                    width: '8px',
                    backgroundColor: filterStatus.colour ? filterStatus.colour : '#AAAAAA'
                  }}
                ></Box>
                {filterStatus.name}
              </Stack>
            ))}
          </Stack>
          <Box>
            <Box sx={classes.selectedApplicantsTableHeader}>Rating</Box>
            <Box sx={classes.filterButton}>
              {filters?.rating[0]} - {filters?.rating[1]} %
            </Box>
          </Box>
        </>
      ) : (
        <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
          <Table
            sx={{ ...classes.selectedApplicantsTable, width: eventCandidates ? '100%' : 'unset' }}
            aria-label="selected applicants"
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Rating</TableCell>
                {eventCandidates && <TableCell>Timeslot Availability</TableCell>}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {globalSelected
                .filter((candidate) => filterCandidates(candidate))
                .map((application: ISelectedApplicantsProps['globalSelected'][0]) => (
                  <TableRow key={application.id}>
                    <TableCell>
                      {application.candidate.firstname} {application.candidate.lastname}
                    </TableCell>
                    <TableCell>{application.candidate.phone1}</TableCell>
                    <TableCell sx={classes.applicationStatus}>
                      <Box
                        sx={{ ...classes.statusDropdownSelected, ...classes.statusClampSelected }}
                        ref={statusDropdownRef}
                      >
                        <Box
                          className="status-dropdown-full"
                          sx={{
                            '&::after': {
                              display: 'block',
                              top:
                                statusDropdownRef.current &&
                                statusDropdownRef.current.clientHeight > 45
                                  ? '25.5px'
                                  : '50%'
                            }
                          }}
                        >
                          <>
                            <Box
                              sx={{
                                ...classes.statusColour,
                                backgroundColor: application.status_details?.colour
                                  ? application.status_details?.colour
                                  : '#AAAAAA'
                              }}
                            ></Box>
                            {application.status}
                          </>
                        </Box>
                        <Box
                          sx={{
                            ...classes.statusColour,
                            backgroundColor: application.status_details?.colour
                              ? application.status_details?.colour
                              : '#AAAAAA'
                          }}
                        ></Box>
                        {application.status}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: '#000000',
                        fontWeight: '600'
                      }}
                    >
                      <Box
                        sx={{
                          ...classes.rating,
                          backgroundColor: getRatingColor(
                            application.rating === null ? 101 : Number(application.rating)
                          )
                        }}
                      >
                        {application.rating ? Math.ceil(Number(application.rating)) + '%' : '-'}
                      </Box>
                    </TableCell>
                    {eventCandidates && (
                      <TableCell>
                        <Stack
                          sx={{
                            ...classes.availabilityColumnCell,
                            background: eventCandidates.includes(application.id)
                              ? '#E5E5E5'
                              : '#EFFDF3',
                            color: eventCandidates.includes(application.id) ? '#939393' : '#8CCF95'
                          }}
                        >
                          {eventCandidates.includes(application.id) ? 'BOOKED' : 'AVAILABLE'}
                        </Stack>
                      </TableCell>
                    )}
                    <TableCell>
                      <IconButton
                        onClick={() => onRemoveCandidate(application.id)}
                        sx={{ marginLeft: '35px' }}
                      >
                        <ClearIcon sx={{ color: theme.palette.error.main }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
