import React, { useState, useRef, Dispatch, SetStateAction } from 'react';
import { Box, FormHelperText, FormControl, IconButton, Collapse } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { sharedClasses } from './sharedClasses';

export default function MultiTagInput({
  tags,
  setTags,
  validation,
  validationMessage,
  label,
  required,
  error,
  width,
  helperText,
  styles,
  tagType,
  labelStylesOverride,
  helperTextStylesOverride,
  autoFocus
}: {
  tags: string[];
  setTags: Dispatch<SetStateAction<string[]>>;
  validation?: (value: string) => boolean;
  validationMessage?: string;
  label: string;
  required?: boolean;
  error?: string;
  width?: string;
  helperText?: string;
  styles?: React.CSSProperties;
  tagType?: string;
  labelStylesOverride?: React.CSSProperties;
  helperTextStylesOverride?: React.CSSProperties;
  autoFocus?: boolean;
}) {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState(false);
  const [message, setMessage] = useState('');

  const handleRemoveTag = (index: number) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const handleAddTag = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!inputValue) return;
    if (validation && !validation(inputValue)) {
      setMessage(validationMessage || 'Invalid item');
      return;
    }
    if (tags.includes(inputValue)) {
      setMessage('Items must be unique');
      return;
    }
    setTags([...tags, inputValue]);
    setInputValue('');
    setMessage('');
    (event.target as Element).setAttribute('size', '2');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ' || event.key === ',') {
      event.preventDefault();
      handleAddTag(event);
    } else if (event.key === 'Tab' && inputValue) {
      handleAddTag(event);
    } else if (event.key === 'Backspace' && inputValue === '') {
      event.preventDefault();
      if (selectedForDelete) {
        setTags((prev) => prev.slice(0, tags.length - 1));
        setMessage('');
        setSelectedForDelete(false);
      } else {
        setSelectedForDelete(true);
      }
    } else if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
      setSelectedForDelete(false);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text').trim();
    const newTags = paste.split(/[\s,]+/);
    const uniqueTags = [...new Set(newTags)];
    const invalidTags = uniqueTags.filter((tag) => validation && !validation(tag));
    const validTags = uniqueTags.filter((tag) => {
      if (validation && !validation(tag)) return false;
      if (tags.includes(tag)) return false;
      return true;
    });
    setTags([...tags, ...validTags]);
    if (validation && invalidTags.length > 0) {
      setMessage(`Invalid ${tagType ? tagType : 'tags'}: ${invalidTags.join(', ')}`);
    } else {
      setMessage('');
    }
    setInputValue('');
  };

  return (
    <FormControl variant="standard" sx={{ width: width || '290px' }}>
      <Box sx={labelStylesOverride || sharedClasses.multiTagLabel}>
        {label}
        {required && <span>*</span>}
      </Box>
      <Box
        sx={{
          ...sharedClasses.textAndTagsWrapper,
          ...(isFocused ? sharedClasses.focusedStyles : {}),
          ...(styles || {})
        }}
        onClick={() => inputRef.current?.focus()}
      >
        {tags.map((option: string, index: number) => (
          <Box
            sx={{
              ...sharedClasses.tag,
              ...(selectedForDelete && index + 1 === tags.length ? sharedClasses.tagSelected : {})
            }}
            key={option}
          >
            {option}
            <IconButton sx={sharedClasses.tagIconButton} onClick={() => handleRemoveTag(index)}>
              <ClearIcon sx={sharedClasses.tagIcon} />
            </IconButton>
          </Box>
        ))}
        <input
          data-testid="multiple-tags-input"
          ref={inputRef}
          size={1}
          type="text"
          value={inputValue}
          onKeyDown={handleKeyDown}
          onPaste={(e) => handlePaste(e)}
          onInput={(e) =>
            (e.target as HTMLInputElement).setAttribute(
              'size',
              `${((e.target as HTMLInputElement).value.length || 1) + 3}`
            )
          }
          onFocus={() => setIsFocused(true)}
          onBlur={(e) => {
            handleAddTag(e);
            setIsFocused(false);
            setSelectedForDelete(false);
          }}
          onChange={(e) => setInputValue(e.target.value)}
          style={{
            ...sharedClasses.textInput,
            caretColor: selectedForDelete ? '#FFFFFF' : '#000000'
          }}
          autoFocus={autoFocus || false}
        />
      </Box>
      {helperText && (
        <FormHelperText sx={helperTextStylesOverride || sharedClasses.multiTagHelperText}>
          {helperText}
        </FormHelperText>
      )}
      <Collapse in={!!(error || message)}>
        <Box sx={sharedClasses.fixedErrorBox}>{error || message}</Box>
      </Collapse>
    </FormControl>
  );
}
