import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { classes } from '../styles';

export type Note = {
  id: number;
  subject?: string;
  body: string;
  noted_by: string;
};

const NotesWithSubjectLine = ({ notes }: { notes: Note[] }) => {
  const [notesExpanded, setNotesExpanded] = React.useState<boolean>(false);
  const [hasOverflow, setHasOverflow] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setHasOverflow(containerRef.current.scrollHeight > containerRef.current.clientHeight);
    }
  }, [containerRef, notes]);

  return (
    <Box>
      <Box
        sx={{
          ...classes.noteContainer,
          ...(notesExpanded && classes.notesExpanded)
        }}
        ref={containerRef}
      >
        {notes?.map((note) => (
          <Box key={note.id}>
            {note.subject && <Box sx={classes.noteSubject}>{note.subject}</Box>}
            <Box>{note.body}</Box>
            <Box sx={classes.noteUser}>-{note.noted_by}</Box>
          </Box>
        ))}
      </Box>
      {(hasOverflow || notesExpanded) && (
        <Box sx={classes.notesExpandContract} onClick={() => setNotesExpanded((prev) => !prev)}>
          {notesExpanded ? 'Show less' : 'Show all'}
        </Box>
      )}
    </Box>
  );
};

export default NotesWithSubjectLine;
