import React from 'react';
import { Box } from '@mui/material';
import { TDetailedEmail } from '../../types';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { classes } from '../../styles';
import ReactLoading from 'react-loading';
import { theme } from '../../../ThemeContext/ThemeObject';

const StatusStatistics = ({
  isLoading,
  emailAnalytics
}: {
  isLoading: boolean;
  emailAnalytics: TDetailedEmail;
}) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: ['Red', 'Blue', 'Yellow'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [300, 50, 100],
        backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
        hoverOffset: 4
      }
    ]
  };

  return (
    <Box
      sx={{
        ...classes.rowWidget,
        marginTop: '100px',
        padding: '4%',
        justifyContent: 'center'
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            height: '300px',
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <ReactLoading type="spin" color={theme.palette.primary.main} width={100} height={100} />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', minHeight: '300px', maxHeight: '500px' }}>
          <Pie
            options={{
              responsive: true,
              maintainAspectRatio: false
            }}
            data={emailAnalytics?.statistics?.opened_email_statistics}
          />
          <div id="legend-container-analytics" />
        </Box>
      )}
    </Box>
  );
};

export default StatusStatistics;
