import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { navbarWidths } from './config';
import { classes } from './styles';
import AppMenu from './Components/AppMenu/AppMenu';
import NavigationItems from './Components/NavigationItems/NavigationItems';
import ExpandCollapseButton from './Components/ExpandCollapseButton';
import AIStudioModal from '../../../AIStudio/AIStudioModal/AIStudioModal';

const Navbar = ({
  userPermissions,
  isAdminUser,
  aiStudioSettings,
  newEngageEnabled
}: {
  userPermissions: string;
  isAdminUser: boolean;
  aiStudioSettings: {
    aiStudioEnabled: boolean;
    aiSummariesEnabled: boolean;
    showPreview: boolean;
    aiStudioFrenchEnabled: boolean;
  };
  newEngageEnabled: boolean;
}) => {
  const [navbarState, setNavbarState] = useState<keyof typeof navbarWidths>(
    (localStorage.getItem('navbarState') as keyof typeof navbarWidths) || 'expanded'
  );
  const [childState, setChildState] = useState<[number, null | HTMLElement]>([-1, null]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 866) {
        setNavbarState('collapsed');
        localStorage.setItem('navbarState', 'collapsed');
      } else {
        setNavbarState('expanded');
        localStorage.setItem('navbarState', 'expanded');
        setChildState([-1, null]);
      }
    };

    !localStorage.getItem('navbarState') && handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box id="react-navbar">
      <ExpandCollapseButton
        navbarState={navbarState}
        setNavbarState={setNavbarState}
        setChildState={setChildState}
      />
      <Box sx={{ ...classes.navbarContainer, width: `${navbarWidths[navbarState]}px` }}>
        <AppMenu navbarState={navbarState} />
        <NavigationItems
          userPermissions={JSON.parse(userPermissions)}
          navbarState={navbarState}
          childState={childState}
          setChildState={setChildState}
          isAdminUser={isAdminUser}
          aiStudioEnabled={aiStudioSettings.aiStudioEnabled}
          newEngageEnabled={newEngageEnabled}
        />
        <AIStudioModal
          aiStudioEnabled={aiStudioSettings.aiStudioEnabled}
          aiSummariesEnabled={aiStudioSettings.aiSummariesEnabled}
          aiStudioFrenchEnabled={aiStudioSettings.aiStudioFrenchEnabled}
          showPreview={aiStudioSettings.showPreview}
          navbarState={navbarState}
        />
      </Box>
    </Box>
  );
};

export default Navbar;
