import React from 'react';
import Job from './Job';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function JobPage({
  apiKey,
  job_id,
  aiStudioEnabled,
  ai_studio_summaries_enabled,
  foundu_enabled,
  foundu_auth_url,
  entity,
  userTimeZone,
  newNoteOnApplicationEnabled,
  newApplicationEnabled
}: {
  apiKey: string;
  job_id: number;
  aiStudioEnabled: boolean;
  entity: object;
  userTimeZone: string;
  ai_studio_summaries_enabled: boolean;
  foundu_enabled: boolean;
  foundu_auth_url?: string;
  newNoteOnApplicationEnabled: boolean;
  newApplicationEnabled: boolean;
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <Job
        apiKey={apiKey}
        job_id={job_id}
        aiStudioEnabled={aiStudioEnabled}
        ai_studio_summaries_enabled={ai_studio_summaries_enabled}
        foundu_enabled={foundu_enabled}
        foundu_auth_url={foundu_auth_url}
        entity={entity}
        userTimeZone={userTimeZone}
        newNoteOnApplicationEnabled={newNoteOnApplicationEnabled}
        newApplicationEnabled={newApplicationEnabled}
      />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
