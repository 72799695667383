"use strict";
import queryString from "query-string";
const BASE_URL = "/api/v4/";
class Api {
  constructor() {
    this.handleResponse = (response) => {
      if (response.ok) return response.json();
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    };
    this.get = (apiKey, path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}?${queryString.stringify(params, { arrayFormat: "bracket" })}`, {
      headers: this.getHeaders(headers, apiKey)
    }).then((r) => this.handleResponse(r));
    this.post = (apiKey, path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers, apiKey),
      method: "POST",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.put = (apiKey, path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers, apiKey),
      method: "PUT",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.delete = (apiKey, path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers, apiKey),
      method: "DELETE",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.custom_get = (apiKey, path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}${queryString.stringify(params, { arrayFormat: "bracket" })}`, {
      headers: this.getHeaders(headers, apiKey)
    }).then((r) => this.handleResponse(r));
    this.getEventOptions = (apiKey) => this.custom_get(apiKey, "calendar/event_types");
    this.getEventRecruiters = (apiKey) => this.custom_get(apiKey, "calendar/users");
    this.getEvents = (apiKey, date, typeId) => this.get(apiKey, "calendar/events", { date, event_type_id: typeId || void 0 });
    this.deleteEvent = (apiKey, id) => this.delete(apiKey, `calendar/events/${id}`);
    this.createEventsBatches = (apiKey, params) => this.post(apiKey, "calendar/event_batches", { event_batch: params });
    this.removeAttendees = (apiKey, eventId, params) => this.post(apiKey, `calendar/events/${eventId}/remove_attendees`, { attendee_ids: params });
    this.updateEvent = (apiKey, id, params) => this.put(apiKey, `calendar/events/${id}`, params);
    this.updateAttendeeInvitation = (apiKey, eventId, attendeeId, state) => this.put(apiKey, `calendar/events/${eventId}/attendees/${attendeeId}`, {
      attendee: { state }
    });
    this.getEmailTemplates = (apiKey) => this.custom_get(apiKey, "email_templates?group_templates=true");
    this.getEmailTemplate = (apiKey, templateId) => this.custom_get(apiKey, `email_templates/${templateId}`);
    this.getSmsTemplates = (apiKey) => this.get(apiKey, "sms_templates");
    this.saveSmsTemplate = (apiKey, smsTemplateName, smsMessage) => this.post(apiKey, `sms_templates`, {
      sms_template: {
        title: smsTemplateName,
        body: smsMessage
      }
    });
    this.smsCandidates = (apiKey, eventId, applicationIds, content) => this.post(apiKey, `calendar/events/${eventId}/sms_candidates`, {
      applications_ids: applicationIds,
      content
    });
    this.getEventApplicationStatuses = (apiKey, eventId) => this.get(apiKey, `calendar/events/${eventId}/event_application_statuses`);
    this.changeApplicationStatuses = (apiKey, eventId, statusId, applicationIds) => this.post(apiKey, `calendar/events/${eventId}/change_status`, {
      application_ids: applicationIds,
      change_status: statusId
    });
    this.getUserPermissions = (apiKey) => this.get(apiKey, `user_permissions`, {});
  }
  getHeaders(headers, apiKey) {
    return Object.assign(
      {},
      {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-api-authenticate": apiKey
      },
      headers
    );
  }
  /**
   *@deprecated remove later
   */
  // inviteToTimeslot = (eventTypeId, jobId, userId, invitation: {}) =>
  //   this.post('invitations', {
  //     user_id: userId,
  //     event_type_id: eventTypeId,
  //     job_id: jobId,
  //     invitation
  //   });
  // addToEvent = (eventId, jobId, applicationId) =>
  //   this.post(`events/${eventId}/attendees`, {
  //     job_id: jobId,
  //     application_id: applicationId
  //   });
  // getJobApplications = (id: number) => this.get(`jobs/${id}/applications`);
  // getRecrutRecruitersById = (id: number) => this.custom_get(`users?event_batch_id=${id}`);
  // getEventBatchesById = (id: string) => this.get(`event_batches/${id}`);
  // getEvent = (id: string) => this.get(`events/${id}`);
  // deleteEventBatch = (id: number) => this.delete(`event_batches/${id}`);
  // updateEventsBatches = (event, id) => this.put(`event_batches/${id}`, event);
  // getApplications = (id: string, body: {}) => this.get(`event_batches/${id}/applications`, body);
  // getStatuses = (id: string) => this.get(`event_batches/${id}/statuses`);
  // getAvailabilities = () => this.get('availabilities');
  // saveAvailabilities = (availabilities) => this.put('availabilities', availabilities);
  // createInvitations = (id: string, invitation: {}) =>
  //   this.post(`event_batches/${id}/invitations`, invitation);
}
export default new Api();
