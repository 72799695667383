import React from 'react';

export default class ScoutTrainingsPortalButton extends React.Component {
    static propTypes = {
      onClick: PropTypes.func.required
    }
    render() {
        return (
           <div>
              <button className="commit" onClick={ this.props.onClick }>Scout Trainings</button>
            </div>
        );
      }
} 
