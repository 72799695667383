import React from 'react';

export default function BouncedSVG() {
return (<svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
<g id="Group_580" data-name="Group 580" transform="translate(-1141 -710)">
  <rect id="Rectangle_676" data-name="Rectangle 676" width="53" height="53" rx="16" transform="translate(1141 710)" fill="#edf2f5"/>
  <g id="MDI_close-thick" data-name="MDI / close-thick" transform="translate(1153.658 722.658)">
    <g id="Boundary" fill="#084d6d" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
      <rect width="27.684" height="27.684" stroke="none"/>
      <rect x="0.5" y="0.5" width="26.684" height="26.684" fill="none"/>
    </g>
    <path id="Path_close-thick" data-name="Path / close-thick" d="M22.456,7.357,19.1,4,13.228,9.871,7.357,4,4,7.357l5.871,5.871L4,19.1l3.357,3.357,5.871-5.871L19.1,22.456,22.456,19.1l-5.871-5.871Z" transform="translate(0.614 0.614)" fill="#084d6d"/>
  </g>
</g>
</svg>)
}