var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { Component } from "react";
function serviceInterface(ServiceComponent, options) {
  class ServiceInterfaceClient extends Component {
    constructor(props) {
      super(props);
      this.state = {
        success: void 0,
        data: {},
        messages: {
          errors: [],
          notices: [],
          alerts: []
        }
      };
      this.parseResponse = this.parseResponse.bind(this);
    }
    parseResponse(response) {
      const { messages, data, success } = response;
      this.setState({ messages, data, success });
    }
    render() {
      const { messages, data, success } = this.state;
      return /* @__PURE__ */ React.createElement(
        ServiceComponent,
        __spreadValues({
          messages,
          response_data: data || {},
          success,
          parseResponse: this.parseResponse
        }, this.props)
      );
    }
  }
  return ServiceInterfaceClient;
}
export default serviceInterface;
