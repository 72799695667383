import React from 'react';
import { Box } from '@mui/material';
import { classes } from '../styles';
import DelaySelect from './DelaySelect';

export default function DelaySelectWrapper({ delay, setDelay }:
  {
    delay: string;
    setDelay: (value: string) => void
  }
) {
  return (
    <Box sx={classes.actionModalFormLine}>
      <DelaySelect delay={delay} setDelay={setDelay} />
    </Box>
  );
}
