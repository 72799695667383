import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { classes } from './styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export default function SortableRow({
  id,
  row,
  index,
  handleChange,
  handleAttachmentInput,
  onRemoveAttachment
}: {
  id: string;
  row: any;
  index: number;
  handleChange: any;
  handleAttachmentInput: any;
  onRemoveAttachment: any;
}) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <TableRow ref={setNodeRef} style={style} {...attributes}>
      <TableCell align="left">
        <Checkbox
          id={`select-checkbox-${index}`}
          sx={classes.checkbox}
          onChange={(e) => handleChange(e, index)}
          checked={row.required}
        />
      </TableCell>
      <TableCell>
        <FormTextField
          value={row.label}
          onChange={(e) => handleAttachmentInput(e, index)}
          required={true}
          styles={{ width: '250px' }}
        />
      </TableCell>
      <TableCell>
        <IconButton
          data-testid={`delete-attachment-button-${index}`}
          onClick={() => onRemoveAttachment(index)}
        >
          <DeleteIcon sx={{ color: '#E37D7A', fontSize: 16 }} />
        </IconButton>
      </TableCell>
      <TableCell>
        <IconButton ref={setActivatorNodeRef} {...listeners}>
          <DragIndicatorIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
