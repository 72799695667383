import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Skeleton,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { classes } from '../styles';
import SkeletonTable from '../../shared/SkeletonTable';
import { IAutomatedTestProps, IAutomatedTest } from '../../types';
import { IApplicationStatus } from '../../../Job/types';
import TestGridModal from './TestGridModal';
import NoTestsImage from './NoTests';

export default function TestGrid({ apiKey, jobId, setSnackbar }: IAutomatedTestProps) {
  const [loading, setLoading] = useState({
    automatedTests: true,
    applicationStatuses: true,
    testGridPackages: true
  });
  const [isDeleting, setIsDeleting] = useState(-1);
  const [automatedTests, setAutomatedTests] = useState<IAutomatedTest[]>([]);
  const [applicationStatuses, setApplicationStatuses] = useState<IApplicationStatus[]>([]);
  const [testGridPackages, setTestGridPackages] = useState<{ value: string; label: string }[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [testIndex, setTestIndex] = useState(-1);

  const getAutomatedTests = useCallback(async () => {
    setLoading((prev) => ({ ...prev, automatedTests: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/test_grid_tests`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { test_grid_tests } = await response.json();
      setAutomatedTests(test_grid_tests);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching automated tests',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, automatedTests: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getTestgridPackages = useCallback(async () => {
    setLoading((prev) => ({ ...prev, testGridPackages: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/test_grid_packages`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { test_grid_packages } = await response.json();
      setTestGridPackages(test_grid_packages);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching application statuses',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, testGridPackages: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getApplicationStatuses = useCallback(async () => {
    setLoading((prev) => ({ ...prev, applicationStatuses: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/job_application_statuses`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { job_application_statuses } = await response.json();
      job_application_statuses.shift(); // Remove "New" status from array
      setApplicationStatuses(job_application_statuses);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching application statuses',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, applicationStatuses: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const handleOpenModal = (index: number) => {
    setTestIndex(index);
    setModalOpen(true);
  };

  const removeAutomatedTest = async (index: number) => {
    setIsDeleting(index);
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/test_grid_tests/${automatedTests[index].id}`,
        {
          method: 'DELETE',
          headers: {
            'X-api-authenticate': apiKey
          }
        }
      );
      if (response.ok) {
        setSnackbar({
          message: 'Automated test deleted successfully',
          state: 'success'
        });
        const newAutomatedTests = [...automatedTests];
        newAutomatedTests.splice(index, 1);
        setAutomatedTests(newAutomatedTests);
      } else {
        throw new Error('Error deleting automated test');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error deleting automated test',
        state: 'error'
      });
    } finally {
      setIsDeleting(-1);
    }
  };

  useEffect(() => {
    getAutomatedTests();
    getApplicationStatuses();
    getTestgridPackages();
  }, [getApplicationStatuses, getAutomatedTests, getTestgridPackages]);

  return (
    <Box sx={classes.testGridContainer}>
      <Box sx={classes.titleAndButtonContainer}>
        <h2>Automated testgrid</h2>
        <Button sx={classes.newButton} onClick={() => handleOpenModal(-1)}>
          Create automated test
        </Button>
      </Box>
      {loading.automatedTests || automatedTests.length > 0 ? (
        <TableContainer sx={classes.tableContainer}>
          <Table sx={classes.table} aria-label="auto email replies table">
            <TableHead>
              <TableRow>
                <TableCell>Package</TableCell>
                <TableCell>Trigger status</TableCell>
                <TableCell>Pass percentage</TableCell>
                <TableCell>Pass status</TableCell>
                <TableCell>Fail status</TableCell>
                <TableCell align="right">{/*Action column*/}</TableCell>
              </TableRow>
            </TableHead>
            {loading.automatedTests || loading.applicationStatuses
              ? <SkeletonTable rows={5} cols={6} />
              : (
                <TableBody>
                  {automatedTests.map((reply, index) => (
                    <TableRow key={reply.id}>
                      <TableCell>
                        {testGridPackages.find((tgPackage) => tgPackage.value === reply.package)
                          ?.label ?? '-'}
                      </TableCell>
                      <TableCell>
                        {applicationStatuses.find((status) => status.id === reply.trigger_status_id)
                          ?.name ?? '-'}
                      </TableCell>
                      <TableCell>{`${reply.pass_at}% or greater`}</TableCell>
                      <TableCell>
                        {applicationStatuses.find((status) => status.id === reply.pass_status_id)
                          ?.name ?? '-'}
                      </TableCell>
                      <TableCell>
                        {applicationStatuses.find((status) => status.id === reply.fail_status_id)
                          ?.name ?? '-'}
                      </TableCell>
                      <TableCell sx={classes.tableActions}>
                        <IconButton
                          sx={classes.tableEditButton}
                          onClick={() => handleOpenModal(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={classes.tableDeleteButton}
                          onClick={() => removeAutomatedTest(index)}
                        >
                          {isDeleting === index ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            <DeleteIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
          </Table>
        </TableContainer>
      ) : (
        <Box sx={classes.noTests}>
          <NoTestsImage />
          <Box>No automated tests have been created for this job</Box>
        </Box>
      )}
      <TestGridModal
        apiKey={apiKey}
        jobId={jobId}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        automatedTests={automatedTests}
        setAutomatedTests={setAutomatedTests}
        applicationStatuses={applicationStatuses}
        testGridPackages={testGridPackages}
        testIndex={testIndex}
        loading={loading}
        setSnackbar={setSnackbar}
      />
    </Box>
  );
}
