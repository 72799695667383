import React, { Dispatch, RefObject, useMemo } from 'react';
import { Checkbox, SxProps, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { classes } from './styles';
import { handleSelectAllOnPage } from './tableHelper';
import {
  GenericTableData,
  TPaginationState,
  TSortableColumn,
  TTableState,
  TablePaginationAction,
  TableSelectionAction
} from './types';
import { handleAPIRequest } from '../Utilities/handleAPIRequest';

interface IGenericTableHeadProps<T> {
  tableState: TTableState<T, TSortableColumn[]>;
  paginationState: TPaginationState<string>;
  dispatch: Dispatch<TableSelectionAction<T> | TablePaginationAction<string>>;
  items: T[];
  titleCellRef: RefObject<HTMLDivElement>;
  actionCellRef: RefObject<HTMLDivElement>;
  headerStyle: (id: string, tableHovered: boolean) => SxProps;
  titleCellId?: string;
  userPreferenceSource?: string;
  hasActionItems?: boolean;
  allowMultipleSelection?: boolean;
}

const GenericTableHead = <GenericTableHeadProps extends GenericTableData>({
  tableState,
  paginationState,
  dispatch,
  items,
  titleCellRef,
  actionCellRef,
  headerStyle,
  titleCellId = 'name', // This is used to attach a ref to the title cell for the table arrows
  userPreferenceSource,
  hasActionItems = true,
  allowMultipleSelection = true
}: IGenericTableHeadProps<GenericTableHeadProps>) => {
  const selectAllIsIndeterminate = useMemo(
    () => items.map((item) => item.id).some((id) => tableState.selectedItemIds.includes(id)),
    [items, tableState.selectedItemIds]
  );
  const selectAllIsChecked = useMemo(
    () => items.map((item) => item.id).every((id) => tableState.selectedItemIds.includes(id)),
    [items, tableState.selectedItemIds]
  );

  const handleSortChange = (id: string) => {
    let sort_order: 'asc' | 'desc' = 'asc';
    if (paginationState.sort === id && paginationState.sort_order === 'asc') {
      sort_order = 'desc';
    }
    dispatch({ type: 'SET_SORT', payload: { sortBy: id, sortOrder: sort_order } });
    userPreferenceSource &&
      handleAPIRequest({
        method: 'POST',
        url: '/api/v4/ui_preferences',
        body: {
          source: userPreferenceSource,
          sorting: {
            sortBy: id,
            sortOrder: sort_order
          }
        }
      });
  };

  return (
    <TableHead>
      <TableRow>
        {allowMultipleSelection && (
          <TableCell sx={headerStyle('checkbox', tableState.tableHovered)}>
            <Checkbox
              id="select-all-checkbox"
              sx={classes.checkbox}
              indeterminate={selectAllIsIndeterminate && !selectAllIsChecked}
              checked={selectAllIsChecked}
              onChange={(e) => handleSelectAllOnPage(e, items, dispatch)}
              inputProps={{
                'aria-label': 'select all items on page'
              }}
            />
          </TableCell>
        )}
        {tableState.columns.map(
          (header) =>
            header.enabled && (
              <TableCell
                key={header.id}
                ref={header.id === titleCellId ? titleCellRef : null}
                sx={headerStyle(header.id, tableState.tableHovered)}
              >
                <TableSortLabel
                  active={paginationState.sort === header.id}
                  direction={
                    paginationState.sort === header.id ? paginationState.sort_order : 'asc'
                  }
                  onClick={() => handleSortChange(header.id)}
                  sx={header.sortable ? classes.sortable : classes.notSortable}
                  disabled={!header.sortable}
                  id={`${header.id}-sort-label`}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            )
        )}
        <TableCell
          sx={{
            ...headerStyle('actions', tableState.tableHovered),
            ...(!hasActionItems && { minWidth: '20px', width: '20px' })
          }}
          ref={actionCellRef}
        />
      </TableRow>
    </TableHead>
  );
};

export default GenericTableHead;
