import React, { Dispatch, FC, useContext, useState } from 'react';
import {
  Box,
  CircularProgress,
  Modal,
  Button,
  TextField,
  Autocomplete,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import { useQuery } from '@tanstack/react-query';
import { ReportContext } from '../../index';
import { IEntity, IApplicationStatusOption } from '../types';

interface IJobDetailsModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
  entities: IEntity[];
  fetchingEntities: boolean;
}

const JobDetailsModal: FC<IJobDetailsModal> = ({ dispatch, state, entities, fetchingEntities }) => {
  const { filtersModal, openPreview, filters, applicationStatus, selectedEntities } = state;
  const [entity, setEntity] = useState<IEntity | null>(
    entities.find((e) => e.id === selectedEntities[0]) || null
  );
  const [status, setStatus] = useState<IApplicationStatusOption[]>([]);
  const reportServiceApi = useContext(ReportContext);

  const { data: statuses, isLoading: loadingStatuses } = useQuery({
    queryKey: ['statuses'],
    queryFn: async () => {
      const { options } = await reportServiceApi.systemReports.options(openPreview);
      return options as IApplicationStatusOption[];
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading application statuses, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: () => {
      const initialStatuses: IApplicationStatusOption[] = [];
      applicationStatus.map((app) => {
        const s = statuses.find((s) => s.application_status_name === app);
        if (s) initialStatuses.push(s);
      });
      setStatus(initialStatuses);
    },
    initialData: []
  });

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, [openPreview]: false }
    });
  };

  return (
    <Modal
      open={filtersModal['Job Details Report']}
      onClose={handleClose}
      aria-labelledby="job-details-filters-modal"
    >
      <Box
        sx={{
          ...classes.actionsModal,
          maxHeight: `400px`,
          width: '720px'
        }}
      >
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>Job details report filters</Box>
        </Box>
        <Stack sx={{ marginTop: '1.75rem', flexGrow: 1 }}>
          <Box sx={{ ...classes.modalFormLine }}>
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              options={entities}
              getOptionLabel={(option) => option.name}
              loading={fetchingEntities}
              loadingText={fetchingEntities ? 'Loading entities...' : 'No Entities'}
              value={entity}
              onChange={(_event, newValue) => (newValue ? setEntity(newValue) : setEntity(null))}
              sx={{
                ...sharedClasses.formAutocomplete,
                width: '100%',
                marginBottom: 3,
                label: { fontSize: '19px' }
              }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro',
                  color: '#333333',
                  maxHeight: '200px'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Entity"
                  sx={{
                    '& .MuiAutocomplete-root label': { fontSize: '14px' },
                    input: { '&::placeholder': { opacity: 1 } }
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {fetchingEntities ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  placeholder="Please select"
                />
              )}
            />
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              multiple
              options={statuses}
              getOptionLabel={(option) => option.application_status_name}
              value={status}
              onChange={(_event, newValue) => setStatus(newValue)}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro',
                  color: '#333333',
                  maxHeight: '200px',
                  textTransform: 'capitalize'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Include time for first application with statuses"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingStatuses && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  placeholder={`${status.length ? '' : 'Please select'}`}
                  sx={{ input: { '&::placeholder': { opacity: 1 }, textTransform: 'capitalize' } }}
                />
              )}
            />
          </Box>
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset' }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              const filter: Record<string, string> = {};
              if (entity) {
                filter.Entity = entity.name;
                dispatch({ type: 'SELECTED_ENTITIES', payload: entity.all_ids });
              } else {
                delete filter.Entity;
                dispatch({ type: 'SELECTED_ENTITIES', payload: [] });
              }
              if (status.length) {
                filter['Application statuses'] = status
                  .map((s) => s.application_status_name)
                  .join(', ');
                dispatch({
                  type: 'APPLICATION_STATUS',
                  payload: status.map((s) => s.application_status_name)
                });
              } else {
                delete filter['Application statuses'];
                dispatch({ type: 'APPLICATION_STATUS', payload: [] });
              }
              dispatch({ type: 'SET_FILTERS', payload: filter });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default JobDetailsModal;
