import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import StyledModal from '../../Components/GenericModal/StyledModal';
import { IApplicantQuestion } from '../../Components/Utilities/QuestionFieldGenerator';
import ModalFooterButtons from '../../Components/GenericModal/ModalFooterButtons';
import { createQuestionErrorsObject } from '../../ApprovalForms/NewApproval/NewApproval';
import Api from '../API';
import { validateForm } from '../SmartFormsTab/validate-form-submission';
import { getAnswerValue } from '../SmartFormsTab/get-answer-value';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import { styles } from '../styles';
import { getAnswerAttributes } from '../SmartFormsTab/get-answer-attributes';
import { useFormQuestions, useFormResponse } from '../helper';
import QuestionsAndRatings from '../OverviewTab/QuestionsAndRatings';
import { ExitConfirmationModal } from './ExitConfirmationModal';

export default function EditFormResponseModal({
  ApplicationState,
  dispatch,
  selectedFormId,
  EditingFormResponseId,
  FormName
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  selectedFormId: number;
  EditingFormResponseId: number;
  FormName: string;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const questionsRef = useRef<Array<HTMLDivElement | null>>([]);
  const [answers, setAnswers] = useState<
    Record<number, string | number | Record<string, string | boolean>>
  >({});
  const [questions, setQuestions] = useState<IApplicantQuestion[]>([]);
  const [questionErrors, setQuestionErrors] = useState<Record<number, string>>(
    createQuestionErrorsObject(questions)
  );
  const [exitConfirmation, setExitConfirmation] = useState<boolean>(false);

  const { data: formResponse, isLoading: loadingFormResponse } = useFormResponse({
    FormResponseId: EditingFormResponseId,
    dispatch,
    application
  });
  const { formQuestions, loadingFormQuestions } = useFormQuestions({
    application,
    selectedFormId,
    dispatch,
    formResponseId: EditingFormResponseId
  });

  // for each question, store the rating value from the form response.rating
  const [ratings, setRatings] = useState<Record<number, number>>({});
  const { mutate: editForm, isLoading: editingForm } = useMutation({
    mutationFn: async () => {
      if (application && EditingFormResponseId) {
        const { res } = await Api.editFormResponse(
          application.job.id,
          application.id,
          EditingFormResponseId,
          answersAttributes
        );
        return res;
      }
    },
    onSuccess: (res) => {
      queryClient.removeQueries(['formQuestionAndAnswers', EditingFormResponseId]);
      queryClient.removeQueries(['formResponses']);
      queryClient.removeQueries(['formQuestions', selectedFormId, EditingFormResponseId]);
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      handleClose();
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Failed to save form response`, state: 'error' }
      })
  });

  useEffect(() => {
    if (formResponse && formQuestions) {
      const initialAnswers = formQuestions.reduce(
        (acc, q, index) => {
          acc[q.id] = getAnswerValue(q, index, formResponse);
          return acc;
        },
        {} as Record<number, string | number | Record<string, string | boolean>>
      );
      const initialRatings = formQuestions.reduce(
        (acc, q, index) => {
          acc[q.id] = formResponse[index]?.rating;
          return acc;
        },
        {} as Record<number, number>
      );
      setAnswers(initialAnswers);
      setRatings(initialRatings);
      setQuestions(formQuestions);
    }
  }, [formResponse, formQuestions]);

  const handleClose = () => {
    dispatch({
      type: 'SET_SELECTED_FORM_RESPONSE_ACTION',
      payload: { edit: null, delete: null }
    });
  };

  const answersAttributes: any = {};
  if (questions && formResponse) {
    questions.forEach((question, index) => {
      answersAttributes[index] = getAnswerAttributes(
        question,
        index,
        answers,
        ratings,
        formResponse
      );
    });
  }
  const handleSubmit = () =>
    validateForm(questions, answers, setQuestionErrors, questionsRef) && editForm();

  if (!application) return null;

  return (
    <StyledModal
      isOpen={!!ApplicationState.selectedFormResponseAction.edit}
      label="Edit form response modal"
      handleClose={() => setExitConfirmation(true)}
      styleOverrides={{ ...styles.modalStyleOverrides, height: '700px' }}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>{FormName}</Stack>
        {loadingFormResponse || loadingFormQuestions ? (
          <Stack sx={styles.loaderContainer}>
            <CircularProgress size={60} sx={{ color: '#084D6D' }} />
            Loading Form
          </Stack>
        ) : (
          <QuestionsAndRatings
            questions={questions}
            questionErrors={questionErrors}
            setQuestionErrors={setQuestionErrors}
            ratings={ratings}
            setRatings={setRatings}
            setAnswers={setAnswers}
            questionsRef={questionsRef}
            formResponseAnswers={formResponse}
          />
        )}
        <ModalFooterButtons
          primaryButtonText={'Save'}
          primaryButtonCallback={handleSubmit}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={() => setExitConfirmation(true)}
          primaryButtonMinWidth={'95px'}
          isLoading={editingForm}
        />
      </Stack>
      <ExitConfirmationModal
        exitConfirmation={exitConfirmation}
        setExitConfirmation={setExitConfirmation}
        handleClose={handleClose}
      />
    </StyledModal>
  );
}
