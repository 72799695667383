import React, { Dispatch } from 'react';
import { Autocomplete, Box, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { classes } from './styles';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { FormNumberField } from '../../../NewUI/Components/CustomUIElements/FormTextField';
import {
  ControlledTimePicker,
  SingleDatePicker
} from '../../../NewUI/Components/CustomUIElements/SingleDatePicker';
import { IEventType, ScoutCalendarAction, ScoutCalendarState } from '../../types';
import MultipleDatesPicker from '../../../NewUI/Components/CustomUIElements/MultipleDatesPicker';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export default function FirstStep({
  eventTypes,
  loadingOptions,
  recruiterOptions,
  loadingRecruiterOptions,
  ScoutCalendarState,
  dispatch,
  userTimezone
}: {
  eventTypes: IEventType[];
  loadingOptions: boolean;
  recruiterOptions: { id: number; name: string; checked: boolean }[];
  loadingRecruiterOptions: boolean;
  ScoutCalendarState: ScoutCalendarState;
  dispatch: Dispatch<ScoutCalendarAction>;
  userTimezone: string;
}) {
  const { createEventObject, errorsMessage, createMultipleEvents } = ScoutCalendarState;
  const handleTimeChange = (time: Dayjs | null) => {
    if (!time) return;
    if (!time.isValid()) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: { ...errorsMessage, time: 'Please input a valid time' }
      });
    } else {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: { ...errorsMessage, time: '' }
      });
      const hourAndMinute = time.format('H:mm').split(':');
      const newDateAndTime = dayjs
        .tz(createEventObject.eventDate, userTimezone)
        .hour(Number(hourAndMinute[0]))
        .minute(Number(hourAndMinute[1]))
        .second(0)
        .toDate();
      const newDates = [...createEventObject.dates];
      newDates[0] = newDateAndTime;
      dispatch({
        type: 'SET_CREATE_EVENT_OBJECT',
        payload: {
          ...createEventObject,
          eventDate: newDateAndTime,
          dates: newDates
        }
      });
    }
  };

  return (
    <Box>
      <Box sx={classes.progressHeader}>Interview date and attendees</Box>
      <Stack
        sx={{
          height: '415px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          rowGap: 3
        }}
      >
        {createEventObject.eventId ? (
          <Stack sx={{ paddingLeft: 0.5, rowGap: 1 }}>
            <Stack sx={{ color: '#939393', flexDirection: 'row', columnGap: '2px' }}>
              Event type <Stack sx={{ color: '#E37D7A' }}>*</Stack>
            </Stack>
            <Stack>{createEventObject.eventType?.name}</Stack>
          </Stack>
        ) : (
          <Autocomplete
            disablePortal
            autoHighlight
            includeInputInList
            options={eventTypes || []}
            groupBy={(option) => option.entity_name}
            loading={loadingOptions}
            getOptionLabel={(option) => option.name}
            noOptionsText="No event types"
            value={createEventObject.eventType}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_event, newValue) =>
              dispatch({
                type: 'SET_CREATE_EVENT_OBJECT',
                payload: {
                  ...createEventObject,
                  eventType: newValue,
                  duration: newValue ? String(newValue.duration) : '15',
                  maxCandidates: newValue ? String(newValue.max_candidates) : '1'
                }
              })
            }
            sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 3 }}
            ListboxProps={{ style: classes.autocompleteListboxStyle }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={classes.autocompleteTextfield}
                label="Choose Event Type"
                InputLabelProps={{ shrink: true }}
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
                helperText={errorsMessage.eventType}
              />
            )}
          />
        )}
        <Stack sx={{ flexDirection: 'row', columnGap: 3, margin: '3px' }}>
          <Box sx={{ width: '100%' }}>
            {createMultipleEvents ? (
              <MultipleDatesPicker
                values={createEventObject.dates}
                label={'Dates'}
                required
                minDate={dayjs()}
                onAccept={(dates) => {
                  dispatch({
                    type: 'SET_CREATE_EVENT_OBJECT',
                    payload: {
                      ...createEventObject,
                      dates: dates,
                      eventDate: dates[0]
                    }
                  });
                }}
              />
            ) : (
              <>
                <SingleDatePicker
                  value={createEventObject.eventDate}
                  label={'Date'}
                  inputStyles={classes.datePicker}
                  disablePast
                  onChange={(value) => {
                    if (!value?.isValid()) {
                      dispatch({
                        type: 'SET_ERROR_MESSAGE',
                        payload: { ...errorsMessage, date: 'Please input a valid date' }
                      });
                    } else {
                      dispatch({
                        type: 'SET_ERROR_MESSAGE',
                        payload: { ...errorsMessage, date: '' }
                      });
                      value?.isValid() &&
                        dispatch({
                          type: 'SET_CREATE_EVENT_OBJECT',
                          payload: {
                            ...createEventObject,
                            eventDate: dayjs(value.toDate()).second(0).toDate(),
                            dates: [dayjs(value.toDate()).second(0).toDate()]
                          }
                        });
                    }
                  }}
                  showTodayButton
                />
                <Typography variant="caption" sx={classes.errorMessage}>
                  {errorsMessage.date}
                </Typography>
              </>
            )}
          </Box>
          <Box sx={{ width: '100%' }}>
            <ControlledTimePicker
              onChange={handleTimeChange}
              value={dayjs.tz(createEventObject.eventDate, userTimezone)}
              label="Time"
              minutesInterval={1}
            />
            <Typography variant="caption" sx={classes.errorMessage}>
              {errorsMessage.time}
            </Typography>
          </Box>
          <FormNumberField
            label={'Duration'}
            value={createEventObject.duration}
            onChange={(e) =>
              dispatch({
                type: 'SET_CREATE_EVENT_OBJECT',
                payload: { ...createEventObject, duration: e.target.value }
              })
            }
            unit="minutes"
            fullWidth
            error={errorsMessage.duration}
          />
        </Stack>
        <Stack
          sx={{
            flexDirection: 'row',
            columnGap: 3,
            margin: '3px'
          }}
        >
          <FormNumberField
            label={'Max. candidates per session'}
            value={createEventObject.maxCandidates}
            onChange={(e) =>
              dispatch({
                type: 'SET_CREATE_EVENT_OBJECT',
                payload: { ...createEventObject, maxCandidates: e.target.value }
              })
            }
            styles={{ width: createMultipleEvents ? undefined : '31%' }}
            fullWidth
            required
            error={errorsMessage.maxCandidates}
          />
          {createMultipleEvents && (
            <>
              <FormNumberField
                label={'Sessions (in the day)'}
                value={createEventObject.sessions}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_CREATE_EVENT_OBJECT',
                    payload: { ...createEventObject, sessions: e.target.value }
                  })
                }
                fullWidth
                required
                error={errorsMessage.sessions}
              />
              <FormNumberField
                label={'Break (in between sessions)'}
                value={createEventObject.breaks}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_CREATE_EVENT_OBJECT',
                    payload: { ...createEventObject, breaks: e.target.value }
                  })
                }
                unit="minutes"
                fullWidth
                required
              />
            </>
          )}
        </Stack>
        <Box>
          <Autocomplete
            multiple
            options={recruiterOptions || []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={createEventObject.recruiters}
            loading={loadingRecruiterOptions}
            onChange={(e, newValue) =>
              dispatch({
                type: 'SET_CREATE_EVENT_OBJECT',
                payload: { ...createEventObject, recruiters: newValue }
              })
            }
            sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 3 }}
            ListboxProps={{ style: classes.autocompleteListboxStyle }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add recruiters"
                sx={classes.autocompleteTextfield}
                InputLabelProps={{ shrink: true }}
                placeholder={createEventObject.recruiters.length ? '' : 'Search Users'}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingRecruiterOptions ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
                helperText={errorsMessage.recruiters}
              />
            )}
          />
        </Box>
      </Stack>
    </Box>
  );
}
