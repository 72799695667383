"use strict";
export const styles = {
  label: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    fontSize: "13px",
    color: "#666666",
    width: "fit-content",
    height: "24px"
  },
  chipContainer: {
    width: "min-content",
    zIndex: 0,
    position: "relative"
  },
  tagContent: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    width: "40px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    marginLeft: "-10px",
    borderRadius: "16px",
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    height: "24px"
  },
  styledChip: {
    position: "absolute",
    width: "30%",
    backgroundColor: "rgb(255, 255, 255)",
    left: -3,
    height: "100%",
    borderBottomRightRadius: "16px",
    borderTopRightRadius: "16px"
  },
  styledChipLabel: {
    marginLeft: "15px",
    textTransform: "none",
    display: "flex",
    alignItems: "center"
  }
};
