import React, { Dispatch, useState } from 'react';
import { Box, Button, CircularProgress, Popover, TextField } from '@mui/material';
import { classes } from '../styles';
import { TableActionItemAction } from '../types';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import callAPI from '../../Utilities/callAPI';
import { useQueryClient, useMutation } from '@tanstack/react-query';

const AddNoteWithSubjectLine = ({
  dispatch,
  isOpen,
  anchorEl,
  itemId,
  url,
  queryKey
}: {
  dispatch: Dispatch<TableActionItemAction<any>>;
  isOpen: boolean;
  anchorEl: Element | null;
  itemId: number;
  url: string;
  queryKey: string;
}) => {
  const [noteSubject, setNoteSubject] = useState('');
  const [noteBody, setNoteBody] = useState('');

  const queryClient = useQueryClient();

  const { mutate: saveNote, isLoading: savingNote } = useMutation({
    mutationFn: () =>
      callAPI(
        url,
        {
          id: itemId,
          note: {
            subject: noteSubject,
            body: noteBody
          }
        },
        'POST'
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
      handleClose();
    }
  });

  const handleClose = () => {
    dispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'addNote' });
    setNoteSubject('');
    setNoteBody('');
  };

  return (
    <Popover
      id={'edit-note-popover'}
      sx={classes.editNotePopover}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <>
        <Box sx={classes.notesTitle}>Add application note</Box>
        <TextField
          id="note-input"
          sx={classes.noteInput}
          label="Subject"
          fullWidth
          variant="outlined"
          onChange={(e) => setNoteSubject(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          required
        />
        <TextField
          id="note-input"
          sx={classes.noteInput}
          label="Body"
          multiline
          fullWidth
          maxRows={4}
          variant="outlined"
          onChange={(e) => setNoteBody(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Box id="notes-button-container" sx={classes.notesButtonContainer}>
          <Button sx={classes.notesCancelButton} endIcon={<CloseIcon />} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={classes.notesEditSaveButton}
            endIcon={savingNote ? <CircularProgress size={16} /> : <DoneIcon />}
            onClick={() => {
              saveNote();
            }}
            disabled={!noteSubject || !!savingNote}
          >
            Save
          </Button>
        </Box>
      </>
    </Popover>
  );
};

export default AddNoteWithSubjectLine;
