import axios from 'axios';

export function logActivity(activity, activityUrl) {
  const data = new FormData();

  data.append(`verb`, activity);
  axios.post(
    activityUrl,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
    },
  );
}

export function getVideo(url, applicationId) {
  const data = new FormData();

  const query = `
    query getVideo($id: ID, $slug: ID) {
      video(id:$id, slug: $slug){
        id
        slug
        originalVideoUrl
        state
        userId
        videoSources {
          format
          id
          mimeType
          url
        }
        bookmarks {
          description
          seconds
        }
      }
    }
  `;

  return axios.post(
    url,
    JSON.stringify({
      query: query,
      variables: {
        slug: `interview-application-${applicationId}`,
      },
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then(response => {
    return response.data.data;
  });
}

export function deleteVideo(url, videoId) {
  if (videoId) {
    const data = new FormData();
    data.append(`video_id`, videoId);

    return axios.post(
      url,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
      }
    ).then(response => {
      return response.data;
    });
  }
}
