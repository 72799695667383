import React, { useRef, useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import CreateNewApproval from './NewApproval/NewApprovalModal';
import { styles } from './styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ITabs } from './types';
import Api from './API';
import { IPermissions } from '../Jobs/types';
import ApprovalTemplates from './ApprovalTemplates/ApprovalTemplates';
import StyledSnackbar from '../Components/CustomUIElements/StyledSnackbar';
import ApprovalManagers from './ApprovalManagers/ApprovalManagers';
import ApprovalPublicLinks from './ApprovalPublicLinks/ApprovalPublicLinks';
import ArchivedFormsAndTemplates from './ArchivedFormsAndTemplates/ArchivedFormsAndTemplates';
import ApprovalFormsTab from './ApprovalFormsTab';
import Popover from '@mui/material/Popover';
import ApprovalTemplateSettings from './ApprovalTemplateSettings/ApprovalTemplateSettings';
import { IUserPermissions } from '../Components/sharedTypes';
import CreateButton from '../Components/Buttons/CreateButton';
import { getPermissions } from '../../shared/permissionHelpers';

const URL_PARAMS = new URL(window.location.href);

const getTabNumber = (urlParams: string) => {
  switch (urlParams) {
    case '?templates':
      return 1;
    case '?managers':
      return 2;
    case '?public-links':
      return 3;
    case '?archived':
      return 4;
    default:
      return 0;
  }
};

export default function ApprovalForms({
  apiKey,
  enableEmailTemplateFields
}: {
  apiKey: string;
  enableEmailTemplateFields: boolean;
}) {
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [newApprovalOptions, setNewApprovalOptions] = useState<string[]>([]);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [userPermissions, setUserPermissions] = useState<IUserPermissions | undefined>(undefined);
  const [isPublicLinkDialogOpen, setIsPublicLinkDialogOpen] = useState(false);
  const [isApprovalManagerDialogOpen, setIsApprovalManagerDialogOpen] = useState<boolean>(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLDivElement | null>(null);
  const [newApprovalFormModal, setNewApprovalFormModal] = useState<boolean>(false);
  const [newApprovalTemplateModal, setNewApprovalTemplateModal] = useState<boolean>(false);
  const [templateArchived, setTemplateArchived] = useState<boolean>(false);
  const [entityDefaultApprovers, setEntityDefaultApprovers] = useState<[]>([]);

  const headerRefs = useRef<HTMLDivElement[]>([]);

  const TabPanel = useCallback((props: ITabs) => {
    const { children, tabValue, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        id={`approvals-tabpanel-${index}`}
        aria-labelledby={`approvals-tab-${index}`}
        {...other}
      >
        {tabValue === index && <>{children}</>}
      </div>
    );
  }, []);

  const TabProps = useCallback((index: number) => {
    return {
      id: `approvals-tab-${index}`,
      value: index,
      'aria-controls': `approvals-tabpanel-${index}`
    };
  }, []);

  const getUserPermissions = useCallback(async () => {
    try {
      const data = await getPermissions(apiKey);
      const allRoles: Record<string, Record<string, boolean>> = {};
      data.roles.forEach((role: IPermissions) =>
        role.feature_groups.forEach((group) => {
          !allRoles[group.name] ? (allRoles[group.name] = {}) : null;
          group.permissions.forEach((permission) => (allRoles[group.name][permission] = true));
        })
      );
      setUserPermissions(allRoles);
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting permissions',
        state: 'error'
      });
    } finally {
      setTabValue(getTabNumber(URL_PARAMS.search));
    }
  }, [setUserPermissions, apiKey]);

  const getEntityDefaultApprovers = useCallback(async () => {
    try {
      const data = await Api.getEntityApprovers({ 'X-api-authenticate': apiKey });
      setEntityDefaultApprovers(data.res.entity_default_approvers);
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting entity default approvers',
        state: 'error'
      });
    }
  }, [apiKey]);

  useEffect(() => {
    setIndicatorWidth(headerRefs.current[tabValue].clientWidth - 32);
    getUserPermissions();
    getEntityDefaultApprovers();
  }, [tabValue, apiKey, getUserPermissions, getEntityDefaultApprovers]);

  const handleTabChange = (event?: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    URL_PARAMS.search = getPageParams(newValue);
    window.history.pushState(null, null, URL_PARAMS.href);
  };

  const getPageParams = (tabNo: number) => {
    switch (tabNo) {
      case 0:
        return '';
      case 1:
        return '?templates';
      case 2:
        return '?managers';
      case 3:
        return '?public-links';
      case 4:
        return '?archived';
      default:
        return '';
    }
  };

  return (
    <Box sx={styles.jobPageContainer}>
      <Box sx={styles.jobPageHeader}>
        <h1>Approvals</h1>
        {(userPermissions?.['Approval Forms']?.['Submit Approval Form'] ||
          userPermissions?.['Approval Forms']?.['Create / Edit Approval Forms (HR use)'] ||
          userPermissions?.['Approval Forms']?.['Manage Approval Managers (HR use)'] ||
          userPermissions?.['Approval Forms']?.['Manage Public Link']) && (
          <CreateButton
            id="approvals-create-button"
            ariaLabel="Add new action button"
            onClick={(e) => {
              setActionsAnchorEl(e.currentTarget);
            }}
            isOpen={Boolean(actionsAnchorEl)}
          />
        )}
        <Popover
          id={actionsAnchorEl ? 'actions-menu-popover' : undefined}
          sx={styles.actionsMenu}
          open={Boolean(actionsAnchorEl)}
          anchorEl={actionsAnchorEl}
          onClose={() => {
            setActionsAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {userPermissions?.['Approval Forms']?.['Submit Approval Form'] && (
            <span
              onClick={() => {
                setActionsAnchorEl(null);
                setNewApprovalFormModal(true);
              }}
              className="create-approval-form-action-button"
            >
              Approval Form
            </span>
          )}
          {userPermissions?.['Approval Forms']?.['Create / Edit Approval Forms (HR use)'] && (
            <span
              onClick={() => {
                setActionsAnchorEl(null);
                setNewApprovalTemplateModal(true);
              }}
              className="create-approval-template-action-button"
            >
              Approval Template
            </span>
          )}
          {userPermissions?.['Approval Forms']?.['Manage Approval Managers (HR use)'] && (
            <span
              onClick={() => {
                setActionsAnchorEl(null);
                handleTabChange(undefined, 2);
                setIsApprovalManagerDialogOpen(true);
              }}
              className="create-approval-manager-action-button"
            >
              Approval Manager
            </span>
          )}
          {userPermissions?.['Approval Forms']?.['Manage Public Link'] && (
            <span
              onClick={() => {
                setActionsAnchorEl(null);
                handleTabChange(undefined, 3);
                setIsPublicLinkDialogOpen(true);
              }}
              className="create-approval-public-link-action-button"
            >
              Approval Public Link
            </span>
          )}
        </Popover>
      </Box>
      <Box sx={styles.tabsContainer}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="Approvals"
          sx={{
            ...styles.tabs,
            '& .MuiTabs-indicator': { ...styles.tabIndicator, maxWidth: indicatorWidth + 'px' }
          }}
        >
          <Tab ref={(el) => (headerRefs.current[0] = el)} label="Approval forms" {...TabProps(0)} />
          {userPermissions?.['Approval Forms']?.['Create / Edit Approval Forms (HR use)'] && (
            <Tab
              ref={(el) => (headerRefs.current[1] = el)}
              label="Approval templates"
              {...TabProps(1)}
            />
          )}
          {userPermissions?.['Approval Forms']?.['Manage Approval Managers (HR use)'] && (
            <Tab
              ref={(el) => (headerRefs.current[2] = el)}
              label="Approval managers"
              {...TabProps(2)}
            />
          )}
          {userPermissions?.['Approval Forms']?.['Manage Public Link'] && (
            <Tab
              ref={(el) => (headerRefs.current[3] = el)}
              label="Approval public links"
              {...TabProps(3)}
            />
          )}
          {(userPermissions?.['Approval Forms']?.['Archive Approval Forms (HR use)'] ||
            userPermissions?.['Approval Forms']?.['Archive Approval Forms']) && (
            <Tab
              ref={(el) => (headerRefs.current[4] = el)}
              label="Archived approvals/templates"
              {...TabProps(4)}
            />
          )}
        </Tabs>
      </Box>
      <Box>
        <TabPanel tabValue={tabValue} index={0}>
          <ApprovalFormsTab apiKey={apiKey} userPermissions={userPermissions} archived={false} />
        </TabPanel>
        <TabPanel tabValue={tabValue} index={1}>
          <ApprovalTemplates
            apiKey={apiKey}
            userPermissions={userPermissions}
            archived={false}
            setTemplateArchived={setTemplateArchived}
            enableEmailTemplateFields={enableEmailTemplateFields}
          />
        </TabPanel>
        <TabPanel tabValue={tabValue} index={2}>
          <ApprovalManagers
            apiKey={apiKey}
            userPermissions={userPermissions}
            isApprovalManagerDialogOpen={isApprovalManagerDialogOpen}
            setIsApprovalManagerDialogOpen={setIsApprovalManagerDialogOpen}
          />
        </TabPanel>
        <TabPanel tabValue={tabValue} index={3}>
          <ApprovalPublicLinks
            apiKey={apiKey}
            userPermissions={userPermissions}
            isPublicLinkDialogOpen={isPublicLinkDialogOpen}
            setIsPublicLinkDialogOpen={setIsPublicLinkDialogOpen}
          />
        </TabPanel>
        <TabPanel tabValue={tabValue} index={4}>
          <ArchivedFormsAndTemplates
            apiKey={apiKey}
            userPermissions={userPermissions}
            setTemplateArchived={setTemplateArchived}
          />
        </TabPanel>
        <StyledSnackbar
          message={snackbar.message}
          state={snackbar.state}
          setSnackbarState={setSnackbar}
        />
      </Box>
      <CreateNewApproval
        apiKey={apiKey}
        newApprovalOptions={newApprovalOptions}
        setNewApprovalOptions={setNewApprovalOptions}
        isDialogOpen={newApprovalFormModal}
        setIsDialogOpen={setNewApprovalFormModal}
        setSnackbar={setSnackbar}
        templateArchived={templateArchived}
        setTemplateArchived={setTemplateArchived}
      />
      {newApprovalTemplateModal && (
        <ApprovalTemplateSettings
          modalsOpen={newApprovalTemplateModal}
          entityDefaultApprovers={entityDefaultApprovers}
          setModalsOpen={() => setNewApprovalTemplateModal(false)}
          setSnackbar={setSnackbar}
          enableEmailTemplateFields={enableEmailTemplateFields}
        />
      )}
    </Box>
  );
}
