import React from 'react';
import { Box, Stack } from '@mui/material';
import { styles } from '../styles';
import dompurify from 'dompurify';
import { IAnswer } from '../types';
import AvailabilityAnswer from './AvailabilityAnswer';

function parseAnswers(answer: string) {
  const answers = answer.split(', ');
  if (answers.length === 1 && answers[0].includes('and')) {
    return answers[0].split(' and ');
  }
  if (answers.length > 1) {
    answers[answers.length - 1] = answers[answers.length - 1].split('and ')[1];
  }

  return answers;
}

const host = window.location.host;

export default function AnswersView({ answer }: { answer: IAnswer }) {
  switch (answer.type) {
    case 'RadioButtonField':
    case 'SelectField':
    case 'TextField':
    case 'TextAreaField':
      return (
        <Box sx={{ padding: 2 }}>
          <Box
            sx={styles.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(answer.question) }}
          />
          <Box>
            <Stack sx={{ overflowWrap: 'anywhere' }}>{answer.answer || '-'}</Stack>
          </Box>
        </Box>
      );
    case 'DateField': {
      let date = '-';
      if (answer.answer) {
        const [year, month, day] = answer.answer?.split('-');
        date = host.includes('scout') ? `${month}-${day}-${year}` : `${day}-${month}-${year}`;
      }
      return (
        <Box sx={{ padding: 2 }}>
          <Box
            sx={styles.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(answer.question) }}
          />
          <Box>
            <Stack>{date}</Stack>
          </Box>
        </Box>
      );
    }
    case 'CheckBoxField': {
      const answers = parseAnswers(answer.answer);
      return (
        <Box sx={{ padding: 2 }}>
          <Box
            sx={styles.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(answer.question) }}
          />
          {answers.map((answer, i) => {
            return (
              <Box key={i} sx={{ paddingBottom: 1 }}>
                <Stack>{answer}</Stack>
              </Box>
            );
          })}
        </Box>
      );
    }
    case 'RankField': {
      const answers = answer.answer ? answer.answer.split('; ') : [];
      return (
        <Box sx={{ padding: 2 }}>
          <Box
            sx={styles.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(answer.question) }}
          />
          <Box sx={{ paddingBottom: 1 }}>
            {answers.length > 0 ? (
              answers.map((ans, i) => <Stack key={i}>{ans.replace(')', '.')}</Stack>)
            ) : (
              <Stack>-</Stack>
            )}
          </Box>
        </Box>
      );
    }
    case 'CsvDataLookupField':
      return (
        <Box sx={{ padding: 2 }}>
          <Box
            sx={styles.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(answer.question) }}
          />
          <Box>
            <Stack sx={{ textTransform: 'capitalize', marginTop: 1 }}>{answer.answer[0]}</Stack>
          </Box>
          <Box>
            <Stack sx={{ fontWeight: 'bold', marginTop: 2 }}>Position ID</Stack>
          </Box>
          <Box>
            <Stack sx={{ marginTop: 2 }}>{answer.answer[1]}</Stack>
          </Box>
        </Box>
      );
    case 'AvailabilityField': {
      return (
        <Box sx={{ padding: 2 }}>
          <Box
            sx={styles.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(answer.question) }}
          />
          <AvailabilityAnswer
            availabilityTableData={answer.answer}
            config={answer.config}
            shift={answer.shift}
            totalTime={answer.total_availability_time}
          />
        </Box>
      );
    }
    case 'JobReferenceLookupField':
      return null;
    default:
      return (
        <Box sx={{ padding: 2 }}>
          <Box
            sx={styles.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(answer.question) }}
          />
          Invalid question type
        </Box>
      );
  }
}
