import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import GenericDialog from '../../Components/Modals/GenericDialog';
import FilePreviewModal from '../../Components/Modals/FilePreviewModal';
import DocTypeIcon from '../../Components/Utilities/DocTypeIcon';
import AttachmentSelector from '../../Components/Utilities/AttachmentSelector';
import { IJobAttachment } from '../types';
import { classes } from './styles';
import Api from '../API';

export default function Attachments({
  apiKey,
  jobId,
  permissions
}: {
  apiKey: string;
  jobId: number;
  permissions: Record<string, boolean>;
}) {
  const BASE_URL = window.location.origin;
  const [attachments, setAttachments] = useState([]);
  const [totalAttachments, setTotalAttachments] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [copied, setCopied] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<IJobAttachment | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteAttachment = async (url: string, attachmentId: number) => {
    setIsDeleting(true);
    try {
      await Api.deleteJobAttachment({ 'X-api-authenticate': apiKey }, jobId, attachmentId);
      fetchAttachments(currentPage, rowsPerPage);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
      setIsDialogOpen(false);
    }
  };

  const [dialogProps, setDialogProps] = useState({
    url: '',
    title: 'Permanently delete attachment?',
    description: '',
    buttonText: 'Delete',
    buttonCallback: deleteAttachment,
    callbackLoading: false,
    dialogId: 0
  });

  const fetchInitialAttachments = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await Api.getJobAttachments({ 'X-api-authenticate': apiKey }, jobId, {});
      setAttachments(response.data.job_attachments);
      setTotalAttachments(response.totalItems);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, jobId]);

  const fetchAttachments = async (page: number, rows: number) => {
    setIsLoading(true);
    try {
      const response = await Api.getJobAttachments({ 'X-api-authenticate': apiKey }, jobId, {
        page: page,
        limit: rows
      });
      setAttachments(response.data.job_attachments);
      setTotalAttachments(response.totalItems);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowsPerPageChange = (selection: number) => {
    if (selection === rowsPerPage) return;
    setRowsPerPage(selection);
    fetchAttachments(currentPage, selection);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage === currentPage) return;
    setCurrentPage(newPage);
    fetchAttachments(newPage, rowsPerPage);
  };

  const handleCopy = (attachmentId: number) => {
    setCopied((prev) => ({ ...prev, [attachmentId]: true }));
    setTimeout(() => setCopied((prev) => ({ ...prev, [attachmentId]: false })), 3000);
  };

  const handleDeleteAttachment = (attachmentId: number, attachmentName: string) => {
    setDialogProps((prev) => ({
      ...prev,
      url: `/api/v1/jobs/${jobId}/attachments/${attachmentId}`,
      description: `Are you sure you want to delete ${attachmentName}? This action cannot be undone.`,
      dialogId: attachmentId,
      buttonCallback: deleteAttachment
    }));
    setIsDialogOpen(true);
  };

  const handleSelectAttachment = (attachment: IJobAttachment) => {
    setSelectedAttachment(attachment);
    setPreviewOpen(true);
  };

  useEffect(() => {
    fetchInitialAttachments();
  }, [fetchInitialAttachments]);

  return (
    <Box>
      <AttachmentSelector
        performUpload={true}
        apiKey={apiKey}
        uploadUrl={`${BASE_URL}/api/v4/jobs/${jobId}/job_attachments`}
        onUploadComplete={() => fetchAttachments(currentPage, rowsPerPage)}
        maxSize={50000000}
        data-testid="job-attachments-selector"
      />
      <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
        <Table sx={classes.tableWrapper} aria-label="job attachments">
          <TableHead>
            <TableRow>
              <TableCell>File</TableCell>
              <TableCell>Public link</TableCell>
              <TableCell>Attached by</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attachments &&
              !isLoading &&
              attachments.map((attachment: IJobAttachment) => (
                <TableRow key={attachment.id}>
                  <TableCell>
                    <Box
                      onClick={() => handleSelectAttachment(attachment)}
                      sx={classes.fileIconContainer}
                    >
                      <Box sx={classes.fileIcon}>
                        {DocTypeIcon(attachment.attached_file_name.split('.').pop())}
                      </Box>
                      <Box>
                        <Box
                          sx={classes.fileName}
                          className="attachment-file-name"
                          data-testid="attachment-file-name"
                        >
                          {attachment.attached_file_name}
                        </Box>
                        <Box sx={classes.fileDate}>
                          {moment(attachment.created_at).format('Do MMMM YYYY[,] h:mma')}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={classes.fileLinkContainer}>
                      <a href={attachment.public_link} target="_blank" rel="noopener noreferrer">
                        <Box>{attachment.public_link}</Box>
                      </a>
                      <CopyToClipboard
                        text={attachment.public_link}
                        onCopy={() => handleCopy(attachment.id)}
                      >
                        <Button sx={classes.copyButton}>
                          {copied[attachment.id as keyof typeof copied] ? 'Copied!' : 'Copy'}
                        </Button>
                      </CopyToClipboard>
                    </Box>
                  </TableCell>
                  <TableCell sx={classes.fileAttachedBy}>{attachment.attached_by}</TableCell>
                  <TableCell>
                    {permissions.delete && (
                      <IconButton
                        onClick={() =>
                          handleDeleteAttachment(attachment.id, attachment.attached_file_name)
                        }
                        className="delete-job-attachment-button"
                        data-testid="delete-job-attachment-button"
                      >
                        <DeleteIcon sx={{ color: '#D6827D' }} />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!isLoading && attachments?.length === 0 && (
          <Box sx={classes.noAttachments} className="no-attachments" data-testid="no-attachments">
            No attachments added
          </Box>
        )}
        {isLoading && (
          <>
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
          </>
        )}
      </TableContainer>
      <Box sx={classes.paginationParent}>
        <Box sx={classes.rowSelectContainer}>
          <span>Rows per page: </span>
          <FormControl>
            <Select
              id="rows-per-page"
              sx={classes.rowSelect}
              value={rowsPerPage}
              onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
              displayEmpty
              MenuProps={{ sx: classes.paginationMenuItems }}
            >
              <MenuItem id="first-rpp-item" value={10}>
                10
              </MenuItem>
              <MenuItem id="second-rpp-item" value={20}>
                20
              </MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={classes.paginationContainer}>
          <Pagination
            id="pagination-menu"
            count={totalPages}
            page={currentPage}
            siblingCount={0}
            onChange={(event, page) => handlePageChange(event, page)}
            sx={classes.pagination}
          />
          <span id="total-jobs">{totalAttachments} Total</span>
        </Box>
      </Box>
      <FilePreviewModal
        previewOpen={previewOpen}
        setPreviewOpen={setPreviewOpen}
        selectedAttachment={selectedAttachment}
        isShareable={true}
      />
      <GenericDialog
        url={dialogProps.url}
        title={dialogProps.title}
        description={dialogProps.description}
        buttonText={dialogProps.buttonText}
        buttonCallback={dialogProps.buttonCallback}
        callbackLoading={isDeleting}
        isDialogOpen={isDialogOpen}
        setDialogOpen={setIsDialogOpen}
        dialogId={dialogProps.dialogId}
      />
    </Box>
  );
}
