var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Api from "../../lib/Api";
import KpiCard from "./KpiCard";
import { Button, Grid, Box } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import KpiSettings from "./KpiSettings";
import { classes } from "./styles";
function KpiDashboard() {
  const [kpiCategories, setCategories] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  function getDashboard() {
    return __async(this, null, function* () {
      setCategories(null);
      const response = yield Api.getDashboard();
      setCategories(response);
    });
  }
  useEffect(() => {
    getDashboard();
  }, []);
  if (!kpiCategories) return /* @__PURE__ */ React.createElement(Box, { mb: 4 }, /* @__PURE__ */ React.createElement(
    ReactLoading,
    {
      type: "cylon",
      color: "blue"
    }
  ));
  return /* @__PURE__ */ React.createElement(Box, { sx: classes.root }, /* @__PURE__ */ React.createElement(Box, { sx: classes.manageMetrics }, /* @__PURE__ */ React.createElement(
    Button,
    {
      sx: classes.button,
      variant: "contained",
      startIcon: /* @__PURE__ */ React.createElement(SettingsIcon, null),
      onClick: () => setSettingsOpen(true)
    },
    "Manage Metrics"
  )), kpiCategories.map(({ title, cards }) => /* @__PURE__ */ React.createElement(Box, { mb: 4, key: title }, /* @__PURE__ */ React.createElement(Box, { component: "h2", sx: classes.category }, title), /* @__PURE__ */ React.createElement(Grid, { container: true, spacing: 2 }, cards.map((cardProps) => /* @__PURE__ */ React.createElement(Grid, { item: true, key: cardProps.description }, /* @__PURE__ */ React.createElement(KpiCard, __spreadValues({}, cardProps))))))), /* @__PURE__ */ React.createElement(
    KpiSettings,
    {
      open: settingsOpen,
      setOpen: setSettingsOpen,
      getDashboard
    }
  ));
}
export default KpiDashboard;
