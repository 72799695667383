import React, { useState } from 'react';
import { Button } from '@mui/material';
import StyledSnackbar from '../NewUI/Components/CustomUIElements/StyledSnackbar';
import XrefDialogue from './XrefDialogue';
import { ButtonProps } from './types';

const XrefDialogueButton = (props: ButtonProps) => {
  const [open, setOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    state: 'success',
    message: ''
  });

  return (
    <>
      <Button onClick={() => setOpen(true)}>Unusual Activity</Button>
      <XrefDialogue
        open={open}
        handleClose={() => setOpen(false)}
        setSnackbarState={setSnackbarState}
        {...props}
      />
      <StyledSnackbar
        state={snackbarState.state}
        setSnackbarState={setSnackbarState}
        message={snackbarState.message}
      />
    </>
  );
};

export default XrefDialogueButton;
