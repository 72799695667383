import React, { useState } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Stack,
  Divider
} from '@mui/material';
import { IAvailabilityConfig } from '../../../Components/Utilities/QuestionFieldViewer';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import FormSelectField from '../../../Components/CustomUIElements/FormSelectField';
import { classes } from '../styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { IAvailabilityTableData } from '../../types';

const DayRow = ({
  payrollDay,
  day,
  allowBreak,
  onChange,
  data,
  disabled = false
}: {
  payrollDay: number;
  day: string;
  allowBreak: boolean;
  onChange: any;
  data: AvailabilityPreviewData;
  disabled?: boolean;
}) => {
  const [fromTime, setFromTime] = React.useState<Dayjs | null>(
    dayjs(data?.from_time || '00:00', 'HH:mm')
  );
  const [toTime, setToTime] = React.useState<Dayjs | null>(
    dayjs(data?.to_time || '00:00', 'HH:mm')
  );

  dayjs.extend(duration);

  const calculateTotalTime = ({
    from = fromTime,
    to = toTime,
    breakTime = data?.break_time
  }: {
    from?: Dayjs | null;
    to?: Dayjs | null;
    breakTime?: number;
  }) => {
    const shiftStart = dayjs(from);
    let shiftEnd = dayjs(to);

    // Check if the shift is overnight
    if (shiftEnd.isBefore(shiftStart)) {
      // If so, add 24 hours to the end time
      shiftEnd = shiftEnd.add(1, 'day');
    }
    const shiftTime = dayjs.duration(shiftEnd.diff(shiftStart)).format('HH:mm');
    const timeDiff = shiftTime.split(':');
    const totalTime = Number(timeDiff[0]) + Number(timeDiff[1]) / 60 - (breakTime || 0) / 60;
    return Math.round(totalTime * 100) / 100;
  };

  return (
    <TableRow>
      <TableCell sx={classes.availabilityTableBody}>
        <Stack alignItems="center">{payrollDay}</Stack>
      </TableCell>
      <TableCell sx={classes.availabilityTableBody}>
        <Stack alignItems="center">{day}</Stack>
      </TableCell>
      <TableCell sx={classes.availabilityTableBody}>
        <Stack alignItems="center">
          <FormTextField
            value={data?.heading_1}
            onChange={(event) => onChange({ ...data, heading_1: event.target.value })}
            styles={classes.tableTextfield}
            draggable={disabled}
          />
        </Stack>
      </TableCell>
      <TableCell sx={classes.availabilityTableBody}>
        <Stack alignItems="center">
          <FormTextField
            value={data?.heading_2}
            onChange={(event) => onChange({ ...data, heading_2: event.target.value })}
            styles={classes.tableTextfield}
            draggable={disabled}
          />
        </Stack>
      </TableCell>
      <TableCell sx={classes.availabilityTableBody}>
        <Stack flexDirection="row" justifyContent="space-around" columnGap="8px">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              views={['hours', 'minutes']}
              format="HH:mm"
              mask="__:__"
              value={dayjs(fromTime)}
              disableOpenPicker
              onChange={(newValue) => {
                const timeString = newValue?.format('HH:mm');
                setFromTime(dayjs(timeString, 'HH:mm'));
                const newData = {
                  ...data,
                  from_time:
                    timeString === 'Invalid Date'
                      ? dayjs('00:00', 'HH:mm').format('HH:mm')
                      : dayjs(timeString, 'HH:mm').format('HH:mm')
                };
                onChange({
                  ...newData,
                  total_time: calculateTotalTime({ from: dayjs(timeString, 'HH:mm') })
                });
              }}
              slotProps={{
                textField: {
                  InputProps: {
                    disableUnderline: true,
                    sx: {
                      width: '70px',
                      border: '1px solid #DDDDDD',
                      padding: '0px 8px',
                      margin: '2px 0px',
                      borderRadius: '6px',
                      '& .MuiInputBase-input': { padding: '6px', fontSize: '12px' }
                    }
                  },
                  variant: 'standard'
                }
              }}
              disabled={disabled}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              views={['hours', 'minutes']}
              format="HH:mm"
              mask="__:__"
              value={dayjs(toTime)}
              disableOpenPicker
              onChange={(newValue) => {
                const timeString = newValue?.format('HH:mm');
                setToTime(dayjs(timeString, 'HH:mm'));
                const newData = {
                  ...data,
                  to_time:
                    timeString === 'Invalid Date'
                      ? dayjs('00:00', 'HH:mm').format('HH:mm')
                      : dayjs(timeString, 'HH:mm').format('HH:mm')
                };
                onChange({
                  ...newData,
                  total_time: calculateTotalTime({ to: dayjs(timeString, 'HH:mm') })
                });
              }}
              disabled={disabled}
              slotProps={{
                textField: {
                  InputProps: {
                    disableUnderline: true,
                    sx: {
                      width: '70px',
                      border: '1px solid #DDDDDD',
                      padding: '0px 8px',
                      margin: '2px 0px',
                      borderRadius: '6px',
                      '& .MuiInputBase-input': { padding: '6px', fontSize: '12px' }
                    }
                  },
                  variant: 'standard'
                }
              }}
            />
          </LocalizationProvider>
        </Stack>
      </TableCell>
      {allowBreak && (
        <TableCell sx={classes.availabilityTableBody}>
          <Stack alignItems="center">
            <FormSelectField
              isDisabled={disabled}
              options={[
                { value: '0', label: '0 mins' },
                { value: '15', label: '15 mins' },
                { value: '30', label: '30 mins' },
                { value: '45', label: '45 mins' },
                { value: '60', label: '1 hour' }
              ]}
              defaultValue={data?.break_time?.toString()}
              styles={{
                minWidth: '80px',
                '& .MuiInputBase-input': { fontSize: '12px', margin: 'unset' },
                '& .MuiInputBase-input.MuiSelect-select': {
                  display: 'flex',
                  height: '18px',
                  alignItems: 'center'
                }
              }}
              onChange={(value) => {
                const newData = { ...data, break_time: Number(value) };
                onChange({
                  ...newData,
                  total_time: calculateTotalTime({ breakTime: Number(value) })
                });
              }}
            />
          </Stack>
        </TableCell>
      )}
      <TableCell sx={classes.availabilityTableBody}>
        <Stack alignItems="center">{data?.total_time}</Stack>
      </TableCell>
    </TableRow>
  );
};

type AvailabilityPreviewData = {
  heading_1: string;
  heading_2: string;
  from_time: string;
  to_time: string;
  break_time?: number;
  total_time: number;
};

export default function AvailabilityPreview({
  availabilityConfig,
  onChange,
  answer,
  disabled = false,
  error,
  innerRef,
  removePadding
}: {
  availabilityConfig: IAvailabilityConfig;
  onChange?: any;
  answer?: Record<string, IAvailabilityTableData>;
  disabled?: boolean;
  error?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  removePadding?: boolean;
}) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const weekDuration = availabilityConfig.week_duration === 'weekly' ? 7 : 14;

  const defaultState = Array.from({ length: weekDuration }, (_, index) => ({
    heading_1: '',
    heading_2: '',
    from_time: '00:00',
    to_time: '00:00',
    break_time: 0,
    total_time: 0
  })).reduce(
    (acc, val, index) => {
      acc[index + 1] = val;
      return acc;
    },
    {} as { [key: string]: AvailabilityPreviewData }
  );
  const calculateTotalShiftsAndHours = (newState: typeof defaultState | typeof answer) => {
    if (!newState) return { totalShifts: 0, totalHours: 0 };
    const totalShifts = Object.values(newState).filter((e) => e.total_time !== 0).length;
    const totalHours = Object.values(newState)
      .map((e) => e.total_time)
      .reduce((acc, val) => acc + val, 0);
    return { totalShifts, totalHours };
  };
  const [state, setState] = useState(answer || defaultState);
  const [totalShiftsAndHours, setTotalShiftsAndHours] = useState(
    calculateTotalShiftsAndHours(answer || defaultState)
  );

  const onRowChange = (index: number) => (value: AvailabilityPreviewData) => {
    const newState = { ...state };
    newState[index] = value;
    setTotalShiftsAndHours(calculateTotalShiftsAndHours(newState));
    setState(newState);
    onChange(newState);
  };

  const tableHeaderStyling = {
    ...classes.availabilityTableHeader,
    ...(removePadding && { padding: 'unset' })
  };

  return (
    <Box sx={{ padding: removePadding ? 0 : 1, overflow: 'auto' }} ref={innerRef}>
      <Table sx={{ backgroundColor: 'inherit', tableLayout: 'auto !important' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={tableHeaderStyling}>
              <Stack alignItems="center" minWidth="75px">
                Payroll Day
              </Stack>
            </TableCell>
            <TableCell sx={tableHeaderStyling}>
              <Stack alignItems="center">Day</Stack>
            </TableCell>
            <TableCell sx={tableHeaderStyling}>
              <Stack alignItems="center">{availabilityConfig.heading_1}</Stack>
            </TableCell>
            <TableCell sx={tableHeaderStyling}>
              <Stack alignItems="center">{availabilityConfig.heading_2}</Stack>
            </TableCell>
            <TableCell sx={tableHeaderStyling}>
              <Stack alignItems="center" height="14px">
                Shift times
              </Stack>
              <Stack flexDirection="row" justifyContent="space-around">
                <Stack fontSize="10px" height="16px">
                  From
                </Stack>
                <Stack fontSize="10px" height="16px">
                  To
                </Stack>
              </Stack>
            </TableCell>
            {availabilityConfig.allow_break && (
              <TableCell sx={tableHeaderStyling}>
                <Stack alignItems="center">Break</Stack>
              </TableCell>
            )}
            <TableCell sx={tableHeaderStyling}>
              <Stack alignItems="center" minWidth="75px">
                Paid Hours
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: weekDuration }, (_, index) => (
            <React.Fragment key={index}>
              {index === 7 && (
                <TableRow>
                  {Array(availabilityConfig.allow_break ? 7 : 6)
                    .fill(_)
                    .map((e, index) => (
                      <TableCell sx={classes.tableDivider} key={index}>
                        <Divider />
                      </TableCell>
                    ))}
                </TableRow>
              )}
              <DayRow
                payrollDay={index + 1}
                day={days[(index + availabilityConfig.week_start_day) % days.length].slice(0, 3)}
                allowBreak={availabilityConfig.allow_break}
                onChange={onRowChange(index + 1)}
                data={state[index + 1]}
                disabled={disabled}
              />
            </React.Fragment>
          ))}
          <TableRow>
            <TableCell sx={classes.availabilityTableBody} colSpan={4}>
              <Stack alignItems="center">TOTAL</Stack>
            </TableCell>
            <TableCell
              sx={classes.availabilityTableBody}
              colSpan={availabilityConfig.allow_break ? 2 : 1}
            >
              <Stack alignItems="center">{totalShiftsAndHours.totalShifts} Shifts</Stack>
            </TableCell>
            <TableCell sx={classes.availabilityTableBody}>
              <Stack alignItems="center">{totalShiftsAndHours.totalHours}</Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {error && <Box sx={{ ...sharedClasses.errorBoxCheckbox, marginLeft: '14px' }}>{error}</Box>}
    </Box>
  );
}
