"use strict";
import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    paddingBottom: 8,
    "& > div": {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      "&:first-child": {
        borderRight: "1px solid #EBF1F3",
        zIndex: 3
      }
    }
  },
  templateContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    "& > div": {
      fontSize: 16,
      padding: "16px 60px",
      "&:hover": {
        background: "#F4F8F9",
        cursor: "pointer",
        userSelect: "none"
      }
    }
  },
  noTemplates: {
    color: "#999",
    paddingRight: "60px",
    "& span": {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  noPreview: {
    margin: "auto",
    color: "#cdcdcd",
    fontWeight: 600,
    fontSize: "20px"
  },
  selected: {
    background: "#EBF1F3",
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  templateView: {
    overflowY: "scroll",
    padding: "10px 0px 0px 40px"
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "8px 0",
    "& svg": {
      color: theme.palette.primary.main
    }
  },
  formLabelWrapper: {
    marginTop: 27,
    marginBottom: 8,
    color: "#939393",
    fontSize: "14",
    display: "flex"
  },
  textEditor: {
    borderRadius: 6,
    border: "1px solid #DDDDDD",
    overflowY: "scroll"
  },
  textEditorToolbar: {
    position: "sticky",
    top: 0,
    display: "flex",
    flexDirection: "row",
    background: "#DDDDDD",
    padding: "10px 15px 6px",
    "& .rdw-inline-wrapper": {
      display: "flex",
      flexDirection: "row"
    },
    "& .rdw-list-wrapper": {
      display: "flex",
      flexDirection: "row"
    },
    "& .rdw-text-align-wrapper": {
      display: "flex",
      flexDirection: "row"
    },
    "& .rdw-link-wrapper": {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      "& .rdw-link-modal": {
        display: "grid",
        position: "absolute",
        background: "lightgrey",
        padding: "10px",
        marginTop: "1.75rem",
        "& label": {
          padding: "10px 0"
        },
        "& button": {
          marginRight: "1rem"
        },
        "& #openLinkInNewWindow": {
          marginRight: "0.5rem"
        }
      }
    },
    "& .rdw-image-wrapper": {
      flexDirection: "row",
      position: "relative",
      "& .rdw-image-modal": {
        display: "grid",
        position: "absolute",
        background: "lightgrey",
        padding: "10px",
        marginTop: "1.75rem",
        minWidth: 200,
        "& label": {
          padding: "10px 0"
        },
        "& button": {
          marginRight: "1rem"
        },
        "& .rdw-image-modal-url-input": {
          width: "calc(100% - 20px)"
        },
        "& .rdw-image-modal-size": {
          marginTop: 10,
          marginBottom: 10
        },
        "& .rdw-image-modal-size-input": {
          width: 60
        }
      }
    },
    "& .rdw-option-wrapper": {
      padding: "5px 5px 5px 5px",
      height: "1.75rem",
      backgroundColor: "transparent",
      cursor: "pointer"
    },
    "& .rdw-option-active ": {
      backgroundColor: "darkgrey"
    }
  },
  textEditorBody: {
    height: 300,
    padding: 20,
    "&:hover": {
      cursor: "text"
    },
    "& ul": {
      paddingLeft: 20
    },
    "& li": {
      listStyle: "disc"
    },
    "& .rdw-center-aligned-block *": {
      textAlign: "center !important"
    },
    "& .rdw-left-aligned-block *": {
      textAlign: "left !important"
    },
    "& .rdw-right-aligned-block *": {
      textAlign: "right !important"
    }
  }
}));
