import React, { Dispatch, useState, SetStateAction } from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styles } from '../styles';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import ApprovalTemplates from '../ApprovalTemplates/ApprovalTemplates';
import ApprovalFormsTab from '../ApprovalFormsTab';
import { IUserPermissions } from '../../Components/sharedTypes';

export default function ArchivedFormsAndTemplates({
  apiKey,
  userPermissions,
  setTemplateArchived
}: {
  apiKey: string;
  userPermissions: IUserPermissions | undefined;
  setTemplateArchived: Dispatch<SetStateAction<boolean>>;
}) {
  const [selected, setSelected] = useState(
    userPermissions?.['Approval Forms']?.['Archive Approval Forms']
      ? 'approvalForms'
      : 'approvalTemplates'
  );

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newSelected: string) => {
    if (newSelected !== null) {
      setSelected(newSelected);
    }
  };

  return (
    <Box>
      <Box sx={styles.automationContainer}>
        <Box sx={styles.toggleButtonsContainer}>
          <ToggleButtonGroup
            value={selected}
            exclusive
            onChange={handleToggle}
            aria-label="approval forms or templates"
          >
            {userPermissions?.['Approval Forms']?.['Archive Approval Forms'] && (
              <ToggleButton value="approvalForms" sx={sharedClasses.toggleButton}>
                Approval forms
              </ToggleButton>
            )}
            {userPermissions?.['Approval Forms']?.['Archive Approval Forms (HR use)'] && (
              <ToggleButton value="approvalTemplates" sx={sharedClasses.toggleButton}>
                Approval templates
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Box>
        {selected === 'approvalForms' ? (
          <ApprovalFormsTab apiKey={apiKey} userPermissions={userPermissions} archived={true} />
        ) : (
          <ApprovalTemplates
            apiKey={apiKey}
            userPermissions={userPermissions}
            archived={true}
            setTemplateArchived={setTemplateArchived}
          />
        )}
      </Box>
    </Box>
  );
}
