import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Modal,
  Button,
  Autocomplete,
  TextField,
  Skeleton,
  CircularProgress
} from '@mui/material';
import CancelButton from '../shared/CancelButton';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { IAutoSmsReplyProps } from '../../types';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { IApplicationStatus, ISMSTemplate } from '../../../Job/types';
import { stringToSeconds } from '../../../Components/Utilities/stringToSeconds';
import { secondsToString } from '../../../Components/Utilities/secondsToString';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getBodyFromSmsTemplateValue } from '../helpers/getBodyFromSmsTemplateValue';
import DelaySelectWrapper from '../shared/DelaySelectWrapper';

export default function NewAutoSMSReply({
  jobId,
  apiKey,
  modalOpen,
  setModalOpen,
  autoReplies,
  setAutoReplies,
  applicationStatuses,
  smsTemplates,
  eventTypes,
  isLoadingTemplateList,
  isLoadingEventTypes,
  replyIndex,
  setSnackbar
}: IAutoSmsReplyProps) {
  const [isSending, setIsSending] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [selectedEventType, setSelectedEventType] = useState<string | null>(null);
  const [delay, setDelay] = useState('None');
  const [selectedStatus, setSelectedStatus] = useState<IApplicationStatus | null>(null);
  const [body, setBody] = useState('');
  const [errors, setErrors] = useState({
    status: '',
    template: ''
  });
  const smsTemplateOptions = smsTemplates.map(
    (template: ISMSTemplate) => `${template.id} - ${template.title}`
  );

  const refObject = {
    status: useRef<HTMLDivElement>(null),
    template: useRef<HTMLDivElement>(null)
  };

  const handleSelectTemplate = useCallback((value: string) => {
    setSelectedTemplate(value || null);
    setBody(getBodyFromSmsTemplateValue(value, smsTemplates));
  }, [smsTemplates]);

  const handleSubmit = async () => {
    let errorRef = '';
    if (!selectedStatus) {
      setErrors((prev) => ({ ...prev, status: 'Please select a status' }));
      errorRef = errorRef || 'status';
    }
    if (!selectedTemplate) {
      setErrors((prev) => ({ ...prev, template: 'Please select a template' }));
      errorRef = errorRef || 'template';
    }
    if (errorRef) {
      refObject[errorRef as keyof typeof refObject].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      return;
    }
    try {
      setIsSending(true);
      if (replyIndex === -1) {
        const response = await fetch(`/api/v4/jobs/${jobId}/auto_sms_replies`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-api-authenticate': apiKey
          },
          body: JSON.stringify({
            trigger_status_id: selectedStatus?.id,
            sms_template_id: selectedTemplate?.split(' -')[0],
            event_type_id: selectedEventType ? selectedEventType.split(' -')[0] : null,
            delay: stringToSeconds(delay)
          })
        });
        const data = await response.json();
        if (response.ok) {
          setAutoReplies((prev) => [...prev, data]);
          setSnackbar({
            message: 'Auto reply created successfully',
            state: 'success'
          });
          setModalOpen(false);
        } else {
          throw new Error('Error creating new auto reply');
        }
      } else {
        const response = await fetch(
          `/api/v4/jobs/${jobId}/auto_sms_replies/${autoReplies[replyIndex].id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'X-api-authenticate': apiKey
            },
            body: JSON.stringify({
              trigger_status_id: selectedStatus?.id,
              sms_template_id: selectedTemplate?.split(' -')[0],
              delay: stringToSeconds(delay)
            })
          }
        );
        const data = await response.json();
        if (response.ok) {
          setAutoReplies((prev) => {
            const newReplies = [...prev];
            newReplies[replyIndex] = data;
            return newReplies;
          });
          setSnackbar({
            message: 'Auto reply updated successfully',
            state: 'success'
          });
          setModalOpen(false);
        } else {
          throw new Error('Error updating auto reply');
        }
      }
    } catch (error: any) {
      setSnackbar({
        message: error.message || 'Something went wrong, please try again',
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    setErrors({
      status: '',
      template: ''
    });
    if (replyIndex === -1) {
      setSelectedTemplate(null);
      setDelay('None');
      setSelectedStatus(null);
      setBody('');
      return;
    }
    setSelectedStatus(
      applicationStatuses.find(
        (status) => status.id === autoReplies[replyIndex].trigger_status_id
      ) || null
    );
    setDelay(secondsToString(autoReplies[replyIndex].delay));
  }, [applicationStatuses, autoReplies, replyIndex]);

  useEffect(() => {
    if (replyIndex !== -1) {
      const smsTemplate =
        smsTemplateOptions.find(
          (template) =>
            template.split(' - ')[0] === autoReplies[replyIndex].sms_template_id.toString()
        ) || null;
      setSelectedTemplate(smsTemplate);
      if (smsTemplate) {
        handleSelectTemplate(smsTemplate);
      }
    }
  }, [autoReplies, smsTemplates, replyIndex, handleSelectTemplate]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="new-auto-sms-reply-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={() => setModalOpen(false)} sx={classes.closeIcon} />
        <Box sx={classes.actionModalHeader}>{`${
          replyIndex === -1 ? 'New' : 'Edit'
        } auto SMS reply`}</Box>
        <Box
          sx={classes.modalContent}
          aria-label={`${replyIndex === -1 ? 'New' : 'Edit'} auto sms reply modal content`}
        >
          <Box
            role="form"
            id="auto-sms-reply-form"
            aria-label="Auto sms reply form"
            sx={{ height: '100%', overflowX: 'clip' }}
          >
            <Box
              sx={{ ...classes.actionModalFormLine, marginTop: '20px', marginBottom: '40px' }}
              ref={refObject.status}
            >
              <Autocomplete
                id="trigger-status-autocomplete"
                data-testid="trigger-status-autocomplete-autoreply"
                options={applicationStatuses}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  setSelectedStatus(value);
                  setErrors((prev) => ({ ...prev, status: '' }));
                }}
                value={selectedStatus}
                sx={{ ...sharedClasses.formAutocomplete, width: '287px' }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trigger status"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                renderOption={(props, option, state) => {
                  return (
                    <li {...props} key={option.id} data-testid={`option-${state.index}`}>
                      {option.name}
                    </li>
                  );
                }}
              />
              {errors.status && <Box sx={classes.formError}>{errors.status}</Box>}
            </Box>
            <Box>
              <Box sx={classes.actionModalFormLine} ref={refObject.template}>
                <Virtualize
                  value={selectedTemplate}
                  passOptions={smsTemplateOptions}
                  setValue={(value: string) => {
                    handleSelectTemplate(value);
                    setErrors((prev) => ({ ...prev, template: '' }));
                  }}
                  passedStyles={{
                    ...sharedClasses.formAutocomplete,
                    width: '600px',
                    marginTop: '20px'
                  }}
                  label="Template"
                  loading={isLoadingTemplateList}
                  loadingText="Loading template list..."
                />
                {errors.template && <Box sx={classes.formError}>{errors.template}</Box>}
              </Box>
              {(selectedTemplate || replyIndex !== -1) &&
                (replyIndex !== -1 && !selectedTemplate ? (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={400}
                    width={'96%'}
                    sx={classes.previewSkeleton}
                  />
                ) : (
                  <Box sx={{ ...classes.htmlContent }}>
                    <Box sx={{ ...classes.previewHeader }}>
                      <VisibilityIcon />
                      Preview
                    </Box>
                    <Box>{body}</Box>
                  </Box>
                ))}
            </Box>
            {body?.includes('{INTERVIEW_URL}') && (
              <Box sx={classes.actionModalFormLine} ref={refObject.template}>
                <Virtualize
                  value={selectedEventType}
                  passOptions={eventTypes.map((eventType) => `${eventType.id} - ${eventType.name}`)}
                  setValue={setSelectedEventType}
                  passedStyles={{
                    ...sharedClasses.formAutocomplete,
                    width: '600px',
                    marginTop: '20px'
                  }}
                  label='Automatically invite to event type'
                  loading={isLoadingEventTypes}
                  loadingText='Loading event types list...'
                />
                {errors.template && <Box sx={classes.formError}>{errors.template}</Box>}
              </Box>
            )}
            <DelaySelectWrapper delay={delay} setDelay={setDelay} />
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <CancelButton scope='auto-sms-reply' setModalOpen={setModalOpen} />
          <Button
            id="send-auto-reply-button"
            data-testid="send-auto-reply-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '71px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSending ? <CircularProgress size={20} color="inherit" /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
