import React from 'react';
import { styled, InputBase, Select, MenuItem } from '@mui/material';

const SelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: '24px'
  },
  '& .MuiInputBase-input': {
    borderRadius: '6px',
    backgroundColor: '#FFFFFF',
    color: '#333333',
    border: '1px solid #DDDDDD',
    fontSize: '18px',
    padding: '11px 20px 8px 20px',
    height: '42px',
    width: '100%',
    fontFamily: 'Source Sans Pro, sans-serif',
    textTransform: 'capitalize',
    '&:focus': {
      borderColor: '#2D388D'
    }
  },
  '& .MuiInputBase-input:focus': {
    backgroundColor: '#ffffff',
    borderRadius: '6px'
  },
  '& .MuiSvgIcon-root': {
    color: '#AAAAAA',
    marginRight: '10px',
    transition: 'all 0.16s ease-in-out'
  }
}));

const menuItemStyle = {
  fontFamily: 'Source Sans Pro, sans-serif',
  fontSize: '18px',
  padding: '8px 20px',
  color: '#333333',
  textTransform: 'capitalize'
};

const StyledDropdown = ({
  id,
  value,
  onChange,
  options
}: {
  id: string;
  value: string;
  onChange: (value: string) => void;
  options: string[];
}) => {
  return (
    <Select
      id={id}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      input={<SelectInput />}
    >
      {options.map((option) => (
        <MenuItem sx={menuItemStyle} value={option} key={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StyledDropdown;
