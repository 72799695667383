import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import API from '../API';
import { INoteProps, NoteState } from '../types';
import { classes } from './styles';
import { IError } from '../../SmartForms/types';
import { emojiInString } from '../../utils/emoji-checker';
import { NOTES_ERROR_MESSAGE } from '../../constants';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { Stack } from '@mui/material';

const defaultErrors = {
  note: '',
  subject: ''
};

const NotesPopover = ({
  anchorEl,
  applicationId,
  handleCloseNote,
  jobId,
  onSuccess,
  setApplications,
  setSnackbarState,
  noteState,
  newNoteOnApplicationEnabled
}: INoteProps) => {
  const [note, setNote] = useState('');
  const [noteSubject, setNoteSubject] = useState('');
  const isNotePad = noteState === NoteState.NOTEPAD;

  const [notesError, setNotesError] = useState<{ note: string; subject: string }>(
    Object.create(defaultErrors)
  );

  const validateInputs = () => {
    setNotesError(defaultErrors);
    if (emojiInString(note) || emojiInString(noteSubject)) {
      setNotesError((prev) => ({
        ...prev,
        ...(emojiInString(note) && { note: NOTES_ERROR_MESSAGE }),
        ...(emojiInString(noteSubject) && { subject: NOTES_ERROR_MESSAGE })
      }));
      return true;
    }
    return false;
  };

  const { mutate: saveNote, isLoading: savingNote } = useMutation({
    mutationFn: async (): Promise<void> => {
      if (!noteState) return;
      switch (noteState) {
        case NoteState.PERMANENT_RECORD:
          await API.updateNotes(jobId, applicationId, {
            note: { body: note, subject: noteSubject }
          }).then((newNote) => {
            if (newNote) {
              setApplications((prevApplications) => {
                const newApplications = [...prevApplications];
                const applicationIndex = newApplications.findIndex(
                  (application) => application.id === applicationId
                );
                newApplications[applicationIndex].notes = [
                  newNote,
                  ...newApplications[applicationIndex].notes
                ];
                return newApplications;
              });
            }
          });
          break;
        case NoteState.NOTEPAD:
          await API.updateNote(jobId, applicationId, { suitability_comment: note }).then(() => {
            setApplications((prevApplications) => {
              const newApplications = [...prevApplications];
              const applicationIndex = newApplications.findIndex(
                (application) => application.id === applicationId
              );
              newApplications[applicationIndex].suitability_comment = note;
              return newApplications;
            });
          });
      }
    },
    onError: (error: IError) => {
      const errorMessage = error.errors ? error.errors.join('. ') : error;

      if (!isNotePad) {
        setNote('');
        setNoteSubject('');
      }
      setSnackbarState({
        message: `Error ${isNotePad ? 'adding' : 'saving'} note, ${errorMessage}`,
        state: 'error'
      });
    },
    onSuccess: () => {
      setNotesError(defaultErrors);
      setNote('');
      handleCloseNote();
      onSuccess();
      setSnackbarState({
        message: `Note was successfully ${isNotePad ? 'added' : 'saved'}`,
        state: 'success'
      });
    }
  });

  const closeNoteHandler = () => {
    setNotesError(defaultErrors);
    handleCloseNote();
  };

  useEffect(() => {
    if (!anchorEl) return;
    setNote('');
  }, [anchorEl]);

  return (
    <Popover
      id={anchorEl ? 'edit-note-popover' : undefined}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={closeNoteHandler}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Stack sx={classes.notesContainer} spacing={2}>
        <Box sx={classes.notesTitle}>
          {newNoteOnApplicationEnabled && !isNotePad ? 'Add permanent record' : 'Add note'}
        </Box>
        {!isNotePad && (
          <>
            <TextField
              id="note-input"
              sx={classes.noteInput}
              label="Subject"
              fullWidth
              variant="outlined"
              onChange={(e) => setNoteSubject(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              required
            />
            {!!notesError.subject && <Box sx={sharedClasses.errorText}>{notesError.subject}</Box>}
          </>
        )}
        <TextField
          id="note-input"
          sx={classes.noteInput}
          label="Note"
          multiline
          fullWidth
          maxRows={4}
          variant="outlined"
          onChange={(e) => setNote(e.target.value)}
          required
          InputLabelProps={{
            shrink: true
          }}
        />
        {!!notesError.note && <Box sx={sharedClasses.errorText}>{notesError.note}</Box>}
        <Box id="notes-button-container" sx={classes.notesButtonContainer}>
          <Button sx={classes.notesCancelButton} endIcon={<CloseIcon />} onClick={closeNoteHandler}>
            Cancel
          </Button>
          <Button
            sx={classes.notesEditSaveButton}
            endIcon={savingNote ? <CircularProgress size={16} /> : <DoneIcon />}
            onClick={() => {
              if (validateInputs()) return;
              saveNote();
            }}
            disabled={!note || savingNote}
          >
            {`${isNotePad ? 'Save' : 'Add'}`}
          </Button>
        </Box>
      </Stack>
    </Popover>
  );
};
export default NotesPopover;
