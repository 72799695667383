import React, { useState, useEffect, useMemo, Dispatch } from 'react';
import { Box, Button } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import DisplayResponseFooter from '../Footers/DisplayResponseFooter';
import { modalTitle } from '../config';
import HtmlEditor from '../../../NewUI/Components/Utilities/HtmlEditor';
import ConfettiExplosion from 'react-confetti-explosion';
import dompurify from 'dompurify';
import { AIStudioAction, AIStudioState } from '../reducer';
import { classes } from '../styles';
import { classes as sharedClasses } from '../../SharedComponents/styles';
import CopyableQuestions from '../../SharedComponents/CopyableQuestions';

const DisplayDescription = ({
  dispatch,
  aiStudioState,
  generateDescription,
  generateQuestions,
  jobFlow,
  setGeneratedContent,
  handleClose
}: {
  dispatch: Dispatch<AIStudioAction>;
  aiStudioState: AIStudioState;
  generateDescription: () => void;
  generateQuestions: () => void;
  jobFlow?: boolean;
  setGeneratedContent?: (description: string) => void;
  handleClose: () => void;
}) => {
  const [editableDescription, setEditableDescription] = useState(aiStudioState.generatedResponse);
  const [isEditing, setIsEditing] = useState(false);
  const [copied, setCopied] = useState(false);
  const [playConfetti, setPlayConfetti] = useState(true);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  useEffect(() => {
    setPlayConfetti(true);
    const confettiTimeout = setTimeout(() => {
      setPlayConfetti(false);
    }, 4100);
    return () => clearTimeout(confettiTimeout);
  }, []);

  const regenerationFunction = {
    description: generateDescription,
    questions: generateQuestions
  };

  const displayResponseContent = useMemo(() => {
    if (aiStudioState.generationType === 'description') {
      return (
        <Box sx={{ position: 'relative', width: '100%', height: '500px' }}>
          {isEditing ? (
            <HtmlEditor
              body={editableDescription}
              onChange={setEditableDescription}
              maxHeight={500}
            />
          ) : (
            <Box
              sx={classes.generatedResponseContainer}
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(editableDescription)
              }}
            />
          )}
          <Box
            sx={{
              ...sharedClasses.floatingActionButton,
              ...(window?.tinyMCE?.majorVersion === '4' && { display: 'none' })
            }}
            onClick={() => {
              setIsEditing((prev) => !prev);
            }}
          >
            {isEditing ? <DoneIcon /> : <EditIcon />}
          </Box>
        </Box>
      );
    } else {
      return <CopyableQuestions questions={aiStudioState.generatedResponse} maxHeight="490px" />;
    }
  }, [
    aiStudioState.generatedResponse,
    aiStudioState.generationType,
    editableDescription,
    isEditing
  ]);

  return (
    <Box sx={classes.generatedContainer}>
      {playConfetti && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <ConfettiExplosion
            force={0.5}
            duration={4000}
            particleCount={120}
            height={1000}
            width={1000}
          />
        </Box>
      )}
      <Box sx={classes.descriptionHeader}>
        <Box sx={classes.descriptionHeaderText}>
          {`${aiStudioState.aiStudioFrenchEnabled ? 'Ton' : 'Your'} ${
            modalTitle[aiStudioState.aiStudioFrenchEnabled ? 'french' : 'english'][
              aiStudioState.generationType
            ]
          }`}
        </Box>
        <Button
          id={`regenerate-${aiStudioState.generationType}`}
          sx={classes.pill}
          endIcon={<CachedIcon />}
          onClick={
            regenerationFunction[aiStudioState.generationType as keyof typeof regenerationFunction]
          }
        >
          {aiStudioState.aiStudioFrenchEnabled ? 'Regénérer' : 'Regenerate'}
        </Button>
      </Box>
      {displayResponseContent}
      <DisplayResponseFooter
        editableDescription={editableDescription}
        aiStudioState={aiStudioState}
        dispatch={dispatch}
        handleCopy={handleCopy}
        copied={copied}
        jobFlow={jobFlow}
        setGeneratedContent={setGeneratedContent}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default DisplayDescription;
