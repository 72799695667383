"use strict";
export const approvalTemplateFormOptions = [
  {
    id: "hide_position_title",
    name: "Hide Position Title",
    tip: "Ticking this box will hide the Job 'Position Title' field when viewing or editing an Approval Form which has been created from this template."
  },
  {
    id: "hide_approval_administrator",
    name: "Hide approval administrator",
    tip: "The Approval administrator receives a notification email when all approvers have approved or declined the approval form. You will not be able to select an Approval Administrator if \u2018Hide Approval Administrator\u2019 is ticked. "
  },
  {
    id: "hide_assigned_user",
    name: "Hide assigned user",
    tip: "The Assigned user will be shown within an Assigned user column on the approvals homepage. This column will not display if 'hide assigned user' is selected"
  },
  {
    id: "enable_approval_template_for_applications",
    name: "Enable Approval Template for Applications"
  },
  {
    id: "hide_email_template",
    name: "Hide email template selection from approval form"
  }
];
export const optionsBooleanValues = (options) => {
  if (!options) return;
  return {
    hide_position_title: Number(options.hide_position_title) === 1 ? true : false,
    hide_approval_administrator: Number(options.hide_approval_administrator) === 1 ? true : false,
    hide_assigned_user: Number(options.hide_assigned_user) === 1 ? true : false,
    enable_approval_template_for_applications: Number(options.enable_approval_template_for_applications) === 1 ? true : false,
    hide_email_template: Number(options.hide_email_template) === 1 ? true : false,
    enable_declaration_of_conflict_of_interest: Number(options.enable_declaration_of_conflict_of_interest) === 1 ? true : false
  };
};
export const optionsNumericValues = (options) => {
  return {
    hide_position_title: (options == null ? void 0 : options.hide_position_title) ? 1 : 0,
    hide_approval_administrator: (options == null ? void 0 : options.hide_approval_administrator) ? 1 : 0,
    hide_assigned_user: (options == null ? void 0 : options.hide_assigned_user) ? 1 : 0,
    enable_approval_template_for_applications: (options == null ? void 0 : options.enable_approval_template_for_applications) ? 1 : 0,
    hide_email_template: (options == null ? void 0 : options.hide_email_template) ? 1 : 0,
    enable_declaration_of_conflict_of_interest: (options == null ? void 0 : options.enable_declaration_of_conflict_of_interest) ? 1 : 0
  };
};
export const defaultFilters = {
  state: [],
  entity_ids: [],
  assigned_user_ids: [],
  requested_by_users: []
};
export const dropdownOptions = [
  {
    name: "created_at",
    label: "Created At"
  },
  {
    name: "updated_at",
    label: "Updated At"
  },
  {
    name: "requested_by",
    label: "Requested By"
  },
  {
    name: "state",
    label: "State"
  },
  {
    name: "entity",
    label: "Entity"
  },
  {
    name: "form_template",
    label: "Form Template"
  },
  {
    name: "assigned_user",
    label: "Assigned User"
  },
  {
    name: "note",
    label: "Notes"
  }
];
export const defaultFilterOptions = {
  state: [
    {
      value: "pending",
      name: "Pending"
    },
    {
      value: "approved",
      name: "Approved"
    },
    {
      value: "draft",
      name: "Draft"
    },
    {
      value: "declined",
      name: "Declined"
    }
  ]
};
export const sortableColumnsDefaults = [
  {
    id: "id",
    label: "ID",
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: "position_title",
    label: "Title",
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: "created_at",
    label: "Created At",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: "updated_at",
    label: "Updated At",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: "requested_by",
    label: "Requested By",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: "state",
    label: "State",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: "entity",
    label: "Entity",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: "form_template",
    label: "Form Template",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: "assigned_user",
    label: "Assigned User",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: "note",
    label: "Notes",
    sortable: false,
    canToggle: true,
    enabled: false
  }
];
export const settingTabs = [
  "Approval template title",
  "Default approvers",
  "Advanced",
  "Approval form title"
];
export const approvalManagersTableHeaders = [
  {
    name: "name",
    label: "Name",
    sortable: true
  },
  {
    name: "email",
    label: "Email",
    sortable: true
  }
];
export const approvalPublicLinkHeaders = [
  {
    name: "name",
    label: "Name",
    sortable: true
  },
  {
    name: "public_link",
    label: "Public Links",
    sortable: false
  },
  {
    name: "approval_template_name",
    label: "Approval Template Name",
    sortable: true
  },
  {
    name: "created_by",
    label: "Created By",
    sortable: true
  }
];
