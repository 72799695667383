import React, { useState, useEffect, useCallback, useRef, FC } from 'react';
import Box from '@mui/material/Box';
import Lottie from 'react-lottie';
import chartLoader from './chart-loader.json';
import DatePicker from './DatePicker';
import moment from 'moment';
import { IChartData } from '../../Components/Charts/types';
import { classes } from './styles';
import { IStyle } from '../types';
import Api from '../API';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default function ChartWidget({
  Chart,
  ChartIcon,
  chartTitle,
  url,
  apiKey
}: {
  Chart: ({ data }: { data: IChartData }) => JSX.Element;
  ChartIcon: ({ sx }: { sx: CSSProperties }) => JSX.Element;
  chartTitle: string;
  url: string;
  apiKey: string;
}) {
  const [data, setData] = useState<IChartData>();
  const [dateFrom, setDateFrom] = useState<Date>(moment().subtract(30, 'days').toDate());
  const [dateTo, setDateTo] = useState<Date>(moment().toDate());
  const [dateError, setDateError] = useState(false);
  const [loading, setLoading] = useState(true);

  const chartAnimation = {
    loop: true,
    autoplay: true,
    animationData: chartLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const fetchData = useCallback(
    async (fromDate: Date, toDate: Date) => {
      setLoading(true);
      try {
        const response = await Api.getGraphData(
          url,
          { 'X-api-authenticate': apiKey },
          {
            start_date: moment(fromDate).format('YYYY-MM-DD'),
            end_date: moment(toDate).format('YYYY-MM-DD')
          }
        );
        setData(response.dataset);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [apiKey, url]
  );

  useEffect(() => {
    fetchData(moment().subtract(30, 'days').toDate(), moment().toDate());
  }, [fetchData]);

  const handleDateChange = (type: 'from' | 'to', date: Date | null) => {
    if (date) {
      if (type === 'from') {
        setDateFrom(date);
      } else {
        setDateTo(date);
      }
    }
    const diff = moment(type === 'to' ? date : dateTo).diff(
      moment(type === 'from' ? date : dateFrom),
      'days'
    );
    if (diff > 0 && diff < 366) {
      setDateError(false);
      fetchData(type === 'from' ? date : dateFrom, type === 'to' ? date : dateTo);
    } else {
      setDateError(true);
    }
  };

  return (
    <Box sx={classes.widget}>
      <Box sx={classes.titleAndDateContainer}>
        <Box sx={classes.title}>{chartTitle}</Box>
        <DatePicker dateFrom={dateFrom} dateTo={dateTo} handleDateChange={handleDateChange} />
        <Box sx={classes.error}>{dateError ? 'Invalid date range (365 days maximum)' : ''}</Box>
      </Box>
      <Box
        sx={
          loading
            ? { ...classes.loading, backdropFilter: 'blur(3px)', opacity: 1, zIndex: 1 }
            : classes.loading
        }
      >
        <Lottie options={chartAnimation} height={400} width={400} style={{ zIndex: 2 }} />
      </Box>
      {data?.data && data.data.length === 0 ? (
        <Box sx={classes.noDataContainer}>
          <ChartIcon sx={classes.noDataIcon} />
          <Box sx={classes.noData}>No data available for selected time range</Box>
        </Box>
      ) : (
        <Box sx={classes.chart}>
          <Chart data={data} />
        </Box>
      )}
    </Box>
  );
}
