import React from 'react';
import PropTypes from 'prop-types';

export default class SearchResultsPagination extends React.Component {
  static propTypes = {
    pagination: PropTypes.object,
    onClick: PropTypes.func
  }

  isEmpty() {
    const { pagination } = this.props;
    return pagination.pages && pagination.pages.length == 1
  }

  renderPreviousPage() {
    const { pagination } = this.props;

    if (!pagination.previous_page && this.isEmpty()) { return null }

    if (pagination.previous_page) {
      return this.renderActiveLink(pagination.previous_page, '← Previous', 'previous_page');
    } else {
      return(<span className='previous_page disabled'>← Previous</span>)
    }
  }

  renderNextPage() {
    const { pagination } = this.props;

    if (!pagination.next_page && this.isEmpty()) {
      return null
    }

    if (pagination.next_page) {
      return this.renderActiveLink(pagination.next_page, 'Next →', 'next_page')
    } else {
      return (<span className='next_page disabled'>Next →</span>)
    }

  }

  renderActiveLink(page, title, classNames = '') {
    return(<a key={ title } className={ classNames } href={ `#page=${page.title}` } onClick={ () => { this.props.onClick(page.url) }}>{ title }</a>);
  }

  isNothingToRender() {
    return this.props.pagination.pages.length === 1 && this.isEmpty()
  }

  renderFirstPage() {
    const { pagination } = this.props;

    if (this.isNothingToRender()) { return null }

    const firstPage = pagination.pages[0];

    if (pagination.previous_page) {
      return this.renderActiveLink(firstPage, 'First', 'next_page')
    } else {
      return (<span className='next_page disabled'>First</span>);
    }
  }

  renderLastPage() {
    const { pagination } = this.props;

    if (this.isNothingToRender()) { return null }

    const lastPage = pagination.pages.slice(-1)[0];

    if (pagination.next_page) {
      return this.renderActiveLink(lastPage, 'Last', 'next_page');
    } else {
      return (<span className='next_page disabled'>Last</span>);
    }
  }

  renderPages() {
    const { pagination, onClick } = this.props;
    const paginationSize = 6;

    const currentPage = pagination.pages.find(({ current }) => current);
    const previousPages = (currentPage.title >= paginationSize) ? currentPage.title - paginationSize : 0;
    const nextPages = currentPage.title + paginationSize - 1;

    if (!pagination.pages || this.isEmpty()) return null;

    return pagination.pages.slice(previousPages, nextPages).map((item, key) =>
      item.current
      ? <em key={ item.title } className='current'>{item.title}</em>
      : this.renderActiveLink(item, item.title)
    );
  }

  render() {
    return (
      <div className='pagination'>
        { this.renderFirstPage() }
        { this.renderPreviousPage() }
        { this.renderPages() }
        { this.renderNextPage() }
        { this.renderLastPage() }
      </div>
    );
  }
};
