"use strict";
import { theme } from "../../components/ThemeContext/ThemeObject";
export const classes = {
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  popover: {
    marginTop: "15px"
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)"
  },
  tableSettingsHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: 1,
    paddingLeft: "4px"
  },
  filterSwitch: {
    transform: "translateX(10px)",
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  filterSwitchActive: {
    transform: "translateX(10px)",
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  filterSwitchLabels: {
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "0px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  filterPopoverHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    backgroundColor: "white",
    position: "sticky",
    top: "0px",
    zIndex: 99,
    paddingTop: "26px",
    paddingBottom: "2px",
    boxShadow: "0px 6px 10px 7px #ffffff"
  },
  filterOptionContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    marginBottom: "12px"
  },
  filterButton: {
    border: "1px solid #DDDDDD",
    borderRadius: 1,
    width: "fit-content",
    padding: "8px 12px"
  },
  filterPopoverContent: {
    width: "272px",
    maxHeight: "500px",
    overflowY: "scroll",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  clearButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#939393",
    backgroundColor: "rgba(147, 147, 147, 0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "rgba(147, 147, 147, 0.24)",
      boxShadow: "none"
    },
    "& .MuiButton-endIcon svg": {
      fontSize: "10px"
    }
  },
  applyButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 23px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  ratingSlider: {
    fontFamily: "Source Sans Pro",
    color: theme.palette.action.main,
    height: "2px",
    "& .MuiSlider-rail": {
      backgroundColor: "#E0E0E0"
    },
    "& .MuiSlider-track": {
      backgroundColor: theme.palette.action.main
    },
    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.action.main,
      width: "8px",
      height: "8px",
      "&:hover": {
        boxShadow: "0px 0px 0px 5px" + theme.palette.action.main + "16"
      }
    },
    "& .MuiSlider-thumb.Mui-active": {
      boxShadow: "0px 0px 0px 7px" + theme.palette.action.main + "16"
    },
    "& .MuiSlider-valueLabel": {
      fontSize: "11px",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      top: "36px",
      "&::after": {
        display: "none"
      }
    }
  }
};
