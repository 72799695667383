import React, { Dispatch, SetStateAction } from 'react';
import { OutlinedInput } from '@mui/material';
import { classes } from '../styles';

const JobDescriptionInput = ({
  generationJobDescription,
  placeholder,
  onChange
}: {
  generationJobDescription: string;
  placeholder: string;
  onChange: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <OutlinedInput
      id="job-description"
      sx={{ ...classes.fieldInput, height: '144px' }}
      placeholder={placeholder}
      value={generationJobDescription}
      multiline
      rows={5}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default JobDescriptionInput;
