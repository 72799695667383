import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { IApprovalPublicLinkTableProps, IApprovalPublicLink } from '../ApprovalTemplates/types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment-timezone';
import { classes } from '../../Job/Applications/styles';
import { approvalPublicLinkHeaders } from '../config';
import Api from '../API';
import GenericDialog from '../../Components/Modals/GenericDialog';
import NewApprovalPublicLink from '../NewApprovalPublicLink/NewApprovalPublicLinkModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@mui/material/Tooltip';

const tablePadding = (density: string) => {
  if (density === 'Default') {
    return '22px';
  } else if (density === 'Compact') {
    return '15px';
  } else {
    return '8px';
  }
};

export default function ApprovalPublicLinksTable({
  approvalPublicLinks,
  setApprovalPublicLinks,
  getApprovalPublicLinks,
  globalSelected,
  setGlobalSelected,
  selectAllIsChecked,
  setSelectAllIsChecked,
  selectAllIsIndeterminate,
  setSelectAllIsIndeterminate,
  setSnackbarState,
  isLoading,
  setIsLoading,
  selected,
  setSelected,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalPages,
  totalApprovalPublicLinks,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  apiKey,
  userPermissions,
  density,
  isPublicLinkDialogOpen,
  setIsPublicLinkDialogOpen
}: IApprovalPublicLinkTableProps) {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedApprovalPublicLink, setSelectedApprovalPublicLink] = useState<{
    id: null | number;
  }>({ id: null });
  const [selectAllApprovalPublicLinks, setSelectAllApprovalPublicLinks] = useState<boolean>(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [tableHovered, setTableHovered] = useState(false);
  const [canScroll, setCanScroll] = useState([false, true]);
  const tableRef = useRef<HTMLDivElement>(null);
  const actionCellRef = useRef(null);
  const titleCellRef = useRef(null);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deleteApprovalPublicLinks, setDeleteApprovalPublicLinks] = useState([]);
  const [newApprovalOptions, setNewApprovalOptions] = useState<string[]>([]);
  const [editingApprovalPublicLink, setEditingApprovalPublicLink] =
    useState<IApprovalPublicLink | null>(null);
  const [copied, setCopied] = useState<number>(0);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newGlobalSelected = globalSelected.filter(
      (currentApp) => !approvalPublicLinks.map((app) => app.id).includes(currentApp.id)
    );
    if (event.target.checked) {
      const selectAllGlobalSelected = [...newGlobalSelected, ...approvalPublicLinks];
      setSelected(selectAllGlobalSelected.map((approvalPublicLink) => approvalPublicLink.id));
      setGlobalSelected(selectAllGlobalSelected);
      setSelectAllIsChecked(true);
      setSelectAllIsIndeterminate(false);
      setSelectAllApprovalPublicLinks(true);
      return;
    }
    setSelected(newGlobalSelected.map((approvalPublicLink) => approvalPublicLink.id));
    setGlobalSelected([]);
    setSelectAllIsChecked(false);
    setSelectAllIsIndeterminate(false);
    setSelectAllApprovalPublicLinks(false);
  };

  const clearAllSelection = () => {
    setSelectAllApprovalPublicLinks(false);
    setGlobalSelected([]);
    setSelected([]);
    setSelectAllIsChecked(false);
    setSelectAllIsIndeterminate(false);
  };

  const handleDeleteApprovalPublicLinks = async (ids: Array<number>) => {
    setIsLoading(true);
    try {
      const response = await Api.deleteApprovalPublicLinks(
        { 'X-api-authenticate': apiKey },
        { requisition_public_link_ids: ids }
      );

      if (response.res?.requisition_public_link_ids?.failure?.length) {
        const message =
          response.res.requisition_public_link_ids.success?.length > 0 ||
          response.res.requisition_public_link_ids.failure?.length > 1
            ? `${response.res.requisition_public_link_ids.failure?.length} ${
                response.res.requisition_public_link_ids.failure?.length > 1
                  ? 'approval public links were'
                  : 'approval public link was'
              } unable to be trashed.`
            : `${
                response.res.requisition_public_link_ids.failure?.length === 1
                  ? `Unable to trash approval public link.`
                  : ''
              }`;
        setSnackbarState({
          message: message,
          state: 'error'
        });
      } else {
        setSnackbarState({
          message: `Approval public ${
            ids.length > 1 ? 'links were' : 'link was'
          } successfully removed.`,
          state: 'success'
        });
      }
    } catch (error) {
      setSnackbarState({
        message: error?.errors,
        state: 'error'
      });
    } finally {
      setSelected([]);
      setGlobalSelected([]);
      setSelectedApprovalPublicLink({ id: null });
      setActionsAnchorEl(null);
      getApprovalPublicLinks();
    }
  };

  const handleSelectOne = (
    event: React.ChangeEvent<HTMLInputElement>,
    approvalPublicLink: IApprovalPublicLink
  ) => {
    let newGlobalSelected = [...globalSelected];
    event.target.checked
      ? newGlobalSelected.push(approvalPublicLink)
      : (newGlobalSelected = newGlobalSelected.filter((app) => app.id !== approvalPublicLink.id));
    const newSelected = newGlobalSelected.map((app) => app.id);
    setSelected(newSelected);
    setGlobalSelected(newGlobalSelected);
    setSelectAllIsChecked(
      approvalPublicLinks.map((app) => app.id).every((id) => newSelected.includes(id))
    );
    setSelectAllIsIndeterminate(
      approvalPublicLinks.map((app) => app.id).some((id) => newSelected.includes(id))
    );
  };

  const handleRowsPerPageChange = (selection: number) => {
    if (selection === rowsPerPage) return;
    setRowsPerPage(selection);
    getApprovalPublicLinks(null, selection, null, null, null);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage === currentPage) return;
    setCurrentPage(newPage);
    sessionStorage.setItem(`approvalPublicLinksTablePage`, newPage);
    getApprovalPublicLinks(newPage, null, null, null, null);
  };

  const handleSortChange = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    columnValue: string
  ) => {
    const isAsc = sortBy === columnValue && sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(columnValue);
    getApprovalPublicLinks(null, null, { sortBy: columnValue, sortOrder: newSortOrder });
  };

  const handleScroll = () => {
    if (tableRef.current.scrollLeft < 10) {
      setCanScroll([false, true]);
    } else if (
      tableRef.current.scrollLeft >
      tableRef.current.scrollWidth - tableRef.current.clientWidth - 10
    ) {
      setCanScroll([true, false]);
    } else {
      setCanScroll([true, true]);
    }
  };

  const handleScrollLeft = () => {
    tableRef.current.scrollLeft > 360
      ? (tableRef.current.scrollLeft -= 300)
      : (tableRef.current.scrollLeft = 0);
  };

  const handleScrollRight = () => {
    tableRef.current.scrollWidth - tableRef.current.clientWidth > 360
      ? (tableRef.current.scrollLeft += 300)
      : (tableRef.current.scrollLeft = tableRef.current.scrollWidth - tableRef.current.clientWidth);
  };

  function isScrollable(element: HTMLElement) {
    return element && element.scrollWidth > element.clientWidth + 20;
  }

  const handleScrollableEnter = () => {
    if (isScrollable(tableRef.current)) setTableHovered(true);
  };

  const handleScrollableExit = () => {
    setTableHovered(false);
  };

  const handleEditApprovalPublicLink = (publicLink: IApprovalPublicLink) => {
    setIsPublicLinkDialogOpen(true);
    setEditingApprovalPublicLink(publicLink);
  };

  const handleCopy = (id: number) => {
    setCopied(id);
    setTimeout(() => setCopied(0), 3000);
  };

  const headerStyle = (name: string) => {
    switch (name) {
      case 'name':
        return {
          ...classes.sticky,
          ...classes.scrollShadowLeft,
          ...(tableHovered && classes.scrollShadowVisible),
          minWidth: '200px',
          left: '58px'
        };
      case 'approval_template_name':
        return { minWidth: '210px' };
      case 'created_by':
        return { minWidth: '140px' };
      default:
        return { minWidth: '90px' };
    }
  };

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box sx={classes.arrowsContainer}>
          <Box
            onClick={handleScrollLeft}
            onMouseEnter={handleScrollableEnter}
            sx={{
              ...classes.leftArrow,
              ...(tableHovered && canScroll[0] && classes.showArrows),
              left: `${titleCellRef.current?.getBoundingClientRect().right}px`,
              top: 0
            }}
            id="left-arrow"
          >
            <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <Box sx={classes.arrowsContainer}>
          <Box
            onMouseEnter={handleScrollableEnter}
            onClick={handleScrollRight}
            sx={{
              ...classes.rightArrow,
              ...(tableHovered && canScroll[1] && classes.showArrows),
              left: `${actionCellRef.current?.getBoundingClientRect().left - 40}px`,
              top: 0
            }}
            id="right-arrow"
          >
            <KeyboardArrowRightIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <TableContainer
          sx={{ boxShadow: 'none', scrollBehavior: 'smooth' }}
          component={Paper}
          onMouseEnter={handleScrollableEnter}
          onMouseLeave={handleScrollableExit}
          ref={tableRef}
          onScroll={handleScroll}
        >
          <Table
            sx={{
              ...classes.tableWrapper,
              'th, td, tr, thead': { padding: `${tablePadding(density)} 10px` }
            }}
            aria-label="approval PublicLinks"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...classes.sticky, width: '58px' }}>
                  <Checkbox
                    id="select-all-checkbox"
                    sx={classes.checkbox}
                    indeterminate={!selectAllIsChecked && selectAllIsIndeterminate}
                    checked={selectAllIsChecked}
                    onChange={handleSelectAll}
                    inputProps={{
                      'aria-label': 'select all approval PublicLinks'
                    }}
                  />
                </TableCell>
                {approvalPublicLinkHeaders.map((header) => (
                  <TableCell
                    key={header.name}
                    sx={headerStyle(header.name)}
                    ref={header.name === 'name' ? titleCellRef : null}
                  >
                    <TableSortLabel
                      active={sortBy === header.name}
                      direction={sortBy === header.name ? (sortOrder as 'asc' | 'desc') : 'asc'}
                      onClick={(event) => handleSortChange(event, header.name)}
                      sx={header.sortable ? classes.sortable : null}
                      disabled={!header.sortable}
                      id={`${header.name}-sort-label-approval-public-links`}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    ...classes.attachmentsHeader,
                    ...classes.stickyRight,
                    ...classes.scrollShadowRight,
                    ...(tableHovered && classes.scrollShadowVisible)
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {approvalPublicLinks &&
                !isLoading &&
                approvalPublicLinks.map((approvalPublicLink: IApprovalPublicLink, index) => {
                  const updatedAt = approvalPublicLink.updated_at
                    ? moment
                        .tz(approvalPublicLink.updated_at, timeZoneString)
                        .format('DD MMM YYYY,-h:mma z')
                        .split('-')
                        .map((line) => <div key={`${approvalPublicLink.id}-${line}`}>{line}</div>)
                    : '-';
                  return (
                    <TableRow key={approvalPublicLink.id}>
                      <TableCell sx={{ ...classes.sticky, width: '58px' }}>
                        <Checkbox
                          id={`select-checkbox-${approvalPublicLink.id}`}
                          sx={classes.checkbox}
                          checked={selected.includes(approvalPublicLink.id)}
                          onChange={(event) => handleSelectOne(event, approvalPublicLink)}
                          inputProps={{
                            'aria-label': `select approval public link ${approvalPublicLink.name}`
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          ...classes.sticky,
                          minWidth: '200px',
                          left: '58px',
                          ...classes.scrollShadowLeft,
                          ...(tableHovered && classes.scrollShadowVisible)
                        }}
                      >
                        <Box
                          sx={{
                            '& a': {
                              color: '#666666',
                              fontWeight: 'bold',
                              '&:hover': {
                                textDecoration: 'none'
                              }
                            }
                          }}
                        >
                          <a className="approval-public-link-title-link">
                            {approvalPublicLink.name || '-'}
                          </a>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={classes.linkContainer}>
                          <>
                            <a
                              href={approvalPublicLink?.public_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Box id="public-link-external-link" sx={classes.link}>
                                {approvalPublicLink.public_link}
                              </Box>
                            </a>
                            <CopyToClipboard
                              text={approvalPublicLink?.public_link}
                              onCopy={() => handleCopy(approvalPublicLink.id)}
                            >
                              <Button sx={classes.copyButton} id="copy-link-public-link-button">
                                {copied === approvalPublicLink?.id ? 'Copied!' : 'Copy'}
                              </Button>
                            </CopyToClipboard>
                          </>
                        </Box>
                      </TableCell>
                      <TableCell>{approvalPublicLink.approval_template_name}</TableCell>
                      <TableCell>{approvalPublicLink.created_by}</TableCell>
                      <TableCell
                        sx={{
                          ...classes.attachmentsCell,
                          ...classes.stickyRight,
                          ...classes.scrollShadowRight,
                          ...(tableHovered && classes.scrollShadowVisible)
                        }}
                        ref={actionCellRef}
                      >
                        <Box sx={classes.actionItems}>
                          <Tooltip placement="top" title="Edit" arrow>
                            <IconButton
                              onClick={() => handleEditApprovalPublicLink(approvalPublicLink)}
                              className="approval-public-link-edit-button"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip placement="top" title="Trash" arrow>
                            <IconButton
                              onClick={(e) => {
                                setDeleteApprovalPublicLinks([approvalPublicLink.id]);
                                setDeleteAlertOpen(true);
                              }}
                              className="approval-public-link-delete-button"
                            >
                              <DeleteIcon sx={{ color: '#E37D7A !important' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!isLoading && approvalPublicLinks?.length === 0 && (
            <Box sx={classes.noApplications}>No Approval PublicLinks found</Box>
          )}
          {isLoading && (
            <>
              {[...Array(10)].map((_, index) => (
                <Box key={index} sx={classes.skeletonContainer}>
                  <Skeleton animation="wave" height={60} />
                </Box>
              ))}
            </>
          )}
        </TableContainer>
      </Box>
      <Box sx={classes.paginationParent}>
        <Box sx={classes.rowSelectContainer}>
          <span>Rows per page: </span>
          <FormControl>
            <Select
              id="rows-per-page"
              sx={classes.rowSelect}
              value={rowsPerPage}
              onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
              displayEmpty
              MenuProps={{ sx: classes.paginationMenuItems }}
            >
              <MenuItem id="first-rpp-item" value={10}>
                10
              </MenuItem>
              <MenuItem id="second-rpp-item" value={20}>
                20
              </MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={classes.paginationContainer}>
          <Pagination
            id="pagination-menu"
            count={totalPages}
            page={currentPage}
            siblingCount={0}
            onChange={handlePageChange}
            sx={classes.pagination}
          />
          <span id="total-jobs">{totalApprovalPublicLinks} Total</span>
        </Box>
      </Box>
      <Box
        id="selected-tooltip"
        sx={
          globalSelected?.length > 0
            ? { ...classes.selectedTooltip, ...classes.fadeIn }
            : { ...classes.selectedTooltip, ...classes.fadeOut }
        }
      >
        <Box
          id="selected-tooltip-text"
          sx={{
            display: 'flex',
            alignItems: 'center',
            ...classes.selectedTooltipText
          }}
        >
          {globalSelected.length === totalApprovalPublicLinks
            ? `All ${globalSelected?.length} approval public links are selected`
            : `${globalSelected?.length} selected`}
          <IconButton
            size="small"
            sx={{
              ...classes.selectedTooltipText,
              ...classes.selectedAllTooltipText,
              marginLeft: 1,
              color: '#666666'
            }}
            onClick={clearAllSelection}
          >
            Clear selection
          </IconButton>
        </Box>
        <Box sx={classes.selectedTooltipButtonsContainer}>
          <Button
            variant="contained"
            sx={classes.selectedTooltipButton}
            onClick={() => {
              setDeleteApprovalPublicLinks(selected);
              setDeleteAlertOpen(true);
            }}
          >
            Trash
          </Button>
        </Box>
      </Box>
      <GenericDialog
        isDialogOpen={deleteAlertOpen}
        setDialogOpen={setDeleteAlertOpen}
        title={
          deleteApprovalPublicLinks.length > 1
            ? 'Trash approval public links?'
            : 'Trash approval public link?'
        }
        description={
          deleteApprovalPublicLinks.length > 1
            ? 'Are you sure want to trash these approval public links?'
            : 'Are you sure want to trash this approval public link?'
        }
        buttonCallback={() => {
          setDeleteAlertOpen(false);
          handleDeleteApprovalPublicLinks(deleteApprovalPublicLinks);
          setDeleteApprovalPublicLinks([]);
        }}
        callbackLoading={false}
        buttonText="Trash"
        url=""
      />
      <NewApprovalPublicLink
        apiKey={apiKey}
        newApprovalOptions={newApprovalOptions}
        setNewApprovalOptions={setNewApprovalOptions}
        isPublicLinkDialogOpen={isPublicLinkDialogOpen}
        setIsPublicLinkDialogOpen={setIsPublicLinkDialogOpen}
        getApprovalPublicLinks={getApprovalPublicLinks}
        setSnackbarState={setSnackbarState}
        editingApprovalPublicLink={editingApprovalPublicLink}
        setEditingApprovalPublicLink={setEditingApprovalPublicLink}
      />
    </Box>
  );
}
