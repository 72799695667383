import React, { FC } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { SxProps } from '@mui/material';
import { theme } from '../../ThemeContext/ThemeObject';

interface IProps {
  existingCandidate: boolean;
  sx?: SxProps;
}

const CandidateIcon: FC<IProps> = ({ existingCandidate, sx }) => {
  return (
    <PersonIcon
      sx={{
        fontSize: '21px',
        color: existingCandidate ? theme.palette.error.main : theme.palette.success.main,
        ...sx
      }}
    />
  );
};

export default CandidateIcon;
