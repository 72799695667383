import React, { useState, useCallback, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import GenericDialog from '../../../Components/Modals/GenericDialog';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { MultilineFormTextField } from '../../../Components/CustomUIElements/FormTextField';
import SelectedApplicants from '../SelectedApplicants';
import { ISMSModalProps } from '../../types';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { theme } from '../../../../ThemeContext/ThemeObject';

export interface ISmsTemplate {
  id: number;
  title: string;
  body: string;
}

export default function SMSCandidates({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  hasTemplatePermissions,
  filters
}: ISMSModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [savingTemplate, setSavingTemplate] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [contentError, setContentError] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<ISmsTemplate | null>(null);
  const [templates, setTemplates] = useState<ISmsTemplate[]>([]);
  const [templateError, setTemplateError] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setMessageContent('');
    setContentError('');
    setSelectedTemplate(null);
    setTemplateName('');
    setTemplateError('');
    setModalsOpen((prev) => ({ ...prev, sms: false }));
  };

  const isSMSContentValid = useCallback(() => {
    if (!messageContent) {
      setContentError('Please enter a message');
      contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    } else if (messageContent.length > 160) {
      setContentError('Message cannot be more than 160 characters');
      contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    return true;
  }, [messageContent]);

  const getTemplates = useCallback(async () => {
    setTemplatesLoading(true);
    try {
      const response = await fetch(`/api/v4/sms_templates`, {
        method: 'GET',
        headers: {
          'x-api-authenticate': apiKey
        }
      });
      const data = await response.json();
      setTemplates(data?.sms_templates);
    } catch (error) {
      setSnackbar({
        message: 'Error fetching templates',
        state: 'error'
      });
    } finally {
      setTemplatesLoading(false);
    }
  }, [apiKey, setSnackbar]);

  const deleteSmsTemplate = async (url: string, smsTemplateId: number) => {
    setIsDeleting(true);
    try {
      const response = await fetch(`${url}${smsTemplateId}`, {
        method: 'DELETE',
        headers: {
          'x-api-authenticate': apiKey
        }
      });

      if (response.status === 204) {
        var newTemplates = templates?.filter((template: ISmsTemplate) => template.id !== smsTemplateId);
        setTemplates(newTemplates);
        setTemplateError('');
        setMessageContent('');
        setSnackbar({
          message: 'Template deleted',
          state: 'success'
        });
      }
      else {
        setSnackbar({
          message: 'Error deleting template',
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        message: 'Error deleting template2',
        state: 'error'
      });
    } finally {
      setIsDeleting(false);
      setIsDialogOpen(false);
    }
  };

  const saveTemplate = useCallback(async () => {
    if (!isSMSContentValid()) return;
    if (templates?.find((template) => template.title === templateName)) {
      setTemplateError('Template name already exists');
      return;
    }
    setSavingTemplate(true);
    setTemplatesLoading(true);
    try {
      const response = await fetch(`/api/v4/sms_templates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        body: JSON.stringify({
          sms_template: {
            title: templateName,
            body: messageContent
          }
        })
      });
      const data = await response.json();
      if (response.ok) {
        setTemplates((prev) => [...prev, data]);
        setSelectedTemplate(data);
        setTemplateError('');
        setSnackbar({
          message: 'Template saved',
          state: 'success'
        });
      } else {
        setSnackbar({
          message: data?.errors ? data.errors : 'Error saving template',
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        message: 'Error saving template',
        state: 'error'
      });
    } finally {
      setSavingTemplate(false);
      setTemplatesLoading(false);
    }
  }, [apiKey, messageContent, setSnackbar, templateName, templates]);

  const editTemplate = useCallback(async () => {
    if (!isSMSContentValid()) return;
    setSavingTemplate(true);
    setTemplatesLoading(true);
    try {
      const response = await fetch(`/api/v4/sms_templates/${selectedTemplate?.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        body: JSON.stringify({
          sms_template: {
            title: templateName,
            body: messageContent
          }
        })
      });
      const data = await response.json();
      if (response.ok) {
        var newTemplates = templates?.map(template => template.id === selectedTemplate?.id ? data : template)
        setTemplates(newTemplates);
        setSelectedTemplate(data);
        setTemplateError('');
        setSnackbar({
          message: 'Template edited',
          state: 'success'
        });
      } else {
        setSnackbar({
          message: data?.errors ? data.errors : 'Error editing template',
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        message: 'Error editing template',
        state: 'error'
      });
    } finally {
      setSavingTemplate(false);
      setTemplatesLoading(false);
    }
  }, [apiKey, messageContent, setSnackbar, templateName, templates]);

  const [dialogProps, setDialogProps] = useState({
    url: '',
    title: 'Permanently delete SMS Template?',
    description: '',
    buttonText: 'Delete',
    callbackLoading: false,
    dialogId: 0,
    buttonCallback: deleteSmsTemplate
  });

  useEffect(() => {
    if (modalsOpen.sms) {
      getTemplates();
    }
  }, [modalsOpen.sms, getTemplates]);

  useEffect(() => {
    if (selectedTemplate) {
        setTemplateName(selectedTemplate.title);
    }
}, [selectedTemplate]);

  const handleSubmit = async () => {
    if (!isSMSContentValid()) return;
    setIsSubmitting(true);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/applications/bulk_sms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        },
        body: JSON.stringify({
          applications_ids: globalSelected.map((app) => app.id),
          content: messageContent
        })
      });
      if (response.status === 201) {
        setSnackbar({
          message: 'Candidate SMS messages sent successfully',
          state: 'success'
        });
        handleClose();
      } else {
        setSnackbar({
          message:
            'Error sending messages, please try again or contact support if the issue persists',
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        message:
          'Error sending messages, please try again or contact support if the issue persists',
        state: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal open={modalsOpen.sms} onClose={handleClose} aria-labelledby="sms-candidates-modal">
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
        <Box id="sms-candidates-title" sx={classes.actionModalHeader}>
          SMS Candidates
        </Box>
        <Box
          sx={{ ...classes.modalContent, marginTop: '16px' }}
          id="sms-candidates-modal-content"
          aria-label="SMS Candidates Modal Content"
        >
          <Box
            sx={{ overflowX: 'hidden' }}
            role="form"
            id="sms-candidates-form"
            aria-label="SMS Candidates form"
          >
            <h3 style={{ marginLeft: '14px' }}>Message</h3>
            <Box sx={classes.actionModalFormLine}>
              <Autocomplete
                key={selectedTemplate?.title}
                disablePortal
                id="sms-template-select"
                options={templates?.map((option) => option.title)}
                value={selectedTemplate?.title}
                sx={{ ...sharedClasses.formAutocomplete, width: '287px', marginTop: '20px' }}
                loading={templatesLoading}
                loadingText="Loading SMS templates..."
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Template" InputLabelProps={{ shrink: true }} />
                )}
                onChange={(event, value) => {
                  if (!value) {
                    setSelectedTemplate(null);
                    setTemplateName('');
                    setMessageContent('');
                  }
                  const selectedTemplate = templates?.find((template) => template.title === value);
                  if (selectedTemplate) {
                    setSelectedTemplate(selectedTemplate);
                    setMessageContent(selectedTemplate.body);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props} display="flex">
                    {option}
                    <Box flexGrow={1} />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(event) => {
                        event.stopPropagation();
                        const selectedTemplate = templates?.find((template: ISmsTemplate) => template.title === option);

                        selectedTemplate && setDialogProps((prev) => ({
                          ...prev,
                          url: '/api/v4/sms_templates/',
                          description: `Are you sure you want to delete '${selectedTemplate.title}'? This action cannot be undone.`,
                          dialogId: selectedTemplate.id,
                          buttonCallback: deleteSmsTemplate
                        }));
                        setIsDialogOpen(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              />
            </Box>
            <Box sx={classes.actionModalFormLine} ref={contentRef}>
              <MultilineFormTextField
                label="Content"
                value={messageContent}
                onChange={(e) => {
                  setMessageContent(e.target.value);
                  if (contentError) setContentError('');
                }}
                styles={{ width: '740px' }}
                error={contentError}
              />
              <Box
                sx={{
                  ...classes.characterCount,
                  ...(messageContent.length > 160 && { color: theme.palette.error.main })
                }}
              >
                {messageContent.length} / 160
              </Box>
            </Box>
            <Collapse
              in={
                !hasTemplatePermissions &&
                !!messageContent &&
                messageContent !== selectedTemplate?.body
              }
            >
              <Box sx={classes.saveSMSTemplateContainer}>
                <FormTextField
                  id="new-sms-template-title"
                  label="Template title"
                  value={templateName}
                  styles={{ width: '290px' }}
                  onChange={(e) => {
                    setTemplateName(e.target.value);
                    if (templateError) setTemplateError('');
                  }}
                />
                <Box sx={{ transform: 'translateY(-1.5px)' }}>
                  {!!messageContent && !selectedTemplate && (
                    <Button
                      id="save-sms-template"
                      type="submit"
                      sx={classes.saveSMSTemplateButton}
                      onClick={saveTemplate}
                    >
                      {savingTemplate ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        'Save template'
                      )}
                    </Button>
                  )}
                  {selectedTemplate && messageContent !== selectedTemplate?.body && (
                    <Button
                      id="edit-sms-template"
                      type="submit"
                      sx={classes.saveSMSTemplateButton}
                      onClick={editTemplate}
                    >
                      {savingTemplate ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        'Edit template'
                      )}
                    </Button>
                  )}
                </Box>
                {templateError && <Box sx={classes.saveSMSTemplateError}>{templateError}</Box>}
              </Box>
            </Collapse>
            <Box sx={{ marginLeft: '15px' }}>
              <h3>Candidates</h3>
              <SelectedApplicants
                globalSelected={globalSelected}
                handleDeselectCandidate={handleDeselectCandidate}
                closeModalCallback={handleClose}
                filters={filters}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <Button
            id="close-sms-candidate-button"
            sx={classes.modalCancelButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            id="send-sms-candidate-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '71px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSubmitting ? <CircularProgress size={20} color="inherit" /> : 'Send'}
          </Button>
        </Box>
        <GenericDialog
        url={dialogProps.url}
        title={dialogProps.title}
        description={dialogProps.description}
        buttonText={dialogProps.buttonText}
        buttonCallback={dialogProps.buttonCallback}
        callbackLoading={isDeleting}
        isDialogOpen={isDialogOpen}
        setDialogOpen={setIsDialogOpen}
        dialogId={dialogProps.dialogId}
      />
      </Box>
    </Modal>
  );
}
