import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Fade, Popper } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ExpandedSummaryModal from './ExpandedSummaryModal';
import dompurify from 'dompurify';
import { classes } from './styles';
import { StyledSnackbarProps } from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { FelixConfused, FelixMessageSVG } from '../SharedComponents/Graphics/FelixSVG';
import { IApplicationInfo } from '../SharedComponents/types';
import { convertToLocalEnglish } from '../../NewUI/Components/Utilities/convertToLocalEnglish';

const SummaryPopover = ({
  applicantHovered,
  handleHoverApplicant,
  handleUnhoverApplicant,
  lastHoveredApplicant,
  anchorEl,
  jobId,
  setSnackbarState,
  summaryGeneratingIds,
  setSummaryGeneratingIds
}: {
  applicantHovered: boolean;
  handleHoverApplicant: (target: HTMLElement | null, application?: IApplicationInfo) => void;
  handleUnhoverApplicant: () => void;
  lastHoveredApplicant: IApplicationInfo;
  anchorEl: HTMLElement | null;
  jobId: number;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
  summaryGeneratingIds: number[];
  setSummaryGeneratingIds: Dispatch<SetStateAction<number[]>>;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [copied, setCopied] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const summaryRef = useRef<HTMLDivElement | null>(null);

  const [dotPoints, summary] = useMemo(() => {
    if (!lastHoveredApplicant?.summary) return [null, null];
    const summary = convertToLocalEnglish(lastHoveredApplicant.summary);
    const ulIndex = summary.indexOf('<ul>');

    if (ulIndex !== -1) {
      const listItems = summary
        ?.replace('\n', '')
        .split('<ul>')[1]
        .split('</li>')
        .slice(0, 3)
        .join('</li>');
      return ['<ul>' + listItems + '</li></ul>', summary];
    } else {
      // Handle the case where <ul> is not found in the summary
      return [summary, summary];
    }
  }, [lastHoveredApplicant.summary]);

  const handleCloseModal = () => {
    setExpanded(false);
  };

  const generateSummary = async () => {
    setLoadingSummary(true);
    try {
      const request = await fetch(
        `/api/v4/jobs/${jobId}/applications/${lastHoveredApplicant.id}/application_summary`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (request.ok) {
        setSnackbarState({
          message: 'Summary is being generated, this may take a few minutes',
          state: 'success'
        });
        setSummaryGeneratingIds((prev) => [...prev, lastHoveredApplicant.id]);
      } else {
        const response = await request.json();
        setSnackbarState({
          message: response.errors || 'Error requesting a generated summary',
          state: 'error'
        });
      }
    } catch (e) {
      setSnackbarState({
        message: 'Something went wrong, please refresh and try again',
        state: 'error'
      });
    } finally {
      setLoadingSummary(false);
      setExpanded(false);
    }
  };

  const isGeneratingSummary = useMemo(
    () => summaryGeneratingIds.includes(lastHoveredApplicant.id || -1),
    [summaryGeneratingIds, lastHoveredApplicant.id]
  );

  return (
    <>
      <Popper
        id={'application-summary-popper'}
        open={applicantHovered}
        anchorEl={anchorEl}
        placement="right"
        transition
        sx={classes.summaryPopper}
        onMouseEnter={() => handleHoverApplicant(anchorEl)}
        onMouseLeave={handleUnhoverApplicant}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={300}>
            <Box sx={classes.summaryPopover}>
              {summary ? (
                <>
                  <OpenInFullIcon sx={classes.expandIcon} onClick={() => setExpanded(true)} />
                  <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(dotPoints) }} />
                </>
              ) : (
                <>
                  <Box sx={classes.noSummaryFelix}>
                    {isGeneratingSummary ? <FelixMessageSVG /> : <FelixConfused />}
                  </Box>
                  <Box sx={classes.noSummary}>
                    {isGeneratingSummary ? 'Summary being generated' : 'No summary available'}
                  </Box>
                  <Box sx={classes.noSummaryGenerate}>
                    {!isGeneratingSummary ? (
                      <Button sx={classes.pillButton} onClick={generateSummary}>
                        {loadingSummary ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          'Request summary'
                        )}
                      </Button>
                    ) : (
                      <Box sx={classes.noSummary}>Please check back in a few minutes</Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Fade>
        )}
      </Popper>
      {summary && (
        <ExpandedSummaryModal
          jobId={jobId}
          expanded={expanded}
          handleCloseModal={handleCloseModal}
          lastHoveredApplicant={lastHoveredApplicant}
          summaryRef={summaryRef}
          setCopied={setCopied}
          copied={copied}
          loadingSummary={loadingSummary}
          generateSummary={generateSummary}
          setSnackbarState={setSnackbarState}
        />
      )}
    </>
  );
};

export default SummaryPopover;
