"use strict";
const DAY_TO_SECOND = 60 * 60 * 24;
const HOUR_TO_SECOND = 60 * 60;
const secondsToTimeString = (seconds, unit, singular) => {
  const amount = seconds / unit;
  return `${amount} ${singular}${amount > 1 ? "s" : ""}`;
};
export const secondsToString = (seconds) => {
  if (seconds % DAY_TO_SECOND === 0) {
    return secondsToTimeString(seconds, DAY_TO_SECOND, "day");
  }
  if (seconds % HOUR_TO_SECOND === 0) {
    return secondsToTimeString(seconds, HOUR_TO_SECOND, "hour");
  }
  return "None";
};
