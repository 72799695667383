import React, { useState, useEffect } from 'react';
import useStyles from './useStyles';
import { ICalendlyEvent, ICalendlyScoutProps } from '../../../../types';
import { withScoutTheme } from '../../../../../ThemeContext/ThemeContext';
import { useTheme } from '@material-ui/core';
import ReactLoading from 'react-loading';
import axios from 'axios';
import EventType from './EventType';

interface IProps {
  calendlyAccount: ICalendlyScoutProps,
  events: ICalendlyEvent[],
  setEvents: (event: ICalendlyEvent[]) => void,
  selectedEvent: ICalendlyEvent,
  setSelectedEvent: (event: ICalendlyEvent | null) => void,
}

function Step2({ calendlyAccount, events, setEvents, selectedEvent, setSelectedEvent }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
 
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const events = await axios.get(`https://api.calendly.com/event_types?user=${calendlyAccount.user}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${calendlyAccount.access_token}`
          }
        });
        setEvents(events.data.collection.filter((event: ICalendlyEvent) => event.active))
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
      }
    }
    if (!events?.length) getData()
    else setIsLoading(false)
  }, [])

  return (
    !isLoading
      ? <>
        <h4 id="step-2-header">Meeting details</h4>
          <div className={classes.recContainer}>
            <div className={classes.recImage}>{ calendlyAccount.user_name.split(' ').map(name => name[0]) }</div>
            <div className={classes.recDetails}>
              <h4>{ calendlyAccount.user_name }</h4>
              <a id="calendly-scheduling-url" target="_blank" rel="noreferrer" href={calendlyAccount.scheduling_url}>{ calendlyAccount.scheduling_url }</a>
            </div>
          </div>
          <div className={`${classes.meetingContainer} ${events.length > 3 ? classes.shadow : ''}`}>
            { events.length
              ? events.map(event =>
                  <EventType
                    key={event.uri}
                    event={event}
                    setSelectedEvent={setSelectedEvent}
                    selected={selectedEvent?.uri === event.uri}
                  />
                )
              : <span className={classes.noEvents}>No available events</span>
            }
          </div>
        </>
      : <div className={classes.loading}>
          <ReactLoading type="spin" color={theme.palette.primary.main} width={45} height={45} />
        </div>
  )
}

export default withScoutTheme(Step2)
