import React, { useState, useEffect } from 'react';
import { Box, Modal, Divider, Stack, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styles } from './styles';
import TemplateTitleTab from './TemplateTitleTab';
import DefaultApproversTab from './DefaultApproversTab';
import AdvancedTab from './AdvancedTab';
import FormTitleTab from './FormTitleTab';
import {
  IApprovalTemplate,
  IApprovalTemplateOptions,
  IApprovalManagers,
  IApprovalTemplateFields
} from '../types';
import { optionsBooleanValues, optionsNumericValues, settingTabs } from '../config';
import Api from '../API';
import LoadingButton from '../../Components/Buttons/LoadingButton';
import { StyledSnackbarProps } from '../../Components/CustomUIElements/StyledSnackbar';
import { emailIsValid } from '../../Components/Utilities/emailIsValid';

const BASE_URL = window.location.origin;

export default function ApprovalTemplateSettings({
  setModalsOpen,
  modalsOpen,
  approvalTemplateData,
  getApprovalTemplate,
  entityDefaultApprovers,
  questions,
  setSnackbar,
  action,
  enableEmailTemplateFields
}: {
  modalsOpen: boolean;
  setModalsOpen: () => void;
  approvalTemplateData?: IApprovalTemplate;
  getApprovalTemplate?: () => Promise<void>;
  entityDefaultApprovers?: string[];
  questions?: IApprovalTemplateFields[];
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
  action?: string;
  enableEmailTemplateFields?: boolean;
}) {
  const [templateName, setTemplateName] = useState<string>(approvalTemplateData?.name || '');
  const [approvalManagers, setApprovalManagers] = useState<IApprovalManagers[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null);
  const [tabNumber, setTabNumber] = useState<number>(0);
  const [templateApprovers, setTemplateApprovers] = useState<string[]>(['']);
  const [approvalTemplateEmail, setApprovalTemplateEmail] = useState<number | null>(null);
  const [options, setOptions] = useState<IApprovalTemplateOptions | null>(null);
  const [emailTemplates, setEmailTemplates] = useState<
    [string, Array<{ id: number; name: string }>][]
  >([]);
  const [tempErrorMessage, setTempErrorMessage] = useState<string | null>(null);
  const [invalidEmailIndex, setInvalidEmailIndex] = useState<number[]>([]);

  useEffect(() => {
    const isMounted = { current: true };
    getApprovers(isMounted);
    getEmailTemplates(isMounted);
    return () => {
      isMounted.current = false;
    };
  }, [modalsOpen]);

  useEffect(() => {
    setTemplateName(approvalTemplateData?.name || '');
    setOptions(optionsBooleanValues(approvalTemplateData?.config) || null);
    setApprovalTemplateEmail(approvalTemplateData?.email_template_id || null);
    setSelectedQuestion(
      approvalTemplateData?.config?.approval_form_title_position === null
        ? null
        : Number(approvalTemplateData?.config?.approval_form_title_position)
    );
    if (approvalTemplateData?.config?.default_approvers) {
      setTemplateApprovers(
        approvalTemplateData.config.default_approvers.split(',').map((email) => email.trim())
      );
    } else if (approvalTemplateData?.entity_default_approver) {
      setTemplateApprovers(
        approvalTemplateData.entity_default_approver.map((approver) => {
          return approver.required ? `*${approver.email}` : approver.email;
        })
      );
    } else if (entityDefaultApprovers) {
      setTemplateApprovers(entityDefaultApprovers);
    } else {
      setTemplateApprovers(['']);
    }
  }, [approvalTemplateData, entityDefaultApprovers]);

  async function getApprovers(isMounted: { current: boolean }) {
    try {
      const data = await Api.getApprovalManagers({
        override_limit: true,
        limit: 2000,
        sort: 'name',
        sort_order: 'asc'
      });
      let defaultApprovers;
      if (entityDefaultApprovers) {
        defaultApprovers = entityDefaultApprovers?.map((approver, index) => {
          return { email: approver, name: approver, id: index };
        });
      } else
        defaultApprovers = approvalTemplateData?.entity_default_approver?.map((approver, index) => {
          return {
            email: approver.required ? `*${approver.email}` : approver.email,
            name: approver.email,
            id: index
          };
        });

      const approvalOptions = defaultApprovers?.length
        ? [...data.res.requisition_managers, ...defaultApprovers]
        : data.res.requisition_managers;

      if (isMounted.current) setApprovalManagers(approvalOptions);
    } catch (err) {
      if (isMounted.current)
        setSnackbar({
          message: 'Failed to fetch approvers list',
          state: 'error'
        });
    }
  }

  const validateEmail = () => {
    if (!templateApprovers) return false;
    const invalid = [];
    templateApprovers.map((approver, index) => {
      if (!emailIsValid(approver) && approver !== '' && approver !== '*') {
        invalid.push(index);
      }
    });
    if (invalid.length > 0) {
      setTabNumber(1);
      setInvalidEmailIndex(invalid);
      return false;
    } else {
      return true;
    }
  };

  async function getEmailTemplates(isMounted: { current: boolean }) {
    try {
      const data = await Api.getEmailTemplates();
      if (isMounted.current) setEmailTemplates(data.res.email_templates);
    } catch (err) {
      if (isMounted.current)
        setSnackbar({
          message: 'Failed to fetch email templates',
          state: 'error'
        });
    }
  }

  const approvalTemplateObj = {
    name: templateName,
    default_approvers: templateApprovers.join(','),
    ...(options?.hide_position_title
      ? { approval_form_title_position: selectedQuestion || 0 }
      : {}),
    email_template_id: approvalTemplateEmail,
    for_application: 1,
    ...optionsNumericValues(options)
  };

  const handleSubmitAction = async () => {
    if (!handleValidateTitle() || !validateEmail()) return;

    try {
      if (approvalTemplateData) {
        if (action === 'Clone') {
          // for cloning
          const response = await Api.cloneApprovalTemplate(approvalTemplateData?.id, {
            requisition_form: approvalTemplateObj
          });
          if (response.res.success) {
            setSnackbar({
              message: response.res.success,
              state: 'success'
            });
          }
          if (response.res.redirect_url) {
            const fieldsUrl = response.res.redirect_url.replace('edit', 'fields');
            window.location.href = `${BASE_URL}${fieldsUrl}`;
          }
        } else {
          // for editing and settings
          const approvalTemplateId = approvalTemplateData?.id;
          await Api.updateApprovalTemplate(approvalTemplateId, {
            requisition_form: approvalTemplateObj
          });
          setSnackbar({
            message: 'Approval template updated',
            state: 'success'
          });
          setModalsOpen();
        }
      } else {
        // for new approval template
        const response = await Api.createApprovalTemplate({
          requisition_form: approvalTemplateObj
        });
        if (response.res.id) {
          setSnackbar({
            message: 'Approval template has been created',
            state: 'success'
          });
        }
        const path = response.res.link;
        window.location.href = `${BASE_URL}${path}`;
      }
      // }
    } catch (error) {
      setSnackbar({
        message: `Failed to ${buttonName().toLowerCase()} approval template, ${error}`,
        state: 'error'
      });
    } finally {
      getApprovalTemplate && getApprovalTemplate();
    }
  };

  const handleNextAction = () => {
    switch (tabNumber) {
      case 0:
        if (!handleValidateTitle()) return;
        break;
      case 1:
        if (!validateEmail()) return;
        break;
    }
    if (tabNumber === 1) {
      setInvalidEmailIndex([]);
    }
    setTabNumber((prev) => prev + 1);
  };

  const handleValidateTitle = () => {
    setTempErrorMessage(null);
    if (tabNumber === 0 && !templateName) {
      setTempErrorMessage('This field is required');
      return false;
    } else {
      return true;
    }
  };

  const modalTitle = () => {
    if (action) {
      return action;
    } else if (approvalTemplateData) {
      return 'Settings';
    } else {
      return 'Create';
    }
  };

  const buttonName = () => {
    if (!approvalTemplateData && !action) {
      return 'Create';
    } else if (action === 'Clone') {
      return 'Clone';
    } else {
      return 'Save';
    }
  };

  return (
    <Modal
      open={modalsOpen}
      aria-labelledby="approval-template-settings-modal"
      onClose={setModalsOpen}
    >
      <Box sx={styles.actionsModal}>
        <Box sx={styles.modalTabsContainer}>
          <Box id="approval-template-settings-modal-title" sx={styles.modalHeader}>
            {modalTitle()}
          </Box>
          {settingTabs.map((tab, index) => {
            if (tab === 'Approval form title' && !options?.hide_position_title) {
              return;
            } else {
              return (
                <Stack
                  sx={{
                    ...styles.tabTitle,
                    backgroundColor: index === tabNumber ? 'rgba(8,77,109,0.07)' : 'transparent',
                    fontWeight: index === tabNumber ? 'bold' : ''
                  }}
                  key={index}
                >
                  <Stack sx={styles.hoverTabTitle} onClick={() => setTabNumber(index)}>
                    {tab}
                  </Stack>
                </Stack>
              );
            }
          })}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={styles.modalFormContainer}>
          <Box sx={styles.closeIconContainer}>
            <CloseIcon onClick={setModalsOpen} sx={styles.closeIcon} />
          </Box>
          {tabNumber === 0 && (
            <TemplateTitleTab
              templateName={templateName}
              setTemplateName={setTemplateName}
              tempErrorMessage={tempErrorMessage}
            />
          )}
          {tabNumber === 1 && (
            <DefaultApproversTab
              approvalManagers={approvalManagers}
              templateApprovers={templateApprovers}
              setTemplateApprovers={setTemplateApprovers}
              invalidEmailIndex={invalidEmailIndex}
              setInvalidEmailIndex={setInvalidEmailIndex}
            />
          )}
          {tabNumber === 2 && (
            <AdvancedTab
              options={options}
              setOptions={setOptions}
              emailTemplates={emailTemplates}
              approvalTemplateEmail={approvalTemplateEmail}
              setApprovalTemplateEmail={setApprovalTemplateEmail}
              enableEmailTemplateFields={enableEmailTemplateFields}
            />
          )}
          {tabNumber === 3 && options?.hide_position_title && (
            <FormTitleTab
              questionsList={questions?.filter((question) => question.enabled)}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
              action={buttonName()}
            />
          )}
          <Box sx={styles.modalActions}>
            {modalTitle() === 'Create' || modalTitle() === 'Clone' ? (
              (options?.hide_position_title && tabNumber === 3) ||
              (!options?.hide_position_title && tabNumber === 2) ? (
                <LoadingButton
                  id="save-approval-template-settings-button"
                  type="submit"
                  task={handleSubmitAction}
                  sx={styles.modalSaveButton}
                >
                  {buttonName()}
                </LoadingButton>
              ) : (
                <Button
                  id="next-approval-template-settings-button"
                  type="button"
                  onClick={handleNextAction}
                  sx={styles.modalSaveButton}
                >
                  Next
                </Button>
              )
            ) : (
              <LoadingButton
                id="save-approval-template-settings-button"
                type="submit"
                task={handleSubmitAction}
                sx={styles.modalSaveButton}
              >
                {buttonName()}
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
