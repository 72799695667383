import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    padding: "30px 40px",
    borderBottom: "1px solid #F3F3F3",
    "&.selected": {
      background: "#F3F6F7"
    },
    "&:hover": {
      cursor: "pointer",
      background: "#F3F6F7",
      "& p": {
        color: "#123848"
      }
    }
  },
  details: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  name: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    "& h4": {
      marginTop: 0,
      fontSize: 18,
      color: theme.palette.primary.main,
      fontWeight: "600",
      lineHeight: "20px",
      textTransform: "capitalize"
    }
  },
  status: {
    borderRadius: "100%",
    background: theme.palette.error.main,
    //TODO Update with appropriate status colors
    marginLeft: "auto",
    height: 8,
    width: 8
  },
  time: {
    color: "#393939",
    fontSize: 14,
    whiteSpace: "nowrap",
    marginLeft: 6,
    textTransform: "capitalize"
  },
  excerpt: {
    fontSize: 12,
    color: "#393939",
    marginTop: 10,
    lineHeight: "18px"
  }
}));
