import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SadNoteSVG from './SadNoteSVG';
import moment from 'moment';
import { IJobNote } from '../types';
import { classes } from './styles';

interface INoteListProps {
  notes: IJobNote[];
  editMode: number;
  isDeleting: boolean;
  isLoading: boolean;
  editingId: number;
  handleNewNote: () => void;
  handleEditNote: (id: number, note: string) => void;
  handleDeleteNote: (id: number) => void;
  permissions: Record<string, boolean>;
}

function NoteList({
  notes,
  editMode,
  isDeleting,
  isLoading,
  editingId,
  handleNewNote,
  handleEditNote,
  handleDeleteNote,
  permissions
}: INoteListProps) {
  return notes && notes.length > 0 ? (
    <Box>
      {permissions.create && (
        <Box sx={classes.newNoteContainer}>
          {!editMode && (
            <Button
              sx={classes.notesNewButton}
              onClick={handleNewNote}
              id="add-a-job-note-button"
              data-testid="add-a-job-note-button"
            >
              Add a note
            </Button>
          )}
        </Box>
      )}
      <Box sx={classes.notesContainer}>
        {notes.map((note: IJobNote) => (
          <Box key={note.id} sx={classes.note}>
            <Box sx={classes.noteContent}>
              <Box sx={classes.noteText} className="job-note" data-testid="job-note">
                {note.body}
              </Box>
              <Box>
                {moment(note.updated_at).format('D MMMM YYYY [at] h:mm A')} by {note.noted_by}
              </Box>
            </Box>
            <Box sx={classes.noteActions}>
              {permissions.edit && (
                <IconButton
                  className="edit-job-note-button"
                  data-testid="edit-job-note-button"
                  onClick={() => handleEditNote(note.id, note.body)}
                >
                  <EditIcon sx={{ color: '#666666' }} />
                </IconButton>
              )}
              {permissions.delete && (
                <IconButton
                  className="delete-job-note-button"
                  data-testid="delete-job-note-button"
                  onClick={() => handleDeleteNote(note.id)}
                >
                  {isDeleting && editingId === note.id ? (
                    <CircularProgress size={32} sx={{ color: '#D6827D' }} />
                  ) : (
                    <DeleteIcon sx={{ color: '#D6827D' }} />
                  )}
                </IconButton>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  ) : (
    <Box>
      {isLoading ? (
        <Box sx={classes.notesSkeleton}>
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
        </Box>
      ) : (
        <Box sx={{ ...classes.newNoteContainer, margin: '100px 0px' }}>
          <Box>
            <SadNoteSVG />
          </Box>
          <Box sx={classes.noNotesText}>No notes added</Box>
          {!editMode && (
            <Button
              sx={classes.notesNewButton}
              onClick={handleNewNote}
              id="add-a-job-note-button"
              data-testid="add-a-job-note-button"
            >
              Add a note
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

export default memo(NoteList);
