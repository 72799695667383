import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';

import { classes } from './Dropdowns/styles';

const ExportCsvButton = ({ isOpen, onClick }: { isOpen: boolean; onClick: () => void }) => {
  return (
    <Tooltip title="Export CSV" placement="top" arrow>
      <IconButton
        id={`export-csv-button`}
        sx={isOpen ? { ...classes.iconButton, ...classes.iconButtonOpen } : classes.iconButton}
        onClick={onClick}
      >
        <IosShareIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ExportCsvButton;
