import React, { useEffect, useRef } from "react";
import useStyles from "./useStyles";
import PropTypes from "prop-types";
import moment from "moment";
import ChatItem from "./components/ChatItem";
import SmsRoundedIcon from "@material-ui/icons/SmsRounded";
function ChatWindow({ conversation, body, signature }) {
  const root = useRef(null);
  useEffect(() => {
    root.current.scrollTop = root.current.scrollHeight + 20;
  }, [conversation, body, signature]);
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement("div", { ref: root, className: classes.root }, conversation.length > 1 ? /* @__PURE__ */ React.createElement("div", { className: classes.noMessages }, /* @__PURE__ */ React.createElement(SmsRoundedIcon, null), /* @__PURE__ */ React.createElement("span", null, "Send a group message")) : conversation[0].messages.length <= 0 ? /* @__PURE__ */ React.createElement("div", { className: classes.noMessages }, /* @__PURE__ */ React.createElement(SmsRoundedIcon, null), /* @__PURE__ */ React.createElement("span", null, "This conversation is empty")) : /* @__PURE__ */ React.createElement("div", { className: classes.chatContainer }, conversation[0].messages.map(
    (message, i) => message && conversation[0].messages[i + 1] && moment.duration(moment(message.sent_time).diff(moment(conversation[0].messages[i + 1].sent_time))).asHours() > 1 ? /* @__PURE__ */ React.createElement(ChatItem, { key: message.id, message }) : /* @__PURE__ */ React.createElement("div", { key: message.id, style: { display: "flex", flexDirection: "column" } }, /* @__PURE__ */ React.createElement("div", { className: classes.timestamp }, message.sent_time && moment(message.sent_time).format("h:mma")), /* @__PURE__ */ React.createElement(ChatItem, { message }))
  )));
}
ChatWindow.propTypes = {
  conversation: PropTypes.array.isRequired,
  body: PropTypes.string,
  signature: PropTypes.string
};
export default ChatWindow;
