import { classes } from '../NewUI/Components/GenericTable/styles';
import React from 'react';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { ActionListItem } from '../NewUI/Components/GenericTable/GenericTableActions';
import { SxProps } from '@mui/material';

export const getTabNumber = (urlParams: string) => {
  switch (urlParams) {
    case '?engage-search':
      return 0;
    case '?candidate-pools':
      return 1;
    default:
      return 0;
  }
};

export const getTabParam = (tabNumber: number) => {
  switch (tabNumber) {
    case 0:
      return '?engage-search';
    case 1:
      return '?candidate-pools';
    default:
      return '?engage-search';
  }
};

export const searchTableColumns = [
  {
    id: 'id',
    label: 'ID',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'name',
    label: 'Candidate',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'suburb',
    label: 'Suburb',
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: 'country',
    label: 'Country',
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: 'last_applied_on',
    label: 'Last Applied',
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: 'jobs_applied_to',
    label: 'Jobs Applied To',
    sortable: false,
    canToggle: true,
    enabled: true
  },
  {
    id: 'tags',
    label: 'Candidate Tags',
    sortable: false,
    canToggle: true,
    enabled: true
  },
  {
    id: 'notes',
    label: 'Notes',
    sortable: false,
    canToggle: true,
    enabled: true
  }
];

export const headerStyle = (
  name: (typeof searchTableColumns)[number]['id'] | 'checkbox' | 'actions',
  tableHovered: boolean
): SxProps => {
  switch (name) {
    case 'checkbox':
      return { ...classes.sticky, width: '58px' };
    case 'id':
      return { ...classes.sticky, maxWidth: '96px', width: '96px', left: '58px' };
    case 'name':
      return {
        ...classes.sticky,
        ...classes.itemTitleTableCell,
        ...(tableHovered && classes.scrollShadowVisible)
      };
    case 'notes':
      return { minWidth: '240px' };
    case 'last_applied_on':
      return { minWidth: '150px' };
    case 'jobs_applied_to':
      return { minWidth: '180px' };
    case 'tags':
      return { minWidth: '150px' };
    case 'actions':
      return {
        ...classes.actionsColumnHeader,
        ...(tableHovered && classes.scrollShadowVisible)
      };
    default:
      return { minWidth: '90px' };
  }
};

export const actionDrawerModals = [
  {
    id: 'addToPool',
    label: 'Add to pool',
    icon: <PlaylistAddIcon />
  },
  {
    id: 'externalEmail',
    label: 'External email',
    icon: <ForwardToInboxIcon />
  },
  {
    id: 'downloadAttachments',
    label: 'Download attachments',
    icon: <AttachFileIcon />
  },
  {
    id: 'exportAttachments',
    label: 'Export attachments',
    icon: <PictureAsPdfIcon />
  },
  {
    id: 'exportAsPdf',
    label: 'Export as PDF',
    icon: <PictureAsPdfIcon />
  }
];

export const engageSearchActionsList: ActionListItem[] = [
  {
    id: 'addNote',
    tooltipLabel: 'Add Note',
    icon: <NoteAddIcon />
  },
  {
    id: 'viewAttachments',
    tooltipLabel: 'View Attachments',
    icon: <AttachFileIcon />
  }
];
