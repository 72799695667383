import React, { useState, Dispatch, SetStateAction } from 'react';
import { defaultInclusions } from '../../config';
import { Box } from '@mui/material';
import { classes } from '../../styles';
import { StyledSnackbarProps } from '../../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import ToggleButtons from '../../../SharedComponents/Buttons/ToggleButtons';
import SavedQuestionsTab from './SavedQuestionsTab';
import InputTab from './InputTab';

const toggleButtonOptions = [
  {
    value: 'input',
    label: 'Create new questions'
  },
  {
    value: 'savedQuestions',
    label: 'Saved questions'
  }
];

const HomeStep = ({
  positionTitle,
  questionType,
  setQuestionType,
  inclusions,
  setInclusions,
  requestQuestions,
  jobId,
  applicationId,
  setSnackbarState
}: {
  positionTitle: string;
  questionType: string;
  setQuestionType: Dispatch<SetStateAction<string>>;
  inclusions: typeof defaultInclusions;
  setInclusions: Dispatch<SetStateAction<typeof defaultInclusions>>;
  requestQuestions: () => void;
  jobId: number;
  applicationId: number | null;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
}) => {
  const [editablePositionTitle, setEditablePositionTitle] = useState<string>(positionTitle);
  const [selectedTab, setSelectedTab] =
    useState<typeof toggleButtonOptions[number]['value']>('input');

  return (
    <>
      <Box sx={classes.header}>Suggested interview questions</Box>
      <Box sx={classes.aiRow}>
        <ToggleButtons
          value={selectedTab}
          setValue={setSelectedTab}
          options={toggleButtonOptions}
        />
      </Box>
      {selectedTab === 'input' ? (
        <InputTab
          editablePositionTitle={editablePositionTitle}
          setEditablePositionTitle={setEditablePositionTitle}
          questionType={questionType}
          setQuestionType={setQuestionType}
          inclusions={inclusions}
          setInclusions={setInclusions}
          requestQuestions={requestQuestions}
        />
      ) : (
        <SavedQuestionsTab
          jobId={jobId}
          applicationId={applicationId}
          setSnackbarState={setSnackbarState}
        />
      )}
    </>
  );
};

export default HomeStep;
