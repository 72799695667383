import React, { useState, useMemo, useCallback, useEffect } from 'react';
import MultiSelect, { IOptionType } from '../../../Components/CustomUIElements/MultiSelect';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import CancelIcon from '@mui/icons-material/Cancel';
import SortIcon from '@mui/icons-material/Sort';
import CircularProgress from '@mui/material/CircularProgress';
import { defaultFilters } from '../config';
import { IFilterDropdownProps } from '../../types';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import Tooltip from '@mui/material/Tooltip';

export default function FilterDropdown({
  filters,
  setFilters,
  getApplications,
  statusList,
  countryList,
  suburbList,
  setUserPreferences,
  setCurrentPage,
  jobId,
  clearAllSelection
}: IFilterDropdownProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [tempFilters, setTempFilters] = useState({ ...filters });
  const [numberOfFilters, setNumberOfFilters] = useState(0);

  const calculateNumberOfFilters = useCallback((newFilters) => {
    return (
      Number(!!newFilters.status.length) +
      Number(!!newFilters?.country?.length) +
      Number(!!newFilters?.suburb?.length) +
      Number(newFilters.rating[0] !== 0 || newFilters.rating[1] !== 100) +
      Number(newFilters.excludeApplicantsWithoutRating)
    );
  }, []);

  const handleDropdownOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };

  const handleFilterChange = (name: string, value: IOptionType[] | number[] | boolean) => {
    setTempFilters({ ...tempFilters, [name]: value });
  };

  const handleApplyFilters = () => {
    setFilters({ ...tempFilters });
    setCurrentPage(1);
    sessionStorage.setItem(`${jobId}-applicationTablePage`, 1);
    setUserPreferences(null, null, null, { ...tempFilters }, null);
    getApplications(1, null, null, null, tempFilters);
    setDropdownOpen(false);
    setAnchorEl(null);
    setNumberOfFilters(calculateNumberOfFilters(tempFilters));
    clearAllSelection();
  };

  const handleResetFilters = () => {
    setTempFilters({ ...defaultFilters });
    setUserPreferences(null, null, null, { ...defaultFilters }, null);
    setFilters({ ...defaultFilters });
    getApplications(null, null, null, null, defaultFilters);
    setDropdownOpen(false);
    setAnchorEl(null);
    setNumberOfFilters(0);
    clearAllSelection();
  };

  const statusOptions = useMemo(() => {
    return statusList?.map((status) => ({
      name: status.name,
      id: status.id,
      colour: status.colour
    }));
  }, [statusList]);

  const countryOptions = useMemo(() => {
    return countryList?.map((country) => ({
      name: country.name,
      id: country.id
    }));
  }, [countryList]);

  const suburbOptions = useMemo(() => {
    return suburbList?.map((suburb) => ({
      name: suburb.name,
      id: suburb.id
    }));
  }, [suburbList]);

  useEffect(() => {
    setTempFilters({ ...filters });
    setNumberOfFilters(calculateNumberOfFilters(filters));
  }, [filters, calculateNumberOfFilters]);

  return (
    <Tooltip title="Filter" placement="top" arrow>
      <Box>
        <Badge
          badgeContent={numberOfFilters}
          sx={{ '& .MuiBadge-badge': { backgroundColor: '#E37D7A', color: '#FFF' } }}
        >
          <IconButton
            id="filter-dropdown-applications-table-button"
            sx={
              dropdownOpen
                ? { ...classes.iconButton, ...classes.iconButtonOpen }
                : classes.iconButton
            }
            onClick={handleDropdownOpen}
          >
            {loadingFilters ? <CircularProgress size={24} /> : <SortIcon />}
          </IconButton>
        </Badge>
        <Popover
          id="filter-dropdown-popover"
          sx={classes.popover}
          open={dropdownOpen}
          anchorEl={anchorEl}
          onClose={() => setDropdownOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box sx={classes.filterPopoverContent}>
            <Box sx={classes.scrollableFilters}>
              <Box sx={classes.filterPopoverHeader}>
                <Button
                  id="reset-filters-button"
                  variant="contained"
                  endIcon={<CancelIcon />}
                  sx={classes.clearButton}
                  onClick={handleResetFilters}
                >
                  Clear all
                </Button>
                <Button
                  id="apply-filters-button"
                  variant="contained"
                  onClick={handleApplyFilters}
                  sx={classes.applyButton}
                >
                  Apply
                </Button>
              </Box>
              <Box
                sx={{ ...classes.filterOptionContainer, marginBottom: '20px' }}
                id="status-filter"
              >
                Status
                <MultiSelect
                  handleFilterChange={(name, value) => handleFilterChange(name, value)}
                  selectOptions={statusOptions}
                  name={'status'}
                  filters={tempFilters.status}
                />
              </Box>
              <Box
                sx={{ ...classes.filterOptionContainer, marginBottom: '20px' }}
                id="country-filter"
              >
                Country
                <MultiSelect
                  handleFilterChange={(name, value) => handleFilterChange(name, value)}
                  selectOptions={countryOptions}
                  name={'country'}
                  filters={tempFilters.country}
                />
              </Box>
              <Box
                sx={{ ...classes.filterOptionContainer, marginBottom: '20px' }}
                id="suburb-filter"
              >
                Suburb
                <MultiSelect
                  handleFilterChange={(name, value) => handleFilterChange(name, value)}
                  selectOptions={suburbOptions}
                  name={'suburb'}
                  filters={tempFilters.suburb}
                />
              </Box>
              <Box
                sx={{ ...classes.filterOptionContainer, paddingBottom: '6px' }}
                id="rating-filter"
              >
                Rating
                <Box sx={{ padding: '0px 9px' }}>
                  <Slider
                    sx={sharedClasses.ratingSlider}
                    getAriaLabel={() => 'Applicant Rating'}
                    value={tempFilters.rating}
                    onChange={(event, value: number | number[]) =>
                      handleFilterChange('rating', value as number[])
                    }
                    valueLabelDisplay="on"
                    valueLabelFormat={(value: number) => `${value}%`}
                    getAriaValueText={(value: number) => `${value}%`}
                  />
                </Box>
              </Box>
              <Box sx={classes.checkboxContainer} id="unrated-filter">
                <FormControlLabel
                  sx={
                    !tempFilters.excludeApplicantsWithoutRating
                      ? { ...classes.filterSwitchLabels, ...classes.labelActive }
                      : classes.filterSwitchLabels
                  }
                  control={
                    <Switch
                      sx={
                        tempFilters.excludeApplicantsWithoutRating
                          ? classes.filterSwitchActive
                          : classes.filterSwitch
                      }
                      checked={tempFilters.excludeApplicantsWithoutRating}
                      onChange={() =>
                        handleFilterChange(
                          'excludeApplicantsWithoutRating',
                          !tempFilters.excludeApplicantsWithoutRating
                        )
                      }
                    />
                  }
                  label="Exclude unrated"
                  labelPlacement="start"
                />
              </Box>
            </Box>
          </Box>
        </Popover>
      </Box>
    </Tooltip>
  );
}
