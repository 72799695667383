import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import SearchResultsTableWithSpinner from './table_with_spinner.jsx';
import FilteringOptionsTable from './filtering_options_table';
import SearchBox from './search_box';
import SearchResultsPagination from './pagination.jsx';
import SearchResultsItem from './item.jsx';
import SearchResultsHeader from './header.jsx';

const SortingContext = createContext('');

export default class SearchResultsTable extends Component {
  constructor(props) {
    super(props);
    const results = { results: [], pagination: {} };
    const { query } = this.props;

    this.state = {
      results,
      query,
      pagination: false,
      isSearchSave: false,
      loading: true,
      by_title: false,
      filter_out_scout_client: true
    };
  }

  componentDidMount = () => {
    this.loadResults();
    document.getElementById('query').value = this.props.query.query;
  };

  loadResults = () => {
    this.setState({ pagination: true });
    const { token, searchEndpoint } = this.props;

    const params = Object.entries(this.state.query).reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
    params['token'] = token;

    const url_with_params = `${searchEndpoint}?${$.param(params)}`;

    fetch(url_with_params)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          results: data,
          query: this.state.query,
          loading: false,
          pagination: false,
          by_title: false,
          filter_out_scout_client: true
        })
      );
  };

  handleSorting = (sorting) => {
    let { query } = this.state;

    if (query.sorting === sorting) {
      query.sorting = `-${sorting}`;
    } else {
      query.sorting = sorting;
    }

    this.setState({ query }, this.loadResults);
  };

  handleSaveSearch = () => {
    const { query } = this.state;
    const $searchName = document.getElementById('#search_name');
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const searchName = $searchName ? $searchName.value : query.query.substring(0, 255);

    fetch(this.props.savedSearchEndpoint, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        saved_search: { name: searchName, params: query }
      }),
      credentials: 'same-origin'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response;
      })
      .then((response) => {
        this.setState({ isSearchSaved: true });
      })
      .catch((response) => {
        response.json().then((body) => {
          alert(Object.values(body.error).join(', '));
        });
      });
  };

  handlePagination = (url) => {
    let url_with_params = `${url}&token=${this.props.token}`;
    this.setState({ pagination: true });

    fetch(url_with_params)
      .then((response) => response.json())
      .then((results) => {
        this.setState({ results, pagination: false });
      });
  };

  handleFilterOnChange = (e) => {
    const { query } = this.state;
    query[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ query });
  };

  handleFilterOnSubmit = () => this.loadResults();

  renderHeader() {
    const { isSearchSaved } = this.state;

    return (
      <div id="titlebar" className="page-title search-toolbar">
        <div className="west">
          <div className="east">
            <div className="search-title">Found Items: {this.state.results.pagination.total}</div>
            <div className="search-header">
              {isSearchSaved && <button className="save-search-disabled">Save This Search</button>}
              {!isSearchSaved && <input type="text" id="search_name" placeholder="Search name" />}
              {!isSearchSaved && (
                <button className="save-search" onClick={this.handleSaveSearch}>
                  Save This Search
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  hasResults = () => {
    if (this.state.results.error !== undefined) {
      return false;
    } else {
      return this.state.results.results.length > 0;
    }
  };

  renderResultsWithSpinner = () => {
    return (
      <div>
        <div className="results-table">
          <SearchResultsHeader onSorting={this.handleSorting} />
          <SearchResultsTableWithSpinner
            items={this.state.results.results}
            color={this.props.color}
          />
        </div>

        <SearchResultsPagination
          pagination={this.state.results.pagination}
          onClick={this.handlePagination}
        />
      </div>
    );
  };

  renderResults = () => {
    return (
      <div>
        <div className="results-table">
          <SortingContext.Provider value={{ sorting: this.state.query.sorting }}>
            {<SearchResultsHeader onSorting={this.handleSorting} />}
          </SortingContext.Provider>
          <div className="body">
            {this.state.results.results.map((item, key) => {
              return <SearchResultsItem key={key} item={item.fields} />;
            })}
          </div>
        </div>
        <SearchResultsPagination
          pagination={this.state.results.pagination}
          onClick={this.handlePagination}
        />
      </div>
    );
  };

  renderError = () =>
    this.state.results.error !== undefined
      ? 'An error has happened during search. Contact Support Team.'
      : '';

  renderNoResults = () =>
    this.state.loading ? (
      <div className="loading-results">
        <ReactLoading type="spin" color={this.props.color} />
      </div>
    ) : (
      <p>No results found!</p>
    );

  defaultTags = (tags) => {
    const result = [];
    tags.forEach((skill) => result.push({ value: skill, label: skill }));
    return result;
  };

  render = () => {
    return (
      <div className="content">
        <SearchBox data={this.state.query} />
        <FilteringOptionsTable
          defaultStatuses={this.props.statuses}
          defaultTags={this.defaultTags(this.props.query.tags)}
          tags={this.defaultTags(this.props.tags)}
          canFilterScoutClients={this.props.canFilterScoutClients}
          onSubmit={this.handleFilterOnSubmit}
          onChange={this.handleFilterOnChange}
          data={this.state.query}
        />

        {this.renderError()}
        {this.hasResults() && this.renderHeader()}
        {this.hasResults() && this.state.pagination && this.renderResultsWithSpinner()}
        {this.hasResults() && !this.state.pagination && this.renderResults()}
        {!this.hasResults() && this.renderNoResults()}
      </div>
    );
  };
}

SearchResultsTable.propTypes = {
  query: PropTypes.object,
  token: PropTypes.string,
  searchEndpoint: PropTypes.string,
  savedSearchEndpoint: PropTypes.string,
  color: PropTypes.string,
  statuses: PropTypes.array,
  tags: PropTypes.array,
  canFilterScoutClients: PropTypes.bool
};
