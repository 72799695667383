import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Modal,
  Button,
  Autocomplete,
  TextField,
  Skeleton,
  CircularProgress
} from '@mui/material';
import MultiTagInput from '../../../Components/CustomUIElements/MultiTagInput';
import CancelButton from '../shared/CancelButton';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { IAutoSmsForwardProps } from '../../types';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { IApplicationStatus, ISMSTemplate } from '../../../Job/types';
import { stringToSeconds } from '../../../Components/Utilities/stringToSeconds';
import { secondsToString } from '../../../Components/Utilities/secondsToString';
import { getBodyFromSmsTemplateValue } from '../helpers/getBodyFromSmsTemplateValue';
import { useV4Api } from '../../../hooks/useV4Api';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DelaySelectWrapper from '../shared/DelaySelectWrapper';

export default function NewAutoSMSForward({
  jobId,
  apiKey,
  modalOpen,
  setModalOpen,
  autoForwards,
  setAutoForwards,
  applicationStatuses,
  smsTemplates,
  isLoadingTemplateList,
  forwardIndex,
  setSnackbar
}: IAutoSmsForwardProps) {
  const [tempPhones, setTempPhones] = useState<string[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [delay, setDelay] = useState('None');
  const [selectedStatus, setSelectedStatus] = useState<IApplicationStatus | null>(null);
  const [body, setBody] = useState('');
  const [errors, setErrors] = useState({
    status: '',
    template: '',
    phones: ''
  });
  const { apiPost, apiPut } = useV4Api({ apiKey });
  const smsTemplateOptions = smsTemplates.map(
    (template: ISMSTemplate) => `${template.id} - ${template.title}`
  );

  const refObject = {
    status: useRef<HTMLDivElement>(null),
    template: useRef<HTMLDivElement>(null),
    phones: useRef<HTMLDivElement>(null)
  };

  const handleSelectTemplate = useCallback(
    (value: string) => {
      setSelectedTemplate(value || null);
      setBody(getBodyFromSmsTemplateValue(value, smsTemplates));
    },
    [smsTemplates]
  );

  const phoneIsValid = (phone: string) => {
    const regex = /^\+?\(?[0-9]{1,4}\)?[- \.0-9]*$/;
    if (regex.test(String(phone).toLowerCase())) {
      setErrors((prev) => ({ ...prev, phones: '' }));
      return true;
    }

    return false;
  };

  const handleSubmit = async () => {
    let errorRef = '';
    if (!selectedStatus) {
      setErrors((prev) => ({ ...prev, status: 'Please select a status' }));
      errorRef = errorRef || 'status';
    }
    if (!selectedTemplate) {
      setErrors((prev) => ({ ...prev, template: 'Please select a template' }));
      errorRef = errorRef || 'template';
    }
    if (!tempPhones.length) {
      setErrors((prev) => ({ ...prev, phones: 'Please enter at least one phone number' }));
      errorRef = errorRef || 'phones';
    }
    if (errorRef) {
      refObject[errorRef as keyof typeof refObject].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      return;
    }
    try {
      setIsSending(true);
      const jsonBody = JSON.stringify({
        trigger_status_id: selectedStatus?.id,
        sms_template_id: selectedTemplate?.split(' -')[0],
        phones: tempPhones.join(', '),
        delay: stringToSeconds(delay)
      });
      if (forwardIndex === -1) {
        const response = await apiPost(`/jobs/${jobId}/auto_sms_forwards`, jsonBody);
        if (response.ok) {
          const data = await response.json();
          setAutoForwards((prev) => [...prev, data]);
          setSnackbar({
            message: 'Auto forward created successfully',
            state: 'success'
          });
          setModalOpen(false);
        } else {
          throw new Error('Error creating new auto forward');
        }
      } else {
        const response = await apiPut(`/jobs/${jobId}/auto_sms_forwards/${autoForwards[forwardIndex].id}`, jsonBody);
        if (response.ok) {
          const data = await response.json();
          setAutoForwards((prev) => {
            const newForward = [...prev];
            newForward[forwardIndex] = data;
            return newForward;
          });
          setSnackbar({
            message: 'Auto forward updated successfully',
            state: 'success'
          });
          setModalOpen(false);
        } else {
          throw new Error('Error updating auto forward');
        }
      }
    } catch (error: any) {
      setSnackbar({
        message: error.message || 'Something went wrong, please try again',
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    setErrors({
      status: '',
      template: '',
      phones: ''
    });
    if (forwardIndex === -1) {
      setTempPhones([]);
      setSelectedTemplate(null);
      setDelay('None');
      setSelectedStatus(null);
      setBody('');
      return;
    }
    setTempPhones(autoForwards[forwardIndex].phones);
    setSelectedStatus(
      applicationStatuses.find(
        (status) => status.id === autoForwards[forwardIndex].trigger_status_id
      ) || null
    );
    setDelay(secondsToString(autoForwards[forwardIndex].delay));
  }, [applicationStatuses, autoForwards, forwardIndex]);

  useEffect(() => {
    if (forwardIndex !== -1) {
      const smsTemplate =
        smsTemplateOptions.find(
          (template) =>
            template.split(' - ')[0] === autoForwards[forwardIndex].sms_template_id.toString()
        ) || null;
      setSelectedTemplate(smsTemplate);
      if (smsTemplate) {
        handleSelectTemplate(smsTemplate);
      }
    }
  }, [autoForwards, smsTemplates, forwardIndex, handleSelectTemplate]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby='new-auto-sms-forward-modal'
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={() => setModalOpen(false)} sx={classes.closeIcon} />
        <Box sx={classes.actionModalHeader}>{`${
          forwardIndex === -1 ? 'New' : 'Edit'
        } auto SMS forward`}</Box>
        <Box
          sx={classes.modalContent}
          aria-label={`${forwardIndex === -1 ? 'New' : 'Edit'} auto sms forward modal content`}
        >
          <Box
            role='form'
            id='auto-sms-forward-form'
            aria-label='Auto sms forward form'
            sx={{ height: '100%', overflowX: 'clip' }}
          >
            <Box
              sx={{ ...classes.actionModalFormLine, marginTop: '20px', marginBottom: '40px' }}
              ref={refObject.status}
            >
              <Autocomplete
                id='trigger-status-autocomplete'
                data-testid='trigger-status-autocomplete-autoforward'
                options={applicationStatuses}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  setSelectedStatus(value);
                  setErrors((prev) => ({ ...prev, status: '' }));
                }}
                value={selectedStatus}
                sx={{ ...sharedClasses.formAutocomplete, width: '287px' }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Trigger status'
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                renderOption={(props, option, state) => {
                  return (
                    <li {...props} key={option.id} data-testid={`option-${state.index}`}>
                      {option.name}
                    </li>
                  );
                }}
              />
              {errors.status && <Box sx={classes.formError}>{errors.status}</Box>}
            </Box>
            <Box>
              <Box sx={classes.actionModalFormLine} ref={refObject.template}>
                <Virtualize
                  value={selectedTemplate}
                  passOptions={smsTemplateOptions}
                  setValue={(value: string) => {
                    handleSelectTemplate(value);
                    setErrors((prev) => ({ ...prev, template: '' }));
                  }}
                  passedStyles={{
                    ...sharedClasses.formAutocomplete,
                    width: '600px',
                    marginTop: '20px'
                  }}
                  label='Template'
                  loading={isLoadingTemplateList}
                  loadingText='Loading template list...'
                />
                {errors.template && <Box sx={classes.formError}>{errors.template}</Box>}
              </Box>
              {(selectedTemplate || forwardIndex !== -1) &&
                (forwardIndex !== -1 && !selectedTemplate ? (
                  <Skeleton
                    variant='rectangular'
                    animation='wave'
                    height={400}
                    width={'96%'}
                    sx={classes.previewSkeleton}
                  />
                ) : (
                  <Box sx={{ ...classes.htmlContent }}>
                    <Box sx={{ ...classes.previewHeader }}>
                      <VisibilityIcon />
                      Preview
                    </Box>
                    <Box>{body}</Box>
                  </Box>
                ))}
            </Box>
            <Box sx={classes.actionModalFormLine} ref={refObject.phones}>
              <MultiTagInput
                tags={tempPhones}
                setTags={(phones) => {
                  setTempPhones(phones);
                  setErrors((prev) => ({ ...prev, phones: '' }));
                }}
                validation={phoneIsValid}
                validationMessage='Must input valid phone'
                label='Recipient phone number/s'
                width='600px'
                error={errors.phones}
              />
            </Box>
            <DelaySelectWrapper delay={delay} setDelay={setDelay} />
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <CancelButton scope='auto-sms-forward' setModalOpen={setModalOpen} />
          <Button
            id='send-auto-forward-button'
            data-testid='send-auto-forward-button'
            type='submit'
            sx={{ ...classes.modalSendButton, width: '71px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSending ? <CircularProgress size={20} color='inherit' /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
