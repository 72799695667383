import { Box, Skeleton } from '@mui/material';
import React from 'react';

const LoadingTableItems = ({ numberOfRows }: { numberOfRows?: number }) => {
  return (
    <>
      {[...Array(numberOfRows || 5)].map((_, index) => (
        <Box key={index} sx={skeletonContaineStyle}>
          <Skeleton animation="wave" height={60} />
        </Box>
      ))}
    </>
  );
};

const skeletonContaineStyle = {
  borderBottom: '1px solid #DDDDDD',
  padding: '10px 0px'
};

export default LoadingTableItems;
