"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import queryString, { stringify } from "query-string";
const BASE_URL = window.location.origin;
class Api {
  constructor() {
    this.handleResponse = (response) => {
      if (response.ok) return response.json();
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    };
    this.handlePaginatedResponse = (response) => __async(this, null, function* () {
      if (response.ok) {
        return {
          data: yield response.json(),
          totalItems: Number(response.headers.get("X-Total-Count")),
          totalPages: Number(response.headers.get("X-Total-Pages"))
        };
      }
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    });
    this.getPaginated = (path, params = {}, headers) => fetch(
      `${BASE_URL}${path}?${queryString.stringify(params, {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: "bracket",
        encode: false
      })}`,
      {
        headers: this.getHeaders(headers)
      }
    ).then((r) => this.handlePaginatedResponse(r));
    this.get = (path, params = {}, headers) => fetch(
      `${BASE_URL}${path}?${queryString.stringify(params, {
        arrayFormat: "bracket",
        encode: false
      })}`,
      {
        headers: this.getHeaders(headers)
      }
    ).then((r) => this.handleResponse(r));
    this.post = (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "POST",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.put = (path, params = {}, headers) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers != null ? headers : {}),
      method: "PUT",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.delete = (path, params = {}, headers) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "DELETE",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.getJob = (jobId, apiKey) => this.get(`/api/v4/jobs/${jobId}`, {}, apiKey);
    this.getJobs = () => this.get(`/api/v4/jobs/jobs_for_task`, {}, {});
    this.getActiveSeekAd = (jobId, apiKey) => this.get(`/api/v4/seek/jobs/${jobId}/job_ads`, {}, apiKey);
    this.getJobCounts = (jobId, apiKey) => this.get(`/api/v4/jobs/${jobId}/counters`, {}, apiKey);
    this.putChangedState = (link, apiKey) => this.put(link, {}, apiKey);
    this.putChangedStatus = (link, apiKey) => this.put(link, {}, apiKey);
    this.getUserPermissions = (apiKey) => this.get(`/api/v4/user_permissions`, {}, apiKey);
    this.getGraphData = (url, apiKey, props) => this.get(url, props, apiKey);
    this.getJobAttachments = (apiKey, jobId, params) => this.getPaginated(`/api/v4/jobs/${jobId}/job_attachments`, params, apiKey);
    this.deleteJobAttachment = (apiKey, jobId, attachmentId) => this.delete(`/api/v4/jobs/${jobId}/job_attachments/${attachmentId}`, {}, apiKey);
    this.getNotes = (apiKey, jobId) => this.get(`/api/v4/jobs/${jobId}/job_notes`, {}, apiKey);
    this.getTask = (apiKey, jobId) => this.get(`/api/v4/tasks/assignable_users`, {}, apiKey);
    this.getMoveJobs = (apiKey, jobId) => this.get(`/api/v4/jobs/${jobId}/target_entities`, {}, apiKey);
    this.moveJob = (apiKey, jobId, entityId) => this.post(`/api/v4/jobs/${jobId}/move?entity_id=${entityId}`, {}, apiKey);
    this.newNote = (apiKey, jobId, params) => this.post(`/api/v4/jobs/${jobId}/job_notes`, params, apiKey);
    this.newTask = (apiKey, params, query) => this.post(`/api/v4/tasks?${query && stringify(query)}`, params, apiKey);
    this.putNote = (apiKey, jobId, noteId, params) => this.put(`/api/v4/jobs/${jobId}/job_notes/${noteId}`, params, apiKey);
    this.deleteNote = (apiKey, jobId, noteId) => this.delete(`/api/v4/jobs/${jobId}/job_notes/${noteId}`, {}, apiKey);
    this.getEmailStatuses = (apiKey, jobId, params) => this.getPaginated(`/api/v4/jobs/${jobId}/job_email_statuses`, params, apiKey);
    this.cancelEmailBatch = (jobId, emailStatusId) => this.post(`/api/v4/jobs/${jobId}/job_email_statuses/${emailStatusId}/cancel`);
    this.getApplications = (apiKey, jobId, params) => this.getPaginated(`/api/v4/jobs/${jobId}/applications`, params, apiKey);
    this.getAllApplications = (apiKey, jobId, params) => this.getPaginated(`/api/v4/jobs/${jobId}/applications`, params, apiKey);
    this.deleteApplications = (url, apiKey, params) => this.delete(url, params, apiKey);
    this.getApplicationStatuses = (apiKey, jobId, params) => this.getPaginated(`/api/v4/jobs/${jobId}/job_application_statuses`, params, apiKey);
    this.getFilterableStatuses = (apiKey, jobId, params) => this.getPaginated(
      `/api/v4/jobs/${jobId}/job_application_statuses/filterable_statuses`,
      params,
      apiKey
    );
    this.getFilterableCountries = (apiKey, jobId, params) => this.getPaginated(`/api/v4/jobs/${jobId}/applications/countries`, params, apiKey);
    this.getFilterableSuburbs = (apiKey, jobId, params) => this.getPaginated(`/api/v4/jobs/${jobId}/applications/suburbs`, params, apiKey);
    this.getWorkflows = (apiKey, params, jobId) => this.getPaginated(`/api/v4/workflows?job_id=${jobId}`, params, apiKey);
    this.assignWorkflow = (apiKey, params, jobId) => this.post(`/api/v4/workflows?job_id=${jobId}`, params, apiKey);
    this.getRevision = (apiKey, jobId, params) => this.getPaginated(`/api/v4/jobs/${jobId}/job_revisions`, params, apiKey);
    this.getRatingSets = (apiKey, jobId) => this.getPaginated(`/api/v4/jobs/${jobId}/rating_sets`, {}, apiKey);
    this.getRaters = (apiKey, jobId) => this.getPaginated(`/api/v4/jobs/${jobId}/rating_sets/raters`, {}, apiKey);
    this.deleteRatingSet = (apiKey, jobId, setId) => this.delete(`/api/v4/jobs/${jobId}/rating_sets/${setId}`, {}, apiKey);
    this.deleteRaterSet = (apiKey, jobId, userId) => this.delete(`/api/v4/jobs/${jobId}/rating_sets/bulk_destroy?user_id=${userId}`, {}, apiKey);
    this.revertRevision = (apiKey, jobId, id) => this.post(`/api/v4/jobs/${jobId}/job_revisions/${id}/revert`, {}, apiKey);
    this.putUnarchiveJob = (apiKey, params) => this.put(`/api/v4/jobs/unarchive`, params, apiKey);
    this.putApplicationStatus = (apiKey, jobId, applicationId, statusId) => this.put(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/application_status/${statusId}`,
      {},
      apiKey
    );
    this.newField = (apiKey, jobId, params) => this.post(`/api/v4/fields?job_id=${jobId}`, params, apiKey);
    this.addSystemField = (apiKey, jobId, systemFieldId) => this.post(`/api/v4/system_fields?job_id=${jobId}&system_field_id=${systemFieldId}`, {}, apiKey);
    this.deleteQuestion = (apiKey, id, jobId) => this.delete(`/api/v4/fields/${id}?job_id=${jobId}`, {}, apiKey);
    this.cloneQuestion = (apiKey, id, jobId) => this.post(`/api/v4/fields/${id}/clone?job_id=${jobId}`, {}, apiKey);
    this.putQuestion = (apiKey, id, jobId, params) => this.put(`/api/v4/fields/${id}?job_id=${jobId}`, params, apiKey);
    this.newHRField = (apiKey, jobId, applicationQuestionTemplateId, params) => this.post(
      `/api/v4/fields?application_question_template_id=${applicationQuestionTemplateId}`,
      params,
      apiKey
    );
    this.putHRQuestion = (apiKey, id, jobId, applicationQuestionTemplateId, params) => this.put(
      `/api/v4/fields/${id}?application_question_template_id=${applicationQuestionTemplateId}`,
      params,
      apiKey
    );
    this.addHRQuestionSystemField = (apiKey, jobId, systemFieldId, applicationQuestionTemplateId) => this.post(
      `/api/v4/system_fields?system_field_id=${systemFieldId}&application_question_template_id=${applicationQuestionTemplateId}`,
      {},
      apiKey
    );
    this.deleteHRQuestion = (apiKey, id, jobId, applicationQuestionTemplateId) => this.delete(
      `/api/v4/fields/${id}?application_question_template_id=${applicationQuestionTemplateId}`,
      {},
      apiKey
    );
    this.referenceValidation = (apiKey, params) => this.get(`/api/v4/jobs/validating_reference`, params, apiKey);
    this.getJobTimezones = (apiKey) => this.get(`/api/v4/timezones`, {}, apiKey);
    this.getJobBrand = (apiKey, jobId) => this.get(`/api/v4/jobs/${jobId}/brands`, {}, apiKey);
    this.getJobAutoRaters = (apiKey, jobId) => this.get(`/api/v4/jobs/${jobId}/auto_raters`, {}, apiKey);
    this.getJobPanelChairs = (apiKey, jobId) => this.get(`/api/v4/panel_chairs?job_id=${jobId}`, {}, apiKey);
    this.getJobrequisitions = (apiKey, params) => this.get(`/api/v4/requisitions`, params, apiKey);
    this.getJobClassifications = (apiKey) => this.get(`/api/v4/job_classifications`, {}, apiKey);
    this.getJobAssignableUsers = (apiKey, jobId) => this.get(`/api/v4/assignable_users?job_id=${jobId}`, {}, apiKey);
    this.getSalesPeople = (apiKey) => this.get(`/api/v4/sales_people`, {}, apiKey);
    this.getRMteamMembers = (apiKey) => this.get(`/api/v4/rm_team_members`, {}, apiKey);
    this.getJobLocales = (apiKey) => this.get(`/api/v4/locales`, {}, apiKey);
    this.updateJob = (apiKey, jobId, params) => this.put(`/api/v4/jobs/${jobId}`, params, apiKey);
    this.getEvents = (date, apiKey, typeId) => this.get(
      "/api/v4/calendar/events",
      { date, event_type_id: typeId || void 0, override_limit: true },
      apiKey
    );
    this.getSmsTemplates = (apiKey) => this.get("/api/v4/sms_templates", {}, apiKey);
    this.saveSmsTemplate = (apiKey, smsTemplateName, smsMessage) => this.post(
      `/api/v4/sms_templates`,
      {
        sms_template: {
          title: smsTemplateName,
          body: smsMessage
        }
      },
      apiKey
    );
    this.getEmailTemplates = (apiKey) => this.get(`/api/v4/calendar/email_templates/grouped_email_templates`, {}, apiKey);
    this.getEmailTemplate = (apiKey, templateId) => this.get(`/api/v4/calendar/email_templates/${templateId}`, {}, apiKey);
    this.getRecruiters = (apiKey) => this.get(`/api/v4/calendar/users`, {}, apiKey);
    this.getPlaceholders = (apiKey, type) => this.get(`/api/v4/calendar/email_templates/placeholders`, { type: type || "" }, apiKey);
    this.sendSms = (apiKey, jobId, eventId, applicationIds, content) => this.post(
      `/api/v4/jobs/${jobId}/applications/bulk_sms`,
      {
        event_id: eventId,
        applications_ids: applicationIds,
        content,
        added: true
      },
      apiKey
    );
    this.addToEventSms = (apiKey, jobId, eventId, applicationIds, content) => this.post(
      `/api/v4/jobs/${jobId}/applications/bulk_sms/add_to_event`,
      {
        event_id: eventId,
        applications_ids: applicationIds,
        content,
        added: true
      },
      apiKey
    );
    this.sendInviteSms = (apiKey, jobId, eventTypeId, applicationIds, content) => this.post(
      `/api/v4/jobs/${jobId}/applications/bulk_sms/invite_to_timeslot`,
      {
        event_type_id: eventTypeId,
        applications_ids: applicationIds,
        content,
        invited: true
      },
      apiKey
    );
    this.sendEmail = (apiKey, jobId, eventId, emailTemplateId, content, applicationIds) => this.post(
      `/api/v4/jobs/${jobId}/applications/email`,
      {
        email: {
          event_id: eventId,
          email_template_id: emailTemplateId,
          content,
          application_ids: applicationIds
        }
      },
      apiKey
    );
    this.updateNote = (jobId, applicationId, params) => this.put(`/api/v4/jobs/${jobId}/applications/${applicationId}/update_notepad`, params);
    this.updateNotes = (jobId, applicationId, params) => this.post(`/api/v4/jobs/${jobId}/applications/${applicationId}/notes`, params);
  }
  getHeaders(headers) {
    return Object.assign(
      {},
      {
        "Content-Type": "application/json"
      },
      headers
    );
  }
}
export default new Api();
