import React, { useCallback } from 'react';
import DataVizSection from './index';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReportServiceApi from '../ReportServiceApi';
import { CurrentUser, EntityRecord } from '../types';
import { ReportContext } from '../index';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export const Standalone = ({
  accessible_entities,
  user,
  report_service_url
}: {
  accessible_entities: EntityRecord;
  user: CurrentUser;
  report_service_url: string;
}) => {
  const reportServiceApi = useCallback(
    () => new ReportServiceApi(accessible_entities, user, report_service_url),
    [accessible_entities, user, report_service_url]
  );
  return (
    <ReportContext.Provider value={reportServiceApi()}>
      <QueryClientProvider client={queryClient}>
        <DataVizSection />
      </QueryClientProvider>
    </ReportContext.Provider>
  );
};

export default Standalone;
