"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  widget: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    flex: "40%",
    height: "420px",
    minWidth: "600px",
    padding: "30px",
    paddingBottom: "20px"
  },
  titleAndDateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    zIndex: 1,
    marginBottom: "20px"
  },
  title: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#333333",
    marginBottom: "4px"
  },
  loading: {
    zIndex: "0",
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    width: "100%",
    top: "0",
    transition: "all 300ms ease-in-out",
    opacity: "0"
  },
  chart: {
    zIndex: "0",
    width: "100%",
    height: "100%"
  },
  noDataContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%"
  },
  noData: {
    color: "#AAAAAA",
    fontSize: "18px",
    fontWeight: "600"
  },
  noDataIcon: {
    color: "#AAAAAA",
    fontSize: "48px",
    marginBottom: "10px"
  },
  date: {
    width: "270px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "& span": {
      color: "#AAAAAA",
      marginBottom: "4px"
    }
  },
  datePicker: {
    fontFamily: "Source Sans Pro",
    color: "#666666",
    width: "119px",
    "& input": {
      padding: "0px 4px 4px 10px",
      fontFamily: "Source Sans Pro"
    },
    "& fieldset": {
      "& legend": {
        display: "none"
      }
    },
    "& button": {
      padding: "0px",
      transform: "translate(-2px, -1.5px)",
      "&:hover": {
        background: "transparent",
        "& svg": {
          color: "#888888"
        }
      }
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0px"
    },
    "& svg": {
      color: "#AAAAAA"
    }
  },
  calendar: {
    "& .PrivatePickersFadeTransitionGroup-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.action.main} !important`
    },
    "& .PrivatePickersSlideTransition-root": {
      minHeight: "242px !important"
    }
  },
  error: {
    color: "#FF0000",
    fontSize: "14px",
    position: "absolute",
    top: "3px",
    right: "29px"
  }
};
