import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import RuleIcon from '@mui/icons-material/Rule';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import MessageIcon from '@mui/icons-material/Message';
import DeleteIcon from '@mui/icons-material/Delete';
import { Event as EventIcon } from '@mui/icons-material';
import { ExternalEmailIcon, PDFIcon } from './CustomIcons';
import { classes } from './styles';
import { EntityContext } from '../Job';

export default function ApplicationsActionsPanel({
  selected,
  setModalsOpen,
  userPermissions,
  handleOpenAIInsights
}: {
  selected: number[];
  setModalsOpen: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  userPermissions: Record<string, Record<string, boolean>>;
  handleOpenAIInsights: () => void;
}) {
  const { ai_studio_summaries_enabled, foundu_enabled, foundu_auth_url } = useContext(EntityContext);

  const handleFounduExport = () => {
    if (foundu_auth_url) {
      location.href = foundu_auth_url;
    } else {
      setModalsOpen((prev) => ({ ...prev, exportToFoundu: true }))
    }
  }

  return (
    <Collapse in={selected?.length > 0}>
      <Box sx={classes.actionsContianer}>
        {userPermissions?.['Applications']?.['Send Bulk Emails'] && (
          <Button
            id="email-action-applications-button"
            variant="outlined"
            startIcon={<EmailIcon />}
            sx={classes.actionButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, email: true }))}
          >
            Email
          </Button>
        )}
        {userPermissions?.['Applications']?.['Send SMS in bulk'] && (
          <Button
            id="sms-action-applications-button"
            variant="outlined"
            startIcon={<StayCurrentPortraitIcon />}
            sx={classes.actionButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, sms: true }))}
          >
            SMS
          </Button>
        )}
        {userPermissions?.['Applications']?.['Change status in bulk'] && (
          <Button
            id="change-status-action-applications-button"
            variant="outlined"
            startIcon={<RuleIcon />}
            sx={classes.actionButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, changeStatus: true }))}
          >
            Change status
          </Button>
        )}
        <Button
          id="add-to-event-action-applications-button"
          variant="outlined"
          startIcon={<EventIcon />}
          sx={classes.actionButton}
          onClick={() => setModalsOpen((prev) => ({ ...prev, addToEvent: true }))}
        >
          Add to Event
        </Button>
        {userPermissions?.['Applications']?.['Bulk Invite to Timeslot'] && (
          <Button
            id="invite-to-timeslot-action-applications-button"
            variant="outlined"
            startIcon={<GroupAddIcon />}
            sx={classes.actionButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, inviteToTimeslot: true }))}
          >
            Invite to timeslot
          </Button>
        )}
        {userPermissions?.['Applications']?.['Email/Bulk Email External'] && (
          <Button
            id="external-email-action-applications-button"
            variant="outlined"
            startIcon={<ExternalEmailIcon />}
            sx={classes.actionButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, externalEmail: true }))}
          >
            External email
          </Button>
        )}
        {userPermissions?.['Applications']?.['Bulk PDF'] && (
          <Button
            id="export-pdf-action-applications-button"
            variant="outlined"
            startIcon={<PDFIcon />}
            sx={classes.actionButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, exportToPdf: true }))}
          >
            Export to PDF
          </Button>
        )}
        {userPermissions?.['Applications']?.['Bulk Move Applications'] && (
          <Button
            id="move-action-applications-button"
            variant="outlined"
            startIcon={<DriveFileMoveIcon />}
            sx={classes.actionButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, move: true }))}
          >
            Move
          </Button>
        )}
        {userPermissions?.['Applications']?.['Bulk Export To Onboard'] && (
          <Button
            id="export-onboard-action-applications-button"
            variant="outlined"
            startIcon={<DriveFileMoveIcon />}
            sx={classes.actionButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, exportToOnboard: true }))}
          >
            Export to Onboard
          </Button>
        )}
        {foundu_enabled && (
          <Button
            id="export-foundu-button"
            variant="outlined"
            startIcon={<DriveFileMoveIcon />}
            sx={classes.actionButton}
            onClick={handleFounduExport}
          >
            Export to FoundU
          </Button>
        )}
        {ai_studio_summaries_enabled && (
          <Button
            id="ai-insights-action-applications-button"
            variant="outlined"
            startIcon={<MessageIcon />}
            sx={classes.actionButtonAI}
            onClick={handleOpenAIInsights}
          >
            AI candidate insights
          </Button>
        )}
        {userPermissions?.['Trash']?.['Trash Application'] && (
          <Button
            id="trash-action-applications-button"
            variant="outlined"
            startIcon={<DeleteIcon />}
            sx={{ ...classes.actionButton, ...classes.deleteApplicant }}
            onClick={() => setModalsOpen((prev) => ({ ...prev, delete: true }))}
          >
            Trash
          </Button>
        )}
      </Box>
    </Collapse>
  );
}
