import React, { Dispatch, useContext } from 'react';
import { TableWithSearchFieldDropDownAndFilter } from '../../../../TableWithSearchFieldDropDownAndFilter';
import { CustomReportsActionType, CustomReportsStateType } from '../types';
import ReportTableBody from './ReportTableBody';
import { useMutation } from '@tanstack/react-query';
import { ReportContext } from '../../index';

const CustomReportsTable = ({
  tableState,
  dispatch,
  refetchForms
}: {
  tableState: CustomReportsStateType;
  dispatch: Dispatch<CustomReportsActionType>;
  refetchForms: () => void;
}) => {
  const reportApiService = useContext(ReportContext);
  const deleteReport = useMutation({
    mutationFn: (id: number) => reportApiService.reports.delete(id),
    onSuccess: () => refetchForms(),
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: 'Failed to delete report', state: 'error' }
      })
  });

  return (
    <TableWithSearchFieldDropDownAndFilter
      tableState={tableState}
      dispatch={dispatch}
      tableBody={
        <ReportTableBody
          state={tableState}
          dispatch={dispatch}
          deleteReport={(id: number) => deleteReport.mutate(id)}
        />
      }
      fetchData={() => refetchForms()}
      filtersComponent={null}
      fieldComponent={false}
      stickyColumnSettings={{ leftBoundOffSet: -267, rightBoundOffSet: -225 }}
      pendoId={'report-builder'}
    />
  );
};

export default CustomReportsTable;
