var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Checkbox,
  TextField,
  FormHelperText,
  Grid,
  FormControlLabel,
  Button
} from "@material-ui/core";
import useStyles from "../useStyles";
import PropTypes from "prop-types";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
function Step2({
  answers,
  setAnswers,
  getSeekAdBrandingData,
  setGetSeekAdBrandingData,
  apiKey,
  jobId,
  activeError,
  requiredRefs
}) {
  const [loadingSeekAdBranding, setLoadingSeekAdBranding] = useState(true);
  const [seekBrandindInfo, setSeekBrandindInfo] = useState(null);
  const classes = useStyles();
  const shortDescriptionLength = 150;
  const getSeekAdType = (url, nextPage) => __async(this, null, function* () {
    !nextPage && setLoadingSeekAdBranding(true);
    try {
      const { data } = yield axios.get(url, {
        headers: { "X-api-authenticate": apiKey }
      });
      setGetSeekAdBrandingData(
        nextPage ? [...getSeekAdBrandingData, ...data.data.advertisementBrandings.edges] : data.data.advertisementBrandings.edges
      );
      setSeekBrandindInfo(data.data.advertisementBrandings.pageInfo);
      if (!answers.branding_id)
        setAnswers(__spreadProps(__spreadValues({}, answers), {
          branding_id: data.data.advertisementBrandings.edges[0].node.id.value
        }));
      setLoadingSeekAdBranding(false);
    } catch (e) {
      setGetSeekAdBrandingData(null);
      setLoadingSeekAdBranding(false);
    }
  });
  useEffect(() => {
    if (!getSeekAdBrandingData) {
      getSeekAdType(`/api/v4/seek/jobs/${jobId}/brands`, false);
    } else setLoadingSeekAdBranding(false);
  }, []);
  return /* @__PURE__ */ React.createElement("div", { className: classes.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Job details"), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.search_title,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Search Results Title"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.search_title,
        className: classes.textField,
        variant: "outlined",
        value: answers.search_title,
        onChange: (e) => e.target.value.length >= 0 && setAnswers(__spreadProps(__spreadValues({}, answers), { search_title: e.target.value })),
        InputProps: {
          endAdornment: activeError === requiredRefs.search_title && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
        }
      }
    )
  ), /* @__PURE__ */ React.createElement(Grid, { container: true, style: { marginTop: 5 } }, /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: "Same as Job Title",
      className: classes.checkboxWrapper,
      control: /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          checked: answers.title === answers.search_title,
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), {
            search_title: answers.title === answers.search_title ? "" : answers.title
          }))
        }
      )
    }
  )), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.description,
      fullWidth: true,
      className: `${classes.formControl} ${classes.large}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Short Description"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.description,
        multiline: true,
        className: `${classes.textField} ${classes.textBox}`,
        variant: "outlined",
        value: answers.description,
        onChange: (e) => e.target.value.length <= shortDescriptionLength && setAnswers(__spreadProps(__spreadValues({}, answers), { description: e.target.value })),
        InputProps: {
          endAdornment: activeError === requiredRefs.description && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
        }
      }
    ),
    /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, answers.description.length, "/", shortDescriptionLength)
  ), answers.ad_type !== "Classic" && /* @__PURE__ */ React.createElement("div", { style: { display: "grid" } }, /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.large}` }, /* @__PURE__ */ React.createElement(FormLabel, { className: classes.label }, "Key Selling Points (Optional)"), /* @__PURE__ */ React.createElement("span", { className: classes.subLabel }, "Enter 3 key selling points to attract candidates to view your role"), /* @__PURE__ */ React.createElement(
    TextField,
    {
      className: classes.textField,
      variant: "outlined",
      value: answers.stand_out_bullet_1,
      onChange: (e) => e.target.value.length <= 80 && setAnswers(__spreadProps(__spreadValues({}, answers), { stand_out_bullet_1: e.target.value }))
    }
  ), /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, answers.stand_out_bullet_1 ? answers.stand_out_bullet_1.length : 0, "/80")), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.large}` }, /* @__PURE__ */ React.createElement(
    TextField,
    {
      className: classes.textField,
      variant: "outlined",
      value: answers.stand_out_bullet_2,
      onChange: (e) => e.target.value.length <= 80 && setAnswers(__spreadProps(__spreadValues({}, answers), { stand_out_bullet_2: e.target.value }))
    }
  ), /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, answers.stand_out_bullet_2 ? answers.stand_out_bullet_2.length : 0, "/80")), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.large}` }, /* @__PURE__ */ React.createElement(
    TextField,
    {
      className: classes.textField,
      variant: "outlined",
      value: answers.stand_out_bullet_3,
      onChange: (e) => e.target.value.length <= 80 && setAnswers(__spreadProps(__spreadValues({}, answers), { stand_out_bullet_3: e.target.value }))
    }
  ), /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, answers.stand_out_bullet_3 ? answers.stand_out_bullet_3.length : 0, "/80"))), answers.ad_type !== "Classic" && /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl}` }, /* @__PURE__ */ React.createElement(FormLabel, { style: { paddingBottom: "10px" }, className: classes.label }, "Branding"), loadingSeekAdBranding ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" }) : /* @__PURE__ */ React.createElement(Grid, { container: true, style: { margin: 0 }, spacing: 2 }, getSeekAdBrandingData ? getSeekAdBrandingData.map((brand) => /* @__PURE__ */ React.createElement(
    Grid,
    {
      item: true,
      xs: 12,
      sm: 12,
      md: 4,
      key: brand.node.id.value,
      style: {
        height: "230px",
        maxWidth: "215px",
        minWidth: "215px",
        cursor: "pointer",
        border: `solid ${answers.branding_id === brand.node.id.value ? "#5BC4BF" : "#e9e8e8"} 1px`,
        borderRadius: "5px",
        marginRight: "10px",
        marginBottom: "10px",
        padding: 0
      }
    },
    /* @__PURE__ */ React.createElement(RenderList, { brand: brand.node, setAnswers, answers })
  )) : /* @__PURE__ */ React.createElement("div", null, "No Branding."), getSeekAdBrandingData && seekBrandindInfo && /* @__PURE__ */ React.createElement("div", null, seekBrandindInfo.hasNextPage ? /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: classes.buttonSecondary,
      style: { minWidth: 130 },
      onClick: () => {
        getSeekAdType(
          `/api/v4/seek/jobs/${jobId}/brands?after=${seekBrandindInfo.endCursor}`,
          true
        );
      }
    },
    "Load More Branding"
  ) : ""))), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`,
      ref: requiredRefs.video_embed_code
    },
    /* @__PURE__ */ React.createElement(FormLabel, { className: classes.label }, "Video URL"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        className: classes.textField,
        error: activeError === requiredRefs.video_embed_code,
        variant: "outlined",
        value: answers.video_embed_code,
        onChange: (e) => e.target.value.length >= 0 && setAnswers(__spreadProps(__spreadValues({}, answers), { video_embed_code: e.target.value }))
      }
    ),
    activeError === requiredRefs.video_embed_code ? /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement("div", { style: { display: "flex", marginTop: "0.5rem" }, className: classes.errorMessage }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("div", { className: "seekValidationErrorMsg" }, "URL must start with https://")), /* @__PURE__ */ React.createElement("div", { style: { display: "flex", marginTop: "0.5rem" }, className: classes.errorMessage }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("div", { className: "seekValidationErrorMsg" }, "Seek requires a YouTube link in one of the following formats:")), /* @__PURE__ */ React.createElement("div", { style: { display: "flex", marginTop: "0.5rem" }, className: classes.errorMessage }, /* @__PURE__ */ React.createElement("div", { className: classes.seekYoutubeUrl }, /* @__PURE__ */ React.createElement("div", null, " https://www.youtube.com/embed/aAgePQvHBQM"), /* @__PURE__ */ React.createElement("div", { style: { padding: "0.25rem 0" } }, " ", "https://www.youtube.com/watch?v=aAgePQvHBQM"), /* @__PURE__ */ React.createElement("div", null, " https://youtu.be/aAgePQvHBQM")))) : /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, "The URL you require will look like this: https://youtube.com/embed/abcd12345")
  ), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { className: classes.label }, "Video Position"), /* @__PURE__ */ React.createElement(Grid, { container: true, style: { margin: 0 }, spacing: 2 }, /* @__PURE__ */ React.createElement(Grid, { style: { marginRight: "10px" } }, /* @__PURE__ */ React.createElement(
    "div",
    {
      id: "seek-video-top",
      onClick: () => setAnswers(__spreadProps(__spreadValues({}, answers), { video_position: "Above" })),
      style: {
        border: `solid ${answers.video_position === "Above" ? "#5BC4BF" : "#e9e8e8"} 1px`,
        borderRadius: "5px",
        cursor: "pointer"
      }
    }
  )), /* @__PURE__ */ React.createElement(Grid, null, /* @__PURE__ */ React.createElement(
    "div",
    {
      id: "seek-video-bottom",
      onClick: () => setAnswers(__spreadProps(__spreadValues({}, answers), { video_position: "Below" })),
      style: {
        border: `solid ${answers.video_position === "Below" ? "#5BC4BF" : "#e9e8e8"} 1px`,
        borderRadius: "5px",
        cursor: "pointer"
      }
    }
  )))));
}
Step2.propTypes = {
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
  apiKey: PropTypes.string.isRequired,
  getSeekAdBrandingData: PropTypes.array || PropTypes.null,
  setGetSeekAdBrandingData: PropTypes.func.isRequired,
  requiredRefs: PropTypes.object.isRequired,
  activeError: PropTypes.ref
};
export default Step2;
const RenderList = ({ brand, setAnswers, answers }) => {
  const imageLength = brand.images.length;
  return /* @__PURE__ */ React.createElement("div", null, imageLength === 1 ? /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    "div",
    {
      role: "img",
      alt: "Image not found",
      onClick: () => setAnswers(__spreadProps(__spreadValues({}, answers), { branding_id: brand.id.value })),
      style: brand.images[0].typeCode !== "OriginalLogo" ? {
        backgroundImage: `url(${brand.images[0].url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100px"
      } : { height: "100px", padding: "10px" }
    },
    brand.images[0].typeCode === "OriginalLogo" && "No Cover Image"
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      role: "img",
      alt: "Image not found",
      onClick: () => setAnswers(__spreadProps(__spreadValues({}, answers), { branding_id: brand.id.value })),
      style: brand.images[0].typeCode === "OriginalLogo" ? {
        backgroundImage: `url(${brand.images[0].url})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        padding: "0 0 0 5px",
        height: "70px",
        width: "100%"
      } : { height: "100px", padding: "10px" }
    },
    brand.images[0].typeCode !== "OriginalLogo" && "No Logo Image"
  )) : /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    "div",
    {
      role: "img",
      alt: "Image not found",
      onClick: () => setAnswers(__spreadProps(__spreadValues({}, answers), { branding_id: brand.id.value })),
      style: {
        backgroundImage: `url(${brand.images[brand.images[0].typeCode === "OriginalLogo" ? 1 : 0].url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100px"
      }
    }
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      role: "img",
      alt: "Image not found",
      onClick: () => setAnswers(__spreadProps(__spreadValues({}, answers), { branding_id: brand.id.value })),
      style: {
        backgroundImage: `url(${brand.images[brand.images[0].typeCode === "OriginalLogo" ? 0 : 1].url})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        padding: "0 0 0 5px",
        height: "70px",
        width: "100%"
      }
    }
  )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { style: { padding: "10px" } }, brand.name)));
};
RenderList.propTypes = {
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  brand: PropTypes.object
};
