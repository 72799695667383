import React, { useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Popover from '@mui/material/Popover';
import CircularProgress from '@mui/material/CircularProgress';
import SortIcon from '@mui/icons-material/Sort';
import moment from 'moment-timezone';
import { classes } from './styles';
import { StyledSnackbarProps } from '../../../Components/CustomUIElements/StyledSnackbar';
import { theme } from '../../../../ThemeContext/ThemeObject';

interface ICandidates {
  id: number;
  application_id: number;
  name: string;
  status: string;
  export_status: string;
  upload_time: string;
  exported_successfully: boolean;
  exported_confirmation_time: string;
}

export default function CeridianDayforce({
  apiKey,
  jobId,
  setSnackbar,
  setActionsAnchorEl
}: {
  apiKey: string;
  jobId: number;
  setActionsAnchorEl: (value: null) => void;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
}) {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [candidates, setCandidates] = useState<ICandidates[]>([]);
  const [enabled, setEnabled] = useState(false);
  const [status, setStatus] = useState('');
  const [retryLoading, setRetryLoading] = useState(-1);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);

  const entityUsesCeridian = useCallback(async () => {
    const response = await fetch(
      `/api/v4/jobs/${jobId}/ceridian_dayforce_uploads/entity_is_configured`,
      {
        method: 'GET',
        headers: {
          'x-api-authenticate': apiKey
        }
      }
    );
    const { entity_is_configured } = await response.json();
    setEnabled(entity_is_configured);
  }, [apiKey, jobId]);

  const getCandidates = useCallback(
    async (selectedStatus?: string) => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/v4/jobs/${jobId}/ceridian_dayforce_uploads${
            selectedStatus ? '?filter[status]=' + selectedStatus : ''
          }`,
          {
            method: 'GET',
            headers: {
              'x-api-authenticate': apiKey
            }
          }
        );
        const { candidates } = await response.json();
        setCandidates(candidates);
      } catch (error) {
        setSnackbar({
          message: 'Error fetching ceridian dayforce candidates',
          state: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    },
    [apiKey, jobId, setSnackbar]
  );

  const handleRetryUpload = async (id: number) => {
    setRetryLoading(id);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/ceridian_dayforce_uploads?id=${id}`, {
        method: 'POST',
        headers: {
          'x-api-authenticate': apiKey
        }
      });
      if (response.ok) {
        setSnackbar({
          message: 'Application is being uploaded to Ceridian Dayforce',
          state: 'success'
        });
      } else {
        setSnackbar({
          message: 'Error retrying failed upload',
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        message: 'Error retrying failed upload',
        state: 'error'
      });
    } finally {
      setRetryLoading(-1);
    }
  };

  const handleFilter = (selected: string) => {
    if (selected !== status) {
      getCandidates(selected);
      setStatus(selected);
    }
    setFilterAnchorEl(null);
  };

  const getStatusText = {
    Processing: 'Processing',
    Pending: 'Pending',
    failed_file_renaming: 'Failed',
    upload_confirmed: 'Successful',
    uploading_failed: 'Failed uploads'
  };

  useEffect(() => {
    if (isDialogOpen) {
      getCandidates();
    } else {
      entityUsesCeridian();
    }
  }, [entityUsesCeridian, getCandidates, isDialogOpen]);

  if (!enabled) {
    return null;
  } else {
    return (
      <>
        <span>
          <Box
            onClick={() => {
              setIsDialogOpen(true);
            }}
          >
            Ceridian Dayforce
          </Box>
        </span>
        <Modal
          open={isDialogOpen}
          aria-labelledby="modal-modal-title"
          onClose={() => setIsDialogOpen(false)}
        >
          <Box sx={classes.modalContent}>
            <CloseIcon onClick={() => setIsDialogOpen(false)} sx={classes.closeIcon} />
            <Box id="modal-modal-title" sx={classes.modalTitle}>
              Ceridian Dayforce
            </Box>
            <Box sx={classes.filterContainer}>
              <Button
                sx={classes.filterButton}
                startIcon={<SortIcon sx={{ marginRight: '8px' }} />}
                onClick={(e) => setFilterAnchorEl(e.currentTarget)}
              >
                {status ? getStatusText[status as keyof typeof getStatusText] : 'Upload Status'}
              </Button>
              <Popover
                id="filter-menu-popover"
                sx={classes.filterMenu}
                open={Boolean(filterAnchorEl)}
                anchorEl={filterAnchorEl}
                onClose={() => {
                  setFilterAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                {status !== 'uploading_failed' && (
                  <span onClick={() => handleFilter('uploading_failed')}>Failed uploads</span>
                )}
                {status !== 'Processing' && (
                  <span onClick={() => handleFilter('Processing')}>Processing</span>
                )}
                {status !== 'upload_confirmed' && (
                  <span onClick={() => handleFilter('upload_confirmed')}>Successful</span>
                )}
                {status !== 'failed_file_renaming' && (
                  <span onClick={() => handleFilter('failed_file_renaming')}>Failed</span>
                )}
                {status !== 'Pending' && (
                  <span onClick={() => handleFilter('Pending')}>Pending</span>
                )}
                {status && <span onClick={() => handleFilter('')}>Clear filter</span>}
              </Popover>
            </Box>
            {!isLoading ? (
              candidates.length > 0 ? (
                <Box sx={classes.tableContainer}>
                  <TableContainer>
                    <Table sx={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ ...classes.sticky, width: '125px' }}>
                            Application ID
                          </TableCell>
                          <TableCell sx={{ ...classes.sticky, left: '124.5px' }}>
                            Canidate Name
                          </TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Export Status</TableCell>
                          <TableCell>Upload Time</TableCell>
                          <TableCell>Exported Successfully</TableCell>
                          <TableCell>Export Confirmation Time</TableCell>
                          <TableCell sx={classes.stickyRight}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {candidates.map((candidate) => (
                          <TableRow key={candidate.application_id}>
                            <TableCell
                              sx={{ ...classes.sticky, width: '125px' }}
                              component="th"
                              scope="row"
                            >
                              {candidate.id ? candidate.id : '-'}
                            </TableCell>
                            <TableCell sx={{ ...classes.sticky, left: '124.5px' }}>
                              {candidate.name ? candidate.name : '-'}
                            </TableCell>
                            <TableCell>{candidate.status ? candidate.status : '-'}</TableCell>
                            <TableCell>
                              {candidate.export_status ? candidate.export_status : '-'}
                            </TableCell>
                            <TableCell>
                              {candidate.upload_time
                                ? moment
                                    .tz(candidate.upload_time, timeZoneString)
                                    .format('DD MMM YY, h:mma zz')
                                : '-'}
                            </TableCell>
                            <TableCell
                              sx={
                                candidate.exported_successfully
                                  ? { color: theme.palette.success.main }
                                  : { color: theme.palette.error.main }
                              }
                            >
                              {candidate.exported_successfully ? 'True' : 'False'}
                            </TableCell>
                            <TableCell>
                              {candidate.exported_confirmation_time
                                ? moment
                                    .tz(candidate.exported_confirmation_time, timeZoneString)
                                    .format('DD MMM YY, h:mma zz')
                                : '-'}
                            </TableCell>
                            <TableCell
                              sx={{ ...classes.stickyRight, ...classes.clickable }}
                              onClick={() => handleRetryUpload(candidate.id)}
                            >
                              {retryLoading === candidate.id ? (
                                <CircularProgress size={15} sx={{ marginLeft: '16px' }} />
                              ) : (
                                'Retry upload'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                <Box sx={classes.noCandidateContainer}>
                  {`No candidates${
                    status ? ' matching your status filter ' : ' '
                  }have been exported to Ceridian Dayforce`}
                </Box>
              )
            ) : (
              <Box sx={classes.loadingContainer}>
                <CircularProgress size={32} />
              </Box>
            )}
            <Box sx={classes.modalActions}>
              <Button
                sx={classes.revertloadButton}
                onClick={() => {
                  setIsDialogOpen(false);
                  setActionsAnchorEl(null);
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
}
