import React from 'react';
import { SxProps } from '@mui/material';
import { classes } from '../../NewUI/Components/GenericTable/styles';
import { ActionListItem } from '../../NewUI/Components/GenericTable/GenericTableActions';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import EmailIcon from '@mui/icons-material/Email';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

export const candidatePoolsColumns = [
  {
    id: 'title',
    label: 'Title',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'candidates_count',
    label: 'Candidates',
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: 'pool_tags',
    label: 'Tags',
    sortable: false,
    canToggle: true,
    enabled: true
  },
  {
    id: 'created_at',
    label: 'Created',
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: 'last_email_date',
    label: 'Last Email Date',
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: 'notes',
    label: 'Notes',
    sortable: false,
    canToggle: true,
    enabled: true
  }
];

export const headerStyles = (
  name: (typeof candidatePoolsColumns)[number]['id'] | 'checkbox' | 'actions',
  tableHovered: boolean
): SxProps => {
  switch (name) {
    case 'checkbox':
      return { ...classes.sticky, width: '58px' };
    case 'title':
      return { ...classes.sticky, ...classes.itemTitleTableCell, left: '58px' };
    case 'candidates_count':
      return { width: '120px', textAlign: 'right' };
    case 'pool_tags':
      return { minWidth: '150px' };
    case 'created_at':
      return { minWidth: '150px' };
    case 'last_email_date':
      return { minWidth: '135px' };
    case 'notes':
      return { minWidth: '240px' };
    case 'actions':
      return {
        ...classes.actionsColumnHeader,
        ...(tableHovered && classes.scrollShadowVisible)
      };
    default:
      return { minWidth: '90px' };
  }
};

export const candidatePoolActionsState = {
  emailAnalytics: false,
  addNote: false,
  email: false,
  moreOptions: false,
  deletePool: false,
  candidatePool: false
};

export const candidatePoolModalState = {
  newPool: false,
  email: false,
  externalEmail: false,
  downloadAttachments: false,
  exportAsPdf: false,
  candidatePool: false
};

export const poolActionDrawerModals = [
  {
    id: 'email',
    label: 'Email',
    icon: <EmailIcon />
  },
  {
    id: 'externalEmail',
    label: 'External email',
    icon: <ForwardToInboxIcon />
  },
  {
    id: 'downloadAttachments',
    label: 'Download attachments',
    icon: <AttachFileIcon />
  },
  {
    id: 'exportAttachments',
    label: 'Export attachments',
    icon: <PictureAsPdfIcon />
  },
  {
    id: 'exportAsPdf',
    label: 'Export as PDF',
    icon: <PictureAsPdfIcon />
  }
];

export const candidatePoolActionsList: ActionListItem[] = [
  {
    id: 'emailAnalytics',
    tooltipLabel: 'Email Analytics',
    elementId: 'email-analytics-button',
    icon: <AssessmentIcon />
  },
  {
    id: 'addNote',
    tooltipLabel: 'Add Note',
    elementId: 'add-note-candidate-pools-button',
    icon: <NoteAddIcon />
  },
  {
    id: 'email',
    tooltipLabel: 'Email',
    elementId: 'email-candidate-pools-button',
    icon: <EmailIcon />
  },
  {
    id: 'moreOptions',
    tooltipLabel: 'More Options',
    elementId: 'three-dots-candidate-pools-dropdown',
    icon: <MoreVertIcon />
  }
];
