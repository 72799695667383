import React, { Dispatch } from 'react';
import { Button, Stack } from '@mui/material';
import {
  Approval as ApprovalIcon,
  EditNote as EditNoteIcon,
  Email as EmailIcon,
  Event as EventIcon,
  GroupAdd as GroupAddIcon,
  MenuBook as MenuBookIcon,
  NoteAdd as NoteAddIcon,
  Smartphone as SmartphoneIcon
} from '@mui/icons-material';
import { ExternalEmailSVG, SendFormSVG } from './Icons';
import { ApplicationAction, IApplication } from '../types';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { ModalType } from '../config';
import { useQueryClient } from '@tanstack/react-query';
import { IUserPermissions } from '../../Components/sharedTypes';

export const ActionButton = ({
  action,
  boldFont,
  buttonId
}: {
  action: { label: string; icon: React.JSX.Element; onClick: () => void };
  boldFont?: boolean;
  buttonId?: string;
}) => (
  <Button
    id={buttonId}
    sx={{
      ...sharedClasses.genericButtonSecondary,
      fontWeight: boldFont ? 'bold' : 600,
      columnGap: 1,
      svg: { fontSize: '20px' }
    }}
    onClick={action.onClick}
  >
    {action.icon}
    {action.label}
  </Button>
);

export default function Actions({ dispatch }: { dispatch: Dispatch<ApplicationAction> }) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  const actions = [
    {
      label: 'Add record',
      icon: <EditNoteIcon />,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ADD_RECORD }),
      permission: permissions?.Applications?.['Add Notes']
    },
    {
      label: 'Add form',
      icon: <NoteAddIcon />,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ADD_FORM }),
      permission: permissions?.Applications?.['Add Form Response']
    },
    {
      label: 'Send form',
      icon: <SendFormSVG />,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.SEND_FORM }),
      permission: permissions?.Applications?.['Send Forms']
    },
    {
      label: 'External email',
      icon: <ExternalEmailSVG />,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.EMAIL_EXTERNAL }),
      permission: permissions?.Applications?.['Email/Bulk Email External']
    },
    {
      label: 'Send email',
      icon: <EmailIcon />,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.SEND_EMAIL }),
      permission: permissions?.Applications?.['Send Emails']
    },
    {
      label: 'Send SMS',
      icon: <SmartphoneIcon />,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.SEND_SMS }),
      permission: permissions?.Applications?.['Send SMS']
    },
    {
      label: 'Add to event',
      icon: <EventIcon />,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ADD_TO_EVENT }),
      permission: permissions?.Events?.['Create / Edit Events']
    },
    {
      label: 'Invite to timeslot',
      icon: <GroupAddIcon sx={{ transform: 'scaleX(-1)' }} />,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.INVITE_TO_TIMESLOT }),
      permission: permissions?.Applications?.['Invite To Book In']
    },
    {
      label: 'Interview guide',
      icon: <MenuBookIcon />,
      onClick: () => window.open(`${window.location.origin}${application?.interview_guide_url}`),
      permission: !!application?.interview_guide_url
    },
    {
      label: 'Create approval',
      icon: <ApprovalIcon />,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.CREATE_APPROVAL }),
      permission: true
    }
  ];

  return (
    <Stack
      sx={{
        padding: 3,
        borderRadius: '6px',
        border: '1px solid #E3E3E3',
        rowGap: 2
      }}
    >
      <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Actions</Stack>
      <Stack sx={{ flexDirection: 'row', flexWrap: 'wrap', columnGap: 2, rowGap: 2 }}>
        {actions
          .filter((a) => a.permission)
          .map((action, index) => (
            <ActionButton
              key={index}
              action={action}
              buttonId={`${action.label.toLowerCase().split(' ').join('-')}-button`}
            />
          ))}
      </Stack>
    </Stack>
  );
}
