import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Stack, Skeleton } from '@mui/material';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import NewSystemFieldSVG from './NewSystemFieldSVG';
import NewField from './Modals/NewField';
import NewEmailTemplateField from './Modals/NewEmailTemplateField';
import AddSystemField from './Modals/AddSystemField';
import ApprovalTemplateSettings from '../ApprovalTemplateSettings/ApprovalTemplateSettings';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import { IApplicantQuestion } from '../../Components/Utilities/QuestionFieldViewer';
import { classes } from './styles';
import { DndContext, closestCenter, KeyboardSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

import SortableItem from './SortableItem';
import { QuestionCardSensor } from './QuestionCardSensor';
import { IApprovalTemplate, IApprovalTemplateFields } from '../types';
import Api from '../API';
import { IUserPermissions } from '../../Components/sharedTypes';
import InfoIcon from '@mui/icons-material/Info';
import { OptionToggleGroup } from '../../Components/OptionToggleGroup';

const toggleButtonOptions = [
  { value: 'enabledQuestions', label: 'Enabled questions' },
  { value: 'disabledQuestions', label: 'Disabled questions' }
];

export default function Questions({
  apiKey,
  entityId,
  approvalTemplateId,
  approvalTemplateData,
  setApprovalTemplateData,
  getApprovalTemplate,
  userPermissions,
  enableEmailTemplateFields
}: {
  apiKey: string;
  entityId: number;
  approvalTemplateId: number;
  approvalTemplateData: IApprovalTemplate;
  setApprovalTemplateData: React.Dispatch<React.SetStateAction<IApprovalTemplate>>;
  getApprovalTemplate: () => Promise<void>;
  userPermissions: IUserPermissions;
  enableEmailTemplateFields?: boolean;
}) {
  const [questions, setQuestions] = useState<IApprovalTemplateFields[]>([]);
  const [emailTemplateQuestions, setEmailTemplateQuestions] = useState<IApprovalTemplateFields[]>(
    []
  );
  const [allQuestions, setAllQuestions] = useState<IApprovalTemplateFields[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalsOpen, setModalsOpen] = useState<Record<string, boolean>>({
    newField: false,
    addSystemField: false,
    approvalTemplateSettings: false,
    newEmailTemplateField: false
  });
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });

  const [editingQuestion, setEditingQuestion] = useState<IApplicantQuestion | null>();
  const [selected, setSelected] = useState('enabledQuestions');

  const sensors = useSensors(
    useSensor(QuestionCardSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      // Use full questions list to swap positions
      const oldIndex = allQuestions.map((question) => question.id).indexOf(active.id);
      const newIndex = allQuestions.map((question) => question.id).indexOf(over.id);
      const newArray = arrayMove(allQuestions, oldIndex, newIndex);
      setAllQuestions(newArray);
      updateApprovalFormQuestions(newArray);
    }
  }

  const updateApprovalFormQuestions = async (questions) => {
    const fieldObject = {};
    questions.map(
      (question, index) =>
        (fieldObject[question.id] = {
          position: index
        })
    );

    const questionIndex = questions
      ?.filter((question) => question.enabled)
      .findIndex((question) => question.is_approval_form_title);

    const newTemplateData = { ...approvalTemplateData };
    newTemplateData.config.approval_form_title_position = questionIndex;
    setApprovalTemplateData(newTemplateData);

    try {
      await Api.updateApprovalTemplate(approvalTemplateId, {
        requisition_form: {
          approval_form_title_position: questionIndex
        }
      });
    } catch (error) {
      setSnackbar({
        message: `Failed to update approval template, ${error}`,
        state: 'error'
      });
    }

    try {
      const response = await fetch(
        `/api/v4/requisition_forms/${approvalTemplateId}/update_questions`,
        {
          method: 'PUT',
          headers: {
            'X-api-authenticate': apiKey,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fields: fieldObject
          })
        }
      ).then((response) => response.json());
      setAllQuestions(response.fields);
      setQuestions(response.fields.filter((field) => !field.for_email_template));
      setEmailTemplateQuestions(response.fields.filter((field) => field.for_email_template));
      setSnackbar({
        message: 'Questions have been updated',
        state: 'success'
      });
    } catch (error) {
      setSnackbar({
        message: 'There was an error saving your changes',
        state: 'error'
      });
    }
  };

  const getQuestions = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await Api.getQuestions(
        { 'X-api-authenticate': apiKey },
        { entity_id: entityId, requisition_form_id: approvalTemplateId }
      );
      setAllQuestions(data.res.fields);
      setQuestions(data.res.fields.filter((field) => !field.for_email_template));
      setEmailTemplateQuestions(data.res.fields.filter((field) => field.for_email_template));
    } catch (err) {
      setSnackbar({
        message: `Failed to fetch questions, ${err}`,
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, approvalTemplateId, entityId]);

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newSelected: string) => {
    if (newSelected !== null) {
      setSelected(newSelected);
    }
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    Api.getQuestions(
      { 'X-api-authenticate': apiKey },
      { entity_id: entityId, requisition_form_id: approvalTemplateId }
    )
      .then((data) => {
        if (isMounted) {
          setAllQuestions(data.res.fields);
          setQuestions(data.res.fields.filter((field) => !field.for_email_template));
          setEmailTemplateQuestions(data.res.fields.filter((field) => field.for_email_template));
        }
      })
      .catch((err) => {
        if (isMounted)
          setSnackbar({
            message: `Failed to fetch system fields, ${err}`,
            state: 'error'
          });
      })
      .finally(() => {
        if (isMounted) setIsLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, [apiKey, approvalTemplateId, entityId]);

  return (
    <Box sx={classes.questionsPageContainer}>
      <Box sx={classes.noticeContainer}>
        <InfoIcon sx={classes.infoIcon} />
        <Box sx={{ marginLeft: 1 }}>
          <b>All changes will be automatically saved and applied immediately. </b>
          All fields that have been answered by a user are not able to be deleted. To remove a
          field, you will need to disable it and create a new field.
        </Box>
      </Box>
      {!!approvalTemplateData.config?.enable_declaration_of_conflict_of_interest && (
        <Box sx={classes.actionsContianer}>
          {isLoading ? (
            <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
          ) : (
            <Stack sx={{ flexDirection: 'row', gap: '22px' }}>
              <Button
                variant="outlined"
                startIcon={<LiveHelpIcon />}
                sx={classes.actionButton}
                onClick={() => {
                  setEditingQuestion(null);
                  setModalsOpen((prev) => ({ ...prev, newEmailTemplateField: true }));
                }}
              >
                New email template field
              </Button>
            </Stack>
          )}
        </Box>
      )}
      {!!approvalTemplateData.config?.enable_declaration_of_conflict_of_interest &&
        emailTemplateQuestions.length > 0 && (
          <Box sx={{ overflow: 'auto' }}>
            <Box sx={classes.questionsContainer}>
              <Box sx={classes.questionsHeader}>Email Template Questions</Box>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={emailTemplateQuestions}
                  strategy={verticalListSortingStrategy}
                >
                  {emailTemplateQuestions.map((question, index) => (
                    <SortableItem
                      allQuestions={emailTemplateQuestions}
                      questions={emailTemplateQuestions}
                      setQuestions={setQuestions}
                      getQuestions={getQuestions}
                      index={index}
                      key={question.id}
                      approvalTemplateId={approvalTemplateId}
                      approvalTemplateData={approvalTemplateData}
                      setApprovalTemplateData={setApprovalTemplateData}
                      apiKey={apiKey}
                      setModalsOpen={setModalsOpen}
                      editingQuestion={question}
                      setEditingQuestion={setEditingQuestion}
                      setSnackbar={setSnackbar}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </Box>
          </Box>
        )}
      <OptionToggleGroup
        options={toggleButtonOptions}
        selected={selected}
        setSelected={setSelected}
      />
      <Box sx={classes.actionsContianer}>
        {isLoading ? (
          <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
        ) : (
          <Stack sx={{ flexDirection: 'row', gap: '22px' }}>
            {selected === 'enabledQuestions' && (
              <>
                <Button
                  variant="outlined"
                  startIcon={<LiveHelpIcon />}
                  sx={classes.actionButton}
                  onClick={() => {
                    setEditingQuestion(null);
                    setModalsOpen((prev) => ({ ...prev, newField: true }));
                  }}
                >
                  New field
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<NewSystemFieldSVG />}
                  sx={classes.actionButton}
                  onClick={() => {
                    setEditingQuestion(null);
                    setModalsOpen((prev) => ({ ...prev, addSystemField: true }));
                  }}
                >
                  Add system field
                </Button>
              </>
            )}
          </Stack>
        )}
        {(userPermissions?.['Approval Forms']?.['Create / Edit Approval Forms (HR use)'] ||
          userPermissions?.['Approval Forms']?.['Edit Approval Form (HR use)']) && (
          <>
            {isLoading ? (
              <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
            ) : (
              <Stack>
                <Button
                  sx={classes.settingButton}
                  onClick={() => {
                    setModalsOpen((prev) => ({ ...prev, approvalTemplateSettings: true }));
                  }}
                >
                  Settings
                </Button>
              </Stack>
            )}
          </>
        )}
      </Box>
      {selected === 'enabledQuestions'
        ? !isLoading &&
          questions.filter((question) => question.enabled).length > 0 && (
            <Box sx={{ overflow: 'auto' }}>
              <Box sx={classes.questionsContainer}>
                <Box sx={classes.questionsHeader}>Questions</Box>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={questions.filter((question) => question.enabled)}
                    strategy={verticalListSortingStrategy}
                  >
                    {questions
                      .filter((question) => question.enabled)
                      .map((question, index) => (
                        <SortableItem
                          allQuestions={questions}
                          questions={questions.filter((question) => question.enabled)}
                          setQuestions={setQuestions}
                          getQuestions={getQuestions}
                          index={index}
                          key={question.id}
                          approvalTemplateId={approvalTemplateId}
                          approvalTemplateData={approvalTemplateData}
                          setApprovalTemplateData={setApprovalTemplateData}
                          apiKey={apiKey}
                          setModalsOpen={setModalsOpen}
                          editingQuestion={question}
                          setEditingQuestion={setEditingQuestion}
                          setSnackbar={setSnackbar}
                          userPermissions={userPermissions}
                        />
                      ))}
                  </SortableContext>
                </DndContext>
              </Box>
            </Box>
          )
        : !isLoading &&
          questions.filter((question) => !question.enabled).length > 0 && (
            <Box sx={{ overflow: 'auto' }}>
              <Box sx={classes.questionsContainer}>
                <Box sx={classes.questionsHeader}>Questions</Box>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={questions.filter((question) => !question.enabled)}
                    strategy={verticalListSortingStrategy}
                  >
                    {questions
                      .filter((question) => !question.enabled)
                      .map((question, index) => (
                        <SortableItem
                          allQuestions={questions}
                          questions={questions.filter((question) => !question.enabled)}
                          setQuestions={setQuestions}
                          getQuestions={getQuestions}
                          index={index}
                          key={question.id}
                          approvalTemplateId={approvalTemplateId}
                          approvalTemplateData={approvalTemplateData}
                          setApprovalTemplateData={setApprovalTemplateData}
                          apiKey={apiKey}
                          setModalsOpen={setModalsOpen}
                          editingQuestion={question}
                          setEditingQuestion={setEditingQuestion}
                          setSnackbar={setSnackbar}
                        />
                      ))}
                  </SortableContext>
                </DndContext>
              </Box>
            </Box>
          )}
      {!isLoading && questions?.length === 0 && (
        <Box sx={{ ...classes.noQuestionsContainer, margin: '150px 0px' }}>
          <Box sx={classes.noQuestionsText}>
            Add questions to your approval template
            <br />
            from the above options
          </Box>
        </Box>
      )}
      <NewField
        apiKey={apiKey}
        getQuestions={getQuestions}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        approvalTemplateId={approvalTemplateId}
        setSnackbar={setSnackbar}
        editingQuestion={editingQuestion}
        userPermissions={userPermissions}
      />
      <NewEmailTemplateField
        apiKey={apiKey}
        getQuestions={getQuestions}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        approvalTemplateId={approvalTemplateId}
        setSnackbar={setSnackbar}
        editingQuestion={editingQuestion}
        userPermissions={userPermissions}
      />
      <AddSystemField
        apiKey={apiKey}
        getQuestions={getQuestions}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        approvalTemplateId={approvalTemplateId}
        setSnackbar={setSnackbar}
      />
      {modalsOpen.approvalTemplateSettings && (
        <ApprovalTemplateSettings
          modalsOpen={modalsOpen.approvalTemplateSettings}
          setModalsOpen={() => setModalsOpen({ ...modalsOpen, approvalTemplateSettings: false })}
          approvalTemplateData={approvalTemplateData}
          getApprovalTemplate={getApprovalTemplate}
          questions={questions.length ? questions : undefined}
          setSnackbar={setSnackbar}
          enableEmailTemplateFields={enableEmailTemplateFields}
        />
      )}
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
