import React, { Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import { IJob } from '../../../Job/types';
import { classes } from '../styles';
import { DebouncedFormTextField } from '../../../Components/CustomUIElements/FormTextField';

export default function Revolve({
  apiKey,
  job,
  revolveTabJobData,
  setRevolveTabJobData,
  setIsEdited
}: { apiKey: string, job: IJob, setIsEdited: Dispatch<SetStateAction<boolean>> }) {

  return (
    <Box sx={classes.tabContent}>
      <Box id="template-title" sx={classes.headerContent}>
        Revolve
      </Box>
      <Box sx={classes.bodyContainer}>
        <Box sx={classes.modalFormLine}>
          <DebouncedFormTextField
            onDebouncedChange={(value: string) => {
              setRevolveTabJobData((prev) => ({
                ...prev,
                revolve_position_title: { ...prev.revolve_position_title, value }
              }))
              setIsEdited(true);
            }}
            id="revolve"
            label="Position title"
            defaultValue={revolveTabJobData?.revolve_position_title?.value}
            required={revolveTabJobData?.revolve_position_title?.required}
            error={revolveTabJobData?.revolve_position_title?.error}
            styles={{ width: '250px' }}
          />
        </Box>
      </Box>
    </Box>
  );
}
