import React, { Component } from 'react';
import T from 'prop-types';

export default class CustomControlColumn extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { components } = this.props
    return(
      <td>
        { components }
      </td>
    )
  }
}
