import React, { Dispatch } from 'react';
import { TCandidate } from '../../../types';
import { Box } from '@mui/material';
import { ISnackbarInput } from '../../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { useQuery } from '@tanstack/react-query';
import { CandidatePoolAPIReturn } from '../../../types';
import { callPaginatedAPI } from '../../../../NewUI/Components/Utilities/callAPI';
import queryString from 'query-string';
import {
  GenericTableAction,
  GenericTableState
} from '../../../../NewUI/Components/GenericTable/genericTableReducer';
import { headerStyles } from './emailConfig';
import EmailsTableRows from './EmailsTableRows';
import { IStyle } from '../../../../ThemeContext/ThemeObject';
import GenericTable from '../../../../NewUI/Components/GenericTable/GenericTable';
import SearchInput from '../../../../NewUI/Components/GenericTable/SearchInput';

const CandidatePoolEmailsModal = ({
  isOpen,
  setSnackbarState,
  selectedPool,
  emailsState,
  emailsDispatch
}: {
  isOpen: boolean;
  setSnackbarState: (snackbarState: ISnackbarInput) => void;
  selectedPool: {
    candidates: TCandidate;
    id: number;
    title: string;
  };
  emailsState: GenericTableState;
  emailsDispatch: Dispatch<GenericTableAction>;
}) => {
  const { data: emails, isFetching } = useQuery({
    queryKey: ['candidatePoolEmails', emailsState.searchQuery, emailsState.searchSettings],
    queryFn: () => {
      return callPaginatedAPI(
        `/api/v4/candidate_pools/${selectedPool.id}/email_batches?${queryString.stringify(
          {
            ...emailsState.searchSettings,
            'q[search]': emailsState.searchQuery
          },
          {
            skipEmptyString: true,
            skipNull: true,
            arrayFormat: 'bracket',
            encode: false
          }
        )}`,
        null,
        'GET'
      ) as Promise<CandidatePoolAPIReturn>;
    },
    onError() {
      setSnackbarState({
        message: `There was an error retrieving candidate pool emails, please refresh the page and try again.`,
        state: 'error'
      });
    },
    enabled: isOpen,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  return (
    <Box>
      <SearchInput dispatch={emailsDispatch} id="search-box-emails-tab" />
      <GenericTable
        items={emails?.data?.analytics || []}
        isLoadingItems={isFetching}
        dispatch={emailsDispatch}
        tableState={emailsState.tableState}
        paginationState={emailsState.searchSettings}
        pagination={{
          totalItems: emails?.totalItems || 0,
          totalPages: emails?.totalPages || 0
        }}
        headerStyle={headerStyles as (id: string, tableHovered: boolean) => IStyle}
        TableRows={EmailsTableRows}
        label="emails"
        titleCellId="email_template"
        allowMultipleSelection={false}
        customEmptyStateMessage="have been sent yet"
      />
    </Box>
  );
};

export default CandidatePoolEmailsModal;
