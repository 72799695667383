"use strict";
export const defaultInclusions = {
  resume: {
    label: "Resume",
    value: true
  },
  answers: {
    label: "Answers to screening questions",
    value: true
  },
  suitability_comment: {
    label: "Suitability comments",
    value: true
  }
};
export const defaultFormats = ["Paragraphs", "Dot points", "Mixed"];
export const emailTones = [
  "Professional",
  "Friendly",
  "Informative",
  "Encouraging",
  "Enthusiastic",
  "Respectful"
];
