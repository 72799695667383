import React, { Dispatch } from 'react';
import { Box, Button, FormControlLabel, Switch } from '@mui/material';
import { classes } from '../styles';
import { classes as sharedClasses } from '../../SharedComponents/styles';
import { FelixSVG } from '../../SharedComponents/Graphics/FelixSVG';
import { AIStudioAction, AIStudioState } from '../reducer';

export const WelcomePage = ({
  dispatch,
  aiStudioState,
  aiStudioFrenchEnabled
}: {
  dispatch: Dispatch<AIStudioAction>;
  aiStudioState: AIStudioState;
  aiStudioFrenchEnabled: boolean;
}) => {
  return (
    <Box sx={classes.welcomeContainer}>
      <Box sx={classes.felixContainer}>
        <FelixSVG />
      </Box>
      <Box sx={classes.headersAndOptionsContainer}>
        <Box
          sx={{
            ...classes.header,
            ...(!aiStudioState.showCaret && { '& span': { borderRight: 'none', lineHeight: '1' } }),
            ...(aiStudioState.aiStudioFrenchEnabled && { height: '136px' })
          }}
        >
          <span>{aiStudioState.welcomeHeader}</span>
        </Box>
        <Box sx={classes.subHeader}>
          {aiStudioState.aiStudioFrenchEnabled
            ? 'Comment puis-je vous aider aujourd’hui'
            : 'What can I help you with today'}
          ?
        </Box>
        <Box sx={classes.optionsContainer}>
          <Button
            id="write-a-tlp"
            sx={classes.option}
            onClick={() => dispatch({ type: 'SELECT_WRITE_TLP' })}
          >
            {aiStudioState.aiStudioFrenchEnabled
              ? 'Rédigez une description de poste'
              : 'Write a talent landing page'}
          </Button>
          <Button
            id="write-screening-questions"
            sx={classes.option}
            onClick={() => dispatch({ type: 'SELECT_WRITE_SCREENING_QUESTION' })}
          >
            {aiStudioState.aiStudioFrenchEnabled
              ? 'Aidez-moi avec des questions de sélection'
              : 'Help me with some screening questions'}
          </Button>
          {aiStudioFrenchEnabled && (
            <Box>
              <FormControlLabel
                sx={
                  !aiStudioState.aiStudioFrenchEnabled
                    ? { ...sharedClasses.switchLabels, ...sharedClasses.labelActive }
                    : sharedClasses.switchLabels
                }
                control={
                  <Switch
                    sx={
                      aiStudioState.aiStudioFrenchEnabled
                        ? sharedClasses.aiSwitchActive
                        : sharedClasses.switch
                    }
                    checked={aiStudioState.aiStudioFrenchEnabled}
                    onChange={() => dispatch({ type: 'TOGGLE_FRENCH' })}
                  />
                }
                label={aiStudioState.aiStudioFrenchEnabled ? 'Français' : 'French'}
                labelPlacement="end"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
