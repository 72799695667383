"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const styles = {
  tableHeaderRow: {
    "& td, th": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      backgroundColor: "white",
      "& span": {
        color: theme.palette.primary.main,
        "&:hover": {
          color: "#0c74a4"
        }
      }
    },
    "& .Mui-selected": {
      backgroundColor: "#F2F2F2"
    },
    "& .Mui-active": {
      color: theme.palette.primary.main
    },
    "& .MuiTableSortLabel-icon": {
      color: "darkgrey !important"
    }
  },
  tableWrapper: {
    tableLayout: "auto !important",
    minWidth: "1000px",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF"
    },
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    tr: {
      fontSize: "16px",
      borderBottom: "1px solid #DDDDDD"
    }
  },
  skeletonContainer: {
    padding: "10px 0px"
  },
  noRecords: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#999999",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  tableCellData: {
    width: "20%",
    minWidth: "11rem",
    textTransform: "capitalize"
  },
  previewTableWrapper: {
    background: " #FFFFFF",
    boxShadow: "inset 0px 0px 30px -18px rgba(0, 0, 0, 0.3)",
    borderRadius: "6px",
    margin: "2rem 0rem"
  },
  previewInfoHeader: {
    background: "#EDF2F5",
    width: "fit-content",
    padding: "0.4rem 0.6rem 0.4rem 0.8rem",
    borderTopLeftRadius: "6px 6px",
    fontSize: "11.5px",
    fontWeight: "bold",
    color: "#084D6D"
  },
  previewIcon: {
    color: "#084d6d",
    fontSize: "16px",
    marginRight: "0.8rem"
  }
};
