import React, { Dispatch } from 'react';
import { Box } from '@mui/material';
import EngageSearchBar from './EngageSearchBar';
import { callPaginatedAPI } from '../../NewUI/Components/Utilities/callAPI';
import { EngageAction, EngageState } from '../reducer';
import { classes } from '../styles';
import { useQuery } from '@tanstack/react-query';
import EngageSearchTableRows from './EngageSearchTableRows';
import TLPMatchModal from './Modals/TLPMatchModal/TLPMatchModal';
import GenericTable from '../../NewUI/Components/GenericTable/GenericTable';
import ColumnSettings from '../../NewUI/Components/GenericTable/Dropdowns/ColumnSettings';
import SearchFilters from './SearchFilters';
import Filters from '../../NewUI/Components/GenericTable/Dropdowns/Filters';
import { CandidateAPIReturn } from '../types';
import EngageTableActionComponents from './EngageTableActionComponents';
import ItemActionsDrawer from '../../NewUI/Components/GenericTable/ItemActionsDrawer';
import { actionDrawerModals, engageSearchActionsList, headerStyle } from '../config';
import ActionDrawerModals from './Modals/ActionDrawer/ActionDrawerModals';
import ExportCsvButton from '../../NewUI/Components/GenericTable/ExportCsvButton';
import TotalSelectedIndicator from '../../NewUI/Components/GenericTable/TotalSelectedIndicator';
import AddCurrentSearchButton from './Modals/AddCurrentSearch';
import { getPreferences } from '../../NewUI/Components/GenericTable/utilities';
import { GenericTableAction } from '../../NewUI/Components/GenericTable/genericTableReducer';

const USER_PREFERENCE_SOURCE = 'engage-search';

const EngageAISearch = ({
  engageState,
  dispatch
}: {
  engageState: EngageState;
  dispatch: Dispatch<EngageAction>;
}) => {
  const { isFetched: fetchedPreferences } = useQuery({
    queryKey: ['engageSearchPreferences'],
    queryFn: () => getPreferences(USER_PREFERENCE_SOURCE, dispatch as Dispatch<GenericTableAction>),
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting user preference data`,
          state: 'error'
        }
      }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 3.6e6 // 1 hour
  });

  const { data: candidates, isLoading: isLoadingCandidates } = useQuery({
    queryKey: [
      'candidates',
      engageState.booleanQuery,
      engageState.searchSettings,
      engageState.searchFilters
    ],
    queryFn: () =>
      callPaginatedAPI(
        `/api/v4/searches`,
        {
          query: engageState.booleanQuery,
          ...engageState.searchSettings,
          ...engageState.searchFilters
        },
        'POST'
      ) as Promise<CandidateAPIReturn>,
    onError() {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error retrieving candidates, please refresh the page and try again.`,
          state: 'error'
        }
      });
    },
    enabled: fetchedPreferences,
    refetchOnWindowFocus: false
  });

  return (
    <>
      <Box sx={classes.searchAndFiltersRow}>
        <EngageSearchBar dispatch={dispatch} engageState={engageState} />
        <TLPMatchModal engageState={engageState} dispatch={dispatch} />
        <Filters
          dispatch={dispatch}
          numberOfFilters={Object.values(engageState.searchFilters)?.length}
          label="engage-search"
        >
          <SearchFilters engageState={engageState} dispatch={dispatch} />
        </Filters>
        <ColumnSettings
          tableState={engageState.searchTable}
          dispatch={dispatch}
          label="engage-search"
          userPreferenceSource={USER_PREFERENCE_SOURCE}
        />
        <ExportCsvButton
          isOpen={engageState.searchTable.modalsOpen.exportCsv}
          onClick={() =>
            dispatch({
              type: 'SET_ACTION_MODAL_OPEN',
              payload: { name: 'exportCsv', isOpen: true }
            })
          }
        />
        <AddCurrentSearchButton
          isOpen={engageState.searchTable.modalsOpen.addSearch}
          onClick={() =>
            dispatch({
              type: 'SET_ACTION_MODAL_OPEN',
              payload: { name: 'addSearch', isOpen: true }
            })
          }
        />
      </Box>
      <ItemActionsDrawer
        drawerOpen={engageState.searchTable.selectedItemIds.length > 0}
        dispatch={dispatch}
        actions={actionDrawerModals}
        label="engage-search"
      />
      <GenericTable
        items={candidates?.data?.ai_search || []}
        isLoadingItems={isLoadingCandidates}
        dispatch={dispatch}
        tableState={engageState.searchTable}
        paginationState={engageState.searchSettings}
        pagination={{
          totalItems: candidates?.totalItems || 0,
          totalPages: candidates?.totalPages || 0
        }}
        headerStyle={headerStyle}
        TableRows={EngageSearchTableRows}
        TableActionList={engageSearchActionsList}
        label="candidates"
        userPreferenceSource={USER_PREFERENCE_SOURCE}
      />
      <TotalSelectedIndicator
        tableState={engageState.searchTable}
        tableFilters={engageState.searchFilters}
        tableSearch={engageState.booleanQuery || undefined}
        dispatch={dispatch}
        totalItems={candidates?.totalItems || 0}
        items={candidates?.data?.ai_search || []}
        label="candidates"
        selectAllUrl="/api/v4/searches"
      />
      <EngageTableActionComponents
        actionsState={engageState.searchTable.actions}
        dispatch={dispatch}
      />
      <ActionDrawerModals engageState={engageState} dispatch={dispatch} />
    </>
  );
};

export default EngageAISearch;
