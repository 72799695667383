import React from 'react'
import renderHTML from 'react-render-html';
import { createBrowserHistory } from 'history';
import { Link } from "react-router-dom";
import ServiceApi from './api.jsx';

const history = createBrowserHistory();

export default class AdminServiceShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { subservices: [] };
  }

  componentDidMount () {
    this.unlisten = history.listen(this.getInfo);
    this.getInfo(window.location);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getInfo = (location) => {
    const id = location.hash.split('/').pop();

    if (location.hash !== `#/services/${id}`) {
      return;
    }

    ServiceApi.get(id, (response) => {
      this.setState({
        subservices: response.subservice_items,
        serviceId: response.id,
        serviceTitle: response.title,
      });
    });
  }

  render() {
    const { subservices, serviceId, serviceTitle } = this.state;

    return (
      <div className="layer-content">
        <div className="layer-container">
          <div className="layer-tabs">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link to="/admin" className="nav-link">
                  <i className="material-icons">chevron_left</i>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={`/services/${serviceId}`} className="nav-link active">
                  {serviceTitle}
                </Link>
              </li>
            </ul>
          </div>

        {
          subservices.map(function (subservice) {
            const { id, title, icon, body } = subservice;
            return (
              <div className="card border-0 mb-4" key={id}>
                <div className="card-body">
                    <div className="card-image">
                      { icon && <img src={icon} alt={title} /> }
                    </div>
                    <div className="card-title">{title}</div>
                    <div className="card-text mb-2">
                      {renderHTML(body)}
                    </div>
                    <Link to={`/services/${serviceId}/subservices/${id}`} className="btn filled-green">Learn More and Purchase</Link>
                </div>
              </div>
            );
          })
        }
        </div>
      </div>
    );
  }
}
