import React from "react";
import PropTypes from "prop-types";
import { ButtonBase, Paper, Box } from "@mui/material";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { getClasses } from "./styles";
export default function KpiCard(props) {
  const { description, indicator, direction, url } = props;
  const classes = getClasses(props);
  const TRENDING_ICONS = {
    up: /* @__PURE__ */ React.createElement(TrendingUpIcon, { sx: classes.trendingIcon }),
    down: /* @__PURE__ */ React.createElement(TrendingDownIcon, { sx: classes.trendingIcon }),
    steady: /* @__PURE__ */ React.createElement(TrendingFlatIcon, { sx: classes.trendingIcon })
  };
  return /* @__PURE__ */ React.createElement(ButtonBase, { onClick: url ? () => location.href = url : void 0 }, /* @__PURE__ */ React.createElement(Paper, { sx: classes.card }, /* @__PURE__ */ React.createElement(Box, { sx: classes.cardImageContainer }, /* @__PURE__ */ React.createElement(Box, { sx: classes.cardImage })), /* @__PURE__ */ React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-start" }, /* @__PURE__ */ React.createElement(Box, { display: "flex" }, /* @__PURE__ */ React.createElement(
    Box,
    {
      component: "span",
      sx: classes.indicator,
      style: { fontSize: indicator ? "3rem" : "2rem" }
    },
    indicator || "No Data"
  )), /* @__PURE__ */ React.createElement(Box, { component: "h3", sx: classes.description }, description)), /* @__PURE__ */ React.createElement(Box, { flex: 1 }), TRENDING_ICONS[direction]));
}
KpiCard.propTypes = {
  description: PropTypes.string.isRequired,
  indicator: PropTypes.string,
  url: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  direction: PropTypes.string
};
