"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "60px",
    maxWidth: "1100px",
    width: "100%",
    height: "94vh",
    maxHeight: "844px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  modalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "38px",
    fontWeight: "700",
    color: "#2D388D",
    width: "100%",
    height: "40px"
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px",
    paddingTop: "10px"
  },
  modalButtonBase: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 20px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    },
    "&:focus": {
      outline: "none"
    }
  },
  modalSecondaryButton: {
    textDecoration: "underline",
    color: theme.palette.error.main,
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      boxShadow: "none"
    }
  }
};
