import React from 'react';
import { TextField, InputLabel, Autocomplete } from '@mui/material';
import { sharedClasses } from './sharedClasses';

interface IValueType {
  key: string | number;
  label: string;
}

interface IArrayValueType {
  key: string;
  name: string;
}

interface ISelectFieldProps {
  label?: string;
  options: any;
  value: IValueType | IArrayValueType[];
  optionLabel: any;
  onChange: (value: string) => void;
  id?: string;
  required?: boolean;
  isLoading?: boolean;
  multiple?: boolean;
  loadingText: [string, string];
  dateField?: boolean;
}

export default function AutocompleteInput({
  label,
  options,
  optionLabel,
  value,
  onChange,
  id,
  required,
  multiple,
  isLoading,
  loadingText,
  dateField
}: ISelectFieldProps) {
  const defaultValue: IArrayValueType[] = [];
  if (Array.isArray(value)) {
    value.map((v) => {
      const matchedValue = options.find((o) => o.key === v.key);
      if (matchedValue) defaultValue.push(matchedValue);
    });
  }
  return (
    <Autocomplete
      id={id}
      options={options}
      getOptionLabel={(option) => optionLabel(option)}
      disableClearable={dateField ? dateField : false}
      value={multiple ? defaultValue : value}
      multiple={multiple}
      sx={sharedClasses.autocomplete}
      ListboxProps={{ style: { ...sharedClasses.autoCompleteListStyles } }}
      loading={options?.length < 1}
      loadingText={isLoading ? loadingText[0] : loadingText[1]}
      renderInput={(params) => (
        <>
          <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
            {label}
            {required && <span>*</span>}
          </InputLabel>
          {dateField ? (
            <TextField
              {...params}
              onKeyPress={(e) => e.preventDefault()}
              onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Backspace' || event.key === 'Delete') event.preventDefault();
              }}
            />
          ) : (
            <TextField {...params} InputLabelProps={{ shrink: true }} />
          )}
        </>
      )}
      onChange={(event, data) => onChange(data)}
    />
  );
}
