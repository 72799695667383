import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import MultiTagInput from '../../../Components/CustomUIElements/MultiTagInput';
import { MultilineFormTextField } from '../../../Components/CustomUIElements/FormTextField';
import CloseIcon from '@mui/icons-material/Close';
import { IExternalEmailProps } from '../../types';
import { classes } from '../styles';
import { EntityContext } from '../../Job';
import SelectedApplicants from '../SelectedApplicants';

export default function ExternalEmail({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  filters
}: IExternalEmailProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [includeAttachments, setIncludeAttachments] = useState(false);
  const [includeSmartForms, setIncludeSmartForms] = useState(false);
  const [includeSummaries, setIncludeSummaries] = useState(false);
  const [submissionError, setSubmissionError] = useState('');
  const [notes, setNotes] = useState('');
  const { ai_studio_summaries_enabled } = useContext(EntityContext);

  const emailIsValid = (email: string) => {
    const simpleEmailRex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (simpleEmailRex.test(String(email).toLowerCase())) {
      setSubmissionError('');
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    if (emails.length === 0) {
      setSubmissionError('Please enter at least one email address');
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/applications/bulk_external_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        },
        body: JSON.stringify({
          application_ids: globalSelected.map((app) => app.id),
          external_view: {
            emails: emails.join(','),
            full_view: includeAttachments,
            smart_forms: includeSmartForms,
            include_summary: includeSummaries,
            notes
          }
        })
      });
      if (response.status === 201) {
        setSnackbar({
          message: 'External emails sent successfully',
          state: 'success'
        });
        handleClose();
      } else {
        setSnackbar({
          message:
            'Error sending emails, please try again or contact support if the issue persists',
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        message: 'Error sending emails, please try again or contact support if the issue persists',
        state: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setEmails([]);
    setIncludeAttachments(false);
    setIncludeSmartForms(false);
    setSubmissionError('');
    setNotes('');
    setModalsOpen({ ...modalsOpen, externalEmail: false });
  };

  return (
    <Modal
      open={modalsOpen.externalEmail}
      onClose={handleClose}
      aria-labelledby="external-email-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
        <Box id="external-email-title" sx={classes.actionModalHeader}>
          External email
        </Box>
        <Box
          sx={classes.modalContent}
          id="external-email-modal-content"
          aria-label="External email modal content"
        >
          <Box
            role="form"
            id="external-email-form"
            aria-label="External email form"
            sx={{ overflowX: 'hidden' }}
          >
            <h3 style={{ marginLeft: '12px' }}>Recipient Details</h3>
            <Box sx={{ ...classes.actionModalFormLine, marginBottom: '18px' }}>
              <MultiTagInput
                tags={emails}
                setTags={setEmails}
                validation={emailIsValid}
                validationMessage="Must input valid email"
                label="Email address/s"
                width="600px"
                error={submissionError}
                tagType="emails"
                helperText="To copy and paste bulk emails, email addresses must be separated with a comma"
              />
            </Box>
            <Box sx={{ ...classes.actionModalFormLine, marginLeft: '15px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={classes.formCheckbox}
                    onChange={(e) => setIncludeAttachments(e.target.checked)}
                    value={includeAttachments}
                  />
                }
                label="Include Attachments"
                sx={classes.checkboxLabel}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={classes.formCheckbox}
                    onChange={(e) => setIncludeSmartForms(e.target.checked)}
                    value={includeSmartForms}
                  />
                }
                label="Include Smart Forms"
                sx={classes.checkboxLabel}
              />
              {ai_studio_summaries_enabled && (
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={classes.formCheckbox}
                      onChange={(e) => setIncludeSummaries(e.target.checked)}
                      value={includeSummaries}
                    />
                  }
                  label="Include Summaries"
                  sx={classes.checkboxLabel}
                />
              )}
            </Box>
            <Box sx={classes.actionModalFormLine}>
              <MultilineFormTextField
                label="Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                styles={{ width: '740px' }}
              />
            </Box>
            <Box sx={{ marginLeft: '15px' }}>
              <h3>Candidates</h3>
              <SelectedApplicants
                globalSelected={globalSelected}
                handleDeselectCandidate={handleDeselectCandidate}
                closeModalCallback={() =>
                  setModalsOpen((prev) => ({ ...prev, externalEmail: false }))
                }
                filters={filters}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <Button
            id="close-external-email-button"
            sx={classes.modalCancelButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            id="send-external-email-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '71px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSubmitting ? <CircularProgress size={20} color="inherit" /> : 'Send'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
