import React, { useState, useReducer } from 'react';
import { Divider, Stack, Tabs, Tab, Skeleton, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  PeopleAlt as PeopleAltIcon
} from '@mui/icons-material';
import { CandidateReducer, InitialCandidateState } from './reducer';
import StyledSnackbar from '../Components/CustomUIElements/StyledSnackbar';
import { ISnackbarInput } from '../Components/CustomUIElements/StyledSnackbar';
import { useQuery } from '@tanstack/react-query';
import Api from './API';
import ProfileTab from './ProfileTab';
import ActivitiesTab from './ActivitiesTab';
import { styles } from './styles';
import EditCandidate from '../Candidates/EditCandidate';
import DeleteCandidate from '../Candidates/DeleteCandidate';
import MergeCandidates from './MergeCandidates';
import { formatUserPermissions } from '../Components/Utilities/formatUserPermissions';
import { getPermissions } from '../../shared/permissionHelpers';

export default function Candidate({
  candidateId,
  isAdminUser
}: {
  candidateId: number;
  isAdminUser: boolean;
}) {
  const [CandidateState, dispatch] = useReducer(CandidateReducer, InitialCandidateState);
  const [tabValue, setTabValue] = useState<number>(0);
  const [openMergeModal, setOpenMergeModal] = useState<boolean>(false);

  const { data: candidate, isLoading: loadingCandidate } = useQuery({
    queryKey: ['candidate'],
    queryFn: async () => {
      const { res } = await Api.getCandidateData(candidateId);
      return res;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting candidate data, ${error}`,
          state: 'error'
        }
      })
  });

  const { data: permissions } = useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const res = await getPermissions();
      return formatUserPermissions(res);
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting user permissions, ${error}`,
          state: 'error'
        }
      });
    }
  });

  return (
    <Stack sx={{ padding: 5 }}>
      <Stack sx={styles.candidateHeaderContainer}>
        {loadingCandidate ? (
          <Skeleton animation="wave" width={500} height={45} />
        ) : (
          <Stack sx={styles.candidateNameHeader}>
            {candidate?.firstname} {candidate?.lastname}
          </Stack>
        )}
        {loadingCandidate ? (
          <Skeleton animation="wave" width={200} height={45} />
        ) : (
          <Stack sx={styles.candidateActionIconsContainer}>
            {permissions?.Candidates?.['Edit Candidates'] && (
              <Tooltip placement="top" title="Edit" arrow>
                <EditIcon
                  onClick={() => dispatch({ type: 'OPEN_EDIT_MODAL', payload: true })}
                  id="edit-candidate-button"
                />
              </Tooltip>
            )}
            {isAdminUser && (
              <Tooltip placement="top" title="Delete" arrow>
                <DeleteIcon
                  sx={{ color: '#E37D7A' }}
                  onClick={() => dispatch({ type: 'OPEN_DELETE_MODAL', payload: true })}
                  id="delete-candidate-button"
                />
              </Tooltip>
            )}
            {candidate?.duplicated_candidates.length > 1 && (
              <Tooltip placement="top" title="Duplicated" arrow>
                <PeopleAltIcon
                  onClick={() => setOpenMergeModal(true)}
                  id="duplicated-candidate-button"
                />
              </Tooltip>
            )}
          </Stack>
        )}
      </Stack>
      <Tabs
        value={tabValue}
        onChange={(e, value) => setTabValue(value)}
        TabIndicatorProps={{
          sx: { ...styles.candidateTabIndicator, maxWidth: tabValue ? '4.8em' : '3.5em' }
        }}
        sx={styles.candidateTabs}
      >
        <Tab label="Profile" id="candidate-profile-tab" />
        <Tab label="Activities" id="candidate-activities-tab" />
      </Tabs>
      <Divider />
      {tabValue ? (
        <ActivitiesTab candidateId={candidateId} dispatch={dispatch} />
      ) : (
        candidate && (
          <ProfileTab
            candidate={candidate}
            CandidateState={CandidateState}
            dispatch={dispatch}
            permissions={permissions}
          />
        )
      )}
      {CandidateState.openEditModal && (
        <EditCandidate
          CandidateState={CandidateState}
          editedCandidate={candidate}
          CandidateDispatch={dispatch}
        />
      )}
      <DeleteCandidate
        isDialogOpen={CandidateState.openDeleteModal}
        candidateId={candidateId}
        CandidateDispatch={dispatch}
      />
      <StyledSnackbar
        message={CandidateState.snackbar.message}
        state={CandidateState.snackbar.state}
        setSnackbarState={(snackbarInput: ISnackbarInput) =>
          dispatch({ type: 'SET_SNACKBAR', payload: snackbarInput })
        }
      />
      <MergeCandidates
        isOpen={openMergeModal}
        handleClose={() => setOpenMergeModal(false)}
        candidateId={candidateId}
        candidates={candidate?.duplicated_candidates}
        dispatch={dispatch}
      />
    </Stack>
  );
}
