import React, { Dispatch, FC, useState, SetStateAction } from 'react';
import {
  Box,
  CircularProgress,
  Modal,
  Button,
  TextField,
  Autocomplete,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { CreateReportEntityOptions } from '../types';
import { CustomReportsActionType, CustomReportsStateType } from '../types';

const entities = (
  entityOptions: CreateReportEntityOptions['entities'],
  initialEntities: CreateReportEntityOptions['entities']
) => {
  const savedEntities: CreateReportEntityOptions['entities'] = [];
  initialEntities.map((e) => {
    if (e.name === 'All entities') savedEntities.push(entityOptions[0]);
    else {
      const savedEntity = entityOptions.find((ent) => ent.id === e.id);
      if (savedEntity) savedEntities.push(savedEntity);
    }
  });
  return savedEntities;
};

interface IEntityFiltersModal {
  reportState: CustomReportsStateType;
  dispatch: Dispatch<CustomReportsActionType>;
  openEntityFiltersModal: boolean;
  setOpenEntityFiltersModal: Dispatch<SetStateAction<boolean>>;
  loadingCreateOptions: boolean;
}

const EntityFiltersModal: FC<IEntityFiltersModal> = ({
  reportState,
  dispatch,
  openEntityFiltersModal,
  setOpenEntityFiltersModal,
  loadingCreateOptions
}) => {
  const entityOptions = reportState.createReportOptions.entitiesOptions.entities;
  const [selectedEntities, setSelectedEntities] = useState<CreateReportEntityOptions['entities']>(
    entities(entityOptions, reportState.data.entityIds)
  );

  const handleClose = () => {
    setOpenEntityFiltersModal(false);
  };

  return (
    <Modal
      open={openEntityFiltersModal}
      onClose={handleClose}
      aria-labelledby="report-builder-entity-modal"
    >
      <Box sx={{ ...classes.actionsModal, maxHeight: '460px', width: '720px' }}>
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>Entity filter modal</Box>
        </Box>
        <Stack sx={{ marginTop: '1.75rem', flexGrow: 1 }}>
          <Box sx={{ ...classes.modalFormLine }}>
            <Autocomplete
              disablePortal
              autoHighlight
              includeInputInList
              multiple
              options={entityOptions}
              getOptionLabel={(option: { name: string; id: number | number[] }) => option.name}
              loading={loadingCreateOptions}
              loadingText={['Loading entity options', 'No entity options']}
              value={selectedEntities}
              onChange={(event, value) => {
                if (value.length > 0) {
                  //If All entities options is selected, remove all other selected options.
                  if (value[value.length - 1].name === 'All entities') {
                    value = [value[value.length - 1]];
                  } else {
                    //If All entities options is already selected and a new option is selected, remove the 'All entities' option
                    if (value[0].name === 'All entities') value.shift();
                  }
                  setSelectedEntities(value);
                } else {
                  //If user empty selection, it will default to "All entities"
                  setSelectedEntities([entityOptions[0]]);
                }
              }}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
              ListboxProps={{ style: classes.autocompleteListboxStyle }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Entity"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingCreateOptions && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  sx={{ input: { '&::placeholder': { opacity: 1 }, textTransform: 'capitalize' } }}
                />
              )}
            />
          </Box>
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset' }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              dispatch({ type: 'SET_ENTITIES', payload: selectedEntities });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default EntityFiltersModal;
