import { IUserPermissions } from '../sharedTypes';

interface IPermissions {
  id: number;
  name: string;
  paid_role: boolean;
  user_type: string;
  feature_groups: {
    name: string;
    permissions: string[];
  }[];
}

export const formatUserPermissions = (data: { roles: IPermissions[] }) => {
  const allRoles: Record<string, Record<string, boolean>> = {};
  data.roles.forEach((role: IPermissions) =>
    role.feature_groups.forEach((group) => {
      !allRoles[group.name] ? (allRoles[group.name] = {}) : null;
      group.permissions.forEach((permission) => (allRoles[group.name][permission] = true));
    })
  );
  return allRoles as IUserPermissions;
};

export const formatUserPermissionsAsArray = (data: { roles: IPermissions[] }) => {
  const rolesArray: string[] = [];
  data.roles.forEach((role: IPermissions) =>
    role.feature_groups.forEach((group) => {
      if (rolesArray.includes(group.name)) return;
      group.permissions.forEach((permission) => rolesArray.push(permission));
    })
  );
  return rolesArray;
};
