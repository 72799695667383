"use strict";
export const SmartFormsTableHeaders = [
  {
    name: "name",
    label: "Title",
    sortable: true
  },
  {
    name: "enabled",
    label: "Enabled",
    sortable: true
  },
  {
    name: "updated_at",
    label: "Updated At",
    sortable: true
  },
  {
    name: "created_at",
    label: "Created At",
    sortable: true
  },
  {
    name: "created_by",
    label: "Created By",
    sortable: false
  }
];
