import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';
import { classes } from '../../styles';
import StyledDropdown from '../../../SharedComponents/Inputs/StyledDropdown';
import { emailTones } from '../../config';
import StaticQuestionsPreview from '../../../SharedComponents/StaticQuestionsPreview';
import DynamicFooter from '../../../SharedComponents/Footers/DynamicFooter';

const InputStep = ({
  questions,
  emailTone,
  setEmailTone,
  generateFollowUpEmail,
  handleClose
}: {
  questions: string[];
  emailTone: string;
  setEmailTone: Dispatch<SetStateAction<string>>;
  generateFollowUpEmail: () => void;
  handleClose: () => void;
}) => {
  return (
    <>
      <Box sx={classes.emailQuestionsContainer}>
        <Box sx={classes.aiRow}>
          <Box sx={classes.fieldSubHeader}>Select email tone</Box>
          <StyledDropdown
            id="question-type-dropdown"
            value={emailTone}
            onChange={setEmailTone}
            options={emailTones}
          />
        </Box>
        <Box sx={classes.aiRow}>
          <Box sx={classes.fieldSubHeader}>Questions to include in email</Box>
          <StaticQuestionsPreview questions={questions} />
        </Box>
      </Box>
      <DynamicFooter
        primaryButtonText="Generate email"
        handlePrimaryButtonClick={generateFollowUpEmail}
        primaryButtonID="generate-follow-up-email-button"
        secondaryButtonText="Cancel"
        handleSecondaryButtonClick={handleClose}
        felixMessage={
          <>
            Select a tone and I'll write a <span>follow up email</span> to ask this candidate for
            further information.
          </>
        }
      />
    </>
  );
};

export default InputStep;
