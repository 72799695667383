import React, { useState } from 'react';
import { CandidateState } from './types';
import { TableCell, Stack, Collapse } from '@mui/material';
import { Circle as CircleIcon } from '@mui/icons-material';
import { classes } from '../../NewUI/Components/GenericTable/styles';
import { ICandidateApplication } from './types';
import dayjs from 'dayjs';
import { styles } from './styles';
import { getRatingColor } from '../Job/Applications/SelectedApplicants';

const CandidateApplicationsTableRows = ({
  column,
  item,
  tableHovered
}: {
  column: CandidateState['candidateApplicationsTableState']['columns'][number];
  item: ICandidateApplication;
  tableHovered: boolean;
}) => {
  const [showMore, setShowMore] = useState<number[]>([]);
  if (!column.enabled) return null;
  switch (column.id) {
    case 'id':
      return (
        <TableCell
          key={item.id + column.id}
          sx={{
            ...classes.sticky,
            ...classes.idTableCell,
            minWidth: '100px',
            left: '0px',
            ...styles.candidateApplicationsTableLink
          }}
        >
          <a
            href={item.sister_entity_application ? undefined : item.application_link}
            id="application-id-link"
          >
            {item.id || '-'}
          </a>
        </TableCell>
      );
    case 'job':
      return (
        <TableCell
          key={item.id + column.id}
          sx={{
            ...classes.sticky,
            ...classes.itemTitleTableCell,
            ...(tableHovered && classes.scrollShadowVisible),
            left: '100px',
            minWidth: '150px',
            ...styles.candidateApplicationsTableLink
          }}
        >
          <a href={item.sister_entity_application ? undefined : item.job_link} id="job-name-link">
            {item.job_title || '-'}
          </a>
        </TableCell>
      );
    case 'entity':
      return (
        <TableCell key={item.id + column.id} sx={styles.candidateApplicationsTableLink}>
          <a
            href={item.sister_entity_application ? undefined : item.entity_link}
            id="entity-name-link"
          >
            {item.entity || '-'}
          </a>
        </TableCell>
      );
    case 'created_at':
      return (
        <TableCell key={item.id + column.id}>
          {dayjs(item.created_at).format('D MMM YYYY, LT') || '-'}
        </TableCell>
      );
    case 'rating':
      return (
        <TableCell key={item.id + column.id}>
          {item.rating ? (
            <Stack
              sx={{
                ...styles.candidateApplicationsRating,
                background: getRatingColor(Number(item.rating))
              }}
            >
              {item.rating.replace('.0', '')}%
            </Stack>
          ) : (
            '-'
          )}
        </TableCell>
      );
    case 'status':
      return (
        <TableCell key={item.id + column.id}>
          {item.status ? (
            <Stack sx={styles.candidateApplicationsTableStatusContainer}>
              <CircleIcon sx={{ fontSize: '0.5rem', color: item.status_colour || '#808080' }} />
              {item.status}
            </Stack>
          ) : (
            '-'
          )}
        </TableCell>
      );
    case 'attachments':
      return (
        <TableCell key={item.id + column.id}>
          {item.attachments.length ? (
            <Stack>
              <Stack>{item.attachments[0]}</Stack>
              <Collapse in={showMore.includes(item.id)}>
                {item.attachments.slice(1).map((i, index) => (
                  <Stack key={index}>{i}</Stack>
                ))}
              </Collapse>
              {item.attachments.length > 1 && (
                <Stack
                  sx={{ color: '#084D6D', cursor: 'pointer', width: 'fit-content' }}
                  onClick={() => {
                    if (showMore.includes(item.id)) {
                      setShowMore(showMore.filter((s) => s !== item.id));
                    } else {
                      setShowMore([...showMore, item.id]);
                    }
                  }}
                >
                  Show {showMore.includes(item.id) ? 'less' : 'all'}
                </Stack>
              )}
            </Stack>
          ) : (
            '-'
          )}
        </TableCell>
      );
    default:
      return <TableCell></TableCell>;
  }
};

export default CandidateApplicationsTableRows;
