import React, { useState, useEffect, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import VirtualizeGrouped, {
  formatOptions
} from '../../../Components/CustomUIElements/VirtualizedGroupedAutocomplete';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SelectedApplicants from '../SelectedApplicants';
import CloseIcon from '@mui/icons-material/Close';
import { IExtendedActionModalProps } from '../../types';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import HtmlEditor from '../../../Components/Utilities/HtmlEditor';
import { FileWithPath } from 'react-dropzone';
import { serialize } from 'object-to-formdata';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import AttachmentSelectorAndPreview from '../../../Components/CustomUIElements/AttachmentSelectorAndPreview';
import { stringToSeconds } from '../../../Components/Utilities/stringToSeconds';
import DelaySelectWrapper from '../../../EditJob/Automation/shared/DelaySelectWrapper';

export default function EmailCandidates({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  filters
}: IExtendedActionModalProps) {
  const [isSending, setIsSending] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [isLoadingTemplateList, setIsLoadingTemplateList] = useState(false);
  const [templates, setTemplates] = useState<string[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [excludeApplicants, setExcludeApplicants] = useState(false);
  const [delay, setDelay] = useState('None');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [sendableBody, setSendableBody] = useState('');
  const [attachments, setAttachments] = useState<FileWithPath[]>([]);
  const [templateAttachments, setTemplateAttachments] = useState([]);
  const [placeholders, setPlaceholders] = useState<string>('');
  const [isLoadingEventList, setIsLoadingEventList] = useState(false);
  const [hasCareAdvantageIntegration, setHasCareAdvantageIntegration] = useState(false);
  const [careAdvantageEvents, setCareAdvantageEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);

  const templateRef = useRef<HTMLDivElement>(null);

  const handleSelectTemplate = async (value: string) => {
    if (!value) {
      setSelectedTemplate(null);
      setBody('');
      setSendableBody('');
      return;
    }
    setBody(
      '<p style="text-align: center;"><span style="font-size: 14pt;"><strong>Loading template...</strong></span></p>'
    );
    const id = value.split(' -')[0];
    setSelectedTemplate(value);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/email_templates/${id}`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const data = await response.json();
      setSubject(data.subject);
      setBody(data.body);
      setSendableBody(data.body);
      setTemplateAttachments(data.email_attachments);
      setTemplateError(false);
      if (response.status !== 200) {
        throw new Error(data);
      }
    } catch (error) {
      setSnackbar({
        message: 'Error loading template',
        state: 'error'
      });
      setBody('');
    }
  };

  const getTemplatesAndPlaceholders = useCallback(async () => {
    setIsLoadingTemplateList(true);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/email_templates?group_templates=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((res) => res.json());
      const formattedTemplates = formatOptions(response.email_templates);
      setTemplates(formattedTemplates);
      setPlaceholders(response.placeholders);
    } catch (error) {
      setSnackbar({
        message: 'Error fetching templates',
        state: 'error'
      });
    } finally {
      setIsLoadingTemplateList(false);
    }
  }, [apiKey, jobId, setSnackbar]);

  const handleClose = () => {
    setModalsOpen((prev) => ({ ...prev, email: false }));
    setSelectedTemplate(null);
    setExcludeApplicants(false);
    setDelay('None');
    setSubject('');
    setBody('');
    setSendableBody('');
    setAttachments([]);
  };

  const getCareAdvantageEvents = useCallback(async () => {
    setIsLoadingEventList(true);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_emails/care_advantage_events`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((res) => res.json());
      setHasCareAdvantageIntegration(response.has_care_advantage_integration);
      setCareAdvantageEvents(response.care_advantage_events);
    } catch (error) {
      setSnackbar({
        message: 'Error fetching care advantage events',
        state: 'error'
      });
    } finally {
      setIsLoadingEventList(false);
    }
  }, [apiKey, jobId, setSnackbar]);

  const handleSubmit = async () => {
    if (!selectedTemplate) {
      setTemplateError(true);
      templateRef?.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setIsSending(true);
    const targetEvent = careAdvantageEvents.find((event) => event.name === selectedEvent);
    const commaSeparatedAppIds = globalSelected.map((app) => app.id).join(',');
    const data = {
      email_batch: {
        applications_ids: commaSeparatedAppIds,
        email_template_id: selectedTemplate.split(' -')[0],
        subject,
        body: sendableBody,
        delay: stringToSeconds(delay),
        only_new: excludeApplicants,
        care_advantage_event_id: targetEvent ? targetEvent.id : '',
        ...(attachments.length > 0 && { new_email_attachments: attachments }),
        filters: filters
      }
    };
    const formData = serialize(data);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/applications/bulk_email`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey
        },
        body: formData
      });
      if (response.status !== 201) {
        const errorData = await response.json();
        throw new Error(errorData.errors || 'An unexpected error occurred');
      } else {
        setSnackbar({
          message: 'Candidates emailed successfully',
          state: 'success'
        });
        handleClose();
      }
    } catch (error) {
      setSnackbar({
        message: error.message,
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (modalsOpen.email) {
      getTemplatesAndPlaceholders();
      getCareAdvantageEvents();
    }
  }, [getTemplatesAndPlaceholders, getCareAdvantageEvents, modalsOpen.email]);

  return (
    <Modal
      open={modalsOpen.email}
      onClose={handleClose}
      disableEnforceFocus
      aria-labelledby="email-candidates-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
        <Box id="email-candidates-title" sx={classes.actionModalHeader}>
          Email Candidates
        </Box>
        <Box
          sx={classes.modalContent}
          id="email-candidates-modal-content"
          aria-label="email Candidates Modal Content"
        >
          <Box role="form" id="email-candidates-form" aria-label="Email Candidates form">
            <Box sx={classes.actionModalFormLine} ref={templateRef}>
              <VirtualizeGrouped
                value={selectedTemplate}
                options={templates}
                onChange={handleSelectTemplate}
                styles={{
                  ...sharedClasses.formAutocomplete,
                  width: '600px',
                  marginTop: '20px'
                }}
                label="Template"
                loading={isLoadingTemplateList}
                loadingText="Loading template list..."
              />
              {templateError && <Box sx={classes.formError}>Please select a template</Box>}
            </Box>
            <Box sx={classes.actionModalFormLine}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={classes.formCheckbox}
                    onChange={(e) => setExcludeApplicants(e.target.checked)}
                    value={excludeApplicants}
                  />
                }
                label="Exclude applicants who have received this email template"
                sx={classes.checkboxLabel}
              />
            </Box>
            <Box sx={{ marginLeft: '15px' }}>
              <h3>Email details</h3>
            </Box>
            <DelaySelectWrapper delay={delay} setDelay={setDelay} />
            {hasCareAdvantageIntegration && (
              <Box sx={{ ...classes.actionModalFormLine, rowGap: '0px !important' }}>
                <Virtualize
                  value={selectedEvent}
                  passOptions={careAdvantageEvents.map((event) => event.name)}
                  setValue={(value: string) => {
                    setSelectedEvent(value);
                  }}
                  passedStyles={{
                    ...sharedClasses.formAutocomplete,
                    width: '600px',
                    marginTop: '20px'
                  }}
                  label="Select a Care Advantage Event"
                />
                <Box sx={classes.placeholderText}>
                  Placeholder to use: {'{'}CARE_ADVANTAGE_INVITE_LINK{'}'}
                </Box>
              </Box>
            )}
            <Box sx={classes.actionModalFormLine}>
              <FormTextField
                id="email-subject"
                label="Subject"
                value={subject}
                styles={{ width: '290px' }}
                onChange={(e) => setSubject(e.target.value)}
              />
            </Box>
            <Box sx={classes.modalSection}>
              <Box sx={classes.formLabel}>Body</Box>
              <HtmlEditor body={body} onChange={setSendableBody} />
              <Box sx={classes.placeholderText}>Available placeholders are: {placeholders}</Box>
            </Box>
            <AttachmentSelectorAndPreview
              attachments={attachments}
              setAttachments={setAttachments}
              templateAttachments={templateAttachments}
            />
            <Box sx={{ marginLeft: '15px' }}>
              <h3>Candidates</h3>
              <SelectedApplicants
                globalSelected={globalSelected}
                handleDeselectCandidate={handleDeselectCandidate}
                closeModalCallback={handleClose}
                filters={filters}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <Button
            id="close-email-candidate-button"
            sx={classes.modalCancelButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            id="send-email-candidate-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '71px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSending ? <CircularProgress size={20} color="inherit" /> : 'Send'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
