import React from 'react';
import { Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { classes } from '../styles';

export default function ImmediateChangesNotice() {
  return (
    <Box sx={classes.noticeContainer}>
      <Box>
        <InfoIcon sx={classes.noticeIcon} />
        Any changes here will be automatically saved and will be applied immediately.
      </Box>
    </Box>
  );
}
