import React, { useEffect, useState, useCallback, useRef } from 'react';
import { classes } from './NewCouncilJobsAd/styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ITabs, IJob } from '../../../Job/types';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import moment from 'moment';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ApproveSVG from './ApproveSVG';
import dompurify from 'dompurify';
import StyledSnackbar from '../../CustomUIElements/StyledSnackbar';
import CircularProgress from '@mui/material/CircularProgress';

const BASE_URL = window.location.origin;

export default function CouncilJobsAd({
  apiKey,
  job,
  jobAdId,
}: {
  apiKey: string;
  job: IJob;
  jobAdId: number;
}) {
  const [ad, setAd] = useState(null);
  const [loading, setLoading] = useState(false);
  const [approving, setApproving] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const headerRefs = useRef<HTMLDivElement[]>([]);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });

  const TabPanel = useCallback((props: ITabs) => {
    const { children, tabValue, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        id={`ads-tabpanel-${index}`}
        aria-labelledby={`jobs-tab-${index}`}
        {...other}
      >
        {tabValue === index && <>{children}</>}
      </div>
    );
  }, []);

  const handleApprove = async (ad) => {
    setApproving(true);
    const url = `/api/v4/council_jobs/jobs/${job.id}/job_ads/${jobAdId}/approve`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      if (response.success) {
        setSnackbar({
          message: response.success,
          state: 'success'
        });
      } else {
        setSnackbar({
          message: response.errors,
          state: 'error'
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setApproving(false);
    }
  };

  const getAd = useCallback(async () => {
    setLoading(true);
    const url = `/api/v4/council_jobs/jobs/${job.id}/job_ads/${jobAdId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setAd(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [apiKey, job]);

  useEffect(() => {
    getAd();
  }, [getAd]);

  useEffect(() => {
    setIndicatorWidth(headerRefs.current[tabValue].clientWidth - 32);
  }, [tabValue]);

  return (
    <Box sx={classes.adPageContainer}>
      <Box sx={classes.adPageHeader}>
        {loading ? (
          <Skeleton animation="wave" width={600} height={56} />
        ) : (
          <Box sx={{ color: '#666666' }}>
            <h4 id="reference">{ad?.reference}</h4>
            <h1 id="title">{ad?.title}</h1>
          </Box>
        )}
      </Box>
      <Box sx={classes.tabsContainer}>
        <Tabs
          value={tabValue}
          aria-label="Council Jobs Ad Details Tab"
          sx={{
            ...classes.tabs,
            '& .MuiTabs-indicator': { ...classes.tabIndicator, maxWidth: indicatorWidth + 'px' }
          }}
        >
          <Tab
            disabled
            ref={(el) => (el ? (headerRefs.current[0] = el) : null)}
            label={'Job ad details'}
          />
          <TabPanel tabValue={tabValue} index={0}></TabPanel>
        </Tabs>
      </Box>
      {loading ? (
        <Skeleton animation="wave" sx={{ marginLeft: '1rem', marginBottom: '20px' }} width={200} height={56} />
      ) : (
        <Box sx={classes.backToJobAdButton}>
          <Stack
            flexDirection="row"
            alignItems="center"
            onClick={() => {
              window.location.href = `${BASE_URL}/admin/jobs/${job.id}/job_ads`;
            }}
          >
            <IconButton sx={classes.iconGrey}>
              <ArrowCircleLeftIcon />
            </IconButton>
            <Box>Back to multiposter</Box>
          </Stack>
        </Box>
      )}
      {loading ? (
        <Skeleton animation="wave" sx={{ marginLeft: '1rem' }} width={200} height={56} />
      ) : (
        <Box sx={classes.tabContent}>
          <Box sx={classes.actionsContianer}>
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              sx={classes.editButton}
              onClick={() => {
                window.location.href = `${BASE_URL}${ad?.edit_url}`;
              }}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              startIcon={approving ? <CircularProgress size={16} /> : <ApproveSVG />}
              sx={classes.approveButton}
              onClick={() => handleApprove(ad)}
            >
              Approve
            </Button>
          </Box>
          <Box sx={{ ...classes.headerContent, marginBottom: '20px' }}>
            Your full job ad
          </Box>
          <Box sx={classes.detailsTitle}>Job title</Box>
          <Box sx={classes.details}>{ad?.title}</Box>
          <Box sx={classes.detailsTitle}>Ad details</Box>
          <Box
            sx={classes.details}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(ad?.ad_details) }}
          />
          <Box id="counciljobs-logo" sx={{ marginTop: '40px', width: '140px !important' }} />
          <Box sx={classes.detailsTitle}>Employer name</Box>
          <Box sx={classes.details}>{ad?.council_jobs_employer_name}</Box>
          <Box sx={classes.detailsTitle}>Ad expiry date</Box>
          <Box sx={classes.details}>{moment(ad?.council_jobs_ad_expiry_date).format('D MMM YYYY')}</Box>
          <Box sx={classes.detailsTitle}>Categories</Box>
          <Box sx={classes.details}>{ad?.council_jobs_categories_to_sentence || '-'}</Box>
          <Box sx={classes.detailsTitle}>Job type</Box>
          <Box sx={classes.details}>{ad?.job_type_to_sentence || '-'}</Box>
          <Box sx={classes.detailsTitle}>Apply type</Box>
          <Box sx={classes.details}>{ad?.council_jobs_apply_type}</Box>
          <Box sx={classes.detailsTitle}>Apply value</Box>
          <Box sx={classes.details}>{ad?.council_jobs_apply_value}</Box>
          <Box sx={classes.detailsTitle}>Job posting options</Box>
          <Box sx={classes.details}>{ad?.council_jobs_posting_options ? ad.council_jobs_posting_options.join(', ') : '-'}</Box>
          <Box sx={{ ...classes.headerContent, marginTop: '40px' }}>
            Other details
          </Box>
          <Box sx={classes.detailsTitle}>Created by</Box>
          <Box sx={classes.details}>{ad?.created_by}</Box>
          <Box sx={classes.detailsTitle}>Created at</Box>
          <Box sx={classes.details}>{moment(ad?.created_at).format('D MMM YYYY HH:mm')}</Box>
          <Box sx={classes.detailsTitle}>Updated by</Box>
          <Box sx={classes.details}>{ad?.updated_by}</Box>
          <Box sx={classes.detailsTitle}>Updated at</Box>
          <Box sx={classes.details}>{moment(ad?.updated_at).format('D MMM YYYY HH:mm')}</Box>
        </Box>
      )}
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  )
}