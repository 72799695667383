import React, { Dispatch } from 'react';
import { EngageState } from '../reducer';
import { TableActionItemAction } from '../../NewUI/Components/GenericTable/types';
import { TCandidate } from '../types';
import AttachmentsList from '../../NewUI/Components/GenericTable/TablePopovers/AttachmentsList';
import AddNoteWithSubjectLine from '../../NewUI/Components/GenericTable/TablePopovers/AddNoteWithSubjectLine';
import MoreOptionsList from '../../NewUI/Components/GenericTable/TablePopovers/MoreOptionsList';

const EngageTableActionComponents = ({
  actionsState,
  dispatch
}: {
  actionsState: EngageState['searchTable']['actions'];
  dispatch: Dispatch<TableActionItemAction<TCandidate>>;
}) => {
  return (
    <>
      <AttachmentsList
        attachments={actionsState?.selectedItem?.attachment_url || []}
        isOpen={actionsState?.isOpen?.viewAttachments}
        handleClose={() => dispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'viewAttachments' })}
        anchorEl={actionsState?.anchorEl}
      />
      <AddNoteWithSubjectLine
        dispatch={dispatch}
        isOpen={actionsState?.isOpen?.addNote}
        anchorEl={actionsState?.anchorEl}
        itemId={actionsState?.selectedItem?.id || 0}
        url="/api/v4/searches/add_note"
        queryKey="candidates"
      />
    </>
  );
};

export default EngageTableActionComponents;
