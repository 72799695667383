"use strict";
import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "30px 0",
    margin: "30px 0",
    position: "relative",
    fontSize: 16,
    "&:not(:last-child) > div:after": {
      content: '""',
      display: "block",
      position: "absolute",
      width: "200%",
      height: 1,
      backgroundColor: "#DDD",
      bottom: 0
    },
    "&:last-child > div": {
      marginBottom: 5
    }
  },
  detailsTop: {
    display: "flex",
    alignItems: "center",
    "& > div": {
      flexBasis: "25%"
    }
  },
  date: {
    display: "flex",
    maxWidth: "30%"
  },
  dateDot: {
    width: 26,
    height: 26,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "100%",
    marginRight: 20
  },
  details: {
    fontSize: 15,
    "& h4": {
      marginTop: 0,
      fontSize: 15
    },
    "& > div": {
      display: "flex",
      "& span:last-child": {
        marginLeft: 6,
        fontWeight: 600
      }
    }
  },
  detailsButton: {
    display: "flex",
    cursor: "pointer",
    userSelect: "none"
  },
  detailsBottom: {
    display: "flex",
    marginTop: 20,
    "& > div": {
      flexBasis: "25%",
      "&:first-child": {
        display: "flex",
        flexDirection: "column",
        padding: "0 6% 0 20px",
        "& button": {
          margin: "6px 15px"
        }
      },
      "& > span": {
        marginTop: 40,
        display: "block",
        color: "#939393"
      }
    }
  },
  buttonRedLight: {
    padding: "14px 30px",
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    "&:hover": {
      background: "#fffafa"
    }
  },
  divider: {
    height: 1,
    width: "100%",
    backgroundColor: "#E5E7EB",
    margin: "20px 0",
    "& ~ button": {
      margin: "6px 0 !important"
    }
  },
  buttonOutline: {
    backgroundColor: "#FFF",
    border: "1px solid #E5E7EB",
    color: "#666666",
    "&:hover": {
      backgroundColor: "#fbfbfb"
    }
  },
  expandDetailItem: {
    paddingBottom: 15,
    "& div": {
      color: "#939393",
      marginBottom: 4
    }
  }
}));
