"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  pageContainer: {
    padding: "30px 30px"
  },
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& h2": {
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "1.2em",
      color: "#666666",
      marginTop: "0",
      "@media (max-width: 1450px)": {
        fontSize: "20px"
      },
      "@media (max-width: 1260px)": {
        fontSize: "14px"
      }
    }
  },
  attachmentsTitle: {
    color: theme.palette.primary.main,
    fontSize: "13px",
    fontWeight: "bold",
    paddingBottom: "5px",
    width: "70px",
    padding: "0 6px"
  },
  actionsContianer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "22px",
    padding: "10px 0"
  },
  actionButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#666666",
    backgroundColor: "#FFFFFF",
    padding: "5px 23px",
    borderRadius: "20px",
    border: "1px solid #DDDDDD",
    textTransform: "none"
  },
  checkedPill: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#5BC4BF",
    backgroundColor: "#FFFFFF",
    padding: "5px 23px",
    borderRadius: "20px",
    border: "1px solid #5BC4BF",
    textTransform: "none"
  },
  addAttachmentButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#5BC4BF",
    backgroundColor: "#5BC4BF12",
    borderRadius: "6px",
    boxShadow: "none",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#4da8a412",
      boxShadow: "none"
    }
  },
  tableWrapper: {
    tableLayout: "auto !important",
    minWidth: "400px",
    border: "none",
    overflowY: "clip",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "26px 10px"
    },
    th: {
      border: "none",
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    tr: {
      fontSize: "16px",
      borderBottom: "1px solid #DDDDDD"
    }
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "40px",
    padding: "24px 16px"
  },
  checkbox: {
    color: "#DDDDDD",
    marginLeft: "10px",
    "&.Mui-checked": {
      color: theme.palette.action.main
    },
    "& .MuiSvgIcon-root": {
      fontSize: "22px"
    }
  }
};
