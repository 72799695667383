import React, { Dispatch, FC, useState } from 'react';
import {
  Box,
  CircularProgress,
  Modal,
  Button,
  TextField,
  Autocomplete,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import { useQuery } from '@tanstack/react-query';
import RecruitApi from '../../RecruitApi';
import { Country, State } from 'country-state-city';
import { ICountry, IState } from 'country-state-city';
import { IEntity } from '../types';

interface ICandidatesEShotsModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
  getEntitiesAndChildren: (id: number) => void;
}

const CandidatesEShotsModal: FC<ICandidatesEShotsModal> = ({
  dispatch,
  state,
  getEntitiesAndChildren
}) => {
  const [country, setCountry] = useState<ICountry | null>(null);
  const [selectedState, setSelectedState] = useState<IState | null>(null);
  const [selectedEntity, setSelectedEntity] = useState<IEntity | null>(null);
  const { filtersModal, openPreview } = state;

  const { data: rootEntities, isFetching: fetchingRootEntities } = useQuery({
    queryKey: ['root entities'],
    queryFn: async () => {
      const { res } = await RecruitApi.getRootEntities();
      return res.entities;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading entities, ${error}`,
          state: 'error'
        }
      }),
    initialData: [],
    enabled: openPreview === 'Candidates for eShots Report'
  });

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, 'Candidates for eShots Report': false }
    });
  };

  return (
    <Modal
      open={filtersModal['Candidates for eShots Report']}
      onClose={handleClose}
      aria-labelledby="candidates-for-eShots-filters-modal"
    >
      <Box sx={{ ...classes.actionsModal, maxHeight: '500px', width: '720px' }}>
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>
            Candidates for sShot report filters
          </Box>
        </Box>
        <Stack sx={{ marginTop: '1.75rem', flexGrow: 1, rowGap: 3 }}>
          <Box sx={{ ...classes.modalFormLine }}>
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              options={rootEntities}
              getOptionLabel={(option) => option.name}
              value={selectedEntity}
              onChange={(_event, newValue) => setSelectedEntity(newValue)}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro',
                  color: '#333333',
                  maxHeight: '200px',
                  textTransform: 'capitalize'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Root entities"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {fetchingRootEntities && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  placeholder="Please select"
                  sx={{ input: { '&::placeholder': { opacity: 1 } } }}
                />
              )}
            />
          </Box>
          <Box sx={{ ...classes.modalFormLine }}>
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              value={country}
              options={Country.getAllCountries()}
              getOptionLabel={(option) => option.name}
              onChange={(_event, newValue) => {
                setCountry(newValue);
                setSelectedState(null);
              }}
              sx={{ ...sharedClasses.formAutocomplete, width: '40%' }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro',
                  color: '#333333',
                  maxHeight: '200px',
                  textTransform: 'capitalize'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Countries"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Please select"
                  sx={{ input: { '&::placeholder': { opacity: 1 } } }}
                />
              )}
            />
          </Box>
          <Box sx={{ ...classes.modalFormLine }}>
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              disabled={!country}
              value={selectedState}
              options={State.getStatesOfCountry(country?.isoCode)}
              getOptionLabel={(option) => option.name}
              onChange={(_event, newValue) => setSelectedState(newValue)}
              sx={{ ...sharedClasses.formAutocomplete, width: '40%' }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro',
                  color: '#333333',
                  maxHeight: '200px',
                  textTransform: 'capitalize'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Please select"
                  sx={{ input: { '&::placeholder': { opacity: 1 } } }}
                />
              )}
              noOptionsText="The country has no state"
            />
          </Box>
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset' }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              const filter: Record<string, string | number> = {};
              if (selectedEntity) {
                filter.Entity = selectedEntity.name;
                getEntitiesAndChildren(selectedEntity.id);
              } else {
                dispatch({ type: 'SELECTED_ENTITIES', payload: [] });
              }
              if (country) filter.Country = country.name;
              if (selectedState) {
                filter.State = selectedState.name;
                dispatch({ type: 'COUNTRY_STATE', payload: selectedState.name });
              } else {
                dispatch({ type: 'COUNTRY_STATE', payload: '' });
              }
              dispatch({ type: 'SET_FILTERS', payload: filter });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default CandidatesEShotsModal;
