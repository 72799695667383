import React, { useCallback, useState, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Box, Button, CircularProgress } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { classes } from '../../EditJob/styles';
import StyledSnackbar from '../CustomUIElements/StyledSnackbar';
import {
  activityReportStackedHorizontalBarOptions,
  exportPDF,
  htmlLegendPlugin
} from '../../../reports/DataVizSection/GraphDefaultOptions';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function ApplicationSources({
  chartData,
  reportState,
  refChartWithoutActors,
  refChartWithActors
}: {
  chartData: IChartData;
  reportState;
  refChartWithoutActors;
  refChartWithActors;
}) {
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });

  const downloadChart = useCallback(() => {
    if (refChartWithoutActors.current === null) return;
    exportPDF(refChartWithoutActors, setSnackbar);
    exportPDF(refChartWithActors, setSnackbar);
  }, [refChartWithoutActors, refChartWithActors]);

  const isLoading = reportState.statusActivitiesFields.loadingOptions;

  return (
    <Box style={{ marginTop: '2rem' }}>
      <Box sx={isLoading ? classes.loadingWrapper : null}>
        {isLoading && <CircularProgress sx={{ position: 'absolute' }} size={24} color="inherit" />}
        <Box
          sx={isLoading ? classes.loadingContent : classes.graphWrapper}
          ref={refChartWithoutActors}
        >
          {reportState.statusActivitiesWithoutActor?.datasets?.length ? (
            <Box>
              <Box sx={{ position: 'relative', minHeight: '525px', maxHeight: '1200px' }}>
                <Bar
                  options={activityReportStackedHorizontalBarOptions(reportState)}
                  data={chartData}
                  plugins={[htmlLegendPlugin]}
                />
              </Box>
              <div id="legend-container-horizontal" />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Box>
      <Box sx={{ ...classes.tabFooter, minWidth: 'unset' }}>
        {reportState.statusActivitiesWithActor?.datasets?.length ? (
          <Button
            id="visual-reports-activity-report-download"
            type="submit"
            sx={classes.saveButton}
            onClick={downloadChart}
          >
            {' '}
            Download
          </Button>
        ) : (
          <Box sx={{ margin: '10px 0' }} />
        )}
      </Box>
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
