import React from 'react';
import { Box, Grid } from '@mui/material';
import { TDetailedEmail } from '../../types';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import ReactLoading from 'react-loading';
import { classes } from '../../styles';
import { theme } from '../../../ThemeContext/ThemeObject';
import DoneIcon from '@mui/icons-material/Done';
import DraftsIcon from '@mui/icons-material/Drafts';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CloseIcon from '@mui/icons-material/Close';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';

const StatusStatistics = ({
  isLoading,
  emailAnalytics
}: {
  isLoading: boolean;
  emailAnalytics: TDetailedEmail;
}) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const stats = emailAnalytics?.statistics?.percentages;

  return (
    <Box sx={{ ...classes.rowWidget, height: '100%', marginTop: '50px', paddingRight: '4%' }}>
      {isLoading ? (
        <Box
          sx={{
            height: '300px',
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <ReactLoading type="spin" color={theme.palette.primary.main} width={100} height={100} />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid xs={6} sx={{ paddingRight: '25px', display: 'flex', justifyContent: 'center' }}>
            <Grid
              container
              spacing={2}
              sx={{ display: 'inline-flex', padding: '40px 0 15px 0', width: '24rem' }}
            >
              <Grid xs={12}>
                <Box
                  sx={{
                    ...classes.statusStatsWrapper,
                    backgroundColor: stats.delivered
                      ? 'rgba(109, 190, 191, 0.2)'
                      : 'rgba(229, 229, 229, 0.5)'
                  }}
                >
                  <Box
                    sx={{
                      ...classes.statusStatsWrapperMetrics,
                      color: stats.delivered ? theme.palette.secondary.medium : '#666666'
                    }}
                  >
                    <Box sx={{ width: '5.5rem', margin: 'auto' }}>
                      <DoneIcon
                        sx={{
                          color: stats.delivered ? theme.palette.secondary.medium : '#666666',
                          fontSize: '23px'
                        }}
                      />
                      <Box>{stats.delivered_pcent}%</Box>
                      <Box sx={{ fontSize: '15px', fontWeight: '400', display: 'flex' }}>
                        {stats.delivered}
                        <Box sx={{ color: 'black', marginLeft: '3px' }}> Delivered</Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box
                  sx={{
                    ...classes.statusStatsWrapper,
                    backgroundColor: stats.opened
                      ? 'rgba(146, 104, 247, 0.2)'
                      : 'rgba(229, 229, 229, 0.5)'
                  }}
                >
                  <Box
                    sx={{
                      ...classes.statusStatsWrapperMetrics,
                      color: stats.opened ? theme.palette.secondary.purple : '#666666'
                    }}
                  >
                    <DraftsIcon
                      sx={{
                        color: stats.opened ? theme.palette.secondary.purple : '#666666',
                        fontSize: '23px'
                      }}
                    />
                    <Box>{stats.opened_pcent}%</Box>
                    <Box sx={{ fontSize: '15px', fontWeight: '400', display: 'flex' }}>
                      {stats.opened}
                      <Box sx={{ color: 'black', marginLeft: '3px' }}> Opened</Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box
                  sx={{
                    ...classes.statusStatsWrapper,
                    backgroundColor: stats.clicks
                      ? 'rgba(247, 161, 255, 0.2)'
                      : 'rgba(229, 229, 229, 0.5)'
                  }}
                >
                  <Box
                    sx={{
                      ...classes.statusStatsWrapperMetrics,
                      color: stats.clicks ? theme.palette.secondary.pink : '#666666'
                    }}
                  >
                    <AdsClickIcon
                      sx={{
                        color: stats.clicks ? theme.palette.secondary.pink : '#666666',
                        fontSize: '23px'
                      }}
                    />
                    <Box>{stats.clicks_pcent}%</Box>
                    <Box sx={{ fontSize: '15px', fontWeight: '400', display: 'flex' }}>
                      {stats.clicks}
                      <Box sx={{ color: 'black', marginLeft: '3px' }}>Clicks</Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box
                  sx={{
                    ...classes.statusStatsWrapper,
                    backgroundColor: stats.bounced
                      ? 'rgba(236, 110, 133, 0.2)'
                      : 'rgba(229, 229, 229, 0.5)'
                  }}
                >
                  <Box
                    sx={{
                      ...classes.statusStatsWrapperMetrics,
                      color: stats.bounced ? theme.palette.secondary.mediumRed : '#666666'
                    }}
                  >
                    <CloseIcon
                      sx={{
                        color: stats.bounced ? theme.palette.secondary.mediumRed : '#666666',
                        fontSize: '23px'
                      }}
                    />
                    <Box>{stats.bounced}</Box>
                    <Box sx={{ fontSize: '15px', fontWeight: '400', display: 'flex' }}>
                      {emailAnalytics.statistics.percentages.bounced}
                      <Box sx={{ color: 'black', marginLeft: '3px' }}>Bounced</Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box
                  sx={{
                    ...classes.statusStatsWrapper,
                    backgroundColor: emailAnalytics.statistics.percentages.unsubscribed
                      ? 'rgba(126, 167, 228, 0.2)'
                      : 'rgba(229, 229, 229, 0.5)'
                  }}
                >
                  <Box
                    sx={{
                      ...classes.statusStatsWrapperMetrics,
                      color: emailAnalytics.statistics.percentages.unsubscribed
                        ? theme.palette.secondary.darkBlue
                        : '#666666'
                    }}
                  >
                    <UnsubscribeIcon
                      sx={{
                        color: emailAnalytics.statistics.percentages.unsubscribed
                          ? theme.palette.secondary.darkBlue
                          : '#666666',
                        fontSize: '23px'
                      }}
                    />
                    <Box>{emailAnalytics.statistics.percentages.unsubscribed_pcent}%</Box>
                    <Box sx={{ fontSize: '15px', fontWeight: '400', display: 'flex' }}>
                      {emailAnalytics.statistics.percentages.unsubscribed}
                      <Box sx={{ color: 'black', marginLeft: '3px' }}>Unsubscribed</Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6} sx={{ paddingLeft: '25px', margin: 'auto' }}>
            <Box sx={{ position: 'relative', minHeight: '300px', maxHeight: '500px' }}>
              <Bar
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        stepSize: 1
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
                data={emailAnalytics?.statistics?.status_statistics}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default StatusStatistics;
