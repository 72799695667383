import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, TextField, TextFieldProps, Popover, Button, CircularProgress } from '@mui/material';
import { classes } from '../../../../reports/DataVizSection/styles';
import { SearchAction } from '../../../../reports/DataVizSection/useReport';
import DayPicker, { DateUtils } from 'react-day-picker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from 'moment';

interface Props {
  isLoading: boolean;
  reportState: {
    startDate: Date | null;
    endDate: Date | null;
    dateRange: { label: string; value: string };
  };
  reportDispatch: any;
  fieldName: string;
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  handleClose?: () => void;
}

export default function DateStartToEndRangeField(myProps: Props) {
  const { isLoading, reportState, reportDispatch, fieldName, anchorEl, setAnchorEl, handleClose } =
    myProps;

  const [publishedRange, setPublishedRange] = useState({
    from: moment(reportState.startDate).toDate(),
    to: moment(reportState.endDate).toDate()
  });

  const [enteredTo, setEnteredTo] = useState<Date | null>(moment(reportState.endDate).toDate());

  const modifiersStyles = {
    first: {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%'
    },
    last: {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%'
    }
  };

  const publishedModifiers = {
    start: publishedRange.from,
    end: publishedRange.to
  };

  function isSelectingFirstDay(from: Date | null, to: Date | null, day: Date | null) {
    if (!day) return;
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  function checkDateValid() {
    if (
      moment(reportState.startDate).isBefore('2000-01-01') ||
      moment(reportState.endDate).isBefore('2000-01-01') ||
      moment(reportState.startDate).isAfter(reportState.endDate) ||
      moment(reportState.endDate).isBefore(reportState.startDate) ||
      !moment(reportState.startDate).isValid() ||
      !moment(reportState.endDate).isValid()
    ) {
      setRange({
        from: moment().subtract(30, 'days').toDate(),
        to: moment().toDate()
      });
      setEnteredTo(moment().toDate());
    }
  }

  function setRange(range: { from: null | Date; to: null | Date }) {
    reportDispatch({
      type: fieldName,
      payload: {
        startDate: range.from,
        endDate: range.to,
        dateRange: reportState.dateRange
      }
    });
    setPublishedRange(range);
  }

  function handlePublishedDayClick(day: Date) {
    const { from, to } = publishedRange;
    if (from && to && day >= from && day <= to) {
      setRange({ from: day, to: null });
      setEnteredTo(null);
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      setRange({ from: day, to: null });
      setEnteredTo(null);
    } else {
      setRange({ from: from, to: day });
      setEnteredTo(day);
    }
  }

  function handleDayMouseEnter(day: Date) {
    if (!isSelectingFirstDay(publishedRange.from, publishedRange.to, day)) {
      setEnteredTo(day);
    }
  }

  return (
    <Box sx={{ position: 'relative', margin: 'auto 0 0 20px' }}>
      <Box>
        <Popover
          id="filter-dropdown-popover"
          sx={classes.popover}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => {
            checkDateValid();
            setAnchorEl(null);
            handleClose && handleClose();
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Box sx={classes.filterPopoverContent}>
            <Box sx={classes.dateDisplay}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  label=""
                  inputFormat="DD/MM/yyyy"
                  value={reportState.startDate}
                  minDate={moment().subtract(5, 'years').toDate()}
                  onChange={(date) => {
                    reportDispatch({
                      type: fieldName,
                      payload: { startDate: moment(date).toDate() }
                    });
                    if (moment(date).isAfter('1990-01-01')) {
                      setPublishedRange({
                        from: moment(date).toDate(),
                        to: publishedRange.to
                      });
                      setEnteredTo(publishedRange.to);
                    }
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} sx={classes.dateInput} />
                  )}
                />
                <Box sx={{ margin: '0 5px' }}>to</Box>
                <DesktopDatePicker
                  label=""
                  inputFormat="DD/MM/yyyy"
                  value={reportState.endDate}
                  onChange={(date) => {
                    reportDispatch({
                      type: fieldName,
                      payload: { endDate: date }
                    });
                    if (moment(date).isAfter(publishedRange.from)) {
                      setPublishedRange({
                        from: publishedRange.from,
                        to: moment(date).toDate()
                      });
                      setEnteredTo(moment(date).toDate());
                    }
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} sx={classes.dateInput} />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={publishedRange.from && publishedRange.to === null ? classes.onlyStartDate : null}
            >
              <Box sx={classes.datePicker}>
                <DayPicker
                  numberOfMonths={2}
                  onDayClick={handlePublishedDayClick}
                  selectedDays={[
                    moment(reportState.startDate).isAfter(moment().subtract(5, 'years').toDate())
                      ? moment(reportState?.startDate).format()
                      : null,
                    {
                      from: publishedRange.from,
                      to: enteredTo
                    }
                  ]}
                  modifiers={publishedModifiers}
                  disabledDays={[
                    {
                      before: moment().subtract(5, 'years').toDate()
                    }
                  ]}
                  modifiersStyles={modifiersStyles}
                  onDayMouseEnter={handleDayMouseEnter}
                />
              </Box>
            </Box>
            <Box sx={classes.filterPopoverHeader}>
              <Box
                onClick={() => {
                  setRange({ from: null, to: null });
                  setEnteredTo(null);
                }}
                sx={classes.resetText}
              >
                Reset
              </Box>
              <Button
                id="apply-filters-button"
                disabled={
                  !moment(reportState.startDate).isValid() ||
                  !moment(reportState.endDate).isValid() ||
                  moment(reportState.startDate).isAfter(reportState.endDate) ||
                  moment(reportState.endDate).isBefore(reportState.startDate) ||
                  moment(reportState.startDate).isBefore(
                    moment().subtract({ days: 1, years: 5 }).toDate()
                  )
                }
                variant="contained"
                onClick={() => {
                  if (isLoading) return;
                  setAnchorEl(null);
                  handleClose && handleClose();
                }}
                sx={classes.applyButton}
              >
                {isLoading ? <CircularProgress size={17} color="inherit" /> : 'Apply'}
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}
