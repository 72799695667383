import React, { useState, useRef, Dispatch, SetStateAction, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import DynamicFooter from '../../SharedComponents/Footers/DynamicFooter';
import { classes } from '../styles';
import { handleRichCopy } from '../../../NewUI/Components/Utilities/handleRichCopy';
import DisplayHTMLViewOnly from '../../SharedComponents/DisplayHTMLViewOnly';
import { modalSteps } from '../types';
import { IWebsocketData } from '../../SharedComponents/types';
import { convertToLocalEnglish } from '../../../NewUI/Components/Utilities/convertToLocalEnglish';
import { handleAPIRequest } from '../../../NewUI/Components/Utilities/handleAPIRequest';
import { IJobApplication } from '../../../NewUI/Job/types';
import { StyledSnackbarProps } from '../../../NewUI/Components/CustomUIElements/StyledSnackbar';

interface IProps {
  jobId?: number;
  setStep?: Dispatch<SetStateAction<modalSteps>>;
  summaryData: IWebsocketData | null;
  requestInsights?: () => void;
  handleDismissNotification?: () => void;
  isNotification?: boolean;
  emails: string[];
  setEmails: Dispatch<SetStateAction<string[]>>;
  selectedApplicants: IJobApplication[];
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
}

const ResponseStep = ({
  jobId,
  setStep,
  summaryData,
  requestInsights,
  handleDismissNotification,
  isNotification,
  emails,
  setEmails,
  selectedApplicants,
  setSnackbar
}: IProps) => {
  const [copied, setCopied] = useState(false);
  const summaryRef = useRef(null);
  const [emailInputOpen, setEmailInputOpen] = useState<boolean>(false);
  const [sendingEmails, setSendingEmails] = useState(false);
  const currentJobId = jobId || summaryData?.job_id;

  const downloadPDF = () => {
    const url = `/api/v4/jobs/${currentJobId}/bulk_application_summaries/${summaryData?.id}.pdf`;
    window.open(url, '_blank');
  };

  const handleSendEmailClick = () => {
    handleAPIRequest({
      url: `/api/v4/jobs/${currentJobId}/applications/send_ai_insights`,
      method: 'POST',
      body: {
        application_ids: selectedApplicants
          ? selectedApplicants.map((applicant) => applicant.id)
          : summaryData?.additional_information?.application_ids,
        summary_data: summaryData?.array_data,
        emails: emails.join(',')
      },
      onSuccess: () => {
        setEmailInputOpen(false);
        setEmails([]);
      },
      setLoaderState: setSendingEmails,
      setSnackbarState: setSnackbar
    });
  };

  const convertedSummaryData = useMemo(
    () =>
      convertToLocalEnglish(
        summaryData?.content || '<p>There was an error generating your summary</p>'
      ),
    [summaryData?.content]
  );

  return (
    <>
      <Box sx={classes.responseHeader}>
        <Box sx={classes.header}>AI candidate insights</Box>
        {!isNotification && setStep && (
          <Button sx={classes.pillButton} onClick={() => setStep('input')}>
            Back to settings
          </Button>
        )}
      </Box>
      <DisplayHTMLViewOnly
        content={convertedSummaryData}
        viewerRef={summaryRef}
        onExportClick={downloadPDF}
        setEmails={setEmails}
        emails={emails}
        onSendEmailClick={handleSendEmailClick}
        emailInputOpen={emailInputOpen}
        setEmailInputOpen={setEmailInputOpen}
        sendingEmails={sendingEmails}
      />
      <DynamicFooter
        primaryButtonText={copied ? 'Copied!' : 'Copy'}
        secondaryButtonText={isNotification ? 'Close' : 'Regenerate'}
        primaryButtonID="copy-summary-text"
        secondaryButtonID={`${isNotification ? 'regenerate' : 'close'}-summary`}
        primaryButtonMinWidth="110px"
        handlePrimaryButtonClick={() => handleRichCopy(summaryRef.current, setCopied)}
        handleSecondaryButtonClick={isNotification ? handleDismissNotification : requestInsights}
        felixMessage={
          <>
            Here are your <span>candidate insights</span>. Now let’s go fill this role!
          </>
        }
        felixMessageMaxWidth="400px"
        alternateSecondaryButton={true}
      />
    </>
  );
};

export default ResponseStep;
