import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import { AutocompleteGetTagProps, useAutocomplete } from '@mui/material';

export interface IMultiSelectProps {
  name: string;
  selectOptions: IOptionType[];
  handleFilterChange: (filter: string, newValues: (string | IOptionType)[]) => void;
  filters: IOptionType[];
  freeInput?: boolean;
  disabled?: boolean;
  displayLabel?: boolean;
}

export interface IOptionType {
  name: string;
  value?: string;
  id?: number;
  state?: string;
  label?: string;
}

const InputWrapper = styled('div')(
  () => `
  width: 100%;
  min-height: 41px;
  border: 1px solid #DADEE0;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 6px 22px 6px 6px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3px;
  &:hover {
    border-color: #DADEE0;
  }

  &.focused {
    border-color: #77C2BE;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: transparent;
    color: #000000;
    box-sizing: border-box;
    padding: 4px 6px 2px 6px;
    width: 0;
    height: 26px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    z-index: 1;
  }

  & .select-placeholder {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    margin-left: 8px;
    margin-top: 2px;
    position: absolute;
    z-index: 0;
  }

  & .select-arrow {
    position: absolute;
    right: 4px;
    bottom: 0px;
    height: 100%;
    fontSize: 1.1rem;
    color: #AAAAAA;
    transition: transform 0.25s ease-in-out;
  }
`
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CancelIcon onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  () => `
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #000000;
  height: 23px;
  margin: 2px;
  line-height: 22px;
  background-color: #F3F3F3;
  border: none;
  border-radius: 0px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 9px;
    cursor: pointer;
    margin: 0px 4px 0px 6px;
  }
`
);

const Listbox = styled('ul')(
  () => `
  width: 220px;
  margin: 4px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #FFFFFF;
  overflow: auto;
  overflow-x: hidden;
  max-height: 160px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 2;

  & .Mui-focused {
    background: #F1F1F1 !important;
  }

  & li {
    padding: 5px 12px;
    display: flex;
    font-weight: 600;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    cursor: pointer;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }

  }

  & li[aria-selected='true'] {
    background-color: #FAFAFA;
    font-weight: 600;

    & svg {
      color: #5BC4BF;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;  

    & svg {
      color: currentColor;
    }
  }
`
);

export default function MultiSelect({
  name,
  selectOptions,
  handleFilterChange,
  filters,
  freeInput = false,
  disabled = false,
  displayLabel = false
}: IMultiSelectProps) {
  const [open, setOpen] = useState(false);
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl
  } = useAutocomplete({
    open: open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    id: 'multi-select',
    multiple: true,
    options: selectOptions ? selectOptions : [],
    getOptionLabel: (option: IOptionType) => option.name,
    value: filters,
    onChange: (event, value) => handleFilterChange(name, value),
    freeSolo: freeInput,
    autoSelect: freeInput,
    disabled: disabled && filters.length === 0
  });
  const displayOption = (option: IOptionType) => {
    if (freeInput) {
      return option;
    } else {
      return displayLabel ? option.label : option.name;
    }
  };
  return (
    <>
      <div {...getRootProps()}>
        <InputWrapper
          ref={setAnchorEl}
          className={focused ? 'focused' : ''}
          sx={{ position: 'relative', ...(disabled && { opacity: 0.5 }) }}
        >
          {value.length < 1 && !focused && !disabled && (
            <span className="select-placeholder">
              {freeInput ? 'Input one or more' : 'Please select'}
            </span>
          )}
          {!freeInput && (
            <ArrowDropDownIcon
              className={'select-arrow'}
              sx={
                open
                  ? { transform: 'rotate(-180deg)', '&:hover': { cursor: 'pointer' } }
                  : { '&:hover': { cursor: 'pointer' } }
              }
              onClick={() => setOpen(!open)}
            />
          )}
          {value.map((option: IOptionType, index: number) => (
            <StyledTag label={displayOption(option)} {...getTagProps({ index })} />
          ))}
          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof selectOptions).map((option, index) => (
            <li {...getOptionProps({ option, index })} key={index} data-testid={`option-${index}`}>
              <span>{displayLabel ? option.label : option.name}</span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
    </>
  );
}
