"use strict";
export const dropdownOptions = [
  {
    name: "title",
    label: "Title",
    id: 1,
    previousCellOffSet: true,
    sticky: true,
    leftBound: true
    // this means sticky column will start from here, not including this column
  },
  {
    name: "updated_at",
    label: "Last Saved On",
    id: 2
  },
  {
    name: "created_at",
    label: "Report Run Date",
    id: 3
  },
  {
    name: "report_type",
    label: "Report Type",
    id: 5
  },
  {
    name: "entity_name",
    label: "Entity",
    id: 6
  }
];
export const defaultActiveColumns = {
  title: { enabled: true, styles: { width: "20%" } },
  created_at: { enabled: true, styles: { width: "15%" } },
  updated_at: { enabled: true, styles: { width: "15%" } },
  entity_name: { enabled: true, styles: { width: "20%" } },
  report_type: { enabled: true, styles: { width: "10%" } },
  query_config: { enabled: false, styles: { width: "5%" } }
};
export const filteringStates = {
  report_type: [],
  user: []
};
