import React from 'react';
import { FormControl, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import { classes } from './styles';

type Props = {
  density: string;
  dispatch: React.Dispatch<any>;
};
const DensityToggles = ({ density, dispatch }: Props) => {
  return (
    <>
      <Stack sx={{ ...classes.tableSettingsHeader, paddingTop: 2 }}>Density</Stack>
      <FormControl>
        <FormGroup>
          {['Default', 'Compact', 'Ultra Compact'].map((size, index) => {
            const isSwitched = size === density;
            return (
              <FormControlLabel
                sx={
                  !isSwitched
                    ? { ...classes.switchLabels, ...classes.labelActive }
                    : classes.switchLabels
                }
                key={index}
                control={
                  <Switch
                    sx={isSwitched ? classes.switchActive : classes.switch}
                    checked={isSwitched}
                    onChange={(event) => {
                      dispatch({ type: 'SET_DENSITY', payload: size });
                    }}
                    name={'density'}
                  />
                }
                label={size}
                labelPlacement="start"
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </>
  );
};

export default DensityToggles;
