import React from 'react';
import { Box, Stack } from '@mui/material';
import { styles } from '../styles';
import DocTypeIcon from '../../Components/Utilities/DocTypeIcon';
import moment from 'moment';
import { IApprovalFormAttachment } from '../types';

const BASE_URL = window.location.origin;

export default function DocumentViewer({ document }: { document: IApprovalFormAttachment }) {
  return (
    <Stack flexDirection="row">
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={() => (window.location.href = `${BASE_URL}/admin/assets/${document.id}`)}
      >
        <Box sx={styles.fileIcon}>{DocTypeIcon(document.attached_file_name.split('.').pop())}</Box>
        <Box>
          <Box sx={styles.fileName}>{document.attached_file_name}</Box>
          <Box sx={{ color: '#999999' }}>
            {moment(document.created_at).format('Do MMMM YYYY[,] h:mma')}
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}
