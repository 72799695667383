import React from 'react'
import { Link } from 'react-router-dom'

export default class AdminServicesIndex extends React.Component {
    render() {
        const { services } = this.props;

        return (
            <div className="layer-content">
                <div className="layer-container">

                    <div className="layer-tabs">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <Link to="/admin" className="nav-link">
                                    <i className="material-icons">chevron_left</i>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/services/" className="nav-link active">
                                    All Services
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="row row-cols-2 row-cols-sm-4">
                        {services.map(function (service) {
                            const { id, icon, title, description } = service;
                            return (
                                <div className="col mb-4" key={id}>
                                    <div className="card text-center h-100">
                                        <div className='card-image d-flex justify-content-center align-items-center border-bottom p-3'>
                                            <img 
                                              src={ icon }
                                              title={ title }
                                              alt={ title }
                                              className="service-icon big"
                                            />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">{title}</div>
                                            <div className="card-text">{description}</div>
                                            <Link to={`/services/${id}`} className="btn btn-sm filled-green">View More</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
