import React, { useEffect, useState } from 'react';
import { withScoutTheme } from '../../ThemeContext/ThemeContext';
import { Button, CircularProgress } from '@material-ui/core';
import useStyles from './useStyles';
import {
  ICalendlyEvent,
  ICandidate,
  IJobPosition,
  IStatus,
  ITemplate,
  ICalendlyScoutProps
} from '../types';
import { withStyles } from '@material-ui/core/styles';
import Step1 from './components/Steps/Step1/Step1';
import Step2 from './components/Steps/Step2/Step2';
import Step3 from './components/Steps/Step3/Step3';
import Step4 from './components/Steps/Step4/Step4';
import CompleteModal from './components/CompleteModal';
import Api from '../API';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

interface IProps {
  calendlyAccount: ICalendlyScoutProps;
  job: any;
  apiKey: string;
  candidateList: ICandidate[];
  application: any;
}

function Modal({ calendlyAccount, job, apiKey, candidateList, application }: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [jobPositions, setJobPositions] = useState<IJobPosition[]>([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState<IJobPosition | null>(job);
  const [candidates, setCandidates] = useState<ICandidate[]>([]);
  const [selectedCandidates, setSelectedCandidates] = useState<ICandidate[]>([application]);
  const [statuses, setStatuses] = useState<IStatus[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<IStatus[]>([]);
  const [events, setEvents] = useState<ICalendlyEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<ICalendlyEvent | null>();
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(null);
  const [defaultJob, setDefaultJob] = useState<boolean>(false);
  const [editedSubject, setEditedSubject] = useState<string>('');
  const [editedBody, setEditedBody] = useState<string>('');
  const TOTAL_STEPS = 5;

  const classes = useStyles();

  const ColorButton = withStyles(() => ({
    root: {
      '&#cancel-button': {
        color: '#E37D7A',
        textDecoration: 'underline',
        backgroundColor: '#ffffff',
        '&:hover': {
          color: '#d74742',
          backgroundColor: '#ffffff',
        },
      },
    },
  }))(Button);

  const closeModal = () => {
    if (selectedJobPosition && application) {
      location.href = `/admin/jobs/${selectedJobPosition.id}/applications/${application.id}`;
    } else if (selectedJobPosition) {
      location.href = `/admin/jobs/${selectedJobPosition.id}/applications`;
    } else {
      location.href = `/admin/calendar/events`;
    }
  };

  useEffect(() => {
    if (candidateList) {
      setSelectedCandidates([...candidateList]);
    }
    localStorage.setItem('userKey', apiKey);
    localStorage.setItem('editedSubject', editedSubject);
    localStorage.setItem('editedBody', editedBody);
  }, [isOpen]);

  useEffect(() => {
    if (
      (step === 1 && !selectedCandidates.length) ||
      (step === 2 && !selectedEvent) ||
      (step === 3 && !selectedTemplate) ||
      (step === 4 && !selectedCandidates.length && !selectedEvent && !selectedTemplate)
    )
      setNextDisabled(true);
    else setNextDisabled(false);
  }, [step, selectedCandidates, selectedEvent, selectedTemplate]);

  function handleStep(increment: boolean) {
    setStep(increment ? Math.min(step + 1, TOTAL_STEPS) : Math.max(step - 1, 1));
  }

  async function handleSubmit() {
    setIsSending(true);
    try {
      await Api.sendInvitations(selectedJobPosition.id, {
        subject: editedSubject || selectedTemplate.subject,
        body: editedBody || selectedTemplate.body,
        application_ids: selectedCandidates.map((application) => application.id),
        event_type_url: selectedEvent.scheduling_url
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
      setStep(TOTAL_STEPS);
    }
  }

  return (
    <>
      {step === TOTAL_STEPS ? (
        <CompleteModal closeModal={closeModal} />
      ) : (
        <>
          <div className={classes.root}>
            {step === 1 && (
              <Step1
                apiKey={calendlyAccount.access_token}
                candidateList={candidateList}
                defaultJob={defaultJob}
                jobPositions={jobPositions}
                setJobPositions={setJobPositions}
                selectedJobPosition={selectedJobPosition}
                setSelectedJobPosition={setSelectedJobPosition}
                statuses={statuses}
                setStatuses={setStatuses}
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
                candidates={candidates}
                setCandidates={setCandidates}
                selectedCandidates={selectedCandidates}
                setSelectedCandidates={setSelectedCandidates}
              />
            )}
            {step === 2 && (
              <Step2
                calendlyAccount={calendlyAccount}
                events={events}
                setEvents={setEvents}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
              />
            )}
            {step === 3 && (
              <Step3
                templates={templates}
                setTemplates={setTemplates}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                editedSubject={editedSubject}
                setEditedSubject={setEditedSubject}
                editedBody={editedBody}
                setEditedBody={setEditedBody}
              />
            )}
            {step === 4 && (
              <Step4
                setStep={setStep}
                calendlyAccount={calendlyAccount}
                selectedEvent={selectedEvent}
                selectedCandidates={selectedCandidates}
                selectedTemplate={selectedTemplate}
                selectedJobPosition={selectedJobPosition}
              />
            )}
            <div className={`${classes.buttonContainer} ${step === 3 ? 'move-z' : ''}`}>
              <ColorButton
                id="cancel-button"
                disableElevation
                variant="text"
                onClick={() => {
                  window.location.pathname === '/admin/calendar/events'
                    ? setIsOpen(false)
                    : window.history.back();
                }}
              >
                Cancel
              </ColorButton>
              {step !== 1 && (
                <Button
                  disableElevation
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleStep(false)}
                >
                  <KeyboardArrowLeftIcon />
                </Button>
              )}
              <Button
                id="next-button"
                disabled={nextDisabled}
                disableElevation
                variant="contained"
                color="secondary"
                onClick={() => {
                  step === TOTAL_STEPS - 1 ? handleSubmit() : handleStep(true);
                }}
                endIcon={
                  isSending ? (
                    <CircularProgress size={14} color="inherit" />
                  ) : (
                    <ChevronRightRoundedIcon />
                  )
                }
              >
                {step === TOTAL_STEPS - 1 ? 'Send invites' : 'Next'}
              </Button>
            </div>
          </div>
          <div
            className={classes.progressBar}
            style={{ width: `${step * (100 / (TOTAL_STEPS - 1))}%` }}
          />
        </>
      )}
    </>
  );
}

export default withScoutTheme(Modal);
