import React from 'react';
import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { classes } from './styles';
import { SingleDatePicker } from '../../Components/CustomUIElements/SingleDatePicker';

export default function DatePicker({
  dateFrom,
  dateTo,
  handleDateChange
}: {
  dateFrom: Date;
  dateTo: Date;
  handleDateChange: (type: 'from' | 'to', date: Date | null) => void;
}) {
  return (
    <Box sx={classes.date}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SingleDatePicker
          value={dateFrom}
          inputStyles={classes.datePicker}
          onChange={(date) => {
            handleDateChange('from', date);
          }}
          slotProps={{
            popper: {
              sx: classes.calendar,
              placement: 'bottom-end',
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [28, 15]
                  }
                }
              ]
            },
            textField: { InputProps: { sx: classes.datePicker } }
          }}
        />
        <span>to</span>
        <SingleDatePicker
          value={dateTo}
          inputStyles={classes.datePicker}
          onChange={(date) => {
            handleDateChange('to', date);
          }}
          slotProps={{
            popper: {
              sx: classes.calendar,
              placement: 'bottom-end',
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [28, 15]
                  }
                }
              ]
            },
            textField: { InputProps: { sx: classes.datePicker } }
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}
