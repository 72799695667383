import React from 'react';
import Box from '@mui/material/Box';
import WorkIcon from '@material-ui/icons/Work';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ApplicationsAndViews from '../NewUI/Job/Widgets/ApplicationsAndViews'
import { classes } from './styles';

export default function CareersPortalReport({ jobsCount, applicationsCount, pageViews }: { jobsCount: number; applicationsCount: number, pageViews: number }) {
  return (
    <Box sx={classes.subWidget}>
      <Box sx={classes.widgetBlockContainer}>
        <Box sx={classes.widgetBlock}>
          <Box sx={classes.widgetBlockIcon}>
            <InsertDriveFileIcon />
          </Box>
          <Box sx={classes.widgetBlockText}>
            <Box sx={classes.blockCount}>{applicationsCount}</Box>
            <Box sx={classes.blockTitle}>Applications last 30 days</Box>
          </Box>
        </Box>
        <Box sx={classes.widgetBlock}>
          <Box sx={classes.widgetBlockIcon}>
            <VisibilityIcon />
          </Box>
          <Box sx={classes.widgetBlockText}>
            <Box sx={classes.blockCount}>{pageViews}</Box>
            <Box sx={classes.blockTitle}>Page views last 30 days</Box>
          </Box>
        </Box>
        <Box sx={classes.widgetBlock}>
          <Box sx={classes.widgetBlockIcon}>
            <InsertDriveFileIcon />
          </Box>
          <Box sx={classes.widgetBlockText}>
            <Box sx={classes.blockCount}>{jobsCount}</Box>
            <Box sx={classes.blockTitle}>Current published jobs</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
