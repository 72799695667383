"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export const copyToClipboard = (content) => __async(void 0, null, function* () {
  if (window.isSecureContext && navigator.clipboard) {
    try {
      yield navigator.clipboard.writeText(content);
    } catch (error) {
      console.error("Failed to copy!", error);
    }
  } else {
    console.error("Not using HTTPS/localhost or Clipboard API not available.");
  }
});
export const copyRichHTML = (HTML) => __async(void 0, null, function* () {
  const PLAINBLOB = new Blob([HTML], { type: "text/plain" });
  const HTMLBLOB = new Blob([HTML], { type: "text/html" });
  try {
    yield navigator.clipboard.write([
      new ClipboardItem({ ["text/plain"]: PLAINBLOB, ["text/html"]: HTMLBLOB })
    ]);
    console.log("copied");
  } catch (error) {
    console.error("Failed to copy!", error);
  }
});
