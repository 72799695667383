import React from 'react';
import { Stack, Avatar } from '@mui/material';
import { Assignment as AssignmentIcon } from '@mui/icons-material';

export default function EmptyTab({ tabValue }: { tabValue: number }) {
  return (
    <Stack sx={{ padding: 7.5, alignItems: 'center', rowGap: 2 }}>
      <Avatar
        variant="rounded"
        sx={{ borderRadius: '6px', background: '#EAEAEA', width: '60px', height: '70px' }}
      >
        <AssignmentIcon sx={{ fontSize: '3.5rem' }} />
      </Avatar>
      <Stack sx={{ color: '#DDDDDD', fontWeight: 'bold' }}>
        {`You have no ${tabValue ? 'completed' : 'pending'} tasks`}
      </Stack>
    </Stack>
  );
}
