import React, { Dispatch, useState } from 'react';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import {
  Box,
  Button,
  Collapse,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { styles } from '../styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import TableSkeleton from '../../Components/Multiposter/TableSkeleton';
import { ModalType } from '../config';
import EditFormResponseModal from '../Modals/EditFormResponseModal';
import { DeleteFormResponse } from '../Modals/DeleteFormResponse';
import { NoFormResponsesSVG } from '../OverviewTab/Icons';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import FormResponseDetails from './FormResponseDetails';
import RenderFormResponseActions from './FormResponseActions';
import { IUserPermissions } from '../../Components/sharedTypes';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

function SmartFormsTab({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const { data: formResponses, isLoading: loadingFormResponses } = useQuery({
    queryKey: ['formResponses'],
    queryFn: async () => {
      if (application?.job) {
        const { res } = await Api.getFormResponses(application.job.id, application.id);
        return res.form_responses;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting form responses, ${error}`,
          state: 'error'
        }
      }),
    enabled: !!application
  });

  const NoResponses = () => (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
        <Box sx={styles.emptyStateContainer}>
          <NoFormResponsesSVG />
          <Box sx={styles.emptyStateText}>No smart forms added</Box>
        </Box>
      </TableCell>
    </TableRow>
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: '8%' }}>ID</TableCell>
        <TableCell sx={{ width: '24%' }}>Form Name</TableCell>
        <TableCell sx={{ width: '16%' }}>Updated At</TableCell>
        <TableCell sx={{ width: '8%', textAlign: 'center' }}>Rating</TableCell>
        <TableCell
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: 2
          }}
        >
          {permissions?.Applications?.['Add Form Response'] && (
            <Button
              id="add-form-button"
              sx={sharedClasses.genericButtonSecondary}
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ADD_FORM })}
            >
              Add form
            </Button>
          )}
          {permissions?.Applications?.['Send Forms'] && (
            <Button
              id="send-form-button"
              sx={sharedClasses.genericButtonSecondary}
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.SEND_FORM })}
            >
              Send form
            </Button>
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <Stack>
        <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
          <Table sx={styles.smartFormsTableContainer}>
            <TableHeader />
            {!loadingFormResponses && (
              <TableBody>
                {formResponses && formResponses?.length === 0 ? (
                  <NoResponses />
                ) : (
                  formResponses &&
                  formResponses.map((formResponse, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <RenderFormResponseActions
                          formResponse={formResponse}
                          dispatch={dispatch}
                          expandedRows={expandedRows}
                          setExpandedRows={setExpandedRows}
                        />
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                          <Collapse
                            in={expandedRows.includes(formResponse.id)}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={1}>
                              {application?.job && (
                                <FormResponseDetails
                                  FormResponseId={formResponse.id}
                                  application={application}
                                  dispatch={dispatch}
                                  created_at={formResponse.created_at}
                                  submitted_by={formResponse.submitted_by}
                                  rating={formResponse.rating}
                                  expandedRows={expandedRows}
                                  linkToUser={formResponse.link_to_user}
                                />
                              )}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Stack>

      {loadingFormResponses && <TableSkeleton size={4} borderType={'bottom'} />}

      {ApplicationState.selectedFormResponseAction.edit && (
        <EditFormResponseModal
          dispatch={dispatch}
          ApplicationState={ApplicationState}
          selectedFormId={ApplicationState.selectedFormResponseAction.edit?.form_id}
          EditingFormResponseId={ApplicationState.selectedFormResponseAction.edit.id}
          FormName={ApplicationState.selectedFormResponseAction.edit.form_name}
        />
      )}
      {ApplicationState.selectedFormResponseAction.delete && (
        <DeleteFormResponse
          dispatch={dispatch}
          ApplicationState={ApplicationState}
          formResponseId={ApplicationState.selectedFormResponseAction.delete.id}
        />
      )}
    </>
  );
}

export default SmartFormsTab;
