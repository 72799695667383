import React, { useState } from 'react';
import { Box, Popover } from '@mui/material';
import { classes } from '../../../reports/DataVizSection/styles';
import { ReportState, ReportAction } from '../../../reports/CustomReports/customReportReducer';
import DayPicker, { DateUtils } from 'react-day-picker';
import moment from 'moment';

interface Props {
  dateState: ReportState;
  dispatch: React.Dispatch<ReportAction>;
  payloadName: string;
  anchorEl: null | HTMLElement;
  setAnchorEl: (data: null | HTMLElement) => void;
}

export default function DateRangePicker(myProps: Props) {
  const { dateState, dispatch, payloadName, anchorEl, setAnchorEl } = myProps;

  const [date, setDate] = useState(moment(dateState.startDate).toDate());

  function handlePublishedDayClick(day: Date) {
    setDate(day);
    dispatch({
      type: payloadName,
      payload: {
        dateRange: dateState.dateRange,
        startDate: day
      }
    });
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={classes.filterPopoverContent}>
        <Popover
          id="filter-dropdown-popover"
          className={classes.popover}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Box sx={classes.onlyStartDate}>
            <Box
              sx={{
                ...classes.datePicker,
                ...classes.singleDayDatePicker
              }}
            >
              <DayPicker onDayClick={handlePublishedDayClick} selectedDays={date} />
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}
