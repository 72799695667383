import React, { useState, useRef } from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';
import { ReportState, ReportAction } from '../../../reports/CustomReports/customReportReducer';
import DateRangePicker from './DateRangePicker';
import moment from 'moment';
import { classes } from '../../../reports/DataVizSection/styles';
import DateRangeIcon from '@mui/icons-material/DateRange';

interface Props {
  dateState: ReportState;
  dateRangeOptions: {
    value: string;
    label: string;
  };
  dispatch: React.Dispatch<ReportAction>;
  fieldName: string;
  payloadName: string;
}

export default function DateRangeInput(myProps: Props) {
  const { dateState, dispatch, dateRangeOptions, fieldName, payloadName } = myProps;
  const inputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={classes.dateInputWrapper} ref={inputRef}>
        <DateRangeIcon
          sx={{
            ...classes.calendarIcon,
            cursor: dateState?.dateRange?.value === 'custom' ? 'pointer' : 'auto'
          }}
          onClick={() => {
            dateState?.dateRange?.value === 'custom' && setAnchorEl(inputRef.current);
          }}
        />
        <Autocomplete
          id="dateRange"
          disableClearable={true}
          options={dateRangeOptions}
          getOptionLabel={(option) => {
            if (option.value !== 'custom')
              return `${moment().subtract(option.value, 'days').format('DD MMM YYYY')}`;
            return `${moment(dateState.startDate).format('DD MMM YYYY')}`;
          }}
          value={dateState.dateRange}
          required={true}
          sx={{
            ...classes.formAutocompleteDate,
            width: '180px'
          }}
          ListboxProps={{ style: { ...classes.autoCompleteListStyles } }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Start date and time'}
              InputLabelProps={{ shrink: true }}
              onKeyPress={(e) => e.preventDefault()}
              onKeyDown={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.key === 'Backspace' || event.key === 'Delete') event.preventDefault();
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label}
            </Box>
          )}
          onClose={() => setAnchorEl(inputRef.current)}
          onChange={(event, data) =>
            dispatch({
              type: payloadName,
              payload: {
                [fieldName]: data,
                startDate: moment()
                  .subtract(data?.value === 'custom' ? 0 : data?.value, 'days')
                  .toDate()
              }
            })
          }
        />
      </Box>
      <Box>
        {dateState.dateRange?.value === 'custom' && (
          <DateRangePicker
            dateState={dateState}
            dispatch={dispatch}
            payloadName={payloadName}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        )}
      </Box>
    </Box>
  );
}
