"use strict";
import RecruitApiClient from "../RecruitApiClient";
class RecruitApi {
  constructor() {
    this.getEntities = () => RecruitApiClient.get(`/api/v4/entities`, {});
    this.getRootEntities = () => RecruitApiClient.get(`/api/v4/entities`, { only_root: true });
    this.getEntitiesAndChildren = (id) => RecruitApiClient.get(`/api/v4/entities/${id}`, {});
    this.getUsers = (params) => RecruitApiClient.get(`/api/v4/users`, params);
    this.getAurionReport = (params, url) => RecruitApiClient.post(
      `/api/v4/reports/aurion_reports/${url}`,
      params,
      null,
      url === "download"
    );
    this.getOnboardExpressReport = (preview) => RecruitApiClient.get(
      `/admin/integrations/revolve_exports${preview || ""}`,
      null,
      null,
      !preview
    );
    this.getPublicStatuses = () => RecruitApiClient.get(`/api/v4/reports/public_statuses`, {});
    this.getUserPermissions = () => RecruitApiClient.get(`/api/v4/user_permissions`, {});
    this.getUserEventsReport = (params, url) => RecruitApiClient.post(`/api/v4/reports/user_events/${url}`, params, null, url === "download");
    this.getCognologyForms = () => RecruitApiClient.get(`/api/v4/reports/cognology_approval/dropdown_options`, {});
    this.postCognologyReport = (params) => RecruitApiClient.post(`/api/v4/reports/cognology_approval`, params);
  }
}
export default new RecruitApi();
