import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, useTheme } from '@material-ui/core/styles'
import { createTheme } from '@mui/material/styles';
import { theme as baseThemeObject } from './ThemeObject'

interface ThemeContextProps {
  children: ReactNode;
}

export default function ThemeContext ({ children }: ThemeContextProps) {
  const baseTheme = useTheme();

  const theme = createTheme({
    ...baseThemeObject.palette,
    ...baseThemeObject.props,
    ...baseThemeObject.overrides,
    typography: {
      ...baseThemeObject.typography,
      button: {
        textTransform: 'none',
        fontWeight: baseTheme.typography.fontWeightBold,
      }
    },
    zIndex: {
      modal: baseTheme.zIndex.modal + 10000,
      tooltip: baseTheme.zIndex.tooltip + 10000,
    }
  });

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

ThemeContext.propTypes = {
  children: PropTypes.node
}

interface WithScoutThemeProps {
  [key: string]: any;
}

export function withScoutTheme<T extends WithScoutThemeProps>(Component: React.ComponentType<T>) {
  return function WrapperComponent(props: T) {
    return (
      <ThemeContext>
        <Component {...props} />
      </ThemeContext>
    );
  };
}
