"use strict";
export const classes = {
  buttonContainer: {
    position: "fixed",
    bottom: "80px",
    left: "28px",
    cursor: "pointer",
    "& svg": {
      transition: "transform 0.7s"
    }
  },
  newNavbarButtonContainer: {
    bottom: "32px",
    left: "38px"
  },
  collapsedNavbar: {
    left: "7.5px",
    transform: "scale(0.87)"
  },
  newNavbarCollapsed: {
    left: "20px",
    bottom: "80px"
  },
  button: {
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    color: "#4E4EC7",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "700",
    padding: "8px 0px",
    paddingRight: "22px",
    paddingLeft: "56px",
    borderRadius: "22px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#4E4EC7"
    }
  },
  avatarContainer: {
    width: "44px",
    height: "44px",
    borderRadius: "50%",
    zIndex: 1,
    position: "absolute",
    top: "0px",
    left: "-1px",
    background: "linear-gradient(225.64deg, #E548FF -23.43%, #084D6D 140.57%, #084D6D 140.58%, #5BC4C0 140.6%)",
    boxShadow: "0px 4px 4px rgba(36, 36, 36, 0.18)"
  },
  hoverAnimation: {
    "& svg": {
      transform: "scale(1.11) translate(2px, -5px) !important",
      transition: "transform 0.7s"
    }
  },
  notificationAnimation: {
    "& svg": {
      transform: "scale(2.5) translate(0px, -14.5px) !important",
      transition: "transform 0.7s"
    }
  },
  notificationPopperContainer: {
    transform: "translate(40px, -90px)"
  },
  notificationsBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#E37D7A",
      color: "#FFF",
      fontWeight: "700",
      width: "28px",
      height: "28px",
      borderRadius: "50%",
      fontSize: "16px"
    }
  },
  notificationPopper: {
    position: "relative",
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
    width: "345px",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "16px"
  },
  notificationHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "1.2",
    color: "#4E4EC7",
    width: "87%"
  },
  notificationButtons: {
    width: "87%",
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "8px"
  },
  notificationViewButton: {
    textTransform: "none",
    backgroundColor: "#7A8EF1",
    color: "#FFFFFF",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "700",
    borderRadius: "22px",
    padding: "2px 20px",
    "&:hover": {
      backgroundColor: "#7A8EF1CC",
      color: "#FFFFFF"
    }
  },
  notificationCloseButton: {
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    color: "#4E4EC7",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "400",
    borderRadius: "22px",
    padding: "2px 20px",
    textDecoration: "underline",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#4E4EC7",
      textDecoration: "none"
    }
  },
  borderBox: {
    position: "absolute",
    top: "-8px",
    bottom: "-8px",
    left: "-8px",
    right: "-8px",
    content: "''",
    zIndex: -1,
    borderRadius: "30px",
    "@property --angle": {
      syntax: "'<angle>'",
      initialValue: "0deg",
      inherits: "false"
    },
    "@keyframes rotate": { to: { "--angle": "360deg" } },
    background: "conic-gradient(from var(--angle), #A24AD3 0 30%, #594CA2 40% 70%, #A24AD3 100%)",
    animation: "3.5s rotate linear infinite"
  },
  betaPill: {
    position: "absolute",
    top: "-8px",
    right: "2px",
    zIndex: 1,
    backgroundColor: "#5BC4C0",
    color: "#FFFFFF",
    fontFamily: "Source Sans Pro",
    fontSize: "9.5px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "16px",
    width: "30px",
    borderRadius: "8px"
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "60px",
    maxWidth: "1200px",
    width: "100%",
    height: "94vh",
    maxHeight: "844px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  notificationModalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90%",
    height: "100%",
    rowGap: "36px",
    marginTop: "34px"
  },
  felixContainer: {
    marginTop: "20px"
  },
  previewModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "680px",
    width: "100%",
    height: "94vh",
    maxHeight: "730px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  teaserContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "500px",
    rowGap: "26px"
  },
  teaserHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "34px",
    fontWeight: "700",
    color: "#2D388D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "12px",
    position: "relative",
    paddingTop: "12px"
  },
  teaserPill: {
    height: "18px",
    width: "36px",
    backgroundColor: "#5BC4BF",
    color: "#FFFFFF",
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    position: "absolute",
    top: "-4px",
    right: "-28px"
  },
  featureList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "center",
    rowGap: "12px",
    width: "460px"
  },
  featureListItem: {
    width: "210px",
    display: "flex",
    alignItems: "flex-start",
    columnGap: "16px",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000000"
  },
  featureListItemIcon: {
    fontSize: "22px",
    color: "#798DF0"
  },
  teaserText: {
    textAlign: "center"
  },
  teaserButton: {
    textTransform: "none",
    backgroundColor: "#7A8EF1",
    color: "#FFFFFF",
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "700",
    width: "320px",
    height: "44px",
    borderRadius: "22px",
    "&:hover": {
      backgroundColor: "#798DF0"
    }
  },
  teaserCloseButton: {
    position: "absolute",
    bottom: "20px",
    right: "8px",
    textTransform: "none",
    textDecoration: "underline",
    height: "22px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontWeight: "600",
    color: "#CCCCCC",
    backgroundColor: "#FFFFFF",
    borderRadius: "20px",
    border: "none"
  },
  welcomeContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    columnGap: "112px",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto"
  },
  descriptionContainer: {
    paddingTop: "30px",
    rowGap: "42px",
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto"
  },
  generatedContainer: {
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: "20px",
    overflowY: "auto"
  },
  headersAndOptionsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "474px"
  },
  header: {
    fontFamily: "Source Sans Pro",
    fontSize: "68px",
    fontWeight: "700",
    color: "#2D388D",
    marginBottom: "26px",
    "@keyframes caret": {
      "50%": {
        borderColor: "transparent"
      }
    },
    "& span": {
      lineHeight: "1",
      borderRight: ".05em solid",
      animation: "caret 0.9s ease-in-out infinite"
    }
  },
  subHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "26px",
    fontWeight: "600",
    color: "#2D388D",
    marginLeft: "4px"
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "22px",
    marginTop: "42px"
  },
  option: {
    width: "366px",
    height: "46px",
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    fontFamily: "Source Sans Pro",
    color: "#2D388D",
    border: "2px solid #2D388D",
    fontSize: "17px",
    fontWeight: "700",
    borderRadius: "23px",
    "&:hover": {
      color: "#AFBCFF",
      border: "2px solid #AFBCFF"
    }
  },
  genericContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%"
  },
  fieldHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "38px",
    fontWeight: "700",
    color: "#2D388D",
    marginBottom: "36px"
  },
  fieldSubHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#2D388D",
    marginBottom: "16px"
  },
  optionalInformationContainer: {
    display: "flex",
    flexDirection: "column",
    marginBlock: "20px"
  },
  pillsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "18px",
    flexWrap: "wrap",
    marginTop: "8px"
  },
  pill: {
    textTransform: "none",
    height: "36px",
    width: "auto",
    paddingInline: "17px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "700",
    color: "#AFBCFF",
    border: "1px solid #AFBCFF",
    borderRadius: "18px"
  },
  filledPill: {
    textTransform: "none",
    height: "36px",
    width: "auto",
    paddingInline: "17px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "700",
    color: "#AFBCFF",
    border: "1px solid #2D388D",
    backgroundColor: "#2D388D",
    borderRadius: "18px",
    "&:hover": {
      borderColor: "#AFBCFF"
    }
  },
  fieldInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "regular",
    borderRadius: "6px",
    height: "42px",
    width: "100%",
    "& legend": {
      display: "none"
    },
    "& input": {
      padding: "0px 20px 3.5px 20px",
      color: "#333333"
    },
    "& fieldset": {
      borderColor: "#DDDDDD"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADADA !important"
      }
    }
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "auto",
    paddingTop: "30px"
  },
  felixMessageContainer: {
    display: "flex",
    columnGap: "26px",
    alignItems: "center"
  },
  felixMessage: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#2D388D",
    maxWidth: "310px",
    "& span": {
      color: "#AFBCFF"
    },
    "@media (max-width: 1024px)": {
      fontSize: "14px",
      width: "220px"
    }
  },
  footerButtonsContainer: {
    display: "flex",
    columnGap: "32px"
  },
  footerContinueButton: {
    textTransform: "none",
    height: "40px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "700",
    color: "#FFFFFF",
    backgroundColor: "#7A8EF1",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#2D388D"
    },
    "&:disabled": {
      backgroundColor: "#CCCCCC",
      color: "#DDDDDD"
    }
  },
  footerBackButton: {
    textTransform: "none",
    textDecoration: "underline",
    height: "40px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#CCCCCC",
    backgroundColor: "#FFFFFF",
    borderRadius: "20px",
    border: "none"
  },
  descriptionHeader: {
    marginBottom: "36px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  descriptionHeaderText: {
    fontFamily: "Source Sans Pro",
    fontSize: "38px",
    fontWeight: "700",
    color: "#2D388D"
  },
  generatedResponseContainer: {
    fontFamily: "Source Sans Pro",
    width: "100%",
    height: "500px",
    overflowY: "auto",
    padding: "18px",
    paddingTop: "4px",
    border: "1px solid #DDDDDD",
    borderRadius: "6px",
    "& h2": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      color: "#666666",
      fontSize: "18px"
    },
    "& h3": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      color: "#666666",
      fontSize: "16px"
    },
    "& p": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "normal"
    },
    "& ul": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "14px",
      listStyleType: "circle",
      marginLeft: "30px",
      marginTop: "16px",
      marginBottom: "8px"
    },
    "& ol": {
      fontFamily: "Source Sans Pro",
      color: "#666666",
      fontSize: "16px",
      marginLeft: "30px",
      marginTop: "16px",
      marginBottom: "8px",
      listStyle: "decimal",
      "& li": {
        marginBottom: "12px",
        paddingLeft: "10px"
      }
    }
  },
  footerRestartButton: {
    textTransform: "none",
    height: "40px",
    paddingInline: "18px",
    minWidth: "130px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "700",
    color: "#FFFFFF",
    backgroundColor: "#AFBCFF",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#2D388D"
    }
  },
  questionsContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    rowGap: "30px",
    overflowY: "auto",
    marginBottom: "34px"
  },
  question: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: "26px",
    padding: "20px",
    background: "rgba(122, 142, 241, 0.12)",
    borderRadius: "6px",
    color: "#2D388D",
    fontFamily: "Source Sans Pro",
    fontSize: "18px"
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  checkbox: {
    color: "#939393 !important",
    "& .MuiSvgIcon-root": {
      fontSize: "24px"
    },
    "&.Mui-checked": {
      color: "#939393 !important"
    }
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: "24px",
    width: "100%"
  },
  formLabel: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: 600,
    color: "#2D388D",
    paddingTop: "2px"
  },
  toggleButton: {
    padding: "4px 17px !important",
    fontFamily: "Source Sans Pro",
    color: "#AAAAAA",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "12px",
    backgroundColor: "#F9F9F9",
    textTransform: "none",
    border: "none",
    "&.Mui-selected": {
      backgroundColor: "#7A8EF1 !important",
      color: "#FFFFFF !important"
    },
    "&:focus": {
      outline: "none"
    },
    "&:hover": {
      border: "none"
    }
  },
  sideBySideContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    columnGap: "44px"
  }
};
