import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexBasis: "10%",
    padding: "24px 50px",
    borderBottom: "1px solid #F3F3F3",
    alignItems: "center",
    "& h4": {
      marginTop: 0,
      color: "#004E70",
      fontSize: 24,
      marginRight: 16,
      fontWeight: "600",
      textTransform: "capitalize"
    },
    "& span": {
      color: "#393939",
      fontSize: 16
    }
  },
  remainingApplicants: {
    background: "#F3F6F7",
    padding: "10px 14px",
    display: "flex",
    borderRadius: "65px",
    alignItems: "center",
    marginLeft: 10,
    fontSize: 14,
    fontWeight: "900",
    color: theme.palette.primary.main,
    pointerEvents: "none",
    "& svg": {
      marginRight: 6
    }
  }
}));
