import * as React from 'react';
import { Box, TextField, Popper, Typography, Tooltip, CircularProgress } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { SxProps } from '@mui/material/styles';

export interface VirtualizedGroupedAutocompleteProps {
  value: string | null;
  options: string[];
  onChange: (value: VirtualizedGroupedAutocompleteProps['value']) => void;
  styles?: SxProps;
  label?: string;
  loading?: boolean;
  loadingText?: string;
  error?: string;
  errorStyles?: React.CSSProperties;
  required?: boolean;
  innerRef?: RefObject<HTMLDivElement>;
}

// To use this component, you must pass in an array of strings
// The strings must be in the format "Option name@-*@Group name"
// This component uses the @-*@ as a delimiter to separate the option name from the group name
// Below is a helper function to format the options array
type TemplateDetails = {
  id: number;
  name: string;
};

type GroupedTemplates = Array<[string, TemplateDetails[]]>;

export const formatOptions = (options: GroupedTemplates): string[] => {
  const formattedTemplates: string[] = [];
  options.forEach((group: [string, TemplateDetails[]]) => {
    const groupName = group[0];
    const templates = group[1];
    templates.forEach((template: TemplateDetails) => {
      formattedTemplates.push(`${template.id} - ${template.name}@-*@${groupName}`);
    });
  });
  return formattedTemplates;
};

const LISTBOX_PADDING = 8; // px

function RenderRow(props: ListChildComponentProps) {
  const [displayTooltip, setDisplayTooltip] = React.useState(false);
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  };

  function refCallback(ref: HTMLElement) {
    if (!ref) return;
    if (ref.clientWidth > ref.parentElement?.clientWidth) {
      setDisplayTooltip(true);
    }
  }

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        <Tooltip
          title={displayTooltip ? dataSet.group : ''}
          enterDelay={600}
          placement="bottom-start"
        >
          <div ref={refCallback}>{dataSet.group}</div>
        </Tooltip>
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      <Tooltip title={displayTooltip ? dataSet[1] : ''} enterDelay={600} placement="bottom-start">
        <div ref={refCallback}>{dataSet[1]}</div>
      </Tooltip>
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach(
      (item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      }
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactChild) => {
      if (child.hasOwnProperty('group')) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {RenderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
});

export default function VirtualizeGrouped({
  options,
  value,
  onChange,
  styles,
  error,
  errorStyles,
  loading,
  loadingText,
  label,
  required = false,
  innerRef
}: VirtualizedGroupedAutocompleteProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <Autocomplete
        id="virtualize-grouped"
        sx={styles}
        value={value}
        onChange={(event, value) => onChange(value)}
        loading={loading}
        loadingText={loadingText}
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={options}
        groupBy={(option) => option.split('@-*@')[1]}
        renderInput={(params) => {
          if (value) {
            params.inputProps.value = value.split('@-*@')[0];
          }
          return (
            <TextField
              {...params}
              label={label}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      params.InputProps.endAdornment
                    )}
                  </React.Fragment>
                )
              }}
              required={required}
              ref={innerRef}
            />
          );
        }}
        renderOption={(props, option) => [props, option.split('@-*@')[0]] as React.ReactNode}
        renderGroup={(params) => params as unknown as React.ReactNode}
      />
      {error && <Box sx={errorStyles}>{error}</Box>}
    </Box>
  );
}
