import React, { Dispatch } from 'react';
import { Box, CircularProgress, Modal, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import Api from '../../API';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { SmartFormFieldsState, SmartFormFieldsAction } from '../../reducer';

export default function AddSystemField({
  formId,
  SmartFormFieldsState,
  dispatch
}: {
  formId: number;
  SmartFormFieldsState: SmartFormFieldsState;
  dispatch: Dispatch<SmartFormFieldsAction>;
}) {
  const queryClient = useQueryClient();

  const handleClose = () => {
    dispatch({ type: 'SET_SELECTED_SYSTEM_FIELD', payload: null });
    dispatch({ type: 'SET_SYSTEM_FIELD_ERROR', payload: '' });
    dispatch({
      type: 'SET_MODALS_OPEN',
      payload: { ...SmartFormFieldsState.modalsOpen, addSystemField: false }
    });
  };

  const { data: systemFields, isLoading: loadingSystemFields } = useQuery({
    queryKey: ['system fields'],
    queryFn: async () => {
      const { res } = await Api.getSystemFields();
      return res.system_fields.map(
        (field: { id: number; title: string }) => `${field.id} - ${field.title}`
      );
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Failed to fetch system fields, ${error}`,
          state: 'error'
        }
      })
  });

  const handleSubmit = async () => {
    if (!SmartFormFieldsState.selectedSystemField) {
      dispatch({ type: 'SET_SYSTEM_FIELD_ERROR', payload: 'This field is required' });
      return;
    }
    AddSystemField.mutate(SmartFormFieldsState.selectedSystemField);
  };

  const AddSystemField = useMutation({
    mutationFn: (selectedField: string) =>
      Api.addSystemField(formId, Number(selectedField.split('-')[0])),
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There has been an error creating the system field question, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'Field has been added',
          state: 'success'
        }
      });
      queryClient.invalidateQueries(['questions'], { exact: true });
      handleClose();
    }
  });

  return (
    <Modal
      open={SmartFormFieldsState.modalsOpen.addSystemField}
      onClose={handleClose}
      aria-labelledby="add-system-field-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
        <Box id="add-system-title" sx={classes.actionModalHeader}>
          Add system field
        </Box>
        <Box
          sx={classes.modalContent}
          id="add-system-modal-content"
          aria-label="Add System Field Modal Content"
        >
          <Box role="form" id="add-system-form" aria-label="Add System Field form">
            <Box sx={{ ...classes.actionModalFormLine, marginTop: '20px' }}>
              <Virtualize
                value={SmartFormFieldsState.selectedSystemField}
                passOptions={systemFields}
                setValue={(value: string) => {
                  dispatch({ type: 'SET_SELECTED_SYSTEM_FIELD', payload: value });
                  dispatch({ type: 'SET_SYSTEM_FIELD_ERROR', payload: '' });
                }}
                passedStyles={{
                  ...sharedClasses.formAutocomplete,
                  width: '700px',
                  marginTop: '20px'
                }}
                label="Select a system field"
                loading={loadingSystemFields}
                loadingText="Loading system fields..."
              />

              {SmartFormFieldsState.systemFieldError && (
                <Box sx={{ ...classes.formError, top: '63px' }}>
                  {SmartFormFieldsState.systemFieldError}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={classes.modalFooterSmallerSection}>
          <Button id="close-add-system-button" sx={classes.modalCancelButton} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            id="submit-add-system-button"
            type="submit"
            sx={{ ...classes.modalCreateButton, width: '91px', height: '41px' }}
            onClick={handleSubmit}
          >
            {AddSystemField.isLoading ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
