import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { classes } from '../styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Box from '@mui/material/Box';
import { theme } from '../../../../ThemeContext/ThemeObject';

export default function SortableChoice({
  id,
  choice,
  index,
  handleChoiceInput,
  onRemoveChoice
}: {
  id: string;
  choice: any;
  index: number;
  handleChoiceInput: any;
  onRemoveChoice: any;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Box
      key={index}
      sx={classes.choice}
      style={style}
      ref={setNodeRef}
      {...attributes}
    >
      <IconButton ref={setActivatorNodeRef} {...listeners}>
        <DragIndicatorIcon sx={{ fontSize: 18 }}/>
      </IconButton>
      <FormTextField
        value={choice.name}
        onChange={(e) => handleChoiceInput(e, index)}
        required={true}
        styles={{ width: '150px'}}
      />
      <IconButton
        onClick={() => onRemoveChoice(index)}
        sx={{ marginLeft: '35px' }}
      >
        <DeleteIcon sx={{ color: theme.palette.error.main, fontSize: 18 }} />
      </IconButton>
    </Box>
  );
}