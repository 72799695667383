"use strict";
export const defaultFilters = {
  created_by: []
};
export const dropdownSetting = {
  name: true,
  updated_at: true,
  created_by: true,
  note: false
};
export const dropdownOptions = [
  {
    name: "updated_at",
    label: "Updated At"
  },
  {
    name: "created_by",
    label: "Created By"
  },
  {
    name: "note",
    label: "Note"
  }
];
export const approvalTemplateHeaders = [
  {
    name: "name",
    label: "Title",
    sortable: true
  },
  {
    name: "updated_at",
    label: "Updated At",
    sortable: true
  },
  {
    name: "created_by",
    label: "Created By",
    sortable: true
  },
  {
    name: "note",
    label: "Note",
    sortable: false
  }
];
