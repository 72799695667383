import React, { useState } from 'react';
import { Popover, Stack } from '@mui/material';
import { classes } from './styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const formatDate = (date: Date, timezone: string) => {
  return dayjs.tz(date, timezone).format('dddd, MMMM D, YYYY');
};

export default function DateDisplay({
  dates,
  userTimezone
}: {
  dates: Date[];
  userTimezone: string;
}) {
  const [datesAnchorEl, setDatesAnchorEl] = useState<HTMLDivElement | null>(null);
  return (
    <Stack flexDirection="row">
      {formatDate(dates[0], userTimezone)}
      {dates.length > 1 && (
        <Stack
          sx={classes.multipleDatesIndicator}
          onClick={(e) => setDatesAnchorEl(e.currentTarget)}
        >{`+${dates.length - 1}`}</Stack>
      )}
      <Popover
        open={Boolean(datesAnchorEl)}
        anchorEl={datesAnchorEl}
        onClose={() => setDatesAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ '& .MuiPaper-root': { marginTop: 1 } }}
      >
        <Stack sx={{ padding: '8px 16px', rowGap: '4px' }}>
          {dates.slice(1).map((d, i) => (
            <Stack key={i} sx={{ fontSize: '14px' }}>
              {formatDate(d, userTimezone)}
            </Stack>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
}
