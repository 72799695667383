import { Box, Button, Modal } from '@mui/material';
import React, { Dispatch } from 'react';
import { classes } from '../../../styles';
import { EngageAction, EngageState } from '../../../reducer';
import SelectTLPStep from './SelectTLPStep';
import EditBooleanStep from './EditBooleanStep';
import { handleAPIRequest } from '../../../../NewUI/Components/Utilities/handleAPIRequest';
import { ISnackbarInput } from '../../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import LoadingResponse from '../../../../AIStudio/SharedComponents/Steps/LoadingResponse';
import StyledModal from '../../../../NewUI/Components/GenericModal/StyledModal';

const TLPMatchModal = ({
  engageState,
  dispatch
}: {
  engageState: EngageState;
  dispatch: Dispatch<EngageAction>;
}) => {
  const handleOpenModal = () => {
    dispatch({ type: 'OPEN_TLP_MATCH_MODAL' });
    dispatch({ type: 'SET_JOBS_LIST_LOADING' });
    handleAPIRequest({
      url: `/api/v4/jobs/jobs_for_tlp_match`,
      method: 'GET',
      setSnackbarState: (snackbarState: ISnackbarInput) =>
        dispatch({ type: 'SET_SNACKBAR', payload: snackbarState }),
      successMessage: { show: false },
      onSuccess: (response: { jobs: Array<{ id: number; reference: string; title: string }> }) => {
        const jobsList = response.jobs;
        dispatch({ type: 'SET_JOBS_LIST', payload: jobsList });
      }
    });
  };

  const handleCloseModal = () => {
    dispatch({ type: 'CLOSE_TLP_MATCH_MODAL' });
  };

  const handleGetBooleanQuery = () => {
    dispatch({ type: 'SET_TLP_MATCH_MODAL_STEP', payload: 'loading' });
    handleAPIRequest({
      url: `/api/v4/searches/generate_boolean_query`,
      method: 'POST',
      body: {
        query: engageState.tlpMatchModalState.selectedTLP.name,
        tlp: engageState.tlpMatchModalState.selectedTLP.html
      },
      setSnackbarState: (snackbarState: ISnackbarInput) =>
        dispatch({ type: 'SET_SNACKBAR', payload: snackbarState }),
      successMessage: { show: false },
      onSuccess: (response: { boolean_query: string }) => {
        const booleanQuery = response?.boolean_query;
        dispatch({ type: 'SET_EDITABLE_BOOLEAN_QUERY', payload: booleanQuery });
        dispatch({ type: 'SET_TLP_MATCH_MODAL_STEP', payload: 'edit' });
      }
    });
  };

  const renderStep = () => {
    switch (engageState.tlpMatchModalState.step) {
      case 'select':
        return (
          <SelectTLPStep
            modalState={engageState.tlpMatchModalState}
            dispatch={dispatch}
            handleGetBooleanQuery={handleGetBooleanQuery}
          />
        );
      case 'loading':
        return (
          <LoadingResponse
            generationType="custom"
            customMessages={[
              'Sit tight, boolean brilliance is brewing',
              'Summing up superb search strings',
              'Hunting for the best buzzwords... Just a little longer!'
            ]}
          />
        );
      case 'edit':
        return (
          <EditBooleanStep
            booleanQuery={engageState.tlpMatchModalState.editableBooleanQuery}
            isEditing={engageState.tlpMatchModalState.isEditing}
            dispatch={dispatch}
          />
        );
      default:
        return <Box>Something went wrong</Box>;
    }
  };

  return (
    <>
      <Button sx={classes.tlpMatchButton} onClick={handleOpenModal} id="match-tlp-button">
        Match TLP
      </Button>
      <StyledModal
        isOpen={engageState.tlpMatchModalState.open}
        handleClose={handleCloseModal}
        label="talent-landing-page-match"
      >
        {renderStep()}
      </StyledModal>
    </>
  );
};

export default TLPMatchModal;
