"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const styles = {
  actionsModal: {
    display: "flex",
    width: "818px",
    borderRadius: "6px",
    fontFamily: "Source Sans Pro",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  modalTabsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 0",
    minWidth: "255px"
  },
  modalFormContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px"
  },
  closeIconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  modalActions: {
    display: "flex",
    width: "100%",
    padding: "8px 16px",
    justifyContent: "flex-end",
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  closeIcon: {
    cursor: "pointer",
    color: "#DDDDDD"
  },
  modalHeader: {
    fontSize: "25px",
    fontWeight: "bold",
    padding: "20px 40px",
    color: theme.palette.primary.main
  },
  tabTitle: {
    fontSize: "18px",
    padding: "15px 40px",
    flexDirection: "row"
  },
  hoverTabTitle: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  tabContent: {
    flexGrow: 1,
    width: "100%",
    paddingBottom: 2,
    height: "400px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  tabContentTitle: {
    fontWeight: "bold",
    fontSize: "22px",
    color: "#666666",
    padding: 2,
    paddingTop: 1
  },
  tabContentBody: {
    fontSize: "14px",
    color: "#939393",
    padding: "8px 16px",
    borderRadius: "4px"
  },
  tabContentError: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px"
  },
  emailItemList: {
    display: "flex",
    marginTop: 3,
    justifyContent: "space-between",
    alignItems: "center"
  },
  modalSaveButton: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFF",
    background: "rgb(91,196,191)",
    borderRadius: "6px",
    padding: "8px 14px",
    boxShadow: "none",
    ":focus": {
      outline: "none"
    },
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  selectButton: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    padding: "8px 14px",
    boxShadow: "none",
    ":focus": {
      outline: "none"
    },
    "&:hover": {
      backgroundColor: "#5BC4BF"
    }
  },
  iconGrey: {
    color: "#666666",
    fontSize: "28px"
  },
  questionListItem: {
    display: "flex",
    fontSize: "14px",
    color: "#939393",
    padding: "8px 16px",
    borderRadius: "4px",
    justifyContent: "space-between",
    "& button": {
      display: "none"
    },
    "&:hover": {
      backgroundColor: "rgba(8,77,109,0.07)",
      color: "#000000",
      "& button": {
        display: "inline-block"
      }
    }
  },
  questionListItemSelected: {
    display: "flex",
    fontSize: "14px",
    color: "#939393",
    padding: "8px 16px",
    borderRadius: "4px",
    justifyContent: "space-between",
    backgroundColor: "rgba(8,77,109,0.07)"
  }
};
