import { theme } from "../ThemeContext/ThemeObject";
export const classes = {
  category: {
    marginBottom: theme.spacing(2),
    marginTop: 0
  },
  root: {
    position: "relative"
  },
  manageMetrics: {
    position: "absolute",
    top: 0,
    right: 0
  },
  button: {
    fontFamily: "source sans pro",
    fontSize: "0.85rem",
    fontWeight: 700,
    backgroundColor: theme.palette.primary.main + " !important",
    color: "#fff !important",
    textTransform: "none",
    borderRadius: "4px !important",
    padding: "10px 20px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark + " !important"
    }
  }
};
