import React, { Dispatch } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { ScoutCalendarState, ScoutCalendarAction } from '../../types';
import { classes } from './styles';
import GroupedAvatars from './GroupedAvatars';
import DateDisplay from './DateDisplay';
import moment from 'moment-timezone';
export default function ReviewStep({
  ScoutCalendarState,
  dispatch,
  userTimezone
}: {
  ScoutCalendarState: ScoutCalendarState;
  dispatch: Dispatch<ScoutCalendarAction>;
  userTimezone: string;
}) {
  const { createEventObject, createMultipleEvents, permissions } = ScoutCalendarState;
  const { recruiters, dates, owner } = createEventObject;

  return (
    <Box flexGrow={1}>
      {!owner && <Box sx={classes.progressHeader}>Final step! is this correct?</Box>}
      <Stack
        sx={{
          height: '415px',
          rowGap: '20px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Stack>
          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', color: '#939393' }}>
            Event type
            {permissions?.['Events']?.['Create / Edit Events'] && (
              <EditIcon
                id="edit-step-one-button"
                sx={classes.editIcon}
                onClick={() => {
                  if (createEventObject.eventId) {
                    dispatch({ type: 'SET_SHOW_VIEW_EVENT_MODAL', payload: false });
                    dispatch({ type: 'SET_SHOW_CREATE_EVENT_MODAL', payload: true });
                  }
                  dispatch({
                    type: 'SET_CREATE_EVENT_OBJECT',
                    payload: { ...createEventObject, step: 1 }
                  });
                }}
              />
            )}
          </Stack>
          <Stack>{createEventObject.eventType?.name}</Stack>
        </Stack>
        <Stack sx={{ flexDirection: 'row' }}>
          <Stack sx={{ width: '100%' }}>
            <Stack sx={{ color: '#939393' }}>Date</Stack>
            <DateDisplay dates={dates} userTimezone={userTimezone} />
          </Stack>
          <Stack sx={{ width: '100%' }}>
            <Stack sx={{ color: '#939393' }}>Time</Stack>
            <Stack>{moment.tz(createEventObject.eventDate, userTimezone).format('h:mm a')}</Stack>
          </Stack>
          <Stack sx={{ width: '100%' }}>
            <Stack sx={{ color: '#939393' }}>Duration</Stack>
            <Stack>{createEventObject.duration} minutes</Stack>
          </Stack>
        </Stack>
        {!owner && (
          <Stack sx={{ flexDirection: 'row' }}>
            <Stack sx={{ width: '100%' }}>
              <Stack sx={{ color: '#939393' }}>{`Max. candidates (per session)`}</Stack>
              <Stack>{createEventObject.maxCandidates}</Stack>
            </Stack>
            {createMultipleEvents && (
              <>
                <Stack sx={{ width: '100%' }}>
                  <Stack sx={{ color: '#939393' }}>{`Sessions (in the day)`}</Stack>
                  <Stack>{createEventObject.sessions}</Stack>
                </Stack>
                <Stack sx={{ width: '100%' }}>
                  <Stack sx={{ color: '#939393' }}>{`Break (in between sessions)`}</Stack>
                  <Stack>{createEventObject.breaks} minutes</Stack>
                </Stack>
              </>
            )}
          </Stack>
        )}
        <Stack sx={{ flexDirection: 'row' }}>
          {owner && (
            <Stack sx={{ width: '33%' }}>
              <Stack sx={{ color: '#939393' }}>Created by</Stack>
              <GroupedAvatars users={[{ id: 0, name: owner }]} />
            </Stack>
          )}
          <Stack>
            <Stack sx={{ color: '#939393' }}>Recruiters</Stack>
            <GroupedAvatars
              users={recruiters.map((r) => ({ id: r.id, name: r.name, state: r.state }))}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack>
          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', color: '#939393' }}>
            Event name
            {permissions?.['Events']?.['Create / Edit Events'] && (
              <EditIcon
                id="edit-step-two-button"
                sx={classes.editIcon}
                onClick={() => {
                  if (createEventObject.eventId) {
                    dispatch({ type: 'SET_SHOW_VIEW_EVENT_MODAL', payload: false });
                    dispatch({ type: 'SET_SHOW_CREATE_EVENT_MODAL', payload: true });
                  }
                  dispatch({
                    type: 'SET_CREATE_EVENT_OBJECT',
                    payload: { ...createEventObject, step: 2 }
                  });
                }}
              />
            )}
          </Stack>
          <Stack sx={{ wordWrap: 'break-word' }}>{createEventObject.eventName}</Stack>
        </Stack>
        <Stack sx={{ overflowWrap: 'anywhere' }}>
          <Stack sx={{ color: '#939393' }}>Description</Stack>
          <Stack>{createEventObject.eventDescription}</Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
