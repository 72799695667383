import React, { Dispatch, useState, useEffect, SetStateAction, useCallback } from 'react';
import moment from 'moment';
import { Box, Drawer, Autocomplete, TextField } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { DebouncedFormTextField } from '../NewUI/Components/CustomUIElements/FormTextField';
import { classes } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { IEvent } from './types';
import { sharedClasses } from '../NewUI/Components/CustomUIElements/sharedClasses';

interface Props {
  events: IEvent[];
  showList: boolean;
  setShowList: Dispatch<SetStateAction<boolean>>;
  handleSelectEvent: (event: IEvent) => void;
}

function Settings({ events, showList, setShowList, handleSelectEvent }: Props) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filter, setFilter] = useState<string | null>(null);
  const [filteredEvents, setFilteredEvents] = useState<IEvent[]>([]);

  const filterEvents = useCallback(
    (events: IEvent[]) => {
      if (filter)
        events = events?.filter((slot) =>
          filter === 'Upcoming events'
            ? moment(slot.begins_at).isAfter(new Date())
            : moment(slot.begins_at).isBefore(new Date())
        );
      events = events?.filter((event) =>
        event.subject.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return events;
    },
    [searchTerm, filter]
  );

  useEffect(() => {
    setFilteredEvents(filterEvents(events));
  }, [events, filterEvents]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        anchor="left"
        variant="persistent"
        open={showList}
        onClose={() => {
          setShowList(false);
          setSearchTerm('');
        }}
        PaperProps={{ style: { position: 'absolute', minWidth: 500, maxWidth: 500, zIndex: 11 } }}
        ModalProps={{
          style: { position: 'absolute' }
        }}
      >
        <Box sx={{ padding: '30px 30px 0 30px' }}>
          <Box sx={{ display: 'grid' }}>
            <CloseIcon onClick={() => setShowList(false)} sx={classes.closeIcon} />
            <DebouncedFormTextField
              onDebouncedChange={(e: string) => setSearchTerm(e)}
              id="searchEvents"
              label="Search events"
              defaultValue={searchTerm}
              required={false}
            />
          </Box>
          <Box sx={{ paddingTop: 5, marginRight: 0.5 }}>
            <Autocomplete
              disablePortal
              id="select-event-filters"
              value={filter}
              options={['Upcoming events', 'Past events']}
              getOptionLabel={(option) => option}
              onChange={(_event, newValue) => setFilter(newValue)}
              sx={sharedClasses.formAutocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Event Filters"
                  InputLabelProps={{ shrink: true }}
                  placeholder="No filter"
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  id={`${option.toLowerCase().replace(' ', '-')}-filter-option`}
                  {...props}
                >
                  {option}
                </Box>
              )}
            />
          </Box>
        </Box>
        {filteredEvents?.map((event) => (
          <Box
            key={event.id}
            id="select-event-from-filter"
            sx={classes.itemContainer}
            onClick={() => {
              setShowList(false);
              handleSelectEvent(event);
            }}
          >
            <h3>{event.subject}</h3>
            <Box sx={classes.itemDetails}>
              <CalendarTodayIcon fontSize="small" color="inherit" />
              <span>
                {moment(event.begins_at).format('llll')} to {moment(event.ends_at).format('LT')}
              </span>
            </Box>
            <Box sx={classes.itemDetails}>
              <QueryBuilderIcon fontSize="small" color="inherit" />
              <span>{event.event_type.duration} minutes</span>
            </Box>
          </Box>
        ))}
      </Drawer>
    </Box>
  );
}

export default Settings;
