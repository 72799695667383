import React from 'react';
import { Box, Grid } from '@mui/material';
import { TEmail, TDetailedEmail } from '../../types';
import { classes } from '../../styles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import FormattedDate from '../../../NewUI/Components/GenericTable/TableCells/FormattedDate';
import StatusStatistics from './StatusStatistics';
import OpenEmailStatistics from './OpenEmailStatistics';

const Analytics = ({
  selectedEmail,
  emailAnalytics,
  isLoading
}: {
  emailAnalytics: TDetailedEmail;
  selectedEmail: TEmail;
  isLoading: boolean;
}) => {
  return (
    <Box>
      <Grid sx={{ display: 'flex', marginLeft: '0', width: '100%' }} container spacing={2}>
        <Grid xs={6} sx={{ paddingRight: '25px' }}>
          <Box sx={{ ...classes.rowWidget, height: '6.5rem' }}>
            <Box sx={classes.widgetBlockIcon}>
              <InsertDriveFileIcon />
            </Box>
            <Box sx={{ marginLeft: '1.75rem' }}>
              <Box sx={classes.blockCount}>{selectedEmail.item?.applications}</Box>
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#333333'
                }}
              >
                Applications
              </Box>
            </Box>
          </Box>
          <Box sx={{ ...classes.rowWidget, marginTop: '50px', height: '6.5rem' }}>
            <Box sx={classes.widgetBlockIcon}>
              <InsertInvitationIcon />
            </Box>
            <Box sx={{ marginLeft: '15%', width: '100%' }}>
              <Grid sx={{ display: 'inline-flex' }} container spacing={2}>
                <Grid xs={6}>
                  <Box sx={classes.widgetTextSubTitle}>Created At</Box>
                  <Box>
                    {selectedEmail.item?.created_at ? (
                      <FormattedDate date={selectedEmail.item.created_at} />
                    ) : (
                      '-'
                    )}
                  </Box>
                </Grid>
                <Grid xs={6}>
                  <Box sx={classes.widgetTextSubTitle}>Scheduled At</Box>
                  <Box>{selectedEmail.item.delayed_to}</Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid xs={6} sx={{ paddingLeft: '25px' }}>
          <Box sx={{ ...classes.rowWidget, height: '100%' }}>
            <Box sx={{ width: '100%' }}>
              <Grid sx={{ display: 'inline-flex', marginBottom: '3rem' }} container spacing={2}>
                <Grid xs={6} sx={classes.widgetContentGridWrapper}>
                  <Box sx={classes.widgetTextSubTitle}>User</Box>
                  <Box>{selectedEmail.item.user?.name ? selectedEmail.item.user?.name : '-'}</Box>
                </Grid>
                <Grid xs={6} sx={classes.widgetContentGridWrapper}>
                  <Box sx={classes.widgetTextSubTitle}>Sent from</Box>
                  <Box sx={{ overflowWrap: 'break-word', paddingRight: '15px' }}>
                    {emailAnalytics?.from ? emailAnalytics?.from : '-'}
                  </Box>
                </Grid>
              </Grid>
              <Grid sx={{ display: 'inline-flex' }} container spacing={2}>
                <Grid xs={6} sx={classes.widgetContentGridWrapper}>
                  <Box sx={classes.widgetTextSubTitle}>Recently Added Candidates</Box>
                  <Box>{selectedEmail.item.only_new ? 'Yes' : 'No'}</Box>
                </Grid>
                <Grid xs={6} sx={classes.widgetContentGridWrapper}>
                  <Box sx={classes.widgetTextSubTitle}>Include Trashed Applications</Box>
                  <Box>{selectedEmail.item.include_deleted ? 'Yes' : 'No'}</Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12}>
          <StatusStatistics isLoading={isLoading} emailAnalytics={emailAnalytics} />
        </Grid>
        <Grid xs={12}>
          <OpenEmailStatistics isLoading={isLoading} emailAnalytics={emailAnalytics} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Analytics;
