import React, { useState } from 'react';
import StyledModal from '../../../NewUI/Components/GenericModal/StyledModal';
import ModalFooterButtons from '../../../NewUI/Components/GenericModal/ModalFooterButtons';
import { Box } from '@mui/material';
import { FormTextField } from '../../../NewUI/Components/CustomUIElements/FormTextField';
import { ISnackbarInput } from '../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import callAPI from '../../../NewUI/Components/Utilities/callAPI';

const CreateNewPoolModal = ({
  isOpen,
  handleClose,
  setSnackbarState
}: {
  isOpen: boolean;
  handleClose: () => void;
  setSnackbarState: (snackbarState: ISnackbarInput) => void;
}) => {
  const [poolName, setPoolName] = useState<string>('');

  const queryClient = useQueryClient();

  const { mutate: handleCreatePool, isLoading: savingPool } = useMutation({
    mutationFn: () =>
      callAPI(
        '/api/v4/candidate_pools',
        {
          title: poolName,
          params: {}
        },
        'POST'
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['candidatePools'] });
      handleClose();
      setPoolName('');
    },
    onError: () =>
      setSnackbarState({
        message: `There was an error creating the candidate pool`,
        state: 'error'
      })
  });

  return (
    <StyledModal
      isOpen={isOpen}
      handleClose={handleClose}
      label="Create new candidate pool"
      modalTitle="Create new candidate pool"
      styleOverrides={{
        maxWidth: '700px',
        maxHeight: '350px'
      }}
      subHeaderStyling={false}
    >
      <Box
        sx={{
          ...sharedClasses.mainModalContent,
          height: '100%',
          paddingInline: '2px',
          marginBottom: '0px'
        }}
      >
        <FormTextField
          label="Candidate pool name"
          value={poolName}
          onChange={(e) => e.target.value?.length <= 255 && setPoolName(e.target.value)}
          fullWidth
          error={poolName.length >= 255 ? 'Name length must be a maximum of 255 characters' : ''}
        />
      </Box>
      <ModalFooterButtons
        primaryButtonText="Create"
        secondaryButtonText="Cancel"
        primaryButtonCallback={handleCreatePool}
        secondaryButtonCallback={handleClose}
        primaryButtonID="create-new-pool-action-button"
        secondaryButtonID="cancel-create-pool-button"
        primaryButtonDisabled={!poolName || poolName.length >= 255}
        isLoading={savingPool}
      />
    </StyledModal>
  );
};

export default CreateNewPoolModal;
