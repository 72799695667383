import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import ScoutTablePagination from './pagination';

import T from 'prop-types';

export default class ScoutIndexTable extends Component {
  static propTypes = {
    pagination: T.object,
    paginationCallback: T.func,
    rows: T.array,
    headers: T.array,
    loading: T.bool
  };

  constructor(props) {
    super(props);
  }

  renderPagination() {
    const { pagination, paginationCallback } = this.props;
    return <ScoutTablePagination pagination={pagination} onClick={paginationCallback.bind(this)} />;
  }

  renderLoadingRow() {
    const { loading, headers } = this.props;

    let output = [
      <td key="0">
        <div className="loading">
          <ReactLoading key="spinner" type="cylon" color="blue" height="25px" width="25px" />
        </div>
      </td>
    ];

    for (var i = 1; i < headers.length; i++) {
      output.push(<td key={i}></td>);
    }
    return <tr style={{ display: loading ? '' : 'none' }}>{output}</tr>;
  }

  renderNothingFound() {
    return <p>No items Found</p>;
  }

  renderTableBody() {
    const { pagination, rows, headers, loading, idName } = this.props;

    return (
      <div>
        {pagination && this.renderPagination()}
        <table id={idName}>
          <thead>
            <tr>{headers}</tr>
          </thead>
          <tbody>
            {this.renderLoadingRow()}
            {!loading && rows}
          </tbody>
        </table>
        {pagination && this.renderPagination()}
      </div>
    );
  }

  render() {
    const { rows, loading } = this.props;
    return (
      <div>{rows.length == 0 && !loading ? this.renderNothingFound() : this.renderTableBody()}</div>
    );
  }
}
