import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

interface IProps {
  size?: number;
  borderType?: 'top' | 'bottom' | 'none';
  padding?: string;
  height?: string;
}

export default function TableSkeleton({
  size = 3,
  borderType = 'top',
  padding = '20px 0px',
  height = '40px'
}: IProps) {
  const skeletonComponent = useMemo(() => {
    const boxStyle = {
      width: '100%',
      padding: padding,
      border: borderType === 'none' ? 'none' : 'inherit',
      borderTop: borderType === 'top' ? '1px solid #eaeaea' : 'none',
      borderBottom: borderType === 'bottom' ? '1px solid #eaeaea' : 'none'
    };
    return [...new Array(size)].map((_, i) => (
      <Box sx={boxStyle} key={i}>
        <Skeleton animation="wave" width="100%" height={height} />
      </Box>
    ));
  }, [borderType, size]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      }}
    >
      {skeletonComponent}
    </Box>
  );
}
