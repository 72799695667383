import React, { Dispatch } from 'react';
import { TCandidate } from '../../../types';
import { Box } from '@mui/material';
import { ISnackbarInput } from '../../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CandidatePoolAPIReturn } from '../../../types';
import { callPaginatedAPI } from '../../../../NewUI/Components/Utilities/callAPI';
import callAPI from '../../../../NewUI/Components/Utilities/callAPI';
import queryString from 'query-string';
import {
  GenericTableAction,
  GenericTableState
} from '../../../../NewUI/Components/GenericTable/genericTableReducer';
import { headerStyles } from './candidatesConfig';
import CandidatePoolsTableRows from './CandidatePoolsTableRows';
import { IStyle } from '../../../../ThemeContext/ThemeObject';
import GenericTable from '../../../../NewUI/Components/GenericTable/GenericTable';
import DeleteIcon from '@mui/icons-material/Delete';
import TotalSelectedIndicatorWithActions from '../../../../NewUI/Components/GenericTable/TotalSelectedIndicatorWithActions';
import SearchInput from '../../../../NewUI/Components/GenericTable/SearchInput';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CandidateTabTableActionComponents from './CandidateTabTableActionComponents';

const IndividualCandidatePoolModal = ({
  isOpen,
  setSnackbarState,
  selectedPool,
  candidatePoolsState,
  candidatePoolsDispatch
}: {
  isOpen: boolean;
  setSnackbarState: (snackbarState: ISnackbarInput) => void;
  selectedPool: {
    candidates: TCandidate;
    id: number;
    title: string;
  };
  candidatePoolsState: GenericTableState;
  candidatePoolsDispatch: Dispatch<GenericTableAction>;
}) => {
  const queryClient = useQueryClient();
  const { data: candidate_pools, isFetching } = useQuery({
    queryKey: [
      'individualCandidatePools',
      candidatePoolsState.searchQuery,
      candidatePoolsState.searchSettings
    ],
    queryFn: () => {
      return callPaginatedAPI(
        `/api/v4/candidate_pool_assignments?${queryString.stringify(
          {
            ...candidatePoolsState.searchSettings,
            q: candidatePoolsState.searchQuery,
            candidate_pool_id: selectedPool.id
          },
          {
            skipEmptyString: true,
            skipNull: true,
            arrayFormat: 'bracket',
            encode: false
          }
        )}`,
        null,
        'GET'
      ) as Promise<CandidatePoolAPIReturn>;
    },
    onError() {
      setSnackbarState({
        message: `There was an error retrieving candidate pools, please refresh the page and try again.`,
        state: 'error'
      });
    },
    enabled: isOpen
  });

  const { mutate: deleteCandidate } = useMutation({
    mutationFn: (id: number[]) =>
      callAPI(`/api/v4/candidate_pools/bulk_remove_candidates`, { assignment_ids: id }, 'POST'),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['individualCandidatePools'] }),
    onError() {
      setSnackbarState({
        message: `There was an error deleting the candidate pool, please refresh the page and try again.`,
        state: 'error'
      });
    }
  });

  return (
    <Box>
      <SearchInput dispatch={candidatePoolsDispatch} id="search-box-candidate-tab" />
      <GenericTable
        items={candidate_pools?.data?.candidate_pool_assignments || []}
        isLoadingItems={isFetching}
        dispatch={candidatePoolsDispatch}
        tableState={candidatePoolsState.tableState}
        paginationState={candidatePoolsState.searchSettings}
        pagination={{
          totalItems: candidate_pools?.totalItems || 0,
          totalPages: candidate_pools?.totalPages || 0
        }}
        headerStyle={headerStyles as (id: string, tableHovered: boolean) => IStyle}
        TableRows={CandidatePoolsTableRows}
        TableActionList={[
          {
            id: 'delete',
            tooltipLabel: 'Delete',
            elementId: 'delete-individual-candidate-button',
            icon: <DeleteIcon />,
            actionCall: (item) => item?.id && deleteCandidate([item?.id])
          },
          {
            id: 'viewAttachments',
            tooltipLabel: 'View Attachments',
            icon: <AttachFileIcon />
          }
        ]}
        label="candidates"
        titleCellId="candidate_name"
        allowMultipleSelection={candidate_pools?.totalItems ? true : false}
        customEmptyStateMessage="available in this pool"
      />
      <TotalSelectedIndicatorWithActions
        totalSelected={candidatePoolsState.tableState.selectedItemIds.length}
        parentDiv={null}
        dispatch={candidatePoolsDispatch}
        actionButtonId="delete-candidate-action-button"
        actionButtonsList={[
          {
            id: 'delete',
            label: 'Delete',
            icon: <DeleteIcon />
          }
        ]}
        actionCall={() => {
          deleteCandidate(candidatePoolsState.tableState.selectedItemIds);
          candidatePoolsDispatch({
            type: 'REMOVE_ALL_PAGE_ITEMS',
            payload: candidatePoolsState.tableState.selectedItemIds
          });
        }}
      />
      <CandidateTabTableActionComponents
        actionsState={candidatePoolsState}
        dispatch={candidatePoolsDispatch}
      />
    </Box>
  );
};

export default IndividualCandidatePoolModal;
