import React, { useState } from 'react';
import { Box } from '@mui/material';
import DocTypeIcon from '../Utilities/DocTypeIcon';
import { humanFileSize } from '../Utilities/humanFileSize';
import { sharedClasses } from './sharedClasses';
import { theme } from '../../../ThemeContext/ThemeObject';

const AttachmentPreview = ({ templateAttachments }: { templateAttachments: any[] }) => {
  return (
    <Box sx={{ marginTop: '25px' }}>
      {templateAttachments?.length > 0 && (
        <>
          <Box sx={{ color: theme.palette.primary.main, fontSize: '16px', fontWeight: 'bold' }}>
            Attachments
          </Box>
          <Box sx={{ ...sharedClasses.emailAttachmentsList, marginTop: '2rem' }}>
            {templateAttachments &&
              templateAttachments.map((templateAttachment, index) => (
                <Box
                  key={templateAttachment.id}
                  sx={{
                    ...sharedClasses.emailAttachmentContainer
                  }}
                >
                  <Box sx={sharedClasses.emailAttachmentIcon}>
                    {DocTypeIcon(templateAttachment.attached_file_name.split('.').pop())}
                  </Box>
                  <Box>
                    <Box sx={{ ...sharedClasses.emailAttachmentName, maxWidth: 'fit-content' }}>
                      {templateAttachment.attached_file_name}
                    </Box>
                    <Box sx={sharedClasses.fileSize}>
                      {humanFileSize(templateAttachment.attached_file_size)}
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default AttachmentPreview;
