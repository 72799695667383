import React, { useState, useRef } from 'react';
import moment from 'moment';
import axios from 'axios';
import { classes } from '../styles';
import { IAdTableProps, IJobAd } from '../types';
import GenericDialog from '../GenericDialog';
import ExpiryDialog from '../ExpiryDialog';
import ExpiryPopover from '../ExpiryPopover';
import { Box, Button, IconButton, Snackbar, Tooltip, Popover } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function AdTable({ jobAds, ads, setAds, adsStatus, apiKey, adminUser }: IAdTableProps) {
  const [dialogUrl, setDialogUrl] = useState('');
  const [dialogAdID, setDialogAdID] = useState(0);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [dialogButtonText, setDialogButtonText] = useState('');
  const [dialogButtonCallback, setDialogButtonCallback] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [expiryDialogOpen, setExpiryDialogOpen] = useState(false);
  const [expiryActionsAnchorEl, setExpiryActionsAnchorEl] = useState(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [selectedAd, setSelectedAd] = useState<IJobAd | null>(null);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [tableHovered, setTableHovered] = useState(false);
  const actionCellRef = useRef(null);
  const jobCellRef = useRef(null);
  const [canScroll, setCanScroll] = useState([false, true]);
  const tableRef = useRef<HTMLDivElement>(null);

  function handleSnack(message: string) {
    setIsSnackOpen(true);
    setSnackMessage(message);
  }

  function handleButtonStyle(ad: IJobAd) {
    if (adsStatus === 'active') {
      return {
        text: (
          <>
            Expire Ad <KeyboardArrowDownIcon sx={classes.downIcon} />
          </>
        ),
        class: classes.buttonExpiry,
        callback: (event: React.MouseEvent) => openExpiryDialog(ad, event)
      };
    } else if (adsStatus === 'draft') {
      return {
        text: 'Continue Draft',
        class: classes.buttonGreenLight,
        callback: () => handleEdit(ad.edit_url)
      };
    } else {
      return {
        text: 'Clone Ad',
        class: classes.buttonSecondaryLight,
        callback: () => {
          setDialogUrl(ad.clone_url);
          setDialogTitle('Are you sure you want to clone this ad?');
          setDialogDescription('You will have to repost for your ad to be active on SEEK.');
          setDialogButtonText('Clone ad');
          setDialogButtonCallback(() => handleCopy);
          setDialogOpen(true);
        }
      };
    }
  }

  const handleImmediateExpiry = (ad: IJobAd) => {
    setDialogAdID(ad.id);
    setDialogUrl(`/api/v4/seek/jobs/${ad.job_id}/job_ads/${ad.id}/ad_posting`);
    setDialogTitle('Are you sure you want to expire this ad?');
    setDialogDescription(
      'This will remove it from SEEK immediately. To repost this ad later you will be charged for a new ad.'
    );
    setDialogButtonText('Expire now');
    setDialogButtonCallback(() => handleExpire);
    setDialogOpen(true);
    setExpiryActionsAnchorEl(null);
  };

  const handleDateExpiry = () => {
    setExpiryDialogOpen(true);
    setExpiryActionsAnchorEl(null);
  };

  const openExpiryDialog = (ad: IJobAd, event: React.MouseEvent) => {
    setSelectedAd(ad);
    setExpiryActionsAnchorEl(event.currentTarget);
  };

  const handleLocalExpiryChange = (newDate, adId) => {
    const newAds = Object.assign(jobAds);
    const index = newAds[adsStatus].findIndex((ad: IJobAd) => ad.id === adId);
    newAds[adsStatus][index].expired_at = newDate;
    setAds({ ...newAds });
  };

  async function handleEdit(url: string) {
    window.location.href = url;
  }

  async function handleExpire(url: string, id: number) {
    setDialogLoading(true);
    try {
      await axios.delete(`${url}`, {
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const newAds = Object.assign(jobAds);
      const index = newAds[adsStatus].findIndex((ad: IJobAd) => ad.id === id);
      const copyJob = newAds[adsStatus][index];
      newAds.expired && newAds.expired.push(copyJob);
      newAds[adsStatus].splice(index, 1);
      setAds({ ...newAds });
      handleSnack('Job has been expired');
    } catch (e) {
      console.log(e);
      handleSnack('Could not expire ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  async function handleArchive(url: string, id: number) {
    setDialogLoading(true);
    try {
      const { data } = await axios.delete(`${url}`, {
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const newAds = Object.assign(jobAds);
      const index = newAds[adsStatus].findIndex((ad: IJobAd) => ad.id === id);
      const copyJob = newAds[adsStatus][index];
      newAds.archived && newAds.archived.push(copyJob);
      newAds[adsStatus].splice(index, 1);
      setAds({ ...newAds });
      handleSnack(`${data.title} has been archived`);
    } catch (e) {
      console.log(e);
      handleSnack('Could not archive ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  async function handleDelete(url: string, id: number) {
    setDialogLoading(true);
    try {
      const { data } = await axios.delete(`${url}`, {
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const newAds = Object.assign(jobAds);
      newAds[adsStatus] = newAds[adsStatus].filter((ad: IJobAd) => ad.id !== id);
      setAds({ ...newAds });
      handleSnack(`${data.title} has been deleted`);
    } catch (e) {
      console.log(e);
      handleSnack('Could not delete ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  async function handleReplay(url: string) {
    setDialogLoading(true);
    try {
      const { data } = await axios.post(
        `${url}`,
        {},
        {
          headers: {
            'X-api-authenticate': apiKey
          }
        }
      );
      handleSnack('Replay request has been posted successfully');
      Object.keys(jobAds).forEach((status) => {
        jobAds[status] = jobAds[status].filter((ad: IJobAd) => ad.id !== data.id);
      });
      setAds({ ...jobAds });
    } catch (e) {
      console.log(e);
      handleSnack('Could not replay ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  function isScrollable(element: HTMLElement) {
    return element && element.scrollWidth > element.clientWidth + 20;
  }

  const handleScrollableEnter = () => {
    if (isScrollable(tableRef.current) && ads.length > 0) setTableHovered(true);
  };

  const handleScrollableExit = () => {
    setTableHovered(false);
  };

  const handleScroll = () => {
    if (tableRef.current.scrollLeft < 10) {
      setCanScroll([false, true]);
    } else if (
      tableRef.current.scrollLeft >
      tableRef.current.scrollWidth - tableRef.current.clientWidth - 10
    ) {
      setCanScroll([true, false]);
    } else {
      setCanScroll([true, true]);
    }
  };

  const handleScrollLeft = () => {
    tableRef.current.scrollLeft > 360
      ? (tableRef.current.scrollLeft -= 300)
      : (tableRef.current.scrollLeft = 0);
  };

  const handleScrollRight = () => {
    tableRef.current.scrollWidth - tableRef.current.clientWidth > 360
      ? (tableRef.current.scrollLeft += 300)
      : (tableRef.current.scrollLeft = tableRef.current.scrollWidth - tableRef.current.clientWidth);
  };

  function handleCopy(url: string) {
    window.location.href = url;
  }

  const showDeleteOption =
    adsStatus !== 'active' &&
    selectedAd?.status !== 'open' &&
    !selectedAd?.active_on_seek &&
    !['open', 'archived'].includes(adsStatus);

  const showIcons = (ad: IJobAd) => {
    switch (adsStatus) {
      case 'active':
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                sx={classes.icon}
                onClick={() => handleEdit(ad.edit_url)}
                className="edit-seek-ad-button"
              >
                <EditIcon fontSize="small" sx={classes.iconGrey} />
              </IconButton>
            </Tooltip>
            <IconButton
              sx={classes.icon}
              onClick={(e) => {
                setActionsAnchorEl(e.currentTarget);
                setSelectedAd(ad);
              }}
              className="seek-ad-actions-button"
            >
              <MoreVertIcon sx={classes.iconGrey} />
            </IconButton>
          </>
        );
      case 'draft':
        return (
          <Tooltip title="Edit">
            <IconButton
              sx={classes.icon}
              onClick={() => handleEdit(ad.edit_url)}
              className="edit-seek-ad-button"
            >
              <EditIcon fontSize="small" sx={classes.iconGrey} />
            </IconButton>
          </Tooltip>
        );
      case 'expired':
        return (
          <>
            <Tooltip title="Archive">
              <IconButton>
                <ArchiveIcon
                  sx={classes.iconRed}
                  onClick={() => {
                    setDialogUrl(`/api/v4/seek/jobs/${ad?.job_id}/job_ads/${ad?.id}/archive`);
                    setDialogAdID(ad?.id);
                    setDialogTitle('Are you sure you want to archive this ad?');
                    setDialogDescription(null);
                    setDialogButtonText('Archive now');
                    setDialogButtonCallback(() => handleArchive);
                    setDialogOpen(true);
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        );
      case 'archived':
        return null;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box sx={classes.arrowsContainer}>
          <Box
            onClick={handleScrollLeft}
            onMouseEnter={handleScrollableEnter}
            sx={{
              ...classes.leftArrow,
              ...(tableHovered && canScroll[0] && classes.showArrows),
              left: `${jobCellRef.current?.getBoundingClientRect().right}px`,
              top: 0
            }}
            id="left-arrow"
          >
            <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <Box sx={classes.arrowsContainer}>
          <Box
            onMouseEnter={handleScrollableEnter}
            onClick={handleScrollRight}
            sx={{
              ...classes.rightArrow,
              ...(tableHovered && canScroll[1] && classes.showArrows),
              left: `${actionCellRef.current?.getBoundingClientRect().left - 40}px`,
              top: 0
            }}
            id="right-arrow"
          >
            <KeyboardArrowRightIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <Box
          sx={classes.tableContainer}
          onMouseEnter={handleScrollableEnter}
          onMouseLeave={handleScrollableExit}
          ref={tableRef}
          onScroll={handleScroll}
        >
          <Box sx={classes.table}>
            <Box sx={{ ...classes.row, ...classes.rowHeader }}>
              <Box component="span" sx={{ ...classes.sticky, minWidth: '86px' }}>
                SEEK Reference
              </Box>
              <Box
                component="span"
                ref={jobCellRef}
                sx={{
                  ...classes.title,
                  minWidth: '182px',
                  ...classes.sticky,
                  ...classes.scrollShadowLeft,
                  ...(tableHovered && classes.scrollShadowVisible),
                  left: '86px'
                }}
              >
                Job
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Location
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Creation Date
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Created By
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Expiry
              </Box>
              <Box
                component="span"
                sx={{
                  ...classes.stickyRight,
                  ...classes.scrollShadowRight,
                  ...(tableHovered && classes.scrollShadowVisible)
                }}
                ref={actionCellRef}
              />
            </Box>
            {ads && ads.length > 0 ? (
              ads.map((ad) => (
                <Box key={ad.id} sx={classes.row}>
                  <Box sx={{ ...classes.sticky, minWidth: '86px' }}>{ad.reference}</Box>
                  <Box
                    sx={{
                      ...classes.title,
                      minWidth: '182px',
                      ...classes.sticky,
                      ...classes.scrollShadowLeft,
                      ...(tableHovered && classes.scrollShadowVisible),
                      left: '86px'
                    }}
                  >
                    {ad.title}
                  </Box>
                  <Box>{ad.listing_location_display}</Box>
                  <Box>{moment(ad.created_at).format('D MMM YYYY')}</Box>
                  <Box>{ad.created_by ? ad.created_by.name : '-'}</Box>
                  <Box>
                    {ad.expired_at ? moment(ad.expired_at).format('D MMM YYYY') : 'No expiry'}
                  </Box>
                  <Box
                    sx={{
                      ...classes.actionsContainer,
                      ...classes.stickyRight,
                      ...classes.scrollShadowRight,
                      ...(tableHovered && classes.scrollShadowVisible)
                    }}
                  >
                    {adsStatus !== 'legacy' && (
                      <Box sx={classes.actionButtonContainer} onClick={() => setDialogAdID(ad.id)}>
                        <Button
                          sx={{
                            ...classes.columnRefrence,
                            ...handleButtonStyle(ad).class,
                            ...classes.columnButtonPadding,
                            ...classes.actionButton
                          }}
                          disableElevation
                          size="large"
                          disabled={adsStatus === 'legacy'}
                          onClick={handleButtonStyle(ad).callback}
                        >
                          {handleButtonStyle(ad).text}
                        </Button>
                      </Box>
                    )}
                    {showIcons(ad)}
                  </Box>
                </Box>
              ))
            ) : (
              <Box sx={classes.row}>
                <Box sx={classes.title}>No ads to display</Box>
              </Box>
            )}
            <Popover
              id={actionsAnchorEl ? 'actions-menu-popover' : undefined}
              sx={{ ...classes.actionsMenu, minWidth: 'unset', width: '135px' }}
              open={Boolean(actionsAnchorEl)}
              anchorEl={actionsAnchorEl}
              onClose={() => {
                setActionsAnchorEl(null);
                setTimeout(() => {
                  setSelectedAd(null);
                }, 300);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              {selectedAd && (
                <>
                  {adsStatus === 'active' && selectedAd.active_on_seek && (
                    <span
                      onClick={() => window.open(selectedAd.seek_live_url, '_blank').focus()}
                      className="preview-seek-ad-button"
                    >
                      Preview ad
                    </span>
                  )}
                  {!['legacy'].includes(adsStatus) && adminUser && (
                    <span
                      className="preview-seek-ad-button"
                      onClick={() => {
                        setDialogUrl(
                          `/api/v4/seek/jobs/${selectedAd.job_id}/job_ads/${selectedAd.id}/webhook_replay`
                        );
                        setDialogTitle(
                          'Are you sure you want to replay webhook events of this ad?'
                        );
                        setDialogDescription(
                          'You will have to repost for your ad to be active on Seek.'
                        );
                        setDialogButtonText('Replay');
                        setDialogButtonCallback(() => handleReplay);
                        setDialogOpen(true);
                      }}
                    >
                      Replay Ad
                    </span>
                  )}
                  {adsStatus === 'legacy' && (
                    <span onClick={() => (window.location.href = selectedAd.preview_url)}>
                      Settings
                    </span>
                  )}
                  {!['expired', 'archived', 'legacy'].includes(adsStatus) && (
                    <span
                      onClick={() => {
                        setDialogUrl(selectedAd.clone_url);
                        setDialogTitle('Are you sure you want to clone this ad?');
                        setDialogDescription(
                          'You will have to repost for your ad to be active on Seek.'
                        );
                        setDialogButtonText('Clone ad');
                        setDialogButtonCallback(() => handleCopy);
                        setDialogOpen(true);
                      }}
                      className="clone-seek-ad-button"
                    >
                      Clone
                    </span>
                  )}
                  {adsStatus === 'expired' ? (
                    <span
                      onClick={() => {
                        setDialogUrl(
                          `/api/v4/seek/jobs/${selectedAd.job_id}/job_ads/${selectedAd.id}/archive`
                        );
                        setDialogAdID(selectedAd.id);
                        setDialogTitle('Are you sure you want to archive this ad?');
                        setDialogDescription(null);
                        setDialogButtonText('Archive now');
                        setDialogButtonCallback(() => handleArchive);
                        setDialogOpen(true);
                      }}
                    >
                      Archive
                    </span>
                  ) : (
                    showDeleteOption && (
                      <span
                        onClick={() => {
                          setDialogUrl(
                            `/api/v4/seek/jobs/${selectedAd.job_id}/job_ads/${selectedAd.id}`
                          );
                          setDialogAdID(selectedAd.id);
                          setDialogTitle('Are you sure you want to delete this ad?');
                          setDialogDescription(
                            'Your job ad will be permanently deleted and you will not be able to recover your ad details.'
                          );
                          setDialogButtonText('Delete ad');
                          setDialogButtonCallback(() => handleDelete);
                          setDialogOpen(true);
                        }}
                      >
                        Delete
                      </span>
                    )
                  )}
                </>
              )}
            </Popover>
          </Box>
        </Box>
      </Box>
      <GenericDialog
        url={dialogUrl}
        title={dialogTitle}
        description={dialogDescription}
        buttonText={dialogButtonText}
        buttonCallback={dialogButtonCallback}
        callbackLoading={dialogLoading}
        isDialogOpen={dialogOpen}
        dialogAdID={dialogAdID}
        setDialogOpen={setDialogOpen}
      />
      <ExpiryDialog
        isExpiryDialogOpen={expiryDialogOpen}
        setExpiryDialogOpen={setExpiryDialogOpen}
        dialogLoading={dialogLoading}
        ad={selectedAd}
        apiKey={apiKey}
        handleSnack={handleSnack}
        handleLocalExpiryChange={handleLocalExpiryChange}
      />
      <ExpiryPopover
        immediateExpiryOnClick={handleImmediateExpiry}
        dateExpiryOnClick={handleDateExpiry}
        expiryActionsAnchorEl={expiryActionsAnchorEl}
        setExpiryActionsAnchorEl={setExpiryActionsAnchorEl}
        ad={selectedAd}
      />
      <Snackbar open={isSnackOpen} onClose={() => setIsSnackOpen(false)} message={snackMessage} />
    </Box>
  );
}

export default AdTable;
