import React, { useMemo, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { theme } from '../../../../ThemeContext/ThemeObject';

type IProps = {
  label: string;
  titleKeyType: 'name' | 'title';
  items: {
    id: number;
    name?: string;
    title?: string;
    url: string;
  }[];
  maxItems?: number;
  maxItemsLink?: string;
};

const HEIGHT = 26;
const MARGIN_BOTTOM = 6;
const BORDER_HEIGHT = 2;

export default function ExpandableLinkedTags({
  label,
  titleKeyType,
  items,
  maxItems,
  maxItemsLink
}: IProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandedItems, setShowExpandedItems] = useState(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleExpandClick = () => {
    if (isExpanded) {
      setIsExpanded(false);
      setTimeout(() => {
        setShowExpandedItems(false);
      }, 500);
    } else {
      setIsExpanded(true);
      setShowExpandedItems(true);
    }
  };

  function getHeight() {
    if (!isExpanded) {
      return `${HEIGHT}px`;
    } else {
      if (maxItems && items.length > maxItems) {
        return `${(HEIGHT + 3) * (maxItems + 1) + MARGIN_BOTTOM * maxItems}px`;
      } else {
        return `${HEIGHT * (items.length + 1) + MARGIN_BOTTOM * items.length}px`;
      }
    }
  }

  const handleShowTooltip = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.currentTarget.offsetWidth < event.currentTarget.scrollWidth) {
      setShowTooltip(true);
    }
  };

  const displayedItems = useMemo(
    () => (maxItems ? items.slice(1, maxItems) : items.slice(1)),
    [items, maxItems]
  );

  const followMaxItemsLink = () => {
    if (maxItemsLink) {
      window.open(maxItemsLink, '_self');
    }
  };

  const displayBadge = items.length > 1 && !isExpanded;

  return (
    <Box sx={{ ...styles.container, ...{ height: getHeight() } }} className={label}>
      <Box display="flex">
        <Tag
          item={items[0]}
          titleKeyType={titleKeyType}
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          handleShowTooltip={handleShowTooltip}
          badge={{
            show: displayBadge,
            handleExpandClick,
            numberOfItems: items.length - 1
          }}
        />
      </Box>
      {showExpandedItems &&
        displayedItems.map((item) => {
          return (
            <Tag
              key={item.id}
              item={item}
              titleKeyType={titleKeyType}
              showTooltip={showTooltip}
              setShowTooltip={setShowTooltip}
              handleShowTooltip={handleShowTooltip}
            />
          );
        })}
      {maxItems && items.length > maxItems && (
        <Box
          id="show-more-job-tag-button"
          onClick={followMaxItemsLink}
          sx={{ ...styles.item, ...styles.viewAllItem, ...(maxItemsLink && { cursor: 'pointer' }) }}
        >
          {maxItemsLink ? `View all ${items.length}` : `+${items.length - maxItems} more`}
        </Box>
      )}
      <Box
        id="show-less-job-tag-button"
        onClick={handleExpandClick}
        sx={{ ...styles.item, ...styles.showLess }}
      >
        Show less
      </Box>
    </Box>
  );
}

function Tag({
  item,
  titleKeyType,
  showTooltip,
  setShowTooltip,
  handleShowTooltip,
  badge
}: {
  item: IProps['items'][0];
  titleKeyType: IProps['titleKeyType'];
  showTooltip: boolean;
  setShowTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  handleShowTooltip: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  badge?: {
    show: boolean;
    handleExpandClick: () => void;
    numberOfItems: number;
  };
}) {
  const badgeFontSize = (numberOfItems: number) => {
    if (numberOfItems < 10) {
      return '14px';
    } else if (numberOfItems < 100) {
      return '12px';
    } else {
      return '10px';
    }
  };
  return (
    <>
      <Tooltip
        key={item.id}
        title={item[titleKeyType]}
        placement="top"
        disableFocusListener={!showTooltip}
        disableHoverListener={!showTooltip}
      >
        <Box
          id="job-name-tag-button"
          key={item.id}
          sx={{
            ...styles.item,
            ...{ width: badge?.show ? '82.5%' : '100%' },
            ...(item.url && { cursor: 'pointer' })
          }}
          onMouseEnter={handleShowTooltip}
          onMouseLeave={() => setShowTooltip(false)}
          onClick={() => item.url && window.open(item.url, '_blank')}
          onAuxClick={() => item.url && window.open(item.url, '_blank')}
        >
          {item[titleKeyType]}
        </Box>
      </Tooltip>
      {badge?.show && (
        <Box
          id="show-more-job-tag-button"
          onClick={badge.handleExpandClick}
          sx={{ ...styles.badge, fontSize: badgeFontSize(badge.numberOfItems) }}
        >
          {`+${badge.numberOfItems}`}
        </Box>
      )}
    </>
  );
}

const styles = {
  container: {
    transition: 'height 0.5s cubic-bezier(.75,.34,.19,.79)',
    overflow: 'hidden'
  },
  item: {
    backgroundColor: '#EDF2F5',
    color: theme.palette.primary.main,
    borderRadius: `${HEIGHT / 2}px`,
    height: HEIGHT,
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: `${MARGIN_BOTTOM}px`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '0 18px',
    border: `${BORDER_HEIGHT}px solid white`,
    zIndex: 1,
    lineHeight: `${HEIGHT - BORDER_HEIGHT * 2}px`,
    transition: 'width 0.5s cubic-bezier(.75,.34,.19,.79)',
    fontSize: '13px'
  },
  viewAllItem: {
    overflow: 'unset',
    textOverflow: 'unset',
    whiteSpace: 'unset',
    height: 'unset',
    lineHeight: 'unset'
  },
  badge: {
    backgroundColor: '#EDF2F5',
    color: theme.palette.primary.main,
    borderRadius: HEIGHT / 2,
    height: HEIGHT,
    border: `${BORDER_HEIGHT}px solid white`,
    textAlign: 'center',
    fontWeight: '600',
    minWidth: '38px',
    transform: 'translateX(-13px)',
    marginRight: '-13px',
    paddingLeft: '8px',
    userSelect: 'none',
    lineHeight: `${HEIGHT - BORDER_HEIGHT * 2}px`,
    transition: 'opacity 0.5s, width 0.5s cubic-bezier(.75,.34,.19,.79)',
    cursor: 'pointer'
  },
  showLess: {
    fontSize: '12px',
    cursor: 'pointer',
    userSelect: 'none',
    width: '100px',
    margin: 'auto'
  }
};
