import React, { Dispatch, SetStateAction, RefObject } from 'react';
import { Stack } from '@mui/material';
import HtmlEditor from '../../../Components/Utilities/HtmlEditor';
import { classes } from '../../../Job/Applications/styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';

export default function EmailBodyComponent({
  body,
  setBody,
  placeholders,
  error,
  innerRef
}: {
  body: string;
  setBody: Dispatch<SetStateAction<string>>;
  placeholders?: string;
  error?: string;
  innerRef?: RefObject<HTMLDivElement>;
}) {
  return (
    <Stack sx={{ marginLeft: '3px', rowGap: 0.5 }} ref={innerRef}>
      <Stack sx={{ color: '#838383', flexDirection: 'row', columnGap: '2px' }}>
        Body <Stack sx={{ color: '#E37D7A' }}>*</Stack>
      </Stack>
      <HtmlEditor body={body} onChange={setBody} maxHeight={400} />
      {error && <Stack sx={sharedClasses.errorBox}>{error}</Stack>}
      <Stack sx={classes.addToEventEmailPlaceholder}>
        Available placeholders are:{' '}
        {placeholders ||
          '{FIRSTNAME}, {LASTNAME}, {USER_FIRSTNAME}, {USER_LASTNAME}, {USER_EMAIL}, {USER_PHONE}, {JOB_TITLE}, {JOB_NUMBER}, {EVENT_NAME}, {EVENT_TIME}, {EVENT_DESCRIPTION}, {EVENT_DURATION}, {NEXT_WEEK}'}
      </Stack>
    </Stack>
  );
}
