import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import useStyles from "../useStyles";
import inputStyles from "../../CandidateFilter/useStyles";
import {
  Popper,
  ClickAwayListener,
  IconButton,
  TextField,
  InputAdornment
} from "@material-ui/core";
import BookmarksOutlinedIcon from "@material-ui/icons/BookmarksOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
function TemplatePicker({ setBody, userOptions }) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const anchor = useRef(null);
  const classes = useStyles();
  function handleClick(template) {
    setBody(template.body);
    setIsOpen(false);
  }
  return /* @__PURE__ */ React.createElement(ClickAwayListener, { onClickAway: () => setIsOpen(false) }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    IconButton,
    {
      classes: { root: classes.actionButton },
      ref: anchor,
      onClick: () => setIsOpen(!isOpen)
    },
    /* @__PURE__ */ React.createElement(BookmarksOutlinedIcon, { fontSize: "small", color: "primary" })
  ), /* @__PURE__ */ React.createElement(
    Popper,
    {
      className: `${classes.popper} ${classes.popperTemplate}`,
      anchorEl: anchor.current,
      open: isOpen,
      placement: "top-start",
      popperOptions: { positionFixed: true }
    },
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        className: classes.textField,
        type: "string",
        placeholder: "Search templates",
        value: searchTerm,
        onChange: (e) => setSearchTerm(e.target.value.toLowerCase()),
        variant: "outlined",
        InputProps: {
          startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, /* @__PURE__ */ React.createElement(SearchOutlinedIcon, null))
        }
      }
    ),
    userOptions && userOptions.templates ? userOptions.templates.filter((template) => template.title.toLowerCase().includes(searchTerm)).map(
      (template) => /* @__PURE__ */ React.createElement("div", { key: template.id, className: classes.popperItemTemplate, onClick: () => handleClick(template) }, /* @__PURE__ */ React.createElement("h4", null, template.title), /* @__PURE__ */ React.createElement("span", null, template.body))
    ) : /* @__PURE__ */ React.createElement("div", { className: `${classes.popperItemTemplate} ${classes.noHover}` }, /* @__PURE__ */ React.createElement("h4", null, "No templates"))
  )));
}
TemplatePicker.propTypes = {
  setBody: PropTypes.func.isRequired,
  userOptions: PropTypes.object.isRequired
};
export default TemplatePicker;
