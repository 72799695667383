import React, { useState, useMemo } from 'react';
import { Box } from '@mui/material';
import AppsIcon from '../../Assets/AppsIcon';
import { navbarWidths } from '../../config';
import { classes } from '../../styles';
import AppMenuItems from './AppMenuItems';

const AppMenu = ({ navbarState }: { navbarState: keyof typeof navbarWidths }) => {
  const [appMenuAnchorEl, setAppMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isRecruit = useMemo(
    () =>
      !window.location.href.includes('search/advanced') && !window.location.href.includes('engage'),
    []
  );
  const logo = useMemo(() => {
    if (isRecruit) {
      return navbarState === 'expanded' ? ':RECRUIT' : ':RE';
    } else {
      return navbarState === 'expanded' ? ':ENGAGE' : ':EN';
    }
  }, [isRecruit, navbarState]);

  return (
    <>
      <Box
        sx={{
          ...classes.appMenuContainer,
          ...(navbarState === 'collapsed' && classes.appMenuContainerCollapsed)
        }}
        onClick={(e) => setAppMenuAnchorEl(e.currentTarget)}
        id={isRecruit ? `logo-name-dropdown-button` : `engage-logo-button`}
      >
        <span>{logo}</span>
        {navbarState === 'expanded' && <AppsIcon />}
      </Box>
      {Boolean(appMenuAnchorEl) && (
        <AppMenuItems
          appMenuAnchorEl={appMenuAnchorEl}
          setAppMenuAnchorEl={setAppMenuAnchorEl}
          navbarState={navbarState}
          isRecruit={isRecruit}
        />
      )}
    </>
  );
};

export default AppMenu;
