"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  container: {
    width: "100%"
  },
  banner: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "22px",
    alignItems: "center",
    marginBottom: "20px"
  },
  toggleButtonGroup: {
    borderRadius: "8px",
    margin: "10px 0px 20px 0px",
    "& button": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      padding: "6px 16px",
      borderRadius: "0",
      textTransform: "capitalize",
      borderRight: "1px solid #f3f3f3",
      "&:first-of-type": {
        borderTopLeftRadius: "6px",
        borderBottomLeftRadius: "6px"
      },
      "&:last-child": {
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        borderRight: "0"
      }
    }
  },
  containerOuter: {
    background: "#FFF",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    "& button:focus": {
      outline: "0"
    }
  },
  buttonPrimary: {
    borderRadius: "6px",
    padding: "14px 30px",
    textTransform: "capitalize",
    color: "#FFF",
    fontWeight: "600",
    fontSize: "14px",
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.dark
    }
  },
  buttonSecondary: {
    borderRadius: "6px",
    padding: "14px 30px",
    textTransform: "capitalize",
    color: "#FFF",
    fontWeight: "600",
    fontSize: "14px",
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.primary.dark
    }
  },
  newAdButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "1.3",
    color: theme.palette.action.main,
    backgroundColor: "#5BC4BF1F",
    height: "47px",
    width: "120px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#5BC4BF35",
      border: "none"
    }
  },
  buttonGrey: {
    padding: "14px 30px",
    background: "#FFF",
    color: "#DDDDDD",
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#FFF"
    }
  },
  buttonActive: {
    color: theme.palette.secondary.main
  },
  tableContainer: {
    overflowX: "auto",
    scrollBehavior: "smooth"
  },
  table: {
    display: "table",
    borderCollapse: "collapse",
    borderSpacing: "0",
    width: "100%",
    background: "#FFF",
    borderRadius: "8px"
  },
  rowHeader: {
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "700",
      color: theme.palette.primary.main,
      marginTop: "24px",
      marginBottom: "20px"
    }
  },
  row: {
    display: "table-row-group",
    width: "100%",
    borderBottom: "1px solid #f3f3f3",
    "& > *": {
      padding: "24px",
      paddingRight: "0px",
      display: "table-cell",
      fontSize: "14px",
      "&:first-of-type": {
        paddingLeft: "18px"
      },
      "&:last-child": {
        paddingRight: "18px"
      }
    }
  },
  actionsContainer: {
    display: "flex",
    whiteSpace: "nowrap",
    textAlign: "right",
    "& .Mui-disabled": {
      opacity: "0.5"
    }
  },
  actionButtonContainer: {
    marginRight: "14px"
  },
  actionButton: {
    width: "100px",
    maxHeight: "44px",
    lineHeight: "1.05rem",
    whiteSpace: "break-spaces"
  },
  title: {
    color: "#666666",
    fontWeight: "600"
  },
  center: {
    textAlign: "center"
  },
  iconGreen: {
    color: theme.palette.success.main
  },
  iconRed: {
    color: theme.palette.error.main
  },
  iconGrey: {
    color: "#666666"
  },
  icon: {
    width: "40px",
    height: "40px"
  },
  columnRefrence: {
    padding: "3px 12px",
    height: "2.75rem",
    lineHeight: "17px"
  },
  columnButtonPadding: {
    padding: "3px 12px"
  },
  dialog: {
    padding: "40px 60px",
    alignItems: "center",
    textAlign: "center",
    "& > h4": {
      fontSize: "26px",
      color: theme.palette.primary.main,
      marginBottom: "14px"
    },
    "& > span": {
      fontWeight: "600",
      color: "#666666",
      fontSize: "16px",
      lineHeight: "20px"
    },
    "& button:focus": {
      outline: "0"
    }
  },
  dialogButtonContainer: {
    display: "flex",
    marginTop: "40px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    "& > button": {
      margin: "0 10px",
      minWidth: "150px"
    }
  },
  buttonSecondaryLight: {
    borderRadius: "6px",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "14px 30px",
    fontSize: "15px",
    fontWeight: "bold",
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#FFF"
    }
  },
  buttonRed: {
    borderRadius: "6px",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "14px 30px",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "15px",
    background: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.dark,
      color: "#FFF"
    }
  },
  buttonRedLight: {
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    fontSize: "15px",
    fontWeight: "bold",
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.main,
      color: "#FFF"
    }
  },
  buttonExpiry: {
    width: "122px",
    maxWidth: "unset !important",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    fontSize: "15px",
    fontWeight: "bold",
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.main,
      color: "#FFF"
    }
  },
  buttonGreenLight: {
    padding: "14px 30px",
    fontSize: "15px",
    fontWeight: "bold",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    background: theme.palette.success.light,
    color: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.main,
      color: "#FFF"
    }
  },
  disableIcon: {
    pointerEvents: "none",
    opacity: "0.5"
  },
  sponsorText: {
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  paginationParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  },
  rowSelectContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "8px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666",
    "& span": {
      marginRight: "12px",
      paddingBottom: "6px"
    }
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "8px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666"
  },
  pagination: {
    marginRight: "24px",
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#CCCCCC",
      margin: "0px -2px"
    },
    "& .Mui-selected": {
      color: "#666666",
      backgroundColor: "#FFFFFF !important",
      "&:hover": {
        backgroundColor: "#FFFFFF !important"
      }
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#666666"
    }
  },
  rowSelect: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: "#666666",
    fontWeight: "bold",
    borderRadius: "6px !important",
    "& legend": {
      display: "none"
    },
    "& svg": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CACDCE"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DBDEDF"
    },
    "& .MuiOutlinedInput-input": {
      padding: "4px 12px 8px 12px !important"
    }
  },
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  },
  downIcon: {
    marginLeft: "8px",
    fontSize: "18px",
    color: "inherit"
  },
  actionsMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "11px",
      minWidth: "155px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#939393",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  buttonDateConfirm: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    padding: "10px 30px",
    fontWeight: "bold",
    textTransform: "capitalize",
    color: "#ffffff",
    background: theme.palette.action.main,
    "&:hover": {
      background: "#4da8a4"
    }
  },
  datePicker: {
    fontFamily: "Source Sans Pro",
    color: "#000000",
    width: "119px",
    marginTop: "6px",
    "& input": {
      fontSize: "14px",
      padding: "0px 6px 6px 10px",
      fontFamily: "Source Sans Pro"
    },
    "& fieldset": {
      "& legend": {
        display: "none"
      }
    },
    "& button": {
      padding: "0px",
      transform: "translate(7px, -2px)",
      "&:hover": {
        background: "transparent",
        "& svg": {
          color: theme.palette.primary.main
        }
      }
    },
    "& svg": {
      fontSize: "17px",
      color: "#666666"
    }
  },
  calendar: {
    "& .PrivatePickersFadeTransitionGroup-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.action.main} !important`
    },
    "& .PrivatePickersSlideTransition-root": {
      minHeight: "242px !important"
    }
  },
  expiryMessage: {
    fontFamily: "Source Sans Pro",
    fontSize: "19px",
    maxWidth: "350px",
    paddingBottom: "20px"
  },
  expiryDateMessage: {
    fontFamily: "Source Sans Pro",
    fontSize: "19px",
    maxWidth: "350px",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  expiryPostPickerMessage: {
    fontFamily: "Source Sans Pro",
    fontSize: "19px",
    maxWidth: "350px",
    paddingTop: "10px",
    paddingBottom: "20px",
    "& span": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      color: theme.palette.primary.main
    }
  },
  expiryChangePreview: {
    display: "flex",
    alignItems: "center",
    margin: "6px 66px"
  },
  expiryDateContainer: {
    width: "140px",
    backgroundColor: "#F8F8F8",
    border: "1px solid #DDDDDD",
    padding: "16px 22px",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  expiryTitle: {
    fontSize: "13px",
    color: "#939393",
    lineHeight: "1.4"
  },
  expiryDate: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#000000",
    lineHeight: "1.4"
  },
  filterMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "11px",
      minWidth: "166px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#939393",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  filterButton: {
    fontFamily: "Source Sans Pro",
    height: "47px",
    textTransform: "capitalize",
    fontSize: "15px",
    fontWeight: "600",
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAEEF0",
    borderRadius: "6px",
    padding: "8px 16px"
  },
  arrowsContainer: {
    height: 0,
    overflow: "visible",
    position: "sticky",
    top: "200px",
    zIndex: "999",
    transform: "translateY(100px)"
  },
  leftArrow: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    width: "40px",
    height: "65px",
    background: "black",
    bottom: "370px",
    borderRadius: "0px 10px 10px 0px",
    background: "#eeeeee",
    zIndex: "9999",
    opacity: "0.9",
    cursor: "pointer"
  },
  rightArrow: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    width: "40px",
    height: "65px",
    background: "black",
    bottom: "370px",
    borderRadius: "10px 0px 0px 10px",
    background: "#eeeeee",
    zIndex: "9999",
    opacity: "0.9",
    cursor: "pointer"
  },
  showArrows: {
    display: "flex",
    animation: "$fadeIn linear 0.17s"
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 0.9 }
  },
  sticky: {
    position: "sticky",
    left: "0px",
    background: "white",
    zIndex: 1
  },
  stickyRight: {
    position: "sticky",
    right: "0px",
    background: "white"
  },
  scrollShadowLeft: {
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      right: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      right: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  scrollShadowRight: {
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      left: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      left: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  scrollShadowVisible: {
    "&&::after": {
      opacity: "20%"
    }
  }
};
