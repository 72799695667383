"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  navbarContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    zIndex: 1200,
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    transition: "width 0.2s ease-in-out"
  },
  appMenuContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px 24px 24px 26px",
    background: "linear-gradient(220deg, #5BC4C0 0%, #084D6D 104%)",
    "& span": {
      fontSize: "24px",
      fontWeight: 700,
      fontFamily: "Source Sans Pro"
    }
  },
  appMenuContainerCollapsed: {
    padding: "24px",
    cursor: "pointer"
  },
  navigationItemsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "44px",
    rowGap: "5px",
    marginInline: "10px",
    overflowY: "auto",
    "& a": {
      textDecoration: "none",
      color: "#fff",
      "&:hover": {
        color: "#fff",
        textDecoration: "none"
      }
    },
    "&::-webkit-scrollbar": {
      display: "none"
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none"
  },
  expandCollapseButtonContainer: {
    position: "fixed",
    zIndex: 1250,
    top: "80px",
    transition: "left 0.2s ease-in-out"
  },
  expandCollapseButton: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    borderRadius: "50% !important",
    padding: "0px !important",
    width: "30px !important",
    height: "30px !important",
    backgroundColor: "#D6DEE2 !important",
    color: theme.palette.primary.main,
    "& svg": {
      fontSize: "14px !important"
    },
    "&:hover": {
      backgroundColor: "#C4D0D6 !important"
    },
    "&:focus": {
      outline: "none !important"
    }
  },
  menuItemParent: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    padding: "12px 16px",
    borderRadius: "6px",
    cursor: "pointer",
    "& span": {
      fontSize: "17px",
      marginLeft: "14px"
    },
    "& svg": {
      fontSize: "24px"
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.07)"
    }
  },
  menuItemChildrenContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "40px",
    rowGap: "2px",
    marginTop: "2px"
  },
  menuItemChild: {
    display: "flex",
    fontSize: "17px",
    padding: "12px 16px",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.07)"
    }
  },
  activeParent: {
    backgroundColor: "rgba(255, 255, 255, 0.07)",
    fontWeight: 700
  },
  activeItem: {
    backgroundColor: "rgba(255, 255, 255, 0.07)"
  },
  navigationItemsContainerCollapsed: {
    marginInline: "0px",
    rowGap: "0px"
  },
  menuItemExpandCollapseIcon: {
    position: "absolute",
    top: "11.5px",
    right: "10px",
    "& svg": {
      fontSize: "18px !important"
    }
  },
  menuItemParentCollapsed: {
    flexDirection: "column",
    borderRadius: "0px",
    padding: "12px 0px",
    "& span": {
      marginLeft: "0px",
      fontSize: "13px"
    }
  },
  collapsedNavigationChildContainer: {
    display: "flex",
    flexDirection: "column",
    width: "172px",
    padding: "22px 0px 22px 20px",
    rowGap: "14px",
    borderRadius: "0px 6px 6px 0px",
    backgroundColor: theme.palette.primary.main,
    "& a": {
      fontSize: "15px",
      color: "#fff",
      textDecoration: "none",
      "&:hover": {
        color: "#fff",
        textDecoration: "none",
        fontWeight: 700
      }
    }
  },
  appMenuItemsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "220px",
    padding: "28px 0px 28px 30px",
    rowGap: "14px",
    background: "linear-gradient(297deg, #5BC4C0 0%, #084D6D 100%)",
    "& a": {
      fontSize: "17px",
      color: "#fff",
      textDecoration: "none",
      fontWeight: 600,
      "&:hover": {
        color: "#fff",
        textDecoration: "none",
        fontWeight: 700
      }
    }
  },
  appMenuItemsContainerCollapsed: {
    borderRadius: "0px 6px 6px 0px",
    width: "170px",
    background: "linear-gradient(171deg, #5BC4C0 0%, #084D6D 80%)"
  }
};
