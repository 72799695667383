import React from 'react';

export default function EditJobToggleLeftSVG() {
return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="body_1" width="19" height="19" viewBox="0 0 23 23">
<g transform="matrix(1.5833334 0 0 1.5833334 0 0)">
    <path d="M10.2562 12.0001L1.74378 12.0001C 0.782174 12.0001 0 11.2178 0 10.2564L0 10.2564L0 1.74378C 0 0.782315 0.782315 0 1.74378 0L1.74378 0L10.2562 0C 11.2178 0 12 0.782315 12 1.74378L12 1.74378L12 10.2562C 12 11.2178 11.2177 12 10.2562 12L10.2562 12L10.2562 12.0001zM1.74378 1.05547C 1.36423 1.05547 1.05547 1.36423 1.05547 1.74378L1.05547 1.74378L1.05547 10.2562C 1.05547 10.6358 1.36423 10.9445 1.74378 10.9445L1.74378 10.9445L10.2562 10.9445C 10.6358 10.9445 10.9445 10.6358 10.9445 10.2562L10.9445 10.2562L10.9445 1.74378C 10.9445 1.36423 10.6358 1.05547 10.2562 1.05547L10.2562 1.05547L1.74378 1.05547z" stroke="none" fill="#000000" fillRule="nonzero" />
    <path d="M8.43218 12.0001C 8.14073 12.0001 7.90444 11.7639 7.90444 11.4724L7.90444 11.4724L7.90444 0.527735C 7.90444 0.236285 8.14073 0 8.43218 0C 8.72363 0 8.95991 0.236285 8.95991 0.527735L8.95991 0.527735L8.95991 11.4724C 8.95991 11.7639 8.72363 12.0001 8.43218 12.0001zM5.392 8.35187C 5.2569 8.35187 5.12194 8.30036 5.01879 8.19735L5.01879 8.19735L3.19465 6.37321C 3.09572 6.27428 3.04013 6.14003 3.04013 6C 3.04013 5.85997 3.09572 5.72586 3.19465 5.62679L3.19465 5.62679L5.01879 3.80265C 5.22481 3.59662 5.55905 3.59662 5.76507 3.80265C 5.9711 4.00868 5.9711 4.34291 5.76507 4.54894L5.76507 4.54894L4.31415 5.99986L5.76507 7.45078C 5.9711 7.65681 5.9711 7.99104 5.76507 8.19707C 5.66206 8.30008 5.52696 8.35159 5.39186 8.35159L5.39186 8.35159L5.392 8.35187z" stroke="none" fill="#000000" fillRule="nonzero" />
</g>
</svg>
)
}
