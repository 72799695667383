import React, { Component } from 'react';
import T from 'prop-types';

export default class ScoutFilter extends Component {
  static propTypes = {
    csvSubmit: T.func, // Will add a 'Export CSV' button that calls this function on click
    filterSubmit: T.func,
    title: T.string
  }

  constructor (props) {
    super(props)

    this.filterFields   = this.filterFields.bind(this)
    this.localSubmit   = this.localSubmit.bind(this);
  }

  filterFields() {
    let out = []
    for (var i = 0; i < filters.length ; i++) {
      out.push(
        <li key={i}>
          {filters[i]}
        </li>)
    }
    return (
      {out}
    )
  }

  localSubmit(event) {
    event.preventDefault();
    this.props.filterSubmit()
  }

  render() {
    const {filters} = this.props

    return(
      <div className='filter'>
        <link rel="stylesheet" media="screen" href="/assets/filters.self.css?body=1"/>
        <h2>Filter {this.props.title}</h2>
        <form onSubmit={this.localSubmit}>
          <ol>
            {filters}
          </ol>
          <div className="submit">
            <input type="submit" name="commit" value="Filter"/>
            {
              this.props.csvSubmit && (
                <input type="button" name="export_csv" value="Export CSV" onClick={this.props.csvSubmit} className='export-csv'/>
              )
            }
          </div>
        </form>
      </div>
    )
  }
}
