import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { classes } from '../styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { theme } from '../../../../ThemeContext/ThemeObject';

const autoRatingOptions = [1, 2, 3, 4, 5];

const SelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: '24px'
  },
  '& .MuiInputBase-input': {
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    color: '#333333',
    border: '1px solid #DDDDDD',
    fontSize: '16px',
    padding: '6px 10px',
    margin: '3px 0px',
    fontFamily: 'Source Sans Pro, sans-serif',
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  '& .MuiInputBase-input:focus': {
    backgroundColor: '#ffffff',
    borderRadius: '6px'
  },
  '& .MuiSvgIcon-root': {
    color: '#AAAAAA',
    transition: 'all 0.16s ease-in-out'
  }
}));

const menuItemStyle = {
  fontFamily: 'Source Sans Pro, sans-serif',
  color: '#333333',
  maxHeight: '200px'
};

export default function SortableChoice({
  id,
  choice,
  index,
  handleChoiceInput,
  handleSelectRating,
  handleRankInput,
  handleSelectRankValue,
  onRemoveChoice,
  editingQuestion,
  rateableSwitch,
  confidentialSwitch,
  autoRating,
  userPermissions,
  fieldType
}: {
  id: string;
  choice: any;
  index: number;
  handleChoiceInput: any;
  handleSelectRating: any;
  handleRankInput: any;
  handleSelectRankValue: any;
  onRemoveChoice: any;
  editingQuestion: any;
  rateableSwitch: boolean;
  confidentialSwitch: boolean;
  autoRating: boolean;
  userPermissions: any;
  fieldType: string;
}) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <Box key={index} sx={classes.choice} style={style} ref={setNodeRef} {...attributes}>
      <IconButton ref={setActivatorNodeRef} {...listeners}>
        <DragIndicatorIcon sx={{ fontSize: 18 }} />
      </IconButton>
      <FormTextField
        dataTestId="choice-input"
        value={choice.name}
        onChange={(e) => handleChoiceInput(e, index)}
        required={true}
        styles={{ width: '150px' }}
      />
      {editingQuestion &&
        rateableSwitch &&
        autoRating &&
        userPermissions?.['Job Options']?.['Auto Rating'] &&
        ['Checkbox', 'Radio Button'].includes(fieldType) && (
          <Box sx={{ marginLeft: '25px', display: 'flex', alignItems: 'center' }}>
            <Select
              sx={{ width: '60px' }}
              value={choice.rating}
              onChange={(e) => handleSelectRating(e.target.value, index)}
              input={<SelectInput />}
            >
              {autoRatingOptions.map((option, index) => (
                <MenuItem key={index} value={option} sx={menuItemStyle}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ marginLeft: '10px', fontSize: '14px' }}>Default rating</Box>
          </Box>
        )}
      {editingQuestion &&
        rateableSwitch &&
        autoRating &&
        userPermissions?.['Job Options']?.['Auto Rating'] &&
        ['Rank'].includes(fieldType) && (
          <Box sx={{ marginLeft: '25px', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ margin: '0 5px 0px 0px', fontSize: '14px' }}>If rank is greater than</Box>
            <FormTextField
              value={choice.rank}
              onChange={(e) => handleRankInput(e.target.value, index)}
              styles={{ width: '60px' }}
            />
            <Box sx={{ margin: '0px 5px', fontSize: '14px' }}>candidate gets</Box>
            <Select
              sx={{ width: '60px' }}
              value={choice.rank_value}
              onChange={(e) => handleSelectRankValue(e.target.value, index)}
              input={<SelectInput />}
            >
              {[0, 1].map((option, index) => (
                <MenuItem key={index} value={option} sx={menuItemStyle}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      <IconButton onClick={() => onRemoveChoice(index)} sx={{ marginLeft: '35px' }}>
        <DeleteIcon sx={{ color: theme.palette.error.main, fontSize: 18 }} />
      </IconButton>
    </Box>
  );
}
