import React, { useState, useCallback, Dispatch, useEffect } from 'react';
import { Box, OutlinedInput } from '@mui/material';
import DynamicFooter from '../../SharedComponents/Footers/DynamicFooter';
import { placeholderText, felixMessageText, isMultiLineInput } from '../config';
import { AIStudioAction, AIStudioState } from '../reducer';
import { convertPreferredSkills } from '../config';
import { classes } from '../styles';

const OptionalInput = ({
  dispatch,
  aiStudioState
}: {
  dispatch: Dispatch<AIStudioAction>;
  aiStudioState: AIStudioState;
}) => {
  const [inputValue, setInputValue] = useState('');
  const { optionalInputType, jobDetails, currentStep } = aiStudioState;

  const handleBackClick = () => {
    dispatch({ type: 'SET_CURRENT_STEP', payload: 1 });
  };

  const handleDoneClick = useCallback(() => {
    dispatch({
      type: 'SET_JOB_DETAILS',
      payload: inputValue,
      fieldName: optionalInputType
    });
    dispatch({ type: 'SET_CURRENT_STEP', payload: 1 });
  }, [optionalInputType, dispatch, inputValue]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && e.shiftKey) {
        handleDoneClick();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleDoneClick]);

  useEffect(() => {
    if (currentStep !== 2 && !jobDetails[optionalInputType as keyof typeof jobDetails]) return;
    setInputValue(jobDetails[optionalInputType as keyof typeof jobDetails]);
  }, [currentStep, jobDetails, optionalInputType]);

  return (
    <Box sx={classes.descriptionContainer}>
      <Box sx={classes.genericContainer}>
        <Box sx={classes.fieldHeader}>
          {
            convertPreferredSkills[aiStudioState.aiStudioFrenchEnabled ? 'french' : 'english'][
              optionalInputType
            ]
          }
        </Box>
        <OutlinedInput
          id="option-field-input"
          sx={{
            ...classes.fieldInput,
            ...(isMultiLineInput[optionalInputType as keyof typeof isMultiLineInput] && {
              height: '142px',
              padding: '0px 16px 4px 16px'
            })
          }}
          placeholder={
            placeholderText[aiStudioState.aiStudioFrenchEnabled ? 'french' : 'english'][
              optionalInputType
            ]
          }
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          multiline={isMultiLineInput[optionalInputType as keyof typeof isMultiLineInput]}
          rows={isMultiLineInput[optionalInputType as keyof typeof isMultiLineInput] ? 5 : 1}
        />
      </Box>
      <DynamicFooter
        handleSecondaryButtonClick={handleBackClick}
        handlePrimaryButtonClick={handleDoneClick}
        primaryButtonText={aiStudioState.aiStudioFrenchEnabled ? 'Terminé' : 'Done'}
        secondaryButtonText={aiStudioState.aiStudioFrenchEnabled ? 'Retour' : 'Back'}
        felixMessage={
          felixMessageText[optionalInputType as keyof typeof felixMessageText][
            aiStudioState.aiStudioFrenchEnabled ? 'french' : 'english'
          ]
        }
        felixMessageMaxWidth="450px"
      />
    </Box>
  );
};

export default OptionalInput;
