"use strict";
import { theme } from "../ThemeContext/ThemeObject";
export function getStatusColor(priority) {
  switch (priority) {
    case "Sent":
      return [theme.palette.success.main, theme.palette.opaque.green];
    case "Failed":
      return [theme.palette.error.main, theme.palette.opaque.red];
    case "Delivered":
      return [theme.palette.secondary.medium, theme.palette.opaque.blue];
    case "Not Yet Delivered":
      return [theme.palette.secondary.yellow, theme.palette.opaque.yellow];
    case "Opened":
      return [theme.palette.secondary.purple, theme.palette.opaque.purple];
    case "Clicked":
      return [theme.palette.secondary.pink, theme.palette.opaque.pink];
    case "Bounced":
      return [theme.palette.error.main, theme.palette.opaque.red];
    case "Unsubscribed":
      return [theme.palette.secondary.darkBlue, theme.palette.opaque.darkBlue];
    default:
      return [theme.palette.success.main, theme.palette.opaque.green];
  }
}
