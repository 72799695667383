import React, { FC, useState } from 'react';
import {
  Box,
  Chip,
  Paper,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { styles } from './styles';

const MAX_CHARACTER = 11;

interface StyledTagList {
  tags: string[];
  customHeight?: string;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffff',
    boxShadow: 4,
    margin: 0,
    fontSize: '13px'
  }
}));

export const TagList: FC<StyledTagList> = ({ tags, customHeight }) => {
  const firstElement = tags[0];
  const [isMouseEnter, setIsMounterEnter] = useState(false);

  const handleMouseEnter = (): void => {
    const hideTooltip = tags.length <= 1 && firstElement.length <= MAX_CHARACTER;
    if (hideTooltip) return;
    setIsMounterEnter(true);
  };

  const handleMouseLeave = (): void => setIsMounterEnter(false);

  return (
    <StyledTooltip
      placement="bottom-start"
      title={
        <Paper sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }} elevation={2}>
          {tags.map((chip, index) => (
            <Chip key={index} label={chip} style={{ margin: '4px 0', ...styles.label }} />
          ))}
        </Paper>
      }
      open={isMouseEnter}
    >
      <Stack
        direction="row"
        alignItems="center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={styles.chipContainer}
      >
        <Chip
          label={
            firstElement.length > MAX_CHARACTER
              ? firstElement.slice(0, MAX_CHARACTER) + '…'
              : firstElement
          }
          style={{ marginRight: '5px', zIndex: 1, maxWidth: '132px', ...styles.label }}
        />
        {tags.length > 1 && (
          <Box sx={styles.tagContent}>
            <Box sx={{ ...styles.styledChip, ...(customHeight && { height: customHeight }) }}></Box>
            <Typography sx={{ ...styles.label, ...styles.styledChipLabel }}>
              {' '}
              {`+${tags.length - 1}`}
            </Typography>
          </Box>
        )}
      </Stack>
    </StyledTooltip>
  );
};
