import React from 'react';
import { Box, TableHead, TableRow, TableCell, Checkbox, TableSortLabel } from '@mui/material';
import { TableHeader } from '../types';
import { visuallyHidden } from '@mui/utils';
import { classes } from './useStyles';

export default function CurrentJobsTableHeader(props: TableHeader) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    pinnedCount,
    onRequestSort,
    sortableColumns,
    handleScrollableEnter,
    handleScrollableExit,
    hovered,
    actionCellRef,
    titleCellRef,
    jobStatusFeature,
    userPermissions,
    isDashboard
  } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent) => {
    onRequestSort(event, property);
  };

  const tableHeadingAlignment = (name: string) => {
    switch (name) {
      case 'applications_count':
        return 'right';
      case 'state':
        return 'center';
      case 'status':
        return 'left';
      default:
        return 'left';
    }
  };

  const tableHeadingWidth = (name: string) => {
    switch (name) {
      case 'published_at':
        return '115px';
      case 'expires_at':
        return '115px';
      case 'created_at':
        return '115px';
      case 'title':
        return '200px';
      case 'applications_count':
        return '120px';
      default:
        return '150px';
    }
  };

  const tableHeadingTransform = (name: string) => {
    switch (name) {
      case 'state':
        return 'translateX(18px)';
      case 'status':
        return 'translateX(12px)';
      default:
        return '';
    }
  };

  return (
    <TableHead>
      <TableRow id="table-header" sx={classes.tableHeaderRow}>
        <TableCell
          padding="checkbox"
          key="checkbox"
          sx={{
            position: 'sticky',
            left: 0,
            width: '60px !important',
            maxWidth: '60px',
            background: 'white',
            zIndex: '4'
          }}
        >
          <Checkbox
            id="select-all-checkbox"
            data-testid="select-all-checkbox-[CurrentJobsTableHeader]"
            sx={{
              color: '#aaa !important',
              '&.Mui-checked': {
                color: '#15C7C0 !important'
              }
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount + pinnedCount}
            checked={rowCount > 0 && numSelected === rowCount + pinnedCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all jobs'
            }}
          />
        </TableCell>
        <TableCell
          sx={{
            position: 'sticky',
            left: '60px',
            width: '50px !important',
            maxWidth: '50px',
            background: 'white',
            zIndex: '4'
          }}
        ></TableCell>
        {sortableColumns.map(
          (headCell, i) =>
            headCell.enabled &&
            !(!jobStatusFeature && headCell.id === 'status') &&
            (headCell.id === 'recruitment_ad_user'
              ? userPermissions?.recruitment_team_enabled
              : true) &&
            (headCell.id === 'entity' ? userPermissions?.Jobs?.['Show Job Sub-Entity'] : true) && (
              <TableCell
                key={headCell.id}
                align={'left'}
                padding={'normal'}
                id={`${headCell.id}-header`}
                ref={headCell.id === 'title' ? titleCellRef : null}
                sortDirection={orderBy === headCell.id ? (order as 'asc' | 'desc') : false}
                onMouseEnter={
                  headCell.id !== 'title' && headCell.id !== 'reference'
                    ? () => handleScrollableEnter()
                    : null
                }
                onMouseLeave={
                  headCell.id !== 'title' && headCell.id !== 'reference'
                    ? () => handleScrollableExit()
                    : null
                }
                sx={{
                  ...(i === 0
                    ? {
                        position: 'sticky',
                        left: '110px',
                        maxWidth: '96px',
                        width: '96px',
                        background: 'white',
                        zIndex: '3'
                      }
                    : {
                        position: i === 1 ? 'sticky' : 'relative',
                        left: i === 1 ? '206px' : 'auto',
                        minWidth: tableHeadingWidth(headCell.id),
                        background: 'white',
                        zIndex: i === 1 ? '2' : headCell.id === 'applications_count' ? '1' : '0',
                        textAlign: tableHeadingAlignment(headCell.id),
                        transform: tableHeadingTransform(headCell.id),
                        paddingLeft: headCell.id === 'state' ? '35px' : '',
                        '& span': (headCell.id === 'status' || headCell.id === 'notes') && {
                          '&:hover': {
                            color: '#084d6d !important',
                            cursor: 'default'
                          }
                        }
                      }),
                  ...(headCell.id === 'title'
                    ? {
                        ...classes.titleHeaderCell,
                        ...(hovered ? classes.cellAnimate : {})
                      }
                    : {})
                }}
              >
                <TableSortLabel
                  id={`${headCell.id}-sort-label`}
                  data-testid={`${headCell.id}-sort-label`}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? (order as 'asc' | 'desc') : 'asc'}
                  onClick={
                    headCell.id !== 'status' && headCell.id !== 'notes'
                      ? createSortHandler(headCell.id)
                      : null
                  }
                  hideSortIcon={headCell.id === 'status' || headCell.id === 'notes'}
                >
                  {headCell.label}
                  {headCell.id !== 'status' &&
                  headCell.id !== 'notes' &&
                  orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        )}
        <TableCell
          padding="checkbox"
          key="actions"
          ref={actionCellRef}
          sx={{
            ...classes.actionsHeaderCell,
            ...(hovered ? classes.cellAnimate : {}),
            ...(isDashboard && {
              minWidth: '140px'
            })
          }}
        />
      </TableRow>
    </TableHead>
  );
}
