"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import moment from "moment";
export const InitialSystemReportsState = {
  snackbar: {
    message: "",
    state: "success"
  },
  filtersModal: {
    "Applications Report": false,
    "Application Timeline Report": false,
    "Approvals Report": false,
    "Aurion Report": false,
    "Candidates for eShots Report": false,
    "Caretakers Report": false,
    "Daily Applications Report": false,
    "Job Details Report": false,
    "Job State Report": false,
    "Placements Advanced Report": false,
    "Published Jobs Report": false,
    "Questions Report": false,
    "SMS Report": false,
    "User Events Report": false
  },
  openPreview: "",
  editTimePeriod: false,
  reportPeriod: {
    startDate: moment().subtract(1, "months").startOf("month").toDate(),
    endDate: moment().subtract(1, "months").endOf("month").toDate()
  },
  groupBy: "entities",
  selectedEntities: [],
  filters: {},
  viewFilters: false,
  jobStates: [],
  notExpired: false,
  countryState: "",
  applicationStatus: [],
  approvalState: "All",
  approvalForm: null,
  cognology: false,
  selectedJobs: [],
  selectedUser: null,
  includeSubEntities: false,
  statusId: null,
  selectedUsers: [],
  jobs: [],
  questions: [],
  scoutClient: false,
  drilldown: false,
  includeNewApplications: false,
  currentPage: 1,
  rowsPerPage: 10
};
export const SystemReportsReducer = (state, action) => {
  switch (action.type) {
    case "SET_SNACKBAR":
      return __spreadProps(__spreadValues({}, state), { snackbar: action.payload });
    case "FILTERS_MODAL":
      return __spreadProps(__spreadValues({}, state), { filtersModal: action.payload });
    case "OPEN_PREVIEW":
      return __spreadProps(__spreadValues({}, state), { openPreview: action.payload });
    case "EDIT_TIME_PERIOD":
      return __spreadProps(__spreadValues({}, state), { editTimePeriod: action.payload });
    case "REPORT_PERIOD":
      return __spreadProps(__spreadValues({}, state), {
        reportPeriod: { startDate: action.payload.startDate, endDate: action.payload.endDate },
        currentPage: action.payload.page,
        editTimePeriod: action.payload.editTimePeriod
      });
    case "GROUP_BY":
      return __spreadProps(__spreadValues({}, state), { groupBy: action.payload });
    case "SELECTED_ENTITIES":
      return __spreadProps(__spreadValues({}, state), { selectedEntities: action.payload });
    case "SET_FILTERS":
      return __spreadProps(__spreadValues({}, state), { filters: action.payload });
    case "VIEW_FILTERS":
      return __spreadProps(__spreadValues({}, state), { viewFilters: action.payload });
    case "JOB_STATES":
      return __spreadProps(__spreadValues({}, state), { jobStates: action.payload });
    case "NOT_EXPIRED":
      return __spreadProps(__spreadValues({}, state), { notExpired: action.payload });
    case "COUNTRY_STATE":
      return __spreadProps(__spreadValues({}, state), { countryState: action.payload });
    case "APPLICATION_STATUS":
      return __spreadProps(__spreadValues({}, state), { applicationStatus: action.payload });
    case "APPROVAL_STATE":
      return __spreadProps(__spreadValues({}, state), { approvalState: action.payload });
    case "APPROVAL_FORM":
      return __spreadProps(__spreadValues({}, state), { approvalForm: action.payload });
    case "COGNOLOGY":
      return __spreadProps(__spreadValues({}, state), { cognology: action.payload });
    case "SELECTED_JOBS":
      return __spreadProps(__spreadValues({}, state), { selectedJobs: action.payload });
    case "SELECTED_USER":
      return __spreadProps(__spreadValues({}, state), { selectedUser: action.payload });
    case "INCLUDE_SUB_ENTITIES":
      return __spreadProps(__spreadValues({}, state), { includeSubEntities: action.payload });
    case "RESET_STATES":
      return InitialSystemReportsState;
    case "DAILY_APPLICATIONS_REPORT":
      return __spreadValues(__spreadValues({}, state), action.payload);
    case "STATUS_ID":
      return __spreadProps(__spreadValues({}, state), { statusId: action.payload });
    case "SELECTED_USERS":
      return __spreadProps(__spreadValues({}, state), { selectedUsers: action.payload });
    case "JOBS":
      return __spreadProps(__spreadValues({}, state), { jobs: action.payload });
    case "QUESTIONS":
      return __spreadProps(__spreadValues({}, state), { questions: action.payload });
    case "SCOUT_CLIENT":
      return __spreadProps(__spreadValues({}, state), { scoutClient: action.payload });
    case "DRILLDOWN":
      return __spreadProps(__spreadValues({}, state), { drilldown: action.payload });
    case "INCLUDE_NEW_APPLICATIONS":
      return __spreadProps(__spreadValues({}, state), { includeNewApplications: action.payload });
    case "SET_PAGE":
      return __spreadProps(__spreadValues({}, state), { currentPage: action.payload });
    case "SET_LIMIT":
      return __spreadProps(__spreadValues({}, state), { rowsPerPage: action.payload });
  }
};
