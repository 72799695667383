"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
export const InitialScoutCalendarState = {
  apiKey: "",
  snackbar: {
    message: "",
    state: "success"
  },
  showCreateEventModal: false,
  showViewEventModal: false,
  createEventObject: {
    step: 1,
    eventType: null,
    maxCandidates: "1",
    recruiters: [],
    sessions: "1",
    breaks: "0",
    duration: "15",
    eventName: "",
    eventDescription: "",
    eventDate: /* @__PURE__ */ new Date(),
    eventId: 0,
    candidates: [],
    dates: [/* @__PURE__ */ new Date()],
    owner: ""
  },
  errorsMessage: {
    eventType: "",
    duration: "",
    maxCandidates: "",
    sessions: "",
    recruiters: "",
    eventName: "",
    time: "",
    date: ""
  },
  createMultipleEvents: false,
  weekStart: /* @__PURE__ */ new Date(),
  permissions: null
};
export const ScoutCalendarReducer = (state, action) => {
  switch (action.type) {
    case "SET_APIKEY":
      return __spreadProps(__spreadValues({}, state), { apiKey: action.payload });
    case "SET_SNACKBAR":
      return __spreadProps(__spreadValues({}, state), { snackbar: action.payload });
    case "SET_SHOW_CREATE_EVENT_MODAL":
      return __spreadProps(__spreadValues({}, state), { showCreateEventModal: action.payload });
    case "SET_SHOW_VIEW_EVENT_MODAL":
      return __spreadProps(__spreadValues({}, state), { showViewEventModal: action.payload });
    case "SET_CREATE_EVENT_OBJECT":
      return __spreadProps(__spreadValues({}, state), { createEventObject: action.payload });
    case "SET_ERROR_MESSAGE":
      return __spreadProps(__spreadValues({}, state), { errorsMessage: action.payload });
    case "CREATE_MULTIPLE_EVENTS":
      return __spreadProps(__spreadValues({}, state), { createMultipleEvents: action.payload });
    case "SET_WEEK_START":
      return __spreadProps(__spreadValues({}, state), { weekStart: action.payload });
    case "SET_PERMISSIONS":
      return __spreadProps(__spreadValues({}, state), { permissions: action.payload });
    case "RESET_STATE":
      return __spreadProps(__spreadValues({}, InitialScoutCalendarState), {
        snackbar: state.snackbar,
        weekStart: state.weekStart,
        apiKey: state.apiKey,
        permissions: state.permissions
      });
  }
};
