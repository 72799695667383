import React, { useState, Dispatch, SetStateAction } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Box, Stack, Switch, IconButton } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { classes } from './styles';
import QuestionFieldViewer from '../../Components/Utilities/QuestionFieldViewer';
import {
  DragIndicator as DragIndicatorIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ContentCopy as ContentCopyIcon
} from '@mui/icons-material';
import Api from '../API';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { StyledSnackbarProps } from '../../Components/CustomUIElements/StyledSnackbar';
import { IApplicantQuestion } from '../../Components/Utilities/QuestionFieldViewer';
import { IApprovalTemplate } from '../types';
import AvailabilityFieldQuestion from '../../Components/CustomUIElements/AvailabilityFieldQuestion';

export default function SortableItem({
  allQuestions,
  questions,
  setQuestions,
  getQuestions,
  index,
  approvalTemplateId,
  approvalTemplateData,
  setApprovalTemplateData,
  apiKey,
  setModalsOpen,
  editingQuestion,
  setEditingQuestion,
  setSnackbar,
  userPermissions
}: {
  allQuestions: IApplicantQuestion[];
  questions: IApplicantQuestion[];
  setQuestions: Dispatch<SetStateAction<IApplicantQuestion[]>>;
  getQuestions: any;
  index: number;
  approvalTemplateId: number;
  approvalTemplateData: IApprovalTemplate;
  setApprovalTemplateData: Dispatch<SetStateAction<IApprovalTemplate>>;
  apiKey: string;
  setModalsOpen: Dispatch<SetStateAction<Record<string, boolean>>>;
  editingQuestion: IApplicantQuestion;
  setEditingQuestion: Dispatch<SetStateAction<Record<IApplicantQuestion, null>>>;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
  userPermissions: Record<string, Record<string, boolean>>;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [tempEnabled, setTempEnabled] = useState(questions[index].enabled);
  const [tempRequired, setTempRequired] = useState(questions[index].required);
  const [tempConfidential, setTempConfidential] = useState(questions[index].confidential);
  const [tempIsFormTitle, setTempIsFormTitle] = useState(questions[index].is_approval_form_title);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: questions[index].id,
    data: {
      for_email_template: questions[index].for_email_template
    }
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    filter: isDragging ? 'brightness(75%)' : 'brightness(100%)',
    zIndex: isDragging ? 10 : 1
  };

  const deleteQuestion = async (url: string, questionId: number) => {
    setIsDeleting(true);
    try {
      const response = await Api.deleteQuestion(
        { 'X-api-authenticate': apiKey },
        questionId,
        approvalTemplateId
      );
      if (response.res.errors) {
        setSnackbar({
          message: 'You do not have right to delete this field',
          state: 'error'
        });
      } else {
        setSnackbar({
          message: 'Field has been deleted',
          state: 'success'
        });
      }
    } catch (error) {
      setSnackbar({
        message: `Field failed to delete: ${error}`,
        state: 'error'
      });
    } finally {
      setIsDeleting(false);
      setIsDialogOpen(false);
      getQuestions();
    }
  };

  const cloneQuestion = async (url: string, questionId: number) => {
    setIsCloning(true);
    try {
      const response = await Api.cloneQuestion(
        { 'X-api-authenticate': apiKey },
        questionId,
        approvalTemplateId
      );
    } catch (error) {
      setSnackbar({
        message: `Field failed to clone: ${error}`,
        state: 'error'
      });
    } finally {
      setIsCloning(false);
      setIsDialogOpen(false);
      getQuestions();
    }
  };

  const handleUpdateActions = async (
    event?: React.ChangeEvent<HTMLInputElement>,
    action: keyof (typeof questions)[0]
  ) => {
    action === 'enabled' && setTempEnabled(!tempEnabled);
    action === 'required' && setTempRequired(!tempRequired);
    action === 'confidential' && setTempConfidential(!tempConfidential);
    const fieldId = questions[index].id;
    try {
      const response = await fetch(
        `/api/v4/fields/${fieldId}?requisition_form_id=${approvalTemplateId}`,
        {
          method: 'PUT',
          headers: {
            'X-api-authenticate': apiKey,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            field: {
              [action]: event?.target.checked
            }
          })
        }
      ).then((response) => response.json());
      if (response.id) {
        setSnackbar({
          message: 'The change has been saved',
          state: 'success'
        });
        const newQuestions = [...allQuestions];
        const indexOfAllquestions = allQuestions.findIndex((item) => item.id === fieldId);
        newQuestions[indexOfAllquestions][action] = !allQuestions[indexOfAllquestions][action];

        if (action === 'enabled') {
          // If disabling a position title question, automatically set the next or the first question as the position title question
          if (!questions[index].enabled && questions[index].is_approval_form_title) {
            const newApprivalFormTitleQuestionId =
              questions[index + 1]?.id || questions[0]?.id || 0;
            handleSelectApprovalFormTitle(null, newApprivalFormTitleQuestionId, true);
          }
          // If enabled questions list is empty, automatically set the first enabled question as position title question
          else if (
            allQuestions?.filter((question) => question.enabled).length === 1 &&
            questions[index].enabled
          ) {
            const newApprivalFormTitleQuestionId = questions[index]?.id;
            newApprivalFormTitleQuestionId &&
              handleSelectApprovalFormTitle(null, newApprivalFormTitleQuestionId, true);
          } else if (
            // If enabling questions, update the position title index based on the enabled questions
            allQuestions?.filter((question) => question.enabled).length > 1 &&
            questions[index].enabled
          ) {
            const newApprivalFormTitleQuestionId =
              allQuestions
                ?.filter((question) => question.enabled)
                .find((question) => question.is_approval_form_title)?.id || 0;
            handleSelectApprovalFormTitle(null, newApprivalFormTitleQuestionId, true);
          } else if (!questions[index].enabled) {
            // If disabling other questions, we still need to update the position title index based on the rest enabled questions
            const newApprivalFormTitleQuestionId =
              questions.find((question) => question.is_approval_form_title)?.id || 0;
            handleSelectApprovalFormTitle(null, newApprivalFormTitleQuestionId, true);
          }
        }

        setTimeout(() => {
          setQuestions(newQuestions);
        }, 500);
      } else if (response.errors) {
        setSnackbar({
          message: `There was an error saving your changes. ${response.errors}`,
          state: 'error'
        });
        setTimeout(() => {
          action === 'enabled' && setTempEnabled(!event?.target.checked);
          action === 'required' && setTempRequired(!event?.target.checked);
          action === 'confidential' && setTempConfidential(!event?.target.checked);
        }, 500);
      }
    } catch (error) {
      setSnackbar({
        message: `There was an error saving your changes. ${error}`,
        state: 'error'
      });
      setTimeout(() => {
        action === 'enabled' && setTempEnabled(!event?.target.checked);
        action === 'required' && setTempRequired(!event?.target.checked);
        action === 'confidential' && setTempConfidential(!event?.target.checked);
      }, 500);
    }
  };

  const handleSelectApprovalFormTitle = async (
    event: React.ChangeEvent<HTMLInputElement> | null,
    questionId: number,
    manual?: boolean
  ) => {
    questions.map((question) => {
      if (question.id === questionId) {
        question.is_approval_form_title = manual || !!event?.target.checked;
      } else {
        question.is_approval_form_title = false;
      }
    });

    let questionIndex = allQuestions
      ?.filter((question) => question.enabled)
      .findIndex((question) => question.id === questionId);

    if (questionIndex === -1) {
      questionIndex = 0;
    }

    const checked = manual || event?.target.checked;

    setTempIsFormTitle(!tempIsFormTitle);
    const newTemplateData = { ...approvalTemplateData };
    newTemplateData.config.approval_form_title_position = checked ? questionIndex : null;
    setApprovalTemplateData(newTemplateData);

    try {
      await Api.updateApprovalTemplate(approvalTemplateId, {
        requisition_form: {
          approval_form_title_position: checked ? questionIndex : null
        }
      });
      setSnackbar({
        message: 'Approval template updated',
        state: 'success'
      });
    } catch (error) {
      setSnackbar({
        message: `Failed to update approval template, ${error}`,
        state: 'error'
      });
    }
  };

  const [dialogProps, setDialogProps] = useState({
    url: '',
    title: 'Permanently delete question?',
    description: '',
    buttonText: 'Delete',
    buttonCallback: deleteQuestion,
    callbackLoading: false,
    dialogId: 0
  });

  const handleDeleteQuestion = (questionId: number) => {
    setDialogProps((prev) => ({
      ...prev,
      url: `/api/v4/fields/${questionId}?requisition_form_id=${approvalTemplateId}`,
      title: 'Permanently delete question?',
      description: `Are you sure you want to delete this question? This action cannot be undone.`,
      buttonText: 'Delete',
      dialogId: questionId,
      buttonCallback: deleteQuestion
    }));
    setIsDialogOpen(true);
  };

  const handleEditQuestion = () => {
    setEditingQuestion({
      ...editingQuestion,
      enabled: questions[index].enabled,
      required: questions[index].required
    });
    questions[index].for_email_template
      ? setModalsOpen((prev) => ({ ...prev, newEmailTemplateField: true }))
      : setModalsOpen((prev) => ({ ...prev, newField: true }));
  };

  const handleCloneQuestion = (questionId: number) => {
    setDialogProps((prev) => ({
      ...prev,
      url: `/api/v4/fields/${questionId}/clone?requisition_form_id=${approvalTemplateId}`,
      title: 'Clone question',
      description: `Are you sure you want to clone this question?`,
      buttonText: 'Clone',
      dialogId: questionId,
      buttonCallback: cloneQuestion
    }));
    setIsDialogOpen(true);
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <Box key={questions[index].id}>
        {questions[index].type === 'JobReferenceLookupField' ? (
          <span />
        ) : (
          <Box sx={classes.questionsWrapper}>
            <Box sx={{ display: 'flex' }}>
              <DragIndicatorIcon
                sx={{ cursor: isDragging ? 'grabbing' : 'pointer', ...classes.dragHandle }}
                {...listeners}
                {...attributes}
              />
              <Box sx={{ marginRight: 'auto', ...classes.questionInput }}>
                <Box sx={{ height: '6px' }} />
                <Box sx={{ ...classes.modalFormLine, position: 'relative', minWidth: '300px' }}>
                  <QuestionFieldViewer question={questions[index]} draggable={true} />
                </Box>
              </Box>
              {!questions[index].for_email_template && (
                <Box
                  sx={{
                    ...classes.sortableItemSwitchContainer,
                    fontWeight: questions[index].enabled ? 'bold' : ''
                  }}
                >
                  <Box sx={{ marginTop: '12px' }}>
                    <Switch
                      sx={questions[index].enabled ? classes.switchActive : classes.switch}
                      checked={tempEnabled}
                      onChange={(event) => handleUpdateActions(event, 'enabled')}
                    />
                    <Stack sx={{ fontSize: '12px' }}>Enabled</Stack>
                  </Box>
                </Box>
              )}
              {!questions[index].for_email_template && (
                <Box
                  sx={{
                    ...classes.sortableItemSwitchContainer,
                    fontWeight: questions[index].required ? 'bold' : ''
                  }}
                >
                  <Box sx={{ marginTop: '12px' }}>
                    <Switch
                      sx={questions[index].required ? classes.switchActive : classes.switch}
                      checked={tempRequired}
                      onChange={(event) => handleUpdateActions(event, 'required')}
                      disabled={!questions[index].editable}
                    />
                    <Stack sx={{ fontSize: '12px' }}>Required</Stack>
                  </Box>
                </Box>
              )}
              {!questions[index].for_email_template && (
                <Box
                  sx={{
                    ...classes.sortableItemSwitchContainer,
                    fontWeight: questions[index].confidential ? 'bold' : ''
                  }}
                >
                  <Box sx={{ marginTop: '12px' }}>
                    <Switch
                      sx={questions[index].confidential ? classes.switchActive : classes.switch}
                      checked={tempConfidential}
                      onChange={(event) => handleUpdateActions(event, 'confidential')}
                      disabled={!questions[index].editable}
                    />
                    <Stack sx={{ fontSize: '12px' }}>Confidential</Stack>
                  </Box>
                </Box>
              )}
              {Number(approvalTemplateData?.config?.hide_position_title) === 1 &&
                questions[index].enabled &&
                !questions[index].for_email_template && (
                  <Box
                    sx={{
                      ...classes.sortableItemSwitchContainer,
                      fontWeight: questions[index].is_approval_form_title ? 'bold' : ''
                    }}
                  >
                    <Box sx={{ marginTop: '12px' }}>
                      <Switch
                        sx={
                          questions[index].is_approval_form_title
                            ? classes.switchActive
                            : classes.switch
                        }
                        checked={!!questions[index].is_approval_form_title}
                        onChange={(event) =>
                          handleSelectApprovalFormTitle(event, questions[index].id)
                        }
                        disabled={!!questions[index].is_approval_form_title}
                      />
                      <Stack sx={{ fontSize: '12px' }}>Approval Form Title</Stack>
                    </Box>
                  </Box>
                )}
              <Box sx={{ padding: '0 6px', minWidth: '120px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                    marginTop: '-8px'
                  }}
                >
                  {questions[index].editable && !questions[index].answered && (
                    <IconButton onClick={() => handleEditQuestion()}>
                      <EditIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                  {questions[index].editable && (
                    <IconButton onClick={() => handleCloneQuestion(questions[index].id)}>
                      <ContentCopyIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                  {questions[index].deletable && (
                    <IconButton
                      onClick={() => {
                        handleDeleteQuestion(questions[index].id);
                      }}
                    >
                      <DeleteIcon sx={{ color: '#E37D7A', fontSize: 16 }} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
            {questions[index].type === 'AvailabilityField' && (
              <AvailabilityFieldQuestion question={questions[index]} />
            )}
            {tempConfidential && (
              <Box sx={{ padding: '0 6px', color: '#838383', fontSize: '12px' }}>
                <span style={{ fontWeight: 'bold' }}>Confidential Setting Enabled:</span> For DE&I
                compliance, only authorised users (HR Admins), can view responses. Hiring Managers
                will not have access.
              </Box>
            )}
          </Box>
        )}
      </Box>
      <GenericDialog
        url={dialogProps.url}
        title={dialogProps.title}
        description={dialogProps.description}
        buttonText={dialogProps.buttonText}
        buttonCallback={dialogProps.buttonCallback}
        callbackLoading={isDeleting || isCloning}
        isDialogOpen={isDialogOpen}
        setDialogOpen={setIsDialogOpen}
        dialogId={dialogProps.dialogId}
      />
    </Box>
  );
}
