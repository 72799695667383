import React, { Dispatch } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TCandidate } from '../types';
import { TableActionItemAction } from '../../NewUI/Components/GenericTable/types';

const EngageSearchTableActionItems = ({
  item,
  dispatch
}: {
  item: TCandidate;
  dispatch: Dispatch<TableActionItemAction<TCandidate>>;
}) => {
  return (
    <>
      <Tooltip placement="top" title="Add note">
        <IconButton
          className="add-candidate-note-button"
          onClick={(event) => {
            dispatch({
              type: 'SELECT_ACTION_ITEM',
              payload: { item: item, anchorEl: event.currentTarget, name: 'addNote' }
            });
          }}
        >
          <NoteAddIcon />
        </IconButton>
      </Tooltip>
      {item.attachment_url?.length > 0 && (
        <Tooltip placement="top" title="View attachments">
          <IconButton
            className="view-attachments-list"
            onClick={(event) => {
              dispatch({
                type: 'SELECT_ACTION_ITEM',
                payload: { item: item, anchorEl: event.currentTarget, name: 'viewAttachments' }
              });
            }}
          >
            <AttachFileIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip placement="top" title="More options" arrow>
        <IconButton
          onClick={(e) => {
            dispatch({
              type: 'SELECT_ACTION_ITEM',
              payload: { item: item, anchorEl: e.currentTarget, name: 'moreOptions' }
            });
          }}
          className="engage-action-dropdown-button"
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default EngageSearchTableActionItems;
