import React, { Dispatch, SetStateAction, useRef } from 'react';
import Box from '@mui/material/Box';
import { IJob } from '../../../Job/types';
import { classes } from '../styles';
import { MultilineFormTextField } from '../../../Components/CustomUIElements/FormTextField';

export default function GoogleScript({
  apiKey,
  job,
  googleScriptTabJobData,
  setGoogleScriptTabJobData,
  setIsEdited
}: { apiKey: string, job: IJob, setIsEdited: Dispatch<SetStateAction<boolean>> }) {  
  const templateRef = useRef<HTMLInputElement>(null);

  return (
    <Box sx={classes.tabContent}>
      <Box id="template-title" sx={classes.headerContent}>
        Google conversion script
      </Box>
      <Box sx={classes.bodyContainer}>
        <Box sx={classes.modalFormLine}>
          <MultilineFormTextField
            value={googleScriptTabJobData?.google_conversion_script?.value}
            onChange={(e) => {
              setGoogleScriptTabJobData((prev) => ({
                ...prev,
                google_conversion_script: { ...prev.google_conversion_script, value: e.target.value },
              }))
              setIsEdited(true);
            }}
            styles={{ maxWidth: '900px' }}
            rows={14}
            innerRef={templateRef}
            required={false}
          />
        </Box>
      </Box>
      <Box sx={classes.hintText}>
        This script will be embedded on the "Application Success" page. It is strongly recommended you perform a test application after changing this script, to test for faulty HTML/Javascript.
      </Box>
    </Box>
  );
}
