import React, { useState, useEffect, useCallback, useRef } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TableSkeleton from '../TableSkeleton';
import AdTable from './AdTable';
import Grid from '@mui/material/Grid';
import { classes } from '../styles';

const BASE_URL = window.location.origin;

export default function CouncilJobsAds({
  apiKey,
  jobId
}: {
  apiKey: string;
  jobId: string | null;
}) {
  const [ads, setAds] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const getAds = useCallback(async () => {
    setLoading(true);
    const url = jobId ? `/api/v4/council_jobs/jobs/${jobId}/job_ads?limit=100` : '';
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setAds(response.ads);
      setMessage(response.errors);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [apiKey, jobId]);

  useEffect(() => {
    getAds();
  }, [getAds]);

  return (
    <Box sx={classes.containerOuter}>
      <Box sx={classes.container}>
        <Box sx={classes.banner}>
          <Box id="counciljobs-logo" sx={{ width: '140px !important' }}></Box>
          {jobId && (
            <Button
              disableElevation
              sx={{ ...classes.buttonSecondary, ...classes.newAdButton }}
              id="CouncilJobsNewAdMultiposterBtn"
              onClick={() => {
                window.location.href = `${BASE_URL}/admin/jobs/${jobId}/job_ads/new`;
              }}
            >
              New Ad
            </Button>
          )}
        </Box>
        <Grid
          container
          sx={jobId ? { marginTop: '20px', marginBottom: '12px', overflow: 'auto' } : {}}
        >
          {loading ? (
            <TableSkeleton />
          ) : (
            ads && <AdTable jobAds={ads} setAds={setAds} apiKey={apiKey} />
          )}
        </Grid>
      </Box>
    </Box>
  );
}
