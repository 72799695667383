import React from 'react';
import { CandidatesState } from './types';
import { TCandidate } from './types';
import { TableCell } from '@mui/material';
import { classes } from '../../NewUI/Components/GenericTable/styles';
import CandidateIcon from '../Candidate/CandidateIcon';

const CandidatesTableRows = ({
  column,
  item,
  tableHovered
}: {
  column: CandidatesState['candidatesTableState']['columns'][number];
  item: TCandidate;
  tableHovered: boolean;
}) => {
  if (!column.enabled) return null;
  const Link = ({ attribute }: { attribute: 'id' | 'lastname' | 'firstname' }) => (
    <a href={`/admin/candidates/${item.id}`} id={`candidate-${attribute}-link`}>
      {item[attribute] || '-'}
    </a>
  );
  switch (column.id) {
    case 'id':
      return (
        <TableCell
          key={item.id + column.id}
          sx={{
            ...classes.sticky,
            ...classes.idTableCell,
            minWidth: '100px',
            left: '0px'
          }}
        >
          <CandidateIcon
            existingCandidate={item?.existing_candidate}
            sx={{
              fontSize: '1rem',
              margin: '-3px 7px 0 0'
            }}
          />
          <Link attribute="id" />
        </TableCell>
      );
    case 'lastname':
      return (
        <TableCell
          key={item.id + column.id}
          sx={{
            ...classes.sticky,
            ...classes.itemTitleTableCell,
            ...(tableHovered && classes.scrollShadowVisible),
            left: '100px',
            minWidth: '125px'
          }}
        >
          <Link attribute="lastname" />
        </TableCell>
      );
    case 'firstname':
      return (
        <TableCell
          key={item.id + column.id}
          sx={{ ...classes.itemTitleTableCell, minWidth: '125px' }}
        >
          <Link attribute="firstname" />
        </TableCell>
      );
    case 'email':
      return <TableCell key={item.id + column.id}>{item.email || '-'}</TableCell>;
    case 'location':
      return (
        <TableCell key={item.id + column.id}>
          {item.address_details?.suburb || '-'}
          {item.address_details?.state?.abbreviation &&
            `, ${item.address_details.state.abbreviation}`}
        </TableCell>
      );
    case 'applications':
      return <TableCell key={item.id + column.id}>{item.number_of_applications || '-'}</TableCell>;
    default:
      return <TableCell></TableCell>;
  }
};

export default CandidatesTableRows;
