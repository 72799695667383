import React from 'react';
import { OutlinedInput } from '@mui/material';
import { classes } from '../styles';

const PositionTitleInput = ({
  positionTitle,
  aiStudioFrenchEnabled,
  onChange,
  onBlur
}: {
  positionTitle: string;
  aiStudioFrenchEnabled: boolean;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
}) => {
  return (
    <OutlinedInput
      id="position-title"
      sx={classes.fieldInput}
      placeholder={aiStudioFrenchEnabled ? 'ex. Designer UX' : 'eg. UX Designer'}
      value={positionTitle}
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => {
        onBlur ? onBlur(positionTitle) : null;
      }}
    />
  );
};

export default PositionTitleInput;
