import React from 'react';
import { IAttachment } from '../types';
import { classes } from '../../Components/Modals/styles';

export default function ImagePreview({
  selectedAttachment,
  isLoadingPreview,
  setIsLoadingPreview
}: {
  selectedAttachment: IAttachment;
  isLoadingPreview: boolean;
  setIsLoadingPreview: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const BASE_URL = window.location.origin;
  return (
    <>
      <img
        src={`${BASE_URL}/admin/assets/${selectedAttachment.id}`}
        alt={selectedAttachment.attached_file_name}
        style={{
          ...(classes.imagePreview as React.CSSProperties),
          opacity: isLoadingPreview ? 0 : 1
        }}
        onLoad={() => setIsLoadingPreview(false)}
      />
    </>
  );
}
