import React, { useRef, useState, useEffect, useReducer } from 'react';
import { Box, Button, IconButton, Skeleton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { ArrowCircleLeft as ArrowCircleLeftIcon } from '@mui/icons-material';
import { styles } from './styles';
import { classes } from '../SmartForms/Questions/styles';
import Api from './API';
import { useQuery } from '@tanstack/react-query';
import StyledSnackbar from '../Components/CustomUIElements/StyledSnackbar';
import { InitialSmartFormsState, SmartFormsReducer } from './reducer';
import QuestionFieldGenerator from '../Components/Utilities/QuestionFieldGenerator';
import { IError } from './types';

export default function SmartFormPreview({ formId }: { formId: number }) {
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [SmartFormsState, dispatch] = useReducer(SmartFormsReducer, InitialSmartFormsState);
  const headerRef = useRef<HTMLDivElement>(null);

  const { data: smartForm, isLoading: loadingSmartForm } = useQuery({
    queryKey: ['smartForm'],
    queryFn: async () => {
      const { res } = await Api.getSmartForm(formId);
      return res;
    },
    onError: (error: IError) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error fetching smart form data, ${error.errors.join('. ')}`,
          state: 'error'
        }
      })
  });

  const navigateToEditSmartform = () => {
    window.location.href = `/admin/forms/${formId}/fields`;
  };

  useEffect(() => {
    if (!headerRef.current) return;
    setIndicatorWidth(headerRef.current.clientWidth);
  }, []);

  return (
    <Box sx={styles.smartFormsPageContainer}>
      {loadingSmartForm ? (
        <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
      ) : (
        <Box sx={{ ...styles.smartFormsPageHeader, marginBottom: 3 }}>
          <Typography variant="h1">{smartForm.name}</Typography>
        </Box>
      )}
      <Box sx={{ ...styles.tabsContainer, padding: '0px 16px' }}>
        <Tabs
          value={0}
          variant="scrollable"
          scrollButtons={false}
          aria-label="Approvals"
          sx={{
            ...styles.tabs,
            '& .MuiTabs-indicator': { backgroundColor: '#5BC4BF', maxWidth: indicatorWidth + 'px' }
          }}
        >
          <Tab ref={headerRef} label="Smart form preview" sx={{ padding: 0 }} disableRipple />
        </Tabs>
      </Box>
      <Box sx={{ ...styles.backToApprovalFormsButton, padding: '0px 16px' }}>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
          <Stack
            id="smart-form-preview-back-button"
            flexDirection="row"
            alignItems="center"
            onClick={() => {
              window.location.href = '/admin/forms';
            }}
          >
            <IconButton sx={{ ...styles.iconGrey, paddingLeft: 0 }}>
              <ArrowCircleLeftIcon />
            </IconButton>
            <Box>Back to smart forms</Box>
          </Stack>
          <Stack>
            <Button
              id="edit-smart-form"
              sx={classes.settingButton}
              onClick={navigateToEditSmartform}
            >
              Edit form
            </Button>
          </Stack>
        </Stack>
      </Box>
      {loadingSmartForm ? (
        <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
      ) : (
        <>
          {smartForm.fields
            .filter((question) => question.enabled)
            .map((question) => (
              <Box sx={{ padding: '12px 16px' }} key={question.id}>
                <QuestionFieldGenerator disabled question={question} />
              </Box>
            ))}
        </>
      )}
      <StyledSnackbar
        message={SmartFormsState.snackbar.message}
        state={SmartFormsState.snackbar.state}
        setSnackbarState={() => {
          dispatch({ type: 'SET_SNACKBAR', payload: { message: '', state: 'success' } });
        }}
      />
    </Box>
  );
}
