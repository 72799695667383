import React from 'react'
import { Link } from 'react-router-dom';

export default class AdminIntegrationsIndex extends React.Component {
    renderIntegrations(integrations) {
        return (
            <div className="row row-cols-2 row-cols-sm-4">
                {
                    integrations.map(function (integration) {
                        const icon2x = integration.icons[0];
                        const icon3x = integration.icons[1];
                        return (
                            <div className="col mb-4" key={integration.id}>
                                <div className="card text-center h-100">
                                    <div className="card-image d-flex justify-content-center align-items-center border-bottom">
                                        {integration.icons.length > 0 ?
                                            <img
                                                src={icon2x}
                                                srcSet={`${icon2x} 2x, ${icon3x} 3x`}
                                                title={integration.title}
                                                alt={integration.title}
                                            />
                                            : integration.title
                                        }
                                    </div>
                                    <div className="card-body">
                                        <div className="card-title mb-0">{integration.title} </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    render() {
        const { integrations } = this.props;

        return (
            <div className="layer-content" >
                <div className="layer-container">
                    <div className="integrations-index">
                        <div className="layer-tabs">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <Link to="/admin" className="nav-link">
                                        <i className="material-icons">chevron_left</i>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/integrations" className="nav-link active">
                                        All Integrations
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {this.renderIntegrations(integrations)}
                    </div>
                </div>
            </div>
        );
    }
}
