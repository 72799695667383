import React, { useState, useContext } from 'react';
import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Button,
  Typography,
  Autocomplete,
  TextField,
  Backdrop
} from '@mui/material';
import { Close as CloseIcon, Info as InfoIcon } from '@mui/icons-material';
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from './styles';
import { useQuery } from '@tanstack/react-query';
import { ReportContext } from '../index';
import { StyledSnackbarProps } from '../../NewUI/Components/CustomUIElements/StyledSnackbar';

export const StatPanel = ({
  setSnackbar,
  type
}: {
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
  type: 'time_to_hire' | 'offer_accepted' | 'avg_application_per_job';
}) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [entity, setEntity] = useState<string>('Current entity only');
  const reportServiceApi = useContext(ReportContext);

  const metricsContent = {
    avg_application_per_job: {
      tooltip: 'This is calculated by averaging the number of applications across all of your jobs',
      metricInfo: 'Average number of applications per job'
    },
    offer_accepted: {
      tooltip:
        "This is calculated by the average percent that it takes an applicant with the 'offered' status moves to an 'accepted' status.",
      metricInfo: 'Offer acceptance rate'
    },
    time_to_hire: {
      tooltip:
        'This is calculated by the average amount of days, from the application date of an applicant to an offer accepted status',
      metricInfo: 'Average time to hire'
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: [type, entity],
    queryFn: async () => {
      const { data } = await reportServiceApi.dashboard.data(
        type,
        entity === 'Current entity only' ? undefined : true
      );
      return data[0];
    },
    onError: () =>
      setSnackbar({
        message: 'There was an error with retrieving the metrics data',
        state: 'error'
      })
  });

  return (
    <Grid sx={{ position: 'relative', minWidth: '300px' }} item xs={4}>
      {isLoading ? (
        <Skeleton variant="rectangular" height={225} />
      ) : (
        <Stack sx={classes.overviewMetricBoxes}>
          <Box>
            {openTooltip ? (
              <Button sx={classes.IconButton} onClick={() => setOpenTooltip(false)}>
                <CloseIcon sx={classes.popUpIconsClose} />
              </Button>
            ) : (
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button sx={classes.IconButton} onClick={() => setOpenTooltip(true)}>
                  <InfoIcon sx={classes.popUpIconsInfo} />
                </Button>
                <Autocomplete
                  disableClearable
                  filterSelectedOptions
                  value={entity}
                  options={['Current entity only', 'Include sub entities']}
                  onChange={(_event, newValue) => {
                    setEntity(newValue);
                  }}
                  sx={{
                    ...sharedClasses.formAutocomplete,
                    minWidth: '145px'
                  }}
                  renderOption={(props, option) => (
                    <Typography
                      {...props}
                      sx={{ fontSize: '12px', fontFamily: 'Source Sans Pro', height: '8px' }}
                    >
                      {option}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        style: { height: '8px', fontSize: '12px', marginRight: 2 }
                      }}
                    />
                  )}
                />
              </Stack>
            )}
          </Box>
          {openTooltip ? (
            <Backdrop
              sx={classes.backDropContent}
              open={openTooltip}
              onClick={() => setOpenTooltip(false)}
            >
              {metricsContent[type].tooltip}
            </Backdrop>
          ) : (
            <Box sx={classes.overviewMetricContent}>
              <Box
                sx={{
                  ...classes.valueMetric,
                  color:
                    data?.current_month_average > 60
                      ? '#76D18F'
                      : data?.current_month_average > 49
                      ? '#B8E15F'
                      : '#D39C16'
                }}
              >
                {data?.current_month_average || '-'}
                {data?.current_month_average && type === 'offer_accepted' ? '%' : ''}
              </Box>
              <Box sx={classes.textMetric}>{metricsContent[type].metricInfo}</Box>
            </Box>
          )}
          {!openTooltip && (
            <Stack sx={{ flexDirection: 'row', justifyContent: 'end', paddingTop: 2 }}>
              <Box
                sx={{
                  color:
                    data?.difference > 0
                      ? '#76D18F'
                      : data?.difference === 0
                      ? '#D39C16'
                      : '#D6827D',
                  ...classes.percentageMetricText
                }}
              >
                {data?.difference > 0 && '+'}
                {data?.difference || '-'}
              </Box>
              from last month
            </Stack>
          )}
        </Stack>
      )}
    </Grid>
  );
};
