import React, { useState } from 'react';
import { Box, Button, Slider, IconButton, CircularProgress, Stack } from '@mui/material';
import FormSelectField from '../../../Components/CustomUIElements/FormSelectField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { classes } from '../styles';
import { IRatingBasedProps } from '../../types';
import { useDebouncedCallback } from 'use-debounce';
import Switch from '@mui/material/Switch';

export default function RatingBased({
  apiKey,
  jobId,
  setSnackbar,
  autoStatuses,
  setAutoStatuses,
  applicationStatuses
}: IRatingBasedProps) {
  const [isLoading, setIsLoading] = useState({
    new: false,
    delete: -1
  });

  const handleRatingChange = async (value: number[], index: number) => {
    const tempAutoStatus = [...autoStatuses];
    const prevAutoStatus = [...autoStatuses];
    tempAutoStatus[index].rating_range = {
      min: value[0],
      max: value[1]
    };
    setAutoStatuses(tempAutoStatus);
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/auto_status_rating_based/${autoStatuses[index].id}`,
        {
          method: 'PUT',
          headers: {
            'X-api-authenticate': apiKey,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            auto_status_rating_based: {
              status_id: autoStatuses[index].status.id,
              min_rating: value[0],
              max_rating: value[1]
            }
          })
        }
      );
      if (!response.ok) {
        throw new Error('Error updating auto status');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error updating auto status',
        state: 'error'
      });
      setAutoStatuses(prevAutoStatus);
    }
  };

  const handleStatusChange = async (value: string | number, index: number) => {
    const tempAutoStatus = [...autoStatuses];
    const prevAutoStatus = [...autoStatuses];
    tempAutoStatus[index].status.id = parseInt(value as string);
    setAutoStatuses(tempAutoStatus);
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/auto_status_rating_based/${autoStatuses[index].id}`,
        {
          method: 'PUT',
          headers: {
            'X-api-authenticate': apiKey,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            auto_status_rating_based: {
              status_id: value,
              min_rating: autoStatuses[index].rating_range.min,
              max_rating: autoStatuses[index].rating_range.max
            }
          })
        }
      );
      if (!response.ok) {
        throw new Error('Error updating auto status');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error updating auto status',
        state: 'error'
      });
      setAutoStatuses(prevAutoStatus);
    }
  };

  const handleNewAutoStatus = async () => {
    setIsLoading((prev) => ({ ...prev, new: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_status_rating_based`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          status_id: applicationStatuses[0].id,
          min_rating: 0,
          max_rating: 100
        })
      });
      const data = await response.json();
      if (response.ok) {
        setAutoStatuses((prev) => [...prev, data]);
      } else {
        throw new Error('Error creating new auto status');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error creating new auto status',
        state: 'error'
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, new: false }));
    }
  };

  const handleDeleteAutoStatus = async (index: number) => {
    setIsLoading((prev) => ({ ...prev, delete: index }));
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/auto_status_rating_based/${autoStatuses[index].id}`,
        {
          method: 'DELETE',
          headers: {
            'X-api-authenticate': apiKey,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.ok) {
        const autoStatusesCopy = [...autoStatuses];
        autoStatusesCopy.splice(index, 1);
        setAutoStatuses(autoStatusesCopy);
      } else {
        throw new Error('Error deleting auto status');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error deleting auto status',
        state: 'error'
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, delete: -1 }));
    }
  };

  const handleEnableStatus = async (index: number, id: number) => {
    const prevAutoStatuses = [...autoStatuses];
    const tempAutoStatus = [...autoStatuses];
    tempAutoStatus[index].enabled = !autoStatuses[index].enabled;
    setAutoStatuses(tempAutoStatus);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_status_rating_based/${id}`, {
        method: 'PUT',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          enabled: tempAutoStatus[index].enabled
        })
      });
      if (!response.ok) {
        setAutoStatuses(prevAutoStatuses);
        throw new Error('Error updating auto status');
      }
    } catch (error) {
      setSnackbar({
        message: 'There was an error saving your changes',
        state: 'error'
      });
    }
  };

  return (
    <Box sx={classes.autoStatusesContainer}>
      <Box sx={{ position: 'relative' }}>
        {autoStatuses.map((ratingBased, index) => (
          <Box sx={{ position: 'relative', display: 'flex' }} key={ratingBased.id}>
            <Box sx={classes.autoStatusesSubContainer} key={ratingBased.id}>
              <Box sx={classes.statusFormLine}>
                <Box component={'h4'}>If candidate rating is</Box>
              </Box>
              <Box sx={classes.statusFormLine}>
                <DebouncedSlider
                  initialValue={[ratingBased.rating_range.min, ratingBased.rating_range.max]}
                  onChange={(e, value) => handleRatingChange(value as number[], index)}
                />
              </Box>
              <Box sx={classes.statusFormLine}>
                <Box component={'h4'}>then set status to</Box>
                <FormSelectField
                  label=" "
                  options={applicationStatuses.map((status) => ({
                    value: status.id.toString(),
                    label: status.name
                  }))}
                  defaultValue={ratingBased.status.id ? ratingBased.status.id.toString() : ''}
                  onChange={(value) => handleStatusChange(value, index)}
                  styles={{ width: '290px' }}
                />
              </Box>
            </Box>
            <Box sx={{ ...classes.switchContainer, fontWeight: ratingBased.enabled ? 'bold' : '' }}>
              <Box sx={{ marginTop: '12px' }}>
                <Switch
                  sx={ratingBased.enabled ? classes.switchActive : classes.switch}
                  checked={ratingBased.enabled}
                  size="small"
                  onChange={() => handleEnableStatus(index, ratingBased.id)}
                />
                <Stack sx={{ marginTop: 1, fontSize: '12px' }}>Enabled</Stack>
              </Box>
            </Box>
            <IconButton
              data-testid="delete-rating-button"
              sx={classes.deleteButton}
              onClick={() => handleDeleteAutoStatus(index)}
              disabled={isLoading.delete === index}
            >
              {isLoading.delete === index ? <CircularProgress size={26} /> : <DeleteIcon />}
            </IconButton>
          </Box>
        ))}
        {autoStatuses && autoStatuses.length > 0 ? (
          <IconButton
            data-testid="add-rating-button"
            sx={classes.addNewStatusButton}
            onClick={handleNewAutoStatus}
            disabled={isLoading.new}
          >
            {isLoading.new ? <CircularProgress size={21} /> : <AddCircleIcon />}
          </IconButton>
        ) : (
          <Button
            data-testid="add-rating-button"
            startIcon={isLoading.new ? <CircularProgress size={26} /> : <AddCircleIcon />}
            onClick={handleNewAutoStatus}
            sx={classes.noStatusesAddButton}
          >
            Add new status
          </Button>
        )}
      </Box>
    </Box>
  );
}

function DebouncedSlider({
  initialValue,
  onChange
}: {
  initialValue: number[];
  onChange: (e: any, value: number | number[]) => void;
}) {
  const [value, setValue] = useState(initialValue);
  const debouncedOnChange = useDebouncedCallback((value) => {
    onChange(undefined, value);
  }, 500);

  const handleChange = (event, newValue: number[]) => {
    setValue(newValue);
    debouncedOnChange(newValue);
  };

  return (
    <Slider
      sx={classes.ratingSlider}
      getAriaLabel={() => 'Applicant rating slider'}
      value={value}
      onChange={handleChange}
      valueLabelDisplay="on"
      valueLabelFormat={(value: number) => `${value}%`}
      getAriaValueText={(value: number) => `${value}%`}
    />
  );
}
