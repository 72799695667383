"use strict";
const BASE_URL = window.location.origin;
import { stringify } from "query-string";
class Api {
  constructor() {
    // handleResponse = (response) => {
    //   if (response.ok) return response.text().then((data) => {
    //     resolve(data ? JSON.parse(data) : {})
    //   });
    //   return response.text().then((error) => {
    //     error.code = response.status;
    //     throw error;
    //   });
    // }
    this.get = (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}?${stringify(params || {})}`, {
      headers: this.getHeaders(headers)
    }).then((response) => {
      return response.text();
    }).then((data) => {
      return data ? JSON.parse(data) : {};
    });
    this.post = (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "POST",
      body: JSON.stringify(params)
    }).then((response) => {
      return response.text();
    }).then((data) => {
      return data ? JSON.parse(data) : {};
    });
    this.put = (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "PUT",
      body: JSON.stringify(params)
    }).then((response) => {
      return response.text();
    }).then((data) => {
      return data ? JSON.parse(data) : {};
    });
    this.postLogin = (params) => this.post(`/api/v4/login`, params);
    this.sendTwoFactor = () => this.post(`/api/v4/two_factor_auth/send_2fa_code`);
    this.postTwoFactor = (param) => this.get(`/api/v4/two_factor_auth/authorize`, param);
    this.postResetPassword = (params) => this.post(`/api/v4/reset_password`, params);
    this.postSSOLogin = (params) => this.post(`/api/v4/sso`, params);
    this.putUpdatePassword = (token, params) => this.put(`/api/v4/update_password/${token}`, params);
  }
  getHeaders(headers) {
    return Object.assign({}, {
      "Content-Type": "application/json"
    }, headers);
  }
}
export default new Api();
