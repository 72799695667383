import { Box } from '@mui/material';
import React from 'react';
import MultiSelect, { IOptionType } from '../../CustomUIElements/MultiSelect';

const MultiSelectFilter = ({
  handleFilterChange,
  selectOptions,
  name,
  label,
  subLabel,
  value,
  freeInput = false,
  disabled = false,
  displayLabel = false
}: {
  handleFilterChange: (name: string, value: IOptionType[]) => void;
  selectOptions: IOptionType[];
  name: string;
  label: string;
  subLabel?: string;
  value: IOptionType[];
  freeInput?: boolean;
  disabled?: boolean;
  displayLabel?: boolean;
}) => {
  return (
    <Box sx={filterSelectGroupStyle} id={`${name}-filter`}>
      {label}
      {subLabel && <Box sx={filterSelectSubLabelStyle}>{subLabel}</Box>}
      <MultiSelect
        handleFilterChange={(name, value) => handleFilterChange(name, value as IOptionType[])}
        selectOptions={selectOptions}
        name={name}
        filters={value}
        freeInput={freeInput}
        disabled={disabled}
        displayLabel={displayLabel}
      />
    </Box>
  );
};

const filterSelectGroupStyle = {
  fontFamily: 'Source Sans Pro',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#084D6D'
};

const filterSelectSubLabelStyle = {
  fontSize: '12px',
  fontWeight: '400'
};

export default MultiSelectFilter;
