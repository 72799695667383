import React  from 'react';
import moment from 'moment';
export default class ScoutTrainingsTable extends React.Component {
    
    constructor( props ) {
        super( props );
    }
    
    static propTypes = {
       collection: PropTypes.array.required
    }
    render() {
        return (
           <div>
              <table>
                <thead>
                    <tr>
                        <th>Course</th>
                        <th>Details</th>
                        <th>Provider</th>
                        <th>Status</th>
                        <th>Commencement Date</th>
                        <th>Estimated Completion</th>
                    </tr>
                </thead>
                <tbody>
                { this.props.collection.map((course, key)=> (
                    <tr key={ key }>
                        <td>{ course.name }</td>
                        <td>{ course.details }</td>
                        <td>{ course.provider }</td>
                        <td>{ course.status }</td>
                        <td>{ course.commencemented_at ? moment(course.commencemented_at).format("D/MM/YYYY") : '' }</td>
                        <td>{ course.completed_at ? moment(course.completed_at).format("D/MM/YYYY") : '' }</td>
                    </tr>
                ))}
                </tbody>
            </table>
  
            </div>
        );
      }
} 
