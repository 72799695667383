import React, { useState } from 'react';
import { Box, Modal, Button, Autocomplete, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from '../../ScheduledReportModal/styles';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import GenericDialog from '../../../NewUI/Components/Modals/GenericDialog';

type Props = {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  reportId: number;
};

const dummyData: string[] = ['Bilbo bagins', 'Steven bagins'];

const TransferReportModal = ({ modalOpen, setModalOpen }: Props) => {
  const [shareValue, setShareValue] = useState<string>('');
  const [isTransferDialogOpen, setIsTransferDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{ ...sharedClasses.modalBody, maxWidth: '700px', minHeight: '350px' }}>
          <CloseIcon onClick={() => setModalOpen(false)} sx={sharedClasses.closeIcon} />
          <Box sx={sharedClasses.sectionContent}>
            <Box sx={sharedClasses.sectionHeader}>Transfer ownership</Box>
            <Box sx={{ ...classes.formContent, marginTop: 4 }} role="form">
              <Autocomplete
                id="transfer-report-users"
                options={dummyData}
                value={shareValue}
                onChange={(_event, newValue) =>
                  newValue ? setShareValue(newValue) : setShareValue('')
                }
                sx={sharedClasses.formAutocomplete}
                renderInput={(params) => (
                  <TextField
                    label={'Transfer to'}
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    placeholder={shareValue.length ? '' : 'Please select'}
                    sx={{ input: { '&::placeholder': { opacity: 1 } } }}
                  />
                )}
              />
            </Box>
            <Box sx={sharedClasses.modalFooter}>
              <Button
                id="transfer-report-button"
                variant="contained"
                sx={sharedClasses.genericButton}
                onClick={() => {
                  setIsTransferDialogOpen(true);
                  setModalOpen(false);
                }}
              >
                Transfer
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <GenericDialog
        isDialogOpen={isTransferDialogOpen}
        setDialogOpen={setIsTransferDialogOpen}
        title={`Are you sure you want to transfer ownership of this report?`}
        description="This will be removed from your Saved Reports and you will no longer have access to this report."
        buttonCallback={() => setIsTransferDialogOpen(false)}
        callbackLoading={false}
        buttonText="Transfer"
        url=""
      />
    </>
  );
};

export default TransferReportModal;
