import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NewAutoSMSReply from './NewAutoSMSReply';
import ImmediateChangesNotice from '../shared/ImmediateChangesNotice';
import SkeletonTable from '../../shared/SkeletonTable';
import { classes } from '../styles';
import { IStatusTransitionsProps, IAutoSmsReply } from '../../types';
import { IEventType } from '../../../../ScoutCalendar/types';
import { IApplicationStatus } from '../../../Job/types';
import { immutableRemove } from '../../../Components/Utilities/immutableRemove';
import { useV4Api } from '../../../hooks/useV4Api';

export default function AutoSMSReply({ apiKey, jobId, setSnackbar }: IStatusTransitionsProps) {
  const [loading, setLoading] = useState({
    autoReplies: true,
    applicationStatuses: true,
    templatesList: true,
    eventTypes: true
  });
  const [isDeleting, setIsDeleting] = useState(-1);
  const [autoReplies, setAutoReplies] = useState<IAutoSmsReply[]>([]);
  const [applicationStatuses, setApplicationStatuses] = useState<IApplicationStatus[]>([]);
  const [templates, setTemplates] = useState([]);
  const [eventTypes, setEventTypes] = useState<IEventType[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [replyIndex, setReplyIndex] = useState(0);
  const { apiGet, apiDelete } = useV4Api({ apiKey });

  const getAutoReplies = useCallback(async () => {
    setLoading((prev) => ({ ...prev, autoReplies: true }));
    try {
      const { auto_sms_replies } = await apiGet(`/jobs/${jobId}/auto_sms_replies`);
      setAutoReplies(auto_sms_replies);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching auto SMS replies',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, autoReplies: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getApplicationStatuses = useCallback(async () => {
    setLoading((prev) => ({ ...prev, applicationStatuses: true }));
    try {
      const { job_application_statuses } = await apiGet(`/jobs/${jobId}/job_application_statuses`);
      setApplicationStatuses(job_application_statuses);
    } catch (error: any) {
      setSnackbar({
        message: error.message || 'Error fetching application statuses',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, applicationStatuses: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getTemplatesAndPlaceholders = useCallback(async () => {
    setLoading((prev) => ({ ...prev, templatesList: true }));
    try {
      const { sms_templates } = await apiGet(`/jobs/${jobId}/sms_templates?kind=custom`);
      setTemplates(sms_templates);
    } catch (error: any) {
      setSnackbar({
        message: 'Error fetching templates',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, templatesList: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getEventTypes = useCallback(async () => {
    setLoading((prev) => ({ ...prev, eventTypes: true }));
    try {
      const response = await fetch(`/api/v4/calendar/event_types`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { event_types } = await response.json();
      setEventTypes(event_types);
    } catch (error) {
      setSnackbar({
        message: 'Error fetching event types',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, eventTypes: false }));
    }
  }, [apiKey, setSnackbar]);

  const removeAutoReplies = async (index: number) => {
    setIsDeleting(index);
    try {
      const response = await apiDelete(`/jobs/${jobId}/auto_sms_replies/${autoReplies[index].id}`);
      if (response.ok) {
        setSnackbar({
          message: 'Auto SMS reply deleted successfully',
          state: 'success'
        });
        setAutoReplies(immutableRemove(autoReplies, index));
      } else {
        throw new Error('Error deleting auto SMS reply');
      }
    } catch (error: any) {
      setSnackbar({
        message: error.message || 'Error deleting auto SMS reply',
        state: 'error'
      });
    } finally {
      setIsDeleting(-1);
    }
  };

  const handleOpenModal = (index: number) => {
    setReplyIndex(index);
    setModalOpen(true);
  };

  useEffect(() => {
    getAutoReplies();
    getApplicationStatuses();
    getTemplatesAndPlaceholders();
    getEventTypes();
  }, [getApplicationStatuses, getAutoReplies, getTemplatesAndPlaceholders, getEventTypes]);

  return (
    <Box sx={classes.automationContainer}>
      <Box sx={classes.titleAndButtonContainer}>
        <h2>Auto SMS reply</h2>
        <Button
          id="new-auto-sms-reply-button"
          data-testid="new-auto-sms-reply-button"
          sx={classes.newButton}
          onClick={() => handleOpenModal(-1)}
        >
          New auto SMS reply
        </Button>
      </Box>
      <ImmediateChangesNotice />
      {loading.autoReplies || (autoReplies && autoReplies.length > 0) ? (
        <TableContainer sx={classes.tableContainer}>
          <Table sx={classes.table} aria-label="auto sms replies table">
            <TableHead>
              <TableRow>
                <TableCell>Trigger status</TableCell>
                <TableCell>SMS template</TableCell>
                <TableCell align="right">{/*Action column*/}</TableCell>
              </TableRow>
            </TableHead>
            {loading.autoReplies || loading.applicationStatuses
              ? <SkeletonTable rows={5} cols={3} />
              : (
                <TableBody>
                  {autoReplies.map((reply, index) => (
                    <TableRow key={reply.id}>
                      <TableCell>
                        {applicationStatuses.find((status) => status.id === reply.trigger_status_id)
                          ?.name ?? '-'}
                      </TableCell>
                      <TableCell>{reply.sms_template_name || '-'}</TableCell>
                      <TableCell sx={classes.tableActions}>
                        <IconButton
                          id="auto-sms-reply-edit-button"
                          data-testid="auto-sms-reply-edit-button"
                          sx={classes.tableEditButton}
                          onClick={() => handleOpenModal(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          id="auto-sms-reply-delete-button"
                          data-testid="auto-sms-reply-delete-button"
                          sx={classes.tableDeleteButton}
                          onClick={() => removeAutoReplies(index)}
                        >
                          {isDeleting === index ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            <DeleteIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <Box sx={classes.noAutoForwarders}>
          No auto replies configured. Click on the button above to create one.
        </Box>
      )}
      {!loading.autoReplies && !loading.applicationStatuses && (
        <NewAutoSMSReply
          jobId={jobId}
          apiKey={apiKey}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          autoReplies={autoReplies}
          setAutoReplies={setAutoReplies}
          applicationStatuses={applicationStatuses || []}
          smsTemplates={templates}
          replyIndex={modalOpen ? replyIndex : -1}
          isLoadingTemplateList={loading.templatesList}
          eventTypes={eventTypes}
          isLoadingEventTypes={loading.eventTypes}
          setSnackbar={setSnackbar}
        />
      )}
    </Box>
  );
}
