"use strict";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  dragArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    rowGap: "18px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#939393",
    backgroundColor: "#F3F3F3",
    outline: "3px solid #FFFFFF",
    height: "340px",
    width: "100%",
    borderRadius: "8px",
    marginBottom: "30px",
    transition: "all 0.3s ease-in-out"
  },
  dragAreaOver: {
    backgroundColor: "#ECECEC",
    outline: "3px solid #084D6D"
  },
  selectFilesButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "120px",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  selectFilesGoogleButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "220px",
    textTransform: "none",
    color: "#5BC4C0",
    backgroundColor: "#5BC4C01F",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#5BC4BF35",
      boxShadow: "none"
    }
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "500px"
  },
  dragAreaSingle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    color: "#939393",
    backgroundColor: "#F3F3F3",
    outline: "2px solid #FFFFFF",
    height: "90px",
    width: "320px",
    borderRadius: "8px",
    padding: "8px",
    transition: "all 0.3s ease-in-out"
  },
  dragAreaOverSingle: {
    backgroundColor: "#ECECEC",
    outline: "2px solid #084D6D"
  },
  dragAreaWithGoogleDrive: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    rowGap: "12px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#939393",
    backgroundColor: "#F3F3F3",
    outline: "3px solid #FFFFFF",
    height: "150px",
    width: "100%",
    borderRadius: "8px",
    transition: "all 0.3s ease-in-out"
  },
  selectFilesButtonSingle: {
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontWeight: "600",
    width: "90px",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  fileIconContainer: {
    display: "flex",
    alignItems: "center",
    width: "90%"
  },
  fileIcon: {
    width: "50px",
    height: "50px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    backgroundColor: "#084D6D10",
    "& svg": {
      color: theme.palette.primary.main
    }
  },
  fileName: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    fontWeight: "bold",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "196px"
  },
  fileSize: {
    color: "#999999"
  },
  formDateWrapper: {
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  formDatePickerStyles: {
    width: "284px",
    height: "32px",
    borderRadius: "6px",
    margin: "3px",
    "& button": {
      transform: "translate(-4px, -1.5px)",
      outline: "none"
    }
  },
  inputLabel: {
    color: "#666666 !important",
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Source Sans Pro, sans-serif",
    paddingLeft: "4px",
    overflowWrap: "anywhere",
    "& span": {
      color: theme.palette.error.main,
      marginLeft: "2px"
    }
  },
  groupLabel: {
    color: "#666666 !important",
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Source Sans Pro, sans-serif",
    marginBottom: "6px",
    overflowWrap: "anywhere",
    "& span": {
      color: theme.palette.error.main,
      marginLeft: "2px"
    }
  },
  questionLabel: {
    color: "#000 !important",
    fontSize: "14px",
    fontFamily: "Source Sans Pro, sans-serif",
    overflowWrap: "anywhere"
  },
  questionInputLabel: {
    color: "#838383 !important",
    fontSize: "13px",
    fontFamily: "Source Sans Pro, sans-serif",
    "& span": {
      color: theme.palette.error.main,
      marginLeft: "2px"
    }
  },
  questionDateWrapper: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "& div": {
      paddingBottom: "8px"
    }
  },
  questionsRankInput: {
    "& div": {
      marginLeft: "0"
    }
  },
  lookUpButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "rgb(91,196,191)",
    background: "rgb(91,196,191,0.12)",
    height: "41px",
    marginLeft: "16px",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#5BC4BF",
      color: "#FFFFFF",
      boxShadow: "none"
    }
  }
};
export const useStyles = makeStyles({
  textEditor: {
    borderRadius: 6,
    border: "1px solid #DDDDDD",
    overflow: "hidden"
  },
  textEditorToolbar: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    background: "#DDDDDD",
    padding: "2px",
    "& .rdw-inline-wrapper": {
      display: "flex",
      flexDirection: "row"
    },
    "& .rdw-list-wrapper": {
      display: "flex",
      flexDirection: "row"
    },
    "& .rdw-text-align-wrapper": {
      display: "flex",
      flexDirection: "row"
    },
    "& .rdw-link-modal-btn, .rdw-image-modal-btn": {
      border: "1px solid #CCCCCC",
      borderRadius: "6px",
      background: "none",
      outline: "none",
      padding: "3px 7px"
    },
    "& .rdw-link-wrapper": {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      "& .rdw-link-modal": {
        display: "grid",
        position: "absolute",
        padding: "10px 15px",
        marginTop: "1.75rem",
        background: "#DDDDDD",
        borderRadius: "6px",
        boxShadow: "2px 2px 5px #DDDDDD",
        width: "220px",
        "& label": {
          padding: "10px 0"
        },
        "& button": {
          marginRight: "1rem"
        },
        "& #openLinkInNewWindow": {
          marginRight: "0.5rem"
        }
      }
    },
    "& .rdw-image-wrapper": {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      "& .rdw-image-modal": {
        background: "#DDDDDD",
        padding: "10px 15px",
        borderRadius: "6px",
        boxShadow: "2px 2px 5px #DDDDDD",
        display: "grid",
        position: "absolute",
        marginTop: "1.75rem",
        width: "220px",
        "& label": {
          padding: "10px 0"
        },
        "& button": {
          marginRight: "1rem"
        },
        "& .rdw-image-modal-url-input": {
          width: "calc(100% - 20px)"
        },
        "& .rdw-image-modal-size": {
          marginTop: 10,
          marginBottom: 10
        },
        "& .rdw-image-modal-size-input": {
          width: 60
        }
      }
    },
    "& .rdw-colorpicker-modal-header": {
      display: "none"
    },
    "& .rdw-colorpicker-wrapper": {
      position: "relative"
    },
    "& .rdw-colorpicker-modal-options": {
      display: "flex",
      flexWrap: "wrap"
    },
    "& .rdw-colorpicker-modal": {
      position: "absolute",
      background: "#DDDDDD",
      padding: "5px",
      borderRadius: "0px 0px 6px 6px",
      maxHeight: "206px",
      width: "138px",
      overflowY: "auto",
      boxShadow: "2px 2px 5px #DDDDDD"
    },
    "& .rdw-colorpicker-option": {
      "& span": {
        width: "100%",
        height: "100%",
        borderRadius: "6px"
      }
    },
    "& .rdw-option-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "28px",
      height: "28px",
      margin: "2px",
      borderRadius: "4px",
      backgroundColor: "transparent",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#CCCCCC"
      }
    },
    "& .rdw-option-active ": {
      backgroundColor: "darkgrey"
    },
    "& .rdw-history-wrapper": {
      display: "flex"
    },
    "& .rdw-block-wrapper, .rdw-fontsize-wrapper, .rdw-fontfamily-wrapper": {
      padding: "4px",
      margin: "0px 2px",
      cursor: "pointer"
    },
    "& .rdw-block-dropdown, .rdw-fontsize-dropdown, .rdw-fontfamily-wrapper": {
      position: "relative",
      "& ul": {
        position: "absolute",
        background: "#DDDDDD",
        padding: "5px 0px",
        borderRadius: "6px",
        maxHeight: "200px",
        overflowY: "auto",
        boxShadow: "2px 2px 5px #DDDDDD",
        "& li": {
          padding: "3px 14px",
          cursor: "pointer",
          whiteSpace: "nowrap",
          "&:hover": {
            backgroundColor: "#CCCCCC"
          }
        }
      }
    },
    "& .rdw-dropdownoption-active": {
      backgroundColor: "lightgrey",
      "&:hover": {
        backgroundColor: "lightgrey"
      }
    }
  },
  textEditorBody: {
    all: "revert",
    "& h1, h2, h3, h4, h5, h6": {
      all: "revert"
    },
    minHeight: 250,
    padding: 10,
    "&:hover": {
      cursor: "text"
    },
    "& ul": {
      paddingLeft: 20,
      listStyle: "disc"
    },
    "& ol": {
      paddingLeft: 20,
      listStyle: "auto"
    },
    "& .rdw-center-aligned-block *": {
      textAlign: "center !important"
    },
    "& .rdw-left-aligned-block *": {
      textAlign: "left !important"
    },
    "& .rdw-right-aligned-block *": {
      textAlign: "right !important"
    },
    "& .rdw-image-alignment-options-popup": {
      display: "none !important"
    }
  }
});
