import React, { useState, Dispatch, SetStateAction, useEffect, useCallback } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import Box from '@mui/material/Box';
import { CSS } from '@dnd-kit/utilities';
import { classes } from './styles';
import QuestionFieldViewer from '../../Components/Utilities/QuestionFieldViewer';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Switch from '@mui/material/Switch';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Api from '../../Job/API';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { StyledSnackbarProps } from '../../Components/CustomUIElements/StyledSnackbar';
import { IApplicantQuestion } from '../types';

export default function SortableItem({
  questions,
  setQuestions,
  getQuestions,
  index,
  jobId,
  applicationQuestionTemplateId,
  apiKey,
  setModalsOpen,
  editingQuestion,
  setEditingQuestion,
  setSnackbar,
} : {
  questions: IApplicantQuestion[];
  setQuestions: Dispatch<SetStateAction<IApplicantQuestion[]>>;
  getQuestions: any;
  index: number;
  jobId: number;
  applicationQuestionTemplateId: number;
  apiKey: string;
  setModalsOpen: Dispatch<SetStateAction<Record<string, boolean>>>;
  editingQuestion: IApplicantQuestion;
  setEditingQuestion: Dispatch<SetStateAction<Record<IApplicantQuestion, null>>>;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteQuestion = async (url: string, questionId: number) => {
    setIsDeleting(true);
    try {
      const response = await Api.deleteHRQuestion({ 'X-api-authenticate': apiKey }, questionId, jobId, applicationQuestionTemplateId);
      if (response.errors) {
        setSnackbar({
          message:
            'You do not have right to delete this field',
          state: 'error'
        });
      }
      else {
        setSnackbar({
          message:
            'Field has been deleted',
          state: 'success'
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
      setIsDialogOpen(false);
      getQuestions();
    }
  };

  const handleUpdateActions = async (action: keyof typeof questions[0]) => {
    const newQuestions = [...questions];
    newQuestions[index][action] = !questions[index][action];
    setQuestions(newQuestions);
    try {
      await fetch(`/api/v4/fields/${questions[index].id}?application_question_template_id=${applicationQuestionTemplateId}`, {
        method: 'PUT',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          field: {
            [action]: newQuestions[index][action],
          }
        })
      }).then((response) => {
        response.json()})
    } catch (error) {
      setSnackbar({
        message: 'There was an error saving your changes',
        state: 'error'
      });
    }
  };

  const [dialogProps, setDialogProps] = useState({
    url: '',
    title: 'Permanently delete question?',
    description: '',
    buttonText: 'Delete',
    buttonCallback: deleteQuestion,
    callbackLoading: false,
    dialogId: 0
  });

  const handleDeleteQuestion = (questionId: number) => {
    setDialogProps((prev) => ({
      ...prev,
      url: `/api/v4/fields/${questionId}?application_question_template_id=${applicationQuestionTemplateId}`,
      description: `Are you sure you want to delete this question? This action cannot be undone.`,
      dialogId: questionId,
      buttonCallback: deleteQuestion
    }));
    setIsDialogOpen(true);
  };

  const handleEditQuestion = () => {
    setEditingQuestion({...editingQuestion, enabled: questions[index].enabled, required: questions[index].required, rateable: questions[index].rateable});
    setModalsOpen((prev) => ({ ...prev, newField: true }));
  }

  return (
    <Box >
      <Box key={questions[index].id}>
        {questions[index].type === "AvailabilityField" || questions[index].type === "CsvDataLookupField" || questions[index].type === "JobReferenceLookupField"
          ? <span />
          : <Box sx={classes.questionsWrapper} >
            <Box sx={{ marginRight: 'auto', ...classes.questionInput }}>
              <Box sx={{height: '6px'}}></Box>
              <Box sx={{ ...classes.modalFormLine, position: 'relative' }}>
                <QuestionFieldViewer question={questions[index]} draggable={true} />
              </Box>
            </Box>
            <Box sx={{ padding: '0 14px', marginBlock: 'auto' }}>
              <Switch
                sx={questions[index].enabled ? classes.switchActive : classes.switch}
                checked={questions[index].enabled}
                onChange={() => handleUpdateActions('enabled')}
                disabled={(questions.map((questions) => (questions.enabled)).includes(true) && !questions[index].enabled)}
              />
            </Box>
            <Box sx={{ padding: '0 6px', display: 'grid', minWidth: '76px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: '-8px' }}>
                {questions[index].editable && 
                  <IconButton
                    onClick={() => handleEditQuestion()}
                  >
                    <EditIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                }
                {questions[index].deletable && 
                  <IconButton
                    onClick={() => { handleDeleteQuestion(questions[index].id) }}
                  >
                    <DeleteIcon sx={{ color: '#E37D7A', fontSize: 16 }} />
                  </IconButton>
                }
              </Box>
            </Box>
          </Box>
        }
      </Box>
      <GenericDialog
        url={dialogProps.url}
        title={dialogProps.title}
        description={dialogProps.description}
        buttonText={dialogProps.buttonText}
        buttonCallback={dialogProps.buttonCallback}
        callbackLoading={isDeleting}
        isDialogOpen={isDialogOpen}
        setDialogOpen={setIsDialogOpen}
        dialogId={dialogProps.dialogId}
      />
    </Box>
  );
}
