import React, { Dispatch, SetStateAction } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { styles } from './styles';
import FormCheckboxGroup from '../../Components/CustomUIElements/FormCheckboxGroup';
import { approvalTemplateFormOptions } from '../config';
import { IApprovalTemplateOptions } from '../types';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';

export default function AdvancedTab({
  options,
  setOptions,
  emailTemplates,
  approvalTemplateEmail,
  setApprovalTemplateEmail,
  enableEmailTemplateFields
}: {
  options: IApprovalTemplateOptions | null;
  setOptions: Dispatch<SetStateAction<IApprovalTemplateOptions | null>>;
  emailTemplates: [string, Array<{ id: number; name: string }>][];
  approvalTemplateEmail: number | null;
  setApprovalTemplateEmail: Dispatch<SetStateAction<number | null>>;
  enableEmailTemplateFields?: boolean;
}) {
  const templates = emailTemplates.reduce(
    (acc, currVal) => {
      const entity: string = currVal[0];
      const arrayOfTemplates: { id: number; name: string }[] = currVal[1];
      return [...acc, ...arrayOfTemplates.map((template) => ({ ...template, entity }))];
    },
    [] as { id: number; name: string; entity: string }[]
  );

  const updatedApprovalTemplateFormOptions = enableEmailTemplateFields
    ? [
        ...approvalTemplateFormOptions,
        {
          id: 'enable_declaration_of_conflict_of_interest',
          name: 'Enable Declaration of Conflict of Interest'
        }
      ]
    : approvalTemplateFormOptions;

  return (
    <Box sx={styles.tabContent}>
      <Box sx={styles.tabContentTitle}>Advanced settings</Box>
      <Box sx={styles.tabContentBody}>
        <FormCheckboxGroup
          options={updatedApprovalTemplateFormOptions}
          defaultValues={options}
          onChange={(value: IApprovalTemplateOptions) => setOptions(value)}
          color={'#838383'}
        />
      </Box>
      <Box sx={{ ...styles.tabContentBody, marginTop: 3 }}>
        <Autocomplete
          disablePortal
          options={templates}
          groupBy={(option) => option.entity}
          getOptionLabel={(option) => option.name || ''}
          value={templates.find((e) => e.id === approvalTemplateEmail) || null}
          onChange={(event, value) =>
            value ? setApprovalTemplateEmail(value.id) : setApprovalTemplateEmail(null)
          }
          sx={{ width: '290px', ...sharedClasses.formAutocomplete }}
          ListboxProps={{
            style: {
              fontFamily: 'Source Sans Pro, sans-serif',
              color: '#333333',
              maxHeight: '200px'
            }
          }}
          renderInput={(params) => (
            <TextField
              sx={{ input: { '&::placeholder': { opacity: 1 } } }}
              {...params}
              label="Selected default email template"
              InputLabelProps={{ shrink: true }}
              placeholder="(Use default template)"
            />
          )}
        />
      </Box>
    </Box>
  );
}
