var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { Component } from "react";
function paginatedDataSource(DataSourcedComponent, options) {
  class PaginatedDataSource extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        filters: options.default_filter || {},
        sort_key: options.sort_key || "",
        sort_state: options.sort_state || {},
        pagination: null,
        page: this.parseBrowserHash().page
      };
      this.fetchCSV = this.fetchCSV.bind(this);
      this.fetchData = this.fetchData.bind(this);
      this.handlePagination = this.handlePagination.bind(this);
      this.buildSortableHeader = this.buildSortableHeader.bind(this);
      this.handleSort = this.handleSort.bind(this);
      this.csvSubmit = this.csvSubmit.bind(this);
      this.filterSubmit = this.filterSubmit.bind(this);
      this.getFilters = this.getFilters.bind(this);
      this.filterCallback = this.filterCallback.bind(this);
    }
    parseBrowserHash() {
      return window.location.hash.split("&").map(function(e) {
        return e.replace("#", "").split("=");
      }).reduce(function(p, c) {
        p[c[0]] = c[1];
        return p;
      }, {});
    }
    componentDidMount() {
      this.fetchData();
    }
    handleSort(event) {
      const sort_key = $(event.currentTarget).data("field");
      const { sort_state } = this.state;
      sort_state[sort_key] = sort_state[sort_key] === "desc" ? "asc" : "desc";
      this.setState({ sort_key, sort_state }, () => {
        this.fetchData();
      });
    }
    filterCallback(value_object) {
      const { filters } = this.state;
      Object.assign(filters, value_object);
      this.setState({ filters });
    }
    handlePagination(id) {
      this.setState({ page: id }, () => {
        this.fetchData();
      });
    }
    csvSubmit() {
      return __async(this, null, function* () {
        this.setState({ loading: true });
        yield this.fetchCSV();
        this.setState({ loading: false });
      });
    }
    filterSubmit() {
      this.handlePagination(1);
    }
    getFilters() {
      const { filters } = this.state;
      var filter_map = {};
      Object.keys(filters).forEach(function(key) {
        filters[key].path_func(filter_map, filters[key].value);
      });
      return filter_map;
    }
    buildSortableHeader(title, key) {
      const { sort_state } = this.state;
      var type = sort_state[key];
      return /* @__PURE__ */ React.createElement("div", null, title, " ", /* @__PURE__ */ React.createElement("span", { className: type ? `sort-#${type}` : "" }));
    }
    fetchCSV() {
      return __async(this, null, function* () {
        var that = this;
        const { page, sort_key, sort_state, filters } = this.state;
        const { archived, parseResponse } = this.props;
        let param_filters;
        if (Object.keys(filters).length == 0) {
          param_filters = { empty: "" };
        } else {
          param_filters = filters;
        }
        let params = jQuery.param({
          page,
          sort: sort_key,
          sort_order: sort_state[sort_key],
          filter: param_filters,
          archived
        });
        window.open(`/admin/api/v1/${options.data_name}.csv?${params}`, "_blank");
      });
    }
    fetchData() {
      var that = this;
      const { page, sort_key, sort_state, filters } = this.state;
      const { archived, parseResponse } = this.props;
      this.setState({ loading: true });
      let param_filters;
      if (Object.keys(filters).length == 0) {
        param_filters = { empty: "" };
      } else {
        param_filters = filters;
      }
      let params = jQuery.param({
        page,
        sort: sort_key,
        sort_order: sort_state[sort_key],
        filter: param_filters,
        archived
      });
      fetch(`/admin/api/v1/${options.data_name}.json?${params}`, {
        credentials: "same-origin"
      }).then((response) => {
        return response.json();
      }).then((data) => {
        that.setState({ loading: false });
        parseResponse(data);
      }).catch((error) => {
        that.setState({ loading: false });
        parseResponse({});
      });
    }
    render() {
      const { data, pagination, sort_key, sort_state, loading, filters } = this.state;
      const { response_data } = this.props;
      return /* @__PURE__ */ React.createElement(
        DataSourcedComponent,
        __spreadValues({
          data: response_data[options.data_name] || [],
          pagination: response_data.pagination || {},
          paginationCallback: this.handlePagination,
          loading,
          csvSubmit: this.csvSubmit,
          dataCallback: this.fetchData,
          sortCallback: this.handleSort,
          filters,
          filterCallback: this.filterCallback,
          filterSubmit: this.filterSubmit,
          buildSortableHeader: this.buildSortableHeader,
          sort_field: sort_key,
          sort_order: sort_state[sort_key]
        }, this.props)
      );
    }
  }
  return PaginatedDataSource;
}
export default paginatedDataSource;
