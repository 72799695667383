import React, { Dispatch } from 'react';
import {
  GenericTableState,
  GenericTableAction
} from '../../../NewUI/Components/GenericTable/genericTableReducer';
import { TApplication } from '../../types';
import { TableCell, Box } from '@mui/material';
import { theme } from '../../../ThemeContext/ThemeObject';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getStatusColor } from '../../helper';
import { classes } from '../../../NewUI/Components/GenericTable/styles';

const ApplicationsTableRows = ({
  column,
  item,
  tableHovered,
  dispatch
}: {
  column: GenericTableState['tableState']['columns'][number];
  item: TApplication;
  tableHovered: boolean;
  dispatch?: Dispatch<GenericTableAction>;
}) => {
  if (!column.enabled) return null;
  switch (column.id) {
    case 'id': {
      return (
        <TableCell
          sx={{
            ...classes.sticky,
            ...classes.idTableCell,
            left: '0px',
            minWidth: '100px'
          }}
          key={`${(item.id, column.id)}`}
        >
          {item.id || '-'}
        </TableCell>
      );
    }
    case 'candidate': {
      return (
        <TableCell
          sx={{
            ...classes.sticky,
            ...classes.itemTitleTableCell,
            ...(tableHovered && classes.scrollShadowVisible),
            left: '100px',
            minWidth: '200px'
          }}
          key={`${(item.id, column.id)}`}
        >
          {item.candidate || '-'}
        </TableCell>
      );
    }
    case 'application_deleted': {
      return (
        <TableCell
          sx={{
            textAlign: 'center'
          }}
          key={`${(item.id, column.id)}`}
        >
          {item.application_deleted ? (
            <CheckCircleIcon sx={{ color: theme.palette.success.main }} fontSize="small" />
          ) : (
            <CancelIcon sx={{ color: theme.palette.error.main }} fontSize="small" />
          )}
        </TableCell>
      );
    }
    case 'status': {
      return (
        <TableCell
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
          key={`${(item.id, column.id)}`}
        >
          {item.status ? (
            <Box
              sx={{
                borderRadius: '6px',
                padding: '6px',
                backgroundColor: `${getStatusColor(item.status)[0]}33`,
                width: 'fit-content',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  color: getStatusColor(item.status)[0],
                  fontWeight: 'bold',
                  fontSize: '15px',
                  padding: '0rem 1.5rem',
                  textTransform: 'uppercase'
                }}
              >
                {item.status}
              </Box>
            </Box>
          ) : (
            '-'
          )}
        </TableCell>
      );
    }
    default:
      return <TableCell></TableCell>;
  }
};

export default ApplicationsTableRows;
