"use strict";
export const classes = {
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    fontFamily: "Source Sans Pro",
    color: "#2D388D",
    "& p": {
      marginTop: "5px"
    }
  },
  felixErrorContainer: {
    width: "200px"
  },
  errorMessageContainer: {
    display: "flex",
    flexDirection: "column",
    marginBlock: "22px",
    alignItems: "center",
    justifyContent: "center"
  },
  errorButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    columnGap: "32px"
  },
  pill: {
    textTransform: "none",
    height: "36px",
    width: "auto",
    paddingInline: "17px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "700",
    color: "#AFBCFF",
    border: "1px solid #AFBCFF",
    borderRadius: "18px",
    minWidth: "160px"
  },
  descriptionContainer: {
    paddingTop: "30px",
    rowGap: "42px",
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto"
  },
  loadingContainer: {
    rowGap: "54px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto"
  },
  loadingMessage: {
    fontFamily: "Source Sans Pro",
    fontSize: "22px",
    fontWeight: "700",
    color: "#2D388D",
    width: "100%",
    textAlign: "center",
    "@keyframes pulse": {
      "50%": {
        opacity: 0.58
      }
    },
    animation: "pulse 1.8s ease-in-out infinite"
  },
  fieldInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "regular",
    borderRadius: "6px",
    height: "42px",
    width: "100%",
    "& legend": {
      display: "none"
    },
    "& input": {
      padding: "0px 20px 3.5px 20px",
      color: "#333333"
    },
    "& fieldset": {
      borderColor: "#DDDDDD"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADADA !important"
      }
    }
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "auto"
  },
  felixMessageContainer: {
    display: "flex",
    columnGap: "26px",
    alignItems: "center"
  },
  felixMessage: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#2D388D",
    maxWidth: "310px",
    "& span": {
      color: "#AFBCFF"
    }
  },
  footerButtonsContainer: {
    display: "flex",
    columnGap: "32px"
  },
  footerContinueButton: {
    textTransform: "none",
    height: "40px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "700",
    color: "#FFFFFF",
    backgroundColor: "#7A8EF1",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#2D388D"
    },
    "&:disabled": {
      backgroundColor: "#CCCCCC",
      color: "#DDDDDD"
    }
  },
  footerBackButton: {
    textTransform: "none",
    textDecoration: "underline",
    height: "40px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#CCCCCC",
    backgroundColor: "#FFFFFF",
    borderRadius: "20px",
    border: "none"
  },
  altFooterSecondaryButton: {
    textTransform: "none",
    height: "40px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "700",
    color: "#FFFFFF",
    backgroundColor: "#AFBCFF",
    borderRadius: "20px",
    border: "none",
    "&:hover": {
      backgroundColor: "#7A8EF1"
    },
    "&:disabled": {
      backgroundColor: "#CCCCCC",
      color: "#DDDDDD"
    }
  },
  copyButton: {
    width: "115px",
    textTransform: "none",
    height: "36px",
    paddingInline: "17px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "700",
    color: "#AFBCFF",
    border: "1px solid #AFBCFF",
    borderRadius: "18px"
  },
  questionsContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    rowGap: "30px",
    overflowY: "auto"
  },
  question: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: "26px",
    padding: "20px",
    background: "rgba(122, 142, 241, 0.12)",
    borderRadius: "6px",
    color: "#2D388D",
    fontFamily: "Source Sans Pro",
    fontSize: "18px"
  },
  floatingActionButton: {
    position: "absolute",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "#2D388D",
    boxShadow: "0px 3px 12px 2px rgba(166, 166, 166, 0.5)",
    bottom: "24px",
    right: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)"
    },
    "& svg": {
      color: "#FFFFFF",
      fontSize: "18px"
    }
  },
  viewOnlyHTMLParent: {
    position: "relative",
    width: "100%",
    height: "50vh",
    maxHeight: "500px"
  },
  emailInputButton: {
    backgroundColor: "#798DF0",
    bottom: "80px"
  },
  emailInputContainer: {
    position: "absolute",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "linear-gradient(#F0F0F086, #D6D6D686)"
  },
  emailInput: {
    marginBottom: "22px",
    padding: "16px 20px",
    borderRadius: "8px",
    width: "96%",
    backgroundColor: "#FFFFFF"
  },
  sendEmailButton: {
    position: "absolute",
    bottom: "34px",
    right: "36px",
    textTransform: "none",
    height: "26px",
    width: "62px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    backgroundColor: "#7A8EF1",
    borderRadius: "28px",
    border: "none",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#2D388D"
    },
    "&:disabled": {
      backgroundColor: "#CCCCCC",
      color: "#DDDDDD"
    }
  },
  labelStyles: {
    color: "#2D388D",
    fontSize: "18px",
    fontWeight: "600",
    fontFamily: "Source Sans Pro",
    marginBottom: "8px",
    marginLeft: "3px"
  },
  inputStyles: {
    border: "none"
  },
  helperTextStyles: {
    marginTop: "12px",
    marginLeft: "3px"
  },
  inputOpen: {
    bottom: "154px",
    right: "3px"
  },
  viewOnlyHTML: {
    fontFamily: "Source Sans Pro",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    padding: "4px 74px 20px 20px",
    border: "1px solid #DDDDDD",
    borderRadius: "6px",
    "& p": {
      fontFamily: "Source Sans Pro",
      fontSize: "15px",
      fontWeight: "normal"
    },
    "& ul": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "15px",
      listStyleType: "circle",
      marginLeft: "15px",
      marginTop: "16px",
      marginBottom: "8px",
      "& li": {
        marginBottom: "6px"
      }
    }
  },
  toggleDescriptionPreview: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#2D388D",
    textDecoration: "underline",
    cursor: "pointer"
  },
  descriptionPreview: {
    fontFamily: "Source Sans Pro",
    width: "100%",
    padding: "18px",
    paddingTop: "4px",
    border: "1px solid #DDDDDD",
    borderRadius: "6px",
    "& h2": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      color: "#666666",
      fontSize: "18px"
    },
    "& h3": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      color: "#666666",
      fontSize: "16px"
    },
    "& p": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "normal"
    },
    "& ul": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "14px",
      listStyleType: "circle",
      marginLeft: "30px",
      marginTop: "16px",
      marginBottom: "8px"
    },
    "& ol": {
      fontFamily: "Source Sans Pro",
      color: "#666666",
      fontSize: "16px",
      marginLeft: "30px",
      marginTop: "16px",
      marginBottom: "8px",
      listStyle: "decimal",
      "& li": {
        marginBottom: "12px",
        paddingLeft: "10px"
      }
    }
  },
  toggleButton: {
    padding: "4px 17px !important",
    fontFamily: "Source Sans Pro",
    color: "#AAAAAA",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "12px",
    backgroundColor: "#F9F9F9",
    textTransform: "none",
    border: "none",
    "&.Mui-selected": {
      backgroundColor: "#7A8EF1 !important",
      color: "#FFFFFF !important"
    },
    "&:focus": {
      outline: "none"
    },
    "&:hover": {
      border: "none"
    }
  },
  socketsMessage: {
    display: "flex",
    alignItems: "center",
    columnGap: "15px",
    backgroundColor: "#E3E8FF",
    borderRadius: "6px",
    padding: "12px 22px 12px 20px",
    fontFamily: "Source Sans Pro",
    fontWeight: "600",
    fontSize: "17px",
    color: "#2D388D",
    opacity: "0",
    transition: "all 0.3s ease"
  },
  socketsInfoIcon: {
    fontSize: "20px",
    marginTop: "1px"
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    },
    "& :hover": {
      "& .MuiSwitch-track": {
        backgroundColor: "#000 !important"
      }
    }
  },
  aiSwitchActive: {
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#7A8EF13D !important",
      opacity: "1 !important"
    },
    "& .MuiSwitch-thumb": {
      color: "#2D388D"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    }
  },
  switchLabels: {
    justifyContent: "start",
    width: "120px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      color: "#000000"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontFamily: "Source Sans Pro",
      fontSize: "14px"
    }
  },
  checkboxContainer: {
    color: "#2D388D",
    width: "42px",
    textAlign: "center"
  },
  checkbox: {
    color: "#939393 !important",
    "& .MuiSvgIcon-root": {
      fontSize: "24px"
    },
    "&.Mui-checked": {
      color: "#939393 !important"
    }
  }
};
