import React, { useState, useCallback, useEffect } from 'react';
import { Box, Skeleton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import QuestionBased from './QuestionBased';
import RatingBased from './RatingBased';
import InfoIcon from '@mui/icons-material/Info';
import { IAutoStatusesProps, IQuestionBasedAS, IRatingBasedAS } from '../../types';
import { IApplicationStatus } from '../../../Job/types';
import InfoBox from '../../../Components/CustomUIElements/InfoBox';

export default function AutoStatuses({
  apiKey,
  jobId,
  setSnackbar,
  jobQuestions,
  handleEditTabChange
}: IAutoStatusesProps) {
  const [loading, setLoading] = useState({
    applicationStatuses: true,
    questionBased: true,
    ratingBased: true
  });
  const [questionBasedAS, setQuestionBasedAS] = useState<IQuestionBasedAS[]>([]);
  const [ratingBasedAS, setRatingBasedAS] = useState<IRatingBasedAS[]>([]);
  const [applicationStatuses, setApplicationStatuses] = useState<IApplicationStatus[]>([]);
  const [selected, setSelected] = useState('questionBased');
  const [hasRadioButtonQuestions, setHasRadioButtonQuestions] = useState(true);

  const getApplicationStatuses = useCallback(async () => {
    setLoading((prev) => ({ ...prev, applicationStatuses: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/job_application_statuses`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { job_application_statuses } = await response.json();
      job_application_statuses.shift(); // Remove "New" status from array
      setApplicationStatuses(job_application_statuses);
    } catch (error) {
      setSnackbar({
        message: 'Error fetching question based auto statuses',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, applicationStatuses: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getQuestionBasedAS = useCallback(async () => {
    setLoading((prev) => ({ ...prev, questionBased: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_status_question_based`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      if (response.errors) {
        setHasRadioButtonQuestions(false);
      } else {
        setQuestionBasedAS(response.auto_statuses_question_based);
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching question based auto statuses',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, questionBased: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getRatingBasedAS = useCallback(async () => {
    setLoading((prev) => ({ ...prev, ratingBased: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_status_rating_based`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { auto_statuses_rating_based } = await response.json();
      setRatingBasedAS(auto_statuses_rating_based);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching rating based auto statuses',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, ratingBased: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newSelected: string) => {
    if (newSelected !== null) {
      setSelected(newSelected);
    }
  };

  useEffect(() => {
    getApplicationStatuses();
    getQuestionBasedAS();
    getRatingBasedAS();
  }, [getApplicationStatuses, getQuestionBasedAS, getRatingBasedAS]);

  return (
    <Box sx={classes.automationContainer}>
      <h2 data-testid="auto-statuses-header">Auto statuses</h2>
      <InfoBox message="Any changes here will be automatically saved and will be applied immediately." />
      <Box sx={classes.toggleButtonsContainer}>
        <ToggleButtonGroup
          value={selected}
          exclusive
          onChange={handleToggle}
          aria-label="question or rating based"
        >
          <ToggleButton
            data-testid="question-based-toggle-button"
            value="questionBased"
            sx={sharedClasses.toggleButton}
          >
            Question Based
          </ToggleButton>
          <ToggleButton
            data-testid="rating-based-toggle-button"
            value="ratingBased"
            sx={sharedClasses.toggleButton}
          >
            Rating Based
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {selected === 'questionBased' ? (
        !loading.questionBased && !loading.applicationStatuses ? (
          <QuestionBased
            apiKey={apiKey}
            jobId={jobId}
            setSnackbar={setSnackbar}
            autoStatuses={questionBasedAS}
            setAutoStatuses={setQuestionBasedAS}
            applicationStatuses={applicationStatuses}
            hasRadioButtonQuestions={hasRadioButtonQuestions}
            handleEditTabChange={handleEditTabChange}
          />
        ) : (
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={450}
            width={'100%'}
            sx={{ borderRadius: '8px' }}
          />
        )
      ) : !loading.ratingBased && !loading.applicationStatuses ? (
        <RatingBased
          apiKey={apiKey}
          jobId={jobId}
          setSnackbar={setSnackbar}
          autoStatuses={ratingBasedAS}
          setAutoStatuses={setRatingBasedAS}
          applicationStatuses={applicationStatuses}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={450}
          width={'100%'}
          sx={{ borderRadius: '8px' }}
        />
      )}
    </Box>
  );
}
