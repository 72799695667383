import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { classes } from '../../styles';
import ProfileDropdownPopper from './ProfileDropdownPopper';
import { IUserInfo } from '../../Header';

const ProfileDropdown = ({
  userInfo,
  setChangingEntity,
  widgetsbarExpanded
}: {
  userInfo: IUserInfo;
  setChangingEntity: (changingEntity: boolean) => void;
  widgetsbarExpanded: boolean;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const avatarAnchorEl = useRef(null);

  const handleAvatarClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  return (
    <>
      <Box
        sx={{
          ...classes.profileDropdownContainer,
          marginRight: widgetsbarExpanded ? 'none' : 5
        }}
        onClick={handleAvatarClick}
        ref={avatarAnchorEl}
      >
        <Box id="profile-circle" sx={classes.profileAvatar}>{userInfo?.firstName[0] + userInfo?.lastName[0]}</Box>
        <ExpandMoreIcon sx={classes.profileDropdownIcon} />
      </Box>
      <ProfileDropdownPopper
        menuOpen={menuOpen}
        avatarAnchorEl={avatarAnchorEl.current}
        setMenuOpen={setMenuOpen}
        setChangingEntity={setChangingEntity}
        userInfo={userInfo}
      />
    </>
  );
};

export default ProfileDropdown;
