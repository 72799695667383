import React from 'react';
import { TableBody, Table, Box, TableContainer, Paper, Skeleton } from '@mui/material';
import { styles } from './styles';
import TableHeader from './TableHeader';
import { PreviewTableType } from '../../../reports/CustomReports/types';

type Props = {
  tableHeaders: PreviewTableType['tableHeaders'];
  data: PreviewTableType['data'];
  tableBody: React.ReactNode;
  isLoading: boolean;
};
const PreviewTable = ({ tableHeaders, data, isLoading, tableBody }: Props) => {
  return (
    <Box sx={{ paddingLeft: '1rem', paddingTop: `${!data ? '1rem' : '0'} ` }}>
      <TableContainer sx={{ boxShadow: 'none', scrollBehavior: 'smooth' }} component={Paper}>
        <Table
          sx={{
            ...styles.tableWrapper,
            'th, td, tr, thead': { padding: '22px 10px', borderBottom: '1px solid #DDDDDD' }
          }}
        >
          {!isLoading && tableHeaders?.length > 0 && <TableHeader state={tableHeaders} />}
          {!isLoading && tableHeaders?.length > 0 && <TableBody>{tableBody}</TableBody>}
        </Table>
        {!isLoading && (data?.length === 0 || !data) && (
          <Box sx={styles.noRecords}>No Records Found</Box>
        )}
        {isLoading && (
          <>
            {[...Array(5)].map((_, index) => (
              <Box key={index} sx={styles.skeletonContainer}>
                <Skeleton animation="wave" height={60} />
              </Box>
            ))}
          </>
        )}
      </TableContainer>
    </Box>
  );
};

export default PreviewTable;
