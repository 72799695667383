import React from 'react';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { Box, Stack } from '@mui/material';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { theme } from '../../../ThemeContext/ThemeObject';

export function DeleteFormResponse({
  dispatch,
  ApplicationState,
  formResponseId
}: {
  dispatch: React.Dispatch<ApplicationAction>;
  ApplicationState: ApplicationState;
  formResponseId: number;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const deleteFormResponse = useMutation({
    mutationFn: async () => {
      if (application && formResponseId) {
        const { res } = await Api.deleteFormResponse(
          application.job.id,
          application.id,
          formResponseId
        );
        return res;
      }
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Form response successfully deleted`,
          state: 'success'
        }
      });
      queryClient.removeQueries(['formResponses']);
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `There was an error deleting form response, ${error}`, state: 'error' }
      });
    },
    onSettled: () => {
      dispatch({
        type: 'SET_SELECTED_FORM_RESPONSE_ACTION',
        payload: { delete: null, edit: null }
      });
    }
  });

  const dialogDescription = (
    <Stack sx={{ color: theme.palette.common.grey, rowGap: 1, fontWeight: 400 }}>
      <Box>Are you sure you want to delete</Box>
      <Box>this form response?</Box>
    </Stack>
  );

  return (
    <GenericDialog
      isDialogOpen={!!ApplicationState.selectedFormResponseAction.delete}
      setDialogOpen={() => {
        dispatch({
          type: 'SET_SELECTED_FORM_RESPONSE_ACTION',
          payload: { delete: null, edit: null }
        });
      }}
      title="Delete form response?"
      description={dialogDescription}
      buttonCallback={() => deleteFormResponse.mutate()}
      callbackLoading={deleteFormResponse.isLoading}
      buttonText="Delete"
      url=""
      primaryButtonId="confirm-delete-form-button"
      secondaryButtonId="cancel-delete-form-button"
    />
  );
}
