import React, { useEffect, useState, useCallback } from 'react';
import StyledModal from '../GenericModal/StyledModal';
import GenericDialog from './GenericDialog';
import ModalFooterButtons from '../GenericModal/ModalFooterButtons';
import { classes } from './styles';
import { Box, LinearProgress } from '@mui/material';
import FileTickSVG from '../Graphics/FileTickSVG';

const DownloadModal = ({
  type,
  isOpen,
  handleClose,
  downloadAPICall,
  emailFallback = false
}: {
  type: 'attachments' | 'pdf' | 'csv' | 'attachmentsInPdf';
  isOpen: boolean;
  handleClose: () => void;
  downloadAPICall: (type: string) => Promise<string>;
  emailFallback?: boolean;
}) => {
  const [confirmCancelIsOpen, setConfirmCancelIsOpen] = useState(false);
  const [generatingDownload, setGeneratingDownload] = useState(true);
  const [downloadURL, setDownloadURL] = useState<string>('');
  const [emailFallbackMessage, setEmailFallbackMessage] = useState<string>('');
  const [hasFetchedData, setHasFetchedData] = useState(false);

  const handlePrimaryButtonClick = () => {
    generatingDownload ? setConfirmCancelIsOpen(true) : handleClose();
  };

  const handleConfirmClose = () => {
    setConfirmCancelIsOpen(false);
    setHasFetchedData(false);
    handleClose();
  };

  const handleDownload = useCallback(
    async (type: 'attachments' | 'pdf' | 'csv' | 'attachmentsInPdf') => {
      if (emailFallback) {
        setGeneratingDownload(true);
        const data = await downloadAPICall(type);
        setGeneratingDownload(false);
        setEmailFallbackMessage(data);
        return;
      }
      setGeneratingDownload(true);
      const id = await downloadAPICall(type);
      const url = `/admin/assets/${id}`;
      setDownloadURL(url);
      setGeneratingDownload(false);
      // Automatically start file download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      setHasFetchedData(true);
    },
    [downloadAPICall]
  );

  useEffect(() => {
    if (isOpen && !hasFetchedData) {
      handleDownload(type);
    }
  }, [handleDownload, isOpen, type]);

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        handleClose={() => setConfirmCancelIsOpen(true)}
        styleOverrides={{
          maxWidth: '740px',
          maxHeight: '400px'
        }}
        label={`download-${type}`}
        modalTitle={
          type === 'attachments' ? 'Download attachments' : `Candidate ${type.toUpperCase()} Export`
        }
        subHeaderStyling={false}
      >
        <Box sx={classes.downloadModalContent}>
          {generatingDownload ? (
            <>
              <Box sx={classes.downloadModalDescription}>
                {type === 'attachments'
                  ? 'Your attachments are being prepared, please wait...'
                  : `Your ${type.toUpperCase()} file is being prepared, please wait...`}
              </Box>
              <Box sx={{ width: '100%' }}>
                <LinearProgress color="inherit" />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ width: '100%', textAlign: 'center' }}>
                <FileTickSVG />
              </Box>
              <Box sx={{ ...classes.downloadModalDescription, textAlign: 'center' }}>
                {emailFallback ? (
                  emailFallbackMessage
                ) : (
                  <>
                    Your download should have started automatically. <br />
                    If it hasn't, click{' '}
                    <a href={downloadURL} download>
                      here
                    </a>
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
        <ModalFooterButtons
          primaryButtonText={generatingDownload ? 'Cancel' : 'Done'}
          primaryButtonCallback={handlePrimaryButtonClick}
          primaryButtonID={generatingDownload ? 'cancel-download-button' : 'done-download-button'}
          secondaryButtonID="cancel-download-attachments-button"
        />
      </StyledModal>
      <GenericDialog
        isDialogOpen={confirmCancelIsOpen}
        setDialogOpen={setConfirmCancelIsOpen}
        title="Are you sure you want to cancel?"
        description=""
        buttonText="Cancel"
        buttonCallback={handleConfirmClose}
        secondaryButtonText="Continue"
        callbackLoading={false}
        url=""
      />
    </>
  );
};

export default DownloadModal;
