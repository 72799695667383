"use strict";
export const classes = {
  rootContainer: {
    display: "flex",
    flexDirection: "column"
  },
  aiHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: "#727272",
    marginBottom: "1.2rem",
    marginTop: ".7rem"
  },
  buttonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "1.5rem",
    rowGap: "1rem",
    marginTop: "0.5rem"
  },
  aiButton: {
    textTransform: "none",
    height: "36px",
    width: "auto",
    paddingInline: "17px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "700",
    color: "#AFBCFF",
    border: "1px solid #AFBCFF",
    borderRadius: "18px",
    minWidth: "268px"
  },
  aiButtonFilled: {
    textTransform: "none",
    height: "36px",
    width: "auto",
    paddingInline: "17px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "700",
    color: "#AFBCFF",
    border: "1px solid #2D388D",
    backgroundColor: "#2D388D",
    borderRadius: "18px",
    minWidth: "268px",
    "&:hover": {
      borderColor: "#AFBCFF",
      backgroundColor: "#2D388D"
    }
  },
  previewContainer: {
    marginTop: "1rem",
    "& h3": {
      marginBottom: "1rem",
      marginTop: ".3rem",
      color: "#727272",
      fontSize: "16px"
    }
  },
  tlpPreview: {
    fontFamily: "Source Sans Pro",
    width: "100%",
    padding: "18px",
    paddingTop: "4px",
    border: "1px solid #DDDDDD",
    borderRadius: "6px",
    "& h2": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      color: "#666666",
      fontSize: "18px"
    },
    "& h3": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      color: "#666666",
      fontSize: "16px"
    },
    "& p": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "normal"
    },
    "& ul": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "14px",
      listStyleType: "circle",
      marginLeft: "30px",
      marginTop: "16px",
      marginBottom: "8px"
    },
    "& ol": {
      fontFamily: "Source Sans Pro",
      color: "#666666",
      fontSize: "16px",
      marginLeft: "30px",
      marginTop: "16px",
      marginBottom: "8px",
      listStyle: "decimal",
      "& li": {
        marginBottom: "12px",
        paddingLeft: "10px"
      }
    }
  },
  toggleTlpPreview: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#2D388D",
    textDecoration: "underline",
    cursor: "pointer"
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    marginBottom: "24px",
    rowGap: "28px",
    width: "100%",
    position: "relative",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  question: {
    width: "100%",
    display: "flex",
    columnGap: "26px",
    padding: "12px 24px",
    background: "rgba(122, 142, 241, 0.12)",
    borderRadius: "6px",
    color: "#2D388D",
    fontFamily: "Source Sans Pro",
    fontSize: "16px"
  },
  deleteQuestionButton: {
    textTransform: "none",
    height: "28px",
    width: "auto",
    paddingInline: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    fontWeight: "700",
    color: "#AFBCFF",
    border: "1px solid #AFBCFF",
    borderRadius: "18px",
    marginLeft: "auto",
    "& svg": {
      fontSize: "18px !important",
      color: "#E37D7A99"
    }
  }
};
