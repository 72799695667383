import React from 'react';

export const welcomeMessage = {
  english: "Hello! I'm Felix.",
  french: 'Salut! Je suis Felix.'
};

export const defaultJobDetails = {
  position_title: '',
  preferred_skills: '',
  position_responsibilities: '',
  position_location: '',
  salary: '',
  company_name: '',
  company_description: '',
  company_benefits: ''
};

// OptionalInput.tsx Variables

export const placeholderText = {
  english: {
    preferred_skills: "eg. 'Attention to detail, time management'",
    position_responsibilities:
      "eg. 'Designing user interfaces, creating wireframes, conducting user research'",
    position_location: "eg. 'Remote, Brisbane, Australia'",
    salary: "eg. '$100,000 - $120,000'",
    company_name: "eg. 'Google'",
    company_description:
      "eg. 'We are a tech company that specialises in AI technology. Our mission is to make the world a better place by creating AI that can help people.'",
    company_benefits:
      "eg. 'Flexible working hours, quarterly team building events, unlimited snacks, employee gym membership'"
  },
  french: {
    preferred_skills: "ex. 'Attention aux détails, gestion du temps'",
    position_responsibilities:
      "ex. 'Conception d'interfaces utilisateur, création de maquettes, réalisation de recherches utilisateur'",
    position_location: "ex. 'À distance, Brisbane, Australie'",
    salary: "ex. '100 000 $ - 120 000 $'",
    company_name: "ex. 'Google'",
    company_description:
      "ex. 'Nous sommes une entreprise technologique spécialisée dans la technologie de l'IA. Notre mission est de rendre le monde meilleur en créant une IA capable d'aider les gens.'",
    company_benefits:
      "ex. 'Horaires de travail flexibles, événements trimestriels de renforcement d'équipe, collations illimitées, adhésion à une salle de sport pour les employés'"
  }
};

export const felixMessageText = {
  preferred_skills: {
    english: (
      <>
        <span>Preferred skills</span> can include include soft skills such as communication and time
        management, or technical skills.
      </>
    ),
    french: (
      <>
        <span>Les compétences préférées</span> peuvent inclure des compétences douces telles que la
        communication et la gestion du temps, ou des compétences techniques.
      </>
    )
  },
  position_responsibilities: {
    english: (
      <>
        <span>Position responsibilities</span> are the tasks that the candidate will be expected to
        perform.
      </>
    ),
    french: (
      <>
        <span>Les responsabilités du poste</span> sont les tâches que le candidat sera censé
        accomplir.
      </>
    )
  },
  position_location: {
    english: (
      <>
        <span>Position location</span> is the location of the position and can include remote work
        with preferred locations.
      </>
    ),
    french: (
      <>
        <span>La localisation du poste</span> est l'endroit où se situe le poste et peut inclure le
        travail à distance avec des lieux préférés.
      </>
    )
  },
  salary: {
    english: (
      <>
        <span>Salary</span> is the salary range for the position. You can include bonuses,
        superannuation and other incentives.
      </>
    ),
    french: (
      <>
        <span>Salaire</span> est la fourchette de salaire pour le poste. Vous pouvez inclure des
        bonus, des pensions et d'autres incitations.
      </>
    )
  },
  company_name: {
    english: (
      <>
        Let me know the <span>name of the company</span> that is hiring, so I can include it in the
        job description.
      </>
    ),
    french: (
      <>
        Faites-moi savoir le <span>nom de l'entreprise</span> qui embauche, pour que je puisse
        l'inclure dans la description du poste.
      </>
    )
  },
  company_description: {
    english: (
      <>
        The <span>company description</span> should be a short description of the company. This
        could include the company&apos;s mission, vision, and values.
      </>
    ),
    french: (
      <>
        La <span>description de l'entreprise</span> devrait être une brève description de
        l'entreprise. Cela pourrait inclure la mission, la vision et les valeurs de l'entreprise.
      </>
    )
  },
  company_benefits: {
    english: (
      <>
        <span>Company benefits</span> are the benefits that the company offers, such as flexible
        hours, free lunch on Fridays, or a gym membership.
      </>
    ),
    french: (
      <>
        <span>Les avantages de l'entreprise</span> sont les avantages que l'entreprise offre, comme
        des horaires flexibles, un déjeuner gratuit le vendredi ou une adhésion à une salle de
        sport.
      </>
    )
  }
};

export const isMultiLineInput = {
  preferred_skills: false,
  position_responsibilities: true,
  position_location: false,
  salary: false,
  company_name: false,
  company_description: true,
  company_benefits: true
};

// LoadingResponse.tsx Variables

const loadingType = {
  description: 'talent landing page',
  questions: 'screening questions',
  social: 'social media post',
  advertisement: 'job board advertisement',
  interview: 'interview questions',
  summary: 'candidate summaries'
};

export const generateLoadingText = (generationType: string) => [
  `Sit tight! I'm drafting your ${loadingType[generationType as keyof typeof loadingType]}`,
  'Waving the wand of computational conjuring',
  'Researching similar roles',
  'Crafting a masterpiece of expression',
  'Exploring the job market',
  'Rising like a phoenix from the ashes of data',
  'Doing the work to save you time'
];

// DisplayResponse.tsx Variables

export const modalTitle = {
  english: {
    description: 'draft talent landing page',
    questions: 'screening questions',
    advertisement: 'job board advertisement'
  },
  french: {
    description: 'brouillon de description de poste',
    questions: 'questions de sélection',
    advertisement: 'annonce sur un site d’emploi'
  }
};

export const notificationTitles = {
  bulk_summary: 'Application Summaries are',
  interview_questions: 'Interview Questions are',
  follow_up_email: 'Follow Up Email is'
};

// French translation
export const convertPreferredSkills = {
  english: {
    preferred_skills: 'Preferred skills',
    position_responsibilities: 'Position responsibilities',
    position_location: 'Position location',
    salary: 'Salary',
    company_name: 'Company name',
    company_description: 'Company description',
    company_benefits: 'Company benefits'
  },
  french: {
    preferred_skills: 'Compétences préférées',
    position_responsibilities: 'Responsabilités du poste',
    position_location: 'Emplacement du poste',
    salary: 'Salaire',
    company_name: "Nom de l'entreprise",
    company_description: "Description de l'entreprise",
    company_benefits: "Avantages de l'entreprise"
  }
};
