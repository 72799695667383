import React, { useState } from 'react';
import moment from 'moment';
import GenericDialog from '../GenericDialog';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { classes } from '../styles';
import { IJobAdBroadbeanProps } from '../types';

const BASE_URL = window.location.origin;

export default function AdTable({ jobAds, setAds, apiKey }: IJobAdBroadbeanProps) {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [dialogUrl, setDialogUrl] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [dialogButtonText, setDialogButtonText] = useState('');
  const [dialogButtonCallback, setDialogButtonCallback] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const handleClone = async (url: string) => {
    window.location.href = url;
  }

  return (
    <Box sx={classes.table}>
      <Box sx={{ ...classes.row, ...classes.rowHeader }}>
        <span>Job</span>
        <span>Reference</span>
        <span>Title</span>
        <span>Approved?</span>
        <span>Active on SEEK?</span>
        <span>Active on Council Jobs?</span>
        <span>Created</span>
        <span>Created by</span>
        <span>Expiry</span>
        <span>Assigned user</span>
        <Box component="span" sx={classes.center}>
          
        </Box>
      </Box>
      {jobAds.length > 0 ? (
        jobAds.map((ad) => {
          const createdAt = ad.created_at
            ? moment
                .tz(ad.created_at, timeZoneString)
                .format('DD MMM YYYY,-h:mma z')
                .split('-')
                .map((line) => <div key={`${ad.id}-${line}`}>{line}</div>)
            : '-';
          const council_jobs_ad_expiry_date = ad.council_jobs_ad_expiry_date
            ? moment
                .tz(ad.council_jobs_ad_expiry_date, timeZoneString)
                .format('DD MMM YYYY,-h:mma z')
                .split('-')
                .map((line) => <div key={`${ad.id}-${line}`}>{line}</div>)
            : 'No expiry';
          return (
            <Box key={ad.id} sx={classes.row}>
              <Box sx={classes.title}>{ad.job_title}</Box>
              <Box>{ad.reference}</Box>
              <Box>{ad.title}</Box>
              <Box sx={classes.center}>
                {ad.approved ? (
                  <CheckCircleIcon sx={classes.iconGreen} />
                ) : (
                  <CancelIcon sx={classes.iconRed} />
                )}
              </Box>
              <Box sx={classes.center}>
                {ad.active_on_seek ? (
                  <CheckCircleIcon sx={classes.iconGreen} />
                ) : (
                  <CancelIcon sx={classes.iconRed} />
                )}
              </Box>
              <Box sx={classes.center}>
                {ad.active_on_council_jobs ? (
                  <CheckCircleIcon sx={classes.iconGreen} />
                ) : (
                  <CancelIcon sx={classes.iconRed} />
                )}
              </Box>
              <Box>{createdAt}</Box>
              <Box>{ad.created_by}</Box>
              <Box>{council_jobs_ad_expiry_date}</Box>
              <Box>{ad.assigned_user || '-'}</Box>
              <Box sx={classes.actionsContainer}>
                <Tooltip title="Preview ad">
                  <IconButton
                    onClick={() => {
                      window.location.href = `${BASE_URL}${ad.preview_url}`;
                    }}
                  >
                    <VisibilityIcon fontSize="small" sx={classes.iconGrey} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => {
                      window.location.href = `${BASE_URL}${ad.edit_url}`;
                    }}
                  >
                    <EditIcon fontSize="small" sx={classes.iconGrey} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Clone">
                  <IconButton
                    onClick={() => {
                      setDialogUrl(`${BASE_URL}${ad.clone_url}`);
                      setDialogTitle('Are you sure you want to clone this ad?');
                      setDialogDescription(
                        'You will have to repost for your ad to be active on CouncilJobs.'
                      );
                      setDialogButtonText('Clone ad');
                      setDialogButtonCallback(() => handleClone);
                      setDialogOpen(true);
                    }}
                  >
                    <FileCopyIcon sx={classes.iconGrey} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          );
        })
      ) : (
        <Box sx={classes.row}>
          <Box sx={classes.title}>No ads to display</Box>
        </Box>
      )}
      <GenericDialog
        url={dialogUrl}
        title={dialogTitle}
        description={dialogDescription}
        buttonText={dialogButtonText}
        buttonCallback={dialogButtonCallback}
        callbackLoading={dialogLoading}
        isDialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
      <Snackbar open={isSnackOpen} onClose={() => setIsSnackOpen(false)} message={snackMessage} />
    </Box>
  );
}
