import React from "react";
import PropTypes from "prop-types";
import useStyles from "../useStyles";
import { FormControl } from "@material-ui/core";
function Step4({ answers }) {
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement("div", { className: classes.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, answers.title), answers.listing_location_display, answers.use_suggested_classification ? /* @__PURE__ */ React.createElement("div", null, answers.listing_location_display, " ", /* @__PURE__ */ React.createElement("span", { style: { margin: "0 1rem" } }, ">"), " ", answers.listing_sub_classification_display) : /* @__PURE__ */ React.createElement("div", null, answers.listing_sub_classification_display), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      style: { margin: "0.5rem 0 1.5rem 0" },
      className: `preview-html ${classes.formControl} ${classes.large}`
    },
    /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: answers.ad_details } })
  ));
}
Step4.propTypes = {
  answers: PropTypes.object
};
export default Step4;
