"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  noteEdit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    transition: "all 0.3s ease-in-out",
    width: "50%"
  },
  noteEditFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    columnGap: "10px",
    padding: "8px 1px",
    minHeight: "50.5px"
  },
  notesContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    marginTop: "32px"
  },
  note: {
    display: "flex",
    justifyContent: "space-between",
    margin: "4px 0"
  },
  noteContent: {
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
    marginRight: "14%",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#939393"
  },
  noteText: {
    color: "#828282",
    fontSize: "16px"
  },
  noteActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "16px"
  },
  notesEditSaveButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "86px",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    },
    "& svg": {
      color: "#FFFFFF",
      fontSize: "16px !important"
    },
    "&:disabled": {
      backgroundColor: "#EEEEEE",
      color: "#BBBBBB",
      transition: "all 250ms ease-in-out"
    }
  },
  notesCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "86px",
    textTransform: "none",
    color: "#939393",
    backgroundColor: "rgba(147, 147, 147, 0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "rgba(147, 147, 147, 0.24)",
      boxShadow: "none"
    },
    "& svg": {
      color: theme.palette.error.main,
      fontSize: "16px !important"
    }
  },
  newNoteContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  notesNewButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "120px",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  noNotesText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  notesSkeleton: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    width: "100%"
  },
  emojiError: {
    color: theme.palette.error.main,
    transition: "opacity 0.3s linear",
    position: "absolute",
    top: "8px",
    right: "0px",
    fontSize: "14px"
  }
};
