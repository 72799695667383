import React, { useState } from 'react';
import {
  Box,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  Tooltip,
  Popper
} from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { IDropdownProps } from '../types';
import { styles } from '../styles';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

export default function FieldDropdown({
  options,
  activeColumns,
  handleChangeActiveColumns,
  density,
  setDensity,
  userPermissions,
  setUserPreferences,
  label
}: IDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeActiveColumns({
      ...activeColumns,
      [event.target.name]: event.target.checked
    });
    setUserPreferences(
      null,
      null,
      {
        ...activeColumns,
        [event.target.name]: event.target.checked
      },
      null,
      null
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen((prev) => !prev);
  };

  return (
    <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
      <Box>
        <Tooltip title="Table settings" placement="top" arrow>
          <IconButton
            id={`field-dropdown-${label}-button`}
            sx={
              dropdownOpen ? { ...styles.iconButton, ...styles.iconButtonOpen } : styles.iconButton
            }
            onClick={handleClick}
          >
            <ViewColumnIcon />
          </IconButton>
        </Tooltip>
        <Popper
          id="field-dropdown-popover"
          sx={styles.popover}
          open={dropdownOpen}
          anchorEl={anchorEl}
          placement="bottom-end"
        >
          <Box sx={styles.popoverContent}>
            <FormControl component="fieldset" variant="standard">
              <Stack sx={styles.tableSettingsHeader}>Column</Stack>
              <FormGroup id="field-dropdown-switches">
                {options.map((field, index) => {
                  if (
                    field.name === 'note' &&
                    !userPermissions?.['Approval Forms']?.['Manage Approval Form Notes']
                  ) {
                    return;
                  }
                  const isSwitched = activeColumns[field.name as keyof typeof activeColumns];
                  return (
                    <FormControlLabel
                      sx={
                        !isSwitched
                          ? { ...styles.switchLabels, ...styles.labelActive }
                          : styles.switchLabels
                      }
                      key={index}
                      control={
                        <Switch
                          sx={isSwitched ? styles.switchActive : styles.switch}
                          checked={isSwitched}
                          onChange={handleChange}
                          name={field.name}
                        />
                      }
                      label={field.label}
                      labelPlacement="start"
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
            <Stack sx={{ ...styles.tableSettingsHeader, paddingTop: 2 }}>Density</Stack>
            <FormControl>
              <FormGroup>
                {['Default', 'Compact', 'Ultra Compact'].map((size, index) => {
                  const isSwitched = size === density;
                  return (
                    <FormControlLabel
                      sx={
                        !isSwitched
                          ? { ...styles.switchLabels, ...styles.labelActive }
                          : styles.switchLabels
                      }
                      key={index}
                      control={
                        <Switch
                          sx={isSwitched ? styles.switchActive : styles.switch}
                          checked={isSwitched}
                          onChange={(event) => {
                            setDensity(size);
                            setUserPreferences(null, null, null, null, size);
                          }}
                          name={'density'}
                        />
                      }
                      label={size}
                      labelPlacement="start"
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
