"use strict";
import { theme } from "../ThemeContext/ThemeObject";
export const classes = {
  pageContainer: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "#FFFFFF",
    padding: "40px"
  },
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px 26px 16px",
    "& h1": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "38px",
      color: "#666666",
      marginTop: "0"
    }
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "#EEEEEE",
    margin: "25px 0"
  },
  tabs: {
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      letterSpacing: "0",
      color: "#939393",
      textTransform: "none",
      minWidth: "unset",
      "&:hover": {
        color: "#737373"
      },
      "&:focus": {
        outline: "none"
      }
    },
    "& button.Mui-selected": {
      fontWeight: "600",
      color: "#084D6D"
    }
  },
  tabIndicator: {
    backgroundColor: "#5BC4BF",
    margin: "0 16px"
  },
  searchAndFiltersRow: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingBottom: "30px",
    columnGap: "17px"
  },
  tlpMatchButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: 700,
    color: "#4E4EC7",
    backgroundColor: "rgba(78, 78, 199, 0.18)",
    width: "146px",
    borderRadius: "6px",
    height: "60px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(78, 78, 199, 0.14)"
    },
    "&:focus": {
      outline: "none",
      backgroundColor: "rgba(78, 78, 199, 0.14)"
    }
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "60px",
    maxWidth: "1100px",
    width: "100%",
    height: "94vh",
    maxHeight: "844px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  modalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "38px",
    fontWeight: "700",
    color: "#2D388D",
    width: "100%",
    paddingBottom: "40px"
  },
  modalFieldSubHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#2D388D",
    marginBottom: "16px"
  },
  modalRow: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  modalMainContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    rowGap: "40px",
    overflowY: "auto"
  },
  autocomplete: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "18px",
      padding: "0 10px 0 10px !important",
      height: "42px",
      width: "80%",
      fontFamily: "Source Sans Pro, sans-serif",
      textTransform: "capitalize",
      "&:focus": {
        borderColor: "#2D388D"
      }
    },
    "& .MuiOutlineInput-root:focus": {
      backgroundColor: "#ffffff",
      borderRadius: "6px"
    },
    "& fieldset": {
      display: "none"
    },
    "& .MuiAutocomplete-popupIndicator": {
      color: "#AAAAAA",
      marginRight: "10px",
      transition: "all 0.16s ease-in-out"
    }
  },
  selectJobPlaceholder: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "22px",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "20px",
      fontWeight: "700",
      color: "#AAAAAA",
      paddingBottom: "100px"
    }
  },
  editBooleanStepHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "26px",
    fontWeight: "600",
    color: "#2D388D",
    paddingLeft: "20px",
    width: "100%"
  },
  filtersContainer: {
    paddingTop: "4px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    rowGap: "20px"
  },
  toggleButton: {
    padding: "0px 14px !important",
    fontWeight: "700",
    fontFamily: "Source Sans Pro",
    color: "#AAAAAA",
    fontSize: "12px",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    textTransform: "none",
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.action.main} !important`
    },
    "&:focus": {
      outline: "none"
    }
  },
  bigToggleButton: {
    fontSize: "18px",
    borderRadius: "18px"
  },
  widgetBlockIcon: {
    backgroundColor: "#EDF2F5",
    padding: "12px",
    borderRadius: "16px",
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "28px"
    }
  },
  blockCount: {
    fontSize: "19px",
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  rowWidget: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "4%"
  },
  widgetTextSubTitle: {
    fontSize: "16px",
    color: theme.palette.primary.main
  },
  widgetContentGridWrapper: {
    margin: "auto",
    padding: "0 4% 0 8%"
  },
  statusStatsWrapper: {
    margin: "0.5rem",
    padding: "1.5rem"
  },
  statusStatsWrapperMetrics: {
    fontSize: "18px",
    fontWeight: "bold"
  }
};
