import React from 'react';
import { Box, Collapse } from '@mui/material';
import JobDescriptionInput from '../Inputs/JobDescription';
import dompurify from 'dompurify';
import { classes } from '../styles';
import DisplayHTMLCollapsible from '../../SharedComponents/DisplayHTMLCollapsible';

const DisplayDescription = ({
  useCustomDescription,
  jobDescription,
  generationJobDescription,
  setGenerationJobDescription,
  collapsed,
  setCollapsed,
  maxCharacters
}: {
  useCustomDescription: string;
  jobDescription: string;
  generationJobDescription: string;
  setGenerationJobDescription: (value: string) => void;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  maxCharacters: number;
}) => {
  const showDescription = () => {
    if (jobDescription.length > maxCharacters) {
      return (
        <Box>
          Your current job description is too long. Please shorten it to {maxCharacters} characters
          or less. Alternatively, you can input a custom description or job summary that adheres to
          the character limit.
        </Box>
      );
    } else if (jobDescription.length > 100) {
      return (
        <DisplayHTMLCollapsible
          content={jobDescription}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      );
    } else {
      return (
        <Box>
          This job doesn&apos;t have a description yet. Please add one to continue or input a custom
          description.
        </Box>
      );
    }
  };

  switch (useCustomDescription) {
    case 'description':
      return (
        <Box sx={classes.genericContainer}>
          <Box sx={classes.fieldSubHeader}>Job description</Box>
          {showDescription()}
        </Box>
      );
    case 'custom':
      return (
        <Box sx={{ ...classes.genericContainer, position: 'relative' }}>
          <Box sx={classes.fieldSubHeader}>Job description</Box>
          <JobDescriptionInput
            generationJobDescription={generationJobDescription}
            placeholder={`Paste your job description here (${maxCharacters} character limit)`}
            onChange={setGenerationJobDescription}
          />
          <Box
            sx={{
              ...classes.characterCounter,
              ...(generationJobDescription.length > maxCharacters && { color: '#E37D7A' })
            }}
          >
            {generationJobDescription.length}/{maxCharacters}
          </Box>
        </Box>
      );
    case 'summary':
      return (
        <Box sx={{ ...classes.genericContainer, position: 'relative' }}>
          <Box sx={classes.fieldSubHeader}>Job summary</Box>
          <JobDescriptionInput
            generationJobDescription={generationJobDescription}
            placeholder={`Summarise the job in a few paragraphs or dot points (${maxCharacters} character limit)`}
            onChange={setGenerationJobDescription}
          />
          <Box
            sx={{
              ...classes.characterCounter,
              ...(generationJobDescription.length > maxCharacters && { color: '#E37D7A' })
            }}
          >
            {generationJobDescription.length}/{maxCharacters}
          </Box>
        </Box>
      );
    default:
      return <>Something went wrong, please refresh the page and try again</>;
  }
};

export default DisplayDescription;
