var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  IconButton,
  TextField,
  Select,
  InputAdornment,
  MenuItem,
  Button,
  CircularProgress,
  Dialog
} from "@material-ui/core";
import { withScoutTheme } from "../../ThemeContext/ThemeContext";
import useStyles from "../AdEditor/useStyles";
import useSharedStyles from "../AdEditor/useStyles";
import axios from "axios";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { formatDate } from "react-day-picker/moment";
const budgetTypes = [["One time", "one_time"], ["Monthly Average*", "monthly"]];
function SponsoredJobEditor({ sponsoredAd, apiKey, accessToken, jobId }) {
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [costPerClick, setCostPerClick] = useState(0.25);
  const [isEndDate, setIsEndDate] = useState(!!sponsoredAd.end_date);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [budgetType, setBudgetType] = useState(budgetTypes.find((budget2) => budget2[1] === sponsoredAd.budget_type) || budgetTypes[0]);
  const [budget, setBudget] = useState(sponsoredAd.budget_amount);
  const [hireCount, setHireCount] = useState(sponsoredAd.hires_number);
  const [endDate, setEndDate] = useState(sponsoredAd.end_date || "");
  function handleSubmit() {
    return __async(this, null, function* () {
      setIsSubmitting(true);
      try {
        yield axios.put(`/api/v4/indeed/job_ads/${sponsoredAd.job_ad_id}/sponsored_job`, {
          indeed_access_token: accessToken,
          sponsored_job: {
            budget_type: budgetType[1],
            budget_amount: budget,
            hires_number: hireCount,
            end_date: endDate ? formatDate(endDate, "YYYY-MM-DD") : null
          }
        }, {
          headers: {
            "X-api-authenticate": apiKey
          }
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsSubmitting(false);
        setIsSuccessDialogOpen(true);
      }
    });
  }
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return /* @__PURE__ */ React.createElement("div", { className: classes.containerOuter }, /* @__PURE__ */ React.createElement("div", { className: classes.container }, /* @__PURE__ */ React.createElement("div", { className: classes.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Sponsor Job"), /* @__PURE__ */ React.createElement("span", { className: classes.sponsorJobSubTitle }, "Choosing the ", /* @__PURE__ */ React.createElement("b", null, "recommended budget"), " means your listing will get ", /* @__PURE__ */ React.createElement("b", null, "better visibility"), " and show up more often in search results, making it easier for relevant job seekers to find and apply to your job.", /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("br", null), "All currency values will use the currency defined in your Indeed account."), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: `${classes.previewType} ${isAdvanced ? "mobile" : ""}` }, /* @__PURE__ */ React.createElement("div", { onClick: () => setIsAdvanced(false) }, "Simple"), /* @__PURE__ */ React.createElement("div", { onClick: () => {
    setIsAdvanced(true);
    setBudgetType(budgetTypes[0]);
  } }, "Advanced")), /* @__PURE__ */ React.createElement(Grid, { container: true, className: classes.sponsorJobContainer }, isAdvanced ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: classes.formControl }, /* @__PURE__ */ React.createElement("h4", { className: classes.paragraphTitle }, "Job Budget"), /* @__PURE__ */ React.createElement("div", { className: classes.paragraph }, "Instead of paying a large amount up-front to post your job, choose a small amount you are willing to pay each time a job seeker clicks to view your job (the Maximum cost per click or Max CPC).", /* @__PURE__ */ React.createElement("ul", null, /* @__PURE__ */ React.createElement("li", null, "Most clicks on Indeed cost between $0.20 - $1.20 (USD)"), /* @__PURE__ */ React.createElement("li", null, "Jobs with higher Max CPCs tend to get seen more often"), /* @__PURE__ */ React.createElement("li", null, "You can change your Max CPC at any time")))), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Maximum cost per click"), /* @__PURE__ */ React.createElement(Grid, { container: true, classes: { root: `${classes.controlledNumberInput} ${classes.budgetInput}` } }, /* @__PURE__ */ React.createElement(
    TextField,
    {
      className: `${classes.textField} ${classes.inputAdornment}`,
      variant: "outlined",
      value: costPerClick,
      placeholder: "0",
      InputProps: {
        classes: { focused: classes.budgetInputFocused },
        startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "$")
      },
      onChange: (e) => {
        if (!e.target.value) setCostPerClick(null);
        else if (/^\d+(\.\d{0,2})?$/.test(e.target.value)) setCostPerClick(e.target.value);
      }
    }
  ))), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Job budget"), /* @__PURE__ */ React.createElement("div", { className: classes.subLabel }, "Choose the total amount you want to spend promoting your job on Indeed. You can change your job budget at any time."), /* @__PURE__ */ React.createElement(Grid, { container: true, classes: { root: `${classes.controlledNumberInput} ${classes.budgetInput}` } }, /* @__PURE__ */ React.createElement(
    TextField,
    {
      className: `${classes.textField} ${classes.inputAdornment}`,
      variant: "outlined",
      value: Number(budget).toLocaleString(),
      InputProps: {
        startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "$")
      },
      onChange: (e) => setBudget(e.target.value ? parseFloat(e.target.value.split(",").join("")) : 0)
    }
  ))), /* @__PURE__ */ React.createElement("span", { className: classes.mediumText }, "You'll get at least ", parseInt(budget / costPerClick), " clicks on your job if you use your entire budget.")) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Grid, { item: true, xs: 8 }, /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, required: true, className: classes.label }, "How many people do you need to hire?"), /* @__PURE__ */ React.createElement(Grid, { className: classes.hireCounter }, /* @__PURE__ */ React.createElement(IconButton, { disabled: hireCount <= 0, onClick: () => setHireCount(hireCount - 1) }, /* @__PURE__ */ React.createElement(RemoveIcon, null)), /* @__PURE__ */ React.createElement("div", null, hireCount), /* @__PURE__ */ React.createElement(IconButton, { onClick: () => setHireCount(hireCount + 1) }, /* @__PURE__ */ React.createElement(AddIcon, null)))), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: classes.formControl }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Job budget"), /* @__PURE__ */ React.createElement("span", { className: classes.subLabel }, "Recommended budget (value) based on your job title and location."), /* @__PURE__ */ React.createElement(Grid, { container: true, classes: { root: `${classes.controlledNumberInput} ${classes.budgetInput}` } }, /* @__PURE__ */ React.createElement(
    TextField,
    {
      className: `${classes.textField} ${classes.inputAdornment}`,
      variant: "outlined",
      value: Number(budget).toLocaleString(),
      InputProps: {
        startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "$")
      },
      onChange: (e) => setBudget(e.target.value ? parseFloat(e.target.value.split(",").join("")) : 0)
    }
  ), /* @__PURE__ */ React.createElement(
    Select,
    {
      classes: { select: classes.input, selectMenu: classes.menuItem },
      variant: "outlined",
      value: budgetType,
      onChange: (e) => setBudgetType(e.target.value),
      displayEmpty: true,
      renderValue: (value) => value[0]
    },
    budgetTypes.map(
      (type) => /* @__PURE__ */ React.createElement(MenuItem, { classes: { root: classes.menuItem }, key: type[1], value: type }, type[0])
    )
  )), /* @__PURE__ */ React.createElement("span", { className: classes.smallText }, "You can change the amount, pause or close your job at any time.")))), /* @__PURE__ */ React.createElement(Grid, { container: true, style: { marginTop: 35 } }, /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: "Set an end date (optional)",
      control: /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          checked: isEndDate,
          onChange: () => setIsEndDate(!isEndDate)
        }
      )
    }
  )), isEndDate && /* @__PURE__ */ React.createElement(
    DayPickerInput,
    {
      value: endDate,
      onDayChange: (day) => setEndDate(formatDate(day, "LL")),
      classNames: { container: `${classes.calendarContainer} ${classes.small}`, overlay: {} },
      format: "LL",
      placeholder: "Select a date"
    }
  ), /* @__PURE__ */ React.createElement(Grid, { container: true, style: { marginTop: 15 }, direction: "column" }, /* @__PURE__ */ React.createElement("span", { className: classes.smallText }, "* Actual daily spend may vary."), /* @__PURE__ */ React.createElement("span", { className: classes.smallText }, "** Based on Indeed's past results. This is not a guarantee of future performance."))), /* @__PURE__ */ React.createElement("div", { className: classes.submitButtonContainer }, /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      color: "secondary",
      onClick: handleSubmit,
      disabled: !budget || !hireCount
    },
    "Sponsor job",
    isSubmitting && /* @__PURE__ */ React.createElement(CircularProgress, { style: { marginLeft: 6 }, size: 20, color: "inherit" })
  ))))), /* @__PURE__ */ React.createElement(Dialog, { open: isSuccessDialogOpen, onClose: () => window.history.back(), classes: { paper: classes.successDialog } }, /* @__PURE__ */ React.createElement("div", { className: classes.successCheck }, /* @__PURE__ */ React.createElement(CheckCircleIcon, { color: "primary", fontSize: "large" })), /* @__PURE__ */ React.createElement("h4", null, "Success"), /* @__PURE__ */ React.createElement("span", null, "Your ad has been sponsored"), /* @__PURE__ */ React.createElement("div", { className: classes.dialogButtonContainer, style: { marginTop: 20 } }, /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      color: "secondary",
      className: sharedClasses.buttonSecondary,
      onClick: () => window.location = `/admin/jobs/${jobId}/job_ads`
    },
    "Back to ads"
  ))));
}
SponsoredJobEditor.propTypes = {
  sponsoredAd: PropTypes.object.isRequired,
  apiKey: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  jobId: PropTypes.number.isRequired
};
export default withScoutTheme(SponsoredJobEditor);
