import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { classes } from '../../../NewUI/Components/GenericTable/Dropdowns/styles';

const AddCurrentSearchButton = ({ isOpen, onClick }: { isOpen: boolean; onClick: () => void }) => {
  return (
    <Tooltip title="Add current search" placement="top" arrow>
      <IconButton
        id={`add-current-search-button`}
        sx={isOpen ? { ...classes.iconButton, ...classes.iconButtonOpen } : classes.iconButton}
        onClick={onClick}
      >
        <PlaylistAddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AddCurrentSearchButton;
