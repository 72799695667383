"use strict";
import { theme } from "../../ThemeContext/ThemeObject";
export const styles = {
  smartFormsPageContainer: {
    backgroundColor: theme.palette.common.white,
    padding: "40px"
  },
  smartFormsPageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "26px",
    padding: "0px 16px",
    "& h1": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "38px",
      color: "#666666",
      marginTop: "0"
    }
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "#DDDDDD",
    margin: "25px 0"
  },
  tabs: {
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      letterSpacing: "0",
      textTransform: "none",
      "&:focus": {
        outline: "none"
      }
    },
    "& button.Mui-selected": {
      fontWeight: "600",
      color: theme.palette.primary.main
    }
  },
  tabIndicator: {
    backgroundColor: theme.palette.action.main,
    marginLeft: 2
  },
  searchInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "regular",
    borderRadius: "6px",
    minHeight: "54px",
    "& legend": {
      display: "none"
    },
    "& svg": {
      fontSize: "31px",
      color: "#084D6D"
    },
    "& input": {
      padding: "10.5px 11px 15.5px 11px",
      color: theme.palette.common.black
    },
    "& fieldset": {
      borderColor: "#EAEEF0"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADEE0 !important"
      }
    }
  },
  defaultSwitch: {
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: theme.palette.common.black
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  textField: {
    marginLeft: 0.5,
    marginRight: "-3px"
  },
  backToApprovalFormsButton: {
    marginBottom: "20px",
    fontSize: "16px",
    color: "#eee",
    opacity: "0.8",
    display: "flex",
    alignItems: "center",
    "& div": {
      fontWeight: "600",
      color: "#666666",
      ":hover": {
        color: "#545454",
        cursor: "pointer"
      }
    }
  },
  iconGrey: {
    color: "#666666",
    fontSize: "28px"
  },
  actionsMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      width: "170px",
      padding: "14px 0px 14px 20px",
      marginTop: "12px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#666666",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  }
};
