"use strict";
import { theme } from "../../ThemeContext/ThemeObject";
export const styles = {
  tabs: {
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      letterSpacing: "0",
      color: "#939393",
      textTransform: "none",
      minWidth: "unset",
      "&:hover": {
        color: "#737373"
      },
      "&:focus": {
        outline: "none"
      }
    },
    "& button.Mui-selected": {
      fontWeight: "600",
      color: "#084D6D"
    }
  },
  tabIndicator: {
    backgroundColor: "#5BC4BF",
    margin: "0 16px"
  },
  filtersRow: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: "30px",
    columnGap: "17px"
  },
  jobPageContainer: {
    backgroundColor: "#FFFFFF",
    padding: "40px"
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "#EEEEEE",
    margin: "25px 0"
  },
  searchInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "regular",
    borderRadius: "6px",
    minHeight: "54px",
    "& legend": {
      display: "none"
    },
    "& svg": {
      fontSize: "31px",
      color: "#084D6D"
    },
    "& input": {
      padding: "10.5px 11px 15.5px 11px",
      color: "#000000"
    },
    "& fieldset": {
      borderColor: "#EAEEF0"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADEE0 !important"
      }
    }
  },
  jobPageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "26px",
    padding: "0px 16px",
    "& h1": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "38px",
      color: "#666666",
      marginTop: "0"
    }
  },
  multiposterContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "40px"
  },
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D",
      transform: "translateY(1px)"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  popover: {
    zIndex: 12,
    background: "inherit"
  },
  filterPopoverContent: {
    width: "272px",
    height: "auto",
    overflowY: "visible",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    marginTop: 2,
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  filterPopoverHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    backgroundColor: "white",
    position: "sticky",
    zIndex: 99,
    paddingTop: "26px",
    paddingBottom: "2px",
    boxShadow: "0px 6px 10px 7px #ffffff"
  },
  clearButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#939393",
    backgroundColor: "rgba(147, 147, 147, 0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "rgba(147, 147, 147, 0.24)",
      boxShadow: "none"
    },
    "& .MuiButton-endIcon svg": {
      fontSize: "10px"
    }
  },
  applyButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 23px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  filterOptionContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    marginBottom: "12px"
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    marginTop: 2
  },
  switchLabels: {
    justifyContent: "space-between",
    width: "200px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    },
    "& :hover": {
      "& .MuiSwitch-track": {
        backgroundColor: "#000 !important"
      }
    }
  },
  switchActive: {
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    }
  },
  iconButtonSelected: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
    marginBottom: "30px",
    width: "100%",
    columnGap: "16px",
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  buttonRedLight: {
    color: "#E37D7A",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "8px 18px",
    fontWeight: "bold",
    fontSize: "14px",
    textDecoration: "underline",
    backgroundColor: "#ffffff",
    "&:hover": {
      color: "#d74742",
      backgroundColor: "#ffffff"
    }
  },
  normalButton: {
    color: "#000000",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "8px 18px",
    fontSize: "14px",
    textDecoration: "underline",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff"
    }
  },
  revertloadButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 18px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  actionsModal: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 60px",
    maxWidth: "800px",
    width: "90%",
    maxHeight: "700px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto"
  },
  modalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  approversHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#666666"
  },
  modalEditButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "rgb(91,196,191)",
    background: "rgb(91,196,191,0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#5BC4BF",
      color: "#FFFFFF",
      boxShadow: "none"
    }
  },
  iconGrey: {
    color: "#666666",
    fontSize: "28px"
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: 2,
    width: "100%"
  },
  backButton: {
    marginLeft: 1,
    fontSize: "16px",
    color: "#eee",
    opacity: "0.8",
    display: "flex",
    alignItems: "center",
    "& div": {
      fontWeight: "600",
      color: "#666666",
      ":hover": {
        color: "#545454",
        cursor: "pointer"
      }
    }
  },
  modalSaveButton: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    textTransform: "none",
    color: "rgb(91,196,191)",
    background: "rgb(91,196,191,0.12)",
    borderRadius: "6px",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#5BC4BF",
      color: "#FFFFFF",
      boxShadow: "none"
    }
  },
  approverItemList: {
    display: "flex",
    marginTop: 3,
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: 2
  },
  tableSettingsHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: 1,
    paddingLeft: "4px"
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "20px",
    padding: "8px 16px"
  },
  backToApprovalFormsButton: {
    marginBottom: "20px",
    fontSize: "16px",
    color: "#eee",
    opacity: "0.8",
    display: "flex",
    alignItems: "center",
    "& div": {
      fontWeight: "600",
      color: "#666666",
      ":hover": {
        color: "#545454",
        cursor: "pointer"
      }
    }
  },
  approvalFormSubtitle: {
    color: "#939393",
    paddingLeft: 2,
    paddingBottom: 2,
    fontSize: "16px"
  },
  approved: {
    color: "#8CCF95",
    backgroundColor: "RGBA(140, 207, 149, 0.12)"
  },
  draft: {
    color: "#FFFFFF",
    backgroundColor: "#CCCCCC"
  },
  pending: {
    color: "#E6A052",
    backgroundColor: "RGBA(230, 160, 82, 0.12)"
  },
  declined: {
    color: "#D6827D",
    backgroundColor: "RGBA(214, 130, 125, 0.12)"
  },
  approverTableStateButton: {
    alignItems: "center",
    fontWeight: "bold",
    fontFamily: "Source Sans Pro",
    width: "105px",
    padding: "2px 12px",
    borderRadius: "6px",
    textTransform: "uppercase"
  },
  questionLabel: {
    color: "#939393",
    fontSize: "14px",
    fontFamily: "Source Sans Pro, sans-serif",
    paddingBottom: 1,
    overflowWrap: "anywhere"
  },
  sectionHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#666666",
    padding: "8px 0px"
  },
  approversTableHeader: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    color: "#084D6D",
    border: "unset",
    backgroundColor: "#FFFFFF",
    textTransform: "capitalize",
    padding: 1
  },
  approversTableCell: {
    border: "unset",
    padding: 1
  },
  coiColumn: {
    height: `50px`,
    margin: "0 auto",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  link: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    color: theme.palette.primary.main
  },
  actionGroup: {
    display: "flex",
    alignItems: "center"
  },
  availabilityTableBody: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    border: "unset",
    padding: 1
  },
  availabilityTableHeader: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    color: "#084D6D",
    textTransform: "capitalize",
    border: "unset",
    backgroundColor: "inherit",
    padding: "2px 8px"
  },
  fileIcon: {
    width: "50px",
    height: "50px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    backgroundColor: "#084D6D10",
    "& svg": {
      color: theme.palette.primary.main,
      margin: "0px 2px 2px 0px"
    }
  },
  fileName: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    fontWeight: "bold"
  },
  automationContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
    h2: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#666666",
      marginBottom: "24px"
    }
  },
  toggleButtonsContainer: {
    marginTop: "20px",
    marginBottom: "40px"
  },
  actionsMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      width: "170px",
      padding: "14px 0px 14px 20px",
      marginTop: "12px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#666666",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  applicationIcon: {
    fontSize: "24px",
    "& svg": { color: theme.palette.primary.main }
  },
  approvalFormIconsContainer: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "end",
    columnGap: 1,
    marginTop: 1
  },
  mandatoryLabel: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    marginLeft: 1
  },
  sortableField: {
    display: "flex",
    alignItems: "center",
    width: "204px"
  },
  sortableSwitchLabels: {
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "4px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  }
};
