import React from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import { convertPreferredSkills } from '../config';
import { classes } from '../styles';

const OptionalInputButtons = ({
  jobDetails,
  pillClickHandler,
  aiStudioFrenchEnabled
}: {
  jobDetails: Record<string, string>;
  pillClickHandler: (pillType: string) => void;
  aiStudioFrenchEnabled: boolean;
}) => {
  return (
    <Box sx={classes.pillsContainer}>
      <Button
        sx={jobDetails.preferred_skills ? classes.filledPill : classes.pill}
        endIcon={jobDetails.preferred_skills ? <DoneIcon /> : <AddIcon />}
        onClick={() => pillClickHandler('preferred_skills')}
      >
        {convertPreferredSkills[aiStudioFrenchEnabled ? 'french' : 'english']['preferred_skills']}
      </Button>
      <Button
        sx={jobDetails.position_responsibilities ? classes.filledPill : classes.pill}
        endIcon={jobDetails.position_responsibilities ? <DoneIcon /> : <AddIcon />}
        onClick={() => pillClickHandler('position_responsibilities')}
      >
        {
          convertPreferredSkills[aiStudioFrenchEnabled ? 'french' : 'english'][
            'position_responsibilities'
          ]
        }
      </Button>
      <Button
        sx={jobDetails.position_location ? classes.filledPill : classes.pill}
        endIcon={jobDetails.position_location ? <DoneIcon /> : <AddIcon />}
        onClick={() => pillClickHandler('position_location')}
      >
        {convertPreferredSkills[aiStudioFrenchEnabled ? 'french' : 'english']['position_location']}
      </Button>
      <Button
        sx={jobDetails.salary ? classes.filledPill : classes.pill}
        endIcon={jobDetails.salary ? <DoneIcon /> : <AddIcon />}
        onClick={() => pillClickHandler('salary')}
      >
        {convertPreferredSkills[aiStudioFrenchEnabled ? 'french' : 'english']['salary']}
      </Button>
      <Button
        sx={jobDetails.company_name ? classes.filledPill : classes.pill}
        endIcon={jobDetails.company_name ? <DoneIcon /> : <AddIcon />}
        onClick={() => pillClickHandler('company_name')}
      >
        {convertPreferredSkills[aiStudioFrenchEnabled ? 'french' : 'english']['company_name']}
      </Button>
      <Button
        sx={jobDetails.company_description ? classes.filledPill : classes.pill}
        endIcon={jobDetails.company_description ? <DoneIcon /> : <AddIcon />}
        onClick={() => pillClickHandler('company_description')}
      >
        {
          convertPreferredSkills[aiStudioFrenchEnabled ? 'french' : 'english'][
            'company_description'
          ]
        }
      </Button>
      <Button
        sx={jobDetails.company_benefits ? classes.filledPill : classes.pill}
        endIcon={jobDetails.company_benefits ? <DoneIcon /> : <AddIcon />}
        onClick={() => pillClickHandler('company_benefits')}
      >
        {convertPreferredSkills[aiStudioFrenchEnabled ? 'french' : 'english']['company_benefits']}
      </Button>
    </Box>
  );
};

export default OptionalInputButtons;
