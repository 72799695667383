import React, { useState } from 'react';
import {
  Box,
  Popover,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  Tooltip
} from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { IDropdownProps } from '../../types';
import { classes } from '../styles';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import SortableField from './SortableField';

export default function FieldDropdown({
  sortableColumns,
  setSortableColumns,
  showGender,
  density,
  setDensity,
  setUserPreferences
}: IDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleFieldSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSortableColumns = sortableColumns.map((field) => {
      if (field.id === event.target.name) {
        return { ...field, enabled: event.target.checked };
      } else {
        return field;
      }
    });
    setSortableColumns(newSortableColumns);
    setUserPreferences(null, null, newSortableColumns, null, null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = sortableColumns.map((c) => c.id).indexOf(active.id);
      const newIndex = sortableColumns.map((c) => c.id).indexOf(over.id);
      const newArray = arrayMove(sortableColumns, oldIndex, newIndex);

      setSortableColumns(newArray);
      setUserPreferences(null, null, newArray, null, null);
    }
  }

  return (
    <Tooltip title="Table settings" placement="top" arrow>
      <Box>
        <IconButton
          id="field-dropdown-applications-table-button"
          sx={
            dropdownOpen ? { ...classes.iconButton, ...classes.iconButtonOpen } : classes.iconButton
          }
          onClick={handleClick}
        >
          <ViewColumnIcon />
        </IconButton>
        <Popover
          id="field-dropdown-popover"
          sx={classes.popover}
          open={dropdownOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box sx={classes.popoverContent}>
            <Stack sx={classes.tableSettingsHeader}>Column</Stack>
            <FormControl component="fieldset" variant="standard">
              <FormGroup id="field-dropdown-switches">
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext items={sortableColumns} strategy={verticalListSortingStrategy}>
                    {sortableColumns.map((field, index) => {
                      if (field.canToggle === false || (field.id === 'gender' && !showGender))
                        return null;
                      return (
                        <SortableField
                          key={index}
                          field={field}
                          handleFieldSwitchChange={handleFieldSwitchChange}
                        />
                      );
                    })}
                  </SortableContext>
                </DndContext>
              </FormGroup>
            </FormControl>
            <Stack sx={{ ...classes.tableSettingsHeader, paddingTop: 2 }}>Density</Stack>
            <FormControl>
              <FormGroup>
                {['Default', 'Compact', 'Ultra Compact'].map((size, index) => {
                  const isSwitched = size === density;
                  return (
                    <FormControlLabel
                      sx={
                        !isSwitched
                          ? { ...classes.switchLabels, ...classes.labelActive }
                          : classes.switchLabels
                      }
                      key={index}
                      control={
                        <Switch
                          sx={isSwitched ? classes.switchActive : classes.switch}
                          checked={isSwitched}
                          onChange={(event) => {
                            setDensity(size);
                            setUserPreferences(null, null, null, null, size);
                          }}
                          name={'density'}
                        />
                      }
                      label={size}
                      labelPlacement="start"
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Box>
        </Popover>
      </Box>
    </Tooltip>
  );
}
