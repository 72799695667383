import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ScoutCalendar from './ScoutCalendar';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export interface IScoutCalendarProps {
  apiKey: string;
  currentUser: { id: number; time_zone: string | null };
  token: string;
}

export default function ScoutCalendarPage({ apiKey, currentUser, token }: IScoutCalendarProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ScoutCalendar apiKey={apiKey} currentUser={currentUser} token={token} />
    </QueryClientProvider>
  );
}
