"use strict";
import { theme } from "../../../../ThemeContext/ThemeObject";
export const classes = {
  buttonRedLight: {
    color: "#E37D7A",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "8px 18px",
    fontWeight: "bold",
    fontSize: "15px",
    textDecoration: "underline",
    backgroundColor: "#ffffff",
    "&:hover": {
      color: "#d74742",
      backgroundColor: "#ffffff"
    }
  },
  revertloadButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 18px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  noRevisions: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  revisionsModalContent: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    maxWidth: "800px",
    width: "90%",
    height: "95%",
    maxHeight: "700px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  revisionsTitle: {
    fontFamily: "Source Sans Pro",
    fontSize: "30px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column"
  },
  revisionsSubTitle: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    marginTop: "35px"
  },
  revisionsContent: {
    fontSize: "12px"
  },
  revisionsVersionsIcon: {
    padding: "0rem 0.5rem",
    background: "#8CCAF7",
    height: "1.65rem",
    borderRadius: "1rem",
    color: "#ffffff",
    "& svg": {
      height: "0.75rem",
      width: "0.75rem"
    }
  },
  sliderInput: {
    color: "#5BC4BF",
    "& span": {
      fontSize: "12px"
    }
  },
  revisionsActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
    width: "100%",
    columnGap: "16px",
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  revisionsVersionsContent: {
    display: "flex",
    alignItems: "center",
    padding: "0 0.5rem"
  },
  revisionsVersionsContentDescription: {
    alignItems: "center",
    padding: "0 0.5rem"
  },
  htmlContent: {
    boxShadow: "inset 0px 0px 10px rgba(0, 0, 0, 0.09)",
    borderRadius: "0.5rem",
    color: "#939393",
    "& p": {
      marginTop: "0"
    }
  },
  optionsText: {
    fontWeight: "700",
    margin: "0 3px"
  },
  revisionsVersionsContentBlue: {
    color: "#084D6D",
    cursor: "pointer",
    textDecoration: "underline"
  },
  headerContent: {
    boxShadow: "inset 0px 0px 10px rgba(0, 0, 0, 0.09)",
    borderRadius: "6px 0 0 0",
    background: "rgba(221, 221, 221, 0.24)",
    padding: "5px 10px",
    width: "85px",
    textAlign: "center",
    fontWeight: "700"
  },
  changesTableContent: {
    display: "flex",
    fontSize: "14.5px",
    color: "#666666",
    marginBottom: "13px"
  },
  attachmentContent: {
    padding: "1rem 1rem 1rem 1rem",
    position: "relative"
  },
  noAttachments: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    top: "-12px",
    fontWeight: "bold"
  },
  codeChangesOnly: {
    position: "absolute",
    top: "10px",
    left: "calc(50% - 85px)",
    color: "#999999",
    fontWeight: "bold"
  }
};
