import React, { Dispatch, useState } from 'react';
import { Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Delete, History } from '@mui/icons-material';
import StyledModal from '../Components/GenericModal/StyledModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from './API';
import { styles } from './styles';
import { sharedClasses } from '../Components/CustomUIElements/sharedClasses';
import { classes as tableStyles } from '../../reports/SystemReports/styles';
import { ApplicationAction, IApplication } from './types';
import TableContainer from '@mui/material/TableContainer';
import { theme } from '../../ThemeContext/ThemeObject';
import TableSkeleton from '../Components/Multiposter/TableSkeleton';
import GenericDialog from '../Components/Modals/GenericDialog';
import CircularProgress from '@mui/material/CircularProgress';

export default function SearchIndex({
  isOpen,
  handleClose,
  dispatch
}: {
  isOpen: boolean;
  handleClose: () => void;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const { data: searchIndexData, isLoading: isLoadingSearchIndex } = useQuery({
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getSearchIndex(application?.job.id, application.id);
        return res;
      }
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in getting Search Index, ${error}`,
          state: 'error'
        }
      });
    },
    refetchOnMount: false,
    queryKey: ['searchIndex']
  });

  const { mutate: updateSearchIndex, isLoading: updatingSearchIndex } = useMutation({
    mutationFn: async () => {
      if (!application) return;
      const { res } = await Api.updateSearchIndex(application?.job.id, application.id);
      return res;
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an issue updating search index, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const { mutate: deleteSearchIndex, isLoading: deletingSearchIndex } = useMutation({
    mutationFn: async () => {
      if (!application) return;
      const { res } = await Api.deleteSearchIndex(application?.job.id, application.id);
      return res;
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an issue deleting search index, ${error.error}`,
          state: 'error'
        }
      });
    },
    onSettled: () => {
      setIsDialogOpen(false);
    }
  });

  const deleteDialogDescription = () => (
    <Stack>
      <Stack>Are you sure you want to destroy</Stack>
      <Stack>this search index?</Stack>
    </Stack>
  );

  return (
    <StyledModal
      isOpen={isOpen}
      label="Search Index modal"
      handleClose={handleClose}
      styleOverrides={{ ...styles.modalStyleOverrides, width: '775px', height: '600px' }}
      closeIconId="close-duplicated-candidates-modal-button"
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Search Index</Stack>
        <Stack flexDirection="row" gap={2}>
          <Button
            sx={{ ...sharedClasses.genericButtonSecondary, minWidth: '200px' }}
            startIcon={
              updatingSearchIndex ? <CircularProgress size={20} color="inherit" /> : <History />
            }
            disabled={updatingSearchIndex}
            onClick={() => updateSearchIndex()}
          >
            {updatingSearchIndex ? 'Updating Search Index' : 'Update Search Index'}
          </Button>
          <Button
            sx={sharedClasses.genericRedButton}
            id="delete-search-index-button"
            startIcon={<Delete />}
            onClick={() => {
              setIsDialogOpen(true);
            }}
          >
            Delete Search Index
          </Button>
        </Stack>
        <Stack>
          <Table sx={tableStyles.tableStyling}>
            <TableHead>
              <TableRow>
                {['Keys', 'Scout Data', 'Search Index'].map((title, index) => (
                  <TableCell
                    key={index}
                    sx={{ ...tableStyles.systemReportsTableHeader, backgroundColor: 'inherit' }}
                  >
                    {title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>

          {isLoadingSearchIndex ? (
            <TableSkeleton size={4} />
          ) : (
            <TableContainer sx={{ maxHeight: 325 }}>
              <Table sx={tableStyles.tableStyling}>
                <TableBody>
                  {searchIndexData &&
                    searchIndexData.keys.map((key: string) => {
                      const sameData =
                        searchIndexData?.scout_data[key] === searchIndexData?.search_data[key];
                      return (
                        <TableRow key={key} sx={{ td: { overflowWrap: 'anywhere' } }}>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              color: theme.palette.common.grey,
                              width: '160px'
                            }}
                          >
                            {key}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '252px',
                              color: sameData
                                ? theme.palette.common.black
                                : theme.palette.secondary.mediumRed
                            }}
                          >
                            {searchIndexData?.scout_data[key] || '-'}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: sameData
                                ? theme.palette.common.black
                                : theme.palette.secondary.mediumRed
                            }}
                          >
                            {searchIndexData?.search_data[key] || '-'}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      </Stack>
      <GenericDialog
        isDialogOpen={isDialogOpen}
        setDialogOpen={setIsDialogOpen}
        title="Destroy search index?"
        description={deleteDialogDescription()}
        buttonCallback={() => deleteSearchIndex()}
        callbackLoading={deletingSearchIndex}
        buttonText="Destroy"
        url=""
        primaryButtonId="delete-search-index-button"
        secondaryButtonId="cancel-delete-search-index-button"
      />
    </StyledModal>
  );
}
