"use strict";
import { theme } from "../../ThemeContext/ThemeObject";
export const classes = {
  jobPageContainer: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "#FFFFFF",
    padding: "40px"
  },
  editTabsContainer: {
    flexGrow: 1,
    display: "flex",
    borderColor: "none"
  },
  editTabIndicator: {
    backgroundColor: "#084D6D",
    left: "0",
    width: "3px",
    borderRadius: "0 2rem 2rem 0"
  },
  editTabs: {
    maxWidth: "230px",
    minWidth: "210px",
    borderRadius: "8px 0 0 8px",
    height: "fit-content",
    paddingBottom: "1rem",
    backgroundColor: "#f6f6f6",
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      letterSpacing: "0",
      minHeight: "45px",
      maxHeight: "45px",
      textAlign: "left",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "black",
      textTransform: "none",
      minWidth: "unset",
      "&:hover": {
        fontWeight: "600"
      },
      "&:focus": {
        outline: "none"
      }
    },
    "& svg": {
      height: "1.5rem",
      marginRight: "16px !important",
      color: "#084D6D"
    },
    "& button.Mui-selected": {
      fontWeight: "600",
      backgroundColor: "#eee",
      color: "black"
    }
  },
  tabsSubheader: {
    margin: "25px 0 10px 0",
    paddingLeft: "14px",
    fontWeight: "700",
    fontSize: "12px",
    height: "12px"
  },
  tabs: {
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      letterSpacing: "0",
      color: "#939393",
      textTransform: "none",
      minWidth: "unset",
      "&:hover": {
        color: "#737373"
      },
      "&:focus": {
        outline: "none"
      }
    },
    "& button.Mui-selected": {
      fontWeight: "600",
      color: "#084D6D"
    }
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "#EEEEEE",
    margin: "35px 0 25px 0"
  },
  tabIndicator: {
    backgroundColor: "#5BC4BF",
    margin: "0 16px"
  },
  backToJobButton: {
    marginBottom: "20px",
    fontSize: "16px",
    color: "#eee",
    opacity: "0.8",
    display: "flex",
    alignItems: "center",
    "& div": {
      fontWeight: "600",
      color: "#666666",
      ":hover": {
        color: "#545454",
        cursor: "pointer"
      }
    }
  },
  iconGrey: {
    color: "#737373",
    fontSize: "28px"
  },
  editJobPageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px",
    "& h1": {
      fontWeight: "bold",
      fontSize: "38px",
      lineHeight: "1.2em",
      color: "#666666",
      marginTop: "0",
      "@media (max-width: 1450px)": {
        fontSize: "34px"
      },
      "@media (max-width: 1260px)": {
        fontSize: "28px"
      }
    }
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "20px",
    padding: "6px 16px",
    width: "100%",
    "& a:hover": {
      textDecoration: "none"
    }
  },
  link: {
    fontSize: "19px",
    color: "#939393",
    cursor: "pointer",
    transition: "color 0.2s ease-in-out",
    "&:hover": {
      color: "#737373"
    },
    "@media (max-width: 1450px)": {
      fontSize: "16px"
    },
    "@media (max-width: 1260px)": {
      fontSize: "14px"
    }
  },
  copyButton: {
    fontSize: "12px",
    fontWeight: "bold",
    backgroundColor: "#5BC4BF1F",
    color: theme.palette.action.main,
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.action.main,
      color: "#FFFFFF"
    }
  },
  saveButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 19px",
    width: "91px",
    height: "41px",
    margin: "8px 0",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  tabFooter: {
    display: "flex",
    flexDirection: "row",
    padding: "1em",
    justifyContent: "flex-end",
    columnGap: "20px"
  },
  toggleIcon: {
    margin: "25px 0 10px 0",
    "&:hover": {
      cursor: "pointer"
    }
  },
  tabContent: {
    display: "flex",
    flexDirection: "column",
    margin: "20px 0px 40px 0"
  },
  headerContent: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#727272",
    marginBottom: "1rem",
    lineHeight: "2rem"
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "28px"
  },
  bodyContainer: {
    padding: "8px 0",
    display: "grid"
  },
  hintText: {
    fontFamily: "Source Sans Pro",
    color: "#939393",
    fontSize: "12px",
    margin: "6px 0px"
  },
  warnText: {
    fontFamily: "Source Sans Pro",
    color: "#E37D7A",
    fontSize: "14px",
    margin: "6px 0px"
  },
  text: {
    fontFamily: "Source Sans Pro",
    color: "#939393",
    fontSize: "16px",
    margin: "20px 0px"
  },
  checkbox: {
    color: "#DDDDDD",
    "&.Mui-checked": {
      color: theme.palette.action.main
    },
    "& .MuiSvgIcon-root": {
      fontSize: "22px"
    }
  },
  label: {
    "& .MuiTypography-root": {
      color: "#939393",
      fontSize: "16px",
      fontFamily: "Source Sans Pro, sans-serif"
    }
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "20px",
    width: "100%",
    "& div": {
      width: "100%"
    }
  },
  selectGroup: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    columnGap: "10px"
  },
  addSourceButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#5BC4BF",
    backgroundColor: "#5BC4BF12",
    borderRadius: "6px",
    boxShadow: "none",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "#4da8a412",
      boxShadow: "none"
    },
    width: "100px"
  },
  applicationsContainer: {
    paddingTop: "6px"
  },
  accordionRoot: {
    boxShadow: "none",
    margin: "25px 0px",
    "&::before": {
      display: "none"
    },
    "&:first-of-type": {
      marginTop: "0px !important"
    },
    "&.Mui-expanded": {
      marginTop: "14px 0px 0px 0px"
    }
  },
  accordionSummary: {
    width: "fit-content",
    padding: "0"
  },
  datePicker: {
    fontFamily: "Source Sans Pro",
    width: "190px",
    color: "#666666",
    borderRadius: "6px",
    "& input": {
      padding: "5px 10px 7px 10px",
      fontFamily: "Source Sans Pro"
    },
    "& fieldset": {
      borderColor: "#DDDDDD",
      "& legend": {
        display: "none"
      }
    },
    "& button": {
      padding: "0px",
      transform: "translate(-2px, -1.5px)",
      "&:hover": {
        background: "transparent",
        "& svg": {
          color: "#888888"
        }
      }
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0px"
    },
    "& svg": {
      color: "#AAAAAA"
    }
  },
  errorBox: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    position: "absolute",
    bottom: "-30px",
    height: "2rem",
    left: "4px"
  },
  generalModalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    marginBottom: "26px",
    width: "100%",
    alignItems: "center"
  },
  fullWidthFieldContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "26px"
  },
  fullWidthField: {
    width: "100%",
    marginBottom: "26px"
  },
  formAutocomplete: {
    "& .MuiAutocomplete-root": {
      "& .MuiOutlinedInput-root": {
        padding: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "0px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "& .MuiAutocomplete-input": {
        padding: "6px 4px 6px 11px"
      }
    },
    "& fieldset": {
      display: "none"
    },
    "& label": {
      color: "#838383 !important",
      fontSize: "21px",
      fontFamily: "Source Sans Pro, sans-serif",
      paddingLeft: "4px",
      left: "-13px",
      top: "-15px",
      "& .MuiFormLabel-asterisk": {
        color: theme.palette.error.main
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      transition: "all 0.25s ease-in-out",
      color: "#AAAAAA"
    }
  },
  classificationLabel: {
    "& span": {
      color: "#838383",
      fontSize: "16px !important",
      fontFamily: "Source Sans Pro,sans-serif",
      marginLeft: "0"
    }
  },
  lastSaved: {
    fontStyle: "italic",
    fontSize: "12px",
    color: "#666666",
    opacity: "0.8",
    flexDirection: "row",
    alignItems: "center"
  },
  backAndLastSaveContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    alignItems: "center"
  },
  loadingWrapper: {
    position: "relative",
    display: "grid",
    placeItems: "center",
    animation: "0.5s shine linear infinite",
    transition: "background-color 2s ease-out"
  },
  loadingContent: {
    background: "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent)",
    animation: "2s shine linear infinite",
    transition: "background-color 2s ease-out",
    "& canvas": {
      opacity: "0.35"
    }
  },
  graphWrapper: {
    padding: "0 1rem 1.5rem 0"
  },
  noDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "80px 0px"
  },
  noDataText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px",
    textAlign: "center"
  },
  generateButton: {
    width: "91px",
    height: "41px",
    margin: "16px 0 0 0"
  },
  buttonContainer: {
    position: "relative",
    cursor: "pointer"
  },
  button: {
    textTransform: "none",
    backgroundColor: "#F2F2F2",
    color: "#4E4EC7",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "700",
    padding: "8px 0px",
    paddingRight: "20px",
    paddingLeft: "54px",
    borderRadius: "22px",
    "&:hover": {
      backgroundColor: "#F2F2F2",
      color: "#4E4EC7"
    }
  },
  avatarContainer: {
    width: "44px",
    height: "44px",
    borderRadius: "50%",
    zIndex: 1,
    position: "absolute",
    top: "0px",
    left: "-1px",
    background: "linear-gradient(225.64deg, #E548FF -23.43%, #084D6D 140.57%, #084D6D 140.58%, #5BC4C0 140.6%)",
    boxShadow: "2px 3px 4px rgba(36, 36, 36, 0.18)"
  },
  hoverAnimation: {
    "@keyframes grow": {
      "0%": {
        transform: "scale(1) translate(2px, -3px)"
      },
      "100%": {
        transform: "scale(1.11) translate(2px, -5px)"
      }
    },
    "& svg": {
      animation: "grow 0.7s forwards"
    }
  },
  unhoverAnimation: {
    "@keyframes shrink": {
      "0%": {
        transform: "scale(1.11) translate(2px, -5px)"
      },
      "100%": {
        transform: "scale(1) translate(2px, -3px)"
      }
    },
    "& svg": {
      animation: "shrink 0.7s forwards"
    }
  },
  betaPill: {
    position: "absolute",
    top: "-8px",
    right: "2px",
    zIndex: 1,
    backgroundColor: "#5BC4C0",
    color: "#FFFFFF",
    fontFamily: "Source Sans Pro",
    fontSize: "9.5px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "16px",
    width: "30px",
    borderRadius: "8px"
  }
};
