import React, { useCallback } from 'react';
import { Box, TableRow, TableCell, Checkbox, CircularProgress, Tooltip } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import moment from 'moment-timezone';
import { classes } from './useStyles';
import { IJob, ICurrentJobsTableRowProps } from '../types';
import NotesWithSubjectLine from '../../Components/GenericTable/TableCells/NotesWithSubjectLine';
import { SortableColumnId } from '../../Job/types';

export default function CurrentJobsTableRow({
  jobs,
  userPermissions,
  BASE_URL,
  isPinning,
  sortableColumns,
  tempState,
  stateIsSending,
  stateError,
  tempStatus,
  statusIsSending,
  statusDropdownRef,
  noteRefs,
  hovered,
  isSelected,
  showMore,
  handleShowMore,
  handleClick,
  handlePin,
  handleScrollableEnter,
  handleScrollableExit,
  handleStateDropdown,
  showDropdown,
  handleStatusDropdown,
  handleOpenNote,
  setActionsAnchorEl,
  setSelectedJob,
  showPinnedBorder,
  isPinnedSection,
  jobStatusFeature,
  isDashboard,
  userTimezone
}: ICurrentJobsTableRowProps) {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const renderTableCell = useCallback(
    (column, job, publishedAt, createdAt, expiresAt, notes, expiryDateColor, labelId, index) => {
      switch (column.id) {
        case 'reference':
          return (
            <TableCell
              key={job.reference + column.id}
              component="th"
              id={labelId}
              data-testid={`reference-cell-${index}-[CurrentJobsTableRow]`}
              className="reference-cell"
              scope="row"
              sx={{
                position: 'sticky',
                left: '110px',
                background: 'white',
                paddingRight: '8px !important',
                fontSize: '14px',
                fontFamily: 'Source Sans Pro',
                zIndex: '3',
                '& a': {
                  wordBreak: 'break-word',
                  color: '#000000'
                },
                '& a:hover': {
                  textDecoration: 'none'
                }
              }}
            >
              <a
                href={`${BASE_URL}${job.job_link || '/admin/jobs/' + job.id}`}
                className="job-reference-link"
                data-testid={`job-reference-link-${index}-[CurrentJobsTableRow]`}
              >
                {job.reference ? job.reference : '-'}
              </a>
            </TableCell>
          );
        case 'title':
          return (
            <TableCell
              key={job.reference + column.id}
              component="th"
              id={labelId}
              scope="row"
              className="title-cell"
              data-testid="title-cell-[CurrentJobsTableRow]"
              sx={{ ...classes.titleCell, ...(hovered ? classes.cellAnimate : {}) }}
            >
              <a
                href={`${BASE_URL}${job.job_link || '/admin/jobs/' + job.id}`}
                className="job-title-link"
                data-testid="job-title-link"
              >
                {job.title ? job.title : '-'}
              </a>
            </TableCell>
          );
        case 'published_at':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '8px !important',
                width: '120px !important',
                lineHeight: '1.22'
              }}
              align="left"
              className="published-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job.published_at ? publishedAt : '-'}
            </TableCell>
          );
        case 'created_at':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '8px !important',
                width: '120px !important',
                lineHeight: '1.22'
              }}
              align="left"
              className="created-cell"
              data-testid={`created-cell-${index}-[CurrentJobsTableRow]`}
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job.created_at ? createdAt : '-'}
            </TableCell>
          );
        case 'expires_at':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '8px !important',
                width: '120px !important',
                lineHeight: '1.22',
                color: expiryDateColor(job.expires_at)
              }}
              align="left"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job.expires_at ? expiresAt : '-'}
            </TableCell>
          );
        case 'applications_count':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '43px !important',
                '& a': {
                  wordBreak: 'break-word',
                  color: '#000000'
                },
                '& a:hover': {
                  textDecoration: 'none'
                }
              }}
              align="right"
              className="applications-cell"
              data-testid={`applications-cell-${index}-[CurrentJobsTableRow]`}
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              <a
                href={`${BASE_URL}/admin/jobs/${job.id}?applications=true`}
                className="application-count-link"
              >
                {job.applications_count} ({job.open_applications_count})
              </a>
            </TableCell>
          );
        case 'state':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{ paddingRight: '8px !important' }}
              className={'state-cell'}
              align="center"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {userPermissions?.Jobs?.['Create / Edit Jobs'] && job.job_states[1] ? (
                <Tooltip
                  open={Boolean(stateError.id === job.id)}
                  placement="top"
                  sx={classes.stateErrorTooltip}
                  title={stateError.message ? stateError.message : ''}
                  arrow
                >
                  <Box
                    className="state-dropdown"
                    sx={{
                      ...classes.stateColumn,
                      ...classes[
                        tempState[job.id as keyof typeof tempState]?.label.toLowerCase() ||
                          job.state
                      ],
                      cursor: 'pointer'
                    }}
                    onClick={(e) =>
                      handleStateDropdown(
                        e,
                        job.id,
                        tempState[job.id as keyof typeof tempState]?.list || job.job_states
                      )
                    }
                  >
                    {stateIsSending === job.id ? (
                      <CircularProgress size={18} sx={classes.stateLoader} />
                    ) : (
                      <>
                        {tempState[job.id as keyof typeof tempState]?.label || job.state}
                        &nbsp;&nbsp;&#9662;
                      </>
                    )}
                  </Box>
                </Tooltip>
              ) : (
                <Box sx={{ ...classes.stateColumn, ...classes[job.state] }}>{job.state}</Box>
              )}
            </TableCell>
          );
        case 'status':
          if (!jobStatusFeature) return null;
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '8px !important'
              }}
              className={'status-cell'}
              align="center"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job.job_statuses ? (
                userPermissions?.Jobs?.['Create / Edit Jobs'] &&
                showDropdown(
                  tempStatus[job.id as keyof typeof tempStatus]?.list || job.job_statuses
                ) ? (
                  <Box
                    className={`status-dropdown`}
                    sx={{ ...classes.statusDropdown, ...classes.statusClamp }}
                    onClick={(e) =>
                      handleStatusDropdown(
                        e,
                        job.id,
                        tempStatus[job.id as keyof typeof tempStatus]?.list || job.job_statuses
                      )
                    }
                    ref={(e) => (statusDropdownRef.current[index] = e)}
                  >
                    <>
                      <Box
                        className="status-dropdown-full"
                        sx={{
                          '&::after': {
                            display: 'block',
                            top:
                              statusDropdownRef.current[index] &&
                              statusDropdownRef.current[index].clientHeight > 45
                                ? '25.5px'
                                : '50%'
                          }
                        }}
                      >
                        {statusIsSending !== job.id &&
                          (tempStatus[job.id as keyof typeof tempStatus]?.label ||
                            (job.job_statuses?.length > 0 ? job.job_statuses[0].label : ''))}
                      </Box>
                      {statusIsSending === job.id ? (
                        <CircularProgress size={18} sx={classes.statusLoader} />
                      ) : (
                        <>
                          {tempStatus[job.id as keyof typeof tempStatus]?.label ||
                            (job.job_statuses?.length > 0 ? job.job_statuses[0].label : '')}
                        </>
                      )}
                    </>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      ...classes.statusClamp,
                      ...classes.noDropdown,
                      '& .status-dropdown-full': {
                        ...classes.statusClamp['& .status-dropdown-full'],
                        ...classes.noDropdown['& .status-dropdown-full']
                      }
                    }}
                  >
                    <Box className={'status-dropdown-full'} sx={classes.noDropdown}>
                      {statusIsSending !== job.id &&
                        (tempStatus[job.id as keyof typeof tempStatus]?.label ||
                          (job.job_statuses?.length > 0 ? job.job_statuses[0].label : ''))}
                    </Box>
                    {tempStatus[job.id as keyof typeof tempStatus]?.label ||
                      (job.job_statuses?.length > 0 ? job.job_statuses[0]?.label : '')}
                  </Box>
                )
              ) : (
                <Box sx={classes.noStatuses}>-</Box>
              )}
            </TableCell>
          );
        case 'entity':
          return userPermissions?.Jobs?.['Show Job Sub-Entity'] ? (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '8px !important',
                '& a': {
                  wordBreak: 'break-word',
                  color: '#000000'
                },
                '& a:hover': {
                  textDecoration: 'none'
                }
              }}
              align="left"
              className="entity-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              <a href={`${BASE_URL}${job.entity_link}`} className="job-entity-link">
                {job.entity ? job.entity : '-'}
              </a>
            </TableCell>
          ) : null;
        case 'location':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{ paddingRight: '8px !important' }}
              align="left"
              className="location-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job.location ? job.location : '-'}
            </TableCell>
          );
        case 'assigned_user':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{ paddingRight: '8px !important' }}
              align="left"
              className="assigned-user-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job?.assigned_user ? job.assigned_user : '-'}
            </TableCell>
          );
        case 'recruitment_ad_user':
          return userPermissions?.recruitment_team_enabled ? (
            <TableCell
              key={job.reference + column.id}
              sx={{ paddingRight: '8px !important' }}
              align="left"
              className="rm-user-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job?.recruitment_ad_user ? job.recruitment_ad_user : '-'}
            </TableCell>
          ) : null;
        case SortableColumnId.NOTES:
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{ minWidth: '300px', paddingRight: '8px !important' }}
              align="left"
              className="note-cell"
              onMouseEnter={handleScrollableEnter}
              onMouseLeave={handleScrollableExit}
              id={`notes-${labelId}`}
            >
              {notes.length > 0 ? <NotesWithSubjectLine notes={notes} /> : '-'}
            </TableCell>
          );
        default:
          return <TableCell></TableCell>;
      }
    },
    [
      BASE_URL,
      classes,
      handleScrollableEnter,
      handleScrollableExit,
      handleShowMore,
      handleStateDropdown,
      handleStatusDropdown,
      hovered,
      noteRefs,
      showDropdown,
      showMore,
      stateError.id,
      stateError.message,
      stateIsSending,
      statusDropdownRef,
      statusIsSending,
      tempState,
      tempStatus,
      userPermissions?.Jobs
    ]
  );
  return jobs.map((job: IJob, index: number) => {
    const jobTimeZone = job.time_zone ?? userTimezone ?? timeZoneString;
    const isItemSelected = isSelected(job.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const formatDate = (date: string | undefined, timeZone: string, jobId: number) =>
      date &&
      moment
        .tz(date, timeZone)
        .format('DD MMM YYYY,-h:mma z')
        .split('-')
        .map((line) => <div key={`${jobId}-${line}`}>{line}</div>);

    const publishedAt = formatDate(job.published_at, jobTimeZone, job.id);
    const createdAt = formatDate(job.created_at, jobTimeZone, job.id);
    const expiresAt = formatDate(job.expires_at, jobTimeZone, job.id);
    const notes = job.job_notes ? job.job_notes : [];
    const expiryDateColor = (date: string) => {
      const dayToExpired = (new Date(date).getTime() - Date.now()) / (1000 * 60 * 60 * 24);
      if (dayToExpired < 0) {
        return '#E37D7A';
      } else if (dayToExpired < 1) {
        return '#E6A052';
      } else {
        return '#333333';
      }
    };
    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={job.id}
        selected={isItemSelected}
        sx={
          showPinnedBorder && index === 0
            ? {
                '& td, th ': {
                  borderColor: 'rgba(230, 160, 82, 0.5)'
                }
              }
            : {}
        }
      >
        <TableCell
          padding="checkbox"
          sx={{
            position: 'sticky',
            left: 0,
            width: '60px !important',
            maxWidth: '60px',
            background: 'white',
            zIndex: '3'
          }}
        >
          <Checkbox
            className="individual-checkbox"
            data-testid={`individual-checkbox-${index}-[CurrentJobsTableRow]`}
            onClick={(event) => handleClick(event, job.id)}
            sx={{
              color: '#aaa !important',
              '&.Mui-checked': {
                color: '#15C7C0 !important'
              }
            }}
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId
            }}
          />
        </TableCell>
        <TableCell
          sx={{
            position: 'sticky',
            left: '60px',
            width: '50px !important',
            maxWidth: '50px',
            background: 'white',
            zIndex: '3'
          }}
        >
          {isPinning === job.id ? (
            <CircularProgress size={16} sx={{ color: '#E6A052' }} />
          ) : (
            <PushPinIcon
              onClick={(event) => handlePin(event, job.id, job.pinned_by_user)}
              sx={
                job.pinned_by_user
                  ? {
                      color: '#E6A052',
                      cursor: 'pointer'
                    }
                  : {
                      color: '#CCCCCC',
                      cursor: 'pointer'
                    }
              }
            />
          )}
        </TableCell>
        {sortableColumns.map((column) => {
          if (column.enabled) {
            return renderTableCell(
              column,
              job,
              publishedAt,
              createdAt,
              expiresAt,
              notes,
              expiryDateColor,
              labelId,
              index
            );
          }
        })}
        <TableCell
          align="right"
          sx={{
            ...(isDashboard && {
              minWidth: '140px',
              right: '-70px !important'
            }),
            ...classes.actionsCell,
            ...(hovered ? classes.cellAnimate : {})
          }}
        >
          <Box
            sx={{
              display: 'flex',
              '& svg': {
                fontSize: '20px !important'
              },
              justifyContent: 'end'
            }}
          >
            {userPermissions?.['Job Options']?.['View Job Notes'] && (
              <IconButton
                className="add-note-button"
                onClick={(e) => handleOpenNote(e, job.id, isPinnedSection)}
              >
                <NoteAddIcon sx={classes.iconGray} />
              </IconButton>
            )}
            {(userPermissions?.Jobs?.['Create / Edit Jobs'] ||
              userPermissions?.Jobs?.['Archive Jobs'] ||
              userPermissions?.Trash?.['Trash Jobs']) && (
              <IconButton
                className="job-actions-button"
                data-testid={`job-actions-button-${index}-[CurrentJobsTableRow]`}
                onClick={(e) => {
                  setActionsAnchorEl(e.currentTarget);
                  setSelectedJob({ id: job.id, states: null, statuses: null });
                }}
              >
                <MoreVertIcon sx={classes.iconGray} />
              </IconButton>
            )}
          </Box>
        </TableCell>
      </TableRow>
    );
  });
}
