import React, { useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Virtualize } from '../../Components/CustomUIElements/VirtualizedAutocomplete';
import Api from '../API';
import CircularProgress from '@mui/material/CircularProgress';
import { classes } from '../../Job/ActionModals/MoveJob/styles';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { StyledSnackbarProps } from '../../Components/CustomUIElements/StyledSnackbar';

export default function MoveApprovalTemplate({
  apiKey,
  approvalTemplateId,
  entity,
  getApprovalTemplates,
  setSnackbarState,
  setActionsAnchorEl
}: {
  apiKey: string;
  entity: string | undefined;
  approvalTemplateId: number;
  getApprovalTemplates: () => void;
  setActionsAnchorEl: (value: null) => void;
  setSnackbarState: StyledSnackbarProps['setSnackBarState'];
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [assignedMoveOptions, setMoveJobOptions] = useState<string[] | null>(null);
  const [moveTo, setMoveTo] = useState(null);

  const fetchMoveJobData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await Api.getTargetEntities({ 'X-api-authenticate': apiKey });
      setMoveJobOptions(data?.res?.entities.map((a) => `${a.id} - ${a.name}`));
    } catch (error) {
      setSnackbarState({
        message: error?.error,
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiKey]);

  const moveApprovalTemplate = async () => {
    if (moveTo) {
      if (typeof moveTo !== 'string') return setHasError(true);
      else setHasError(false);
    } else return setHasError(true);
    try {
      const entityId = moveTo.split(' -')[0];
      await Api.moveApprovalTemplate({ 'X-api-authenticate': apiKey }, approvalTemplateId, entityId);
      setSnackbarState({
        message: 'Your approval template has been moved',
        state: 'success'
      });
      setMoveTo(null);
      setIsDialogOpen(false);
      setActionsAnchorEl(null);
      getApprovalTemplates();
    } catch (error) {
      setSnackbarState({
        message: error?.error,
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  function setValue(value: string) {
    setMoveTo(value);
  }

  return (
    <Box>
      <Box
        onClick={() => {
          setIsDialogOpen(true);
          !assignedMoveOptions && fetchMoveJobData();
        }}
        id="move-approval-template-button"
      >
        Move
      </Box>
      <Modal
        open={isDialogOpen}
        aria-labelledby="modal-modal-title"
        onClose={() => setIsDialogOpen(false)}
      >
        <Box sx={classes.modalContent}>
          <CloseIcon onClick={() => setIsDialogOpen(false)} sx={classes.closeIcon} />
          <Box id="modal-modal-title" sx={classes.modalTitle}>
            Move Approval Template
          </Box>
          <Box sx={{ marginTop: '1rem', display: 'grid' }}>
            <Box sx={classes.subTitle}>Current entity: </Box>
            <Box sx={{ margin: '0.75rem 0' }}>{entity}</Box>
            <Box sx={{ marginTop: '2.25rem' }}>
              {assignedMoveOptions ? (
                <Box sx={{ marginTop: '1rem' }}>
                  {!isLoading ? (
                    <Box sx={classes.modalFormLine}>
                      <Virtualize
                        value={moveTo}
                        passOptions={assignedMoveOptions}
                        setValue={setValue}
                        passedStyles={{ width: '290px', ...sharedClasses.formAutocomplete }}
                        errorStyles={{ ...sharedClasses.errorBox, top: '-24px', right: '0px' }}
                        error={!moveTo && hasError}
                        label="Move to"
                      />
                    </Box>
                  ) : (
                    <CircularProgress size={20} color="inherit" />
                  )}
                </Box>
              ) : (
                <Box>
                  {isLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Box sx={classes.noEntitys}>No Entities are available</Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={classes.modalActions}>
            <Button
              disableElevation
              sx={{ ...classes.buttonRedLight }}
              variant="text"
              onClick={() => {
                setIsDialogOpen(false);
                setActionsAnchorEl(null);
              }}
            >
              Cancel
            </Button>
            <Button sx={classes.revertloadButton} onClick={() => moveApprovalTemplate()}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
