import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import DataVizSection from './DataVizSection';
import CustomReportsSection from './CustomReports';
import { Box, Tab, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { classes } from './styles';
import ReportServiceApi from './ReportServiceApi';
import RecruitReportApiService from './RecruitReportApiService';
import { CurrentUser, EntityRecord, CurrentEntity } from './types';
import SystemReportsSection from './SystemReports';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Info as InfoIcon } from '@mui/icons-material';
import CreateButton from '../NewUI/Components/Buttons/CreateButton';

const URL_PARAMS = new URL(window.location.href);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const getTabNumber = (urlParams: string) => {
  switch (urlParams) {
    case '?system-reports':
      return '1';
    case '?report-builder':
      return '2';
    case '?my-reports':
      return '3';
    case '?history':
      return '4';
    default:
      return '0';
  }
};

export const ReportContext = createContext<ReportServiceApi>(null);
export const RecruitReportContext = createContext<RecruitReportApiService>(null);
const ReportPage = ({
  accessible_entities,
  user,
  report_service_url,
  current_entity,
  jobStatusFeature
}: {
  accessible_entities: EntityRecord;
  user: CurrentUser;
  report_service_url: string;
  current_entity: CurrentEntity;
  jobStatusFeature: boolean;
}) => {
  const [value, setValue] = useState<string>(getTabNumber(URL_PARAMS.search).toString());
  const [indicatorWidth, setIndicatorWidth] = useState(1);
  const [openCreateReport, setOpenCreateReport] = useState<boolean>(false);
  const [previewReport, setPreviewReport] = useState<boolean>(false);
  const headerRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const currentElement = headerRefs.current[Number(value)];
    if (currentElement) {
      setIndicatorWidth(currentElement.clientWidth - 32);
    }
  }, [value]);

  const reportServiceApi = useCallback(
    () =>
      new ReportServiceApi(
        accessible_entities,
        user,
        report_service_url,
        current_entity,
        jobStatusFeature
      ),
    [accessible_entities, user, report_service_url, current_entity, jobStatusFeature]
  );

  const recruitReportApiService = useCallback(
    () => new RecruitReportApiService(accessible_entities, user),
    [accessible_entities, user]
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setOpenCreateReport(false);
    URL_PARAMS.search = getPageParams(newValue);
    window.history.pushState(null, '', URL_PARAMS.href);
  };

  const getPageParams = (tabNo: string) => {
    switch (tabNo) {
      case '0':
        return '';
      case '1':
        return '?system-reports';
      case '2':
        return '?report-builder';
      // case '3':
      //   return '?my-reports';
      // case '4':
      //   return '?history';
      default:
        return '';
    }
  };

  return (
    <RecruitReportContext.Provider value={recruitReportApiService()}>
      <ReportContext.Provider value={reportServiceApi()}>
        <QueryClientProvider client={queryClient}>
          {current_entity.new_reporting_enabled && (
            <Box sx={classes.reportPageContainer}>
              <Box sx={classes.reportPageHeader}>
                <h1>Reports</h1>
                {value === '2' && !previewReport && (
                  <CreateButton
                    id="create-report-button"
                    ariaLabel="create report"
                    onClick={() => {
                      setOpenCreateReport(true);
                    }}
                    disableDropDown
                  />
                )}
              </Box>
              <Stack sx={classes.disclaimerContainer}>
                <InfoIcon sx={classes.disclaimerIcon} />
                <Box>
                  This is a <strong>beta test</strong> environment made available for our valuable
                  clients to test and provide feedback. A selection of our most common used system
                  reports have been turned on for this testing environment.
                </Box>
              </Stack>
              <TabContext value={value}>
                <Box sx={classes.tabsContainer}>
                  <TabList
                    sx={{
                      ...classes.tabs,
                      '& .MuiTabs-indicator': {
                        ...classes.tabIndicator,
                        maxWidth: indicatorWidth + 'px'
                      }
                    }}
                    onChange={(e, v) => handleTabChange(e, v)}
                  >
                    <Tab ref={(el) => (headerRefs.current[0] = el)} label="Dashboard" value="0" />
                    <Tab
                      ref={(el) => (headerRefs.current[1] = el)}
                      label="System Reports"
                      value="1"
                    />
                    <Tab
                      ref={(el) => (headerRefs.current[2] = el)}
                      label="Reports Builder"
                      value="2"
                    />
                    {/* <Tab ref={(el) => (headerRefs.current[3] = el)} label="My Reports" value="3" />
                  <Tab ref={(el) => (headerRefs.current[4] = el)} label="History" value="4" /> */}
                  </TabList>
                </Box>
                <TabPanel sx={{ padding: '0' }} value="0">
                  <DataVizSection />
                </TabPanel>
                <TabPanel value="1">
                  <SystemReportsSection />
                </TabPanel>
                <TabPanel sx={{ padding: '16px' }} value="2">
                  <CustomReportsSection
                    openCreateReport={openCreateReport}
                    setOpenCreateReport={setOpenCreateReport}
                    setPreviewReport={setPreviewReport}
                  />
                </TabPanel>
                {/* <TabPanel sx={{ padding: '16px' }} value="3">
                <MyReports />
              </TabPanel>
              <TabPanel value="4" sx={{ padding: '0px' }}>
                <HistorySection />
              </TabPanel> */}
              </TabContext>
            </Box>
          )}
        </QueryClientProvider>
      </ReportContext.Provider>
    </RecruitReportContext.Provider>
  );
};

export default ReportPage;
