import React, { Component } from 'react';
import T from 'prop-types';
import AsyncCreatable from 'react-select/async-creatable';

const LIMIT = 300;

export default class ScoutTags extends Component {
  static propTypes = {
    values: T.array,
    data: T.array.isRequired,
    name: T.string.isRequired,
    onChange: T.func
  };

  constructor (props) {
    super(props);
    const { data, values } = this.props;

    this.state = { values }
    this.onChange = this.onChange.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  UNSAFE_componentWillReceiveProps ({ values }) {
    if (this.props.values && this.props.values.length > 0 && this.props.values !== values) {
      this.setState({ values: values});
    }
  }

  onChange (value) {
    this.setState({ values: [].concat(value) });

    if (this.props.onChange) {
      this.props.onChange([].concat(value))
    }
  }

  getOptions (input, callback) {
    const searchString = input.toLowerCase().trim();
    let options = this.props.data.filter(
      ({ value, label }) => {
        if (searchString.length == 0) {
          return this.state.values.includes(value);
        } else {
          return this.state.values.includes(value) || label.toLowerCase().includes(searchString);
        }
      });

    return callback(options.slice(0, LIMIT));
  }

  render () {
    const { values } = this.state;
    const { name, className } = this.props;

    return (
      <AsyncCreatable
        className={ className }
        name={ name }
        isMulti
        value={ values }
        onChange={ this.onChange }
        joinValues={ false }
        loadOptions={ this.getOptions }
        defaultOptions
      />
    );
  }
}
