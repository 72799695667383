import React, { useRef, useEffect, useState, memo } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { IJobInformationProps } from '../types';
import { classes } from './styles';

function JobInformation({
  createdBy,
  createdByLink,
  entity,
  entityLink,
  brand,
  brandLink,
  location,
  tags,
  assignedUser,
  isLoading
}: IJobInformationProps) {
  const [infoWidgetWidth, setInfoWidgetWidth] = useState(0);
  const infoWidgetRef = useRef(null);

  useEffect(() => {
    const widgetObserver = new ResizeObserver((entries) => {
      setInfoWidgetWidth(entries[0].contentRect.width);
    });
    widgetObserver.observe(infoWidgetRef.current);
    return () => {
      widgetObserver.disconnect();
    };
  }, []);

  return (
    <Box
      sx={{ ...classes.infoWidget, minHeight: infoWidgetWidth < 1052 ? '250px' : '118px' }}
      ref={infoWidgetRef}
    >
      <Box sx={classes.infoContainer}>
        <Box sx={classes.jobInfoContainer}>
          <Box sx={classes.infoText}>
            <Box sx={classes.infoTextTitle}>Created By</Box>
            {isLoading ? (
              <Skeleton animation="wave" width={140} height={30} />
            ) : createdBy ? (
              <a href={createdByLink}>
                <Box sx={classes.infoTextContent}>{createdBy}</Box>
              </a>
            ) : (
              <Box sx={classes.infoTextContent}>-</Box>
            )}
          </Box>
          <Box sx={classes.infoText}>
            <Box sx={classes.infoTextTitle}>Entity</Box>
            {isLoading ? (
              <Skeleton animation="wave" width={140} height={30} />
            ) : entity ? (
              <a href={entityLink}>
                <Box sx={classes.infoTextContent}>{entity}</Box>
              </a>
            ) : (
              <Box sx={classes.infoTextContent}>-</Box>
            )}
          </Box>
          <Box sx={classes.infoText}>
            <Box sx={classes.infoTextTitle}>Brand</Box>
            {isLoading ? (
              <Skeleton animation="wave" width={140} height={30} />
            ) : brand ? (
              <a href={brandLink}>
                <Box sx={classes.infoTextContent}>{brand}</Box>
              </a>
            ) : (
              <Box sx={classes.infoTextContent}>-</Box>
            )}
          </Box>
        </Box>
        <Box sx={classes.jobInfoContainer}>
          <Box sx={classes.infoText}>
            <Box sx={classes.infoTextTitle}>Location</Box>
            {isLoading ? (
              <Skeleton animation="wave" width={140} height={30} />
            ) : (
              <Box sx={classes.infoTextContent}>{location ? location : '-'}</Box>
            )}
          </Box>
          <Box sx={classes.infoText}>
            <Box sx={classes.infoTextTitle}>Tags</Box>
            {isLoading ? (
              <Skeleton animation="wave" width={140} height={30} />
            ) : (
              <Box sx={classes.infoTextContent}>
                {tags?.length
                  ? tags.map((tag, i) => (
                      <Box key={i} sx={classes.tag}>
                        {tag.name}
                      </Box>
                    ))
                  : '-'}
              </Box>
            )}
          </Box>
          <Box sx={classes.infoText}>
            <Box sx={classes.infoTextTitle}>Assigned User</Box>
            {isLoading ? (
              <Skeleton animation="wave" width={140} height={30} />
            ) : (
              <Box sx={classes.infoTextContent}>{assignedUser ? assignedUser : '-'}</Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(JobInformation);
