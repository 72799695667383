import React, { Dispatch, FC, useContext, useState } from 'react';
import {
  Box,
  CircularProgress,
  Modal,
  Button,
  TextField,
  Autocomplete,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import { useQuery } from '@tanstack/react-query';
import { ReportContext } from '../../index';
import { IEntity, IJobOption, IApplicationStatusOption } from '../types';

interface IDailyApplicationsModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
  entities: IEntity[];
  fetchingEntities: boolean;
}

const DailyApplicationsModal: FC<IDailyApplicationsModal> = ({
  dispatch,
  state,
  entities,
  fetchingEntities
}) => {
  const { filtersModal, openPreview } = state;
  const reportServiceApi = useContext(ReportContext);
  const [entity, setEntity] = useState<IEntity | null>(null);
  const [job, setJob] = useState<IJobOption | null>(null);
  const [jobStates, setJobStates] = useState<string[]>([]);
  const [candidateStatuses, setCandidateStatuses] = useState<IApplicationStatusOption[]>([]);

  const { data: options, isLoading: loadingOptions } = useQuery({
    queryKey: ['options', entity],
    queryFn: async () => {
      const entityId = entity ? [entity.id] : undefined;
      const { options } = await reportServiceApi.systemReports.options(openPreview, entityId);
      return options as {
        jobs: IJobOption[];
        job_state: string[];
        application_status_id: IApplicationStatusOption[];
      };
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading application statuses, ${error}`,
          state: 'error'
        }
      }),
    enabled: openPreview === 'Daily Applications Report'
  });

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, [openPreview]: false }
    });
  };

  const dropdowns = [
    {
      options: entities,
      getOptionLabel: (option: IEntity) => option.name,
      noOptionsText: 'No entities',
      disabled: false,
      loading: fetchingEntities,
      loadingText: 'Loading entities...',
      value: entity,
      multiple: false,
      onChange: (_event: React.ChangeEvent, newValue: IEntity | null) => {
        setEntity(newValue);
        setJob(null);
        setJobStates([]);
        setCandidateStatuses([]);
      },
      label: 'Entity'
    },
    {
      options: options?.jobs || [],
      getOptionLabel: (option: IJobOption) => option?.job_title || '',
      noOptionsText: 'No jobs for this entity',
      disabled: !entity,
      loading: loadingOptions,
      loadingText: 'Loading jobs...',
      value: job,
      multiple: false,
      onChange: (_event: React.ChangeEvent, newValue: IJobOption | null) => setJob(newValue),
      label: 'Jobs'
    },
    {
      options: options?.job_state || [],
      getOptionLabel: (option: string) => option,
      noOptionsText: 'No job states for this entity',
      disabled: !entity,
      loading: loadingOptions,
      loadingText: 'Loading job states...',
      value: jobStates,
      multiple: true,
      onChange: (_event: React.ChangeEvent, newValue: string[]) => setJobStates(newValue),
      label: 'Job states'
    },
    {
      options: options?.application_status_id || [],
      getOptionLabel: (option: IApplicationStatusOption) => option?.application_status_name || '',
      noOptionsText: 'No candidate statuses for this entity',
      disabled: !entity,
      loading: loadingOptions,
      loadingText: 'Loading candidate statuses...',
      value: candidateStatuses,
      multiple: true,
      onChange: (_event: React.ChangeEvent, newValue: IApplicationStatusOption[]) =>
        setCandidateStatuses(newValue),
      label: 'Candidate statuses'
    }
  ];

  return (
    <Modal
      open={filtersModal['Daily Applications Report']}
      onClose={handleClose}
      aria-labelledby="daily-applications-filters-modal"
    >
      <Box sx={{ ...classes.actionsModal, maxHeight: `575px`, width: '720px' }}>
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>
            Daily applications report filters
          </Box>
        </Box>
        <Stack sx={{ marginTop: '1.75rem', flexGrow: 1 }}>
          <Box sx={{ ...classes.modalFormLine, rowGap: 6 }}>
            {dropdowns.map((dropdown, index) => (
              <Autocomplete // TS is complaining due to the nature of .map that can't differentiate the types on each element of the array.
                key={index}
                filterSelectedOptions
                disablePortal
                autoHighlight
                includeInputInList
                sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
                ListboxProps={{ style: classes.autocompleteListboxStyle }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={dropdown.label}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {dropdown.loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                    placeholder="Please select"
                    sx={{ input: { '&::placeholder': { opacity: 1 } } }}
                  />
                )}
                {...dropdown}
              />
            ))}
          </Box>
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset' }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              const filter: Record<string, string> = {};
              if (entity) filter.Entity = entity.name;
              if (job) filter.Job = job.job_title;
              if (jobStates.length) filter['Job states'] = jobStates.join(', ');
              if (candidateStatuses.length)
                filter['Candidate statuses'] = candidateStatuses
                  .map((status) => status.application_status_name)
                  .join(', ');
              dispatch({
                type: 'DAILY_APPLICATIONS_REPORT',
                payload: {
                  selectedEntities: entity ? [entity.id] : [],
                  selectedJobs: job ? [job.job_id] : [],
                  jobStates,
                  applicationStatus: candidateStatuses.map(
                    (status) => status.application_status_name
                  ),
                  filters: filter
                }
              });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default DailyApplicationsModal;
