import * as React from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';
import { classes } from '../styles';
import { SearchState, SearchAction } from '../useReport';

interface Props {
  statusOptions: {
    value: string;
    label: string;
  }[];
  isLoading: boolean;
  reportState: SearchState;
  reportDispatch: React.Dispatch<SearchAction>;
  fieldName: string;
}

export default function StatusField(myProps: Props) {
  const { statusOptions, reportState, isLoading, reportDispatch, fieldName } = myProps;
  return (
    <Box sx={{ position: 'relative', marginBottom: '4px' }}>
      <Autocomplete
        id="statusField"
        multiple
        options={statusOptions}
        getOptionLabel={(option) => (fieldName === 'statusActivitiesFields' ? option : option.name)}
        value={reportState[fieldName]?.status}
        loading={isLoading}
        loadingText={isLoading ? 'Loading statuses...' : 'No statuses'}
        sx={{ ...classes.formAutocomplete, ...classes.formAutocompleteDynamicWidth }}
        ListboxProps={{ style: { ...classes.autoCompleteListStyles } }}
        renderInput={(params) => (
          <TextField {...params} label="Status" InputLabelProps={{ shrink: true }} />
        )}
        onChange={(event, value) => {
          reportDispatch({
            type: fieldName,
            payload: { status: value }
          });
        }}
      />
    </Box>
  );
}
