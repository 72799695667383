import React, { useState, Dispatch, SetStateAction, Fragment } from 'react';
import { Box, IconButton } from '@mui/material';
import { IBooleanQueryObject } from './booleanQueryParser';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { classes } from './styles';
import AndOrToggle from './AndOrToggle';

const BooleanEditor = ({
  editableBooleanArray,
  setEditableBooleanArray
}: {
  editableBooleanArray: IBooleanQueryObject[];
  setEditableBooleanArray: Dispatch<SetStateAction<IBooleanQueryObject[]>>;
}) => {
  const [addValue, setAddValue] = useState({
    rowIndex: -1,
    value: ''
  });
  const [isAddingRow, setIsAddingRow] = useState(false);

  const addValueToRow = (rowIndex: number, value: string) => {
    setAddValue({
      rowIndex: -1,
      value: ''
    });
    if (value === '') return;
    const newEditableBooleanArray = [...editableBooleanArray];
    newEditableBooleanArray[rowIndex].queryValues.push(value);
    setEditableBooleanArray(newEditableBooleanArray);
  };

  const removeValue = (rowIndex: number, valueIndex: number) => {
    const newEditableBooleanArray = [...editableBooleanArray];
    newEditableBooleanArray[rowIndex].queryValues.splice(valueIndex, 1);
    setEditableBooleanArray(newEditableBooleanArray);
  };

  const addRow = (rowIndex: number, value: string) => {
    const newEditableBooleanArray = [...editableBooleanArray];
    newEditableBooleanArray.push({
      id: rowIndex,
      baseOperator: value,
      operator: 'AND',
      queryValues: []
    });
    setEditableBooleanArray(newEditableBooleanArray);
    setIsAddingRow(false);
    setAddValue({
      rowIndex: rowIndex,
      value: ''
    });
  };

  const removeRow = (rowIndex: number) => {
    const newEditableBooleanArray = [...editableBooleanArray];
    newEditableBooleanArray.splice(rowIndex, 1);
    setEditableBooleanArray(newEditableBooleanArray);
  };

  const changeBaseOperator = (rowIndex: number, value: string) => {
    if (editableBooleanArray[rowIndex].baseOperator === value || !value) return;
    const newEditableBooleanArray = [...editableBooleanArray];
    newEditableBooleanArray[rowIndex].baseOperator = value;
    setEditableBooleanArray(newEditableBooleanArray);
  };

  const changeOperator = (rowIndex: number, value: string) => {
    if (editableBooleanArray[rowIndex].operator === value || !value) return;
    const newEditableBooleanArray = [...editableBooleanArray];
    newEditableBooleanArray[rowIndex].operator = value;
    setEditableBooleanArray(newEditableBooleanArray);
  };

  return (
    <Box sx={classes.booleanEditorContainer}>
      {editableBooleanArray.map((editableBooleanObject, index) => (
        <Box sx={classes.booleanEditorRow} key={editableBooleanObject.id}>
          <Box sx={classes.booleanEditorValuesContainer}>
            {editableBooleanObject.queryValues.map((queryValue, valueIndex) => (
              <Fragment key={valueIndex}>
                <Box sx={classes.booleanEditorValue}>
                  <Box>{queryValue}</Box>
                  <IconButton
                    sx={classes.booleanEditorRemoveValueButton}
                    onClick={() => removeValue(index, valueIndex)}
                    id="close-"
                  >
                    <CloseIcon color="inherit" />
                  </IconButton>
                </Box>
                {valueIndex !== editableBooleanObject.queryValues.length - 1 && (
                  <AndOrToggle
                    index={index}
                    editableBooleanObject={editableBooleanObject}
                    onChange={changeOperator}
                  />
                )}
              </Fragment>
            ))}
            {addValue.rowIndex === index ? (
              <input
                type="text"
                size={1}
                style={classes.booleanEditorValueInput}
                value={addValue.value}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addValueToRow(index, addValue.value);
                  }
                }}
                onChange={(e) => setAddValue({ rowIndex: index, value: e.target.value })}
                onBlur={() => addValueToRow(index, addValue.value)}
                onInput={(e) =>
                  (e.target as HTMLInputElement).setAttribute(
                    'size',
                    `${(e.target as HTMLInputElement).value.length || 1}`
                  )
                }
                autoFocus={true}
              />
            ) : (
              <IconButton
                sx={classes.booleanEditorAddButton}
                onClick={() => setAddValue({ rowIndex: index, value: '' })}
              >
                <AddIcon color="inherit" />
              </IconButton>
            )}
          </Box>
          {editableBooleanObject.baseOperator !== 'ROOT' && (
            <>
              <Box sx={classes.booleanEditorRemoveRowButtonContainer}>
                <IconButton
                  sx={classes.booleanEditorRemoveRowButton}
                  onClick={() => removeRow(index)}
                  id="delete-preference-button"
                >
                  <CloseIcon color="inherit" />
                </IconButton>
              </Box>
              <Box sx={classes.booleanEditorOperatorContainer}>
                <AndOrToggle
                  index={index}
                  editableBooleanObject={editableBooleanObject}
                  onChange={changeBaseOperator}
                  changeBase={true}
                  hasExclude={editableBooleanObject.baseOperator === 'NOT'}
                />
              </Box>
            </>
          )}
        </Box>
      ))}
      <Box sx={{ transform: 'translateY(-16px)' }}>
        {isAddingRow ? (
          <AndOrToggle
            index={editableBooleanArray.length}
            onChange={addRow}
            hasExclude={editableBooleanArray.at(-1)?.baseOperator !== 'NOT'}
          />
        ) : (
          <IconButton
            id="add-preference-button"
            sx={classes.booleanEditorAddButton}
            onClick={() => setIsAddingRow(true)}
          >
            <AddIcon color="inherit" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default BooleanEditor;
