import React, { Component, Fragment } from 'react';
import T from 'prop-types';
import Async from 'react-select/async';

const LIMIT = 300;

export default class ScoutSelect extends Component {
  static propTypes = {
    multi: T.bool,
    values: T.array,
    value: T.string,
    data: T.array.isRequired,
    name: T.string.isRequired,
    onChange: T.func,
    className: T.string,
  };

  constructor (props) {
    super(props);
    const { data, values, value } = this.props;

    this.state = { value, values, options: data, selectingOption: false }

    this.onChange = this.onChange.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.formatEmail = this.formatEmail.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.multi && !prevState.selectingOption) {
      return { value: nextProps.value };
    } else {
      return { selectingOption: false };
    }
  }

  onChange (option) {
    if (this.props.multi) {
      let values = [];
      if (option) {
        option.map((opt) => { values.push(opt.value) })
      }

      this.setState({ values, selectingOption: true, options: this.optionsForQuery('') });
      if (this.props.onChange) {
        this.props.onChange(values);
      }
    } else {
      this.setState({ selectingOption: true, value: option.value, options: this.optionsForQuery('') });
      if (this.props.onChange) {
        
        this.props.onChange(option.value)
      }
    }
  }

  optionsForQuery(input) {
    const searchString = input.toLowerCase().trim();
    return this.props.data.filter(
      ({ value, label }) => {
        if (this.props.multi) {
          if (searchString.length == 0) {
            return this.state.values;
          } else {
            return this.state.values.includes(value) || label.toLowerCase().includes(searchString);
          }
        } else {
          return label.toLowerCase().includes(searchString);
        }
      }
    );
  }

  formatEmail (str) {
    if (typeof str !== 'string') return str;

    return str.trim().charAt(0) === '*' ? str.trim().substring(1) : str.trim();
  }

  getOptions (input, callback) {
    const options = this.optionsForQuery(input);

    const newOptions = options.map((item) => {
      item.value = this.formatEmail(item.value);
      return item;
    });

    this.setState({ options: newOptions });
    return callback(options.slice(0, LIMIT));
  }

  render () {
    const { values, value, options } = this.state;
    const { name, multi, className } = this.props;

    if (multi) {
      const selected = options.filter((option) => values.indexOf(option.value) >= 0);
      return (
        <Async
          className={ className }
          name={ name }
          isMulti
          value={ selected }
          onChange={ this.onChange }
          joinValues={ false }
          loadOptions={ this.getOptions }
          defaultOptions
        />
      );
    } else {
      const selected = this.state.options.filter((option) => this.formatEmail(value) === option.value);
      const defaultValue = this.props.value;
      const disabled = defaultValue && defaultValue.trim().charAt(0) === '*' ? true : false;
      return (
        <Fragment>
          <Async
            className={ className }
            name={ name }
            value={ selected }
            onChange={ this.onChange }
            loadOptions={ this.getOptions }
            isDisabled={ disabled }
            defaultOptions
          />
          { disabled && <input type="hidden" name={name} value={this.formatEmail(defaultValue)} />}
        </Fragment>
      );
    }
  }
}
