"use strict";
export const classes = {
  emptyStateContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "100px 0px"
  },
  noReportsText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px",
    textAlign: "center"
  }
};
