import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, Button, Stack } from '@mui/material';
import { classes } from './styles';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';

const DownloadPreview = ({
  finishedDownload,
  file,
  downloadAgain,
  back
}: {
  finishedDownload: null | boolean;
  file: boolean;
  downloadAgain: () => void;
  back?: () => void;
}) => {
  return (
    <Box sx={classes.sectionContent}>
      {finishedDownload && (
        <Box sx={{ ...classes.sectionHeader, textAlign: 'center', marginTop: 8 }}>
          Your report is being generated
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        {finishedDownload ? (
          <CircularProgress sx={{ position: 'absolute', color: '#5BC4BF' }} size={70} />
        ) : (
          <Box>
            {file ? (
              <Box>
                <Box sx={classes.tickWrapper}>
                  <DoneIcon sx={classes.tickGreen} />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Box sx={classes.tickTextHeader}>Complete</Box>
                  <Box sx={classes.tickTextBody}>
                    Please check your downloads for your report or{' '}
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={downloadAgain}
                    >
                      click here
                    </span>{' '}
                    to download again
                  </Box>
                </Box>
                {back && (
                  <Stack sx={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 5 }}>
                    <Button sx={sharedClasses.genericButton} onClick={back}>
                      Return to system reports
                    </Button>
                  </Stack>
                )}
              </Box>
            ) : (
              <Box>
                <Box sx={classes.crossWrapper}>
                  <CloseIcon sx={classes.crossRed} />
                </Box>
                <Box>Failed</Box>
                <Box>Something went wrong</Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DownloadPreview;
