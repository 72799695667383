import React, { ReactElement } from 'react';
import useStyles from './useStyles';
import { withScoutTheme } from '../../../../../ThemeContext/ThemeContext';
import { SvgIconProps } from '@material-ui/core';

interface IProps {
  icon: ReactElement<SvgIconProps>,
  title: string,
  description: string,
  step: number,
  setStep: (step: number) => void,
}

function Step2({ icon, title, description, step, setStep }: IProps) {
 
  const classes = useStyles();

  return (
    <div className={classes.overviewItem}>
      { icon }
      <div className={classes.overviewTitle}>{ title }</div>
      <div className={classes.overviewDescription}>{ description }</div>
      <div
        className={classes.overviewLink}
        onClick={() => setStep(step)}
      >
        Edit selection
      </div>
    </div>
  )
}

export default withScoutTheme(Step2)
