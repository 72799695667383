import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    padding: "30px 40px",
    borderBottom: "1px solid #F3F3F3"
  },
  reducePadding: {
    paddingBottom: 10
  },
  searchContainer: {
    display: "flex"
  },
  search: {
    flexGrow: 1,
    marginRight: 12,
    background: "#FAFAFA",
    border: "1px solid #EAEEF0",
    borderRadius: 6,
    justifyContent: "center",
    "& svg": {
      fill: "#939393"
    },
    "& fieldset": {
      border: 0
    },
    "& input": {
      padding: "0 8px !important"
    }
  },
  filterIcon: {
    color: "#939393",
    padding: 5,
    background: "#FAFAFA",
    border: "1px solid #EAEEF0",
    borderRadius: 6,
    "&:focus": {
      outline: 0
    },
    "& svg": {
      fill: "#939393"
    }
  },
  filterIconActive: {
    background: "#ececec"
  },
  popperStatuses: {
    maxHeight: 400,
    maxWidth: 250,
    minWidth: 250
  },
  chipContainer: {
    paddingTop: 10
  },
  chipItem: {
    margin: "2px 4px"
  },
  chipNoItems: {
    padding: "10px 0",
    fontSize: 15,
    color: "#999",
    textAlign: "center"
  }
}));
