import React, { memo, useState, useCallback } from 'react';
import { Button } from '@material-ui/core';
import useStyles from './useStyles';
import { withScoutTheme } from '../../../../../ThemeContext/ThemeContext';
import { ICalendlyEvent } from '../../../../types';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

interface IProps {
  event: ICalendlyEvent,
  setSelectedEvent: (event: ICalendlyEvent) => void,
  selected: boolean,
}

function EventType({ event, setSelectedEvent, selected }: IProps) {
  const [buttonText, setButtonText] = useState('Copy link');
 
  const classes = useStyles();

  const handleClick = useCallback(() => {
    setSelectedEvent(event)
  }, [event])

  const handleCopy = useCallback(() => {
    setButtonText('Copied!');
    setTimeout(() => setButtonText('Copy link'), 1800);
    navigator.clipboard.writeText(event.scheduling_url)
  }, [event])

  const handleEdit = useCallback(() => {
    window.open('https://calendly.com/event_types/user/me')
  }, [])

  return (
    <div className={classes.container} onClick={handleClick} id="calendly-event-item">
      <div className={classes.content} style={{ borderColor: selected ? event.color : '' }} >
        <div className={classes.name}>{ event.name }</div>
        <div className={classes.details}>{`${event.duration} mins, ${event.kind === 'solo' ? 'One-on-One' : 'Group'}`}</div>
        <a
          className={classes.booking}
          target="_blank"
          rel="noreferrer"
          href={event.scheduling_url}
        >
          View booking page
        </a>
      </div>
      <div className={classes.footer}>
        <Button
          className={classes.copyButton}
          variant="text"
          color="secondary"
          startIcon={<FileCopyOutlinedIcon fontSize="small" />}
          onClick={handleCopy}
        >
          { buttonText }
        </Button>
        <Button
          className={classes.editButton}
          variant="text"
          color="secondary"
          onClick={handleEdit}
        >
          Edit
        </Button>
      </div>
    </div>
  )
}

export default withScoutTheme(memo(EventType))
