import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { styles } from './styles';
import { IApprovalManagers } from '../types';
import ApproversListAutoComplete from './ApproversListAutoComplete';
import ApproversListText from './ApproversListText';

export default function DefaultApproversTab({
  approvalManagers,
  templateApprovers,
  setTemplateApprovers,
  invalidEmailIndex,
  setInvalidEmailIndex
}: {
  approvalManagers: IApprovalManagers[];
  templateApprovers: string[];
  setTemplateApprovers: Dispatch<SetStateAction<string[]>>;
  invalidEmailIndex: number[];
  setInvalidEmailIndex: Dispatch<SetStateAction<number[]>>;
}) {
  const handleAddApprover = () => {
    setTemplateApprovers([...templateApprovers, '']);
  };
  const removeApprover = (i: number) => {
    const newApprovers = [...templateApprovers];
    const newInvalidEmailIndex = [...invalidEmailIndex];
    newApprovers.splice(i, 1);
    setTemplateApprovers(newApprovers);
    if (newInvalidEmailIndex.includes(i)) {
      newInvalidEmailIndex.splice(newInvalidEmailIndex.indexOf(i), 1);
      setInvalidEmailIndex(newInvalidEmailIndex);
    }
  };
  const handleMandatoryButton = (i: number) => {
    const newApprovers = [...templateApprovers];
    const currentApprover = newApprovers[i];
    if (currentApprover.includes('*')) {
      newApprovers[i] = currentApprover.slice(1);
      setTemplateApprovers(newApprovers);
    } else {
      newApprovers[i] = '*' + currentApprover;
      setTemplateApprovers(newApprovers);
    }
  };

  const handleEmailChange = (i: number, value: IApprovalManagers | null) => {
    const newApprovers = [...templateApprovers];
    newApprovers[i] = value ? value.email : '';
    setTemplateApprovers(newApprovers);
  };

  const handleEmailInput = (i: number, value: string) => {
    const newApprovers = [...templateApprovers];
    newApprovers[i] = value;
    setTemplateApprovers(newApprovers);
  };

  return (
    <Box sx={styles.tabContent}>
      <Box sx={styles.tabContentTitle}>Default approvers</Box>
      {approvalManagers.length === 0 ? (
        <Stack sx={{ padding: 2, rowGap: 2 }}>
          {templateApprovers.map((approver, index) => (
            <ApproversListText
              key={index}
              approvalManagers={approvalManagers}
              approver={approver}
              index={index}
              handleEmailInput={handleEmailInput}
              removeApprover={removeApprover}
              handleMandatoryButton={handleMandatoryButton}
              invalidEmail={invalidEmailIndex && invalidEmailIndex.includes(index)}
            />
          ))}
        </Stack>
      ) : (
        <Stack sx={{ padding: 2, rowGap: 2 }}>
          {templateApprovers.map((approver, index) => (
            <ApproversListAutoComplete
              key={index}
              approvalManagers={approvalManagers}
              approver={approver}
              index={index}
              handleEmailChange={handleEmailChange}
              removeApprover={removeApprover}
              handleMandatoryButton={handleMandatoryButton}
            />
          ))}
        </Stack>
      )}
      <Box sx={{ padding: '10px 0 0 20px' }}>
        <Button
          sx={{ ...styles.modalSaveButton, fontSize: '12px', padding: '4px 12px' }}
          onClick={handleAddApprover}
        >
          Add approver
        </Button>
      </Box>
    </Box>
  );
}
