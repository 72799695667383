import React, { Dispatch } from 'react';
import { Box, Button } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import DoneIcon from '@mui/icons-material/Done';
import { FelixMessageSVG } from '../../SharedComponents/Graphics/FelixSVG';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { modalTitle } from '../config';
import { classes } from '../styles';
import { AIStudioState, AIStudioAction } from '../reducer';

const DisplayResponseFooter = ({
  editableDescription,
  aiStudioState,
  dispatch,
  handleCopy,
  copied,
  jobFlow,
  setGeneratedContent,
  handleClose
}: {
  editableDescription: string;
  aiStudioState: AIStudioState;
  dispatch: Dispatch<AIStudioAction>;
  handleCopy: () => void;
  copied: boolean;
  jobFlow?: boolean;
  setGeneratedContent: (description: string) => void;
  handleClose: () => void;
}) => {
  const handleAddToJobClick = () => {
    setGeneratedContent(editableDescription);
    handleClose();
  };

  const renderPrimaryButton = () => {
    if (aiStudioState.generationType !== 'description') return null;
    if (jobFlow) {
      return (
        <Button
          id="save-tlp-new-job"
          sx={{ ...classes.footerContinueButton, minWidth: '146px' }}
          endIcon={<DoneIcon />}
          onClick={handleAddToJobClick}
        >
          Add to job
        </Button>
      );
    } else {
      return (
        <CopyToClipboard text={editableDescription} onCopy={handleCopy}>
          <Button id="copy-tlp-html" sx={classes.footerContinueButton} endIcon={<CodeIcon />}>
            {copied
              ? aiStudioState.aiStudioFrenchEnabled
                ? 'Copié'
                : 'Copied'
              : aiStudioState.aiStudioFrenchEnabled
              ? 'Copier'
              : 'Copy'}{' '}
            HTML
          </Button>
        </CopyToClipboard>
      );
    }
  };

  return (
    <Box sx={classes.footerContainer}>
      <Box sx={classes.felixMessageContainer}>
        <FelixMessageSVG />
        <Box sx={{ ...classes.felixMessage, maxWidth: '432px' }}>
          {aiStudioState.aiStudioFrenchEnabled ? (
            <>
              <span>Voilà!</span> Votre{' '}
              <span>
                {modalTitle['french'][aiStudioState.generationType as keyof typeof modalTitle]}
              </span>{' '}
              {aiStudioState.generationType === 'description' ? 'est' : 'sont'} prêt
              {aiStudioState.generationType === 'description' ? '' : 's'} à l’action. Vous pouvez
              l’utiliser tel quel ou apporter des ajustements avant de le publier.
            </>
          ) : (
            <>
              Ta-da! Your{' '}
              <span>
                {modalTitle['english'][aiStudioState.generationType as keyof typeof modalTitle]}
              </span>{' '}
              {aiStudioState.generationType === 'description' ? 'is' : 'are'} ready for action. You
              may want to use {aiStudioState.generationType === 'description' ? 'this' : 'them'} as
              is, or make some adjustments before publishing.
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          ...classes.footerButtonsContainer,
          minWidth: '310px',
          justifyContent:
            aiStudioState.generationType === 'description' ? 'space-around' : 'flex-end',
          rowGap: 'none'
        }}
      >
        <Button
          id={`start-again-${aiStudioState.generationType}`}
          sx={classes.footerRestartButton}
          onClick={() =>
            dispatch({
              type: 'SET_CURRENT_STEP',
              payload: aiStudioState.generationType === 'description' ? 1 : 5
            })
          }
        >
          {aiStudioState.aiStudioFrenchEnabled ? 'Recommencer' : 'Start again'}
        </Button>
        {renderPrimaryButton()}
      </Box>
    </Box>
  );
};

export default DisplayResponseFooter;
