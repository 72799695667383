import React from 'react';
import PropTypes from 'prop-types';
import SavedSearchItem from './item';

const SavedSearchTable = ({ collection, isStaff }) => (
  <table className="saved-searches">
    <thead>
      <tr>
        <th>Title </th>
        <th>Filtering Options </th>
        <th>Operations </th>
      </tr>
    </thead>
    <tbody>
      {collection &&
        collection.map((savedSearch, key) => (
          <SavedSearchItem key={key} savedSearch={savedSearch} isStaff={isStaff} />
        ))}
    </tbody>
  </table>
);

SavedSearchTable.propTypes = {
  collection: PropTypes.array,
  isStaff: PropTypes.bool,
  token: PropTypes.string,
  endpoint: PropTypes.string
};

export default SavedSearchTable;
