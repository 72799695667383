import React from 'react';
import { Box, Button } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import HomeIcon from '@mui/icons-material/Home';
import { FelixConfused } from '../Graphics/FelixSVG';
import { classes } from '../styles';

const ErrorScreen = ({
  regenerateCallback,
  setGenerationError,
  backToHome
}: {
  regenerateCallback: () => void;
  setGenerationError?: (value: boolean) => void;
  backToHome: () => void;
}) => {
  const onRegenerate = () => {
    setGenerationError && setGenerationError(false);
    regenerateCallback();
  };

  const onBackToHome = () => {
    setGenerationError && setGenerationError(false);
    backToHome();
  };

  return (
    <Box sx={classes.errorContainer}>
      <Box sx={classes.felixErrorContainer}>
        <FelixConfused />
      </Box>
      <Box sx={classes.errorMessageContainer}>
        <h1>Oops! Something went wrong.</h1>
        <p>You can try regenerating your request, or refreshing the page.</p>
        <p>
          If the problem persists please contact our Client Success team{' '}
          <a href="mailto:support@scouttalent.com.au">support@scouttalent.com.au</a>
        </p>
      </Box>
      <Box sx={classes.errorButtons}>
        <Button sx={classes.pill} endIcon={<HomeIcon />} onClick={onBackToHome}>
          Back to home
        </Button>
        <Button sx={classes.pill} endIcon={<CachedIcon />} onClick={onRegenerate}>
          Regenerate
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorScreen;
