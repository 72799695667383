"use strict";
export var NoteState = /* @__PURE__ */ ((NoteState2) => {
  NoteState2["PERMANENT_RECORD"] = "permanent_record";
  NoteState2["NOTEPAD"] = "note_pad";
  return NoteState2;
})(NoteState || {});
export var SortableColumnId = /* @__PURE__ */ ((SortableColumnId2) => {
  SortableColumnId2["CANDIDATE"] = "candidate";
  SortableColumnId2["CANDIDATE_TAGS"] = "candidate_tags";
  SortableColumnId2["COUNTRY"] = "country";
  SortableColumnId2["DATE"] = "date";
  SortableColumnId2["GENDER"] = "gender";
  SortableColumnId2["ID"] = "id";
  SortableColumnId2["NOTE"] = "note";
  SortableColumnId2["NOTES"] = "notes";
  SortableColumnId2["PERMANENT_RECORD"] = "permanent_record";
  SortableColumnId2["NOTE_PAD"] = "note_pad";
  SortableColumnId2["PHONE"] = "phone";
  SortableColumnId2["RATING"] = "rating";
  SortableColumnId2["STATE"] = "state";
  SortableColumnId2["STATUS"] = "status";
  SortableColumnId2["SUBURB"] = "suburb";
  return SortableColumnId2;
})(SortableColumnId || {});
