import React, { Dispatch, FC, useContext, useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Modal,
  Button,
  TextField,
  Autocomplete,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import { useQuery } from '@tanstack/react-query';
import { ReportContext } from '../../index';
import { IEntity } from '../types';

interface IQuestionsModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
  entities: IEntity[];
  fetchingEntities: boolean;
}

const QuestionsModal: FC<IQuestionsModal> = ({ dispatch, state, entities, fetchingEntities }) => {
  const { filtersModal, openPreview, selectedEntities, jobs, questions } = state;
  const reportServiceApi = useContext(ReportContext);
  const [entity, setEntity] = useState<IEntity[]>([]);
  const [job, setJob] = useState<string[]>(jobs);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>(questions);

  const { data: options, isLoading: loadingOptions } = useQuery({
    queryKey: ['options', entity],
    queryFn: async () => {
      const entityId = entity.length ? entity.map((e) => e.id) : undefined;
      const { options } = await reportServiceApi.systemReports.options(openPreview, entityId);
      return options as {
        questions: Record<
          string,
          { application_id: number; job_id: number; job_title: string; question_title: string }[]
        >;
      };
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading application statuses, ${error}`,
          state: 'error'
        }
      }),
    enabled: openPreview === 'Questions Report'
  });

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, [openPreview]: false }
    });
  };

  const dropdowns = [
    {
      options: entities,
      getOptionLabel: (option: IEntity) => option.name,
      noOptionsText: 'No entities',
      disabled: false,
      loading: fetchingEntities,
      loadingText: 'Loading entities...',
      value: entity,
      multiple: true,
      onChange: (_event: React.ChangeEvent, newValue: IEntity[]) => {
        setEntity(newValue);
        setJob([]);
        setSelectedQuestions([]);
      },
      label: 'Entity',
      placeholder: entity.length ? '' : 'Select Entity'
    },
    {
      options: options ? Object.keys(options.questions) : [],
      getOptionLabel: (option: string) => option,
      noOptionsText: 'No jobs for this entity',
      disabled: !entity,
      loading: loadingOptions,
      loadingText: 'Loading jobs...',
      value: job,
      multiple: true,
      onChange: (_event: React.ChangeEvent, newValue: string[]) => {
        setJob(newValue);
        setSelectedQuestions([]);
      },
      label: 'Jobs',
      placeholder: job.length ? '' : 'Select Job'
    }
  ];

  useEffect(() => {
    const entityArr: IEntity[] = [];
    selectedEntities.map((se) => {
      const savedEntity = entities.find((entity) => entity.id === se);
      if (savedEntity) {
        entityArr.push(savedEntity);
      }
    });
    setEntity(entityArr);
  }, [selectedEntities]);

  return (
    <Modal
      open={filtersModal['Questions Report']}
      onClose={handleClose}
      aria-labelledby="questions-filters-modal"
    >
      <Box sx={{ ...classes.actionsModal, maxHeight: `850px`, width: '720px', overflow: 'auto' }}>
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>Questions report filters</Box>
        </Box>
        <Stack sx={{ marginTop: '1.75rem', flexGrow: 1 }}>
          <Box sx={{ ...classes.modalFormLine, rowGap: 6, marginBottom: 'unset' }}>
            {dropdowns.map((dropdown, index) => (
              // @ts-ignore TS is complaining due to the nature of .map that can't differentiate the types on each element of the array.
              <Autocomplete
                key={index}
                filterSelectedOptions
                disablePortal
                autoHighlight
                includeInputInList
                sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
                ListboxProps={{ style: classes.autocompleteListboxStyle }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={dropdown.label}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {dropdown.loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                    placeholder={dropdown.placeholder}
                    sx={{ input: { '&::placeholder': { opacity: 1 } } }}
                  />
                )}
                {...dropdown}
              />
            ))}
          </Box>
          {options && (
            <>
              <Box sx={{ padding: 1, marginTop: 2 }}>
                <Typography
                  sx={{ color: '#939393', fontFamily: 'Source Sans Pro', paddingBottom: 1 }}
                >
                  Questions
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ color: '#DDDDDD' }}
                      checked={(job.length ? job : Object.keys(options.questions)).every((key) =>
                        options.questions[key].every((question) =>
                          selectedQuestions.includes(question.question_title)
                        )
                      )}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.checked) {
                          const allQuestions = (
                            job.length ? job : Object.keys(options.questions)
                          ).flatMap((key) =>
                            options.questions[key].map((question) => question.question_title)
                          );
                          setSelectedQuestions(allQuestions);
                        } else {
                          setSelectedQuestions([]);
                        }
                      }}
                    />
                  }
                  label="Select all questions"
                  sx={{ color: '#939393' }}
                />
              </Box>
              {(job.length ? job : Object.keys(options.questions)).map((key, index) => (
                <Box
                  key={index}
                  sx={{
                    margin: 1,
                    marginRight: 'unset',
                    padding: 2,
                    border: '1px solid #DDDDDD',
                    borderRadius: '6px'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ color: '#DDDDDD' }}
                        checked={options.questions[key].every((question) =>
                          selectedQuestions.includes(question.question_title)
                        )}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const newSelectedQuestions = [...selectedQuestions];
                          if (event.target.checked) {
                            options.questions[key].map((question) => {
                              if (!selectedQuestions.includes(question.question_title)) {
                                newSelectedQuestions.push(question.question_title);
                              }
                            });
                            setSelectedQuestions(newSelectedQuestions);
                          } else {
                            const filteredQuestions = newSelectedQuestions.filter(
                              (e) =>
                                !options.questions[key]
                                  .map((question) => question.question_title)
                                  .includes(e)
                            );
                            setSelectedQuestions(filteredQuestions);
                          }
                        }}
                      />
                    }
                    label={key}
                  />
                  {options.questions[key].map((question, index) => {
                    const checked = selectedQuestions.includes(question.question_title);
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            sx={{ color: '#DDDDDD' }}
                            checked={checked}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const newSelectedQuestions = [...selectedQuestions];
                              if (event.target.checked) {
                                newSelectedQuestions.push(question.question_title);
                                setSelectedQuestions(newSelectedQuestions);
                              } else {
                                const filteredQuestions = newSelectedQuestions.filter(
                                  (e) => e !== question.question_title
                                );
                                setSelectedQuestions(filteredQuestions);
                              }
                            }}
                          />
                        }
                        label={question.question_title}
                        sx={{ color: '#939393', paddingLeft: 2 }}
                      />
                    );
                  })}
                </Box>
              ))}
            </>
          )}
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset', marginTop: 2 }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              dispatch({ type: 'SELECTED_ENTITIES', payload: entity.map((e) => e.id) });
              dispatch({ type: 'JOBS', payload: job });
              dispatch({ type: 'QUESTIONS', payload: selectedQuestions });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default QuestionsModal;
