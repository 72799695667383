import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { classes } from './styles';

const ModalFooterButtons = ({
  primaryButtonText,
  primaryButtonID,
  primaryButtonCallback,
  primaryButtonMinWidth,
  primaryButtonDisabled,
  secondaryButtonText,
  secondaryButtonID,
  secondaryButtonCallback,
  secondaryButtonMinWidth,
  isLoading
}: {
  primaryButtonText?: string | JSX.Element;
  primaryButtonID?: string;
  primaryButtonCallback?: () => void;
  primaryButtonMinWidth?: string;
  primaryButtonDisabled?: boolean;
  secondaryButtonText?: string | JSX.Element;
  secondaryButtonID?: string;
  secondaryButtonCallback?: () => void;
  secondaryButtonMinWidth?: string;
  isLoading?: boolean;
}) => {
  return (
    <Box sx={classes.modalFooter}>
      {secondaryButtonText && (
        <Button
          id={secondaryButtonID || 'secondary-modal-button'}
          sx={{
            ...classes.modalButtonBase,
            ...classes.modalSecondaryButton,
            ...(secondaryButtonMinWidth && { minWidth: secondaryButtonMinWidth })
          }}
          onClick={secondaryButtonCallback && secondaryButtonCallback}
        >
          {secondaryButtonText}
        </Button>
      )}
      {primaryButtonText && (
        <Button
          id={primaryButtonID || 'primary-modal-button'}
          sx={{
            ...classes.modalButtonBase,
            ...(primaryButtonMinWidth && { minWidth: primaryButtonMinWidth }),
            '&.Mui-disabled': { background: '#E5E5E5', color: '#939393' }
          }}
          onClick={primaryButtonCallback && primaryButtonCallback}
          disabled={primaryButtonDisabled}
        >
          {isLoading ? <CircularProgress size={20} color="inherit" /> : primaryButtonText}
        </Button>
      )}
    </Box>
  );
};

export default ModalFooterButtons;
