"use strict";
import RecruitApiClient from "../../RecruitApiClient";
class Api {
  constructor() {
    this.getCandidates = (params) => RecruitApiClient.get(`/api/v4/candidates`, params);
    this.getPreferences = () => RecruitApiClient.get(`/api/v4/ui_preferences?source=candidates`);
    this.getDensityPreferences = () => RecruitApiClient.get(`/api/v4/ui_preferences?source=universal`);
    this.postUserPreferences = (params) => RecruitApiClient.post(`/api/v4/ui_preferences`, {
      ui_preference: {
        row_count: params.limit,
        sorting: {
          sortBy: params.sort,
          sortOrder: params.sortOrder
        },
        source: `candidates`
      }
    });
    this.deleteCandidate = (candidateId) => RecruitApiClient.delete(`/api/v4/candidates/${candidateId}`);
    this.updateCandidate = (candidateId, params) => RecruitApiClient.put(`/api/v4/candidates/${candidateId}`, { candidate: params });
    this.getCountriesStates = () => RecruitApiClient.get("/api/system/v1/countries_states.json");
    this.getUserPermissions = () => RecruitApiClient.get(`/api/v4/user_permissions`);
  }
}
export default new Api();
