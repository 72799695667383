import React, { Dispatch } from 'react';
import { Stack, Box, Skeleton } from '@mui/material';
import { CandidateAction, ICandidateTask } from '../types';
import CandidateTask from './CandidateTask';
import EmptyTab from './EmptyTab';

export default function CandidateTasksTab({
  tasks,
  tabValue,
  fetchingTasks,
  dispatch,
  candidateId
}: {
  tasks?: ICandidateTask[];
  tabValue: number;
  fetchingTasks: boolean;
  dispatch: Dispatch<CandidateAction>;
  candidateId: number;
}) {
  const filteredTasks = tabValue
    ? tasks?.filter((task) => !!task.completed_at) // completed tasks
    : tasks?.filter((task) => !task.completed_at); // pending tasks
  return (
    <>
      {fetchingTasks ? (
        <>
          {[...Array(5)].map((_, index) => (
            <Box key={index}>
              <Skeleton animation="wave" height={60} />
            </Box>
          ))}
        </>
      ) : (
        <>
          {tasks && filteredTasks?.length ? (
            <Stack sx={{ paddingTop: 3, rowGap: 3 }}>
              {filteredTasks.map((task) => {
                return (
                  <CandidateTask
                    task={task}
                    key={task.id}
                    dispatch={dispatch}
                    candidateId={candidateId}
                    tasks={tasks}
                  />
                );
              })}
            </Stack>
          ) : (
            <EmptyTab tabValue={tabValue} />
          )}
        </>
      )}
    </>
  );
}
