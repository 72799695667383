"use strict";
import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    borderRadius: 8,
    padding: "70px 80px",
    paddingBottom: 0,
    height: "88vh",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    "& h4": {
      fontSize: 34,
      color: "#666666",
      fontWeight: "700",
      marginTop: 0
    }
  },
  actions: {
    display: "flex",
    fontSize: 16,
    alignItems: "flex-end",
    marginTop: 30,
    borderBottom: "1px solid #EEEEEE"
  },
  type: {
    color: "#DDDDDD",
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: 60,
    columnGap: 40,
    "& > span": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        userSelect: "none"
      }
    }
  },
  datePopover: {
    marginTop: 10,
    padding: 20,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& .DayPicker-Weekday abbr": {
      fontSize: "0.85rem",
      color: "#939393",
      textDecoration: "none"
    },
    "& .DayPicker-Caption": {
      color: "#004665",
      fontWeight: "600",
      marginBottom: 15,
      fontSize: 17
    },
    "& .DayPicker-Day": {
      borderRadius: 100,
      padding: 0,
      border: 0,
      width: 40,
      height: 40
    },
    " & .DayPicker-Month": {
      display: "block !important"
    },
    "& .DayPicker-Day--event": {
      backgroundColor: "#EEFBFB"
    },
    "& .DayPicker-Day--highlighted": {
      backgroundColor: "#EEFBFB",
      color: "#15C7C0"
    },
    "& .DayPicker-Day--today": {
      color: "black"
    },
    "& .DayPicker-Day--selected": {
      backgroundColor: "#15C7C0 !important",
      color: "white !important"
    },
    "& .DayPicker-wrapper": {
      paddingTop: "0.5rem",
      fontSize: "0.85rem",
      paddingBottom: "0",
      "& .DayPicker-NavButton": {
        width: "1rem",
        height: "1rem"
      },
      "& .DayPicker-Day--disabled": {
        backgroundColor: "white",
        pointerEvents: "none"
      }
    },
    "& .DayPicker-Day--outside": {
      opacity: 0
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
      color: `${theme.palette.secondary.main} !important`
    }
  },
  dateSelect: {
    color: "#084d6d",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#E5E7EB",
    padding: "6px 16px",
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: "-12px",
    borderRadius: 10,
    letterSpacing: "0.1px",
    opacity: 0,
    transition: "opacity 0.25s cubic-bezier(.6,.36,.25,1), transform 0.25s cubic-bezier(.6,.36,.25,1)",
    transform: "translateX(-30px)",
    "& em": {
      color: "#babdc3"
    },
    "&.visible": {
      transform: "translateX(0px)",
      opacity: 1
    },
    "&.active": {
      borderColor: theme.palette.secondary.main
    },
    "&:hover": {
      borderColor: "#d9dce3",
      cursor: "pointer",
      userSelect: "none"
    }
  },
  typeUnderline: {
    position: "absolute",
    height: 3,
    borderRadius: 3,
    backgroundColor: theme.palette.secondary.main,
    bottom: -1.5,
    transition: "left 0.25s cubic-bezier(.6,.36,.25,1), width 0.25s cubic-bezier(.6,.36,.25,1)"
  },
  activeType: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  moreActions: {
    marginLeft: "auto",
    marginBottom: 6,
    "& button": {
      fontWeight: 400,
      fontSize: 17,
      "& svg": {
        marginleft: 4,
        width: "1.2rem"
      }
    }
  },
  eventPagination: {
    marginTop: "auto",
    alignSelf: "flex-end",
    paddingRight: 90,
    paddingBottom: 90,
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 10,
    "& button": {
      "&:last-child": {
        marginLeft: 15
      },
      "& svg": {
        width: "0.8em",
        height: "0.8em"
      }
    }
  },
  eventHeader: {
    fontWeight: 900,
    fontSize: 19,
    margin: "60px 0 -15px"
  },
  noEvents: {
    marginTop: 30,
    color: "#999",
    fontSize: 20
  },
  eventContainer: {
    overflowX: "hidden",
    overflowY: "scroll",
    flexBasis: "100%"
  },
  filters: {
    marginTop: 20,
    display: "flex",
    "& > div": {
      marginRight: 40,
      cursor: "pointer",
      userSelect: "none",
      "& > span": {
        color: "#939393"
      },
      "& > div": {
        color: theme.palette.secondary.main,
        marginTop: 6
      }
    }
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "8px 0"
  },
  filterWrapper: {
    "& .MuiInputBase-root": {
      height: "3rem",
      borderRadius: "0.3rem",
      border: "1px solid grey"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none !important"
    },
    "& .MuiInput-underline:after": {
      border: "none !important"
    }
  },
  badAPIModalInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "400px",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.2)",
    "& h1": {
      fontSize: "26px",
      color: "rgb(102, 102, 102)",
      marginTop: "0.5rem"
    }
  }
}));
