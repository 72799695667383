import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import VideoPlayer from './index.jsx';
import axios from 'axios';
import { logActivity } from './video_interview_helper.jsx';

class Upload extends Component {
  state = {
    uploading: false,
    file : [],
    slug: '',
    uploadProgress: 0,
    videoPlayer: false,
    responseVideoURL: {},
    videoId: null,
    display: true,
    transcoding: true,
    loadingState: false,
    validFile: true,
    bookMarks: null,
    errors: ''
  };

  componentDidMount() {
    if (this.props.hasVideo) this.checkVideoState();
  }

  checkVideoState = () => {
    const query = `query fetchVideo($slug: ID){
      video(slug: $slug) {
        bookmarks{
          description
          seconds
        }
        state
    		id
        videoSources{
          src: url
          type: mimeType
        }
      }
    }`;
    fetch(this.props.url, {
      method: 'post',
      headers: {'Content-Type' : 'application/json' },
      body: JSON.stringify({
        query: query,
        variables: {slug: `interview-application-${this.props.applicationId}`},
      })
    })
    .then(response => response.json())
      .then(response => {
        if (response.data) {
          const videoState = response.data.video.state;
          const url = response.data.video.videoSources;
          const Id = response.data.video.id;
          if (videoState !== 'completed' && this.state.uploadProgress !== 0) {
            setTimeout(this.checkVideoState, 2000);
          }
          else {
            this.setState({ videoPlayer: true, responseVideoURL: url, videoId: Id })

            const data = new FormData();
            data.append(`application_id`, this.props.applicationId);

            axios.post(
              this.props.updateUrl,
              data,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
                },
              },
            ).then((response) => {

            });
          }
        }
      });
  }

  upload = (file) => {
    const { uploading } = this.state;

    if (uploading) return;

    this.setState({ uploading: true }, () => this.handelUpload());
  };

  onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  prepareLink = (file) => {
    const query = `
    mutation prepare($userId: ID!, $slug: ID!, $checksum: String, $contentType: String, $contentLength: Int){
      prepareUploadingVideo(input: {slug: $slug, userId: $userId, checksum: $checksum, contentLength: $contentLength, contentType: $contentType}) {
        errors
        url
        video {
          id
          state
         originalVideoUrl
        }
      }
     }`;
    const variables = {
      'slug': `interview-application-${this.props.applicationId}`,
      'userId': 'SomeID',
      'contentType': file.type,
      'contentLength': file.totalSize
    };
    fetch(this.props.url, {
      method: 'post',
      headers: {'Content-Type' : 'application/json' },
      body: JSON.stringify({
        query: query,
        variables: variables,
      })
    })
      .then(response => response.json())
      .then(response => {
        const url = response.data.prepareUploadingVideo.url;
        this.uploadFile(file, url);
      });
  }

  uploadFile = (file, url) => {
    var xhr = new XMLHttpRequest();
    xhr.upload.onprogress = (e) => {
      var done = e.position || e.loaded, total = e.totalSize || e.total;
      let progress = (Math.floor(done/total*1000)/10);
      this.setState({ uploadProgress : progress });
    };

    xhr.open('put', url, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
    xhr.onloadend = () => {
      if (xhr.status !== 200){
        console.log(xhr.status, ' Error ' );
      }
      else { this.upload(); }
    }
  }

  getMetaData = (file) => {
    const data = new FormData();

    data.append(`video`, file[0]);
    data.append(`application_id`, this.props.applicationId);

    axios.post(
      this.props.uploadUrl,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
      },
    ).then((response) => {
      this.setState({ bookMarks: response.data });
    });
  }

  onDrop = (file) => {
    const { validFile } = this.state;

    if (file.length === 0) {
      this.setState({ validFile : false})
    } else {
      this.getMetaData(file);
      this.prepareLink(file[0]);
      this.setState({display: false, transcoding: false, validFile: true});
    }
};

  handelUpload = () => {
    const query = `mutation notifyUploading($slug: ID!){
      transcodeVideo(input: {slug: $slug}) {
        video {
          state
        }
      }
     }`;
     fetch(this.props.url, {
      method: 'post',
      headers: {'Content-Type' : 'application/json' },
      body: JSON.stringify({
        query: query,
        variables: {slug: `interview-application-${this.props.applicationId}`},
      })
    })
    logActivity('uploaded video for', this.props.activityUrl);
    this.setState({display: true, transcoding: true, loadingState: true})
    console.log(this.state.transcoding);
    this.checkVideoState();
  }

  onDropRejected = (rejectedFiles, e) => {

    let errorsList = [];

    rejectedFiles.forEach((file) => {
      file.file.size > this.props.fileSizeLimit && errorsList.push('This video is bigger than 500MB')
      !file.file.type.match(/video\//) && errorsList.push('This file is not a valid video...')
    })
    errorsList = errorsList.filter((value, index) => errorsList.indexOf(value) === index)

    this.setState({
      errors: errorsList.join(', ')
    });
  }

  render () {
    const { uploading, uploadProgress, videoPlayer, responseVideoURL, videoId, display, transcoding, loadingState, validFile } = this.state;
    const progressStyles = {
      width: uploadProgress + '%', background: 'lightgrey',
      display: display ? 'none' : 'block',
      textAlign: 'center'
    }
    const { admin, applicationId, url } = this.props
    const transcode = { display: transcoding ? 'none' : 'block' };
    return (
      <div>
        {!videoPlayer ?
          <div className="Upload">
            { loadingState ?
                <div >Transcoding video.. Please wait, do not refresh the page.
                    <div className="Loader"></div>
                </div>
              :
              <div className="DropZoneWrapper">
                <Dropzone accept={{'video/*': []}} maxSize={this.props.fileSizeLimit} onDrop={this.onDrop.bind(this)} onDragOver={this.onDragOver} onDropRejected={this.onDropRejected}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      className="DropzoneStyles"
                    >
                      <input {...getInputProps()} />
                      Drop a video, or click here.
                    </div>
                  )}
                </Dropzone>
              </div>
            }
            <div style={{marginLeft: '1rem', color: 'red'}}>
              {!validFile && this.state.errors}
            </div>
            <div>
              {uploadProgress > 0 && uploadProgress < 100 &&
                <div>
                  <div className="progress" style={{width: '15rem', height: '1.25rem'}}>
                    <div className="progress-bar" style={progressStyles}>
                      <span className="percentStyle">{parseInt(uploadProgress)}%</span>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        : <VideoPlayer
            applicationId={applicationId}
            admin={admin}
            url={url}
            uploadUrl={this.props.uploadUrl}
            savedBookmarks={this.state.bookMarks}
            activityUrl={this.props.activityUrl}
            deletePermission={this.props.deletePermission}
            deleteVideoUrl={this.props.deleteVideoUrl}
            updateUrl={this.props.updateUrl}
          />
        }
      </div>
    );
  }
}

export default Upload;
