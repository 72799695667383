import React, { useState } from 'react';
import { Box, OutlinedInput, Dialog, Stack } from '@mui/material';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import { classes } from '../styles';

const Search = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [openSearch, setOpenSearch] = useState<boolean>(false);

  const handleSearch = () => {
    window.location.href = `/admin/search?utf8=✓&s=${searchQuery}`;
  };

  return (
    <Box>
      <SearchIcon sx={classes.searchIcon} onClick={() => setOpenSearch(true)} />
      <Dialog
        open={openSearch}
        sx={classes.searchDialog}
        onClose={() => {
          setOpenSearch(false);
          setSearchQuery('');
        }}
      >
        <OutlinedInput
          autoFocus
          id="recruit-search"
          sx={classes.searchInput}
          placeholder={'Search...'}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          endAdornment={
            <Stack sx={{ flexDirection: 'row', columnGap: 1, alignItems: 'center' }}>
              {searchQuery && (
                <CloseIcon onClick={() => setSearchQuery('')} sx={classes.clearSearch} />
              )}
              <SearchIcon sx={{ ...classes.searchIcon, fontSize: '32px' }} onClick={handleSearch} />
            </Stack>
          }
        />
      </Dialog>
    </Box>
  );
};

export default Search;
