import React from 'react';
import { Button } from '@mui/material';
import { classes } from '../styles';

export default function CancelButton({ setModalOpen, scope }) {
  return (
    <Button
      id={`close-${scope}-button`}
      sx={classes.modalCancelButton}
      onClick={() => setModalOpen(false)}
    >
      Cancel
    </Button>
  );
}
