import React, { useState } from 'react';
import AvailabilityPreview from '../../ApprovalForms/Questions/Modals/AvailabilityPreview';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IApplicantQuestion } from '../../EditJob/types';

interface AvailabilityFieldQuestionProps {
  question: IApplicantQuestion;
}
const AvailabilityFieldQuestion: React.FC<AvailabilityFieldQuestionProps> = ({ question }: AvailabilityFieldQuestionProps
) => {
  const [showPreview, setShowPreview] = useState(false);

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 1,
          alignItems: 'center',
          alignSelf: 'center'
        }}
      >
        {showPreview && (
          <AvailabilityPreview availabilityConfig={question.availability_config} />
        )}
        <Stack
          flexDirection="row"
          alignItems="center"
          onClick={() => setShowPreview(!showPreview)}
          sx={{
            fontFamily: 'Source Sans Pro',
            fontSize: '12px',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        >
          {showPreview ? 'Hide preview' : 'Show preview'}
          {showPreview ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Stack>
      </Box>
    );
}

export default AvailabilityFieldQuestion;
