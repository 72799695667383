import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import { classes } from '../../styles';
import { TMenuItem, navbarWidths } from '../../config';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const NavigationParent = ({
  item,
  expandedParents,
  setExpandedParents,
  navbarState,
  activeParent,
  onCollapsedClick
}: {
  item: TMenuItem;
  expandedParents: string[];
  setExpandedParents: Dispatch<SetStateAction<string[]>>;
  navbarState: keyof typeof navbarWidths;
  activeParent: string;
  onCollapsedClick: (currentTarget: HTMLElement) => void;
}) => {
  const highlightActiveParent = (title: string) =>
    navbarState === 'collapsed' ? activeParent === title : expandedParents.includes(title);

  const displayExpandCollapseIcon = (item: TMenuItem) =>
    item.submenu && item.submenu.length > 0 && navbarState === 'expanded';

  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'people_search':
        return <PersonSearchOutlinedIcon />;
      case 'shield':
        return <AdminPanelSettingsOutlinedIcon />;
      case 'spanner':
        return <BuildOutlinedIcon sx={{ fontSize: '21px !important' }} />;
      case 'home':
        return <HomeOutlinedIcon />;
      default:
        return null;
    }
  };

  const handleParentClick = (title: string, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (navbarState === 'collapsed') {
      e.stopPropagation();
      onCollapsedClick(e.currentTarget);
      return;
    }
    if (expandedParents.includes(title)) {
      const tempExpandedParents = expandedParents.filter((item) => item !== title);
      setExpandedParents(tempExpandedParents);
      localStorage.setItem('expandedParents', JSON.stringify(tempExpandedParents));
    } else {
      const tempExpandedParents = [...expandedParents, title];
      setExpandedParents(tempExpandedParents);
      localStorage.setItem('expandedParents', JSON.stringify(tempExpandedParents));
    }
  };
  return (
    <Box
      key={item.title}
      sx={{
        ...classes.menuItemParent,
        ...(highlightActiveParent(item.title) && classes.activeParent),
        ...(navbarState === 'collapsed' && classes.menuItemParentCollapsed)
      }}
      onClick={(e) => handleParentClick(item.title, e)}
      id={`${item?.id ? item?.id : ''}`}
    >
      {renderIcon(item.icon)}
      <span>{item.title}</span>
      {displayExpandCollapseIcon(item) && (
        <Box sx={classes.menuItemExpandCollapseIcon}>
          {expandedParents.includes(item.title) ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowRightIcon />
          )}
        </Box>
      )}
    </Box>
  );
};

export default NavigationParent;
