import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IAppsAndViewsProps } from '../types';
import { classes } from './styles';

export default function ApplicationsAndViews({
  applications,
  openApplications,
  jobCounts,
  isLoading,
  handleTabChange
}: IAppsAndViewsProps) {
  return (
    <Box sx={classes.subWidget}>
      <Box sx={classes.widgetBlockContainer}>
        <Box
          sx={{ ...classes.widgetBlock, cursor: 'pointer' }}
          onClick={!isLoading ? () => handleTabChange(undefined, 1) : undefined}
          id="applications-widget-job"
        >
          <Box sx={classes.widgetBlockIcon}>
            <InsertDriveFileIcon />
          </Box>
          <Box sx={classes.widgetBlockNew}>
            {isLoading ? (
              <Skeleton animation="wave" width={32} height={26} />
            ) : (
              jobCounts?.applications_count > 0 && `+ ${jobCounts?.applications_count}`
            )}
          </Box>
          <Box sx={classes.widgetBlockText}>
            {isLoading ? (
              <Skeleton animation="wave" width={80} height={30} />
            ) : (
              <Box sx={classes.blockCount}>{`${applications} (${openApplications})`}</Box>
            )}
            <Box sx={classes.blockTitle}>Applications</Box>
          </Box>
        </Box>
        <Box sx={classes.widgetBlock}>
          <Box sx={classes.widgetBlockIcon}>
            <VisibilityIcon />
          </Box>
          <Box sx={classes.widgetBlockNew}>
            {isLoading ? (
              <Skeleton animation="wave" width={32} height={26} />
            ) : (
              jobCounts?.unique_page_views?.daily_unique_views > 0 &&
              `+ ${jobCounts?.unique_page_views?.daily_unique_views}`
            )}
          </Box>
          <Box sx={classes.widgetBlockText}>
            {isLoading ? (
              <Skeleton animation="wave" width={80} height={30} />
            ) : (
              <Box sx={classes.blockCount}>{jobCounts?.unique_page_views?.unique_views}</Box>
            )}
            <Box sx={classes.blockTitle}>Unique Pageviews</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
