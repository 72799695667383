import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import {
  ArrowCircleLeft as ArrowCircleLeftIcon,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  AssignmentInd as AssignmentIndIcon
} from '@mui/icons-material';
import { ITabs, IApprovalForm, IAnswer } from '../types';
import { styles } from '../styles';
import { PDFIcon } from '../../Job/Applications/CustomIcons';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import Api from '../API';
import AnswersView from './AnswersView';
import ApproversTable from './ApproversTable';
import NewJob from '../../Jobs/NewJob/NewJob';
import DocumentViewer from './DocumentViewer';
import { IPermissions } from '../../Jobs/types';
import { IUserPermissions } from '../../Components/sharedTypes';
import { getPermissions } from '../../../shared/permissionHelpers';
import QuestionFieldGenerator, {
  IApplicantQuestion
} from '../../Components/Utilities/QuestionFieldGenerator';

const BASE_URL = window.location.origin;

export default function ApprovalForm({
  apiKey,
  requisitionId
}: {
  apiKey: string;
  requisitionId: number;
}) {
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [approvalFormData, setApprovalFormData] = useState<IApprovalForm>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: string;
  }>({
    message: '',
    state: 'success'
  });
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isCloning, setIsCloning] = useState<boolean>(false);
  const [isGeneratingBulkPdf, setIsGeneratingBulkPdf] = useState<boolean>(false);
  const [userPermissions, setUserPermissions] = useState<IUserPermissions | null>(null);
  const [reorderedAnswers, setReorderedAnswers] = useState<IAnswer[]>([]);
  const [emailTemplateQuestions, setEmailTemplateQuestions] = useState<IApplicantQuestion[]>([]);

  const fieldRefs = {
    questions: useRef<Array<HTMLDivElement | null>>([])
  };

  const getUserPermissions = useCallback(async () => {
    setIsLoadingPermissions(true);
    try {
      const data = await getPermissions(apiKey);
      const allRoles: Record<string, Record<string, boolean>> = {};
      data.roles.forEach((role: IPermissions) =>
        role.feature_groups.forEach((group) => {
          !allRoles[group.name] ? (allRoles[group.name] = {}) : null;
          group.permissions.forEach((permission) => (allRoles[group.name][permission] = true));
        })
      );
      setUserPermissions(allRoles);
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting permissions',
        state: 'error'
      });
    } finally {
      setIsLoadingPermissions(false);
    }
  }, [apiKey]);

  const getApprovalFormData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await Api.getApprovalForm({ 'X-api-authenticate': apiKey }, requisitionId);
      const answers = data.res.answers;

      const orderedAnswers = answers.sort((a, b) => a.position - b.position);
      setApprovalFormData(data.res);
      setReorderedAnswers(orderedAnswers);
      setEmailTemplateQuestions(data.res.email_template_fields);
    } catch (err) {
      setSnackbar({
        message: `Failed to fetch approval form data, ${err}`,
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, requisitionId]);

  useEffect(() => {
    getApprovalFormData();
    getUserPermissions();
  }, [getApprovalFormData, getUserPermissions]);

  const headerRefs = useRef(null);

  const TabPanel = useCallback((props: ITabs) => {
    const { children, tabValue, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        id={`approvals-tabpanel-${index}`}
        aria-labelledby={`approvals-tab-${index}`}
        {...other}
      >
        {tabValue === index && <>{children}</>}
      </div>
    );
  }, []);

  const TabProps = useCallback((index: number) => {
    return {
      id: `approvals-tab-${index}`,
      'aria-controls': `approvals-tabpanel-${index}`
    };
  }, []);

  const navigateToEditApproval = () => {
    window.location.href = `/admin/requisitions/${requisitionId}/edit`;
  };

  useEffect(() => {
    setIndicatorWidth(headerRefs?.current.clientWidth - 32);
  }, []);

  const cloneApprovalForm = async () => {
    setIsCloning(true);
    try {
      const response = await Api.cloneApprovalForm({ 'X-api-authenticate': apiKey }, requisitionId);
      if (response.res.success === 'Approval cloned') {
        setSnackbar({
          message: 'Approval form has been successfully cloned.',
          state: 'success'
        });
        window.location.href = `${BASE_URL}${response.res.url}`;
      }
    } catch (error) {
      setSnackbar({
        message: `Failed to clone approval form, ${error?.errors}`,
        state: 'error'
      });
    } finally {
      setIsCloning(false);
    }
  };

  const generateBulkPdf = async () => {
    setIsGeneratingBulkPdf(true);
    try {
      const response = await Api.generateBulkPdf({ 'X-api-authenticate': apiKey }, requisitionId);
      if (response.res.success === 'Report submitted') {
        setSnackbar({
          message: 'Bulk pdf has been successfully submitted.',
          state: 'success'
        });
      }
    } catch (error) {
      setSnackbar({
        message: `Failed to generate bulk pdf, ${error?.errors}`,
        state: 'error'
      });
    } finally {
      setIsGeneratingBulkPdf(false);
    }
  };

  const jobDefaults = useMemo(() => {
    if (!approvalFormData.answers?.length) return {};
    return approvalFormData.answers
      .filter((answer) => !!answer.job_field)
      .filter((answer) =>
        answer.type === 'RankField' ||
        (answer.job_field === 'expires_at' && answer.type !== 'DateField') ||
        !answer.answer
          ? false
          : true
      )
      .reduce(
        (acc, cur) => {
          acc[cur.job_field] = cur.answer;
          return acc;
        },
        { title: approvalFormData.position_title }
      );
  }, [approvalFormData.answers, approvalFormData.position_title]);

  return (
    <Box sx={styles.jobPageContainer}>
      {isLoading ? (
        <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
      ) : (
        <Box sx={{ ...styles.jobPageHeader, marginBottom: 'unset', alignItems: 'start' }}>
          <h1>{approvalFormData.position_title}</h1>
          <Stack>
            <Stack sx={styles.approvalFormIconsContainer}>
              {(userPermissions?.['Approval Forms']?.['Edit Approval Form at Any Stage (HR use)'] ||
                ((approvalFormData.state === 'draft' || approvalFormData.state === 'pending') &&
                  userPermissions?.['Approval Forms']?.['Edit Previously Submitted Form'])) && (
                <Tooltip title="Edit">
                  <IconButton
                    sx={styles.iconGrey}
                    id="edit-approval-form-button"
                    onClick={navigateToEditApproval}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {userPermissions?.['Approval Forms']?.['Clone Previously Submitted Form'] && (
                <Tooltip title="Clone">
                  <IconButton
                    sx={styles.iconGrey}
                    id="clone-approval-form-button"
                    onClick={cloneApprovalForm}
                  >
                    {isCloning ? (
                      <CircularProgress size={24} color={'inherit'} />
                    ) : (
                      <FileCopyIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              {userPermissions?.['Applications']?.['Bulk PDF'] && (
                <Tooltip title="Bulk PDF">
                  <IconButton
                    sx={styles.iconGrey}
                    id="export-approval-form-pdf-button"
                    onClick={generateBulkPdf}
                  >
                    {isGeneratingBulkPdf ? (
                      <CircularProgress size={24} color={'inherit'} />
                    ) : (
                      <PDFIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              {approvalFormData.state === 'approved' &&
                userPermissions?.['Approval Forms']?.['Create Job From Approval Form'] && (
                  <Button
                    sx={{ ...styles.modalSaveButton, marginLeft: 1, width: '100px' }}
                    onClick={() => setIsDialogOpen(true)}
                  >
                    Create Job
                  </Button>
                )}
            </Stack>
          </Stack>
        </Box>
      )}
      <Stack sx={styles.approvalFormSubtitle}>{`Approval #${requisitionId}`}</Stack>
      {isLoading ? (
        <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
      ) : (
        <Box sx={styles.statusContainer}>
          <Typography
            sx={{
              ...styles[approvalFormData.state],
              fontSize: '13px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              padding: '6px 16px',
              borderRadius: (theme) => theme.spacing(0.5)
            }}
          >
            {approvalFormData.state}
          </Typography>
        </Box>
      )}
      <Box sx={styles.tabsContainer}>
        <Tabs
          value={0}
          variant="scrollable"
          scrollButtons={false}
          aria-label="Approvals"
          sx={{
            ...styles.tabs,
            '& .MuiTabs-indicator': { ...styles.tabIndicator, maxWidth: indicatorWidth + 'px' }
          }}
        >
          <Tab ref={headerRefs} label="Approval form summary" {...TabProps(0)} />
        </Tabs>
      </Box>
      <Box>
        <TabPanel tabValue={0} index={0} />
      </Box>
      <Box sx={styles.backToApprovalFormsButton}>
        <Stack
          flexDirection="row"
          alignItems="center"
          onClick={() => {
            window.location.href = '/admin/requisitions';
          }}
        >
          <IconButton sx={styles.iconGrey}>
            <ArrowCircleLeftIcon />
          </IconButton>
          <Box>Back to approval forms</Box>
        </Stack>
      </Box>
      {isLoading ? (
        <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
      ) : (
        <>
          {approvalFormData?.requisition_requester && (
            <Box sx={{ padding: 2, marginBottom: 2 }}>
              <Stack sx={{ ...styles.sectionHeader, paddingBottom: 2 }}>
                Approval created with a public link
              </Stack>
              <Stack sx={{ rowGap: 2 }}>
                <Stack>Requested by: {approvalFormData?.requisition_requester.name}</Stack>
                <Stack>Email: {approvalFormData?.requisition_requester.email}</Stack>
                <Stack>Phone: {approvalFormData?.requisition_requester.phone}</Stack>
              </Stack>
            </Box>
          )}
          <Box>
            <Stack sx={{ ...styles.sectionHeader, paddingLeft: 2 }}>Form</Stack>
            {reorderedAnswers?.map((answer) => <AnswersView answer={answer} key={answer.id} />)}
          </Box>
          {/* TODO:Hide this section for now */}
          {/* <Box>
            {emailTemplateQuestions.length > 0 && (
              <Stack sx={{ ...styles.sectionHeader, paddingLeft: 2 }}>
                Email Template Questions
              </Stack>
            )}
            {emailTemplateQuestions?.map((question, index) => (
              <Box sx={{ paddingLeft: 2 }} key={question.id}>
                <QuestionFieldGenerator
                  question={question}
                  questionRef={(el) => (fieldRefs.questions.current[index] = el)}
                  questionError={''}
                  onChangeCallback={() => {}}
                  answer={''}
                  disabled={true}
                />
              </Box>
            ))}
          </Box> */}
          <Box sx={{ padding: 2 }}>
            <Stack sx={{ ...styles.sectionHeader, paddingBottom: 2 }}>Documents</Stack>
            <Stack sx={{ rowGap: 2 }}>
              {approvalFormData.documents?.map((document) => (
                <DocumentViewer document={document} key={document.id} />
              ))}
            </Stack>
          </Box>
          {!Number(approvalFormData?.template_config?.hide_assigned_user) &&
            userPermissions?.['Approval Forms']?.['Assign User To Requisition'] && (
              <Box sx={{ padding: 2 }}>
                <Stack sx={{ ...styles.sectionHeader, paddingBottom: 2 }}>Assigned User</Stack>
                <Stack>
                  {approvalFormData.assigned_user ? approvalFormData.assigned_user : '-'}
                </Stack>
              </Box>
            )}
          {!Number(approvalFormData?.template_config?.hide_approval_administrator) && (
            <Box sx={{ padding: 2 }}>
              <Stack sx={{ ...styles.sectionHeader, paddingBottom: 2 }}>
                Approval Administrator
              </Stack>
              <Stack>
                {approvalFormData.approval_administrator
                  ? approvalFormData.approval_administrator
                  : '-'}
              </Stack>
            </Box>
          )}
          <Box sx={{ padding: 2 }}>
            <Stack sx={{ ...styles.sectionHeader, paddingBottom: 2 }}>Approvers</Stack>
            <Stack>
              {approvalFormData.approvers?.length > 0 ? (
                <ApproversTable
                  apiKey={apiKey}
                  approvers={approvalFormData.approvers}
                  setSnackbar={setSnackbar}
                  requisitionId={requisitionId}
                  approvalFormState={approvalFormData?.state}
                  getApprovalFormData={getApprovalFormData}
                  userPermissions={userPermissions}
                />
              ) : (
                'No approvers yet.'
              )}
            </Stack>
          </Box>
          {userPermissions?.['Approval Forms']?.['Manage Approval Form Notes'] && (
            <Box sx={{ padding: 2 }}>
              <Stack sx={{ ...styles.sectionHeader, paddingBottom: 2 }}>Notes</Stack>
              <Stack>{approvalFormData.note ? approvalFormData.note : '-'}</Stack>
            </Box>
          )}
          {approvalFormData.application && (
            <Box sx={{ padding: 2 }}>
              <Stack sx={{ ...styles.sectionHeader, paddingBottom: 2 }}>Application</Stack>
              <Stack flexDirection="row" alignItems="center">
                <Box
                  sx={{ ...styles.fileIcon, ...styles.applicationIcon, cursor: 'pointer' }}
                  onClick={() => (window.location.href = approvalFormData.application.url)}
                >
                  <AssignmentIndIcon />
                </Box>
                <Box
                  sx={{ ...styles.fileName, cursor: 'pointer' }}
                  onClick={() => (window.location.href = approvalFormData.application.url)}
                >
                  {approvalFormData.application.id} - {approvalFormData.application.name}
                </Box>
              </Stack>
            </Box>
          )}
        </>
      )}
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
      <NewJob
        apiKey={apiKey}
        jobDefaults={jobDefaults}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        fromApproval
        approvalFormId={requisitionId}
      />
    </Box>
  );
}
