import React, { Dispatch } from 'react';
import { Box, Stack } from '@mui/material';
import { classes } from './styles';
import {
  FormTextField,
  MultilineFormTextField
} from '../../../NewUI/Components/CustomUIElements/FormTextField';
import { ScoutCalendarAction, ScoutCalendarState } from '../../types';

export default function SecondStep({
  ScoutCalendarState,
  dispatch
}: {
  ScoutCalendarState: ScoutCalendarState;
  dispatch: Dispatch<ScoutCalendarAction>;
}) {
  const { createEventObject, errorsMessage } = ScoutCalendarState;

  return (
    <Box>
      <Box sx={classes.progressHeader}>Event Details</Box>
      <Stack sx={{ height: '415px', rowGap: 3 }}>
        <FormTextField
          label="Event name"
          value={createEventObject.eventName}
          onChange={(e) =>
            dispatch({
              type: 'SET_CREATE_EVENT_OBJECT',
              payload: { ...createEventObject, eventName: e.target.value }
            })
          }
          fullWidth
          required
          error={errorsMessage.eventName}
          maxLength={255}
        />
        <Box>
          <MultilineFormTextField
            label="Description"
            value={createEventObject.eventDescription}
            onChange={(e) =>
              dispatch({
                type: 'SET_CREATE_EVENT_OBJECT',
                payload: { ...createEventObject, eventDescription: e.target.value }
              })
            }
            fullWidth
            rows={6}
            placeholder="Write your additional notes or comments"
            maxLength={255}
          />
          <Stack sx={{ paddingRight: 1, flexDirection: 'row-reverse', color: '#838383' }}>
            {createEventObject.eventDescription.length}/255
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
