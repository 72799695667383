import React from 'react';
import PropTypes from 'prop-types';

export default class SearchBox extends React.Component {
  static propTypes = {
    data: PropTypes.object
  }

  render() {
    const { data } = this.props;

    return(
      <div className='search-welcome'>
        <form action='/admin/search/advanced/results' acceptCharset='UTF-8' method='get'>
          <input name='utf8' type='hidden' value='✓' />
          <input type='search' name='query' defaultValue={ data.query} id='query' className='search' autoFocus='autofocus' />
          { data.by_title && (<input type='hidden' name='by_title' value={ data.by_title } />) }
          { data.post_code1 && (<input type='hidden' name='post_code1' value={ data.post_code1 } />) }
          { data.post_code2 && (<input type='hidden' name='post_code2' value={ data.post_code2 } />) }
          { data.date1 && (<input type='hidden' name='date1' value={ data.date1 } />) }
          { data.date2 && (<input type='hidden' name='date2' value={ data.date2 } />) }
          { data.status && data.status.map((status, key) => (<input type='hidden' name='status[]' value={ status } key={ key }/>)) }
          { data.filter_out_scout_scout && (<input type='hidden' name='filter_out_scout_client' value={ data.filter_out_scout_client } />) }
          <input type='submit' name='commit' value='Search Now' className='search' />
        </form>
      </div>
    )
  }
}
