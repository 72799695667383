import React from 'react';
import { Box } from '@mui/material';
import { FelixMessageSVG } from '../../SharedComponents/Graphics/FelixSVG';
import { classes } from '../styles';

const WelcomeFooter = () => {
  return (
    <Box sx={classes.footerContainer}>
      <Box sx={classes.felixMessageContainer}>
        <FelixMessageSVG />
        <Box sx={{ ...classes.felixMessage, maxWidth: '608px' }}>
          <div>
            Welcome to Smart Share, powered by AI Studio. I&apos;m Felix, your AI assistant.
          </div>
          <div>
            I can help you write a <span>job board advertisment</span> or{' '}
            <span>social media post</span> for your job.
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default WelcomeFooter;
