import React from 'react';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import { classes } from '../styles';
import { classes as sharedClasses } from '../../Questions/styles';
import { QuestionsList } from './QuestionsList';
import { StyledSnackbarProps } from '../../../Components/CustomUIElements/StyledSnackbar';

const AIStudioSettings = ({
  jobId,
  setSnackbarState
}: {
  jobId: number;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
}) => {
  return (
    <Box sx={classes.tabContent}>
      <Box sx={classes.headerContent}>AI Studio settings</Box>
      <Box
        sx={{
          ...sharedClasses.noticeContainer,
          justifyContent: 'flex-start',
          margin: '0 0 18px 0'
        }}
      >
        <InfoIcon sx={{ ...sharedClasses.infoIcon, marginTop: '2px' }} />
        <Box sx={{ marginLeft: 1 }}>
          All changes in this section will be automatically saved and applied immediately.
        </Box>
      </Box>
      <Box sx={classes.bodyContainer}>
        <Box sx={classes.headerContent}>Application summaries question selection</Box>
        <Box sx={classes.editInfoText}>
          Select the screening questions you want to include when generating AI application
          summaries, AI insights or interview questions for this job. All questions will be included
          by default.
        </Box>
      </Box>
      <QuestionsList jobId={jobId} setSnackbarState={setSnackbarState} />
    </Box>
  );
};

export default AIStudioSettings;
