import React, { memo } from 'react';
import { TTabs } from '../types';

const TabPanel = (props: TTabs) => {
  const { children, tabValue, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`engage-tabpanel-${index}`}
      aria-labelledby={`engage-tab-${index}`}
      {...other}
    >
      {tabValue === index && <>{children}</>}
    </div>
  );
};

export default memo(TabPanel);
