"use strict";
import { theme } from "../ThemeContext/ThemeObject";
export const classes = {
  subWidget: {
    flex: "40%",
    borderRadius: "3px",
    height: "120px",
    minWidth: "524px"
  },
  widgetBlockContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    columnGap: "50px"
  },
  widgetBlock: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "4%",
    width: "50%",
    height: "100%"
  },
  widgetBlockText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    rowGap: "3px",
    marginLeft: "25px"
  },
  blockCount: {
    fontSize: "24px",
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  blockTitle: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#333333"
  },
  widgetBlockIcon: {
    backgroundColor: "#EDF2F5",
    padding: "12px",
    borderRadius: "16px",
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "28px"
    }
  }
};
