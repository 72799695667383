import React, { useState } from 'react';
import { ApplicationAction } from '../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { Box, Stack } from '@mui/material';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { IApplication } from '../types';

export function DeleteApplication({
  dispatch,
  isDialogOpen
}: {
  dispatch: React.Dispatch<ApplicationAction>;
  isDialogOpen: boolean;
}) {
  const [confirmDelete, setConfirmDelete] = useState<string>('');
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const deleteApplication = useMutation({
    mutationFn: async () => {
      if (application) {
        const { res } = await Api.deleteApplication(application?.job.id, application.id);
        return res;
      }
    },
    onSuccess: () => {
      window.location.href = `${window.location.origin}/admin/jobs/${application?.job.id}?applications=true`;
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `${application?.candidate?.firstname} ${application?.candidate?.lastname} successfully deleted`,
          state: 'success'
        }
      });
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `There was an error deleting application, ${error}`, state: 'error' }
      });
    },
    onSettled: () => {
      dispatch({
        type: 'SET_MODALS_OPEN',
        payload: null
      });
    }
  });

  const dialogDescription = (
    <Stack sx={{ color: '#000000', rowGap: 1 }}>
      <Box>
        This will <b>permanently</b> delete the application and all
      </Box>
      <Box>associated tasks, notes, and all events and invitations.</Box>
      <Stack sx={{ rowGap: 1, alignItems: 'center' }}>
        <Box sx={{ color: '#939393', fontSize: '14px', '& b': { color: '#000000' } }}>
          Type the word <b>DELETE</b> in the text field below to
          <Box>confirm deletion</Box>
        </Box>
        <FormTextField value={confirmDelete} onChange={(e) => setConfirmDelete(e.target.value)} />
      </Stack>
    </Stack>
  );
  return (
    <GenericDialog
      isDialogOpen={isDialogOpen}
      setDialogOpen={() => {
        dispatch({
          type: 'SET_MODALS_OPEN',
          payload: null
        });
      }}
      title="Are you sure you want to delete this application?"
      description={dialogDescription}
      buttonCallback={() => deleteApplication.mutate()}
      callbackLoading={deleteApplication.isLoading}
      buttonText="Delete"
      url=""
      disablePrimaryButton={confirmDelete !== 'DELETE'}
      primaryButtonId="confirm-delete-application-button"
      secondaryButtonId="cancel-delete-application-button"
    />
  );
}
