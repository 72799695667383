"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { serialize } from "object-to-formdata";
export const handleAPIRequest = (_0) => __async(void 0, [_0], function* ({
  url,
  method,
  body,
  onSuccess,
  onFailure,
  onFinally,
  setLoaderState,
  setSnackbarState,
  successMessage,
  failureMessage,
  isFormData = false
}) {
  setLoaderState && setLoaderState(true);
  try {
    const response = yield fetch(url, __spreadValues(__spreadValues({
      method
    }, !isFormData && {
      headers: {
        "Content-Type": "application/json"
      }
    }), body && (isFormData ? { body: serialize(body) } : { body: JSON.stringify(body) })));
    const data = yield response.json();
    if (response.ok) {
      onSuccess && onSuccess(data);
      if (setSnackbarState && (successMessage !== void 0 ? successMessage.show : true)) {
        setSnackbarState({
          message: (successMessage == null ? void 0 : successMessage.message) || data.message || "Success",
          state: "success"
        });
      }
    } else {
      throw data;
    }
  } catch (error) {
    if (setSnackbarState && (failureMessage !== void 0 ? failureMessage.show : true)) {
      setSnackbarState({
        message: (failureMessage == null ? void 0 : failureMessage.message) || error.message || error.errors || "Something went wrong, please try again",
        state: "error"
      });
    }
    onFailure && onFailure(error);
  } finally {
    setLoaderState && setLoaderState(false);
    onFinally && onFinally();
  }
});
