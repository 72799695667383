import axios from 'axios';

export default class ServiceApi {
  static get(id, callback) {
    return axios.get(
      `/admin/integration_menu_items/${id}.json`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
      },
    ).then(response => callback(response.data));
  }

  static getPage(path, callback) {
    return axios.get(
      path, {
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
      },
    ).then(response => callback(response));
  }

  static sendNotification(id, pack, callback) {
    return axios.post(
      `/admin/integration_menu_items/${id}/notify_shortlisting`,
      { price_pack: pack },
      {
        'Content-Type': 'application/json',
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      },
    ).then(response => callback(response.data));
  }
}

