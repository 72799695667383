import React, { useState, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { classes } from '../styles';

export interface IMetaData {
  title?: string;
  description?: string;
  image?: string;
}

const SocialMediaPreview = ({ apiKey, jobUrl }: { apiKey: string; jobUrl: string }) => {
  const [metaData, setMetaData] = useState<IMetaData | null>(null);

  const matches = jobUrl.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  const domain = matches && matches[1];

  useEffect(() => {
    const fetchMetaData = async () => {
      const { metadata } = await fetch(`/api/v4/meta_scraper?url=${jobUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((res) => res.json());
      setMetaData(metadata);
    };
    fetchMetaData();
  }, [apiKey, jobUrl]);

  if (metaData) {
    return (
      <Box sx={classes.socialCardPreview}>
        {metaData.image ? (
          <Box sx={{ ...classes.previewImage, backgroundImage: `url(${metaData.image})` }} />
        ) : (
          <Box sx={classes.previewImage}>
            <ImageNotSupportedIcon style={classes.noImageIcon} />
          </Box>
        )}
        <Box sx={classes.socialCardPreviewFooter}>
          <Box sx={classes.previewTitle}>
            {metaData?.title && metaData?.title.length > 58
              ? metaData?.title.slice(0, 58) + '...'
              : metaData?.title}
          </Box>
          <Box sx={classes.previewDescription}>
            {metaData?.description && metaData?.description.length > 130
              ? metaData?.description.slice(0, 130) + '...'
              : metaData?.description}
          </Box>
          <Box sx={classes.previewLink}>{domain}</Box>
        </Box>
      </Box>
    );
  } else {
    return <Skeleton sx={classes.skeleton} />;
  }
};

export default SocialMediaPreview;
