import { theme } from "../ThemeContext/ThemeObject";
export const classes = {
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      borderBottom: "1px solid",
      borderBottomColor: "rgba(0, 0, 0, 0.12)",
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2)
    }
  },
  chip: {
    zIndex: 2,
    color: theme.palette.common.white,
    backgroundColor: "#616161",
    fontFamily: "Source Sans Pro"
  },
  actor: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    margin: `0 ${theme.spacing(0.5)}`,
    fontSize: "0.875rem",
    lineHeight: 1.43
  },
  activity: {
    fontFamily: "Source Sans Pro",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.43,
    color: "rgba(0, 0, 0, 0.87)",
    "& a": {
      color: "#2a7572",
      textDecoration: "none"
    }
  },
  timeStamp: {
    fontSize: "0.875rem",
    fontFamily: "Source Sans Pro",
    fontWeight: 500,
    lineHeight: 1.57,
    color: "rgba(0, 0, 0, 0.6)"
  },
  accordian: {
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "40px"
    }
  },
  activitiesContainer: {
    maxHeight: "400px",
    overflow: "auto",
    flexDirection: "column"
  },
  accordianButton: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    minHeight: "40px",
    "& .MuiAccordionSummary-content": {
      margin: "0 !important"
    }
  },
  accordianTitle: {
    fontSize: "0.88rem",
    fontFamily: "Source Sans Pro",
    fontWeight: 700,
    lineHeight: 1.75,
    textTransform: "none",
    color: theme.palette.common.white
  }
};
