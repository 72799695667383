import React, { useState, Dispatch } from 'react';
import { Box } from '@mui/material';
import BooleanEditor from '../../../SharedComponents/BooleanEditor';
import DynamicFooter from '../../../../AIStudio/SharedComponents/Footers/DynamicFooter';
import { EngageAction } from '../../../reducer';
import {
  booleanQueryToEditableArray,
  editableBooleanArrayToBooleanQuery,
  IBooleanQueryObject
} from '../../../SharedComponents/booleanQueryParser';
import { classes } from '../../../styles';

const EditBooleanStep = ({
  booleanQuery,
  isEditing,
  dispatch
}: {
  booleanQuery: string;
  isEditing: boolean;
  dispatch: Dispatch<EngageAction>;
}) => {
  const [editableBooleanArray, setEditableBooleanArray] = useState<IBooleanQueryObject[]>(
    booleanQueryToEditableArray(booleanQuery)
  );

  const handleConfirmBooleanQuery = () => {
    const editedBooleanQuery = editableBooleanArrayToBooleanQuery(editableBooleanArray);
    dispatch({ type: 'CONFIRM_EDIT_BOOLEAN_QUERY', payload: editedBooleanQuery });
  };

  return (
    <>
      <Box sx={classes.modalHeader}>Refine your search</Box>
      <Box sx={{ ...classes.modalMainContentContainer, rowGap: '26px', marginBottom: '20px' }}>
        <Box sx={classes.editBooleanStepHeader}>I'm looking for...</Box>
        <BooleanEditor
          editableBooleanArray={editableBooleanArray}
          setEditableBooleanArray={setEditableBooleanArray}
        />
      </Box>
      <DynamicFooter
        primaryButtonText="Search"
        primaryButtonID="search-button"
        secondaryButtonID="back-button"
        handlePrimaryButtonClick={handleConfirmBooleanQuery}
        secondaryButtonText={isEditing ? 'Cancel' : 'Back'}
        handleSecondaryButtonClick={() => {
          isEditing
            ? dispatch({ type: 'CANCEL_EDIT_BOOLEAN_QUERY' })
            : dispatch({ type: 'SET_TLP_MATCH_MODAL_STEP', payload: 'select' });
        }}
        felixMessage={
          <>
            Refine your <span>search preferences</span> and I'll find candidates that best match
            them!
          </>
        }
        felixMessageMaxWidth="360px"
      />
    </>
  );
};

export default EditBooleanStep;
