import React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { classes } from '../styles';
import { headerText } from '../config';
import dompurify from 'dompurify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SocialMediaPreview from './SocialMediaPreview';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function ResponseStep({
  apiKey,
  generatedResponse,
  generatedKeyPoints,
  generatedResponseRef,
  generationType,
  jobUrl,
  generatePost,
  generateSummary
}: {
  apiKey: string;
  generatedResponse: string;
  generatedKeyPoints: string[];
  generatedResponseRef: React.RefObject<HTMLDivElement>;
  generationType: string;
  jobUrl: string;
  generatePost: () => void;
  generateSummary: () => void;
}) {
  return (
    <>
      <Box sx={classes.responseHeader}>
        <Box sx={classes.responseHeaderText}>{`Your draft ${
          headerText[generationType as keyof typeof headerText]
        }`}</Box>
        <Button
          id={`regenerate-${generationType}`}
          sx={classes.pill}
          endIcon={<CachedIcon />}
          onClick={generationType === 'advertisement' ? generateSummary : generatePost}
        >
          Regenerate
        </Button>
      </Box>
      {generationType === 'advertisement' ? (
        <>
          <Box
            sx={classes.generatedResponseContainer}
            ref={generatedResponseRef}
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(generatedResponse)
            }}
          />
          {generatedKeyPoints?.length > 0 && (
            <Box sx={{ width: '90%' }}>
              <Box sx={classes.fieldSubHeader}>Key selling points</Box>
              <Box>
                {generatedKeyPoints.map((point, index) => (
                  <Box key={index} sx={classes.keyPoint}>
                    {point}
                    <CopyToClipboard text={point}>
                      <IconButton sx={classes.copyIcon}>
                        <ContentCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box sx={classes.socialResponseContainer}>
          <Box sx={classes.socialCard}>
            <Box sx={classes.socialCardHeader}>
              <span>{generatedResponse}</span>
              <a href={jobUrl}>{jobUrl}</a>
            </Box>
            <SocialMediaPreview apiKey={apiKey} jobUrl={jobUrl} />
          </Box>
        </Box>
      )}
    </>
  );
}

export default ResponseStep;
