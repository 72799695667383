import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { externalTooltipHandler } from './LineTooltip';
import { IChartData } from './types';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function LineChart({ data }: { data: IChartData }) {
  defaults.font.family = 'Source Sans Pro';

  const chartAreaBorder = {
    id: 'chartAreaBorder',
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { left, top, width, height }
      } = chart;
      ctx.save();
      ctx.strokeStyle = options.borderColor;
      ctx.lineWidth = options.borderWidth;
      ctx.setLineDash(options.borderDash || []);
      ctx.lineDashOffset = options.borderDashOffset;
      ctx.strokeRect(left, top, width, height);
      ctx.restore();
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.4,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler
      },
      chartAreaBorder: {
        borderColor: '#F0F0F0',
        borderWidth: 1
      }
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14
          },
          color: '#333333'
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          font: {
            size: 12
          },
          color: '#A3A3A3'
        },
        grid: {
          display: true,
          color: '#F0F0F0',
          drawTicks: false,
          drawBorder: false
        }
      }
    }
  };
  const dataset = {
    labels: data?.labels.map((date) => moment(date).format('MMM DD')),
    datasets: [
      {
        data: data?.data,
        pointRadius: 4.5,
        pointHoverRadius: 7.5,
        hoverBorderWidth: 18,
        borderColor: '#084D6D80',
        hoverBorderColor: '#084D6D24',
        pointBackgroundColor: '#084D6D',
        pointBorderColor: '#084D6D70'
      }
    ]
  };

  return <Line options={options} plugins={[chartAreaBorder, Tooltip]} data={dataset} />;
}
