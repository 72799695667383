import React, { useRef, Dispatch } from 'react';
import { Box, Paper, SxProps, Table, TableContainer } from '@mui/material';
import { classes } from './styles';
import {
  handleScrollableEnter,
  handleScrollableExit,
  handleScroll,
  tablePadding
} from './tableHelper';
import GenericTableHead from './GenericTableHead';
import GenericTableBody from './GenericTableBody';
import GenericTablePagination from './GenericTablePagination';
import TableArrows from './TableArrows';
import LoadingTableItems from './LoadingTableItems';
import { GenericTableData, TPaginationState, TSortableColumn, TTableState } from './types';
import NoItemsMessage from './NoItemsMessage';
import { GenericTableAction } from './genericTableReducer';
import { ActionListItem } from './GenericTableActions';

interface IGenericTableProps<T> {
  items: T[];
  isLoadingItems: boolean;
  dispatch: Dispatch<GenericTableAction<T>>;
  tableState: TTableState<T, TSortableColumn[]>;
  paginationState: TPaginationState<string>;
  headerStyle: (id: string, tableHovered: boolean) => SxProps;
  TableRows: React.FC<{
    column: TSortableColumn;
    item: any;
    tableHovered: boolean;
  }>;
  TableActionList?: ActionListItem[];
  pagination?: { totalPages: number; totalItems: number };
  allowMultipleSelection?: boolean;
  label: string;
  titleCellId?: string;
  userPreferenceSource?: string;
  customEmptyStateMessage?: string;
}

const GenericTable = <GenericTableProps extends GenericTableData>({
  items,
  pagination,
  isLoadingItems,
  dispatch,
  tableState,
  paginationState,
  headerStyle,
  TableRows,
  TableActionList,
  allowMultipleSelection = true,
  label,
  titleCellId, // This is used to attach a ref to the title cell for the table arrows
  userPreferenceSource,
  customEmptyStateMessage
}: IGenericTableProps<GenericTableProps>) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const titleCellRef = useRef<HTMLDivElement>(null);
  const actionCellRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  return (
    <Box>
      <Box sx={classes.tableAndArrowsContainer} ref={parentRef}>
        {!isLoadingItems && (
          <TableArrows
            tableRef={tableRef}
            titleCellRef={titleCellRef}
            actionCellRef={actionCellRef}
            parentRef={parentRef}
            tableState={tableState}
            dispatch={dispatch}
          />
        )}
        <TableContainer
          sx={{ boxShadow: 'none', scrollBehavior: 'smooth' }}
          component={Paper}
          onMouseEnter={() => handleScrollableEnter(tableRef, dispatch)}
          onMouseLeave={() => handleScrollableExit(dispatch)}
          ref={tableRef}
          onScroll={() => handleScroll(tableRef, dispatch)}
        >
          <Table
            sx={{
              ...classes.tableWrapper,
              'th, td, tr, thead': {
                padding: `${tablePadding(tableState.density)} 10px`
              }
            }}
            aria-label={`${label} table`}
          >
            <GenericTableHead
              tableState={tableState}
              paginationState={paginationState}
              dispatch={dispatch}
              items={items}
              titleCellRef={titleCellRef}
              actionCellRef={actionCellRef}
              headerStyle={headerStyle}
              userPreferenceSource={userPreferenceSource}
              allowMultipleSelection={allowMultipleSelection}
              hasActionItems={!!TableActionList}
              titleCellId={titleCellId}
            />
            {!isLoadingItems && (
              <GenericTableBody
                tableState={tableState}
                dispatch={dispatch}
                items={items}
                TableRows={TableRows}
                TableActionList={TableActionList}
                allowMultipleSelection={allowMultipleSelection}
              />
            )}
          </Table>
          {isLoadingItems && <LoadingTableItems numberOfRows={10} />}
          <NoItemsMessage
            showNoItemsMessage={items.length === 0 && !isLoadingItems}
            label={label}
            customEmptyStateMessage={customEmptyStateMessage}
          />
        </TableContainer>
      </Box>
      {pagination && (
        <GenericTablePagination
          paginationState={paginationState}
          dispatch={dispatch}
          totalPages={pagination.totalPages}
          totalItems={pagination.totalItems}
          userPreferenceSource={userPreferenceSource}
        />
      )}
    </Box>
  );
};

export default GenericTable;
