import React, { useEffect, useState } from 'react';
import SelectableCopyableQuestions from '../../../SharedComponents/SelectableCopyableQuestions';
import { handleAPIRequest } from '../../../../NewUI/Components/Utilities/handleAPIRequest';
import {
  arrayQuestion,
  formatQuestionArray
} from '../../../SharedComponents/Utility/questionStringToArray';
import DynamicFooter from '../../../SharedComponents/Footers/DynamicFooter';
import { StyledSnackbarProps } from '../../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { copyToClipboard } from '../../../../NewUI/Components/Utilities/copyToClipboard';
import { Box, Skeleton } from '@mui/material';
import { FelixConfused } from '../../../SharedComponents/Graphics/FelixSVG';
import { classes } from '../../styles';
import FollowUpEmailModal from '../FollowUpEmail/FollowUpEmail';

const SavedQuestionsTab = ({
  jobId,
  applicationId,
  setSnackbarState
}: {
  jobId: number;
  applicationId: number;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
}) => {
  const [questions, setQuestions] = useState<arrayQuestion[]>([]);
  const [questionsLoading, setQuestionsLoading] = useState<boolean>(true);
  const [copiedQuestions, setCopiedQuestions] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    handleAPIRequest({
      url: `/api/v4/jobs/${jobId}/applications/${applicationId}/saved_interview_questions`,
      method: 'GET',
      onSuccess: (response) => {
        setQuestions(formatQuestionArray(response?.questions || ''));
        setQuestionsLoading(false);
      }
    });
  }, [applicationId, jobId]);

  const handleDeleteQuestion = async (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index].status = 'deleting';
    setQuestions(newQuestions);
    await handleAPIRequest({
      url: `/api/v4/jobs/${jobId}/applications/${applicationId}/saved_interview_questions/remove_question`,
      method: 'PATCH',
      body: {
        saved_interview_questions: {
          index: index
        }
      },
      onSuccess: () => {
        setQuestions((prev) => prev.filter((_, i) => i !== index));
      }
    });
  };

  const handleCopyQuestions = () => {
    const questionCopyText = questions
      .filter((item) => item.selected)
      .map((item, index) => `${index + 1}. ${item.text}`)
      .join('\n\n');
    copyToClipboard(questionCopyText);
    setQuestions((prev) => prev.map((item) => ({ ...item, selected: false })));
    setCopiedQuestions(true);
    setTimeout(() => setCopiedQuestions(false), 3000);
  };

  return (
    <>
      {questionsLoading ? (
        [...Array(3)].map((_, index) => (
          <Skeleton key={index} sx={classes.questionSkeleton} animation="wave" />
        ))
      ) : (
        <>
          <SelectableCopyableQuestions
            questions={questions}
            setQuestions={setQuestions}
            maxHeight="450px"
            deleteQuestion={handleDeleteQuestion}
          />
          {!questions.length && (
            <Box sx={classes.noSavedQuestions}>
              <Box sx={{ width: '140px', marginBottom: '40px' }}>
                <FelixConfused />
              </Box>
              <Box sx={{ marginBottom: '10px' }}>
                No saved questions for the selected application
              </Box>
              <Box>Go to the create new questions tab to generate and save some!</Box>
            </Box>
          )}
        </>
      )}
      <DynamicFooter
        primaryButtonText="Email to Candidate"
        primaryButtonID="generate-email-button"
        disablePrimaryButton={!questions.some((item) => item.selected)}
        handlePrimaryButtonClick={() => setModalOpen(true)}
        secondaryButtonText={copiedQuestions ? 'Copied!' : 'Copy Questions'}
        secondaryButtonID="copy-questions-button"
        secondaryButtonMinWidth="167px"
        disableSecondaryButton={!questions.some((item) => item.selected)}
        handleSecondaryButtonClick={handleCopyQuestions}
        felixMessage={
          <>
            View, manage and copy all of your saved <span>questions</span> here. Select questions to
            copy multiple at a time!
          </>
        }
        felixMessageMaxWidth="450px"
      />
      {modalOpen && (
        <FollowUpEmailModal
          questions={questions.filter((item) => item.selected).map((item) => item.text)}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          jobId={jobId}
          applicationId={applicationId}
          setSnackbarState={setSnackbarState}
        />
      )}
    </>
  );
};

export default SavedQuestionsTab;
