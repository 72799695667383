import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Skeleton,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NewAutoReply from './NewAutoReply';
import ImmediateChangesNotice from '../shared/ImmediateChangesNotice';
import SkeletonTable from '../../shared/SkeletonTable';
import { classes } from '../styles';
import { IStatusTransitionsProps, IAutoReplies } from '../../types';
import { IApplicationStatus, IEmailTemplate } from '../../../Job/types';
import { immutableRemove } from '../../../Components/Utilities/immutableRemove';

export default function AutoReply({ apiKey, jobId, setSnackbar }: IStatusTransitionsProps) {
  const [loading, setLoading] = useState({
    autoReplies: true,
    applicationStatuses: true,
    templatesList: true,
    careAdvantageEvents: true
  });
  const [isDeleting, setIsDeleting] = useState(-1);
  const [autoReplies, setAutoReplies] = useState<IAutoReplies[]>([]);
  const [applicationStatuses, setApplicationStatuses] = useState<IApplicationStatus[]>([]);
  const [templates, setTemplates] = useState([]);
  const [hasCareAdvantageIntegration, setHasCareAdvantageIntegration] = useState(false);
  const [careAdvantageEvents, setCareAdvantageEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [replyIndex, setReplyIndex] = useState(0);

  const getAutoReplies = useCallback(async () => {
    setLoading((prev) => ({ ...prev, autoReplies: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_emails`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { auto_emails } = await response.json();
      setAutoReplies(auto_emails);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching auto emails',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, autoReplies: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getApplicationStatuses = useCallback(async () => {
    setLoading((prev) => ({ ...prev, applicationStatuses: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/job_application_statuses`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { job_application_statuses } = await response.json();
      setApplicationStatuses(job_application_statuses);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching application statuses',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, applicationStatuses: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getTemplatesAndPlaceholders = useCallback(async () => {
    setLoading((prev) => ({ ...prev, templatesList: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/email_templates`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((res) => res.json());
      setTemplates(
        response.email_templates.map(
          (template: IEmailTemplate) => `${template.id} - ${template.name}`
        )
      );
    } catch (error) {
      setSnackbar({
        message: 'Error fetching templates',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, templatesList: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getCareAdvantageEvents = useCallback(async () => {
    setLoading((prev) => ({ ...prev, careAdvantageEvents: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_emails/care_advantage_events`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((res) => res.json());
      setHasCareAdvantageIntegration(response.has_care_advantage_integration);
      setCareAdvantageEvents(response.care_advantage_events);
    } catch (error) {
      setSnackbar({
        message: 'Error fetching care advantage events',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, careAdvantageEvents: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const removeAutoReplies = async (index: number) => {
    setIsDeleting(index);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_emails/${autoReplies[index].id}`, {
        method: 'DELETE',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      if (response.ok) {
        setSnackbar({
          message: 'Auto email deleted successfully',
          state: 'success'
        });
        setAutoReplies(immutableRemove(autoReplies, index));
      } else {
        throw new Error('Error deleting auto email');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error deleting auto email',
        state: 'error'
      });
    } finally {
      setIsDeleting(-1);
    }
  };

  const handleOpenModal = (index: number) => {
    setReplyIndex(index);
    setModalOpen(true);
  };

  useEffect(() => {
    getAutoReplies();
    getApplicationStatuses();
    getTemplatesAndPlaceholders();
    getCareAdvantageEvents();
  }, [getApplicationStatuses, getAutoReplies, getTemplatesAndPlaceholders, getCareAdvantageEvents]);

  return (
    <Box sx={classes.automationContainer}>
      <Box sx={classes.titleAndButtonContainer}>
        <h2>Auto email reply</h2>
        <Button
          id="new-auto-email-reply-button"
          data-testid="new-auto-email-reply-button"
          sx={classes.newButton}
          onClick={() => handleOpenModal(-1)}
        >
          New auto email reply
        </Button>
      </Box>
      <ImmediateChangesNotice />
      {loading.autoReplies || (autoReplies && autoReplies.length > 0) ? (
        <TableContainer sx={classes.tableContainer}>
          <Table sx={classes.table} aria-label="auto email replies table">
            <TableHead>
              <TableRow>
                <TableCell>Trigger status</TableCell>
                <TableCell>Email template</TableCell>
                {/* <TableCell>Sender email</TableCell> */}
                <TableCell align="right">{/*Action column*/}</TableCell>
              </TableRow>
            </TableHead>
            {loading.autoReplies || loading.applicationStatuses
              ? <SkeletonTable rows={5} cols={3} />
              : (
                <TableBody>
                  {autoReplies.map((reply, index) => (
                    <TableRow key={reply.id}>
                      <TableCell>
                        {applicationStatuses.find((status) => status.id === reply.trigger_status_id)
                          ?.name ?? '-'}
                      </TableCell>
                      <TableCell>{reply.email_template_name || '-'}</TableCell>
                      {/* <TableCell>{reply.email_setting || '-'}</TableCell> */}
                      <TableCell sx={classes.tableActions}>
                        <IconButton
                          id="auto-email-reply-edit-button"
                          data-testid="auto-email-reply-edit-button"
                          sx={classes.tableEditButton}
                          onClick={() => handleOpenModal(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          id="auto-email-reply-delete-button"
                          data-testid="auto-email-reply-delete-button"
                          sx={classes.tableDeleteButton}
                          onClick={() => removeAutoReplies(index)}
                        >
                          {isDeleting === index ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            <DeleteIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <Box sx={classes.noAutoForwarders}>
          No auto replies configured. Click on the button above to create one.
        </Box>
      )}
      {!loading.autoReplies && !loading.applicationStatuses && (
        <NewAutoReply
          jobId={jobId}
          apiKey={apiKey}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          autoReplies={autoReplies}
          setAutoReplies={setAutoReplies}
          applicationStatuses={applicationStatuses || []}
          emailTemplates={templates}
          replyIndex={modalOpen ? replyIndex : -1}
          isLoadingTemplateList={loading.templatesList}
          hasCareAdvantageIntegration={hasCareAdvantageIntegration}
          careAdvantageEvents={careAdvantageEvents}
          setSnackbar={setSnackbar}
        />
      )}
    </Box>
  );
}
