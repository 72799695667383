import React, { useEffect, useState, useCallback, useRef } from 'react';
import { classes } from './styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ITabs, IJob } from '../../../../Job/types';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DebouncedFormTextField } from '../../../CustomUIElements/FormTextField';
import HtmlEditor from '../../../Utilities/HtmlEditor';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { sharedClasses } from '../../../CustomUIElements/sharedClasses';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { SingleDatePicker } from '../../../CustomUIElements/SingleDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import StyledSnackbar from '../../../CustomUIElements/StyledSnackbar';

const BASE_URL = window.location.origin;

const handleInitialContent = (content: string) => {
  if ((content || '').replace(/(<([^>]+)>)/gi, "") === '') {
    const contentArray = (content || '').split('</div>');
    if (contentArray.length > 1) {
      contentArray[0] = contentArray[0] + '<p></p>';
      return contentArray.join('</div>');
    }
  }
  return content;
};

const getDefaults = (options, defaults) => {
  if (options?.length > 0 && defaults?.length > 0) {
    const indexs = [];
    defaults.map((option) => {
      indexs.push(options[options.findIndex((value) => value?.id === option)]);
    });
    return indexs;
  } else return defaults;
};

export default function NewCouncilJobsAd({
  apiKey,
  job,
  jobAdId,
 }: { 
  apiKey: string;
  job: IJob;
  jobAdId: number | null;
}) {
  const [ad, setAd] = useState(null);
  const [adDetails, setAdDetails] = useState(handleInitialContent(ad?.ad_details))
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const headerRefs = useRef<HTMLDivElement[]>([]);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });

  const fieldRefs = {
    reference: useRef<HTMLInputElement>(null),
    council_jobs_employer_name: useRef<HTMLInputElement>(null),
    council_jobs_contact_email: useRef<HTMLInputElement>(null),
    council_jobs_region: useRef<HTMLInputElement>(null),
    council_jobs_state: useRef<HTMLInputElement>(null),
    council_jobs_ad_expiry_date: useRef<HTMLInputElement>(null),
    council_jobs_job_type: useRef<HTMLInputElement>(null),
    council_jobs_apply_value: useRef<HTMLInputElement>(null),
  };

  const TabPanel = useCallback((props: ITabs) => {
    const { children, tabValue, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        id={`ads-tabpanel-${index}`}
        aria-labelledby={`jobs-tab-${index}`}
        {...other}
      >
        {tabValue === index && <>{children}</>}
      </div>
    );
  }, []);

  const getAd = useCallback(async () => {
    setLoading(true);
    let url = jobAdId ? `/api/v4/council_jobs/jobs/${job.id}/job_ads/${jobAdId}` : `/api/v4/council_jobs/jobs/${job.id}/job_ads/new`;
    if(window.location.href.includes('clone')) {
      const url_str = window.location.href;
      const clone_id = url_str.substring(url_str.indexOf('job_ads') + 8, url_str.indexOf('clone') - 1);
      url = `/api/v4/council_jobs/jobs/${job.id}/job_ads/${clone_id}/clone`;
    } 
    const method = window.location.href.includes('clone') ? 'POST' : 'GET';
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setAd(response);
      setAdDetails(response.ad_details);
      setMessage(response.errors);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [apiKey, job]);

  const handleSave = async (ad) => {
    setIsSaving(true);
    const attributes = {
      send_to_council_jobs: ad.send_to_council_jobs ? '1' : '0',
      title: ad.title,
      ad_details: ad.ad_details,
      council_jobs_employer_name: ad.council_jobs_employer_name,
      council_jobs_contact_email: ad.council_jobs_contact_email,
      council_jobs_region: ad.council_jobs_region,
      council_jobs_state: ad.council_jobs_state,
      council_jobs_ad_expiry_date: moment(ad.council_jobs_ad_expiry_date).format('DD/MM/YYYY'),
      council_jobs_categories: ad.council_jobs_categories,
      council_jobs_job_type: ad.council_jobs_job_type,
      council_jobs_apply_type: ad.council_jobs_apply_type,
      council_jobs_apply_value: ad.council_jobs_apply_value,
      council_jobs_posting_options: ad.council_jobs_posting_options
    }
    const url = jobAdId ? `/api/v4/council_jobs/jobs/${job.id}/job_ads/${jobAdId}` : `/api/v4/council_jobs/jobs/${job.id}/job_ads`;
    const method = jobAdId ? 'PUT' : 'POST';
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        },
        body: JSON.stringify({
          job_ad: attributes
        }),
      }).then((response) => response.json());
      if(response.success) {
        window.location.href = `${BASE_URL}${response?.preview_url}`;
        setSnackbar({
          message: response.success,
          state: 'success'
        });
      } else {
        setSnackbar({
          message: response.error,
          state: 'error'
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    getAd();
  }, [getAd]);

  useEffect(() => {
    setIndicatorWidth(headerRefs.current[tabValue].clientWidth - 32);
  }, [tabValue]);

  return (
    <Box sx={classes.adPageContainer}>
      <Box sx={classes.adPageHeader}>
        {loading ? (
          <Skeleton animation="wave" width={600} height={56} />
        ) : (
          <Box sx={{ color: '#666666' }}>
            <h4 id="reference">{ad?.reference}</h4>
            <h1 id="title">{ad?.title}</h1>
          </Box>
        )}
      </Box>
      <Box sx={classes.tabsContainer}>
        <Tabs
          value={tabValue}
          aria-label="Council Jobs New Ad Tab"
          sx={{
            ...classes.tabs,
            '& .MuiTabs-indicator': { ...classes.tabIndicator, maxWidth: indicatorWidth + 'px' }
          }}
        >
          <Tab
            disabled
            ref={(el) => (el ? (headerRefs.current[0] = el) : null)}
            label={'Write job ad details'}
          />
          <TabPanel tabValue={tabValue} index={0}></TabPanel>
        </Tabs>
      </Box>
      {loading ? (
        <Skeleton animation="wave" sx={{ marginLeft: '1rem', marginBottom: '20px' }} width={200} height={56} />
      ) : (
        <Box sx={classes.backToJobAdButton}>
          <Stack
            flexDirection="row"
            alignItems="center"
            onClick={() => {
              window.location.href = `${BASE_URL}/admin/jobs/${job.id}/job_ads`;
            }}
          >
            <IconButton sx={classes.iconGrey}>
              <ArrowCircleLeftIcon />
            </IconButton>
            <Box>Back</Box>
          </Stack>
        </Box>
      )}
      {loading ? (
        <Skeleton animation="wave" sx={{ marginLeft: '1rem' }} width={200} height={56} />
      ) : (
        <Box sx={classes.tabContent}>
          <Box sx={classes.headerContent}>
            Select advertising service to export
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                sx={classes.checkbox}
                onChange={(event, value) => {
                  setAd((prev) => ({
                    ...prev,
                    send_to_council_jobs: value
                  }));
                }}
                value={ad?.send_to_council_jobs}
                defaultChecked={ad?.send_to_council_jobs}
                name={''}
              />
            }
            label={'Send to CouncilJobs'}
            sx={classes.label}
          />
          <Box sx={{...classes.headerContent, marginBottom: '20px' }}>
            Your full job ad
          </Box>
          <DebouncedFormTextField
            onDebouncedChange={(value: string) => {
              setAd((prev) => ({
                ...prev,
                title: value
              }));
            }}
            id="title"
            label="Job title"
            defaultValue={ad?.title}
            required={true}
            innerRef={fieldRefs.reference}
            styles={{ width: '500px' }}
          />
          <Box sx={classes.detailsTitle}>
            Ad details
          </Box>
          <HtmlEditor
            body={handleInitialContent(ad?.ad_details)}
            onChange={setAdDetails}
            onBlur={(value) => {
              setAd((prev) => ({
                ...prev,
                ad_details: value
              }))
            }}
          />
          {ad?.send_to_council_jobs &&
            <Box sx={classes.modalFormLine}>
              <Box sx={classes.headerContent}>
                Council Jobs
              </Box>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  setAd((prev) => ({
                    ...prev,
                    council_jobs_employer_name: value
                  }));
                }}
                id="employer_name"
                label="Employer name"
                defaultValue={ad?.council_jobs_employer_name}
                required={true}
                innerRef={fieldRefs.council_jobs_employer_name}
                styles={{ width: '500px' }}
              />
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  setAd((prev) => ({
                    ...prev,
                    council_jobs_contact_email: value
                  }));
                }}
                id="contact_email"
                label="Contact email"
                defaultValue={ad?.council_jobs_contact_email}
                required={false}
                innerRef={fieldRefs.council_jobs_contact_email}
                styles={{ width: '500px' }}
              />
              <Autocomplete
                disablePortal
                options={ad?.region}
                getOptionLabel={(option) => `${option?.label}`}
                id="region"
                value={ad?.region.filter((r) => r.id === ad?.council_jobs_region)[0]}
                onChange={(event, value) => {
                  const id = value.id;
                  setAd((prev) => ({
                    ...prev,
                    council_jobs_region: id
                  }));
                }}
                sx={{ width: '500px', ...sharedClasses.formAutocomplete, marginTop: '26px'}}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Region"
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={fieldRefs.council_jobs_region}
                  />
                )}
              />
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  setAd((prev) => ({
                    ...prev,
                    council_jobs_state: value
                  }));
                }}
                id="state"
                label="State"
                defaultValue={ad?.council_jobs_state}
                required={false}
                innerRef={fieldRefs.council_jobs_state}
                styles={{ width: '500px' }}
              />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormControl variant="standard">
                  <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
                    Ad expiry date
                  </InputLabel>
                  <SingleDatePicker
                    value={ad?.council_jobs_ad_expiry_date}
                    inputStyles={classes.datePicker}
                    onChange={(value) => {
                      setAd((prev) => ({
                        ...prev,
                        council_jobs_ad_expiry_date: value
                      }));
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
              <Autocomplete
                id="categories"
                multiple
                options={ad?.categories_options}
                getOptionLabel={(option) => `${option?.label}`}
                value={getDefaults(ad?.categories_options, ad?.council_jobs_categories)}
                sx={{
                  ...classes.formAutocomplete,
                  minWidth: '250px',
                  width: '500px',
                  marginTop: '24px'
                }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categories"
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(event, value) => {
                  const ids = value.map((v) => v.id)
                  setAd((prev) => ({
                    ...prev,
                    council_jobs_categories: ids
                  }));
                }}
              />
              <Autocomplete
                disablePortal
                options={ad?.job_type}
                getOptionLabel={(option) => `${option?.label}`}
                id="job_type"
                value={ad?.job_type.filter((t) => t.id.toString() === ad?.council_jobs_job_type)[0]}
                onChange={(event, value) => {
                  const id = value.id;
                  setAd((prev) => ({
                    ...prev,
                    council_jobs_job_type: id
                  }));
                }}
                sx={{ width: '500px', ...sharedClasses.formAutocomplete, marginTop: '26px'}}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job type"
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={fieldRefs.council_jobs_job_type}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                options={['Email', 'Website', 'Offline']}
                id="job_type"
                value={ad?.council_jobs_apply_type}
                onChange={(event, value) => {
                  setAd((prev) => ({
                    ...prev,
                    council_jobs_apply_type: value
                  }));
                }}
                sx={{ width: '500px', ...sharedClasses.formAutocomplete, marginTop: '26px'}}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Apply type"
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={fieldRefs.council_jobs_job_type}
                  />
                )}
              />
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  setAd((prev) => ({
                    ...prev,
                    council_jobs_apply_value: value
                  }));
                }}
                id="apply_value"
                label="Apply value"
                defaultValue={ad?.council_jobs_apply_value}
                required={false}
                innerRef={fieldRefs.council_jobs_apply_value}
                styles={{ width: '500px' }}
              />
              <Autocomplete
                id="posting_options"
                multiple
                options={['Premium', 'Hot Job']}
                value={ad?.council_jobs_posting_options}
                sx={{
                  ...classes.formAutocomplete,
                  minWidth: '250px',
                  width: '500px',
                  marginTop: '24px'
                }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job posting options"
                    required={false}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(event, value) => {
                  setAd((prev) => ({
                    ...prev,
                    council_jobs_posting_options: value
                  }));
                }}
              />
            </Box>
          }
          <Box sx={classes.modalFooter}>
            <Button
              id="close-new-field-button"
              sx={classes.modalCancelButton}
              onClick={() => {
                window.location.href = `${BASE_URL}/admin/jobs/${job.id}/job_ads`;
              }}
            >
              Cancel
            </Button>
            <Button
              id="save-council-jobs-ad-button"
              type="submit"
              sx={{ ...classes.modalCreateButton, width: '91px', height: '41px' }}
              onClick={() => handleSave(ad)}
            >
              {isSaving ? <CircularProgress size={20} color="inherit" /> : 'Save'}
            </Button>
          </Box>
        </Box>
      )}
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  )
}