import React, { useEffect, useState, useRef } from 'react';
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { IApprovalTemplate, IApprovalTemplateFields } from '../types';
import QuestionFieldGenerator, {
  IApplicantQuestion
} from '../../Components/Utilities/QuestionFieldGenerator';
import dompurify from 'dompurify';
import { MultilineFormTextField } from '../../Components/CustomUIElements/FormTextField';
import { styles } from './styles';
import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from '../../Components/Buttons/LoadingButton';

const BASE_URL = window.location.origin;

const createApprovalFormQuestionsObject = (questions: IApplicantQuestion[]) => {
  const newObject = {};
  questions.forEach((question: IApplicantQuestion) => {
    newObject[question.id] = {
      ...question,
      data: '',
      inputText: ''
    };
  });
  return newObject;
};

const createQuestionErrorsObject = (questions: IApplicantQuestion[]) => {
  const errors: Record<number, string> = {};
  questions.forEach((question: IApplicantQuestion) => {
    errors[question.id] = '';
  });
  return errors;
};

export default function ApprovalFormPage({
  approvalTemplateData,
  requisitionId,
  authenticity_token,
  hash,
  state
}: {
  approvalTemplateData?: IApprovalTemplate;
  requisitionId: number;
  authenticity_token: string;
  hash: string;
  state: string;
}) {
  const [emailTemplateQuestions, setEmailTemplateQuestions] = useState<IApplicantQuestion[]>([]);
  const [approvalFormQuestions, setApprovalFormQuestions] = useState(() =>
    createApprovalFormQuestionsObject([])
  );
  const [questionErrors, setQuestionErrors] = useState(() =>
    createQuestionErrorsObject(emailTemplateQuestions || [])
  );
  const [selected, setSelected] = useState('approve');
  const [comment, setComment] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [actioned, setActioned] = useState(false);

  const fieldRefs = {
    questions: useRef<Array<HTMLDivElement | null>>([])
  };

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newSelected: string) => {
    if (newSelected !== null) {
      setSelected(newSelected);
    }
  };

  const submitEmailTemplateRequisition = async () => {
    const coi_response = Object.values(approvalFormQuestions)
      .map((question) => question.inputText)
      .join('. ');

    // Approve or decline requisition
    try {
      const response = await fetch(`${BASE_URL}/requisition_approval`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authenticity_token: authenticity_token,
          id: requisitionId,
          hash: hash,
          approval: selected,
          coi_response: coi_response,
          comment: comment,
          commit: 'Submit'
        })
      });
      if (response.ok) {
        setActioned(true);
      } else {
        console.log('Error:', response.status, response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!approvalTemplateData) return;
    const questionsData = approvalTemplateData.fields.filter(
      (question) => question.enabled && question.for_email_template
    );
    setEmailTemplateQuestions(questionsData);

    if (Object.keys(approvalFormQuestions).length !== questionsData.length) return;
    Object.values(approvalFormQuestions).every(
      (question) => question.index === 0 || question.inputText.length > 0
    )
      ? setIsValid(true)
      : setIsValid(false);
  }, [approvalTemplateData, approvalFormQuestions]);

  return (
    <Box>
      {actioned ? (
        <Box>
          <h2>Request {selected === 'approve' ? 'Approved' : 'Declined'}</h2>
          <p>{`Thank you, this Request has been ${selected}d. It is safe to leave this page.`}</p>
        </Box>
      ) : (
        <>
          <Box sx={styles.noticeContainer}>
            <InfoIcon sx={styles.noticeIcon} />
            <Box sx={{ marginLeft: 1 }}>
              {state === 'pending'
                ? `Please note – your request has not yet been submitted! Please check the form that was emailed to you, select approve or decline, and add any comments before submitting your response.`
                : `This approval was already ${state} by you.`}
            </Box>
          </Box>
          {state === 'pending' && (
            <Box sx={{ padding: 4 }}>
              {emailTemplateQuestions?.map((question, index) => (
                <Box sx={{ paddingBottom: 3 }} key={question.id}>
                  <QuestionFieldGenerator
                    question={question}
                    questionRef={(el) => (fieldRefs.questions.current[index] = el)}
                    questionError={questionErrors[question.id]}
                    onChangeCallback={(id, value) => {
                      const label = question.field_choices.find(
                        (choice) => choice.id === Number(value)
                      )?.name;
                      setApprovalFormQuestions((prev) => ({
                        ...prev,
                        [id]: {
                          ...prev[id],
                          data: value,
                          index: question.field_choices.findIndex(
                            (choice) => choice.id === Number(value)
                          ),
                          inputText:
                            question.field_choices.findIndex(
                              (choice) => choice.id === Number(value)
                            ) === 0
                              ? label
                              : ''
                        }
                      }));
                      setQuestionErrors((prev) => ({
                        ...prev,
                        [id]: value || !question.required ? '' : 'This field is required'
                      }));
                    }}
                    answer={approvalFormQuestions[question.id]?.data}
                    inputText={approvalFormQuestions[question.id]?.inputText}
                    setApprovalFormQuestions={setApprovalFormQuestions}
                    approvalPage={true}
                  />
                </Box>
              ))}
              <Box sx={styles.toggleButtonsContainer}>
                <ToggleButtonGroup
                  value={selected}
                  exclusive
                  onChange={handleToggle}
                  aria-label="enabled or disabled questions"
                >
                  <ToggleButton
                    value="approve"
                    sx={{
                      ...styles.toggleButton,
                      '&.Mui-selected': {
                        backgroundColor: `#41BF56 !important`,
                        color: `#FFFFFF !important`
                      }
                    }}
                  >
                    APPROVE
                  </ToggleButton>
                  <ToggleButton
                    value="decline"
                    sx={{
                      ...styles.toggleButton,
                      '&.Mui-selected': {
                        backgroundColor: `#E2583e !important`,
                        color: `#FFFFFF !important`
                      }
                    }}
                  >
                    DECLINE
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box sx={{ marginTop: '60px', width: '100%' }}>
                <Box
                  sx={styles.label}
                  dangerouslySetInnerHTML={{
                    __html: dompurify.sanitize(
                      'Additionally, you can leave a comment (emojis are not supported):'
                    )
                  }}
                />
                <MultilineFormTextField
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required={false}
                  fullWidth={true}
                />
              </Box>
              <LoadingButton
                id="approve-or-decline-approval"
                type="submit"
                task={submitEmailTemplateRequisition}
                sx={{ ...styles.saveButton, marginTop: '20px' }}
                disabled={!isValid}
              >
                Submit
              </LoadingButton>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
