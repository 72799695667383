"use strict";
export const HtmlEditorFonts = [
  `@font-face {
    font-family: 'Nexa';
    src: url("/fonts/nexa/Nexa.ttf");
    font-weight: 400;
    font-weight: normal;
    font-style: normal;
  }`,
  `@font-face {
    font-family: 'Nexa';
    src: url("/fonts/nexa/Nexa Bold.ttf");
    font-weight: 700;
    font-style: bold;
  }`
];
