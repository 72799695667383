"use strict";
export const classes = {
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  popover: {
    marginTop: "15px"
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)"
  },
  switchLabels: {
    justifyContent: "space-between",
    width: "200px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17)",
      opacity: "1"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5)"
    }
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  switchActive: {
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC"
    }
  },
  filterPopoverContent: {
    width: "272px",
    maxHeight: "500px",
    overflowY: "auto",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  filterPopoverHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    backgroundColor: "white",
    position: "sticky",
    top: "0px",
    zIndex: 99,
    paddingTop: "26px",
    paddingBottom: "2px",
    boxShadow: "0px 6px 10px 7px #ffffff"
  },
  accordionRoot: {
    boxShadow: "none",
    margin: "14px 0px",
    "&::before": {
      display: "none"
    },
    "&:first-of-type": {
      marginTop: "0px !important"
    },
    "&.Mui-expanded": {
      marginTop: "14px 0px 0px 0px"
    }
  },
  accordionSummary: {
    minHeight: "0px !important",
    display: "inline-flex",
    padding: "0px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0px !important",
      paddingRight: "25px"
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "#084D6D",
      transform: "rotate(-90deg)",
      "&.Mui-expanded": {
        transform: "rotate(0deg)"
      },
      "& svg": {
        fontSize: "20px"
      }
    }
  },
  summaryTypography: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D"
  },
  accordionDetails: {
    padding: "6px 0px"
  },
  checkboxLabels: {
    justifyContent: "space-between",
    width: "174px",
    height: "25px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#939393"
    },
    "& .MuiCheckbox-colorPrimary": {
      color: "#DDDDDD",
      "&.Mui-checked": {
        color: "#5BC4C0"
      }
    },
    "& svg": {
      fontSize: "17px"
    }
  },
  uncheckedLabel: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontWeight: "600"
    }
  },
  clearButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#939393",
    backgroundColor: "rgba(147, 147, 147, 0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "rgba(147, 147, 147, 0.24)",
      boxShadow: "none"
    },
    "& .MuiButton-endIcon svg": {
      fontSize: "10px"
    }
  },
  applyButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 23px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  multiSelectContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    marginBottom: "10px"
  },
  checkboxContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  dateDisplay: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#939393",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2px"
  },
  dateDisplayBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    borderRadius: "4px",
    border: "2px solid #DDDDDD",
    width: "88px",
    height: "36px"
  },
  dateInput: {
    "& .MuiOutlinedInput-root": {
      width: "88px",
      height: "31px",
      padding: "0px",
      transform: "translateY(2px)",
      "&:hover": {
        "& fieldset": {
          borderColor: "#BBBBBB"
        }
      }
    },
    "& fieldset": {
      border: "2px solid #DDDDDD",
      borderColor: "#DDDDDD",
      borderRadius: "4px",
      "& legend": {
        display: "none"
      }
    },
    "& input": {
      fontSize: "12px",
      color: "#939393",
      padding: "0px",
      paddingLeft: "12px",
      paddingBottom: "4px"
    },
    "& button": {
      display: "none"
    }
  },
  invalidDate: {
    "& .MuiOutlinedInput-root": {
      "&:hover": {
        "& fieldset": {
          borderColor: "#E60B0B !important"
        }
      },
      "& fieldset": {
        borderColor: "#E60B0B"
      }
    }
  },
  tableSettingsHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: "8px",
    paddingLeft: "4px"
  },
  sortableField: {
    display: "flex",
    alignItems: "center",
    width: "204px"
  },
  sortableSwitchLabels: {
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "4px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  }
};
