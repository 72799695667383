"use strict";
import { SortableColumnId } from "../types";
export const initialModalsOpen = {
  resend: false,
  email: false,
  sms: false,
  changeStatus: false,
  addToEvent: false,
  inviteToTimeslot: false,
  externalEmail: false,
  exportToPdf: false,
  move: false,
  exportToOnboard: false,
  exportToFoundu: false,
  ceridianDayforce: false,
  aiInsights: false,
  delete: false,
  newApplication: false
};
export const defaultFilters = {
  status: [],
  country: [],
  suburb: [],
  rating: [0, 100],
  excludeApplicantsWithoutRating: false
};
export const applicantDetailsDefault = {
  firstName: { error: "", required: true, value: "" },
  lastName: { error: "", required: true, value: "" },
  email: { error: "", required: true, value: "" },
  phone: { error: "", required: true, value: "" },
  phone2: { error: "", required: false, value: "" },
  addressFirstLine: { error: "", required: true, value: "" },
  addressSecondLine: { error: "", required: false, value: "" },
  suburb: { error: "", required: true, value: "" },
  postcode: { error: "", required: true, value: "" },
  country: { error: "", required: true, value: null },
  region: { error: "", required: true, value: null },
  employeeId: { error: "", required: false, value: "" },
  gender: { error: "", required: false, value: { id: "", label: "" } }
};
export const sortableColumnsDefaults = [
  {
    id: SortableColumnId.ID,
    label: "ID",
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: SortableColumnId.CANDIDATE,
    label: "Candidate",
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: SortableColumnId.GENDER,
    label: "Gender",
    sortable: true,
    canToggle: true,
    enabled: false
  },
  {
    id: SortableColumnId.SUBURB,
    label: "Suburb",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: SortableColumnId.STATE,
    label: "State",
    sortable: true,
    canToggle: true,
    enabled: false
  },
  {
    id: SortableColumnId.COUNTRY,
    label: "Country",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: SortableColumnId.DATE,
    label: "Date",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: SortableColumnId.RATING,
    label: "Rating",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: SortableColumnId.STATUS,
    label: "Status",
    sortable: true,
    canToggle: true,
    enabled: true
  },
  {
    id: SortableColumnId.NOTES,
    label: "Notes",
    sortable: false,
    canToggle: true,
    enabled: false
  },
  {
    id: SortableColumnId.PERMANENT_RECORD,
    label: "Permanent Record",
    sortable: false,
    canToggle: true,
    enabled: false
  },
  {
    id: SortableColumnId.NOTE_PAD,
    label: "Notepad",
    sortable: false,
    canToggle: true,
    enabled: false
  },
  {
    id: SortableColumnId.PHONE,
    label: "Phone",
    sortable: false,
    canToggle: true,
    enabled: false
  },
  {
    id: SortableColumnId.CANDIDATE_TAGS,
    label: "Candidate Tags",
    sortable: false,
    canToggle: true,
    enabled: true
  }
];
