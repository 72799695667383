import React, { Dispatch, RefObject, useLayoutEffect, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { classes } from './styles';
import { handleScrollLeft, handleScrollRight, handleScrollableEnter } from './tableHelper';
import { Box } from '@mui/material';
import { GenericTableData, TSortableColumn, TTableState, TableScrollAction } from './types';

const ARROW_WIDTH = 40;

interface ITableArrowProps<T> {
  tableRef: RefObject<HTMLDivElement>;
  titleCellRef: RefObject<HTMLDivElement>;
  actionCellRef: RefObject<HTMLDivElement>;
  parentRef: RefObject<HTMLDivElement>;
  tableState: TTableState<T, TSortableColumn[]>;
  dispatch: Dispatch<TableScrollAction>;
}

const TableArrows = <TableArrowProps extends GenericTableData>({
  tableRef,
  titleCellRef,
  actionCellRef,
  parentRef,
  tableState,
  dispatch
}: ITableArrowProps<TableArrowProps>) => {
  const [leftPosition, setLeftPosition] = useState({
    leftArrow: 0,
    rightArrow: 0
  });

  useLayoutEffect(() => {
    setLeftPosition({
      leftArrow:
        (titleCellRef?.current?.getBoundingClientRect()?.right || 0) -
        (parentRef?.current?.getBoundingClientRect()?.left || 0),
      rightArrow:
        (actionCellRef?.current?.getBoundingClientRect()?.left || 0) -
        (parentRef?.current?.getBoundingClientRect()?.left || 0) -
        ARROW_WIDTH
    });
  }, [actionCellRef, tableState.tableHovered, parentRef, titleCellRef]);

  return (
    <>
      <Box sx={classes.arrowsContainer}>
        <Box
          onClick={() => handleScrollLeft(tableRef)}
          onMouseEnter={() => handleScrollableEnter(tableRef, dispatch)}
          sx={{
            ...classes.leftArrow,
            ...(tableState.tableHovered && tableState.canScroll[0] && classes.showArrows),
            left: `${leftPosition.leftArrow}px`,
            top: 0
          }}
          id="left-arrow"
        >
          <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
        </Box>
      </Box>
      <Box sx={classes.arrowsContainer}>
        <Box
          onMouseEnter={() => handleScrollableEnter(tableRef, dispatch)}
          onClick={() => handleScrollRight(tableRef)}
          sx={{
            ...classes.rightArrow,
            ...(tableState.tableHovered && tableState.canScroll[1] && classes.showArrows),
            left: `${leftPosition.rightArrow}px`,
            top: 0
          }}
          id="right-arrow"
        >
          <KeyboardArrowRightIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
        </Box>
      </Box>
    </>
  );
};

export default TableArrows;
