import React, { Dispatch, SetStateAction, useRef } from 'react';
import Box from '@mui/material/Box';
import { IJob } from '../../Job/types';
import { classes } from '../styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { MultilineFormTextField } from '../../Components/CustomUIElements/FormTextField';

export default function Template({
  apiKey,
  job,
  templateTabJobData,
  setTemplateTabJobData,
  setIsEdited
}: {
  apiKey: string;
  job: IJob;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
}) {

  const templateRef = useRef<HTMLInputElement>(null);

  return (
    <Box sx={classes.tabContent}>
      <Box id="template-title" sx={classes.headerContent}>
        Template
      </Box>
      <Box sx={classes.bodyContainer}>
        <FormControlLabel
          control={
            <Checkbox
              sx={classes.checkbox}
              onChange={(event, value) => {
                setTemplateTabJobData((prev) => ({
                  ...prev,
                  template_enabled: value
                }))
                setIsEdited(true);
              }}
              value={templateTabJobData?.template_enabled}
              defaultChecked={templateTabJobData?.template_enabled}
              name={''}
            />
          }
          label={'Use separate template to current entity/EO skin'}
          sx={classes.label}
        />
        {templateTabJobData.template_enabled && (
          <>
            <Box sx={classes.text}>Add code below to use a different template</Box>
            <Box sx={classes.modalFormLine}>
              <MultilineFormTextField
                value={templateTabJobData?.template?.value}
                onChange={(e) => {
                  setTemplateTabJobData((prev) => ({
                    ...prev,
                    template: { ...prev.template, value: e.target.value }
                  }));
                  setIsEdited(true);
                }}
                rows={20}
                innerRef={templateRef}
                required={false}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
