import React, { Dispatch, useState, useEffect, useCallback, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import ApprovalManagersTable from './ApprovalManagersTable';
import Api from '../API';
import { IApprovalManagers } from '../types';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import Search from './Search';
import { IUserPermissions } from '../../Components/sharedTypes';

export default function ApprovalManagers({
  apiKey,
  userPermissions,
  isApprovalManagerDialogOpen,
  setIsApprovalManagerDialogOpen
}: {
  apiKey: string;
  userPermissions: IUserPermissions | undefined;
  isApprovalManagerDialogOpen: boolean;
  setIsApprovalManagerDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [approvalManagers, setApprovalManagers] = useState<IApprovalManagers[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [selected, setSelected] = useState<number[]>([]);
  const [globalSelected, setGlobalSelected] = useState<IApprovalManagers[]>([]);
  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
  const [selectAllIsIndeterminate, setSelectAllIsIndeterminate] = useState(false);
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalApprovalManagers, setTotalApprovalManagers] = useState(0);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [density, setDensity] = useState<string>('Default');

  const getFirstApprovalManagersAndPreferences = useCallback(
    async (apiKey: string) => {
      setIsLoading(true);
      try {
        const preferenceData = await fetch(`/api/v4/ui_preferences?source=approval_managers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-authenticate': apiKey
          },
          cache: 'no-store'
        }).then((res) => res.json());
        const densityData = await fetch(`/api/v4/ui_preferences?source=universal`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-authenticate': apiKey
          },
          cache: 'no-store'
        }).then((res) => res.json());
        const preference = preferenceData?.ui_preferences[0];
        const densityPreference = densityData?.ui_preferences[0];
        preference?.sorting?.sortBy && setSortBy(preference.sorting.sortBy);
        preference?.sorting?.sortOrder && setSortOrder(preference.sorting.sortOrder.toLowerCase());
        const sessionPage = sessionStorage.getItem(`approvalManagersTablePage`);
        sessionPage && setCurrentPage(Number(sessionPage));
        preference?.row_count && setRowsPerPage(preference.row_count);
        densityPreference?.columns?.density && setDensity(densityPreference?.columns?.density);

        const response = await Api.getApprovalManagers({
          page: sessionPage ? Number(sessionPage) : 1,
          limit: preference?.row_count || rowsPerPage,
          sort: preference?.sorting?.sortBy || 'name',
          sort_order: (preference?.sorting?.sortOrder?.toLowerCase() || 'desc').toUpperCase(),
          'q[search]': search
        });
        setApprovalManagers(response.res.requisition_managers);
        setTotalPages(parseInt(response.resHead['x-total-pages']));
        setTotalApprovalManagers(parseInt(response.resHead['x-total-count']));
      } catch (error) {
        setSnackbar({
          message: `There was an error getting approval managers, ${error?.errors}`,
          state: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    },
    [apiKey]
  );

  const setUserPreferences = async (
    limit: number | null,
    sort: { sortBy: string; sortOrder: string } | null
  ) => {
    try {
      await fetch(`/api/v4/ui_preferences`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        body: JSON.stringify({
          ui_preference: {
            row_count: limit || rowsPerPage,
            sorting: sort,
            source: `approval_managers`
          }
        })
      });
    } catch (error) {
      setSnackbar({
        message: 'There was an error saving your preferences',
        state: 'error'
      });
    }
  };

  const getApprovalManagers = async (
    page: number | null,
    limit: number | null,
    searchQuery: string | null,
    sort: { sortBy: string; sortOrder: string } | null
  ) => {
    setIsLoading(true);
    try {
      const response = await Api.getApprovalManagers({
        page: page || currentPage,
        limit: limit || rowsPerPage,
        sort: sort?.sortBy || sortBy,
        sort_order: (sort?.sortOrder || sortOrder).toUpperCase(),
        'q[search]': searchQuery !== null ? searchQuery : search
      });
      setApprovalManagers(response.res.requisition_managers);
      setTotalPages(parseInt(response.resHead['x-total-pages']));
      setTotalApprovalManagers(parseInt(response.resHead['x-total-count']));
    } catch (error) {
      setSnackbar({
        message: `There was an error getting approval managers, ${error}`,
        state: 'error'
      });
    } finally {
      if (sort) {
        setUserPreferences(null, sort);
      } else if (limit) {
        setUserPreferences(limit, null);
        setCurrentPage(1);
      }
      setIsLoading(false);
    }
  };

  const getAllApprovalManagers = async () => {
    setIsLoading(true);
    try {
      const response = await Api.getApprovalManagers({
        override_limit: true,
        limit: totalApprovalManagers,
        sort: sortBy,
        sort_order: sortOrder.toUpperCase(),
        'q[search]': search
      });
      return response.res.requisition_managers;
    } catch (error) {
      setSnackbar({
        message: `There was an error getting all approval managers, ${error.errors}`,
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFirstApprovalManagersAndPreferences(apiKey);
  }, [apiKey, getFirstApprovalManagersAndPreferences]);

  return (
    <Box>
      <Box sx={{ marginBottom: 3, paddingTop: '6px' }}>
        <Search setSearch={setSearch} getApprovalManagers={getApprovalManagers} />
      </Box>
      <ApprovalManagersTable
        apiKey={apiKey}
        approvalManagers={approvalManagers}
        density={density}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalApprovalManagers={totalApprovalManagers}
        selected={selected}
        setSelected={setSelected}
        globalSelected={globalSelected}
        setGlobalSelected={setGlobalSelected}
        selectAllIsChecked={selectAllIsChecked}
        setSelectAllIsChecked={setSelectAllIsChecked}
        selectAllIsIndeterminate={selectAllIsIndeterminate}
        setSelectAllIsIndeterminate={setSelectAllIsIndeterminate}
        getApprovalManagers={getApprovalManagers}
        userPermissions={userPermissions}
        setSnackbar={setSnackbar}
        isApprovalManagerDialogOpen={isApprovalManagerDialogOpen}
        setIsApprovalManagerDialogOpen={setIsApprovalManagerDialogOpen}
        getAllApprovalManagers={getAllApprovalManagers}
      />
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
