import React from 'react';
import useStyles from './useStyles';
import { withScoutTheme } from '../../../../../ThemeContext/ThemeContext';
import { ICalendlyEvent, ICalendlyScoutProps, ICandidate, IJobPosition, ITemplate } from '../../../../types';
import OverviewItem from './OverviewItem';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

interface IProps {
  setStep: (step: number) => void,
  calendlyAccount: ICalendlyScoutProps,
  selectedEvent: ICalendlyEvent,
  selectedCandidates: ICandidate[],
  selectedTemplate: ITemplate,
  selectedJobPosition: IJobPosition,
}

function Step2({ setStep, calendlyAccount, selectedEvent, selectedCandidates, selectedTemplate, selectedJobPosition }: IProps) {
 
  const classes = useStyles();

  return (
    <>
      <h4 id="step-4-header">Overview</h4>
      <div className={classes.recContainer}>
        <div className={classes.recImage}>{ calendlyAccount.user_name.split(' ').map(name => name[0]) }</div>
        <div className={classes.recDetails}>
          <h4>{ calendlyAccount.user_name }</h4>
          <a target="_blank" rel="noreferrer" href={calendlyAccount.scheduling_url}>{ calendlyAccount.scheduling_url }</a>
        </div>
      </div>
      <div className={classes.overviewContainer}>
        { selectedCandidates.length &&
          <OverviewItem
            icon={<PeopleAltRoundedIcon />}
            title={`${selectedCandidates.length} Candidate${selectedCandidates.length ? 's' : ''}`}
            description={selectedJobPosition.title}
            step={1}
            setStep={setStep}
          />
        }
        { selectedEvent &&
          <OverviewItem
            icon={<TodayRoundedIcon />}
            title={selectedEvent.name}
            description={`${selectedEvent.duration} mins, ${selectedEvent.kind === 'solo' ? 'One-on-One' : 'Group'}`}
            step={2}
            setStep={setStep}
          />
        }
        { selectedTemplate &&
          <OverviewItem
            icon={<EmailRoundedIcon />}
            title="Template"
            description={selectedTemplate.name}
            step={3}
            setStep={setStep}
          />
        }
      </div>
    </>
  )
}

export default withScoutTheme(Step2)
