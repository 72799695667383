import * as React from 'react';
import { Box } from '@mui/material';
import { classes } from './styles';
import { SearchState, SearchAction } from './reducer';
import DateRangeField from '../../NewUI/Components/CustomUIElements/DateRangePickerWithDynamicTitle/DateRangeField';
import DateRangePicker from '../../NewUI/Components/CustomUIElements/DateRangeInput';
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';
import { dateRangeOptions } from '../../reports/DataVizSection/GraphDefaultOptions';
import AutocompleteInput from '../../NewUI/Components/CustomUIElements/AutocompleteInput';
import { DebouncedFormTextField } from '../../NewUI/Components/CustomUIElements/FormTextField';
import { dateRangeOptionsWithNoCustom } from '../helper';
import moment from 'moment';

interface Props {
  isLoading: boolean;
  scheduleState: SearchState;
  scheduleDispatch: React.Dispatch<SearchAction>;
}

export default function Step1(myProps: Props) {
  const { scheduleState, scheduleDispatch, isLoading } = myProps;
  return (
    <Box sx={{ position: 'relative', marginBottom: '4px' }}>
      <Box sx={classes.sectionHeader}>Report timeframe</Box>
      <DateRangeField
        dateRangeOptions={dateRangeOptions}
        reportState={scheduleState.timeFrameDates}
        inputValue={scheduleState.timeFrameDates.dateRange}
        isLoading={isLoading}
        reportDispatch={scheduleDispatch}
        fieldName="TIME_FRAME_DATES"
        payloadName="dateRange"
        inputHeader="Select timeframe"
      />
      <Box sx={{ ...classes.sectionHeader, marginTop: '2.3rem' }}>Scheduling</Box>
      <Box sx={{ display: 'flex' }}>
        <DateRangePicker
          dispatch={scheduleDispatch}
          dateState={scheduleState.scheduleDate}
          payloadName="SET_DATE"
          fieldName="dateRange"
          dateRangeOptions={dateRangeOptions}
        />
        <Box sx={{ ...sharedClasses.timePicker, marginLeft: '1.5rem' }}>
          <input
            type="time"
            className="time-picker"
            style={{ height: '37px', marginBottom: '4px' }}
            onChange={(e) =>
              scheduleDispatch({
                type: 'SET_DATE',
                payload: {
                  startDate: moment(scheduleState.scheduleDate.startDate)
                    .set({
                      hour: e.target.value.split(':')[0],
                      minute: e.target.value.split(':')[1]
                    })
                    .toDate(),
                  dateRange: scheduleState.scheduleDate.dateRange
                }
              })
            }
            value={
              scheduleState.scheduleDate.dateRange
                ? moment(new Date(scheduleState.scheduleDate.startDate)).format('HH:mm')
                : ''
            }
          />
        </Box>
      </Box>
      <AutocompleteInput
        id="frequency"
        options={dateRangeOptionsWithNoCustom}
        multiple={false}
        optionLabel={(option: { label: string; value: number }) => option.label}
        label={'Frequency'}
        isLoading={false}
        loadingText={['Loading date options', 'No date options']}
        value={scheduleState.frequency}
        required={false}
        onChange={(event, data) =>
          scheduleDispatch({
            type: 'SET_FREQUENCY',
            payload: data
          })
        }
        dateField={true}
      />
      <Box sx={classes.sendToInput}>
        <DebouncedFormTextField
          onDebouncedChange={(value: string) => {
            scheduleDispatch({
              type: 'SET_SEND_TO',
              payload: value
            });
          }}
          label="Send to"
          defaultValue={scheduleState.sendTo}
          styles={{ width: '300px' }}
          id="send-to"
        />
        <span>To copy and paste bulk emails, email addresses mist be separated with a comma</span>
      </Box>
    </Box>
  );
}
