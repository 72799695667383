import React from 'react';
import { withScoutTheme } from '../../../../ThemeContext/ThemeContext';
import { Button } from '@material-ui/core';
import useStyles from './useStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface IProps {
  closeModal: () => void,
}

function CompleteModal({ closeModal }: IProps) {

  const classes = useStyles();

  return (
    <div className={classes.root} id="complete-modal">
      <CheckCircleIcon color="secondary" />
      <h4>Complete</h4>
      <div className={classes.text}>Your Calendly invite has been sent</div>
      <Button
        id="complete-modal-button"
        disableElevation
        color="secondary"
        variant="contained"
        onClick={closeModal}
      >
        Back
      </Button>
    </div>
  )
}

export default withScoutTheme(CompleteModal);
