import { useReducer, Dispatch } from 'react';
import {
  ITimeToHireFields,
  IApplicationSourcesFields,
  IStatusActivitiesFields,
  SearchState
} from './types';
import { IEntity } from '../SystemReports/types';
import { dateRangeOptions } from './GraphDefaultOptions';
import moment from 'moment';


export type SearchAction =
  | {
      type: 'setChartData';
      payload: { loadingOptions: boolean; entitiesOptions: IEntity[] };
    }
  | {
      type: 'timeToHireFields';
      payload: ITimeToHireFields;
    }
  | {
      type: 'applicationSourcesFields';
      payload: IApplicationSourcesFields;
    }
  | {
      type: 'statusActivitiesFields';
      payload: IStatusActivitiesFields;
    };

const getDefaultSearchState = (): SearchState => ({
  loadingOptions: false,
  statusActivitiesWithActor: null,
  statusActivitiesWithoutActor: null,
  timeToHire: null,
  applicationSources: null,
  timeToHireFields: {
    timeToHire: dateRangeOptions[1],
    user: [],
    entity: [],
    jobTitle: [],
    startDate: moment().subtract(30, 'days').toDate(),
    endDate: moment().toDate()
  },
  applicationSourcesFields: {
    timeToHire: dateRangeOptions[1],
    status: [],
    entity: [],
    sourceName: [],
    startDate: moment().subtract(30, 'days').toDate(),
    endDate: moment().toDate()
  },
  statusActivitiesFields: {
    timeToHire: dateRangeOptions[1],
    status: [],
    entity: [],
    user: [],
    startDate: moment().subtract(30, 'days').toDate(),
    endDate: moment().toDate(),
    loadingOptions: false
  },
  entitiesOptions: [],
  jobTitleOptions: [],
  usersOptions: [],
  statusOptions: [],
  sourceNameOptions: [],
  statusOptionsActivityReport: []
});

function searchReducer(state: SearchState, action: SearchAction): SearchState {
  switch (action.type) {
    case 'setChartData': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'timeToHireFields': {
      return {
        ...state,
        timeToHireFields: {
          ...state.timeToHireFields,
          ...action.payload
        }
      };
    }
    case 'applicationSourcesFields': {
      return {
        ...state,
        applicationSourcesFields: {
          ...state.applicationSourcesFields,
          ...action.payload
        }
      };
    }
    case 'statusActivitiesFields': {
      return {
        ...state,
        statusActivitiesFields: {
          ...state.statusActivitiesFields,
          ...action.payload
        }
      };
    }
    default:
      throw new Error();
  }
}

export default function useReport(): [SearchState, Dispatch<SearchAction>] {
  return useReducer(searchReducer, getDefaultSearchState());
}
