import { ApplicationAction, IApplication } from '../types';
import React, { Dispatch, useState } from 'react';
import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { RenderFormResponseBlocks } from '../Modals/Components/FormResponseInfo';
import { styles } from '../styles';
import AnswersView from '../../ApprovalForms/ApprovalForm/AnswersView';
import Rating from '../OverviewTab/Rating';
import { useFormResponse } from '../helper';

export default function FormResponseDetails({
  FormResponseId,
  application,
  dispatch,
  created_at,
  submitted_by,
  rating,
  expandedRows,
  linkToUser
}: {
  FormResponseId: number;
  application: IApplication;
  dispatch: Dispatch<ApplicationAction>;
  created_at: string;
  submitted_by: string;
  rating: string;
  expandedRows: number[];
  linkToUser: string;
}) {
  const { data: formResponse, isLoading: loadingFormResponse } = useFormResponse({
    FormResponseId,
    dispatch,
    application
  });
  const [ratings, setRatings] = useState<Record<number, number>>({});
  return (
    <Stack sx={{ rowGap: 2, padding: 1 }}>
      <Box margin={1}>
        <RenderFormResponseBlocks
          ApplicationId={application.id}
          name={application.candidate.firstname + ' ' + application.candidate.lastname}
          created_at={created_at}
          submitted_by={submitted_by}
          rating={rating}
          isLoading={loadingFormResponse}
          expandedRows={expandedRows}
          linkToUser={linkToUser}
        />
      </Box>
      <Stack sx={{ rowGap: 2, padding: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Stack sx={{ color: '#666666', fontWeight: 'bold', fontSize: '16px' }}>Responses</Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack sx={{ color: '#666666', fontWeight: 'bold', fontSize: '16px' }}>Rating</Stack>
          </Grid>
        </Grid>
        {loadingFormResponse &&
          Array.from({ length: 3 }).map((_, index) => (
            <Grid key={index} container spacing={2}>
              <Grid item xs={9}>
                <Skeleton animation="wave" height={60} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton animation="wave" height={60} />
              </Grid>
            </Grid>
          ))}
        {formResponse &&
          formResponse.map((questionsAndAnswers) => {
            return (
              <Grid key={questionsAndAnswers.id} container spacing={2}>
                <Grid item xs={9}>
                  <Stack sx={{ rowGap: 1 }}>
                    <Stack sx={styles.smartFormsAnswersPreviewContainer}>
                      <AnswersView answer={questionsAndAnswers} />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={3} alignContent={'center'}>
                  {questionsAndAnswers.rateable && (
                    <Rating
                      value={questionsAndAnswers.rating}
                      setValue={(rate) =>
                        setRatings({ ...ratings, [questionsAndAnswers.id]: rate })
                      }
                      disabled={true}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
      </Stack>
    </Stack>
  );
}
