import React, { useState, useRef } from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';
import { classes } from '../../../../reports/DataVizSection/styles';
import { SearchState, SearchAction } from '../../../../reports/DataVizSection/useReport';
import DateStartToEndRange from './DateStartToEndRangeField';
import moment from 'moment';
import DateRangeIcon from '@mui/icons-material/DateRange';

interface Props {
  dateRangeOptions: ({ value: number; label: string } | { value: string; label: string })[];
  isLoading: boolean;
  reportState: SearchState;
  reportDispatch: React.Dispatch<SearchAction>;
  fieldName: string;
  payloadName: string;
  inputHeader: string;
  inputValue: { value: number; label: string } | { value: string; label: string };
}

export default function DateRangeField(myProps: Props) {
  const {
    dateRangeOptions,
    reportState,
    isLoading,
    reportDispatch,
    fieldName,
    payloadName,
    inputHeader,
    inputValue
  } = myProps;

  const inputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box sx={classes.dateInputWrapper} ref={inputRef}>
      <DateRangeIcon
        sx={{
          ...classes.calendarIcon,
          cursor: inputValue?.value === 'custom' ? 'pointer' : 'auto'
        }}
        onClick={() => {
          inputValue?.value === 'custom' && setAnchorEl(inputRef.current);
        }}
      />
      <Autocomplete
        id="dateRange"
        options={dateRangeOptions}
        disableClearable={true}
        getOptionLabel={(option) => {
          const dateWithoutDefinedEndDate = `${moment()
            .subtract(option.value, 'days')
            .format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')}`;
          if (option.value !== 'custom') return dateWithoutDefinedEndDate;
          else {
            return reportState?.startDate && reportState?.endDate
              ? `${moment(reportState.startDate).format('DD MMM YYYY')} - ${moment(
                  reportState.endDate
                ).format('DD MMM YYYY')}`
              : dateWithoutDefinedEndDate;
          }
        }}
        value={inputValue}
        loading={dateRangeOptions?.length < 1}
        loadingText={isLoading ? 'Loading date range options...' : 'No date range options'}
        sx={classes.formAutocompleteDate}
        ListboxProps={{ style: { ...classes.autoCompleteListStyles } }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={inputHeader}
            InputLabelProps={{ shrink: true }}
            onKeyPress={(e) => e.preventDefault()}
            onKeyDown={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.key === 'Backspace' || event.key === 'Delete') event.preventDefault();
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.label}
          </Box>
        )}
        onClose={() => setAnchorEl(inputRef.current)}
        onChange={(event, data) => {
          reportDispatch({
            type: fieldName,
            payload: {
              [payloadName]: data,
              startDate: moment()
                .subtract(data?.value === 'custom' ? 365 : data?.value, 'days')
                .toDate(),
              endDate: moment().toDate()
            }
          });
          setAnchorEl(inputRef.current);
        }}
      />
      {inputValue?.value === 'custom' && (
        <DateStartToEndRange
          isLoading={isLoading}
          reportState={reportState}
          reportDispatch={reportDispatch}
          fieldName={fieldName}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
    </Box>
  );
}
