"use strict";
import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  autocompleteField: {
    flexBasis: "60%",
    marginRight: 20
  },
  autoCompleteInput: {
    paddingLeft: 15
  },
  endAdornment: {
    marginRight: 10
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row"
  },
  inputBase: {
    flexBasis: "25%"
  },
  dialogPaper: {
    transform: "translateY(59px) !important"
  },
  chipContainer: {
    paddingTop: 14
  },
  chipItem: {
    margin: "4px 8px 4px 0",
    color: "#666",
    "& span": {
      color: "#666",
      padding: "0 16px",
      fontSize: 12
    }
  },
  table: {
    marginTop: 20,
    width: "100%",
    height: "100%",
    overflow: "scroll",
    display: "flex",
    flexDirection: "column"
  },
  row: {
    margin: 0,
    display: "flex",
    "& div": {
      display: "flex",
      alignItems: "center"
    },
    "& button": {
      display: "block",
      marginLeft: "auto",
      opacity: 0,
      marginRight: 12,
      transition: "opacity 0.24s ease-in-out",
      padding: 7,
      "&:focus": {
        outline: 0
      }
    },
    "&:hover button": {
      opacity: 1
    }
  },
  noApplicants: {
    color: "#999",
    display: "block",
    marginTop: 7
  },
  tableHeader: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 10,
    paddingTop: 15,
    color: theme.palette.primary.main
  },
  tableLg: {
    width: "35%",
    minWidth: "35%",
    maxWidth: "35%",
    userSelect: "none"
  },
  tableMd: {
    width: "20%",
    minWidth: "20%",
    maxWidth: "20%",
    userSelect: "none"
  },
  shadow: {
    boxShadow: "rgb(0 0 0 / 55%) -4px -33px 15px -45px inset"
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "8px 0",
    "& svg": {
      color: theme.palette.primary.main
    }
  },
  noData: {
    paddingInline: "10px",
    textAlign: "center"
  }
}));
