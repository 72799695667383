import React, { Component } from 'react';
import classnames from 'classnames';
import videojs from 'video.js';
import axios from 'axios';
import { logActivity, getVideo, deleteVideo } from './video_interview_helper.jsx';
import {
  getBookmarks,
  addBookmark,
  deleteBookmark,
  updateBookmark
} from './video_bookmarks_helper.jsx';

window.addEventListener('error', (e) => {
  if (e.message === 'ResizeObserver loop limit exceeded') {
    e.stopImmediatePropagation();
    e.preventDefault();
    console.log('ResizeObserver loop limit exceeded, error suppressed');
  }
});

class VideoInterview extends Component {
  state = {
    videoCurrentTime: 0,
    videoJS: {},
    bookmarkTxt: '',
    timeMins: 0,
    timeSecs: 0,
    time: 0,
    edit: false,
    editTxt: '',
    editNum: 0,
    editSecs: 0,
    editMins: 0,
    addId: 0,
    removeIndex: 0,
    videoId: null,
    bookMarks: [{}],
    deletedVideo: false
  };

  componentDidMount() {
    getVideo(this.props.url, this.props.applicationId).then((data) => {
      if (data) {
        this.createVideo(data);

        if (this.props.savedBookmarks) {
          this.addMultipleBookmarks(data.video.id, this.props.savedBookmarks);
        } else {
          getBookmarks(this.props.url, data.video.id).then((bookmarks) => {
            this.setState({ bookMarks: bookmarks });
          });
        }

        this.player.on('timeupdate', () => {
          const timeMins = Math.floor(this.player.currentTime() / 60);
          this.setState({
            timeSecs: Math.floor(this.player.currentTime() - timeMins * 60),
            timeMins: timeMins
          });
        });

        this.player.on('loadedmetadata', () => {
          this.player.addTextTrack('Question List', 'en');
        });

        this.handleUpdateTime = this.handleUpdateTime.bind(this);
      }
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  calcTimeFormat = (time) => {
    let mins = ~~((time % 3600) / 60);
    let secs = time % 60;
    let value = '';
    value += '' + mins + ':' + (secs < 10 ? '0' : '');
    value += '' + secs;

    if (!time) {
      return '0:00';
    }
    return value;
  };

  createVideo = (data) => {
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      sources: data.video.videoSources.map((video) => ({
        type: `video/${video.format}`,
        src: video.url
      }))
    };

    this.player = videojs(this.videoNode, videoJsOptions);
    this.setState({
      videoCurrentTime: this.player.currentTime(),
      videoId: data.video.id
    });
  };

  addMultipleBookmarks = (videoId, bookMarks) => {
    var promises = [];
    const query = `mutation createBookmark($videoId: ID!, $seconds: Int!, $description: String) {
      createBookmark(input: {videoId: $videoId, seconds: $seconds, description: $description}){
        errors
        bookmark {
          id
          seconds
          description
        }
      }
    }`;

    Array.isArray(bookMarks) &&
      bookMarks.forEach((bookmark) => {
        promises.push(
          axios.post(
            this.props.url,
            {
              query: query,
              variables: {
                videoId: videoId,
                seconds: parseInt(bookmark.timeStamp),
                description: bookmark.text
              }
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
        );
      });

    axios.all(promises).then((results) => {
      var sortBookmarks = [];
      results.forEach((result) => {
        const bookmark = result.data.data.createBookmark.bookmark;
        sortBookmarks.push({
          text: bookmark.description,
          id: bookmark.id,
          timeStamp: parseInt(bookmark.seconds)
        });
      });
      sortBookmarks.sort(function (a, b) {
        return a.timeStamp - b.timeStamp;
      });
      this.setState({ bookMarks: sortBookmarks });
    });
  };

  handleUpdateTime = (val) => {
    this.player.currentTime(val);
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddSubmit = async (e) => {
    e.preventDefault();
    const { bookmarkTxt, timeMins, timeSecs, bookMarks, edit } = this.state;
    const previousAry = bookMarks.slice();
    const calTimeStamp = parseInt(timeMins) * 60 + parseInt(timeSecs);
    const addId = await addBookmark(this.props.url, bookmarkTxt, calTimeStamp, this.state.videoId);
    this.setState({ addId: addId });
    previousAry.push({ id: addId, text: bookmarkTxt, timeStamp: parseInt(calTimeStamp) });
    previousAry.sort((a, b) => a.timeStamp - b.timeStamp);
    this.setState({ bookMarks: previousAry, timeMins: 0, timeSecs: 0, bookmarkTxt: '' });
  };

  handleEdit = (val, i) => {
    const { edit, editTxt, editNum, removeIndex, editMins, editSecs } = this.state;
    let mins = Math.floor(val.timeStamp / 60);
    let secs = val.timeStamp - mins * 60;
    const dropDown = removeIndex === i ? !edit : true;
    this.setState({
      edit: dropDown,
      editTxt: val.text,
      editNum: val.timeStamp,
      editMins: mins,
      editSecs: secs,
      removeIndex: i
    });
  };

  handleEditSubmit = (e) => {
    const { editTxt, bookMarks, editMins, editSecs, removeIndex, edit } = this.state;
    const previousAry = bookMarks.slice();
    const calTimeStamp = parseInt(editMins) * 60 + parseInt(editSecs);
    previousAry.splice(removeIndex, 1);
    previousAry.push({
      text: editTxt,
      timeStamp: parseInt(calTimeStamp),
      id: bookMarks[removeIndex].id
    });
    previousAry.sort((a, b) => a.timeStamp - b.timeStamp);
    updateBookmark(this.props.url, calTimeStamp, editTxt, bookMarks[removeIndex].id);
    this.setState({ bookMarks: previousAry, editSecs: 0, editMins: 0, edit: false });
    e.preventDefault();
  };

  handleDelete = (i) => {
    const { bookMarks } = this.state;
    const previousAry = bookMarks.slice();
    previousAry.splice(i, 1);
    this.setState({ bookMarks: previousAry, edit: false });
    deleteBookmark(this.props.url, bookMarks[i].id);
  };

  updateColor = (i) => {
    const { removeIndex, edit } = this.state;
    if (removeIndex === i && edit) {
      return 'SelectedTime BookMarkList';
    }
    return 'BookMarkList';
  };

  exitEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  };

  showBookmarkTime = (bookMarks, admin) => {
    const { videoJS, bookmarkTxt, time, timeMins, timeSecs, edit, editTxt, editMins, editSecs } =
      this.state;

    if (admin) {
      return (
        <div className="TimeStampSection" style={{ width: '100%' }}>
          <div className="TimeStampWrapper">
            {bookMarks.map((time, i) => (
              <div
                key={i}
                onClick={this.handleUpdateTime.bind(this, time.timeStamp)}
                className={this.updateColor(i)}
              >
                <div className="ellipsTxt">{time.text}</div>
                <div className="TimeStampTxtSection">
                  {this.props.deletePermission && (
                    <div>
                      <span className="deleteIcon" onClick={this.handleDelete.bind(this, i)}>
                        <span className="IconTxt">DELETE</span>
                      </span>
                      <span className="editIcon" onClick={this.handleEdit.bind(this, time, i)}>
                        <span className="IconTxt">EDIT</span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="TimeStampForm">
            {edit ? (
              <div>
                <form onSubmit={this.handleEditSubmit}>
                  <div style={{ display: 'inline-flex' }}>
                    <input
                      type="text"
                      name="editTxt"
                      className="TimeStampTxtInput"
                      onChange={this.handleInput}
                      value={editTxt}
                      placeholder="Time Stamp Title"
                    />
                    <div className="VideoTimeInput">
                      <input
                        className="InputTimeVideo"
                        required={true}
                        type="number"
                        name="editMins"
                        min="0"
                        max="99"
                        onChange={this.handleInput}
                        value={this.padTimeWithZeros(editMins, 'mins')}
                      />
                      :
                      <input
                        type="number"
                        className="InputTimeVideo"
                        name="editSecs"
                        required={true}
                        min="00"
                        max="59"
                        onChange={this.handleInput}
                        value={this.padTimeWithZeros(editSecs)}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="SubmitBTNVideos">
                    <input
                      onClick={this.exitEdit}
                      style={{ width: '3rem', textAlign: 'center' }}
                      className="EditTimeStampBtn"
                      defaultValue="Exit"
                    />
                    <input type="submit" value="SAVE" />
                  </div>
                </form>
              </div>
            ) : (
              <div className="TimeStampForm">
                {this.props.deletePermission && (
                  <form onSubmit={this.handleAddSubmit}>
                    <div style={{ display: 'inline-flex' }}>
                      <input
                        type="text"
                        name="bookmarkTxt"
                        onChange={this.handleInput}
                        className="TimeStampTxtInput"
                        value={bookmarkTxt}
                        placeholder="Time Stamp Title"
                      />
                      <div className="VideoTimeInput">
                        <input
                          className="InputTimeVideo"
                          required={true}
                          type="number"
                          name="timeMins"
                          min="0"
                          max="99"
                          onChange={this.handleInput}
                          value={this.padTimeWithZeros(timeMins, 'mins')}
                        />
                        :
                        <input
                          className="InputTimeVideo"
                          type="number"
                          name="timeSecs"
                          required={true}
                          min="00"
                          max="59"
                          onChange={this.handleInput}
                          value={this.padTimeWithZeros(timeSecs)}
                        />
                      </div>
                    </div>
                    <br />
                    <input className="SubmitBTNVideos" type="submit" value="ADD" />
                  </form>
                )}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  showBookmark = (admin) => {
    const { bookMarks, bookmarkTxt, timeMins, timeSecs } = this.state;

    if (bookMarks.length === 0 && admin) {
      return (
        <div className="EmptyTimeStampForm">
          {this.props.deletePermission && (
            <form onSubmit={this.handleAddSubmit}>
              <div style={{ display: 'inline-flex' }}>
                <input
                  type="text"
                  name="bookmarkTxt"
                  onChange={this.handleInput}
                  className="TimeStampTxtInput"
                  value={bookmarkTxt}
                  placeholder="Time Stamp Title"
                />
                <div className="VideoTimeInput">
                  <input
                    className="InputTimeVideo"
                    required={true}
                    type="number"
                    name="timeMins"
                    min="0"
                    max="99"
                    onChange={this.handleInput}
                    value={this.padTimeWithZeros(timeMins, 'mins')}
                  />
                  :
                  <input
                    className="InputTimeVideo"
                    type="number"
                    name="timeSecs"
                    required={true}
                    min="00"
                    max="59"
                    onChange={this.handleInput}
                    value={this.padTimeWithZeros(timeSecs)}
                  />
                </div>
              </div>
              <br />
              <input className="SubmitBTNVideos" type="submit" value="ADD" />
            </form>
          )}
        </div>
      );
    } else {
      return <div style={{ margin: 'auto' }}>{this.showBookmarkTime(bookMarks, admin)}</div>;
    }
  };

  showDeleteButton = (permission) => {
    if (permission) {
      return (
        <div>
          <button
            type="button"
            onClick={(e) => {
              window.confirm('Are you sure you wish to delete this video?') &&
                deleteVideo(this.props.deleteVideoUrl, this.state.videoId).then((res) => {
                  logActivity('deleted video in', this.props.activityUrl);
                  this.setState({ deletedVideo: true });
                  window.location.reload(true);
                });
            }}
            style={{ backgroundColor: '#d9534f', color: 'white' }}
          >
            DELETE VIDEO
          </button>
        </div>
      );
    }
  };

  padTimeWithZeros = (number, unit) => {
    if (unit !== 'mins') unit = 'secs';

    number = parseInt(number, 10);

    if (unit === 'secs' && number > 59) number = 59;
    if (unit === 'mins' && number > 99) number = 99;
    if (number < 0) number = 0;

    if (number < 10) number = `0${number}`;

    return number;
  };

  showVideo = (admin) => {
    const pathname = window.location.pathname;

    const VideoInterviewClass = classnames({
      VideoInterviewAdmin: pathname.startsWith('/admin/'),
      VideoInterviewClient: pathname.startsWith('/video_interviews/')
    });

    const VideoWrapperClass = classnames({
      VideoWrapperAdmin: pathname.startsWith('/admin/'),
      VideoWrapperClient: pathname.startsWith('/video_interviews/')
    });

    return (
      <div>
        {!this.state.deletedVideo ? (
          <div className={VideoInterviewClass}>
            <div className={VideoWrapperClass}>
              <div data-vjs-player className="VideoStyles">
                <video
                  ref={(node) => (this.videoNode = node)}
                  className="video-js vjs-big-play-centered"
                  controls
                ></video>
              </div>
              {this.showBookmark(admin)}
            </div>
            {this.showDeleteButton(this.props.deletePermission)}
          </div>
        ) : (
          <VideoUpload
            applicationId={this.props.applicationId}
            admin={this.props.admin}
            url={this.props.url}
            uploadUrl={this.props.uploadUrl}
            deleteVideoUrl={this.props.deleteVideoUrl}
            updateUrl={this.props.updateUrl}
          />
        )}
      </div>
    );
  };

  render() {
    const admin = this.props.admin;

    return this.showVideo(admin);
  }
}

export default VideoInterview;
