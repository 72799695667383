"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  sharedTableActionReducer,
  sharedTableInitialState
} from "../../../../TableWithSearchFieldDropDownAndFilter/reducer";
import {
  createReportOptions,
  modalStates,
  previewState
} from "../../CustomReports/customReportReducer";
export const setInitialState = (defaultActiveColumns, defaultFieldOptions, filters, fetchParams) => {
  return __spreadValues(__spreadProps(__spreadValues({}, sharedTableInitialState), {
    records: [],
    activeColumns: defaultActiveColumns,
    fieldOptions: defaultFieldOptions,
    filters,
    hasEmptyHeaderFirstColumn: true,
    entity_id: fetchParams.entity_id,
    user: fetchParams.user,
    openPreviewCustom: false,
    data: void 0,
    previewTable: previewState,
    createReportOptions
  }), modalStates);
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_RECORD":
      return __spreadProps(__spreadValues({}, state), {
        data: action.payload.data,
        openPreviewCustom: true,
        isLoading: false,
        previewTable: {
          data: action.payload.previewData,
          tableHeaders: action.payload.previewHeaders
        }
      });
    case "SET_CREATE_REPORT_OPTIONS":
      return __spreadProps(__spreadValues({}, state), {
        createReportOptions: __spreadValues(__spreadValues({}, state.createReportOptions), action.payload)
      });
    case "CLOSE_PREVIEW":
      return __spreadProps(__spreadValues({}, state), { openPreviewCustom: false });
    default:
      return sharedTableActionReducer(state, action);
  }
};
