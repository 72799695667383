"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  widget: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    flex: "40%",
    minWidth: "600px"
  },
  subcontainer: {
    flex: "40%",
    minWidth: "600px",
    display: "flex",
    flexWrap: "wrap",
    columnGap: "50px",
    rowGap: "50px"
  },
  subWidget: {
    flex: "40%",
    borderRadius: "3px",
    height: "120px",
    minWidth: "524px"
  },
  creationExpiryContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
    paddingLeft: "4%"
  },
  widgetIcon: {
    backgroundColor: "#EDF2F5",
    padding: "12px",
    borderRadius: "16px",
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "32px"
    }
  },
  widgetText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    rowGap: "6px",
    marginLeft: "30px"
  },
  widgetTextTitle: {
    fontSize: "16px",
    color: theme.palette.primary.main
  },
  widgetTextContent: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#333333"
  },
  widgetBlockContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    columnGap: "50px"
  },
  widgetBlock: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "4%",
    width: "50%",
    height: "100%"
  },
  widgetBlockText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    rowGap: "3px",
    marginLeft: "25px"
  },
  blockCount: {
    fontSize: "24px",
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  blockTitle: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#333333"
  },
  widgetBlockIcon: {
    backgroundColor: "#EDF2F5",
    padding: "12px",
    borderRadius: "16px",
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "28px"
    }
  },
  infoContainer: {
    flex: "40%",
    minWidth: "600px",
    display: "flex",
    flexWrap: "wrap",
    columnGap: "20px",
    minHeight: "120px",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px"
  },
  widgetBlockNew: {
    position: "absolute",
    top: "20px",
    right: "24px",
    color: "#8CCF95",
    fontWeight: "bold",
    fontSize: "18px"
  },
  jobInfoContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flex: "40%",
    borderRadius: "3px",
    minWidth: "500px",
    maxHeight: "90px",
    overflow: "hidden"
  },
  infoText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: "6px",
    marginLeft: "20px",
    maxWidth: "160px",
    width: "100%"
  },
  infoTextTitle: {
    fontSize: "16px",
    color: theme.palette.primary.main
  },
  infoTextContent: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#333333"
  },
  infoWidget: {
    display: "flex",
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    flex: "40%",
    minWidth: "600px"
  },
  tag: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#939393",
    backgroundColor: "#F8F8F8",
    borderRadius: "18px",
    height: "23px",
    maxWidth: "140px",
    display: "inline-block",
    padding: "2px 12px",
    marginRight: "6px",
    marginBottom: "4px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&:hover": {
      maxWidth: "unset"
    }
  }
};
