"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import moment from "moment";
import { SortableColumnId } from "../Job/types";
import { convertToGroupAndTabIndex, convertToPanelIndex, TabGroup } from "../EditJob/tabIndexUtils";
export const jobsConfig = {
  sortableColumnsDefaults: [
    {
      id: "reference",
      label: "Reference",
      sortable: false,
      canToggle: false,
      enabled: true
    },
    {
      id: "title",
      label: "Title",
      sortable: true,
      canToggle: false,
      enabled: true
    },
    {
      id: "published_at",
      label: "Published",
      sortable: true,
      canToggle: true,
      enabled: false
    },
    {
      id: "created_at",
      label: "Created",
      sortable: true,
      canToggle: true,
      enabled: true
    },
    {
      id: "expires_at",
      label: "Expiry",
      sortable: true,
      canToggle: true,
      enabled: false
    },
    {
      id: "applications_count",
      label: "Applications",
      sortable: true,
      canToggle: true,
      enabled: true
    },
    {
      id: "state",
      label: "State",
      sortable: true,
      canToggle: true,
      enabled: true
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      canToggle: true,
      enabled: true
    },
    {
      id: "entity",
      label: "Entity",
      sortable: true,
      canToggle: true,
      enabled: true
    },
    {
      id: "location",
      label: "Location",
      sortable: false,
      canToggle: true,
      enabled: false
    },
    {
      id: "assigned_user",
      label: "Assigned User",
      sortable: false,
      canToggle: true,
      enabled: false
    },
    {
      id: "recruitment_ad_user",
      label: "RM User",
      sortable: false,
      canToggle: true,
      enabled: false
    },
    {
      id: SortableColumnId.NOTES,
      label: "Notes",
      sortable: false,
      canToggle: true,
      enabled: false
    }
  ],
  headers: [
    {
      name: "reference",
      label: "Reference"
    },
    {
      name: "title",
      label: "Title"
    },
    {
      name: "published_at",
      label: "Published"
    },
    {
      name: "created_at",
      label: "Created"
    },
    {
      name: "expires_at",
      label: "Expiry"
    },
    {
      name: "applications_count",
      label: "Applications"
    },
    {
      name: "state",
      label: "State"
    },
    {
      name: "status",
      label: "Status"
    },
    {
      name: "entity",
      label: "Entity"
    },
    {
      name: "location",
      label: "Location"
    },
    {
      name: "assigned_user",
      label: "Assigned User"
    },
    {
      name: "recruitment_ad_user",
      label: "RM User"
    },
    {
      name: "notes",
      label: "Notes"
    }
  ],
  archivedHeaders: [
    {
      name: "reference",
      label: "Reference"
    },
    {
      name: "title",
      label: "Title"
    },
    {
      name: "published_at",
      label: "Published"
    },
    {
      name: "created_at",
      label: "Created"
    },
    {
      name: "expires_at",
      label: "Expiry"
    },
    {
      name: "applications_count",
      label: "Applications"
    },
    {
      name: "state",
      label: "State"
    },
    {
      name: "entity",
      label: "Entity"
    },
    {
      name: "location",
      label: "Location"
    },
    {
      name: "assigned_user",
      label: "Assigned User"
    },
    {
      name: "recruitment_ad_user",
      label: "RM User"
    }
  ],
  dropdownOptions: [
    {
      name: "published_at",
      label: "Published"
    },
    {
      name: "created_at",
      label: "Created"
    },
    {
      name: "expires_at",
      label: "Expiry"
    },
    {
      name: "applications_count",
      label: "Applications"
    },
    {
      name: "state",
      label: "State"
    },
    {
      name: "status",
      label: "Status"
    },
    {
      name: "entity",
      label: "Entity"
    },
    {
      name: "location",
      label: "Location"
    },
    {
      name: "assigned_user",
      label: "Assigned User"
    },
    {
      name: "recruitment_ad_user",
      label: "RM User"
    },
    {
      name: "notes",
      label: "Notes"
    }
  ],
  archivedDropdownOptions: [
    {
      name: "published_at",
      label: "Published"
    },
    {
      name: "created_at",
      label: "Created"
    },
    {
      name: "expires_at",
      label: "Expiry"
    },
    {
      name: "applications_count",
      label: "Applications"
    },
    {
      name: "state",
      label: "State"
    },
    {
      name: "entity",
      label: "Entity"
    },
    {
      name: "location",
      label: "Location"
    },
    {
      name: "assigned_user",
      label: "Assigned User"
    },
    {
      name: "recruitment_ad_user",
      label: "RM User"
    }
  ],
  fieldDropdownSetting: {
    published_at: true,
    created_at: false,
    expires_at: true,
    applications_count: true,
    state: true,
    status: false,
    location: true,
    entity: true,
    assigned_user: false,
    recruitment_ad_user: false,
    notes: false
  },
  defaultFilterOptions: {
    state: [
      {
        value: "published",
        name: "Published"
      },
      {
        value: "unpublished",
        name: "Unpublished"
      },
      {
        value: "draft",
        name: "Draft"
      },
      {
        value: "saved",
        name: "Saved"
      },
      {
        value: "closed",
        name: "Closed"
      }
    ],
    entity: [
      {
        id: 1,
        name: "No Entities Found"
      }
    ],
    status: [
      {
        name: "No Statuses Found"
      }
    ],
    location: [
      {
        name: "No Locations Found"
      }
    ],
    assigned_user: [
      {
        id: 1,
        name: "No Assigned Users Found"
      }
    ],
    recruitment_ad_user: [
      {
        id: 0,
        name: "No RM Users Found"
      }
    ]
  },
  currentStateOptions: {
    state: [
      {
        value: "published",
        name: "Published"
      },
      {
        value: "unpublished",
        name: "Unpublished"
      },
      {
        value: "draft",
        name: "Draft"
      },
      {
        value: "saved",
        name: "Saved"
      },
      {
        value: "closed",
        name: "Closed"
      }
    ]
  },
  archivedStateOptions: {
    state: [
      {
        value: "saved",
        name: "Saved"
      },
      {
        value: "closed",
        name: "Closed"
      }
    ]
  }
};
export const applicantDetailsDefault = {
  firstName: { error: "", required: true, value: "" },
  lastName: { error: "", required: true, value: "" },
  email: { error: "", required: true, value: "" },
  phone: { error: "", required: true, value: "" },
  addressFirstLine: { error: "", required: true, value: "" },
  addressSecondLine: { error: "", required: false, value: "" },
  country: { error: "", required: true, value: "" },
  region: { error: "", required: true, value: "" },
  employeeId: { error: "", required: false, value: "" }
};
export const createJobDefault = {
  reference: { error: "", required: true, value: "" },
  title: { error: "", required: true, value: "" },
  expires_at: { error: "", required: true, value: null },
  location: { error: "", required: true, value: "" }
};
export const editGeneralJobDefault = (job) => {
  var _a, _b;
  return {
    reference: { error: "", required: true, value: job.reference },
    title: { error: "", required: true, value: job.title },
    expires_at: { error: "", required: true, value: job.expires_at },
    time: { error: "", required: true, value: job.expires_at },
    location: { error: "", required: true, value: job.location },
    employment_status: { error: "", required: false, value: job.employment_status },
    salary_info: { error: "", required: false, value: job.salary_info },
    number_of_vacancies: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.number_of_vacancies) ? job.number_of_vacancies : null
    },
    from_email: { error: "", required: false, value: (job == null ? void 0 : job.from_email) ? job.from_email : "" },
    external_approval_id: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.external_approval_id) ? job.external_approval_id : ""
    },
    requisitions: { error: "", required: false, value: (job == null ? void 0 : job.requisitions) ? job.requisitions : [] },
    time_zone: { error: "", required: false, value: (job == null ? void 0 : job.time_zone) ? job.time_zone : null },
    brand: { error: "", required: false, value: (job == null ? void 0 : job.brand) ? job.brand : null },
    locale: { error: "", required: false, value: (job == null ? void 0 : job.locale) ? localDefaults(job) : [] },
    country_id: { error: "", required: false, value: (job == null ? void 0 : job.country_id) ? job == null ? void 0 : job.country_id : null },
    options: (job == null ? void 0 : job.options) ? additionalOptionsDefaults(job) : null,
    classification_list: ((_a = job == null ? void 0 : job.classifications) == null ? void 0 : _a.length) > 0 ? job.classifications : [],
    classification_ids: ((_b = job == null ? void 0 : job.classifications) == null ? void 0 : _b.length) > 0 ? setClassificationIds(job) : [],
    classifications: { error: "", required: false, value: null },
    sub_classifications: { error: "", required: false, value: null },
    auto_rater: { error: "", required: false, value: (job == null ? void 0 : job.auto_rater) ? job.auto_rater : null },
    assigned_user: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.assigned_user) ? job.assigned_user : null
    },
    smart_form_recipients: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.smart_form_recipients) ? job.smart_form_recipients : []
    },
    pinned_users: { error: "", required: false, value: (job == null ? void 0 : job.pinned_users) ? job.pinned_users : [] },
    recipients: { error: "", required: false, value: (job == null ? void 0 : job.recipients) ? job.recipients : [] },
    salesperson: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.salesperson) ? `${job.salesperson.id} - ${job.salesperson.firstname} ${job.salesperson.lastname}` : null
    },
    rm_team_member: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.rm_team_member) ? `${job.rm_team_member.id} - ${job.rm_team_member.firstname} ${job.rm_team_member.lastname}` : null
    },
    external: job.external,
    internal: job.internal,
    privacy_acknowledgement: job.privacy_acknowledgement,
    entity_privacy_acknowledgement: job.entity_privacy_acknowledgement,
    entity_privacy_acknowledgement_link: job.entity_privacy_acknowledgement_link,
    linkedin_listing_opt_in: job.linkedin_listing_opt_in,
    address_state: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.address_state) ? job == null ? void 0 : job.address_state : ""
    },
    postcode: { error: "", required: false, value: (job == null ? void 0 : job.postcode) ? job == null ? void 0 : job.postcode : "" },
    tag_list: { error: "", required: false, value: (job == null ? void 0 : job.tag_list) ? job == null ? void 0 : job.tag_list : "" }
  };
};
export const editPanelWorkflowDefault = (job) => {
  return {
    panel_chairs: { error: "", required: false, value: (job == null ? void 0 : job.panel_chairs) ? job.panel_chairs : [] },
    panel_members: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.panel_members) ? job.panel_members : []
    },
    panel_observers: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.panel_observers) ? job.panel_observers : []
    }
  };
};
export const editGoogleScriptDefault = (job) => {
  return {
    google_conversion_script: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.google_conversion_script) ? job.google_conversion_script : ""
    }
  };
};
export const editRevolveDefault = (job) => {
  return {
    revolve_position_title: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.revolve_position_title) ? job.revolve_position_title : ""
    }
  };
};
export const editRequisitionInfoDefault = (job) => {
  return {
    requisition_info: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.requisition_info) ? job.requisition_info : ""
    }
  };
};
export const editDescriptionDefault = (job) => {
  return {
    summary: { error: "", required: false, value: (job == null ? void 0 : job.summary) ? job.summary : "" },
    description: { error: "", required: false, value: (job == null ? void 0 : job.description) ? job.description : "" }
  };
};
export const editTemplateDefault = (job) => {
  return {
    template_enabled: job == null ? void 0 : job.template_enabled,
    template: { error: "", required: false, value: (job == null ? void 0 : job.template) ? job.template : "" }
  };
};
export const editSourcesDefault = (job) => {
  return {
    sources: { error: "", required: false, value: (job == null ? void 0 : job.sources) ? job.sources : [] }
  };
};
export const editAttachmentsDefault = (job) => {
  return {
    candidate_attachments: {
      error: "",
      required: false,
      value: (job == null ? void 0 : job.candidate_attachments) ? job.candidate_attachments : []
    }
  };
};
export const newJobsConfig = {
  resource_field_choices: [
    {
      id: "resources[description]",
      name: "Description",
      position: 0
    },
    {
      id: "resources[questions]",
      name: "Questions",
      position: 1
    },
    {
      id: "resources[attachments]",
      name: "Attachments",
      position: 2
    },
    {
      id: "resources[sources]",
      name: "Sources",
      position: 3
    },
    {
      id: "resources[auto_actions]",
      name: "Auto-Actions",
      position: 4
    },
    {
      id: "resources[options]",
      name: "Options",
      position: 5
    },
    {
      id: "resources[visibility]",
      name: "Visibility",
      position: 6
    }
  ]
};
export const resourceDefault = {
  "resources[description]": true,
  "resources[questions]": true,
  "resources[attachments]": true,
  "resources[sources]": true,
  "resources[auto_actions]": true,
  "resources[options]": true,
  "resources[visibility]": true
};
export const additionalOptionsDefaults = (job) => {
  return __spreadProps(__spreadValues({}, job.options), {
    indeed_apply: job.options.indeed_apply === "1",
    indeed_salary: job.options.indeed_salary === "1",
    blind_rating: job.options.blind_rating === "1",
    hide_rating_activity: job.options.hide_rating_activity === "1",
    auto_ratings: job.options.auto_ratings === "1",
    title_field: job.options.title_field === "1",
    gender_field: job.options.gender_field === "1",
    employee_id_field: job.options.employee_id_field === "1",
    linkedin_field: job.options.linkedin_field === "1",
    linkedin_listing_opt_in: job.linkedin_listing_opt_in,
    job_expiry_email_reminder: job.options.job_expiry_email_reminder === "1"
  });
};
export const changeURL = (newValue, search, urlParams) => {
  if (search) {
    const tabIndex = convertToGroupAndTabIndex(newValue);
    switch (tabIndex.group) {
      case TabGroup.JOB_INFORMATION:
        switch (tabIndex.index) {
          case 0:
            return "";
          case 1:
            return "?description";
          case 2:
            return "?template";
          case 3:
            return "?questions";
          case 4:
            return "?sources";
        }
        break;
      case TabGroup.CANDIDATE_SETTINGS:
        switch (tabIndex.index) {
          case 0:
            return "?attachments";
        }
        break;
      case TabGroup.AUTOMATION:
        switch (tabIndex.index) {
          case 0:
            return "?auto-statuses";
          case 1:
            return "?status-transitions";
          case 2:
            return "?auto-forward";
          case 3:
            return "?auto-email-reply";
          case 4:
            return "?auto-sms-reply";
          case 5:
            return "?auto-sms-forward";
        }
        break;
      case TabGroup.ADVANCED:
        switch (tabIndex.index) {
          case 0:
            return "?requisition-info";
          case 1:
            return "?configure-application-hr-questions";
          case 2:
            return "?automated-testgrid";
          case 3:
            return "?revolve";
          case 4:
            return "?google-conversion-script";
          case 5:
            return "?panel-workflow";
          case 6:
            return "?ai-studio";
        }
      default:
        return "";
    }
  } else {
    switch (urlParams) {
      case "?description":
        return convertToPanelIndex(1, TabGroup.JOB_INFORMATION);
      case "?template":
        return convertToPanelIndex(2, TabGroup.JOB_INFORMATION);
      case "?questions":
        return convertToPanelIndex(3, TabGroup.JOB_INFORMATION);
      case "?sources":
        return convertToPanelIndex(4, TabGroup.JOB_INFORMATION);
      case "?attachments":
        return convertToPanelIndex(0, TabGroup.CANDIDATE_SETTINGS);
      case "?auto-statuses":
        return convertToPanelIndex(0, TabGroup.AUTOMATION);
      case "?status-transitions":
        return convertToPanelIndex(1, TabGroup.AUTOMATION);
      case "?auto-forward":
        return convertToPanelIndex(2, TabGroup.AUTOMATION);
      case "?auto-email-reply":
        return convertToPanelIndex(3, TabGroup.AUTOMATION);
      case "?auto-sms-reply":
        return convertToPanelIndex(4, TabGroup.AUTOMATION);
      case "?auto-sms-forward":
        return convertToPanelIndex(5, TabGroup.AUTOMATION);
      case "?requisition-info":
        return convertToPanelIndex(0, TabGroup.ADVANCED);
      case "?configure-application-hr-questions":
        return convertToPanelIndex(1, TabGroup.ADVANCED);
      case "?automated-testgrid":
        return convertToPanelIndex(2, TabGroup.ADVANCED);
      case "?revolve":
        return convertToPanelIndex(3, TabGroup.ADVANCED);
      case "?google-conversion-script":
        return convertToPanelIndex(4, TabGroup.ADVANCED);
      case "?panel-workflow":
        return convertToPanelIndex(5, TabGroup.ADVANCED);
      case "?ai-studio":
        return convertToPanelIndex(6, TabGroup.ADVANCED);
      default:
        return 1;
    }
  }
};
export const editJobObject = (candidateAttachments, generalTabJobData, descriptionTabJobData, templateTabJobData, revolveTabJobData, googleScriptTabJobData, panelWorkflowTabJobData, sourceListIds) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H, _I, _J, _K, _L, _M, _N, _O, _P, _Q, _R, _S, _T, _U, _V, _W, _X, _Y, _Z, __, _$, _aa, _ba, _ca, _da, _ea, _fa, _ga, _ha, _ia, _ja, _ka, _la, _ma, _na, _oa, _pa, _qa, _ra, _sa, _ta, _ua, _va, _wa, _xa, _ya, _za, _Aa, _Ba, _Ca;
  const attachmentObject = {};
  candidateAttachments == null ? void 0 : candidateAttachments.map((attachment) => {
    attachmentObject[attachment.id] = {
      name: attachment.label,
      mandatory: attachment.required ? "1" : "0"
    };
  });
  const expiryDate = (_a = generalTabJobData == null ? void 0 : generalTabJobData.expires_at) == null ? void 0 : _a.value;
  const newDateUTC = moment(expiryDate).utc().format("YYYY-MM-DD");
  const newTimeUTC = moment(expiryDate).utc().format("HH:mm");
  const updatedExpiryDate = `${newDateUTC}T${newTimeUTC}:00Z`;
  return {
    reference: (_b = generalTabJobData == null ? void 0 : generalTabJobData.reference) == null ? void 0 : _b.value,
    title: (_c = generalTabJobData == null ? void 0 : generalTabJobData.title) == null ? void 0 : _c.value,
    expires_at: updatedExpiryDate,
    location: (_d = generalTabJobData == null ? void 0 : generalTabJobData.location) == null ? void 0 : _d.value,
    external_requisition_id: ((_e = generalTabJobData == null ? void 0 : generalTabJobData.external_approval_id) == null ? void 0 : _e.value) ? generalTabJobData.external_approval_id.value : null,
    salesperson_id: ((_f = generalTabJobData == null ? void 0 : generalTabJobData.salesperson) == null ? void 0 : _f.value) ? generalTabJobData.salesperson.value.split(" -")[0] : null,
    // "recruitment_ad_user_id": 42,
    assigned_user_id: ((_g = generalTabJobData == null ? void 0 : generalTabJobData.assigned_user) == null ? void 0 : _g.value) ? generalTabJobData.assigned_user.value.id : null,
    pinned_users_ids: ((_h = generalTabJobData == null ? void 0 : generalTabJobData.pinned_users) == null ? void 0 : _h.value) ? ((_j = (_i = generalTabJobData == null ? void 0 : generalTabJobData.pinned_users) == null ? void 0 : _i.value) == null ? void 0 : _j.length) > 0 ? (_l = (_k = generalTabJobData == null ? void 0 : generalTabJobData.pinned_users) == null ? void 0 : _k.value) == null ? void 0 : _l.map((x) => x.id) : [] : [],
    recipient_ids: ((_m = generalTabJobData == null ? void 0 : generalTabJobData.recipients) == null ? void 0 : _m.value) ? ((_o = (_n = generalTabJobData == null ? void 0 : generalTabJobData.recipients) == null ? void 0 : _n.value) == null ? void 0 : _o.length) > 0 ? (_q = (_p = generalTabJobData == null ? void 0 : generalTabJobData.recipients) == null ? void 0 : _p.value) == null ? void 0 : _q.map((x) => x.id) : [] : [],
    time_zone: (_r = generalTabJobData == null ? void 0 : generalTabJobData.time_zone) == null ? void 0 : _r.value,
    locale: ((_t = (_s = generalTabJobData == null ? void 0 : generalTabJobData.locale) == null ? void 0 : _s.value) == null ? void 0 : _t.label) ? (_u = generalTabJobData.locale.value) == null ? void 0 : _u.value : (_v = generalTabJobData == null ? void 0 : generalTabJobData.locale) == null ? void 0 : _v.value,
    country_id: ((_w = generalTabJobData == null ? void 0 : generalTabJobData.country_id) == null ? void 0 : _w.value) ? (_y = (_x = generalTabJobData == null ? void 0 : generalTabJobData.country_id) == null ? void 0 : _x.value) == null ? void 0 : _y.id : (_z = generalTabJobData == null ? void 0 : generalTabJobData.country_id) == null ? void 0 : _z.value,
    from_email: (_A = generalTabJobData == null ? void 0 : generalTabJobData.from_email) == null ? void 0 : _A.value,
    employment_status: (_B = generalTabJobData == null ? void 0 : generalTabJobData.employment_status) == null ? void 0 : _B.value,
    number_of_vacancies: (_C = generalTabJobData == null ? void 0 : generalTabJobData.number_of_vacancies) == null ? void 0 : _C.value,
    postcode: (_D = generalTabJobData == null ? void 0 : generalTabJobData.postcode) == null ? void 0 : _D.value,
    address_state: (_E = generalTabJobData == null ? void 0 : generalTabJobData.address_state) == null ? void 0 : _E.value,
    brand_id: ((_F = generalTabJobData == null ? void 0 : generalTabJobData.brand) == null ? void 0 : _F.value) ? generalTabJobData.brand.value.id : null,
    tag_list: (_G = generalTabJobData == null ? void 0 : generalTabJobData.tag_list) == null ? void 0 : _G.value,
    auto_rater_id: (_I = (_H = generalTabJobData == null ? void 0 : generalTabJobData.auto_rater) == null ? void 0 : _H.value) == null ? void 0 : _I.id,
    salary_info: (_J = generalTabJobData == null ? void 0 : generalTabJobData.salary_info) == null ? void 0 : _J.value,
    requisition_ids: ((_K = generalTabJobData == null ? void 0 : generalTabJobData.requisitions) == null ? void 0 : _K.value) ? ((_M = (_L = generalTabJobData == null ? void 0 : generalTabJobData.requisitions) == null ? void 0 : _L.value) == null ? void 0 : _M.length) > 0 ? (_O = (_N = generalTabJobData == null ? void 0 : generalTabJobData.requisitions) == null ? void 0 : _N.value) == null ? void 0 : _O.map((x) => x.id) : [] : [],
    smart_form_recipients_ids: ((_P = generalTabJobData == null ? void 0 : generalTabJobData.smart_form_recipients) == null ? void 0 : _P.value) ? ((_R = (_Q = generalTabJobData == null ? void 0 : generalTabJobData.smart_form_recipients) == null ? void 0 : _Q.value) == null ? void 0 : _R.length) > 0 ? (_T = (_S = generalTabJobData == null ? void 0 : generalTabJobData.smart_form_recipients) == null ? void 0 : _S.value) == null ? void 0 : _T.map((x) => x.id) : [] : [],
    options: {
      indeed_apply: ((_U = generalTabJobData == null ? void 0 : generalTabJobData.options) == null ? void 0 : _U.indeed_apply) ? "1" : "0",
      indeed_salary: ((_V = generalTabJobData == null ? void 0 : generalTabJobData.options) == null ? void 0 : _V.indeed_salary) ? "1" : "0",
      blind_rating: ((_W = generalTabJobData == null ? void 0 : generalTabJobData.options) == null ? void 0 : _W.blind_rating) ? "1" : "0",
      hide_rating_activity: ((_X = generalTabJobData == null ? void 0 : generalTabJobData.options) == null ? void 0 : _X.hide_rating_activity) ? "1" : "0",
      auto_ratings: ((_Y = generalTabJobData == null ? void 0 : generalTabJobData.options) == null ? void 0 : _Y.auto_ratings) ? "1" : "0",
      title_field: ((_Z = generalTabJobData == null ? void 0 : generalTabJobData.options) == null ? void 0 : _Z.title_field) ? "1" : "0",
      gender_field: ((__ = generalTabJobData == null ? void 0 : generalTabJobData.options) == null ? void 0 : __.gender_field) ? "1" : "0",
      employee_id_field: ((_$ = generalTabJobData == null ? void 0 : generalTabJobData.options) == null ? void 0 : _$.employee_id_field) ? "1" : "0",
      linkedin_field: ((_aa = generalTabJobData == null ? void 0 : generalTabJobData.options) == null ? void 0 : _aa.linkedin_field) ? "1" : "0",
      privacy_acknowledgement: (generalTabJobData == null ? void 0 : generalTabJobData.privacy_acknowledgement) ? "1" : "0",
      attachments: attachmentObject || {},
      job_expiry_email_reminder: ((_ba = generalTabJobData == null ? void 0 : generalTabJobData.options) == null ? void 0 : _ba.job_expiry_email_reminder) ? "1" : "0"
    },
    external: (generalTabJobData == null ? void 0 : generalTabJobData.external) ? "1" : "0",
    linkedin_listing_opt_in: (generalTabJobData == null ? void 0 : generalTabJobData.linkedin_listing_opt_in) ? "1" : "0",
    internal: (generalTabJobData == null ? void 0 : generalTabJobData.internal) ? "1" : "0",
    recruitment_ad_user_id: ((_ca = generalTabJobData == null ? void 0 : generalTabJobData.rm_team_member) == null ? void 0 : _ca.value) ? (_da = generalTabJobData == null ? void 0 : generalTabJobData.rm_team_member) == null ? void 0 : _da.value.split(" -")[0] : null,
    summary: (_ea = descriptionTabJobData == null ? void 0 : descriptionTabJobData.summary) == null ? void 0 : _ea.value,
    description: (_fa = descriptionTabJobData == null ? void 0 : descriptionTabJobData.description) == null ? void 0 : _fa.value,
    template_enabled: templateTabJobData == null ? void 0 : templateTabJobData.template_enabled,
    template: (_ga = templateTabJobData == null ? void 0 : templateTabJobData.template) == null ? void 0 : _ga.value,
    classification_ids: (generalTabJobData == null ? void 0 : generalTabJobData.classification_ids) ? generalTabJobData == null ? void 0 : generalTabJobData.classification_ids : [],
    application_recipient_ids: ((_ha = generalTabJobData == null ? void 0 : generalTabJobData.application_recipient) == null ? void 0 : _ha.value) ? ((_ja = (_ia = generalTabJobData == null ? void 0 : generalTabJobData.application_recipient) == null ? void 0 : _ia.value) == null ? void 0 : _ja.length) > 0 ? (_la = (_ka = generalTabJobData == null ? void 0 : generalTabJobData.application_recipient) == null ? void 0 : _ka.value) == null ? void 0 : _la.map((x) => x.id) : [] : [],
    revolve_position_title: (_ma = revolveTabJobData == null ? void 0 : revolveTabJobData.revolve_position_title) == null ? void 0 : _ma.value,
    google_conversion_script: (_na = googleScriptTabJobData == null ? void 0 : googleScriptTabJobData.google_conversion_script) == null ? void 0 : _na.value,
    panel_chair_ids: ((_oa = panelWorkflowTabJobData == null ? void 0 : panelWorkflowTabJobData.panel_chairs) == null ? void 0 : _oa.value) ? ((_qa = (_pa = panelWorkflowTabJobData == null ? void 0 : panelWorkflowTabJobData.panel_chairs) == null ? void 0 : _pa.value) == null ? void 0 : _qa.length) > 0 ? (_sa = (_ra = panelWorkflowTabJobData == null ? void 0 : panelWorkflowTabJobData.panel_chairs) == null ? void 0 : _ra.value) == null ? void 0 : _sa.map((x) => x.id) : [] : [],
    panel_member_ids: ((_ta = panelWorkflowTabJobData == null ? void 0 : panelWorkflowTabJobData.panel_members) == null ? void 0 : _ta.value) ? ((_va = (_ua = panelWorkflowTabJobData == null ? void 0 : panelWorkflowTabJobData.panel_members) == null ? void 0 : _ua.value) == null ? void 0 : _va.length) > 0 ? (_xa = (_wa = panelWorkflowTabJobData == null ? void 0 : panelWorkflowTabJobData.panel_members) == null ? void 0 : _wa.value) == null ? void 0 : _xa.map((x) => x.id) : [] : [],
    panel_observer_ids: ((_ya = panelWorkflowTabJobData == null ? void 0 : panelWorkflowTabJobData.panel_observers) == null ? void 0 : _ya.value) ? ((_Aa = (_za = panelWorkflowTabJobData == null ? void 0 : panelWorkflowTabJobData.panel_observers) == null ? void 0 : _za.value) == null ? void 0 : _Aa.length) > 0 ? (_Ca = (_Ba = panelWorkflowTabJobData == null ? void 0 : panelWorkflowTabJobData.panel_observers) == null ? void 0 : _Ba.value) == null ? void 0 : _Ca.map((x) => x.id) : [] : [],
    source_ids: sourceListIds ? sourceListIds : []
  };
};
export const editJobsConfig = (userPermissions) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const additionalOptions = [
    {
      id: "blind_rating",
      name: "Blind ratings"
    }
  ];
  ((_a = userPermissions == null ? void 0 : userPermissions["Job Options"]) == null ? void 0 : _a["Indeed Apply Option"]) && additionalOptions.unshift({
    id: "indeed_apply",
    name: "Use Indeed Apply"
  });
  ((_b = userPermissions == null ? void 0 : userPermissions["Integrations"]) == null ? void 0 : _b["Indeed Integration"]) && additionalOptions.unshift({
    id: "indeed_salary",
    name: "Send salary to Indeed"
  });
  ((_c = userPermissions == null ? void 0 : userPermissions["Integrations"]) == null ? void 0 : _c["LinkedIn"]) && additionalOptions.push({
    id: "linkedin_listing_opt_in",
    name: "Post to Linkedin"
  });
  ((_d = userPermissions == null ? void 0 : userPermissions["Job Options"]) == null ? void 0 : _d["Linkedin"]) && additionalOptions.push({
    id: "linkedin_field",
    name: "Show Linkedin"
  });
  ((_e = userPermissions == null ? void 0 : userPermissions["Job Options"]) == null ? void 0 : _e["Candidate Title"]) && additionalOptions.push({
    id: "title_field",
    name: "Show title field"
  });
  ((_f = userPermissions == null ? void 0 : userPermissions["Job Options"]) == null ? void 0 : _f["Candidate Employee ID"]) && additionalOptions.push({
    id: "employee_id_field",
    name: "Show employee ID field"
  });
  ((_g = userPermissions == null ? void 0 : userPermissions["Job Options"]) == null ? void 0 : _g["Candidate Gender"]) && additionalOptions.push({
    id: "gender_field",
    name: "Show gender field"
  });
  ((_h = userPermissions == null ? void 0 : userPermissions["Job Options"]) == null ? void 0 : _h["Auto Rating"]) && additionalOptions.push({
    id: "auto_ratings",
    name: "Auto ratings"
  });
  ((_i = userPermissions == null ? void 0 : userPermissions["Job Options"]) == null ? void 0 : _i["Hide Rating Activity"]) && additionalOptions.push({
    id: "hide_rating_activity",
    name: "Hide ratings activities"
  });
  ((_j = userPermissions == null ? void 0 : userPermissions["Job Options"]) == null ? void 0 : _j["Send Job Expiry Reminder Email"]) && additionalOptions.push({
    id: "job_expiry_email_reminder",
    name: "Send Job Expiry Reminder Email"
  });
  return additionalOptions;
};
const localDefaults = (job) => {
  var _a, _b;
  const data = [
    {
      value: "en-au",
      label: "English/Australia"
    },
    {
      value: "en-ca",
      label: "English/Canada"
    },
    {
      value: "en-nz",
      label: "English/New Zealand"
    },
    {
      value: "en-sg",
      label: "English/Singapore"
    },
    {
      value: "en-uk",
      label: "English/United Kingdom"
    },
    {
      value: "en-us",
      label: "English/United States"
    },
    {
      value: "en-za",
      label: "English/South Africa"
    },
    {
      value: "fr-ca",
      label: "French/Canada"
    }
  ];
  const i = data == null ? void 0 : data.findIndex((x) => (job == null ? void 0 : job.locale) === (x == null ? void 0 : x.value));
  if (i >= 0) return { label: (_a = data[i]) == null ? void 0 : _a.label, value: (_b = data[i]) == null ? void 0 : _b.value };
  else return null;
};
const setClassificationIds = (job) => {
  var _a;
  return (_a = job == null ? void 0 : job.classifications) == null ? void 0 : _a.filter((child) => child == null ? void 0 : child.parent).map((sub) => sub == null ? void 0 : sub.id);
};
export const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
