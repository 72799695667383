"use strict";
import ReportApiActions from "./ReportApiActions";
export default class ReportServiceApi {
  constructor(accessibleEntities, user, reportServiceUrl, entity, jobStatusFeature) {
    this.reports = {
      getPreview: (body) => {
        return this.reportApiActions.getPreview(body);
      },
      getHistories: (body) => {
        return this.reportApiActions.getHistories(body);
      },
      saveReport: (body) => {
        return this.reportApiActions.saveReport(body);
      },
      getCustomReports: (body) => {
        return this.reportApiActions.getCustomReports(body);
      },
      getSavedReports: (body) => {
        return this.reportApiActions.getSavedReports(body);
      },
      update: (reportId, body) => {
        return this.reportApiActions.updateReport(reportId, body);
      },
      delete: (reportId) => {
        return this.reportApiActions.trashReport(reportId);
      },
      download: (body) => {
        return this.reportApiActions.downloadReport(body);
      },
      schemas: () => {
        return this.reportApiActions.getSchemas();
      }
    };
    this.systemReports = {
      options: (report, entity_id) => {
        return this.reportApiActions.getDropdownOptionsData(report, entity_id);
      },
      data: (params, report, groupBy, action) => {
        return this.reportApiActions.getData(params, report, groupBy, action);
      }
    };
    this.dashboard = {
      data: (type, includeSub) => {
        return this.reportApiActions.getDashboardData(type, includeSub);
      }
    };
    this.dataVisualisation = {
      getStatusActivities: (params) => {
        return this.reportApiActions.getDataVizData("status_activities", params);
      },
      getTimeToHire: (params) => {
        return this.reportApiActions.getDataVizData("time_to_hire", params);
      },
      getApplicationSources: (params) => {
        return this.reportApiActions.getDataVizData("application_sources", params);
      },
      getChartOptions: (params) => {
        return this.reportApiActions.getDataVizData("dropdown_options", params);
      }
    };
    const headers = {
      "api-authenticate": user.api_key,
      "Content-Type": "application/json"
    };
    this.currentUser = user;
    this.currentEntity = entity;
    this.accessibleEntities = accessibleEntities;
    this.reportApiActions = new ReportApiActions(accessibleEntities, headers, reportServiceUrl);
    this.jobStatusFeature = jobStatusFeature;
  }
}
