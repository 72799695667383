import React, { useRef, useEffect, useState, memo, useCallback } from 'react';
import moment from 'moment-timezone';
import Api from '../API';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableSkeleton from './TableSkeleton';
import NoJobs from './NoJobs';
import Pagination from '@mui/material/Pagination';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Popover from '@mui/material/Popover';
import { visuallyHidden } from '@mui/utils';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NotesPopover from './NotesPopover';
import { TableHeader, IRequestParams, ITableProps, IJob } from '../types';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { classes } from './useStyles';

const BASE_URL = window.location.origin;

const tablePadding = (density: string) => {
  if (density === 'Default') {
    return '22px';
  } else if (density === 'Compact') {
    return '15px';
  } else {
    return '8px';
  }
};

const tableHeadingAlignment = (name: string) => {
  switch (name) {
    case 'applications_count':
      return 'right';
    case 'state':
      return 'center';
    default:
      return 'left';
  }
};

const tableHeadingWidth = (name: string) => {
  switch (name) {
    case 'published_at':
      return '110px';
    case 'expires_at':
      return '110px';
    case 'created_at':
      return '110px';
    case 'title':
      return '200px';
    case 'applications_count':
      return '120px';
    default:
      return '150px';
  }
};

const tableHeadingTransform = (name: string) => {
  switch (name) {
    case 'state':
      return 'translateX(18px)';
    case 'status':
      return 'translateX(12px)';
    default:
      return '';
  }
};

function EnhancedTableHead(props: TableHeader) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    sortableColumns,
    handleScrollableEnter,
    handleScrollableExit,
    hovered,
    actionCellRef,
    titleCellRef,
    jobStatusFeature,
    userPermissions
  } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow id="table-header" sx={classes.tableHeaderRow}>
        <TableCell
          padding="checkbox"
          key="checkbox"
          sx={{
            position: 'sticky',
            left: 0,
            width: '60px !important',
            maxWidth: '60px',
            background: 'white',
            zIndex: '4'
          }}
        >
          <Checkbox
            id="select-all-checkbox"
            sx={{
              color: '#aaa !important',
              '&.Mui-checked': {
                color: '#15C7C0 !important'
              }
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all jobs'
            }}
          />
        </TableCell>
        {sortableColumns.map(
          (headCell, i) =>
            headCell.enabled &&
            !(!jobStatusFeature && headCell.id === 'status') &&
            (headCell.id === 'recruitment_ad_user'
              ? userPermissions?.recruitment_team_enabled
              : true) &&
            (headCell.id === 'entity' ? userPermissions?.Jobs?.['Show Job Sub-Entity'] : true) && (
              <TableCell
                key={headCell.id}
                align={'left'}
                padding={'normal'}
                id={`${headCell.id}-header`}
                ref={headCell.id === 'title' ? titleCellRef : null}
                sortDirection={orderBy === headCell.id ? (order as 'asc' | 'desc') : false}
                onMouseEnter={
                  headCell.id !== 'title' && headCell.id !== 'reference'
                    ? () => handleScrollableEnter()
                    : null
                }
                onMouseLeave={
                  headCell.id !== 'title' && headCell.id !== 'reference'
                    ? () => handleScrollableExit()
                    : null
                }
                sx={{
                  ...(i === 0
                    ? {
                        position: 'sticky',
                        left: '60px',
                        maxWidth: '96px',
                        width: '96px',
                        background: 'white',
                        zIndex: '3'
                      }
                    : {
                        position: i === 1 ? 'sticky' : 'relative',
                        left: i === 1 ? '156px' : 'auto',
                        minWidth: tableHeadingWidth(headCell.id),
                        background: 'white',
                        zIndex: i === 1 ? '2' : headCell.id === 'applications_count' ? '1' : '0',
                        textAlign: tableHeadingAlignment(headCell.id),
                        transform: tableHeadingTransform(headCell.id),
                        paddingLeft: headCell.id === 'state' ? '35px' : ''
                      }),
                  ...(headCell.id === 'title'
                    ? {
                        ...classes.titleHeaderCell,
                        ...(hovered ? classes.cellAnimate : {})
                      }
                    : {})
                }}
              >
                <TableSortLabel
                  id={`${headCell.id}-sort-label-archived`}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? (order as 'asc' | 'desc') : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        )}
        <TableCell
          padding="checkbox"
          key="actions"
          ref={actionCellRef}
          sx={{ ...classes.actionsHeaderCell, ...(hovered ? classes.cellAnimate : {}) }}
        />
      </TableRow>
    </TableHead>
  );
}

function ArchivedJobs({
  apiKey,
  sortableColumns,
  rowsPerPage,
  setRowsPerPage,
  search,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  filters,
  fetchUISwitch,
  page,
  setPage,
  filtersApplied,
  userPermissions,
  setSnackbarState,
  density,
  jobStatusFeature
}: ITableProps) {
  const [jobs, setJobs] = useState(null);
  const [selected, setSelected] = useState([]);
  const [maxPages, setMaxPages] = useState(1);
  const [maxJobs, setMaxJobs] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [canScroll, setCanScroll] = useState([false, true]);
  const [selectedJob, setSelectedJob] = useState({ id: null });
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [trashAlertOpen, setTrashAlertOpen] = useState(false);
  const [deleteJobs, setDeleteJobs] = useState([]);
  const [showMore, setShowMore] = useState([]);
  const [noteAnchorEl, setNoteAnchorEl] = useState(null);
  const allColumns = useRef(null);
  const actionCellRef = useRef(null);
  const titleCellRef = useRef(null);
  const noteRefs = useRef([]);
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (fetchUISwitch) return;
    getJobs(
      {
        'filter[archived]': true,
        page: page,
        limit: rowsPerPage,
        sort_by: orderBy,
        sort_direction: order,
        'q[search]': search ? search : undefined,
        'filter[location]': filters.location.map((location) => location.name),
        'filter[state]': filters.state.map((state) => state.value),
        'filter[entity_ids]': filters.entity.map((entity) => entity.id),
        'filter[assigned_user_ids]': filters.assigned_user.map((user) => user.id),
        'filter[recruitment_ad_user_ids]': filters.recruitment_ad_user.map((user) => user.id),
        'filter[job_status_ids]': filters.status.map((status) => status.id),
        'filter[start_published_at]':
          filters.published[0] && moment(filters.published[0]).format('YYYY-MM-DD'),
        'filter[end_published_at]':
          filters.published[1] && moment(filters.published[1]).format('YYYY-MM-DD'),
        'filter[start_expires_at]':
          filters.expiry[0] && moment(filters.expiry[0]).format('YYYY-MM-DD'),
        'filter[end_expires_at]':
          filters.expiry[1] && moment(filters.expiry[1]).format('YYYY-MM-DD')
      },
      apiKey
    );
  }, [search, page, rowsPerPage, orderBy, order, filters, fetchUISwitch]);

  async function getJobs(params: IRequestParams, apiKey: string) {
    setIsLoading(true);
    try {
      const data = await Api.getJobs(params, { 'X-api-authenticate': apiKey });
      setJobs([...data.res.jobs]);
      setMaxPages(parseInt(data.resHead['x-total-pages']));
      setMaxJobs(parseInt(data.resHead['x-total-count']));
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      firstLoad && setFirstLoad(false);
    }
  }

  const vpHeight = window.innerHeight;
  const arrowHeight = () => {
    return allColumns.current?.getBoundingClientRect().top + 100 > vpHeight / 2
      ? allColumns.current?.getBoundingClientRect().top + 100
      : allColumns.current?.getBoundingClientRect().bottom < vpHeight
      ? vpHeight / 2 - (vpHeight - allColumns.current?.getBoundingClientRect().bottom)
      : vpHeight / 2;
  };

  const handleScroll = () => {
    if (allColumns.current.scrollLeft < 10) {
      setCanScroll([false, true]);
    } else if (
      allColumns.current.scrollLeft >
      allColumns.current.scrollWidth - allColumns.current.clientWidth - 10
    ) {
      setCanScroll([true, false]);
    } else {
      setCanScroll([true, true]);
    }
  };

  const handleScrollLeft = () => {
    allColumns.current.scrollLeft > 360
      ? (allColumns.current.scrollLeft -= 300)
      : (allColumns.current.scrollLeft = 0);
  };

  const handleScrollRight = () => {
    allColumns.current.scrollWidth - allColumns.current.clientWidth > 360
      ? (allColumns.current.scrollLeft += 300)
      : (allColumns.current.scrollLeft =
          allColumns.current.scrollWidth - allColumns.current.clientWidth);
  };

  function isScrollable(element: HTMLElement) {
    return element && element.scrollWidth > element.clientWidth + 20;
  }

  const handleScrollableEnter = () => {
    if (isScrollable(allColumns.current)) setHovered(true);
  };

  const handleScrollableExit = () => {
    setHovered(false);
  };

  const handleRequestSort = (event: React.MouseEvent<Element, MouseEvent>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = jobs.map((job: IJob) => job.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<number> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleOpenNote = (event: React.MouseEvent<HTMLElement>) => {
    setNoteAnchorEl(event.currentTarget);
  };

  const handleCloseNote = () => {
    setNoteAnchorEl(null);
  };

  const handleShowMore = useCallback(
    (id: number) => {
      if (!showMore.includes(id)) {
        const copy = [...showMore];
        copy.push(id);
        setShowMore(copy);
      }
      const flag = showMore.indexOf(id);
      if (flag > -1) {
        const copy = [...showMore];
        copy.splice(flag, 1);
        setShowMore(copy);
      }
    },
    [showMore]
  );

  const handlePaginationChange = (event: React.ChangeEvent<HTMLInputElement>, newPage: number) => {
    setPage(newPage);
    scrollToTableTop();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const scrollToTableTop = () => {
    window.scrollTo({
      top: 40,
      behavior: 'smooth'
    });
  };

  const reloadJobs = () => {
    getJobs(
      {
        'filter[archived]': true,
        page: page,
        limit: rowsPerPage,
        sort_by: orderBy,
        sort_direction: order,
        'q[search]': search ? search : undefined,
        'filter[location]': filters.location.map((location) => location.name),
        'filter[state]': filters.state.map((state) => state.value),
        'filter[entity_ids]': filters.entity.map((entity) => entity.id),
        'filter[assigned_user_ids]': filters.assigned_user.map((user) => user.id),
        'filter[recruitment_ad_user_ids]': filters.recruitment_ad_user.map((user) => user.id),
        'filter[job_status_ids]': filters.status.map((status) => status.id),
        'filter[start_published_at]':
          filters.published[0] && moment(filters.published[0]).format('YYYY-MM-DD'),
        'filter[end_published_at]':
          filters.published[1] && moment(filters.published[1]).format('YYYY-MM-DD'),
        'filter[start_expires_at]':
          filters.expiry[0] && moment(filters.expiry[0]).format('YYYY-MM-DD'),
        'filter[end_expires_at]':
          filters.expiry[1] && moment(filters.expiry[1]).format('YYYY-MM-DD')
      },
      apiKey
    );
  };

  async function handleUnarchiveJobs(ids: Array<number>) {
    setIsLoading(true);
    try {
      const response = await Api.putUnrchiveJobs(
        { 'X-api-authenticate': apiKey },
        { job_ids: ids }
      );
      if (response.res?.job_ids?.failure?.length) {
        const message =
          response?.res?.job_ids?.success?.length > 0 || response?.res?.job_ids?.failure?.length > 1
            ? `${response?.res?.job_ids?.failure?.length} ${
                response?.res?.job_ids?.failure?.length > 1 ? 'jobs were' : 'job was'
              } unable to be unarchived. Jobs in the Draft state cannot be unarchived.`
            : 'Unable to unarchive job. Jobs in the Draft state cannot be unarchived.';
        setSnackbarState({
          message: message,
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbarState({
        message: 'Something went wrong. Please try again.',
        state: 'error'
      });
    } finally {
      setSelected([]);
      setSelectedJob({ id: null });
      setActionsAnchorEl(null);
      reloadJobs();
    }
  }

  async function handleTrashJobs(ids: Array<number>) {
    setIsLoading(true);
    try {
      await fetch(`${BASE_URL}/api/v4/jobs/delete`, {
        method: 'DELETE',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ job_ids: ids })
      });
      setDeleteJobs([]);
    } catch (error) {
      setSnackbarState({
        message: 'Something went wrong. Please try again.',
        state: 'error'
      });
    } finally {
      setSelected([]);
      setSelectedJob({ id: null });
      setActionsAnchorEl(null);
      reloadJobs();
    }
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const expiryDateColor = (date: string) => {
    const dayToExpired = (new Date(date).getTime() - Date.now()) / (1000 * 60 * 60 * 24);
    if (dayToExpired < 0) {
      return '#E37D7A';
    } else if (dayToExpired < 1) {
      return '#E6A052';
    } else {
      return '#333333';
    }
  };

  const renderTableCell = useCallback(
    (column, job, publishedAt, createdAt, expiresAt, expiryDateColor, labelId, notes) => {
      switch (column.id) {
        case 'reference':
          return (
            <TableCell
              key={job.reference + column.id}
              component="th"
              id={labelId}
              className="reference-cell"
              scope="row"
              sx={{
                position: 'sticky',
                left: '60px',
                wordBreak: 'break-word',
                background: 'white',
                paddingRight: '8px !important',
                fontSize: '14px',
                fontFamily: 'Source Sans Pro',
                zIndex: '3',
                '& a': {
                  wordBreak: 'break-word',
                  color: '#000000'
                },
                '& a:hover': {
                  textDecoration: 'none'
                }
              }}
            >
              <a
                href={`${BASE_URL}${job.job_link || '/admin/jobs/' + job.id}`}
                className="job-reference-link-archived"
              >
                {job.reference ? job.reference : '-'}
              </a>
            </TableCell>
          );
        case 'title':
          return (
            <TableCell
              key={job.reference + column.id}
              component="th"
              id={labelId}
              scope="row"
              sx={{
                ...classes.titleCell,
                ...(hovered ? classes.cellAnimate : {}),
                left: '156px !important'
              }}
              className="title-cell"
            >
              <a
                href={`${BASE_URL}${job.job_link || '/admin/jobs/' + job.id}`}
                className="job-title-link-archived"
              >
                {job.title ? job.title : '-'}
              </a>
            </TableCell>
          );
        case 'published_at':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '8px !important',
                width: '120px !important',
                lineHeight: '1.22'
              }}
              align="left"
              className="published-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job.published_at ? publishedAt : '-'}
            </TableCell>
          );
        case 'created_at':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '8px !important',
                width: '120px !important',
                lineHeight: '1.22'
              }}
              align="left"
              className="published-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job.created_at ? createdAt : '-'}
            </TableCell>
          );
        case 'expires_at':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '8px !important',
                width: '120px !important',
                lineHeight: '1.22',
                color: expiryDateColor(job.expires_at)
              }}
              align="left"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job.expires_at ? expiresAt : '-'}
            </TableCell>
          );
        case 'applications_count':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '43px !important',
                '& a': {
                  wordBreak: 'break-word',
                  color: '#000000'
                },
                '& a:hover': {
                  textDecoration: 'none'
                }
              }}
              align="right"
              className="applications-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              <a
                href={`${BASE_URL}/admin/jobs/${job.id}?applications=true`}
                className="application-count-link-archived"
              >
                {job.applications_count} ({job.open_applications_count})
              </a>
            </TableCell>
          );
        case 'state':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{ paddingRight: '8px !important' }}
              className="state-cell"
              align="center"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              <Box sx={{ ...classes.stateColumn, ...classes[job.state] }}>{job.state}</Box>
            </TableCell>
          );
        case 'status':
          if (!jobStatusFeature) return null;
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '8px !important'
              }}
              className={'status-cell'}
              align="center"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job.job_statuses ? (
                <Box
                  sx={{
                    ...classes.statusClamp,
                    ...classes.noDropdown,
                    '& .status-dropdown-full': {
                      ...classes.statusClamp['& .status-dropdown-full'],
                      ...classes.noDropdown['& .status-dropdown-full']
                    }
                  }}
                >
                  <Box className={'status-dropdown-full'} sx={classes.noDropdown}>
                    {job.job_statuses?.length > 0 ? job.job_statuses[0].label : ''}
                  </Box>
                  {job.job_statuses?.length > 0 ? job.job_statuses[0]?.label : ''}
                </Box>
              ) : (
                <Box sx={classes.noStatuses}>-</Box>
              )}
            </TableCell>
          );
        case 'entity':
          return userPermissions?.Jobs?.['Show Job Sub-Entity'] ? (
            <TableCell
              key={job.reference + column.id}
              sx={{
                paddingRight: '8px !important',
                '& a': {
                  wordBreak: 'break-word',
                  color: '#000000'
                },
                '& a:hover': {
                  textDecoration: 'none'
                }
              }}
              align="left"
              className="entity-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              <a href={`${BASE_URL}${job.entity_link}`} className="job-entity-link-archived">
                {job.entity ? job.entity : '-'}
              </a>
            </TableCell>
          ) : null;
        case 'location':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{ paddingRight: '8px !important' }}
              align="left"
              className="location-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job.location ? job.location : '-'}
            </TableCell>
          );
        case 'assigned_user':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{ paddingRight: '8px !important' }}
              align="left"
              className="location-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job?.assigned_user ? job.assigned_user : '-'}
            </TableCell>
          );
        case 'recruitment_ad_user':
          return userPermissions?.recruitment_team_enabled ? (
            <TableCell
              key={job.reference + column.id}
              sx={{ paddingRight: '8px !important' }}
              align="left"
              className="location-cell"
              onMouseEnter={() => handleScrollableEnter()}
              onMouseLeave={() => handleScrollableExit()}
            >
              {job?.recruitment_ad_user ? job.recruitment_ad_user : '-'}
            </TableCell>
          ) : null;
        case 'notes':
          return (
            <TableCell
              key={job.reference + column.id}
              sx={{ minWidth: '300px', paddingRight: '8px !important' }}
              align="left"
              className="entity-cell"
              onMouseEnter={handleScrollableEnter}
              onMouseLeave={handleScrollableExit}
              id={`notes-${labelId}`}
            >
              <Box
                sx={!showMore.includes(job.id) ? classes.noteColumn : {}}
                ref={(element) => (noteRefs.current[job.id] = element)}
              >
                {notes.map((note: { body: string; noted_by: string }, note_index: number) => {
                  const listId = `enhanced-table-checkbox-${job.id}-${note_index}`;
                  return (
                    <Box key={`note-show-${listId}`} style={{ margin: '1em 0' }}>
                      <Box key={`note-${listId}`}>{note?.body}</Box>
                      <Box>{note?.noted_by}</Box>
                    </Box>
                  );
                })}
              </Box>
              {noteRefs.current[job.id]?.offsetHeight >= 60 &&
                noteRefs.current[job.id]?.scrollHeight != 81 && (
                  <Box
                    key={`show-${labelId}`}
                    sx={classes.link}
                    onClick={() => handleShowMore(job.id)}
                  >
                    {showMore.includes(job.id) ? 'Show less' : 'Show more'}
                  </Box>
                )}
            </TableCell>
          );
        default:
          return <TableCell></TableCell>;
      }
    },
    [classes, handleScrollableEnter, handleShowMore, hovered, showMore]
  );

  return (
    <Box id="table-container" sx={{ width: '100%' }}>
      <Backdrop sx={{ color: '#83ECE7', zIndex: '99999' }} open={isLoading && !firstLoad}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none ' }}>
        {isLoading && firstLoad ? (
          <>
            <TableContainer
              id="table-scrollable"
              sx={classes.tableContainer}
              onScroll={handleScroll}
            >
              <Table
                stickyHeader
                sx={{
                  ...classes.tableWrapper,
                  minWidth: 750,
                  '& tr': { '& td, th': { padding: `${tablePadding(density)} 18px` } }
                }}
                aria-labelledby="tableTitle"
                size={'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={jobs && jobs.length}
                  sortableColumns={sortableColumns}
                  handleScrollableEnter={handleScrollableEnter}
                  handleScrollableExit={handleScrollableExit}
                  hovered={hovered}
                  actionCellRef={actionCellRef}
                  titleCellRef={titleCellRef}
                  jobStatusFeature={jobStatusFeature}
                  userPermissions={userPermissions}
                />
              </Table>
            </TableContainer>
            <TableSkeleton />
          </>
        ) : (
          <>
            <TableContainer
              id="table-scrollable"
              ref={allColumns}
              sx={classes.tableContainer}
              onScroll={handleScroll}
            >
              <Box
                onMouseEnter={() => handleScrollableEnter()}
                onMouseLeave={() => handleScrollableExit()}
                onClick={() => handleScrollLeft()}
                sx={{
                  ...classes.leftArrow,
                  ...(hovered && canScroll[0] ? classes.showArrows : {}),
                  left: `${titleCellRef.current?.getBoundingClientRect().right}px`,
                  top: `${arrowHeight()}px`
                }}
                id="left-arrow"
              >
                <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
              </Box>
              <Box
                onMouseEnter={() => handleScrollableEnter()}
                onMouseLeave={() => handleScrollableExit()}
                onClick={() => handleScrollRight()}
                sx={{
                  ...classes.rightArrow,
                  ...(hovered && canScroll[1] ? classes.showArrows : {}),
                  left: `${actionCellRef.current?.getBoundingClientRect().left - 40}px`,
                  top: `${arrowHeight()}px`
                }}
                id="right-arrow"
              >
                <KeyboardArrowRightIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
              </Box>
              <Table
                stickyHeader
                sx={{
                  ...classes.tableWrapper,
                  minWidth: 750,
                  '& tr': {
                    '& td, th': { padding: `${tablePadding(density)} 18px`, textTransform: 'none' }
                  }
                }}
                aria-labelledby="tableTitle"
                size={'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={jobs && jobs.length}
                  sortableColumns={sortableColumns}
                  handleScrollableEnter={handleScrollableEnter}
                  handleScrollableExit={handleScrollableExit}
                  hovered={hovered}
                  actionCellRef={actionCellRef}
                  titleCellRef={titleCellRef}
                  jobStatusFeature={jobStatusFeature}
                  userPermissions={userPermissions}
                />
                <TableBody id="table-body" sx={classes.tableBody}>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                          rows.slice().sort(getComparator(order, orderBy)) */}
                  {jobs &&
                    jobs.map((job: IJob, index: number) => {
                      const jobTimeZone = job.time_zone ? job.time_zone : timeZoneString;
                      const isItemSelected = isSelected(job.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const notes = job.job_notes ? job.job_notes : [];
                      const publishedAt =
                        job.published_at &&
                        moment
                          .tz(job.published_at, jobTimeZone)
                          .format('DD MMM YYYY,-h:mma z')
                          .split('-')
                          .map((line) => <div key={`${job.id}-${line}`}>{line}</div>);
                      const createdAt =
                        job.created_at &&
                        moment
                          .tz(job.created_at, jobTimeZone)
                          .format('DD MMM YYYY,-h:mma z')
                          .split('-')
                          .map((line) => <div key={`${job.id}-${line}`}>{line}</div>);
                      const expiresAt =
                        job.expires_at &&
                        moment
                          .tz(job.expires_at, jobTimeZone)
                          .format('DD MMM YYYY,-h:mma z')
                          .split('-')
                          .map((line) => <div key={`${job.id}-${line}`}>{line}</div>);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={job.id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            padding="checkbox"
                            sx={{
                              position: 'sticky',
                              left: 0,
                              width: '60px !important',
                              maxWidth: '60px',
                              background: 'white',
                              zIndex: 3
                            }}
                          >
                            <Checkbox
                              className="individual-checkbox"
                              onClick={(event) => handleClick(event, job.id)}
                              sx={{
                                color: '#aaa !important',
                                '&.Mui-checked': {
                                  color: '#15C7C0 !important'
                                }
                              }}
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                            />
                          </TableCell>
                          {sortableColumns.map((column) => {
                            if (column.enabled) {
                              return renderTableCell(
                                column,
                                job,
                                publishedAt,
                                createdAt,
                                expiresAt,
                                expiryDateColor,
                                labelId,
                                notes
                              );
                            }
                          })}
                          <TableCell
                            sx={{ ...classes.actionsCell, ...(hovered ? classes.cellAnimate : {}) }}
                            align="right"
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                '& svg': {
                                  fontSize: '20px !important'
                                }
                              }}
                            >
                              {userPermissions?.Trash?.['Trash Jobs'] && (
                                <IconButton
                                  onClick={() => {
                                    setDeleteJobs([job.id]);
                                    setTrashAlertOpen(true);
                                  }}
                                  className="trash-button-archived"
                                >
                                  <DeleteIcon sx={classes.iconRed} />
                                </IconButton>
                              )}
                              {(userPermissions?.Jobs?.['Create / Edit Jobs'] ||
                                userPermissions?.Jobs?.['Archive Jobs']) && (
                                <IconButton
                                  className="actions-button-archived"
                                  onClick={(e) => {
                                    setActionsAnchorEl(e.currentTarget);
                                    setSelectedJob({ id: job.id });
                                  }}
                                >
                                  <MoreVertIcon sx={classes.iconGray} />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Popover
              id={actionsAnchorEl ? 'actions-menu-popover' : undefined}
              sx={classes.actionsMenu}
              open={Boolean(actionsAnchorEl)}
              anchorEl={actionsAnchorEl}
              onClose={() => {
                setActionsAnchorEl(null);
                setSelectedJob({ id: null });
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              {userPermissions?.Jobs?.['Create / Edit Jobs'] && (
                <>
                  <span
                    onClick={() => {
                      window.location.href = BASE_URL + `/admin/jobs/${selectedJob.id}/edit`;
                    }}
                    className="edit-job-button-archived"
                  >
                    Edit
                  </span>
                  <span
                    onClick={() => {
                      window.location.href = BASE_URL + `/admin/jobs/${selectedJob.id}/clone`;
                    }}
                    className="clone-job-button-archived"
                  >
                    Clone
                  </span>
                </>
              )}
              {userPermissions?.Jobs?.['Archive Jobs'] && (
                <span
                  onClick={() => {
                    handleUnarchiveJobs([selectedJob.id]);
                  }}
                  className="unarchive-job-button"
                >
                  Unarchive
                </span>
              )}
              {userPermissions?.['Job Options']?.['View Job Notes'] && (
                <span
                  onClick={(e) => handleOpenNote(e, selectedJob.id)}
                  className="add-note-button-archived"
                >
                  Add note
                </span>
              )}
            </Popover>
            {(userPermissions?.Jobs?.['Archive Jobs'] ||
              userPermissions?.Trash?.['Trash Jobs']) && (
              <Box
                id="selected-tooltip"
                sx={{
                  ...classes.selectedTooltip,
                  ...(selected.length > 0 ? classes.fadeIn : classes.fadeOut)
                }}
              >
                <Box id="selected-tooltip-text" sx={classes.selectedTooltipText}>
                  {selected.length} selected
                </Box>
                <Box sx={classes.selectedTooltipButtonsContainer}>
                  {userPermissions?.Jobs?.['Archive Jobs'] && (
                    <Button
                      variant="contained"
                      sx={classes.selectedTooltipButton}
                      onClick={() => {
                        handleUnarchiveJobs(selected);
                      }}
                    >
                      Unarchive
                    </Button>
                  )}
                  {userPermissions?.Trash?.['Trash Jobs'] && (
                    <Button
                      variant="contained"
                      sx={classes.selectedTooltipButton}
                      onClick={() => {
                        setDeleteJobs(selected);
                        setTrashAlertOpen(true);
                      }}
                    >
                      Trash
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            {jobs?.length === 0 && (
              <NoJobs searchOrFilterApplied={!!search || !!filtersApplied || isLoading} />
            )}
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '16px'
          }}
        >
          <Box sx={classes.rowSelectContainer}>
            <span>Rows per page: </span>
            <FormControl>
              <Select
                id="rows-per-page"
                sx={classes.rowSelect}
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                displayEmpty
                MenuProps={{ sx: classes.paginationMenuItems }}
              >
                <MenuItem id="first-rpp-item" value={10}>
                  10
                </MenuItem>
                <MenuItem id="second-rpp-item" value={20}>
                  20
                </MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={classes.paginationContainer}>
            <Pagination
              id="pagination-menu"
              count={maxPages}
              page={page}
              defaultPage={Math.ceil(maxPages / 2)}
              siblingCount={0}
              onChange={handlePaginationChange}
              sx={classes.pagination}
            />
            <span id="total-jobs">{maxJobs} Total</span>
          </Box>
        </Box>
        <GenericDialog
          isDialogOpen={trashAlertOpen}
          setDialogOpen={setTrashAlertOpen}
          title={deleteJobs.length > 1 ? 'Trash jobs?' : 'Trash job?'}
          description={
            deleteJobs.length > 1
              ? 'Are you sure want to trash these jobs?'
              : 'Are you sure want to trash this job?'
          }
          buttonCallback={() => {
            setTrashAlertOpen(false);
            handleTrashJobs(deleteJobs);
            setDeleteJobs([]);
          }}
          callbackLoading={false}
          buttonText="Trash"
          url=""
        />
      </Paper>
      <NotesPopover
        setJobs={setJobs}
        anchorEl={noteAnchorEl}
        handleCloseNote={handleCloseNote}
        selectedJobId={selectedJob.id}
        apiKey={apiKey}
        setSnackbarState={setSnackbarState}
      />
    </Box>
  );
}

export default memo(ArchivedJobs);
