import React, { Dispatch, RefObject, SetStateAction } from 'react';
import { useQuery } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, IEmailTemplateList } from '../../types';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { IError } from '../../../SmartForms/types';
import VirtualizeGrouped from '../../../Components/CustomUIElements/VirtualizedGroupedAutocomplete';
import { styles } from '../../styles';

export default function EmailsDropdown({
  apiKey,
  selectedEmailTemplate,
  setSelectedEmailTemplate,
  setBody,
  dispatch,
  required,
  dropdownLabel,
  error,
  innerRef,
  type,
  endpoint
}: {
  apiKey: string;
  selectedEmailTemplate: string | null;
  setSelectedEmailTemplate: Dispatch<SetStateAction<string | null>>;
  setBody: Dispatch<SetStateAction<string>>;
  dispatch: Dispatch<ApplicationAction>;
  required?: boolean;
  dropdownLabel?: string;
  error?: string;
  innerRef?: RefObject<HTMLDivElement>;
  type?: string;
  endpoint?: Promise<{ res: IEmailTemplateList }>;
}) {
  const queryKey = (() => {
    if (endpoint) {
      return ['email templates', endpoint];
    } else if (type) {
      return ['email templates', type];
    } else {
      return ['email templates'];
    }
  })();

  const { data: emailTemplates, isLoading: loadingEmailTemplates } = useQuery({
    queryKey,
    queryFn: async () => {
      const { res } = await (endpoint || Api.getEmailLetterheadGroupedEmailTemplates(apiKey, type));
      const emailTemplates: string[] = [];
      res.email_templates.forEach((group: [string, { id: number; name: string }[]]) => {
        group[1].forEach((template) => {
          emailTemplates.push(`${template.id} - ${template.name}@-*@${group[0]}`);
        });
      });
      dispatch({ type: 'SET_EMAIL_PLACEHOLDERS', payload: res.placeholders });
      return emailTemplates;
    },
    onError: (error: IError) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: error.errors.join(', '), state: 'error' }
      })
  });

  return (
    <VirtualizeGrouped
      options={emailTemplates || []}
      value={selectedEmailTemplate}
      onChange={(value) => {
        !value && setBody('');
        setSelectedEmailTemplate(value);
      }}
      styles={{
        ...sharedClasses.formAutocomplete,
        marginRight: '1px',
        '&.MuiAutocomplete-root': {
          '& .MuiOutlinedInput-root': {
            paddingRight: '16px !important'
          }
        }
      }}
      label={dropdownLabel || 'Choose email template'}
      loading={loadingEmailTemplates}
      loadingText="Loading email templates..."
      required={required}
      error={error}
      errorStyles={styles.errorMessage}
      innerRef={innerRef}
    />
  );
}
