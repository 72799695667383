import React from 'react';

export default function SourcesIconSVG() {
return (<svg id="MDI_bullhorn-outline" data-name="MDI / bullhorn-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
<g id="Boundary" fill="#084d6d" stroke="rgba(0,0,0,0)" stroke-width="1" opacity="0">
  <rect width="24" height="24" stroke="none"/>
  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
</g>
<path id="Path_bullhorn-outline" data-name="Path / bullhorn-outline" d="M12.784,8.314H4.157A2.157,2.157,0,0,0,2,10.471v4.314a2.157,2.157,0,0,0,2.157,2.157H5.235v4.314a1.078,1.078,0,0,0,1.078,1.078H8.471a1.078,1.078,0,0,0,1.078-1.078V16.941h3.235l5.392,4.314V4L12.784,8.314m3.235,8.2-2.157-1.725H4.157V10.471h9.706L16.02,8.745V16.51m7.01-3.882a4.763,4.763,0,0,1-2.7,4.314V8.314A4.805,4.805,0,0,1,23.029,12.627Z" transform="translate(-0.515 -1.167)" fill="#084d6d"/>
</svg>)
}
