import React, { Dispatch } from 'react';
import {
  GenericTableAction,
  GenericTableState
} from '../../NewUI/Components/GenericTable/genericTableReducer';
import AddNoteWithSubjectLine from '../../NewUI/Components/GenericTable/TablePopovers/AddNoteWithSubjectLine';
import MoreOptionsList from '../../NewUI/Components/GenericTable/TablePopovers/MoreOptionsList';
import GenericDialog from '../../NewUI/Components/Modals/GenericDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import callAPI from '../../NewUI/Components/Utilities/callAPI';
import { ISnackbarInput } from '../../NewUI/Components/CustomUIElements/StyledSnackbar';

const CandidatePoolsActionComponents = ({
  actionsState,
  dispatch,
  setSnackbarState
}: {
  actionsState: GenericTableState['tableState']['actions'];
  dispatch: Dispatch<GenericTableAction>;
  setSnackbarState: (snackbarState: ISnackbarInput) => void;
}) => {
  const queryClient = useQueryClient();
  const { mutate: handleDeletePool, isLoading: deletingPool } = useMutation({
    mutationFn: () =>
      callAPI(`/api/v4/candidate_pools/${actionsState?.selectedItem?.id}`, null, 'DELETE'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['candidatePools'] });
      dispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'deletePool' });
    },
    onError: () =>
      setSnackbarState({
        message: `There was an error deleting the candidate pool`,
        state: 'error'
      })
  });
  return (
    <>
      <AddNoteWithSubjectLine
        dispatch={dispatch}
        isOpen={actionsState?.isOpen?.addNote}
        anchorEl={actionsState?.anchorEl}
        itemId={actionsState?.selectedItem?.id || 0}
        url="/api/v4/candidate_pools/add_note"
        queryKey="candidatePools"
      />
      <MoreOptionsList
        options={[
          {
            id: 'deletePool',
            label: 'Delete',
            action: () => {
              dispatch({
                type: 'SELECT_ACTION_ITEM',
                payload: {
                  item: actionsState?.selectedItem,
                  name: 'deletePool',
                  anchorEl: null
                }
              });
            }
          }
        ]}
        actionsState={actionsState}
        dispatch={dispatch}
        label="candidate-pools"
      />
      <GenericDialog
        isDialogOpen={actionsState?.isOpen?.deletePool}
        setDialogOpen={() => dispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'deletePool' })}
        title="Delete Candidate Pool"
        description={`Are you sure you want to delete the ${actionsState?.selectedItem?.title} candidate pool?`}
        buttonCallback={() => handleDeletePool()}
        callbackLoading={deletingPool}
        url=""
        buttonText="Delete"
      />
    </>
  );
};

export default CandidatePoolsActionComponents;
