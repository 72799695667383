import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import SelectedApplicants from '../SelectedApplicants';
import { IExtendedActionModalProps } from '../../types';
import { classes } from '../styles';

export default function ExportPDF({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  filters
}: IExtendedActionModalProps) {
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async () => {
    setIsSending(true);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/export_pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        body: JSON.stringify({
          application_ids: globalSelected.map((app) => app.id)
        })
      });
      if (response.ok) {
        setModalsOpen((prev) => ({ ...prev, exportToPdf: false }));
        setSnackbar({
          message:
            'PDFs are being generated. You will receive a download link via email when they are ready.',
          state: 'success'
        });
      }
    } catch (error) {
      setSnackbar({
        message: 'An error occurred while exporting to PDF. Please try again.',
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Modal
      open={modalsOpen.exportToPdf}
      onClose={() => setModalsOpen((prev) => ({ ...prev, exportToPdf: false }))}
      aria-labelledby="export-pdf-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon
          onClick={() => setModalsOpen((prev) => ({ ...prev, exportToPdf: false }))}
          sx={classes.closeIcon}
        />
        <Box id="export-pdf-title" sx={classes.actionModalHeader}>
          Export to PDF
        </Box>
        <Box
          sx={classes.modalContent}
          id="export-pdf-modal-content"
          aria-label="Export PDF Modal Content"
        >
          <Box sx={{ marginLeft: '15px' }}>
            <h3>Candidates</h3>
            <SelectedApplicants
              globalSelected={globalSelected}
              handleDeselectCandidate={handleDeselectCandidate}
              closeModalCallback={() => setModalsOpen((prev) => ({ ...prev, exportToPdf: false }))}
              filters={filters}
            />
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <Button
            id="close-export-pdf-button"
            sx={classes.modalCancelButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, exportToPdf: false }))}
          >
            Cancel
          </Button>
          <Button
            id="submit-export-pdf-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '128px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSending ? <CircularProgress size={20} color="inherit" /> : 'Export to PDF'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
