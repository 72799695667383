"use strict";
export const classes = {
  iconButtonSelected: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D",
      transform: "translateY(1px)"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  sortableField: {
    display: "flex",
    alignItems: "center",
    width: "204px"
  },
  sortableSwitchLabels: {
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "4px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  switchActive: {
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    }
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    },
    "& :hover": {
      "& .MuiSwitch-track": {
        backgroundColor: "#000 !important"
      }
    }
  },
  switchLabels: {
    justifyContent: "space-between",
    width: "200px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  popover: {
    zIndex: 12
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    marginTop: 2,
    background: "white",
    borderRadius: "4px"
  },
  tableSettingsHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: 1,
    paddingLeft: "4px"
  },
  filterPopoverContent: {
    background: "white",
    width: "272px",
    maxHeight: "500px",
    overflowY: "scroll",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    marginTop: 2,
    position: "relative",
    borderRadius: "4px",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  filterPopoverHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    backgroundColor: "white",
    position: "sticky",
    top: "0px",
    zIndex: 99,
    paddingTop: "26px",
    paddingBottom: "2px",
    boxShadow: "0px 6px 10px 7px #ffffff"
  },
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  clearButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#939393",
    backgroundColor: "rgba(147, 147, 147, 0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "rgba(147, 147, 147, 0.24)",
      boxShadow: "none"
    },
    "& .MuiButton-endIcon svg": {
      fontSize: "10px"
    }
  },
  applyButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 23px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  }
};
