import React from 'react';
import { Box } from '@mui/material';
import { classes } from '../../styles';
import StyledDropdown from '../../../SharedComponents/Inputs/StyledDropdown';
import PositionTitleInput from '../../../SharedComponents/Inputs/PositionTitle';
import Inclusions from '../../Inputs/Inclusions';
import DynamicFooter from '../../../SharedComponents/Footers/DynamicFooter';
import { questionTypes } from '../../../SharedComponents/sharedConfig';
import { defaultInclusions } from '../../config';

const InputTab = ({
  editablePositionTitle,
  setEditablePositionTitle,
  questionType,
  setQuestionType,
  inclusions,
  setInclusions,
  requestQuestions
}: {
  editablePositionTitle: string;
  setEditablePositionTitle: React.Dispatch<React.SetStateAction<string>>;
  questionType: string;
  setQuestionType: React.Dispatch<React.SetStateAction<string>>;
  inclusions: typeof defaultInclusions;
  setInclusions: React.Dispatch<React.SetStateAction<typeof defaultInclusions>>;
  requestQuestions: () => void;
}) => {
  return (
    <>
      <Box sx={classes.aiRow}>
        <Box sx={classes.fieldSubHeader}>Job title</Box>
        <PositionTitleInput
          positionTitle={editablePositionTitle}
          onChange={setEditablePositionTitle}
        />
      </Box>
      <Box sx={classes.aiRow}>
        <Box sx={classes.fieldSubHeader}>Question type</Box>
        <StyledDropdown
          id="question-type-dropdown"
          value={questionType}
          onChange={setQuestionType}
          options={questionTypes}
        />
      </Box>
      <Box sx={classes.aiRow}>
        <Box sx={classes.fieldSubHeader}>Base questions on the following information:</Box>
        <Inclusions inclusions={inclusions} setInclusions={setInclusions} />
      </Box>
      <DynamicFooter
        primaryButtonText="Generate"
        primaryButtonID="generate-questions-button"
        disablePrimaryButton={
          !Object.keys(inclusions)
            .map((key) => inclusions[key as keyof typeof inclusions].value)
            .includes(true)
        }
        handlePrimaryButtonClick={requestQuestions}
        felixMessage={
          <>
            I can help write some <span>interview questions</span> based on the candidate’s
            application!
          </>
        }
        felixMessageMaxWidth="400px"
      />
    </>
  );
};

export default InputTab;
