import React, { useState, ChangeEvent, Dispatch } from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from '../SharedComponents/styles';
import { EngageAction, EngageState } from '../reducer';
import { handleAPIRequest } from '../../NewUI/Components/Utilities/handleAPIRequest';
import { ISnackbarInput } from '../../NewUI/Components/CustomUIElements/StyledSnackbar';

const EngageSearchBar = ({
  engageState,
  dispatch
}: {
  engageState: EngageState;
  dispatch: Dispatch<EngageAction>;
}) => {
  const [showSearchTooltip, setShowSearchTooltip] = useState<boolean>(false);
  const [tlpMatchText, setTlpMatchText] = useState<string>('Matching TLP');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleQuerySearch = (query: string) => {
    handleAPIRequest({
      url: `/api/v4/searches/generate_boolean_query`,
      body: { query },
      method: 'POST',
      setLoaderState: setIsLoading,
      setSnackbarState: (snackbarState: ISnackbarInput) =>
        dispatch({ type: 'SET_SNACKBAR', payload: snackbarState }),
      successMessage: { show: false },
      onSuccess: (response: { boolean_query: string }) => {
        const booleanQuery = response?.boolean_query;
        dispatch({ type: 'CONFIRM_STRING_MATCH_SEARCH', payload: booleanQuery });
      }
    });
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isLoading) return;
    setShowSearchTooltip(!!event.target.value);
    dispatch({ type: 'SET_SEARCH_QUERY', payload: event.target.value });
  };

  const handleSearch = () => {
    if (isLoading || !engageState.searchQuery) return;
    setShowSearchTooltip(false);
    handleQuerySearch(engageState.searchQuery);
  };

  const searchInputAdornment = () => {
    if (engageState.searchType === 'string_match' && !isLoading) {
      return (
        <InputAdornment
          sx={classes.searchAdornment}
          position="start"
          onClick={() => dispatch({ type: 'CLEAR_SEARCH' })}
          id="clear-tlp-search-button"
        >
          <CloseIcon sx={{ marginBottom: '3.5px' }} />
        </InputAdornment>
      );
    } else {
      return (
        <InputAdornment
          sx={classes.searchAdornment}
          position="start"
          onClick={handleSearch}
          id="search-candidates-button"
        >
          {isLoading ? (
            <CircularProgress size={31} color="inherit" />
          ) : (
            <SearchIcon id="search-candidates-button" />
          )}
        </InputAdornment>
      );
    }
  };

  return (
    <Box sx={classes.searchContainer} id="search-candidates-box">
      <FormControl fullWidth>
        <OutlinedInput
          id="candidate-search-input"
          sx={{ ...classes.searchInput, ...(!isLoading && { cursor: 'pointer' }) }}
          placeholder={'Search all of your candidates'}
          value={
            engageState.searchType === 'tlp_match'
              ? engageState.tlpMatchModalState.selectedTLP.name
              : engageState.searchQuery
          }
          onChange={handleQueryChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleSearch();
            }
          }}
          disabled={engageState.searchType === 'tlp_match'}
          startAdornment={
            engageState.searchType === 'tlp_match' ? (
              <InputAdornment position="start">
                <Box
                  sx={classes.tlpMatch}
                  onMouseEnter={() => setTlpMatchText('Clear TLP Search')}
                  onMouseLeave={() => setTlpMatchText('Matching TLP')}
                  onClick={() => dispatch({ type: 'CLEAR_SEARCH' })}
                  id="clear-tlp-search-button"
                >
                  {tlpMatchText}
                </Box>
              </InputAdornment>
            ) : (
              searchInputAdornment()
            )
          }
          endAdornment={
            engageState.searchType !== 'none' && (
              <InputAdornment position="end">
                <IconButton
                  sx={classes.editBooleanIcon}
                  onClick={() => dispatch({ type: 'EDIT_BOOLEAN_QUERY' })}
                >
                  <SettingsIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </FormControl>
      {showSearchTooltip && (
        <Box sx={classes.searchTooltip}>
          Press enter, or click the search icon to find candidates
        </Box>
      )}
    </Box>
  );
};

export default EngageSearchBar;
