import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { HtmlEditorFonts } from './HtmlEditorFonts';
import { Box } from '@mui/material';
import { IStyle } from '../../../ThemeContext/ThemeObject';

export default function HtmlEditor({
  body,
  onChange,
  onBlur,
  maxHeight,
  readonlyEditor
}: {
  body?: string;
  onChange?: (body: string) => void;
  onBlur?: (value: string) => void;
  maxHeight?: number;
  readonlyEditor?: null | { body: string; styling: IStyle };
}) {
  const editorRef = useRef(null);
  const onEdit = () => {
    if (editorRef.current) {
      onChange(editorRef.current.getContent());
    }
  };

  const onBlurHandler = () => {
    if (editorRef.current && onBlur) {
      onBlur(editorRef.current.getContent());
    }
  };

  if (readonlyEditor)
    return (
      <Box sx={readonlyEditor.styling}>
        <Editor initialValue={readonlyEditor.body} disabled={true} />
      </Box>
    );

  return (
    <Editor
      onBlur={onBlurHandler}
      onChange={onEdit}
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={body}
      init={{
        menubar: false,
        statusbar: false,
        paste_data_images: false,
        min_height: 400,
        max_height: maxHeight || 1000,
        autoresize_bottom_margin: 0,
        relative_urls: true,
        remove_script_host: false,
        browser_spellcheck: true,
        convert_urls: false,
        plugins: [
          'advlist',
          'autolink',
          'autoresize',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'media',
          'table',
          'code',
          'powerpaste'
        ],
        toolbar1:
          'bold italic underline forecolor backcolor | fontfamily fontsize blocks | undo redo ',
        toolbar2:
          'alignleft aligncenter alignright alignjustify bullist numlist outdent indent | link image removeformat table | code preview',
        powerpaste_word_import: 'prompt',
        powerpaste_html_import: 'prompt',
        content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px } ${HtmlEditorFonts.join(
          ' '
        )}`,
        contextmenu: false,
        font_family_formats:
          'Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Nexa=nexa; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats'
      }}
    />
  );
}
