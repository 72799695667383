import React from 'react';

export default function StatusTypeOcpm(status: string) {
  switch (status) {
    case 'clicked':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="16 16 22 22">
          <g id="Group_579" data-name="Group 579" transform="translate(-899 -710)">
            <g id="MDI_cursor-default-click" data-name="MDI / cursor-default-click" transform="translate(912.333 723.833)">
              <g id="Boundary" fill="#084d6d" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
                <rect width="26.334" height="26.334" stroke="none"/>
                <rect x="0.5" y="0.5" width="25.334" height="25.334" fill="none"/>
              </g>
              <path id="Path_cursor-default-click" data-name="Path / cursor-default-click" d="M11.612,9.535a.834.834,0,0,0-.834.834V22.933a.834.834,0,0,0,.834.834.845.845,0,0,0,.527-.176l2.1-1.7,1.821,3.972a.835.835,0,0,0,.757.472.558.558,0,0,0,.362-.088l3.028-1.4a.815.815,0,0,0,.4-1.108L18.766,19.75l2.644-.494a.965.965,0,0,0,.472-.285.824.824,0,0,0-.132-1.185l-9.59-8.065-.011.011a.829.829,0,0,0-.538-.2m4.652,1.437V8.778H21.75v2.194H16.264M14.98,5.223l3.105-3.105,1.547,1.547L16.528,6.77,14.98,5.223M10.778,0h2.194V5.486H10.778V0M4.118,16.086,7.223,12.98,8.77,14.528,5.665,17.633,4.118,16.086m0-12.421L5.665,2.118,8.77,5.223,7.223,6.77,4.118,3.665m3.369,7.308H2V8.778H7.486v2.194" transform="translate(1.292)" fill="#084d6d"/>
            </g>
          </g>
        </svg>
      );
    case 'delivered':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="12 14 28 28">
          <g id="Group_577" data-name="Group 577" transform="translate(-415 -710)">
            <g id="MDI_account-multiple-check" data-name="MDI / account-multiple-check" transform="translate(426.592 721.592)">
              <g id="Boundary" fill="#084d6d" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
                <rect width="29.815" height="29.815" stroke="none"/>
                <rect x="0.5" y="0.5" width="28.815" height="28.815" fill="none"/>
              </g>
              <path id="Path_account-multiple-check" data-name="Path / account-multiple-check" d="M23.361,19.908v2.485H8.454V19.908s0-4.969,7.454-4.969,7.454,4.969,7.454,4.969M19.634,8.727a3.727,3.727,0,1,0-3.727,3.727,3.727,3.727,0,0,0,3.727-3.727m3.975,6.286a6.957,6.957,0,0,1,2.236,4.895v2.485h3.727V19.908s0-4.286-5.963-4.895M22.119,5a3.615,3.615,0,0,0-1.106.174,6.211,6.211,0,0,1,0,7.106,3.615,3.615,0,0,0,1.106.174,3.727,3.727,0,1,0,0-7.454M8.876,9.87l1.441,1.752-5.9,5.9L1,13.8l1.441-1.441,1.975,1.963L8.876,9.87" transform="translate(0.242 1.211)" fill="#084d6d"/>
            </g>
          </g>
        </svg>
      );
    case 'opened':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="16 16 22 22">
          <g id="Group_578" data-name="Group 578" transform="translate(-657 -710)">
            <g id="MDI_email-open" data-name="MDI / email-open" transform="translate(671.5 724.5)">
              <g id="Boundary" fill="#084d6d" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
                <rect width="24" height="24" stroke="none"/>
                <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
              </g>
              <path id="Path_email-open" data-name="Path / email-open" d="M4,8l8,5,8-5h0L12,3,4,8H4M22,8V18a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V8a1.991,1.991,0,0,1,.97-1.71L12,.64l9.03,5.65A1.991,1.991,0,0,1,22,8Z" transform="translate(0 1.36)" fill="#084d6d"/>
            </g>
          </g>
        </svg>
      );
    case 'bounced':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="16 16 22 22">
          <g id="Group_580" data-name="Group 580" transform="translate(-1141 -710)">
            <g id="MDI_close-thick" data-name="MDI / close-thick" transform="translate(1153.658 722.658)">
              <g id="Boundary" fill="#084d6d" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
                <rect width="27.684" height="27.684" stroke="none"/>
                <rect x="0.5" y="0.5" width="26.684" height="26.684" fill="none"/>
              </g>
              <path id="Path_close-thick" data-name="Path / close-thick" d="M22.456,7.357,19.1,4,13.228,9.871,7.357,4,4,7.357l5.871,5.871L4,19.1l3.357,3.357,5.871-5.871L19.1,22.456,22.456,19.1l-5.871-5.871Z" transform="translate(0.614 0.614)" fill="#084d6d"/>
            </g>
          </g>
        </svg>
      );
    case 'unsubscribed':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="22 16 22 22">
          <g id="Group_581" data-name="Group 581" transform="translate(-1379 -710)">
            <g id="MDI_exclamation-thick" data-name="MDI / exclamation-thick" transform="translate(1393 723.114)">
              <g id="Boundary" transform="translate(0 0.886)" fill="#084d6d" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
                <rect width="25" height="25" stroke="none"/>
                <rect x="0.5" y="0.5" width="24" height="24" fill="none"/>
              </g>
              <path id="Path_exclamation-thick" data-name="Path / exclamation-thick" d="M10,3h4.3V14.838H10V3m0,19.371v-4.3h4.3v4.3Z" transform="translate(0.762 0.286)" fill="#084d6d"/>
            </g>
          </g>
        </svg>
      );
    default:
      return ( null );
  }
}
