import React, { useState, Dispatch } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Box, Stack, Switch, IconButton } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { classes } from './styles';
import QuestionFieldViewer from '../../Components/Utilities/QuestionFieldViewer';
import {
  DragIndicator as DragIndicatorIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Edit as EditIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import Api from '../API';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { IApplicantQuestion } from '../../Components/Utilities/QuestionFieldViewer';
import AvailabilityPreview from '../../ApprovalForms/Questions/Modals/AvailabilityPreview';
import { useQueryClient, useMutation, UseMutationResult } from '@tanstack/react-query';
import { SmartFormFieldsState, SmartFormFieldsAction } from '../reducer';
import { IError } from '../types';
import AvailabilityFieldQuestion from '../../Components/CustomUIElements/AvailabilityFieldQuestion';

interface IDialogProps {
  title: string;
  description: string;
  buttonText: string;
  callback: UseMutationResult<any, IError, void, unknown>;
}

export default function SortableItem({
  question,
  formId,
  SmartFormFieldsState,
  dispatch,
  userPermissions
}: {
  question: IApplicantQuestion;
  formId: number;
  SmartFormFieldsState: SmartFormFieldsState;
  dispatch: Dispatch<SmartFormFieldsAction>;
  userPermissions: Record<string, Record<string, boolean>>;
}) {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [dialogProps, setDialogProps] = useState<IDialogProps | null>(null);

  const queryClient = useQueryClient();
  const actionStates = SmartFormFieldsState.actionStates;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: question.id
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    filter: isDragging ? 'brightness(75%)' : 'brightness(100%)',
    zIndex: isDragging ? 10 : 1
  };

  const deleteQuestion = useMutation({
    mutationFn: () => Api.deleteQuestion(question.id, formId),
    onError: (error: IError) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: error.errors.join('. '),
          state: 'error'
        }
      });
      setDialogProps(null);
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'The field has been deleted',
          state: 'success'
        }
      });
      setDialogProps(null);
      queryClient.invalidateQueries(['questions'], { exact: true });
    }
  });

  const cloneQuestion = useMutation({
    mutationFn: () => Api.cloneQuestion(question.id, formId),
    onError: (error: IError) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: error.errors.join('. '),
          state: 'error'
        }
      });
      setDialogProps(null);
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'The field has been cloned',
          state: 'success'
        }
      });
      setDialogProps(null);
      queryClient.invalidateQueries(['questions'], { exact: true });
    }
  });

  const updateActions = useMutation({
    mutationFn: (fieldObject: Record<string, boolean>) =>
      Api.putQuestion(question.id, formId, {
        field: fieldObject
      }),
    onError: (error: IError) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: error.errors.join('. '),
          state: 'error'
        }
      });
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'The change has been saved',
          state: 'success'
        }
      });
      queryClient.invalidateQueries(['questions'], { exact: true });
    }
  });

  const handleUpdateActions = async (action: keyof typeof question) => {
    dispatch({
      type: 'SET_ACTION_STATES',
      payload: {
        ...actionStates,
        [question.id]: {
          ...actionStates[question.id],
          [action]: !actionStates[question.id][action]
        }
      }
    });
    updateActions.mutate(
      { [action]: !question[action] },
      {
        onError: () =>
          dispatch({
            type: 'SET_ACTION_STATES',
            payload: {
              ...actionStates,
              [question.id]: {
                ...actionStates[question.id],
                [action]: actionStates[question.id][action]
              }
            }
          })
      }
    );
  };

  const handleEditQuestion = (question: IApplicantQuestion) => {
    dispatch({
      type: 'SET_EDITING_QUESTION',
      payload: question
    });
    dispatch({
      type: 'SET_MODALS_OPEN',
      payload: { ...SmartFormFieldsState.modalsOpen, newField: true }
    });
  };

  const handleDeleteQuestion = () => {
    setDialogProps({
      title: 'Permanently delete question?',
      description: 'Are you sure you want to delete this question? This action cannot be undone.',
      buttonText: 'Delete',
      callback: deleteQuestion
    });
  };

  const handleCloneQuestion = () => {
    setDialogProps({
      title: 'Clone question',
      description: `Are you sure you want to clone this question?`,
      buttonText: 'Clone',
      callback: cloneQuestion
    });
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <Box key={question.id}>
        {question.type === 'JobReferenceLookupField' ? (
          <span />
        ) : (
          <Box sx={classes.questionsWrapper}>
            <Box sx={{ display: 'flex' }}>
              <DragIndicatorIcon
                sx={{ cursor: isDragging ? 'grabbing' : 'pointer', ...classes.dragHandle }}
                {...listeners}
                {...attributes}
              />
              <Box sx={{ marginRight: 'auto', ...classes.questionInput }}>
                <Box sx={{ height: '6px' }}></Box>
                <Box sx={{ ...classes.modalFormLine, position: 'relative' }}>
                  <QuestionFieldViewer question={question} draggable={true} />
                </Box>
              </Box>
              <Box
                sx={{
                  ...classes.sortableItemSwitchContainer,
                  fontWeight: SmartFormFieldsState.actionStates[question.id]?.enabled ? 'bold' : ''
                }}
              >
                <Box sx={{ marginTop: '12px' }}>
                  <Switch
                    sx={
                      SmartFormFieldsState.actionStates[question.id].enabled
                        ? classes.switchActive
                        : classes.switch
                    }
                    checked={SmartFormFieldsState.actionStates[question.id].enabled}
                    onChange={() => handleUpdateActions('enabled')}
                  />
                  <Stack sx={{ marginTop: 1, fontSize: '12px' }}>Enabled</Stack>
                </Box>
              </Box>
              <Box
                sx={{
                  ...classes.sortableItemSwitchContainer,
                  fontWeight: SmartFormFieldsState.actionStates[question.id].required ? 'bold' : ''
                }}
              >
                <Box sx={{ marginTop: '12px' }}>
                  <Switch
                    sx={
                      SmartFormFieldsState.actionStates[question.id].required
                        ? classes.switchActive
                        : classes.switch
                    }
                    checked={SmartFormFieldsState.actionStates[question.id].required}
                    onChange={() => handleUpdateActions('required')}
                    disabled={!question.editable}
                  />
                  <Stack sx={{ marginTop: 1, fontSize: '12px' }}>Required</Stack>
                </Box>
              </Box>
              <Box
                sx={{
                  ...classes.sortableItemSwitchContainer,
                  fontWeight: SmartFormFieldsState.actionStates[question.id].rateable ? 'bold' : ''
                }}
              >
                <Box sx={{ marginTop: '12px' }}>
                  <Switch
                    sx={
                      SmartFormFieldsState.actionStates[question.id].rateable
                        ? classes.switchActive
                        : classes.switch
                    }
                    checked={SmartFormFieldsState.actionStates[question.id].rateable}
                    onChange={() => handleUpdateActions('rateable')}
                    disabled={!question.editable}
                  />
                  <Stack sx={{ marginTop: 1, fontSize: '12px' }}>Rateable</Stack>
                </Box>
              </Box>
              <Box
                sx={{
                  ...classes.sortableItemSwitchContainer,
                  fontWeight: SmartFormFieldsState.actionStates[question.id].confidential
                    ? 'bold'
                    : ''
                }}
              >
                <Box sx={{ marginTop: '12px' }}>
                  <Switch
                    sx={
                      SmartFormFieldsState.actionStates[question.id].confidential
                        ? classes.switchActive
                        : classes.switch
                    }
                    checked={SmartFormFieldsState.actionStates[question.id].confidential}
                    onChange={() => handleUpdateActions('confidential')}
                    disabled={!question.editable}
                  />
                  <Stack sx={{ marginTop: 1, fontSize: '12px' }}>Confidential</Stack>
                </Box>
              </Box>
              <Box sx={{ padding: '0 6px', minWidth: '120px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                    marginTop: '-8px'
                  }}
                >
                  {question.editable && !question.answered && (
                    <IconButton
                      id={`${question.title}-edit-button`}
                      onClick={() => handleEditQuestion(question)}
                    >
                      <EditIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                  {question.editable && (
                    <IconButton id={`${question.title}-clone-button`} onClick={handleCloneQuestion}>
                      <ContentCopyIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                  {question.deletable && (
                    <IconButton
                      id={`${question.title}-delete-button`}
                      onClick={handleDeleteQuestion}
                    >
                      <DeleteIcon sx={{ color: '#E37D7A', fontSize: 16 }} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
            {question.type === 'AvailabilityField' && (
              <AvailabilityFieldQuestion question={question} />
            )}
            {SmartFormFieldsState.actionStates[question.id].confidential && (
              <Box sx={{ padding: '0 6px', color: '#838383', fontSize: '12px' }}>
                <span style={{ fontWeight: 'bold' }}>Confidential Setting Enabled:</span> For DE&I
                compliance, only authorised users (HR Admins), can view responses. Hiring Managers
                will not have access.
              </Box>
            )}
          </Box>
        )}
      </Box>
      {dialogProps && (
        <GenericDialog
          url={''}
          title={dialogProps.title}
          description={dialogProps.description}
          buttonText={dialogProps.buttonText}
          buttonCallback={() => dialogProps.callback.mutate()}
          callbackLoading={deleteQuestion.isLoading || cloneQuestion.isLoading}
          isDialogOpen={Boolean(dialogProps)}
          setDialogOpen={() => setDialogProps(null)}
          dialogId={question.id}
        />
      )}
    </Box>
  );
}
