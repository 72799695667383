import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { classes } from './styles';
import { IBooleanQueryObject } from './booleanQueryParser';

const AndOrToggle = ({
  index,
  editableBooleanObject,
  onChange,
  changeBase,
  hasExclude = false
}: {
  index: number;
  editableBooleanObject?: IBooleanQueryObject;
  onChange: (index: number, value: string) => void;
  changeBase?: boolean;
  hasExclude?: boolean;
}) => {
  return (
    <ToggleButtonGroup
      value={editableBooleanObject?.[changeBase ? 'baseOperator' : 'operator'] || ''}
      exclusive
      onChange={(_, value) => onChange(index, value)}
      sx={classes.booleanEditorOperatorGroup}
    >
      <ToggleButton id="add-button" value="AND" sx={classes.booleanEditorOperatorButton}>
        And
      </ToggleButton>
      <ToggleButton id="or-button" value="OR" sx={classes.booleanEditorOperatorButton}>
        Or
      </ToggleButton>
      {hasExclude && (
        <ToggleButton value="NOT" sx={{ ...classes.booleanEditorOperatorButton, width: '85px' }}>
          Exclude
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

export default AndOrToggle;
