import React, { Dispatch, useState, useRef, useCallback, useEffect, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import { IJob } from '../../Job/types';
import { classes } from '../styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import Button from '@mui/material/Button';

const SourceTypes = [
  {
    id: 1,
    name: 'Newspaper'
  },
  {
    id: 2,
    name: 'Online Job Board'
  },
  {
    id: 3,
    name: 'Universities'
  },
  {
    id: 4,
    name: 'General'
  }
];

const countries = [
  {
    id: 1,
    name: 'Australia'
  },
  {
    id: 2,
    name: 'Canada'
  },
  {
    id: 3,
    name: 'New Zealand'
  },
  {
    id: 4,
    name: 'South Africa'
  },
  {
    id: 5,
    name: 'United Kingdom'
  },
  {
    id: 6,
    name: 'United States'
  },
  {
    id: 7,
    name: 'International'
  },
  {
    id: 8,
    name: 'Other'
  }
];

export default function Sources({
  apiKey,
  job,
  sourcesTabJobData,
  setSourcesTabJobData,
  sourceListIds,
  setSourceListIds,
  setIsEdited
}: {
  apiKey: string;
  job: IJob;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
}) {
  const [sourceOptions, setSourceOptions] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedSource, setSelectedSource] = useState();

  const getSourceOptions = useCallback(async () => {
    setSourceOptions([]);
    try {
      const response = await fetch(
        `/api/v4/sources?source_type_id=${selectedType?.id}&country_id=${selectedCountry?.id}`,
        {
          method: 'GET',
          headers: {
            'X-api-authenticate': apiKey
          }
        }
      ).then((response) => response.json());
      setSourceOptions(response.sources);
    } catch (error) {
      console.log(error);
    }
  }, [apiKey, job, selectedType, selectedCountry]);

  const handleChange = (e, id: number) => {
    setIsEdited(true);
    const list = sourceListIds;

    if (e.target.checked) {
      list.push(id);
      setSourceListIds(list);
    } else {
      const index = list.indexOf(id);
      if (index > -1) {
        list.splice(index, 1);
        setSourceListIds(list);
      }
    }
  };

  const addSourceLabel = () => {
    if (!selectedType || !selectedCountry || !selectedSource) return;
    if (!sourceListIds.includes(selectedSource.id)) {
      const tempSources = { ...sourcesTabJobData };
      tempSources.sources.value.push({
        label: `${selectedType.name}/${selectedCountry.name}/${selectedSource.name}`,
        id: selectedSource.id
      });
      setSourcesTabJobData(tempSources);
      setSourceListIds([...sourceListIds, selectedSource.id]);
    }
  };

  useEffect(() => {
    getSourceOptions();
  }, [getSourceOptions]);

  const sourcesData = sourcesTabJobData?.sources?.value;
  const sortedSourcesData = sourcesData.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );

  return (
    <Box sx={classes.tabContent}>
      <Box id="sources-title" data-testid="sources-title" sx={classes.headerContent}>
        Sources
      </Box>
      <Box sx={classes.bodyContainer}>
        {sourcesData &&
          sortedSourcesData.map((source, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  sx={{ ...classes.checkbox, marginLeft: 1 }}
                  onChange={(e) => handleChange(e, source.id)}
                  defaultChecked={sourceListIds.includes(source.id)}
                />
              }
              label={source.label}
              sx={classes.label}
            />
          ))}
      </Box>
      <Box sx={classes.selectGroup}>
        <Autocomplete
          disablePortal
          options={SourceTypes}
          filterSelectedOptions={true}
          getOptionLabel={(option) => `${option.name}`}
          id="source-type"
          data-testid="source-type"
          value={selectedType || { name: '' }}
          onChange={(event, value) => {
            setSelectedType(value);
            setSelectedCountry(null);
            setSelectedSource(null);
            setIsEdited(true);
          }}
          sx={{ width: '190px', ...sharedClasses.formAutocomplete, marginTop: '35px' }}
          ListboxProps={{
            style: {
              fontFamily: 'Source Sans Pro, sans-serif',
              color: '#333333',
              maxHeight: '200px'
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Source type" InputLabelProps={{ shrink: true }} />
          )}
          renderOption={(props, option, state) => {
            return (
              <li {...props} key={option.id} data-testid={`option-${state.index}`}>
                {option.name}
              </li>
            );
          }}
        />
        <Autocomplete
          disablePortal
          options={countries}
          filterSelectedOptions={true}
          getOptionLabel={(option) => `${option.name}`}
          id="source-country"
          data-testid="source-country"
          value={selectedCountry || { name: '' }}
          onChange={(event, value) => {
            setSelectedCountry(value);
            setSelectedSource(null);
          }}
          sx={{
            width: '190px',
            ...sharedClasses.formAutocomplete,
            marginTop: '35px',
            display: selectedType ? '' : 'none'
          }}
          ListboxProps={{
            style: {
              fontFamily: 'Source Sans Pro, sans-serif',
              color: '#333333',
              maxHeight: '200px'
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Country" InputLabelProps={{ shrink: true }} />
          )}
          renderOption={(props, option, state) => {
            return (
              <li {...props} key={option.id} data-testid={`option-${state.index}`}>
                {option.name}
              </li>
            );
          }}
        />
        <Autocomplete
          disablePortal
          options={sourceOptions.sort((a, b) =>
            a.name.toLowerCase().replace(/[^a-z]/g, '') >
            b.name.toLowerCase().replace(/[^a-z]/g, '')
              ? 1
              : -1
          )}
          filterSelectedOptions={true}
          loading={sourceOptions.length < 1}
          getOptionLabel={(option) => `${option.name}`}
          id="source-name"
          value={selectedSource || { name: '' }}
          onChange={(event, value) => setSelectedSource(value)}
          sx={{
            width: '290px',
            ...sharedClasses.formAutocomplete,
            marginTop: '35px',
            display: selectedCountry && selectedType ? '' : 'none'
          }}
          ListboxProps={{
            style: {
              fontFamily: 'Source Sans Pro, sans-serif',
              color: '#333333',
              maxHeight: '200px'
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Source" InputLabelProps={{ shrink: true }} />
          )}
        />
      </Box>
      <Button
        id="add-source-button"
        sx={classes.addSourceButton}
        onClick={() => addSourceLabel()}
        disabled={!selectedSource}
      >
        Add source
      </Button>
    </Box>
  );
}
