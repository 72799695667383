import React, { forwardRef } from 'react';
import { Box, SxProps } from '@mui/material';
import { sharedClasses } from '../CustomUIElements/sharedClasses';

const BetaButton = forwardRef<HTMLElement, { sx?: SxProps }>(({ sx, ...props }, ref) => {
  return (
    <Box sx={{ ...sharedClasses.applicationBetaButton, ...sx }} ref={ref} {...props}>
      BETA
    </Box>
  );
});
BetaButton.displayName = 'BetaButton';
export default BetaButton;
