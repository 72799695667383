import React, { useState } from 'react';
import { theme } from '../../../ThemeContext/ThemeObject';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { sharedClasses } from './sharedClasses';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const populateValue = (options: { name: string; id: number }[]) => {
  const value = {} as { [key: string]: boolean };
  options.forEach((option) => {
    value[option.id] = false;
  });
  return value;
};

export default function FormCheckboxGroup({
  options,
  defaultValues,
  onChange,
  groupLabel,
  directionRow,
  innerRef,
  error,
  color,
  required,
  disabled
}: {
  options: { name: string; id: number; tip: string }[];
  onChange: (value: Record<string, boolean>) => void;
  directionRow?: boolean;
  groupLabel?: string;
  defaultValues?: Record<string | number, boolean>;
  innerRef?: React.Ref<HTMLDivElement>;
  error?: string;
  color?: string;
  required?: boolean;
  disabled?: boolean;
}) {
  const [value, setValue] = useState(defaultValues ? defaultValues : populateValue(options));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setValue({
      ...value,
      [id]: event.target.checked
    });
    onChange({ ...value, [id]: event.target.checked });
  };

  const checkboxStyle = {
    color: '#DDDDDD',
    '&.Mui-checked': {
      color: theme.palette.action.main
    },
    '& .MuiSvgIcon-root': {
      fontSize: '22px'
    },
    paddingLeft: 'unset',
    paddingRight: 1
  };

  const labelStyle = {
    '& .MuiTypography-root': {
      color: color ? color : '#333333',
      fontSize: '16px',
      marginLeft: '2px',
      fontFamily: 'Source Sans Pro, sans-serif'
    },
    paddingRight: 2
  };

  return (
    <FormControl>
      {groupLabel && (
        <FormLabel
          id={groupLabel?.toLowerCase().split(' ').join('-')}
          sx={{ ...sharedClasses.inputLabel, fontSize: '16px' }}
        >
          {groupLabel}
          {required && <span>*</span>}
        </FormLabel>
      )}
      <FormGroup
        ref={innerRef}
        aria-labelledby={groupLabel?.toLowerCase().split(' ').join('-')}
        sx={{ padding: '8px 0px 10px 12px', flexDirection: directionRow ? 'row' : 'column' }}
      >
        {options.map((option, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  disableRipple
                  sx={checkboxStyle}
                  onChange={(event) => handleChange(event, option.id)}
                  value={value[option.id]}
                  defaultChecked={value[option.id]}
                  name={option.name}
                  disabled={disabled}
                />
              }
              label={option.name}
              sx={labelStyle}
            />
            {option.tip && (
              <Tooltip title={option.tip}>
                <InfoIcon sx={sharedClasses.infoIcon} />
              </Tooltip>
            )}
          </Box>
        ))}
        {error && <Box sx={sharedClasses.errorBoxCheckbox}>{error}</Box>}
      </FormGroup>
    </FormControl>
  );
}
