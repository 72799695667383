import React, { Dispatch } from 'react';
import { Box } from '@mui/material';
import { ApplicationsAPIReturn } from '../../types';
import GenericTable from '../../../NewUI/Components/GenericTable/GenericTable';
import SearchInput from '../../../NewUI/Components/GenericTable/SearchInput';
import { headerStyles } from './config';
import {
  GenericTableAction,
  GenericTableState
} from '../../../NewUI/Components/GenericTable/genericTableReducer';
import { IStyle } from '../../../ThemeContext/ThemeObject';
import ApplicationTableRows from './ApplicationTableRows';

const Applications = ({
  isFetching,
  applications,
  applicationsState,
  applicationsDispatch
}: {
  isFetching: boolean;
  applications?: ApplicationsAPIReturn;
  applicationsState: GenericTableState;
  applicationsDispatch: Dispatch<GenericTableAction>;
}) => {
  return (
    <Box>
      <Box>
        <SearchInput dispatch={applicationsDispatch} id="search-box-applications-tab" />
        <GenericTable
          items={applications?.data?.applications || []}
          isLoadingItems={isFetching}
          dispatch={applicationsDispatch}
          tableState={applicationsState.tableState}
          paginationState={applicationsState.searchSettings}
          pagination={{
            totalItems: applications?.totalItems || 0,
            totalPages: applications?.totalPages || 0
          }}
          headerStyle={headerStyles as (id: string, tableHovered: boolean) => IStyle}
          TableRows={ApplicationTableRows}
          label="applications analytics"
          titleCellId="candidate"
          allowMultipleSelection={false}
        />
      </Box>
    </Box>
  );
};

export default Applications;
