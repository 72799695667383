import React from 'react';
import { Button, Stack, Tooltip } from '@mui/material';
import { styles } from './styles';
import { sharedClasses } from '../Components/CustomUIElements/sharedClasses';
import BetaButton from '../Components/Buttons/BetaButton';
import { FelixSurveySVG } from './OverviewTab/Icons';

export default function ApplicationBetaButton() {
  return (
    <Tooltip
      placement="right-start"
      slotProps={{
        arrow: { sx: { color: 'white', fontSize: 'large' } },
        tooltip: {
          sx: {
            backgroundColor: 'white',
            boxShadow: '0 0 20px rgba(36, 36, 36, 0.18)',
            maxWidth: 'unset'
          }
        }
      }}
      title={
        <Stack sx={styles.betaButtonTooltipContainer}>
          <FelixSurveySVG />
          <Stack sx={styles.modalTitle}>Our new interface is live!</Stack>
          <Stack sx={{ fontSize: '16px' }}>
            {`We built it to enhance your experience. It's still in Beta, so feel free to
                      share any feedback to help us improve!`}
          </Stack>
          <Stack sx={{ flexDirection: 'row', columnGap: 1, justifyContent: 'flex-end' }}>
            <Button
              sx={sharedClasses.genericButtonSecondary}
              onClick={() => window.open(`https://form.jotform.com/242677079968882`, '_blank')}
            >
              Give feedback
            </Button>
            <Button
              sx={sharedClasses.genericButton}
              onClick={() =>
                window.open(`https://scouttalenthq.com/new-applications-design`, '_blank')
              }
            >
              Learn more
            </Button>
          </Stack>
        </Stack>
      }
      arrow
    >
      <BetaButton sx={{ padding: '4px 8px', fontSize: '8px', boxShadow: 'none' }} />
    </Tooltip>
  );
}
