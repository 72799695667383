import React from 'react';
import { Box } from '@mui/material';
import { classes } from './styles';

const NoItemsMessage = ({
  showNoItemsMessage,
  label,
  customEmptyStateMessage = 'found that match your search parameters'
}: {
  showNoItemsMessage: boolean;
  label?: string;
  customEmptyStateMessage?: string;
}) => {
  if (!showNoItemsMessage) return null;
  return (
    <Box sx={classes.noResultsContainer}>
      <svg
        width="134"
        height="110"
        viewBox="0 0 134 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58.8275 43.8437C61.8329 43.8437 64.2656 46.5195 64.2656 49.8329V87.1779C64.2656 90.4817 61.8353 93.1683 58.8275 93.1683H9.54269C6.5445 93.1683 4.10938 90.4829 4.10938 87.1779V48.9799C4.10938 45.6665 6.53969 43.8401 9.54269 43.8401"
          fill="#D1D1D1"
        />
        <path
          d="M22.4689 36.625C23.4819 36.625 24.3036 37.5273 24.3036 38.6463L36.3349 51.5305C36.3349 52.6446 35.512 53.5494 34.5001 53.5494H5.94034C4.92972 53.5494 4.11039 52.6446 4.11039 51.5305V38.6463C4.11039 37.5273 4.93092 36.625 5.94034 36.625H22.4689Z"
          fill="#D1D1D1"
        />
        <path
          d="M68.3683 54.6045C71.3713 54.6045 73.804 57.2803 73.804 60.5937L65.4218 87.1827C64.5075 89.5637 61.7884 93.1731 58.7806 93.1731H9.54269C6.5445 93.1731 4.10938 90.4877 4.10938 87.1827L12.494 60.5937C13.7344 58.2813 16.1274 54.6045 19.1304 54.6045"
          fill="#EBEBEB"
        />
        <path
          d="M110.384 88.0627L93.4971 65.5819L99.0447 61.4338L115.932 83.9146L110.384 88.0627Z"
          fill="#CFCFCF"
          stroke="#E3E3E3"
        />
        <path
          d="M106.686 83.0262L112.129 78.9564C113.057 78.2627 114.372 78.451 115.067 79.3758L131.344 101.045C132.039 101.969 131.851 103.282 130.923 103.975L125.48 108.045C124.552 108.739 123.237 108.551 122.542 107.626L106.265 85.9569C105.57 85.0321 105.758 83.7199 106.686 83.0262Z"
          fill="#D1D1D1"
          stroke="#E3E3E3"
        />
        <g opacity="0.2">
          <path
            d="M101.28 16.9181C105.077 21.9746 107.286 28.041 107.628 34.35C107.969 40.659 106.428 46.9273 103.199 52.3624C99.9698 57.7975 95.1978 62.1553 89.4864 64.8846C83.775 67.6139 77.3807 68.5922 71.112 67.6958C64.8433 66.7995 58.9817 64.0687 54.2686 59.8488C49.5554 55.6288 46.2023 50.1093 44.6332 43.9881C43.0641 37.8669 43.3495 31.419 45.4534 25.4596C47.5572 19.5003 51.385 14.2971 56.4527 10.5082C59.8176 7.99159 63.6466 6.16109 67.7208 5.12123C71.795 4.08137 76.0346 3.85253 80.1975 4.44778C84.3604 5.04303 88.3649 6.45072 91.9824 8.59041C95.5999 10.7301 98.7594 13.5599 101.28 16.9181Z"
            fill="white"
          />
          <path
            d="M105.637 24.8457C108.598 32.7335 108.32 41.4684 104.864 49.1533C101.409 56.8382 95.0534 62.8525 87.1792 65.89L105.637 24.8457Z"
            fill="#EBEBEB"
          />
          <path
            d="M80.5641 4.50764L55.2798 60.7207C51.0568 57.2441 47.7962 52.7467 45.8086 47.6569L64.4609 6.15355C69.5989 4.23829 75.1437 3.67155 80.5641 4.50764Z"
            fill="#EBEBEB"
          />
          <path
            d="M99.2538 14.4699L75.1715 68.0157C73.0724 67.98 70.9818 67.7403 68.9292 67.3002L94.57 10.2849C96.2695 11.5177 97.8388 12.92 99.2538 14.4699Z"
            fill="#EBEBEB"
          />
          <path
            d="M84.8377 5.47534L58.8488 63.2682C58.5172 63.0655 58.1898 62.8546 57.8706 62.6437L83.7186 5.16105C84.0916 5.25616 84.4688 5.35541 84.8377 5.47534Z"
            fill="#EBEBEB"
          />
          <path
            d="M55.1929 11.5048L43.6574 37.1446C43.6201 36.1893 43.6326 35.2298 43.6906 34.2746L53.0706 13.4112C53.7421 12.7537 54.4468 12.1169 55.1929 11.5048Z"
            fill="#EBEBEB"
          />
        </g>
        <path
          d="M75.6571 3.63546H75.6565C69.2268 3.63546 62.9413 5.53766 57.595 9.10167C52.2486 12.6657 48.0813 17.7315 45.6203 23.6586C43.1593 29.5858 42.5152 36.108 43.7694 42.4005C45.0236 48.693 48.1198 54.4729 52.6663 59.0096C57.2128 63.5462 63.0054 66.6357 69.3115 67.8878C75.6176 69.1398 82.1542 68.498 88.0949 66.0437C94.0355 63.5893 99.1135 59.4325 102.687 54.0986C106.26 48.7647 108.167 42.4934 108.168 36.0777V36.0771C108.159 27.4761 104.731 19.2302 98.6359 13.1483C92.5408 7.06648 84.2769 3.64546 75.6571 3.63546ZM75.656 71.647C68.6035 71.6469 61.7096 69.5603 55.846 65.6512C49.9822 61.742 45.4122 56.1859 42.7137 49.6856C40.0152 43.1853 39.3093 36.0326 40.6854 29.1322C42.0615 22.2317 45.4576 15.8932 50.4446 10.9182C55.4316 5.94325 61.7854 2.55533 68.7026 1.1831C75.6198 -0.189144 82.7895 0.515971 89.3049 3.20921C95.8203 5.90245 101.389 10.4628 105.306 16.3133C109.223 22.1637 111.314 29.0415 111.313 36.077C111.301 45.5074 107.541 54.5484 100.856 61.2167C94.1719 67.885 85.1092 71.6362 75.656 71.647Z"
          fill="#D1D1D1"
          stroke="#E3E3E3"
        />
      </svg>
      <span>
        No {label || 'items'} {customEmptyStateMessage}
      </span>
    </Box>
  );
};

export default NoItemsMessage;
