import React, { Dispatch } from 'react';
import { GenericTableState } from '../../../NewUI/Components/GenericTable/genericTableReducer';
import { TableActionDrawerAction } from '../../../NewUI/Components/GenericTable/types';
import CreateNewPoolModal from './CreateNewPoolModal';
import { ISnackbarInput } from '../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import EmailCandidate from '../../SharedComponents/Modals/EmailCandidate';
import DownloadModal from '../../../NewUI/Components/Modals/DownloadModal';
import callAPI from '../../../NewUI/Components/Utilities/callAPI';
import ExternalEmail from '../../SharedComponents/Modals/ExternalEmail';

const ActionDrawerModals = ({
  tableState,
  dispatch,
  setSnackbarState
}: {
  tableState: GenericTableState['tableState'];
  dispatch: Dispatch<TableActionDrawerAction>;
  setSnackbarState: (snackbarState: ISnackbarInput) => void;
}) => {
  const downloadAPICall = async (type: string) => {
    if (type === 'attachments') {
      const { zip_file } = await callAPI(
        '/api/v4/candidate_pools/zip_attachments',
        { candidate_pool_ids: tableState.selectedItemIds },
        'POST'
      );
      return zip_file.id;
    } else if (type === 'pdf') {
      const response = await callAPI(
        '/api/v4/candidate_pools/export_pdf',
        { candidate_pool_ids: tableState.selectedItemIds },
        'POST'
      );
      return response.success || response.errors || 'Something went wrong';
    } else if (type === 'attachmentsInPdf') {
      const response = await callAPI(
        '/api/v4/candidate_pools/export_pdf',
        { candidate_pool_ids: tableState.selectedItemIds, attachments_in_pdf: true },
        'POST'
      );
      return response.success || response.errors || 'Something went wrong';
    }
  };

  return (
    <>
      <CreateNewPoolModal
        isOpen={tableState.modalsOpen?.newPool}
        handleClose={() =>
          dispatch({ type: 'SET_ACTION_MODAL_OPEN', payload: { name: 'newPool', isOpen: false } })
        }
        setSnackbarState={setSnackbarState}
      />
      <ExternalEmail
        isOpen={tableState.modalsOpen.externalEmail}
        dispatch={dispatch}
        selectedPoolIds={tableState.selectedItemIds}
        apiUrl="/api/v4/candidate_pools/bulk_external_email"
      />     
      <EmailCandidate
        isOpen={tableState.modalsOpen?.email || tableState.actions?.isOpen?.email}
        dispatch={dispatch}
        selectedPoolIds={
          !tableState.actions?.isOpen?.email
            ? tableState.selectedItemIds
            : [tableState.actions?.selectedItem?.id || -1]
        }
        openedFromTableActions={tableState.actions?.isOpen?.email}
        setSnackbarState={setSnackbarState}
        showTotalSelectedCandidates={false}
      />
      <DownloadModal
        type="attachments"
        isOpen={tableState.modalsOpen?.downloadAttachments}
        handleClose={() =>
          dispatch({
            type: 'SET_ACTION_MODAL_OPEN',
            payload: { name: 'downloadAttachments', isOpen: false }
          })
        }
        downloadAPICall={downloadAPICall}
      />
      <DownloadModal
        type="attachmentsInPdf"
        isOpen={tableState.modalsOpen?.exportAttachments}
        handleClose={() =>
          dispatch({
            type: 'SET_ACTION_MODAL_OPEN',
            payload: { name: 'exportAttachments', isOpen: false }
          })
        }
        downloadAPICall={downloadAPICall}
        emailFallback={true}
      />
      <DownloadModal
        type="pdf"
        isOpen={tableState.modalsOpen?.exportAsPdf}
        handleClose={() =>
          dispatch({
            type: 'SET_ACTION_MODAL_OPEN',
            payload: { name: 'exportAsPdf', isOpen: false }
          })
        }
        downloadAPICall={downloadAPICall}
        emailFallback={true}
      />
    </>
  );
};

export default ActionDrawerModals;
