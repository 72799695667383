"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "./API";
export function useDeleteEvent({
  events,
  setSnackbar,
  ScoutCalendarState: ScoutCalendarState2,
  dispatch,
  onClose
}) {
  const queryClient = useQueryClient();
  const { apiKey, createEventObject } = ScoutCalendarState2;
  const { candidates, eventId } = createEventObject;
  const { mutate: deleteEvent, isLoading: deletingEvent } = useMutation({
    mutationFn: () => __async(this, null, function* () {
      return yield API.deleteEvent(apiKey, eventId);
    }),
    onError: (error) => {
      const snackbarPayload = {
        message: `There was an error deleting the event, ${error.errors}`,
        state: "error"
      };
      setSnackbar ? setSnackbar(snackbarPayload) : dispatch({ type: "SET_SNACKBAR", payload: snackbarPayload });
    },
    onSuccess: () => {
      const snackbarPayload = {
        message: `Event has been successfully deleted.${candidates.length > 0 ? ` ${candidates[0].name}${candidates.length > 1 ? ` and ${candidates.length - 1} others` : ""} has been successfully removed and notified` : ""}`,
        state: "success"
      };
      setSnackbar ? setSnackbar(snackbarPayload) : dispatch({ type: "SET_SNACKBAR", payload: snackbarPayload });
      const updatedList = events.filter((e) => e.id !== eventId);
      setSnackbar ? queryClient.setQueryData(["getEvents"], { pages: [{ data: { events: updatedList } }] }) : queryClient.setQueryData(["events", ScoutCalendarState2.weekStart], updatedList);
      queryClient.invalidateQueries(["application events"], { exact: true });
      onClose();
    }
  });
  return { deleteEvent, deletingEvent };
}
