import React from 'react';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styles } from '../styles';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';

export default function ApproversListText({
  approver,
  index,
  handleEmailInput,
  removeApprover,
  handleMandatoryButton,
  invalidEmail
}: {
  approver: string;
  index: number;
  handleEmailInput: (index: number, value: string) => void;
  removeApprover: (index: number) => void;
  handleMandatoryButton: (index: number) => void;
  invalidEmail?: boolean;
}) {
  const mandatory = approver.includes('*');

  return (
    <Box sx={styles.approverItemList}>
      <FormTextField
        label="Email"
        onChange={(e) => {
          handleEmailInput(index, e.target.value);
        }}
        value={mandatory ? approver.slice(1) : approver}
        styles={{ width: '290px' }}
        error={invalidEmail ? 'Please enter a proper email address' : ''}
      />
      <CloseIcon
        color="warning"
        onClick={() => removeApprover(index)}
        sx={{ cursor: 'pointer', marginRight: 'auto' }}
      />
      {approver && (
        <FormControlLabel
          control={
            <Switch
              sx={mandatory ? styles.switchActive : styles.switch}
              checked={mandatory}
              onChange={() => handleMandatoryButton(index)}
            />
          }
          label={
            <Typography
              sx={{ ...styles.mandatoryLabel, color: `${mandatory ? 'black' : '#939393'}` }}
            >
              Mandatory
            </Typography>
          }
        />
      )}
    </Box>
  );
}
