import React from 'react';
import { Box } from '@mui/material';
import { classes } from '../../NewUI/EditJob/styles';

export const emptyChartText = (hasChartData: boolean) => {
  return (
    <Box sx={classes.noDataText}>
      <Box>
        {hasChartData ? 'There is no data to show from the' : 'Use the filters above to generate'}
      </Box>
      <Box>{hasChartData ? 'parameters you have selected' : 'your visual report'}</Box>
    </Box>
  );
};
