import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import _ from 'lodash';
import ScoutSelect from '../scout/select';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DateUtils } from 'react-day-picker';

dayjs.extend(customParseFormat);

const FilteringOptionsTable = ({
  defaultStatuses,
  defaultTags,
  onSubmit,
  onChange,
  data,
  tags,
  canFilterScoutClients
}) => {
  const [statuses, setStatuses] = useState(data.status || []);
  const [hidden, setHidden] = useState(
    _.compact([
      data.by_title,
      data.post_code1,
      data.post_code2,
      data.date1,
      data.date2,
      data.filter_out_scout_client
    ]).length === 0
  );
  const [componentTags, setTags] = useState(data.tags || []);

  const onDropdownChange = (e) => {
    const newStatuses = [...statuses, e.target.value];
    setStatuses(newStatuses);
    onChange({ target: { name: 'status', value: newStatuses } });
  };

  const onRemoveClick = (status) => {
    const newStatuses = _.xor(statuses, [status]);
    setStatuses(newStatuses);
    onChange({ target: { name: 'status', value: newStatuses } });
  };

  const handleToggle = () => {
    setHidden(!hidden);
  };

  const isDisabled = (status) => {
    return _.indexOf(statuses, status) > -1;
  };

  const onTagsChange = (newTags) => {
    const updatedTags = [...newTags];
    setTags(updatedTags);
    onChange({ target: { name: 'tags', value: updatedTags } });
  };

  const renderDropdown = () => {
    return (
      <div className="input">
        <select name="status" onChange={onDropdownChange}>
          {defaultStatuses.map((status) => (
            <option
              key={status}
              value={status}
              disabled={isDisabled(status)}
              onClick={onDropdownChange}
            >
              {status}
            </option>
          ))}
        </select>
        {statuses.map((status, key) => (
          <div key={key}>
            {status}
            <a href="#" onClick={() => onRemoveClick(status)}>
              X
            </a>
          </div>
        ))}
      </div>
    );
  };

  const onDate1Change = (day, modifiers, dayPickerInput) => {
    const inputDay = dayPickerInput.getInput().value;
    if (day !== undefined) {
      let dayYear = day.getFullYear().toString();
      if (dayYear.length > 3) {
        onChange({ target: { name: 'date1', value: dayjs(day).format(FORMAT) } });
      }
    } else if (inputDay !== '') {
      onChange({ target: { name: 'date1', value: inputDay } });
    } else {
      onChange({ target: { name: 'date1', value: '' } });
    }
  };

  const onDate2Change = (day, modifiers, dayPickerInput) => {
    const inputDay = dayPickerInput.getInput().value;
    if (day !== undefined) {
      let dayYear = day.getFullYear().toString();
      if (dayYear.length > 3) {
        onChange({ target: { name: 'date2', value: dayjs(day).format(FORMAT) } });
      }
    } else if (inputDay !== '') {
      onChange({ target: { name: 'date2', value: inputDay } });
    } else {
      onChange({ target: { name: 'date2', value: '' } });
    }
  };

  const { by_title, post_code1, post_code2, date1, date2 } = data;

  function parseDate(str, format, locale) {
    const parsed = dayjs(str, format, locale);
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  function formatDate(date, format, locale) {
    return dayjs(date, format, locale);
  }

  const FORMAT = 'DD/MM/YYYY';

  return (
    <div className="filtering-options">
      <div id="titlebar" className="page-title" onClick={handleToggle}>
        <h1 className={hidden ? 'inactive' : 'active'}>Filtering Options</h1>
      </div>

      <div className={hidden ? 'hidden' : 'search-welcome'}>
        <div className="item">
          <label>
            <input
              type="checkbox"
              name="by_title"
              onChange={onChange}
              defaultChecked={!!by_title}
            />{' '}
            Search By Title
          </label>
        </div>

        <div className="item hidden">
          <label>
            <input
              type="checkbox"
              name="filter_out_scout_client"
              defaultChecked={canFilterScoutClients}
            />{' '}
            Filter out Scout Candidates
          </label>
        </div>

        <div className="item">
          <div className="title">Post code:</div>
          <div className="input">
            <input type="text" name="post_code1" onChange={onChange} value={post_code1} />
            &nbsp;&mdash;&nbsp;
            <input type="text" name="post_code2" onChange={onChange} value={post_code2} />
          </div>
        </div>

        <div className="item">
          <div className="title">Status:</div>
          {renderDropdown()}
        </div>

        <div className="item">
          <div className="title">Date:</div>
          <div className="input">
            <DayPickerInput
              name="date1"
              placeholder="DD/MM/YYYY"
              value={date1}
              formatDate={formatDate}
              parseDate={parseDate}
              format={FORMAT}
              onDayChange={onDate1Change}
            />
            &nbsp;&mdash;&nbsp;
            <DayPickerInput
              name="date2"
              placeholder="DD/MM/YYYY"
              value={date2}
              formatDate={formatDate}
              parseDate={parseDate}
              format={FORMAT}
              onDayChange={onDate2Change}
            />
          </div>
        </div>

        {defaultTags && (
          <div className="item">
            <div className="title">Tags:</div>
            <ScoutSelect
              name="tags"
              values={defaultTags}
              onChange={onTagsChange}
              data={tags}
              multi={true}
            />
          </div>
        )}

        <div className="buttons" style={{ width: '18%' }}>
          <input
            type="submit"
            value="Apply"
            className="apply-button"
            style={{ width: '100%' }}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

FilteringOptionsTable.propTypes = {
  defaultStatuses: PropTypes.array,
  defaultTags: PropTypes.array,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.object,
  tags: PropTypes.array,
  canFilterScoutClients: PropTypes.bool
};
export default FilteringOptionsTable;
