import React, { Dispatch, SetStateAction } from 'react';
import { Collapse, Button, Stack } from '@mui/material';
import {
  Email as EmailIcon,
  Smartphone as SmartphoneIcon,
  Rule as RuleIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';
import { IUserPermissions } from '../../NewUI/Components/sharedTypes';

export default function ActionButtons({
  selectedCandidates,
  permissions,
  setAction,
  openDialog
}: {
  selectedCandidates: number[];
  permissions: IUserPermissions | null;
  setAction: Dispatch<SetStateAction<string>>;
  openDialog: (action: string) => void;
}) {
  return (
    <Collapse in={selectedCandidates.length > 0}>
      <Stack sx={{ flexDirection: 'row', columnGap: 2, paddingTop: 0.5 }}>
        {permissions?.['Events']?.['Send emails in bulk on events'] && (
          <Button
            id="email-candidates-from-event-button"
            startIcon={<EmailIcon />}
            sx={{ ...sharedClasses.genericButtonSecondary, padding: '6px 16px' }}
            onClick={() => setAction('Email')}
          >
            Email
          </Button>
        )}
        {permissions?.['Events']?.['Send SMS in bulk on events'] && (
          <Button
            id="sms-candidates-from-event-button"
            startIcon={<SmartphoneIcon />}
            sx={{ ...sharedClasses.genericButtonSecondary, padding: '6px 16px' }}
            onClick={() => setAction('SMS')}
          >
            SMS
          </Button>
        )}
        {permissions?.['Events']?.['Change candidates status in bulk on events'] && (
          <Button
            id="change-candidates-status-from-event-button"
            startIcon={<RuleIcon />}
            sx={{ ...sharedClasses.genericButtonSecondary, padding: '6px 16px' }}
            onClick={() => setAction('Change status')}
          >
            Change Status
          </Button>
        )}
        <Button
          id="remove-candidates-from-event-button"
          startIcon={<DeleteIcon />}
          sx={{ ...sharedClasses.genericRedButton, '&:focus': { outline: 'none' } }}
          onClick={() => openDialog('Remove')}
        >
          Remove
        </Button>
      </Stack>
    </Collapse>
  );
}
