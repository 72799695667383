"use strict";
import { theme } from "../../../../ThemeContext/ThemeObject";
export const classes = {
  widget: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    flex: "40%",
    minWidth: "600px"
  },
  subcontainer: {
    flex: "40%",
    minWidth: "600px",
    display: "flex",
    flexWrap: "wrap",
    columnGap: "50px",
    rowGap: "50px"
  },
  subWidget: {
    marginTop: "24px",
    flex: "40%",
    borderRadius: "3px",
    height: "100px",
    minWidth: "240px"
  },
  widgetIcon: {
    backgroundColor: "#EDF2F5",
    padding: "12px",
    borderRadius: "16px",
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "32px"
    }
  },
  widgetText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    rowGap: "6px",
    marginLeft: "30px"
  },
  widgetTextTitle: {
    fontSize: "12px",
    color: theme.palette.primary.main
  },
  widgetTextContent: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#333333"
  },
  widgetBlockContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    columnGap: "50px"
  },
  widgetBlock: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    height: "100%"
  },
  widgetBlockText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "12px"
  },
  blockCount: {
    fontSize: "24px",
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  blockTitle: {
    fontSize: "10px",
    fontWeight: "regular",
    color: "#939393"
  },
  widgetBlockIcon: {
    backgroundColor: "#EDF2F5",
    padding: "0px",
    borderRadius: "16px"
  }
};
