import React, { useState } from 'react';
import {
  Box,
  FormControl,
  IconButton,
  Select,
  Skeleton,
  MenuItem,
  Pagination,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  styled,
  Stack,
  Popover
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  FileCopy as FileCopyIcon
} from '@mui/icons-material';
import { ISmartFormsTableProps, IError, ModalType, ISmartForm } from './types';
import { SmartFormsTableHeaders } from './config';
import { classes } from '../Job/Applications/styles';
import GenericDialog from '../Components/Modals/GenericDialog';
import { useMutation } from '@tanstack/react-query';
import Api from './API';
import moment from 'moment-timezone';

const tablePadding = (density: string) => {
  if (density === 'Default') {
    return '22px';
  } else if (density === 'Compact') {
    return '15px';
  } else {
    return '8px';
  }
};

const DeleteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({ [`& .${tooltipClasses.tooltip}`]: { maxWidth: '200px', lineHeight: '1.75em' } });

export default function SmartFormsTable({
  forms,
  loadingForms,
  density,
  totalPages,
  totalForms,
  SmartFormsState,
  dispatch,
  refetchForms
}: ISmartFormsTableProps) {
  const { sortBy, sortOrder, rowsPerPage, currentPage, isDialogOpen, deleteSmartForm } =
    SmartFormsState;
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [enabledAnchorEl, setEnabledAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedForm, setSelectedForm] = useState<ISmartForm | null>(null);

  const handleSortChange = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    columnValue: string
  ) => {
    const isAsc = sortBy === columnValue && sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    dispatch({ type: 'SET_SORT_ORDER', payload: newSortOrder });
    dispatch({ type: 'SET_SORT_BY', payload: columnValue });
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage === currentPage) return;
    dispatch({ type: 'SET_CURRENT_PAGE', payload: newPage });
    sessionStorage.setItem(`smartFormsTablePage`, newPage.toString());
  };

  const deleteForm = useMutation({
    mutationFn: async (formId: number) => {
      const { res } = await Api.deleteSmartForm(formId);
      return res;
    },
    onError: (error: IError) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Failed to delete smart form, ${error.errors.join('. ')}`,
          state: 'error'
        }
      }),
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      refetchForms();
    },
    onSettled: () => {
      dispatch({ type: 'SET_IS_DIALOG_OPEN', payload: false });
    }
  });

  const { mutate: updateSmartForm } = useMutation({
    mutationFn: async (): Promise<void> => {
      const body = {
        name: selectedForm?.title,
        entity_id: selectedForm?.entity_id,
        enabled: !selectedForm?.enabled
      };

      await Api.updateSmartForm(selectedForm?.id, { form: body });
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error updating the smart form, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: (data) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Smart form was successfully updated`,
          state: 'success'
        }
      });
      refetchForms();
    },
    onSettled: async () => selectedForm
  });

  const handleEnabledDropdown = (event: React.MouseEvent<HTMLElement>, form: ISmartForm) => {
    setEnabledAnchorEl(event.currentTarget);
    setSelectedForm(form);
  };

  const handleEnabledDropdownClose = () => {
    setEnabledAnchorEl(null);
    setTimeout(() => {
      // timeout is used to account for the dropdown close animation time
      setSelectedForm(null);
    }, 300);
  };

  const handleEnabledChange = () => {
    updateSmartForm();
    handleEnabledDropdownClose();
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ position: 'relative' }}>
        <TableContainer sx={{ boxShadow: 'none', scrollBehavior: 'smooth' }}>
          <Table
            sx={{
              ...classes.tableWrapper,
              minWidth: 'unset',
              'th, td, tr, thead': { padding: `${tablePadding(density)} 10px` }
            }}
            aria-label="smart forms"
          >
            <TableHead>
              <TableRow>
                {SmartFormsTableHeaders.map((header) => (
                  <TableCell key={header.name}>
                    <TableSortLabel
                      active={sortBy === header.name}
                      direction={sortBy === header.name ? (sortOrder as 'asc' | 'desc') : 'asc'}
                      onClick={(event) => handleSortChange(event, header.name)}
                      sx={header.sortable ? classes.sortable : null}
                      disabled={!header.sortable}
                      id={`${header.name}-sort-label-smart-forms`}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {forms &&
                !loadingForms &&
                forms.map((form) => {
                  const updatedAt = form.updated_at
                    ? moment
                        .tz(form.updated_at, timeZoneString)
                        .format('DD MMM YYYY,-h:mma z')
                        .split('-')
                        .map((line) => <div key={`${form.id}-${line}`}>{line}</div>)
                    : '-';
                  const createdAt = form.created_at
                    ? moment
                        .tz(form.created_at, timeZoneString)
                        .format('DD MMM YYYY,-h:mma z')
                        .split('-')
                        .map((line) => <div key={`${form.id}-${line}`}>{line}</div>)
                    : '-';
                  return (
                    <TableRow key={form.id}>
                      <TableCell sx={{ minWidth: '200px' }}>
                        <Box
                          sx={{
                            '& a': {
                              color: '#666666',
                              fontWeight: 'bold',
                              '&:hover': {
                                textDecoration: 'none'
                              }
                            }
                          }}
                        >
                          <a href={`/admin/forms/${form.id}`} className="smart-form-title-link">
                            {form.title || '-'}
                          </a>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }}>
                        <Stack id="enabled-dropdown" sx={{ marginLeft: 2 }}>
                          {form.enabled ? (
                            <CheckCircleIcon
                              sx={{ color: '#8CCF95' }}
                              onClick={(event) => handleEnabledDropdown(event, form)}
                            />
                          ) : (
                            <CancelIcon
                              sx={{ color: '#E37D7A' }}
                              onClick={(event) => handleEnabledDropdown(event, form)}
                            />
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ minWidth: '150px' }}>{updatedAt}</TableCell>
                      <TableCell sx={{ minWidth: '150px' }}>{createdAt}</TableCell>
                      <TableCell sx={{ minWidth: '150px' }}>{form.created_by || '-'}</TableCell>
                      <TableCell>
                        <Box sx={{ ...classes.actionItems, columnGap: '4px' }}>
                          <IconButton
                            className="smart-form-edit-button"
                            onClick={() => {
                              window.location.href = `/admin/forms/${form.id}/fields`;
                            }}
                          >
                            <EditIcon sx={{ fontSize: '18px' }} />
                          </IconButton>
                          <IconButton
                            className="smart-form-clone-button"
                            onClick={() => {
                              dispatch({ type: 'SET_SELECTED_FORM', payload: form });
                              dispatch({
                                type: 'SET_MODAL_OPEN',
                                payload: ModalType.CLONE
                              });
                            }}
                          >
                            <FileCopyIcon sx={{ fontSize: '18px' }} />
                          </IconButton>
                          <DeleteTooltip
                            title={
                              !form.deletable
                                ? 'This smart form is attached to a candidate/s and cannot be trashed.'
                                : ''
                            }
                            placement="top"
                            arrow
                          >
                            <Stack>
                              <IconButton
                                className="smart-form-delete-button"
                                sx={{ color: '#E37D7A' }}
                                onClick={() => {
                                  dispatch({ type: 'SET_IS_DIALOG_OPEN', payload: true });
                                  dispatch({ type: 'SET_DELETE_SMART_FORM', payload: form.id });
                                }}
                                disabled={!form.deletable}
                              >
                                <DeleteIcon sx={{ fontSize: '18px' }} />
                              </IconButton>
                            </Stack>
                          </DeleteTooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!loadingForms && forms?.length === 0 && (
            <Box sx={classes.noApplications}>No Smart Forms found</Box>
          )}
          {loadingForms && (
            <>
              {[...Array(10)].map((_, index) => (
                <Box key={index} sx={classes.skeletonContainer}>
                  <Skeleton animation="wave" height={60} />
                </Box>
              ))}
            </>
          )}
        </TableContainer>
        <Popover
          id={enabledAnchorEl ? 'enabled-selection' : undefined}
          sx={classes.enabledDropdown}
          open={Boolean(enabledAnchorEl)}
          anchorEl={enabledAnchorEl}
          onClose={handleEnabledDropdownClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <span key={`${selectedForm?.id}-enabled`} onClick={handleEnabledChange}>
            {selectedForm?.enabled ? (
              <CancelIcon sx={{ color: '#E37D7A' }} />
            ) : (
              <CheckCircleIcon sx={{ color: '#8CCF95' }} />
            )}
          </span>
        </Popover>
      </Box>
      {!loadingForms && (
        <Box sx={classes.paginationParent}>
          <Box sx={classes.rowSelectContainer}>
            <span>Rows per page: </span>
            <FormControl>
              <Select
                id="rows-per-page"
                sx={classes.rowSelect}
                value={rowsPerPage}
                onChange={(e) =>
                  dispatch({ type: 'SET_ROWS_PER_PAGE', payload: Number(e.target.value) })
                }
                displayEmpty
                MenuProps={{ sx: classes.paginationMenuItems }}
              >
                <MenuItem id="first-rpp-item" value={10}>
                  10
                </MenuItem>
                <MenuItem id="second-rpp-item" value={20}>
                  20
                </MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={classes.paginationContainer}>
            <Pagination
              id="pagination-menu"
              count={Number(totalPages)}
              page={currentPage}
              siblingCount={0}
              onChange={handlePageChange}
              sx={classes.pagination}
            />
            <span id="total-forms">{totalForms} Total</span>
          </Box>
        </Box>
      )}
      <GenericDialog
        isDialogOpen={isDialogOpen}
        setDialogOpen={(value: boolean) => dispatch({ type: 'SET_IS_DIALOG_OPEN', payload: value })}
        title={`Are you sure you want to trash this form?`}
        description=""
        buttonCallback={() => deleteForm.mutate(deleteSmartForm)}
        callbackLoading={deleteForm.isLoading}
        buttonText="Trash"
        url=""
      />
    </Box>
  );
}
