import React from 'react';
import { classes } from './styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
export interface IEmailPreviewModalProps {
  title: string;
  subject: string;
  body: string;
  isDialogOpen: boolean;
  dialogId: number;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function EmailPreviewModal({
  title,
  subject,
  body,
  isDialogOpen,
  setDialogOpen,
  dialogId
}: IEmailPreviewModalProps) {
  return (
    <Modal
      id={`${dialogId}`}
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
    >
      <Box sx={classes.attachmentPreviewModal}>
        <CloseIcon onClick={() => setDialogOpen(false)} sx={classes.closeIcon} />
        <Box id="modal-modal-title" sx={classes.attachmentPreviewTitle}>
          {title}
        </Box>
        {
          subject && body ? 
            <Box sx={classes.attachmentPreviewContent}>{<div dangerouslySetInnerHTML={{ __html: `<h3>${subject}</h3>${body}` }} />}</Box>
            : <Box sx={classes.noPreview}>No email template.</Box>
        }
        <Box sx={classes.attachmentPreviewActions}>
          <Button onClick={() => setDialogOpen(false)} sx={classes.downloadButton}>Close</Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default EmailPreviewModal;
