import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button, IconButton, CircularProgress, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { classes } from '../styles';
import { IQuestionBasedProps } from '../../types';
import FormSelectField from '../../../Components/CustomUIElements/FormSelectField';
import NoAutoStatusSVG from './NoAutoStatusSVG';
import Switch from '@mui/material/Switch';

export default function QuestionBased({
  apiKey,
  jobId,
  setSnackbar,
  autoStatuses,
  setAutoStatuses,
  applicationStatuses,
  hasRadioButtonQuestions,
  handleEditTabChange
}: IQuestionBasedProps) {
  const [isLoading, setIsLoading] = useState({
    new: false,
    delete: -1,
    addField: -1,
    deleteField: [-1, -1],
    updateField: [-1, -1]
  });
  const [hoveredField, setHoveredField] = useState([-1, -1]);

  const [radioQuestions, setRadioQuestions] = useState([]);

  const getRadioButtonQuestions = useCallback(async () => {
    try {
      const response = await fetch(`/api/v4/fields?job_id=${jobId}`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setRadioQuestions(response.fields.filter((question) => question.type === 'RadioButtonField'));
    } catch (error) {
      console.log(error);
    }
  }, [apiKey, jobId]);

  const handleAddNewAutoStatus = async () => {
    setIsLoading({ ...isLoading, new: true });
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_status_question_based`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          auto_status_question_based: {
            status_id: applicationStatuses[0].id,
            auto_status_conditions_attributes: {
              0: {
                field_id: radioQuestions[0]?.id,
                field_choice_id: radioQuestions[0]?.field_choices[0]?.id
              }
            }
          }
        })
      });
      if (response.ok) {
        const data = await response.json();
        setAutoStatuses([...autoStatuses, data]);
      } else {
        throw new Error('Error adding auto status');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error creating auto status',
        state: 'error'
      });
    } finally {
      setIsLoading({ ...isLoading, new: false });
    }
  };

  const handleNewAutoStatusField = async (index: number) => {
    const tempAutoStatus = JSON.parse(JSON.stringify(autoStatuses));
    const filteredQuestion = radioQuestions?.filter(
      (question) => !tempAutoStatus[index].fields.map((f) => f.question.id).includes(question.id)
    );
    tempAutoStatus[index].fields.push({
      question: {
        id: filteredQuestion[0].id,
        title: filteredQuestion[0].title
      },
      choice: {
        id: filteredQuestion[0].field_choices[0].id,
        name: filteredQuestion[0].field_choices[0].name
      }
    });
    setIsLoading({ ...isLoading, addField: index });
    const payload = {
      auto_status_question_based: {
        status_id: tempAutoStatus[index].status.id,
        auto_status_conditions_attributes: {}
      }
    };
    tempAutoStatus[index].fields.forEach((field, i) => {
      payload.auto_status_question_based.auto_status_conditions_attributes[i] = {
        id: field.id,
        field_id: field.question.id,
        field_choice_id: field.choice.id
      };
    });
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/auto_status_question_based/${autoStatuses[index].id}`,
        {
          method: 'PUT',
          headers: {
            'X-api-authenticate': apiKey,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }
      );
      const data = await response.json();
      if (response.ok) {
        tempAutoStatus[index] = data;
        setAutoStatuses(tempAutoStatus);
      } else {
        throw new Error('Error adding auto status field');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error adding auto status field',
        state: 'error'
      });
    } finally {
      setIsLoading({ ...isLoading, addField: -1 });
    }
  };

  const handleRemoveField = async (index: number, fieldIndex: number) => {
    setIsLoading({ ...isLoading, deleteField: [index, fieldIndex] });
    const tempAutoStatus = JSON.parse(JSON.stringify(autoStatuses));
    tempAutoStatus[index].fields.splice(fieldIndex, 1);
    setIsLoading({ ...isLoading, deleteField: [index, fieldIndex] });
    const payload = {
      auto_status_question_based: {
        status_id: tempAutoStatus[index].status.id,
        auto_status_conditions_attributes: {}
      }
    };
    tempAutoStatus[index].fields.forEach((field, i) => {
      payload.auto_status_question_based.auto_status_conditions_attributes[i] = {
        id: field.id,
        field_id: field.question.id,
        field_choice_id: field.choice.id
      };
    });
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/auto_status_question_based/${autoStatuses[index].id}`,
        {
          method: 'PUT',
          headers: {
            'X-api-authenticate': apiKey,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }
      );
      const data = await response.json();
      if (response.ok) {
        tempAutoStatus[index] = data;
        setAutoStatuses(tempAutoStatus);
      } else {
        throw new Error('Error removing auto status field');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error removing auto status field',
        state: 'error'
      });
    } finally {
      setIsLoading({ ...isLoading, deleteField: [-1, -1] });
    }
  };

  const handleDeleteAutoStatus = async (index: number) => {
    const autoStatusesCopy = JSON.parse(JSON.stringify(autoStatuses));
    autoStatusesCopy.splice(index, 1);
    setIsLoading({ ...isLoading, delete: index });
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/auto_status_question_based/${autoStatuses[index].id}`,
        {
          method: 'DELETE',
          headers: {
            'X-api-authenticate': apiKey,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.ok) {
        setAutoStatuses(autoStatusesCopy);
      } else {
        throw new Error('Error deleting auto status');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error deleting auto status',
        state: 'error'
      });
    } finally {
      setIsLoading({ ...isLoading, delete: -1 });
    }
  };

  const handleChangeStatus = async (index: number, statusId: number) => {
    const prevAutoStatuses = JSON.parse(JSON.stringify(autoStatuses));
    const tempAutoStatus = JSON.parse(JSON.stringify(autoStatuses));
    tempAutoStatus[index].status.id = statusId;
    setAutoStatuses(tempAutoStatus);
    const payload = {
      auto_status_question_based: {
        status_id: statusId,
        auto_status_conditions_attributes: {}
      }
    };
    tempAutoStatus[index].fields.forEach((field, i) => {
      payload.auto_status_question_based.auto_status_conditions_attributes[i] = {
        id: field.id,
        field_id: field.question.id,
        field_choice_id: field.choice.id
      };
    });
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/auto_status_question_based/${autoStatuses[index].id}`,
        {
          method: 'PUT',
          headers: {
            'X-api-authenticate': apiKey,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }
      );
      if (!response.ok) {
        setAutoStatuses(prevAutoStatuses);
        throw new Error('Error updating auto status');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error updating auto status',
        state: 'error'
      });
    }
  };

  const handleChangeField = async (
    index: number,
    fieldIndex: number,
    value: number,
    type: 'q' | 'a'
  ) => {
    const prevAutoStatuses = JSON.parse(JSON.stringify(autoStatuses));
    const tempAutoStatus = JSON.parse(JSON.stringify(autoStatuses));
    if (type === 'q') {
      setIsLoading({ ...isLoading, updateField: [index, fieldIndex] });
      tempAutoStatus[index].fields[fieldIndex].question.id = value;
      tempAutoStatus[index].fields[fieldIndex].choice.id = radioQuestions.find(
        (question) => question.id === value
      )?.field_choices[0].id;
    } else {
      tempAutoStatus[index].fields[fieldIndex].choice.id = value;
    }
    setAutoStatuses(tempAutoStatus);
    const payload = {
      auto_status_question_based: {
        status_id: tempAutoStatus[index].status.id,
        auto_status_conditions_attributes: {}
      }
    };
    tempAutoStatus[index].fields.forEach((field, i) => {
      payload.auto_status_question_based.auto_status_conditions_attributes[i] = {
        id: field.id,
        field_id: field.question.id,
        field_choice_id: field.choice.id
      };
    });
    try {
      const response = await fetch(
        `/api/v4/jobs/${jobId}/auto_status_question_based/${autoStatuses[index].id}`,
        {
          method: 'PUT',
          headers: {
            'X-api-authenticate': apiKey,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }
      );
      if (!response.ok) {
        setAutoStatuses(prevAutoStatuses);
        throw new Error('Error updating auto status field');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error updating auto status field',
        state: 'error'
      });
    } finally {
      setIsLoading({ ...isLoading, updateField: [-1, -1] });
    }
  };

  const handleEnableStatus = async (index: number, id: number) => {
    const prevAutoStatuses = JSON.parse(JSON.stringify(autoStatuses));
    const tempAutoStatus = JSON.parse(JSON.stringify(autoStatuses));
    tempAutoStatus[index].enabled = !autoStatuses[index].enabled;
    setAutoStatuses(tempAutoStatus);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/auto_status_question_based/${id}`, {
        method: 'PUT',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          enabled: tempAutoStatus[index].enabled
        })
      });
      if (!response.ok) {
        setAutoStatuses(prevAutoStatuses);
        throw new Error('Error updating auto status');
      }
    } catch (error) {
      setSnackbar({
        message: 'There was an error saving your changes',
        state: 'error'
      });
    }
  };

  useEffect(() => {
    getRadioButtonQuestions();
  }, [getRadioButtonQuestions]);

  return (
    <Box sx={classes.autoStatusesContainer}>
      <Box sx={{ position: 'relative' }}>
        {hasRadioButtonQuestions ? (
          <>
            {autoStatuses &&
              autoStatuses?.map((autoStatus, statusIndex) => (
                <Box sx={{ position: 'relative', display: 'flex' }} key={autoStatus.id}>
                  <Box sx={classes.autoStatusesSubContainer} key={autoStatus.id}>
                    {autoStatus.fields?.map((field, fieldIndex) => (
                      <Box
                        sx={classes.statusFormLine}
                        key={field.id}
                        onMouseEnter={() => setHoveredField([statusIndex, fieldIndex])}
                        onMouseLeave={() => setHoveredField([-1, -1])}
                      >
                        <Box
                          component={'h4'}
                          sx={{ marginRight: fieldIndex === 0 ? '31px' : '8px' }}
                        >
                          {fieldIndex === 0 ? 'If' : 'and'}
                        </Box>
                        <FormSelectField
                          label="Variable"
                          isDisabled={!radioQuestions}
                          defaultValue={field.question.id ? field.question.id.toString() : ''}
                          options={radioQuestions
                            .filter(
                              (question) =>
                                question.id === field.question.id ||
                                !autoStatus.fields?.map((f) => f.question.id).includes(question.id)
                            )
                            ?.map((question) => ({
                              value: question.id.toString(),
                              label: question.title
                            }))}
                          onChange={(value) =>
                            handleChangeField(statusIndex, fieldIndex, Number(value), 'q')
                          }
                          styles={{ width: '290px' }}
                        />
                        <h4>equals</h4>
                        <FormSelectField
                          label="Answer"
                          options={radioQuestions
                            .filter(
                              (question) =>
                                question.id === field.question.id || field.question.id === 0
                            )[0]
                            ?.field_choices?.map((choice) => ({
                              value: choice.id.toString(),
                              label: choice.name
                            }))}
                          defaultValue={field.choice.id ? field.choice.id.toString() : ''}
                          onChange={(value) =>
                            handleChangeField(statusIndex, fieldIndex, Number(value), 'a')
                          }
                          styles={{ width: '290px' }}
                          isDisabled={
                            !autoStatuses[statusIndex]?.fields[fieldIndex]?.question.id ||
                            (isLoading.updateField[0] === statusIndex &&
                              isLoading.updateField[1] === fieldIndex)
                          }
                        />
                        {hoveredField[1] > 0 &&
                          hoveredField.join('') === `${statusIndex}${fieldIndex}` && (
                            <IconButton
                              onClick={() => handleRemoveField(statusIndex, fieldIndex)}
                              sx={classes.clearButton}
                            >
                              {isLoading.deleteField.join('') === `${statusIndex}${fieldIndex}` ? (
                                <CircularProgress size={20} />
                              ) : (
                                <ClearIcon />
                              )}
                            </IconButton>
                          )}
                      </Box>
                    ))}
                    {autoStatus?.fields?.length < radioQuestions?.length && (
                      <Box sx={classes.statusFormLine}>
                        <Button
                          startIcon={
                            isLoading.addField === statusIndex ? (
                              <CircularProgress size={22} />
                            ) : (
                              <AddIcon />
                            )
                          }
                          onClick={() => handleNewAutoStatusField(statusIndex)}
                          sx={classes.addNewFieldButton}
                        >
                          and
                        </Button>
                      </Box>
                    )}
                    <Box sx={classes.statusFormLine}>
                      <h4>then</h4>
                      <FormSelectField
                        label="Status"
                        options={
                          applicationStatuses
                            ? applicationStatuses?.map((status) => ({
                                value: status.id.toString(),
                                label: status.name
                              }))
                            : []
                        }
                        defaultValue={autoStatus.status.id ? autoStatus.status.id.toString() : ''}
                        onChange={(value) => handleChangeStatus(statusIndex, Number(value))}
                        styles={{ width: '290px' }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      ...classes.switchContainer,
                      fontWeight: autoStatus.enabled ? 'bold' : ''
                    }}
                  >
                    <Box sx={{ marginTop: '12px' }}>
                      <Switch
                        sx={autoStatus.enabled ? classes.switchActive : classes.switch}
                        checked={autoStatus.enabled}
                        size="small"
                        onChange={() => handleEnableStatus(statusIndex, autoStatus.id)}
                      />
                      <Stack sx={{ marginTop: 1, fontSize: '12px' }}>Enabled</Stack>
                    </Box>
                  </Box>
                  <IconButton
                    data-testid="delete-auto-status"
                    sx={classes.deleteButton}
                    onClick={() => handleDeleteAutoStatus(statusIndex)}
                    disabled={isLoading.delete === statusIndex}
                  >
                    {isLoading.delete === statusIndex ? (
                      <CircularProgress size={26} />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                </Box>
              ))}
            {autoStatuses && autoStatuses.length > 0 ? (
              <IconButton
                sx={classes.addNewStatusButton}
                onClick={handleAddNewAutoStatus}
                disabled={isLoading.new}
              >
                {isLoading.new ? <CircularProgress size={21} /> : <AddCircleIcon />}
              </IconButton>
            ) : (
              <Box sx={{ ...classes.noQuestionsContainer, margin: '100px 0px' }}>
                <Box>
                  <NoAutoStatusSVG />
                </Box>
                <Box sx={classes.noQuestionsText}>You have no auto statuses.</Box>
                <Button
                  data-testid="add-new-status-button"
                  startIcon={isLoading.new ? <CircularProgress size={26} /> : <AddCircleIcon />}
                  onClick={handleAddNewAutoStatus}
                  sx={classes.noStatusesAddButton}
                >
                  Add new status
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Box sx={{ ...classes.noQuestionsContainer, margin: '100px 0px' }}>
            <Box>
              <NoAutoStatusSVG />
            </Box>
            <Box sx={classes.noQuestionsText}>
              Question based auto statuses can only be used with radio button question types.
              <br />
              You have currently no radio button questions.
            </Box>
            <Button
              data-testid="create-radio-button"
              startIcon={isLoading.new ? <CircularProgress size={26} /> : <AddCircleIcon />}
              onClick={(e) => handleEditTabChange(e, 4)}
              sx={classes.noStatusesAddButton}
            >
              Create radio button questions
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
