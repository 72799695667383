"use strict";
export const navbarWidths = {
  expanded: 220,
  collapsed: 82
};
export const appMenuItems = (isRecruit) => [
  !isRecruit && {
    title: ":Recruit",
    link: "/admin/jobs",
    target: "_self"
  },
  {
    title: ":Onboard",
    link: "https://scouttalentonboard.com/",
    target: "_blank"
  },
  isRecruit && {
    title: ":Engage",
    link: "/admin/engage?engage-search",
    target: "_self"
  },
  {
    title: "Scout Learning",
    link: "https://scouttalenthq.com/talent-acquisition-software/scout-learning",
    target: "_blank"
  },
  {
    title: "Marketplace",
    link: "https://scouttalenthq.com/marketplace",
    target: "_blank"
  }
];
export const defaultMenuItems = (newEngage, isEngage) => isEngage ? [
  {
    title: "Home",
    icon: "home",
    id: "engage-home-left-tab",
    submenu: [
      {
        title: "Search",
        id: "engage-home-left-search",
        url: "/admin/search/advanced",
        active: "/admin/search/advanced"
      }
    ]
  },
  {
    title: "Tools",
    icon: "spanner",
    id: "engage-spanner-left-tab",
    submenu: [
      {
        title: "Saved Searches",
        url: "/admin/search/advanced/saved",
        active: "/admin/search/advanced/saved",
        permissions: ["Advanced Search"],
        id: "engage-saved-searches-left-tab"
      }
    ]
  },
  ...newEngage ? [
    {
      title: "Engage Beta",
      icon: "people_search",
      id: "engage-left-tab",
      submenu: [
        {
          title: "AI Search",
          url: "/admin/engage?engage-search",
          active: "/admin/engage\\?engage-search",
          id: "ai-search-left-tab"
        },
        {
          title: "Candidate Pools",
          url: "/admin/engage?candidate-pools",
          active: "/admin/engage\\?candidate-pools",
          id: "candidate-pools-left-tab"
        }
      ]
    }
  ] : []
] : [
  {
    title: "Recruitment",
    id: "recruitment-tab-sidebar",
    icon: "people_search",
    submenu: [
      {
        title: "Approval Forms",
        url: "/admin/requisitions",
        active: "/admin/requisitions",
        permissions: [
          "Create / Edit Approval Forms (HR use)",
          "Edit Previously Submitted Form",
          "Submit Approval Form"
        ],
        id: "approval-forms-tab-sidebar"
      },
      {
        title: "Jobs",
        url: "/admin/jobs",
        active: "/admin/(seek_)?jobs",
        id: "jobs-tab-sidebar"
      },
      {
        title: "Candidates",
        url: "/admin/candidates",
        active: "/admin/candidates",
        permissions: ["View Candidates"],
        id: "candidates-tab-sidebar"
      },
      {
        title: "Events",
        url: "/admin/calendar/events",
        active: "/admin/calendar",
        permissions: ["Create / Edit Events"],
        id: "events-tab-sidebar"
      },
      {
        title: "Smart Forms",
        url: "/admin/forms",
        active: "/admin/forms",
        permissions: ["Create / Edit Forms"],
        id: "smart-forms-tab-sidebar"
      },
      {
        title: "Full Search",
        url: "/admin/search/full",
        active: "/admin/search/(full|results)",
        permissions: ["Full Search"],
        id: "full-search-tab-sidebar"
      }
    ]
  },
  {
    title: "Admin",
    icon: "shield",
    submenu: [
      {
        title: "Entities",
        url: "/admin/entities",
        active: "/admin/entities",
        permissions: ["Edit Entities"],
        id: "entities-tab-sidebar"
      },
      {
        title: "Reports",
        url: "/admin/reports",
        active: "/admin/reports",
        permissions: [
          "Applications by Source & Entity Reports",
          "Placements Advanced Report"
        ],
        id: "reports-tab-sidebar"
      },
      {
        title: "Trash",
        url: "/admin/trash",
        active: "/admin/trash",
        permissions: ["Manage Trashed Jobs / Applications"],
        id: "trash-tab-sidebar"
      },
      {
        title: "Reports (Beta)",
        url: "/admin/new_reports",
        active: "/admin/new_reports",
        permissions: ["Beta Access to New Reporting"],
        id: "new-reports-tab-sidebar"
      }
    ]
  },
  {
    title: "Tools",
    icon: "spanner",
    submenu: [
      {
        title: "CSV Data",
        url: "/admin/csv-data",
        active: "/admin/csv-data",
        id: "csv-tab-sidebar"
      },
      {
        title: "Internal Tools",
        url: "/admin/internal_tools/index",
        active: "/admin/internal_tools",
        id: "internal-tools-tab-sidebar"
      },
      {
        title: "System Fields",
        url: "/admin/system_fields",
        active: "/admin/system_fields",
        id: "system-fields-tab-sidebar"
      },
      {
        title: "Integrations",
        url: "/admin/integrations",
        active: "/admin/integrations",
        id: "integrations-tab-sidebar"
      },
      {
        title: "Configuration",
        url: "/admin/configurations",
        active: "/admin/configuration",
        id: "configuration-tab-sidebar"
      },
      {
        title: "News",
        url: "/admin/news",
        active: "/admin/news",
        id: "news-tab-sidebar"
      },
      {
        title: "Menu",
        url: "/admin/service_menu_items",
        active: "/admin/.*menu_items",
        id: "menu-tab-sidebar"
      },
      {
        title: "Unsubscribes",
        url: "/admin/unsubscribes",
        active: "/admin/unsubscribes",
        id: "unsubscribes-tab-sidebar"
      }
    ]
  }
];
