import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Modal,
  Button,
  Autocomplete,
  TextField,
  Skeleton,
  CircularProgress
} from '@mui/material';
import CancelButton from '../shared/CancelButton';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { IAutoReplyProps } from '../../types';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { IApplicationStatus } from '../../../Job/types';
import dompurify from 'dompurify';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { stringToSeconds } from '../../../Components/Utilities/stringToSeconds';
import { secondsToString } from '../../../Components/Utilities/secondsToString';
import DelaySelectWrapper from '../shared/DelaySelectWrapper';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function NewAutoReply({
  jobId,
  apiKey,
  modalOpen,
  setModalOpen,
  autoReplies,
  setAutoReplies,
  applicationStatuses,
  emailTemplates,
  isLoadingTemplateList,
  replyIndex,
  hasCareAdvantageIntegration,
  careAdvantageEvents,
  setSnackbar
}: IAutoReplyProps) {
  const [isSending, setIsSending] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [emailSetting, setEmailSetting] = useState('');
  const [delay, setDelay] = useState('None');
  const [selectedStatus, setSelectedStatus] = useState<IApplicationStatus | null>(null);
  const [body, setBody] = useState('');
  const [errors, setErrors] = useState({
    status: '',
    template: '',
    email: ''
  });

  const refObject = {
    status: useRef<HTMLDivElement>(null),
    template: useRef<HTMLDivElement>(null),
    email: useRef<HTMLDivElement>(null)
  };

  const handleSelectTemplate = useCallback(
    async (value: string) => {
      if (!value) {
        setSelectedTemplate(null);
        setBody('');
        return;
      }
      setIsLoadingTemplate(true);
      setBody(
        '<p style="text-align: center;"><span style="font-size: 14pt;"><strong>Loading template...</strong></span></p>'
      );
      const id = value.split(' -')[0];
      setSelectedTemplate(value);
      try {
        const response = await fetch(`/api/v4/jobs/${jobId}/email_templates/${id}`, {
          method: 'GET',
          headers: {
            'X-api-authenticate': apiKey
          }
        });
        const data = await response.json();
        setBody(data.body);
      } catch (error) {
        setBody(
          '<p style="text-align: center;"><span style="font-size: 14pt;"><strong>Error loading template</strong></span></p>'
        );
      } finally {
        setIsLoadingTemplate(false);
      }
    },
    [apiKey, jobId]
  );

  const emailIsValid = (email: string) => {
    const simpleEmailRex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email === '' || simpleEmailRex.test(String(email).toLowerCase())) {
      setErrors((prev) => ({ ...prev, email: '' }));
      return true;
    }
  };

  const handleSubmit = async () => {
    let errorRef = '';
    if (!selectedStatus) {
      setErrors((prev) => ({ ...prev, status: 'Please select a status' }));
      errorRef = errorRef || 'status';
    }
    if (!selectedTemplate) {
      setErrors((prev) => ({ ...prev, template: 'Please select a template' }));
      errorRef = errorRef || 'template';
    }
    if (!emailIsValid(emailSetting)) {
      setErrors((prev) => ({
        ...prev,
        email: 'Please enter a valid email or leave this field blank'
      }));
      errorRef = errorRef || 'email';
    }
    if (errorRef) {
      refObject[errorRef as keyof typeof refObject].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      return;
    }
    try {
      setIsSending(true);
      const targetEvent = careAdvantageEvents.find((event) => event.name === selectedEvent);
      if (replyIndex === -1) {
        const response = await fetch(`/api/v4/jobs/${jobId}/auto_emails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-api-authenticate': apiKey
          },
          body: JSON.stringify({
            trigger_status_id: selectedStatus?.id,
            email_template_id: selectedTemplate?.split(' -')[0],
            email_setting: emailSetting,
            delay: stringToSeconds(delay),
            care_advantage_event_id: targetEvent ? targetEvent.id : ''
          })
        });
        const data = await response.json();
        if (response.ok) {
          setAutoReplies((prev) => [...prev, data]);
          setSnackbar({
            message: 'Auto reply created successfully',
            state: 'success'
          });
          setModalOpen(false);
        } else {
          throw new Error('Error creating new auto reply');
        }
      } else {
        const response = await fetch(
          `/api/v4/jobs/${jobId}/auto_emails/${autoReplies[replyIndex].id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'X-api-authenticate': apiKey
            },
            body: JSON.stringify({
              trigger_status_id: selectedStatus?.id,
              email_template_id: selectedTemplate?.split(' -')[0],
              email_setting: emailSetting,
              delay: stringToSeconds(delay),
              care_advantage_event_id: targetEvent ? targetEvent.id : ''
            })
          }
        );
        const data = await response.json();
        if (response.ok) {
          setAutoReplies((prev) => {
            const newReplies = [...prev];
            newReplies[replyIndex] = data;
            return newReplies;
          });
          setSnackbar({
            message: 'Auto reply updated successfully',
            state: 'success'
          });
          setModalOpen(false);
        } else {
          throw new Error('Error updating auto reply');
        }
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Something went wrong, please try again',
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    setErrors({
      status: '',
      template: '',
      email: ''
    });
    if (replyIndex === -1) {
      setEmailSetting('');
      setSelectedTemplate(null);
      setDelay('None');
      setSelectedStatus(null);
      setBody('');
      return;
    }
    setEmailSetting(autoReplies[replyIndex].email_setting || '');
    setSelectedStatus(
      applicationStatuses.find(
        (status) => status.id === autoReplies[replyIndex].trigger_status_id
      ) || null
    );
    setDelay(secondsToString(autoReplies[replyIndex].delay));
  }, [applicationStatuses, autoReplies, replyIndex]);

  useEffect(() => {
    if (replyIndex !== -1) {
      const emailTemplate =
        emailTemplates.find(
          (template) =>
            template.split(' - ')[0] === autoReplies[replyIndex].email_template_id.toString()
        ) || null;
      setSelectedTemplate(emailTemplate);
      hasCareAdvantageIntegration &&
        setSelectedEvent(autoReplies[replyIndex].care_advantage_event_name);
      if (emailTemplate) {
        handleSelectTemplate(emailTemplate);
      }
    }
  }, [autoReplies, emailTemplates, replyIndex, hasCareAdvantageIntegration, handleSelectTemplate]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="new-auto-email-reply-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={() => setModalOpen(false)} sx={classes.closeIcon} />
        <Box sx={classes.actionModalHeader}>{`${
          replyIndex === -1 ? 'New' : 'Edit'
        } auto email reply`}</Box>
        <Box
          sx={classes.modalContent}
          aria-label={`${replyIndex === -1 ? 'New' : 'Edit'} auto email reply modal content`}
        >
          <Box
            role="form"
            id="auto-email-reply-form"
            aria-label="Auto email reply form"
            sx={{ height: '100%', overflowX: 'clip' }}
          >
            <Box
              sx={{ ...classes.actionModalFormLine, marginTop: '20px', marginBottom: '40px' }}
              ref={refObject.status}
            >
              <Autocomplete
                id="trigger-status-autocomplete"
                data-testid="trigger-status-autocomplete-autoreply"
                options={applicationStatuses}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  setSelectedStatus(value);
                  setErrors((prev) => ({ ...prev, status: '' }));
                }}
                value={selectedStatus}
                sx={{ ...sharedClasses.formAutocomplete, width: '287px' }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trigger status"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                renderOption={(props, option, state) => {
                  return (
                    <li {...props} key={option.id} data-testid={`option-${state.index}`}>
                      {option.name}
                    </li>
                  );
                }}
              />
              {errors.status && <Box sx={classes.formError}>{errors.status}</Box>}
            </Box>
            <Box>
              <Box sx={classes.actionModalFormLine} ref={refObject.template}>
                <Virtualize
                  value={selectedTemplate}
                  passOptions={emailTemplates}
                  setValue={(value: string) => {
                    handleSelectTemplate(value);
                    setErrors((prev) => ({ ...prev, template: '' }));
                  }}
                  passedStyles={{
                    ...sharedClasses.formAutocomplete,
                    width: '600px',
                    marginTop: '20px'
                  }}
                  label="Template"
                  loading={isLoadingTemplateList}
                  loadingText="Loading template list..."
                />
                {errors.template && <Box sx={classes.formError}>{errors.template}</Box>}
              </Box>
              {(selectedTemplate || replyIndex !== -1) &&
                (isLoadingTemplate || (replyIndex !== -1 && !selectedTemplate) ? (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={400}
                    width={'96%'}
                    sx={classes.previewSkeleton}
                  />
                ) : (
                  <Box sx={{ ...classes.htmlContent }}>
                    <Box sx={{ ...classes.previewHeader }}>
                      <VisibilityIcon />
                      Preview
                    </Box>
                    <Box
                      sx={classes.emailPreview}
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(body)
                      }}
                    />
                  </Box>
                ))}
            </Box>
            <Box sx={classes.actionModalFormLine} ref={refObject.email}>
              <FormTextField
                onChange={(event) => {
                  setEmailSetting(event.target.value);
                  setErrors((prev) => ({ ...prev, email: '' }));
                }}
                value={emailSetting}
                id="sender email"
                label="Sender email (optional)"
                error={errors.email}
                styles={{ width: '290px' }}
              />
            </Box>
            <DelaySelectWrapper delay={delay} setDelay={setDelay} />
            {hasCareAdvantageIntegration && (
              <Box sx={{ ...classes.actionModalFormLine, rowGap: '0px !important' }}>
                <Virtualize
                  value={selectedEvent}
                  passOptions={careAdvantageEvents.map((event) => event.name)}
                  setValue={(value: string) => {
                    setSelectedEvent(value);
                  }}
                  passedStyles={{
                    ...sharedClasses.formAutocomplete,
                    width: '600px',
                    marginTop: '20px'
                  }}
                  label="Select a Care Advantage Event"
                />
                <Box sx={classes.placeholderText}>
                  Placeholder to use: {'{'}CARE_ADVANTAGE_INVITE_LINK{'}'}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <CancelButton scope='auto-reply' setModalOpen={setModalOpen} />
          <Button
            id="send-auto-reply-button"
            data-testid="send-auto-reply-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '71px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSending ? <CircularProgress size={20} color="inherit" /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
