import moment from 'moment';
import { toPng } from 'html-to-image';
import { jsPDF } from 'jspdf';
import { SearchState } from './types';

export const updateEntityOptions = (options: { id: number[]; name: string }[]) => {
  if (options[0]?.name === 'All entities') return options;
  const allOption = { name: 'All entities', id: options.map((i) => i.id) };
  options.unshift(allOption);
  return options;
};

export const timeToHireOptions = (reportState: SearchState) => {
  return {
    scales: {
      y: {
        title: {
          display: true,
          text: 'Median Time To Hire (Days)',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 18
          }
        }
      },
      x: {
        title: {
          display: true,
          text: 'Entity',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 25
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      htmlLegend: {
        containerID: 'legend-container'
      },
      legend: {
        display: false
      },
      title: {
        font: {
          size: 16
        },
        display: true,
        text: `Time to hire ${dateRangeTitle(reportState.timeToHireFields)}`
      }
    }
  };
};

export const exportPDF = (refChart, setSnackbar) => {
  toPng(refChart.current, { cacheBust: true, style: { background: 'white' } })
    .then((dataUrl: string) => {
      const pdf = new jsPDF('l', 'px', [
        refChart.current.scrollWidth,
        refChart.current.scrollHeight
      ]);
      pdf.addImage(
        dataUrl,
        'png',
        10,
        10,
        refChart.current.scrollWidth,
        refChart.current.scrollHeight
      );
      pdf.save('download.pdf');
    })
    .catch(() => {
      setSnackbar({
        message: 'There was an error exporting the graph',
        state: 'error'
      });
    });
};

export const statusActivityOptions = (reportState: SearchState) => {
  return {
    indexAxis: 'y',
    plugins: {
      htmlLegend: {
        containerID: 'legend-container'
      },
      legend: {
        display: false
      },
      title: {
        font: {
          size: 16
        },
        display: true,
        text: `Application Sources ${dateRangeTitle(reportState.applicationSourcesFields)}`
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Number of Applications',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 25
          }
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Status',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 18
          }
        }
      }
    }
  };
};

export const activityReportStackedHorizontalBarOptions = (reportState: SearchState) => {
  return {
    plugins: {
      htmlLegend: {
        containerID: 'legend-container-horizontal'
      },
      legend: {
        display: false
      },
      title: {
        font: {
          size: 16
        },
        display: true,
        text: `Number of records by date ${dateRangeTitle(reportState.statusActivitiesFields)}`
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Date',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 25
          }
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Number of records',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 18
          }
        }
      }
    }
  };
};

export const activityReportStackedBarOptions = (reportState: SearchState) => {
  return {
    indexAxis: 'y',
    plugins: {
      htmlLegend: {
        containerID: 'legend-container'
      },
      legend: {
        display: false
      },
      title: {
        font: {
          size: 16
        },
        display: true,
        text: `Number of records by user ${dateRangeTitle(reportState.statusActivitiesFields)}`
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Number of records',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 25
          }
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'User',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 18
          }
        }
      }
    }
  };
};

export const getTabNumber = (urlParams: string) => {
  switch (urlParams) {
    case '?templates':
      return 1;
    case '?managers':
      return 2;
    case '?public-links':
      return 3;
    case '?archived':
      return 4;
    default:
      return 0;
  }
};

export const dateRangeTitle = (date) => {
  switch (date.timeToHire?.value) {
    case 7:
    case 30:
    case 90:
    case 180:
    case 365:
    case 1095:
    case 1825:
      return ` - ${moment()
        .subtract(date.timeToHire.value, 'days')
        .format('ll')} to ${moment().format('ll')}`;

    case 'custom':
      let dateRange = '';
      if (moment(date.startDate).isValid() && moment(date.startDate).isAfter('2000-01-01')) {
        dateRange = dateRange + ` - ${moment(date.startDate).format('ll')}`;
        if (
          moment(date.endDate).isValid() &&
          moment(date.startDate).isBefore(date.beforeDate) &&
          moment(date.endDate).isAfter('2000-01-01')
        )
          dateRange = dateRange + ` to ${moment(date.endDate).format('ll')}`;
        else dateRange = dateRange + ` to ${moment().format('ll')}`;
      }
      return dateRange;

    default:
      return '';
  }
};

export const resetFilters = (field: string) => {
  switch (field) {
    case 'timeToHireFields':
      return {
        user: [],
        jobTitle: []
      };
    case 'applicationSourcesFields':
      return {
        sourceName: [],
        status: []
      };
    default:
      return {};
  }
};

export const setDatePeriod = (diff: number) => {
  if (diff <= 7) return 'day';
  else if (diff > 7 && diff <= 31) return 'week';
  else if (diff > 31 && diff <= 91) return 'month';
  else if (diff > 91 && diff <= 1095) return 'quarter';
  else return 'year';
};

export const applicationSourcesOptions = (reportState: SearchState) => {
  return {
    plugins: {
      legend: {
        position: 'bottom'
      },
      title: {
        font: {
          size: 16
        },
        display: true,
        text: `Application Sources ${dateRangeTitle(reportState.applicationSourcesFields)}`
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Date',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 25
          }
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Number of Records',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 18
          }
        }
      }
    }
  };
};

export const sortGraphByDate = (statusNoActor) => {
  const labels = statusNoActor?.data?.labels;
  const reorderLabels = labels.slice().sort();
  const reorderIndex = labels.map((i: number) => reorderLabels.indexOf(i));
  const reorderDataset = statusNoActor?.data?.datasets;

  reorderDataset?.map((dataset) => {
    const data = dataset.data;
    const newArray = data.slice();
    reorderIndex.map((i: number, index: number) => (newArray[i] = data[index]));
    dataset.data = newArray;
  });

  return {
    datasets: reorderDataset,
    labels: reorderLabels
  };
};

export const dateRangeOptions = [
  { value: 7, label: 'Last Week' },
  { value: 30, label: 'Last Month' },
  { value: 90, label: 'Last Quarter' },
  { value: 180, label: 'Last Half Year' },
  { value: 365, label: 'Last Year' },
  { value: 1095, label: 'Last Three Years' },
  { value: 1825, label: 'Last Five Years' },
  { value: 'custom', label: 'Custom' }
];

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  if (!legendContainer) return;
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexWrap = 'wrap';
    listContainer.style.justifyContent = 'center';
    listContainer.style.margin = '0';
    listContainer.style.padding = '0';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.margin = '7px 6px';

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '15px';
      boxSpan.style.marginRight = '7px';
      boxSpan.style.width = '15px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.fontSize = '13px';
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
};
