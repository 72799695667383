import React from 'react';
import { TableCell, TableRow } from '@mui/material';

type ConfigOptionType = {
  name: string;
  label: string;
  id: number;
};

const ReportTableBody = ({ state }: { state }) => {
  const { data } = state;
  return (
    <>
      {data?.map((r, i) => (
        <TableRow key={i} sx={{ width: '100%' }}>
          {Object.entries(r.attributes).map((option) => {
            const [key, value] = option as unknown as [string, ConfigOptionType];
            return <TableCell key={key}>{value ? value : '-'}</TableCell>;
          })}
        </TableRow>
      ))}
    </>
  );
};

export default ReportTableBody;
