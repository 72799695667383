import React from 'react';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { theme } from '../../../ThemeContext/ThemeObject';
import { sharedClasses } from './sharedClasses';

interface ISelectFieldProps {
  label?: string;
  options: string[];
  value?: string;
  onChange: (value: string) => void;
  id?: string;
  innerRef?: React.RefObject<HTMLInputElement> | ((el: HTMLDivElement) => void);
  styles?: React.CSSProperties | Record<string, React.CSSProperties>;
  error?: string;
  required?: boolean;
}

const SelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: '24px'
  },
  '& .MuiInputBase-input': {
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    color: '#333333',
    border: '1px solid #DDDDDD',
    fontSize: '16px',
    padding: '6px 10px',
    margin: '3px',
    fontFamily: 'Source Sans Pro, sans-serif',
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  '& .MuiInputBase-input:focus': {
    backgroundColor: '#ffffff',
    borderRadius: '6px'
  },
  '& .MuiSvgIcon-root': {
    color: '#AAAAAA',
    marginRight: '10px',
    transition: 'all 0.16s ease-in-out'
  }
}));

const menuItemStyle = {
  fontFamily: 'Source Sans Pro, sans-serif',
  color: '#333333',
  maxHeight: '200px'
};

export default function SelectField({
  label,
  options,
  value,
  onChange,
  id,
  innerRef,
  styles,
  error,
  required
}: ISelectFieldProps) {
  return (
    <FormControl variant="standard" sx={{ position: 'relative' }} ref={innerRef}>
      {label && (
        <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
          {label}
          {required && <span>*</span>}
        </InputLabel>
      )}
      <Select
        sx={styles}
        value={value}
        id={id}
        onChange={(e) => onChange(e.target.value)}
        input={<SelectInput />}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option} sx={menuItemStyle}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {error && <Box sx={sharedClasses.errorBox}>{error}</Box>}
    </FormControl>
  );
}
