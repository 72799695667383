import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { IQuestion } from '../../AIStudioQuestions/AIStudioQuestions';
import { classes } from '../styles';

const QuestionSwitch = ({
  item,
  index,
  type,
  label,
  onChange
}: {
  item: IQuestion;
  index: number;
  type: 'enabled' | 'required' | 'rateable' | 'confidential';
  label: string;
  onChange: (index: number, type: string) => void;
}) => {
  return (
    <FormControlLabel
      sx={
        !item[type as keyof typeof item]
          ? { ...classes.switchLabels, ...classes.labelActive }
          : classes.switchLabels
      }
      control={
        <Switch
          sx={item[type as keyof typeof item] ? classes.aiSwitchActive : classes.switch}
          checked={item[type as keyof typeof item] as boolean}
          onChange={() => onChange(index, type)}
        />
      }
      label={label}
      labelPlacement="end"
    />
  );
};

export default QuestionSwitch;
