import React from 'react';
import Box from '@mui/material/Box';
import { FormTextField, MultilineFormTextField } from '../CustomUIElements/FormTextField';
import { alpha, styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import { classes } from './styles';
import dompurify from 'dompurify';
import { theme } from '../../../ThemeContext/ThemeObject';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Button from '@mui/material/Button';
import { SingleDatePicker } from '../CustomUIElements/SingleDatePicker';

export interface IApplicantRequisition {
  id: number;
  question: string;
  answer:
    | {
        id: number;
        name: string;
      }
    | IFieldCheckbox[];
  type: string;
  field_choices: IFieldChoice[];
}

export interface IFieldChoice {
  id: number;
  name: string;
  position: number;
}

export interface IFieldCheckbox {
  id: number;
  name: string;
}

const SelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: '24px'
  },
  '& .MuiInputBase-input': {
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    color: '#333333',
    border: '1px solid #DDDDDD',
    fontSize: '16px',
    padding: '6px 10px',
    margin: '3px 0px',
    fontFamily: 'Source Sans Pro, sans-serif',
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  '& .MuiInputBase-input:focus': {
    backgroundColor: '#ffffff',
    borderRadius: '6px'
  },
  '& .MuiSvgIcon-root': {
    color: '#AAAAAA',
    marginRight: '10px',
    transition: 'all 0.16s ease-in-out'
  }
}));

const menuItemStyle = {
  fontFamily: 'Source Sans Pro, sans-serif',
  color: '#333333',
  maxHeight: '200px'
};

const radioStyle = {
  color: '#DDDDDD',
  '&.Mui-checked': {
    color: theme.palette.action.main
  },
  '& .MuiSvgIcon-root': {
    fontSize: '22px'
  }
};

const labelStyle = {
  '& .MuiTypography-root': {
    color: '#000 !important',
    fontSize: '16px',
    marginLeft: '2px',
    fontFamily: 'Source Sans Pro, sans-serif'
  }
};

const checkboxStyle = {
  color: '#DDDDDD',
  '&.Mui-checked': {
    color: theme.palette.action.main
  },
  '& .MuiSvgIcon-root': {
    fontSize: '22px'
  }
};

const buttonStyle = {
  fontFamily: 'Source Sans Pro',
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'none',
  color: '#5BC4BF',
  backgroundColor: '#5BC4BF12',
  borderRadius: '6px',
  boxShadow: 'none',
  marginLeft: '20px',
  '&:hover': {
    backgroundColor: '#4da8a412',
    boxShadow: 'none'
  }
};

export default function RequisitionInfoViewer({
  requisition,
  index,
  requisitionInfos,
  setRequisitionInfos
}: {
  requisition: IApplicantRequisition;
}) {
  const updateAnswer = (index: number, value: string | number) => {
    const answers = [...requisitionInfos];
    answers[index].answer = value;
    setRequisitionInfos(answers);
  };

  const updateCheckbox = (index: number, value: number) => {
    const i = requisitionInfos[index].answer.indexOf(value);
    if (i < 0) {
      const answers = [...requisitionInfos];
      answers[index].answer.push(value);
      setRequisitionInfos(answers);
    } else {
      requisitionInfos[index].answer.splice(i, 1);
      setRequisitionInfos(requisitionInfos);
    }
  };

  switch (requisition.type) {
    case 'RadioButtonField':
      return (
        <Box sx={{ marginTop: '20px' }}>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(requisition.question) }}
          />
          <RadioGroup
            name="radio-buttons-group"
            value={requisition.answer}
            onChange={(e) => updateAnswer(index, e.target.value)}
            sx={{ padding: '8px 0px', flexDirection: 'column' }}
          >
            {requisition.field_choices
              .map((choice) => ({
                label: choice.name,
                value: choice.id
              }))
              .map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio sx={radioStyle} />}
                  label={option.label}
                  sx={labelStyle}
                />
              ))}
          </RadioGroup>
        </Box>
      );
    case 'CheckBoxField':
      return (
        <Box sx={{ marginTop: '20px' }}>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(requisition.question) }}
          />
          <Box sx={{ right: '12px' }}>
            <FormGroup sx={{ padding: '8px 0px', flexDirection: 'column' }}>
              {requisition.field_choices
                .map((choice) => ({
                  label: choice.name,
                  value: choice.id
                }))
                .map((option) => (
                  <FormControlLabel
                    key={option.value}
                    control={
                      <Checkbox
                        sx={checkboxStyle}
                        onChange={() => updateCheckbox(index, option.value)}
                        value={option.value}
                        defaultChecked={requisition.answer.includes(option.value)}
                        name={option.label}
                      />
                    }
                    label={option.label}
                    sx={labelStyle}
                  />
                ))}
            </FormGroup>
          </Box>
        </Box>
      );
    case 'TextField':
      return (
        <Box sx={{ marginTop: '20px' }}>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(requisition.question) }}
          />
          <FormTextField
            value={requisition.answer}
            onChange={(e) => updateAnswer(index, e.target.value)}
            styles={{ width: '290px' }}
          />
        </Box>
      );
    case 'TextAreaField':
      return (
        <Box sx={{ marginTop: '20px' }}>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(requisition.question) }}
          />
          <MultilineFormTextField
            value={requisition.answer}
            onChange={(e) => updateAnswer(index, e.target.value)}
            styles={{ minWidth: '300px', maxWidth: '600px' }}
          />
        </Box>
      );
    case 'SelectField':
      return (
        <Box sx={{ marginTop: '20px' }}>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(requisition.question) }}
          />
          <Select
            sx={{ width: '290px' }}
            value={requisition.answer}
            onChange={(e) => updateAnswer(index, e.target.value)}
            input={<SelectInput />}
          >
            {requisition.field_choices
              .map((choice) => ({
                label: choice.name,
                value: choice.id
              }))
              .map((option, index) =>
                typeof option === 'string' ? (
                  <MenuItem key={index} value={option} sx={menuItemStyle}>
                    {option}
                  </MenuItem>
                ) : (
                  <MenuItem key={index} value={option.value} sx={menuItemStyle}>
                    {option.label}
                  </MenuItem>
                )
              )}
          </Select>
        </Box>
      );
    case 'DateField':
      return (
        <Box sx={{ marginTop: '20px' }}>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(requisition.question) }}
          />
          <Box sx={{ marginTop: '10px', ...classes.formDateWrapper }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <SingleDatePicker
                value={requisition.answer}
                disabled={false}
                onChange={(date) => updateAnswer(index, moment(date).format('YYYY-MM-DD'))}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      );
    case 'AvailabilityField':
    case 'CsvDataLookupField':
      return (
        <Box sx={{ marginTop: '20px' }}>
          <Box
            sx={classes.questionLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(requisition.question) }}
          />
          <Box sx={{ marginTop: '10px', ...classes.formDateWrapper }}>
            <Select
              sx={{ width: '290px' }}
              value={requisition.answer[0] == 'existing' ? 'Existing Position' : 'New Position'}
              onChange={{}}
              input={<SelectInput />}
              disabled={true}
            >
              {['Existing Position', 'New Position'].map((option, index) =>
                typeof option === 'string' ? (
                  <MenuItem key={index} value={option} sx={menuItemStyle}>
                    {option}
                  </MenuItem>
                ) : (
                  <MenuItem key={index} value={option.value} sx={menuItemStyle}>
                    {option.label}
                  </MenuItem>
                )
              )}
            </Select>
            <Box sx={{ marginTop: '10px', ...classes.questionLabel }}>
              {requisition.answer[0] == 'existing'
                ? 'Enter Existing Position Number'
                : 'Enter New Position Number'}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormTextField
                draggable={true}
                value={requisition.answer[1]}
                onChange={{}}
                required={false}
                styles={{ width: '290px' }}
              />
              <Button id="csv-look-up-button" sx={buttonStyle} onClick={{}} disabled={true}>
                Look up
              </Button>
            </Box>
          </Box>
        </Box>
      );
    case 'JobReferenceLookupField':
      return null;
    default:
      return (
        <Box>
          <Box>{requisition.question}</Box>
          Invalid question type
        </Box>
      );
  }
}
