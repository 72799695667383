import React from 'react';

// SmartShare.tsx variables & functions

export const displayFelixMessage = (useCustomDescription: string, currentStep: number) => {
  const felixMessageContext =
    currentStep === 1 ? ' social media post ' : ' job board advertisment ';
  switch (useCustomDescription) {
    case 'description':
      return (
        <>
          I&apos;ll write you an engaging
          <span>{felixMessageContext}</span>
          using your job description!
        </>
      );
    case 'custom':
      return (
        <>
          Paste your position description or talent landing page here and I&apos;ll use it to write
          a <span>{felixMessageContext}</span>
        </>
      );
    case 'summary':
      return (
        <>
          Input the key points of the position and I&apos;ll use it to write a
          <span>{felixMessageContext}</span>
        </>
      );
    default:
      return <>Something went wrong. Please refresh the page and try again.</>;
  }
};

export const disableNextButton = (
  useCustomDescription: string,
  jobDescription: string,
  generationJobDescription: string,
  generationJobTitle: string,
  maxCharacters: number
) => {
  if (generationJobTitle.length === 0) {
    return true;
  } else if (useCustomDescription === 'custom' || useCustomDescription === 'summary') {
    return generationJobDescription.length === 0 || generationJobDescription.length > maxCharacters;
  } else if (useCustomDescription === 'description') {
    return jobDescription.length < 100 || jobDescription.length > maxCharacters;
  } else {
    return false;
  }
};

// Social media post

export const postTones = [
  'excited',
  'professional',
  'casual',
  'creative',
  'persuasive',
  'informative',
  'funny'
];

export const responseLength = ['short', 'medium', 'long'];

// Job board advertisement

export const adTypes = ['dot points', 'paragraphs', 'free form'];

// Response step variables

export const headerText = {
  social: 'social media post',
  advertisement: 'job board advertisement'
};

export const toggleButtonOptions = [
  {
    value: 'description',
    label: 'Use existing description'
  },
  {
    value: 'custom',
    label: 'Use custom description'
  },
  {
    value: 'summary',
    label: 'Use job summary'
  }
];
