import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DoneIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { INoteProps } from '../types';
import Api from '../API';
import { classes } from './useStyles';
import { emojiInString } from '../../utils/emoji-checker';
import { NOTES_ERROR_MESSAGE } from '../../constants';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { Stack } from '@mui/material';
import { classes as styles } from '../../Job/Applications/styles';

const NotesPopover = ({
  setJobs,
  anchorEl,
  handleCloseNote,
  selectedJobId,
  apiKey,
  setSnackbarState
}: INoteProps) => {
  const [editedNote, setEditedNote] = useState('');
  const [savingNote, setSavingNote] = useState(false);
  const [noteError, setNoteError] = useState('');

  async function saveNote() {
    if (emojiInString(editedNote)) {
      setNoteError(NOTES_ERROR_MESSAGE);
      return;
    }
    try {
      setSavingNote(true);
      const { res } = await Api.postJobNote({ 'X-api-authenticate': apiKey }, selectedJobId, {
        job_note: { body: editedNote }
      });
      if (res.body) {
        setJobs((prevJobs) => {
          const newJobs = [...prevJobs];
          const jobIndex = newJobs.findIndex((job) => job.id === selectedJobId);
          newJobs[jobIndex].job_notes = [
            { body: res.body, noted_by: res.noted_by },
            ...newJobs[jobIndex].job_notes
          ];
          return newJobs;
        });
        setEditedNote('');
        handleCloseNote();
      }
      setNoteError('');
    } catch (error) {
      console.error(error);
      setSnackbarState({
        message: error.message,
        state: 'error'
      });
    } finally {
      setSavingNote(false);
    }
  }

  const closeNoteHandler = () => {
    setNoteError('');
    handleCloseNote();
  };

  useEffect(() => {
    if (!anchorEl) return;
  }, [anchorEl]);

  return (
    <Popover
      id={anchorEl ? 'edit-note-popover' : undefined}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={closeNoteHandler}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Stack sx={styles.notesContainer} spacing={2}>
        <TextField
          id="note-input"
          sx={classes.noteInput}
          label="Add Note"
          multiline
          fullWidth
          maxRows={4}
          variant="outlined"
          onChange={(e) => setEditedNote(e.target.value)}
        />
        {!!noteError && <Box sx={sharedClasses.errorText}>{noteError}</Box>}
        <Box id="notes-button-container" sx={classes.notesButtonContainer}>
          <Button sx={classes.notesCancelButton} endIcon={<CloseIcon />} onClick={closeNoteHandler}>
            Cancel
          </Button>
          <Button
            sx={classes.notesEditSaveButton}
            endIcon={savingNote ? <CircularProgress size={16} /> : <DoneIcon />}
            onClick={() => {
              setNoteError('');
              saveNote();
            }}
            disabled={!editedNote || !!savingNote}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </Popover>
  );
};

export default NotesPopover;
