import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class SearchResultsItem  extends React.Component {

  static propTypes = {
    item: PropTypes.object
  }

  render() {
    const { item } = this.props;

    return(
        <div className='row'>
          <div className='body-cell'>
            <a href={ item.url }>{ item.id }</a>
          </div>
          <div className='body-cell'>
            <a href={ item.url }> { item.candidate }</a>
          </div>
          <div className='body-cell'>{ item.reference }</div>
          <div className='body-cell'>{ item.job }</div>
          <div className='body-cell'>{ item.post_code }</div>
          <div className='body-cell'>{ item.location }</div>
          <div className='body-cell'>{ moment(item.created_at[0]).format('DD/MM/YYYY') }</div>
          <div className='body-cell'>{ item.rating }</div>
          <div className='body-cell'>{ item.status }</div>
        </div>
      );
  }
};
