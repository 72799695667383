import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: "50px 60px",
    "& h4": {
      marginTop: 0,
      color: "#004E70",
      fontSize: 24,
      fontWeight: "600",
      marginBottom: 20
    }
  },
  autocompleteField: {
    flexBasis: "60%",
    marginRight: 20,
    "& .MuiInput-underline-36:after": {
      border: 0
    }
  },
  autoCompleteInput: {
    paddingLeft: 15
  },
  endAdornment: {
    marginRight: 10
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row"
  },
  search: {
    flexGrow: 1,
    marginRight: 12,
    background: "#FAFAFA",
    border: "1px solid #EAEEF0",
    borderRadius: 6,
    justifyContent: "center",
    "& svg": {
      fill: "#939393"
    },
    "& fieldset": {
      border: 0
    },
    "& input": {
      padding: "14px 8px !important"
    }
  },
  table: {
    marginTop: 20,
    width: "100%",
    height: "100%",
    overflow: "scroll",
    display: "flex",
    flexDirection: "column"
  },
  row: {
    margin: 0,
    display: "flex",
    "& div": {
      display: "flex",
      alignItems: "center"
    },
    "& button": {
      display: "block",
      marginLeft: "auto",
      opacity: 0,
      transition: "opacity 0.24s ease-in-out",
      padding: 7,
      "&:focus": {
        outline: 0
      }
    },
    "&:hover button": {
      opacity: 1
    }
  },
  noApplicants: {
    color: "#999",
    display: "block",
    marginTop: 7
  },
  tableHeader: {
    fontSize: 16,
    fontWeight: "600",
    paddingBottom: 10,
    paddingTop: 15,
    color: theme.palette.primary.main
  },
  tableLg: {
    width: "35%",
    minWidth: "35%",
    maxWidth: "35%",
    userSelect: "none"
  },
  tableMd: {
    width: "20%",
    minWidth: "20%",
    maxWidth: "20%",
    userSelect: "none"
  },
  chipContainer: {
    paddingTop: 10
  },
  chipItem: {
    margin: "4px 8px 4px 0",
    color: "#666",
    "& span": {
      color: "#666",
      padding: "0 16px",
      fontSize: 12
    }
  },
  chipNoItems: {
    padding: "10px 0",
    fontSize: 15,
    color: "#999",
    textAlign: "center"
  },
  inputBase: {
    flexBasis: "25%",
    "& fieldset": {
      border: "1px solid #DDDDDD !important"
    }
  },
  select: {
    height: "100%",
    padding: 0,
    display: "flex",
    alignItems: "center",
    color: "#333333",
    paddingLeft: 20,
    "&:focus": {
      background: "#FFF"
    }
  },
  buttonContainer: {
    paddingTop: 20,
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      padding: "12px 26px",
      color: "#FFF"
    }
  }
}));
