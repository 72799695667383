import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, Button, Modal, CircularProgress } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { DebouncedFormTextField } from '../../Components/CustomUIElements/FormTextField';
import { styles } from '../styles';
import Api from '../API';
import { IApprovalManagers, IGetApprovalManagers } from '../types';
import LoadingButton from '../../Components/Buttons/LoadingButton';

export default function NewApprovalManager({
  apiKey,
  setSnackbar,
  isApprovalManagerDialogOpen,
  setIsApprovalManagerDialogOpen,
  editedManager,
  setEditedManager,
  getApprovalManagers
}: {
  apiKey: string;
  setSnackbar: Dispatch<
    SetStateAction<{ message: string; state: 'success' | 'warning' | 'error' }>
  >;
  isApprovalManagerDialogOpen: boolean;
  setIsApprovalManagerDialogOpen: Dispatch<SetStateAction<boolean>>;
  editedManager: IApprovalManagers | null;
  setEditedManager: Dispatch<SetStateAction<IApprovalManagers | null>>;
  getApprovalManagers: IGetApprovalManagers;
}) {
  const [nameError, setNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const createApprovalManager = async () => {
    if (!validateInputs()) return;
    try {
      const name = editedManager.name;
      const email = editedManager.email;
      const { res } = await Api.createApprovalManager(
        { 'X-api-authenticate': apiKey },
        { requisition_manager: { name, email } }
      );
      if (res.success) {
        setSnackbar({
          message: `Approval manager has been successfully created.`,
          state: 'success'
        });
        getApprovalManagers(null, null, null, null);
        handleClose();
      }
    } catch (error) {
      setSnackbar({
        message: `Failed to create approval manager. ${error.error}`,
        state: 'error'
      });
    }
  };

  const updateApprovalManager = async () => {
    const approvalManagerId = editedManager?.id;
    if (!approvalManagerId) return;
    if (!validateInputs()) return;
    try {
      const name = editedManager.name;
      const email = editedManager.email;
      const { res } = await Api.updateApprovalManager(
        { 'X-api-authenticate': apiKey },
        { requisition_manager: { name, email } },
        approvalManagerId
      );
      if (res.success) {
        setSnackbar({
          message: `Approval manager has been successfully updated.`,
          state: 'success'
        });
        getApprovalManagers(null, null, null, null);
        handleClose();
      }
    } catch (error) {
      setSnackbar({
        message: `Failed to update approval manager. ${error.error}`,
        state: 'error'
      });
    }
  };

  const handleClose = () => {
    setIsApprovalManagerDialogOpen(false);
    setEditedManager(null);
    setNameError('');
    setEmailError('');
  };

  const validateEmail = () => {
    if (!editedManager || !editedManager.email) return false;
    return String(editedManager.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateInputs = () => {
    setNameError('');
    setEmailError('');
    if (!editedManager || !editedManager.name)
      setNameError(`Name is required, please enter a name`);
    if (!validateEmail()) setEmailError(`Please enter a proper email address`);
    if (!editedManager || !editedManager.name || !validateEmail()) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Box>
      <Modal
        open={isApprovalManagerDialogOpen}
        onClose={handleClose}
        aria-labelledby="new-approval-manager-modal"
      >
        <Box sx={styles.actionsModal}>
          <Box sx={{ padding: '30px 0px' }}>
            <Box id="new-approval-manager-modal-title" sx={styles.modalHeader}>
              {editedManager?.id ? 'Edit' : 'New'} approval manager
            </Box>
          </Box>
          <Box sx={{ ...styles.modalFormLine, paddingTop: 2 }}>
            <Box sx={{ position: 'relative' }}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  const newEditedManager = { ...editedManager };
                  newEditedManager.name = value;
                  setEditedManager(newEditedManager);
                }}
                id="approval_manager_name"
                label="Name"
                defaultValue={editedManager?.name}
                required
                styles={{ width: '250px' }}
                error={nameError}
              />
            </Box>
          </Box>
          <Box sx={{ ...styles.modalFormLine, paddingTop: 1 }}>
            <Box sx={{ position: 'relative' }}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  const newEditedManager = { ...editedManager };
                  newEditedManager.email = value;
                  setEditedManager(newEditedManager);
                }}
                id="approval_manager_email"
                label="Email"
                defaultValue={editedManager?.email}
                required
                styles={{ width: '250px' }}
                error={emailError}
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: 5 }}>
            <CloseIcon onClick={handleClose} sx={styles.closeIcon} />
            <Box sx={styles.modalActions}>
              <Button
                disableElevation
                sx={{ ...styles.buttonRedLight }}
                variant="text"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <LoadingButton
                id="submit-new-approval-manager-button"
                type="submit"
                sx={styles.modalEditButton}
                task={() => (editedManager?.id ? updateApprovalManager() : createApprovalManager())}
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
