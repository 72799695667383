import React, { Dispatch } from 'react';
import { Box, FormControlLabel, IconButton, Switch } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { classes } from './styles';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ConfigOptionType } from '../types';

type Props = {
  state: {
    activeColumns: {
      [key: string]: { enabled: boolean };
    };
  };
  field: ConfigOptionType;
  dispatch: Dispatch<any>;
};
const SortableItem = ({ state, field, dispatch }: Props) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id: field.id });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };
  const isSwitched = state.activeColumns[field.name as keyof typeof state.activeColumns].enabled;
  return (
    <Box key={field.id} sx={classes.sortableField} style={style} ref={setNodeRef} {...attributes}>
      <IconButton ref={setActivatorNodeRef} {...listeners}>
        <DragIndicatorIcon sx={{ fontSize: 16 }} />
      </IconButton>
      <FormControlLabel
        sx={
          !isSwitched
            ? { ...classes.sortableSwitchLabels, ...classes.labelActive }
            : classes.sortableSwitchLabels
        }
        control={
          <Switch
            sx={isSwitched ? classes.switchActive : classes.switch}
            checked={isSwitched}
            onChange={(event) =>
              dispatch({
                type: 'SET_ACTIVE_COLUMNS',
                payload: { name: event.target.name, value: event.target.checked }
              })
            }
            name={field.name}
          />
        }
        label={field.label}
        labelPlacement="start"
      />
    </Box>
  );
};

export default SortableItem;
