var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  InputAdornment,
  TextField,
  Radio,
  RadioGroup,
  FormHelperText,
  Grid,
  withStyles,
  Select,
  MenuItem,
  Box
} from "@material-ui/core";
import useStyles from "../useStyles";
import PropTypes from "prop-types";
import axios from "axios";
import { useDebounce } from "use-debounce";
import { CircularProgress } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import ScoutAutocomplete from "../../../scout/autocomplete";
const workTypesOptions = [
  { value: "Hourly", label: "Hourly" },
  { value: "Salaried", label: "Salaried" },
  { value: "SalariedPlusCommission", label: "Salaried plus Commission" }
];
const workSalaryOptions = [
  { value: "Casual", label: "Casual" },
  { value: "ContractTemp", label: "Contract" },
  { value: "FullTime", label: "Full Time" },
  { value: "PartTime", label: "Part Time" }
];
const TITLE_LIMIT = 80;
const PinkRadio = withStyles({
  root: {
    "&$checked": {
      color: "#E60278"
    }
  },
  checked: {}
})((props) => /* @__PURE__ */ React.createElement(Radio, __spreadValues({ color: "default" }, props)));
function Step1({
  answers,
  setAnswers,
  getSeekAdSelectionData,
  setGetSeekAdSelectionData,
  apiKey,
  jobId,
  requiredRefs,
  activeError,
  jobAdId,
  seek_billing_reference,
  seek_billing_reference_flag
}) {
  const [loadingSeekAdType, setLoadingSeekAdType] = useState(true);
  const [loadingSeekAdAllCategories, setLoadingSeekAdTypeAllCategories] = useState(true);
  const [loadingSeekAdCategories, setLoadingSeekAdCategories] = useState(true);
  const [seekAdCategories, setSeekAdCategories] = useState(null);
  const [getSeekAdCategoriesData, setGetSeekAdCategoriesData] = useState(null);
  const [categoriesIndex, setCategoriesIndex] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState(answers.ad_type);
  const [debouncedTitle] = useDebounce(answers.title, 2500);
  const [debouncedAddress] = useDebounce(answers.listing_location, 2500);
  const classes = useStyles();
  const getSeekAdType = (url) => __async(this, null, function* () {
    setLoadingSeekAdType(true);
    try {
      const { data } = yield axios.get(url, {
        headers: { "X-api-authenticate": apiKey },
        params: {
          listing_sub_classification: answers.listing_sub_classification,
          listing_location: answers.listing_location,
          title: answers.title,
          job_ad_id: jobAdId
        }
      });
      setGetSeekAdSelectionData(data.data.seekAnzHirerAdvertisementCreationProducts);
      setLoadingSeekAdType(false);
    } catch (e) {
      setGetSeekAdSelectionData(null);
      setLoadingSeekAdType(false);
    }
  });
  const getSeekAdCategories = (url) => __async(this, null, function* () {
    try {
      const { data } = yield axios.get(url, {
        headers: { "X-api-authenticate": apiKey }
      });
      setGetSeekAdCategoriesData(data.data.jobCategories);
      answers.use_suggested_classification && answers.listing_classification && answers.listing_sub_classification && updateCategoryIndex(data.data.jobCategories);
      setLoadingSeekAdTypeAllCategories(false);
    } catch (e) {
      setSeekAdCategories(null);
      setLoadingSeekAdTypeAllCategories(false);
    }
  });
  useEffect(() => {
    !getSeekAdSelectionData ? getSeekAdType(`/api/v4/seek/jobs/${jobId}/ad_selections`) : (setLoadingSeekAdType(false), setAnswers(__spreadProps(__spreadValues({}, answers), { ad_type: "" })));
    !getSeekAdCategoriesData ? getSeekAdCategories(`/api/v4/seek/jobs/${jobId}/job_categories`) : setLoadingSeekAdTypeAllCategories(false);
  }, []);
  const handleChangeAdType = (event) => {
    setSelectedValue(event.target.value);
    setAnswers(__spreadProps(__spreadValues({}, answers), { ad_type: event.target.value }));
  };
  const getSugestedSeekAdCategories = (url) => __async(this, null, function* () {
    if (!answers.title || !answers.listing_location) {
      setSeekAdCategories(null);
      setLoadingSeekAdCategories(false);
      return;
    }
    try {
      const { data } = yield axios.get(url, {
        headers: { "X-api-authenticate": apiKey },
        params: { title: answers.title, listing_location: answers.listing_location }
      });
      setSeekAdCategories(data.data.jobCategorySuggestions);
      setLoadingSeekAdCategories(false);
    } catch (e) {
      setSeekAdCategories(null);
      setLoadingSeekAdCategories(false);
    }
  });
  useEffect(() => {
    getSugestedSeekAdCategories(`/api/v4/seek/jobs/${jobId}/job_categories/suggestions`);
    getSeekAdType(`/api/v4/seek/jobs/${jobId}/ad_selections`);
  }, [debouncedTitle, debouncedAddress]);
  useEffect(() => {
    setLoadingSeekAdCategories(true);
  }, [answers.title, answers.listing_location]);
  useEffect(() => {
    answers.listing_sub_classification && answers.title && answers.listing_location && getSeekAdType(`/api/v4/seek/jobs/${jobId}/ad_selections`);
  }, [answers.listing_sub_classification]);
  function updateCategoryIndex(data) {
    const index = data ? data.findIndex((category) => category.id.value === answers.listing_classification) : getSeekAdCategoriesData.findIndex(
      (category) => category.id.value === answers.listing_classification
    );
    setCategoriesIndex(index);
  }
  useEffect(() => {
    if (!getSeekAdCategoriesData) return;
    updateCategoryIndex(null);
  }, [answers.listing_classification]);
  return /* @__PURE__ */ React.createElement("div", { className: classes.stepContainer }, /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.title,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Job Title"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.title,
        className: classes.textField,
        variant: "outlined",
        value: answers.title,
        onChange: (e) => e.target.value.length <= TITLE_LIMIT && setAnswers(__spreadProps(__spreadValues({}, answers), { title: e.target.value })),
        InputProps: {
          endAdornment: activeError === requiredRefs.title && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
        }
      }
    ),
    /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, answers.title.length, "/", TITLE_LIMIT)
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.listing_location,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { className: classes.label }, "Location", /* @__PURE__ */ React.createElement("span", { className: classes.asterisk }, " *"), activeError === requiredRefs.listing_location && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", { id: "SeekMultiposterErrorTxt" }, "This field is required"))),
    /* @__PURE__ */ React.createElement(
      ScoutAutocomplete,
      {
        className: `${activeError === requiredRefs.listing_location ? classes.errorAutocomplete : ""} select-seek-location`,
        url: `/api/v4/seek/jobs/${jobId}/locations/options?job_ad_id=${answers.id}`,
        name: "location",
        value: answers.listing_location,
        selectedLabel: answers.listing_location_display,
        includeBlank: true,
        onChange: (value, label) => setAnswers(__spreadProps(__spreadValues({}, answers), { listing_location: value, listing_location_display: label })),
        urlHeaders: {
          "X-api-authenticate": apiKey
        }
      }
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.category,
      fullWidth: true,
      className: `${classes.formControl}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Category", /* @__PURE__ */ React.createElement("span", { className: classes.asterisk }, " *"), activeError === requiredRefs.category && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    seekAdCategories && /* @__PURE__ */ React.createElement("span", { className: classes.subLabel }, "Suggested category based on both job title and location"),
    loadingSeekAdCategories || loadingSeekAdAllCategories ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" }) : seekAdCategories ? /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        value: answers.listing_sub_classification,
        onChange: (e) => {
          setAnswers(__spreadProps(__spreadValues({}, answers), {
            listing_sub_classification: e.target.value,
            use_suggested_classification: false,
            listing_sub_classification_display: e.target.name
          }));
        }
      },
      seekAdCategories.map((category) => /* @__PURE__ */ React.createElement("div", { key: category.jobCategory.id.value }, /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          label: category.jobCategory.parent.name,
          name: `${category.jobCategory.parent.name} > ${category.jobCategory.name}`,
          value: category.jobCategory.id.value,
          control: /* @__PURE__ */ React.createElement(Radio, null)
        }
      ), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { style: { padding: "0 0.5rem 0 1.75rem" } }, ">"), " ", category.jobCategory.name)))
    ) : /* @__PURE__ */ React.createElement(Grid, { item: true }, /* @__PURE__ */ React.createElement("div", null, !answers.listing_location || !answers.listing_sub_classification ? "No suggested categories, please add a job title and location to see suggested categories" : "No suggested categories for the current job title and location")),
    /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        value: answers.use_suggested_classification,
        onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), {
          use_suggested_classification: true,
          listing_sub_classification: null,
          listing_classification: null
        }))
      },
      /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          key: "other",
          label: "Choose a different category",
          checked: answers.use_suggested_classification === true,
          control: /* @__PURE__ */ React.createElement(Radio, null)
        }
      )
    ),
    answers.use_suggested_classification && getSeekAdCategoriesData && /* @__PURE__ */ React.createElement(
      FormControl,
      {
        style: { marginTop: "5px" },
        fullWidth: true,
        className: `${classes.formControl} ${classes.medium}`
      },
      /* @__PURE__ */ React.createElement(
        Select,
        {
          error: activeError === requiredRefs.category,
          classes: { select: classes.input },
          variant: "outlined",
          value: answers.listing_classification,
          onChange: (e) => {
            const index = getSeekAdCategoriesData.findIndex(
              (category) => category.id.value === e.target.value
            );
            setAnswers(__spreadProps(__spreadValues({}, answers), {
              listing_classification: e.target.value,
              listing_classification_display: index >= 0 ? getSeekAdCategoriesData[index].name : ""
            }));
          },
          displayEmpty: true
        },
        getSeekAdCategoriesData.map((category) => /* @__PURE__ */ React.createElement(MenuItem, { key: category.id.value, value: category.id.value }, category.name))
      ),
      /* @__PURE__ */ React.createElement(
        FormLabel,
        {
          focused: false,
          style: { marginTop: "20px" },
          required: true,
          className: classes.label
        },
        "Sub Category"
      ),
      /* @__PURE__ */ React.createElement(
        Select,
        {
          error: activeError === requiredRefs.category,
          classes: { select: classes.input },
          disabled: !answers.listing_classification,
          variant: "outlined",
          value: answers.listing_sub_classification,
          onChange: (e) => {
            const index = getSeekAdCategoriesData[categoriesIndex].children.findIndex(
              (category) => category.id.value === e.target.value
            );
            setAnswers(__spreadProps(__spreadValues({}, answers), {
              listing_sub_classification: e.target.value,
              listing_sub_classification_display: index >= 0 ? getSeekAdCategoriesData[categoriesIndex].children[index].name : ""
            }));
          },
          displayEmpty: true
        },
        answers.listing_classification && getSeekAdCategoriesData && typeof categoriesIndex === "number" && categoriesIndex >= 0 && getSeekAdCategoriesData[categoriesIndex].children.map((category) => /* @__PURE__ */ React.createElement(MenuItem, { key: category.id.value, value: category.id.value }, category.name))
      )
    )
  ), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Work Type"), /* @__PURE__ */ React.createElement(
    RadioGroup,
    {
      value: answers.listing_work_type,
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { listing_work_type: e.target.value }))
    },
    workSalaryOptions.map((pay) => /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        key: pay.value,
        label: pay.label,
        value: pay.value,
        control: /* @__PURE__ */ React.createElement(Radio, null)
      }
    ))
  )), /* @__PURE__ */ React.createElement("h4", null, "Pay details"), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Pay Type"), /* @__PURE__ */ React.createElement(
    RadioGroup,
    {
      value: answers.salary_type,
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { salary_type: e.target.value }))
    },
    workTypesOptions.map((pay) => /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        key: pay.value,
        label: pay.label,
        value: pay.value,
        control: /* @__PURE__ */ React.createElement(Radio, null)
      }
    ))
  )), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.salary,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Pay Range ($AUD)", /* @__PURE__ */ React.createElement("span", { className: classes.asterisk }, " *"), activeError === requiredRefs.salary && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), !answers.salary_min || !answers.salary_max ? /* @__PURE__ */ React.createElement("span", null, "This field is required") : /* @__PURE__ */ React.createElement("span", null, "The pay range cannot exceed $100,000"))),
    /* @__PURE__ */ React.createElement("span", { className: classes.subLabel }, "Enter a pay range to offer candidates (this will not show on your ad)"),
    /* @__PURE__ */ React.createElement(Grid, { container: true, classes: { root: classes.controlledNumberInput } }, /* @__PURE__ */ React.createElement(
      Grid,
      {
        item: true,
        container: true,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        wrap: "nowrap"
      },
      /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, { m: 1, ml: 0 }, /* @__PURE__ */ React.createElement(
        TextField,
        {
          error: activeError === requiredRefs.salary,
          className: classes.textField,
          variant: "outlined",
          onBlur: () => {
            if (answers.salary_min > answers.salary_max)
              setAnswers(__spreadProps(__spreadValues({}, answers), { salary_max: answers.salary_min }));
          },
          value: Number(answers.salary_min).toLocaleString(),
          InputProps: {
            startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "From $")
          },
          onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), {
            salary_min: parseFloat(e.target.value.split(",").join("")) ? parseFloat(e.target.value.split(",").join("")) : 0
          }))
        }
      )), /* @__PURE__ */ React.createElement(Box, { ml: 2 }), /* @__PURE__ */ React.createElement(Box, { m: 1 }, /* @__PURE__ */ React.createElement(
        TextField,
        {
          error: activeError === requiredRefs.salary,
          className: classes.textField,
          variant: "outlined",
          value: Number(answers.salary_max).toLocaleString(),
          onBlur: () => {
            if (answers.salary_max < answers.salary_min)
              setAnswers(__spreadProps(__spreadValues({}, answers), { salary_max: answers.salary_min }));
          },
          InputProps: {
            startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "To $")
          },
          onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), {
            salary_max: parseFloat(e.target.value.split(",").join("")) ? parseFloat(e.target.value.split(",").join("")) : 0
          }))
        }
      )))
    ))
  ), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { className: classes.label }, "Customise the Pay information on your Ad (Optional)"), /* @__PURE__ */ React.createElement(
    TextField,
    {
      className: classes.textField,
      variant: "outlined",
      value: answers.salary_additional_text,
      onChange: (e) => e.target.value.length <= 50 && setAnswers(__spreadProps(__spreadValues({}, answers), { salary_additional_text: e.target.value }))
    }
  ), /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, /* @__PURE__ */ React.createElement("span", { style: { marginRight: "1rem" } }, "E.g. $50,000 + annual bonus"), answers.salary_additional_text ? answers.salary_additional_text.length : 0, " /50")), seek_billing_reference && seek_billing_reference_flag && /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { className: classes.label }, "Seek Billing Reference (Optional)"), /* @__PURE__ */ React.createElement(
    TextField,
    {
      className: classes.textField,
      variant: "outlined",
      value: answers.billing_reference,
      onChange: (e) => e.target.value.length <= 50 && setAnswers(__spreadProps(__spreadValues({}, answers), { billing_reference: e.target.value }))
    }
  ), /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, /* @__PURE__ */ React.createElement("span", { style: { marginRight: "1rem" } }, "Include a purchase order or reference to appear on your SEEK invoice"), answers.billing_reference ? answers.billing_reference.length : 0, " /50")), /* @__PURE__ */ React.createElement("h4", null, "SEEK Ad Type"), activeError === requiredRefs.ad_type && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", { id: "SeekMultiposterErrorTxt" }, "This field is required")), loadingSeekAdType ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" }) : /* @__PURE__ */ React.createElement(Grid, { container: true }, getSeekAdSelectionData ? getSeekAdSelectionData.map((ad, index) => /* @__PURE__ */ React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 4, key: index }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: !ad.enabledIndicator ? classes.seekAdTypeDisabled : classes.seekAdType,
      onClick: () => {
        if (!ad.enabledIndicator) {
          return;
        }
        setSelectedValue(ad.advertisementTypeCode);
        setAnswers(__spreadProps(__spreadValues({}, answers), { ad_type: ad.advertisementTypeCode }));
      }
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "seekAdheaderWrapper",
        style: index === 0 || index === 2 ? { borderRadius: index === 0 ? "5px 0 0 0" : "0 5px 0 0" } : {}
      },
      "Pay using your SEEK Contract"
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: selectedValue === ad.advertisementTypeCode && ad.enabledIndicator ? { border: "#E60278 1px solid", minHeight: "12.75rem" } : { border: "#E9E8E8 1px solid", minHeight: "12.5rem" }
      },
      /* @__PURE__ */ React.createElement("div", { className: "seekRadioRadioBtnWrapper", ref: requiredRefs.ad_type }, /* @__PURE__ */ React.createElement(
        PinkRadio,
        {
          checked: selectedValue === ad.advertisementTypeCode && ad.enabledIndicator,
          onChange: handleChangeAdType,
          value: ad.advertisementTypeCode,
          disabled: !ad.enabledIndicator,
          name: "radio-buttons",
          inputProps: { "aria-label": "A" }
        }
      )),
      /* @__PURE__ */ React.createElement("h4", null, ad.advertisementTypeCode),
      /* @__PURE__ */ React.createElement("div", null, ad.price.summary),
      /* @__PURE__ */ React.createElement(
        "div",
        {
          className: classes.seekEmbeddedHTML,
          dangerouslySetInnerHTML: { __html: ad.description }
        }
      )
    )
  ))) : /* @__PURE__ */ React.createElement(Grid, { item: true }, "Something went wrong")));
}
Step1.propTypes = {
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  getSeekAdSelectionData: PropTypes.object,
  setGetSeekAdSelectionData: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
  apiKey: PropTypes.string.isRequired,
  requiredRefs: PropTypes.object.isRequired,
  activeError: PropTypes.ref,
  jobAdId: PropTypes.number,
  seek_billing_reference: PropTypes.bool,
  seek_billing_reference_flag: PropTypes.bool
};
export default Step1;
