import React, { useReducer, useState, Dispatch } from 'react';
import { Box, Tabs, Tab, Stack, IconButton } from '@mui/material';
import { classes } from '../styles';
import StyledSnackbar from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';
import Analytics from './Analytics/Analytics';
import Applications from './Applications/Applications';
import EmailTemplates from './EmailTemplates/EmailTemplates';
import { GenericTableAction } from '../../NewUI/Components/GenericTable/genericTableReducer';
import { TEmail, ApplicationsAPIReturn } from '../types';
import { ArrowCircleLeft as ArrowCircleLeftIcon } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import callAPI from '../../NewUI/Components/Utilities/callAPI';
import { getStatusColor } from '../helper';
import {
  genericTableReducer,
  initialTableState
} from '../../NewUI/Components/GenericTable/genericTableReducer';
import { applicationColumns } from './Applications/config';
import { callPaginatedAPI } from '../../NewUI/Components/Utilities/callAPI';
import queryString from 'query-string';

const CandidatePoolAnalytics = ({
  selectedEmail,
  emailsDispatch,
  selectedCandidatePoolId
}: {
  selectedEmail: TEmail;
  emailsDispatch: Dispatch<GenericTableAction>;
  selectedCandidatePoolId: number;
}) => {
  const [modalTabValue, setModalTabValue] = useState<number>(0);
  const [snackbarState, setSnackbarState] = useState({
    message: '',
    state: 'success' as 'success' | 'error' | 'warning'
  });
  const [analyticsState, analyticsDispatch] = useReducer(
    genericTableReducer,
    initialTableState(applicationColumns, { sortBy: 'created_at', sortOrder: 'desc' })
  );

  const { data: emailAnalytics, isLoading: analyticsLoading } = useQuery({
    queryKey: ['candidateEmailAnalytics', selectedEmail.item.id],
    queryFn: () =>
      callAPI(
        `/api/v4/candidate_pools/${selectedCandidatePoolId}/email_batches/${selectedEmail.item.id}`,
        null,
        'GET'
      ),
    onError: () =>
      setSnackbarState({
        message: `There was an error getting user analytics data`,
        state: 'error'
      }),
    refetchOnWindowFocus: false
  });

  const { data: emailTemplates, isLoading: emailTemplatesLoading } = useQuery({
    queryKey: ['emailTemplatesAnalytics'],
    queryFn: () =>
      callAPI(
        `/api/v4/candidate_pools/${selectedCandidatePoolId}/email_batches/${selectedEmail.item.id}/email_template`,
        null,
        'GET'
      ),
    onError: () =>
      setSnackbarState({
        message: `There was an error getting user email template data`,
        state: 'error'
      }),
    refetchOnWindowFocus: false
  });

  const { data: applications, isLoading: fetchingApplications } = useQuery({
    queryKey: ['applicationsAnalytics', analyticsState.searchQuery, analyticsState.searchSettings],
    queryFn: () => {
      return callPaginatedAPI(
        `/api/v4/candidate_pools/${selectedCandidatePoolId}/email_batches/${
          selectedEmail.item.id
        }/applications?${queryString.stringify(
          {
            ...analyticsState.searchSettings,
            'q[search]': analyticsState.searchQuery
          },
          {
            skipEmptyString: true,
            skipNull: true,
            arrayFormat: 'bracket',
            encode: false
          }
        )}`,
        null,
        'GET'
      ) as Promise<ApplicationsAPIReturn>;
    },
    onError() {
      setSnackbarState({
        message: `There was an error retrieving applications`,
        state: 'error'
      });
    },
    refetchOnWindowFocus: false
  });

  return (
    <>
      <Box>
        <Box sx={{ ...classes.pageHeader, paddingLeft: '0' }}>
          <h1>{selectedEmail.item.email_template}</h1>
        </Box>
        <Box
          sx={{
            backgroundColor: getStatusColor(selectedEmail.item.status)[1],
            padding: '0.4rem 2.5rem',
            width: 'fit-content'
          }}
        >
          <Box
            sx={{
              color: getStatusColor(selectedEmail.item.status)[0],
              fontWeight: 'bold',
              fontSize: '18px'
            }}
          >
            {selectedEmail.item.status.toUpperCase()}
          </Box>
        </Box>
        <Tabs
          value={modalTabValue}
          onChange={(e, value) => setModalTabValue(value)}
          TabIndicatorProps={{
            sx: { maxWidth: modalTabValue === 2 ? '8.5em' : modalTabValue ? '7em' : '5em' }
          }}
          sx={{
            ...classes.tabs,
            '& .MuiTabs-indicator': {
              ...classes.tabIndicator
            },
            margin: '1rem 0 1.5rem 0',
            borderBottom: '1px solid #EEEEEE'
          }}
        >
          <Tab label="Analytics" id="analytics-tab" />
          <Tab label="Applications" id="applications-tab" />
          <Tab label="Email template" id="email-template-tab" />
        </Tabs>
        <Box
          sx={{ ...sharedClasses.navigateBackButton, marginBottom: '40px', marginLeft: '-10px' }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            onClick={() => emailsDispatch({ type: 'REMOVE_SELECTED_ROW_DATA' })}
          >
            <IconButton sx={{ color: '#666666', fontSize: '28px' }}>
              <ArrowCircleLeftIcon />
            </IconButton>
            <Box id="back-to-pool-button">Back to candidate pools</Box>
          </Stack>
        </Box>
        <Box>
          {modalTabValue === 0 && (
            <Analytics
              selectedEmail={selectedEmail}
              emailAnalytics={emailAnalytics}
              isLoading={analyticsLoading}
            />
          )}
        </Box>
        {modalTabValue === 1 && (
          <Applications
            isFetching={fetchingApplications}
            applications={applications}
            applicationsState={analyticsState}
            applicationsDispatch={analyticsDispatch}
          />
        )}
        {modalTabValue === 2 && (
          <EmailTemplates isLoading={emailTemplatesLoading} emailTemplate={emailTemplates} />
        )}
      </Box>
      <StyledSnackbar
        message={snackbarState.message}
        state={snackbarState.state}
        setSnackbarState={setSnackbarState}
      />
    </>
  );
};

export default CandidatePoolAnalytics;
