import React, { useState, useEffect } from 'react';
import useStyles from './useStyles';
import { ICalendlyEvent, ICalendlyScoutProps, IJobPosition } from '../../../../types';
import { withScoutTheme } from '../../ThemeContext/ThemeContext';
import { Dialog, Button, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import ReactLoading from 'react-loading';
import axios from 'axios';
import Api from '../API';
import EventType from '../Modal/components/Steps/Step2/EventType';

interface IProps {
  calendlyAccount: ICalendlyScoutProps,
  job: IJobPosition,
  applicationId: number,
  candidateName: string,
  apiKey: string,
}

function AddToCalendlyEventModal({ job, applicationId, candidateName, calendlyAccount, apiKey }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<ICalendlyEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<ICalendlyEvent[]>([]);
 
  const classes = useStyles();
  const theme = useTheme();

  const handleSubmit = async () => {
    await Api.addToEvent(job.id, {
      application_id: applicationId,
      event_type_url: selectedEvent.scheduling_url,
    });
    location.reload();
  }

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const events = await axios.get(`https://api.calendly.com/event_types?user=${calendlyAccount.user}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${calendlyAccount.access_token}`
          }
        });
        setEvents(events.data.collection.filter((event: ICalendlyEvent) => event.active))
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
      }
    }
    if (!events?.length) getData()
    else setIsLoading(false)
  }, [])

  return (
    <>
      <div className={classes.root}>
        {!isLoading
          ? <>
            <h4>Add to event {job.title} - {candidateName}</h4>
              <div className={classes.recContainer}>
                <div className={classes.recImage}>{ calendlyAccount.user_name.split(' ').map(name => name[0]) }</div>
                <div className={classes.recDetails}>
                  <h4>{ calendlyAccount.user_name }</h4>
                  <a id="calendly-scheduling-url" target="_blank" rel="noreferrer" href={calendlyAccount.scheduling_url}>{ calendlyAccount.scheduling_url }</a>
                </div>
              </div>
              <div className={`${classes.meetingContainer} ${events.length > 3 ? classes.shadow : ''}`}>
                { events.length
                  ? events.map(event =>
                      <EventType
                        key={event.uri}
                        event={event}
                        setSelectedEvent={setSelectedEvent}
                        selected={selectedEvent?.uri === event.uri}
                      />
                    )
                  : <span className={classes.noEvents}>No available events</span>
                }
              </div>
            </>
          : <div className={classes.loading}>
              <ReactLoading type="spin" color={theme.palette.primary.main} width={45} height={45} />
            </div>
        }
        <Box className={classes.buttonContainer}>
          <Button
            id="back-button"
            disableElevation
            variant="outlined"
            color="secondary"
            onClick={() => {window.history.back()}}
            startIcon={<ChevronLeftIcon />}
          >
          </Button>
          <Button
            id="next-button"
            disableElevation
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            endIcon={<ChevronRightRoundedIcon />}
            disabled={!selectedEvent?.uri}
          >
            Book event
          </Button>
        </Box>
      </div>
    </>
  )
}

export default withScoutTheme(AddToCalendlyEventModal)
