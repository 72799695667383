import React, { Dispatch, useMemo, useState } from 'react';
import { Autocomplete, Checkbox, Stack, TextField, Typography } from '@mui/material';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import { ModalType } from '../config';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function MoveApplicationModal({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const [selectedJob, setSelectedJob] = useState<string | null>(null);
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [cloneAttachments, setCloneAttachments] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const { data: jobs, isLoading: loadingJobs } = useQuery({
    queryKey: ['job options'],
    queryFn: async () => {
      if (application?.job) {
        const { res } = await Api.getJobsOptions(application.job.id);
        return res.jobs
          .filter((job: { reference: string; title: string }) => job.reference)
          .map((job: { reference: string; title: string }) => `${job.reference} - ${job.title}`);
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting job options, ${error}`,
          state: 'error'
        }
      }),
    initialData: [],
    enabled: !!application
  });
  const modalState = useMemo(() => {
    switch (modalsOpen) {
      case ModalType.MOVE:
        return {
          errorMessage: 'There was an error moving the application',
          modalTitle: 'Move application',
          textFieldLabel: 'Select job to move application to',
          primaryButtonText: 'Move'
        };
      case ModalType.CLONE:
        return {
          errorMessage: 'There was an error cloning the application',
          modalTitle: 'Clone and move application',
          textFieldLabel: 'Select job to clone and move application to',
          checkboxLabel: 'I want to clone attachments',
          note: 'Please note: By cloning attachments, it may take longer for the cloning process to complete',
          primaryButtonText: 'Clone and move'
        };
    }
  }, [modalsOpen]);

  const { errorMessage } = modalState || {};
  const { mutate: handleApplication, isLoading: handlingApplication } = useMutation({
    mutationFn: async () => {
      if (!application?.job || !selectedJob) return;
      switch (modalsOpen) {
        case ModalType.MOVE: {
          const { res } = await Api.moveApplication(application.job.id, {
            target_job: selectedJob.split(' -')[0],
            application_ids: [application.id]
          });
          return res;
        }
        case ModalType.CLONE: {
          const { res } = await Api.cloneApplication(application.job.id, application.id, {
            to_job_id: selectedJob.split(' -')[0],
            cloning_assets: cloneAttachments
          });
          return res;
        }
      }
    },
    onSuccess: (res) => {
      if (modalsOpen === ModalType.MOVE) {
        window.location.href = `${window.location.origin}${res.new_application_link.replace('applications', 'new_applications')}`;
      }
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `${errorMessage}, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.MOVE || modalsOpen === ModalType.CLONE}
      label="Move application modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>{modalState?.modalTitle}</Stack>
        <Stack sx={{ flexGrow: 1 }}>
          <Autocomplete
            disablePortal
            options={jobs}
            getOptionLabel={(option) => option}
            value={selectedJob}
            sx={{ ...sharedClasses.formAutocomplete, paddingTop: 4, flexGrow: 1 }}
            loading={loadingJobs}
            loadingText="Loading workflows..."
            ListboxProps={{ style: styles.listboxPropsStyle }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={modalState?.textFieldLabel}
                InputLabelProps={{ shrink: true }}
                required
                sx={styles.autoCompleteTextfield}
                helperText={error}
              />
            )}
            onChange={(event, value) => {
              value ? setError('') : setError('Must select a job');
              setSelectedJob(value);
            }}
          />
          {modalsOpen === ModalType.CLONE && (
            <Stack sx={{ marginLeft: 0.5 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={styles.checkbox}
                    checked={cloneAttachments}
                    onChange={(event) => setCloneAttachments(event.target.checked)}
                  />
                }
                label={
                  <Typography style={styles.checkboxLabel}>{modalState?.checkboxLabel}</Typography>
                }
              />
              <Typography sx={styles.modalInfoStyle} variant="subtitle2">
                {modalState?.note}
              </Typography>
            </Stack>
          )}
        </Stack>
        <ModalFooterButtons
          primaryButtonText={modalState?.primaryButtonText}
          primaryButtonCallback={() =>
            selectedJob ? handleApplication() : setError('Must select a job')
          }
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          isLoading={handlingApplication}
          primaryButtonMinWidth={modalsOpen === ModalType.CLONE ? '145px' : '75px'}
        />
      </Stack>
    </StyledModal>
  );
}
