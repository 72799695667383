var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import queryString from "query-string";
import "whatwg-fetch";
const BASE_URL = "/admin/api/";
class Api {
  constructor() {
    __publicField(this, "handleResponse", (response) => {
      if (response.ok) return response.json();
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    });
    __publicField(this, "get", (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}?${queryString.stringify(params, { arrayFormat: "bracket" })}`, {
      headers: this.getHeaders(headers)
    }).then((r) => this.handleResponse(r)));
    __publicField(this, "post", (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "POST",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r)));
    __publicField(this, "delete", (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "DELETE",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r)));
    __publicField(this, "getDashboard", () => this.get("v1/dashboard"));
    __publicField(this, "getDashboardSettings", () => this.get("v1/dashboard/settings"));
    __publicField(this, "saveDashboardSettings", (settings) => this.post("v1/dashboard/settings", { settings }));
    __publicField(this, "getActivities", (beforeId) => this.get("v1/dashboard/activities_feed", { before_id: beforeId }));
  }
  //public authToken = '';
  getHeaders(headers) {
    return Object.assign({}, {
      "Accept": "application/json",
      "Content-Type": "application/json"
      //'Authorization': `Bearer: ${this.authToken}`,
    }, headers);
  }
}
export default new Api();
