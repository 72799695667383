import React from "react";
import useStyles from "../../AdEditor/useStyles";
import sharedStyles from "../../AdList/useStyles";
import PropTypes from "prop-types";
import { Dialog, Button, CircularProgress } from "@material-ui/core";
function GenericDialog({ url, title, description, buttonText, buttonCallback, callbackLoading, isDialogOpen, setDialogOpen }) {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  return /* @__PURE__ */ React.createElement(Dialog, { open: isDialogOpen, onClose: () => setDialogOpen(false), classes: { paper: classes.dialog } }, /* @__PURE__ */ React.createElement("h4", null, title), description && /* @__PURE__ */ React.createElement("span", null, description), /* @__PURE__ */ React.createElement("div", { className: classes.dialogButtonContainer }, /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: sharedClasses.buttonSecondaryLight,
      onClick: () => setDialogOpen(false),
      style: { padding: "10px 30px" }
    },
    "Cancel"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: buttonText === "Clone ad" ? sharedClasses.buttonSecondary : sharedClasses.buttonRed,
      onClick: () => buttonCallback(url),
      style: { padding: "10px 30px" }
    },
    callbackLoading ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" }) : buttonText
  )));
}
GenericDialog.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonCallback: PropTypes.func,
  callbackLoading: PropTypes.bool.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired
};
export default GenericDialog;
