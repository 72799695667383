import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useReducer,
  Dispatch,
  SetStateAction
} from 'react';
import { Box, Button, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { WelcomePage } from './Steps/WelcomePage';
import AIStudioTeaserModal from './AIStudioTeaserModal';
import JobDescriptionInput from './Steps/JobDescriptionInput';
import OptionalInput from './Steps/OptionalInput';
import DisplayResponse from './Steps/DisplayResponse';
import { classes } from './styles';
import { FelixButtonSVG } from '../SharedComponents/Graphics/FelixSVG';
import LoadingResponse from '../SharedComponents/Steps/LoadingResponse';
import QuestionsInput from './Steps/QuestionsInput';
import GenericDialog from '../../NewUI/Components/Modals/GenericDialog';
import ErrorScreen from '../SharedComponents/Steps/ErrorScreen';
import { navbarWidths } from '../../NewUI/Layout/Navbar/config';
import { welcomeMessage } from './config';
import { aiStudioReducer, initialAIStudioState } from './reducer';
import { convertToLocalEnglish } from '../../NewUI/Components/Utilities/convertToLocalEnglish';
import FelixNotification from './FelixNotification';
import { serialize } from 'object-to-formdata';

export default function AIStudioModal({
  aiStudioEnabled,
  aiSummariesEnabled,
  aiStudioFrenchEnabled,
  showPreview,
  navbarState,
  jobFlow,
  setTlpModalOpen,
  prefillData,
  setGeneratedContent
}: {
  aiStudioEnabled: boolean;
  aiSummariesEnabled: boolean;
  aiStudioFrenchEnabled: boolean;
  showPreview?: boolean;
  navbarState: keyof typeof navbarWidths;
  jobFlow?: boolean;
  setTlpModalOpen?: Dispatch<SetStateAction<boolean>>;
  prefillData?: {
    title: string;
    country: string;
  };
  setGeneratedContent?: (content: string) => void;
}) {
  const [aiStudioState, dispatch] = useReducer<typeof aiStudioReducer>(
    aiStudioReducer,
    initialAIStudioState
  );

  const typeWriterRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const caretRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const requestController = useRef<AbortController | null>(null);
  const aiButtonAnchorRef = useRef<HTMLElement | null>(null);

  const hideButton = useMemo(() => {
    // is the user on a page that breaks MUI 5?
    const URLPATH = new URL(window.location.href).pathname;
    const pathRegexList = [/entities.*edit/, /events/];
    const isOnIncompatiblePage = pathRegexList.some((regex) => regex.test(URLPATH));
    const showButton = aiStudioEnabled || aiSummariesEnabled || showPreview;

    return isOnIncompatiblePage || !showButton;
  }, [aiStudioEnabled, aiSummariesEnabled, showPreview]);

  const generateDescription = useCallback(async () => {
    dispatch({ type: 'GO_TO_LOADING_STEP' });
    requestController.current = new AbortController();
    const data = {
      ai_studio: {
        type: 'tlp',
        tone: aiStudioState.generatedTone,
        job_details: aiStudioState.jobDetails,
        send_email_copy: aiStudioState.sendEmail,
        french: aiStudioState.aiStudioFrenchEnabled,
        ...(aiStudioState.document && { document: aiStudioState.document })
      }
    };
    const serializedData = serialize(data);
    try {
      const response = await fetch('/api/v4/ai_studio', {
        signal: requestController.current.signal,
        method: 'POST',
        body: serializedData,
        formData: true
      });
      const description = await response.json();
      if (response && description.result && description.result.length > 30) {
        const localEnglishDescription = convertToLocalEnglish(description.result);
        dispatch({ type: 'SET_GENERATED_RESPONSE', payload: localEnglishDescription });
      } else {
        throw new Error('No response from AI API');
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Description generation aborted');
      }
      console.error(error);
      dispatch({ type: 'SET_GENERATION_ERROR', payload: true });
    } finally {
      if (!requestController.current.signal.aborted) {
        dispatch({ type: 'GO_TO_ERROR_STEP' });
      }
    }
  }, [
    aiStudioState.aiStudioFrenchEnabled,
    aiStudioState.generatedTone,
    aiStudioState.jobDetails,
    aiStudioState.sendEmail,
    aiStudioState.document
  ]);

  const generateQuestions = useCallback(async () => {
    requestController.current = new AbortController();
    dispatch({ type: 'GO_TO_LOADING_STEP' });
    try {
      const response = await fetch('/api/v4/ai_studio', {
        signal: requestController.current.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ai_studio: {
            type: 'questions',
            question_type: aiStudioState.questionType,
            job_details: aiStudioState.jobDetails,
            number_of_questions: 5
          }
        })
      });
      const questions = await response.json();
      if (response && questions.result && questions.result.length > 30) {
        const localEnglishQuestions = convertToLocalEnglish(questions.result);
        dispatch({ type: 'SET_GENERATED_RESPONSE', payload: localEnglishQuestions });
      } else {
        throw new Error('No response from AI API');
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Description generation aborted');
      }
      console.error(error);
      dispatch({ type: 'SET_GENERATION_ERROR', payload: true });
    } finally {
      if (!requestController.current.signal.aborted) {
        dispatch({ type: 'GO_TO_ERROR_STEP' });
      }
    }
  }, [aiStudioState.jobDetails, aiStudioState.questionType]);

  const typeWriter = useCallback((text: string, i: number) => {
    if (i < text.length) {
      dispatch({ type: 'ADD_TO_WELCOME_HEADER', payload: text.charAt(i) });
      typeWriterRef.current = setTimeout(function () {
        typeWriter(text, i + 1);
      }, 100);
    }
    if (i === text.length) {
      caretRef.current = setTimeout(() => {
        dispatch({ type: 'SET_SHOW_CARET', payload: false });
      }, 2200);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: 'CLEAR_WELCOME_HEADER' });
    if (!aiStudioState.open || aiStudioState.currentStep !== 0) return;
    typeWriter(welcomeMessage[aiStudioState.aiStudioFrenchEnabled ? 'french' : 'english'], 0);
    return () => {
      if (typeWriterRef.current) {
        clearTimeout(typeWriterRef.current);
      }
      if (caretRef.current) {
        clearTimeout(caretRef.current);
      }
    };
  }, [
    typeWriter,
    aiStudioState.open,
    aiStudioState.currentStep,
    aiStudioState.aiStudioFrenchEnabled
  ]);

  const handleClose = useCallback(() => {
    dispatch({ type: 'CLOSE_MODAL' });
    jobFlow && setTlpModalOpen && setTlpModalOpen(false);
    // Abort any pending requests
    requestController?.current?.abort('User quit the modal');
  }, [jobFlow, setTlpModalOpen]);

  const handleCloseAction = () => {
    aiStudioState.currentStep === 0
      ? handleClose()
      : dispatch({ type: 'SET_CONFIRM_CANCEL_IS_OPEN', payload: true });
  };

  // Check if the Recruit sidebar is collapsed and set the state accordingly
  useEffect(() => {
    if (navbarState) return;
    const navbarElement = document.querySelector('#sidebar');
    const navbarObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect.width < 200) {
        dispatch({ type: 'SET_NAVBAR_IS_COLLAPSED', payload: true });
      } else {
        dispatch({ type: 'SET_NAVBAR_IS_COLLAPSED', payload: false });
      }
    });
    navbarElement && navbarObserver.observe(navbarElement);
    return () => {
      navbarObserver.disconnect();
    };
  }, [navbarState]);

  // Handle opening modal from the New Job flow
  useEffect(() => {
    jobFlow &&
      dispatch({ type: 'INIT_NEW_JOB_FLOW', payload: prefillData || { title: '', country: '' } });
  }, [jobFlow, prefillData]);

  const stepSelect = useCallback(
    (step) => {
      if (aiStudioState.generationError) {
        return (
          <ErrorScreen
            regenerateCallback={
              aiStudioState.generationType === 'description'
                ? generateDescription
                : generateQuestions
            }
            setGenerationError={(value: boolean) =>
              dispatch({ type: 'SET_GENERATION_ERROR', payload: value })
            }
            backToHome={() => dispatch({ type: 'GO_TO_HOME_STEP' })}
          />
        );
      }
      switch (step) {
        case 0:
          return (
            <WelcomePage
              dispatch={dispatch}
              aiStudioState={aiStudioState}
              aiStudioFrenchEnabled={aiStudioFrenchEnabled}
            />
          );
        case 1:
          return (
            <JobDescriptionInput
              dispatch={dispatch}
              aiStudioState={aiStudioState}
              generateDescription={generateDescription}
              jobFlow={jobFlow}
            />
          );
        case 2:
          return <OptionalInput dispatch={dispatch} aiStudioState={aiStudioState} />;
        case 3:
          return <LoadingResponse generationType={aiStudioState.generationType} />;
        case 4:
          return (
            <DisplayResponse
              dispatch={dispatch}
              aiStudioState={aiStudioState}
              generateDescription={generateDescription}
              generateQuestions={generateQuestions}
              jobFlow={jobFlow}
              setGeneratedContent={setGeneratedContent}
              handleClose={handleClose}
            />
          );
        case 5:
          return (
            <QuestionsInput
              dispatch={dispatch}
              aiStudioState={aiStudioState}
              generateQuestions={generateQuestions}
            />
          );
        default:
          return 'Something went wrong';
      }
    },
    [
      aiStudioFrenchEnabled,
      aiStudioState,
      generateDescription,
      generateQuestions,
      handleClose,
      jobFlow,
      setGeneratedContent
    ]
  );

  const buttonClass = () => {
    return {
      ...classes.buttonContainer,
      ...(aiStudioState.buttonHovered &&
        !(aiStudioState.notificationData?.length > 0) &&
        classes.hoverAnimation),
      ...(aiStudioState.navbarIsCollapsed && classes.collapsedNavbar),
      ...(aiStudioState.notificationData?.length > 0 && classes.notificationAnimation),
      ...(!!navbarState && classes.newNavbarButtonContainer),
      ...(navbarState === 'collapsed' && classes.newNavbarCollapsed)
    };
  };

  if (hideButton) return null;

  return (
    <Box sx={{ zIndex: 2 }}>
      {!jobFlow && (
        <>
          <Box
            id={`ai-studio-button${aiStudioEnabled ? '' : '-teaser'}`}
            sx={buttonClass()}
            onMouseEnter={() => dispatch({ type: 'SET_BUTTON_HOVERED', payload: true })}
            onMouseLeave={() => dispatch({ type: 'SET_BUTTON_HOVERED', payload: false })}
            onClick={() => dispatch({ type: 'OPEN_MODAL' })}
          >
            <Box sx={classes.avatarContainer} ref={aiButtonAnchorRef}>
              <FelixButtonSVG />
            </Box>
            {(navbarState ? navbarState === 'expanded' : !aiStudioState.navbarIsCollapsed) && (
              <>
                <Button sx={classes.button}>{!aiStudioState.open ? 'AI Studio' : 'Close'}</Button>
                {!aiStudioState.open && <Box sx={classes.betaPill}>Beta</Box>}
              </>
            )}
          </Box>
          {(aiStudioEnabled || aiSummariesEnabled) && (
            <FelixNotification
              anchorEl={aiButtonAnchorRef.current}
              aiStudioState={aiStudioState}
              dispatch={dispatch}
            />
          )}
        </>
      )}
      {aiStudioEnabled ? (
        <>
          <Modal
            open={aiStudioState.open || !!jobFlow}
            onClose={handleCloseAction}
            aria-labelledby="ai-studio-title"
            aria-describedby="ai-studio-description"
          >
            <Box sx={classes.modal}>
              <CloseIcon onClick={handleCloseAction} sx={classes.closeIcon} />
              {stepSelect(aiStudioState.currentStep)}
            </Box>
          </Modal>
          <GenericDialog
            isDialogOpen={aiStudioState.confirmCancelIsOpen}
            setDialogOpen={(value: boolean) =>
              dispatch({ type: 'SET_CONFIRM_CANCEL_IS_OPEN', payload: value })
            }
            title="Close AI Studio?"
            description="All unsaved changes will be lost."
            buttonCallback={() => {
              dispatch({ type: 'SET_CONFIRM_CANCEL_IS_OPEN', payload: false });
              handleClose();
            }}
            callbackLoading={false}
            buttonText="Close AI Studio"
            url=""
          />
        </>
      ) : (
        <AIStudioTeaserModal open={aiStudioState.open} handleCloseAction={handleCloseAction} />
      )}
    </Box>
  );
}
