import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  container: {
    background: "#F3F3F3",
    borderRadius: 8,
    padding: 40,
    width: "100%"
  },
  banner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  toggleButtonGroup: {
    borderRadius: 8,
    margin: "20px 0",
    "& button": {
      padding: "6px 16px",
      borderRadius: 0,
      textTransform: "capitalize",
      borderRight: "1px solid #f3f3f3",
      "&:first-child": {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6
      },
      "&:last-child": {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderRight: 0
      }
    }
  },
  containerOuter: {
    marginTop: 40,
    background: "#FFF",
    borderRadius: 8,
    padding: 40,
    display: "flex",
    justifyContent: "center",
    "& button:focus": {
      outline: 0
    }
  },
  buttonPrimary: {
    borderRadius: 6,
    padding: "14px 30px",
    color: "#FFF",
    fontWeight: "600",
    fontSize: 14,
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.dark
    }
  },
  buttonPrimaryLight: {
    borderRadius: 6,
    padding: "14px 30px",
    background: theme.palette.primary.light,
    color: theme.palette.primary
  },
  buttonSecondary: {
    borderRadius: 6,
    padding: "14px 30px",
    color: "#FFF",
    fontWeight: "600",
    fontSize: 14,
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.dark
    }
  },
  buttonSecondaryLight: {
    borderRadius: 6,
    padding: "14px 30px",
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#FFF"
    }
  },
  buttonRed: {
    borderRadius: 6,
    padding: "14px 30px",
    color: "#FFF",
    fontWeight: "600",
    fontSize: 14,
    background: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.dark,
      color: "#FFF"
    }
  },
  buttonRedLight: {
    padding: "14px 30px",
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.main,
      color: "#FFF"
    }
  },
  buttonGreen: {
    padding: "14px 30px",
    background: theme.palette.success.main
  },
  buttonGreenLight: {
    padding: "14px 30px",
    background: theme.palette.success.light,
    color: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.main,
      color: "#FFF"
    }
  },
  buttonWhite: {
    padding: "14px 30px",
    background: "#FFF",
    color: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#FFF"
    }
  },
  buttonGrey: {
    padding: "14px 30px",
    background: "#FFF",
    color: "#DDDDDD",
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#FFF"
    }
  },
  buttonActive: {
    color: theme.palette.secondary.main
  },
  disableIcon: {
    pointerEvents: "none",
    opacity: 0.5
  }
}));
