import React from "react";
import useStyles from "./useStyles";
import PropTypes from "prop-types";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
function CandidateDetails({ conversation }) {
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement("div", { className: classes.root }, conversation.length > 1 ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h4", null, `${conversation[0].candidate_name}, ${conversation[1].candidate_name}`), conversation.length - 2 > 0 && /* @__PURE__ */ React.createElement("div", { className: classes.remainingApplicants }, /* @__PURE__ */ React.createElement(GroupRoundedIcon, { color: "primary", fontSize: "small" }), "+", conversation.length - 2)) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h4", null, conversation[0].candidate_name), /* @__PURE__ */ React.createElement("span", null, conversation[0].candidate_phone)));
}
CandidateDetails.propTypes = {
  conversation: PropTypes.array.isRequired
};
export default CandidateDetails;
