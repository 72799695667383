import * as React from 'react';
import {
  Box,
  Autocomplete,
  TextField
} from '@mui/material';
import { classes } from '../../EditJob/styles';
import { SearchState, SearchAction } from '../../../reports/DataVizSection/useReport';

interface Props {
  userOptions: {
    value: string;
    label: string;
  }
  isLoading: boolean;
  reportState: SearchState;
  reportDispatch: React.Dispatch<SearchAction>;
}

export default function UserField(myProps: Props) {
  const {
    userOptions,
    reportState,
    isLoading,
    reportDispatch
  } = myProps;
  return (
    <Box sx={{ position: 'relative', marginBottom: '4px' }}>
      <Autocomplete
        id="user"
        options={userOptions}
        getOptionLabel={(option) => option.label}
        value={reportState.timeToHireFields.user}
        loading={userOptions?.length < 1}
        loadingText={isLoading ? 'Loading users...' : 'No users'}
        sx={{ ...classes.formAutocomplete, width: '225px', marginTop: '24px' }}
        ListboxProps={{
          style: {
            fontFamily: 'Source Sans Pro, sans-serif',
            color: '#333333',
            maxHeight: '200px'
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="User" InputLabelProps={{ shrink: true }} />
        )}
        onChange={(event, value) => {
          reportDispatch({
            type: 'timeToHireFields',
            payload: { 'user': value }
          })
        }}
      />
    </Box>
  );
}
