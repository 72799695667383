"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import moment from "moment";
import { dateRangeOptions } from "../DataVizSection/GraphDefaultOptions";
import {
  sharedTableInitialState,
  sharedTableActionReducer
} from "../../../TableWithSearchFieldDropDownAndFilter/reducer";
import {
  defaultActiveColumns,
  dropdownOptions,
  filteringStates
} from "./CustomReportsTable/configs";
export const previewState = {
  tableHeaders: [],
  data: null
};
export const createReportOptions = {
  entitiesOptions: {
    current_entity: null,
    entities: []
  },
  jobs: [],
  candidates: [],
  applications: [],
  approvals: [],
  interviews: [],
  smart_forms: [],
  currentUser: null
};
export const modalStates = {
  open: false,
  currentActiveField: 0
};
export const initialCustomReportState = (fetchParams) => {
  return __spreadProps(__spreadValues(__spreadValues({}, sharedTableInitialState), modalStates), {
    data: {
      name: "",
      entityIds: [{ id: fetchParams.entity_id, name: "All entities" }],
      jobFields: [],
      candidateFields: [],
      applicationFields: [],
      eventInterviewFields: [],
      approvalFields: [],
      smartFormFields: []
    },
    startDate: moment().subtract(dateRangeOptions[1].value, "days").toDate(),
    endDate: moment().toDate(),
    createReportOptions,
    openPreview: false,
    dateRange: dateRangeOptions[1],
    previewTable: previewState,
    activeColumns: defaultActiveColumns,
    fieldOptions: dropdownOptions,
    filters: filteringStates,
    filterOptions: filteringStates,
    entity_id: fetchParams.entity_id,
    user: fetchParams.user,
    editingTitle: false
  });
};
export const reportReducer = (state, action) => {
  var _a;
  switch (action.type) {
    case "CLOSE_MODAL":
      return __spreadProps(__spreadValues({}, state), { open: false });
    case "EXIT_PREVIEW":
      return __spreadProps(__spreadValues({}, state), {
        data: initialCustomReportState(action.payload).data,
        startDate: moment().subtract(dateRangeOptions[1].value, "days").toDate(),
        endDate: moment().toDate(),
        dateRange: dateRangeOptions[1],
        reportId: void 0,
        openPreview: !state.openPreview
      });
    case "SET_ACTIVE_FIELD":
      return __spreadProps(__spreadValues({}, state), { currentActiveField: action.payload });
    case "SET_DATE":
      return __spreadValues(__spreadValues({}, state), action.payload);
    case "SET_ENTITIES":
      return __spreadProps(__spreadValues({}, state), {
        data: __spreadProps(__spreadValues({}, state.data), {
          entityIds: action.payload
        })
      });
    case "SET_CREATE_REPORT_OPTIONS":
      return __spreadProps(__spreadValues({}, state), {
        createReportOptions: __spreadValues(__spreadValues({}, state.createReportOptions), action.payload)
      });
    case "SET_CREATE_REPORT_PREVIEW":
      return __spreadProps(__spreadValues({}, state), {
        openPreview: true,
        open: false,
        data: action.payload
      });
    case "SET_EDIT_TITLE_FIELDS":
      return __spreadProps(__spreadValues({}, state), {
        editingTitle: action.payload,
        open: true
      });
    case "SET_PREVIEW_TABLE":
      return __spreadProps(__spreadValues({}, state), {
        previewTable: __spreadValues({}, action.payload)
      });
    case "SET_PREVIEW_REPORT":
      return __spreadProps(__spreadValues({}, state), {
        reportId: action.payload.id,
        openPreview: true,
        data: __spreadProps(__spreadValues({}, (_a = action.payload.query_config) == null ? void 0 : _a.data), {
          entityIds: state.data.entityIds
        })
      });
    default:
      return sharedTableActionReducer(state, action);
  }
};
