import React, { useState } from 'react';
import { Box, FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { styles } from './styles';
import { useDebouncedCallback } from 'use-debounce';

export default function Search({
  idLabel,
  placeholder,
  setSearch
}: {
  idLabel: string;
  placeholder: string;
  setSearch: (query: string) => void;
}) {
  const [tempSearch, setTempSearch] = useState('');
  const debouncedAPICall = useDebouncedCallback((searchQuery) => {
    setSearch(searchQuery);
  }, 600);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearch(event.target.value);
    debouncedAPICall(event.target.value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <OutlinedInput
          id={`${idLabel}-search`}
          sx={styles.searchInput}
          placeholder={placeholder}
          value={tempSearch}
          onChange={handleChange}
          startAdornment={
            <InputAdornment sx={{ padding: '0px 0px 0px 14px' }} position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}
