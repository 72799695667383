import React from 'react'

export default class AdminIntegrationsNew extends React.Component {
    render() {
        return (
            <div className="layer-content">
                <div className="integrations-new">
                    <div className="title">New Integration Setup</div>
                    <form className="integration">
                        <div className="field">
                            <label htmlFor="token" className="field">Indeed integration token</label>
                            <input type="text" className="field" name="token"/>
                        </div>
                        <div className="field">
                            <label htmlFor="secret" className="field">Indeed integration secret key</label>
                            <input type="text" className="field" name="secret"/>
                        </div>
                        
                        <div className="field">
                            <label htmlFor="name" className="field">Indeed company name</label>
                            <input type="text" className="field" name="name"/>
                        </div>
                        <div className="actions">
                            <input className="action update" type="button" value="Create" />
                            <a className="action cancel" href="#">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
} 