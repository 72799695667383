"use strict";
export const FILTERS_KEY = "filters";
export const NOTES_ERROR_MESSAGE = "Emojis are not allowed";
export const TIMESTAMP_REGEX = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/;
export const DELAY_OPTIONS = [
  "None",
  "1 hour",
  "2 hours",
  "4 hours",
  "1 day",
  "2 days",
  "3 days",
  "4 days",
  "5 days",
  "6 days",
  "7 days",
  "14 days",
  "30 days"
];
