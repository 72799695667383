import React, { Component } from 'react';
import IntegrationSettings from './integration_settings';

const Integrations = (props) => {
  return (
    <div>
      {props.integrations.map((integration) => {
        return (
          <IntegrationSettings
            key={integration.id}
            integration={integration}
            entityId={props.entity_id}
            url={props.url}
          />
        );
      })}
    </div>
  );
};

export default Integrations;
