import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Search from './Components/Search';
import { classes } from './styles';
import ProfileDropdown from './Components/ProfileDropdown/ProfileDropdown';
import BreadcrumbsContainer from './Components/Breadcrumbs';
import SwitchEntityDialog from './Components/SwitchEntityDialog';

export interface IUserInfo {
  firstName: string;
  lastName: string;
  roleNames: string;
  entity: {
    id: number;
    name: string;
    url: string;
    multiEntitiesAccess: boolean;
  };
  links: {
    unmasqueradeURL: string;
    sourceURL: string;
  };
}

const Header = ({
  userInfo,
  isUAT,
  breadcrumbsData
}: {
  userInfo: IUserInfo;
  isUAT: boolean;
  breadcrumbsData: string;
}) => {
  const [widgetsbarExpanded, setWidgetsbarExpanded] = useState<boolean>(
    localStorage.getItem('widgetsbarState') === 'expanded'
  );
  const [changingEntity, setChangingEntity] = useState(false);

  useEffect(() => {
    const checkLocalStorage = () => {
      if (localStorage.getItem('widgetsbarState') === 'expanded') {
        setWidgetsbarExpanded(true);
      } else {
        setWidgetsbarExpanded(false);
      }
    };

    window.addEventListener('onWidgetBarChange', checkLocalStorage);
    return () => {
      window.removeEventListener('onWidgetBarChange', checkLocalStorage);
    };
  }, []);

  return (
    <Box id="react-header">
      {isUAT && <Box sx={classes.uatBanner} />}
      <Box sx={{ ...classes.headerContainer, paddingRight: widgetsbarExpanded ? '60px' : '76px' }}>
        <BreadcrumbsContainer breadcrumbsData={breadcrumbsData} />
        <Search />
        {userInfo?.entity?.multiEntitiesAccess && (
          <SwitchEntityDialog
            open={changingEntity}
            setChangingEntity={setChangingEntity}
            userInfo={userInfo}
          />
        )}
        <ProfileDropdown
          userInfo={userInfo}
          widgetsbarExpanded={widgetsbarExpanded}
          setChangingEntity={setChangingEntity}
        />
      </Box>
    </Box>
  );
};

export default Header;
