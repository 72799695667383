"use strict";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const startOfDay = (date, userTimezone) => {
  return dayjs.utc(date).startOf("day").tz(userTimezone || browserTimezone);
};
export const endOfDay = (date, userTimezone) => {
  return dayjs.utc(date).endOf("day").tz(userTimezone || browserTimezone);
};
