export default [{
  width: "100%",
  height: "760px",
  title: "Activity Report",
  key: "StatusChangeVolume"
}, {
  width: "100%",
  height: "380px",
  title: "Application Sources",
  key: "application_source_dashboard_1"
}, {
  width: "100%",
  height: "760px",
  title: "Time to Hire",
  key: "time_to_hire_dashboard_1"
}];
