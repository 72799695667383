import React, { Dispatch, FC, useState } from 'react';
import {
  Box,
  CircularProgress,
  Modal,
  Button,
  TextField,
  Autocomplete,
  Stack,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import { useQuery } from '@tanstack/react-query';
import RecruitApi from '../../RecruitApi';
import { IEntity, IUser } from '../types';

interface IUserEventsModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
  entities: IEntity[];
  fetchingEntities: boolean;
  getEntitiesAndChildren: (id: number) => void;
}

const UserEventsModal: FC<IUserEventsModal> = ({
  dispatch,
  state,
  entities,
  fetchingEntities,
  getEntitiesAndChildren
}) => {
  const { filtersModal, selectedEntities, selectedUser, includeSubEntities } = state;
  const [entity, setEntity] = useState<IEntity | null>(
    entities.find((e) => e.id === selectedEntities[0]) || null
  );
  const [user, setUser] = useState<IUser | null>(null);
  const [includeSub, setIncludeSub] = useState<boolean>(includeSubEntities);

  const {
    data: users,
    isLoading: loadingUsers,
    isFetching: fetchingUsers
  } = useQuery({
    queryKey: ['users', entity],
    queryFn: async () => {
      const { res } = await RecruitApi.getUsers({
        entity_id: entity?.id || undefined
      });
      if (res.users.length) {
        return [{ id: 0, email: '', full_name: 'All users' }, ...res.users] as IUser[];
      } else {
        return [];
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading users, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: () => {
      setUser(users.find((u) => u.id === selectedUser) || null);
    },
    initialData: []
  });

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, 'User Events Report': false }
    });
  };

  return (
    <Modal
      open={filtersModal['User Events Report']}
      onClose={handleClose}
      aria-labelledby="aurion-filters-modal"
    >
      <Box sx={{ ...classes.actionsModal, maxHeight: '460px', width: '720px' }}>
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>User events report filters</Box>
        </Box>
        <Stack sx={{ marginTop: '1.75rem', flexGrow: 1 }}>
          <Box sx={{ ...classes.modalFormLine }}>
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              options={entities}
              getOptionLabel={(option) => option.name}
              loading={fetchingEntities}
              loadingText={fetchingEntities ? 'Loading entities...' : 'No Entities'}
              value={entity}
              onChange={(_event, newValue) => {
                if (newValue) {
                  setEntity(newValue);
                } else {
                  setEntity(null);
                }
                setIncludeSub(false);
                setUser(null);
              }}
              sx={{
                ...sharedClasses.formAutocomplete,
                width: '100%',
                marginBottom: 3,
                label: { fontSize: '19px' }
              }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro',
                  color: '#333333',
                  maxHeight: '200px'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Entity"
                  sx={{
                    '& .MuiAutocomplete-root label': { fontSize: '14px' },
                    input: { '&::placeholder': { opacity: 1 } }
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {fetchingEntities ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  placeholder="Please select"
                />
              )}
            />
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              options={users}
              disabled={!entity}
              getOptionLabel={(option) =>
                `${option.full_name} ${option.email ? `(${option.email})` : ''}`
              }
              value={user}
              onChange={(_event, newValue) => {
                if (newValue) {
                  setUser(newValue);
                } else {
                  setUser(null);
                }
                setIncludeSub(false);
              }}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro',
                  color: '#333333',
                  maxHeight: '200px',
                  textTransform: 'capitalize'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {(loadingUsers || fetchingUsers) && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  placeholder={`Please select ${entity ? '' : 'an entity'}`}
                  sx={{ input: { '&::placeholder': { opacity: 1 }, textTransform: 'capitalize' } }}
                />
              )}
            />
            {user?.id === 0 && (
              <FormControlLabel
                control={
                  <Checkbox
                    sx={classes.checkbox}
                    checked={includeSub}
                    onClick={() => setIncludeSub(!includeSub)}
                    disableRipple
                  />
                }
                label="Include sub-entities"
                sx={classes.checkboxLabel}
              />
            )}
          </Box>
        </Stack>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset' }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              const filter: Record<string, string> = {};
              if (entity) {
                filter.Entity = entity.name;
                dispatch({ type: 'SELECTED_ENTITIES', payload: [entity.id] });
              } else {
                delete filter.Entity;
                dispatch({ type: 'SELECTED_ENTITIES', payload: [] });
              }
              if (user) {
                filter.User = user.full_name;
                dispatch({ type: 'SELECTED_USER', payload: user.id });
              } else {
                delete filter.User;
                dispatch({ type: 'SELECTED_USER', payload: null });
              }
              if (includeSub) {
                filter['Include sub-entities'] = 'True';
                entity && getEntitiesAndChildren(entity.id);
              } else {
                delete filter['Include sub-entities'];
              }
              dispatch({ type: 'INCLUDE_SUB_ENTITIES', payload: includeSub });
              dispatch({ type: 'SET_FILTERS', payload: filter });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default UserEventsModal;
