import { Box } from '@mui/material';
import React from 'react';
import dashboardStyles from '../styles';

function KpiBanner() {
  return (
    <Box
      id="dashboard-marketplace"
      sx={{ ...dashboardStyles.section, ...dashboardStyles.kpiSection, ...styles.section }}
      onClick={() => window.open('https://scouttalenthq.com/marketplace', '_blank')}
    ></Box>
  );
}

const styles = {
  section: {
    minWidth: '196px',
    padding: 0,
    backgroundImage: `url('/images/dashboard/marketplace_tiles.svg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    overflow: 'hidden',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
      cursor: 'pointer'
    }
  }
};

export default KpiBanner;
