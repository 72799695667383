import React, { useState, Dispatch, SetStateAction } from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { classes } from './styles';
import AIStudioModal from '../AIStudioModal/AIStudioModal';
import AIStudioField from '../AIStudioQuestions/AIStudioQuestions';
import GenericDialog from '../../NewUI/Components/Modals/GenericDialog';
import { QuestionsPreview } from './QuestionsPreview';
import DisplayHTMLCollapsible from '../SharedComponents/DisplayHTMLCollapsible';

export interface IGeneratedContent {
  talentLandingPage: string;
  screeningQuestions: {
    title: string;
    enabled: boolean;
    required: boolean;
    rateable: boolean;
    confidential: boolean;
  }[];
}

function AIStudioNewJob({
  generatedContent,
  setGeneratedContent,
  prefillData
}: {
  generatedContent: IGeneratedContent;
  setGeneratedContent: Dispatch<SetStateAction<IGeneratedContent>>;
  prefillData: {
    title: string;
    country: string;
  };
}) {
  const [tlpModalOpen, setTlpModalOpen] = useState(false);
  const [questionsModalOpen, setQuestionsModalOpen] = useState(false);
  const [tlpButtonIsHovered, setTlpButtonIsHovered] = useState(false);
  const [questionsButtonIsHovered, setQuestionsButtonIsHovered] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const tlpButtonEndIcon = () => {
    if (tlpButtonIsHovered && generatedContent.talentLandingPage) {
      return <DeleteIcon sx={{ color: '#E37D7A' }} />;
    } else {
      return generatedContent.talentLandingPage ? <DoneIcon /> : <AddIcon />;
    }
  };

  const questionButtonEndIcon = () => {
    if (generatedContent.screeningQuestions.length > 0) {
      return questionsButtonIsHovered ? <AddIcon sx={{ color: '#76D18F' }} /> : <DoneIcon />;
    } else {
      return <AddIcon />;
    }
  };

  const handleTlpButtonClick = () => {
    if (generatedContent.talentLandingPage) {
      setDeleteConfirmOpen(true);
    } else {
      setTlpModalOpen(true);
    }
  };

  const handleQuestionsButtonClick = () => {
    setQuestionsModalOpen(true);
  };

  const confirmTlpDelete = () => {
    setGeneratedContent((prev) => ({ ...prev, talentLandingPage: '' }));
    setDeleteConfirmOpen(false);
  };

  const handleSwitchChange = (index: number, type: string) => {
    const newQuestions = [...generatedContent.screeningQuestions];
    newQuestions[index][type as keyof (typeof newQuestions)[index]] = !newQuestions[index][type];
    setGeneratedContent((prev) => ({ ...prev, screeningQuestions: newQuestions }));
  };

  const handleRemoveQuestion = (index: number) => {
    const newQuestions = [...generatedContent.screeningQuestions];
    newQuestions.splice(index, 1);
    setGeneratedContent((prev) => ({ ...prev, screeningQuestions: newQuestions }));
  };

  return (
    <>
      <Box sx={classes.rootContainer}>
        <Box sx={classes.aiHeader}>Add generated content with AI Studio</Box>
        <Box sx={classes.buttonsContainer}>
          <Button
            sx={generatedContent.talentLandingPage ? classes.aiButtonFilled : classes.aiButton}
            onClick={handleTlpButtonClick}
            endIcon={tlpButtonEndIcon()}
            onMouseEnter={() => setTlpButtonIsHovered(true)}
            onMouseLeave={() => setTlpButtonIsHovered(false)}
          >
            Generate talent landing page
          </Button>
          <Button
            sx={
              generatedContent.screeningQuestions.length > 0
                ? classes.aiButtonFilled
                : classes.aiButton
            }
            endIcon={questionButtonEndIcon()}
            onClick={handleQuestionsButtonClick}
            onMouseEnter={() => setQuestionsButtonIsHovered(true)}
            onMouseLeave={() => setQuestionsButtonIsHovered(false)}
          >
            Generate screening questions
          </Button>
        </Box>
        {generatedContent.talentLandingPage && (
          <Box sx={classes.previewContainer}>
            <h3>Talent landing page preview</h3>
            <DisplayHTMLCollapsible
              content={generatedContent.talentLandingPage}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          </Box>
        )}
        {generatedContent.screeningQuestions.length > 0 && (
          <QuestionsPreview
            questions={generatedContent.screeningQuestions}
            handleSwitchChange={handleSwitchChange}
            handleRemoveQuestion={handleRemoveQuestion}
          />
        )}
      </Box>
      {tlpModalOpen && (
        <AIStudioModal
          aiStudioEnabled={true}
          showPreview={false}
          setTlpModalOpen={setTlpModalOpen}
          jobFlow={true}
          prefillData={prefillData}
          setGeneratedContent={(content) =>
            setGeneratedContent((prev) => ({
              ...prev,
              talentLandingPage: content
            }))
          }
        />
      )}
      {questionsModalOpen && (
        <AIStudioField
          jobTitle={prefillData.title}
          modalsOpen={questionsModalOpen}
          setModalsOpen={(open: boolean) => setQuestionsModalOpen(open)}
          setGeneratedContent={setGeneratedContent}
        />
      )}
      <GenericDialog
        isDialogOpen={deleteConfirmOpen}
        setDialogOpen={setDeleteConfirmOpen}
        title="Remove talent landing page?"
        description="Are you sure you want to remove the talent landing page? This action cannot be undone."
        buttonCallback={confirmTlpDelete}
        callbackLoading={false}
        buttonText="Remove TLP"
        url=""
      />
    </>
  );
}

export default AIStudioNewJob;
