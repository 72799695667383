export const LIST_ITEMS_REGEX = /\b([0-9]+\. )|([0-9]+\) )/g;
export const questionTypes = [
  'general',
  'technical',
  'behavioural',
  'motivational',
  'case',
  'situational',
  'brainteasers'
];

export const aiToneTypes = [
  'neutral',
  'professional',
  'casual',
  'creative',
  'excited',
  'urgent',
  'persuasive'
];
