const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.color = "#084D6D";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 30%)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.width = "82px";
    const table = document.createElement("table");
    table.style.margin = "0px";
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};
const eyeCon = document.createElement("img");
eyeCon.src = "/images/icons/eyeLogo.svg";
eyeCon.style.height = "18px";
eyeCon.style.marginRight = "6px";
export const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);
    const tableHead = document.createElement("thead");
    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;
      tr.style.background = "transparent";
      tr.style.fontSize = "18px";
      const th = document.createElement("th");
      th.style.borderWidth = 0;
      th.style.background = "transparent";
      th.style.padding = "0px";
      th.style.display = "flex";
      th.style.alignItems = "center";
      th.style.textTransform = "unset";
      const text = document.createTextNode(title);
      th.appendChild(eyeCon);
      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });
    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const span = document.createElement("span");
      span.innerText = " Applicants";
      span.style.fontSize = "12px";
      const tr = document.createElement("tr");
      tr.style.backgroundColor = "transparent";
      tr.style.borderWidth = 0;
      const td = document.createElement("td");
      td.style.borderWidth = 0;
      td.style.fontSize = "12px";
      td.style.color = "#666666";
      td.style.padding = "0px";
      const text = document.createTextNode(body);
      td.appendChild(text);
      td.appendChild(span);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });
    const tableRoot = tooltipEl.querySelector("table");
    tableRoot.style.background = "transparent";
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.background = "#FEFEFE20";
  tooltipEl.style.borderRadius = "4px";
  tooltipEl.style.padding = "6px";
  tooltipEl.style.backdropFilter = "blur(4px)";
};
