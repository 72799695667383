"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { classes as sharedClasses } from "../styles";
export const classes = __spreadProps(__spreadValues({}, sharedClasses), {
  dialog: {
    "& .MuiPaper-root": {
      width: "725px"
    }
  },
  modalContainer: {
    padding: 5,
    width: "725px"
  },
  tabsContainer: {
    flexDirection: "row",
    borderBottom: "1px solid #EDEDED",
    marginBottom: "20px",
    columnGap: 1
  },
  tab: {
    margin: "0px 8px",
    cursor: "pointer",
    paddingBottom: 0.5
  },
  selectedTab: {
    borderBottom: "1px solid #5BC4C0",
    color: "#084D6D",
    fontWeight: "bold"
  },
  checkbox: {
    color: "#DDDDDD",
    "&.Mui-checked": {
      color: "#5BC4BF"
    },
    padding: "unset"
  },
  candidateList: {
    flexGrow: 1,
    border: "1px solid #DDDDDD",
    borderRadius: "6px",
    padding: 2,
    fontWeight: 600,
    rowGap: 0.5
  },
  acceptingInviteContainer: {
    boxShadow: "0px 12px 25px 0px #33333340",
    padding: "20px 40px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  acceptingInviteButtonGroup: {
    border: "2px solid #7CD0CC",
    borderRadius: "6px",
    button: {
      color: "#7CD0CC",
      textTransform: "capitalize",
      padding: "8px 24px",
      fontWeight: "bold",
      "&:focus": {
        outline: "none"
      }
    }
  },
  selectedCandidatesIndicatorContainer: {
    position: "absolute",
    width: "500px",
    left: "calc(50% - 250px)",
    bottom: "5%",
    background: "#F2F2F2",
    borderRadius: "6px",
    padding: "12px 48px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: 600,
    color: "#666666"
  },
  clearSelectionButton: {
    fontSize: "16px",
    color: "#084D6D",
    fontWeight: "700",
    textDecoration: "underline",
    borderRadius: "10px",
    padding: "4px 12px",
    "&:hover": {
      background: "rgba(171, 205, 239, 0.3)"
    }
  },
  noCandidatesContainer: {
    color: "#DDDDDD",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 0.5,
    rowGap: 4
  },
  longEventTitle: {
    paddingTop: "unset",
    paddingBottom: 3,
    width: "90%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  modalDeleteAndCloseContainer: {
    flexDirection: "row",
    position: "absolute",
    top: "20px",
    right: "20px",
    columnGap: 2
  },
  candidatesListContainer: {
    rowGap: 2,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  actionModalContent: {
    flexGrow: 1,
    rowGap: 3,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    marginTop: 2
  },
  actionModalTemplateTitle: {
    width: "290px",
    "& .MuiInputBase-input": { margin: "unset", marginTop: 1 }
  },
  link: {
    color: "unset !important",
    display: "flex",
    flexDirection: "row"
  }
});
