import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, Button } from '@mui/material';
import { IJobData } from '../types';
import { classes } from '../styles';
import { emojiRegex } from '../../Jobs/config';
import HtmlEditor from '../../Components/Utilities/HtmlEditor';
import AIStudioModal from '../../../AIStudio/AIStudioModal/AIStudioModal';
import { FelixButtonSVG } from '../../../AIStudio/SharedComponents/Graphics/FelixSVG';
import { IUserPermissions } from '../../Components/sharedTypes';

const handleInitialContent = (content: string) => {
  if (content.replace(/(<([^>]+)>)/gi, '') === '') {
    const contentArray = content.split('</div>');
    if (contentArray.length > 1) {
      contentArray[0] = contentArray[0] + '<p></p>';
      return contentArray.join('</div>');
    }
  }
  return content;
};

export default function Description({
  descriptionTabJobData,
  setDescriptionTabJobData,
  setIsEdited,
  userPermissions,
  aiStudioEnabled,
  aiPrefillData
}: {
  descriptionTabJobData: IJobData;
  setDescriptionTabJobData: IJobData;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  userPermissions: IUserPermissions;
  aiStudioEnabled: boolean;
  aiPrefillData?: {
    title: string;
    country: string;
  };
}) {
  const [summary, setSummary] = useState(
    handleInitialContent(descriptionTabJobData?.summary?.value)
  );
  const [description, setDescription] = useState(
    handleInitialContent(descriptionTabJobData?.description?.value)
  );
  const [buttonHovered, setButtonHovered] = useState(false);
  const [aiStudioOpen, setAiStudioOpen] = useState(false);

  const handleSetAiStudioContent = (content: string) => {
    setDescriptionTabJobData((prev) => ({
        ...prev,
        description: { ...prev.description, value: content }
      }));
    setDescription(content);
    setIsEdited(true);
  };

  return (
    <Box sx={classes.tabContent}>
      {userPermissions?.['Job Options']?.['Summary field'] && (
        <Box>
          <Box id="discription-summary-title" sx={classes.headerContent}>
            Summary
          </Box>
          <Box sx={classes.bodyContainer}>
            <Box
              sx={
                descriptionTabJobData?.summary?.value.length <= 65535
                  ? { display: 'none', ...classes.warnText }
                  : classes.warnText
              }
            >
              The field can't have more than 65535 characters.
            </Box>
            <Box
              sx={
                descriptionTabJobData?.summary?.value.match(emojiRegex)
                  ? classes.warnText
                  : { display: 'none', ...classes.warnText }
              }
            >
              This field can't have emojis.
            </Box>
            <HtmlEditor
              body={handleInitialContent(descriptionTabJobData?.summary?.value)}
              onChange={setSummary}
              onBlur={(value) => {
                setDescriptionTabJobData((prev) => ({
                  ...prev,
                  summary: { ...prev.summary, value: value }
                }));
                setIsEdited(true);
              }}
            />
          </Box>
        </Box>
      )}
      <Box sx={{...classes.headerContent, ...classes.headerRow }}>
        <Box>Description</Box>
        {aiStudioEnabled && (
          <Box sx={{ marginLeft: 'auto' }}>
            <Box
              id="ai-studio-questions-integration-button"
              sx={{
                ...classes.buttonContainer,
                ...(buttonHovered ? classes.hoverAnimation : classes.unhoverAnimation)
              }}
              onMouseEnter={() => setButtonHovered(true)}
              onMouseLeave={() => setButtonHovered(false)}
              onClick={() => {
                setAiStudioOpen(true);
              }}
            >
              <Box sx={classes.avatarContainer}>
                <FelixButtonSVG />
              </Box>
              <Button sx={classes.button}>Generate description</Button>
              <Box sx={classes.betaPill}>Beta</Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={classes.bodyContainer}>
        <Box
          sx={
            descriptionTabJobData?.description?.value.length <= 65535
              ? { display: 'none', ...classes.warnText }
              : classes.warnText
          }
        >
          The field can't have more than 65535 characters.
        </Box>
        <Box
          sx={
            descriptionTabJobData?.description?.value.match(emojiRegex)
              ? classes.warnText
              : { display: 'none', ...classes.warnText }
          }
        >
          This field can't have emojis.
        </Box>
        <HtmlEditor
          body={handleInitialContent(descriptionTabJobData?.description?.value)}
          onChange={setDescription}
          onBlur={(value) => {
            setDescriptionTabJobData((prev) => ({
              ...prev,
              description: { ...prev.description, value }
            }));
            setIsEdited(true);
          }}
        />
      </Box>
      {aiStudioOpen && (
        <AIStudioModal
          aiStudioEnabled={true}
          showPreview={false}
          setTlpModalOpen={setAiStudioOpen}
          jobFlow={true}
          prefillData={aiPrefillData}
          setGeneratedContent={handleSetAiStudioContent}
        />
      )}
    </Box>
  );
}
