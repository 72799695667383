import { Close } from '@mui/icons-material';
import { Box, Button, CircularProgress, TextField, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import API from '../API';
import { IEditableNotes } from '../types';
import { classes } from './styles';
import { IError } from '../../SmartForms/types';
import { emojiInString } from '../../utils/emoji-checker';
import { NOTES_ERROR_MESSAGE } from '../../constants';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';

const EditableNote: FC<IEditableNotes> = ({
  application,
  setApplications,
  jobId,
  setSnackbarState,
  noteRef
}) => {
  const [note, setNote] = useState(application.suitability_comment || '');
  const [isNotEditable, setIsNotEditable] = useState(true);
  const [noteError, setNoteError] = useState('');

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isNotEditable) return;
    const { value } = e.target;
    setNote(value);
  };

  const handleCancel = () => {
    setNoteError('');
    setNote(application.suitability_comment);
    setIsNotEditable(true);
  };

  const handleInputFocus = (): void => setIsNotEditable(false);

  const validateInput = () => {
    if (emojiInString(note)) {
      setNoteError(NOTES_ERROR_MESSAGE);
      return true;
    }
    return false;
  };

  const { mutate: saveNote, isLoading } = useMutation({
    mutationFn: async (): Promise<void> => {
      await API.updateNote(jobId, application.id, { suitability_comment: note }).then(
        (response) => {
          if (response.success) {
            setSnackbarState({
              message: response.message,
              state: 'success'
            });
          }
          setApplications((prevApplications) => {
            const newApplications = [...prevApplications];
            const applicationIndex = newApplications.findIndex(
              (currentApplication) => currentApplication.id === application.id
            );
            newApplications[applicationIndex].suitability_comment = note;
            return newApplications;
          });
          setIsNotEditable(true);
          setNoteError('');
        }
      );
    },
    onError: (error: IError) => {
      const errorMessage = error.errors ? error.errors.join('. ') : error;
      setSnackbarState({
        message: `There was an error updating the note ${errorMessage}`,
        state: 'error'
      });
    }
  });

  const handleEditClick = () => {
    setIsNotEditable(false);
  };

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key == 'Escape') {
      setIsNotEditable(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <Tooltip placement="top-end" title="Edit" arrow>
      <Box sx={classes.noteContainer}>
        <TextField
          id={`notes-text-area-${application.id}`}
          InputProps={{ sx: classes.text }}
          inputRef={noteRef}
          multiline={true}
          onChange={handleOnChange}
          onClick={handleEditClick}
          onFocus={handleInputFocus}
          rows={2}
          value={note}
        />
        {!!noteError && <Box sx={sharedClasses.errorText}>{noteError}</Box>}
        {!isNotEditable && (
          <Box sx={classes.noteEditButtonContainer}>
            <Button sx={classes.notesCancelButton} endIcon={<Close />} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              sx={classes.notesEditSaveButton}
              disabled={isLoading}
              onClick={() => {
                if (validateInput()) return;
                saveNote();
              }}
            >
              {isLoading ? <CircularProgress size={20} color="inherit" /> : 'Save'}
            </Button>
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

export default EditableNote;
