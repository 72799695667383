"use strict";
import { theme } from "../../ThemeContext/ThemeObject";
export const styles = {
  candidatesPageContainer: {
    backgroundColor: theme.palette.common.white,
    padding: "40px",
    rowGap: 4
  },
  candidatesPageHeader: {
    fontWeight: "bold",
    fontSize: "38px",
    color: "#666666"
  },
  editCandidateModalDetailsHeader: {
    fontWeight: "bold",
    color: "#666666",
    fontSize: "18px"
  },
  editCandidateModalContent: {
    rowGap: 4,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  editCandidateModalDetailsRows: {
    flexDirection: "row",
    columnGap: 4
  },
  editCandidateListboxStyle: {
    fontFamily: "Source Sans Pro",
    color: "#333333",
    maxHeight: "200px"
  },
  editCandidateAutocompleteTextfield: {
    input: { "&::placeholder": { opacity: 1, color: "#838383" } },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#E37D7A",
      margin: "0px 3px",
      fontFamily: "inherit"
    }
  },
  streetTypesAutocomplete: {
    width: "100%",
    paddingTop: 3,
    ".MuiInputBase-root": {
      margin: "3px 0px",
      "& .MuiAutocomplete-input": {
        padding: "6px 4px 6px 11px"
      }
    }
  }
};
