"use strict";
import RecruitApiClient from "../../RecruitApiClient";
class Api {
  constructor() {
    this.getCandidateData = (candidateId) => RecruitApiClient.get(`/api/v4/candidates/${candidateId}`, null);
    this.updateCandidateTags = (candidateId, tags) => RecruitApiClient.post(`/api/v4/candidates/${candidateId}/update_tags`, { tags });
    this.getCandidateApplications = (candidateId, params) => RecruitApiClient.get(`/api/v4/candidates/${candidateId}/applications`, params);
    this.getCandidateTasks = (candidateId) => RecruitApiClient.get(`/api/v4/candidates/${candidateId}/tasks`);
    this.getAssignableUsers = () => RecruitApiClient.get(`/api/v4/tasks/assignable_users`);
    this.createCandidateTask = (candidateId, params) => RecruitApiClient.post(`/api/v4/candidates/${candidateId}/tasks`, params);
    this.deleteCandidateTask = (candidateId, taskId) => RecruitApiClient.delete(`/api/v4/candidates/${candidateId}/tasks/${taskId}`);
    this.updateCandidateTask = (candidateId, taskId, params) => RecruitApiClient.put(`/api/v4/candidates/${candidateId}/tasks/${taskId}`, params);
    this.getWorkflows = (candidateId) => RecruitApiClient.get(`/api/v4/workflows?candidate_id=${candidateId}`);
    this.assignWorkflow = (candidateId, params) => RecruitApiClient.post(`/api/v4/workflows?candidate_id=${candidateId}`, params);
    this.getCandidateActivities = (candidateId) => RecruitApiClient.get(`/api/v4/candidates/${candidateId}/activities`);
    this.addCandidateNote = (candidateId, params) => RecruitApiClient.post(`/api/v4/candidates/${candidateId}/add_note`, { note: params });
    this.mergeCandidates = (candidateId) => RecruitApiClient.post(`/api/v4/candidates/${candidateId}/merge`);
    this.getUserPermissions = () => RecruitApiClient.get(`/api/v4/user_permissions`);
    this.updateCandidateNotepad = (candidateId, body) => RecruitApiClient.put(`/api/v4/candidates/${candidateId}/update_notepad`, { body });
  }
}
export default new Api();
