import React, { Dispatch, SetStateAction } from 'react';
import AutocompleteInput from '../../NewUI/Components/CustomUIElements/AutocompleteInput';
import { Box } from '@mui/material';
import { DataType } from './types';
import { CustomReportsStateType } from './types';
import ReportServiceApi from '../ReportServiceApi';
import { endOfDay, startOfDay } from '../../NewUI/Components/Charts/helper';

export const selectFieldsInputs = (
  reportState: CustomReportsStateType,
  reportData: DataType,
  setReportData: Dispatch<SetStateAction<DataType>>,
  field: number,
  loadingCreateOptions: boolean
) => {
  switch (field) {
    case 0:
      return (
        <AutocompleteInput
          id="job-form"
          options={reportState.createReportOptions.jobs}
          multiple={true}
          optionLabel={(option: { name: string; key: string }) => option.name}
          label={'Add fields'}
          isLoading={loadingCreateOptions}
          loadingText={['Loading job options', 'No job options']}
          value={reportData.jobFields}
          required={false}
          onChange={(data: DataType['jobFields']) =>
            setReportData({ ...reportData, jobFields: data })
          }
        />
      );
    case 1:
      return (
        <AutocompleteInput
          id="candidate"
          options={reportState.createReportOptions.candidates}
          multiple={true}
          optionLabel={(option: { name: string; id: string }) => option.name}
          label={'Add fields'}
          isLoading={loadingCreateOptions}
          loadingText={['Loading candidate options', 'No candidate options']}
          value={reportData.candidateFields}
          required={false}
          onChange={(data: DataType['candidateFields']) =>
            setReportData({ ...reportData, candidateFields: data })
          }
        />
      );
    case 2:
      return (
        <AutocompleteInput
          id="application-form"
          options={reportState.createReportOptions.applications}
          multiple={true}
          optionLabel={(option: { name: string; id: string }) => option.name}
          label={'Add fields'}
          isLoading={loadingCreateOptions}
          loadingText={['Loading application options', 'No application options']}
          value={reportData.applicationFields}
          required={false}
          onChange={(data: DataType['applicationFields']) =>
            setReportData({ ...reportData, applicationFields: data })
          }
        />
      );
    case 3:
      return (
        <AutocompleteInput
          id="approvals-form"
          options={reportState.createReportOptions.approvals}
          multiple={true}
          optionLabel={(option: { name: string; id: string }) => option.name}
          label={'Add fields'}
          isLoading={loadingCreateOptions}
          loadingText={['Loading approvals field options', 'No approvals field options']}
          value={reportData.approvalFields}
          required={false}
          onChange={(data: DataType['approvalFields']) =>
            setReportData({ ...reportData, approvalFields: data })
          }
        />
      );
    case 4:
      return (
        <AutocompleteInput
          id="interview-form"
          options={reportState.createReportOptions.interviews}
          multiple={true}
          optionLabel={(option: { name: string; id: string }) => option.name}
          label={'Add fields'}
          isLoading={loadingCreateOptions}
          loadingText={['Loading event interview options', 'No event interview options']}
          value={reportData.eventInterviewFields}
          required={false}
          onChange={(data: DataType['eventInterviewFields']) =>
            setReportData({ ...reportData, eventInterviewFields: data })
          }
        />
      );
    case 5:
      return (
        <AutocompleteInput
          id="smart-form"
          options={reportState.createReportOptions.smart_forms}
          multiple={true}
          optionLabel={(option: { name: string; id: string }) => option.name}
          label={'Add fields'}
          isLoading={loadingCreateOptions}
          loadingText={['Loading smart form options', 'No smart form options']}
          value={reportData.smartFormFields}
          required={false}
          onChange={(data: DataType['smartFormFields']) =>
            setReportData({ ...reportData, smartFormFields: data })
          }
        />
      );
    default:
      <Box />;
  }
};

export const parseColumnName = (columnName: string) => {
  const split = columnName.split(/_/);
  split.forEach((word, index) => {
    split[index] = word.charAt(0).toUpperCase() + word.substring(1);
  });
  return split.length > 1 ? split.join(' ') : split[0];
};

export const getColumnsData = (column) => {
  return [
    ...column.jobFields,
    ...column.candidateFields,
    ...column.applicationFields,
    ...column.approvalFields,
    ...column.eventInterviewFields,
    ...column.smartFormFields
  ].map((i) => i.key);
};

export const getEntityIds = (reportState: CustomReportsStateType) => {
  const ids = reportState.data?.entityIds;
  if (!ids) return null;
  return ids[0].name === 'All entities' ? ids[0].id : ids.map((i) => i.id);
};

export const downloadParams = (
  reportState: CustomReportsStateType,
  reportApiService: ReportServiceApi
) => {
  const userTimezone = reportApiService.currentUser.iana_timezone;
  return {
    entity_id: getEntityIds(reportState),
    columns: getColumnsData(reportState.data),
    start_date: startOfDay(reportState.startDate as Date, userTimezone),
    end_date: endOfDay(reportState.endDate as Date, userTimezone)
  };
};

export const getCreateReportParams = (reportState: CustomReportsStateType) => {
  return {
    entity_id: reportState.createReportOptions.entitiesOptions?.current_entity?.id,
    entity_name: reportState.createReportOptions.entitiesOptions?.current_entity?.name,
    query_config: {
      columns: getColumnsData(reportState.data),
      entity_ids: getEntityIds(reportState),
      report_type: 'custom',
      user_id: reportState.createReportOptions.currentUser?.id,
      dates: {
        startDate: reportState.startDate,
        endDate: reportState.endDate,
        dateRange: reportState.dateRange
      },
      data: { ...reportState.data }
    },
    start_date: reportState.startDate,
    end_date: reportState.endDate,
    title: reportState.data.name,
    report_type: 'custom',
    user_name: `${reportState.createReportOptions.currentUser?.firstname} ${reportState.createReportOptions.currentUser?.lastname}`,
    user_id: reportState.createReportOptions.currentUser?.id
  };
};

export const getFieldsTotal = (reportState: CustomReportsStateType) => {
  const {
    jobFields,
    candidateFields,
    applicationFields,
    eventInterviewFields,
    approvalFields,
    smartFormFields
  } = reportState.data;

  return (
    jobFields?.length +
    candidateFields?.length +
    applicationFields?.length +
    eventInterviewFields?.length +
    approvalFields?.length +
    smartFormFields.length
  );
};
