import React, { Dispatch, useState } from 'react';
import { Box } from '@mui/material';
import { EngageAction, EngageState } from '../../../reducer';
import { classes } from '../../../styles';
import { Virtualize } from '../../../../NewUI/Components/CustomUIElements/VirtualizedAutocomplete';
import { handleAPIRequest } from '../../../../NewUI/Components/Utilities/handleAPIRequest';
import { ISnackbarInput } from '../../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import DynamicFooter from '../../../../AIStudio/SharedComponents/Footers/DynamicFooter';
import { IJob } from '../../../../NewUI/Job/types';
import DisplayHTMLViewOnly from '../../../../AIStudio/SharedComponents/DisplayHTMLViewOnly';
import SelectJobSVG from '../../../Graphics/SelectJobSVG';

const SelectTLPStep = ({
  modalState,
  dispatch,
  handleGetBooleanQuery
}: {
  modalState: EngageState['tlpMatchModalState'];
  dispatch: Dispatch<EngageAction>;
  handleGetBooleanQuery: () => void;
}) => {
  const [selectedJob, setSelectedJob] = useState<string>('');
  const [isLoadingTLP, setIsLoadingTLP] = useState<boolean>(false);

  const handleSelectJob = (value: string) => {
    if (!value) {
      setSelectedJob('');
      dispatch({ type: 'SET_SELECTED_TLP', payload: { id: -1, name: '', html: '' } });
      return;
    }
    const reference = value.split(' -')[0];
    const i = modalState.jobsList.data.findIndex((option) => option?.reference === reference);
    setSelectedJob(value);
    const jobId = modalState.jobsList.data[i]?.id;
    getSelectedJobTLP(Number(jobId));
  };

  const getSelectedJobTLP = (id: number) => {
    handleAPIRequest({
      url: `/api/v4/jobs/${id}`,
      method: 'GET',
      setSnackbarState: (snackbarState: ISnackbarInput) =>
        dispatch({ type: 'SET_SNACKBAR', payload: snackbarState }),
      successMessage: { show: false },
      setLoaderState: setIsLoadingTLP,
      onSuccess: (response: IJob) => {
        const [id, name, html] = [response?.id, response?.title, response?.description];
        dispatch({
          type: 'SET_SELECTED_TLP',
          payload: { id, name, html }
        });
      }
    });
  };

  const options = modalState.jobsList.data.map((job) => `${job.reference} - ${job.title}`);

  return (
    <>
      <Box sx={classes.modalHeader}>Search using a Talent Landing Page</Box>
      <Box sx={classes.modalMainContentContainer}>
        <Box sx={classes.modalRow}>
          <Box sx={classes.modalFieldSubHeader}>Select a job</Box>
          <Virtualize
            passOptions={options}
            value={selectedJob}
            setValue={(value: string) => handleSelectJob(value)}
            passedStyles={classes.autocomplete}
            loading={modalState.jobsList.isLoading}
            loadingText="Loading jobs with Talent Landing Pages..."
            freeSolo={false}
          />
        </Box>
        {!selectedJob ? (
          <Box sx={classes.selectJobPlaceholder}>
            <SelectJobSVG />
            <span>Select a job to see its Talent Landing Page</span>
          </Box>
        ) : (
          <DisplayHTMLViewOnly
            content={modalState.selectedTLP.html}
            loadingHTML={isLoadingTLP}
            maxHeight="420px"
          />
        )}
      </Box>
      <DynamicFooter
        primaryButtonText="Confirm"
        primaryButtonID="confirm-button"
        secondaryButtonID="close-button"
        disablePrimaryButton={modalState.selectedTLP.html === ''}
        handlePrimaryButtonClick={handleGetBooleanQuery}
        secondaryButtonText="Close"
        handleSecondaryButtonClick={() => dispatch({ type: 'CLOSE_TLP_MATCH_MODAL' })}
        felixMessage={
          <>
            Select a job and I'll create a search to find candidates based on its{' '}
            <span>Talent Landing Page</span>
          </>
        }
        felixMessageMaxWidth="360px"
      />
    </>
  );
};

export default SelectTLPStep;
