import React from 'react';
import { classes } from './styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { IGenericDialogProps } from './types';

function GenericDialog({
  url,
  title,
  description,
  buttonText,
  buttonCallback,
  callbackLoading,
  isDialogOpen,
  setDialogOpen,
  dialogAdID
}: IGenericDialogProps) {
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      sx={{ '& .MuiPaper-root': classes.dialog }}
    >
      <h4>{title}</h4>
      {description && <span>{description}</span>}
      <Box sx={classes.dialogButtonContainer}>
        <Button
          disableElevation
          sx={classes.buttonSecondaryLight}
          onClick={() => setDialogOpen(false)}
          style={{ padding: '10px 30px' }}
        >
          Cancel
        </Button>
        <Button
          disableElevation
          sx={buttonText === 'Clone ad' ? classes.buttonSecondary : classes.buttonRed}
          onClick={() => (dialogAdID ? buttonCallback(url, dialogAdID) : buttonCallback(url))}
          style={{ padding: '10px 30px' }}
        >
          {callbackLoading ? <CircularProgress size={20} color="inherit" /> : buttonText}
        </Button>
      </Box>
    </Dialog>
  );
}

export default GenericDialog;
