import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Modal,
  Button,
  Autocomplete,
  TextField,
  Skeleton,
  CircularProgress
} from '@mui/material';
import MultiTagInput from '../../../Components/CustomUIElements/MultiTagInput';
import CancelButton from '../shared/CancelButton';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { IAutoModalProps } from '../../types';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { IApplicationStatus } from '../../../Job/types';
import { stringToSeconds } from '../../../Components/Utilities/stringToSeconds';
import { secondsToString } from '../../../Components/Utilities/secondsToString';
import dompurify from 'dompurify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DelaySelectWrapper from '../shared/DelaySelectWrapper';

export default function NewAutoForward({
  jobId,
  apiKey,
  modalOpen,
  setModalOpen,
  autoForwarders,
  setAutoForwarders,
  applicationStatuses,
  emailTemplates,
  isLoadingTemplateList,
  forwardIndex,
  setSnackbar
}: IAutoModalProps) {
  const [tempEmails, setTempEmails] = useState<string[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [delay, setDelay] = useState('None');
  const [selectedStatus, setSelectedStatus] = useState<IApplicationStatus | null>(null);
  const [body, setBody] = useState('');
  const [errors, setErrors] = useState({
    status: '',
    template: '',
    emails: ''
  });

  const refObject = {
    status: useRef<HTMLDivElement>(null),
    template: useRef<HTMLDivElement>(null),
    emails: useRef<HTMLDivElement>(null)
  };

  const handleSelectTemplate = useCallback(
    async (value: string) => {
      if (!value) {
        setSelectedTemplate(null);
        setBody('');
        return;
      }
      setIsLoadingTemplate(true);
      setBody(
        '<p style="text-align: center;"><span style="font-size: 14pt;"><strong>Loading template...</strong></span></p>'
      );
      const id = value.split(' -')[0];
      setSelectedTemplate(value);
      try {
        const response = await fetch(`/api/v4/jobs/${jobId}/email_templates/${id}`, {
          method: 'GET',
          headers: {
            'X-api-authenticate': apiKey
          }
        });
        const data = await response.json();
        setBody(data.body);
      } catch (error) {
        setBody(
          '<p style="text-align: center;"><span style="font-size: 14pt;"><strong>Error loading template</strong></span></p>'
        );
      } finally {
        setIsLoadingTemplate(false);
      }
    },
    [apiKey, jobId]
  );

  const emailIsValid = (email: string) => {
    const simpleEmailRex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (simpleEmailRex.test(String(email).toLowerCase())) {
      setErrors((prev) => ({ ...prev, emails: '' }));
      return true;
    }

    return false;
  };

  const handleSubmit = async () => {
    let errorRef = '';
    if (!selectedStatus) {
      setErrors((prev) => ({ ...prev, status: 'Please select a status' }));
      errorRef = errorRef || 'status';
    }
    if (!tempEmails.length) {
      setErrors((prev) => ({ ...prev, emails: 'Please enter at least one email address' }));
      errorRef = errorRef || 'emails';
    }
    if (errorRef) {
      refObject[errorRef as keyof typeof refObject].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      return;
    }
    try {
      setIsSending(true);
      if (forwardIndex === -1) {
        const response = await fetch(`/api/v4/jobs/${jobId}/auto_forwarders`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-api-authenticate': apiKey
          },
          body: JSON.stringify({
            auto_forwarder: {
              trigger_status_id: selectedStatus?.id,
              email_template_id: selectedTemplate?.split(' -')[0],
              recipient_emails: tempEmails.join(', '),
              delay: stringToSeconds(delay)
            }
          })
        });
        const data = await response.json();
        if (response.ok) {
          setAutoForwarders((prev) => [...prev, data]);
          setSnackbar({
            message: 'Auto forwarder created successfully',
            state: 'success'
          });
          setModalOpen(false);
        } else {
          throw new Error('Error creating auto forwarder');
        }
      } else {
        const response = await fetch(
          `/api/v4/jobs/${jobId}/auto_forwarders/${autoForwarders[forwardIndex].id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'X-api-authenticate': apiKey
            },
            body: JSON.stringify({
              auto_forwarder: {
                trigger_status_id: selectedStatus?.id,
                email_template_id: selectedTemplate?.split(' -')[0],
                recipient_emails: tempEmails.join(', '),
                delay: stringToSeconds(delay)
              }
            })
          }
        );
        const data = await response.json();
        if (response.ok) {
          setAutoForwarders((prev) => {
            const newAutoForwarders = [...prev];
            newAutoForwarders[forwardIndex] = data;
            return newAutoForwarders;
          });
          setSnackbar({
            message: 'Auto forwarder updated successfully',
            state: 'success'
          });
          setModalOpen(false);
        } else {
          throw new Error('Error updating auto forwarder');
        }
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error saving auto-forwarder',
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    setErrors({
      status: '',
      template: '',
      emails: ''
    });
    if (forwardIndex === -1) {
      setTempEmails([]);
      setSelectedTemplate(null);
      setDelay('None');
      setSelectedStatus(null);
      setBody('');
      return;
    }
    setTempEmails(autoForwarders[forwardIndex].recipient_emails.split(','));
    setSelectedStatus(
      applicationStatuses.find(
        (status) => status.id === autoForwarders[forwardIndex].trigger_status_id
      ) || null
    );
    setDelay(secondsToString(autoForwarders[forwardIndex].delay));
  }, [applicationStatuses, autoForwarders, forwardIndex]);

  useEffect(() => {
    if (forwardIndex !== -1) {
      const emailTemplate =
        emailTemplates.find(
          (template) =>
            template.split(' - ')[0] === autoForwarders[forwardIndex].email_template_id.toString()
        ) || null;
      setSelectedTemplate(emailTemplate);
      if (emailTemplate) {
        handleSelectTemplate(emailTemplate);
      }
    }
  }, [autoForwarders, emailTemplates, forwardIndex, handleSelectTemplate]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="new-auto-forward-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={() => setModalOpen(false)} sx={classes.closeIcon} />
        <Box sx={classes.actionModalHeader}>{`${
          forwardIndex === -1 ? 'New' : 'Edit'
        } auto forward config`}</Box>
        <Box
          sx={classes.modalContent}
          aria-label={`${forwardIndex === -1 ? 'New' : 'Edit'} auto forward modal content`}
        >
          <Box
            role="form"
            id="auto-forward-form"
            aria-label="Auto forward form"
            sx={{ height: '100%', overflowX: 'clip' }}
          >
            <Box
              sx={{ ...classes.actionModalFormLine, marginTop: '20px', marginBottom: '40px' }}
              ref={refObject.status}
            >
              <Autocomplete
                data-testid="trigger-status-autocomplete-auto-forward"
                options={applicationStatuses}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  setSelectedStatus(value);
                  setErrors((prev) => ({ ...prev, status: '' }));
                }}
                value={selectedStatus}
                sx={{ ...sharedClasses.formAutocomplete, width: '287px' }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trigger status"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                renderOption={(props, option, state) => {
                  return (
                    <li {...props} key={option.id} data-testid={`option-${state.index}`}>
                      {option.name}
                    </li>
                  );
                }}
              />
              {errors.status && <Box sx={classes.formError}>{errors.status}</Box>}
            </Box>
            <Box>
              <Box sx={classes.actionModalFormLine} ref={refObject.template}>
                <Virtualize
                  value={selectedTemplate}
                  passOptions={emailTemplates}
                  setValue={(value: string) => {
                    handleSelectTemplate(value);
                    setErrors((prev) => ({ ...prev, template: '' }));
                  }}
                  passedStyles={{
                    ...sharedClasses.formAutocomplete,
                    width: '600px',
                    marginTop: '20px'
                  }}
                  label="Template"
                  loading={isLoadingTemplateList}
                  loadingText="Loading template list..."
                />
                {errors.template && <Box sx={classes.formError}>{errors.template}</Box>}
              </Box>
              {selectedTemplate &&
                forwardIndex !== -1 &&
                (isLoadingTemplate || (forwardIndex !== -1 && !selectedTemplate) ? (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={400}
                    width={'96%'}
                    sx={classes.previewSkeleton}
                  />
                ) : (
                  <Box sx={{ ...classes.htmlContent }}>
                    <Box sx={{ ...classes.previewHeader }}>
                      <VisibilityIcon />
                      Preview
                    </Box>
                    <Box
                      sx={classes.emailPreview}
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(body)
                      }}
                    />
                  </Box>
                ))}
            </Box>
            <Box sx={classes.actionModalFormLine} ref={refObject.emails}>
              <MultiTagInput
                tags={tempEmails}
                setTags={(emails) => {
                  setTempEmails(emails);
                  setErrors((prev) => ({ ...prev, emails: '' }));
                }}
                validation={emailIsValid}
                validationMessage="Must input valid email"
                label="Recipient email address/s"
                width="600px"
                error={errors.emails}
              />
            </Box>
            <DelaySelectWrapper delay={delay} setDelay={setDelay} />
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <CancelButton scope='auto-forward' setModalOpen={setModalOpen} />
          <Button
            id="send-auto-forward-button"
            data-testid="send-auto-forward-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '71px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSending ? <CircularProgress size={20} color="inherit" /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
