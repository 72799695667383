import React, {
  useReducer,
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { engageReducer, initialEngageState } from './reducer';
import { Box } from '@mui/material';
import { classes } from './styles';
import { getTabNumber, getTabParam } from './config';
import StyledSnackbar, {
  ISnackbarInput
} from '../NewUI/Components/CustomUIElements/StyledSnackbar';
import EngageAISearch from './AISearch/EngageAISearch';
import CandidatePools from './CandidatePools/CandidatePools';
import TabPanel from './Tabs/TabPanel';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  genericTableReducer,
  initialTableState
} from '../NewUI/Components/GenericTable/genericTableReducer';
import {
  candidatePoolActionsState,
  candidatePoolModalState,
  candidatePoolsColumns
} from './CandidatePools/config';
import { individualCandidatePoolsColumns } from './CandidatePools/Modals/CandidatePool/candidatesConfig';
import { emailsColumns } from './CandidatePools/Modals/CandidatePool/emailConfig';
import Analytics from './CandidatePoolAnalytics/CandidatePoolAnalytics';
import TabSelector from './Tabs/TabSelector';

const URL_PARAMS = new URL(window.location.href);
const queryClient = new QueryClient();

const Engage = () => {
  const [engageState, engageDispatch] = useReducer(engageReducer, initialEngageState);
  const [candidatePoolsState, candidatePoolsDispatch] = useReducer(
    genericTableReducer,
    initialTableState(
      candidatePoolsColumns,
      { sortBy: 'created_at', sortOrder: 'desc' },
      candidatePoolActionsState,
      candidatePoolModalState
    )
  );
  const tabIndicatorRef = useRef([]);
  const [individualCandidatePoolsState, individualCandidatePoolsDispatch] = useReducer(
    genericTableReducer,
    initialTableState(individualCandidatePoolsColumns, { sortBy: 'created_at', sortOrder: 'desc' })
  );
  const handleTabChange = useCallback((event: React.SyntheticEvent, tabValue: number) => {
    engageDispatch({ type: 'SET_SELECTED_TAB', payload: tabValue });
    URL_PARAMS.search = getTabParam(tabValue);
    window.history.pushState(null, null, URL_PARAMS.href);
  }, []);
  const [modalTabValue, setModalTabValue] = useState<number>(0);
  const [emailsState, emailsDispatch] = useReducer(
    genericTableReducer,
    initialTableState(emailsColumns, { sortBy: 'created_at', sortOrder: 'desc' })
  );

  useEffect(() => {
    if (URL_PARAMS.search) {
      engageDispatch({ type: 'SET_SELECTED_TAB', payload: getTabNumber(URL_PARAMS.search) });
    } else {
      URL_PARAMS.search = getTabParam(0);
      window.history.pushState(null, null, URL_PARAMS.href);
    }
  }, []);

  useEffect(() => {
    if (candidatePoolsState.tableState.actions.isOpen.emailAnalytics) {
      setModalTabValue(1);
    }
  }, [candidatePoolsState]);

  useLayoutEffect(() => {
    !individualCandidatePoolsState.selectedRowTitle &&
      engageDispatch({
        type: 'SET_INDICATOR_WIDTH',
        payload: tabIndicatorRef?.current[engageState.selectedTab]?.clientWidth
      });
  }, [engageState.selectedTab]);

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Box sx={classes.pageContainer}>
          {emailsState.selectedRowTitle ? (
            <Box>
              <Analytics
                selectedEmail={emailsState.selectedRowTitle}
                emailsDispatch={emailsDispatch}
                selectedCandidatePoolId={candidatePoolsState?.tableState?.actions?.selectedItem?.id}
              />
            </Box>
          ) : (
            <Box>
              <Box sx={classes.pageHeader}>
                <h1>:Engage</h1>
              </Box>
              <TabSelector
                engageState={engageState}
                handleTabChange={handleTabChange}
                tabIndicatorRef={tabIndicatorRef}
              />
              <TabPanel tabValue={engageState.selectedTab} index={0}>
                <EngageAISearch engageState={engageState} dispatch={engageDispatch} />
              </TabPanel>
              <TabPanel tabValue={engageState.selectedTab} index={1}>
                <CandidatePools
                  candidatePoolsState={candidatePoolsState}
                  dispatch={candidatePoolsDispatch}
                  setSnackbarState={(snackbarInput: ISnackbarInput) => {
                    engageDispatch({ type: 'SET_SNACKBAR', payload: snackbarInput });
                    candidatePoolsDispatch({ type: 'SET_SNACKBAR', payload: snackbarInput });
                  }}
                  individualCandidatePoolsState={individualCandidatePoolsState}
                  individualCandidatePoolsDispatch={individualCandidatePoolsDispatch}
                  emailsState={emailsState}
                  emailsDispatch={emailsDispatch}
                  modalTabValue={modalTabValue}
                  setModalTabValue={setModalTabValue}
                />
              </TabPanel>
            </Box>
          )}
          <ReactQueryDevtools initialIsOpen={false} />
        </Box>
        <StyledSnackbar
          message={engageState.snackbarState.message || candidatePoolsState.snackbarState.message}
          state={engageState.snackbarState.state || candidatePoolsState.snackbarState.state}
          setSnackbarState={(snackbarInput: ISnackbarInput) => {
            engageDispatch({ type: 'SET_SNACKBAR', payload: snackbarInput });
            candidatePoolsDispatch({ type: 'SET_SNACKBAR', payload: snackbarInput });
          }}
        />
      </>
    </QueryClientProvider>
  );
};

export default Engage;
