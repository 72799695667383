import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import NoQuestionSVG from './NoQuestionSVG';
import NoQuestionArrowSVG from '../Questions/NoQuestionArrowSVG';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import InfoIcon from '@mui/icons-material/Info';
import NewSystemFieldSVG from './NewSystemFieldSVG';
import NewField from './Modals/NewField';
import AddSystemField from './Modals/AddSystemField';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import { IApplicantQuestion } from '../types';
import { classes } from './styles';

import SortableItem from './SortableItem';

export default function HRQuestions({ apiKey, jobId, applicationQuestionTemplateId }: { apiKey: string; jobId: number; applicationQuestionTemplateId: number }) {
  const [questions, setQuestions] = useState<IApplicantQuestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalsOpen, setModalsOpen] = useState<Record<string, boolean>>(
    {newField: false, addSystemField: false}
  );
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });

  const [editingQuestion, setEditingQuestion] = useState<IApplicantQuestion | null>();

  const getQuestions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/v4/fields?application_question_template_id=${applicationQuestionTemplateId}`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setQuestions(response.fields);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, applicationQuestionTemplateId]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  return (
    <Box sx={classes.questionsPageContainer}>
      <Box sx={classes.pageHeader}>
        <h2>Configure application HR Questions</h2>
      </Box>
      <Box sx={classes.noticeContainer}>
       <Box sx={classes.noticeHeader}>
          <InfoIcon sx={classes.noticeIcon} />
          Only one question can be enabled at a time.
       </Box>
        <Box sx={classes.noticeBody}>
          Any changes here will be automatically saved and will be applied immediately.
        </Box>
      </Box>
      <Box sx={classes.actionsContianer}>
        <Button
            variant="outlined"
            startIcon={<LiveHelpIcon/>}
            sx={classes.actionButton}
            onClick={() => {
                setEditingQuestion(null);
                setModalsOpen((prev) => ({...prev, newField: true}));
            }}
        >
            New field
        </Button>
        <Button
            variant="outlined"
            startIcon={<NewSystemFieldSVG/>}
            sx={classes.actionButton}
            onClick={() => {
                setEditingQuestion(null);
                setModalsOpen((prev) => ({...prev, addSystemField: true}));
            }}
        >
            Add system field
        </Button>
      </Box>
      {questions && questions.length > 0 &&
        <Box sx={classes.questionsContainer}>
          <Box sx={classes.titleContainer}>
            <Box sx={{textAlign: 'start !important', marginRight: 'auto', ...classes.questionsTitle}}>Title</Box>
            <Box sx={classes.questionsTitle}>Enabled</Box>
          </Box>
              {questions.map((question, index) => (
                <SortableItem
                  questions={questions}
                  setQuestions={setQuestions}
                  getQuestions={getQuestions}
                  index={index}
                  key={question.id}
                  jobId={jobId}
                  applicationQuestionTemplateId={applicationQuestionTemplateId}
                  apiKey={apiKey}
                  setModalsOpen={setModalsOpen}
                  editingQuestion={question}
                  setEditingQuestion={setEditingQuestion}
                  setSnackbar={setSnackbar}
                />
              ))}
        </Box>
      }
      {!isLoading && questions?.length === 0 && (
        <Box sx={{ ...classes.noQuestionsContainer, margin: '100px 0px' }}>
          <Box sx={{display: 'flex', flexDirection: 'row',}}>
            <Box sx={{width: '100px'}}>
            </Box>
            <NoQuestionSVG />
            <NoQuestionArrowSVG />
          </Box>
          <Box sx={classes.noQuestionsText}>
            You have no questions
            <br />
            Choose an options above to start creating questions
          </Box>
        </Box>
      )}
      <NewField
        apiKey={apiKey}
        getQuestions={getQuestions}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        jobId={jobId}
        applicationQuestionTemplateId={applicationQuestionTemplateId}
        setSnackbar={setSnackbar}
        editingQuestion={editingQuestion}
        canEnable={!questions.map((question) => (question.enabled)).includes(true)}
      />
      <AddSystemField
        apiKey={apiKey}
        getQuestions={getQuestions}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        jobId={jobId}
        applicationQuestionTemplateId={applicationQuestionTemplateId}
        setSnackbar={setSnackbar}
      />
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
