"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import queryString from "query-string";
const BASE_URL = window.location.origin;
class Api {
  constructor() {
    this.headers = {
      "Content-Type": "application/json"
    };
    this.handleResponse = (response) => {
      if (response.ok) return response.json();
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    };
    this.handlePaginatedResponse = (response) => __async(this, null, function* () {
      if (response.ok) {
        return {
          data: yield response.json(),
          totalItems: Number(response.headers.get("X-Total-Count")),
          totalPages: Number(response.headers.get("X-Total-Pages")),
          nextPage: Number(response.headers.get("X-Next-Page"))
        };
      }
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    });
    this.getPaginated = (path, params = {}) => fetch(
      `${BASE_URL}${path}?${queryString.stringify(params, {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: "bracket",
        encode: false
      })}`,
      {
        headers: this.headers
      }
    ).then((r) => this.handlePaginatedResponse(r));
    this.get = (path, params = {}) => fetch(
      `${BASE_URL}${path}?${queryString.stringify(params, {
        arrayFormat: "bracket",
        encode: false
      })}`,
      {
        headers: this.headers
      }
    ).then((r) => this.handleResponse(r));
    this.post = (path, params = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.headers,
      method: "POST",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.put = (path, params = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.headers,
      method: "PUT",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.delete = (path, params = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.headers,
      method: "DELETE",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.getKpiItems = () => this.get(`/api/v4/dashboard`, {});
    this.getTasks = (params) => this.getPaginated(`/api/v4/tasks`, __spreadProps(__spreadValues({}, params), { limit: 10 }));
    this.getEvents = (params) => this.getPaginated("/api/v4/dashboard/events", __spreadProps(__spreadValues({}, params), { limit: 10 }));
    this.completeTask = (id, completed) => this.put(`/api/v4/tasks/${id}`, { task: { completed: completed ? 1 : 0 } });
    this.getActivityFeed = (params) => __async(this, null, function* () {
      if (params.page === null) {
        return this.get(`/api/v4/dashboard/activities_feed`, __spreadValues({}, params));
      } else {
        yield new Promise((resolve) => setTimeout(resolve, 2500));
        return this.get(`/api/v4/dashboard/activities_feed`, __spreadValues({}, params));
      }
    });
    this.getRecentlyViewed = () => this.get(`/api/v4/dashboard/recent_page_views`, {});
    this.getUserPermissions = () => this.get(`/api/v4/user_permissions`, {});
    this.getUserEntities = () => this.get(`/api/v4/user_entities`, {});
  }
}
export default new Api();
