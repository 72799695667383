import React, { Dispatch } from 'react';
import {
  GenericTableState,
  GenericTableAction
} from '../../../../NewUI/Components/GenericTable/genericTableReducer';
import { TEmail } from '../../../types';
import { TableCell, Box, Tooltip } from '@mui/material';
import { classes } from '../../../../NewUI/Components/GenericTable/styles';
import FormattedDate from '../../../../NewUI/Components/GenericTable/TableCells/FormattedDate';
import { theme } from '../../../../ThemeContext/ThemeObject';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getStatusColor } from '../../../helper';

const CandidatePoolsTableRows = ({
  column,
  item,
  tableHovered,
  dispatch
}: {
  column: GenericTableState['tableState']['columns'][number];
  item: TEmail['item'];
  tableHovered: boolean;
  dispatch?: Dispatch<GenericTableAction>;
}) => {
  if (!column.enabled) return null;
  switch (column.id) {
    case 'email_template': {
      return (
        <TableCell
          key={`${(item.id, column.id)}`}
          sx={{
            ...classes.sticky,
            ...classes.itemTitleTableCell,
            left: '0px',
            ...(tableHovered && classes.scrollShadowVisible),
            cursor: 'pointer',
            width: '150px'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={item.status} placement="top" arrow>
              <Box
                id="email-status-icon"
                sx={{
                  borderRadius: '6px',
                  padding: '6px',
                  backgroundColor: `${getStatusColor(item.status)[0]}33`
                }}
              >
                <EmailIcon sx={{ color: getStatusColor(item.status)[0] }} fontSize="small" />
              </Box>
            </Tooltip>
            <Tooltip title={'Click here to see email Analytics'} placement="top" arrow>
              <Box
                id="email-template-name-link"
                onClick={() => {
                  dispatch &&
                    dispatch({
                      type: 'SET_SELECTED_ROW_DATA',
                      payload: { name: item.email_template, item: item }
                    });
                }}
                sx={{
                  paddingLeft: '20px',
                  fontWeight: 'bold',
                  color: '#666666',
                  '&& span:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                <span>{item.email_template || '-'}</span>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
      );
    }
    case 'user': {
      return (
        <TableCell id="user-name-link" key={`${(item.id, column.id)}`}>
          {item?.user?.name ? (
            <a href={item.user.link} target="_blank" rel="noreferrer">
              {item.user.name}
            </a>
          ) : (
            '-'
          )}
        </TableCell>
      );
    }
    case 'only_new': {
      return (
        <TableCell key={`${(item.id, column.id)}`}>
          <Box sx={classes.centerCellContent}>
            {item.only_new ? (
              <CheckCircleIcon sx={{ color: theme.palette.success.main }} fontSize="small" />
            ) : (
              <CancelIcon sx={{ color: theme.palette.error.main }} fontSize="small" />
            )}
          </Box>
        </TableCell>
      );
    }
    case 'include_deleted': {
      return (
        <TableCell key={`${(item.id, column.id)}`}>
          <Box sx={classes.centerCellContent}>
            {item.include_deleted ? (
              <CheckCircleIcon sx={{ color: theme.palette.success.main }} fontSize="small" />
            ) : (
              <CancelIcon sx={{ color: theme.palette.error.main }} fontSize="small" />
            )}
          </Box>
        </TableCell>
      );
    }
    case 'applications': {
      return (
        <TableCell sx={{ textAlign: 'right' }} key={`${(item.id, column.id)}`}>
          {item.applications || '-'}
        </TableCell>
      );
    }
    case 'delayed_to': {
      return <TableCell key={`${(item.id, column.id)}`}>{item.delayed_to || '-'}</TableCell>;
    }
    case 'created_at': {
      return (
        <TableCell key={`${(item.id, column.id)}`}>
          {item.created_at ? <FormattedDate date={item.created_at} /> : '-'}
        </TableCell>
      );
    }
    default:
      return <TableCell></TableCell>;
  }
};

export default CandidatePoolsTableRows;
