import React, { Dispatch, useState } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Stack,
  TextField
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { CandidateAction, CandidateState, ICandidateTask } from '../types';
import dayjs from 'dayjs';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { TCandidate } from '../../Candidates/types';
import {
  FormTextField,
  MultilineFormTextField
} from '../../Components/CustomUIElements/FormTextField';
import { SingleDateTimePicker } from '../../Components/CustomUIElements/SingleDatePicker';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { classes as importClasses } from '../../../ScoutCalendar/CreateEvent/EventProgress/styles';
import FormSelectWithIconField from '../../Components/CustomUIElements/FormSelectWithIconField';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { IAssignableUsers } from '../../ApprovalForms/types';
import ModalFooterButtons from '../../Components/GenericModal/ModalFooterButtons';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { styles } from '../styles';

const priorities = [
  { label: 'Low', value: '1', color: '#8CCF95' },
  { label: 'Normal', value: '0', color: '#E6A052' },
  { label: 'High', value: '-1', color: '#E37D7A' }
];

export default function AddEditTaskModal({
  tasks,
  candidate,
  assignableUsers,
  loadingAssignableUsers,
  CandidateState,
  dispatch
}: {
  tasks: ICandidateTask[];
  candidate: TCandidate;
  assignableUsers: IAssignableUsers[];
  loadingAssignableUsers: boolean;
  CandidateState: CandidateState;
  dispatch: Dispatch<CandidateAction>;
}) {
  const { editedTask } = CandidateState;
  const [subject, setSubject] = useState<string>(editedTask?.subject || '');
  const [note, setNote] = useState<string>(editedTask?.note || '');
  const [date, setDate] = useState<Date>(new Date(editedTask?.due_at || Date.now()));
  const [priority, setPriority] = useState<{ label: string; value: string; color: string }>(
    priorities.find((p) => p.label === editedTask?.priority) || priorities[0]
  );
  const [assignedUser, setAssignedUser] = useState<IAssignableUsers | null>(null);
  const [taskCompleted, setTaskCompleted] = useState<boolean>(!!editedTask?.completed_at);
  const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const queryClient = useQueryClient();

  const { mutate: createCandidateTask, isLoading: creatingCandidateTask } = useMutation({
    mutationFn: async () => {
      if (!assignedUser) return;
      const { res } = await Api.createCandidateTask(candidate.id, {
        subject,
        note,
        priority: Number(priority.value),
        due_at: dayjs(date).toISOString(),
        user_id: assignedUser.id
      });
      return res;
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Task has been successfully added`, state: 'success' }
      });
      queryClient.setQueryData(['tasks'], { tasks: [...tasks, res] });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error creating candidate task, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const { mutate: updateCandidateTask, isLoading: updatingCandidateTask } = useMutation({
    mutationFn: async () => {
      if (!editedTask) return;
      const { res } = await Api.updateCandidateTask(candidate.id, editedTask.id, {
        subject,
        note,
        priority: Number(priority.value),
        due_at: dayjs(date).toISOString(),
        completed_at: taskCompleted ? dayjs().toISOString() : null
      });
      return res;
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Task has been successfully updated`, state: 'success' }
      });
      const newTasks = [...tasks];
      const index = tasks.findIndex((t) => t.id === editedTask?.id);
      newTasks[index] = res;
      queryClient.setQueryData(['tasks'], { tasks: newTasks });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error updating candidate task, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const { mutate: deleteCandidateTask, isLoading: deletingCandidateTask } = useMutation({
    mutationFn: async () => {
      editedTask && Api.deleteCandidateTask(candidate.id, editedTask.id);
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Task has been successfully deleted`, state: 'success' }
      });
      const newTasks = tasks.filter((task) => task.id !== editedTask?.id);
      queryClient.setQueryData(['tasks'], { tasks: newTasks });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error deleting candidate task, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const handleClose = () => {
    editedTask && dispatch({ type: 'SET_EDITED_TASK', payload: null });
    dispatch({ type: 'OPEN_TASK_MODAL', payload: false });
  };

  const DeleteDialogDescription = () => (
    <Stack>
      <Stack>Are you sure you want to delete the current task?</Stack>
      <Stack>{`You will lose the task you've made.`}</Stack>
    </Stack>
  );

  const validateInputs = () => {
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!subject.trim()) inputErrors.subject = 'Subject can not be empty';
    if (!note.trim()) inputErrors.note = 'Note can not be empty';
    if (!assignedUser && !editedTask) inputErrors.user = 'Assigned user can not be empty';
    setErrors(inputErrors);
    return !Object.keys(inputErrors).length;
  };

  const handleSubmit = () => {
    if (validateInputs()) {
      editedTask ? updateCandidateTask() : createCandidateTask();
    }
  };

  return (
    <StyledModal
      isOpen={CandidateState.candidateTaskModalIsOpen}
      label="Add/Edit candidate task"
      handleClose={handleClose}
      styleOverrides={styles.modalContainer}
      closeIconId={`close-${editedTask ? 'edit' : 'add'}-task-button`}
    >
      <Stack sx={styles.modalContent}>
        <Stack sx={styles.modalTitle}>{editedTask ? 'Edit' : 'Add'} task</Stack>
        <Stack sx={styles.modalCandidateName}>
          {candidate.firstname} {candidate.lastname}
        </Stack>
        <FormTextField
          label="Subject"
          value={subject}
          required
          onChange={(v) => setSubject(v.target.value)}
          error={errors.subject}
        />
        <MultilineFormTextField
          label="Note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          fullWidth
          rows={5}
          required
          error={errors.note}
        />
        <Stack sx={{ flexDirection: 'row', columnGap: 5 }}>
          <Stack sx={{ width: '45%', flexDirection: 'row', columnGap: 2 }}>
            <Stack>
              <SingleDateTimePicker
                value={date}
                label={
                  <InputLabel htmlFor="text-input" sx={sharedClasses.inputLabel}>
                    Expiry date and time<span>*</span>
                  </InputLabel>
                }
                inputStyles={importClasses.datePicker}
                disablePast
                onChange={(value) => value && setDate(value)}
              />
            </Stack>
          </Stack>
          <FormSelectWithIconField
            options={priorities}
            defaultValue={priority.value}
            onChange={(value) =>
              setPriority(priorities.find((p) => p.value === value) || priorities[0])
            }
            label={'Priority'}
            styles={styles.prioritySelectStyling}
          />
        </Stack>
        {!editedTask && (
          <Autocomplete
            disablePortal
            id="add-candidate-task-assigned-users-select"
            options={assignableUsers || []}
            getOptionLabel={(option: IAssignableUsers) =>
              `${option?.firstname} ${option?.lastname}`
            }
            value={assignedUser}
            sx={{ ...sharedClasses.formAutocomplete, paddingTop: 3, width: '45%' }}
            loading={loadingAssignableUsers}
            loadingText="Loading assignable users..."
            ListboxProps={{ style: styles.listboxProps }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assign to user"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
                required
                error={!assignedUser}
                helperText={!assignedUser && errors.user}
              />
            )}
            onChange={(event, value) => setAssignedUser(value)}
          />
        )}
        {editedTask && (
          <FormControlLabel
            control={
              <Checkbox
                id="mark-as-complete-check-box"
                sx={sharedClasses.formCheckbox}
                checked={taskCompleted}
                onClick={() => setTaskCompleted(!taskCompleted)}
                disableRipple
              />
            }
            label="Mark as complete"
            sx={sharedClasses.checkboxLabel}
          />
        )}
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {editedTask && (
            <Button
              id="delete-task-button"
              sx={{
                ...sharedClasses.genericRedButton,
                marginTop: '10px',
                columnGap: 0.5,
                minWidth: '135px'
              }}
              onClick={() => setDeleteTaskDialogOpen(true)}
            >
              <DeleteIcon />
              Delete task
            </Button>
          )}
          <ModalFooterButtons
            primaryButtonText={editedTask ? 'Save' : 'Submit'}
            primaryButtonCallback={handleSubmit}
            secondaryButtonText="Cancel"
            secondaryButtonCallback={handleClose}
            isLoading={creatingCandidateTask || updatingCandidateTask}
            primaryButtonID={`${editedTask ? 'save' : 'submit'}-task-button`}
            secondaryButtonID={`cancel-${editedTask ? 'edit' : 'add'}-task-button`}
          />
        </Stack>
      </Stack>
      <GenericDialog
        isDialogOpen={deleteTaskDialogOpen}
        setDialogOpen={setDeleteTaskDialogOpen}
        title={`Delete task?`}
        description={DeleteDialogDescription()}
        buttonCallback={() => deleteCandidateTask()}
        callbackLoading={deletingCandidateTask}
        buttonText="Delete"
        secondaryButtonText="Cancel"
        url=""
        primaryButtonId="confirm-delete-task-button"
        secondaryButtonId="cancel-delete-task-button"
      />
    </StyledModal>
  );
}
