import React, { Dispatch } from 'react';
import { Box } from '@mui/material';
import { classes } from '../styles';
import { SocialSVG, AdvertisementSVG } from '../Graphics/buttonSVGs';
import { SmartShareAction } from '../reducer';

export const WelcomePage = ({ dispatch }: { dispatch: Dispatch<SmartShareAction> }) => {
  const handleSelectSocial = () => {
    dispatch({ type: 'SELECT_SOCIAL' });
  };

  const handleSelectJobBoard = () => {
    dispatch({ type: 'SELECT_JOB_AD' });
  };

  return (
    <Box sx={classes.welcomePageSelections}>
      <Box
        sx={classes.advertisementSelection}
        onClick={handleSelectJobBoard}
        id="write-job-board-advertisement"
      >
        <AdvertisementSVG />
        <Box sx={classes.welcomePageSelectionHeader}>Write me a job board advertisement</Box>
        <span>
          Write a job board advertisement to post on sites such as Seek, Indeed and LinkedIn.
        </span>
      </Box>
      <Box sx={classes.socialSelection} onClick={handleSelectSocial} id="write-social-media-post">
        <SocialSVG />
        <Box sx={classes.welcomePageSelectionHeader}>Write me a social media post</Box>
        <span>Write a social media post to attract the right talent to your job</span>
      </Box>
    </Box>
  );
};
