import React, { useEffect, useState, useCallback, Dispatch, SetStateAction, useMemo } from 'react';
import { Box } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { defaultMenuItems, navbarWidths } from '../../config';
import { classes } from '../../styles';
import NavigationParent from './NavigationParent';
import NavigationChildren from './NavigationChildren';
import CollapsedNavigationChildren from './CollapsedNavigationChildren';
import { formatUserPermissionsAsArray } from '../../../../Components/Utilities/formatUserPermissions';
import { IPermissions } from '../../../../Job/types';

const IS_ENGAGE_URL =
  window.location.pathname.includes('/admin/search/advanced') ||
  window.location.pathname.includes('/admin/engage');

const NavigationItems = ({
  navbarState,
  childState,
  setChildState,
  userPermissions,
  isAdminUser,
  aiStudioEnabled,
  newEngageEnabled
}: {
  navbarState: keyof typeof navbarWidths;
  childState: [number, null | HTMLElement];
  setChildState: Dispatch<SetStateAction<[number, null | HTMLElement]>>;
  userPermissions: { roles: IPermissions[] };
  isAdminUser: boolean;
  aiStudioEnabled: boolean;
  newEngageEnabled: boolean;
}) => {
  const [expandedParents, setExpandedParents] = useState<string[]>(
    localStorage.getItem('expandedParents')
      ? JSON.parse(localStorage.getItem('expandedParents') as string)
      : []
  );
  const [activeItem, setActiveItem] = useState({
    parent: '',
    child: ''
  });

  const filterMenuItems = useCallback(
    (userPermissions: string[]) => {
      let filteredMenuItems = defaultMenuItems(newEngageEnabled, IS_ENGAGE_URL);
      if (!isAdminUser && !IS_ENGAGE_URL) {
        filteredMenuItems = filteredMenuItems.filter((item) => item.title !== 'Tools');
      }
      filteredMenuItems = filteredMenuItems.map((item) => {
        if (item.submenu && item.submenu.length > 0) {
          item.submenu = item.submenu.filter((subItem) => {
            if (!subItem.permissions) return true;
            return subItem.permissions.some((permission) => userPermissions.includes(permission));
          });
        }
        return item;
      });
      filteredMenuItems = filteredMenuItems.filter(
        (item) => item.submenu && item.submenu.length > 0
      );
      return filteredMenuItems;
    },
    [isAdminUser, newEngageEnabled]
  );
  const menuItems = useMemo(
    () => filterMenuItems(formatUserPermissionsAsArray(userPermissions)),
    [filterMenuItems, userPermissions]
  );

  const discoverActiveItem = useCallback(() => {
    const isActive = (regex: RegExp) =>
      regex.test(window.location.pathname + window.location.search);
    if (isActive(/admin\.?$|\/admin\?/)) {
      setActiveItem({
        parent: 'dashboard',
        child: 'none'
      });
    }
    defaultMenuItems(newEngageEnabled, IS_ENGAGE_URL).forEach((item) => {
      if (item.submenu && item.submenu.length > 0) {
        item.submenu.forEach((subItem) => {
          if (isActive(new RegExp(subItem.active))) {
            setActiveItem({
              parent: item.title,
              child: subItem.title
            });
            setExpandedParents((prev) =>
              prev.includes(item.title) ? prev : [...prev, item.title]
            );
          }
        });
      }
    });
  }, [newEngageEnabled]);

  useEffect(() => {
    discoverActiveItem();
  }, [discoverActiveItem]);

  const handleChildPopperState = (index: number, anchorEl: HTMLElement | null) => {
    if (childState[0] === index) {
      setChildState([-1, null]);
    } else {
      setChildState([index, anchorEl]);
    }
  };

  return (
    <>
      <Box
        sx={{
          ...classes.navigationItemsContainer,
          ...(navbarState === 'collapsed' && classes.navigationItemsContainerCollapsed),
          ...(aiStudioEnabled && { marginBottom: '100px' })
        }}
      >
        {!IS_ENGAGE_URL && (
          <a href={activeItem.parent === 'dashboard' ? '#' : '/admin'}>
            <Box
              sx={{
                ...classes.menuItemParent,
                ...(activeItem.parent === 'dashboard' && classes.activeParent),
                ...(navbarState === 'collapsed' && classes.menuItemParentCollapsed)
              }}
            >
              <HomeOutlinedIcon />
              <span>Dashboard</span>
            </Box>
          </a>
        )}
        {menuItems &&
          menuItems.map((item, index) => (
            <Box key={item.title}>
              <NavigationParent
                item={item}
                expandedParents={expandedParents}
                setExpandedParents={setExpandedParents}
                navbarState={navbarState}
                activeParent={activeItem.parent}
                onCollapsedClick={(currentTarget) => handleChildPopperState(index, currentTarget)}
              />
              {navbarState === 'expanded' && (
                <NavigationChildren
                  item={item}
                  expandedParents={expandedParents}
                  activeItem={activeItem}
                />
              )}
            </Box>
          ))}
      </Box>
      {menuItems && navbarState === 'collapsed' && (
        <CollapsedNavigationChildren
          menuItems={menuItems}
          activeChild={activeItem.child}
          childState={childState}
          handleChildPopperState={handleChildPopperState}
        />
      )}
    </>
  );
};

export default NavigationItems;
