import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { IAttachment } from '../types';
import { classes } from '../../Components/Modals/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfPreview({
  selectedAttachment,
  setIsLoadingPreview
}: {
  selectedAttachment: IAttachment;
  isLoadingPreview: boolean;
  setIsLoadingPreview: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const BASE_URL = window.location.origin;
  const [numPages, setNumPages] = useState<number>(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setIsLoadingPreview(false);
  }

  function onDocumentLoadProgress({ loaded, total }: { loaded: number; total: number }) {
    if (loaded >= total) {
      setIsLoadingPreview(false);
    }
  }

  return (
    <Box sx={{ paddingBottom: '5px' }}>
      <Document
        file={`${BASE_URL}/admin/assets/${selectedAttachment.id}`}
        loading={<Box sx={classes.previewLoader}></Box>}
        onLoadStart={() => setIsLoadingPreview(true)}
        onLoadError={() => setIsLoadingPreview(false)}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadProgress={onDocumentLoadProgress}
      >
        {[...Array(numPages)].map((_, page) => (
          <Box sx={classes.pdfPage} key={page}>
            <Page pageNumber={page + 1} renderTextLayer={false} renderAnnotationLayer={false} />
          </Box>
        ))}
      </Document>
    </Box>
  );
}
