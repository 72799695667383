"use strict";
export function isPermittedOption(id, userPermissions) {
  var _a, _b;
  switch (id) {
    case "assigned_user":
      return !!((_a = userPermissions == null ? void 0 : userPermissions["Approval Forms"]) == null ? void 0 : _a["Assign User To Requisition"]);
    case "note":
      return !!((_b = userPermissions == null ? void 0 : userPermissions["Approval Forms"]) == null ? void 0 : _b["Manage Approval Form Notes"]);
    default:
      return true;
  }
}
