import { SxProps } from '@mui/material';
import { classes } from '../../../NewUI/Components/GenericTable/styles';

export const applicationColumns = [
  {
    id: 'id',
    label: 'ID',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'candidate',
    label: 'Candidate',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'application_deleted',
    label: 'Application Deleted',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'status',
    label: 'Status',
    sortable: false,
    canToggle: false,
    enabled: true
  }
];

export const headerStyles = (
  name: typeof applicationColumns[number]['id'] | 'checkbox' | 'actions',
  tableHovered: boolean
): SxProps => {
  switch (name) {
    case 'id':
      return {
        ...classes.sticky,
        width: '100px',
        minWidth: '100px'
      };
    case 'candidate':
      return {
        ...classes.sticky,
        ...classes.itemTitleTableCell,
        ...(tableHovered && classes.scrollShadowVisible),
        left: '100px',
        minWidth: '200px'
      };
    case 'application_deleted':
      return {
        minWidth: '120px',
        textAlign: 'center'
      };
    case 'status':
      return {
        minWidth: '70px',
        textAlign: 'center'
      };
    default:
      return { minWidth: '90px' };
  }
};
