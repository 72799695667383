import axios from 'axios';

export default class ServiceApi {
    // currently just mockups

  static get(id, callback) {
    return axios.get(
      `/admin/service_menu_items/${id}.json`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
      },
    ).then(response => callback(response.data));
  }

  static getSubservice(serviceId, id, callback) {
    return axios.get(
      `/admin/service_menu_items/${serviceId}/subservice_items/${id}.json`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
      },
    ).then(response => callback(response.data));
  }

  static sendNotification(serviceId, id, pack, callback) {
    return axios.post(
      `/admin/service_menu_items/${serviceId}/subservice_items/${id}/notify_shortlisting`,
      { price_pack: pack },
      {
        'Content-Type': 'application/json',
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      },
    ).then(response => callback(response.data));
  }
}
