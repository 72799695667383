import React, { useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Api from '../../API';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IRevision, ISnackBarMessage } from '../../types';
import { classes } from './styles';

export default function Revisions({
  apiKey,
  jobId,
  fetchJobs,
  setSnackbar,
  setActionsAnchorEl,
  userTimeZone
}: {
  apiKey: string;
  jobId: number;
  fetchJobs: () => void;
  setActionsAnchorEl: (value: null) => void;
  setSnackbar: ({ message, state }: ISnackBarMessage) => void;
  userTimeZone: string;
}) {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openContent, setOpenContent] = useState('');
  const [versionNumber, setVersionNumber] = useState<number | null>(null);
  const [revisions, setRevisions] = useState<IRevision[] | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const handleChange = (event: Event, newValue: number) => {
    setVersionNumber(newValue);
  };

  const createNewVersion = async () => {
    try {
      await Api.revertRevision({ 'X-api-authenticate': apiKey }, jobId, versionNumber);
      fetchJobs();
      setIsDialogOpen(false);
      fetchRevision();
      setOpenContent('');
      setActionsAnchorEl(null);
      setSnackbar({
        message: 'Your version was successful changed',
        state: 'success'
      });
    } catch (error) {
      setSnackbar({
        message: 'There was an error changing the version. Please try again.',
        state: 'error'
      });
      setIsDialogOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  const ticked = ['unchecked', 'checked'];

  const renderTableData = (field, value) => {
    const timestamp = value.timestamp
      ? moment.tz(value.timestamp, userTimeZone || timeZoneString).format('DD MMM YYYY, h:mma z')
      : '';
    switch (field) {
      case 'expires_at':
      case 'status_changed_at':
        return (
          <span>
            <Box sx={classes.revisionsVersionsContent}>
              <Box
                sx={classes.revisionsVersionsContentBlue}
                onClick={() =>
                  window.open(`${window.location.origin}${value.editor_url}`, '_blank').focus()
                }
              >
                {value.editor}
              </Box>
              <Box sx={{ paddingLeft: '2.5px' }}>
                <Box sx={{ display: 'flex' }}>
                  changed {field} from
                  <Box sx={classes.optionsText}>
                    {value.change_from ? moment(value.change_from).format('D MMM YYYY') : '-'}
                  </Box>
                  to{' '}
                  <Box sx={classes.optionsText}>
                    {value.change_to ? moment(value.change_to).format('D MMM YYYY') : '-'}{' '}
                  </Box>
                  <span style={{ margin: '0 3px' }}>on {timestamp}</span>
                </Box>
              </Box>
            </Box>
          </span>
        );
      case 'options':
        return (
          <span>
            <Box sx={classes.revisionsVersionsContentDescription}>
              <Box style={{ display: 'flex' }}>
                <Box
                  sx={classes.revisionsVersionsContentBlue}
                  onClick={() =>
                    window.open(`${window.location.origin}${value.editor_url}`, '_blank').focus()
                  }
                >
                  {value.editor}
                </Box>
                <Box sx={{ paddingLeft: '2.5px' }}>
                  <span>added new {field}</span>
                  <span style={{ margin: '0 3px' }}>on {timestamp}</span>
                </Box>
              </Box>
              <span
                onClick={() =>
                  setOpenContent(field === '' ? field : field === openContent ? '' : field)
                }
              >
                Show
                {openContent === field ? (
                  <ExpandLessIcon sx={{ color: '#939393' }} fontSize="small" />
                ) : (
                  <ExpandMoreIcon sx={{ color: '#939393' }} fontSize="small" />
                )}
              </span>
              {openContent === field ? (
                <Box sx={{ marginTop: '0.5rem' }}>
                  {!!value.change_from.blind_rating && (
                    <div style={{ display: 'flex' }}>
                      Blind rating changed from
                      <Box sx={classes.optionsText}>
                        {value.change_from.blind_rating
                          ? ticked[value.change_from.blind_rating]
                          : '-'}
                      </Box>
                      to <Box sx={classes.optionsText}>{ticked[value.change_to.blind_rating]}</Box>
                    </div>
                  )}
                  {!!value.change_from.hide_rating_activity && (
                    <div style={{ display: 'flex' }}>
                      Hide rating activity rating changed from
                      <Box sx={classes.optionsText}>
                        {value.change_from.hide_rating_activity
                          ? ticked[value.change_from.hide_rating_activity]
                          : '-'}
                      </Box>
                      to{' '}
                      <Box sx={classes.optionsText}>
                        {ticked[value.change_to.hide_rating_activity]}
                      </Box>
                    </div>
                  )}
                  {!!value.change_from.auto_ratings && (
                    <div style={{ display: 'flex' }}>
                      Auto ratings rating changed from
                      <Box sx={classes.optionsText}>
                        {value.change_from.auto_ratings
                          ? ticked[value.change_from.auto_ratings]
                          : '-'}
                      </Box>
                      to <Box sx={classes.optionsText}>{ticked[value.change_to.auto_ratings]}</Box>
                    </div>
                  )}
                  {!!value.change_from.title_field && (
                    <div style={{ display: 'flex' }}>
                      Title field rating changed from
                      <Box sx={classes.optionsText}>
                        {value.change_from.title_field
                          ? ticked[value.change_from.title_field]
                          : '-'}
                      </Box>
                      to <Box sx={classes.optionsText}>{ticked[value.change_to.title_field]}</Box>
                    </div>
                  )}
                  {!!value.change_from.gender_field && (
                    <div style={{ display: 'flex' }}>
                      Gender field rating changed from
                      <Box sx={classes.optionsText}>
                        {value.change_from.gender_field
                          ? ticked[value.change_from.gender_field]
                          : '-'}
                      </Box>
                      to <Box sx={classes.optionsText}>{ticked[value.change_to.gender_field]}</Box>
                    </div>
                  )}
                  {!!value.change_from.linkedin_field && (
                    <div style={{ display: 'flex' }}>
                      Linkedin field field rating changed from
                      <Box sx={classes.optionsText}>
                        {value.change_from.linkedin_field
                          ? ticked[value.change_from.linkedin_field]
                          : '-'}
                      </Box>
                      to{' '}
                      <Box sx={classes.optionsText}>{ticked[value.change_to.linkedin_field]}</Box>
                    </div>
                  )}
                  {!!value.change_from.indeed_apply && (
                    <div style={{ display: 'flex' }}>
                      Indeed apply field rating changed from
                      <Box sx={classes.optionsText}>
                        {value.change_from.indeed_apply
                          ? ticked[value.change_from.indeed_apply]
                          : '-'}
                      </Box>
                      to <Box sx={classes.optionsText}>{ticked[value.change_to.indeed_apply]}</Box>
                    </div>
                  )}
                  {!!value.change_from.employee_id_field && (
                    <div style={{ display: 'flex' }}>
                      Employee id field field rating changed from
                      <Box sx={classes.optionsText}>
                        {value.change_from.employee_id_field
                          ? ticked[value.change_from.employee_id_field]
                          : '-'}
                      </Box>
                      to{' '}
                      <Box sx={classes.optionsText}>
                        {ticked[value.change_to.employee_id_field]}
                      </Box>
                    </div>
                  )}
                  {(!!value.change_from.attachments || !!value.change_to.attachments) && (
                    <Box sx={{ marginTop: '0.5rem' }}>
                      Attachments:
                      <Box sx={{ ...classes.htmlContent, margin: '1rem 0' }}>
                        <Box sx={{ ...classes.headerContent, color: '#D6827D' }}>Previous</Box>
                        <Box sx={classes.attachmentContent}>
                          {value.change_from.attachments ? (
                            <span>
                              {Object.keys(value.change_from.attachments).map((key, i) => (
                                <li className="travelcompany-input" key={i}>
                                  <span className="input-label">
                                    {value.change_from.attachments[key].name}
                                    {Number(value.change_from.attachments[key].mandatory)
                                      ? ' (Required)'
                                      : ''}
                                  </span>
                                </li>
                              ))}
                            </span>
                          ) : (
                            <Box sx={classes.noAttachments}>Unset</Box>
                          )}
                        </Box>
                      </Box>
                      <Box sx={{ ...classes.htmlContent, margin: '1.5rem 0' }}>
                        <Box sx={{ ...classes.headerContent, color: '#8CCF95' }}>New</Box>
                        <Box sx={classes.attachmentContent}>
                          {value.change_to.attachments &&
                          Object.keys(value.change_to.attachments).length !== 0 ? (
                            <span>
                              {Object.keys(value.change_to.attachments).map((key, i) => (
                                <li className="travelcompany-input" key={i}>
                                  <span className="input-label">
                                    {value.change_to.attachments[key].name}
                                    {Number(value.change_to.attachments[key].mandatory)
                                      ? ' (Required)'
                                      : ''}
                                  </span>
                                </li>
                              ))}
                            </span>
                          ) : (
                            <Box sx={classes.noAttachments}>No attachments</Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <span />
              )}
            </Box>
          </span>
        );
      case 'description':
      case 'summary':
      case 'template':
        return (
          <span style={{ width: '100%' }}>
            <Box sx={classes.revisionsVersionsContentDescription}>
              <Box style={{ display: 'flex' }}>
                <Box
                  sx={classes.revisionsVersionsContentBlue}
                  onClick={() =>
                    window.open(`${window.location.origin}${value.editor_url}`, '_blank').focus()
                  }
                >
                  {value.editor}
                </Box>
                <Box sx={{ paddingLeft: '2.5px' }}>
                  <span>changed the {field}</span>
                  <span style={{ margin: '0 3px' }}>on {timestamp}</span>
                </Box>
              </Box>
              <span
                onClick={() =>
                  setOpenContent(field === '' ? field : field === openContent ? '' : field)
                }
              >
                Show
                {openContent === field ? (
                  <ExpandLessIcon sx={{ color: '#939393' }} fontSize="small" />
                ) : (
                  <ExpandMoreIcon sx={{ color: '#939393' }} fontSize="small" />
                )}
              </span>
              {openContent === field ? (
                <Box>
                  <Box sx={{ ...classes.htmlContent, margin: '1.5rem 0' }}>
                    <Box sx={{ ...classes.headerContent, color: '#D6827D' }}>Previous</Box>
                    {field === 'template' ? (
                      <Box sx={{ padding: '1rem 1rem 2rem 1rem' }}>{value.change_from}</Box>
                    ) : (
                      <Box
                        sx={{ padding: '1rem 1rem 2rem 1rem' }}
                        dangerouslySetInnerHTML={{ __html: value.change_from }}
                      />
                    )}
                  </Box>
                  <Box sx={{ ...classes.htmlContent, position: 'relative' }}>
                    {value.change_from.replace(/<\/?[^>]+(>|$)|\n/g, '') ===
                    value.change_to.replace(/<\/?[^>]+(>|$)|\n/g, '') ? (
                      <Box sx={classes.codeChangesOnly}>Styling or code changes only</Box>
                    ) : null}
                    <Box sx={{ ...classes.headerContent, color: '#8CCF95' }}>New</Box>
                    {field === 'template' ? (
                      <Box sx={{ padding: '1rem 1rem 2rem 1rem' }}>{value.change_to}</Box>
                    ) : (
                      <Box
                        sx={{ padding: '1rem 1rem 2rem 1rem' }}
                        dangerouslySetInnerHTML={{ __html: value.change_to }}
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <span />
              )}
            </Box>
          </span>
        );
      case 'template_enabled':
      case 'internal':
      case 'external':
        return (
          <span>
            <Box sx={classes.revisionsVersionsContentDescription}>
              <Box style={{ display: 'flex' }}>
                <Box
                  onClick={() =>
                    window.open(`${window.location.origin}${value.editor_url}`, '_blank').focus()
                  }
                  sx={classes.revisionsVersionsContentBlue}
                >
                  {value.editor}
                </Box>
                <Box sx={{ paddingLeft: '2.5px' }}>
                  <span>changed the job {field}</span>
                  <span style={{ fontWeight: '700', marginLeft: '3px' }}>
                    {typeof value.change_from === 'boolean' ? value.change_from.toString() : '-'}
                  </span>
                  <span style={{ margin: '0 3px' }}>to</span>
                  <span style={{ fontWeight: '700' }}>
                    {typeof value.change_to === 'boolean' ? value.change_to.toString() : '-'}
                  </span>
                  <span style={{ margin: '0 3px' }}>on {timestamp}</span>
                </Box>
              </Box>
            </Box>
          </span>
        );
      case 'trashed':
        return (
          <span>
            <Box sx={classes.revisionsVersionsContentDescription}>
              <Box style={{ display: 'flex' }}>
                <Box
                  onClick={() =>
                    window.open(`${window.location.origin}${value.editor_url}`, '_blank').focus()
                  }
                  sx={classes.revisionsVersionsContentBlue}
                >
                  {value.editor}
                </Box>
                <Box sx={{ paddingLeft: '2.5px' }}>
                  {value.change_to === false ? (
                    <span>restored the job from Trash</span>
                  ) : (
                    <span>sent the job to Trash</span>
                  )}
                  <span style={{ margin: '0 3px' }}>on {timestamp}</span>
                </Box>
              </Box>
            </Box>
          </span>
        );
      case 'archived':
        return (
          <span>
            <Box sx={classes.revisionsVersionsContentDescription}>
              <Box style={{ display: 'flex' }}>
                <Box
                  onClick={() =>
                    window.open(`${window.location.origin}${value.editor_url}`, '_blank').focus()
                  }
                  sx={classes.revisionsVersionsContentBlue}
                >
                  {value.editor}
                </Box>
                <Box sx={{ paddingLeft: '2.5px' }}>
                  {value.change_to === false ? (
                    <span>Unarchived the job</span>
                  ) : (
                    <span>Archived the job</span>
                  )}
                  <span style={{ margin: '0 3px' }}>on {timestamp}</span>
                </Box>
              </Box>
            </Box>
          </span>
        );
      case 'original_job_reference':
        return (
          <span>
            <Box sx={classes.revisionsVersionsContentDescription}>
              <Box style={{ display: 'flex' }}>
                <Box
                  onClick={() =>
                    window.open(`${window.location.origin}${value.editor_url}`, '_blank').focus()
                  }
                  sx={classes.revisionsVersionsContentBlue}
                >
                  {value.editor}
                </Box>
                <Box sx={{ paddingLeft: '2.5px' }}>
                  <span>Cloned from job {value.change_to}</span>
                  <span style={{ margin: '0 3px' }}>on {timestamp}</span>
                </Box>
              </Box>
            </Box>
          </span>
        );
      default:
        return (
          <span>
            <Box sx={classes.revisionsVersionsContent}>
              <Box
                sx={classes.revisionsVersionsContentBlue}
                onClick={() =>
                  window.open(`${window.location.origin}${value.editor_url}`, '_blank').focus()
                }
              >
                {value.editor}
              </Box>
              <Box sx={{ paddingLeft: '2.5px' }}>
                <span>
                  changed {field} from
                  <span style={{ fontWeight: '700', marginLeft: '3px' }}>
                    {value?.change_from ? value?.change_from : '-'}
                  </span>
                  <span style={{ margin: '0 3px' }}>to</span>
                  <span style={{ fontWeight: '700' }}>
                    {value?.change_to ? value.change_to : '-'}
                  </span>
                  <span style={{ margin: '0 3px' }}>on {timestamp}</span>
                </span>
              </Box>
            </Box>
          </span>
        );
    }
  };

  const fetchRevision = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await Api.getRevision({ 'X-api-authenticate': apiKey }, jobId, {});

      // Filter out revisions with no changes
      const filteredRevisions: IRevision[] = [];
      response?.data?.job_revisions.forEach((revision: IRevision) => {
        const filteredLogs = {};
        Object.keys(revision.logs).forEach((key) => {
          // Only include options with changes
          if (key === 'options') {
            const filteredOptions = {};
            Object.keys(revision.logs[key].change_from).forEach((optionKey) => {
              if (
                JSON.stringify(revision.logs[key].change_from[optionKey]) !==
                JSON.stringify(revision.logs[key].change_to[optionKey])
              ) {
                filteredOptions['change_from'] = {
                  [optionKey]: revision.logs[key].change_from[optionKey]
                };
                filteredOptions['change_to'] = {
                  [optionKey]: revision.logs[key].change_to[optionKey]
                };
              }
              if (Object.prototype.hasOwnProperty.call(filteredOptions, 'change_from')) {
                filteredLogs[key] = { ...revision.logs[key], ...filteredOptions };
              }
            });
            return;
          }

          // Filter out empty description, summary, and template
          if (
            ['description', 'summary', 'template'].includes(key) &&
            revision.logs[key].change_from.replace(/<\/?[^>]+(>|$)/g, '') === '' &&
            revision.logs[key].change_to.replace(/<\/?[^>]+(>|$)/g, '') === ''
          )
            return;

          // Only include fields with changes
          if (revision.logs[key].change_from !== revision.logs[key].change_to) {
            filteredLogs[key] = revision.logs[key];
          }
        });

        // Add revision to filteredRevisions if it has changes
        if (Object.keys(filteredLogs).length > 0) {
          filteredRevisions.push({ ...revision, logs: filteredLogs });
        }
      });

      setRevisions(filteredRevisions);
      setVersionNumber(filteredRevisions.length);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, jobId]);

  useEffect(() => {
    if (revisions !== null && versionNumber !== null) {
      const currentRevision = revisions[versionNumber - 1];
      if (currentRevision) {
        if (
          currentRevision.logs.hasOwnProperty('archived') ||
          currentRevision.logs.hasOwnProperty('trashed')
        ) {
          setDisableButton(true);
        } else {
          setDisableButton(false);
        }
      }
    }
  }, [revisions, versionNumber]);

  return (
    <Box>
      <Box
        onClick={() => {
          setIsDialogOpen(true);
          !revisions && fetchRevision();
        }}
        id="revisions-job-button"
      >
        Revisions
      </Box>
      <Modal
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={classes.revisionsModalContent}>
          <CloseIcon onClick={() => setIsDialogOpen(false)} sx={classes.closeIcon} />
          <Box id="modal-modal-title" sx={classes.revisionsTitle}>
            Revisions
          </Box>
          {revisions && versionNumber ? (
            <Box sx={{ marginTop: '50px' }}>
              <Box sx={classes.revisionsContent}>Versions</Box>
              <Slider
                aria-label="Temperature"
                value={versionNumber}
                onChange={handleChange}
                sx={classes.sliderInput}
                valueLabelDisplay="auto"
                step={1}
                min={1}
                max={revisions.length}
                marks={[
                  {
                    value: revisions.length,
                    label: 'Current'
                  }
                ]}
              />
            </Box>
          ) : (
            <span />
          )}
          <Box sx={classes.revisionsSubTitle}>
            {!revisions || revisions?.length === 0 ? '' : 'Changes'}
          </Box>
          {isLoading || !revisions || !versionNumber ? (
            <Box sx={{ display: 'flex', margin: 'auto' }}>
              {revisions && revisions?.length === 0 ? (
                <Box sx={classes.noRevisions}>No revisions for this job</Box>
              ) : (
                <CircularProgress size={32} sx={{ color: '#084D6D' }} />
              )}
            </Box>
          ) : (
            <Box sx={{ display: ' contents' }}>
              <Box sx={{ ...classes.revisionsContent, marginBottom: '10px' }}>
                {moment(revisions[versionNumber - 1]?.created_at).format('D MMM YYYY')}
              </Box>
              <Box sx={{ height: '100%', overflow: 'auto' }}>
                {Object.entries(revisions[versionNumber - 1].logs).map(([key, value], i) => (
                  <Box key={i} sx={classes.changesTableContent}>
                    <Box sx={classes.revisionsVersionsIcon}>
                      <BusinessCenterIcon fontSize="small" />
                    </Box>
                    {renderTableData(key, value)}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <Box sx={classes.revisionsActions}>
            <Button
              disableElevation
              sx={{ ...classes.buttonRedLight }}
              variant="text"
              onClick={() => {
                setIsDialogOpen(false);
                setActionsAnchorEl(null);
              }}
            >
              Cancel
            </Button>
            <Tooltip
              title={disableButton ? 'Reverting disabled for Archived and Trashed Jobs' : 'Revert'}
            >
              <span>
                <Button
                  sx={
                    revisions && versionNumber
                      ? { ...classes.revertloadButton, backgroundColor: '#5BC4BF' }
                      : { ...classes.revertloadButton, backgroundColor: 'grey' }
                  }
                  disabled={!revisions || !versionNumber || disableButton}
                  onClick={() => revisions && createNewVersion()}
                >
                  Revert
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
