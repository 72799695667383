import React, { useState, Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { Box, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledSnackbarProps } from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { classes } from './styles';
import Api from '../../NewUI/Job/API';
import AIStudioForm from './AIStudioForm';
import LoadingResponse from '../SharedComponents/Steps/LoadingResponse';
import AIStudioResponse from './AIStudioResponse';
import ErrorScreen from '../SharedComponents/Steps/ErrorScreen';
import { IGeneratedContent } from '../NewJobIntegration/AIStudioNewJob';
import { convertToLocalEnglish } from '../../NewUI/Components/Utilities/convertToLocalEnglish';
import { LIST_ITEMS_REGEX } from '../SharedComponents/sharedConfig';

export interface IQuestion {
  selected?: boolean;
  title: string;
  enabled: boolean;
  required: boolean;
  rateable: boolean;
  confidential: boolean;
}

export default function AIStudioField({
  jobId,
  jobDescription,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  getQuestions,
  jobTitle,
  setGeneratedContent,
  userPermissions
}: {
  jobId?: number;
  jobDescription?: string;
  setSnackbar?: StyledSnackbarProps['setSnackbarState'];
  modalsOpen: boolean;
  setModalsOpen: (open: boolean) => void;
  getQuestions?: () => void;
  jobTitle: string;
  setGeneratedContent?: Dispatch<SetStateAction<IGeneratedContent>>;
  userPermissions: Record<string, Record<string, boolean>>;
}) {
  const [questionType, setQuestionType] = useState<string>('general');
  const [positionTitle, setPositionTitle] = useState(jobTitle);
  const [preferredSkills, setPreferredSkills] = useState('');
  const [generatedResponse, setGeneratedResponse] = useState<IQuestion[] | undefined>(undefined);
  const [generationError, setGenerationError] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isCreating, setIsCreating] = useState(false);
  const [jobInformationSource, setJobInformationSource] = useState('skills');
  const questionsController = useRef<AbortController | null>(null);

  const handleClose = () => {
    // abort any pending requests
    questionsController?.current?.abort();

    // close the modal
    setModalsOpen(!modalsOpen);

    // reset the state to defaults
    setCurrentStep(0);
    setPositionTitle(jobTitle);
    setGenerationError(false);
    setGeneratedResponse(undefined);
    setQuestionType('general');
    setPreferredSkills('');
  };

  const generateQuestions = useCallback(async () => {
    questionsController.current = new AbortController();
    setCurrentStep(1);
    try {
      const response = await fetch('/api/v4/ai_studio', {
        signal: questionsController.current.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          job_id: jobId,
          ai_studio: {
            type: 'questions',
            question_type: questionType,
            job_details: {
              position_title: positionTitle,
              preferred_skills: preferredSkills
            },
            number_of_questions: 3,
            ...(jobInformationSource === 'description' && {
              use_description: true,
              job_description: jobDescription
            })
          }
        })
      }).then((res) => res.json());
      if (response && response.result && response.result.length > 30) {
        const localEnglishQuestions = convertToLocalEnglish(response.result);
        const questions = localEnglishQuestions
          .replace(LIST_ITEMS_REGEX, '')
          .split('**')
          .slice(0, -1)
          .map((question) => {
            return {
              selected: false,
              title: question,
              enabled: true,
              required: false,
              rateable: false
            };
          });
        setGeneratedResponse(questions);
      } else {
        throw new Error('No response from AI API');
      }
    } catch (error) {
      console.error(error);
      setGenerationError(true);
    } finally {
      if (!questionsController.current.signal.aborted) {
        setCurrentStep(2);
      }
    }
  }, [jobDescription, jobInformationSource, positionTitle, preferredSkills, questionType]);

  const handleSubmit = async () => {
    setIsCreating(true);
    const requests = generatedResponse?.map((question) => {
      if (!question.selected) return;
      return Api.newField({ 'X-api-authenticate': 'NONE' }, jobId || -1, {
        field: {
          title: question.title.replace(/\n/g, ''),
          enabled: question.enabled,
          required: question.required,
          rateable: question.rateable,
          confidential: question.confidential,
          field_type: 'text_area_field'
        }
      });
    });
    try {
      await Promise.all(requests);
      setSnackbar &&
        setSnackbar({
          message: 'Fields have been created',
          state: 'success'
        });
    } catch (error) {
      console.log(error);
      setSnackbar &&
        setSnackbar({
          message: 'Fields could not be created',
          state: 'error'
        });
    } finally {
      handleClose();
      setIsCreating(false);
      getQuestions && getQuestions();
    }
  };

  const handleAddToNewJob = () => {
    const newQuestions =
      generatedResponse
        ?.filter((q) => q.selected)
        .map((question) => {
          return {
            title: question.title.replace(/\n/g, ''),
            enabled: question.enabled,
            required: question.required,
            rateable: question.rateable
          };
        }) || [];
    setGeneratedContent &&
      setGeneratedContent((prev) => ({
        ...prev,
        screeningQuestions: [...prev.screeningQuestions, ...newQuestions]
      }));
    handleClose();
  };

  const stepSelect = () => {
    if (generationError) {
      return (
        <ErrorScreen
          regenerateCallback={generateQuestions}
          setGenerationError={setGenerationError}
          backToHome={() => setCurrentStep(0)}
        />
      );
    }
    switch (currentStep) {
      case 0:
        return (
          <AIStudioForm
            jobDescription={jobDescription}
            positionTitle={positionTitle}
            setPositionTitle={setPositionTitle}
            preferredSkills={preferredSkills}
            setPreferredSkills={setPreferredSkills}
            questionType={questionType}
            setQuestionType={setQuestionType}
            generateQuestions={generateQuestions}
            jobInformationSource={jobInformationSource}
            setJobInformationSource={setJobInformationSource}
          />
        );
      case 1:
        return <LoadingResponse generationType="questions" />;
      case 2:
        return (
          <AIStudioResponse
            generatedResponse={generatedResponse}
            setGeneratedResponse={setGeneratedResponse}
            generateQuestions={generateQuestions}
            setCurrentStep={setCurrentStep}
            handleSubmit={jobId ? handleSubmit : handleAddToNewJob}
            isCreating={isCreating}
            userPermissions={userPermissions}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal open={modalsOpen} onClose={handleClose} aria-labelledby="new-field-modal">
      <Box sx={classes.aiModal}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
        {stepSelect()}
      </Box>
    </Modal>
  );
}
