"use strict";
import { theme } from "../ThemeContext/ThemeObject";
export const classes = {
  pageContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100vw",
    height: "100dvh",
    overflow: "hidden",
    fontFamily: "Source Sans Pro"
  },
  loginFormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40vw",
    "@media  screen and (max-width: 1024px)": {
      width: "80%",
      height: "50%",
      position: "absolute",
      bottom: "0px",
      background: "white",
      borderRadius: "25vw 0 25vw 0",
      zIndex: "1",
      "@media  screen and (max-width: 430px)": {
        width: "100%",
        "@media  screen and (max-height: 700px)": {
          height: "60%"
        }
      }
    }
  },
  loginForm: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
    "& h1": {
      fontSize: "38px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "28px"
    },
    "@media  screen and (max-width: 1024px)": {
      width: "50%",
      height: "90%",
      "& h1": {
        fontSize: "48px"
      },
      "@media  screen and (max-width: 430px)": {
        height: "85%",
        width: "65%",
        fontSize: "14px",
        "& h1": {
          fontSize: "16px",
          marginBottom: "14px"
        }
      }
    }
  },
  description: {
    display: "block",
    marginTop: "20px",
    marginBottom: "20px",
    "& > span": {
      color: "#999"
    }
  },
  errorMessage: {
    position: "relative",
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.error.main,
    padding: "10px",
    transition: "height 0.4s ease-in-out",
    "@media  screen and (max-width: 430px)": {
      fontSize: "12px"
    }
  },
  fadeIn: {
    width: "100%",
    position: "absolute",
    visibility: "visible",
    opacity: "1",
    transition: "visibility 0s linear 0s, opacity 500ms",
    zIndex: "100"
  },
  fadeOut: {
    width: "100%",
    position: "absolute",
    visibility: "hidden",
    opacity: "0",
    transition: "visibility 0s linear 300ms, opacity 500ms"
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: "14px"
  },
  inputIcons: {
    color: theme.palette.primary.main,
    marginLeft: "10px",
    "@media  screen and (max-width: 430px)": {
      fontSize: "20px",
      marginLeft: "0"
    }
  },
  forgotPassword: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "12px",
    zIndex: 1e3,
    color: theme.palette.primary.main,
    "@media  screen and (max-width: 430px)": {
      margin: "8px 0px"
    }
  },
  loginButton: {
    display: "flex",
    gap: "10px",
    fontFamily: "Source Sans Pro",
    width: "100%",
    height: "56px",
    backgroundColor: theme.palette.action.main,
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "none",
    transition: "all 250ms ease-in-out",
    margin: "20px 0",
    "&:hover": {
      filter: "brightness(0.9)",
      backgroundColor: theme.palette.action.main,
      color: "#ffffff"
    },
    "&:disabled": {
      backgroundColor: "#EEEEEE",
      color: "#BBBBBB",
      transition: "all 250ms ease-in-out"
    },
    "@media  screen and (max-width: 1024px)": {
      margin: "0",
      "@media  screen and (max-width: 430px)": {
        height: "40px"
      }
    }
  },
  singleSignOn: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    color: theme.palette.action.main,
    marginTop: "14px",
    fontSize: "16px",
    fontWeight: "bold"
  },
  imageAndBlurbContainer: {
    position: "relative",
    width: "60vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    "@media screen and (max-width: 1024px)": {
      width: "100vw",
      zIndex: "-2"
    }
  },
  blurb: {
    maxWidth: "65%",
    fontSize: "54px",
    fontWeight: "bold",
    color: "#ffffff",
    paddingLeft: "30px",
    paddingBottom: "5vh",
    "@media screen and (max-width: 1024px)": {
      visibility: "hidden"
    }
  },
  infoPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: "0",
    left: "38vw",
    borderRadius: "6vw 0 6vw 0",
    width: "36vw",
    height: "30vh",
    fontSize: "3vh",
    fontWeight: "500",
    color: "#ffffff",
    "@media screen and (max-width: 1024px)": {
      position: "absolute",
      bottom: "auto",
      top: "39dvh",
      left: "auto",
      right: "0",
      borderRadius: "10vw 0 10vw 0",
      width: "45vw",
      height: "15vh",
      zIndex: "3",
      "@media  screen and (max-width: 430px)": {
        width: "70vw",
        borderRadius: "25vw 0 0 25vw",
        "@media  screen and (max-height: 700px)": {
          top: "30dvh"
        }
      }
    }
  },
  infoPanelText: {
    maxWidth: "24vw",
    "@media screen and (max-width: 1024px)": {
      maxWidth: "30vw",
      maxHeight: "12vh",
      fontSize: "1.8vh",
      "@media  screen and (max-width: 430px)": {
        maxWidth: "45vw"
      }
    }
  },
  inputs: {
    margin: "16px 0px",
    "@media  screen and (max-width: 430px)": {
      margin: "8px 0px"
    },
    "& legend": {
      display: "none"
    },
    "& fieldset": {
      top: "-2px"
    },
    "& input": {
      paddingLeft: "10px",
      "@media  screen and (max-width: 430px)": {
        paddingLeft: "0"
      }
    }
  },
  inputLabel: {
    left: "45px",
    backgroundColor: "#ffffff",
    padding: "0 8px",
    color: theme.palette.primary.main,
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    "& .Mui-focused": {
      color: theme.palette.primary.main
    },
    "@media  screen and (max-width: 430px)": {
      left: "25px",
      fontSize: "14px"
    }
  },
  logoContainer: {
    position: "absolute",
    top: "40px",
    left: "40px",
    "& img": {
      width: "100px",
      "@media  screen and (max-width: 1024px)": {
        width: "40%",
        "@media  screen and (max-width: 430px)": {
          width: "20%"
        }
      }
    }
  },
  link: {
    cursor: "pointer",
    "@media  screen and (max-width: 430px)": {
      fontSize: "12px"
    },
    "& > div": {
      position: "absolute",
      right: "-26px"
    }
  },
  alert: {
    position: "absolute",
    top: "-70px",
    maxWidth: "300px",
    borderRadius: "6px",
    backgroundColor: "#FAFAFA",
    color: theme.palette.primary.main,
    fontFamily: "Source Sans Pro",
    "& svg": {
      color: theme.palette.action.main
    },
    "@media  screen and (max-width: 1024px)": {
      top: "-200px",
      "@media  screen and (max-width: 430px)": {
        top: "-300px",
        "@media  screen and (max-height: 700px)": {
          top: "-200px"
        }
      }
    }
  },
  helpContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bottom: "20px",
    left: "40px",
    width: "max-content",
    fontSize: "15px",
    fontWeight: "bold",
    cursor: "pointer",
    color: theme.palette.primary.main,
    zIndex: "2",
    "@media  screen and (max-width: 430px)": {
      left: "30px"
    }
  },
  helpIcon: {
    color: theme.palette.primary.main,
    fontSize: "20px",
    marginRight: "8px",
    paddingBottom: "1px",
    "@media screen and (max-width: 1024px)": {
      fontSize: "40px",
      "@media  screen and (max-width: 430px)": {
        fontSize: "20px"
      }
    }
  },
  helpText: {
    "@media screen and (max-width: 1024px)": {
      visibility: "hidden"
    }
  }
};
