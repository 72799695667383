"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import RecruitApiClient from "../RecruitApiClient";
const PERMISSION_EXPIRY_PERIOD = 10 * 60 * 1e3;
export const getPermissions = (apiKey) => __async(void 0, null, function* () {
  try {
    const permissionCache = localStorage.getItem("userPermissions");
    const permissionExpiry = localStorage.getItem("userPermissionsExpiry");
    if (permissionCache && permissionExpiry && parseInt(permissionExpiry) > Date.now()) {
      return JSON.parse(permissionCache);
    }
    localStorage.removeItem("userPermissions");
    const { res } = yield RecruitApiClient.get(`/api/v4/user_permissions`, null, {
      "X-api-authenticate": apiKey
    });
    localStorage.setItem("userPermissions", JSON.stringify(res));
    localStorage.setItem(
      "userPermissionsExpiry",
      (Date.now() + PERMISSION_EXPIRY_PERIOD).toString()
    );
    return res;
  } catch (error) {
    console.error(error);
  }
});
