import React from 'react';
import { classes } from './styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

export interface IGenericDialogProps {
  url: string;
  title: string;
  description: string;
  moreContent?: string;
  buttonText: string;
  buttonCallback: (url: string, id: number) => void;
  callbackLoading: boolean;
  isDialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function GenericDialog({
  url,
  title,
  description,
  moreContent,
  buttonText,
  buttonCallback,
  callbackLoading,
  isDialogOpen,
  setDialogOpen
}: IGenericDialogProps) {
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => {
        setDialogOpen(false);
        if (title === 'Complete' && url) buttonCallback(url, 0);
      }}
      sx={{ '& .MuiPaper-root': classes.dialog, zIndex: '20000' }}
    >
      <CloseIcon
        onClick={() => {
          setDialogOpen(false);
          url && buttonCallback(url, 0);
        }}
        sx={classes.closeIcon}
      />
      {title !== 'Failed' ? (
        <Box sx={classes.tickWrapper}>
          <DoneIcon sx={classes.tickGreen} />
        </Box>
      ) : (
        <Box sx={classes.crossWrapper}>
          <CloseIcon sx={classes.crossRed} />
        </Box>
      )}
      <Box sx={classes.dialogHeader}>{title}</Box>
      {description && <Box>{description}</Box>}
      {moreContent && <Box>{moreContent}</Box>}
      <Box sx={classes.dialogButtonContainer}>
        <Button
          id={`${buttonText.toLowerCase().split(' ').join('-')}-button`}
          data-testid={`${buttonText.toLowerCase().split(' ').join('-')}-button`}
          disableElevation
          sx={title !== 'Failed' ? classes.confirmationButton : classes.buttonRed}
          onClick={() => {
            buttonCallback(url, 0);
            if (title === 'Complete' && url) window.open(url);
          }}
          style={{ padding: '10px 30px' }}
        >
          {callbackLoading ? <CircularProgress size={20} color="inherit" /> : buttonText}
        </Button>
      </Box>
    </Dialog>
  );
}

export default GenericDialog;
