import React, { useState, useEffect, useCallback, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TableSkeleton from '../TableSkeleton';
import AdTable from './AdTable';
import Alert from '@mui/material/Alert';
import { classes } from '../styles';

export default function BroadbeanAds({ apiKey, jobId }: { apiKey: string; jobId: string | null }) {
  const [ads, setAds] = useState(null);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    tabValue: number;
  }
  
  const TabPanel = useCallback((props: TabPanelProps) => {
    const { children, tabValue, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        id={`jobs-tabpanel-${index}`}
        aria-labelledby={`jobs-tab-${index}`}
        {...other}
      >
        {tabValue === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
  }, [])
  
  function a11yProps(index: number) {
    return {
      id: `jobs-tab-${index}`,
      'aria-controls': `jobs-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getAds = useCallback(async () => {
    setLoading(true);
    const url = jobId ? `/api/v4/broadbean/jobs/${jobId}/job_ads` : '';
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        }
      }).then((response) => response.json());
      setAds(response.ads)
      setIframeUrl(response.new_broadbean_url);
      setMessage(response.errors);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [apiKey, jobId]);

  useEffect(() => {
    getAds();
  }, [getAds]);

  return (
    <Box sx={classes.containerOuter}>
      <Box sx={classes.container}>
        <Box sx={classes.banner}>
          <Box id="broadbean-logo"></Box>
        </Box>
        <Box sx={classes.toggleButtonGroup}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Post, Manage"
          >
            <Tab label="Post new Ad " {...a11yProps(0)} />
            <Tab label="Manage Ads" {...a11yProps(1)} />
          </Tabs>
        </Box>
        {loading && <TableSkeleton />}
        <TabPanel tabValue={tabValue} index={0}>
          { iframeUrl ?  
            <iframe className="one-whole" style={{borderRadius: '8px', height: '1200px'}} src= {iframeUrl}/>
            : 
            <Alert severity="error">{message.substring(message.indexOf('<Message>') + 9, message.indexOf('</Message>'))}</Alert>
          }
          
        </TabPanel>
        <TabPanel tabValue={tabValue} index={1}>
          {ads && <AdTable jobAds={ads} setAds={setAds} apiKey={apiKey} />}
        </TabPanel>
      </Box>
    </Box>
  );
}
