import React, { useState, Dispatch, SetStateAction } from 'react';
import DynamicFooter from '../../SharedComponents/Footers/DynamicFooter';
import StyledDropdown from '../../SharedComponents/Inputs/StyledDropdown';
import CloseIcon from '@mui/icons-material/Close';
import { defaultFormats, defaultInclusions } from '../config';
import { Box } from '@mui/material';
import { classes } from '../styles';
import { IJobApplication } from '../../../NewUI/Job/types';
import Inclusions from '../Inputs/Inclusions';

const InputStep = ({
  selectedFormat,
  setSelectedFormat,
  inclusions,
  setInclusions,
  selectedApplicants,
  handleDeselectApplicant,
  requestInsights
}: {
  selectedFormat: string;
  setSelectedFormat: Dispatch<SetStateAction<string>>;
  inclusions: typeof defaultInclusions;
  setInclusions: Dispatch<SetStateAction<typeof defaultInclusions>>;
  selectedApplicants: IJobApplication[];
  handleDeselectApplicant: (applicantId: number) => void;
  requestInsights: () => void;
}) => {
  const [applicantHovered, setApplicantHovered] = useState<number>(-1);

  const inclusionsArray = Object.keys(inclusions).filter(
    (key) => inclusions[key as keyof typeof inclusions].value
  );

  const areAllSuitabilityComments = inclusionsArray.every((i) => i === 'suitability_comment');

  return (
    <>
      <Box sx={classes.header}>AI candidate insights</Box>
      <Box sx={classes.aiRow}>
        <Box sx={classes.fieldSubHeader}>Format</Box>
        <StyledDropdown
          id="insights-format-dropdown"
          value={selectedFormat}
          onChange={setSelectedFormat}
          options={defaultFormats}
        />
      </Box>
      <Box sx={classes.aiRow}>
        <Box sx={classes.fieldSubHeader}>Choose information to use for summary</Box>
        <Inclusions inclusions={inclusions} setInclusions={setInclusions} />
        {inclusionsArray.length !== 0 && areAllSuitabilityComments && (
          <Box sx={classes.suitabilityCommentWarning}>
            Suitability comments can only be used if at least one other information option is also
            selected
          </Box>
        )}
      </Box>
      <Box sx={classes.aiRow}>
        <Box sx={classes.fieldSubHeader}>Applications</Box>
        <Box sx={classes.applicantsContainer}>
          {selectedApplicants.map((applicant: IJobApplication, index: number) => (
            <Box
              sx={classes.applicant}
              key={applicant.id}
              onMouseEnter={() => setApplicantHovered(index)}
              onMouseLeave={() => setApplicantHovered(-1)}
            >
              <Box sx={classes.applicantInitials}>
                {applicant.candidate.firstname[0] + applicant.candidate.lastname[0]}
              </Box>
              {applicantHovered === index && (
                <>
                  <Box
                    sx={classes.removeApplicant}
                    onClick={() => handleDeselectApplicant(applicant.id)}
                  >
                    <CloseIcon />
                  </Box>
                  <Box sx={classes.applicantName}>
                    {applicant.candidate.firstname.split(' ') +
                      ' ' +
                      applicant.candidate.lastname.split(' ')}
                  </Box>
                </>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <DynamicFooter
        primaryButtonText="Generate"
        primaryButtonID="generate-custom-summary-button"
        disablePrimaryButton={areAllSuitabilityComments}
        handlePrimaryButtonClick={requestInsights}
        felixMessage={
          <>
            Let me know what format and information to use for your <span>candidate insights</span>.
            You can also email the insights!
          </>
        }
        felixMessageMaxWidth="400px"
      />
    </>
  );
};

export default InputStep;
