import React, { useState, useCallback, useEffect, useMemo } from 'react';
import MultiSelect, { IOptionType } from '../../Components/CustomUIElements/MultiSelect';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import SortIcon from '@mui/icons-material/Sort';
import CircularProgress from '@mui/material/CircularProgress';
import { defaultFilters, defaultFilterOptions } from '../config';
import { IFilterDropdownProps } from '../types';
import { styles } from '../styles';
import Tooltip from '@mui/material/Tooltip';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';

export default function FilterDropdown({
  filters,
  setFilters,
  getApprovalForms,
  entityList,
  assignedUserList,
  requesterList,
  setUserPreferences,
  setCurrentPage,
  archived
}: IFilterDropdownProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [tempFilters, setTempFilters] = useState({ ...filters });
  const [numberOfFilters, setNumberOfFilters] = useState(0);

  const calculateNumberOfFilters = useCallback((newFilters) => {
    return (
      Number(!!newFilters?.state.length) +
      Number(!!newFilters?.entity_ids?.length) +
      Number(!!newFilters?.assigned_user_ids?.length) +
      Number(!!newFilters?.requested_by_users?.length)
    );
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen((prev) => !prev);
  };

  const handleFilterChange = (name: string, value: IOptionType[] | number[] | boolean) => {
    setTempFilters({ ...tempFilters, [name]: value });
  };

  const handleApplyFilters = () => {
    setFilters({ ...tempFilters });
    setCurrentPage(1);
    sessionStorage.setItem(`approvalFormsTablePage`, 1);
    setUserPreferences(null, null, null, { ...tempFilters }, null);
    getApprovalForms(1, null, null, null, tempFilters, archived);
    setDropdownOpen(false);
    setAnchorEl(null);
    setNumberOfFilters(calculateNumberOfFilters(tempFilters));
  };

  const handleResetFilters = () => {
    setTempFilters({ ...defaultFilters });
    setUserPreferences(null, null, null, { ...defaultFilters }, null);
    setFilters({ ...defaultFilters });
    getApprovalForms(null, null, null, null, defaultFilters, archived);
    setDropdownOpen(false);
    setAnchorEl(null);
    setNumberOfFilters(0);
  };

  const entityOptions = useMemo(() => {
    return entityList?.map((entity) => ({
      name: entity.name,
      id: entity.id
    }));
  }, [entityList]);

  const assignedUserOptions = useMemo(() => {
    return assignedUserList?.map((user) => ({
      name: user.name,
      id: user.id
    }));
  }, [assignedUserList]);

  const requesterOptions = useMemo(() => {
    return requesterList?.map((requester) => ({
      name: requester.name,
      id: requester.id
    }));
  }, [requesterList]);

  useEffect(() => {
    setTempFilters({ ...filters });
    setNumberOfFilters(calculateNumberOfFilters(filters));
  }, [filters, calculateNumberOfFilters]);

  return (
    <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
      <Box>
        <Tooltip title="Filter" placement="top" arrow>
          <Badge
            badgeContent={numberOfFilters}
            sx={{ '& .MuiBadge-badge': { backgroundColor: '#E37D7A', color: '#FFF' } }}
          >
            <IconButton
              id="filter-approval-forms-button"
              sx={
                dropdownOpen ? { ...styles.iconButton, ...styles.iconButtonOpen } : styles.iconButton
              }
              onClick={handleClick}
            >
              {loadingFilters ? <CircularProgress size={24} /> : <SortIcon />}
            </IconButton>
          </Badge>
        </Tooltip>
        <Popper
          id="filter-approval-forms-dropdown-popover"
          sx={styles.popover}
          open={dropdownOpen}
          anchorEl={anchorEl}
          placement="bottom-end"
        >
          <Box sx={styles.filterPopoverContent}>
            <Box sx={styles.scrollableFilters}>
              <Box sx={styles.filterPopoverHeader}>
                <Button
                  id="reset-filters-button"
                  variant="contained"
                  endIcon={<CancelIcon />}
                  sx={styles.clearButton}
                  onClick={handleResetFilters}
                >
                  Clear all
                </Button>
                <Button
                  id="apply-filters-button"
                  variant="contained"
                  onClick={handleApplyFilters}
                  sx={styles.applyButton}
                >
                  Apply
                </Button>
              </Box>
              <Box sx={{ ...styles.filterOptionContainer, marginBottom: '20px' }} id="state-filter">
                State
                <MultiSelect
                  handleFilterChange={(name, value) => handleFilterChange(name, value)}
                  selectOptions={defaultFilterOptions.state}
                  name={'state'}
                  filters={tempFilters.state}
                />
              </Box>
              <Box
                sx={{ ...styles.filterOptionContainer, marginBottom: '20px' }}
                id="entity-filter"
              >
                Entity
                <MultiSelect
                  handleFilterChange={(name, value) => handleFilterChange(name, value)}
                  selectOptions={entityOptions}
                  name={'entity_ids'}
                  filters={tempFilters.entity_ids}
                />
              </Box>
              <Box
                sx={{ ...styles.filterOptionContainer, marginBottom: '20px' }}
                id="assigned-user-filter"
              >
                Assigned User
                <MultiSelect
                  handleFilterChange={(name, value) => handleFilterChange(name, value)}
                  selectOptions={assignedUserOptions}
                  name={'assigned_user_ids'}
                  filters={tempFilters.assigned_user_ids}
                />
              </Box>
              <Box
                sx={{ ...styles.filterOptionContainer, marginBottom: '20px' }}
                id="requester-filter"
              >
                Requested By
                <MultiSelect
                  handleFilterChange={(name, value) => handleFilterChange(name, value)}
                  selectOptions={requesterOptions}
                  name={'requested_by_users'}
                  filters={tempFilters.requested_by_users}
                />
              </Box>
            </Box>
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
