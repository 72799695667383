import React, { ChangeEvent, Dispatch, MouseEvent, useState } from 'react';
import {
  Box,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  Tooltip,
  Popper
} from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { classes } from './styles';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import SortableField from './SortableField';
import {
  GenericTableData,
  TSortableColumn,
  TTableState,
  TableColumnAction,
  TableDensity
} from '../types';
import { handleAPIRequest } from '../../Utilities/handleAPIRequest';

interface IColumnSettings {
  dispatch: Dispatch<TableColumnAction<TSortableColumn[]>>;
  tableState: TTableState<GenericTableData, TSortableColumn[]>;
  label: string;
  userPreferenceSource?: string;
}

const DENSITIES = ['Default', 'Compact', 'Ultra Compact'];

export default function ColumnSettings({
  dispatch,
  tableState,
  label,
  userPreferenceSource
}: IColumnSettings) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleIconClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen((prev) => !prev);
  };

  const sendLocalUserPreferences = (newColumns: TSortableColumn[]) => {
    if (userPreferenceSource) {
      handleAPIRequest({
        url: `/api/v4/ui_preferences`,
        method: 'POST',
        body: {
          ui_preference: {
            sortable_columns: newColumns,
            source: userPreferenceSource
          }
        }
      });
    }
  };

  const handleFieldSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSortableColumns = tableState.columns.map((field) => {
      if (field.id === event.target.name) {
        return { ...field, enabled: event.target.checked };
      } else {
        return field;
      }
    });
    dispatch({ type: 'SET_COLUMNS', payload: newSortableColumns });
    sendLocalUserPreferences(newSortableColumns);
  };

  const handleDensitySwitchChange = (newDensity: TableDensity) => {
    dispatch({ type: 'SET_DENSITY', payload: newDensity });
    handleAPIRequest({
      url: '/api/v4/ui_preferences',
      method: 'POST',
      body: {
        ui_preference: {
          columns: {
            density: newDensity
          },
          source: 'universal'
        }
      }
    });
  };

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = tableState.columns.map((c) => c.id).indexOf(String(active.id));
      const newIndex = tableState.columns.map((c) => c.id).indexOf(String(over?.id));
      const newSortableColumns = arrayMove(tableState.columns, oldIndex, newIndex);

      dispatch({ type: 'SET_COLUMNS', payload: newSortableColumns });
      sendLocalUserPreferences(newSortableColumns);
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Box>
        <Tooltip title="Table settings" placement="top" arrow>
          <IconButton
            id={`field-dropdown-${label}-button`}
            sx={isOpen ? { ...classes.iconButton, ...classes.iconButtonOpen } : classes.iconButton}
            onClick={handleIconClick}
          >
            <ViewColumnIcon />
          </IconButton>
        </Tooltip>
        <Popper
          id="column-settings-popover"
          sx={classes.popover}
          open={isOpen}
          anchorEl={anchorEl}
          placement="bottom-end"
        >
          <Box sx={classes.popoverContent}>
            <FormControl component="fieldset" variant="standard">
              <Stack sx={classes.tableSettingsHeader}>Column</Stack>
              <FormGroup id="column-settings-switches">
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={tableState.columns}
                    strategy={verticalListSortingStrategy}
                  >
                    {tableState.columns.map(
                      (field, index) =>
                        field.canToggle && (
                          <SortableField
                            key={index}
                            field={field}
                            handleFieldSwitchChange={handleFieldSwitchChange}
                          />
                        )
                    )}
                  </SortableContext>
                </DndContext>
              </FormGroup>
            </FormControl>
            <Stack sx={{ ...classes.tableSettingsHeader, paddingTop: 2 }}>Density</Stack>
            <FormControl>
              <FormGroup>
                {DENSITIES.map((density, index) => {
                  const isSwitched = density === tableState.density;
                  return (
                    <FormControlLabel
                      sx={
                        !isSwitched
                          ? { ...classes.switchLabels, ...classes.labelActive }
                          : classes.switchLabels
                      }
                      key={index}
                      control={
                        <Switch
                          sx={isSwitched ? classes.switchActive : classes.switch}
                          checked={isSwitched}
                          onChange={() => handleDensitySwitchChange(density as TableDensity)}
                        />
                      }
                      label={density}
                      labelPlacement="start"
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
