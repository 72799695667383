import React from 'react';
import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom';
import AdminSubmenu from './submenu';
import AdminServicesIndex from './services';
import AdminIntegrationsIndex from './integrations';
import AdminIntegrationsNew from './integrations/new';
import AdminIntegrationsShow from './integrations/show';
import AdminIntegrationsInfoShow from './integrations/info/show';
import AdminServiceShow from './services/show';
import AdminSubserviceShow from './services/subservices/show';

export default class AdminMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailSidebar: false,
      showAllIntegrations: false,
      mobileExpanded: false
    };
  }

  toggleDetailSidebar = () => {
    this.setState({ detailSidebar: !this.state.detailSidebar });
  };

  toggleIntegrationsList = () => {
    this.setState({ showAllIntegrations: !this.state.showAllIntegrations });
  };

  toggleMobileNav = (bool) => {
    bool
      ? $('body').removeClass('navigation-collapsed')
      : $('body').addClass('navigation-collapsed');
    this.setState({ mobileExpanded: !bool });
  };

  renderHub() {
    const { apps } = this.props.menu;

    return (
      <div className="section app-hub-section">
        <div className="toolbar d-flex align-items-center">
          <div className="title">Apps</div>
        </div>

        <div className="app-hub-items d-flex justify-content-center text-center">
          {apps.map(function (app, idx) {
            return (
              <div className={`item ${app.active ? 'active' : ''}`} key={idx}>
                <a href={app.url}>
                  <div className={`app-hub-logo ${app.icon}`} title={app.title} />
                  <div className="app-name">{app.title}</div>
                </a>
              </div>
            );
          })}

          {/* { apps.length < 3 && (
            <div className="item more">
              <span className="icon" />
              <a href="#">Go to App Hub</a>
            </div>
          )} */}
        </div>
      </div>
    );
  }

  renderServices() {
    const { services } = this.props.menu;

    return (
      <div className="section services-section">
        <div className="toolbar d-flex align-items-center">
          <div className="title">Services</div>
          <div className="more ml-auto">
            <Link to="/services" className="btn btn-link link-green">
              All Services &gt;
            </Link>
          </div>
        </div>
        <ul className="nav flex-column services-list">
          {services.map(function (service) {
            const { id, icon, title, url } = service;
            return (
              <li className="nav-item" key={id}>
                <Link to={`/services/${id}`} className="nav-link text-decoration-none">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex service-icon justify-content-center">
                      <img src={icon} title={title} alt={title} />
                    </div>
                    <div className="ml-2">{title}</div>
                    <div className="icon arrow-right ml-auto" />
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  renderIntegrations(showAll) {
    const { integrations } = this.props.menu;
    const limit = showAll ? integrations.length : 5;

    return (
      <div className="section integrations-section">
        <div className="toolbar d-flex align-items-center">
          <div className="title">Integrations</div>
          <div className="more ml-auto">
            <Link to="/integrations" className="btn btn-link link-green">
              All Integrations &gt;
            </Link>
          </div>
        </div>
        <ul className="nav flex-column integrations-list">
          {integrations.map(function (integration, idx) {
            if (idx >= limit) {
              return null;
            }

            const { id, icons, title, configured, info_only } = integration;
            const icon2x = icons[0];
            const icon3x = icons[1];

            return (
              <li className="nav-item" key={id}>
                <span className="nav-link text-decoration-none">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="integration-logo">
                      {icon2x ? (
                        <img
                          src={icon2x}
                          srcSet={`${icon2x} 2x, ${icon3x} 3x`}
                          title={title}
                          alt={title}
                        />
                      ) : (
                        title
                      )}
                    </div>
                    <div className="d-flex">
                      {configured && !info_only && (
                        <Link to={`/integrations/${id}`} className="btn btn-sm filled-green mr-1">
                          Update
                        </Link>
                      )}

                      {!configured && !info_only && (
                        <Link to={`/integrations/${id}`} className="btn btn-sm outline-green mr-1">
                          <span className="icon" /> Set it up now
                        </Link>
                      )}
                      <Link to={`/integrations/info/${id}`} className="btn btn-sm filled-green">
                        Learn more
                      </Link>
                    </div>
                  </div>
                </span>
              </li>
            );
          })}
        </ul>
        <div className="d-flex">
          <button
            className="btn btn-link link-green ml-auto text-decoration-none"
            onClick={this.toggleIntegrationsList}
          >
            Show {showAll ? 'Less' : 'More'}
          </button>
        </div>
      </div>
    );
  }

  renderComingSoon() {
    return (
      <div className="text-center border-top">
        <h1>Coming Soon</h1>
        <p className="font-weight-bold mb-4">
          Manage your recruitment services and enable integrations all in one place.
        </p>
        <a
          href="https://scouttalenthq.com/new-user-interface-for-scout-talent-software-users/#app-menu"
          target="_blank"
        >
          <button type="button" className="btn filled-blue">
            Learn More
          </button>
        </a>
      </div>
    );
  }

  render() {
    const { menu, applicationName, brandImageUrl, showServicesAndIntegrations, enableButtons } =
      this.props;
    const { integrations, services } = menu;
    const { detailSidebar } = this.state;

    return (
      <div>
        <div className="logo-wrapper">
          <Router>
            <Link to="/">
              <i className="material-icons" onClick={this.toggleDetailSidebar}>
                apps
              </i>
            </Link>
          </Router>
          <h2 className="new-logo">{applicationName}</h2>
        </div>
        <ul className="nav flex-column" id="main-menu" role="navigation">
          <li className="nav-item d-lg-none" id="navigation-expanded-button">
            <a
              href="#"
              className="nav-link"
              onClick={() => this.toggleMobileNav(this.state.mobileExpanded)}
            >
              <div className="d-flex flex-row">
                <i className="icon material-icons">chevron_right</i>
                <span>Hide Nav</span>
                <i className="chevron material-icons ml-auto">chevron_left</i>
              </div>
            </a>
          </li>

          {menu.items.map((item, idx) => (
            <AdminSubmenu key={idx} item={item} active={item.active} />
          ))}

          <div
            id="navigation-expander"
            onClick={() => this.toggleMobileNav(this.state.mobileExpanded)}
          ></div>
        </ul>
        {detailSidebar && (
          <Router>
            <div>
              <div className="detail-sidebar">
                <div className="menu-header">
                  <Link to="/">
                    <i className="material-icons" onClick={this.toggleDetailSidebar}>
                      apps
                    </i>
                  </Link>
                </div>
                <div className="detail-sidebar-content">
                  {this.renderHub()}
                  {showServicesAndIntegrations && this.renderServices()}
                  {showServicesAndIntegrations &&
                    this.renderIntegrations(this.state.showAllIntegrations)}
                  {!showServicesAndIntegrations && this.renderComingSoon()}
                </div>
              </div>
              <Route exact path="/"></Route>
              <Switch>
                <Route
                  exact
                  path="/services"
                  render={(props) => <AdminServicesIndex {...props} services={services} />}
                />
                <Route
                  exact
                  path="/integrations"
                  render={(props) => (
                    <AdminIntegrationsIndex {...props} integrations={integrations} />
                  )}
                />
                <Route exact path="/integrations/new" component={AdminIntegrationsNew} />
                <Route
                  path="/integrations/info/:id"
                  component={(props) => (
                    <AdminIntegrationsInfoShow {...props} enableButtons={enableButtons} />
                  )}
                />
                <Route path="/integrations/:id" component={AdminIntegrationsShow} />
                <Route
                  exact
                  path="/services/:serviceId/subservices/:id"
                  component={(props) => (
                    <AdminSubserviceShow {...props} enableButtons={enableButtons} />
                  )}
                />
                <Route
                  exact
                  path="/services/:id"
                  component={(props) => <AdminServiceShow {...props} />}
                />
              </Switch>
            </div>
          </Router>
        )}
      </div>
    );
  }
}
