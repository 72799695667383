import React, { Dispatch, FC, useState, useContext } from 'react';
import {
  Box,
  Modal,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { classes } from '../../../NewUI/ApprovalForms/NewApprovalPublicLink/styles';
import { IEntity } from '../types';
import { SystemReportsState, SystemReportsAction } from '../reducer';
import FormRadioButtonGroup from '../../../NewUI/Components/CustomUIElements/FormRadioButtonGroup';
import { useQuery } from '@tanstack/react-query';
import { ReportContext } from '../../index';
import RecruitApi from '../../RecruitApi';

interface IPlacementModal {
  dispatch: Dispatch<SystemReportsAction>;
  state: SystemReportsState;
  entities: IEntity[];
  fetchingEntities: boolean;
  getEntitiesAndChildren: (id: number) => void;
}

const ApplicationAndPlacementModal: FC<IPlacementModal> = ({
  dispatch,
  state,
  entities,
  fetchingEntities,
  getEntitiesAndChildren
}) => {
  const {
    filtersModal,
    groupBy,
    openPreview,
    selectedEntities,
    applicationStatus,
    includeNewApplications
  } = state;
  const [group, setGroup] = useState<string | number>(groupBy);
  const [entity, setEntity] = useState<IEntity | null>(
    entities.find((e) => e.id === selectedEntities[0]) || null
  );
  const [statuses, setStatuses] = useState<string[]>(applicationStatus);
  const [includeNew, setIncludeNew] = useState<boolean>(includeNewApplications);
  const reportServiceApi = useContext(ReportContext);

  const { data: options, isLoading: loadingOptions } = useQuery({
    queryKey: ['placement advanced options'],
    queryFn: async () => {
      const statusOptions: string[] = [];
      const { res } = await RecruitApi.getPublicStatuses();
      const { options } = await reportServiceApi.systemReports.options(openPreview);
      options.status.map((s: string) => {
        if (!res.statuses.includes(s)) {
          statusOptions.push(s);
        }
      });
      return [...res.statuses, ...statusOptions];
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading application statuses, ${error}`,
          state: 'error'
        }
      }),
    enabled: filtersModal['Placements Advanced Report']
  });

  const handleClose = () => {
    dispatch({
      type: 'FILTERS_MODAL',
      payload: { ...filtersModal, [openPreview]: false }
    });
  };

  const modalHeader = () => {
    if (filtersModal['Applications Report']) {
      return 'Applications';
    } else if (filtersModal['Application Timeline Report']) {
      return 'Application timeline';
    } else {
      return 'Placements advanced';
    }
  };

  return (
    <Modal
      open={
        filtersModal['Applications Report'] ||
        filtersModal['Application Timeline Report'] ||
        filtersModal['Placements Advanced Report']
      }
      onClose={handleClose}
      aria-labelledby="application-and-placement-filters-modal"
    >
      <Box sx={{ ...classes.actionsModal, height: 'unset', maxHeight: `unset`, width: '720px' }}>
        <Box sx={{ padding: '12px 0px 30px 0px' }}>
          <Box sx={{ ...classes.modalHeader, fontSize: '25px' }}>
            {`${modalHeader()} report filters`}
          </Box>
        </Box>
        <Box sx={{ ...classes.modalFormLine, paddingTop: 2 }}>
          {filtersModal['Applications Report'] && (
            <FormRadioButtonGroup
              groupLabel="Group by"
              options={[
                { label: 'Entity', value: 'entities' },
                { label: 'Source', value: 'source' }
              ]}
              defaultValue={group}
              onChange={(value) => setGroup(value)}
              directionRow
            />
          )}
          {filtersModal['Placements Advanced Report'] && !reportServiceApi.currentUser.staff ? (
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              multiple
              includeInputInList
              options={options || []}
              loading={loadingOptions}
              getOptionLabel={(option) => option}
              noOptionsText="No statuses"
              value={statuses}
              onChange={(_event, newValue) => (newValue ? setStatuses(newValue) : setStatuses([]))}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 3 }}
              ListboxProps={{ style: classes.autocompleteListboxStyle }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Statuses"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          ) : (
            <Autocomplete
              filterSelectedOptions
              disablePortal
              autoHighlight
              includeInputInList
              options={entities}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option.name ?? '';
              }}
              renderOption={(props, option, index) => {
                const key = `listItem-${index}-${option.id}`;
                return (
                  <li {...props} key={key}>
                    {option?.name}
                  </li>
                );
              }}
              loading={fetchingEntities}
              loadingText={fetchingEntities ? 'Loading entities...' : 'No Entities'}
              value={entity}
              onChange={(_event, newValue) => setEntity(newValue)}
              sx={{
                ...sharedClasses.formAutocomplete,
                width: '100%',
                marginTop: `${filtersModal['Applications Report'] ? '8px' : '24px'}`,
                label: { fontSize: '19px' }
              }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro',
                  color: '#333333',
                  maxHeight: '200px'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Entity"
                  sx={{ '& .MuiAutocomplete-root label': { fontSize: '14px' } }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {fetchingEntities ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          )}
          {filtersModal['Placements Advanced Report'] && (
            <FormControlLabel
              control={
                <Checkbox
                  sx={classes.checkbox}
                  checked={includeNew}
                  onClick={() => setIncludeNew(!includeNew)}
                  disableRipple
                />
              }
              label="Include New Applications"
              sx={classes.checkboxLabel}
            />
          )}
        </Box>
        <Box sx={{ ...classes.modalActions, marginBottom: 'unset', paddingTop: 4 }}>
          <Button
            type="submit"
            sx={classes.modalEditButton}
            onClick={() => {
              const filter: Record<string, string | number> = {};
              if (filtersModal['Applications Report']) {
                filter['Group by'] = group;
              }
              if (entity) {
                filter.Entity = entity.name;
                getEntitiesAndChildren(entity.id);
              } else {
                dispatch({ type: 'SELECTED_ENTITIES', payload: [] });
              }
              if (statuses.length) {
                filter['Statuses'] = statuses.join(', ');
              }
              if (includeNew) {
                filter['Include New Applications'] = 'True';
              }
              dispatch({ type: 'SET_FILTERS', payload: filter });
              dispatch({ type: 'GROUP_BY', payload: group });
              dispatch({ type: 'APPLICATION_STATUS', payload: statuses });
              dispatch({ type: 'INCLUDE_NEW_APPLICATIONS', payload: includeNew });
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
      </Box>
    </Modal>
  );
};

export default ApplicationAndPlacementModal;
