import React, { Dispatch } from 'react';
import AttachmentsList from '../../../../NewUI/Components/GenericTable/TablePopovers/AttachmentsList';
import {
  GenericTableAction,
  GenericTableState
} from '../../../../NewUI/Components/GenericTable/genericTableReducer';

const CandidateTabTableActionComponents = ({
  actionsState,
  dispatch
}: {
  actionsState: GenericTableState;
  dispatch: Dispatch<GenericTableAction>;
}) => {
  return (
    <AttachmentsList
      attachments={actionsState?.tableState?.actions?.selectedItem?.attachment_url || []}
      isOpen={actionsState.tableState.actions.isOpen.viewAttachments}
      handleClose={() => dispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'viewAttachments' })}
      anchorEl={actionsState?.tableState.actions.anchorEl}
    />
  );
};

export default CandidateTabTableActionComponents;
