import React from 'react';
import { Box, ToggleButtonGroup, ToggleButton, SxProps } from '@mui/material';
import { sharedClasses } from '../CustomUIElements/sharedClasses';

interface IProps {
  options: { value: string; label: string }[];
  selected: string;
  setSelected: (selected: string) => void;
  sx?: SxProps;
}

export const OptionToggleGroup = ({ options, selected, setSelected, sx }: IProps) => {
  return (
    <Box sx={{ ...sharedClasses.toggleButtonsContainer, ...sx }}>
      <ToggleButtonGroup
        aria-label="toggle button group"
        exclusive
        onChange={(_e, value) => {
          if (value === null) return;
          setSelected(value);
        }}
        value={selected}
      >
        {options.map(({ value, label }) => (
          <ToggleButton key={value} value={value} sx={sharedClasses.toggleButton}>
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};
