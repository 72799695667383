import React from 'react';

export default function AccountClockIcon() {
return (
  <svg id="MDI_account-clock-outline" data-name="MDI / account-clock-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Boundary" fill="#084d6d" stroke="rgba(0,0,0,0)" stroke-width="1" opacity="0">
      <rect width="24" height="24" stroke="none"/>
      <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
    </g>
    <path id="Path_account-clock-outline" data-name="Path / account-clock-outline" d="M16,14h1.5v2.82l2.44,1.41-.75,1.3L16,17.69V14m1-2a5,5,0,1,0,5,5,5,5,0,0,0-5-5m0-2a7,7,0,1,1-6.33,10H1V17c0-2.66,5.33-4,8-4a13.393,13.393,0,0,1,2.12.2A6.992,6.992,0,0,1,17,10m-7,7a6.858,6.858,0,0,1,.29-2A7.921,7.921,0,0,0,9,14.9c-2.97,0-6.1,1.46-6.1,2.1v1.1h7.19A6.71,6.71,0,0,1,10,17M9,4A4,4,0,1,1,5,8,4,4,0,0,1,9,4M9,5.9A2.1,2.1,0,1,0,11.1,8,2.1,2.1,0,0,0,9,5.9Z" transform="translate(0 -2)" fill="#084d6d"/>
  </svg>
)}
