import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import {
  Button,
  useTheme,
  Box,
  MenuItem,
  FormControl,
  Select,
  Popover,
  Modal,
  Typography,
} from '@material-ui/core';
import queryString from 'query-string';
import useStyles from './useStyles';
import { withScoutTheme } from '../../ThemeContext/ThemeContext';
import EventItem from './components/EventItem';
import BadAPIModal from './BadAPIModal';
import ReactLoading from 'react-loading';
import { ICalendlyScoutProps, ICalendlyEventListItem } from '../types';
import moment from 'moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import { TYPES, FILTERS } from './constants';
import SortIcon from '@material-ui/icons/Sort';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

interface IProps {
  calendlyAccount: ICalendlyScoutProps,
  apiKey: string,
}

interface IDateRange {
  min: Date | null,
  max: Date | null,
}

interface IEventApiParams {
  min: string,
  max: string,
  page: number,
}

function ScheduledEvents ({ calendlyAccount, apiKey }: IProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isNextPage, setIsNextPage] = useState(false);
  const [events, setEvents] = useState<ICalendlyEventListItem[]>([]);
  const [selectedType, setSelectedType] = useState<string>(TYPES[0]);
  const [pages, setPages] = useState<string[]>([null]);
  const [refOffset, setRefOffset] = useState<number>(0.5);
  const [refWidth, setRefWidth] = useState<number>(72);
  const [firstTabSwitch, setFirstTabSwitch] = useState<boolean>(false);
  const [savedDateRange, setSavedDateRange] = useState<IDateRange>({ min: new Date(moment().subtract(1, 'week').format()), max: new Date() })
  const [isDateSelectOpen, setIsDateSelectOpen] = useState(false);
  const [enteredTo, setEnteredTo] = useState(null);
  const [activeFilter, setActiveFilter] = useState<string>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterStatus, setFilterStatus] = React.useState('active');
  const [invalidAPIKey, setinvalidAPIKey] = useState(false);
  const [apiParams, setApiParams] = useState<IEventApiParams>({
    min: moment().format(),
    max: null,
    page: 0
  });

  const headerRefs = useRef([]);
  const filterRefs = useRef([]);
  const dateSelectAnchor = useRef(null);

  const classes = useStyles();
  const theme = useTheme();

  // Fetch events
  const headers = useMemo(() => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${calendlyAccount.access_token}`
  }), [calendlyAccount.access_token])

  useEffect(() => {
    getEvents() 
  }, [filterStatus])

  const getEvents = useCallback(async () => {
    setIsLoading(true);
    setEvents([])
    const inviteeParams = {
      count: 100,
      sort: 'created_at:asc',
      status: filterStatus
    }
    const eventParams = {
      ...(apiParams.max && { max_start_time: apiParams.max }),
      ...(apiParams.min && { min_start_time: apiParams.min }),
      user: calendlyAccount.user,
      count: 10,
      sort: 'start_time:asc',
      status: filterStatus
    }
    try {
      const { data } = await axios.get(pages[apiParams.page]
        ? pages[apiParams.page]
        : `https://api.calendly.com/scheduled_events?${queryString.stringify(eventParams)}`, { headers });
      const invitees = await Promise.all(data.collection.map((event: ICalendlyEventListItem) =>
        axios.get(`${event.uri}/invitees?${queryString.stringify(inviteeParams)}`, { headers })
          .then(invitee => ({
            ...event,
            invitees: invitee.data.collection
          }))
        )
      )
      setEvents(invitees);
      setIsNextPage(!!data.pagination.next_page);
      apiParams.page + 1 >= pages.length && setPages([...pages, data.pagination.next_page])
    } catch (err) {
      console.log(err)
      err.response.status === 401 && setinvalidAPIKey(true)
    } finally {
      setIsLoading(false)
    }
  }, [apiParams, pages, filterStatus])

  useEffect(() => {
    getEvents()
  }, [apiParams])

  // Handle calendly data when User has switched back to the tab
  const onFocus = () => getEvents();

  useEffect(() => {
    if (!calendlyAccount.access_token) setinvalidAPIKey(true)
    window.addEventListener("focus", onFocus);
    onFocus();
    return () => {
        window.removeEventListener("focus", onFocus);
    };
  }, []);

  // Handle categories
  const handleSelectedType = useCallback((type: string) => {
    const range = (() => {
      switch (type) {
        case 'Upcoming': return { min: moment().format(), max: null };
        case 'Past': return { min: null, max: moment().format() };
        case 'Date Range': return { min: moment(savedDateRange.min).format(), max: moment(savedDateRange.max).format() };
      }
    })();
    setApiParams(params => ({
      ...params,
      page: 0,
      min: range.min,
      max: range.max,
    }))
    setPages([])
    setSelectedType(type)
  }, [selectedType])

  useEffect(() => {
    const index = TYPES.indexOf(selectedType);
    if (!firstTabSwitch) return;
    if (index !== -1) {
      setRefOffset(headerRefs.current[index].offsetLeft + 0.5);
      setRefWidth(headerRefs.current[index].getBoundingClientRect().width);
    }
  }, [headerRefs, selectedType])

  useEffect(() => {
    setFirstTabSwitch(true);
    handleSelectedType(selectedType)
  }, [selectedType])


  // Pagination
  const handlePageUp = useCallback(() => {
    setApiParams(params => ({
      ...params,
      page: apiParams.page + 1
    }))
  }, [apiParams])

  const handlePageDown = useCallback(() => {
    setApiParams(params => ({
      ...params,
      page: apiParams.page - 1
    }))
  }, [apiParams])

  // Date selection
  const selectedDays = [savedDateRange.min, { from: savedDateRange.min, to: enteredTo }];
  const disabledDays = { before: new Date() };
  const modifiers = { start: savedDateRange.min, end: enteredTo };

  function isSelectingFirstDay(from: Date, to: Date, day: Date) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  function handleDayClick(day: Date) {
    if (savedDateRange.min && savedDateRange.max && day >= savedDateRange.min && day <= savedDateRange.max) return
    if (isSelectingFirstDay(savedDateRange.min, savedDateRange.max, day)) {
      setSavedDateRange({
        min: day,
        max: null
      })
      setEnteredTo(null)
    } else {
      setSavedDateRange(params => ({
        ...params,
        max: day
      }))
      setEnteredTo(day)
    }
  }

  function handleDayMouseEnter(day: Date) {
    if (!isSelectingFirstDay(savedDateRange.min, savedDateRange.max, day)) {
      setEnteredTo(day)
    }
  }

  return (
    <div className={classes.root}>
      <BadAPIModal invalidAPIKey={invalidAPIKey} hasAPIKey={!!calendlyAccount.access_token}/>
      <div className={classes.header}>
        <h4>My Calendly</h4>
        <Button
          disableElevation
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => { location.href = "/admin/calendly" }}
        >
          Create
        </Button>
      </div>
      <div className={classes.actions}>
        <div className={classes.type}>
          {TYPES.map((type: string, i: number) =>
            type === 'Date Range'
              ? <>
                  <span
                    ref={el => headerRefs.current[i] = el}
                    onClick={() => setSelectedType(type)}
                    className={selectedType === type ? classes.activeType : ''}
                  >
                    {type}
                  </span>
                  <div
                    className={`${classes.dateSelect} ${selectedType === 'Date Range' ? 'visible' : ''} ${isDateSelectOpen ? 'active' : ''}`}
                    ref={dateSelectAnchor}
                    onClick={() => setIsDateSelectOpen(true)}
                  >
                    <span>
                      { `${moment(savedDateRange.min).format('ll')} \u2014 `}
                      <em>{ `${moment(savedDateRange.max).format('ll')}` }</em>
                    </span>
                  </div>
                  <Popover
                    classes={{ paper: classes.datePopover }}
                    open={isDateSelectOpen}
                    onClose={() => setIsDateSelectOpen(false)}
                    anchorEl={dateSelectAnchor.current}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                  >
                    <DayPicker
                      numberOfMonths={2}
                      fromMonth={new Date(apiParams.min)}
                      selectedDays={selectedDays}
                      disabledDays={disabledDays}
                      modifiers={modifiers}
                      onDayClick={handleDayClick}
                      onDayMouseEnter={handleDayMouseEnter}
                    />
                    <Button
                      style={{ marginTop: 10 }}
                      disableElevation
                      color="secondary"
                      onClick={() => {
                        setIsDateSelectOpen(false)
                        setApiParams(params => ({
                          ...params,
                          min: moment(savedDateRange.min).format(),
                          max: moment(savedDateRange.max).format()
                        }))
                      }}
                    >
                      Apply
                    </Button>
                  </Popover>
                </>
              : <span
                  ref={el => headerRefs.current[i] = el}
                  onClick={() => setSelectedType(type)}
                  className={selectedType === type ? classes.activeType : ''}
                >
                  {type}
                </span>
          )}
          <div
            className={classes.typeUnderline}
            style={{
              left: `${refOffset}px`,
              width: `${refWidth}px`
            }}
          />
        </div>
        <div className={classes.moreActions}>
          <Button
            disableElevation
            variant="text"
            startIcon={<SortIcon />}
            onClick={() => setIsFilterOpen(filter => !filter)}
          >
            Filter
          </Button>
        </div>
      </div>
      { isFilterOpen &&
        <div className={classes.filters}>
          <div
            key={FILTERS[0].key}
            ref={el => filterRefs.current[0] = el}
            onClick={() => setActiveFilter(FILTERS[0].key)}
          >
            <span>{ FILTERS[0].name }</span>
            <div>
              {filterStatus}
              {activeFilter === FILTERS[0].key
                ? <ArrowDropUpIcon />
                : <ArrowDropDownIcon />
              }
            </div>
          </div>
          <Popover
            classes={{ paper: classes.datePopover }}
            open={!!activeFilter}
            onClose={() => setActiveFilter(null)}
            anchorEl={filterRefs.current[FILTERS.findIndex(filter => filter.key === activeFilter)]}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            {activeFilter === 'status' &&
              <Box style={{ 
                width: '250px',
                height: '100px' 
              }}>
                <div style={{ paddingBottom: '1rem' }}>Status</div>
                <FormControl variant="outlined" className={classes.filterWrapper} fullWidth>
                  <Select
                    value={filterStatus}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterStatus(e.target.value)}
                  >
                    <MenuItem value={'active'}>Active</MenuItem>
                    <MenuItem value={'canceled'}>Canceled</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            }
          </Popover>
        </div>
      }
      <div className={classes.eventContainer}>
        { isLoading
          ? <div className={classes.loading}>
              <CircularProgress sx={{color: theme.palette.primary.main}} />
            </div>
          : <>
              { events.length
                ? <div className={classes.eventHeader}>
                    { moment(events[0].start_time).format('dddd, MMMM Do YYYY') }
                  </div>
                : <div className={classes.noEvents}>No events to display</div>
              }
              { events.map((event, i) =>
                <React.Fragment key={event.uri}>
                  <EventItem event={event} />
                  { events[i + 1] && moment(events[i + 1].start_time).format('DD/MM/YYYY') !== moment(event.start_time).format('DD/MM/YYYY') && i !== 0 &&
                    <div className={classes.eventHeader}>{ moment(events[i+1].start_time).format('dddd, MMMM Do YYYY') }</div>
                  }
                </React.Fragment>
              )}
            </>
        }
      </div>
      { !isLoading &&
        <div className={classes.eventPagination}>
          {apiParams.page != 0 && 
            <Button
              color="secondary"
              variant="contained"
              disableElevation
              onClick={handlePageDown}
            >
              <ArrowBackIosIcon fontSize="medium"/>
            </Button>
          }
          {isNextPage &&
            <Button
              color="secondary"
              disableElevation
              onClick={handlePageUp}
            >
              <ArrowForwardIosIcon fontSize="medium"/>
            </Button>
          }
        </div>
      }
    </div>
  )
}

export default withScoutTheme(ScheduledEvents);
