import React from 'react';
import { GenericTableState } from '../../../../NewUI/Components/GenericTable/genericTableReducer';
import { TCandidatePool } from '../../../types';
import { TableCell } from '@mui/material';
import { classes } from '../../../../NewUI/Components/GenericTable/styles';
import FormattedDate from '../../../../NewUI/Components/GenericTable/TableCells/FormattedDate';
import { theme } from '../../../../ThemeContext/ThemeObject';

const CandidatePoolsTableRows = ({
  column,
  item,
  tableHovered
}: {
  column: GenericTableState['tableState']['columns'][number];
  item: TCandidatePool;
  tableHovered: boolean;
}) => {
  if (!column.enabled) return null;

  switch (column.id) {
    case 'candidate_name': {
      return (
        <TableCell
          id="individual-candidate-name"
          key={item.id + column.id}
          sx={{
            ...classes.sticky,
            ...classes.itemTitleTableCell,
            left: '58px',
            ...(tableHovered && classes.scrollShadowVisible),
            cursor: 'pointer',
            width: '150px'
          }}
        >
          {item?.candidate_name ? (
            <a href={item.candidate_link} target="_blank" rel="noreferrer">
              {item.candidate_name}
            </a>
          ) : (
            '-'
          )}
        </TableCell>
      );
    }
    case 'candidate_email': {
      return <TableCell key={item.id + column.id}>{item.candidate_email || '-'}</TableCell>;
    }
    case 'added_date': {
      return (
        <TableCell key={item.id + column.id}>
          {item.added_date ? <FormattedDate date={item.added_date} /> : '-'}
        </TableCell>
      );
    }
    case 'postcode': {
      return <TableCell key={item.id + column.id}>{item.postcode || '-'}</TableCell>;
    }
    case 'location': {
      return <TableCell key={item.id + column.id}>{item.location || '-'}</TableCell>;
    }
    case 'candidate_phone_number':
      return <TableCell key={item.id + column.id}>{item.candidate_phone_number || '-'}</TableCell>;
    case 'unsubscribed': {
      return (
        <TableCell
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            color: theme.palette.common.black
          }}
          key={item.id + column.id}
        >
          {item.unsubscribed ? 'Yes' : 'No'}
        </TableCell>
      );
    }
    default:
      return <TableCell></TableCell>;
  }
};

export default CandidatePoolsTableRows;
