import React, { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export default function DebounceInput({
  children,
  onDebouncedChange,
  debounceTime,
  defaultValue
}: {
  children: (props: {
    value: string | number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }) => React.ReactNode;
  onDebouncedChange: (value: string | number) => void;
  debounceTime?: number;
  defaultValue?: string | number;
}) {
  const [inputValue, setInputValue] = useState(defaultValue || '');

  useEffect(() => {
    if (defaultValue === 0) {
      setInputValue(0);
    } else {
      setInputValue(defaultValue || '');
    }
  }, [defaultValue]);

  const debouncedAPICall = useDebouncedCallback((input) => {
    onDebouncedChange(input);
  }, debounceTime || 300);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    debouncedAPICall(e.target.value);
  };

  return <>{children({ value: inputValue, onChange: handleChange })}</>;
}
