import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DoneIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { INoteProps } from './types';
import { classes } from '../Job/Applications/styles';

const NotePopover = ({
  setApprovalForms,
  anchorEl,
  handleCloseNote,
  approvalFormId,
  approvalFormNote,
  apiKey,
  setSnackbarState
}: INoteProps) => {
  const [noteBody, setNoteBody] = useState('');
  const [savingNote, setSavingNote] = useState(0);

  async function saveNote() {
    setSavingNote((prevValue) => prevValue + 1);
    try {
      const response = await fetch(`/api/v4/requisitions/${approvalFormId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        },
        body: JSON.stringify({
          requisition: {
            note: noteBody
          }
        })
      }).then((res) => res.json());
      if (response.success) {
        setApprovalForms((prevApprovalForms) => {
          const newApprovalForms = [...prevApprovalForms];
          const approvalFormIndex = newApprovalForms.findIndex(
            (approvalForm) => approvalForm.id === approvalFormId
          );
          newApprovalForms[approvalFormIndex].note = noteBody
          return newApprovalForms;
        });
        setNoteBody('');
        handleCloseNote();
      }
    } catch (error) {
      setSnackbarState({
        message: `Error saving note, ${error}`,
        state: 'error'
      });
    } finally {
      setSavingNote((prevValue) => prevValue - 1);
    }
  }

  useEffect(() => {
    if (!anchorEl) return;
    setNoteBody('');
  }, [anchorEl]);

  return (
    <Popover
      id={anchorEl ? 'edit-note-popover' : undefined}
      sx={classes.editNotePopover}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleCloseNote}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <>
        <Box sx={classes.notesTitle}>Add approval form note</Box>
        <TextField
          id="note-input"
          sx={classes.noteInput}
          label="Note"
          multiline
          fullWidth
          maxRows={4}
          variant="outlined"
          defaultValue={approvalFormNote || noteBody}
          onChange={(e) => setNoteBody(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Box id="notes-button-container" sx={classes.notesButtonContainer}>
          <Button sx={classes.notesCancelButton} endIcon={<CloseIcon />} onClick={handleCloseNote}>
            Cancel
          </Button>
          <Button
            id="save-note-approval-forms-button"
            sx={classes.notesEditSaveButton}
            endIcon={savingNote ? <CircularProgress size={16} /> : <DoneIcon />}
            onClick={() => {
              saveNote();
            }}
            disabled={!!savingNote}
          >
            {approvalFormNote ? "Update" : "Save"}
          </Button>
        </Box>
      </>
    </Popover>
  );
};

export default NotePopover;
