import React from 'react';
import PropTypes from 'prop-types';
import ScoutTrainingsTable from './table';
import ScoutTrainingsPortalButton from './portal-button';

export default class ScoutTrainingsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = { courses: [] };
  }

  static propTypes = {
    candidate: PropTypes.object.required,
    accessToken: PropTypes.string.required,
    endPoint: PropTypes.string.required
  };

  loadCourses() {
    const { candidate, accessToken, endPoint } = this.props;
    const url = endPoint + '/api/candidates/' + candidate.scout_candidate_id + '/courses';

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-AccessToken': accessToken
    };

    fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then((response) => {
        return response.json();
      })
      .then((courses) => {
        return this.setState({ courses: courses });
      })
      .catch((response) => {});
  }

  syncCandidate() {
    const { accessToken, endPoint } = this.props;
    const url = endPoint + '/api/candidates';

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-AccessToken': accessToken
    };

    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ candidate: this.props.candidate })
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.url) {
          return (window.location = data.url);
        }
      })
      .catch((response) => {
        response.json().then((body) => {
          alert(Object.values(body.error).join(', '));
        });
      });
  }

  componentDidMount() {
    this.loadCourses();
  }

  render() {
    return (
      <div>
        <div className="section">
          <h2>
            <div className="east">
              <div className="west">Scout Trainings</div>
            </div>
          </h2>
          <ScoutTrainingsTable collection={this.state.courses} />
        </div>
        <div className="section">
          <ScoutTrainingsPortalButton onClick={this.syncCandidate.bind(this)} />
        </div>
      </div>
    );
  }
}
