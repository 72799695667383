import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SelectedApplicants from '../SelectedApplicants';
import GenericDialog from '../../../Components/Modals/GenericDialog';
import {
  IExtendedActionModalProps,
  IApplicationStatus,
  IJobApplication,
  IGetApplications
} from '../../types';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';

interface IChangeStatusModalProps extends IExtendedActionModalProps {
  applicationStatuses: IApplicationStatus[];
  getApplications: IGetApplications;
}

export default function ChangeStatus({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  applicationStatuses,
  getApplications,
  filters
}: IChangeStatusModalProps) {
  const [isSending, setIsSending] = useState(false);
  const [noStatusSelected, setNoStatusSelected] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<IApplicationStatus | null>(null);
  const [tempGlobalSelected, setTempGlobalSelected] = useState<IJobApplication[]>([]);
  const [confirmCancelIsOpen, setConfirmCancelIsOpen] = useState(false);
  const selectStatusRef = useRef(null);

  const handleClose = (confirm: boolean) => {
    if (selectedStatus && confirm) {
      setConfirmCancelIsOpen(true);
    } else {
      setModalsOpen((prev) => ({ ...prev, changeStatus: false }));
      setSelectedStatus(null);
    }
  };

  const handleSubmit = async () => {
    if (!selectedStatus) {
      setNoStatusSelected(true);
      return;
    }
    setIsSending(true);
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/bulk_change_applications_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        body: JSON.stringify({
          application_ids: tempGlobalSelected.map((app) => app.id),
          change_status: selectedStatus.id
        })
      });
      if (response.ok) {
        getApplications(null, null, null, null, null);
        handleClose(false);
        setSnackbar({
          message:
            'Status changes queued, please allow up to a few minutes for them to take effect.',
          state: 'success'
        });
      }
    } catch (error) {
      setSnackbar({
        message: 'An error occurred while changing statuses, please try again',
        state: 'error'
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleStatusSelect = (
    event: React.SyntheticEvent<Element, Event>,
    value: IApplicationStatus | null
  ) => {
    setSelectedStatus(value);
    setNoStatusSelected(false);
  };

  useEffect(() => {
    if (globalSelected.length > 0 && modalsOpen.changeStatus) {
      setTempGlobalSelected(
        globalSelected.map((application) => ({
          ...application,
          status: selectedStatus?.name,
          status_details: selectedStatus
        }))
      );
    }
  }, [globalSelected, modalsOpen.changeStatus, selectedStatus]);

  return (
    <Modal
      open={modalsOpen.changeStatus}
      onClose={() => handleClose(true)}
      aria-labelledby="change-status-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={() => handleClose(true)} sx={classes.closeIcon} />
        <Box id="change-status-title" sx={classes.actionModalHeader}>
          Change status
        </Box>
        <Box
          sx={classes.modalContent}
          id="change-status-modal-content"
          aria-label="Change Status Modal Content"
        >
          <Box role="form" id="change-status-form" aria-label="Change Status form">
            <Box sx={{ ...classes.actionModalFormLine, marginTop: '20px' }} ref={selectStatusRef}>
              <Autocomplete
                options={applicationStatuses}
                getOptionLabel={(option) => option.name}
                onChange={handleStatusSelect}
                value={selectedStatus}
                sx={{ ...sharedClasses.formAutocomplete, width: '287px' }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Change to status"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />

              {noStatusSelected && <Box sx={classes.formError}>Status must be selected</Box>}
            </Box>
          </Box>
          <Box sx={{ marginLeft: '15px' }}>
            <h3>Candidates</h3>
            <SelectedApplicants
              globalSelected={selectedStatus ? tempGlobalSelected : globalSelected}
              handleDeselectCandidate={handleDeselectCandidate}
              closeModalCallback={() => handleClose(false)}
              filters={filters}
            />
          </Box>
        </Box>
        <Box sx={classes.modalFooter}>
          <Button
            id="close-change-status-button"
            sx={classes.modalCancelButton}
            onClick={() => handleClose(true)}
          >
            Cancel
          </Button>
          <Button
            id="submit-change-status-button"
            type="submit"
            sx={{ ...classes.modalSendButton, width: '91px', height: '41px' }}
            onClick={handleSubmit}
          >
            {isSending ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
          </Button>
        </Box>
        <GenericDialog
          isDialogOpen={confirmCancelIsOpen}
          setDialogOpen={setConfirmCancelIsOpen}
          title="Status changes have not been saved"
          description="Are you sure you want to close without submitting your changes?"
          buttonCallback={() => {
            setConfirmCancelIsOpen(false);
            handleClose(false);
          }}
          callbackLoading={false}
          buttonText="Close without saving"
          url=""
        />
      </Box>
    </Modal>
  );
}
