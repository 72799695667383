"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
export const InitialSmartFormsState = {
  currentPage: Number(sessionStorage.getItem(`smartFormsTablePage`)) || 1,
  rowsPerPage: 10,
  sortBy: "name",
  sortOrder: "asc",
  search: "",
  snackbar: {
    message: "",
    state: "success"
  },
  isDialogOpen: false,
  deleteSmartForm: 0,
  showSmartFormModal: null,
  enabled: true,
  selectedEntity: 0,
  name: "",
  selectedForm: null
};
export const SmartFormsReducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_PAGE":
      return __spreadProps(__spreadValues({}, state), { currentPage: action.payload });
    case "SET_ROWS_PER_PAGE":
      return __spreadProps(__spreadValues({}, state), { rowsPerPage: action.payload });
    case "SET_SORT_BY":
      return __spreadProps(__spreadValues({}, state), { sortBy: action.payload });
    case "SET_SORT_ORDER":
      return __spreadProps(__spreadValues({}, state), { sortOrder: action.payload });
    case "SET_SEARCH":
      return __spreadProps(__spreadValues({}, state), { search: action.payload });
    case "SET_SNACKBAR":
      return __spreadProps(__spreadValues({}, state), { snackbar: action.payload });
    case "SET_IS_DIALOG_OPEN":
      return __spreadProps(__spreadValues({}, state), { isDialogOpen: action.payload });
    case "SET_DELETE_SMART_FORM":
      return __spreadProps(__spreadValues({}, state), { deleteSmartForm: action.payload });
    case "SET_MODAL_OPEN":
      return __spreadProps(__spreadValues({}, state), {
        showSmartFormModal: action.payload
      });
    case "SET_TOGGLE_ENABLED":
      return __spreadProps(__spreadValues({}, state), { enabled: !state.enabled });
    case "SET_SELECTED_ENTITY":
      return __spreadProps(__spreadValues({}, state), { selectedEntity: action.payload });
    case "SET_NAME":
      return __spreadProps(__spreadValues({}, state), { name: action.payload });
    case "SET_SELECTED_FORM":
      return __spreadProps(__spreadValues({}, state), { selectedForm: action.payload });
    case "RESET_SMART_FORM":
      return __spreadProps(__spreadValues({}, state), {
        name: InitialSmartFormsState.name,
        enabled: InitialSmartFormsState.enabled
      });
  }
};
export const InitialSmartFormFieldsState = {
  snackbar: {
    message: "",
    state: "success"
  },
  modalsOpen: {
    newField: false,
    addSystemField: false,
    importFields: false,
    smartFormSettings: false
  },
  editingQuestion: null,
  selectedSystemField: null,
  selectedSourceId: null,
  systemFieldError: "",
  actionStates: {}
};
export const SmartFormFieldsReducer = (state, action) => {
  switch (action.type) {
    case "SET_SNACKBAR":
      return __spreadProps(__spreadValues({}, state), { snackbar: action.payload });
    case "SET_MODALS_OPEN":
      return __spreadProps(__spreadValues({}, state), { modalsOpen: action.payload });
    case "SET_EDITING_QUESTION":
      return __spreadProps(__spreadValues({}, state), { editingQuestion: action.payload });
    case "SET_SELECTED_SYSTEM_FIELD":
      return __spreadProps(__spreadValues({}, state), { selectedSystemField: action.payload });
    case "SET_SELECTED_SOURCE_ID":
      return __spreadProps(__spreadValues({}, state), { selectedSourceId: action.payload });
    case "SET_SYSTEM_FIELD_ERROR":
      return __spreadProps(__spreadValues({}, state), { systemFieldError: action.payload });
    case "SET_ACTION_STATES":
      return __spreadProps(__spreadValues({}, state), { actionStates: action.payload });
  }
};
