import React, { Component } from 'react';
import T from 'prop-types';

import debounce from 'debounce';
import Async from 'react-select/async';

export default class ScoutAutocomplete extends Component {
  static propTypes = {
    url: T.string.isRequired,
    name: T.string.isRequired,
    onChange: T.func,
    multi: T.bool,
    values: T.array,
    value: T.string,
    includeBlank: T.string,
    urlHeaders: T.object,
    selectedLabel: T.string,
  };

  constructor (props) {
    super(props);
    const { values, value } = this.props;

    this.state = { values, value, options: [], limitation: '50', optionsFirstLoad: false };
  }

  handleLimitationChange = (e) => this.setState({limitation: e.target.value});

  onChange = (option) => {
    if (this.props.multi) {
      let values = [];
      if (option) {
        option.map((opt) => { values.push(opt.value) })
      }
      this.setState({ values });
      if (this.props.onChange) {
        this.props.onChange(values);
      }
    } else {
      this.setState({ value: option.value });
      if (this.props.onChange) {
        this.props.onChange(option.value, option.label);
      }
    }
  }

  getOptions = (input, callback) => {
    const { url, multi, includeBlank, urlHeaders } = this.props;
    const { value, values, limitation } = this.state;
    const selected = multi ? values.join(',') : value;
    const searchString = input.toLowerCase().trim();
    const searchUrl = `${url}${url.indexOf('?') < 0 ? '?' : '&'}search=${searchString}&selected=${selected}&limit=${limitation}`
    fetch(searchUrl, { headers: urlHeaders }).then((response) => {
      response.json().then((json) => {
        let options = json.data.map(node => ({ label: node[0], value: node[1] }));
        if (includeBlank) {
          options.unshift({ label: includeBlank, value: '' });
        }
        this.setState({ options, optionsFirstLoad: true });
        callback(options);
      })
    });

    return;
  }

  loadOptions = debounce(this.getOptions, 500);

  limitationSelect = (limitation) => {
    const limitationOptions = ['50', '100', '200', '300', '400', '500', '1000', 'All'];

    return (
      <div className='limit-selection'>
        <label className='inline-label'>Number of the entities:</label>
        <select name="entities_limitation" onChange={this.handleLimitationChange} value={limitation}>
          { limitationOptions.map(option => <option value={option}>{option}</option>)}
        </select>
      </div>
    )
  }

  render () {
    const { values, value, limitation, options, optionsFirstLoad } = this.state;
    const { name, multi, className, url, moveType, selectedLabel } = this.props;
    let selected;

    if (multi) {
      selected = options.filter((option) => values.indexOf(option.value) >= 0);
      return (
        <div key={limitation}>
          {moveType == 'job_moving' ? this.limitationSelect(limitation) : ''}
          <Async
            key={ url }
            className={ className }
            name={ name }
            isMulti
            value={ selected }
            onChange={ this.onChange }
            joinValues={ false }
            loadOptions={ this.loadOptions }
            cache={ false }
            defaultOptions={ selectedLabel ? selected : true }
          />
        </div>
      );
    } else {
      if (!optionsFirstLoad && selectedLabel) {
        selected = [{ label: selectedLabel, value: value }];
      } else {
        selected = options.filter((option) => value + '' === option.value + '');
      }
      return (
        <div key={limitation}>
          {moveType == 'job_moving' ? this.limitationSelect(limitation) : ''}
          <Async
            key={ url }
            className={ className }
            name={ name }
            value={ selected }
            onChange={ this.onChange }
            loadOptions={ this.loadOptions }
            cache={ false }
            defaultOptions={ selectedLabel ? selected : true }
          />
        </div>
      );
    }
  }
}
