"use strict";
import {
  AmericanSpellings,
  BritishSpellings
} from "../data";
import { translateCase } from "./translateCase";
function map(word, options) {
  if (options == null ? void 0 : options.american) {
    return BritishSpellings[word];
  } else {
    return AmericanSpellings[word];
  }
}
export function translateWord(word, options) {
  let key = word.toLowerCase();
  let translated = map(key, options);
  if (key === "") return word;
  if (translated != null && typeof translated === "string") {
    return translateCase(word, translated);
  }
  if (key.endsWith("-")) {
    key = key.substring(0, key.length - 1);
    translated = map(key, options);
    if (translated != null) {
      return translateCase(word, translated) + "-";
    }
  }
  return word;
}
