import React from 'react'
import Box from '@mui/material/Box'

function NoJobs({ searchOrFilterApplied }: { searchOrFilterApplied: boolean }) {
  return (
    <Box 
      sx={{
        paddingTop: '90px',
        marginBottom: '90px',
        borderTop: '1px solid #efefef',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <svg id="Group_382" data-name="Group 382" xmlns="http://www.w3.org/2000/svg" width="108.165" height="114.658" viewBox="0 0 108.165 114.658">
        <defs>
        <clipPath id="clip-path">
        <rect id="Rectangle_714" data-name="Rectangle 714" width="108.165" height="114.658" fill="none"/>
        </clipPath>
        </defs>
        <g id="Group_381" data-name="Group 381" clipPath="url(#clip-path)">
          <path id="Path_196" data-name="Path 196" d="M134.714,629.344c-1.242-.28-1.7-.609-1.105-1,4.134-2.7,22.743-2.615,31.769-2.612,11.354,0,23.167-.067,33.394.589,4.28.275,13.976.528,15.639,1.44,4.362,2.4-26.574,3.057-31.581,3.089-8.347.055-41.023.086-48.116-1.51" transform="translate(-110.912 -520.443)" fill="#e3e3e3"/>
          <path id="Path_197" data-name="Path 197" d="M204.486,22.764l0,83.721a3.7,3.7,0,0,1-3.7,3.69l-75.431,0a3.7,3.7,0,0,1-3.7-3.69V93.434l.062-7.191,0-79.5a3.7,3.7,0,0,1,3.7-3.69l59.357,0,19.713,19.7Z" transform="translate(-101.179 -2.535)" fill="#efefef"/>
          <path id="Path_198" data-name="Path 198" d="M121.65,99.758l.06-7.09,0-78.386a3.6,3.6,0,0,1,3.555-3.638l57,0,.822.844V26.345a3.928,3.928,0,0,0,3.928,3.928h12.646a2.984,2.984,0,0,1,2.983,2.984l0,79.367a3.6,3.6,0,0,1-3.555,3.638l-73.885,0a3.6,3.6,0,0,1-3.555-3.638Z" transform="translate(-101.18 -8.853)" fill="#fff"/>
          <path id="Path_199" data-name="Path 199" d="M201.824,20.042q0,4.6,0,9.207,0,10.836,0,21.673,0,12.71,0,25.421v20.5q0,2.812,0,5.624c0,.389,0,.778,0,1.167a3.357,3.357,0,0,1-3.147,3.518c-3.416.119-6.858,0-10.275,0H124.728c-.446,0-.892,0-1.338,0a3.544,3.544,0,0,1-3.586-3.476c-.1-3.164,0-6.35,0-9.516,0-3.937.062-7.873.062-11.81v-14.6q0-12.161,0-24.321,0-12.113,0-24.226V4.891c0-.312-.008-.625,0-.937A3.34,3.34,0,0,1,123.1.54c1.454-.037,2.915,0,4.369,0h52.986a12.3,12.3,0,0,0,1.711,0c.285-.042.146.116-.02-.086.116.141.265.264.393.393l15.127,15.115,3.012,3.009.85.849c.126.126.3.415.294.22.008.376.824.219.817-.085-.008-.4-.9-1.008-1.151-1.258L198.32,15.53l-8.894-8.887L183.189.411a.943.943,0,0,0-.719-.384c-.566-.032-1.144,0-1.711,0H136.418c-4.362,0-8.727-.059-13.089,0a4.19,4.19,0,0,0-4.277,3.99c-.006.237,0,.476,0,.713q0,2.557,0,5.114,0,10.229,0,20.458,0,12.907,0,25.814,0,10.48,0,20.961c0,3.32,0,6.638-.031,9.958q-.035,3.993-.032,7.984,0,3.107,0,6.214a12.781,12.781,0,0,0,.177,3.719,4.027,4.027,0,0,0,3.879,2.715c.411.008.822,0,1.233,0h71.5a26.657,26.657,0,0,0,3.169-.031,4.148,4.148,0,0,0,3.694-3.995c.008-.319,0-.638,0-.956q0-7.164,0-14.329,0-12.135,0-24.271,0-12.531,0-25.062,0-8.352,0-16.7V19.956c0-.375-.817-.221-.817.085" transform="translate(-98.926 0)" fill="#cfcfcf"/>
          <path id="Path_200" data-name="Path 200" d="M496.544,1.693V17.561a3.886,3.886,0,0,0,3.886,3.886H516.3Z" transform="translate(-412.991 -1.408)" fill="#fff"/>
          <path id="Path_201" data-name="Path 201" d="M494.13.913q0,7.546,0,15.092a4.854,4.854,0,0,0,.789,3.122,4.23,4.23,0,0,0,3.536,1.6c2.658.01,5.316,0,7.973,0h7.6c.082,0,.812-.141.646-.307L497.366,3.113,494.919.665c-.149-.149-.893.15-.765.278L511.459,18.25l2.448,2.448.646-.307H499.666a5.014,5.014,0,0,1-3.034-.636,4.1,4.1,0,0,1-1.69-3.623q0-4,0-8,0-3.716,0-7.431c0-.134-.813-.018-.813.218" transform="translate(-410.983 -0.519)" fill="#cfcfcf"/>
          <path id="Path_202" data-name="Path 202" d="M279.563,168.983h24.813c.225,0,.711-.081.8-.332s-.078-.357-.33-.357H280.038c-.225,0-.711.081-.8.333s.078.357.33.357" transform="translate(-232.226 -139.975)" fill="#cfcfcf"/>
          <path id="Path_203" data-name="Path 203" d="M181.925,230.691h54.264c2.484,0,5.027.158,7.507,0,.036,0,.073,0,.109,0,.308,0,.862-.465.315-.465H189.857c-2.484,0-5.027-.158-7.507,0-.036,0-.073,0-.109,0-.308,0-.862.465-.315.465" transform="translate(-151.121 -191.428)" fill="#cfcfcf"/>
          <path id="Path_204" data-name="Path 204" d="M180.51,255.164h59.8a22.375,22.375,0,0,0,2.462,0,.979.979,0,0,1,.109,0c.458,0,.629-.579.076-.579h-59.8a22.413,22.413,0,0,0-2.462,0,.979.979,0,0,1-.109,0c-.458,0-.629.579-.076.579" transform="translate(-149.848 -211.713)" fill="#cfcfcf"/>
          <path id="Path_205" data-name="Path 205" d="M180.395,278.441q14.835,0,29.671,0c2.191,0,4.355,0,6.539-.176,1.71-.136,5.275-.265,5.174,2.353-.086,2.239-3.7,2.588-5.33,2.948a19.24,19.24,0,0,0-5.6,1.853,3.492,3.492,0,0,0-1.753,1.872,1.694,1.694,0,0,0,.781,2.035,9.455,9.455,0,0,0,3.338.776,18.529,18.529,0,0,0,3.823.037c.231-.021.877-.4.344-.356a17.65,17.65,0,0,1-3.647-.036c-1.032-.122-3.21-.264-3.753-1.339-1.176-2.332,2.782-3.609,4.3-4.024a55.546,55.546,0,0,0,5.982-1.533c1.245-.5,2.769-1.562,2.228-3.117-.559-1.607-2.408-1.906-3.881-1.9-1.015,0-2.023.12-3.034.2-1.182.088-2.368.061-3.552.06H180.739c-.239,0-.882.356-.344.356" transform="translate(-149.866 -231.08)" fill="#cfcfcf"/>
          <path id="Path_206" data-name="Path 206" d="M423.136,352.474l-6.861-4.787,1.493,8.38Z" transform="translate(-346.228 -289.182)" fill="#cfcfcf"/>
          <path id="Path_207" data-name="Path 207" d="M425.146,379.726l30.565,46.348c.272.413.621.662.779.556l4.8-3.21c.158-.106.066-.526-.206-.94l-30.565-46.348Z" transform="translate(-353.607 -312.842)" fill="#efefef"/>
          <path id="Path_208" data-name="Path 208" d="M417.1,349.19l.644,3.464,2.3-1.607Z" transform="translate(-346.912 -290.433)" fill="#cfcfcf"/>
          <path id="Path_209" data-name="Path 209" d="M588.022,625.99l5.162-3.427a.089.089,0,0,1,.124.022l3.633,5.549a.09.09,0,0,1-.029.123l-5.162,3.427a.089.089,0,0,1-.124-.022l-3.633-5.549a.09.09,0,0,1,.029-.123" transform="translate(-489.041 -517.792)" fill="#efefef"/>
          <path id="Path_210" data-name="Path 210" d="M468.874,430.648,442.77,391l-1.38.924,26.1,39.65Z" transform="translate(-367.118 -325.206)" fill="#efefef"/>
          <path id="Path_211" data-name="Path 211" d="M452.219,398.079l-3.954-6-9.079-13.784-10.029-15.227-6.805-10.332a8,8,0,0,0-.981-1.44,22.313,22.313,0,0,0-2.654-1.834l-3.443-2.379-.637-.44c-.144-.1-.83.007-.787.254l1.323,7.715a5.057,5.057,0,0,0,1.013,1.958l7.993,12.136,11.434,17.359,8.821,13.392c.526.8,1.016,1.645,1.589,2.412.654.877,1.365.267,2.051-.191l3.826-2.556c.444-.3.635-.453.321-1.04-.121-.225-.9.058-.813.218.179.334.236.408.05.634a3.105,3.105,0,0,1-.645.431l-2.005,1.34-1.73,1.156c-.046.031-.31.258-.363.243.641.185.143-.389.12-.456a3.8,3.8,0,0,0-.467-.709l-1.076-1.634-8.47-12.859-11.144-16.919L417.429,357a13.226,13.226,0,0,1-1.346-2.044,3.935,3.935,0,0,1-.131-.759l-.343-2-.8-4.664-.148-.861-.787.254,5.782,4a4.136,4.136,0,0,1,1.506,1.453l6.148,9.333,9.869,14.984,9.369,14.223,3.235,4.912a15.635,15.635,0,0,0,1.553,2.358.734.734,0,0,1,.071.108c.146.221.9-.083.813-.218" transform="translate(-344.209 -288.28)" fill="#cfcfcf"/>
          <path id="Path_212" data-name="Path 212" d="M423.33,378.65l5.369-3.593c.457-.306-.214-.562-.533-.348L422.8,378.3c-.457.306.214.562.533.349" transform="translate(-351.525 -311.592)" fill="#cfcfcf"/>
          <path id="Path_213" data-name="Path 213" d="M587.126,625.876l4.861-3.253c.457-.306-.229-.492-.519-.3l-4.861,3.254c-.457.306.229.492.519.3" transform="translate(-487.772 -517.541)" fill="#cfcfcf"/>
          <path id="Path_214" data-name="Path 214" d="M436.877,395.295l8.871,13.474,14.017,21.29,3.217,4.886c.127.194.873-.127.813-.218l-8.871-13.474-14.017-21.29-3.217-4.886c-.128-.194-.873.127-.813.218" transform="translate(-363.362 -328.547)" fill="#cfcfcf"/>
          <path id="Path_215" data-name="Path 215" d="M447.234,388.107l8.871,13.474,14.017,21.29,3.217,4.886c.149.226.938-.04.755-.318l-8.871-13.474-14.017-21.29-3.217-4.886c-.149-.226-.938.04-.755.318" transform="translate(-371.956 -322.465)" fill="#cfcfcf"/>
          <path id="Path_216" data-name="Path 216" d="M.581,634.245a1.191,1.191,0,0,1-.436-1.558c2.177-4.016,11.268-3.738,15.68-3.658,5.549.1,11.328.092,16.288,1.163,2.076.448,6.8.909,7.56,2.292,1.573,2.866-6.109,4.023-7.521,4.281a69.789,69.789,0,0,1-22.984.186c-1.113-.17-6.749-1.107-8.588-2.706" transform="translate(0 -523.153)" fill="#e3e3e3"/>
          <path id="Path_217" data-name="Path 217" d="M46.663,419.842v-7.071l-35.85,0-.005,38.469h0v.01h0c.044,1.374,8.05,4.071,17.923,4.072s17.879-2.694,17.923-4.067h0v-6.529a12.444,12.444,0,0,0,0-24.881m0,21.235,0-17.589a8.8,8.8,0,0,1,0,17.589" transform="translate(-8.99 -343.31)" fill="#eaeaea"/>
          <path id="Path_218" data-name="Path 218" d="M45.062,418.787q0-3.536,0-7.071c0-.086-.194-.082-.238-.082l-12.137,0-19.259,0H8.975c-.157,0-.575.086-.575.3q0,6.593,0,13.187,0,10.4,0,20.8c0,1.432,0,3.219,0,4.5,0,.086.194.082.238.082h0L8.4,450.41c.042.447.58.762.92.966a12.714,12.714,0,0,0,2.5,1.078,41.662,41.662,0,0,0,8.827,1.8,50.4,50.4,0,0,0,20.346-1.692,15.055,15.055,0,0,0,2.907-1.187c.4-.227,1.1-.6,1.154-1.129l-.572.254h0c.157,0,.575-.086.575-.3q0-3.264,0-6.529l-.575.3a13.172,13.172,0,0,0,11.771-7.914,12.637,12.637,0,0,0-3.334-14.241,12.794,12.794,0,0,0-8.007-3.106c-.2-.007-.934.363-.515.377a12.343,12.343,0,0,1,8.25,21.2,11.759,11.759,0,0,1-7.827,3.3c-.157.005-.575.083-.575.3v6.529l.575-.3h0c-.112,0-.555.082-.572.254-.041.434-.6.714-.934.9a13.929,13.929,0,0,1-2.59,1.035,42.339,42.339,0,0,1-8.345,1.606A49.6,49.6,0,0,1,13.2,452.339a15.931,15.931,0,0,1-2.842-1.1c-.381-.2-1.106-.546-1.153-1.048-.008-.088-.186-.082-.238-.082h0l.238.082c0-4.027,0-8.54,0-12.719q0-10.467,0-20.933,0-2.414,0-4.829l-.575.3H20.775l19.259,0h4.454l-.238-.082q0,3.536,0,7.071c0,.181.813.05.813-.218" transform="translate(-6.982 -342.365)" fill="#cfcfcf"/>
          <path id="Path_219" data-name="Path 219" d="M222.266,493.029q0-7.678,0-15.356,0-1.116,0-2.233l-.664.3a8.773,8.773,0,0,1,8.333,8.126,8.508,8.508,0,0,1-7.91,9.083c-.251.012-.876.408-.337.383a9.51,9.51,0,0,0,8.323-5.482,8.957,8.957,0,0,0-2.227-10.231,9.147,9.147,0,0,0-5.668-2.256c-.174-.008-.664.05-.664.3q0,7.678,0,15.356,0,1.116,0,2.233c0,.181.813.05.813-.218" transform="translate(-184.189 -395.372)" fill="#cfcfcf"/>
          <path id="Path_220" data-name="Path 220" d="M46.689,399.01c0,1.379-8.025,2.493-17.925,2.489s-17.925-1.125-17.925-2.5,8.025-2.493,17.925-2.489,17.925,1.125,17.925,2.5" transform="translate(-9.015 -329.786)" fill="#fff"/>
          <path id="Path_221" data-name="Path 221" d="M44.3,398.04c-.026.408-.613.58-.925.694a15.052,15.052,0,0,1-2.628.625,67.492,67.492,0,0,1-8.435.795,93.11,93.11,0,0,1-18.888-.761,18.814,18.814,0,0,1-2.773-.616c-.375-.121-1.136-.311-1.328-.714-.409-.857,2.31-1.254,2.747-1.34,5.983-1.185,12.319-1.169,18.389-.983a73.676,73.676,0,0,1,9.774.83,16.7,16.7,0,0,1,2.91.68c.365.131,1.123.357,1.154.84.014.216.831.06.813-.218-.068-1.052-2.635-1.371-3.343-1.513a59.927,59.927,0,0,0-8.861-.941c-3.871-.192-7.755-.2-11.627-.019a62.5,62.5,0,0,0-8.992.917,14.222,14.222,0,0,0-2.57.683c-.4.158-1.047.4-1.22.844-.386.978,1.856,1.413,2.4,1.546a47.5,47.5,0,0,0,8.369,1.064c3.828.238,7.672.281,11.505.156a67.8,67.8,0,0,0,10.414-.959,13.459,13.459,0,0,0,2.809-.763c.386-.166,1.085-.462,1.117-.964.019-.285-.793-.118-.808.118" transform="translate(-7.03 -328.757)" fill="#cfcfcf"/>
          <path id="Path_224" data-name="Path 224" d="M131.02,301.791a3.235,3.235,0,0,0,1.146-1.139c1.413-2.786-1.813-5.721-3.223-7.74a4.951,4.951,0,0,1,3.227-7.917c-.4.688-.932,1.292-1.35,1.969a3.425,3.425,0,0,0-.605,2.259,4.8,4.8,0,0,0,1.5,2.347,12.332,12.332,0,0,1,1.806,2.511,8.137,8.137,0,0,1,.961,4.36c-.124,1.464-1.5,4.387-3.458,3.35" transform="translate(-106.408 -237.039)" fill="#fff"/>
        </g>
      </svg>
      <Box
        id="no-jobs-message"
        sx={{
          fontFamily: 'Source Sans Pro',
          fontSize: '18px',
          fontWeight: 'bold',
          color: '#DDDDDD',
          marginTop: '32px',
        }}
      >
        {searchOrFilterApplied ? 'No jobs match your search or filter criteria' : 'You have no current jobs'}
      </Box>
    </Box>
  )
}

export default NoJobs