import React, { Dispatch, useMemo } from 'react';
import { Box, FormControl, FormGroup, Stack } from '@mui/material';
import { classes } from './styles';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import { ConfigOptionType } from '../types';

type Props = {
  state: {
    fieldOptions: ConfigOptionType[];
    activeColumns: {
      [key: string]: { enabled: boolean };
    };
  };
  dispatch: Dispatch<any>;
};
const SortableColumns = ({ state, dispatch }: Props) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const activeIndex = state.fieldOptions.findIndex(({ id }) => id === active.id);
      const overIndex = state.fieldOptions.findIndex(({ id }) => id === over?.id);
      const newArray = arrayMove(state.fieldOptions, activeIndex, overIndex);
      dispatch({
        type: 'SET_FIELD_OPTIONS',
        payload: newArray
      });
    }
  }

  const fieldIds = useMemo(() => state.fieldOptions.map((field) => field.id), [state.fieldOptions]);
  const sortableFields = state.fieldOptions.filter((field) => !field.sticky);
  return (
    <Box>
      <Stack sx={classes.tableSettingsHeader}>Column</Stack>
      <FormControl component="fieldset" variant="standard">
        <FormGroup id="field-dropdown-switches">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={fieldIds} strategy={verticalListSortingStrategy}>
              {sortableFields.map((field, index) => (
                <SortableItem key={index} field={field} state={state} dispatch={dispatch} />
              ))}
            </SortableContext>
          </DndContext>
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default SortableColumns;
