import React, { useState, Dispatch, SetStateAction, useEffect, useCallback } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Box, Stack } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { classes } from './styles';
import QuestionFieldViewer from '../../Components/Utilities/QuestionFieldViewer';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Switch from '@mui/material/Switch';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import Api from '../../Job/API';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { StyledSnackbarProps } from '../../Components/CustomUIElements/StyledSnackbar';
import { IApplicantQuestion } from '../types';
import AvailabilityFieldQuestion from '../../Components/CustomUIElements/AvailabilityFieldQuestion';

export default function SortableItem({
  allQuestions,
  questions,
  setQuestions,
  getQuestions,
  index,
  jobId,
  apiKey,
  setModalsOpen,
  editingQuestion,
  setEditingQuestion,
  setSnackbar,
  applicationCount,
  userPermissions
}: {
  allQuestions: IApplicantQuestion[];
  questions: IApplicantQuestion[];
  setQuestions: Dispatch<SetStateAction<IApplicantQuestion[]>>;
  getQuestions: any;
  index: number;
  jobId: number;
  apiKey: string;
  setModalsOpen: Dispatch<SetStateAction<Record<string, boolean>>>;
  editingQuestion: IApplicantQuestion;
  setEditingQuestion: Dispatch<SetStateAction<Record<IApplicantQuestion, null>>>;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
  applicationCount: number;
  updateJobQuestions: (questions: IApplicantQuestion[]) => void;
  userPermissions: Record<string, Record<string, boolean>>;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [tempEnabled, setTempEnabled] = useState(questions[index].enabled);
  const [tempRequired, setTempRequired] = useState(questions[index].required);
  const [tempRateable, setTempRateable] = useState(questions[index].rateable);
  const [tempConfidential, setTempConfidential] = useState(questions[index].confidential);
  const [tempWeight, setTempWeight] = useState(questions[index].weight);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: questions[index].id
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    filter: isDragging ? 'brightness(75%)' : 'brightness(100%)',
    zIndex: isDragging ? 10 : 1
  };

  const deleteQuestion = async (url: string, questionId: number) => {
    setIsDeleting(true);
    try {
      const response = await Api.deleteQuestion(
        { 'X-api-authenticate': apiKey },
        questionId,
        jobId
      );
      if (response.errors) {
        setSnackbar({
          message: 'You do not have right to delete this field',
          state: 'error'
        });
      } else {
        setSnackbar({
          message: 'Field has been deleted',
          state: 'success'
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
      setIsDialogOpen(false);
      getQuestions();
    }
  };

  const cloneQuestion = async (url: string, questionId: number) => {
    setIsCloning(true);
    try {
      await Api.cloneQuestion({ 'X-api-authenticate': apiKey }, questionId, jobId);

      setSnackbar({
        message: 'Field has been cloned',
        state: 'success'
      });
    } catch (error) {
      setSnackbar({
        message: `Failed to clone, ${error}`,
        state: 'error'
      });
    } finally {
      setIsCloning(false);
      setIsDialogOpen(false);
      getQuestions();
    }
  };

  const handleUpdateActions = async (
    event?: React.ChangeEvent<HTMLInputElement>,
    action: keyof (typeof questions)[0],
    weight?: number
  ) => {
    action === 'enabled' && setTempEnabled(!tempEnabled);
    action === 'required' && setTempRequired(!tempRequired);
    action === 'rateable' && setTempRateable(!tempRateable);
    action === 'confidential' && setTempConfidential(!tempConfidential);
    action === 'weight' && setTempWeight(weight);
    const fieldId = questions[index].id;
    const originalWeight = questions[index].weight;
    try {
      const response = await fetch(`/api/v4/fields/${fieldId}?job_id=${jobId}`, {
        method: 'PUT',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          field: {
            [action]: event?.target.checked,
            weight: weight
          }
        })
      }).then((response) => response.json());
      if (response.id) {
        setSnackbar({
          message: 'The change has been saved',
          state: 'success'
        });
        const newQuestions = [...allQuestions];
        const indexOfAllquestions = allQuestions.findIndex((item) => item.id === fieldId);
        if (weight !== undefined) {
          newQuestions[indexOfAllquestions].weight = weight;
        } else {
          newQuestions[indexOfAllquestions][action] = !allQuestions[indexOfAllquestions][action];
        }
        setTimeout(() => {
          setQuestions(newQuestions);
        }, 500);
      } else if (response.errors) {
        setSnackbar({
          message: `There was an error saving your changes. ${response.errors}`,
          state: 'error'
        });
        setTimeout(() => {
          action === 'enabled' && setTempEnabled(!event?.target.checked);
          action === 'required' && setTempRequired(!event?.target.checked);
          action === 'rateable' && setTempRateable(!event?.target.checked);
          action === 'confidential' && setTempConfidential(!event?.target.checked);
          action === 'weight' && setTempWeight(originalWeight);
        }, 500);
      }
    } catch (error) {
      setSnackbar({
        message: `There was an error saving your changes. ${error}`,
        state: 'error'
      });
      setTimeout(() => {
        action === 'enabled' && setTempEnabled(!event?.target.checked);
        action === 'required' && setTempRequired(!event?.target.checked);
        action === 'rateable' && setTempRateable(!event?.target.checked);
        action === 'confidential' && setTempConfidential(!event?.target.checked);
        action === 'weight' && setTempWeight(originalWeight);
      }, 500);
    }
  };

  const [dialogProps, setDialogProps] = useState({
    url: '',
    title: 'Permanently delete question?',
    description: '',
    buttonText: 'Delete',
    buttonCallback: deleteQuestion,
    callbackLoading: false,
    dialogId: 0
  });

  const handleDeleteQuestion = (questionId: number) => {
    setDialogProps((prev) => ({
      ...prev,
      url: `/api/v4/fields/${questionId}?job_id=${jobId}`,
      description: `Are you sure you want to delete this question? This action cannot be undone.`,
      dialogId: questionId,
      buttonCallback: deleteQuestion
    }));
    setIsDialogOpen(true);
  };

  const handleCloneQuestion = (questionId: number) => {
    setDialogProps((prev) => ({
      ...prev,
      url: `/api/v4/fields/${questionId}?job_id=${jobId}`,
      title: 'Clone question',
      description: `Are you sure you want to clone this question?`,
      buttonText: 'Clone',
      dialogId: questionId,
      buttonCallback: cloneQuestion
    }));
    setIsDialogOpen(true);
  };

  const handleEditQuestion = () => {
    setEditingQuestion({
      ...editingQuestion,
      enabled: questions[index].enabled,
      required: questions[index].required,
      rateable: questions[index].rateable
    });
    setModalsOpen((prev) => ({ ...prev, newField: true }));
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <Box key={questions[index].id}>
        {questions[index].type === 'CsvDataLookupField' ||
        questions[index].type === 'JobReferenceLookupField' ? (
          <span />
        ) : (
          <Box sx={classes.questionsWrapper}>
            <Box sx={{ display: 'flex' }}>
              <DragIndicatorIcon
                sx={{ cursor: isDragging ? 'grabbing' : 'pointer', ...classes.dragHandle }}
                {...listeners}
                {...attributes}
              />
              <Box sx={{ marginRight: 'auto', ...classes.questionInput }}>
                <Box sx={{ height: '6px' }}></Box>
                <Box sx={{ ...classes.modalFormLine, position: 'relative', minWidth: '300px' }}>
                  <QuestionFieldViewer question={questions[index]} draggable={true} />
                </Box>
              </Box>
              <Box
                sx={{
                  ...classes.sortableItemSwitchContainer,
                  fontWeight: questions[index].enabled ? 'bold' : ''
                }}
              >
                <Box sx={{ marginTop: '12px' }}>
                  <Switch
                    sx={questions[index].enabled ? classes.switchActive : classes.switch}
                    checked={tempEnabled}
                    size="small"
                    onChange={(event) => handleUpdateActions(event, 'enabled')}
                  />
                  <Stack sx={{ marginTop: 1, fontSize: '12px' }}>Enabled</Stack>
                </Box>
              </Box>
              <Box
                sx={{
                  ...classes.sortableItemSwitchContainer,
                  fontWeight: questions[index].required ? 'bold' : ''
                }}
              >
                <Box sx={{ marginTop: '12px' }}>
                  <Switch
                    sx={questions[index].required ? classes.switchActive : classes.switch}
                    checked={tempRequired}
                    size="small"
                    onChange={(event) => handleUpdateActions(event, 'required')}
                    disabled={!questions[index].editable}
                  />
                  <Stack sx={{ marginTop: 1, fontSize: '12px' }}>Required</Stack>
                </Box>
              </Box>
              <Box
                sx={{
                  ...classes.sortableItemSwitchContainer,
                  fontWeight: questions[index].rateable ? 'bold' : ''
                }}
              >
                <Box sx={{ marginTop: '12px' }}>
                  <Switch
                    sx={questions[index].rateable ? classes.switchActive : classes.switch}
                    checked={tempRateable}
                    size="small"
                    onChange={(event) => handleUpdateActions(event, 'rateable')}
                    disabled={!questions[index].editable}
                  />
                  <Stack sx={{ marginTop: 1, fontSize: '12px' }}>Rateable</Stack>
                </Box>
              </Box>
              <Box
                sx={{
                  ...classes.sortableItemSwitchContainer,
                  fontWeight: questions[index].confidential ? 'bold' : ''
                }}
              >
                <Box sx={{ marginTop: '12px' }}>
                  <Switch
                    sx={questions[index].confidential ? classes.switchActive : classes.switch}
                    checked={tempConfidential}
                    size="small"
                    onChange={(event) => handleUpdateActions(event, 'confidential')}
                    disabled={!questions[index].editable}
                  />
                  <Stack sx={{ marginTop: 1, fontSize: '12px' }}>Confidential</Stack>
                </Box>
              </Box>
              <Box
                sx={{
                  padding: '0 6px',
                  display: 'grid',
                  minWidth: '76px',
                  alignItems:
                    questions[index].answered && !questions[index].editable ? 'center' : '',
                  ...(questions[index].answered && { marginTop: '40px' })
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                    marginTop: '-8px',
                    marginBottom: 3
                  }}
                >
                  {questions[index].editable && !questions[index].answered && (
                    <IconButton
                      data-testid={`edit-question-button-${questions[index].title}`}
                      onClick={() => handleEditQuestion()}
                    >
                      <EditIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                  {questions[index].editable && (
                    <IconButton
                      data-testid={`clone-question-button-${questions[index].title}`}
                      onClick={() => handleCloneQuestion(questions[index].id)}
                    >
                      <ContentCopyIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                  {!questions[index].answered && (
                    <IconButton
                      data-testid={`delete-question-button-${questions[index].title}`}
                      onClick={() => {
                        handleDeleteQuestion(questions[index].id);
                      }}
                    >
                      <DeleteIcon sx={{ color: '#E37D7A', fontSize: 16 }} />
                    </IconButton>
                  )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FormTextField
                    value={tempWeight?.toString()}
                    onChange={(e) => handleUpdateActions(e, 'weight', parseInt(e.target.value))}
                    styles={{
                      width: '50px',
                      marginTop:
                        questions[index].answered && !questions[index].editable ? '-26px' : '-32px'
                    }}
                    draggable={!questions[index].editable}
                  />
                  <Stack sx={{ fontSize: '12px' }}>Weight</Stack>
                </Box>
              </Box>
            </Box>
            {questions[index].type === 'AvailabilityField' && (
              <AvailabilityFieldQuestion question={questions[index]} />
            )}
            {tempConfidential && (
              <Box sx={{ padding: '0 6px', color: '#838383', fontSize: '12px' }}>
                <span style={{ fontWeight: 'bold' }}>Confidential Setting Enabled:</span> For DE&I
                compliance, only authorised users (HR Admins), can view responses. Hiring Managers
                will not have access.
              </Box>
            )}
          </Box>
        )}
      </Box>
      <GenericDialog
        url={dialogProps.url}
        title={dialogProps.title}
        description={dialogProps.description}
        buttonText={dialogProps.buttonText}
        buttonCallback={dialogProps.buttonCallback}
        callbackLoading={isDeleting || isCloning}
        isDialogOpen={isDialogOpen}
        setDialogOpen={setIsDialogOpen}
        dialogId={dialogProps.dialogId}
      />
    </Box>
  );
}
