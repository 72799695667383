"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { URL } from "./SystemReports/config";
export default class ReportApiActions {
  constructor(accessibleEntityIds, headers, reportServiceUrl) {
    this.accessibleEntityIds = [];
    this.headers = null;
    this.reportServiceUrl = "";
    this.accessibleEntityIds = accessibleEntityIds;
    this.headers = headers;
    this.reportServiceUrl = reportServiceUrl;
  }
  getPreview(params) {
    return fetch(`${this.reportServiceUrl}/reports/preview`, {
      headers: this.headers,
      method: "POST",
      body: JSON.stringify(params)
    }).then((response) => __async(this, null, function* () {
      return yield response.json();
    }));
  }
  getHistories(body) {
    return fetch(`${this.reportServiceUrl}/saved_reports/fetch`, {
      headers: this.headers,
      method: "POST",
      body: JSON.stringify({ saved_report: __spreadProps(__spreadValues({}, body), { entity_id: this.accessibleEntityIds }) })
    }).then((response) => __async(this, null, function* () {
      return yield response.json();
    }));
  }
  saveReport(body) {
    return fetch(`${this.reportServiceUrl}/saved_reports`, {
      headers: this.headers,
      method: "POST",
      body: JSON.stringify(body)
    }).then((response) => __async(this, null, function* () {
      return yield response.json();
    }));
  }
  getCustomReports(body) {
    return fetch(`${this.reportServiceUrl}/saved_reports/fetch`, {
      headers: this.headers,
      method: "POST",
      body: JSON.stringify({
        saved_report: {
          entity_id: body.entity_id,
          user: body.user,
          q: body.q,
          report_type: ["custom"]
        },
        page: body.page,
        limit: body.limit
      })
    }).then((response) => __async(this, null, function* () {
      return yield response.json();
    }));
  }
  getSavedReports(body) {
    return fetch(`${this.reportServiceUrl}/saved_reports/fetch`, {
      headers: this.headers,
      method: "POST",
      body: JSON.stringify({ saved_report: __spreadProps(__spreadValues({}, body), { favorited: true }) })
    }).then((response) => __async(this, null, function* () {
      return yield response.json();
    }));
  }
  updateReport(reportId, body) {
    return fetch(`${this.reportServiceUrl}/saved_reports/${reportId}`, {
      headers: this.headers,
      method: "PUT",
      body: JSON.stringify(body)
    }).then((response) => __async(this, null, function* () {
      return yield response.json();
    }));
  }
  trashReport(reportId) {
    return fetch(`${this.reportServiceUrl}/saved_reports/${reportId}`, {
      headers: this.headers,
      method: "DELETE"
    }).then((response) => __async(this, null, function* () {
      return yield response == null ? void 0 : response.ok;
    }));
  }
  downloadReport(body) {
    return fetch(`${this.reportServiceUrl}/reports/download`, {
      headers: __spreadValues({
        Accept: "text/csv"
      }, this.headers),
      body: JSON.stringify(body),
      method: "POST"
    }).then((response) => __async(this, null, function* () {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.blob();
    }));
  }
  getSchemas() {
    return fetch(`${this.reportServiceUrl}/reports/schemas`, {
      headers: this.headers,
      method: "GET"
    }).then((response) => __async(this, null, function* () {
      return yield response.json();
    }));
  }
  getDropdownOptionsData(report, entity_id) {
    return fetch(`${this.reportServiceUrl}/system_reports/${URL[report]}/dropdown_options`, {
      headers: this.headers,
      method: "POST",
      body: JSON.stringify({ entity_id: entity_id || this.accessibleEntityIds })
    }).then((response) => __async(this, null, function* () {
      return yield response.json();
    }));
  }
  getDashboardData(type, includeSub) {
    return fetch(`${this.reportServiceUrl}/dashboard/${type}`, {
      headers: this.headers,
      method: "POST",
      body: JSON.stringify({
        dashboard: {
          entity_id: includeSub ? this.accessibleEntityIds : [this.accessibleEntityIds[0]]
        }
      })
    }).then((response) => __async(this, null, function* () {
      return yield response.json();
    }));
  }
  getData(params, report, groupBy, action) {
    return fetch(
      `${this.reportServiceUrl}/system_reports/${URL[report]}${groupBy}/${action || "download"}`,
      {
        headers: this.headers,
        method: "POST",
        body: JSON.stringify(__spreadProps(__spreadValues({}, params), { entity_id: params.entity_id || this.accessibleEntityIds }))
      }
    ).then((response) => __async(this, null, function* () {
      return (yield action) ? response.json() : response.blob();
    }));
  }
  getDataVizData(chartPath, params) {
    return fetch(`${this.reportServiceUrl}/reports/charts/${chartPath}`, {
      headers: this.headers,
      method: "POST",
      body: JSON.stringify(params)
    }).then((response) => __async(this, null, function* () {
      return yield response.json();
    }));
  }
}
