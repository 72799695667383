import React, { useState, useEffect, useCallback } from 'react';
import { Box, Skeleton, Button, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ImmediateChangesNotice from '../shared/ImmediateChangesNotice';
import FormSelectField from '../../../Components/CustomUIElements/FormSelectField';
import { classes } from '../styles';
import { IStatusTransitions, IStatusTransitionsProps } from '../../types';
import { IApplicationStatus } from '../../../Job/types';
import { FormNumberField } from '../../../Components/CustomUIElements/FormTextField';
import { useDebouncedCallback } from 'use-debounce';

export default function TimeBased({ apiKey, jobId, setSnackbar }: IStatusTransitionsProps) {
  const [loading, setLoading] = useState({ statusTransitions: true, applicationStatuses: true });
  const [applicationStatuses, setApplicationStatuses] = useState<IApplicationStatus[]>([]);
  const [statusTransitions, setStatusTransitions] = useState<IStatusTransitions[]>([]);
  const [numberError, setNumberError] = useState<boolean[]>([]);

  const getApplicationStatuses = useCallback(async () => {
    setLoading((prev) => ({ ...prev, applicationStatuses: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/job_application_statuses`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { job_application_statuses } = await response.json();
      setApplicationStatuses(job_application_statuses);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching status transitions',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, applicationStatuses: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const getStatusTransitions = useCallback(async () => {
    setLoading((prev) => ({ ...prev, statusTransitions: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/status_transitions`, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const { status_transitions } = await response.json();
      setStatusTransitions(status_transitions);
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error fetching status transitions',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, statusTransitions: false }));
    }
  }, [apiKey, jobId, setSnackbar]);

  const handleChange = useCallback(
    async (value, index, type, statusTransition) => {
      try {
        const response = await fetch(
          `/api/v4/jobs/${jobId}/status_transitions/${statusTransition.id}`,
          {
            method: 'PUT',
            headers: {
              'X-api-authenticate': apiKey,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              status_transition: {
                ...statusTransition,
                [type]: value
              }
            })
          }
        );
        if (!response.ok) {
          const body = await response.json();
          throw new Error(body.errors || 'Error updating auto status');
        }
        setStatusTransitions((prev) => {
          const newStatusTransitions = [...prev];
          newStatusTransitions[index][type as keyof typeof newStatusTransitions[0]] = value;
          return newStatusTransitions;
        });
      } catch (error) {
        setSnackbar({
          message: error.message || 'Error updating auto status',
          state: 'error'
        });
      }
    },
    [apiKey, jobId, setSnackbar]
  );

  const handleNewStatusTransition = useCallback(async () => {
    setLoading((prev) => ({ ...prev, statusTransitions: true }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/status_transitions`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          from_status_id: 0,
          to_status_id: 0,
          days: 10
        })
      });
      const data = await response.json();
      if (response.ok) {
        setStatusTransitions((prev) => [...prev, data]);
      } else {
        throw new Error('Error creating new auto status');
      }
    } catch (error) {
      setSnackbar({
        message: error.message || 'Error creating new auto status',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, statusTransitions: false }));
    }
  }, []);

  const handleDeleteStatusTransition = useCallback(async (index, id) => {
    setLoading((prev) => ({ ...prev, delete: index }));
    try {
      const response = await fetch(`/api/v4/jobs/${jobId}/status_transitions/${id}`, {
        method: 'DELETE',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        setStatusTransitions((prev) => {
          const newStatusTransitions = [...prev];
          newStatusTransitions.splice(index, 1);
          return newStatusTransitions;
        });
      } else {
        throw new Error('Error deleting status transition');
      }
    } catch (error) {
      setSnackbar({
        message: error?.message || 'Error deleting status transition',
        state: 'error'
      });
    } finally {
      setLoading((prev) => ({ ...prev, delete: -1 }));
    }
  }, []);

  const debouncedNumberInputCallback = useDebouncedCallback((e, index, statusTransition) => {
    if (Number(e.target.value) > 0) {
      handleChange(e.target.value, index, 'days', statusTransition);
    } else {
      setSnackbar({
        message: 'Days must be a valid number greater than 0',
        state: 'error'
      });
    }
  }, 500);

  useEffect(() => {
    getApplicationStatuses();
    getStatusTransitions();
  }, [getApplicationStatuses, getStatusTransitions]);

  return (
    <Box sx={classes.automationContainer}>
      <h2 data-testid="status-transition-header">Status transition</h2>
      <ImmediateChangesNotice />
      {!loading.applicationStatuses && !loading.statusTransitions ? (
        <Box sx={classes.autoStatusesContainer}>
          <Box sx={{ position: 'relative' }}>
            {statusTransitions?.map((statusTransition, index) => (
              <Box sx={classes.autoStatusesSubContainer} key={statusTransition.id}>
                <Box sx={classes.statusFormLine}>
                  <FormSelectField
                    label="From status"
                    options={applicationStatuses.map((status) => ({
                      value: status.id.toString(),
                      label: status.name
                    }))}
                    defaultValue={
                      statusTransition.from_status_id
                        ? statusTransition.from_status_id.toString()
                        : ''
                    }
                    onChange={(value) =>
                      handleChange(value, index, 'from_status_id', statusTransition)
                    }
                    styles={{ width: '290px' }}
                  />
                  <Box component={'h4'}>to</Box>
                  <FormSelectField
                    label="To status"
                    options={applicationStatuses.map((status) => ({
                      value: status.id.toString(),
                      label: status.name
                    }))}
                    defaultValue={
                      statusTransition.to_status_id ? statusTransition.to_status_id.toString() : ''
                    }
                    onChange={(value) =>
                      handleChange(value, index, 'to_status_id', statusTransition)
                    }
                    styles={{ width: '290px' }}
                  />
                  <Box component={'h4'}>after</Box>
                </Box>
                <Box sx={classes.statusFormLine}>
                  <FormNumberField
                    label=" "
                    value={statusTransition.days}
                    error={numberError[index] ? 'Days must be greater than 0' : ''}
                    onChange={(e) => {
                      setStatusTransitions((prev) => {
                        const newStatusTransitions = [...prev];
                        newStatusTransitions[index].days = e.target.value;
                        return newStatusTransitions;
                      });
                      if (Number(e.target.value) > 0) {
                        debouncedNumberInputCallback(e, index, statusTransition);
                        setNumberError((prev) => {
                          const newNumberError = [...prev];
                          newNumberError[index] = false;
                          return newNumberError;
                        });
                      } else {
                        setNumberError((prev) => {
                          const newNumberError = [...prev];
                          newNumberError[index] = true;
                          return newNumberError;
                        });
                      }
                    }}
                    styles={{ width: '190px' }}
                  />
                  <Box component={'h4'}>days</Box>
                </Box>
                <IconButton
                  data-testid="delete-status-transition-button"
                  sx={classes.deleteButton}
                  onClick={() => handleDeleteStatusTransition(index, statusTransition.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            {statusTransitions && statusTransitions.length > 0 ? (
              <IconButton sx={classes.addNewStatusButton} onClick={handleNewStatusTransition}>
                <AddCircleIcon />
              </IconButton>
            ) : (
              <Button
                data-testid="add-new-transition-button"
                startIcon={<AddCircleIcon />}
                onClick={handleNewStatusTransition}
                sx={classes.noStatusesAddButton}
              >
                Add new transition
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={450}
          width={'100%'}
          sx={{ borderRadius: '8px' }}
        />
      )}
    </Box>
  );
}
