var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect } from "react";
import useStyles from "./useStyles";
import sharedStyles from "../../AdList/useStyles";
import PropTypes from "prop-types";
import { Button, IconButton, Snackbar, Tooltip } from "@material-ui/core";
import moment from "moment";
import axios from "axios";
import GenericDialog from "../GenericDialog";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import ArchiveIcon from "@material-ui/icons/Archive";
function AdTable({ jobAds, ads, setAds }) {
  const [token, setToken] = useState(null);
  const [dialogUrl, setDialogUrl] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDescription, setDialogDescription] = useState("");
  const [dialogButtonText, setDialogButtonText] = useState("");
  const [dialogButtonCallback, setDialogButtonCallback] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  function handleSnack(message) {
    setIsSnackOpen(true);
    setSnackMessage(message);
  }
  useEffect(() => {
    setToken(document.querySelector('meta[name="csrf-token"]').content);
  }, []);
  function handleButtonStyle(ad) {
    switch (ad.status) {
      case "open":
        return {
          text: "Expire Ad",
          class: sharedClasses.buttonRedLight,
          callback: () => {
            setDialogUrl(ad.indeed_urls.expire);
            setDialogTitle("Are you sure you want to expire this ad?");
            setDialogDescription("This will remove it from Indeed immediately. To repost this ad later you will be charged for a new ad.");
            setDialogButtonText("Expire now");
            setDialogButtonCallback(() => handleExpire);
            setDialogOpen(true);
          }
        };
      case "new":
        return {
          text: "Continue Draft",
          class: sharedClasses.buttonGreenLight,
          callback: () => handleEdit(ad.indeed_urls.edit)
        };
      default:
        return {
          text: "Clone Ad",
          class: sharedClasses.buttonSecondaryLight,
          callback: () => {
            setDialogUrl(ad.indeed_urls.copy);
            setDialogTitle("Are you sure you want to clone this ad?");
            setDialogDescription("You will have to repost for your ad to be active on Indeed.");
            setDialogButtonText("Clone ad");
            setDialogButtonCallback(() => handleCopy);
            setDialogOpen(true);
          }
        };
    }
  }
  function handleEdit(url) {
    return __async(this, null, function* () {
      window.location = url;
    });
  }
  function handleExpire(url) {
    return __async(this, null, function* () {
      setDialogLoading(true);
      try {
        const { data } = yield axios.put(`${url}`, {
          authenticity_token: token
        });
        handleSnack(`${data.title} has been expired`);
        const newAds = [...jobAds];
        newAds[newAds.findIndex((ad) => ad.id === data.id)].status = "expired";
        setAds(newAds);
      } catch (e) {
        console.log(e);
        handleSnack("Could not expire ad");
      } finally {
        setDialogLoading(false);
        setDialogUrl(null);
        setDialogOpen(false);
      }
    });
  }
  function handleArchive(url) {
    return __async(this, null, function* () {
      setDialogLoading(true);
      try {
        const { data } = yield axios.put(`${url}`, {
          authenticity_token: token
        });
        const newAds = [...jobAds];
        newAds[newAds.findIndex((ad) => ad.id === data.id)].status = "archived";
        setAds(newAds);
        handleSnack(`${data.title} has been archived`);
      } catch (e) {
        console.log(e);
        handleSnack("Could not archive ad");
      } finally {
        setDialogLoading(false);
        setDialogUrl(null);
        setDialogOpen(false);
      }
    });
  }
  function handleDelete(url) {
    return __async(this, null, function* () {
      setDialogLoading(true);
      try {
        const { data } = yield axios.delete(`${url}`, {
          data: {
            authenticity_token: token
          }
        });
        handleSnack(`${data.title} has been deleted`);
        setAds(jobAds.filter((ad) => ad.id !== data.id));
      } catch (e) {
        console.log(e);
        handleSnack("Could not delete ad");
      } finally {
        setDialogLoading(false);
        setDialogUrl(null);
        setDialogOpen(false);
      }
    });
  }
  function handleCopy(url) {
    return __async(this, null, function* () {
      setDialogLoading(true);
      try {
        const { data } = yield axios.get(`${url}?operation=clone`);
        window.location = data.url;
      } catch (e) {
        console.log(e);
        handleSnack("Could not clone ad");
      } finally {
        setDialogLoading(false);
        setDialogUrl(null);
        setDialogOpen(false);
      }
    });
  }
  return /* @__PURE__ */ React.createElement("div", { className: classes.table }, /* @__PURE__ */ React.createElement("div", { className: `${classes.row} ${classes.rowHeader}` }, /* @__PURE__ */ React.createElement("span", null, "Job"), /* @__PURE__ */ React.createElement("span", null, "Creation Date"), /* @__PURE__ */ React.createElement("span", null, "Created By"), /* @__PURE__ */ React.createElement("span", null, "Expiry"), /* @__PURE__ */ React.createElement("span", { className: classes.center }, "Active on Indeed"), /* @__PURE__ */ React.createElement("span", { className: classes.center }, "Sponsored"), /* @__PURE__ */ React.createElement("span", null), /* @__PURE__ */ React.createElement("span", null)), ads.length > 0 ? ads.map(
    (ad) => /* @__PURE__ */ React.createElement("div", { key: ad.id, className: classes.row }, /* @__PURE__ */ React.createElement("div", { className: classes.title }, ad.title), /* @__PURE__ */ React.createElement("div", null, moment(ad.created_at).format("D MMM YYYY")), /* @__PURE__ */ React.createElement("div", null, ad.created_by), /* @__PURE__ */ React.createElement("div", null, ad.expiry ? moment(ad.expiry).format("D MMM YYYY") : "No expiry"), /* @__PURE__ */ React.createElement("div", { className: `${classes.icon} ${classes.center}` }, ad.status === "open" ? /* @__PURE__ */ React.createElement(CheckCircleIcon, { className: classes.iconGreen }) : /* @__PURE__ */ React.createElement(CancelIcon, { className: classes.iconRed })), /* @__PURE__ */ React.createElement("div", { className: `${classes.icon} ${classes.center}` }, ad.sponsored ? /* @__PURE__ */ React.createElement(CheckCircleIcon, { className: classes.iconGreen }) : /* @__PURE__ */ React.createElement(
      "div",
      {
        onClick: () => window.location = `/admin/indeed/job_ads/${ad.id}/sponsored_job/edit/`,
        className: classes.sponsorText
      },
      "Sponsor Ad"
    )), /* @__PURE__ */ React.createElement("div", { className: classes.center }, /* @__PURE__ */ React.createElement(
      Button,
      {
        style: { minWidth: 150, padding: "6px 16px" },
        disableElevation: true,
        size: "large",
        classes: { root: handleButtonStyle(ad).class },
        onClick: handleButtonStyle(ad).callback
      },
      handleButtonStyle(ad).text
    )), /* @__PURE__ */ React.createElement("div", { className: classes.actionsContainer }, /* @__PURE__ */ React.createElement(Tooltip, { title: "Edit" }, /* @__PURE__ */ React.createElement(
      IconButton,
      {
        className: `${["expired", "archived"].includes(ad.status) ? sharedClasses.disableIcon : ""}`,
        onClick: () => handleEdit(ad.indeed_urls.edit)
      },
      /* @__PURE__ */ React.createElement(EditIcon, { fontSize: "small", className: classes.iconGrey })
    )), /* @__PURE__ */ React.createElement(Tooltip, { title: "Clone" }, /* @__PURE__ */ React.createElement(
      IconButton,
      {
        className: `${["expired", "archived"].includes(ad.status) ? sharedClasses.disableIcon : ""}`,
        onClick: () => {
          setDialogUrl(ad.indeed_urls.copy);
          setDialogTitle("Are you sure you want to clone this ad?");
          setDialogDescription("You will have to repost for your ad to be active on Indeed.");
          setDialogButtonText("Clone ad");
          setDialogButtonCallback(() => handleCopy);
          setDialogOpen(true);
        }
      },
      /* @__PURE__ */ React.createElement(FileCopyIcon, { className: classes.iconGrey })
    )), ad.status === "expired" ? /* @__PURE__ */ React.createElement(Tooltip, { title: "Archive" }, /* @__PURE__ */ React.createElement(IconButton, null, /* @__PURE__ */ React.createElement(
      ArchiveIcon,
      {
        className: classes.iconRed,
        onClick: () => {
          setDialogUrl(ad.indeed_urls.archive);
          setDialogTitle("Are you sure you want to archive this ad?");
          setDialogDescription(null);
          setDialogButtonText("Archive now");
          setDialogButtonCallback(() => handleArchive);
          setDialogOpen(true);
        }
      }
    ))) : /* @__PURE__ */ React.createElement(Tooltip, { title: "Delete" }, /* @__PURE__ */ React.createElement(
      IconButton,
      {
        disabled: ad.status === "open",
        className: `${["open", "archived"].includes(ad.status) ? sharedClasses.disableIcon : ""}`,
        onClick: () => {
          setDialogUrl(ad.indeed_urls.delete);
          setDialogTitle("Are you sure you want to delete this ad?");
          setDialogDescription("Your job ad will be permanently deleted and you will not be able to recover your ad details.");
          setDialogButtonText("Delete ad");
          setDialogButtonCallback(() => handleDelete);
          setDialogOpen(true);
        }
      },
      /* @__PURE__ */ React.createElement(DeleteIcon, { className: classes.iconRed })
    ))))
  ) : /* @__PURE__ */ React.createElement("div", { className: classes.row }, /* @__PURE__ */ React.createElement("div", { className: classes.title }, "No ads to display")), /* @__PURE__ */ React.createElement(
    GenericDialog,
    {
      url: dialogUrl,
      title: dialogTitle,
      description: dialogDescription,
      buttonText: dialogButtonText,
      buttonCallback: dialogButtonCallback,
      callbackLoading: dialogLoading,
      isDialogOpen: dialogOpen,
      setDialogOpen
    }
  ), /* @__PURE__ */ React.createElement(
    Snackbar,
    {
      open: isSnackOpen,
      onClose: () => setIsSnackOpen(false),
      message: snackMessage
    }
  ));
}
AdTable.propTypes = {
  jobAds: PropTypes.array.isRequired,
  ads: PropTypes.array.isRequired,
  setAds: PropTypes.func.isRequired
};
export default AdTable;
