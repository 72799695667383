import React from 'react';

export default function UnsubscribedSVG() {
return (<svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
<g id="Group_581" data-name="Group 581" transform="translate(-1379 -710)">
  <rect id="Rectangle_678" data-name="Rectangle 678" width="53" height="53" rx="16" transform="translate(1379 710)" fill="#edf2f5"/>
  <g id="MDI_exclamation-thick" data-name="MDI / exclamation-thick" transform="translate(1393 723.114)">
    <g id="Boundary" transform="translate(0 0.886)" fill="#084d6d" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
      <rect width="25" height="25" stroke="none"/>
      <rect x="0.5" y="0.5" width="24" height="24" fill="none"/>
    </g>
    <path id="Path_exclamation-thick" data-name="Path / exclamation-thick" d="M10,3h4.3V14.838H10V3m0,19.371v-4.3h4.3v4.3Z" transform="translate(0.762 0.286)" fill="#084d6d"/>
  </g>
</g>
</svg>)
}