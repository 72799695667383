import React from 'react';
import renderHTML from 'react-render-html';
import { createBrowserHistory } from 'history';
import { Link } from "react-router-dom";
import ServiceApi from '../api.jsx';

const history = createBrowserHistory();
const flashDuration = 10000;
const flashMessage = "Thank you! Your request has been forwarded to our team and they will get back to you shortly. If you haven't heard anything in 24 hours, please contact 07 3330 2595";

export default class AdminIntegrationsInfoShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tabs: [], packs: [] };
  }

  componentDidMount () {
    this.unlisten = history.listen(this.getInfo);
    this.getInfo(window.location);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getInfo = (location) => {
    const id = location.hash.split('/').pop();

    if (location.hash !== `#/integrations/info/${id}`) {
      return;
    }

    ServiceApi.get(id, (response) => {
      const tabs = response.info_tabs;
      this.setState({
        tabs,
        title: response.title,
        logo: response.logo,
        packs: response.packs,
        ctaLabel: response.cta_label,
        targetUrl: response.target_url,
        showButton: response.show_button,
      })
    });
  }

  tabs = () => {
    const result = this.state.tabs.map(function (tab, idx) {
      const tabClass = ['nav-link'];
      let tabSelected = 'false';
      if (idx === 0) {
        tabClass.push('active');
        tabSelected = 'true';
      }
      return <a className={tabClass.join(' ')} id={`#tab-id-${tab.id}`} data-toggle="pill" href={`#tab-content-${tab.id}`} role="tab" aria-controls={`#tab-content-${tab.id}`} aria-selected={tabSelected} key={tab.id}>
        {tab.title}
      </a>;
    });
    return result;
  }

  tabPanes = () => {
    const result = this.state.tabs.map(function (tab, idx) {
      const tabPaneClass = ['tab-pane', 'fade'];
      if (idx === 0) {
        tabPaneClass.push('show');
        tabPaneClass.push('active');
      }

      return (
        <div className={tabPaneClass.join(' ')} id={`tab-content-${tab.id}`} role="tabpanel" aria-labelledby={`tab-id-${tab.id}`} key={tab.id}>
           {renderHTML(tab.body)}
        </div>
      );
    });
    return result;
  }

  sendNotification = () => {
    const id = location.hash.split('/').pop();
    const { currentPack } = this.state;

    this.setState({ sending: true });
    ServiceApi.sendNotification(id, currentPack, () => {
      this.setState({ sending: false, sent: true }, () => {
        window.setTimeout(() => {
          this.setState({ sent: false })
        }, flashDuration);
      });
    });
  }

  handlePackChange = ({ target }) => {
    this.setState({ currentPack: target.value });
  }

  priceList() {
    const result = this.state.packs.map(function (price, idx) {
      return <option value={price} key={idx}>{price}</option>
    });
    return result;
  }

  render() {
    const { title, logo, packs, ctaLabel, targetUrl, sending, sent, showButton } = this.state;
    const { enableButtons } = this.props;
    const priceList = this.priceList();

    return (
      <div className="layer-content">
        <div className="layer-container">

          <div className="layer-tabs">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link to="/admin" className="nav-link">
                  <i className="material-icons">chevron_left</i>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/integrations" className="nav-link active">
                  All Integrations
                </Link>
              </li>
            </ul>
          </div>
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="offset-sm-4 col-sm-8">
                  <div className="card-image"><img src={logo} /></div>
                  <div className="card-title">{title}</div>
                </div>
                <div className="col-sm-4">
                  <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                    {this.tabs()}
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="d-flex justify-content-end border-top border-bottom mb-2 pt-1 pb-1">
                    <div className={`${showButton ? 'mr-2' : 'w-100'} ${priceList.length === 0 ? 'd-none' : ''}`}>
                      <select className="form-control" name="" id="" onChange={this.handlePackChange}>
                        {priceList}
                      </select>
                    </div>
                    {
                      showButton && (
                        <div className="mr-0">
                          {
                            targetUrl && targetUrl.length > 0 ?
                              <a href={targetUrl} target="_blank" className={`btn filled-blue${enableButtons ? '' : ' disabled'}`} >{ctaLabel}</a> :
                              <button className="btn filled-blue" disabled={!enableButtons || sending || sent} onClick={this.sendNotification}>
                                {ctaLabel}
                              </button>
                          }
                        </div>
                      )
                    }
                  </div>
                  {
                    !showButton && (
                      <div className="text-center mb-4">
                        <strong>Contact your Account Executive</strong>
                      </div>
                    )
                  }
                  <div className="tab-content">
                    {sent && <div className='flash-msg'>{flashMessage}</div>}
                    {this.tabPanes()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
