import React, { Dispatch, useState } from 'react';
import { Stack, Autocomplete, TextField } from '@mui/material';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { IAssignableUsers } from '../../ApprovalForms/types';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import ModalFooterButtons from '../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../../Candidate/styles';
import { ApplicationAction, ApplicationState } from '../types';
import { IWorkflow } from '../../Candidate/types';
import { IApplication } from '../types';
import { ModalType } from '../config';
import { useGetAssignableUsers } from '../helper';

export default function TaskWorkflow({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const [assignedUser, setAssignedUser] = useState<IAssignableUsers | null>(null);
  const [workflow, setWorkflow] = useState<IWorkflow | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const { assignableUsers, loadingAssignableUsers } = useGetAssignableUsers({ dispatch });

  const handleClose = () => {
    dispatch({ type: 'SET_MODALS_OPEN', payload: null });
  };

  const { data: workflows, isLoading: loadingWorkflows } = useQuery({
    queryKey: ['workflows'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getWorkflows(application.id);
        return res;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting candidate data, ${error}`,
          state: 'error'
        }
      })
  });

  const { mutate: assignWorkflow, isLoading: assigningWorkflow } = useMutation({
    mutationFn: async () => {
      if (application && workflow && assignedUser) {
        return Api.assignWorkflow(application.id, { id: workflow.id, user_id: assignedUser.id });
      }
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Workflow has been successfully started.`, state: 'success' }
      });
      queryClient.invalidateQueries(['tasks'], { exact: true });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error starting the workflow, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const validateInputs = () => {
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!workflow) inputErrors.workflow = 'Workflow is a required field';
    if (!assignedUser) inputErrors.user = 'Assigned user is a required field';
    setErrors(inputErrors);
    return !Object.keys(inputErrors).length;
  };

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.TASK_WORKFLOW}
      label="Application task workflow"
      handleClose={handleClose}
      styleOverrides={styles.modalContainer}
      closeIconId="close-start-workflow-button"
    >
      <Stack sx={styles.modalContent}>
        <Stack sx={styles.modalTitle}>Start workflow</Stack>
        <Stack sx={styles.modalCandidateName}>
          {`Application #${application?.id} - ${application?.candidate.firstname} ${application?.candidate.lastname}`}
        </Stack>
        <Autocomplete
          disablePortal
          id="application-task-workflows-select"
          options={workflows?.workflows || []}
          getOptionLabel={(option) => option.name}
          value={workflow}
          sx={{ ...sharedClasses.formAutocomplete, paddingTop: 3, width: '35%' }}
          loading={loadingWorkflows}
          loadingText="Loading workflows..."
          ListboxProps={{ style: styles.listboxProps }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Workflow name"
              InputLabelProps={{ shrink: true }}
              sx={styles.autoCompleteTextfield}
              required
              error={!workflow}
              helperText={!workflow && errors.workflow}
            />
          )}
          onChange={(event, value) => setWorkflow(value)}
        />
        <Autocomplete
          disablePortal
          id="application-task-workflow-assigned-users-select"
          options={assignableUsers || []}
          getOptionLabel={(option: IAssignableUsers) => `${option?.firstname} ${option?.lastname}`}
          value={assignedUser}
          sx={{ ...sharedClasses.formAutocomplete, paddingTop: 3, width: '35%' }}
          loading={loadingAssignableUsers}
          loadingText="Loading assignable users..."
          ListboxProps={{ style: styles.listboxProps }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Assign to"
              InputLabelProps={{ shrink: true }}
              sx={styles.autoCompleteTextfield}
              required
              error={!assignedUser}
              helperText={!assignedUser && errors.user}
            />
          )}
          onChange={(event, value) => setAssignedUser(value)}
        />
        <ModalFooterButtons
          primaryButtonText="Submit"
          primaryButtonCallback={() => validateInputs() && assignWorkflow()}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          isLoading={assigningWorkflow}
          primaryButtonID="submit-workflow-button"
          secondaryButtonID="cancel-workflow-button"
          primaryButtonMinWidth="90px"
        />
      </Stack>
    </StyledModal>
  );
}
