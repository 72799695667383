import React, { useState, useEffect } from 'react';
import { IFilterProps, IFilters, IOptionType } from '../types';
import { classes } from './useStyles';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import SortIcon from '@mui/icons-material/Sort';
import CircularProgress from '@mui/material/CircularProgress';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DayPicker, { DateUtils } from 'react-day-picker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import moment from 'moment';
import MultiSelect from '../../Components/CustomUIElements/MultiSelect';
import Tooltip from '@mui/material/Tooltip';
import { addLocalStorageItem } from '../../utils/local-storage';
import { FILTERS_KEY } from '../../constants';

export default function FilterDropdown({
  filters,
  setFilters,
  options,
  setPage,
  numberOfFilters,
  loadingFilters,
  archived,
  jobStatusFeature,
  userPermissions
}: IFilterProps) {
  const [statusOptions, setStatusOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState({ published: true, expiry: false });
  const [publishedRange, setPublishedRange] = useState({ from: null, to: null });
  const [expiryRange, setExpiryRange] = useState({ from: null, to: null });
  const [tempFilters, setTempFilters] = useState({ ...filters });
  const [validDate, setValidDate] = useState({
    publishedStart: true,
    publishedEnd: true,
    expiryStart: true,
    expiryEnd: true
  });
  const defaultFilters: IFilters = {
    state: [],
    status: [],
    entity: [],
    location: [],
    assigned_user: [],
    recruitment_ad_user: [],
    published: [],
    expiry: []
  };
  const publishedModifiers = {
    start: publishedRange.from,
    end: publishedRange.to,
    first: { daysOfWeek: [0] },
    last: { daysOfWeek: [6] }
  };
  const expiryModifiers = {
    start: expiryRange.from,
    end: expiryRange.to,
    first: { daysOfWeek: [0] },
    last: { daysOfWeek: [6] }
  };

  const modifiersStyles = {
    first: {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%'
    },
    last: {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%'
    }
  };

  useEffect(() => {
    updateStatusTempFilter();
  }, [tempFilters.state]);

  function handlePublishedDayClick(day: Date) {
    let range = DateUtils.addDayToRange(day, publishedRange);
    if (range.from && range.to && range.from > range.to) range = { from: range.to, to: range.from };
    setPublishedRange(range);
    setTempFilters({
      ...tempFilters,
      published: [range.from ? range.from : null, range.to ? range.to : null]
    });
    if (range.from || range.to)
      setValidDate({ ...validDate, publishedStart: !!range.from, publishedEnd: !!range.to });
  }

  function handleExpiryDayClick(day: Date) {
    let range = DateUtils.addDayToRange(day, expiryRange);
    if (range.from && range.to && range.from > range.to) range = { from: range.to, to: range.from };
    setExpiryRange(range);
    setTempFilters({
      ...tempFilters,
      expiry: [range.from ? range.from : null, range.to ? range.to : null]
    });
    if (range.from || range.to)
      setValidDate({ ...validDate, expiryStart: !!range.from, expiryEnd: !!range.to });
  }

  function handleDateInput(day: Date, published: boolean, isStart: boolean) {
    if (day === null) {
      if (published) {
        setPublishedRange({
          from: isStart ? null : publishedRange.from,
          to: isStart ? publishedRange.to : null
        });
        setTempFilters({
          ...tempFilters,
          published: [isStart ? null : publishedRange.from, isStart ? publishedRange.to : null]
        });
      } else {
        setExpiryRange({
          from: isStart ? null : expiryRange.from,
          to: isStart ? expiryRange.to : null
        });
        setTempFilters({
          ...tempFilters,
          expiry: [isStart ? null : expiryRange.from, isStart ? expiryRange.to : null]
        });
      }
      setValidDate({
        ...validDate,
        [`${published ? 'published' : 'expiry'}${isStart ? 'Start' : 'End'}`]: true
      });
      return;
    }
    day = new Date(day);
    if (!(moment(day).isValid() && day > new Date('1980-01-01T00:00:00'))) {
      setValidDate({
        ...validDate,
        [`${published ? 'published' : 'expiry'}${isStart ? 'Start' : 'End'}`]: false
      });
      return;
    }
    if (published) {
      if (isStart) {
        const publishedEnd =
          publishedRange.to && day > publishedRange.to ? null : publishedRange.to;
        setPublishedRange({ from: day, to: publishedEnd });
        setTempFilters({ ...tempFilters, published: [day, publishedEnd] });
      } else {
        const publishedStart =
          publishedRange.from && day < publishedRange.from ? null : publishedRange.from;
        setPublishedRange({ from: publishedStart, to: day });
        setTempFilters({ ...tempFilters, published: [publishedStart, day] });
      }
    } else {
      if (isStart) {
        const expiryEnd = expiryRange.to && day > expiryRange.to ? null : expiryRange.to;
        setExpiryRange({ from: day, to: expiryEnd });
        setTempFilters({ ...tempFilters, expiry: [day, expiryEnd] });
      } else {
        const expiryStart = expiryRange.from && day < expiryRange.from ? null : expiryRange.from;
        setExpiryRange({ from: expiryStart, to: day });
        setTempFilters({ ...tempFilters, expiry: [expiryStart, day] });
      }
    }
    setValidDate({
      ...validDate,
      [`${published ? 'published' : 'expiry'}${isStart ? 'Start' : 'End'}`]: true
    });
  }

  function updateStatusSelectOptions(tempFilters: IFilters) {
    const states = tempFilters.state.map(function (s) {
      return s.value;
    });
    const statusSelectOptions = options.status.filter(function (status) {
      return states.includes(status.state);
    });
    states.length > 0 ? setStatusOptions(statusSelectOptions) : setStatusOptions(options.status);
  }

  function updateStatusTempFilter() {
    const states = tempFilters.state.map(function (s) {
      return s.value;
    });
    const newTemp = tempFilters.status.filter(function (status) {
      return states.includes(status.state);
    });
    setTempFilters({ ...tempFilters, status: newTemp });
  }

  const handleExpandChange = (filter: string) => (event: React.SyntheticEvent) => {
    setExpanded({ ...expanded, [filter]: !expanded[filter as keyof typeof expanded] });
  };

  const handleFilterChange = (filter: string, newValues: (string | IOptionType)[]) => {
    updateStatusSelectOptions({ ...tempFilters, [filter]: newValues });
    setTempFilters({ ...tempFilters, [filter]: newValues });
  };

  const handleCheckPinned = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempFilters({ ...tempFilters, pinned: event.target.checked });
  };

  const handleApplyFilters = () => {
    addLocalStorageItem(FILTERS_KEY, { ...tempFilters });
    setFilters({ ...tempFilters });
    setPage(1);
    setAnchorEl(null);
  };

  const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    addLocalStorageItem(FILTERS_KEY, { ...defaultFilters });
    setFilters(defaultFilters);
    setTempFilters(defaultFilters);
    setPublishedRange({ from: null, to: null });
    setExpiryRange({ from: null, to: null });
    setPage(1);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loadingFilters) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!open) return;
    setTempFilters({ ...filters });
  }, [filters, open]);

  return (
    <Tooltip title="Filter" placement="top" arrow>
      <Box>
        <Badge
          badgeContent={numberOfFilters}
          sx={{ '& .MuiBadge-badge': { backgroundColor: '#E37D7A', color: '#FFF' } }}
          data-testid={`filter-badge-[FilterDropdown]`}
        >
          <IconButton
            id={`filter-dropdown-${archived ? 'archived' : 'current'}-button`}
            data-testid={`filter-dropdown-${archived ? 'archived' : 'current'}-button`}
            sx={{
              ...classes.iconButton,
              backgroundColor: open
                ? classes.iconButtonOpen.backgroundColor
                : classes.iconButton.backgroundColor
            }}
            onClick={handleClick}
          >
            {loadingFilters ? <CircularProgress size={24} /> : <SortIcon />}
          </IconButton>
        </Badge>
        <Popover
          id="filter-dropdown-popover"
          sx={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box sx={classes.filterPopoverContent}>
            <Box sx={classes.scrollableFilters}>
              <Box sx={classes.filterPopoverHeader}>
                <Button
                  id="reset-filters-button"
                  data-testid="reset-filters-button-[FilterDropdown]"
                  variant="contained"
                  endIcon={<CancelIcon />}
                  sx={classes.clearButton}
                  onClick={handleReset}
                >
                  Clear all
                </Button>
                <Button
                  id="apply-filters-button"
                  data-testid="apply-filters-button-[FilterDropdown]"
                  variant="contained"
                  onClick={handleApplyFilters}
                  sx={classes.applyButton}
                >
                  Apply
                </Button>
              </Box>
              {userPermissions?.Jobs?.['Show Job Sub-Entity'] && (
                <Box
                  sx={classes.multiSelectContainer}
                  id="entity-filter"
                  data-testid="entity-filter"
                >
                  Entity
                  <MultiSelect
                    handleFilterChange={handleFilterChange}
                    selectOptions={options.entity}
                    name={'entity'}
                    filters={tempFilters.entity || filters.entity}
                  />
                </Box>
              )}
              <Box sx={classes.multiSelectContainer} id="state-filter" data-testid="state-filter">
                State
                <MultiSelect
                  handleFilterChange={handleFilterChange}
                  selectOptions={options.state}
                  name={'state'}
                  filters={tempFilters.state || filters.state}
                />
              </Box>
              {jobStatusFeature && (
                <Box
                  sx={classes.multiSelectContainer}
                  id="status-filter"
                  data-testid="status-filter"
                >
                  Status
                  <MultiSelect
                    handleFilterChange={handleFilterChange}
                    selectOptions={tempFilters.state.length > 0 ? statusOptions : options.status}
                    name={'status'}
                    filters={tempFilters.status || filters.status}
                  />
                </Box>
              )}
              <Box sx={classes.multiSelectContainer} id="location-filter">
                Location
                <MultiSelect
                  handleFilterChange={handleFilterChange}
                  selectOptions={options.location}
                  name={'location'}
                  filters={tempFilters.location || filters.location}
                />
              </Box>
              <Box sx={classes.multiSelectContainer} id="assigned-user-filter">
                Assigned User
                <MultiSelect
                  handleFilterChange={handleFilterChange}
                  selectOptions={options.assigned_user}
                  name={'assigned_user'}
                  filters={tempFilters.assigned_user || filters.assigned_user}
                />
              </Box>
              {userPermissions?.recruitment_team_enabled && (
                <Box sx={classes.multiSelectContainer} id="rm-user-filter">
                  RM User
                  <MultiSelect
                    handleFilterChange={handleFilterChange}
                    selectOptions={options.recruitment_ad_user}
                    name={'recruitment_ad_user'}
                    filters={tempFilters.recruitment_ad_user || filters.recruitment_ad_user}
                  />
                </Box>
              )}
              <Accordion
                expanded={expanded.published}
                onChange={handleExpandChange('published')}
                sx={classes.accordionRoot}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={classes.accordionSummary}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography sx={classes.summaryTypography}>Published Date</Typography>
                </AccordionSummary>
                <AccordionDetails sx={classes.accordionDetails}>
                  <Box
                    sx={{
                      ...sharedClasses.datePickerCalendar,
                      ...(publishedRange.from && publishedRange.to === null
                        ? sharedClasses.onlyStartDate
                        : {})
                    }}
                  >
                    <DayPicker
                      onDayClick={handlePublishedDayClick}
                      selectedDays={[publishedRange.from, publishedRange]}
                      modifiers={publishedModifiers}
                      modifiersStyles={modifiersStyles}
                    />
                  </Box>
                  <Box sx={classes.dateDisplay}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DesktopDatePicker
                        label=""
                        inputFormat="DD/MM/yyyy"
                        value={publishedRange.from}
                        onChange={(input) => handleDateInput(input, true, true)}
                        renderInput={(params: TextFieldProps) => (
                          <TextField
                            {...params}
                            sx={{
                              ...classes.dateInput,
                              '& .MuiOutlinedInput-root': validDate.publishedStart
                                ? classes.dateInput['& .MuiOutlinedInput-root']
                                : {
                                    ...classes.dateInput['& .MuiOutlinedInput-root'],
                                    ...classes.invalidDate['& .MuiOutlinedInput-root']
                                  }
                            }}
                          />
                        )}
                      />
                      <span>to</span>
                      <DesktopDatePicker
                        label=""
                        inputFormat="DD/MM/yyyy"
                        value={publishedRange.to}
                        onChange={(input) => handleDateInput(input, true, false)}
                        renderInput={(params: TextFieldProps) => (
                          <TextField
                            {...params}
                            sx={{
                              ...classes.dateInput,
                              '& .MuiOutlinedInput-root': validDate.publishedEnd
                                ? classes.dateInput['& .MuiOutlinedInput-root']
                                : {
                                    ...classes.dateInput['& .MuiOutlinedInput-root'],
                                    ...classes.invalidDate['& .MuiOutlinedInput-root']
                                  }
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded.expiry}
                onChange={handleExpandChange('expiry')}
                sx={classes.accordionRoot}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={classes.accordionSummary}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography sx={classes.summaryTypography}>Expiry Date</Typography>
                </AccordionSummary>
                <AccordionDetails sx={classes.accordionDetails}>
                  <Box
                    sx={{
                      ...classes.datePicker,
                      ...(expiryRange.from && expiryRange.to === null ? classes.onlyStartDate : {})
                    }}
                  >
                    <DayPicker
                      onDayClick={handleExpiryDayClick}
                      selectedDays={[expiryRange.from, expiryRange]}
                      modifiers={expiryModifiers}
                      modifiersStyles={modifiersStyles}
                    />
                  </Box>
                  <Box sx={classes.dateDisplay}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DesktopDatePicker
                        label=""
                        inputFormat="DD/MM/yyyy"
                        value={expiryRange.from}
                        onChange={(input) => handleDateInput(input, false, true)}
                        renderInput={(params: TextFieldProps) => (
                          <TextField
                            {...params}
                            sx={{
                              ...classes.dateInput,
                              '& .MuiOutlinedInput-root': validDate.expiryStart
                                ? classes.dateInput['& .MuiOutlinedInput-root']
                                : {
                                    ...classes.dateInput['& .MuiOutlinedInput-root'],
                                    ...classes.invalidDate['& .MuiOutlinedInput-root']
                                  }
                            }}
                          />
                        )}
                      />
                      <span>to</span>
                      <DesktopDatePicker
                        label=""
                        inputFormat="DD/MM/yyyy"
                        value={expiryRange.to}
                        onChange={(input) => handleDateInput(input, false, false)}
                        renderInput={(params: TextFieldProps) => (
                          <TextField
                            {...params}
                            sx={{
                              ...classes.dateInput,
                              '& .MuiOutlinedInput-root': validDate.expiryEnd
                                ? classes.dateInput['& .MuiOutlinedInput-root']
                                : {
                                    ...classes.dateInput['& .MuiOutlinedInput-root'],
                                    ...classes.invalidDate['& .MuiOutlinedInput-root']
                                  }
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Popover>
      </Box>
    </Tooltip>
  );
}
