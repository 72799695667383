import React, { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Api from '../../API';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import { classes } from './styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { StyledSnackbarProps } from '../../../Components/CustomUIElements/StyledSnackbar';

export default function RatingSet({
  apiKey,
  jobId,
  setSnackbarState,
  setActionsAnchorEl
}: {
  apiKey: string;
  jobId: number;
  setActionsAnchorEl: (value: null) => void;
  setSnackbarState: StyledSnackbarProps['setSnackBarState'];
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteBulk, setDeleteBulk] = useState(false);
  const [ratingOptions, setRatingOptions] = useState(null);
  const [raterOptions, setRaterOptions] = useState(null);
  const [raterName, setRaterName] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [deleteUserConfirm, setDeleteUserConfirm] = useState(null);

  const fetchRatingData = useCallback(async () => {
    setIsLoading(true)
    try {
      const [ratingSets, raters] = await Promise.all([
        Api.getRatingSets({ 'X-api-authenticate': apiKey }, jobId),
        Api.getRaters({ 'X-api-authenticate': apiKey }, jobId),
      ]);
      ratingSets?.data?.rating_sets && setRatingOptions(ratingSets?.data?.rating_sets);
      raters?.data && setRaterOptions(raters?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, jobId]);

  const deleteSet = async (id: number) => {
    try {
      await Api.deleteRatingSet({ 'X-api-authenticate': apiKey },
        jobId,
        id,
      );
      setSnackbarState({
        message:
          'Rating set has been removed',
        state: 'success'
      });
    } catch (error) {
      setSnackbarState({
        message: 'There was an error removing a rating set. Please try again.',
        state: 'error'
      });
    } finally {
      setRatingOptions(null);
      setIsDialogOpen(false);
      setActionsAnchorEl(null);
      setDeleteUserConfirm(null);
      setIsLoading(false);
    }
  };

  const deleteRaters = async () => {
    setIsLoading(true);
    if (!raterName) {
       setHasError(true);
       return;
    } else setHasError(false);
    try {
      await Api.deleteRaterSet({ 'X-api-authenticate': apiKey },
        jobId,
        raterName.id
      );
      setSnackbarState({
        message:
        `Rating sets have been removed from ${raterName.firstname} ${raterName.lastname}.`,
        state: 'success'
      });
      setRaterName(null);
      setDeleteBulk(false);
    } catch (error) {
      setSnackbarState({
        message: 'There was an error removing a workflow. Please try again.',
        state: 'error'
      });
    } finally {
      setRatingOptions(null);
      setActionsAnchorEl(null);
      setIsDialogOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box 
        onClick={() => {
          !ratingOptions && fetchRatingData();
          setIsDialogOpen(true);
        }}
        id="rating-sets-job-button"
      >
        Rating Sets
      </Box>
      <Modal 
        open={isDialogOpen} 
        aria-labelledby="modal-modal-title"
        onClose={() => {
          setIsDialogOpen(false);
          setDeleteBulk(false);
        }}
      >

        <Box sx={classes.modalContent}>
          <CloseIcon 
            onClick={() => {
              setIsDialogOpen(false);
              setDeleteBulk(false);
            }} 
            sx={classes.closeIcon}
          />
          <Box id="modal-modal-title" sx={classes.modalTitle}>Rating Sets</Box>
          <Box sx={{ margin: '10px 0 15px 0', display: 'grid', overflow: 'auto', minHeight: '300px' }}>
            {ratingOptions && ratingOptions?.length > 0
                ? <Box sx={{ marginTop: '1rem' }}>
                  {!isLoading
                    ? <Box sx={classes.modalFormLine}>
                        {deleteBulk 
                          ? <Box>
                              <Box sx={{ position: 'relative' }}>
                                <Autocomplete
                                  disablePortal
                                  options={raterOptions}
                                  getOptionLabel={(option) => `${option.firstname}  ${option.lastname}`}
                                  id="state-region-province"
                                  value={raterName}
                                  onChange={(event, value) => setRaterName(value)}
                                  sx={{ width: '290px', ...sharedClasses.formAutocomplete, marginTop: '35px'}}
                                  ListboxProps={{
                                    style: {
                                      fontFamily: 'Source Sans Pro, sans-serif',
                                      color: '#333333',
                                      maxHeight: '200px'
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Rater name"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {!raterName && hasError && (
                                <Box sx={{ ...sharedClasses.errorBox }}>
                                  This field is required
                                </Box>
                                )}
                              </Box>
                              {raterName 
                                ? <Box sx={{...classes.modalActions, margin: '15px 0 0px 3px'}}>
                                    <Button
                                      disableElevation
                                      sx={classes.buttonGreen}
                                      variant="text"
                                      onClick={() => { 
                                        setIsDialogOpen(false);
                                        setActionsAnchorEl(null);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button 
                                      sx={{...classes.revertloadButton, backgroundColor: '#D6827D33' }}
                                      onClick={() => deleteRaters()}
                                    >
                                      Delete 
                                    </Button>
                                  </Box>
                                : <span />
                              }
                            </Box>
                          : <Box sx={{ width: '100%' }}>
                              {deleteUserConfirm
                              ? <Box sx={{ color: '#939393', marginBottom: '1rem' }}>Are you sure you want to delete this rating</Box>
                              : <Button
                                  variant="outlined"
                                  startIcon={<DeleteIcon />}
                                  sx={{ ...classes.actionButton, ...classes.deleteApplicant }}
                                  onClick={() => setDeleteBulk(true)}
                                >
                                  Bulk delete rating set
                                </Button>
                              }
                              {deleteUserConfirm 
                                ? <Box sx={classes.tableHeader}>
                                    <Box sx={{ width: '45%' }}>Application</Box>
                                    <Box sx={{ width: '40%' }}>Rater</Box>
                                  </Box>
                                : <Box sx={classes.tableHeader}>
                                    <Box sx={{ width: '45%' }}>Application</Box>
                                    <Box sx={{ width: '40%' }}>Rater</Box>
                                    <Box>
                                      <Button
                                        disableElevation
                                        disabled
                                        sx={{ color: '#FFFFFF !Important' }}
                                        startIcon={<DeleteIcon />}
                                      />
                                    </Box>
                                  </Box>
                              }
                              {deleteUserConfirm
                                ? <Box>
                                    <Box sx={classes.tableRow}>
                                      <Box sx={{ width: '45%' }}>{deleteUserConfirm?.application_id} - {deleteUserConfirm?.candidate_name}</Box>
                                      <Box sx={{ width: '40%' }}>{deleteUserConfirm?.rater_name}</Box>
                                    </Box>
                                    <Box sx={{...classes.modalActions, marginTop: '1rem'}}>
                                      <Button
                                        disableElevation
                                        sx={classes.buttonGreen}
                                        variant="text"
                                        onClick={() => setDeleteUserConfirm(null)}
                                      >
                                        Cancel
                                      </Button>
                                      <Button 
                                        sx={{...classes.revertloadButton, backgroundColor: '#D6827D33' }}
                                        onClick={() => {deleteSet(deleteUserConfirm?.id)}}
                                      >
                                        Delete 
                                      </Button>
                                    </Box>
                                  </Box>
                                : <Box>
                                    {ratingOptions.map(sets =>
                                        <Box key={sets.id} sx={classes.tableRow}>
                                          <Box sx={{ width: '45%' }}>{sets.application_id} - {sets.candidate_name}</Box>
                                          <Box sx={{ width: '40%' }}>{sets.rater_name}</Box>
                                          <Box onClick={() => {
                                              setDeleteUserConfirm(sets);
                                              
                                            }}
                                          >
                                            <Button
                                              disableElevation
                                              sx={{ ...classes.buttonRed, width: '1rem' }}
                                              startIcon={<DeleteIcon />}
                                            />
                                          </Box>
                                        </Box>
                                      )
                                    }
                                  </Box>
                              }
                            </Box>
                        }
                      </Box>
                    : <CircularProgress size={20} color="inherit" />
                  }
                </Box>
              : <Box>
                  {isLoading
                    ? <CircularProgress size={20} color="inherit" />
                    : <Box sx={classes.noWorkflows}>No Rating sets have been created</Box>
                  }
                </Box>
              }
            </Box>
          </Box>
      </Modal>
    </Box>
  );
}
