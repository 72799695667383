import React from "react";
import PropTypes from "prop-types";
import { withScoutTheme } from "../ThemeContext/ThemeContext";
import AdEditor from "./AdEditor";
function SeekAd({
  jobAdId,
  jobId,
  apiKey,
  linkOutEnabled,
  privacyOverLimit,
  seek_billing_reference,
  seek_billing_reference_flag,
  job_url_prop
}) {
  return (
    // <div>Seek Ad: Job ID: {jobId}. Ad ID: {jobAdId} </div>
    /* @__PURE__ */ React.createElement(
      AdEditor,
      {
        jobAdId,
        apiKey,
        jobId,
        linkOutEnabled,
        privacyOverLimit,
        seek_billing_reference,
        seek_billing_reference_flag,
        job_url_prop
      }
    )
  );
}
export default withScoutTheme(SeekAd);
SeekAd.propTypes = {
  jobAdId: PropTypes.number.isRequired,
  jobId: PropTypes.number.isRequired,
  apiKey: PropTypes.string.isRequired,
  linkOutEnabled: PropTypes.bool.isRequired,
  privacyOverLimit: PropTypes.bool,
  seek_billing_reference: PropTypes.bool,
  seek_billing_reference_flag: PropTypes.bool,
  job_url_prop: PropTypes.string
};
