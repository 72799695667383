import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  table: {
    display: "table",
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    background: "#FFF",
    borderRadius: 8
  },
  rowHeader: {
    "& span": {
      fontSize: 14,
      fontWeight: "600",
      color: theme.palette.primary.main
    }
  },
  row: {
    display: "table-row-group",
    width: "100%",
    borderBottom: "1px solid #f3f3f3",
    "& > *": {
      padding: 24,
      paddingRight: 0,
      display: "table-cell",
      fontSize: 14,
      "&:first-child": {
        paddingLeft: 44
      },
      "&:last-child": {
        paddingRight: 44
      }
    }
  },
  actionsContainer: {
    whiteSpace: "nowrap",
    textAlign: "right"
  },
  title: {
    color: "#666666",
    fontWeight: "600"
  },
  center: {
    textAlign: "center"
  },
  iconGreen: {
    color: theme.palette.success.main
  },
  iconRed: {
    color: theme.palette.error.main
  },
  iconGrey: {
    color: "#666666"
  },
  sponsorText: {
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  }
}));
