import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  Divider,
  InputAdornment,
  Link,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Circle as CircleIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  DriveFileMove as DriveFileMoveIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  LinkedIn as LinkedInIcon,
  LocationOn as LocationOnIcon,
  Man4 as Man4Icon,
  MoreVert as MoreVertIcon,
  Person as PersonIcon,
  Phone as PhoneIcon
} from '@mui/icons-material';
import { styles } from './styles';
import { sharedClasses } from '../Components/CustomUIElements/sharedClasses';
import { FormTextField } from '../Components/CustomUIElements/FormTextField';
import MoveApplicationModal from './Modals/MoveApplicationModal';
import { ApplicationAction, ApplicationState, IApplication } from './types';
import { LINKEDIN_URL_REGEX, ModalType } from './config';
import { IApplicationStatus } from '../Job/types';
import Api from './API';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import MoreOptionsPopover from './MoreOptionsPopover';
import GenericDialog from '../Components/Modals/GenericDialog';
import { DeleteApplication } from './Modals/DeleteApplication';
import SearchIndex from './SearchIndex';
import { formatAddress } from '../Candidate/ProfileTab';
import { IUserPermissions } from '../Components/sharedTypes';
import { theme } from '../../ThemeContext/ThemeObject';
import ApplicationBetaButton from './ApplicationBetaButton';

const BASE_URL = window.location.origin;

export default function ApplicationHeader({
  ApplicationState,
  dispatch,
  showLinkedInURL
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  showLinkedInURL: boolean;
}) {
  const { modalsOpen } = ApplicationState;
  const [applicationStatus, setApplicationStatus] = useState<IApplicationStatus | null>(null);
  const [linkedInURL, setLinkedInURL] = useState<string>('');
  const [editLinkedInURL, setEditLinkedInURL] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);
  const closeModal = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  const { data: applicationStatuses, isLoading: loadingApplicationStatuses } = useQuery({
    queryKey: ['application statuses'],
    queryFn: async () => {
      if (application?.job) {
        const { res } = await Api.getApplicationStatuses(application.job.id);
        return res.job_application_statuses as IApplicationStatus[];
      }
    },
    onSuccess: (res) => {
      if (res) {
        const savedStatus = res?.find((s) => s.id === application?.status_details?.id);
        savedStatus ? setApplicationStatus(savedStatus) : setApplicationStatus(res[0]);
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting application statuses, ${error}`,
          state: 'error'
        }
      }),
    initialData: [],
    enabled: !!application
  });

  const { mutate: trashApplication, isLoading: trashingApplication } = useMutation({
    mutationFn: async () => {
      if (application?.job) {
        await Api.trashApplication(application.job.id, application.id);
      }
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: 'Application trashed', state: 'success' }
      });
      if (application?.job) {
        window.location.href = `${window.location.origin}/admin/jobs/${application.job.id}?applications=true`;
      }
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error trashing application, ${error.error}`,
          state: 'error'
        }
      });
    },
    onSettled: () => closeModal()
  });

  const { mutate: resendApplication, isLoading: resendingApplication } = useMutation({
    mutationFn: async () => {
      if (application?.job) {
        const response = await Api.resendApplication(application.job.id, application.id);
        return response;
      }
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.res.message, state: 'success' }
      });
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error resending application, ${error.error}`,
          state: 'error'
        }
      });
    },
    onSettled: () => closeModal()
  });

  const { mutate: updateApplicationStatus, isLoading: updatingApplicationStatus } = useMutation({
    mutationFn: async () => {
      if (application?.job && applicationStatus) {
        Api.updateApplicationStatus(application.job.id, application.id, applicationStatus.id);
      }
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Application status has been successfully updated`, state: 'success' }
      });
      queryClient.invalidateQueries(['application'], { exact: true });
    },
    onError: (error: { error: string }) => {
      const savedStatus = applicationStatuses?.find(
        (s) => s.id === application?.status_details?.id
      );
      savedStatus && setApplicationStatus(savedStatus);
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error updating application status, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const { mutate: updateLinkedInURL, isLoading: updatingLinkedInURL } = useMutation({
    mutationFn: async () =>
      application?.candidate &&
      Api.updateCandidateLinkedInURL(application.candidate.id, linkedInURL),
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Candidate linkedIn url has been successfully updated`,
          state: 'success'
        }
      });
      application?.candidate &&
        queryClient.setQueryData(['application'], {
          ...application,
          candidate: { ...application.candidate, linkedin_url: linkedInURL }
        });
      queryClient.invalidateQueries(['application'], { exact: true });
      setEditLinkedInURL(false);
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error updating candidate linkedIn url, ${error.error}`,
          state: 'error'
        }
      });
    }
  });
  const modalState = useMemo(() => {
    switch (modalsOpen) {
      case ModalType.TRASH:
        return {
          modalDesc: () => (
            <Stack>
              <Stack>Are you sure you want to move this</Stack>
              <Stack>application to Trash?</Stack>
            </Stack>
          ),
          modalTitle: 'Trash application',
          buttonText: 'Trash',
          callback: trashApplication,
          callbackLoading: trashingApplication,
          alternateColor: false
        };
      case ModalType.RESEND:
        return {
          modalDesc: () => (
            <Stack>
              <Stack>Are you sure you want to resend</Stack>
              <Stack>application to application recipient?</Stack>
            </Stack>
          ),
          modalTitle: 'Resend application',
          buttonText: 'Resend',
          callback: resendApplication,
          callbackLoading: resendingApplication,
          alternateColor: true
        };
    }
  }, [modalsOpen, resendApplication, resendingApplication, trashApplication, trashingApplication]);

  useEffect(() => {
    application?.candidate?.linkedin_url && setLinkedInURL(application.candidate.linkedin_url);
  }, [application]);

  const validateLinkedInURL = (url: string) => {
    return !!url.match(LINKEDIN_URL_REGEX) || url.length === 0;
  };

  const sanitizeLinkedInURL = (url: string) => {
    const match = url.match(LINKEDIN_URL_REGEX);
    return match ? match[0] : '';
  };

  const handleLinkedinSubmit = () => {
    if (validateLinkedInURL(linkedInURL)) {
      setLinkedInURL(sanitizeLinkedInURL(linkedInURL));
      updateLinkedInURL();
    }
  };

  const getLinkedInProfileName = () => {
    const profileName = application?.candidate?.linkedin_url?.split('/')[4];
    return !application?.candidate?.linkedin_url || !profileName ? 'Search Candidate' : profileName;
  };

  const LinkedInSection = useMemo(() => {
    return (
      <Stack sx={styles.applicationHeaderItems}>
        <LinkedInIcon sx={styles.scoutNavyIcon} />
        {editLinkedInURL ? (
          <>
            <FormTextField
              value={linkedInURL}
              onChange={(e) => setLinkedInURL(e.target.value)}
              styles={styles.linkedInURLInput}
              placeholder="e.g. https://linkedin.com/in/felix"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLinkedinSubmit();
                }
              }}
            />
            {updatingLinkedInURL ? (
              <CircularProgress size={15} />
            ) : (
              <DoneIcon
                id="save-linkedin-url-button"
                sx={{ color: '#5BC4C0', cursor: 'pointer', fontSize: '16px' }}
                onClick={handleLinkedinSubmit}
              />
            )}
            <CloseIcon
              id="cancel-linkedin-url-button"
              sx={{ ...styles.clickableGreyIcon, fontSize: '16px' }}
              onClick={() => {
                application?.candidate?.linkedin_url
                  ? setLinkedInURL(application?.candidate.linkedin_url)
                  : setLinkedInURL('');
                setEditLinkedInURL(false);
              }}
            />
            {linkedInURL && !validateLinkedInURL(linkedInURL) && (
              <Stack sx={{ color: theme.palette.error.main, fontSize: '10px' }}>
                Please enter a valid LinkedIn URL
              </Stack>
            )}
          </>
        ) : (
          <>
            <Typography
              sx={styles.linkedInURLLink}
              onClick={() =>
                window.open(
                  application?.candidate?.linkedin_url ||
                    `https://www.linkedin.com/pub/dir?firstName=${application?.candidate?.firstname}&lastName=${application?.candidate?.lastname}&trk=people-guest_people-search-bar_search-submit`,
                  '_blank'
                )
              }
            >
              {getLinkedInProfileName()}
            </Typography>
            <EditIcon
              id="edit-linkedin-url-button"
              sx={{ ...styles.clickableGreyIcon, fontSize: '12px' }}
              onClick={() => setEditLinkedInURL(true)}
            />
          </>
        )}
      </Stack>
    );
  }, [application, editLinkedInURL, linkedInURL, updateLinkedInURL, updatingLinkedInURL]);

  return (
    <Stack>
      {!application ? (
        [...Array(3)].map((_, index) => <Skeleton key={index} height={30} animation="wave" />)
      ) : (
        <>
          <Stack sx={{ flexDirection: 'row', columnGap: 3 }}>
            <Avatar sx={{ background: '#DDDDDD', width: '72px', height: '72px' }}>
              <PersonIcon
                sx={{
                  color:
                    application.candidate?.number_of_applications &&
                    application.candidate.number_of_applications > 1
                      ? '#D6827D'
                      : '#8CCF95',
                  fontSize: '60px'
                }}
              />
            </Avatar>
            <Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', columnGap: 1 }}>
                <Stack sx={{ color: '#666666', fontSize: '10px', fontWeight: 600 }}>
                  Application #{application.id}
                </Stack>
                <ApplicationBetaButton />
              </Stack>
              <Link
                href={`${BASE_URL}/admin/candidates/${application.candidate.id}`}
                target="_blank"
                sx={styles.candidateLink}
              >
                {application.candidate?.firstname} {application.candidate?.lastname}
              </Link>
              <Stack
                id="job-name-link"
                sx={{ color: '#939393', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => window.open(`${BASE_URL}${application.job.job_link}`, '_blank')}
              >
                {application.job?.title}
              </Stack>
              <Autocomplete
                id="change-status-dropdown"
                disablePortal
                options={applicationStatuses || []}
                getOptionLabel={(option: IApplicationStatus) => option.name}
                value={applicationStatus}
                sx={{ ...sharedClasses.formAutocomplete, minWidth: '125px', paddingTop: 1 }}
                loading={loadingApplicationStatuses}
                loadingText="Loading application statuses..."
                disableClearable={!!applicationStatus}
                ListboxProps={{ style: styles.applicationStatusListboxStyle }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    sx={{ input: { fontSize: '12px', paddingLeft: 'unset !important' } }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" sx={{ paddingLeft: 1 }}>
                          {updatingApplicationStatus ? (
                            <CircularProgress size={15} />
                          ) : (
                            <CircleIcon
                              sx={{
                                fontSize: '0.5rem',
                                color: applicationStatus?.colour || '#808080'
                              }}
                            />
                          )}
                        </InputAdornment>
                      )
                    }}
                    required
                  />
                )}
                onChange={(event, value) => {
                  setApplicationStatus(value);
                  updateApplicationStatus();
                }}
                disabled={!permissions?.Applications?.['Change Status']}
              />
            </Stack>
            <Divider orientation="vertical" flexItem sx={{ margin: '0px 16px' }} />
            <Stack sx={{ rowGap: 2, paddingTop: 0.5 }}>
              <Stack
                id="email-link"
                sx={{
                  ...styles.applicationHeaderItems,
                  cursor: permissions?.Applications?.['Send Emails'] ? 'pointer' : 'unset'
                }}
                onClick={() =>
                  permissions?.Applications?.['Send Emails'] &&
                  dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.SEND_EMAIL })
                }
              >
                <EmailIcon sx={styles.scoutNavyIcon} />
                {application.candidate?.email}
              </Stack>
              <Stack sx={styles.applicationHeaderItems}>
                <PhoneIcon sx={styles.scoutNavyIcon} />
                {application.candidate?.phone1}
              </Stack>
              {!!Number(application.job.options.gender_field) &&
                permissions?.Applications?.['Show Gender'] &&
                application?.candidate?.gender &&
                showLinkedInURL &&
                LinkedInSection}
            </Stack>
            <Stack sx={{ rowGap: 2, paddingTop: 0.5 }}>
              <Stack sx={styles.applicationHeaderItems}>
                <LocationOnIcon sx={styles.scoutNavyIcon} />
                {formatAddress(application.candidate?.address_details)}
              </Stack>
              {!!Number(application.job.options.gender_field) &&
              permissions?.Applications?.['Show Gender'] &&
              application?.candidate?.gender ? (
                <Stack sx={styles.applicationHeaderItems}>
                  <Man4Icon sx={styles.scoutNavyIcon} />
                  {application.candidate.gender}
                </Stack>
              ) : (
                <>{showLinkedInURL && LinkedInSection}</>
              )}
            </Stack>
            <Stack sx={styles.applicationHeaderActionsContainer}>
              {permissions?.Applications?.['Move Application'] &&
                permissions?.Applications?.['Clone and Move Application'] && (
                  <Tooltip placement="top" title="Move" arrow>
                    <DriveFileMoveIcon
                      id="move-application-button"
                      sx={styles.clickableGreyIcon}
                      onClick={() =>
                        dispatch({
                          type: 'SET_MODALS_OPEN',
                          payload: ModalType.MOVE
                        })
                      }
                    />
                  </Tooltip>
                )}
              <MoreVertIcon
                sx={styles.clickableGreyIcon}
                onClick={(e) => {
                  dispatch({
                    type: 'SET_ACTIONS',
                    payload: { anchorEl: e.target as HTMLElement }
                  });
                }}
              />
            </Stack>
          </Stack>
        </>
      )}
      {(modalsOpen === ModalType.MOVE || modalsOpen === ModalType.CLONE) && (
        <MoveApplicationModal ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      <MoreOptionsPopover
        actionsState={ApplicationState}
        dispatch={dispatch}
        label="MoreOptionsPopover"
      />
      {modalsOpen == ModalType.DELETE && (
        <DeleteApplication isDialogOpen={modalsOpen == ModalType.DELETE} dispatch={dispatch} />
      )}
      {modalState && (
        <GenericDialog
          url={''}
          title={modalState.modalTitle}
          description={modalState.modalDesc()}
          buttonText={modalState.buttonText}
          buttonCallback={() => {
            if (modalsOpen === ModalType.RESEND && !application?.job.recipients?.length) {
              dispatch({
                type: 'SET_SNACKBAR',
                payload: {
                  message: `No email attached to application recipient. Please add a user on the Edit Job page to resend the application`,
                  state: 'warning'
                }
              });
              closeModal();
            } else {
              modalState.callback();
            }
          }}
          callbackLoading={modalState.callbackLoading}
          isDialogOpen={modalsOpen == ModalType.TRASH || modalsOpen == ModalType.RESEND}
          setDialogOpen={closeModal}
          alternateColor={modalState.alternateColor}
          primaryButtonId={
            modalsOpen === ModalType.TRASH ? 'confirm-trash-application-button' : undefined
          }
        />
      )}
      {modalsOpen === ModalType.SEARCH && (
        <SearchIndex
          isOpen={modalsOpen === ModalType.SEARCH}
          handleClose={closeModal}
          dispatch={dispatch}
        />
      )}
    </Stack>
  );
}
