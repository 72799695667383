import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import useStyles from "../useStyles";
import {
  Popper,
  ClickAwayListener,
  IconButton
} from "@material-ui/core";
import MoreOutlinedIcon from "@material-ui/icons/MoreOutlined";
function TagPicker({ insertTag, job }) {
  const [isOpen, setIsOpen] = useState(false);
  const anchor = useRef(null);
  const classes = useStyles();
  function handleClick(tag) {
    insertTag(tag);
    setIsOpen(false);
  }
  return /* @__PURE__ */ React.createElement(ClickAwayListener, { onClickAway: () => setIsOpen(false) }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    IconButton,
    {
      classes: { root: classes.actionButton },
      ref: anchor,
      onClick: () => setIsOpen(!isOpen)
    },
    /* @__PURE__ */ React.createElement(MoreOutlinedIcon, { fontSize: "small", color: "primary" })
  ), /* @__PURE__ */ React.createElement(
    Popper,
    {
      className: classes.popper,
      anchorEl: anchor.current,
      open: isOpen,
      placement: "top-start"
    },
    /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: classes.popperItem, onClick: () => handleClick('{{contact.firstName | default: "there"}}') }, /* @__PURE__ */ React.createElement("span", null, "First Name")), /* @__PURE__ */ React.createElement("div", { className: classes.popperItem, onClick: () => handleClick('{{contact.lastName | default: "there"}}') }, /* @__PURE__ */ React.createElement("span", null, "Last Name")), /* @__PURE__ */ React.createElement("div", { className: classes.popperItem, onClick: () => handleClick(job.title) }, /* @__PURE__ */ React.createElement("span", null, "Job Name")), /* @__PURE__ */ React.createElement("div", { className: classes.popperItem, onClick: () => handleClick(job.address_state || job.location) }, /* @__PURE__ */ React.createElement("span", null, "Job Location")))
  )));
}
TagPicker.propTypes = {
  insertTag: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired
};
export default TagPicker;
