"use strict";
import RecruitApiClient from "../../RecruitApiClient";
class Api {
  constructor() {
    this.getApplicationData = (jobId, applicationId, index) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/applications/${applicationId}?i=${index}`, null);
    this.getApplicationStatuses = (jobId) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/job_application_statuses`, null);
    this.updateApplicationStatus = (jobId, applicationId, statusId) => RecruitApiClient.put(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/application_status/${statusId}`
    );
    this.updateCandidateLinkedInURL = (candidateId, linkedInURL) => RecruitApiClient.put(`/api/v4/candidates/${candidateId}`, {
      candidate: { linkedin_url: linkedInURL }
    });
    this.getJobsOptions = (jobId) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/bulk_move_applications`, null);
    this.moveApplication = (jobId, param) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/bulk_move_applications`, param);
    this.trashApplication = (jobId, applicationId) => RecruitApiClient.delete(`/api/v4/jobs/${jobId}/applications`, {
      application_ids: [applicationId]
    });
    this.updateApplicationNotepad = (jobId, applicationId, note) => RecruitApiClient.put(`/api/v4/jobs/${jobId}/applications/${applicationId}/update_notepad`, {
      suitability_comment: note
    });
    this.sendApplicationSummary = (jobId, applicationId, emails) => RecruitApiClient.post(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/send_individual_summary`,
      { emails }
    );
    this.updateSummary = (jobId, applicationId) => RecruitApiClient.post(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/application_summary`
    );
    this.getHrQuestions = (jobId, applicationId) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/applications/${applicationId}/hr_answers`);
    this.saveHrQuestion = (jobId, applicationId, questionId, params) => RecruitApiClient.put(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/hr_answers/${questionId}`,
      {
        hr_answer_set: params
      }
    );
    this.getRatingSetForApplication = (jobId, applicationId) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/applications/${applicationId}/rating_set`);
    this.resetRatingForApplication = (jobId, applicationId) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/${applicationId}/reset_rating`);
    this.rateResponses = (jobId, applicationId, params) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/${applicationId}/rating_sets`, {
      rating_set: params
    });
    this.cloneApplication = (jobId, application_id, param) => {
      return RecruitApiClient.post(
        `/api/v4/jobs/${jobId}/applications/${application_id}/clone_application`,
        param
      );
    };
    this.resendApplication = (jobId, applicationId) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/${applicationId}/resend_application`);
    this.deleteApplication = (jobId, applicationId) => RecruitApiClient.delete(`/api/v4/jobs/${jobId}/applications/${applicationId}`);
    this.getSearchIndex = (jobId, applicationId) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/applications/${applicationId}/search_index`, null);
    this.updateSearchIndex = (jobId, applicationId) => RecruitApiClient.put(`/api/v4/jobs/${jobId}/applications/${applicationId}/search_index`, {});
    this.deleteSearchIndex = (jobId, applicationId) => RecruitApiClient.delete(`/api/v4/jobs/${jobId}/applications/${applicationId}/search_index`);
    this.updateApplicationRating = (jobId, applicationId, param) => {
      return RecruitApiClient.put(`/api/v4/jobs/${jobId}/applications/${applicationId}`, param);
    };
    this.updateUiPreferences = (param) => {
      return RecruitApiClient.post(`/api/v4/ui_preferences`, { ui_preference: param });
    };
    this.getUiPreferences = () => RecruitApiClient.get(`/api/v4/ui_preferences?source=application`, null);
    this.addRecord = (jobId, applicationId, params) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/${applicationId}/permanent_record`, {
      note: params
    });
    this.getEvents = (apiKey, date, eventTypeId) => RecruitApiClient.get(
      "/api/v4/calendar/events",
      {
        date,
        override_limit: true,
        event_type_id: eventTypeId
      },
      { "X-api-authenticate": apiKey }
    );
    this.getSmsTemplates = () => RecruitApiClient.get("/api/v4/sms_templates", {});
    this.saveSmsTemplate = (smsTemplateName, smsMessage) => RecruitApiClient.post(`/api/v4/sms_templates`, {
      sms_template: {
        title: smsTemplateName,
        body: smsMessage
      }
    });
    this.getSubEntityGroupedEmailTemplates = (apiKey, jobId, params) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/email_templates`, params, {
      "X-api-authenticate": apiKey
    });
    this.getEmailLetterheadGroupedEmailTemplates = (apiKey, type) => RecruitApiClient.get(
      `/api/v4/calendar/email_templates/grouped_email_templates`,
      { type },
      { "X-api-authenticate": apiKey }
    );
    this.getEmailTemplateFromCalendar = (apiKey, templateId) => RecruitApiClient.get(
      `/api/v4/calendar/email_templates/${templateId}`,
      {},
      { "X-api-authenticate": apiKey }
    );
    this.getEmailTemplateFromJob = (apiKey, templateId, jobId) => RecruitApiClient.get(
      `/api/v4/jobs/${jobId}/email_templates/${templateId}`,
      {},
      { "X-api-authenticate": apiKey }
    );
    this.addToEventSms = (jobId, eventId, applicationIds, content) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/bulk_sms/add_to_event`, {
      event_id: eventId,
      applications_ids: applicationIds,
      content,
      added: true
    });
    this.sendInviteSms = (jobId, eventTypeId, applicationIds, content) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/bulk_sms/invite_to_timeslot`, {
      event_type_id: eventTypeId,
      applications_ids: applicationIds,
      content,
      invited: true
    });
    this.sendEmail = (jobId, eventId, emailTemplateId, content, applicationIds) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/email`, {
      email: {
        event_id: eventId,
        email_template_id: emailTemplateId,
        content,
        application_ids: applicationIds
      }
    });
    this.getApplicationActivities = (jobId, applicationId) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/applications/${applicationId}/activities`, {
      override_limit: true,
      limit: 100
    });
    this.getApprovalTemplates = () => RecruitApiClient.get(`/api/v4/requisition_forms/enabled_for_applications`);
    this.sendExternalEmails = (jobId, params) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/bulk_external_email`, params);
    this.getSmartForms = () => RecruitApiClient.get(`/api/v4/smart_forms`, {
      override_limit: true,
      limit: 500,
      enabled: true
    });
    this.getFormQuestions = (params) => RecruitApiClient.get(`/api/v4/fields`, params);
    this.addForm = (jobId, applicationId, formId, params) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/${applicationId}/form_responses`, {
      form_id: formId,
      form_response: { answers_attributes: params }
    });
    this.sms = (jobId, applicationId, params) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/${applicationId}/sms`, {
      sms: params
    });
    this.sendForm = (jobId, applicationId, params) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/${applicationId}/send_form`, {
      send_form: params
    });
    this.sendFormBySMS = (jobId, applicationId, params) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/${applicationId}/send_sms_form`, {
      send_sms_form: params
    });
    this.getCareAdvantageEvents = (jobId) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/auto_emails/care_advantage_events`);
    this.getApplicationAttachments = (jobId, applicationId) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/applications/${applicationId}/attachments`, null);
    this.deleteAttachment = (jobId, applicationId, attachmentId) => RecruitApiClient.delete(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/attachments/${attachmentId}`
    );
    this.getRecruiters = (apiKey) => RecruitApiClient.get("/api/v4/calendar/users", {}, { "X-api-authenticate": apiKey });
    this.getEventTypes = () => RecruitApiClient.get("/api/v4/calendar/event_types", {});
    this.inviteByEmail = (apiKey, params) => RecruitApiClient.post(`/api/v4/calendar/invitations`, params, { "X-api-authenticate": apiKey });
    this.previewAttachment = (jobId, applicationId, attachmentId, index) => RecruitApiClient.get(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/attachments/${attachmentId}`,
      { index }
    );
    this.getVideoTimestamps = (assetId) => RecruitApiClient.get(`/api/v4/video_timestamp`, { asset_id: assetId });
    this.createVideoTimestamp = (assetId, timestampName, timestampValue) => RecruitApiClient.post(`/api/v4/video_timestamp`, {
      asset_id: assetId,
      timestamp: { name: timestampName, value: timestampValue }
    });
    this.updateVideoTimestamp = (assetId, timestampId, timestampName, timestampValue) => RecruitApiClient.put(`/api/v4/video_timestamp/${timestampId}`, {
      asset_id: assetId,
      timestamp: { name: timestampName, value: timestampValue }
    });
    this.deleteVideoTimestamp = (assetId, timestampId) => RecruitApiClient.delete(`/api/v4/video_timestamp/${timestampId}`, { asset_id: assetId });
    this.getApplicationTasks = (jobId, applicationId) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/applications/${applicationId}/tasks`);
    this.updateApplicationTask = (jobId, applicationId, taskId, params) => RecruitApiClient.put(`/api/v4/jobs/${jobId}/applications/${applicationId}/tasks/${taskId}`, {
      task: params
    });
    this.getWorkflows = (applicationId) => RecruitApiClient.get(`/api/v4/workflows?application_id=${applicationId}`);
    this.getAssignableUsers = () => RecruitApiClient.get(`/api/v4/tasks/assignable_users`);
    this.assignWorkflow = (applicationId, params) => RecruitApiClient.post(`/api/v4/workflows?application_id=${applicationId}`, params);
    this.addTask = (jobId, applicationId, params) => RecruitApiClient.post(`/api/v4/jobs/${jobId}/applications/${applicationId}/tasks`, {
      task: params
    });
    this.deleteTask = (jobId, applicationId, taskId) => RecruitApiClient.delete(`/api/v4/jobs/${jobId}/applications/${applicationId}/tasks/${taskId}`);
    this.getApplicationEvents = (jobId, applicationId) => RecruitApiClient.get(`/api/v4/jobs/${jobId}/applications/${applicationId}/events`);
    this.getFormResponses = (jobId, applicationId) => RecruitApiClient.get(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/form_responses`,
      null
    );
    this.showFormResponse = (jobId, applicationId, formResponseId) => RecruitApiClient.get(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/form_responses/${formResponseId}`
    );
    this.editFormResponse = (jobId, applicationId, formResponseId, params) => RecruitApiClient.put(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/form_responses/${formResponseId}`,
      {
        form_response: { answers_attributes: params }
      }
    );
    this.deleteFormResponse = (jobId, applicationId, formResponseId) => RecruitApiClient.delete(
      `/api/v4/jobs/${jobId}/applications/${applicationId}/form_responses/${formResponseId}`
    );
    this.getVideoInterview = (ApplicationId) => RecruitApiClient.get(`/api/v4/video_interviews/${ApplicationId}`);
    this.deleteVideoInterview = (ApplicationId) => RecruitApiClient.delete(`/api/v4/video_interviews/${ApplicationId}`);
    this.getVideoInterviewBookmarks = (ApplicationId) => RecruitApiClient.get(`/api/v4/video_interviews/${ApplicationId}/bookmarks`);
    this.deleteVideoInterviewBookmark = (ApplicationId, id) => {
      return RecruitApiClient.delete(`/api/v4/video_interviews/${ApplicationId}/bookmarks/${id}`);
    };
    this.createVideoInterviewBookmark = (ApplicationId, name, value) => {
      return RecruitApiClient.post(`/api/v4/video_interviews/${ApplicationId}/bookmarks`, {
        name,
        value
      });
    };
    this.updateVideoInterviewBookmark = (ApplicationId, id, name, value) => {
      return RecruitApiClient.put(`/api/v4/video_interviews/${ApplicationId}/bookmarks/${id}`, {
        name,
        value
      });
    };
  }
}
export default new Api();
