import React from 'react';

export default class AdminSubmenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: props.active };
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toGMTString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  getCookie = (cname) => {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  clickHandler = (cname) => {
    this.setCookie(cname, !(this.getCookie(cname) === 'true'), 1);
  };

  render() {
    const { active } = this.state;
    const { item } = this.props;
    const subMenuId = item.title.toLowerCase().replace(/ /g, '-');

    return (
      <li className={active ? 'active nav-item menu-item' : 'nav-item menu-item'}>
        <a
          href="#"
          className="nav-link"
          data-toggle="collapse"
          data-target={'#' + subMenuId}
          aria-expanded={this.getCookie(subMenuId) === 'true' ? true : false}
          aria-controls={subMenuId}
          onClick={() => this.clickHandler(subMenuId)}
        >
          <div className="d-flex flex-row">
            <i className="icon material-icons">{item.icon}</i>
            <span>{item.title}</span>
            <i className="chevron material-icons ml-auto">chevron_right</i>
          </div>
        </a>
        <div
          className={
            item.active || this.getCookie(subMenuId) === 'true'
              ? 'sub-menu collapse show'
              : 'sub-menu collapse'
          }
          id={subMenuId}
        >
          <ul className="nav flex-column">
            {item.submenu.map((subitem, idx) => (
              <li key={idx} className="nav-item">
                <a
                  id={subitem.id}
                  href={subitem.url}
                  className={subitem.active ? 'nav-link active' : 'nav-link'}
                  target={subitem.external ? '_blank' : ''}
                >
                  {subitem.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </li>
    );
  }
}
