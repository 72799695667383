import React from 'react';
import { Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { sharedClasses } from './sharedClasses';

const InfoBox = ({ message }: { message: string | JSX.Element }) => {
  return (
    <Box sx={sharedClasses.noticeContainer}>
      <Box sx={{ display: 'flex' }}>
        <InfoIcon sx={sharedClasses.noticeIcon} />
        {message}
      </Box>
    </Box>
  );
};

export default InfoBox;
