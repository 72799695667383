import React from 'react';
import { Box, Collapse } from '@mui/material';
import dompurify from 'dompurify';
import { classes } from './styles';

const DisplayHTMLCollapsible = ({
  content,
  collapsed,
  setCollapsed
}: {
  content: string;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}) => {
  return (
    <>
      <Collapse in={!collapsed} collapsedSize={120}>
        <Box
          sx={classes.descriptionPreview}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(content)
          }}
        />
      </Collapse>
      <Box sx={classes.toggleDescriptionPreview} onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? 'Show more' : 'Show less' }
      </Box>
    </>
  );
};

export default DisplayHTMLCollapsible;
