import React, { useEffect, useState, useRef, useReducer } from 'react';
import { Box, Typography, Tabs, Tab, Stack, IconButton, Skeleton } from '@mui/material';
import { ArrowCircleLeft as ArrowCircleLeftIcon } from '@mui/icons-material';
import { styles } from './styles';
import { IError } from './types';
import Questions from './Questions/Questions';
import { useQuery } from '@tanstack/react-query';
import { InitialSmartFormFieldsState, SmartFormFieldsReducer } from './reducer';
import StyledSnackbar from '../Components/CustomUIElements/StyledSnackbar';
import Api from './API';

export default function SmartFormFields({
  formId,
  userPermissions
}: {
  formId: number;
  userPermissions: Record<string, Record<string, boolean>>;
}) {
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [SmartFormFieldsState, dispatch] = useReducer(
    SmartFormFieldsReducer,
    InitialSmartFormFieldsState
  );

  const headerRefs = useRef<HTMLDivElement>(null);

  const {
    data: formData,
    isLoading: loadingFormData,
    refetch: refetchFormData,
    isFetched: fetchedFormData
  } = useQuery({
    queryKey: ['formData'],
    queryFn: async () => {
      const { res } = await Api.getSmartForm(formId);
      return res;
    },
    onError: (error: IError) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting form data, ${error.errors.join('. ')}`,
          state: 'error'
        }
      })
  });

  useEffect(() => {
    headerRefs.current && setIndicatorWidth(headerRefs?.current.clientWidth - 32);
  }, []);

  return (
    <Box sx={styles.smartFormsPageContainer}>
      <Box sx={styles.smartFormsPageHeader}>
        {loadingFormData ? (
          <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
        ) : (
          <Typography variant="h1">{formData?.name}</Typography>
        )}
      </Box>
      <Box sx={styles.tabsContainer}>
        <Tabs
          value={0}
          aria-label="Smart Forms"
          sx={{
            ...styles.tabs,
            '& .MuiTabs-indicator': { ...styles.tabIndicator, maxWidth: indicatorWidth + 'px' }
          }}
        >
          <Tab ref={headerRefs} label="Add/Edit Fields" disableRipple />
        </Tabs>
      </Box>
      <Box sx={styles.backToApprovalFormsButton}>
        <Stack
          id="smart-form-fields-back-button"
          flexDirection="row"
          alignItems="center"
          onClick={() => {
            window.location.href = '/admin/forms';
          }}
        >
          <IconButton sx={styles.iconGrey}>
            <ArrowCircleLeftIcon />
          </IconButton>
          <Box>Back to smart forms</Box>
        </Stack>
      </Box>
      <Box>
        <Questions
          formData={formData}
          fetchedFormData={fetchedFormData}
          SmartFormFieldsState={SmartFormFieldsState}
          dispatch={dispatch}
          refetchFormData={refetchFormData}
          formId={formId}
          userPermissions={userPermissions}
        />
      </Box>
      <StyledSnackbar
        message={SmartFormFieldsState.snackbar.message}
        state={SmartFormFieldsState.snackbar.state}
        setSnackbarState={() =>
          dispatch({ type: 'SET_SNACKBAR', payload: { message: '', state: 'success' } })
        }
      />
    </Box>
  );
}
