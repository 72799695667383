import React, { useState, useEffect, useCallback, Dispatch } from 'react';
import { Box, Button, Popper, Fade, PopperPlacementType, Modal, Badge } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from './styles';
import { notificationTitles } from './config';
import { AIStudioAction, AIStudioState } from './reducer';
import BulkSummaries from '../ApplicationSummaries/InsightsModal/ResponseStep';
import InterviewQuestions from '../ApplicationSummaries/QuestionsModal/ResponseStep';
import { dismissNotification } from '../SharedComponents/Utility/dismissNotification';
import { IWebsocketData, INotificationHistoryItem } from '../SharedComponents/types';
import { useWebSockets } from '../../NewUI/Components/Utilities/useWebSockets';
import StyledSnackbar from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import EditStep from '../ApplicationSummaries/QuestionsModal/FollowUpEmail/EditStep';

const FelixNotification = ({
  anchorEl,
  aiStudioState,
  dispatch
}: {
  anchorEl: HTMLElement | null;
  aiStudioState: AIStudioState;
  dispatch: Dispatch<AIStudioAction>;
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [snackbarState, setSnackbarState] = useState({
    message: '',
    state: 'success' as 'success' | 'error' | 'warning'
  });
  const displayNumberOfNotifications = () => {
    if (aiStudioState.notificationData.length > 1) {
      return aiStudioState.notificationData.length;
    } else {
      return 0;
    }
  };

  const handleDismissNotification = () => {
    dismissNotification(aiStudioState.notificationData[0]?.id);
    dispatch({ type: 'DISMISS_NOTIFICATION' });
  };

  const { subscribeToChannel } = useWebSockets('AiStudioChannel', (data: IWebsocketData) => {
    if (data.kind === 'dismissal') {
      dispatch({ type: 'SET_DISMISSED_NOTIFICATION', payload: data.id });
    } else {
      dispatch({ type: 'SET_RECEIVED_NOTIFICATION', payload: data });
    }
  });

  const getInitialNotifications = useCallback(async () => {
    const response = await fetch('/api/v4/ai_generation_histories?exclude_dismissed=true', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const notifications = await response.json();
    const formattedNotifications = notifications?.histories?.map(
      (notification: INotificationHistoryItem) => ({
        id: notification.id,
        kind: notification.kind,
        content: notification.content,
        ...(notification.candidate_name && { candidate_name: notification.candidate_name }),
        ...(notification.application_id && { application_id: notification.application_id }),
        ...(notification.job_id && { job_id: notification.job_id })
      })
    );
    dispatch({ type: 'SET_NOTIFICATIONS', payload: formattedNotifications });
  }, [dispatch]);

  useEffect(() => {
    subscribeToChannel();
  }, [subscribeToChannel]);

  useEffect(() => {
    getInitialNotifications();
  }, [getInitialNotifications]);

  const displayNotificationContent = () => {
    switch (aiStudioState.notificationData[0]?.kind) {
      case 'bulk_summary':
        return (
          <BulkSummaries
            summaryData={aiStudioState.notificationData[0]}
            handleDismissNotification={handleDismissNotification}
            isNotification
            setEmails={setEmails}
            emails={emails}
            setSnackbar={setSnackbarState}
          />
        );
      case 'interview_questions':
        return (
          <InterviewQuestions
            questionData={aiStudioState.notificationData[0]}
            setSnackbarState={setSnackbarState}
            isNotification
          />
        );
      case 'follow_up_email':
        return (
          <EditStep
            emailData={aiStudioState.notificationData[0]}
            handleClose={handleDismissNotification}
            goBack={handleDismissNotification}
            jobId={aiStudioState.notificationData[0]?.job_id}
            applicationId={aiStudioState.notificationData[0]?.application_id}
            setSnackbarState={setSnackbarState}
          />
        );
      default:
        return <div>Something went wrong, please contact support</div>;
    }
  };

  const notificationText = (kind) => {
    if (kind === 'error') {
      return 'There was an error generating your content';
    } else {
      return `Your
          ${notificationTitles[kind as keyof typeof notificationTitles]}
          ready!`;
    }
  };

  return (
    <>
      <Popper
        open={aiStudioState.notificationData?.length > 0}
        anchorEl={anchorEl}
        placement={'right-end' as PopperPlacementType}
        transition
        sx={{ zIndex: 1255 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={700}>
            <Box sx={classes.notificationPopperContainer}>
              <Badge badgeContent={displayNumberOfNotifications()} sx={classes.notificationsBadge}>
                <Box sx={classes.notificationPopper}>
                  {aiStudioState.notificationData?.length > 0 && (
                    <>
                      <Box sx={classes.notificationHeader}>
                        {notificationText(aiStudioState.notificationData[0]?.kind)}
                      </Box>
                      <Box sx={classes.notificationButtons}>
                        <Button
                          sx={classes.notificationCloseButton}
                          onClick={handleDismissNotification}
                        >
                          Dismiss
                        </Button>
                        {aiStudioState.notificationData[0]?.kind !== 'error' && (
                          <Button
                            sx={classes.notificationViewButton}
                            onClick={() => dispatch({ type: 'VIEW_NOTIFICATION' })}
                          >
                            View
                          </Button>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
                <Box sx={classes.borderBox} />
              </Badge>
            </Box>
          </Fade>
        )}
      </Popper>
      <Modal
        open={aiStudioState.notificationModalOpen}
        onClose={handleDismissNotification}
        aria-labelledby="ai-studio-view-notification"
        aria-describedby="ai-studio-view-notification"
      >
        <Box sx={classes.modal}>
          <Box sx={classes.notificationModalContent}>
            <CloseIcon onClick={handleDismissNotification} sx={classes.closeIcon} />
            {displayNotificationContent()}
          </Box>
        </Box>
      </Modal>
      <StyledSnackbar
        message={snackbarState.message}
        state={snackbarState.state}
        setSnackbarState={setSnackbarState}
      />
    </>
  );
};

export default FelixNotification;
