"use strict";
import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    width: "100%",
    padding: "50px 60px 20px 60px",
    minHeight: 630,
    maxHeight: 630,
    height: 630,
    "& h4": {
      marginTop: 0,
      color: "#004E70",
      fontSize: 26,
      fontWeight: 700,
      marginBottom: 30
    }
  },
  buttonContainer: {
    marginTop: "auto",
    paddingTop: 30,
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      padding: "12px 26px",
      color: "#FFF",
      minHeight: 50,
      "&:last-child": {
        marginLeft: 12
      }
    },
    "&.move-z": {
      marginBottom: -20,
      paddingBottom: 20,
      zIndex: 2,
      background: "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,1), rgba(255,255,255,1))"
    }
  },
  buttonIcon: {
    fontSize: "1.7rem",
    width: "0.8rem"
  },
  recContainer: {
    display: "flex"
  },
  recImage: {
    borderRadius: "100%",
    width: 45,
    height: 45,
    maxHeight: 45,
    maxWidth: 45,
    background: "#DDDDDD",
    color: "#000",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  recDetails: {
    marginLeft: 14,
    display: "flex",
    flexDirection: "column",
    "& h4": {
      fontSize: 15,
      color: "#000000",
      fontWeight: 400,
      marginBottom: 4
    },
    "& a": {
      fontWeight: "600",
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontSize: 14,
      lineHeight: "14px"
    }
  },
  meetingContainer: {
    marginTop: 30,
    paddingTop: 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    overflow: "scroll",
    marginLeft: "-1%",
    marginRight: "-1%"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "31%",
    maxWidth: "31%",
    borderRadius: 6,
    marginBottom: 30,
    marginLeft: "1%",
    marginRight: "1%",
    boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.16)",
    "&:hover": {
      cursor: "pointer",
      transform: "translateY(-2px)",
      boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.16)"
    },
    "&:active": {
      transform: "translateY(-1px)",
      boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.14)"
    }
  },
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    padding: "40px 20px",
    alignItems: "flex-start",
    borderRadius: 6,
    borderTopWidth: 6,
    borderStyle: "solid",
    borderTopColor: "#DDD"
  },
  name: {
    fontSize: 21,
    color: "#000",
    userSelect: "none"
  },
  details: {
    color: "#939393",
    fontSize: 16,
    marginBottom: 8,
    userSelect: "none"
  },
  booking: {
    fontWeight: 600,
    color: `${theme.palette.secondary.main} !important`,
    fontSize: 14,
    userSelect: "none"
  },
  footer: {
    borderTop: "1px solid #DDDDDD",
    padding: "10px 26px",
    display: "flex",
    justifyContent: "space-between"
  },
  copyButton: {
    fontWeight: 400,
    fontSize: 10,
    padding: "4px 10px !important"
  },
  editButton: {
    fontWeight: 400,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 20,
    fontSize: 10,
    background: "#FFF",
    padding: "4px 10px !important"
  },
  shadow: {
    boxShadow: "rgb(0 0 0 / 55%) -4px -33px 15px -45px inset"
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  noEvents: {
    color: "#999",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  }
}));
