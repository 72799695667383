import React from 'react';

export default function SadNoteSVG() {
return (<svg
xmlns="http://www.w3.org/2000/svg"
width="93.637"
height="96.195"
viewBox="0 0 93.637 96.195"
>
<g id="Group_77" data-name="Group 77" transform="translate(-131.839 -176.424)">
  <g id="_ÎÓÈ_2" data-name="—ÎÓÈ_2" transform="translate(131.839 176.424)">
    <path
      id="Path_21"
      data-name="Path 21"
      d="M343.215,799.111c-4.859-.158-9.672-.4-14.384-.784-1.849-.151-3.7-.333-5.394-.643a5.6,5.6,0,0,1-1.617-.469c-2.04-1.2,3.466-2.547,6.029-2.812,3.612-.374,7.547-.239,11.285-.312,11.486-.226,23.029-.221,34.554.029,5.15.111,10.526.242,15.742.474,4.745.211,11.074.248,14.791,1.48q.2.066.388.136c.734.274,1.227.657,1.026,1.02a2.848,2.848,0,0,1-1.476.761c-4.442,1.37-10.065,1.558-15.809,1.639-7.4.1-14.858-.135-22.248-.183C358.474,799.4,350.836,799.359,343.215,799.111Z"
      transform="translate(-317.04 -718.314)"
      fill="#e5e5e5"
    />
    <path
      id="Path_22"
      data-name="Path 22"
      d="M317.6,888.23c-5.4-.186-10.742-.475-15.975-.938a56.13,56.13,0,0,1-5.99-.78,5.774,5.774,0,0,1-1.795-.572c-2.263-1.466,3.854-3.131,6.7-3.46,4.012-.465,8.383-.306,12.535-.4,12.758-.3,25.579-.309,38.379-.022,5.72.128,11.69.28,17.484.556,5.27.251,12.3.287,16.427,1.792q.221.081.43.167c.815.335,1.361.8,1.138,1.249-.191.381-.9.683-1.64.937-4.936,1.689-11.182,1.928-17.562,2.036-8.223.139-16.5-.141-24.71-.188C334.543,888.555,326.059,888.521,317.6,888.23Z"
      transform="translate(-293.343 -792.635)"
      fill="#e5e5e5"
    />
    <g id="Group_67" data-name="Group 67" transform="translate(4.441 6.923)">
      <g id="Group_65" data-name="Group 65">
        <path
          id="Path_23"
          data-name="Path 23"
          d="M407.922,383.372l-41.645-32.386-41.8,32.466.015.012.051,52.882,83.615-.08-.051-52.894Z"
          transform="translate(-324.104 -350.812)"
          fill="#e3e3e3"
        />
        <g id="Group_64" data-name="Group 64">
          <path
            id="Path_24"
            data-name="Path 24"
            d="M406.223,382.295l-14.1-10.964-22.439-17.45-5.106-3.971c-.144-.112-.516.016-.639.112l-4.169,3.238-9.949,7.728-12.1,9.395-10.412,8.087-3.469,2.694a17.339,17.339,0,0,0-1.631,1.267.791.791,0,0,1-.073.057c-.038.029-.131.1-.063.148a6.981,6.981,0,0,1-.006,1.391l0,3.836q.006,6.3.012,12.6.007,7.666.015,15.332.006,6.595.013,13.19,0,2.176,0,4.351v1.357a2.351,2.351,0,0,0,0,.714.3.3,0,0,1,0,.093c0,.046.113.059.133.059l8.333-.008,20.005-.019,24.112-.023,20.849-.02c3.389,0,6.784.056,10.173-.01h.143c.142,0,.614-.05.614-.259q0-2.623,0-5.246-.006-6.3-.012-12.6l-.015-15.335q-.006-6.6-.013-13.193l0-4.351v-1.358a2.344,2.344,0,0,0,0-.714.3.3,0,0,1,0-.093c0-.046-.113-.059-.133-.059h-.186c-.168,0-.848.319-.481.319.177,0,.006-.114.053.019a.936.936,0,0,1,0,.258v1.019q0,1.921,0,3.842L405.7,400.3l.015,15.334q.006,6.607.013,13.215,0,2.2,0,4.4,0,.679,0,1.357a2.645,2.645,0,0,0,0,.714.341.341,0,0,1,0,.093l.614-.26-5.715.005-14.422.014-18.966.018-19.585.019-16.052.015-8.421.008c-.1,0-.364.051-.451,0,.237.137.13-.316.13-.5q0-2.13,0-4.26-.008-8.39-.016-16.779-.009-9.6-.018-19.2-.005-5.793-.011-11.586c0-.112.066-.466-.038-.546l-.063.148,4.169-3.238,9.949-7.728,12.1-9.395,10.412-8.087,3.468-2.694a10.483,10.483,0,0,0,1.631-1.267.474.474,0,0,1,.073-.057l-.639.112,14.1,10.965,22.439,17.45,5.106,3.971C405.687,382.684,406.347,382.391,406.223,382.295Z"
            transform="translate(-322.054 -349.865)"
            fill="#e3e3e3"
          />
        </g>
      </g>
      <g id="Group_66" data-name="Group 66" transform="translate(0.004 32.314)">
        <path
          id="Path_25"
          data-name="Path 25"
          d="M322.89,611.952q0-2.623-.005-5.246-.006-6.3-.012-12.6l-.015-15.335q-.007-6.6-.013-13.193,0-2.176,0-4.352v-1.358a2.5,2.5,0,0,0,0-.714.331.331,0,0,1,0-.093l-.513.281,8.333-.008,20.005-.019,24.112-.023,20.849-.02,6.882-.007,2.17,0a4.437,4.437,0,0,0,1.12,0,.564.564,0,0,1,.143,0l-.24-.208,0,5.246q.006,6.3.012,12.6.007,7.668.015,15.335l.013,13.193q0,2.176,0,4.351,0,.679,0,1.357a2.509,2.509,0,0,0,0,.714.319.319,0,0,1,0,.093l.513-.281-8.333.008-20.005.019-24.112.023-20.849.02c-3.386,0-6.787-.085-10.173.01-.048,0-.1,0-.143,0-.324,0-.776.49-.273.49l8.333-.008,20.005-.019,24.112-.023,20.849-.02c3.386,0,6.787.085,10.173-.01.047,0,.1,0,.143,0,.142,0,.513-.067.513-.282l0-5.246q-.006-6.3-.012-12.6-.007-7.667-.015-15.335-.006-6.6-.013-13.193,0-2.176,0-4.352a11.491,11.491,0,0,0,0-2.071.542.542,0,0,1,0-.093.219.219,0,0,0-.24-.208l-8.333.008-20.005.019-24.112.023-20.849.02c-3.386,0-6.787-.084-10.173.01-.047,0-.1,0-.143,0-.142,0-.513.067-.513.282l.005,5.246q.006,6.3.012,12.6.007,7.668.015,15.335.006,6.6.013,13.193,0,2.176,0,4.352a10.616,10.616,0,0,0,0,2.071.475.475,0,0,1,0,.093C322.138,612.381,322.89,612.246,322.89,611.952Z"
          transform="translate(-322.077 -558.77)"
          fill="#cfcfcf"
        />
      </g>
    </g>
    <g id="Group_72" data-name="Group 72" transform="translate(13.085)">
      <path
        id="Path_26"
        data-name="Path 26"
        d="M446.946,375.673l0-67.012a2.958,2.958,0,0,0-2.963-2.953l-60.377,0a2.958,2.958,0,0,0-2.963,2.954v10.444l.05,5.756,0,63.638a2.958,2.958,0,0,0,2.963,2.954h47.511l15.778-15.766Z"
        transform="translate(-380.223 -305.615)"
        fill="#e5e5e5"
      />
      <path
        id="Path_27"
        data-name="Path 27"
        d="M393.844,330.97l.048,5.615,0,62.072a2.863,2.863,0,0,0,2.845,2.881H442.36l.658-.668V389.1a3.127,3.127,0,0,1,3.144-3.111h9.519a2.375,2.375,0,0,0,2.388-2.363l0-62.849a2.863,2.863,0,0,0-2.845-2.881l-58.533,0a2.863,2.863,0,0,0-2.845,2.881Z"
        transform="translate(-391.383 -315.921)"
        fill="#fff"
      />
      <g id="Group_68" data-name="Group 68" transform="translate(0.063 45.71)">
        <path
          id="Path_28"
          data-name="Path 28"
          d="M444.2,600.785l-16.918,13.1-2.379,1.843.563-.2-9.221.007-14.625.01-3.4,0,.505.1-16.684-12.924-2.362-1.829c-.488-.378-1.817.32-1.161.828L391.4,611.7l5.9,4.569a1.569,1.569,0,0,0,1.08.31l2.529,0,20.008-.014,3.371,0a2.929,2.929,0,0,0,1.6-.206c.466-.292.891-.691,1.325-1.026l3.14-2.432,14.511-11.239.317-.246c.8-.62-.418-1.055-.975-.623Z"
          transform="translate(-378.345 -600.611)"
          fill="#e3e3e3"
        />
      </g>
      <g id="Group_69" data-name="Group 69">
        <path
          id="Path_29"
          data-name="Path 29"
          d="M445.035,375.312v-7.573q0-8.9,0-17.809,0-10.34,0-20.68,0-8.092,0-16.184,0-2.053,0-4.1c0-.217.005-.435,0-.653a3.212,3.212,0,0,0-3.2-3.161c-2.8-.086-5.612,0-8.41,0H382.3c-.273,0-.546,0-.82,0a3.459,3.459,0,0,0-3.437,2.611,14.6,14.6,0,0,0-.065,2.928v5.01c0,3.255.05,6.509.05,9.763q0,6.021,0,12.042,0,9.911,0,19.822,0,9.732,0,19.463v10.965a6.855,6.855,0,0,0,.032.944,3.224,3.224,0,0,0,3.223,2.674c1.145.026,2.293,0,3.437,0H427.5c.387,0,.784.027,1.17,0a1.169,1.169,0,0,0,.841-.48l12.236-12.226,2.4-2.394c.169-.169.886-.672.892-.956.007-.346-.745-.209-.751.077,0-.1.1-.143-.037,0l-.327.327-1.145,1.144-3.787,3.785-8.438,8.431-1.974,1.973.443-.155H382.067a6.786,6.786,0,0,1-.934-.029,2.812,2.812,0,0,1-2.351-2.821c-.057-2.648,0-5.3,0-7.952q0-8.17,0-16.34V344.964q0-7.52,0-15.04c0-2.051.011-4.1-.007-6.155-.041-4.74-.044-9.479-.044-14.219a4.373,4.373,0,0,1,.627-3.021,2.61,2.61,0,0,1,2.106-.9c1.053,0,2.106,0,3.159,0h49.232c2.552,0,5.119-.082,7.669,0a2.808,2.808,0,0,1,2.759,2.766c.006.188,0,.378,0,.566v3.454q0,6.935,0,13.871,0,9.269,0,18.538,0,8.814,0,17.627V375.39C444.284,375.737,445.035,375.6,445.035,375.312Z"
          transform="translate(-377.936 -305.109)"
          fill="#cfcfcf"
        />
      </g>
      <g id="Group_71" data-name="Group 71" transform="translate(23.258 24.837)">
        <g id="Group_70" data-name="Group 70" transform="translate(1.021 7.165)">
          <path
            id="Path_30"
            data-name="Path 30"
            d="M554.549,515.673a13.349,13.349,0,0,0-6.148-3.24,15.784,15.784,0,0,0-11.252,1.508,10.784,10.784,0,0,0-2.173,1.5c-.327.305.4.36.611.166,2.437-2.274,6.276-3.232,9.507-3.118a13.424,13.424,0,0,1,8.766,3.5c.207.208.917-.08.69-.309Z"
            transform="translate(-534.892 -511.996)"
            fill="#cfcfcf"
          />
        </g>
        <circle
          id="Ellipse_14"
          data-name="Ellipse 14"
          cx="1.154"
          cy="1.154"
          r="1.154"
          transform="translate(0 1.632) rotate(-45)"
          fill="#cfcfcf"
        />
        <circle
          id="Ellipse_15"
          data-name="Ellipse 15"
          cx="1.154"
          cy="1.154"
          r="1.154"
          transform="translate(18.103 1.632) rotate(-45)"
          fill="#cfcfcf"
        />
      </g>
    </g>
    <g id="Group_76" data-name="Group 76" transform="translate(4.228 39.419)">
      <path
        id="Path_31"
        data-name="Path 31"
        d="M351.21,582.735l-28.12-21.79.051,52.882,83.615-.08L406.7,561l-27.672,21.782Z"
        transform="translate(-322.716 -560.79)"
        fill="#e3e3e3"
      />
      <path
        id="Path_32"
        data-name="Path 32"
        d="M368.324,583.028l-24.989-19.29.034,35.143a12.106,12.106,0,0,0,12.118,12.095l68.379-.065-.047-49.619L395.934,583Z"
        transform="translate(-339.83 -561.084)"
        fill="#eaeaea"
      />
      <g id="Group_73" data-name="Group 73" transform="translate(0.714 22.123)">
        <path
          id="Path_33"
          data-name="Path 33"
          d="M326.5,726.275l9.319-7.646,14.719-12.077,3.409-2.8-.754.2,11.689-.011,14.315-.014c.061,0,.379.044.426,0-.068.064-.331-.228-.207-.027a4.395,4.395,0,0,0,.934.761l4.465,3.639,13.507,11.007,5.8,4.729c1.013.825,2,1.731,3.059,2.493.052.037.1.081.149.121.442.36,1.791-.287,1.17-.793L393.016,713.24l-9.607-7.829-2.551-2.079a1.573,1.573,0,0,0-1.2-.368l-23.487.022c-.8,0-1.619-.036-2.423,0-.765.036-1.263.619-1.826,1.081l-8.248,6.767-17.778,14.587-.35.287c-.781.641.442.987.959.563Z"
          transform="translate(-325.288 -702.965)"
          fill="#e3e3e3"
        />
      </g>
      <g id="Group_74" data-name="Group 74">
        <path
          id="Path_34"
          data-name="Path 34"
          d="M349.521,581.765l-9.548-7.4-15.08-11.685-3.493-2.706c-.119-.092-.726.046-.726.225q0,2.622,0,5.245.006,6.3.012,12.6.007,7.666.015,15.332.006,6.6.013,13.19,0,2.175,0,4.351,0,.678,0,1.357a2.3,2.3,0,0,0,0,.714.3.3,0,0,1,0,.093c0,.042.116.047.13.047l8.333-.008,20-.019,24.112-.023,20.849-.02c3.387,0,6.787.08,10.173-.01.047,0,.1,0,.143,0,.127,0,.618-.06.617-.247l-.017-17.858q-.013-14.211-.027-28.422,0-3.234-.006-6.468c0-.169-.651.086-.711.132l-9.443,7.328-15.12,11.735-3.457,2.683.581-.179-9.258,0-14.785-.005h-3.432c-.153,0-.842.294-.488.294l11.737,0,15.007.005a2.987,2.987,0,0,0,1.145-.087,3.473,3.473,0,0,0,.675-.487l4.054-3.146,13.84-10.741,6.2-4.814c1.069-.83,2.273-1.6,3.26-2.53.047-.044.1-.081.155-.12l-.711.132q.009,8.929.017,17.858.014,14.211.027,28.422,0,3.234.006,6.468l.617-.247-5.674.005-14.4.014-18.956.018-19.591.019-16.024.015-8.5.008c-.092,0-.393.054-.474,0,.214.142.123-.31.123-.458l0-4.171q-.008-8.307-.016-16.615-.009-9.651-.018-19.3-.006-5.858-.011-11.716,0-.287,0-.574l-.726.225,9.548,7.4,15.08,11.685,3.493,2.706C348.973,582.137,349.618,581.84,349.521,581.765Z"
          transform="translate(-320.675 -559.945)"
          fill="#cfcfcf"
        />
      </g>
      <g id="Group_75" data-name="Group 75" transform="translate(0.039 21.704)">
        <path
          id="Path_35"
          data-name="Path 35"
          d="M321.6,723.414l9.525-7.74,15.118-12.286,3.491-2.837-.445.148,11.732-.011,14.929-.014a2.266,2.266,0,0,0,.555,0c.2-.058-.067-.034-.067-.034a6.777,6.777,0,0,0,.817.662l4.344,3.518,13.724,11.115,5.959,4.826c1.042.844,2.056,1.757,3.142,2.545.051.037.1.081.149.121.194.157.882-.134.7-.284l-15.877-12.859-9.687-7.845c-.8-.644-1.585-1.3-2.386-1.932-.376-.3-.728-.229-1.205-.229l-24.161.023a20.449,20.449,0,0,0-2.364,0c-.6.072-1.158.727-1.6,1.088l-8.425,6.847-18.207,14.8-.345.28c-.323.263.418.244.593.1Z"
          transform="translate(-320.926 -700.26)"
          fill="#cfcfcf"
        />
      </g>
    </g>
  </g>
</g>
</svg>)
}