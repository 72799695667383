import React from 'react'
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import axios from 'axios';
import ServiceApi from './api.jsx'

const history = createBrowserHistory();

export default class AdminIntegrationsShow extends React.Component {
  constructor (props) {
    super(props);
  }

  componentDidMount () {
    this.unlisten = history.listen(this.getInfo);
    this.getInfo(window.location);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getInfo = (location) => {
    const id = location.hash.split('/').pop();

    if (location.hash !== `#/integrations/${id}`) {
      return;
    }

    ServiceApi.get(id, (response) => {
      ServiceApi.getPage(response.integration_edit_path, (pageResponse) => {
        document.querySelector('.edit-integration-modal').innerHTML = pageResponse.data
      })
    });
  }
  
  render() {
    return (
      <div className="layer-content">
        <div className="layer-container">
          <div className="layer-tabs">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link to="/admin" className="nav-link">
                    <i className="material-icons">chevron_left</i>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/integrations" className="nav-link active">
                    All Integrations
                </Link>
              </li>
            </ul>
          </div>
          <div className="card text-center">
            <div className="edit-integration-modal p-3" />
          </div>
        </div>
      </div>
    );
  }
} 
