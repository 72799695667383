import React from 'react';
import { classes } from '../Multiposter/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { IGenericDialogProps } from '../Multiposter/types';
import ErrorIcon from '@mui/icons-material/Error';
import dompurify from 'dompurify';

function WarningDialog({
  title,
  description,
  buttonText,
  isDialogOpen,
  setDialogOpen,
}: IGenericDialogProps) {
  return (
    <Dialog
      open={isDialogOpen}
      sx={{ '& .MuiPaper-root': classes.dialog }}
    >
      <ErrorIcon sx={{color: '#E37D7A', fontSize: '70px'}}/>
      <Box sx={{color: '#666666', marginBottom: '10px'}}><h1>{title}</h1></Box>
      {description && 
        <Box
          dangerouslySetInnerHTML={{ __html: dompurify.sanitize(description) }}
        />
      }
      <Box sx={classes.dialogButtonContainer}>
        <Button
          disableElevation
          sx={classes.buttonSecondary}
          onClick={() => setDialogOpen(false)}
          style={{ padding: '10px 30px' }}
        >
          {buttonText}
        </Button>
      </Box>
    </Dialog>
  );
}

export default WarningDialog;
