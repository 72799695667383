import React, { Dispatch, useState } from 'react';
import { Box, Popover, IconButton, Tooltip } from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { classes } from './styles';
import DensityToggles from './DensityToggles';
import SortableColumns from './SortableColumns';
import { ConfigOptionType } from '../types';

interface IDropdownProps {
  state: {
    density: string;
    fieldOptions: ConfigOptionType[];
    activeColumns: { [key: string]: { enabled: boolean } };
  };
  dispatch: Dispatch<any>;
}

export default function FieldDropdown({ state, dispatch }: IDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };

  return (
    <Tooltip title="Table settings" placement="top" arrow>
      <Box>
        <IconButton
          id="field-dropdown-applications-table-button"
          sx={
            dropdownOpen ? { ...classes.iconButton, ...classes.iconButtonOpen } : classes.iconButton
          }
          onClick={handleClick}
        >
          <ViewColumnIcon />
        </IconButton>
        <Popover
          id="field-dropdown-popover"
          sx={classes.popover}
          open={dropdownOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box sx={classes.popoverContent}>
            <SortableColumns state={state} dispatch={dispatch} />
            <DensityToggles density={state.density} dispatch={dispatch} />
          </Box>
        </Popover>
      </Box>
    </Tooltip>
  );
}
