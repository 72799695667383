import { SxProps } from '@mui/material';
import { classes } from '../Components/GenericTable/styles';

export const candidateApplicationsTableColumn = [
  {
    id: 'id',
    label: 'ID',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'job',
    label: 'Job',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'entity',
    label: 'Entity',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'created_at',
    label: 'Date',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'rating',
    label: 'Rating',
    sortable: true,
    canToggle: false,
    enabled: true
  },
  {
    id: 'status',
    label: 'Status',
    sortable: false,
    canToggle: false,
    enabled: true
  },
  {
    id: 'attachments',
    label: 'Attachments',
    sortable: false,
    canToggle: false,
    enabled: true
  }
];

export const headerStyle = (
  name: (typeof candidateApplicationsTableColumn)[number]['id'] | 'checkbox' | 'actions',
  tableHovered: boolean
): SxProps => {
  switch (name) {
    case 'id':
      return { ...classes.sticky, maxWidth: '100px', width: '100px' };
    case 'job':
      return {
        ...classes.sticky,
        ...classes.itemTitleTableCell,
        ...(tableHovered && classes.scrollShadowVisible),
        left: '100px',
        minWidth: '150px'
      };
    case 'entity':
      return { minWidth: '100px' };
    case 'created_at':
      return { minWidth: '75px' };
    case 'rating':
      return { minWidth: '75px' };
    case 'status':
      return { minWidth: '120px' };
    case 'attachments':
      return { minWidth: '150px' };
    case 'actions':
      return {
        ...classes.actionsColumnHeader,
        ...(tableHovered && classes.scrollShadowVisible)
      };
    default:
      return { minWidth: '90px' };
  }
};
