"use strict";
import { useState } from "react";
import { emojiInString } from "../utils/emoji-checker";
const useInputValidation = (input) => {
  const [inputError, setInputError] = useState(false);
  const validateInput = () => {
    if (emojiInString(input)) {
      setInputError(true);
      setTimeout(() => {
        setInputError(false);
      }, 4e3);
      throw new Error("Emojis are not allowed in notes.");
    }
    return true;
  };
  return { inputError, validateInput };
};
export default useInputValidation;
