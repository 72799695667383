import React, { Dispatch } from 'react';
import { Box, Button } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FelixMessageSVG } from '../../SharedComponents/Graphics/FelixSVG';
import { classes } from '../styles';
import { SmartShareAction, SmartShareState } from '../reducer';

const ResponseFooter = ({
  dispatch,
  smartShareState,
  handleRichCopy,
  handleCopy,
  jobUrl
}: {
  dispatch: Dispatch<SmartShareAction>;
  smartShareState: SmartShareState;
  handleRichCopy: () => void;
  handleCopy: () => void;
  jobUrl: string;
}) => {
  return (
    <Box sx={classes.footerContainer}>
      <Box sx={classes.felixMessageContainer}>
        <FelixMessageSVG />
        <Box sx={{ ...classes.felixMessage, maxWidth: '400px' }}>
          {smartShareState.generationType === 'social' ? (
            <>
              Your draft <span>social media post</span> is ready to roll! You may want to use this
              as is, or make some adjustments before publishing.
            </>
          ) : (
            <>
              Your draft <span>job board advertisement</span> is ready to roll! You may want to use
              this as is, or make some adjustments before publishing.
            </>
          )}
        </Box>
      </Box>
      <Box sx={classes.footerButtonsContainer}>
        <Button
          id={`back-to-edit-fields-${smartShareState.generationType}`}
          sx={classes.footerBackButton}
          onClick={() => dispatch({ type: 'SET_CURRENT_STEP', payload: 0 })}
        >
          Back
        </Button>
        {smartShareState.generationType === 'social' ? (
          <CopyToClipboard
            text={smartShareState.generatedResponse + `\n\n${jobUrl}`}
            onCopy={() => handleCopy()}
          >
            <Button
              id="copy-social-post-button"
              sx={classes.footerContinueButton}
              endIcon={<ContentCopyIcon />}
            >
              {smartShareState.copied ? 'Copied!' : 'Copy post text'}
            </Button>
          </CopyToClipboard>
        ) : (
          <Button
            id="copy-job-board-ad-button"
            sx={classes.footerContinueButton}
            endIcon={<ContentCopyIcon />}
            onClick={handleRichCopy}
          >
            {smartShareState.copied ? 'Copied!' : 'Copy advertisement'}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ResponseFooter;
