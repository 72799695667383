"use strict";
import { theme } from "../../ThemeContext/ThemeObject";
const dashboardStyles = {
  section: {
    border: "1px solid #E3E3E3",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: "100%"
  },
  kpiSection: {
    flexBasis: "20%",
    flexGrow: 1,
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "180px",
    maxHeight: "180px",
    padding: "30px"
  },
  sectionTitle: {
    color: "#333",
    fontWeight: "500",
    fontSize: "22px",
    padding: "30px"
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row"
  },
  noticeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "9px 16px",
    border: `1px solid ${theme.palette.action.main}`,
    borderRadius: "8px",
    color: "#666666",
    fontSize: "16px",
    margin: "26px 0px",
    rowGap: "1px"
  },
  infoIcon: {
    color: theme.palette.action.main,
    marginTop: "4px",
    fontSize: "16px"
  }
};
export default dashboardStyles;
