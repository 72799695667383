import React from 'react';
import { Autocomplete, Box, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styles } from '../styles';
import { IApprovalManagers } from '../types';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';

export default function ApproversListAutoComplete({
  approvalManagers,
  approver,
  index,
  handleEmailChange,
  removeApprover,
  handleMandatoryButton
}: {
  approvalManagers: IApprovalManagers[];
  approver: string;
  index: number;
  handleEmailChange: (index: number, value: IApprovalManagers | null) => void;
  removeApprover: (index: number) => void;
  handleMandatoryButton: (index: number) => void;
}) {
  const mandatory = approver.includes('*');
  let value = approvalManagers.find((manager) => approver === manager.email);
  if (!value) {
    const email = mandatory ? approver : `*${approver}`;
    value = approvalManagers.find((manager) => email === `*${manager.email}`);
    if (!value) value = approvalManagers.find((manager) => email === manager.email);
  }

  return (
    <Box sx={styles.approverItemList}>
      <Autocomplete
        disablePortal
        options={approvalManagers}
        getOptionLabel={(option) => option.email}
        id="default-approvers"
        value={value || null}
        onChange={(event, value) => handleEmailChange(index, value)}
        sx={{ width: '290px', marginTop: '24px', ...sharedClasses.formAutocomplete }}
        ListboxProps={{
          style: {
            fontFamily: 'Source Sans Pro, sans-serif',
            color: '#333333',
            maxHeight: '200px'
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="Email" InputLabelProps={{ shrink: true }} />
        )}
      />
      <CloseIcon
        color="warning"
        onClick={() => removeApprover(index)}
        sx={{ cursor: 'pointer', marginRight: 'auto' }}
      />
      <FormControlLabel
        control={
          <Switch
            sx={mandatory ? styles.switchActive : styles.switch}
            checked={mandatory}
            onChange={() => handleMandatoryButton(index)}
          />
        }
        label={
          <Typography
            sx={{ ...styles.mandatoryLabel, color: `${mandatory ? 'black' : '#939393'}` }}
          >
            Mandatory
          </Typography>
        }
      />
    </Box>
  );
}
