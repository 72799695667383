import React from 'react';
import { Box } from '@mui/material';
import { classes } from './styles';

const StaticQuestionsPreview = ({ questions }: { questions: string[] }) => {
  return (
    <Box sx={classes.questionsContainer}>
      {questions &&
        questions.map((item, index) => (
          <Box sx={classes.question} key={index}>
            <Box sx={{ maxWidth: '700px' }}>{item}</Box>
          </Box>
        ))}
    </Box>
  );
};

export default StaticQuestionsPreview;
