var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, CircularProgress, Box, Typography, Grid, DialogActions, Button } from "@mui/material";
import { capitalCase } from "change-case";
import Api from "../../../lib/Api";
import KpiSettingsCard from "./KpiSettingsCard";
import { withFormValidation } from "react-form-context-validation";
import { classes } from "./styles";
function KpiSettings({ open, setOpen, getDashboard }) {
  const [kpiSettings, setSettings] = useState(null);
  const [saving, setSaving] = useState(false);
  function fetchSettings() {
    return __async(this, null, function* () {
      const { settings } = yield Api.getDashboardSettings();
      setSettings(settings);
    });
  }
  function getCardList(category) {
    const list = Object.keys(kpiSettings[category]);
    list.sort((kpiA, kpiB) => kpiSettings[category][kpiA].position - kpiSettings[category][kpiB].position);
    return list;
  }
  function moveCard(category, kpi, forward) {
    const list = getCardList(category);
    const currentIndex = list.indexOf(kpi);
    const currentPosition = kpiSettings[category][kpi].position;
    const swapWith = list[forward ? currentIndex - 1 : currentIndex + 1];
    if (!swapWith) return;
    const newSettings = __spreadValues({}, kpiSettings);
    newSettings[category][kpi].position = newSettings[category][swapWith].position;
    newSettings[category][swapWith].position = currentPosition;
    setSettings(newSettings);
  }
  function saveSettings() {
    return __async(this, null, function* () {
      setSaving(true);
      yield Api.saveDashboardSettings(kpiSettings);
      setOpen(false);
      getDashboard();
    });
  }
  useEffect(() => {
    fetchSettings();
  }, []);
  return /* @__PURE__ */ React.createElement(
    Dialog,
    {
      open,
      onClose: () => {
        setOpen(false);
      },
      maxWidth: "lg"
    },
    /* @__PURE__ */ React.createElement(DialogTitle, { sx: { fontFamily: "Source Sans Pro" } }, "Dashboard Settings"),
    /* @__PURE__ */ React.createElement(DialogContent, null, kpiSettings ? Object.keys(kpiSettings).map((category) => /* @__PURE__ */ React.createElement(Box, { mb: 4, key: category }, /* @__PURE__ */ React.createElement(Typography, { variant: "h5", sx: { fontFamily: "Source Sans Pro" } }, ":", capitalCase(category)), /* @__PURE__ */ React.createElement(Box, { mb: 2 }), /* @__PURE__ */ React.createElement(Grid, { container: true, spacing: 2 }, getCardList(category).map((kpi) => /* @__PURE__ */ React.createElement(Grid, { item: true, key: kpi }, /* @__PURE__ */ React.createElement(
      KpiSettingsCard,
      __spreadProps(__spreadValues({}, kpiSettings[category][kpi]), {
        title: capitalCase(kpi),
        move: (forward) => moveCard(category, kpi, forward),
        onChange: (field, value) => setSettings(__spreadProps(__spreadValues({}, kpiSettings), {
          [category]: __spreadProps(__spreadValues({}, kpiSettings[category]), {
            [kpi]: __spreadProps(__spreadValues({}, kpiSettings[category][kpi]), {
              [field]: value
            })
          })
        }))
      })
    )))))) : /* @__PURE__ */ React.createElement(Box, { display: "flex", justifyContent: "center" }, /* @__PURE__ */ React.createElement(CircularProgress, null))),
    /* @__PURE__ */ React.createElement(DialogActions, { sx: classes.buttonContainer }, /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "outlined",
        onClick: () => setOpen(false),
        sx: classes.cancelButton
      },
      "Cancel"
    ), /* @__PURE__ */ React.createElement(Box, { position: "relative" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: saveSettings,
        disabled: saving,
        sx: classes.saveButton
      },
      "Save Setting"
    ), saving && /* @__PURE__ */ React.createElement(
      CircularProgress,
      {
        size: 24,
        sx: classes.buttonProgress
      }
    )))
  );
}
KpiSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  getDashboard: PropTypes.func.isRequired
};
export default withFormValidation(KpiSettings);
