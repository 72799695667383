import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    flexBasis: "15%",
    padding: "20px 50px",
    borderTop: "1px solid #F3F3F3",
    fontSize: 15,
    lineHeight: "20px",
    "& button:focus": {
      outline: 0
    }
  },
  textArea: {
    resize: "none",
    border: 0,
    borderRadius: 0,
    width: "100%",
    "&:focus-visible": {
      outline: 0
    },
    "&::placeholder": {
      color: "#B4B4B4"
    }
  },
  signature: {
    whiteSpace: "pre-line"
  },
  actions: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end"
  },
  characterCount: {
    marginLeft: "auto",
    color: "#B4B4B4",
    fontSize: 14,
    marginRight: 20
  },
  popper: {
    boxShadow: "0px 2px 14px rgba(48, 90, 111, 0.16)",
    padding: "8px 0",
    background: "#FFF",
    borderRadius: 6,
    zIndex: 1e5,
    overflow: "scroll",
    "& > div:last-child": {
      borderBottom: 0
    }
  },
  popperScheduler: {
    padding: 26,
    overflow: "visible",
    "& h4": {
      marginTop: 0,
      marginBottom: 16,
      color: theme.palette.primary.main,
      fontWeight: "600",
      fontSize: 20
    }
  },
  popperTemplate: {
    padding: 0,
    minWidth: 340,
    maxWidth: 340,
    minHeight: 500,
    maxHeight: 500
  },
  popperItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "6px 24px",
    "& svg": {
      marginRight: 12
    },
    "& span": {
      fontSize: 15,
      userSelect: "none"
    },
    "&:hover": {
      background: "#F3F6F7",
      cursor: "pointer"
    }
  },
  popperItemTemplate: {
    display: "flex",
    flexDirection: "column",
    padding: "25px 30px",
    borderBottom: "1px solid #F3F3F3",
    "& h4": {
      marginTop: 0,
      fontSize: 16,
      color: theme.palette.primary.main,
      fontWeight: "600",
      marginBottom: 6
    },
    "& span": {
      fontSize: 12,
      color: "#393939",
      lineHeight: "16px"
    },
    "&:hover": {
      background: "#F3F6F7",
      cursor: "pointer"
    }
  },
  noHover: {
    "&:hover": {
      background: "none",
      cursor: "default"
    }
  },
  textField: {
    width: "100%",
    padding: 20,
    paddingBottom: 6,
    "& .MuiInputBase-root": {
      background: "#FAFAFA",
      "&:hover fieldset": {
        border: "1px solid #D3DCE0"
      }
    },
    "& svg": {
      fill: "#939393"
    },
    "& input": {
      padding: 14,
      paddingLeft: 2
    },
    "& fieldset, .Mui-focused fieldset": {
      border: "1px solid #EAEEF0"
    }
  },
  actionButton: {
    "&:focus": {
      outline: 0
    }
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row"
  },
  send: {
    minWidth: 120,
    display: "flex"
  },
  spinner: {
    color: "#FFF"
  },
  sendButton: {
    minHeight: 40,
    maxHeight: 40,
    flexGrow: 1,
    borderRadius: 6,
    color: "#FFF",
    fontWeight: "600",
    fontSize: 16,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: 1,
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark
    }
  },
  schedulerButton: {
    minHeight: 40,
    borderRadius: 6,
    color: "#FFF",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.success.main,
    maxHeight: 37,
    "&:hover": {
      background: theme.palette.success.dark
    }
  },
  schedulerButtonActive: {
    background: theme.palette.success.dark
  },
  schedulerOptions: {
    display: "flex",
    alignItems: "center",
    "& fieldset": {
      border: "1px solid #DDDDDD !important"
    },
    "& > div": {
      marginRight: 20,
      "&:last-child": {
        marginRight: 0
      }
    }
  },
  select: {
    padding: "10px 18px",
    borderRadius: 6,
    border: "1px solid #DDD",
    width: "100%",
    minHeight: 46,
    maxHeight: 46,
    "&:focus": {
      background: "#FFF"
    }
  },
  calendarContainer: {
    position: "relative",
    "& input": {
      padding: "10px 18px",
      borderRadius: 6,
      border: "1px solid #DDD",
      width: "100%",
      minHeight: 46,
      maxHeight: 46,
      "&:focus": {
        background: "#FFF",
        outline: 0,
        borderColor: theme.palette.primary.main
      }
    },
    "& .DayPicker": {
      background: "#FFF",
      borderRadius: 6,
      boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
      position: "absolute",
      bottom: 46,
      zIndex: 100
    },
    "& .DayPicker-wrapper": {
      padding: "20px 10px",
      borderRadius: 6
    },
    "& .DayPicker-Caption": {
      height: "2rem",
      "& div": {
        fontWeight: "600",
        fontSize: 18,
        color: theme.palette.primary.main
      }
    },
    "& .DayPicker-NavBar": {
      top: "1.3rem",
      "& span": {
        height: "0.9rem",
        width: "0.9rem"
      }
    },
    "& .DayPicker-Weekdays abbr": {
      textDecoration: "none"
    },
    "& .DayPicker-Day": {
      border: 0,
      padding: "7px 12px",
      fontSize: 12
    },
    "& .DayPicker-Day--today": {
      color: theme.palette.primary.main,
      fontWeight: "600"
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "100%"
    }
  },
  startingTimeInputWrapper: {
    marginTop: 0,
    display: "flex",
    margin: "auto",
    "& .MuiButton-label": {
      margin: "0 10px"
    }
  },
  startTimeInput: {
    width: "100% !important",
    border: "1px solid #DDDDDD !important",
    padding: "7.5px 15px",
    borderRadius: "6px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: 0,
    height: 46,
    maxWidth: "5rem",
    "&:focus-visible": {
      outline: 0
    },
    "& .MuiInput-underline:before": {
      display: "none"
    },
    "& input": {
      fontSize: "0.85rem"
    },
    "& label": {
      display: "none"
    },
    "& button": {
      paddingRight: "0",
      paddingLeft: "0"
    },
    "& .MuiButton-label": {
      margin: "0 10px"
    }
  },
  isAMActive: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FFF !important"
  },
  isPMActive: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FFF !important"
  },
  timeBntGroupWrapper: {
    height: 46,
    "& button": {
      padding: "0",
      minWidth: "2.5rem",
      borderColor: "#DDDDDD",
      "&:focus": {
        outline: 0
      },
      "&:first-child": {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        borderLeft: 0
      }
    }
  }
}));
