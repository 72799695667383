import React, { useEffect, useState } from 'react';
import { useWebSockets } from './NewUI/Components/Utilities/useWebSockets';
import StyledSnackbar, { ISnackbarInput } from './NewUI/Components/CustomUIElements/StyledSnackbar';

const WorkatoNotification = () => {
  const { subscribeToChannel, unsubscribeFromChannel } = useWebSockets(
    'WorkatoChannel',
    (data: any, UID?: number) => {
      console.log('data.message:', data);
      setSnackbar({ message: data.message, state: data.state });
    }
  );
  const [snackbar, setSnackbar] = useState<ISnackbarInput>({
    message: '',
    state: 'success'
  });
  useEffect(() => {
    subscribeToChannel();
  }, []);

  return (
    <StyledSnackbar
      message={snackbar.message}
      state={snackbar.state}
      setSnackbarState={setSnackbar}
    />
  );
};

export default WorkatoNotification;
