"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { defaultJobDetails } from "./config";
export const initialAIStudioState = {
  open: false,
  currentStep: 0,
  optionalInputType: "",
  welcomeHeader: "",
  showCaret: true,
  buttonHovered: false,
  jobDetails: defaultJobDetails,
  generatedTone: "neutral",
  sendEmail: false,
  generatedResponse: "",
  generationError: false,
  questionType: "general",
  generationType: "description",
  navbarIsCollapsed: false,
  confirmCancelIsOpen: false,
  notificationData: [],
  notificationModalOpen: false,
  aiStudioFrenchEnabled: false,
  document: void 0
};
export const aiStudioReducer = (state, action) => {
  switch (action.type) {
    case "CLEAR_ALL_JOB_DETAILS":
      return __spreadProps(__spreadValues({}, state), {
        jobDetails: defaultJobDetails,
        generatedTone: "neutral",
        sendEmail: false
      });
    case "INIT_NEW_JOB_FLOW":
      return __spreadProps(__spreadValues({}, state), {
        jobDetails: __spreadProps(__spreadValues({}, defaultJobDetails), {
          position_title: action.payload.title,
          position_location: action.payload.country
        }),
        currentStep: 1,
        generationType: "description"
      });
    case "CLOSE_MODAL":
      return initialAIStudioState;
    case "OPEN_MODAL":
      return __spreadProps(__spreadValues({}, state), { open: true });
    case "SET_CURRENT_STEP":
      return __spreadProps(__spreadValues({}, state), { currentStep: action.payload });
    case "GO_TO_LOADING_STEP":
      return __spreadProps(__spreadValues({}, state), { currentStep: 3 });
    case "GO_TO_ERROR_STEP":
      return __spreadProps(__spreadValues({}, state), { currentStep: 4 });
    case "GO_TO_HOME_STEP":
      return __spreadProps(__spreadValues({}, state), { currentStep: 0 });
    case "SELECT_WRITE_TLP":
      return __spreadProps(__spreadValues({}, state), { currentStep: 1, generationType: "description" });
    case "SELECT_WRITE_SCREENING_QUESTION":
      return __spreadProps(__spreadValues({}, state), { currentStep: 5, generationType: "questions" });
    case "SELECT_OPTIONAL_INPUT":
      return __spreadProps(__spreadValues({}, state), { optionalInputType: action.payload, currentStep: 2 });
    case "ADD_TO_WELCOME_HEADER":
      return __spreadProps(__spreadValues({}, state), { welcomeHeader: state.welcomeHeader + action.payload });
    case "CLEAR_WELCOME_HEADER":
      return __spreadProps(__spreadValues({}, state), { welcomeHeader: "" });
    case "SET_SHOW_CARET":
      return __spreadProps(__spreadValues({}, state), { showCaret: action.payload });
    case "SET_BUTTON_HOVERED":
      return __spreadProps(__spreadValues({}, state), { buttonHovered: action.payload });
    case "SET_DOCUMENT":
      return __spreadProps(__spreadValues({}, state), { document: action.payload });
    case "SET_JOB_DETAILS":
      return __spreadProps(__spreadValues({}, state), {
        jobDetails: __spreadProps(__spreadValues({}, state.jobDetails), {
          [action.fieldName]: action.payload
        })
      });
    case "SET_GENERATED_TONE":
      return __spreadProps(__spreadValues({}, state), { generatedTone: action.payload });
    case "SET_SEND_EMAIL":
      return __spreadProps(__spreadValues({}, state), { sendEmail: action.payload });
    case "SET_GENERATED_RESPONSE":
      return __spreadProps(__spreadValues({}, state), { generatedResponse: action.payload });
    case "SET_GENERATION_ERROR":
      return __spreadProps(__spreadValues({}, state), { generationError: action.payload });
    case "SET_QUESTION_TYPE":
      return __spreadProps(__spreadValues({}, state), { questionType: action.payload });
    case "SET_NAVBAR_IS_COLLAPSED":
      return __spreadProps(__spreadValues({}, state), { navbarIsCollapsed: action.payload });
    case "SET_CONFIRM_CANCEL_IS_OPEN":
      return __spreadProps(__spreadValues({}, state), { confirmCancelIsOpen: action.payload });
    case "SET_NOTIFICATIONS":
      return __spreadProps(__spreadValues({}, state), { notificationData: action.payload });
    case "SET_RECEIVED_NOTIFICATION":
      if (!state.notificationModalOpen) {
        return __spreadProps(__spreadValues({}, state), {
          notificationData: [...state.notificationData, action.payload]
        });
      } else {
        const tempNotificationData = [...state.notificationData];
        tempNotificationData.splice(1, 0, action.payload);
        return __spreadProps(__spreadValues({}, state), {
          notificationData: tempNotificationData
        });
      }
    case "DISMISS_NOTIFICATION":
      return __spreadProps(__spreadValues({}, state), {
        notificationData: state.notificationData.slice(1),
        notificationModalOpen: false
      });
    case "VIEW_NOTIFICATION":
      return __spreadProps(__spreadValues({}, state), { notificationModalOpen: true });
    case "SET_DISMISSED_NOTIFICATION":
      if (state.notificationModalOpen) return state;
      return __spreadProps(__spreadValues({}, state), {
        notificationData: state.notificationData.filter(
          (notification) => notification.id !== action.payload
        )
      });
    case "TOGGLE_FRENCH":
      return __spreadProps(__spreadValues({}, state), { aiStudioFrenchEnabled: !state.aiStudioFrenchEnabled });
    default:
      return state;
  }
};
