import { useQuery } from '@tanstack/react-query';
import { getColumnsData, parseColumnName } from './helper';
import RecruitApi from '../RecruitApi';
import { updateEntityOptions } from '../DataVizSection/GraphDefaultOptions';
import { CustomReportsStateType } from './types';
import { endOfDay, startOfDay } from '../../NewUI/Components/Charts/helper';

export const useReportOptions = (dispatch, setSnackbar, reportApiService) => {
  const optionsFetcher = async () => {
    const { res } = await RecruitApi.getEntities();
    const { entities, current_entity } = res;
    if (current_entity) {
      // add (parent entity) text to option
      const i = entities?.findIndex(
        (x: { id: number; name: string }) => current_entity?.id === x?.id
      ) as number;
      if (i >= 0)
        entities[i] = {
          id: entities[i].id,
          name: `${entities[i].name} (parent entity)`
        } as { id: number; name: string };
    }

    const createReportOptions: CustomReportsStateType['createReportOptions'] = {
      entitiesOptions: { entities: updateEntityOptions(entities), current_entity }
    };
    await reportApiService.reports
      .schemas()
      .then(
        (options) =>
          options?.data?.map(
            (field) => (createReportOptions[field?.attributes?.type] = field.attributes.columns)
          )
      );
    createReportOptions.currentUser = reportApiService.currentUser;
    return createReportOptions;
  };

  const { isLoading } = useQuery(['createOptions'], optionsFetcher, {
    onSuccess: (createOptions) =>
      dispatch({ type: 'SET_CREATE_REPORT_OPTIONS', payload: createOptions }),
    onError: () =>
      setSnackbar({
        message: 'Failed to fetch create report options',
        state: 'error'
      })
  });

  return { isLoading };
};

export const usePreviewTable = (reportState, dispatch, setSnackbar, reportServiceApi) => {
  const userTimezone = reportServiceApi.currentUser.iana_timezone;
  const tableFetcher = async () => {
    const entity_id =
      reportState.data.entityIds[0].name === 'All entities'
        ? reportServiceApi.accessibleEntities
        : reportState.data.entityIds.map((i) => i.id);
    const params = {
      entity_id,
      columns: getColumnsData(reportState.data),
      start_date: startOfDay(reportState.startDate, userTimezone),
      end_date: endOfDay(reportState.endDate, userTimezone)
    };
    return reportServiceApi.reports.getPreview(params);
  };

  const { isLoading } = useQuery(
    [reportState.data, reportState.startDate, reportState.endDate],
    tableFetcher,
    {
      // When the query function is async, don't run it immediately
      enabled: reportState.openPreview,
      onSuccess: (options) => {
        const { data, errors, headers } = options;
        if (errors) throw new Error(errors);
        if (data?.length > 0) {
          const tableHeader = Object.keys(data[0]?.attributes);
          tableHeader.map((h, i) => (tableHeader[i] = parseColumnName(h)));
          dispatch({
            type: 'SET_PREVIEW_TABLE',
            payload: {
              tableHeaders: tableHeader,
              data: data
            }
          });
        } else
          dispatch({
            type: 'SET_PREVIEW_TABLE',
            payload: {
              tableHeaders: headers,
              data: null
            }
          });
      },
      onError: () =>
        setSnackbar({
          message: 'Failed to preview table data',
          state: 'error'
        })
    }
  );

  return { isLoading };
};
