import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Switch, Skeleton } from '@mui/material';
import { classes } from '../styles';
import { classes as switchClasses } from '../../../../AIStudio/SharedComponents/styles';
import QuestionFieldViewer, {
  IApplicantQuestion
} from '../../../Components/Utilities/QuestionFieldViewer';
import { handleAPIRequest } from '../../../Components/Utilities/handleAPIRequest';
import { StyledSnackbarProps } from '../../../Components/CustomUIElements/StyledSnackbar';

export const QuestionsList = ({
  jobId,
  setSnackbarState
}: {
  jobId: number;
  setSnackbarState: StyledSnackbarProps['setSnackbarState'];
}) => {
  const [questions, setQuestions] = useState<IApplicantQuestion[]>([]);
  const [loadingQuestions, setLoadingQuestions] = useState(true);

  useEffect(() => {
    handleAPIRequest({
      url: `/api/v4/fields?job_id=${jobId}`,
      method: 'GET',
      onSuccess: (data) => {
        setQuestions(data.fields);
      },
      setLoaderState: setLoadingQuestions,
      setSnackbarState,
      successMessage: { show: false }
    });
  }, [jobId, setSnackbarState]);

  const handleSwitchChange = (index: number, fieldId: number) => {
    // Optimistically update the UI
    const newQuestions = [...questions];
    newQuestions[index].enabled_for_summary = !newQuestions[index].enabled_for_summary;
    setQuestions(newQuestions);

    // Send the API request
    handleAPIRequest({
      url: `/api/v4/fields/${fieldId}?job_id=${jobId}`,
      method: 'PUT',
      body: { enabled_for_summary: newQuestions[index].enabled_for_summary },
      onFailure: () => {
        // Revert the UI if the API request fails
        const newQuestions = [...questions];
        newQuestions[index].enabled_for_summary = !newQuestions[index].enabled_for_summary;
        setQuestions(newQuestions);
      },
      setSnackbarState,
      successMessage: { show: true, message: 'Question updated successfully' }
    });
  };

  if (loadingQuestions) {
    return (
      <Box sx={classes.questionsContainer}>
        {Array.from(Array(3).keys()).map((_, index) => (
          <Skeleton variant="rectangular" sx={classes.questionSkeleton} key={index} />
        ))}
      </Box>
    );
  }
  return (
    <Box sx={classes.questionsContainer}>
      {questions.map((item, index) => (
        <Box sx={classes.question} key={item.id}>
          <Box sx={{ maxWidth: '640px' }}>
            <QuestionFieldViewer question={item} />
          </Box>
          <Box sx={classes.switchContainer}>
            <FormControlLabel
              sx={
                !item.enabled_for_summary
                  ? { ...switchClasses.switchLabels, ...switchClasses.labelActive }
                  : switchClasses.switchLabels
              }
              control={
                <Switch
                  sx={
                    item.enabled_for_summary ? switchClasses.aiSwitchActive : switchClasses.switch
                  }
                  checked={item.enabled_for_summary as boolean}
                  onChange={() => handleSwitchChange(index, item.id)}
                />
              }
              label="Include"
              labelPlacement="end"
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
