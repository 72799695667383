import React from 'react';
import { Box, Grid } from '@mui/material';
import { StyledSnackbarProps } from '../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { StatPanel } from './StatPanel';

const types: ('time_to_hire' | 'offer_accepted' | 'avg_application_per_job')[] = [
  'avg_application_per_job',
  'offer_accepted',
  'time_to_hire'
];

const OverviewMetrics = ({
  setSnackbar
}: {
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
}) => {
  return (
    <Box sx={{ marginBottom: '2.5rem' }}>
      <Grid container spacing={3}>
        {types.map((type, i) => (
          <StatPanel key={i} setSnackbar={setSnackbar} type={type} />
        ))}
      </Grid>
    </Box>
  );
};

export default OverviewMetrics;
