import React, { useRef, Dispatch, SetStateAction } from 'react';
import { Box, Button, Checkbox } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { IQuestion } from './AIStudioQuestions';
import { classes } from './styles';
import ConfettiExplosion from 'react-confetti-explosion';
import QuestionSwitch from '../SharedComponents/Inputs/Switch';
import ResponseFooter from './Footers/ResponseFooter';

export default function AIStudioResponse({
  generatedResponse,
  setGeneratedResponse,
  generateQuestions,
  setCurrentStep,
  handleSubmit,
  isCreating,
  userPermissions
}: {
  generatedResponse: IQuestion[] | undefined;
  setGeneratedResponse: Dispatch<SetStateAction<IQuestion[] | undefined>>;
  generateQuestions: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  handleSubmit: () => void;
  isCreating: boolean;
  userPermissions: Record<string, Record<string, boolean>>;
}) {
  const playConfetti = useRef(true);
  setTimeout(() => {
    playConfetti.current = false;
  }, 3500);
  if (generatedResponse === undefined) {
    return (
      <Box sx={classes.aiQuestionsContainer}>
        There was an error generating your questions. Please try again.
      </Box>
    );
  }

  const addButtonText = `Add ${
    generatedResponse.filter((item) => item.selected).length > 0
      ? '(' + generatedResponse.filter((item) => item.selected).length + ')'
      : ''
  }`;

  const handleSwitchChange = (index: number, type: string) => {
    playConfetti.current && (playConfetti.current = false);
    const newQuestions = [...generatedResponse];
    switch (type) {
      case 'enabled':
        newQuestions[index].enabled = !newQuestions[index].enabled;
        break;
      case 'required':
        newQuestions[index].required = !newQuestions[index].required;
        break;
      case 'rateable':
        newQuestions[index].rateable = !newQuestions[index].rateable;
        break;
      case 'confidential':
        newQuestions[index].confidential = !newQuestions[index].confidential;
        break;
      default:
        break;
    }
    setGeneratedResponse(newQuestions);
  };

  const handleSelectQuestion = (index: number) => {
    playConfetti.current && (playConfetti.current = false);
    const newQuestions = [...generatedResponse];
    newQuestions[index].selected = !newQuestions[index].selected;
    setGeneratedResponse(newQuestions);
  };

  return (
    <>
      {playConfetti.current && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <ConfettiExplosion
            force={0.5}
            duration={4000}
            particleCount={120}
            height={1000}
            width={1000}
          />
        </Box>
      )}
      <Box sx={classes.aiQuestionsContainer}>
        <Box sx={classes.descriptionHeader}>
          <Box sx={classes.descriptionHeaderText}>Your screening questions</Box>
          <Button
            id="regenerate-questions-integration-button"
            sx={classes.pill}
            endIcon={<CachedIcon />}
            onClick={generateQuestions}
          >
            Regenerate
          </Button>
        </Box>
        <Box sx={classes.questionsContainer}>
          {generatedResponse.map((item, index) => (
            <Box sx={classes.question} key={index}>
              <Box>
                <Checkbox
                  sx={classes.checkbox}
                  checked={item.selected}
                  onChange={() => handleSelectQuestion(index)}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
                <Box sx={{ marginTop: '7px' }}>{item.title}</Box>
                <Box>
                  <Box sx={{ display: 'flex' }}>
                    <QuestionSwitch
                      item={item}
                      index={index}
                      type="enabled"
                      label="Enabled"
                      onChange={handleSwitchChange}
                    />
                    <QuestionSwitch
                      item={item}
                      index={index}
                      type="required"
                      label="Required"
                      onChange={handleSwitchChange}
                    />
                    <QuestionSwitch
                      item={item}
                      index={index}
                      type="rateable"
                      label="Rateable"
                      onChange={handleSwitchChange}
                    />
                    <QuestionSwitch
                      item={item}
                      index={index}
                      type="confidential"
                      label="Confidential"
                      onChange={handleSwitchChange}
                    />
                  </Box>
                </Box>
              </Box>
              {generatedResponse[index].confidential && (
                <Box sx={{ padding: '0 6px', color: '#838383', fontSize: '12px' }}>
                  <span style={{ fontWeight: 'bold' }}>Confidential Setting Enabled:</span> For DE&I
                  compliance, only authorised users (HR Admins), can view responses. Hiring Managers
                  will not have access.
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <ResponseFooter
          generatedResponse={generatedResponse}
          setCurrentStep={setCurrentStep}
          handleSubmit={handleSubmit}
          isCreating={isCreating}
          addButtonText={addButtonText}
        />
      </Box>
    </>
  );
}
