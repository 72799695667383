import React, { useEffect } from 'react';
import { classes } from '../../Components/Modals/styles';
import { Box } from '@mui/material';
import { IAttachment } from '../types';

export default function AudioPreview({
  selectedAttachment,
  setIsLoadingPreview
}: {
  selectedAttachment: IAttachment;
  setIsLoadingPreview: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const BASE_URL = window.location.origin;

  useEffect(() => {
    setIsLoadingPreview(true);
  }, [selectedAttachment]);

  return (
    <Box sx={{ ...classes.mediaContentContainer, height: '15vh' }}>
      <audio
        controls
        onLoadStart={() => setIsLoadingPreview(true)}
        onCanPlayThrough={() => setIsLoadingPreview(false)}
      >
        <source src={`${BASE_URL}/admin/assets/${selectedAttachment.id}`} />
        Your browser does not support the audio tag.
      </audio>
    </Box>
  );
}
